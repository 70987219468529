<h3 class="text-color-secondary mb-4 mt-2">Outputs</h3>
<div class="container card">
    <div class="sidebar">
        <ul class="input-section">
            <li class="input-list" *ngFor="let option of sidebarOptions" (click)="selectOption(option.id)"
                [ngClass]="option.id === selectedOption ? 'bg-primary-100 border-1 border-primary-300 border-round-lg' : ''">
                {{ option.name }}
            </li>
        </ul>
    </div>

    <div class="content col-10 mx-auto">
        <ng-container *ngIf="selectedOption === 'yearAgo'">
            <div class="col-12">
                <p-fieldset legend="Years Ago">
                    <mvs-form-control-output-years-ago [value]="date"></mvs-form-control-output-years-ago>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'userName'">
            <div class="grid">
                <div class="col-12">
                    <p-fieldset legend="User Name">
                        <mvs-form-control-output-user-name [value]="'root'"></mvs-form-control-output-user-name>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedOption === 'avatar'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Selected Avatar">
                        <mvs-form-control-output-selected-avatar
                                [value]="oString"></mvs-form-control-output-selected-avatar>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="User">
                        <mvs-form-control-output-user [value]="'root'"></mvs-form-control-output-user>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Initial">
                        <mvs-form-control-output-initials [value]="oString"></mvs-form-control-output-initials>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'dateTime'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Date">
                        <mvs-form-control-output-date [value]="date"></mvs-form-control-output-date>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Time Date">
                        <mvs-form-control-output-time-date [value]="date"></mvs-form-control-output-time-date>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Date Time">
                        <mvs-form-control-output-date-time [value]="date"></mvs-form-control-output-date-time>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Date Ago">
                        <mvs-form-control-output-date-ago [value]="date"></mvs-form-control-output-date-ago>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Due Date">
                        <mvs-form-control-output-due-date [value]="date"></mvs-form-control-output-due-date>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Seconds in HHMMSS">
                        <mvs-form-control-output-seconds-in-HhMmSs
                                [value]="time"></mvs-form-control-output-seconds-in-HhMmSs>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Seconds in HHMM">
                        <mvs-form-control-output-seconds-in-HhMm
                                [value]="time"></mvs-form-control-output-seconds-in-HhMm>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedOption === 'showMore'">
            <div class="grid">
                <div class="col-12">
                    <p-fieldset legend="Show More Wrapper">
                        <mvs-form-control-text-show-more-wrapper
                                [value]="description"></mvs-form-control-text-show-more-wrapper>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'textEvent'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Text">
                        <mvs-form-control-output-text [value]="oString"></mvs-form-control-output-text>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Text Event">
                        <mvs-form-control-text-event [value]="oString"></mvs-form-control-text-event>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Text Clipboard">
                        <mvs-form-control-output-text-clipboard
                                [value]="oString"></mvs-form-control-output-text-clipboard>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Link">
                        <mvs-form-control-link [value]="oString"></mvs-form-control-link>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="HyperLink">
                        <mvs-form-control-hyperlink [value]="oString"></mvs-form-control-hyperlink>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'tagChip'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Tag">
                        <mvs-form-control-output-tag [value]="oString"></mvs-form-control-output-tag>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Chip">
                        <mvs-form-control-output-chip [value]="oString"></mvs-form-control-output-chip>

                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Badge">
                        <mvs-form-control-output-badge [value]="1"
                                                       [formField]="{valueList:valueList}"></mvs-form-control-output-badge>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'range'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Range">
                        <mvs-form-control-output-range [value]="oNumber"></mvs-form-control-output-range>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Range Color">
                        <mvs-form-control-output-range-color [value]="oNumber"></mvs-form-control-output-range-color>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Range Progress">
                        <mvs-form-control-output-range-progress
                                [value]="oNumber"></mvs-form-control-output-range-progress>

                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Range Progress 100">
                        <mvs-form-control-output-range-progress-100
                                [value]="oNumber"></mvs-form-control-output-range-progress-100>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'navigation'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Navigate to object right">
                        <mvs-form-control-output-navigate-to-object-right
                                [value]="oString"></mvs-form-control-output-navigate-to-object-right>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Navigate to object left">
                        <mvs-form-control-output-navigate-to-object-left
                                [value]="oString"></mvs-form-control-output-navigate-to-object-left>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Navigate to object bottom">
                        <mvs-form-control-output-navigate-to-object-bottom
                                [value]="oString"></mvs-form-control-output-navigate-to-object-bottom>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Navigate to object main">
                        <mvs-form-control-output-navigate-to-object-main
                                [value]="oString"></mvs-form-control-output-navigate-to-object-main>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Navigate to object new">
                        <mvs-form-control-output-navigate-to-object-new
                                [value]="oString"></mvs-form-control-output-navigate-to-object-new>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'imageIcon'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Image">
                        <mvs-form-control-output-image [value]="imageSrc"></mvs-form-control-output-image>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Icon Image">
                        <mvs-form-control-output-icon-image [value]="imageSrc"></mvs-form-control-output-icon-image>

                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Icon">
                        <mvs-form-control-output-icon [value]="icon"></mvs-form-control-output-icon>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Color Icon">
                        <mvs-form-control-output-color-icon [value]="icon" [formField]="{id:'image',absoluteId:'image'}"
                                                            [dto]="{id:1}"></mvs-form-control-output-color-icon>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Device Icon">
                        <mvs-form-control-output-device-icon [value]="'Apple iPhone'"
                                                             [formField]="{absoluteId:'deviceType'}"
                                                             [dto]="{deviceId:'ND'}"></mvs-form-control-output-device-icon>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Icon Yes">
                        <mvs-form-control-output-icon-yes [value]="switch"></mvs-form-control-output-icon-yes>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Icon No">
                        <mvs-form-control-output-icon-no [value]="switch"></mvs-form-control-output-icon-no>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Icon Null">
                        <mvs-form-control-output-icon-null [value]="switch"></mvs-form-control-output-icon-null>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'boolean'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Bool">
                        <mvs-form-control-output-bool [value]="switch"></mvs-form-control-output-bool>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Bool Active">
                        <mvs-form-control-output-bool-active [value]="switch"></mvs-form-control-output-bool-active>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Bool Negative">
                        <mvs-form-control-output-bool-negative [value]="switch"></mvs-form-control-output-bool-negative>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'fileSize'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="File size kb">
                        <mvs-form-control-output-filesize-kb [value]="oNumber"></mvs-form-control-output-filesize-kb>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="File size mb">
                        <mvs-form-control-output-filesize-mb [value]="oNumber"></mvs-form-control-output-filesize-mb>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="File size gb">
                        <mvs-form-control-output-filesize-gb [value]="oNumber"></mvs-form-control-output-filesize-gb>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'phoneNumber'">
            <div class="grid">
                <div class="col-12">
                    <p-fieldset legend="Anonymize PhoneNumber">
                        <mvs-form-control-anonymize-phone-number
                                [value]="'03139634862'"></mvs-form-control-anonymize-phone-number>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'email'">
            <div class="grid">
                <div class="col-12">
                    <p-fieldset legend="Anonymize Email">
                        <mvs-form-control-anonymize-email
                                [value]="'daudkhan316@gmail.com'"></mvs-form-control-anonymize-email>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'amount'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Amount">
                        <mvs-form-control-output-amount [value]="oNumber"></mvs-form-control-output-amount>

                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Amount Color">
                        <mvs-form-control-output-amount-color [value]="oNumber"></mvs-form-control-output-amount-color>

                    </p-fieldset>
                </div>
            </div>
        </ng-container>


    </div>

</div>