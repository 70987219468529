import {Component, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsDashboardPage} from "../../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page";
import {OptionGroups} from "../../../../../core/dashboard/interfaces/mvs-dashboard-option-groups.interface";
import {PagingDto} from "@kvers/alpha-core-common";
import {
    ObjectRequestEntityProcessingTypeEnum
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {OptionGroupPlacementEnum} from "../../../../../core/dashboard/interfaces/option-group-placement.enum";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {AgentService} from "../../../../../am/service/api/agent.service";
import {AgentDto} from "../../../../../am/dto/agent.dto";
import {AgentPoolService} from "../../../../../am/service/api/agent-pool.service";
import {AgentPoolDto} from "../../../../../am/dto/agent-pool.dto";
import {SubSelection} from "../../../../../core/dashboard/interfaces/mvs-dashboard-sub-selection.interface";
import {CallTypeService} from "../../../../service/api/call-type.service";
import {CallService} from "../../../../service/api/call.service";
import {CallTypeDto} from "../../../../dto/call-type.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {GroupItem} from "../../../../../core/dashboard/interfaces/mvs-dashboard-group-item.interface";
import {PeriodHelper} from "@kvers/alpha-core-common";
import {AgentPoolAgentService} from "../../../../../am/service/api/agent-pool-agent.service";
import {AgentPoolAgentDto} from "../../../../../am/dto/agent-pool-agent.dto";

@Component({
    selector: 'pc-team-dashboard',
    templateUrl: '../../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page.html',
    styleUrls: ['./pc-team-dashboard.page.scss']
})
export class PcTeamDashboardPage
    extends MvsDashboardPage {

    // Properties for the component
    defaultLabel: string = "Offene Statusübersicht";

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    // Properties related to the logged-in agent
    loggedOnAgent: AgentDto;
    loggedOnAgentPools: AgentPoolDto[];
    agentPoolAgents: AgentPoolAgentDto[];

    // Constructor for the component, injecting necessary services
    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected agentService: AgentService,
        protected agentPoolService: AgentPoolService,
        protected agentPoolAgentService: AgentPoolAgentService,
        protected callService: CallService,
        protected callTypeService: CallTypeService,
        protected navigationService: MvsObjectNavigationService
    ) {

        super(route, router, coreService, navigationService)
    }


    ngOnInit() {
        super.ngOnInit();
    }

    /**
     *  Custom method to start the initialization process
     */
    startInit() {
        // we are not calling the super implementation as we need to
        // retrieve some additional information upfront.
        this.loadBasicData();
    }

    /**
     * Method to load basic data required for the component
     */
    loadBasicData() {

        this.fetchCallDirectionGroups();
    }


    /**
     * Refreshes the component by fetching ticket template and logged-on agent and setting the 'initialized' flag.
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * Method to get observable for main navigation
     */
    getMainNavigationObservable(): Observable<any> {
        return null;
    }

    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getSubNavigationObservable(): Observable<any> {
        const filterCriteria = this.getSubNavigationFilter();

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteria,
            [],
            ['callType'],
            [new ObjectRequestListAttribute('callType', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.callService.groupBy(objectRequestList);

    }

    /**
     * Method to get observable for right navigation
     */
    getTopRightNavigationObservable(): Observable<any> {

        const filterCriteria = this.getTopRightNavigationFilter();

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteria,
            [],
            ['createdDate'],
            [new ObjectRequestListAttribute('createdDate', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.callService.groupBy(objectRequestList);
    }

    /**
     * Method to get observable for load option groups
     */
    getOptionGroupsObservable(): Observable<any> {
        const filterCriteria = this.getOptionGroupsFilter();
        const objectRequestList = ObjectRequestListGroupBy.create(
            true,
            filterCriteria,
            [],
            ['callResult'],
            [new ObjectRequestListAttribute('callResult', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.callService.groupBy(objectRequestList);

    }

    /**
     * Method to store main options
     * @param value
     */
    storeMainOptions(value: any) {
        this.mainSelections = [];

        this.mainSelections = this.loggedOnAgentPools.map(obj => ({
            key: obj.id,
            label: obj.name,
            icon: obj.image,
            subLabel: ''
        }))

        const item = {
            key: 0,
            label: "All",
            icon: '',
            subLabel: ''
        }

        this.mainSelections.unshift(item);


        const loggedOnAgentItem = {
            key: this.loggedOnAgent.id,
            label: "My Calls",
            icon: '',
            subLabel: ''
        }

        this.mainSelections.unshift(loggedOnAgentItem);
        // this.mainSelections.push(loggedOnAgentItem);

    }

    /**
     * Method to store sub-navigation options
     * @param value
     */
    storeSubNavigation(value: any) {

        this.updateCounts(this.subSelections, value.entries);
    }


    updateCounts(menuStructure: SubSelection[], countData: any[]): SubSelection[] {
        const countMap = new Map();

        // Create a map of typeDtoId to type_count
        countData.forEach(dtoDetail => {
            countMap.set(dtoDetail.callTypeDtoId, dtoDetail.callType_count);
        });

        // Recursive function to update count and calculate sum for submenus
        function updateCount(menu: SubSelection) {
            menu.count = 0; // Initialize count to 0

            if (menu.items) {
                menu.items.forEach(item => {
                    updateCount(item);
                    if (menu.field == 'callType') {
                        menu.count += (item.count || 0);
                    }
                });
            }

            const callTypeDtoId = menu.key;
            if (countMap.has(callTypeDtoId)) {
                if (menu.field == 'callType') {
                    menu.count += countMap.get(callTypeDtoId);
                }
            }
        }

        // Update counts and calculate sums in the menu structure
        menuStructure.forEach(menu => updateCount(menu));

        return menuStructure;
    }


    /**
     * Method to store right option groups
     * @param value
     */
    storeTopRightOptions(value: any) {

        let uiEntries = PeriodHelper.groupData(value.entries, 'createdDate', true);
        this.typeFilterSelections = [];

        this.typeFilterSelections = uiEntries
            .map(obj => ({
                key: obj.key,
                label: obj.label,
                count: obj.amount
            }));

        const totalCount = this.typeFilterSelections.reduce((acc, item) => acc + item.count, 0);

        const item: GroupItem = {
            key: -1,
            label: 'All',
            count: totalCount,
            icon: ''
        }

        this.typeFilterSelections.unshift(item)
    }

    /**
     * Method to store side option groups
     * @param value
     */
    storeOptionGroups(value: DtoList) {
        const valueList = value.form.getFormField('callResult').valueList.entries;
        const groupedItems = []

        for (let entry of value.entries) {
            const id = entry['callResult'];
            if (id == undefined) {
                continue;
            }

            let label = ''
            let icon = ''
            label = valueList[id].label;
            icon = valueList[id].image;

            const item = {
                key: entry['callResult'],
                label: label,
                count: entry['callResult_count'],
                icon: icon
            }
            groupedItems.push(item);
        }

        const totalCount = groupedItems.reduce((acc, item) => acc + item.count, 0);

        const groupItemAll = [{
            key: -1,
            label: 'All',
            count: totalCount
        }];

        const optionGroupAll: OptionGroups = {
            key: 0,
            label: 'All',
            groupItems: groupItemAll,
            placement: OptionGroupPlacementEnum.regular
        };
        const optionGroup: OptionGroups = {
            key: 1,
            label: 'Call Result',
            groupItems: groupedItems,
            placement: OptionGroupPlacementEnum.regular
        };

        this.optionGroups = [optionGroupAll, optionGroup];

    }


    /**
     * Private method to construct filter criteria for top navigation
     * @private
     */
    private getSubNavigationFilter(): FilterCriteria[] {

        const filters = [];

        if (this.mainSelections && this.mainSelections.length && this.mainSelection.key) {
            if (this.loggedOnAgent.id == this.mainSelection.key) {
                filters.push(FilterCriteria.createSingleCondition("agent", FilterCriteria.cOperatorEqual, this.loggedOnAgent.id, null));
            } else {
                // const filter: FilterCriteria[] = [];
                const filteredAgentPoolAgents = this.agentPoolAgents.filter(agent => agent.agentPoolDtoId == this.mainSelection.key)
                const filter = FilterCriteria.createOrFromArray('agent', 'agentDtoId', filteredAgentPoolAgents)
                filters.push(filter);

            }
        } else {
            const filteredAgentPoolAgents = this.agentPoolAgents.filter(agent => {
                return this.loggedOnAgentPools.some(pool => pool.id === agent.agentPoolDtoId);
            });
            const filter = FilterCriteria.createOrFromArray('agent', 'agentDtoId', filteredAgentPoolAgents)
            filters.push(filter);
        }

        return filters;
    }

    /**
     * Private method to construct filter criteria for top right navigation
     * @private
     */
    private getTopRightNavigationFilter(): FilterCriteria[] {

        const filters = this.getSubNavigationFilter()

        if (this.subSelections && this.subSelections.length) {
            if (this.subSelection.field == 'callType') {
                filters.push(FilterCriteria.createSingleCondition("callType", FilterCriteria.cOperatorEqual, this.subSelection.key, null));
            } else {
                const filter: FilterCriteria[] = [];
                for (let items of this.subSelection.items) {
                    filter.push(FilterCriteria.createSingleCondition("callType", FilterCriteria.cOperatorEqual, items.key, null));
                }
                filters.push(FilterCriteria.createOr(...filter));
            }
        }

        return filters
    }

    /**
     * Private method to construct filter criteria for option groups
     * @private
     */
    private getOptionGroupsFilter(): FilterCriteria[] {

        const filters = this.getTopRightNavigationFilter()

        if (this.typeFilterSelection && this.typeFilterSelection.key && this.typeFilterSelection.key != -1) {
            const dates = PeriodHelper.getDateRangeForKey(this.typeFilterSelection.key);
            const startDate = PeriodHelper.convertDateToApiDate(dates.startDate);
            const endDate = PeriodHelper.convertDateToApiDate(dates.endDate);
            filters.push(FilterCriteria.create('createdDate', FilterCriteria.cOperatorBetween, startDate, endDate));
        }

        return filters;
    }


    /**
     * Private method to construct filter criteria for the widget
     * @private
     */
    private getWidgetFilter(): FilterCriteria[] {

        const filters = this.getOptionGroupsFilter()

        if (this.optionGroupSelection && this.optionGroupSelection.key >= 0) {
            filters.push(FilterCriteria.createSingleCondition("callResult", FilterCriteria.cOperatorEqual, this.optionGroupSelection.key, null));
        }

        return filters;
    }


    /**
     * Refreshes the widget based on the provided filter criteria.
     */
    refreshWidget() {
        const filter = this.getWidgetFilter();

        //show contract status by category
        this.widgetData = WidgetFactory.createWidgetListComplex(
            "pc.call.status.overview.dashboard.show.call",
            "Call",
            "table",
            "list",
            "entity",
            "pc.Call",
            "Keine Daten vorhanden",

            ObjectRequestList.createWithPaging(
                true,
                filter,
                [],
                PagingDto.create(0, 10),
                ObjectRequestEntityProcessingTypeEnum.regular),

            this.callService.getComplexSelectionCustomerAndLastProtocol(),

            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data")
        );

    }

    /**
     * Method to fetch ticket groups
     */
    fetchCallDirectionGroups() {

        const dtoRequest = new ObjectRequestList(
            true,
            [],
            [])

        this.callTypeService.list(dtoRequest).subscribe(res => {
            // Convert the API response to a format suitable for display
            this.subSelections = this.groupByCallDirection(res);
            // this.subSelections = this.groupByCallDirection(res.entries);

            // Fetch additional data related to logged-in agent
            this.fetchLoggedOnAgent();
        })
    }

    /**
     * Method to fetch information about the logged-on agent
     */
    fetchLoggedOnAgent() {

        this.agentService.me().subscribe(res => {
            this.loggedOnAgent = res;

            this.fetchLoggedOnAgentPools()
        })
    }

    /**
     * Method to fetch information about the logged-on agent's pools
     */
    fetchLoggedOnAgentPools() {

        this.agentPoolService.my().subscribe(res => {
            this.loggedOnAgentPools = res.entries;

            this.agentPoolAgentService.list(new ObjectRequestList(false, [], [])).subscribe(res => {
                this.agentPoolAgents = res.entries
                // now, we continue with the general lifecycle of the Dashboard
                this.initComponent();
            })


        })
    }

    /**
     * convert the ticket group data intp proper SubSelection
     * @param dtoList
     */
    groupByCallDirection(dtoList: DtoList<CallTypeDto>): SubSelection[] {
        const entries = dtoList.entries
        const valueList = dtoList.form.getFormField('callDirection').valueList.entries;

        // Step 1: Initialize an object to store grouped items
        const groupedItems = {};

        // Step 2: Group items based on callDirection and assign labels
        entries.forEach((item) => {
            const direction = item.callDirection || 0; // Default to 0 if callDirection is undefined
            const label = valueList[direction].label;
            const image = valueList[direction].image;

            if (!groupedItems[direction]) {
                groupedItems[direction] = {
                    key: direction,
                    label: label,
                    icon: image,
                    items: [],
                    field: 'callDirection'
                };
            }

            groupedItems[direction].items.push({
                icon: item.image,
                key: item.id,
                label: item.name,
                count: 0,
                field: 'callType'
                // ... other properties you want to include
            });
        });
        // Step 3: Create a new array with the grouped items
        return Object.values(groupedItems);

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
