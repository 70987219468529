import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UiPageUserFavoriteService} from "../../service/api/ui-page-user-favorite.service";
import {UiPageVisitResponseDto} from "../../dto/ui-page-visit-response.dto";
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {UiNavigationSubPageDto} from "../../dto/ui-navigation-sub-page.dto";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";

@Component({
    selector: 'ui-navigation-filtered-links',
    templateUrl: './ui-navigation-frequent-pages.component.html',
})
export abstract class UiNavigationFrequentPagesComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    icon: string = 'fa-light fa-heart';

    @Input() uiNavigationMainDto! : UiNavigationMainDto;

    frequentPages: UiPageVisitResponseDto[];

    navigationFrequentPages: UiPageVisitResponseDto[];

    constructor(protected crudService: MvsCrudService,) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    abstract getData(): Observable<any>;

    initComponent() {

        this.getData().subscribe(value => {
            this.frequentPages = value;
            this.refreshComponent();
        });
    }



    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.navigationFrequentPages = this.filterFavorites(this.retrieveNavigationSubPageDtoList());

        this.initialized = true;

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        if (changes["id"] || changes["uiNavigationMainDto"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    /**
     * Filter favorites list from pages in UiNavigationSubPageDtoList[]
     * @param uiNavigationSubPageDtoList
     * @protected
     */
    protected filterFavorites(uiNavigationSubPageDtoList: UiNavigationSubPageDto[]):UiPageVisitResponseDto[] {
        let navigationFavorites:UiPageVisitResponseDto[] = [];

        for (const uiNavigationSubPageDto of uiNavigationSubPageDtoList) {
            for (const page of this.frequentPages) {
                // check if favorite belongs to sub nav page list
                if (page.pageId === uiNavigationSubPageDto.pageDtoId){
                    navigationFavorites.push(page);
                }else {
                    continue;
                }
            }
        }

        return navigationFavorites;
    }

    /**
     * Helper method to retrieve just simple page list from main nav
     * @private
     */
    private retrieveNavigationSubPageDtoList() : UiNavigationSubPageDto[]{
        let navigationPages: UiNavigationSubPageDto[] = [];
        // extract pages from sub navigations of main navigation element if sub navigation exists
        if (this.uiNavigationMainDto['subNavigations']){
            for (const subNavigationElement of this.uiNavigationMainDto.subNavigations) {
                if (subNavigationElement.pages){
                    navigationPages = navigationPages.concat(subNavigationElement.pages);
                }
            }
        }
        // return simple uiNavigationSubPageDtoList or empty List
        return navigationPages;

    }

}
