import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    ContractBaseTypeComponent
} from "../../components/object-base-components/contract-base-type-component/contract-base-type.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
    providedIn: 'root'
})
export class ContractBaseTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cm/contractBaseTypes')
    }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {

        if (mode != MvsCrudModeEnum.create) {
            return ContractBaseTypeComponent;
        }
        return null
    }

}