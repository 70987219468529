import {XrCrossReportSelectionFieldDto} from "./xr-cross-report-selection-field.dto";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";

export class XrCrossReportSelectionFormDto {

    public fields: XrCrossReportSelectionFieldDto[];

    public static convertToForm(selectionForm: XrCrossReportSelectionFormDto) : MvsFormDto {

        const mvsFormDto = new MvsFormDto();

        mvsFormDto.formFields = [];

        for (let field of selectionForm.fields) {
            mvsFormDto.formFields.push(field.formField);
        }
        return mvsFormDto;

    }

}