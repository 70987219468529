<ng-container *ngIf="initialized">
    <div class="gap-3 flex align-items-center">
<!--        <p-button (click)="gotoPrevious()" icon="fa-regular fa-arrow-left" pTooltip="Previous"-->
<!--                  styleClass="h-3rem border-noround"></p-button>-->

        <p-button icon="fa fa-arrow-left text-sm" styleClass="p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                  [pTooltip]="'Previous'"
                  (click)="gotoPrevious()"></p-button>

        <p-dropdown
                *ngIf="objectList"
                [options]="objectList"
                (onChange)="handleSelect()"
                [(ngModel)]="this.selectedObject"
                [showClear]="false" placeholder="Select a Contract"
                [filter]="true"
                optionLabel="name"
                styleClass="w-20rem border-round-base border-1 border-200"></p-dropdown>

        <p-button icon="fa fa-arrow-right text-sm" styleClass="p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                  (click)="gotoNext()"
                  [pTooltip]="'Next'"></p-button>

<!--        <p-button (click)="gotoNext()" icon="fa-regular fa-arrow-right" pTooltip="Next"-->
<!--                  styleClass="h-3rem border-noround"></p-button>-->
    </div>
</ng-container>