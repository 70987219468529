import {PmPersonMaritalStatus} from "../enum/pm-person-martial-status.enum";
import {DtoDetail} from "@kvers/alpha-core-common";

export class PmPersonDetailDto extends DtoDetail {

    public birthDate : Date;
    public birthName : string;
    public middleName : string;
    public maritalStatus : PmPersonMaritalStatus;
    public marriedSince : Date;
    public divorcedSince : Date;
}