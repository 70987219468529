import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {TeTemplateObjectComponent} from "../../component/te-template/te-template-object.component";
import {TeTemplateGenerateResponseDto} from "../../dto/te-template-generate-response.dto";
import {TeTemplateGenerateRequestDto} from "../../dto/te-template-generate-request.dto";
import {Observable} from "rxjs";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {ObjectIdentifierWithId} from "@kvers/alpha-core-common";
import {NavigationItem} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TeTemplateService extends MvsCrudService{

  navigationItems: NavigationItem[] = [
    { label: 'Variablen', icon: 'pi pi-folder' },
    { label: 'Artefakte', icon: 'fa-sharp fa-regular fa-pen-nib' },
    { label: 'Basisdaten', icon: 'pi pi-info-circle' },
    { label: 'Testen', icon: 'fa-regular fa-flask-vial' },
  ];

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/te/teTemplates');
  }

  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return TeTemplateObjectComponent;
    }

    if (mode == MvsCrudModeEnum.create) {
      return null
    }

    return null;
  }

  getObjectComponentNavigationItems(): NavigationItem[] {

    return this.navigationItems;

  }

  // TeTemplateGenerateRequestDto
  generateViaTestCase(
      templateId: number,
      testCaseId: number,
      generatePdf: boolean,
      objectVariables: {[key: string]: ObjectIdentifierWithId},
      simpleVariables: {[key: string]: any}) : Observable<TeTemplateGenerateResponseDto> {

    const requestDto = new TeTemplateGenerateRequestDto(
        true,
        true,
        testCaseId,
        generatePdf,
        objectVariables,
        simpleVariables);

    return this.http.post<TeTemplateGenerateResponseDto>(this.apiUrl + `/generate/${templateId}`, requestDto);

  }



}
