import {Component, OnInit} from "@angular/core";
import {
    FilterCriteria,
    NavigationItem,
    ObjectBaseModeComponent,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FieldTypeDto} from "../../../../dto/field-type.dto";

@Component({
    selector: 'value-list-object-base',
    template: '',
})

export class ValueListBaseComponent extends ObjectBaseModeComponent implements OnInit {

    basicWidgetData: WidgetData;
    fieldValueListEntriesWidget: WidgetData;
    dto: FieldTypeDto;

    navigationItems: NavigationItem[] = [
        {
            label: 'valueListEntries',
            action: 'valueListEntries',
            icon: 'fa-solid fa-list',
            tooltip: 'Value List Entries',
            default: true
        },
        {
            label: 'valueList',
            action: 'valueList',
            icon: 'fa-regular fa-info-circle',
            tooltip: 'Value List',
            default: false
        }

    ];


    ngOnInit() {

        if (this.navigationItems?.length) {
            this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            this.onNavigationItems.emit(this.navigationItems);
        }

        super.ngOnInit();

        this.refreshComponent();
    }

    refreshComponent() {
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.basicWidgetData = WidgetFactory.createWidgetEntityData(
            'cc.value-list.basic.widget.data',
            'Value List',
            'cc.FieldType',
            this.objectIdentifier?.objectId
        );


        const filterCriteria = FilterCriteria.create('fieldValueList', FilterCriteria.cOperatorEqual, this.objectIdentifier?.objectId);

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.fieldValueListEntriesWidget = WidgetFactory.createWidgetTableEntity(
            'cc.value.list.entries.widget.data',
            'Value List Entries',
            'cc.FieldValueListEntry',
            'No Values',
            objectRequestList
        );


    }
}