import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DtoList, FilterCriteria, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {WfProcessDto} from "../../../../wf/dto/wf-process.dto";
import {WfProcessStatusInternal} from "../../../../wf/enum/wf-process-status-internal.enum";

export interface MvsObjectCount {
    completedCount: number;
    pendingCount: number;
}

@Component({
    selector: 'mvs-workflow',
    templateUrl: './mvs-workflow.component.html',
})
export class MvsWorkflowComponent implements OnInit, OnChanges, OnDestroy {

    @Input() filterCriteria: FilterCriteria[];
    @Input() objectRequestList: ObjectRequestList;
    @Input() visible: boolean;
    @Output() onWorkflowCount = new EventEmitter<MvsObjectCount>();

    workflowWidget: WidgetData;
    completedWorkflowCount: number;
    pendingWorkflowCount: number;

    initialized: boolean;
    busy: boolean;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected workflowService: WfProcessService) {
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getTickets();
    }

    getTickets() {
        let objectRequestList = this.objectRequestList;

        if (!objectRequestList) {
            objectRequestList = new ObjectRequestList(
                true,
                this.filterCriteria,
                []
            );
        }

        this.workflowService.list(objectRequestList).subscribe((res: DtoList<WfProcessDto>) => {
            this.getTicketsCount(res.entries);
            this.refreshTicketWidget(res);
            this.initialized = true;
        });
    }

    getTicketsCount(dtoList: WfProcessDto[]) {
        this.completedWorkflowCount = 0;
        this.pendingWorkflowCount = 0;

        for (let item of dtoList) {
            if (item.processStatusInternal == WfProcessStatusInternal.completed || item.processStatusInternal == WfProcessStatusInternal.cancelled) {
                this.completedWorkflowCount++;
            } else {
                this.pendingWorkflowCount++;
            }
        }

        const event: MvsObjectCount = {
            completedCount: this.completedWorkflowCount,
            pendingCount: this.pendingWorkflowCount
        }

        this.onWorkflowCount.emit(event);

    }

    refreshTicketWidget(dtoList: DtoList<WfProcessDto>) {

        this.workflowWidget = WidgetFactory.createWidgetTransient(
            'mvs.wf.workflows.base.basic',
            'Workflows',
            'list',
            'transient',
            'transient',
            'wf.WfProcess',
            dtoList,
        );

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["filterCriteria"] || changes["objectRequestList"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }


}
