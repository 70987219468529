import {DtoDetail} from "@kvers/alpha-core-common";
import { CategoryDto } from "./category.dto";
import { GroupDto } from "./group.dto";
import { ContractTypeDto } from "./contract-type.dto";
import { ProductDto } from "./product.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {PartnerDto} from "./partner.dto";
import {CustomerDto} from "../../cr/dto/customer.dto";
import {ContractAgentDto} from "./contract-agent.dto";
import {ContractPriceDto} from "./contract-price.dto";
import {ContractStatusDto} from "./contract-status.dto";
import {ContractPartnerDto} from "./contract-partner.dto";
import {ContractPersonDto} from "../../ci/dto/contract-person.dto";
import {ContractInsurableObjectDto} from "../../ci/dto/contract-insurable-object.dto";

export class ContractDto extends DtoDetail{


    public categoryDto? : CategoryDto;
    public categoryDtoId : number;
    public categoryDtoName : string;
    public groupDto? : GroupDto;
    public groupDtoId : number;
    public baseTypeDtoId : number;
    public groupDtoName : string;
    public contractTypeDto? : ContractTypeDto;
    public contractTypeDtoId : number
    public contractTypeDtoName : string;
    public productDto? : ProductDto;
    public productDtoId : number;
    public productDtoName : string;
    public entityStatus: EntityStatusEnum;
    public contractIdentifier: string;
    public alias: string;
    public startDate: Date;
    public endDate: Date;
    public plannedEndDate: Date;
    public comment: string;

    public joinCurrentMainPartner?: PartnerDto;
    public joinCurrentMainCustomer?: CustomerDto;

    contractAgent?: ContractAgentDto;
    contractPrice?: ContractPriceDto;
    contractStatus?: ContractStatusDto;
    contractPartner?: ContractPartnerDto;

    public contractInsurableObject?: ContractInsurableObjectDto[];
    public contractPerson?: ContractPersonDto[];

}