<ng-container *ngIf="initialized">
    <div class="grid">
        <div class="col-12">
            <mvs-dashboard-top-navigation
                    [mainSelections]="mainSelections"
                    [subSelections]="subSelections"
                    [typeFilterSelections]="typeFilterSelections"
                    [(mainSelection)]="mainSelection"
                    (mainSelectionChange)="handleMainSelectionChange($event)"
                    [(subSelection)]="subSelection"
                    (subSelectionChange)="handleSubSelectionChange($event)"
                    [(typeFilterSelection)]="typeFilterSelection"
                    (typeFilterSelectionChange)="handleFilterSelectionChange($event)"
            >
            </mvs-dashboard-top-navigation>
        </div>
        <div class="col-12">

            <ng-container *ngIf="isCollapsed">
                <div class="border-round font-bold flex align-items-center justify-content-between px-2 my-2"
                     [style]="{'background': 'var(--primary-100)'}">
                    <div class="flex gap-2 align-items-center justify-content-between">
                        <span>{{optionGroupSelection.label}}</span>
                        <span>{{optionGroupSelection.count}}</span>
                    </div>
                    <p-button icon="fa-regular fa-xmark"
                              styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                              (click)="isCollapsed = !isCollapsed">
                    </p-button>
                </div>
            </ng-container>

            <div class="grid">
                <ng-container *ngIf="!isCollapsed">

                    <div class="col-4 xl:col-2">
                        <mvs-dashboard-side-options
                                [optionGroups]="optionGroups"
                                [isCollapsable]="true"
                                [(isCollapsed)]="isCollapsed"
                                (isCollapsedChange)="onCollapseStateChange($event)"
                                [(selectedItem)]="optionGroupSelection"
                                (selectedItemChange)="handleOptionGroupSelectionChange($event)"
                        ></mvs-dashboard-side-options>
                    </div>
                </ng-container>

                <div [ngClass]="isCollapsed ? 'col-12' : 'col-8 xl:col-10'">
                    <div class="shadow-1">
                        <mvs-widget
                                *ngIf="widgetData"
                                [widgetData]="widgetData"
                                (onObjectSelect)="handleRowAction($event)"
                                [widgetToolbarButtons]="widgetToolbarButtons"
                                (onToolbarButtonClick)="handleToolbarButtonClick($event)"
                        ></mvs-widget>
                    </div>

                </div>
            </div>
        </div>

    </div>

</ng-container>