import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {MvsFormFieldDto, ObjectIdentifier} from "@kvers/alpha-core-common";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";

@Component({
    selector: 'mvs-entity-search',
    templateUrl: './mvs-entity-search.component.html',
    styleUrls: ['./mvs-entity-search.component.scss']
})

export class MvsEntitySearchComponent implements OnInit {

    @Output() onChange: EventEmitter<{ objectType: ObjectTypeDto, objectId: number }> = new EventEmitter<{
        objectType: ObjectTypeDto,
        objectId: number
    }>();

    objectType: ObjectTypeDto;

    constructor(private formBuilder: FormBuilder) {
    }

    formGroup: FormGroup;
    formField: MvsFormFieldDto;

    ngOnInit() {
    }

    handleObjectType(objectType: ObjectTypeDto) {
        this.objectType = null;
        setTimeout(() => {
            this.objectType = objectType;

            this.formGroup = this.formBuilder.group({
                id: new FormControl('', [Validators.required]),
            });

            this.formField = new MvsFormFieldDto();
            this.formField.id = 'id';
            this.formField.dataType = this.objectType.alias;
        });

    }

    handleObjectSelect(id: number) {
        this.onChange.emit({objectType: this.objectType, objectId: id});
    }
}