import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeStepDto} from "./wf-process-type-step.dto";
import {WfProcessTypeFieldDto} from "./wf-process-type-field.dto";
import {WfProcessTypeResultDto} from "./wf-process-type-result.dto";

export class WfProcessTypeDto extends DtoDetail {

    public name : string;
    public processGroupDtoId : number;
    public processGroupDtoName : string;
    public processGroupDtoImage : string;
    public processGroupDtoColor : string;
    public parentDtoId : number;
    public parentDtoName : string;
    public parentDtoImage : string;
    public parentDtoColor : string;
    public active : Boolean;
    public instanceAble : Boolean;
    public description : string;
    public image : string;
    public rgbaColor : string;


    public steps?: WfProcessTypeStepDto[];
    public fields?: WfProcessTypeFieldDto[];
    public results?: WfProcessTypeResultDto[];


    /**
     * Retrieve Step by ID.
     * @param id
     * @param processType
     */
    public static getStepById(id: number, processType: WfProcessTypeDto) : WfProcessTypeStepDto |null {

        if (!processType.steps) {
            return null;
        }

        for (let step of processType.steps) {
            if (step.id === id) {
                return step;
            }
        }

        return null;

    }

    /**
     * Retrieve field by ID.
     * @param id
     */
    public static getFieldById(id: number, processType: WfProcessTypeDto) : WfProcessTypeFieldDto | null {

        if (!processType.fields) {
            return null;
        }

        for (let field of processType.fields) {
            if (field.id === id) {
                return field;
            }
        }

        return null;

    }

}