
<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-12">
                <!-- show all customers created sort by descending -->
                <mvs-widget
                        *ngIf="createdContractsWidgetData"
                        [widgetData]="createdContractsWidgetData"
                        (onObjectSelect)="onContractSelect($event)"
                ></mvs-widget>
        </div>
    </div>

</ng-container>
