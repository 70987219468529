@if (initialized) {

    <mvs-widget [widgetData]="objectRelatedTicketsWidget" (onObjectSelect)="handleObjectSelect($event)"></mvs-widget>

    <div class="grid">
        @for (ticket of ticketsList; track ticket.id) {
            <div class="col-12">
                {{ ticket.name }}
            </div>
        }
    </div>
}