import {Component, EventEmitter, Input, OnChanges, OnInit, Output,} from '@angular/core';
import {
    DtoDetail, DtoList,
    FilterCriteria,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList, ObjectRequestRelation,
} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {TicketActionAvailableResponseDto} from "../../service/api/dto/ticket-action-available-response.dto";
import {TicketActionDto} from "../../dto/ticket-action.dto";
import {TicketActionStatusInternal} from "../../enum/ticket-action-status-internal.enum";
import {TicketActionAvailableDto} from "../../service/api/dto/ticket-action-available.dto";
import {UiTicketActionDto} from "../ticket/ticket-components/tm-ticket-actions/data/ui-ticket-action.dto";
import {TicketService} from "../../service/api/ticket.service";
import {TicketActionService} from "../../service/api/ticket-action.service";
import {ConfirmationService} from "primeng/api";
import {TicketDto} from "../../dto/ticket.dto";
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {forkJoin} from "rxjs";
import {AgentService} from "../../../am/service/api/agent.service";
import {AgentPoolService} from "../../../am/service/api/agent-pool.service";
import {AgentPoolDto} from "../../../am/dto/agent-pool.dto";


@Component({
    selector: 'ticket-complete-form',
    templateUrl: 'ticket-complete-form.component.html',
})
export class TicketCompleteFormComponent implements OnInit, OnChanges {

    initialized: boolean;
    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    actionsList: TicketActionDto[]
    actionAvailableDto: TicketActionAvailableDto[];

    @Input() ticketDto: TmTicketDto;
    ticketActionsList: TicketActionDto[];
    availableActionsResponse: TicketActionAvailableResponseDto;

    @Output() onAddTicketAction = new EventEmitter<string>;
    @Output() onInitiateProcess = new EventEmitter<ObjectIdentifier>;
    @Output() onChangedObject = new EventEmitter<ObjectChangeInformation>;

    constructor(protected ticketService: TicketService,
                protected ticketActionService: TicketActionService,
                protected agentService: AgentService,
                protected agentPoolService: AgentPoolService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        this.formControlOverwrite = MvsFormControlOverwrite.createHideAll('closingStatusDtoId', 'closingComment', 'status');
        this.formControlOverwrite.addField('status', MvsFormFieldAccessEnum.READ);

        const defaultDto: any = {
            status: TmStatusEnum.resolved,
            closingDate: new Date(),
        }

        this.createDefaultDto = defaultDto;

        this.getAvailableTicketActions();
        this.getTicketActionList();

        this.initialized = true;
    }

    getAvailableTicketActions() {
        this.ticketService.getAvailableActions(this.ticketDto.id).subscribe(res => {
            this.availableActionsResponse = res;
            this.actionAvailableDto = this.availableActionsResponse?.availableActions.filter(action => (
                action.exists == true &&
                action.existsStatusInternal != TicketActionStatusInternal.completed &&
                action.existsStatusInternal != TicketActionStatusInternal.cancelled &&
                action.existsStatusInternal != TicketActionStatusInternal.negative_completed)
            );
            this.availableActionsResponse.availableActions = this.actionAvailableDto;
        })
    }

    getTicketActionList() {

        const complexSelection =
            ObjectRequestComplex.build(false, false,
                ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeAction')
            );


        const filterCriteria = [
            FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.ticketDto.id),
            FilterCriteria.create('statusInternal', FilterCriteria.cOperatorNotEqual, TicketActionStatusInternal.completed),
            FilterCriteria.create('statusInternal', FilterCriteria.cOperatorNotEqual, TicketActionStatusInternal.cancelled),
        ]

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            filterCriteria,
            null,
            null,
            null);

        this.ticketActionService.list(objectRequestList).subscribe(res => {
            const ticketActions = res.entries;

            if (ticketActions && ticketActions.length) {
                this.ticketActionsList = ticketActions.filter(item => item.mandatory == true &&
                    item.statusInternal != TicketActionStatusInternal.completed &&
                    item.statusInternal != TicketActionStatusInternal.cancelled &&
                    item.statusInternal != TicketActionStatusInternal.negative_completed);
            }

            // this.actionsList = this.ticketActionsList?.filter(action => action.statusInternal != TicketActionStatusInternal.completed);
            // this.actionAvailableDto = this.availableActionsResponse?.availableActions.filter(action => action.existsStatusInternal != TicketActionStatusInternal.completed);
            // this.availableActionsResponse.availableActions = this.actionAvailableDto;
        })
    }

    handleBeforeSaveCallback(fn: Function) {

        forkJoin({
            agent: this.agentService.me(),
            agentPools: this.agentPoolService.my()
        }).subscribe({
            next: ({ agent, agentPools }) => {
                const loggedOnAgent = agent;
                const loggedOnAgentPoolsInfo = agentPools;

                // directly close ticket without confirmation if
                // a) the agent is closing their assigned ticket
                // b) the agent is in agent pool of the assignee

                if (this.ticketDto.assigneeAgentDtoId == loggedOnAgent.id || this.checkIfLoggedOnUserInTicketPool(loggedOnAgentPoolsInfo)) {
                    fn();
                } else {

                    // otherwise show a dialog message
                    this.showConfirmationDialog(fn);
                }

            },
            error: (error) => {
                console.error('Error fetching agent or agent pools:', error);
            }
        });

    }

    checkIfLoggedOnUserInTicketPool(loggedOnAgentPools: DtoList<AgentPoolDto>): boolean {
        const exists = loggedOnAgentPools.entries.find(item => item.id == this.ticketDto.assigneeAgentPoolDtoId);
        if (exists) {
            return true;
        }
        return false;
    }

    showConfirmationDialog(fn: Function) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to close this ticket?',
            header: 'Confirm Close',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            acceptButtonStyleClass: "p-button-success",
            rejectButtonStyleClass: "p-button-danger",
            accept: () => {
                fn(); // Call save function if user confirms
            },
            reject: () => {
                console.log('Save cancelled');
            }
        });
    }

    handleAddTicketAction(event: string) {
        this.onAddTicketAction.emit(event);
    }

    handleInitiateProcess(event: ObjectIdentifier) {
        this.onInitiateProcess.emit(event);
    }

    handleObjectChange(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }

    handleStatusChange(action: TicketActionDto) {
        this.getTicketActionList();
        // this.ticketActionsList = this.ticketActionsList.filter(actions => actions.statusInternal != action.statusInternal);
    }

    ngOnChanges() {
        if (!this.initialized) {
            return
        }

        this.getAvailableTicketActions()
    }

}
