import {Component, Input, OnInit} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DmDocumentAssignmentService} from "../../../dm/service/api/dm-document-assignment.service";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {DmDocumentAssignmentDto} from "../../../dm/dto/dm-document-assignment.dto";

@Component({
    selector: 'tm-ticket-document-upload',
    templateUrl: './tm-ticket-document-upload.component.html',
    styleUrls: ['./tm-ticket-document-upload.component.scss']
})
export class TmTicketDocumentUploadComponent implements OnInit {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() layoutVariation: string = 'm-version';

    busy: boolean;
    documentDtoId: number;
    visible: boolean;
    ticketAttachments: DmDocumentAssignmentDto[];

    constructor(protected documentAssignment: DmDocumentAssignmentService, protected objectType: ObjectTypeService) {
    }

    ngOnInit(): void {
        this.refreshDocuments();
    }

    async refreshDocuments() {

        const objectTypeId = await this.objectType.getViaObjectType(this.objectIdentifier.objectType);

        const filterCriteriaList: FilterCriteria[] = [];

        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectType', FilterCriteria.cOperatorEqual, objectTypeId.id, null));
        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));

        const objectRequest = new ObjectRequestList(false, filterCriteriaList, []);

        this.documentAssignment.list(objectRequest).subscribe(res => {
            this.ticketAttachments = res.entries;
        })

    }

    removeAttachment(id: number) {
        this.documentAssignment.delete(id).subscribe(res => {
            this.refreshDocuments();
        })
    }

    onObjectSelect(id) {
        this.documentDtoId = id;
        this.visible = true;
    }


}
