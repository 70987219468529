<h1>Test</h1>

<p-multiSelect [options]="agents" [(ngModel)]="selectedAgents" optionLabel="name" (onChange)="handleAgentSelectChange($event)"></p-multiSelect>




<ng-container *ngIf="selectedAgents && selectedAgents.length > 0">

<!--    <ng-container *ngIf="selectedAgents.length == 1">-->
<!--        <h2>Single Agent Calendar</h2>-->

<!--        <ng-container *ngIf="singleCalendar">-->

<!--            <div *ngFor="let event of singleCalendar.value">-->
<!--                <span>{{event.subject}} - {{event.start.dateTime}} - {{event.end.dateTime}}</span>-->
<!--            </div>-->

<!--        </ng-container>-->

<!--    </ng-container>-->

<!--    <ng-container *ngIf="selectedAgents.length > 1">-->
<!--        <h2>Multi Agent Calendar</h2>-->

<!--        <ng-container *ngIf="multiCalendar">-->

<!--            <div *ngFor="let agentId of selectedAgents">-->

<!--                <ng-container *ngIf="multiCalendar[agentId] && multiCalendar[agentId].value">-->

<!--                    <h3>Agent: {{agentId}}</h3>-->
<!--                    <div *ngFor="let event of multiCalendar[agentId].value">-->
<!--                        <span>{{event.subject}} - {{event.start.dateTime}} - {{event.end.dateTime}}</span>-->
<!--                    </div>-->

<!--                </ng-container>-->

<!--            </div>-->


<!--        </ng-container>-->

<!--    </ng-container>-->

    <am-agent-calendar-management
            [selectedAgents]="selectedAgents"
    ></am-agent-calendar-management>

</ng-container>


