import { Injectable, NgZone } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenRefreshService {
    constructor(private oauthService: OAuthService, private ngZone: NgZone) {
        this.setupAutomaticSilentRefresh();
    }

    private setupAutomaticSilentRefresh() {
        // Use NgZone to avoid triggering Angular's change detection mechanism unnecessarily
        this.ngZone.runOutsideAngular(() => {
            interval(300000) // Check every 5 minutes
                .pipe(
                    switchMap(() => this.oauthService.refreshToken())
                )
                .subscribe(
                    (success) => {
                        console.log('Token successfully refreshed', success);
                    },
                    (error) => {
                        console.error('Error refreshing token', error);
                    }
                );
        });
    }
}
