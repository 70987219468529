<ng-container *ngIf="initialized && processTypes && processTypes.length">

    <p-dropdown
            *ngIf="processTypes && processTypes.length > 1"
            [options]="processTypes"
            [(ngModel)]="selectedProcessType"
            optionLabel="name"
            [disabled]="!!defaultedProcessType"
            (onChange)="onProcessSelect(selectedProcessType.id)"
    ></p-dropdown>

    <div class="shadow-1 surface-0 border-round p-3" *ngIf="selectedProcessType">

        @if (heading) {
            <h5 *ngIf="selectedProcessType.name" class="mb-0">{{ selectedProcessType.name }}</h5>
        }

        <!-- Description -->
        <div *ngIf="selectedProcessType.description" class="py-4"
             [innerHTML]="selectedProcessType.description | mvsSavePipe:'html'"></div>

        <mvs-widget
                *ngIf="formWidget"
                [widgetData]="formWidget"
                (onGenericEvent)="handleGenericEvent($event)"
        ></mvs-widget>

        <!-- Perform Object Selection at start
        <ng-container *ngIf="fields && fields.length">

            <div *ngFor="let field of fields; let i = index" class="my-4">

                <ng-container *ngIf="field.fieldProcessingType == 1">
                    <div class="flex align-items-center gap-2">
                        <span>
                            {{field['name']}}
                        </span>
                        <mvs-form-field-flexible-search
                                *ngIf="field.fieldTypeDto['dataTypeObjectTypeAlias']"
                                [objectTypeAlias]="field.fieldTypeDto['dataTypeObjectTypeAlias']"
                                (onObjectSelect)="handleObjectFromDialog($event,i)"
                                (onObjectRemove)="handleObjectRemove(i)"
                        ></mvs-form-field-flexible-search>
                    </div>

                </ng-container>
            </div>
        </ng-container>
        -->

        <div class="flex justify-content-end flex-wrap card-container green-container">
            <p-button
                    icon="fa-solid fa-play"
                    label="Prozess starten"
                    (click)="onProcessStartWithImports()"
                    [disabled]="busy || !enableButton"></p-button>

        </div>

    </div>

</ng-container>








