import {Component, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import {AppMainComponent} from './app.main.component';
import {TenantDto} from "@kvers/alpha-auth/lib/tenant/tenant.dto";
import {TenantService} from "@kvers/alpha-auth";
import {UiSettingUserComponent} from "./ui/component/ui-setting-user/ui-setting-user.component";
import {UiUserThemeConfigurations} from "./ui/component/ui-setting-user/data/ui-user-theme-configurations";
import {UiSettingUserService} from "./ui/service/api/ui-setting-user.service";
import {MvsMessageService} from "@kvers/alpha-core-common";

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html',
    styleUrls: ['./app.rightmenu.component.scss']
})
export class AppRightMenuComponent {

    showUserSettingDialog: boolean = false;
    tenantInfo: TenantDto;
    uiUserSettingsDto: UiUserThemeConfigurations;
    busy: boolean = false;

    constructor(protected tenantService: TenantService,
                public appMain: AppMainComponent,
                public app: AppComponent,
                protected uiSettingUserService: UiSettingUserService,
                protected messageService: MvsMessageService,
                ) {}

    ngOnInit(): void {
        this.initComponent();
    }
    initComponent() {
        this.tenantInfo = this.tenantService.getTenantInfo();
    }

    copyToClipboard(text: string): void {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    copyRouteToClipboard() {
        const fullRoute = window.location.href;

        // Call the copyToClipboard method to copy the route to the clipboard
        this.copyToClipboard(fullRoute);
    }

    openUserSettingDialog() {
        this.appMain.rightMenuActive = false;
        this.showUserSettingDialog = true;
    }

    handleSaveUserSettingClick() {
        const json = JSON.stringify(this.uiUserSettingsDto);

        this.uiSettingUserService.me(json).subscribe(res => {
            this.messageService.showSuccessMessage('','User Settings saved');
            localStorage.setItem('ui.userSettings', JSON.stringify(res));
            this.busy = false;
        }, error => {
            console.log(error);
            this.busy = false;
        });
    }

    handleCloseUserSettingDialog() {
        this.showUserSettingDialog = false;
    }

    handleUserSettingsChange(event: UiUserThemeConfigurations) {
        this.uiUserSettingsDto = event;
    }
}
