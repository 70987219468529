import {WfProcessTypeStepProcessingEnum} from "../enum/wf-process-type-step-processing.enum";
import {WfActivityTypeEnum} from "../enum/wf-activity-type.enum";
import {WfActivityTypeObjectActivityEnum} from "../enum/wf-activity-type-object-activity.enum";
import {WfProcessTypeStepDto} from "./wf-process-type-step.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeStepActivityFormDefaultDto} from "./wf-process-type-step-activity-form-default.dto";
import {WfProcessTypeStepNextDto} from "./wf-process-type-step-next.dto";
import {DmDocumentTypeDto} from "../../dm/dto/dm-document-type.dto";
import {WfActivityRefreshType} from "../enum/wf-activity-refresh-type.enum";
import {Component} from "@angular/core";
import {WfProcessTypeStepActivityObjectDto} from "./wf-process-type-step-activity-object.dto";
import {WfProcessTypeStepActivityTicketCreateDto} from "./wf-process-type-step-activity-ticket-create.dto";
import {WfProcessTypeStepActivityTicketStatusDto} from "./wf-process-type-step-activity-ticket-status.dto";
import {WfProcessTypeStepActivityStartProcessDto} from "./wf-process-type-step-activity-start-process.dto";
import {WfProcessTypeStepActivityNotificationDto} from "./wf-process-type-step-activity-notification.dto";
import {WfProcessTypeStepActivityDocumentDto} from "./wf-process-type-step-activity-document.dto";
import {WfProcessTypeStepActivityChooseNextStepDto} from "./wf-process-type-step-activity-choose-next-step.dto";

export class WfProcessTypeStepActivityDto extends DtoDetail {

    public processTypeStepDto: WfProcessTypeStepDto;
    public processTypeStepDtoId: number;
    public processTypeStepDtoName: string;
    public processTypeStepDtoImage: string;
    public processTypeStepDtoColor: string;

    public processTypeStepNextDto: WfProcessTypeStepDto;
    public processTypeStepNextDtoId: number;
    public processTypeStepNextDtoName: string;
    public processTypeStepNextDtoImage: string;
    public processTypeStepNextDtoColor: string;

    public priority: number;
    public name: string;
    public processing: WfProcessTypeStepProcessingEnum;
    public activityType: WfActivityTypeEnum;
    public activityMandatory: boolean;
    public refreshType: WfActivityRefreshType;

    public description: string;

    // provided based on the Activity Type
    public activityObjectDto?: WfProcessTypeStepActivityObjectDto;
    public activityTicketCreateDto?: WfProcessTypeStepActivityTicketCreateDto;
    public activityTicketStatusDto?: WfProcessTypeStepActivityTicketStatusDto;
    public activityStartProcessDto?: WfProcessTypeStepActivityStartProcessDto;
    public activityNotificationDto?: WfProcessTypeStepActivityNotificationDto;
    public activityDocumentDto?: WfProcessTypeStepActivityDocumentDto;
    public activityChooseNextStepDto?: WfProcessTypeStepActivityChooseNextStepDto;


    public _uiDisplayComponent: Component;

    /**
     * Retrieve main activity within step.
     * @param step
     */
    public static getMainActivityForStep(activities: WfProcessTypeStepActivityDto[]): WfProcessTypeStepActivityDto | null {

        // if (!step.activities || step.activities.length === 0) {
        //     return null;
        // }

        for (let activity of activities) {
            if (activity.processing === WfProcessTypeStepProcessingEnum.main) {
                return activity;
            }
        }

        return null;

    }

    /**
     * Retrieve optional activity within step.
     * @param step
     */
    public static getOptionalActivityForStep(activities: WfProcessTypeStepActivityDto[]): WfProcessTypeStepActivityDto[] | null {

        if (!activities || !activities.length) {
            return null;
        }

        const optionalActivities: WfProcessTypeStepActivityDto[] | null = [];

        for (let activity of activities) {
            if (activity.processing === WfProcessTypeStepProcessingEnum.optional) {
                optionalActivities.push(activity);
            }
        }

        return optionalActivities;

    }

    public static getProcessTypeStepActivityById(
        id: number,
        activities: WfProcessTypeStepActivityDto[]
    ): WfProcessTypeStepActivityDto | null {
        return activities.find(activity => activity.id === id) || null;
    }

    public static getProcessedActivityFromObject(
        object: WfProcessTypeStepActivityDto,
        activities: WfProcessTypeStepActivityDto[]
    ): WfProcessTypeStepActivityDto | null {
        const nextProcessTypeStepDtoId = object.processTypeStepNextDtoId;

        // Find the activity with matching nextProcessTypeStepDtoId
        const processedActivity = activities.find(
            (activity) => activity.processTypeStepDtoId === nextProcessTypeStepDtoId
        );

        return processedActivity || null;
    }

}