import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";
import {AddressService} from "../../../../service/api/address.service";
import {AddressTypeService} from "../../../../service/api/address-type.service";


@Component({
    selector: 'mvs-example',
    templateUrl: 'ad-kpi.component.html',
})
export class AdKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalAddress: number = 0;
    totalAddressType: number = 0;
    thisMonthAddress: number = 0;
    thisWeekAddress: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'AD Module'}];

    constructor(
        protected addressService: AddressService,
        protected addressTypeService: AddressTypeService,
    ) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalAddress();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalAddress() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['city'],
            [new ObjectRequestListAttribute('city', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.addressService.groupBy(objectRequestList).subscribe(res => {
            this.totalAddress = res.entries.reduce((acc, item) => acc + item['city_count'], 0);
            this.getTotalAddressType();
        }, error => {
            this.getTotalAddressType();
        });
    }

    getTotalAddressType() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['primaryAddress'],
            [new ObjectRequestListAttribute('primaryAddress', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.addressTypeService.groupBy(objectRequestList).subscribe(res => {
            this.totalAddressType = res.entries.reduce((acc, item) => acc + item['primaryAddress_count'], 0);
            this.getThisMonthAddress();
        }, error => {
            this.getThisMonthAddress();
        });
    }

    getThisMonthAddress() {
        const objectRequestList = this.getThisMonthRequest('city');
        this.addressService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthAddress = res.entries.reduce((acc, item) => acc + item['city_count'], 0);
            this.getThisWeekAddress();
        }, error => {
            this.getThisWeekAddress();
        });
    }

    getThisWeekAddress() {
        const objectRequestList = this.getThisWeekRequest('city');
        this.addressService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekAddress = res.entries.reduce((acc, item) => acc + item['city_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Address', value: this.totalAddress,
            },
            {
                label: 'Total Address Type', value: this.totalAddressType,
            },
            {
                label: 'This Month Address', value: this.thisMonthAddress,
            },
            {
                label: 'This Week Address', value: this.thisWeekAddress,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
