import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {XhAnonymizePage} from "./page/overview/anonymize/xh-anonymize.page";

@NgModule({
  declarations: [
    XhAnonymizePage
  ],
  exports:[
    XhAnonymizePage
  ],
  imports: [
    CoreModule,
    ProgressSpinnerModule,
  ]
})
export class XhModule { }

 
 
