import {ObjectIdentifier} from "@kvers/alpha-core-common";

export class WfProcessStartEvent {


    constructor(
        public processTypeId: number,
        public mainObject?: ObjectIdentifier,
        public objectIdentifiers?: ObjectIdentifier,
    ) {

    }


}