import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent, MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsNavigateToNewWindowOutputInterface} from "./interface/mvs-navigate-to-new-window-output.interface";
import {DtoList, MvsCoreService, ObjectTypeService} from "@kvers/alpha-core-common";
import {ParamRequest} from "@kvers/alpha-ui/lib/navigation/mvs-object-navigation-entry-param.interface";
import {Router} from "@angular/router";

@Component({
    selector: 'mvs-navigate-to-new-window-output-component',
    templateUrl: './mvs-navigate-to-new-window-output.component.html',
})
export class MvsNavigateToNewWindowOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsNavigateToNewWindowOutputInterface {


    objectType: string;
    objectId: number;
    location: 'left' | 'right' | 'bottom' | 'main' | 'newTab';
    componentType: 'Object' | 'Form';

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected coreService: MvsCoreService,
                protected router: Router) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {

        this.initComponent();

    }




    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectType = this.formField['uiConfigData']['objectType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];
        this.objectId = this.dto[objectIdField];
        this.location = this.formField['uiConfigData']['location'];
        this.componentType = this.formField['uiConfigData']['componentType'];

    }


    navigateToWindow(event: MouseEvent) {
        event.stopPropagation();
        const navigationObject = MvsObjectNavigationEntry.createNavigationEntry(this.objectType, this.objectId, this.componentType, null, null, null, MvsObjectNavigationActionEnum.any, 'side');
        if (this.location == 'newTab') {
            this.navigationService.openObjectInNewTab(navigationObject);
        } else {

            if (this.location == 'main' && this.componentType == 'Object' && (this.objectType == 'cm.Contract' || this.objectType == 'cr.Customer')) {
                let url = '';
                if (this.objectType == 'cm.Contract') {
                    url = `/cm/contract/${this.objectId}`;
                }
                if (this.objectType == 'cr.Customer') {
                    url = `/cr/customers/${this.objectId}`;
                }
                // window.location.href = url;
                this.router.navigate([url], { queryParams: { navItem: 'standard' } });
            }else {

                this.navigationService.navigateTo(navigationObject, this.location);
            }

        }
    }
Ò

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
