import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ContractDto} from "../../dto/contract.dto";
import {ContractService} from "../../service/api/contract.service";
import {ObjectIdentifier, WidgetHelperButton, EntityStatusEnum} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {TicketObjectService} from "../../../tm/service/api/ticket-object.service";

@Component({
    selector: 'cm-contract-inline-component',
    templateUrl: './cm-contract-inline-component.html',
})
export class CmContractInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    icon: string;

    protected readonly EntityStatusEnum = EntityStatusEnum;
    dto: ContractDto;

    toolbarButtons: WidgetHelperButton[] = [
        {
            label: 'Open in background',
            icon: 'fa-regular fa-send-backward',
            display: true,
            action: 'background',
            tooltip: 'Open in background'
        },
        {
            label: 'Open in new window',
            icon: 'fa-regular fa-arrow-up-right-from-square',
            display: true,
            action: 'newWindow',
            tooltip: 'Open in new window'
        }
    ];

    initialized: boolean;
    busy: boolean;

    constructor(protected contractService: ContractService,
                protected ticketObjectService: TicketObjectService,
                protected navigationService: MvsObjectNavigationService,) {

    }

    ngOnInit() {
        this.refreshComponent();
    }

    refreshComponent() {



        this.contractService.get(this.objectIdentifier.objectId).subscribe((res: ContractDto) => {
            this.dto = res;
            this.initialized = true;
        })

        this.icon = this.contractService.getObjectIcon();

    }

    ngOnChanges(changes: SimpleChanges) {

    }

    handleButtonClick(event: WidgetHelperButton) {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        if (event.action == 'background') {
            this.navigationService.addOverlay(navigationEntry)
        } else if (event.action == 'newWindow') {
            this.navigationService.handleObjectNavigation(this.objectIdentifier,null,{openNewTab:true});
        }
    }

    handleClick() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.addOverlay(navigationEntry)
    }
}
