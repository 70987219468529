import {Route, Routes} from "@angular/router";
import {WfProcessTypeComponent} from "../wf/page/overview/wf-config/wf-config.page";
import {UmConfigPage} from "./page/overview/config/um-config.page";
import {UserProfileComponent} from "./components/user-profile/user-profile.component";
import {UserOverviewPage} from "./page/user-overview-page/user-overview.page";

export function UmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'um', component: UserOverviewPage},
        {path: 'um/config', component: UmConfigPage},
        {path: 'user/profile', component: UserProfileComponent},

    ];

    return routes;

}
