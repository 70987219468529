<div class="grid">
    <div class="col-4">
        <p-tree
                [value]="schemaTreeData"
                [filter]="true"
                selectionMode="single"
                (onNodeSelect)="onNodeSelect($event)"
                [(selection)]="selectedNode"
                filterPlaceholder="Search Item"
        >

            <ng-template let-node pTemplate="default">
                <i class="fas fa-sitemap mr-2"></i> {{ node.label }}
            </ng-template>

            <ng-template let-node pTemplate="simple">
                <span [ngClass]="{'text-red-500': isHighlightedLeaf(node)}">{{ node.label }}</span>
            </ng-template>

            <ng-template let-node pTemplate="complex">
                <div [attr.data-node-alias]="node.alias">
                    {{ node.label }}
                </div>
            </ng-template>

        </p-tree>
    </div>
    <div class="col-8">

        <p-tabView *ngIf="selectedNode">

            <p-tabPanel header="Active Node">
                <div class="schema-runtime" *ngIf="selectedNode">

                    <p-button
                            (click)="goBackSingleNavigationHistory()"
                            [disabled]="singleNavigationHistory.length === 0"
                            icon="fa fa-arrow-left"
                            label="Back">
                    </p-button>

                    <em-schema-node-data [node]="selectedNode" (nodeFocused)="handleNodeFocus($event)" (onShowNode)="handleShowNode($event.node); op.toggle($event.event)" (onMouseLeave)="op.toggle($event)"></em-schema-node-data>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Peers">

                <div *ngIf="tableData?.length" class="w-full text-right mb-2">
                    <p-button
                            (click)="goBack()"
                            [disabled]="navigationHistory.length === 0"
                            icon="fa fa-arrow-left"
                            label="Back">
                    </p-button>
                </div>

                <p-table *ngIf="tableData?.length" [value]="tableData" [paginator]="true" [rows]="15"
                         responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngIf="selectedNode">
                                {{ selectedNode.label }}
                            </th>
                            <th *ngFor="let col of tableColumns">
                                {{ col.name }}
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-row>
                        <tr>
                            <td *ngIf="selectedNode">Node</td>
                            <td *ngFor="let col of tableColumns">
                        <span *ngIf="row[col.alias]?.type !== 'link'"
                              (click)="highlightTreeNode(row[col.alias], col.alias)"
                              class="clickable-value">{{ row[col.alias] }}</span>

                                <!-- Show action icons for complex fields -->
                                <span *ngIf="row[col.alias]?.type === 'link'" class="flex gap-4">
                <i class="fas fa-folder-tree hyperlink"
                   (click)="onNodeSelect({ node: row[col.alias].target, showSelected: false })"
                   title="Show All Records"></i>

                <i class="fas fa-filter hyperlink"
                   (click)="onNodeSelect({ node: row[col.alias].target, showSelected: true })"
                   title="Show Only Selected"></i>
            </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </p-tabPanel>

        </p-tabView>


    </div>
</div>

<p-overlayPanel #op [style]="{ width: '450px' }" [showCloseIcon]="true" appendTo="body">
    @if (selectedField) {

        <div class="overlay-header">
            <i [class]="selectedField?.fieldTypeInternalEnumImage" [ngStyle]="{'color': selectedField?.fieldTypeInternalEnumColor}"></i>
            <h4>{{ selectedField?.name }}</h4>
        </div>

        <div class="overlay-content">
            <p><strong>Alias:</strong> {{ selectedField?.alias }}</p>
            <p><strong>Type:</strong> {{ selectedField?.fieldTypeExternal }}</p>
            <p *ngIf="selectedField?.minLength"><strong>Min Length:</strong> {{ selectedField?.minLength }}</p>
            <p *ngIf="selectedField?.length"><strong>Max Length:</strong> {{ selectedField?.length }}</p>
            <p *ngIf="selectedField?.decimals"><strong>Decimals:</strong> {{ selectedField?.decimals }}</p>
            <p *ngIf="selectedField?.hasValueList"><strong>Has Value List:</strong> Yes</p>
        </div>

        <div class="overlay-footer">
        <span class="badge" [ngStyle]="{'background-color': selectedField?.fieldTypeInternalEnumColorBackground, 'color': 'white'}">
            {{ selectedField?.fieldTypeInternalEnumName }}
        </span>
        </div>
    }
</p-overlayPanel>