<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12">

            <mvs-widget
                    *ngIf="widgetTemplateArtefacts"
                    [widgetData]="widgetTemplateArtefacts"
                    (onObjectSelect)="handleSelectArtefact($event)"
                    [importObjectContext]="importContextTemplate"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetTemplateArtefactVariableLink"
                    [widgetData]="widgetTemplateArtefactVariableLink"
                    [importObjectContext]="importContextArtefact"
            ></mvs-widget>

        </div>
    </div>

    <!--
        <div class="grid">
            <div class="col-12">

                <mvs-widget-header
                        *ngIf="configurationItems"
                        [configurationItems]="configurationItems"
                        title="Embedded Artefacts"
                ></mvs-widget-header>

            </div>

            <div class="col-12 scrollable-section">
                <div *ngIf="templateArtefacts.length">
                    <p-table
                            *ngIf="templateArtefacts"
                            [value]="templateArtefacts"
                            class="overlay-custom"
                            [reorderableColumns]="true"
                            [scrollable]="true"
                            (onRowReorder)="changeAttributePriority()">
                        <ng-template pTemplate="header">
                            <tr class="text-sm">
                                <th></th>
                                <th>Artefacts</th>
                                <th>Locations</th>
                                <th>Variable Link</th>
                            </tr>
                        </ng-template>
                        <ng-template
                                pTemplate="body"
                                let-rowData
                                let-artefacts
                                let-rowIndex="rowIndex">
                            <tr
                                    [pReorderableRow]="rowIndex"
                                    [pSelectableRow]="rowData"
                                    class="text-sm">
                                <td>
                                    <span class="pi pi-bars" pReorderableRowHandle></span>
                                </td>
                                <td>
                                    {{ artefacts.artefactDtoName }}
                                </td>
                                <td class="text-center">
                                    <p-button
                                            styleClass="p-button-outlined p-button-rounded w-2rem h-2rem"
                                            [icon]="artefacts.locationEnum ? 'fa-solid fa-arrow-down' : 'fa-solid fa-arrow-up'"
                                            [pTooltip]="artefacts.locationEnum ? 'Down' : 'Up'"
                                            (onClick)="changeArtefactPosition(artefacts.id,artefacts.locationEnum ? 0 : 1,'position')">
                                    </p-button>

                                </td>
                                <td>
                                    <div class="flex justify-content-center">
                                        <p-button
                                                icon="fa-solid fa-paperclip"
                                                (click)="showArtefacts[rowIndex] = !showArtefacts[rowIndex]"
                                        ></p-button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="showArtefacts[rowIndex]">
                                <td colspan="4">
                                    <div class="grid"
                                         *ngFor="let item of artefacts.artefactDto.artefactVariables">

                                        <div class="col-6">
                                            <div class="flex align-items-center h-full">
                                                <span>{{ item?.name }}: </span>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <p-chip
                                                    [label]="item?.artefactVariableLink?.attributeName ? item?.artefactVariableLink?.attributeName : 'NotDefine'"
                                                    [removable]="item?.artefactVariableLink?.attributeName"
                                                    pDroppable="variable"
                                                    (onDrop)="handleOnDrop($event, artefacts, item)"
                                                    (onRemove)="onRemoveTemplateArtefactVariableLink(item.artefactVariableLink)"
                                            ></p-chip>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
-->
</ng-container>


<p-dialog header="Header" [(visible)]="visible" [style]="{width: '50vw'}">

    <div class="grid">
        <div class="col-12">

            <h5 class="text-sm">Artefact Browser</h5>
        </div>
        <div class="col-12">

            <!-- dropdown to select artefact -->
            <p-dropdown
                    [style]="{'min-width': '180px'}"
                    [options]="artefactsList.entries"
                    placeholder="Select Artefact"
                    panelStyleClass="text-sm artefact"
                    [(ngModel)]="selectedArtefact"
                    optionLabel="name"
                    appendTo="body"
            ></p-dropdown>
        </div>

        <div class="col-12">

            <p-selectButton [options]="artefactLocation" [(ngModel)]="selectedLocation" optionLabel="icon"
                            optionValue="value">
                <ng-template let-item pTemplate>
                    <i [class]="item.icon"></i>
                </ng-template>
            </p-selectButton>

        </div>

        <div class="col-12">
            <!-- input to enter artefact priority -->
            <p-inputNumber
                    [(ngModel)]="selectedPriority"
                    placeholder="Enter Priority"
            ></p-inputNumber>
        </div>

        <div class="col-12">
            <p-button
                    label="Submit"
                    (click)="createTemplateArtefact()"
                    [disabled]="busy"
            ></p-button>
        </div>
    </div>

</p-dialog>