import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    EntityStatusEnum,
    FilterCriteria,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    Sorting,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {CustomerService} from "../../../../service/api/customer.service";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'customer-kpi.component.html',
})
export class CustomerKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalCustomer: number = 0;
    todayCustomers: number = 0;
    activeCustomer: number = 0;
    thisWeekCustomers: number = 0;
    thisMonthCustomers: number = 0;


    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'CR Module'}];

    constructor(
        protected customerService: CustomerService) {
        super()
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getTotalCustomers();
        this.initialized = true;
    }


    getTotalCustomers() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.customerService.groupBy(objectRequestList).subscribe(res => {
            this.totalCustomer = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            for (let entry of res.entries) {
                if (entry['entityStatus'] == EntityStatusEnum.active) {
                    this.activeCustomer = entry['entityStatus_count']
                    break;
                }
            }
            this.getThisMonthCustomers();
        }, error => {

            this.getThisMonthCustomers();
        });


    }

    getThisMonthCustomers() {
        const objectRequestList = this.getThisMonthRequest('entityStatus');
        this.customerService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthCustomers = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getThisWeekCustomers();
        }, error => {
            this.getThisWeekCustomers();
        });
    }

    getThisWeekCustomers() {
        const objectRequestList = this.getThisWeekRequest('entityStatus');
        this.customerService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekCustomers = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getTodayCustomers();
        },error => {
            this.getTodayCustomers();
        });
    }

    getTodayCustomers() {


        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [FilterCriteria.create('createdDate', FilterCriteria.cOperatorBetween, DatesHelper.getStartOfToday(), DatesHelper.getEndOfToday())],
            [new Sorting('createdDate', false)],
        );

        this.customerService.list(objectRequestList).subscribe(res => {
            this.todayCustomers = res.entries.length;
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Registered Customers', value: this.totalCustomer, route: '/cr/dashboard'
            },
            {
                label: 'Today\'s Customers', value: this.todayCustomers, route: '/cr/last'
            },
            {
                label: 'Active Customers', value: this.activeCustomer
            },
            {
                label: 'This Month Customers', value: this.thisMonthCustomers,
            },
            {
                label: 'This Week Customers', value: this.thisWeekCustomers,
            },
        ]
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
