<ng-container *ngIf="initialized">
   <ng-container *ngFor="let navigationFrequentPage of navigationFrequentPages" class="w-full ">
      <div routerLink="/{{navigationFrequentPage.route}}"
           class="my-4  flex surface-card align-items-center cursor-pointer">
          <i class="{{icon}} fa-fw mr-2"></i>
          <span>{{navigationFrequentPage.label}}</span>
      </div>
   </ng-container>
</ng-container>


