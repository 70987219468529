import {Component, OnInit} from "@angular/core";
import {DmDocumentBaseComponent} from "../dm-document-base.component";

@Component({
    selector: 'dm-document-side',
    templateUrl:'dm-document-side.component.html'
})

export class DmDocumentSideComponent extends DmDocumentBaseComponent implements OnInit {

    ngOnInit() {
        super.ngOnInit();
    }
}