@if (activeNavigationItem.action === 'Calculation-Logic-Fields') {
    <mvs-widget class="shadow-1 border-round mb-3 surface-section block"
                *ngIf="calculationLogicTypeFieldWidgetData"
                [widgetData]="calculationLogicTypeFieldWidgetData"
    ></mvs-widget>

    <lg-calculation-logic-type-step-component class="shadow-1 border-round mb-3 block surface-section"
                                              [calculationLogicTypeId]="this.objectIdentifier.objectId"
    ></lg-calculation-logic-type-step-component>

    <mvs-object-comments class="shadow-1 border-round mb-3 px-2 block surface-section"
                         headline="Kommentare"
                         [mainObject]="objectIdentifier"
                         linkedEntityObjectType="lg.CalculationLogicTypeComment"
    ></mvs-object-comments>
}

@if (activeNavigationItem.action === 'TestCases') {
    <mvs-lg-calculation-test-case [screen]="'sm'"
                                  [calculationLogicTypeId]="objectIdentifier.objectId"
    ></mvs-lg-calculation-test-case>
}

@if (activeNavigationItem.action === 'Info') {
    <mvs-widget
            *ngIf="calculationLogicTypeWidgetData"
            [widgetData]="calculationLogicTypeWidgetData"
    ></mvs-widget>
}