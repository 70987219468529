import {Route, Routes} from "@angular/router";
import {MySpacePage} from "./page/overview/my-space/my-space.page";

export function WsSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        //{path: 'ws/config', component: WfProcessTypeComponent},
        {path: 'ws/mySpace', component: MySpacePage},

    ];

    return routes;

}
