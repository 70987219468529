import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ConfigPage} from "./page/overview/config/config.page";
import {
    MsBusinessStaffMembersComponent
} from "./component/ms/ms-business-staff-members/ms-business-staff-members.component";
import {MsBookComponent} from "./component/ms/ms-book/ms-book.component";
import {PeriodDurationPipe} from "@kvers/alpha-core-common";
import {AsOverviewPage} from "./page/as-overview-page/as-overview.page";
import {AsStatisticsComponent} from "./page/as-overview-page/components/statisticsComponent/as-statistics.component";
import {AsKpiComponent} from "./page/as-overview-page/components/kpiComponent/as-kpi.component";
import {UiModule} from "../ui/ui.module";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";

@NgModule({
    declarations: [
        ConfigPage,
        MsBusinessStaffMembersComponent,
        MsBookComponent,
        AsOverviewPage,
        AsStatisticsComponent,
        AsKpiComponent
    ],
    exports: [
        ConfigPage,
        MsBookComponent,
    ],
    imports: [
        CoreModule,
        UiModule,
        PeriodDurationPipe
    ]
})
export class AsModule implements DynamicModule {

    alias = 'as';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return AsKpiComponent;
    }

    getStatisticsComponent() {
        return AsStatisticsComponent;
    }
}
