import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-my-tickets',
    templateUrl: './my-tickets.component.html',
    styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() agentId: number;
    @Input() importObjectContextDto: DtoImportObjectContext;


    widgetTicketTableMe: WidgetData;

    constructor(protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {

        // to open ticket drawer
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.widgetTicketTableMe = new WidgetData();
        this.widgetTicketTableMe.name = 'Meine Tickets';
        this.widgetTicketTableMe.idAlias = 'tm.dashboard.me.tickets';
        this.widgetTicketTableMe.uiComponent = 'table';
        this.widgetTicketTableMe.dataProvider = 'list';
        this.widgetTicketTableMe.dataSource = 'entity';
        this.widgetTicketTableMe.dataProviderObject = 'tm.Ticket';

        const filterCriteriaList = [];

        filterCriteriaList.push(FilterCriteria.createSingleCondition('assigneeAgent', FilterCriteria.cOperatorEqual, this.agentId, null));

        this.widgetTicketTableMe.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("dueDate", true)],
            PagingDto.create(0, 5)
        )

        this.widgetTicketTableMe.setParamValue("selectionMode", "single");
        this.widgetTicketTableMe.setParamValue("size", "S");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    onTicketSelect(object: any) {

        this.openTicketDrawer(object.objectId);
    }

    openTicketDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", ticketId, "Ticekt", null, null,null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
