import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectComponent, WidgetFactory} from "@kvers/alpha-ui";
import {
    FilterCriteria,
    NavigationItem, ObjectBaseModeComponent,
    ObjectIdentifier,
    ObjectRequestList,
    PageComponent,
    WidgetData
} from "@kvers/alpha-core-common";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../../../../core/helper/widget-function-call-back-create";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'cp-provision-agent-full',
    templateUrl: './cp-provision-agent-full.component.html',
})
export class CpProvisionAgentFullComponent extends ObjectBaseModeComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() name: string;

    @Output() onNavigationItems: EventEmitter<NavigationItem[]> = new EventEmitter();


    provisionAgentWidget: WidgetData;
    provisionAgentGroupWidget: WidgetData;
    activeNavItem: string;
    queryParamSubscription: Subscription;

    navigationItems: NavigationItem[] = [
        {
            label: 'cp.ProvisionAgent',
            action: 'cp.ProvisionAgent',
            tooltip: 'Provision Agent',
            icon: 'fa fa-calendar-alt', // Represents a time period
            toggleable: false,
            default: true
        },
        {
            label: 'cp.ProvisionAgentGroup',
            action: 'cp.ProvisionAgentGroup',
            tooltip: 'Provision Agent Group',
            icon: 'fa fa-user-tie', // Represents an agent or professional
            toggleable: false,
            default: false
        },

    ];

    constructor(protected route: ActivatedRoute) {
        super(route);
    }

    ngOnInit(): void {
        this.initComponent();
        this.handleNavigationItemsChange();
        super.ngOnInit();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.refreshProvisionAgentWidget();
        this.refreshProvisionAgentGroupWidget();
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshProvisionAgentWidget() {

        this.provisionAgentWidget = WidgetFactory.createWidgetEntityData(
            'cp.provision.agent.data.widget',
            'Provision Agent',
            'cp.ProvisionAgent',
            this.objectIdentifier.objectId
        );

    }

    refreshProvisionAgentGroupWidget() {

        const filterCriteria = FilterCriteria.create('provisionAgent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionAgentGroupWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.group.widget',
            'Provision Agent Group',
            'cp.ProvisionAgentGroup',
            'No Record',
            objectRequest
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'provisionAgentDtoId', fieldValue: this.objectIdentifier.objectId}]

        this.provisionAgentGroupWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }


    getNavigationItems(): NavigationItem[] {
        return this.navigationItems;
    }

    handleNavigationItemsChange(): void {
        this.onNavigationItems.emit(this.navigationItems)
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
