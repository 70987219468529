<div class="bg-white">
    <mvs-config-header header="Ticket Type Configuration" [categoryTypeMode]="'system'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2">
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                        </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'ui.UiSettingUser'">
            <mvs-widget *ngIf="userSettingsWidget"
                        [widgetData]="userSettingsWidget"
            >
            </mvs-widget>
        </ng-container>


    </ng-container>

</div>