<!--<div class="ticket-types-container" #container>-->
<!--    <ng-container *ngFor="let ticketType of ticketTypes; let i = index">-->
<!--        <span #ticketElement class="ticket-type" *ngIf="i < maxVisibleTickets">{{ ticketType.name }}</span>-->
<!--    </ng-container>-->

<!--    <span *ngIf="showMoreButton" (click)="toggleShowMore()">-->
<!--    {{ showAll ? 'Show Less' : 'More...' }}-->
<!--  </span>-->
<!--</div>-->


<div class="card p-5">
{{textContent}}
    <div class="container">
        <div class="backdrop">
            <div #highlights class="highlights"></div>
        </div>
        <input
            #inputField
            type="text"
            placeholder="Search or type ':' for short keys"
            [(ngModel)]="textContent"
            (input)="handleInput()"
            (keydown)="handleKeyDown($event)"
        />

        <!-- Dropdown -->
        <div *ngIf="showDropdown" class="dropdown-search" #dropdown>
            <div
                    *ngFor="let suggestion of filteredSuggestions; let i = index"
                    (click)="insertSuggestion(suggestion.label)"
                    [class.selected]="i === selectedIndex"
                    class="dropdown-item">
               <div class="flex align-items-center gap-2">
                   <span class="surface-200 p-2 border-round">
                       {{suggestion.label}}
                   </span>
                   <span>
                       {{ suggestion.value }}
                  </span>
               </div>
            </div>
        </div>
    </div>
    <button (click)="togglePerspective()">Toggle Perspective</button>




    <!--    working-->
<!--    <div class="container">-->
<!--        <div #backdrop class="backdrop">-->
<!--            <div #highlights class="highlights"></div>-->
<!--        </div>-->
<!--        <textarea-->
<!--            #textarea-->
<!--            [(ngModel)]="textContent"-->
<!--            (input)="handleInput()"-->
<!--            (scroll)="handleScroll()"-->
<!--        ></textarea>-->
<!--    </div>-->
<!--    <button (click)="togglePerspective()">Toggle Perspective</button>-->

</div>



<div class="card p-5 w-full">


    <div class="highlight-container">
        <!-- Div that shows highlighted text with inline styles -->
<!--        <div class="highlighted-text"-->
<!--             [innerHTML]="getHighlightedText()"-->
<!--             contenteditable="true"-->
<!--             (input)="text = $event.target.innerText">-->
<!--        </div>-->
    </div>







    <div class="container">
        <div
                contenteditable="true"
                class="editable-input"
                (input)="updateHighlightedText($event)"
                [innerHTML]="highlightedText">
        </div>

    </div>




    <!--    <div class="autocomplete-wrapper">-->
    <!--        <p-autoComplete-->
    <!--            #autoCompleteRef-->
    <!--            [(ngModel)]="searchText"-->
    <!--            [suggestions]="filteredSuggestions"-->
    <!--            (completeMethod)="onSearch($event, autoCompleteRef)"-->
    <!--            (ngModelChange)="onInputChange()"-->
    <!--            field="label"-->
    <!--            (onSelect)="onSelectSuggestion($event)"-->
    <!--            [dropdown]="false"-->
    <!--            [forceSelection]="false"-->
    <!--            [autoHighlight]="false"-->
    <!--            [emptyMessage]="''"-->
    <!--            placeholder="Search..."-->
    <!--        ></p-autoComplete>-->
    <!--        <span class="highlight-text" *ngIf="highlightPrefix">{{ highlightPrefix }}</span>-->
    <!--    </div>-->


    <!--    <p-inputGroup>-->
    <!--        &lt;!&ndash; Shortkey Display in Input Group, removed when backspacing an empty input field &ndash;&gt;-->
    <!--        <p-inputGroupAddon *ngIf="highlightPrefix">-->
    <!--            {{ highlightPrefix }}-->
    <!--        </p-inputGroupAddon>-->

    <!--        &lt;!&ndash; Input Field (Only for user input) &ndash;&gt;-->
    <!--        <input pInputText [(ngModel)]="searchText"-->
    <!--               (ngModelChange)="onInputChange()"-->
    <!--               (keydown.backspace)="onBackspace()"-->
    <!--               placeholder="Type here..." />-->
    <!--    </p-inputGroup>-->


    <!--    <span class="p-input-icon-left w-full">-->
    <!--    &lt;!&ndash; Show Shortkey Instead of 'pi-times' When Selected &ndash;&gt;-->
    <!--    <i [ngClass]="highlightPrefix ? '' : 'pi pi-search'" (click)="clearInput()">-->
    <!--         <span *ngIf="highlightPrefix"  class="shortkey-display" (click)="clearInput()">{{ highlightPrefix }}</span>-->
    <!--    </i>-->

    <!--    <input-->
    <!--            type="text"-->
    <!--            pInputText-->
    <!--            [(ngModel)]="searchString"-->
    <!--            (keyup)="onSearch()"-->
    <!--            (keydown)="onKeydown($event)"-->
    <!--            [autofocus]="true"-->
    <!--            [placeholder]="!highlightPrefix ? 'Search or type a command' : ''"-->
    <!--            class="w-full"/>-->
    <!--</span>-->


    <span class="p-input-icon-left p-input-icon-right w-full">
    <!-- Show Shortkey Instead of Search Icon -->
    <i [ngClass]="highlightPrefix ? '' : 'pi pi-search'" (click)="clearInput()">
        <span *ngIf="highlightPrefix" class="shortkey-display" (click)="clearInput()">
            {{ highlightPrefix }}
        </span>
    </i>
    <i class="pi pi-info-circle cursor" (click)="op.toggle($event)">
    </i>

    <input
            type="text"
            pInputText
            [(ngModel)]="searchString"
            (keyup)="onSearch($event)"
            (keydown)="onKeydown($event)"
            [autofocus]="true"
            [placeholder]="!highlightPrefix ? 'Search or type a command' : ''"
            class="w-full"/>

        <!-- Dropdown for Shortkeys -->
    <ul *ngIf="showDropdown" class="dropdown-search">
       <li *ngFor="let suggestion of filteredSuggestions; let i = index"
           [class.selected]="i === selectedIndex"
           (click)="selectShortkey(suggestion)">
            {{ suggestion.label }}
        </li>
    </ul>
</span>
{{highlightPrefix}}{{searchString}}

    <!-- Section to display search results -->
    <div *ngIf="searchResults.length > 0" class="search-results mt-5">
        <h4>Search Results:</h4>
        <ul>
            <li *ngFor="let result of searchResults">
                <span *ngIf="result.type === 'fn'">{{ result.name }} - Age: {{ result.age }}</span>
                <span *ngIf="result.type === 'cr'">{{ result.name }} - Score: {{ result.score }}</span>
                <span *ngIf="result.type === 'ln'">{{ result.name }} - Amount: {{ result.amount }}</span>
            </li>
        </ul>
    </div>

</div>


<p-overlayPanel #op>
            <div class="flex flex-column gap-3">
               <div class="flex align-items-center gap-2" *ngFor="let suggestion of allSuggestions; let i = index"  (click)="selectShortkey(suggestion)">
                <span class="surface-200 w-2rem h-2rem flex justify-content-center align-items-center border-round"> {{ suggestion.label }}</span>
                <span class=""> {{ suggestion.value }}</span>
               </div>
            </div>
</p-overlayPanel>


<!--topbar theme-->

<div class="p-5">
    <h4>Tenant A</h4>
    <div class="layout-config">

        <h6 class="">Topbar theme</h6>
        <div class="layout-config-options flex flex-wrap">
            <!--        <p-colorPicker [(ngModel)]="color" [inline]="true"/>-->
            <div *ngFor="let t of topbarColors" class="col col-fixed">
                <a style="cursor: pointer" class="layout-config-color-option" [title]="t.name">
                    <span class="color" [ngStyle]="{'background-color': t.color}"></span>

                    <!-- *ngIf for slected tick -->
                    <span class="check flex align-items-center justify-content-center">
                                    <i class="pi pi-check" style="color: var(--topbar-text-color)"></i>
                                </span>
                </a>
            </div>
        </div>
    </div>

    <div class="flex flex-column gap-2 mt-5">
        <h6 class="mb-0">Logo/Icon</h6>
        <div class="flex flex-wrap gap-3">
            <div class="card mb-0 px-3 w-10rem">
                <div class="flex align-items-center justify-content-between mb-3">
                    <span class="mb-0 font-medium">default</span>
                    <p-radioButton variant="filled"/>
                </div>
                <img src="{{tenantLogo}}" alt="logo" class="p-3 w-8rem">
            </div>
            <div class="card mb-0 px-3 w-10rem">
                <div class="flex align-items-center justify-content-between mb-3">
                    <span class="mb-0 font-medium">light</span>
                    <p-radioButton variant="filled"/>
                </div>
                <img src="{{tenantLogo}}" alt="logo" class="bg-primary p-3 w-8rem light-logo">
            </div>

        </div>
    </div>
</div>


<div class="col-3 card p-5">

    <!--   @Haris copy from here-->

    <h5 class="px-1">External Partners</h5>

    <div class="flex flex gap-3 flex-column">
        <div class="flex align-items-center justify-content-between gap-2">
            <div class="flex gap-2 w-full">
                <i class="fa-kit fa-system-alpha text-6xl text-primary"></i>
                <div class="flex flex gap-1 flex-column w-full">
                    <div class="flex flex gap-1 justify-content-between align-items-center">
                        <span class="text-color-secondary text-lg">User ID:</span>
                        <span class="font-bold text-xl">
                    002145
                </span>
                    </div>
                    <span class="text-color-secondary text-lg">Alpha</span>
                </div>
            </div>
        </div>
    </div>


</div>


<p-overlayPanel #pc>
    <h4 class="font-medium">Customer Profile Analytics</h4>
    <div class="flex align-items-center gap-2">
        <div class="p-1 border-round bg-{{profileCheckData?.completenessRangeDto?.profileColor}}">
            <p-avatar class="mx-1 avatar-progress-border" style="width: 4.4rem; height: 4.4rem"
                      [style.background]="'conic-gradient(var(--' + profileCheckData?.completenessRangeDto?.ringLineColor + ') 0% '
                           + profileCheckData?.completionInPercent + '%,' +
                           ' var(--' + profileCheckData?.completenessRangeDto?.progressCircleColor + ') 0% 100%)'"
                      icon="pi pi-user"
                      styleClass="mx-1"
                      size="xlarge" shape="circle"/>
        </div>
        <div class="flex flex-column gap-1">
                            <span class="text-800 font-medium text-3xl">
                            {{ profileCheckData?.completionInPercent }}%
                            </span>
            <span class="text-500 text-2xl">
                                {{ profileCheckData?.completenessRangeDto?.name }}
                            </span>
        </div>
    </div>
    <div class="" *ngIf="profileCheckData?.failedChecks?.length">
        <h5 class="mt-4 font-medium"><i class="fa-regular fa-circle-exclamation mr-2 text-red-600"></i> Failed Checks
        </h5>
        <div class="flex flex-column gap-2">
            <div class="p-2" *ngFor="let check of profileCheckData?.failedChecks">
                <span class="">{{ check.personName }} - </span>
                <span class="text-600">{{ check.checkTypeDto.name }}</span>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="profileCheckData?.successfulChecks?.length">
        <h5 class="mt-4 font-medium"><i class="fa-regular fa-circle-check mr-2 text-green-600"></i> Successful Checks
        </h5>
        <div class="flex flex-column gap-2">
            <div class="p-2" *ngFor="let check of profileCheckData?.successfulChecks">
                <span class="">{{ check.personName }} - </span>
                <span class="text-600">{{ check.checkTypeDto.name }}</span>
            </div>
        </div>
    </div>
</p-overlayPanel>


<p-overlayPanel #ch>
    <h4 class="font-medium">Customer Insurance Health</h4>
    <div class="flex align-items-center gap-3 mb-3">
        <div class="p-1">
            <i class="text-7xl {{contractHealthData?.result?.image}} text-{{contractHealthData?.result?.rgbaColor}}"></i>
        </div>
        <div class="flex flex-column gap-1">
                            <span class="text-800 font-medium text-2xl">
                            {{ contractHealthData?.result?.name }}
                            </span>
            <span class="text-500">
                               Overall Insurance Health
                            </span>
        </div>
    </div>

    <div *ngIf="contractHealthData?.groups" class="col-12 p-0">
        <p-accordion [multiple]="true" [activeIndex]="0">
            <p-accordionTab *ngFor="let entry of contractHealthData.groups"
                            tabStyleClass="mb-1 shadow-none border-bottom-1 border-300">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center gap-2">
                        <i class="text-xl text-primary {{entry.group.image}}"></i>
                        <h5 class="mr-3 my-0 font-normal">{{ entry.group.name }} </h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="p-2" *ngFor="let entryCheck of entry.checks">
                        <div class="flex flex-column gap-3">
                            <div class="flex align-items-center gap-2">
                                <i class="{{entryCheck.check.image ? entryCheck.check.image : 'fa-duotone fa-solid fa-circle'}}"></i>
                                <span class="">
                                    {{ entryCheck.check.name }}
                                </span>
                            </div>
                        </div>

                        @if (entryCheck.personName) {
                            <div class="shadow-1 flex gap-2 p-3 mt-3">
                                <i class="pi pi-user"></i>
                                <div class="flex flex-column gap-2">
                                    <span class="font-medium"> {{ entryCheck.personName }}</span>
                                </div>
                            </div>
                        }
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
</p-overlayPanel>

<div class="flex justify-content-between">
    <div></div>
    <div class="flex align-items-center gap-3">
        <div class="flex align-items-center border-1 p-2 border-300 border-round">
            <div class="flex flex-column justify-content-center gap-1">
                <div class="flex align-items-center gap-3">
                        <span class="text-800 font-bold flex gap-2 align-items-center">
                            <i class="fa-regular fa-arrow-up"></i>
                            <span class="text-xl">600$</span>
                        </span>
                    <span class="text-secondary flex gap-2 align-items-center">
                            <i class="fa-regular fa-arrow-down"></i>
                                <span class="text-xl">800$</span>
                        </span>
                </div>
                <span class="text-500">Monthly</span>
            </div>
            <p-divider layout="vertical"/>
            <div class="flex flex-column justify-content-center gap-1">
                <div class="flex align-items-center gap-3">
                        <span class="text-800 font-bold flex gap-2 align-items-center">
                            <i class="fa-regular fa-arrow-up"></i>
                            <span class="text-xl">1600$</span>
                        </span>
                    <span class="text-secondary flex gap-2 align-items-center">
                            <i class="fa-regular fa-arrow-down"></i>
                                <span class="text-xl">340$</span>
                        </span>
                </div>
                <span class="text-500">Yearly</span>
            </div>
        </div>


        <div class="flex align-items-center border-1 border-300 border-round">
            <div class="flex align-items-center gap-2 cursor-pointer hover:surface-hover px-3 py-1"
                 (click)="pc.toggle($event)">
                <div class="p-1 border-round bg-{{profileCheckData?.completenessRangeDto?.profileColor}}">
                    <p-avatar class="mx-1 avatar-progress-border"
                              [style.background]="'conic-gradient(var(--' + profileCheckData?.completenessRangeDto?.ringLineColor + ') 0% '
                           + profileCheckData?.completionInPercent + '%,' +
                           ' var(--' + profileCheckData?.completenessRangeDto?.progressCircleColor + ') 0% 100%)'"
                              image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
                              styleClass="mx-1"
                              size="large" shape="circle"/>
                </div>
                <div class="flex flex-column gap-1">
                            <span class="text-800 font-bold text-xl">
                            {{ profileCheckData?.completionInPercent }}%
                            </span>
                    <span class="text-500 text-xs">
                        {{ profileCheckData?.completenessRangeDto?.name }}
                            </span>
                </div>
            </div>

            <p-divider styleClass="mx-2" layout="vertical"/>

            <div class="px-3 py-1 cursor hover:surface-hover" (click)="ch.toggle($event)">
                <i class="text-5xl {{contractHealthData?.result?.image}} text-{{contractHealthData?.result?.rgbaColor}}"></i>
            </div>
        </div>

    </div>

</div>

<!--<p-card>-->
<!--    <p-chip styleClass="p-1 border-round-sm">-->
<!--        <span class="mx-2">       ticket chip   </span>-->
<!--        <p-badge [value]="2" class="mx-1" />-->
<!--    </p-chip>-->
<!--</p-card>-->


<!--&lt;!&ndash;<div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mvs-stepper&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        [stepItems]="stepItems"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        [activeStep]="stepperActiveStep"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        [busy]="busy"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        [showStepName]="showStepName">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</mvs-stepper>&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;<mvs-widget *ngIf="widgetObject" [widgetData]="widgetObject"></mvs-widget>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<div class="">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<cm-create-new-insurable-object [customerId]="84348"></cm-create-new-insurable-object>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->


<!--&lt;!&ndash;&lt;!&ndash;<span class=""> heading color testing</span>&ndash;&gt;&ndash;&gt;-->


<!--&lt;!&ndash;<div class="grid  bg-white h-full">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;   <div class="side-drawer card" [ngClass]="sidebar ? 'side-drawer-opened col-6' : 'col-1'">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;       sidebar&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;       <br>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;       testing&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;   </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;    <div class="col-4 card">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <i class="fa fa-times" (click)="sidebar = !sidebar"></i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            content&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <br>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            here&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        <mvs-object-comments&ndash;&gt;-->
<!--&lt;!&ndash;                             headline="Kommentare/ Vetragsinformationen"&ndash;&gt;-->
<!--&lt;!&ndash;                             [mainObject]="objectIdentifier"&ndash;&gt;-->
<!--&lt;!&ndash;                             linkedEntityObjectType="cm.ContractComment"&ndash;&gt;-->
<!--&lt;!&ndash;        ></mvs-object-comments>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;    </div>&ndash;&gt;&ndash;&gt;-->


<!--&lt;!&ndash;&lt;!&ndash;    <div class="container">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <div class="left-section relative" [class.closed]="!sidebar">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <button class="absolute" (click)="sidebar = !sidebar">Toggle Left Section</button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            &lt;!&ndash; Content of the left section goes here &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="left-content" *ngIf="sidebar">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                &lt;!&ndash; Your content when the left section is open &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                Left Section &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <div class="main-content">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            &lt;!&ndash; Main content goes here &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            Main Content&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->


<!--&lt;!&ndash;<ng-container>&ndash;&gt;-->

<!--&lt;!&ndash;        <h4>Filter:</h4>&ndash;&gt;-->

<!--&lt;!&ndash;        <p-button (click)="onShowViaAlias()" label="Filter via Alias"></p-button>&ndash;&gt;-->
<!--&lt;!&ndash;        &lt;!&ndash;    <p-button (click)="onShowViaId()" label="Filter via Object ID"></p-button>&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->


<!--&lt;!&ndash;widgets&ndash;&gt;-->

<!--<div class="grid">-->

<!--    <p-button (onClick)="op.toggle($event)" icon="pi pi-share-alt" label="filter" />-->
<!--    <p-overlayPanel #op>-->
<!--        <div class="flex flex-column gap-4">-->
<!--    <p-calendar-->
<!--            placeholder="Starts width"-->
<!--                appendTo="body"-->
<!--                dateFormat="dd.mm.yy">-->
<!--    </p-calendar>-->
<!--    <p-calendar-->
<!--            placeholder="Ends width"-->
<!--                appendTo="body"-->
<!--                dateFormat="dd.mm.yy">-->
<!--    </p-calendar>-->
<!--        </div>-->
<!--    </p-overlayPanel>-->

<!--    &lt;!&ndash;profile header 1 &ndash;&gt;-->
<!--&lt;!&ndash;    <div class="col-12 p-4">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="card">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="flex align-items-center justify-content-end w-full">&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="p-button-rounded p-button-text mvs-wigdet-menu-btn h-1rem p-0"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="flex flex-wrap align-items-center gap-4">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Company Logo &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="w-4rem h-4rem surface-400 border-circle flex align-items-center justify-content-center">&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="pi pi-building" style="font-size:2rem"></i> &lt;!&ndash; Example: Using PrimeIcons &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Company Name and ID &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="flex flex-column gap-2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="font-semibold text-lg">Carlo's Company</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="text-sm">64214</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--&lt;!&ndash;            <div class="flex flex-wrap gap-4 mt-5">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Dynamically adjust columns based on the parent's class &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Each Detail Item &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="flex flex-column gap-2 basis-full w-12rem" *ngFor="let item of profileData">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="text-color-secondary">{{ item.title }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="word-break">{{ item.value }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Repeat for other details, adjust 'basis' classes as needed for responsive behavior &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--    &lt;!&ndash;profile header 2 &ndash;&gt;-->
<!--&lt;!&ndash;    <div class="col-12 card p-0">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="border-round-lg"&ndash;&gt;-->
<!--&lt;!&ndash;             style="height:200px; background: linear-gradient(to right, #407192, #40C8C8);"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="px-4 py-5 md:px-6 lg:px-8 surface-section">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"&ndash;&gt;-->
<!--&lt;!&ndash;                 style="margin-top:-2rem; top:-70px; margin-bottom:-70px">&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div style="width:140px; height: 140px; border-radius: 10px"&ndash;&gt;-->
<!--&lt;!&ndash;                         class="mb-3 surface-card shadow-2 flex align-items-center justify-content-center">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="assets/images/blocks/logos/hyper.svg" alt="Image" width="70" height="70">&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="text-900 text-3xl font-medium mb-3">Carlo's Company</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mt-0 mb-3 text-700 text-xl">Vitae tortor condimentum lacinia quis vel eros.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="text-600 font-medium">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span>Software | USA | 15523 Followers</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="mt-3 lg:mt-0">&ndash;&gt;-->
<!--&lt;!&ndash;                    <button pButton pRipple label="Follow" icon="pi pi-plus-circle"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="p-button-outlined mr-2"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                    <button pButton pRipple label="Website" icon="pi pi-link" class="mr-2"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                    <button pButton pRipple icon="pi pi-ellipsis-v" class="p-button-outlined p-button-rounded"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--    &lt;!&ndash;    profile header 3&ndash;&gt;-->

<!--&lt;!&ndash;    <div class="col-12 card px-4 py-5">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-6">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="flex align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="pi pi-inbox text-2xl mr-3 text-500"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="text-3xl font-medium text-900">Page Title</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="mt-3 md:mt-0">&ndash;&gt;-->
<!--&lt;!&ndash;                <p-button icon="pi pi-chevron-down" label="Page Link" iconPos="right" styleClass="p-button-text"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="mr-4"></p-button>&ndash;&gt;-->
<!--&lt;!&ndash;                <button pButton pRipple label="Compose" icon="pi pi-plus"></button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <p-tabMenu [model]="tabItems" [activeItem]="activeItem"></p-tabMenu>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->


<!--    &lt;!&ndash;category pill widget&ndash;&gt;-->
<!--    <div class="lg:col-3 col-12">-->
<!--        <p-panel>-->
<!--            <ng-template pTemplate="header">-->
<!--                <div class="mb-1 flex align-items-center justify-content-between w-full">-->
<!--                    <span class="text-lg font-medium">Category</span>-->
<!--                    <div>-->
<!--                        <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"-->
<!--                                class="p-button-rounded p-button-text mvs-wigdet-menu-btn"></button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--            <ng-template pTemplate="content">-->
<!--                <div class="flex gap-2">-->
<!--                    <div class="flex flex-wrap gap-2">-->
<!--                        <div class="" *ngFor="let item of profileData">-->
<!--                            <p-tag value="{{item.value}}" severity="secondary" styleClass="px-3 surface-500"></p-tag>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    ope&ndash;&gt;-->
<!--                    <span class="w-3rem h-2rem surface-300 border-round-md flex justify-content-center align-items-center">-->
<!--                    <i class="pi pi-pencil"></i>-->
<!--                </span>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--        </p-panel>-->
<!--    </div>-->

<!--    &lt;!&ndash;    Selectable Widget&ndash;&gt;-->
<!--    <div class="lg:col-3 col-12">-->
<!--        <p-panel [iconPos]="'end'">-->
<!--            <ng-template pTemplate="header">-->
<!--                <div class="mb-1 flex align-items-center justify-content-between w-full">-->
<!--                    <span class="text-lg font-medium">Selectable Widget</span>-->
<!--                    <div>-->
<!--                        <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"-->
<!--                                class="p-button-rounded p-button-text mvs-wigdet-menu-btn"></button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--            <ng-template pTemplate="content">-->
<!--                <ul class="p-flex p-flex-column p-0 m-0" style="list-style: none;">-->
<!--                    <li *ngFor="let item of selectableItems; let i = index"-->
<!--                        class="mb-3 px-3 py-2 border-round-lg cursor-pointer hover:surface-200"-->
<!--                        [class]="selectedItem === i ? 'border-1 border-primary bg-primary-50' : 'border-transparent'"-->
<!--                        (mouseenter)="showButton = i" (mouseleave)="showButton = null">-->

<!--                        <div class="flex justify-content-between">-->
<!--                            <div class="w-full" (click)="selectedItem = i">-->
<!--                                <p-avatar [label]="item.label" styleClass="mr-2 text-0 {{item.color}}"></p-avatar>-->
<!--                                {{ item.title }}-->
<!--                            </div>-->
<!--                            <div *ngIf="showButton === i">-->
<!--                                <p-button styleClass="p-button-text py-1" icon="pi pi-window-maximize"></p-button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->

<!--                </ul>-->
<!--            </ng-template>-->
<!--        </p-panel>-->
<!--    </div>-->

<!--    &lt;!&ndash;    profile&ndash;&gt;-->
<!--    <div class="lg:col-3 col-12">-->
<!--        <p-panel>-->
<!--            <ng-template pTemplate="header">-->
<!--                <div class="mb-1 flex align-items-center justify-content-between w-full">-->
<!--                    <span class="text-lg font-medium">Profile</span>-->
<!--                    <div>-->
<!--                        <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"-->
<!--                                class="p-button-rounded p-button-text mvs-wigdet-menu-btn"></button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--            <ng-template pTemplate="content">-->
<!--                <div class="flex flex-column gap-2 align-items-center">-->
<!--                    <div class="w-5rem h-5rem surface-400 border-circle"></div>-->
<!--                    <div class="flex flex-column gap-2 justify-content-center">-->
<!--                        <div class="font-semibold text-lg">Carlo's Company</div>-->
<!--                        <span class="text-sm text-center">64214</span>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="flex flex-wrap mt-5">-->
<!--                    <div class="lg:col-6 col-12 flex flex-column gap-2 mb-3" *ngFor="let item of profileData">-->
<!--                        <div class="text-color-secondary">{{ item.title }}</div>-->
<!--                        <span>{{ item.value }}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--        </p-panel>-->
<!--    </div>-->

<!--    &lt;!&ndash;    Icon Data Widget&ndash;&gt;-->
<!--    <div class="lg:col-3 col-12">-->
<!--        <p-panel>-->
<!--            <ng-template pTemplate="header">-->
<!--                <div class="mb-1 flex align-items-center justify-content-between w-full">-->
<!--                    <span class="text-lg font-medium">Icon Data Widget</span>-->
<!--                    <div>-->
<!--                        <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"-->
<!--                                class="p-button-rounded p-button-text mvs-wigdet-menu-btn"></button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--            <ng-template pTemplate="content">-->
<!--                <div class="flex flex-column gap-4">-->
<!--                    <div class="flex gap-3 align-items-center" *ngFor="let item of dataWithIcons">-->
<!--                <span class="{{item.color}} {{item.backgroundColor}} w-2rem h-2rem flex justify-content-center align-items-center border-round-sm">-->
<!--                <i class="{{item.icon}}"></i>-->
<!--                </span>-->
<!--                        <div class="flex flex-column gap-1">-->
<!--                            <div class="word-break  text-500">{{ item.heading }}</div>-->
<!--                            <div>{{ item.value }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-template>-->
<!--        </p-panel>-->
<!--    </div>-->


<!--&lt;!&ndash;    <div class="col-4">&ndash;&gt;-->
<!--&lt;!&ndash;        <mvs-condition-builder&ndash;&gt;-->
<!--&lt;!&ndash;                *ngIf="initialized"&ndash;&gt;-->
<!--&lt;!&ndash;                [filterCriteria]="filterCriteria"&ndash;&gt;-->
<!--&lt;!&ndash;                [filterAttributeGroups]="filterAttributeGroups"&ndash;&gt;-->
<!--&lt;!&ndash;                [readOnly]="readOnly"&ndash;&gt;-->
<!--&lt;!&ndash;        ></mvs-condition-builder>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->


<!--    <div class="w-full">-->
<!--    <h2>Filter Table</h2>-->

<!--    <p-table-->
<!--        #dt2-->
<!--        [value]="customers"-->
<!--        dataKey="id"-->
<!--        [rows]="10"-->
<!--        [rowsPerPageOptions]="[10, 25, 50]"-->
<!--        [loading]="loading"-->
<!--        [paginator]="true"-->
<!--        [tableStyle]="{ 'min-width': '75rem' }"-->
<!--        (onFilter)="handleFilter($event)"-->
<!--    >-->


<!--        <ng-template pTemplate="header">-->


<!--            <tr>-->
<!--                <th style="min-width:15rem">-->
<!--                    <div class="flex align-items-center">-->
<!--                        Name-->
<!--                    </div>-->
<!--                </th>-->
<!--            </tr>-->
<!--            <tr>-->
<!--                <th>-->
<!--                    <p-columnFilter-->
<!--                            type="text"-->
<!--                            field="name"-->
<!--                            placeholder="Search by name"-->
<!--                            ariaLabel="Filter Name"/>-->
<!--                </th>-->
<!--            </tr>-->
<!--        </ng-template>-->

<!--        <ng-template pTemplate="body" let-customer>-->
<!--            <tr>-->
<!--                <td>-->
<!--                    {{ customer.name }}-->
<!--                </td>-->
<!--            </tr>-->
<!--        </ng-template>-->
<!--        <ng-template pTemplate="emptymessage">-->
<!--            <tr>-->
<!--                <td colspan="5">No customers found.</td>-->
<!--            </tr>-->
<!--        </ng-template>-->
<!--    </p-table>-->
<!--    </div>-->

<!--&lt;!&ndash;    <div class="mt-5">&ndash;&gt;-->
<!--&lt;!&ndash;        <h2>Sorting Table</h2>&ndash;&gt;-->
<!--&lt;!&ndash;        <p-table [value]="customers" [tableStyle]="{'min-width': '60rem'}" sortMode="multiple">&ndash;&gt;-->
<!--&lt;!&ndash;            <ng-template pTemplate="header">&ndash;&gt;-->
<!--&lt;!&ndash;                <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                    <th pSortableColumn="name" style="width:20%">&ndash;&gt;-->
<!--&lt;!&ndash;                        Code&ndash;&gt;-->
<!--&lt;!&ndash;                        <p-sortIcon field="name"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </th>&ndash;&gt;-->
<!--&lt;!&ndash;                    <th pSortableColumn="name" style="width:20%">&ndash;&gt;-->
<!--&lt;!&ndash;                        Name&ndash;&gt;-->
<!--&lt;!&ndash;                        <p-sortIcon field="name"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </th>&ndash;&gt;-->
<!--&lt;!&ndash;                    <th pSortableColumn="category" style="width:20%">&ndash;&gt;-->
<!--&lt;!&ndash;                        Category&ndash;&gt;-->
<!--&lt;!&ndash;                        <p-sortIcon field="category"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </th>&ndash;&gt;-->
<!--&lt;!&ndash;                    <th pSortableColumn="quantity" style="width:20%">&ndash;&gt;-->
<!--&lt;!&ndash;                        Quantity&ndash;&gt;-->
<!--&lt;!&ndash;                        <p-sortIcon field="quantity"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </th>&ndash;&gt;-->
<!--&lt;!&ndash;                </tr>&ndash;&gt;-->
<!--&lt;!&ndash;            </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;            <ng-template pTemplate="body" let-product>&ndash;&gt;-->
<!--&lt;!&ndash;                <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                    &lt;!&ndash;                <td>{ {product.status }}</td>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    <td>{{ product.name }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                    <td>{{ product.status }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                    <td>{{ product.code }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                    <td>{{ product.name }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                </tr>&ndash;&gt;-->
<!--&lt;!&ndash;            </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;        </p-table>&ndash;&gt;-->

<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--</div>-->
<!--<section class="pt-5 surface-0">-->

<!--    <mvs-wf-process        [objectIdentifier]="objectIdentifier"        [processMode]="true"></mvs-wf-process>-->


<!--<div class="flex justify-content-between align-items-center">-->
<!--    <div class="flex gap-3 mx-2 align-items-center">-->
<!--        <h5 class="font-medium m-0">Ticket</h5>-->
<!--        <p-button styleClass="h-2rem surface-200 text-color-secondary"-->
<!--                  label="Mark as complete" icon="pi pi-check-circle">-->
<!--        </p-button>-->

<!--        <div>-->
<!--            <mvs-user-group-avatar-->
<!--                    [userObjectAccess]="users"-->
<!--                    [maxVisibleAvatars]="3"-->
<!--            ></mvs-user-group-avatar>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="flex gap-2 align-items-center">-->
<!--    <ng-container *ngFor="let button of widgetButtons">-->
<!--    <div class="flex align-items-center cursor gap-2 overflow-visible" pRipple-->
<!--         pTooltip="button tooltip" tooltipPosition="bottom"-->
<!--         >-->
<!--        <ng-container *ngIf="button.type == 'badge'">-->
<!--            <i class="{{button.icon}} p-2"-->
<!--               *ngIf="button.display"-->
<!--               pBadge-->
<!--               [value]="button.badge">-->
<!--            </i>-->
<!--        </ng-container>-->

<!--        <ng-container *ngIf="button.type != 'badge'">-->
<!--            <i class="{{button.icon}} p-2"-->
<!--               *ngIf="button.display">-->
<!--            </i>-->
<!--        </ng-container>-->
<!--        <span class="" *ngIf="button.label">{{ button.label }}</span>-->
<!--    </div>-->
<!--    </ng-container>-->
<!--    </div>-->


<!--</div>-->
<!--<div class="mb-3">-->
<!--    &lt;!&ndash;    <kpi-header [itemsArray]="kpiCards"></kpi-header>&ndash;&gt;-->
<!--</div>-->
<!--<div class="mb-3 col-8">-->
<!--    <overview [itemsArray]="overViewData"></overview>-->
<!--</div>-->


<!--<div class="p-3 surface-0">-->
<!--    <div class="mb-3 flex justify-content-between">-->
<!--        <h4 class="">History</h4>-->
<!--        <p-toggleButton-->
<!--                [(ngModel)]="historyDetail"-->
<!--                onLabel="Detail View"-->
<!--                offLabel="History View"/>-->
<!--    </div>-->

<!--    <div class="" *ngIf="!historyDetail">-->

<!--        <div class="mb-3 flex">-->
<!--            <div class="flex flex-column align-items-center mt-1" style="width:2rem">-->
<!--                <span class="bg-primary-800 text-0 flex align-items-center justify-content-center border-round-lg p-2">-->
<!--                                <i class="fa-regular fa-pen text-xl"></i>-->
<!--                </span>-->
<!--                <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 4rem"></div>-->
<!--            </div>-->
<!--            <div class="ml-5 surface-card shadow-1 border-round p-4 flex-auto">-->
<!--                <div class="flex flex-column gap-3">-->
<!--                    <div class="w-full flex justify-content-between align-items-center">-->
<!--                        <span class="font-medium text-xl">Name Changed</span>-->
<!--                        <span class="text-500">Nov 1, 2024 </span>-->
<!--                    </div>-->
<!--                    <div class="flex gap-2 text-lg">-->
<!--                        <span>Name changed to</span>-->
<!--                        <span class="font-bold text-xl">Amos1</span>-->
<!--                    </div>-->
<!--                    <div class="flex align-items-center gap-2">-->
<!--                        <p-avatar shape="circle"-->
<!--                                  image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"/>-->
<!--                        <span class="font-medium text-600">Jacob Jones</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mb-3 flex">-->
<!--            <div class="flex flex-column align-items-center mt-1" style="width:2rem">-->
<!--                <span class="bg-primary-800 text-0 flex align-items-center justify-content-center border-round-lg p-2">-->
<!--                <i class="fa-regular fa-pen text-xl"></i>-->
<!--                </span>-->
<!--                <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 4rem"></div>-->
<!--            </div>-->
<!--            <div class="ml-5 surface-card shadow-1 border-round p-4 flex-auto">-->
<!--                <div class="flex flex-column gap-3">-->
<!--                    <div class="w-full flex justify-content-between align-items-center">-->
<!--                        <span class="font-medium text-xl">Status Changed</span>-->
<!--                        <span class="text-500">Nov 1, 2024 </span>-->
<!--                    </div>-->
<!--                    <div class="flex gap-2 text-lg">-->
<!--                        <span>Status changed to</span>-->
<!--                        <span class="font-bold text-xl">inAktiv</span>-->
<!--                    </div>-->
<!--                    <div class="flex align-items-center gap-2">-->
<!--                        <p-avatar shape="circle"-->
<!--                                  image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"/>-->
<!--                        <span class="font-medium text-600">Jacob Jones</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="" *ngIf="historyDetail">-->
<!--        <div class="mb-3 flex">-->
<!--            <div class="flex flex-column align-items-center mt-1" style="width:2rem">-->

<!--                <span class="text-primary flex align-items-center justify-content-center p-2">-->
<!--                    <i class="fa-regular fa-circle-dot text-2xl"></i>-->
<!--                </span>-->
<!--                <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 4rem"></div>-->
<!--            </div>-->
<!--            <div class="ml-5 surface-card shadow-1 border-round p-3 flex-auto">-->
<!--                <div class="mb-4 flex justify-content-between">-->
<!--                    <span class="text-lg">Revison ID : 00543 </span>-->
<!--                    <span class="text-500 text-sm">2 hours ago</span>-->
<!--                </div>-->

<!--                <div class="grid">-->

<!--                    <div class="col-6">-->
<!--                        <div class="grid bg-yellow-100">-->
<!--                            <span class="text-500 col-6">Name</span>-->
<!--                            <span class="text-700 col-6">Amos1</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Status</span>-->
<!--                            <span class="text-700 col-6">Aktiv</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Revision Type</span>-->
<!--                            <span class="text-700 col-6">Modified</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Processed By</span>-->
<!--                            <span class="text-700 col-6">-->
<!--                                   <div class="flex align-items-center gap-2">-->
<!--                                       <p-avatar shape="circle"-->
<!--                                                 image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"/>-->
<!--                                       <span class="font-medium text-600">Jacob Jones</span>-->
<!--                                   </div>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->


<!--            </div>-->
<!--        </div>-->

<!--        <div class="mb-3 flex">-->
<!--            <div class="flex flex-column align-items-center mt-1" style="width:2rem">-->

<!--                <span class="text-primary flex align-items-center justify-content-center p-2">-->
<!--                    <i class="fa-regular fa-circle-dot text-2xl"></i>-->
<!--            </span>-->
<!--                <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 4rem"></div>-->
<!--            </div>-->
<!--            <div class="ml-5 surface-card shadow-1 border-round p-3 flex-auto">-->
<!--                <div class="mb-4 flex justify-content-between">-->
<!--                    <span class="text-lg">Revison ID : 00543 </span>-->
<!--                    <span class="text-500 text-sm">2 hours ago</span>-->
<!--                </div>-->
<!--                <div class="grid">-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Name</span>-->
<!--                            <span class="text-700 col-6">Amos1</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid bg-yellow-100">-->
<!--                            <span class="text-500 col-6">Status</span>-->
<!--                            <span class="text-700 col-6">inAktiv</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Revision Type</span>-->
<!--                            <span class="text-700 col-6">Modified</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <div class="grid">-->
<!--                            <span class="text-500 col-6">Processed By</span>-->
<!--                            <span class="text-700 col-6">-->
<!--                                   <div class="flex align-items-center gap-2">-->
<!--                                       <p-avatar shape="circle"-->
<!--                                                 image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"/>-->
<!--                                       <span class="font-medium text-600">Jacob Jones</span>-->
<!--                                   </div>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--</section>-->
<!--<mvs-object objectType="dm.DmDocument" objectId="232599"></mvs-object>-->


<!--<div class="flex flex-column gap-2 mt-5">-->
<!--    <h6 class="mb-0">Logo/Icon</h6>-->
<!--    <div class="flex flex-wrap gap-3">-->
<!--        <div class="card mb-0 px-3 w-10rem">-->
<!--            <div class="flex align-items-center justify-content-between mb-3">-->
<!--                <span class="mb-0 font-medium">default</span>-->
<!--                <p-radioButton/>-->
<!--            </div>-->
<!--            <div class="w-full text-center border-round">-->
<!--                <img  src="{{tenantInfo.logo}}" alt="logo" class="p-3 w-8rem h-5rem">-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="card mb-0 px-3 w-10rem">-->
<!--            <div class="flex align-items-center justify-content-between mb-3">-->
<!--                <span class="mb-0 font-medium">light</span>-->
<!--                <p-radioButton />-->
<!--            </div>-->
<!--            <div class="w-full text-center surface-400 border-round">-->
<!--                <img  src="{{tenantInfo.logo}}" alt="logo" class="p-3 w-8rem h-5rem light-logo">-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</div>-->

<!--changeTopbarTheme(theme: UiThemeInterface) {-->
<!--this.selectedTopbarTheme = theme;-->
<!--this.app.topbarTheme = theme.name;-->

<!--const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;-->

<!--// if (theme.name === 'white' || theme.name === 'yellow' || theme.name === 'amber'-->
<!--//     || theme.name === 'orange' || theme.name === 'lime') {-->
<!--//     appLogoLink.src = 'assets/layout/images/logo-dark.svg';-->
<!--// }-->
<!--// else {-->
<!--//     appLogoLink.src = 'assets/layout/images/logo-light.svg';-->
<!--// }-->

<!--// logo color change-->
<!--if (theme.name === 'dark' || theme.name === 'yellow'|| theme.name === 'teal' || theme.name === 'amber'-->
<!--|| theme.name === 'orange' || theme.name === 'lime') {-->
<!--appLogoLink.style.filter = 'brightness(5)';-->
<!--//     for testing tenat logo-->
<!--appLogoLink.src = 'https://mimespublic.blob.core.windows.net/webapp/tenantC.svg';-->
<!--} else {-->
<!--appLogoLink.style.filter = 'none';-->
<!--appLogoLink.src = 'https://mimespublic.blob.core.windows.net/webapp/tenantC.svg';-->
<!--}-->
<!--        }-->