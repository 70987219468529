import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {CreateBiproServiceRequestDto} from "../dto/create-bipro-service-request.dto";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BiproServiceService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/hb/biproServices')
  }


  public createBiproService(request: CreateBiproServiceRequestDto) : Observable<any> {
    return this.postInternal(request, "/create");
  }

}