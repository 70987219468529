import {DtoDetail} from "@kvers/alpha-core-common";
import {TeEntityStatusEnum} from "../enum/te-entity-status.enum";
import {TeContentTypeEnum} from "../enum/te-content-type.enum";
import {TeTeArtefactVariableDto} from "./te-artefact-variable.dto";

export class TeArtefactDto extends DtoDetail {

    public name : string;
    public entityStatus : TeEntityStatusEnum;
    public artefactGroupDtoId : number;
    public artefactGroupDtoName : string;
    public artefactGroupDtoImage : string;
    public artefactGroupDtoColor : string;
    public description : string;
    public contentTypeEnum : TeContentTypeEnum;
    public content : string;
    public image : string;
    public rgbaColor : string;

    public artefactVariables? : TeTeArtefactVariableDto[];

}
