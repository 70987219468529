@if (activeNavigationItem.action == 'fieldType') {
    <mvs-widget
            *ngIf="basicWidgetData"
            [widgetData]="basicWidgetData"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'valueList') {

    <cc-field-value-list-and-entries
            [fieldValueListId]="dto?.fieldValueListDtoId"
    ></cc-field-value-list-and-entries>
}