export class DatesHelper{
    public static getStartOfToday(): Date {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to the start of the day
        return today;
    };

    public static getEndOfToday(): Date {
        const today = new Date();
        today.setHours(23, 59, 59, 999); // Set time to the end of the day
        return today;
    };


    public static calculateLastDayOfMonth(year: number, month: number): Date {
        const firstDayNextMonth = new Date(year, month, 1);
        const lastDayCurrentMonth = new Date(firstDayNextMonth.setDate(firstDayNextMonth.getDate() - 1));
        lastDayCurrentMonth.setHours(23, 59, 59, 999);
        return lastDayCurrentMonth;
    };

    public static calculateFirstDayOfCurrentMonth(): Date {
        const today = new Date();

        // Get the last day of the previous month
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);

        // Add 1 day to get the first day of the current month
        const firstDayOfCurrentMonth = new Date(lastDayOfPreviousMonth);
        firstDayOfCurrentMonth.setDate(lastDayOfPreviousMonth.getDate() + 1);

        return firstDayOfCurrentMonth;
    }

    public static calculateFirstDayOfLastMonth(): Date {
        const today = new Date();

        // Create a new date for the first day of the last month
        const _firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

        const firstDayOfLastMonth = new Date(_firstDayOfLastMonth);
        firstDayOfLastMonth.setDate(firstDayOfLastMonth.getDate() + 1);

        return firstDayOfLastMonth;
    };


    public static getFirstDayOfCurrentYear(): Date {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1); // January is month 0 in JavaScript
        firstDayOfYear.setHours(0, 0, 0, 0); // Set time to start of the day
        return firstDayOfYear;
    };

    public static getLastDayOfCurrentYear(): Date {
        const today = new Date();
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31); // December is month 11 in JavaScript
        lastDayOfYear.setHours(23, 59, 59, 999); // Set time to end of the day
        return lastDayOfYear;
    };

    public static getFirstDayOfPreviousYear(): Date {
        const today = new Date();
        const firstDayOfPreviousYear = new Date(today.getFullYear() - 1, 0, 1); // January 1st of the previous year
        firstDayOfPreviousYear.setHours(0, 0, 0, 0); // Set time to start of the day
        return firstDayOfPreviousYear;
    }

    public static getLastDayOfPreviousYear(): Date {
        const today = new Date();
        const lastDayOfPreviousYear = new Date(today.getFullYear() - 1, 11, 31); // December 31st of the previous year
        lastDayOfPreviousYear.setHours(23, 59, 59, 999); // Set time to end of the day
        return lastDayOfPreviousYear;
    }

}