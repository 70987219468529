<div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
    <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
</div>

<div class="grid">
    <div class="lg:col-6 col-12" *ngFor="let step of nextSteps.entries">

        <p-card header="{{step.name}}">

            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center p-3">
                    <i [ngClass]="step.image != undefined ? step.image : 'fa fa-user'" class="p-3 white-color"
                       [ngStyle]="{'background': step.rgbaColor ? 'var(--' + step.rgbaColor + ')' : 'var(--primary-500)', 'border-radius': '50%'}"></i>

                    <p-tag icon="pi pi-info-circle" value="90% Usage" styleClass="border-round-xl"
                           pTooltip="Used by agents for 90% of insurance claims processing" tooltipPosition="top"
                           [style]="{background: 'var(--indigo-100)' , color: 'var(--indigo-500)'}"></p-tag>
                </div>
            </ng-template>

            <!--        [innerHTML]="step.description | mvsSavePipe:'html'"-->

            <p [style]="{ 'min-height': '75px'}">
                <mvs-form-control-text-show-more-wrapper *ngIf="step.description" [maxLength]="100"
                                                         [text]="step.description"></mvs-form-control-text-show-more-wrapper>
            </p>

            <ng-template pTemplate="footer">
                <p-button [label]="step.name" icon="fa fa-arrow-right" iconPos="right"
                          (onClick)="onStepSelect(step)" [disabled]="busy"></p-button>
            </ng-template>

        </p-card>

    </div>
</div>