import { DtoDetail } from "@kvers/alpha-core-common";

export class TmTicketObjectDto extends DtoDetail {

    public ticketDtoId : number;
    public ticketDtoName : string;
    public ticketDtoImage : string;
    public ticketDtoColor : string;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectTypeDtoImage : string;
    public objectTypeDtoColor : string;
    public objectId : number;
   }