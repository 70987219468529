@if (initialized && dto) {
    @if (uiMode == 'side') {
        <hg-gdv-partner-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)" >
        </hg-gdv-partner-side>
    } @else {
        <hg-gdv-partner-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)" >
        </hg-gdv-partner-full>
    }
}