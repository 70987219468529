@if (activeNavigationItem.action == 'valueList') {
    <mvs-widget
            *ngIf="basicWidgetData"
            [widgetData]="basicWidgetData"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'valueListEntries') {

    <mvs-widget
            *ngIf="fieldValueListEntriesWidget"
            [widgetData]="fieldValueListEntriesWidget"
    ></mvs-widget>
}