<p-panel [toggleable]="false" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up"
         [collapsed]="false"
         class="panel-blank" [iconPos]="'end'">
    <ng-template pTemplate="header">
        <span class="text-lg text-800 font-medium">Add Sub Ticket</span>
    </ng-template>
    <ng-template pTemplate="icons">
        <p-button styleClass="p-button-text" icon="pi pi-times" [disabled]="busy"
                  (click)="handleClose()">
        </p-button>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="col-12" *ngIf="breadcrumbsItems.length > 1">
            <p-breadcrumb [model]="breadcrumbsItems"></p-breadcrumb>
        </div>

        <div class="col-12">
            <mvs-crud-object [objectType]="'tm.Ticket'"
                             [objectId]="null"
                             [readonly]="false"
                             [disabled]="false"
                             (onChangedObject)="handleChangedObject($event)"
                             (onFormDirty)="handleFormDirty($event)"
            >
            </mvs-crud-object>

        </div>
    </ng-template>

</p-panel>