import {Component, Input, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CustomerPhoneContactAvailabilityDto} from "../../dto/customer-phone-contact-availability.dto";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {DtoDetail} from "@kvers/alpha-core-common";
import {CustomerCommentService} from "../../service/api/customer-comment.service";
import {CustomerCommentDto} from "../../dto/customer-comment.dto";
import {MvsFormGroup} from "@kvers/alpha-core-common";
import {MvsFormControlService} from "@kvers/alpha-ui";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {CustomerService} from "../../service/api/customer.service";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-customer-comments',
    templateUrl: './cr-customer-comments.component.html',
    styleUrls: ['./cr-customer-comments.component.scss']
})
export class CrCustomerCommentsComponent
    extends ObjectBaseComponent {

    @Input() customerObjectId: number;
    @Input() headline: string;

    dto: CustomerCommentDto;
    editComment: boolean = true;
    formGroup: MvsFormGroup;
    form: MvsFormDto;
    formFieldComment: MvsFormFieldDto;

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected customerCommentService: CustomerCommentService,
        protected customerService: CustomerService,
        protected formService: MvsFormControlService,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit(): void {

        if (this.customerObjectId && !this.objectIdentifier) {
            this.objectIdentifier = new ObjectIdentifier("cr.CustomerComment", 0);
        }

        super.ngOnInit();
    }

    getUpdateObservable(updateDto: CustomerCommentDto): Observable<DtoDetail> {

        updateDto.id = this.objectIdentifier.objectId;

        if (updateDto.id) {
            return this.customerCommentService.update(updateDto);
        } else {
            return this.customerCommentService.create(updateDto);
        }

    }

    updateObject(updateDto: CustomerCommentDto) {
        this.busy = true;
        this.getUpdateObservable(updateDto).subscribe((res: CustomerCommentDto) => {

            this.dto = res;
            this.form = this.dto.form;
            this.setFormGroup();
            this.messageService.showSuccessMessage('Comment','Comment gespeichert!!')
            this.busy = false;
        });
    }

    handleComment(dto: CustomerCommentDto) {
        const updateDto = new CustomerPhoneContactAvailabilityDto();
        updateDto.comment = dto.comment;

        this.updateObject(updateDto);

    }


    /**
     * Retrieve obeservable depending on the input.
     * @protected
     */
    protected getRetrieveObjectObservable(): Observable<any> {
        if (this.objectIdentifier && this.objectIdentifier.objectId) {
            // retrieve via ID
            return this.crudService.get(this.objectIdentifier.objectId, null, true);

        } else if (this.customerObjectId) {

            // retrieve via customer ID
            return this.customerCommentService.listByAttribute('customer', this.customerObjectId).pipe(
                map(value => {

                    const dto = <CustomerCommentDto>value.entries[0];
                    if (dto) {
                        dto.form = value.form;
                        this.form = value.form;
                        this.objectIdentifier = new ObjectIdentifier("cr.CustomerComment", dto.id);
                        return dto;
                    } else {
                        const createDto: CustomerCommentDto = new CustomerCommentDto();
                        createDto.customerDtoId = this.customerObjectId;
                        this.updateObject(createDto);
                        return null;
                    }



                }));

        } else {
            return this.crudService.get(this.objectIdentifier.objectId, null, true);
        }

    }

    ngOnChanges(changes: SimpleChanges): void {

        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

        if (changes["customerObjectId"]) {
            this.refreshObject(false);
        }

    }

    setFormGroup() {

        // this.formFieldComment = { id: this.form.formFields['comment'].id, field : this.form.formFields['comment'], uiOutputControl: 'text'};
        this.formFieldComment = this.form.getFormField("comment");

        this.formGroup = this.formService.buildFormGroup(this.form);
        this.formGroup.patchValue(this.dto);
    }

    handleEditComment() {
        this.editComment = !this.editComment;
    }

    onObjectChanged() {
        if (this.dto) {
            this.setFormGroup();
        }
    }

}
