import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {UiPageVisitRequestDto} from "../../dto/ui-page-visit-request.dto";
import {UiPageTypeEnum} from "../../enum/ui-page-type.enum";
import {UiPageVisitResponseDto} from "../../dto/ui-page-visit-response.dto";

@Injectable({
  providedIn: 'root'
})
export class UiPageUserService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiPageUsers')
  }

  /**
   *
   * @param count
   * @protected
   */
  public getLastVisits(count: number = 10) : Observable<UiPageVisitResponseDto[]> {

    const url = `${this.apiUrl}/lastVisited?count=${count}`;
    return this.http.get<UiPageVisitResponseDto[]>(url);

  }

  /**
   * Retrieve last visited by route.
   * @param pageRoute
   * @param count
   */
  public getLastVisitsByRoute(pageRoute: string, count: number = 10) : Observable<UiPageVisitResponseDto[]> {

    const url = `${this.apiUrl}/lastVisitedByRoute?pageRoute=${pageRoute}&count=${count}`;
    return this.http.get<UiPageVisitResponseDto[]>(url);

  }

  /**
   * Most used.
   * @param count
   */
  public getMostUsed(count: number = 10) : Observable<UiPageVisitResponseDto[]> {

    const url = `${this.apiUrl}/mostUsed?count=${count}&pageType=0`;
    return this.http.get<UiPageVisitResponseDto[]>(url);

  }



  /**
   * Page visit.
   * @param pageRoute
   * @param defaultLabel
   * @param pageType
   * @param objectTypeAlias
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public pageVisit(
      pageRoute: string,
      defaultLabel: string,
      pageType: UiPageTypeEnum = UiPageTypeEnum.regular,
      objectTypeAlias?: string,
      objectTypeId?: number,
      objectId?:number
  ) {

    const url = `${this.apiUrl}/visit`;

    const request = new UiPageVisitRequestDto();
    request.pageRoute = pageRoute;
    request.defaultLabel = defaultLabel;
    request.pageType = pageType;
    request.objectTypeAlias = objectTypeAlias;
    request.objectTypeId = objectTypeId;
    request.objectId = objectId;

    this.http.post<DtoList>(
        url,
        request).subscribe(value => {
      // we are doing nothing with the result
    });

  }



}
