<ng-container *ngIf="objectIdentifier">

<!--    <div class="flex justify-content-end">-->

<!--&lt;!&ndash;        <i class="fa-regular fa-gear text-3xl" (click)="onNotificationTypesClick()"></i>&ndash;&gt;-->
<!--        <p-button icon="fa-light fa-gear" styleClass="surface-500" (click)="onNotificationTypesClick()"></p-button>-->
<!--    </div>-->

    <div class="grid">

        <div class="col-12">
            <mvs-widget *ngIf="widgetNotificationData"
                        [widgetData]="widgetNotificationData">
            </mvs-widget>
        </div>


        <div class="col-6">
            <mvs-widget *ngIf="widgetNotificationRecipientData"
                        [widgetData]="widgetNotificationRecipientData">
            </mvs-widget>
        </div>

        <div class="col-6">
            <mvs-widget *ngIf="widgetNotificationChannelData"
                        [widgetData]="widgetNotificationChannelData">
            </mvs-widget>
        </div>

    </div>

<!--    <mvs-widget *ngIf="widgetNotificationRecipientData"-->
<!--                [widgetData]="widgetNotificationRecipientData">-->
<!--    </mvs-widget>-->

<!--    <mvs-widget *ngIf="widgetNotificationChannelData"-->
<!--                [widgetData]="widgetNotificationChannelData">-->
<!--    </mvs-widget>-->

    <mvs-widget *ngIf="widgetNotificationChannelRecipientData"
                [widgetData]="widgetNotificationChannelRecipientData">
    </mvs-widget>

    <mvs-widget *ngIf="widgetNotificationEmailData"
                [widgetData]="widgetNotificationEmailData">
    </mvs-widget>

    <mvs-widget *ngIf="widgetNotificationEmailData"
                [widgetData]="widgetNotificationEmailData">
    </mvs-widget>

    <mvs-widget *ngIf="widgetNotificationEmailRecipientsData"
                [widgetData]="widgetNotificationEmailRecipientsData">
    </mvs-widget>



    <p-dialog [(visible)]="notificationDialogVisible"
              [modal]="true"
              [style]="{width: '40vw', height:'100vw'}"
              [draggable]="true"
              [maximizable]="true"
              [resizable]="true">

        <ng-template pTemplate="header">
            <h3>Notification Type</h3>
        </ng-template>

        <mvs-ns-dashboard-page></mvs-ns-dashboard-page>


    </p-dialog>


</ng-container>
