import { Component, OnInit } from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-rp-new-report-page',
  templateUrl: './rp-config-new.page.html',
  styleUrls: ['./rp-config-new.page.scss']
})
export class RpConfigNewPage extends PageComponent implements OnInit {

  defaultLabel: string = "Report Configuration";

  rpReportGroupWidget: WidgetData;
  rpReportWidget: WidgetData;
  rpReportFilterWidget: WidgetData;
  rpReportSortingWidget: WidgetData;

  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label: 'Reportgruppe', id: 'rp.ReportGroup', type: ''},
    {label: 'Report', id: 'rp.Report', type: ''},
    {label: 'Report Filter', id: 'rp.ReportFilter', type: ''},
    {label: 'Report Sortierung', id: 'rp.ReportSorting', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();
  }
  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshRpReportGroupWidget();
    this.refreshRpReportWidget();
    this.RpReportFilterWidget();
    this.refreshRpReportSortingWidget();
  }

  refreshRpReportGroupWidget() {
    this.rpReportGroupWidget = WidgetFactory.createWidgetList(
        "rp.report.group.widget.simple",
        'Reportgruppe',
        'table',
        'list',
        'entity',
        'rp.ReportGroup',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshRpReportWidget() {
    this.rpReportWidget = WidgetFactory.createWidgetList(
        "rp.report.widget.simple",
        'Report',
        'table',
        'list',
        'entity',
        'rp.Report',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  RpReportFilterWidget() {
    this.rpReportFilterWidget = WidgetFactory.createWidgetList(
        "rp.report.filter.widget.simple",
        'Report Filter',
        'table',
        'list',
        'entity',
        'rp.ReportFilter',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshRpReportSortingWidget() {
    this.rpReportSortingWidget = WidgetFactory.createWidgetList(
        "rp.report.sorting.widget.simple",
        'Report Sortierung',
        'table',
        'list',
        'entity',
        'rp.ReportSorting',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }



}
