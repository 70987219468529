import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../dto/object-type.dto";
import {MetaService} from "@kvers/alpha-core-common";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";
import {MetaDataJoinDto} from "@kvers/alpha-core-common";
import {ObjectTypeMetaJoinExtensionService} from "../../../service/api/object-type-meta-join-extension.service";
import {ObjectTypeMetaJoinExtensionDto} from "../../../dto/object-type-meta-join-extension.dto";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cc-object-type-meta-extension-page',
  templateUrl: './object-type-meta-extension.page.html',
  styleUrls: ['./object-type-meta-extension.page.scss']
})
export class ObjectTypeMetaExtensionPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  objectTypes: DtoList<ObjectTypeDto>;
  selectedObjectType: ObjectTypeDto;

  historyObjectTypes: ObjectTypeDto[] = [];

  metaData: MetaDataEntityDto;

  defaultLabel: string = "Object Type Meta Extension";

  relationDialogShow: boolean;

  metaJoinExtensions: DtoList<ObjectTypeMetaJoinExtensionDto>;
  metaDataJoinRelationEnum = MetaDataJoinRelationEnum;


  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected objectTypeService: ObjectTypeService,
      protected messageService: MvsMessageService,
      protected metaService: MetaService,
      protected objectTypeMetaJoinExtensionService: ObjectTypeMetaJoinExtensionService,) {
    super(route, coreService);
  }

  ngOnInit(): void {

    // call super implementation
    super.ngOnInit();

    this.initComponent();
    this.refreshComponent();
  }

  onRelationSaveDialog(event: any) {
    this.relationDialogShow = event.showRelationDialog;
  }

  onRelationUpdateDialog(event: any) {
  }

  /**
   * Initialize Component.
   */
  initComponent() {

    // retrieve object types
    this.objectTypeService.list(ObjectRequestList.createSimple(null)).subscribe(value => {
      this.objectTypes = <DtoList<ObjectTypeDto>>value;
      this.objectTypes.sortBy("alias", true);
      this.initialized = true;
    });
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {

    // only refresh the component if an object type was selected
    if (!this.selectedObjectType) {
      this.metaData = null;
      return;
    }

    this.busy = true;

    // retrieve meta Meta information
    this.metaService.getById(this.selectedObjectType.id, true, true, true, true, true).subscribe(value => {
      this.busy = false;
      this.metaData = value;
    });

    // retrieve external joins
    this.metaJoinExtensions = null;

    this.objectTypeMetaJoinExtensionService.listByObjectTypeId(this.selectedObjectType.id).subscribe(value => {
      this.metaJoinExtensions = <DtoList<ObjectTypeMetaJoinExtensionDto>>value;
    });


  }

  onCreateJoinExtension() {
    this.relationDialogShow = true;
  }

  /**
   * On Change Object Type
   * @param event
   */
  onChangeObjectType(event: any) {

    this.changeSelectedObjectType(event.value);

  }

  changeSelectedObjectType(objectType: ObjectTypeDto) {

    this.selectedObjectType = objectType;

    // add entry to history
    this.addToHistory(this.selectedObjectType);

    // refresh component
    this.refreshComponent();
  }


  /**
   * Add to history.
   * @param objectType
   */
  addToHistory(objectType: ObjectTypeDto) {

    const objectTypeExists = this.historyObjectTypes.find(value => (objectType.id == value.id));

    if (!objectTypeExists) {

      // copy history objects (in order to trigger the control refresh)
      const historyEntries = this.historyObjectTypes;
      this.historyObjectTypes = [];
      this.historyObjectTypes.push(objectType);
      this.historyObjectTypes.push(...historyEntries);
    }

  }

  onNavigateToJoinObjectType(join: MetaDataJoinDto) {

    const objectTypeDto = this.objectTypes.entries.find(value => value.id == join.joinObjectTypeId);

    if (objectTypeDto) {
      this.changeSelectedObjectType(objectTypeDto);
    }

  }

  onHistoryChipSelect(event: any) {
    this.changeSelectedObjectType(event.value);
  }

  onRemoveHistoryObjectType(event: any) {

    const index = this.historyObjectTypes.findIndex(value => event.value.id == value.id);

    if (index || index === 0) {
      this.historyObjectTypes.slice(index,index);
    }

  }

  onBackToOverview() {
    this.selectedObjectType = null;
    this.metaData = null;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }
}
