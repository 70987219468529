<ng-container *ngIf="initialized && dto">

    <div class="min-h-screen flex surface-ground mt-5">

        <div class="col-12 p-0"
             *ngIf="activeNavigationItem"
             [ngClass]="activeNavigationItem.action == 'Testen' || activeNavigationItem.action == 'Test Case' || activeNavigationItem.action == 'Selected Case' ?
                      'lg:col-5' : 'lg:col-3'">

            <div class="surface-border border-round shadow-1 flex-auto h-full surface-section p-3">
                <div class="" *ngIf="activeNavigationItem.action == 'Variablen'">
                    <mvs-te-variables
                            *ngIf="dto.id"
                            [variableProviderService]="crudService"
                            alias="te.TeTemplateVariable"
                            attributeName="template"
                            [contentProviderId]="dto.id"
                    ></mvs-te-variables>
                </div>

                <div *ngIf="activeNavigationItem.action == 'Selected Case'">

                    <div class="flex justify-content-between align-items-center surface-50 border-round-lg p-3 mb-3">

                        <h4 class="font-medium mb-0">
                            Test Result
                            <p-progressSpinner *ngIf="busy"
                                               styleClass="w-2rem h-2rem"
                                               animationDuration=".5s">
                            </p-progressSpinner>
                        </h4>


                        <p-dropdown
                                [options]="testCaseDtoList.entries"
                                [(ngModel)]="selectedTestCase.dto"
                                (onChange)="refreshTestCaseVariables()"
                                placeholder="Select a test case"
                                optionLabel="name"
                                class="col-5 p-0" styleClass="w-full">
                        </p-dropdown>

                    </div>



                    <div class="col-12" *ngIf="occurredException">
                        <p-message severity="error" [text]="occurredException"></p-message>

                        <p-message *ngIf="occurredExceptionDetails" severity="error"
                                   [text]="occurredExceptionDetails"></p-message>
                    </div>

                    <div class="col-12" *ngIf="templateGenerateResponse">

                        <div style="word-break: break-word;"
                             [innerHTML]="trustedGenerateResponseHtml"
                             *ngIf="trustedGenerateResponseHtml">
                        </div>

                    </div>
                </div>

                <div class="" *ngIf="activeNavigationItem.action == 'Artefakte'">
                    <mvs-embedded-artefacts
                            *ngIf="dto.id"
                            [templateId]="dto.id"
                    ></mvs-embedded-artefacts>
                </div>

                <div class="" *ngIf="activeNavigationItem.action == 'Basisdaten'">
                    <mvs-widget *ngIf="widgetBasicData" [widgetData]="widgetBasicData"></mvs-widget>
                </div>

                <div class="" *ngIf="activeNavigationItem.action == 'Testen'">
                    <mvs-te-test-case
                            [templateId]="dto.id"
                            (onSelectTestCase)="handleSelectTestCase($event)"
                    ></mvs-te-test-case>
                </div>

            </div>
        </div>

        <div class="min-h-screen p-0 col-12"
             [ngClass]="activeNavigationItem.action == 'Testen' || activeNavigationItem.action == 'Test Case' || activeNavigationItem.action == 'Selected Case' ?
                      'lg:col-7' : !activeNavigationItem ? 'lg:col-12' : 'lg:col-9'">

            <div class="px-3 h-full" [ngClass]="activeNavigationItem.action == 'Testen' ? 'hidden' : 'block'">
                <div class="surface-border surface-section flex-auto px-5 h-full shadow-1 border-round">
                    <mvs-te-editor
                            [variableProviderService]="crudService"
                            [contentProviderId]="dto.id"
                            [selectedTestCase]="selectedTestCase"
                            [content]="dto.content"
                            (onSave)="handleEditorOnSave($event)"
                            [contentType]="dto.contentTypeEnum"
                            alias="te.TeTemplateVariable"
                            attributeName="template"
                    ></mvs-te-editor>
                </div>
            </div>

            <div class="px-2" *ngIf="activeNavigationItem.action == 'Testen'">
                <mvs-te-test-case-setup></mvs-te-test-case-setup>
            </div>

        </div>
    </div>

</ng-container>