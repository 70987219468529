import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {DmDocumentService} from "../../../../service/api/dm-document.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {DmDocumentStatusInternalEnum} from "../../../../enum/dm-document-status-internal.enum";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'dm-kpi.component.html',
})
export class DmKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalDocuments: number = 0;
    totalPendingDocuments: number = 0;
    thisMonthDocument: number = 0;
    thisWeekDocument: number = 0;


    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'DM Module'}];

    constructor(
        protected documentService: DmDocumentService,) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalDocuments();


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalDocuments() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.documentService.groupBy(objectRequestList).subscribe(res => {
            this.totalDocuments = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
            this.getPendingDocuments();
            },error => {
            this.getPendingDocuments();
        });
    }


    getPendingDocuments() {

        const filterCriteria = FilterCriteria.createOr(...[
            FilterCriteria.create('status', FilterCriteria.cOperatorEqual, DmDocumentStatusInternalEnum.uploaded),
            FilterCriteria.create('status', FilterCriteria.cOperatorEqual, DmDocumentStatusInternalEnum.to_be_checked),
            FilterCriteria.create('status', FilterCriteria.cOperatorEqual, DmDocumentStatusInternalEnum.rejected)])

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [filterCriteria],
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.documentService.groupBy(objectRequestList).subscribe(res => {
            this.totalPendingDocuments = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
            this.getThisMonthDocument();
            },error => {
            this.getThisMonthDocument();
        });
    }

    getThisMonthDocument() {
        const objectRequestList = this.getThisMonthRequest('status');
        this.documentService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthDocument = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
            this.getThisWeekDocument();
        },error => {
            this.getThisWeekDocument();
        });
    }

    getThisWeekDocument() {
        const objectRequestList = this.getThisWeekRequest('status');
        this.documentService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekDocument = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
            this.initKpiHeaderDto();
        },error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Documents', value: this.totalDocuments,
            },
            {
                label: 'Pending Documents', value: this.totalPendingDocuments,
            },
            {
                label: 'This Month Documents', value: this.thisMonthDocument
            },
            {
                label: 'This Week Documents', value: this.thisWeekDocument,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
