import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {MvsFormValueListDto} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './output-fields.page.html',
    styleUrls: ['./output-fields.page.scss']
})
export class OutputFieldsPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Output Fields";
    date = new Date();
    time = new Date();
    oString: string = 'Test String';
    description: string = 'Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String Test String';
    icon: string = 'fa fa-home';
    oNumber: number = 420;
    switch: boolean = true;
    imageSrc:string = 'assets/layout/images/loading/alphaKlausLogo.png';
    valueList: MvsFormValueListDto;
    selectedOption = 'dateTime';
    sidebarOptions = [
        { id: 'dateTime', name: 'Date Time' },
        { id: 'textEvent', name: 'Text' },
        { id: 'showMore', name: 'Show More' },
        { id: 'userName', name: 'User Name' },
        { id: 'avatar', name: 'Avatar' },
        { id: 'tagChip', name: 'Tag/Chip/Badge' },
        { id: 'range', name: 'Range' },
        { id: 'navigation', name: 'Navigation' },
        { id: 'imageIcon', name: 'Image/Icon' },
        { id: 'boolean', name: 'Boolean' },
        { id: 'fileSize', name: 'File Size' },
        { id: 'phoneNumber', name: 'Phone Number' },
        { id: 'email', name: 'Email' },
        { id: 'amount', name: 'Amount' },
        { id: 'yearAgo', name: 'Year Ago' },
    ];
    ngOnInit(): void {

        this.valueList = new MvsFormValueListDto();
        this.valueList.entries = [
            {
                key: 1,
                label: 'a',
                image: 'fa fa-trash',
                color: 'red-800',
                backgroundColor: 'red-200'
            },
            {
                key: 2,
                label: 'b',
                image: 'fa fa-home',
                color: 'green-800',
                backgroundColor: 'green-200'
            },
            {
                key: 3,
                label: 'c',
                image: 'fa fa-user',
                color: 'blue-800',
                backgroundColor: 'blue-200'
            },
            {
                key: 4,
                label: 'd',
                image: 'fa fa-circle',
                color: 'yellow-800',
                backgroundColor: 'yellow-200'
            },
        ];

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }
    selectOption(id: string) {
        this.selectedOption = id;
    }
    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
