import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'pm-statistics.component.html',
})
export class PmStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    busyCalls: number = 0;
    no_answerCalls: number = 0;
    no_timeCalls: number = 0;
    successCalls: number = 0;


    personHistoryWidget: WidgetData;
    personTypeWidget: WidgetData;
    personGenderWidget: WidgetData;
    recentPersonsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;
    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'Person Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
        this.overviewDto = [
            {label: 'Busy', value: 0},
            {label: 'No answer', value: 0},
            {label: 'No time', value: 0},
            {label: 'Success', value: 0}
        ];
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshPersonHistoryWidget();
        this.refreshPersonTypeWidget();
        this.refreshPersonGenderWidget();
        this.refreshRecentPersonsWidget();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/pm/config']);
    }

    refreshPersonHistoryWidget() {
        this.personHistoryWidget = new WidgetData();
        this.personHistoryWidget.idAlias = "pm.overview.person.history.widget";
        this.personHistoryWidget.name = "Person Hisory";

        this.personHistoryWidget.uiComponent = "history.chart";
        this.personHistoryWidget.dataProvider = "list";
        this.personHistoryWidget.dataSource = "entity.groupBy";
        this.personHistoryWidget.dataProviderObject = "pc.Call";
        this.personHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["callType", "createdDate"],
            [new ObjectRequestListAttribute("callType", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.personHistoryWidget.setParamValue("fieldCategory", "callType");
        this.personHistoryWidget.setParamValue("fieldCategoryCount", "callType_count");
        this.personHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.personHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.personHistoryWidget.setParamValue("selectionMode", "single");
        this.personHistoryWidget.setParamValue("size", "S");
    }

    refreshPersonTypeWidget() {
        this.personTypeWidget = WidgetFactory.createWidgetGroupBy(
            'pc.overview.call.type.widget',
            'Call Type',
            'category',
            'entity.groupBy',
            'pc.Call',
            'callType',
            'callType_count',
            this.filterCriteria,
            'callType',
            'Anzahl');
    }

    refreshPersonGenderWidget() {
        this.personGenderWidget = WidgetFactory.createWidgetGroupBy(
            'pc.overview.call.agents.widget',
            'Call Agents',
            'category',
            'entity.groupBy',
            'pc.Call',
            'agent',
            'agent_count',
            this.filterCriteria,
            'agent',
            'Anzahl');
    }

    refreshRecentPersonsWidget() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentPersonsWidget = WidgetFactory.createWidgetTableEntity(
            'pc.overview.recently.calls',
            'Recent Calls',
            'pc.Call',
            'No Registrations',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
