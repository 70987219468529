import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {WfProcessStepDto} from "../../dto/wf-process-step.dto";
import {WfProcessStepStatusEnum} from "../../enum/wf-process-step-status.enum";
import {WfProcessTypeStepDto} from "../../dto/wf-process-type-step.dto";

@Injectable({
  providedIn: 'root'
})
export class WfProcessStepService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/wf/wfProcessSteps');
  }


  /**
   * Create new step.
   * @param process
   * @param stepType
   */
  public createStep(process: WfProcessDto, stepType: WfProcessTypeStepDto) : Observable<WfProcessStepDto> {
    const wfProcessStepDto = new WfProcessStepDto();
    wfProcessStepDto.processDtoId = process.id;
    wfProcessStepDto.processTypeStepDtoId = stepType.id;
    wfProcessStepDto.status = WfProcessStepStatusEnum.STARTED;

    return <Observable<WfProcessStepDto>>this.create(wfProcessStepDto);
  }

  /**
   * Change Status of step.
   * @param step
   * @param newStatus
   */
  public changeStepStatus(step: WfProcessStepDto, newStatus: WfProcessStepStatusEnum) : Observable<WfProcessStepDto> {

    const wfProcessStepDto = new WfProcessStepDto();
    wfProcessStepDto.id = step.id;
    wfProcessStepDto.status = newStatus;

    return <Observable<WfProcessStepDto>>this.update(wfProcessStepDto);

  }

}
