<div class="p-inputgroup">
    <p-autoComplete
            [(ngModel)]="selectedObject"
            [suggestions]="objectTypeList"
            (completeMethod)="search($event)"
            [minLength]="3"
            appendTo="body"
            class="w-full"
            inputStyleClass="w-full"
            styleClass="w-full"
            [dropdown]="true"
            (onSelect)="handleOnChange()">
    </p-autoComplete>
    <span class="p-inputgroup-addon cursor-pointer">
        <i *ngIf="state === 'VALID'" class="fas fa-check-circle valid-icon"></i>
        <i *ngIf="state === 'INVALID'" class="fas fa-times-circle invalid-icon"></i>
        <i *ngIf="state === 'IN_PROGRESS'" class="fas fa-spinner fa-spin in-progress-icon"></i>
    </span>
</div>
