@if(initialized && tenantInfo) {

    <div class="p-5">
<!--        <h4>{{tenantInfo.name}}</h4>-->
        <div class="layout-config">

            <h5 class="font-medium text-lg">Topbar theme</h5>
            <div class="layout-config-options flex flex-wrap" style="max-width: 30rem;">
                <!--        <p-colorPicker [(ngModel)]="color" [inline]="true"/>-->
                <div *ngFor="let t of topbarColors" class="col col-fixed">
                    <a style="cursor: pointer" class="layout-config-color-option" [title]="t.name" (click)="changeTopbarTheme(t)">
                        <span class="color" [ngStyle]="{'background-color': t.color}"></span>

                        <!-- *ngIf for selected tick -->
                        <span class="check flex align-items-center justify-content-center" *ngIf="this.uiDto.topbarTheme && t.color == this.uiDto.topbarTheme.color">
                                    <i class="pi pi-check" style="color: var(--topbar-text-color)"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>

        <div class="flex flex-column gap-2 mt-5">
            <h5 class="font-medium text-lg mb-0">Logo/Icon</h5>
            <div class="flex flex-wrap gap-5">
                <!-- Default Logo -->
                <div class="card mb-0 px-3 w-10rem shadow-1">
                    <div class="flex align-items-center justify-content-between mb-3">
                        <span class="mb-0 font-medium">default</span>
                        <p-radioButton
                                name="logoSelection"
                                value="default"
                                (click)="handleLogoChange('default')"
                                [(ngModel)]="uiDto.topbarLogo">
                        </p-radioButton>
                    </div>
                    <img src="{{tenantInfo.logo}}" alt="logo" class="p-3 w-8rem">
                </div>

                <!-- Light Logo -->
                <div class="card mb-0 px-3 w-10rem shadow-1">
                    <div class="flex align-items-center justify-content-between mb-3">
                        <span class="mb-0 font-medium">light</span>
                        <p-radioButton
                                name="logoSelection"
                                value="light"
                                (click)="handleLogoChange('light')"
                                [(ngModel)]="uiDto.topbarLogo"
                        >
                        </p-radioButton>
                    </div>
                    <img src="{{tenantInfo.logo}}" alt="logo" class="bg-primary p-3 w-8rem light-logo">
                </div>
            </div>
        </div>

<!--        <div class="mt-7 text-right">-->
<!--            <p-button styleClass="px-5" label="Save" (click)="handleSaveUserSettings()"></p-button>-->
<!--        </div>-->


        <!--        <div class="flex flex-column gap-2 mt-5">-->
<!--            <h6 class="mb-0">Logo/Icon</h6>-->
<!--            <div class="flex flex-wrap gap-3">-->
<!--                <div class="card mb-0 px-3 w-10rem">-->
<!--                    <div class="flex align-items-center justify-content-between mb-3">-->
<!--                        <span class="mb-0 font-medium">default</span>-->
<!--                        <p-radioButton variant="filled"/>-->
<!--                    </div>-->
<!--                    <img  src="{{tenantInfo.logo}}" alt="logo" class="p-3 w-8rem">-->
<!--                </div>-->
<!--                <div class="card mb-0 px-3 w-10rem">-->
<!--                    <div class="flex align-items-center justify-content-between mb-3">-->
<!--                        <span class="mb-0 font-medium">light</span>-->
<!--                        <p-radioButton variant="filled"/>-->
<!--                    </div>-->
<!--                    <img  src="{{tenantInfo.logo}}" alt="logo" class="bg-primary p-3 w-8rem light-logo">-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
    </div>


}