<div class="flex flex-column gap-2">

    <!-- Activity Types -->
    <!--    <div *ngFor="let activity of activityType">-->

    <!-- Show Side Activity -->
    <mvs-wf-process-step-activity-main
            [processRuntime]="processRuntime"
            [processMeta]="processMeta"
            [activity]="processRuntime.activeStepActivity"
            [activityType]="activityType"
            [stepType]="stepType"
            [bindings]="bindings"
            [formDefaults]="formDefaults"
            (onAction)="onContinue($event)"
            (onSkip)="onSkipStep($event)"
            (onNextStepSelect)="onNextStep($event)"
            (onProcessCreated)="handleProcessCreated($event)"
    ></mvs-wf-process-step-activity-main>

    <!--    </div>-->

</div>
