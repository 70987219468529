@if (initialized) {
    @if (uiMode == 'side') {
        <as-appointment-booking-side
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></as-appointment-booking-side>
    } @else {

        <as-appointment-booking-full
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></as-appointment-booking-full>
    }
}