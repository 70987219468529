import {MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {ContractInsurableObjectDto} from "../../../../ci/dto/contract-insurable-object.dto";
import {CustomerInsurableObjectDto} from "../../../../cr/dto/customer-insurable-object.dto";

export class CmInsurableObjectWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;

    constructor(protected configService: MvsConfigService,
                protected selectedContract: ContractInsurableObjectDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    addSelectedPartner(contractInsurableObjectDto: ContractInsurableObjectDto[]) {

        this.selectedContract = contractInsurableObjectDto;

        for (let contractInsurableObjectDto of this.selectedContract) {
            contractInsurableObjectDto['__color'] = 'var(--green-100)';
        }
    }


    getListStyle(rowDto: CustomerInsurableObjectDto, rowData: any): object {

        const found = this.selectedContract.find(item => item.insurableObjectDtoId == rowDto.insurableObjectDtoId);

        if (found) {
            return {
                "border": "2px",
                "background-color": found['__color']
            };
        }
        return {};
    }

    public clone(): CmInsurableObjectWidgetStyle {

        const widgetStyle = new CmInsurableObjectWidgetStyle(this.configService, this.selectedContract);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;

        return widgetStyle;
    }

}
