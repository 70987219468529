import {Route, Routes} from "@angular/router";
import {CpConfigPage} from "./page/cp-config-page/cp-config.page";
import {
    ProvisionContractSimulateComponent
} from "./component/provision-contract-simulate/provision-contract-simulate.component";
import {CpProvisionAgentPage} from "./page/cp-provision-agent-page/cp-provision-agent.page";

export function CpSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'cp/config', component: CpConfigPage},
        {path: 'cp/provision-agent/:id', component: CpProvisionAgentPage},
        {path: 'cp/contract-simulate', component: ProvisionContractSimulateComponent},


    ];

    return routes;

}
