<ng-container *ngIf="initialized">

    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>

    <ng-container *ngIf="activeItem.id == 'cr.CustomerRelatedPerson'">
        <mvs-widget
                *ngIf="widgetContractPerson"
                [widgetData]="widgetContractPerson"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeItem.id == 'pm.Person'">

        <br />

        <mvs-object
            objectType="pm.Person"
            [objectId]="dto.personDtoId"
        ></mvs-object>

    </ng-container>

</ng-container>