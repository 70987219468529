<ng-container *ngIf="initialized">

    <p-card>
        <ng-container>

            <mvs-stepper
                    [stepItems]="stepItems"
                    [activeStep]="activeStep"
                    [busy]="busy"
                    [showStepName]="true"
                    [backStepAllowed]="false">
            </mvs-stepper>

            <!--    <ng-container *ngIf="!forceCreate && !createNewCustomer && !personFormWidget && !selectedPersonId">-->
            <ng-container *ngIf="activeStep == 0">
                <mvs-widget *ngIf="customerRelatedPersonWidget"
                            [widgetData]="customerRelatedPersonWidget"
                            [uiStyle]="cmInsurableObjectWidgetStyle"
                            (onObjectSelect)="handleStep0RelatedPersonSelect($event)">
                </mvs-widget>

                <div class="flex justify-content-end align-items-center mt-4 px-3">
                    <label class="text-base text-500 mr-3">Person nicht vorhanden? </label>
                    <button pButton type="button" label="Weiter" (click)="gotoStep(1)"></button>
                </div>
            </ng-container>

            <!--    <ng-container *ngIf="createNewCustomer && !personFormWidget && !relatedPersonForm">-->
            <ng-container *ngIf="activeStep == 1">
                <div class="ml-5">
                    <h3>Person auswählen</h3>

                    <search-flexible
                            [defaultDto]="flexiblePersonSearchDto"
                            [objectType]="'pm.Person'"
                            (onObjectSelect)="handlePersonSelectStep1($event)"
                            (onSearchAction)="handlePersonSearchStep1($event)"
                    ></search-flexible>

                    <div class="flex justify-content-end align-items-center mt-4 px-3">
                        <label class="text-base text-500 mr-3">Person nicht gefunden?</label>
                        <button pButton type="button" class="mx-2" label="Neue Person anlegen"
                                (click)="handleCreateNewPersonStep1()"></button>
                        <button *ngIf="selectedPersonId" pButton type="button" label="Wählen"
                                (click)="handleContinueWithSelectedPersonStep1()"></button>

                    </div>
                </div>
            </ng-container>

            <!--Create Person Form -->
            <ng-container *ngIf="activeStep == 2">
                <mvs-widget *ngIf="personFormWidget"
                            [widgetData]="personFormWidget"
                            (onChangedObject)="handlePersonCreateStep2($event)"
                >
                </mvs-widget>
            </ng-container>

            <ng-container *ngIf="activeStep == 3">

                <!-- Create Related Person -->
                <ng-container *ngIf="personRelationCreated == undefined">
                    <mvs-widget *ngIf="personRelationPersonForm"
                                [widgetData]="personRelationPersonForm"
                                (onChangedObject)="handleCreateRelatedPersonStep3($event)">
                    </mvs-widget>
                </ng-container>

                <ng-container *ngIf="personRelationCreated">
                    <div class="flex justify-content-start gap-3">
                        <span class="flex align-items-center">DOES THIS PERSON LIVE IN SAME HOUSEHOLD?</span>
                        <span class="flex gap-2">
                        <p-button label="Yes" (click)="handleSameHousehold()"></p-button>
                        <p-button label="No" (click)="handleCompleteWithoutCreatingHousehold()" i></p-button>
                    </span>

                    </div>
                </ng-container>

            </ng-container>

            <ng-container *ngIf="activeStep == 4">

                <!-- Create Contract Person -->
                <mvs-widget *ngIf="contractPersonForm"
                            [widgetData]="contractPersonForm"
                            (onChangedObject)="handleCreateContractPersonStep4($event)">
                </mvs-widget>

            </ng-container>

<!--            <ng-container *ngIf="activeStep == 5">-->

<!--                <div class="flex justify-content-start gap-3">-->
<!--                    <span class="flex align-items-center">DOES THIS PERSON LIVE IN SAME HOUSEHOLD?</span>-->
<!--                    <span class="flex gap-2">-->
<!--                        <p-button label="Yes" (click)="handleSameHousehold()"></p-button>-->
<!--                        <p-button label="No" (click)="handleCompleteWithoutCreatingHousehold()" i></p-button>-->
<!--                    </span>-->

<!--                </div>-->

<!--                <ng-container *ngIf="householdWidget">-->
<!--                    <mvs-widget [widgetData]="householdWidget"-->
<!--                                (onObjectSelect)="handleHouseholdSelect($event)">-->
<!--                    </mvs-widget>-->

<!--                    <div class="flex justify-content-end align-items-center mt-4 px-3">-->
<!--                        <label class="text-base text-500 mr-3">Household not found?</label>-->
<!--                        <button pButton type="button" class="mx-2" label="Create New Household"-->
<!--                                (click)="handleCreateNewHousehold()"></button>-->
<!--                        <button *ngIf="selectedHouseholdId" pButton type="button" label="Choose"-->
<!--                                (click)="handleContinueWithSelectedHousehold()"></button>-->
<!--                    </div>-->

<!--                    <mvs-widget *ngIf="householdWidgetForm"-->
<!--                                [widgetData]="householdWidgetForm"-->
<!--                                (onChangedObject)="handleHouseholdCreated($event)">-->
<!--                    </mvs-widget>-->

<!--                </ng-container>-->

<!--            </ng-container>-->

        </ng-container>

    </p-card>

</ng-container>