import {Injectable, Injector} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActivityContextHolderService} from "../activity-context-holder.service";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {AgentActiveObjectService} from "../../../am/service/api/agent-active-object.service";

@Injectable()
export class ActivityContextInterceptor implements HttpInterceptor {
    private agentActiveObjectService: AgentActiveObjectService | null = null;


    constructor(private activityContextHolderService: ActivityContextHolderService,
                private navigationService: MvsObjectNavigationService,
                private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let activityContext = this.activityContextHolderService.getContext();

        if (req.method == 'PUT') {

            if (!this.agentActiveObjectService) {
                this.agentActiveObjectService = this.injector.get(AgentActiveObjectService);
            }

            // checking for any opened objects
            const openedObjectLeft = this.navigationService.getNavigationBehaviourSubject('left')?.getValue();
            const openedObjectRight = this.navigationService.getNavigationBehaviourSubject('right')?.getValue();

            let objectContexts: string;

            if (openedObjectLeft) {
                objectContexts = `${openedObjectLeft.objectIdentifier.objectType}:${openedObjectLeft.objectIdentifier.objectId}`;
            }

            if (openedObjectRight) {
                if (objectContexts) {
                    objectContexts = `${objectContexts};${openedObjectRight.objectIdentifier.objectType}:${openedObjectRight.objectIdentifier.objectId}`;
                } else {
                    objectContexts = `${openedObjectRight.objectIdentifier.objectType}:${openedObjectRight.objectIdentifier.objectId}`;
                }

            }

            if (activityContext) {
                activityContext = `${activityContext};${objectContexts}`;
            } else {
                activityContext = `${objectContexts}`;
            }
        }

        // if activityContext is present then we add it into our headers
        if (activityContext) {
            req = req.clone({
                setHeaders: {
                    'x-activity-context': activityContext
                }
            });
        }

        // pass the request to the next handler
        return next.handle(req);
    }
}
