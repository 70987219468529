import {Component, OnInit, SimpleChanges} from '@angular/core';
import {
  FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {
  MvsObjectNavigationProviderGeneric
} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {AgentService} from "../../../../am/service/api/agent.service";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {AgentDto} from "../../../../am/dto/agent.dto";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {UmUserService} from "../../../../um/service/api/um-user.service";
import {DtoDetail} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";


@Component({
  selector: 'ws-my-space',
  templateUrl: './my-space.page.html',
  styleUrls: ['./my-space.page.scss']
})
export class MySpacePage extends PageComponent implements OnInit {

  defaultLabel: string = "My Space";
  openTicketsWidgetData: WidgetData;
  widgetCalls: WidgetData;
  assignedWorkFlowsWidgetData: WidgetData;
  openWorkflowsWidgetData: WidgetData;
  agentDto: AgentDto;
  userDto: DtoDetail;

  ngOnInit(): void {
    super.ngOnInit();
    this.initComponent();
  }

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected agentService: AgentService,
      protected ticketService: TicketService,
      protected userService: UmUserService,
      protected wfProcessService: WfProcessService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  /**
   * Initialize Component.
   */
  initComponent() {
    this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

    this.agentService.me().subscribe(value => {
      this.agentDto = value;
      this.refreshComponent();
    });

  }

  refreshWidgetData(){

    //active tickets filter widget data
    const activeTicketsFilterList = [];
    this.ticketService.includeTicketStatusOpenToFilter(activeTicketsFilterList);
    activeTicketsFilterList.push(FilterCriteria.create('assigneeAgent',FilterCriteria.cOperatorEqual, this.agentDto.id));


    this.openTicketsWidgetData = WidgetFactory.createWidgetList(
        "ws.workspace.active.tickets.list",
        "Tickets",
        "table",
        "list",
        "entity",
        "tm.Ticket",
        "No active tickets found!",
        ObjectRequestList.createBasic(
            true,
            activeTicketsFilterList,
            [new Sorting("createdDate", false)]
        )
    );

    //active tickets filter widget data
    const filterCriteriaList = [ FilterCriteria.create("agent", FilterCriteria.cOperatorEqual, this.agentDto.id)];
    this.widgetCalls = WidgetFactory.createWidgetListComplex(
        "ws.workspace.my.calls.list",
        "Meine Anrufe",
        "timeline",
        "list",
        "entity",
        "pc.Call",
        "Keine Anrufe vorhanden",
        ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("createdDate", false)],
            PagingDto.create(0,5)),
        ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("lastProtocol", ObjectRequestRelation.createLatest("pc.CallProtocol", "call"))),
        WidgetDataParam.create("selectionMode", "rowSelect"),
        WidgetDataParam.create("labelPosition", "left"),
    );


    //my assigned workflow tasks
    const assignedWorkflowsFilter = [ FilterCriteria.create("assignmentAgent", FilterCriteria.cOperatorEqual, this.agentDto.id)];
    this.assignedWorkFlowsWidgetData = WidgetFactory.createWidgetListComplex(
        "ws.workspace.my.assigned.workflows.list",
        "My Assigned Workflows",
        "list",
        "list",
        "entity",
        "wf.WfProcessStep",
        "No data found!",
        new ObjectRequestList(
            true,
            assignedWorkflowsFilter,
            [new Sorting("createdDate", false)]
        ),
        ObjectRequestComplex.build(true,false,ObjectRequestComplexNode.createSimpleAttributeNode('process')),
    );


    this.getUserService();
  }

  getUserService() {

    this.userService.me().subscribe(value => {
      this.userDto = value;
      this.getOpenWorkflows();
    });
  }

  getOpenWorkflows() {

    //my open workflows widget data
    const openWorkflowsFilterList = [];
    openWorkflowsFilterList.push(FilterCriteria.create("processStatusInternal", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved ));
    openWorkflowsFilterList.push(FilterCriteria.create("processStatusInternal", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled ));
    openWorkflowsFilterList.push(FilterCriteria.create('createdBy',FilterCriteria.cOperatorEqual, this.userDto.id));

    this.openWorkflowsWidgetData = WidgetFactory.createWidgetList(
        "ws.workspace.open.workflows.list",
        "Open Workflows",
        "list",
        "list",
        "entity",
        "wf.WfProcess",
        "No data found!",
        ObjectRequestList.createBasic(
            true,
            openWorkflowsFilterList,
            [new Sorting("createdDate", false)]
        )
    );

  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.refreshWidgetData()
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }
}


