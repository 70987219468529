export class SearchIndexRequestDto {


    public requestedObjectTypeIds: number[];

    public limitProcessingCount: number;

    public resolveSources: boolean;

    public threadCount: number;

    public threadPackageSize: number;

    public disableReferenceCreation: boolean;

    public static create(requestedObjectTypeIds: number[], limitProcessingCount: number, resolveSources: boolean, threadCount: number, threadPackageSize: number) {
        const instance = new SearchIndexRequestDto();
        instance.requestedObjectTypeIds = requestedObjectTypeIds;
        instance.limitProcessingCount = limitProcessingCount;
        instance.resolveSources = resolveSources;
        instance.threadCount = threadCount;
        instance.threadPackageSize = threadPackageSize;
        return instance;
    }


}