import {Component} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum, MvsMessageService,
    NavigationItem,
    ObjectIdentifierData,
    ObjectRequestList,
    PageComponent,
    PagingDto,
    TabEntries,
    WidgetData,
    WidgetDataParam,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {ContractBaseTypeDto} from "../../../dto/contract-base-type.dto";
import {ContractBaseTypeReasonDto} from "../../../dto/contract-base-type-reason.dto";
import {ContractBaseTypeTicketTypeDto} from "../../../dto/contract-base-type-ticket-type.dto";
import {ContractPriceService} from "../../../service/api/contract-price.service";


@Component({
    selector: 'mvs-cm-config',
    templateUrl: './cm-config.page.html',
})
export class CmConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Vertragskonfiguration";

    cmCategoryWidget: WidgetData;
    cmTaxWidget: WidgetData;
    cmBaseCategoryWidget: WidgetData;
    cmBaseTypeWidget: WidgetData;
    cmBaseTypeReasonWidget: WidgetData;
    cmBaseTypeTicketTypeWidget: WidgetData;
    cmGroupWidget: WidgetData;
    cmGroupAmountTypeWidget: WidgetData;
    cmContractStatusTypeWidget: WidgetData;
    cmContractStatusTypeEntryWidget: WidgetData;
    cmRatingWidget: WidgetData;
    cmRatingTypeWidget: WidgetData;
    cmRatingTypeObjectTypeWidget: WidgetData;
    cmTagTypeWidget: WidgetData;
    cmTagTypeScopeWidget: WidgetData;
    cmPriceReasonWidget: WidgetData;
    cmContractOneTimePaymentReasonWidget: WidgetData;
    cmContractNotificationTypeWidget: WidgetData;
    cmContractOriginTypeWidget: WidgetData;
    cmContractClosureChannelTypeWidget: WidgetData;

    cmContractTerminationTypeWidget: WidgetData;
    cmContractTerminationReasonTypeWidget: WidgetData;
    cmContractCategoryTypeWidget: WidgetData;
    cmContractTypeWidget: WidgetData;
    cmProductWidget: WidgetData;
    cmContractTypeFieldWidget: WidgetData;
    cmContractTypeAmountTypeFieldWidget: WidgetData;

    categoryId: number;
    tagTypeId: number;
    ratingTypeId: number;
    contractTypeId: number;

    batchBusy: boolean = false;

    selectedContractBaseTypeId;
    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    activeNavItem: string;
    uiWidgetEntries: TabEntries[];
    navigationItems: NavigationItem[] = [
        {
            label: 'contractStructure',
            action: 'contractStructure',
            tooltip: 'Contract Structure',
            icon: 'fa fa-sitemap',
            toggleable: false,
            default: false
        },
        {
            label: 'contractStatus',
            action: 'contractStatus',
            tooltip: 'Contract Status',
            icon: 'fa fa-info-circle',
            toggleable: false,
            default: false
        },
        {label: 'tags',action: 'tags', tooltip: 'Tags', icon: 'fa fa-tags', toggleable: false, default: false},
        {label: 'valueLists',action: 'valueLists', tooltip: 'Value Lists', icon: 'fa fa-list-alt', toggleable: false, default: false},
        {label: 'rating',action: 'rating', tooltip: 'Rating', icon: 'fa fa-star', toggleable: false, default: false},
        {label: 'storno',action: 'storno', tooltip: 'Storno', icon: 'fa fa-ban', toggleable: false, default: false},
        {label: 'notifications',action: 'notifications', tooltip: 'Notifications', icon: 'fa fa-bell', toggleable: false, default: false},
        {
            label: 'contractBase',
            action: 'contractBase',
            tooltip: 'Contract Base',
            icon: 'fa fa-file-contract',
            toggleable: false,
            default: false
        },
        {label: 'tax',action: 'tax', tooltip: 'Steuer', icon: 'fas fa-receipt', toggleable: false, default: false},
    ];
    widgetEntries: TabEntries[] = [
        {label: 'Kategorie', id: 'cm.Category', type: ''},
        {label: 'Gruppe', id: 'cm.Group', type: ''},
        {label: 'Typen', id: 'cm.ContractStatusType', type: 'contractStatus'},
        {label: 'Werte', id: 'cm.ContractStatusTypeEntry', type: 'contractStatus'},

        //TODO: DAUD
        // {label: 'Category', id: 'cm.ContractBaseCategory', type: 'contractBase'},
        // {label: 'Type', id: 'cm.ContractBaseType', type: 'contractBase'},
        // {label: 'Type Reason', id: 'cm.ContractBaseTypeReason', type: 'contractBase'},

        {label: 'Rating', id: 'cm.Rating', type: ''},
        {label: 'Rating Type', id: 'cm.RatingType', type: ''},
        {label: 'Rating Type - Object Type', id: 'cm.RatingTypeObjectType', type: ''},

        {label: 'Tag Types', id: 'cm.TagType', type: ''},
        {label: 'Tag Type Scope', id: 'cm.TagTypeScope', type: ''},
        {label: 'Grund für Preisänderung', id: 'cm.PriceReason', type: 'valueLists'},
        {label: 'Grund für Einmalzahlung', id: 'cm.ContractOneTimePaymentReason', type: 'valueLists'},
        {label: 'Notifications', id: 'cm.ContractNotificationType', type: 'notifications'},
        {label: 'Vertragsherkunft', id: 'cm.ContractOriginType', type: 'valueLists'},
        {label: 'Abschlussarten', id: 'cm.ContractClosureChannelType', type: 'valueLists'},
        {label: 'Stornotyp', id: 'cm.ContractTerminationType', type: ''},
        {label: 'Stornogrund', id: 'cm.ContractTerminationReasonType', type: ''},
        {label: 'Vertragskategorie', id: 'cm.ContractCategoryType', type: 'valueLists'},
        {label: 'Vertragstyp', id: 'cm.ContractType', type: ''},
        {label: 'Product', id: 'cm.Product', type: ''},
        {label: 'Contract Type Field', id: 'cm.ContractTypeField', type: ''},



    ];

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected contractPriceService: ContractPriceService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    batchUpdatePrices() {

        if (this.batchBusy) {
            return;
        }
        this.batchBusy= true;

        this.contractPriceService.executeBatchUpdatePrices().subscribe(value => {

            this.messageService.showSuccessMessage("Batch", "Beiträge wurden erfolgreich aktualisiert!");
            this.batchBusy = false;
        }, error => {
            this.messageService.showErrorMessage("Batch", "Fehler aufgetreten!");
            this.batchBusy = false;
        });

    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.initialized = false;
            let objectType = params['tid'];
            if (params['navItem']) {
                if (this.activeNavItem && this.activeNavItem !== params['navItem']) {
                    this.refreshWidgets();
                    objectType = this.getFirstActiveWidget(params['navItem'])?.id;
                    this.router.navigate([], {
                        queryParams: {tid: objectType, 'tid-action': 2},
                        queryParamsHandling: 'merge'
                    });
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }
                this.activeNavItem = params['navItem'];
                this.uiWidgetEntries = this.getRelevantGroupedEntries(this.activeNavItem);
                if (!objectType) {
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }

            }

            if (objectType) {
                const object = this.uiWidgetEntries.find(item => item.id === objectType);
                if (object) {
                    this.activeTabIndex = this.uiWidgetEntries.findIndex(item => item.id === object.id);
                    this.activeWidget = object;
                }
            }
            //should be solved after primeng library update (tabPanel not resetting properly)
            setTimeout(() => {
                this.initialized = true;
            }, 10);
        });
    }

    getFirstActiveWidget(currentNavItem: string) {
        const relevantEntries = this.getRelevantGroupedEntries(currentNavItem);
        if (relevantEntries) {
            return relevantEntries[0];
        }
        return null;
    }

    getRelevantGroupedEntries(currentNavItem: string) {
        return this.widgetEntries.filter(item => item.type === currentNavItem);
    }

    refreshWidgets() {
        this.refreshCmCategoryWidget();
        this.refreshCmContractTypeWidget(null);
        this.refreshCmGroupWidget(null);
        this.refreshCmGroupAmountTypeWidget(null);
        this.refreshCmProductWidget(null);
        this.refreshCmRatingWidget(null);
        this.refreshCmRatingTypeObjectTypeWidget(null);
        this.refreshCmContractTerminationReasonTypeWidget(null);
        this.refreshCmTagTypeScopeWidget(null);
        this.refreshCmContractStatusTypeWidget();
        this.refreshCmContractStatusTypeEntryWidget();
        this.refreshCmRatingTypeWidget();
        this.refreshCmTagTypeWidget();
        this.refreshCmPriceReasonWidget();
        this.refreshCmContractOneTimePaymentWidget();
        this.refreshCmContractNotificationTypeWidget();
        this.refreshCmContractClosureChannelType();
        this.refreshCmContractOriginTypeWidget();
        this.refreshCmContractTerminationTypeWidget();
        this.refreshCmContractCategoryTypeWidget();
        this.refreshCmContractBaseCategoryWidget();
        this.refreshCmTax();
        // this.refreshCmContractBaseTypeWidget(null);
        // this.refreshCmContractBaseTypeReasonWidget(null);
    }

    refreshCmTax() {
        this.cmTaxWidget = WidgetFactory.createWidgetTableEntity(
            "cm.config.tax.simple",
            'Steuer',
            'cm.Tax',
            'Keine Daten vorhanden',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
                    );
    }

    refreshCmCategoryWidget() {
        this.cmCategoryWidget = WidgetFactory.createWidgetList(
            "cm.category.widget.simple",
            'Kategorie',
            'selectable',
            'list',
            'entity',
            'cm.Category',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractBaseCategoryWidget() {
        this.cmBaseCategoryWidget = WidgetFactory.createWidgetList(
            "cm.base.category.widget.simple",
            'Contract Base Category',
            'selectable',
            'list',
            'entity',
            'cm.ContractBaseCategory',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }


    refreshCmGroupAmountTypeWidget(filter: FilterCriteria[]) {
        this.cmGroupAmountTypeWidget = WidgetFactory.createWidgetList(
            "cm.group.amount.type.widget.simple",
            'Konfiguration',
            'table',
            'list',
            'entity',
            'cm.GroupAmountType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        //this.cmGroupAmountTypeWidget.functionCallbacks = this.categoryWidgetFunctionCallback();
    }

    refreshCmGroupWidget(filter: FilterCriteria[]) {
        this.cmGroupWidget = WidgetFactory.createWidgetList(
            "cm.group.widget.simple",
            'Gruppe',
            'table',
            'list',
            'entity',
            'cm.Group',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmGroupWidget.functionCallbacks = this.categoryWidgetFunctionCallback();
    }

    refreshCmContractStatusTypeWidget() {
        this.cmContractStatusTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.status.type.widget.simple",
            'Typen',
            'table',
            'list',
            'entity',
            'cm.ContractStatusType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractStatusTypeEntryWidget() {
        this.cmContractStatusTypeEntryWidget = WidgetFactory.createWidgetList(
            "cm.contract.status.type.entry.widget.simple",
            'Werte',
            'table',
            'list',
            'entity',
            'cm.ContractStatusTypeEntry',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmRatingWidget(filter: FilterCriteria[]) {
        this.cmRatingWidget = WidgetFactory.createWidgetList(
            "c.rating.widget.simple",
            'Rating',
            'table',
            'list',
            'entity',
            'cm.Rating',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmRatingWidget.functionCallbacks = this.ratingTypeWidgetFunctionCallback();
    }

    refreshCmRatingTypeWidget() {
        this.cmRatingTypeWidget = WidgetFactory.createWidgetList(
            "cm.rating.type.widget.simple",
            'Rating Type',
            'selectable',
            'list',
            'entity',
            'cm.RatingType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, [], [], PagingDto.create(0, 20))
        );
    }

    refreshCmRatingTypeObjectTypeWidget(filter: FilterCriteria[]) {
        this.cmRatingTypeObjectTypeWidget = WidgetFactory.createWidgetList(
            "cm.rating.type.object.type.widget.simple",
            'Rating Type - Object Type',
            'table',
            'list',
            'entity',
            'cm.RatingTypeObjectType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmRatingTypeObjectTypeWidget.functionCallbacks = this.ratingTypeWidgetFunctionCallback();
    }

    refreshCmTagTypeWidget() {
        this.cmTagTypeWidget = WidgetFactory.createWidgetList(
            "cm.tag.type.widget.simple",
            'Tag Types',
            'selectable',
            'list',
            'entity',
            'cm.TagType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmTagTypeScopeWidget(filter: FilterCriteria[]) {
        this.cmTagTypeScopeWidget = WidgetFactory.createWidgetList(
            "cm.tag.type.scope.widget.simple",
            'Tag Type Scope',
            'table',
            'list',
            'entity',
            'cm.TagTypeScope',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmTagTypeScopeWidget.functionCallbacks = this.tagTypeScopeCallback();
    }

    refreshCmContractOneTimePaymentWidget() {
        this.cmContractOneTimePaymentReasonWidget = WidgetFactory.createWidgetList(
            "cm.contract.one.time.payment.reason.widget.simple",
            'Grund für Einmalzahlung',
            'table',
            'list',
            'entity',
            'cm.ContractOneTimePaymentReason',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmPriceReasonWidget() {
        this.cmPriceReasonWidget = WidgetFactory.createWidgetList(
            "cm.price.reason.widget.simple",
            'Grund für Preisänderung',
            'table',
            'list',
            'entity',
            'cm.PriceReason',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractNotificationTypeWidget() {
        this.cmContractNotificationTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.notification.type.widget.simple",
            'Notifications',
            'table',
            'list',
            'entity',
            'cm.ContractNotificationType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractClosureChannelType() {
        this.cmContractClosureChannelTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.closure.channel.type.widget.simple",
            'Abschlussart',
            'table',
            'list',
            'entity',
            'cm.ContractClosureChannelType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractOriginTypeWidget() {
        this.cmContractOriginTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.origin.type.widget.simple",
            'Vertragsherkunft',
            'table',
            'list',
            'entity',
            'cm.ContractOriginType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractTerminationTypeWidget() {
        this.cmContractTerminationTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.termination.type.widget.simple",
            'Stornotyp',
            'selectable',
            'list',
            'entity',
            'cm.ContractTerminationType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    resetWidgets() {
        this.cmContractTypeFieldWidget = null;
        this.cmGroupWidget = null;
        this.cmProductWidget = null;
        this.cmContractTypeWidget = null;
        this.cmTagTypeScopeWidget = null;
        this.cmRatingWidget = null;
        this.cmRatingTypeObjectTypeWidget = null;
        this.cmContractTerminationReasonTypeWidget = null;
    }

    refreshCmContractTerminationReasonTypeWidget(filter: FilterCriteria[]) {
        this.cmContractTerminationReasonTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.termination.reason.type.widget.simple",
            'Stornogrund',
            'table',
            'list',
            'entity',
            'cm.ContractTerminationReasonType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractCategoryTypeWidget() {
        this.cmContractCategoryTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.category.type.widget.simple",
            'Vertragskategorie',
            'table',
            'list',
            'entity',
            'cm.ContractCategoryType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractTypeWidget(filter: FilterCriteria[]) {
        this.cmContractTypeWidget = WidgetFactory.createWidgetList(
            "cm.contract.type.widget.simple",
            'Vertragstyp',
            'table',
            'list',
            'entity',
            'cm.ContractType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 10)),
            WidgetDataParam.create('rowSelectionMode', 'sticky')
        );
        this.cmContractTypeWidget.functionCallbacks = this.categoryWidgetFunctionCallback();

    }

    refreshCmProductWidget(filter: FilterCriteria[]) {
        this.cmProductWidget = WidgetFactory.createWidgetList(
            "cm.product.widget.simple",
            'Product',
            'table',
            'list',
            'entity',
            'cm.Product',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmProductWidget.functionCallbacks = this.categoryWidgetFunctionCallback();
    }

    refreshCmContractTypeFieldWidget(filter: FilterCriteria[]) {
        this.cmContractTypeFieldWidget = WidgetFactory.createWidgetList(
            "cm.contract.type.field.widget.simple",
            'Contract Type Field',
            'table',
            'list',
            'entity',
            'cm.ContractTypeField',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }

    refreshCmContractTypeAmountTypeWidget(filter: FilterCriteria[]) {
        this.cmContractTypeAmountTypeFieldWidget = WidgetFactory.createWidgetList(
            "cm.contract.type..amount.type.field.widget.simple",
            'Contract Type Amount Type',
            'table',
            'list',
            'entity',
            'cm.ContractTypeAmountType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.uiWidgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateSelect(event: ObjectIdentifierData) {
        this.objectType = event.objectType;
        this.objectId = event.objectId;
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

    handleCategorySelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('category', FilterCriteria.cOperatorEqual, event.objectId)];
        this.categoryId = event.objectId;
        this.refreshCmGroupWidget(filter);
        this.refreshCmGroupAmountTypeWidget(null);
        this.refreshCmProductWidget(filter);
        this.refreshCmContractTypeWidget(filter);
        this.cmContractTypeFieldWidget = null;
        this.cmContractTypeAmountTypeFieldWidget = null;
    }

    categoryWidgetFunctionCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['categoryDtoId'] = this.categoryId;

                const formControlOverwrite = new MvsFormControlOverwrite();
                // formControlOverwrite.addField('categoryDtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    tagTypeScopeCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['tagTypeDtoId'] = this.tagTypeId;

                const formControlOverwrite = new MvsFormControlOverwrite();

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    ratingTypeWidgetFunctionCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['ratingTypeDtoId'] = this.ratingTypeId;

                const formControlOverwrite = new MvsFormControlOverwrite();

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    // cmContractBaseTypeWidgetFunctionCallback() {
    //     return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
    //             const dto = new DtoDetail();
    //             dto['Vertragsart'] = this.contractTypeId;
    //
    //             const formControlOverwrite = new MvsFormControlOverwrite();
    //
    //             let test: WidgetToolbarCallbackInterface;
    //             test = {
    //                 dto: dto,
    //                 formControlOverwrite: formControlOverwrite
    //             };
    //             return test;
    //         }};
    // }

    handleContractTypeSelect(event: ObjectIdentifierData) {
        const filterTypeWidget = [FilterCriteria.create('type', FilterCriteria.cOperatorEqual, event.objectId)];
        const filterContractTypeAmountTypeWidget = [FilterCriteria.create('contractType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshCmContractTypeFieldWidget(filterTypeWidget);
        this.refreshCmContractTypeAmountTypeWidget(filterContractTypeAmountTypeWidget);
    }

    handleRatingTypeSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('ratingType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.ratingTypeId = event.objectId;
        this.refreshCmRatingWidget(filter);
        this.refreshCmRatingTypeObjectTypeWidget(filter);
    }

    handleTagTypeSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('tagType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.tagTypeId = event.objectId;
        this.refreshCmTagTypeScopeWidget(filter);
    }

    handleContractTerminationSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('contractTerminationType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshCmContractTerminationReasonTypeWidget(filter);
    }

    cmBaseCategoryId: number;

    handleCategoryBaseSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('category', FilterCriteria.cOperatorEqual, event.objectId)];
        this.cmBaseCategoryId = event.objectId;
        this.refreshCmContractBaseTypeWidget(filter, event.objectId);
        this.cmBaseTypeReasonWidget = null;
        this.cmBaseTypeTicketTypeWidget = null;
    }

    refreshCmContractBaseTypeWidget(filter: FilterCriteria[], cmBaseCategoryId: number) {
        const dto: any = {categoryDtoId: cmBaseCategoryId};
        this.cmBaseTypeWidget = WidgetFactory.createWidgetList(
            "cm.base.type.category.widget.simple",
            'Contract Base Type',
            'table',
            'list',
            'entity',
            'cm.ContractBaseType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20)),
        );
        this.cmBaseTypeWidget.functionCallbacks = this.genericFunctionCallback(dto);
    }

    genericFunctionCallback(object: any) {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                for (let key in object) {
                    dto[key] = object[key];
                }

                const formControlOverwrite = new MvsFormControlOverwrite();

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    handleContractBaseTypeSelect(event: ObjectIdentifierData) {
        this.selectedContractBaseTypeId = event.objectId;
        const filter = [FilterCriteria.create('baseType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshCmContractBaseTypeReasonWidget(filter, event.objectId);

        const filterTicketType = [FilterCriteria.create('contractBaseType', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshCmContractBaseTypeTicketTypeWidget(filterTicketType, event.objectId);
    }

    refreshCmContractBaseTypeReasonWidget(filter: FilterCriteria[], baseType: number) {
        const dto = {baseTypeDtoId: baseType};
        this.cmBaseTypeReasonWidget = WidgetFactory.createWidgetList(
            "cm.base.tpe.reason.category.widget.simple",
            'Contract Base Type Reason',
            'table',
            'list',
            'entity',
            'cm.ContractBaseTypeReason',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmBaseTypeReasonWidget.functionCallbacks = this.genericFunctionCallback(dto);
    }

    refreshCmContractBaseTypeTicketTypeWidget(filter: FilterCriteria[], contractBaseType: number) {
        const dto = {contractBaseTypeDtoId: contractBaseType};
        this.cmBaseTypeTicketTypeWidget = WidgetFactory.createWidgetList(
            "cm.base.tpe.ticket.type.widget.simple",
            'Automatische Tickets',
            'table',
            'list',
            'entity',
            'cm.ContractBaseTypeTicketType',
            'No records found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
        this.cmBaseTypeTicketTypeWidget.functionCallbacks = this.genericFunctionCallback(dto);
    }

    openObjectInNewTab(event: MouseEvent) {
        const navigationObject = MvsObjectNavigationEntry.createNavigationEntry('cm.ContractBaseType', this.selectedContractBaseTypeId, 'object', null, null, null, MvsObjectNavigationActionEnum.edit, 'side');
        this.navigationService.openObjectInNewTab(navigationObject);
    }
}


