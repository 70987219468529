import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cc-entity-overview-page',
  templateUrl: './entity-overview.page.html',
  styleUrls: ['./entity-overview.page.scss']
})
export class EntityOverviewPage extends PageComponent implements OnInit {

  defaultLabel: string = "Entity Overview Page";

  constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
