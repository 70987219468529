import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {UiPageVisitRequestDto} from "../../dto/ui-page-visit-request.dto";
import {UiPageTypeEnum} from "../../enum/ui-page-type.enum";
import {UiPageVisitResponseDto} from "../../dto/ui-page-visit-response.dto";

@Injectable({
  providedIn: 'root'
})
export class UiPageUserFavoriteService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiPageUserFavorites')
  }

  public getFavorites() : Observable<UiPageVisitResponseDto[]> {

    const url = `${this.apiUrl}/userFavorites`;
    return this.http.get<UiPageVisitResponseDto[]>(url);

  }

}
