
<mvs-dm-upload
        *ngIf="ticketDto && ticketDto?.typeDto && objectIdentifier"
        [objectIdentifier]="objectIdentifier"
        [documentTypeId]="ticketDto?.typeDto?.dmDocumentTypeDtoId"
        [isDocumentTypeSelectionMode]="true"
        [showDocumentTypeDropdown]="false"
        layoutVariation="s-version"
></mvs-dm-upload>

<mvs-dm-object-documents *ngIf="objectIdentifier"
                         [objectIdentifier]="objectIdentifier">
</mvs-dm-object-documents>
