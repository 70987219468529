import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {WfProcessTypeStepDto} from "../../../dto/wf-process-type-step.dto";
import {WfProcessTypeStepActivityDto} from "../../../dto/wf-process-type-step-activity.dto";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WfProcessRuntimeDto} from "../../../service/dto/wf-process-runtime.dto";
import {WfProcessMetaDto} from "../../../service/dto/wf-process-meta.dto";
import {WfProcessRuntimeBindingDto} from "../../../service/dto/wf-process-runtime-binding.dto";
import {WfActivityFormDefaultDto} from "../../../service/dto/wf-activity-form-default.dto";
import {WfProcessStepActivityDto} from "../../../dto/wf-process-step-activity.dto";

@Component({
    selector: 'mvs-process-step-activity-side-base',
    template: 'about:blank'
})
export class WfProcessStepActivitySideBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() processRuntime: WfProcessRuntimeDto;
    @Input() processMeta: WfProcessMetaDto;

    @Input() stepType: WfProcessTypeStepDto;
    @Input() activityType: WfProcessTypeStepActivityDto;

    @Input() bindings: WfProcessRuntimeBindingDto[];
    @Input() formDefaults: WfActivityFormDefaultDto[];

    @Input() optionalActivityTypes: WfProcessTypeStepActivityDto[];

    @Output() onAction: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    constructor(protected coreService: MvsCoreService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
