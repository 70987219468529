import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DtoTemplate} from "@kvers/alpha-core-common";
import {DtoHistoryRequest} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {forkJoin} from 'rxjs';
import {MvsCrudObjectAssignment} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnChanges, OnDestroy {

    @Input() multiHistory: MvsCrudObjectAssignment[] = [];


    @Input() referenceId: number;
    @Input() serviceInterface: MvsCrudService;


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    multiEvents: any[] = [];
    events: any;
    differences: any[] = [];
    template: any[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshTemplate();

        this.initialized = true;
    }

    refreshTemplate() {
        let dtoDetail = new DtoTemplate;

        let templateInterfaces: MvsCrudService[] = [];

        // calculate required templates
        const objectEntries = this.getObjectInterfaces();

        for (let objectEntry of objectEntries) {
            if (!templateInterfaces.find(value => objectEntry.serviceInterface.objectType == value.objectType)) {
                templateInterfaces.push(objectEntry.serviceInterface);
            }
        }

        let forkStream = [];

        objectEntries.forEach((element,) => {
            forkStream.push(element.serviceInterface.template(dtoDetail));
        })

        // FORKJOIN retrieve Templates

        forkJoin(
            forkStream
        ).subscribe(
            (value) => {

                value.forEach((element: any) => {
                    if (element && element.formFields) {
                        this.template.push(element.formFields);
                        // this.template = element.formFields;

                    }
                })
                this.refreshChangeHistory();
            }
        );
    }

    getObjectInterfaces(): MvsCrudObjectAssignment[] {
        let objectEntries: MvsCrudObjectAssignment[] = null;

        if (this.multiHistory.length) {
            objectEntries = this.multiHistory;
        } else {
            const mvsCrudObjectAssignment = new MvsCrudObjectAssignment();
            mvsCrudObjectAssignment.referenceId = this.referenceId;
            mvsCrudObjectAssignment.serviceInterface = this.serviceInterface;

            objectEntries = [mvsCrudObjectAssignment];
        }

        return objectEntries;
    }

    refreshChangeHistory() {
        const objectEntries = this.getObjectInterfaces();

        let forkStream = [];

        objectEntries.forEach(element => {
            const dtoHistoryRequest: DtoHistoryRequest = new DtoHistoryRequest(element.referenceId, 0, 20);
            forkStream.push(element.serviceInterface.history(dtoHistoryRequest));
        })

        forkJoin(forkStream).subscribe((value: any) => {
                value.forEach(
                    (element: any) => {
                        this.multiEvents.push(element.historyEntries)
                    }
                )

                if (this.multiEvents.length) {
                    this.multiEvents.forEach((el, index) => {
                        for (let i = 0; i < this.multiEvents[index]?.length - 1; i++) {
                            this.compareObjects(this.multiEvents[index][i].dtoDetail, this.multiEvents[index][i + 1].dtoDetail, index);
                        }
                    });
                }
            }
        );
    }

    compareObjects(obj1: object, obj2: object, index: number) {
        let result: any = {};
        for (let key in obj2) {

            if (key === "createdDate" || key === "lastModifiedDate") {
                continue;
            }

            if (obj2[key] != obj1[key]) {
                result[key] = {
                    'old': obj1[key],
                    'new': obj2[key]
                }
            }
        }

        const resultCheck = Object.keys(result).map((key) => {

            const templateElement: MvsFormFieldDto = this.template[index][key];
            let oldValue = result[key]['old'];
            let newValue = result[key]['new'];
            let label = this.template[index][key]?.uiLabel;

            // check whether value list exists
            if (templateElement?.valueList && templateElement.valueList?.entries) {
                if (templateElement.valueList.entries[oldValue]) {
                    oldValue = templateElement.valueList.entries[oldValue].label;
                }
                if (templateElement.valueList.entries[newValue]) {
                    newValue = templateElement.valueList.entries[newValue].label;
                }
            }

            return {'field': label, 'old': oldValue, 'new': newValue}
        });

        resultCheck['lastModifiedDate'] = obj2["lastModifiedDate"];
        this.differences.push(resultCheck);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
