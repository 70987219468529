import {Component, OnInit} from "@angular/core";
import {ObjectBaseModeComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FieldTypeDto} from "../../../../dto/field-type.dto";

@Component({
    selector: 'field-type-object-base',
    template: '',
})

export class FieldTypeBaseComponent extends ObjectBaseModeComponent implements OnInit {

    basicWidgetData: WidgetData;
    dto: FieldTypeDto;


    ngOnInit() {

        if (this.dto.hasValueList) {
            this.navigationItems.push(
                {
                    label: 'valueList',
                    action: 'valueList',
                    icon: 'fa-solid fa-list',
                    tooltip: 'Value List',
                    default: false
                })
        }

        if (this.navigationItems?.length) {
            this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            this.onNavigationItems.emit(this.navigationItems);
        }

        super.ngOnInit();

        this.refreshComponent();
    }

    refreshComponent() {
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.basicWidgetData = WidgetFactory.createWidgetEntityData(
            'cc.field.type.basic.widget.data',
            'Field Type',
            'cc.FieldType',
            this.objectIdentifier?.objectId
        );

    }
}