@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
        <date-selection-dropdown
                [defaultOption]="'thisMonth'"
                (selectedDateStart)="handlePeriodFilter($event)"
        ></date-selection-dropdown>
    </div>

    <div class="grid dashboard">

        <div class="col-12 md:col-6 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="channelTypeContactTypeHistoryWidget"
                        [widgetData]="channelTypeContactTypeHistoryWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 md:col-3 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="channelTypeInternalTypeWidget"
                        [widgetData]="channelTypeInternalTypeWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 md:col-3 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="channelTypePriorityWidget"
                        [widgetData]="channelTypePriorityWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="recentlyAddedChannelsWidget"
                        [widgetData]="recentlyAddedChannelsWidget"
            ></mvs-widget>
        </div>


    </div>

}