import {Route, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';

import {AdSubRoutes} from "./ad/ad.route";
import {AmSubRoutes} from "./am/am.route";
import {AcSubRoutes} from "./ac/ac.route";
import {AsSubRoutes} from "./as/as.route";
import {CcSubRoutes} from "./cc/cc.route";
import {CdSubRoutes} from "./cd/cd.route";
import {CfSubRoutes} from "./cf/cf.route";
import {CmSubRoutes} from "./cm/cm.route";
import {CiSubRoutes} from "./ci/ci.route";
import {CoSubRoutes} from "./co/co.route";
import {CrSubRoutes} from "./cr/cr.route";
import {DmSubRoutes} from "./dm/dm.route";
import {EuSubRoutes} from "./eu/eu.route";
import {ImSubRoutes} from "./im/im.route";
import {NsSubRoutes} from "./ns/ns.route";
import {OmSubRoutes} from "./om/om.route";
import {OsSubRoutes} from "./os/os.route";
import {PmSubRoutes} from "./pm/pm.route";
import {RpSubRoutes} from "./rp/rp.route";
import {TmSubRoutes} from "./tm/tm.route";
import {UiSubRoutes} from "./ui/ui.route";
import {SmSubRoutes} from "./sm/sm.route";
import {WfSubRoutes} from "./wf/wf.route";
import {UmSubRoutes} from "./um/um.route";
import {TestSubRoutes} from "./test/test.route";
import {CoreSubRoutes} from "./core/core.route";
import {TeSubRoutes} from "./te/te.route";
import {XxSubRoutes} from "./xx/xx.route";
import {XrSubRoutes} from "./xr/xr.route";
import {PcSubRoutes} from "./pc/pc.route";
import {WsSubRoutes} from "./ws/ws.route";
import {SiSubRoutes} from "./si/si.route";
import {IgSubRoutes} from "./ig/ig.route";
import {XuSubRoutes} from "./xu/xu.route";
import {CsSubRoutes} from "./cs/cs.route";
import {authGuard} from "@kvers/alpha-auth";
import {XhSubRoutes} from "./xh/xh.route";
import {HomePagePage} from "./core/home-page/home-page.page";
import {LgSubRoutes} from "./lg/lg.route";
import {JbSubRoutes} from "./jb/jb.route";
import {HcSubRoutes} from "./hc/hc.route";
import {BmSubRoutes} from "./bm/bm.route";
import {CpSubRoutes} from "./cp/cp.route";
import {HbSubRoutes} from "./hb/hb.route";
import {EmSubRoutes} from "./em/em.route";
import {HmSubRoutes} from "./hm/hm.route";
import {HgSubRoutes} from "./hg/hg.route";
import {LmSubRoutes} from "./lm/lm.route";


//import {UiComponentBuilderComponent} from "./core/uiComponentBuilder/ui-component-builder/ui-component-builder.component";

// see https://learn.microsoft.com/de-de/azure/active-directory/develop/tutorial-v2-angular-auth-code

export function getMvsaRouters() : {name: string, routeMethod: () => Routes}[] {

    const modules : {name: string, routeMethod: () => Routes}[] =
        [
            {name : "core", routeMethod: CoreSubRoutes},
            {name : "ad", routeMethod: AdSubRoutes},
            {name : "ac", routeMethod: AcSubRoutes },
            {name : "am", routeMethod: AmSubRoutes },
            {name : "as", routeMethod: AsSubRoutes },
            {name : "bm", routeMethod: BmSubRoutes },
            {name : "cc", routeMethod: CcSubRoutes },
            {name : "cs", routeMethod: CsSubRoutes },
            {name : "cd", routeMethod: CdSubRoutes },
            {name : "cf", routeMethod: CfSubRoutes },
            {name : "cm", routeMethod: CmSubRoutes },
            {name : "ci", routeMethod: CiSubRoutes },
            {name : "co", routeMethod: CoSubRoutes },
            {name : "cp", routeMethod: CpSubRoutes },
            {name : "cr", routeMethod: CrSubRoutes },
            {name : "dm", routeMethod: DmSubRoutes },
            {name : "eu", routeMethod: EuSubRoutes },
            {name : "em", routeMethod: EmSubRoutes },
            {name : "ig", routeMethod: IgSubRoutes },
            {name : "hb", routeMethod: HbSubRoutes },
            {name : "lm", routeMethod: LmSubRoutes },
            {name : "hm", routeMethod: HmSubRoutes },
            {name : "hc", routeMethod: HcSubRoutes },
            {name : "hg", routeMethod: HgSubRoutes },
            {name : "im", routeMethod: ImSubRoutes },
            {name : "lg", routeMethod: LgSubRoutes },
            {name : "jb", routeMethod: JbSubRoutes },
            {name : "ns", routeMethod: NsSubRoutes },
            {name : "om", routeMethod: OmSubRoutes },
            {name : "os", routeMethod: OsSubRoutes },
            {name : "pc", routeMethod: PcSubRoutes },
            {name : "pm", routeMethod: PmSubRoutes },
            {name : "rp", routeMethod: RpSubRoutes },
            {name : "sm", routeMethod: SmSubRoutes },
            {name : "tm", routeMethod: TmSubRoutes },
            {name : "si", routeMethod: SiSubRoutes },
            {name : "te", routeMethod: TeSubRoutes },
            {name : "ui", routeMethod: UiSubRoutes },
            {name : "um", routeMethod: UmSubRoutes },
            {name : "wf", routeMethod: WfSubRoutes },
            {name : "ws", routeMethod: WsSubRoutes },
            {name : "xr", routeMethod: XrSubRoutes },
            {name : "xx", routeMethod: XxSubRoutes },
            {name : "xh", routeMethod: XhSubRoutes },
            {name : "xu", routeMethod: XuSubRoutes },
            {name : "test", routeMethod: TestSubRoutes },
        ];

    return modules;

}


function collectSubRoutes(): Route[] {

    let routes : Route[] =     [

        {path: '', component: HomePagePage},
    ];

    // collect all routes
    const mvsaRouters = getMvsaRouters();

    if (mvsaRouters) {
        for (let mvsaRouter of mvsaRouters) {

            const subRoutes = mvsaRouter.routeMethod();

            if (subRoutes) {
                for (let subRoute of subRoutes) {

                    // add guard
                    if (subRoute.canActivate) {
                        subRoute.canActivate.push(authGuard);
                    } else {
                        subRoute.canActivate = [authGuard];
                    }


                    routes.push(subRoute);
                }
            }

        }
    }

    // import {AdSubRoutes} from "./ad/ad.route";


    return routes;

}



const routes: Routes =
    [
        {
            path: '', component: AppMainComponent,
            children: collectSubRoutes()
        },

        {path: '**', redirectTo: '/notfound'},
    ];

const isIframe = window !== window.parent && !window.opener;


@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                scrollPositionRestoration: 'disabled',
                // Don't perform initial navigation in iframes or popups
                // initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
            }
            )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {


}
