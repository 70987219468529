import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-ns-notification-type-page',
  templateUrl: './ns-white-list.page.html'
})
export class NsWhiteListPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Notification White List";


  ngOnInit(): void {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'ns.NsRecipientWhiteList',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "White List",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
      ]
    };


  }
}
