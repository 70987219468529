import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem, MessageService, TreeNode} from 'primeng/api';
import {MvsMessageService} from "@kvers/alpha-core-common";
import {TeVariableTypeEnum} from '../../enum/te-variable-type.enum';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {firstValueFrom} from 'rxjs';
import {TeVariableComponentBase} from '../../logic/te-variable-component-base';
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {OverlayPanel} from "primeng/overlaypanel";
import {DataTypeHelper} from "@kvers/alpha-core-common";
import {IconClassHelper} from "@kvers/alpha-core-common";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {TeContentProviderVariableDto} from "../../service/api/dto/te.content-provider-variable.dto";
import {TeVariableProviderService} from "../../service/api/interfaces/te-variable-provider.service";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {TeTemplateVariableDto} from "../../dto/te-template-variable.dto";

@Component({
    selector: "mvs-te-variables",
    templateUrl: "./te-variables.component.html",
    styleUrls: ["./te-variables.component.scss"],
})
export class TeVariablesComponent
    extends TeVariableComponentBase
    implements OnInit, OnChanges, OnDestroy {

    busy: boolean; // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    // @Input() height: number = 300;

    // When add button is active, then the user is able to add variable.
    @Input() isAddButtonShown?: boolean = true;
    // When title is active, then the title will be shown on the header.
    @Input() isTitleShown?: boolean = true;
    // we can pass the tree width for required width.
    @Input() treeWidth?: boolean = false;
    // When drag mode is active, then the user is able to drag one variable into the editor.
    @Input() dragMode?: boolean = true;
    /**
     * When select mode is active, then the user is able to select one variable. Whenever
     * a variable is selected then the event onVariableSelect will be emitted.
     */
    @Input() selectMode?: string = null;

    @Output() onVariableSelect: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("simpleVariable", {static: false}) simpleVariablePanel: OverlayPanel;
    @ViewChild("objectVariable", {static: false}) objectVariablePanel: OverlayPanel;
    objectTypeService: ObjectTypeService;
    objectTypes: ObjectTypeDto[];
    //nodes: TreeNode[];

    teVariableTypeEnum: typeof TeVariableTypeEnum = TeVariableTypeEnum;

    dataTypes = [
        {name: 'string'}, {name: 'number'}
    ]
    variableName: string;
    variableAlias: string;

    selectedVariableType: { name: string };

    selectedObjectType: ObjectTypeDto;
    variables: TreeNode[] = [];
    selectionNode: TreeNode;
    configurationItems: MenuItem[] | undefined;
    visible: boolean;
    variableToggle: boolean;
    displayTechnicalNames: boolean = false;

    constructor(
        protected coreService: MvsCoreService,
        protected metaService: MetaService,
        protected messageService: MvsMessageService,
        protected toast: MessageService,
        protected objectService: MvsUiObjectService,
        protected confirmationService: ConfirmationService) {
        super();
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.variableProviderService = <MvsCrudService & TeVariableProviderService>this.coreService.getCrudService(this.alias);
        this.objectTypeService = <ObjectTypeService>(this.coreService.getCrudService("cc.ObjectType"));

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getAllObjectTypes();
        this.refreshVariables();
        this.handleConfigurationItems();

        this.initialized = true;
    }


    getAllObjectTypes() {
        this.objectTypeService.list(
            new ObjectRequestList(false, [], [new Sorting("alias", true)])
        ).subscribe((res: DtoList<ObjectTypeDto>) => {
            this.objectTypes = res.entries;
            this.objectTypes.sort((a, b) => a.alias.localeCompare(b.alias));
        });
    }

    /**
     * Retrieve variables.
     */
    refreshVariables() {
        /*
        const dtoListRequest = new ObjectRequestList(
            false,
            [FilterCriteria.create(this.attributeName, FilterCriteria.cOperatorEqual, this.contentProviderId, null)],
            [new Sorting("lastModifiedDate", false)]
        );
         */

        this.variableProviderService.listVariables(this.contentProviderId).subscribe(value => {
            this.variables = value.map(variable => this.createRootNode(variable, ''));
        });

        /*
        this.variableProviderService.list(dtoListRequest).subscribe((response: any) => {
            this.nodes = response.entries;

            this.variables = response.entries.map((items: any) => this.createNode(items, ''));
        });
         */

    }

    /**
     * Create new variable.
     * @param type
     * @param objectType
     */
    createVariable(type: number, objectType: { name: string } & ObjectTypeDto) {
        this.visible = false;
        this.busy = true;
        const data: TeTemplateVariableDto = new TeTemplateVariableDto();
        data.name = this.variableName;
        data.type = type;
        data.multiple = false;
        data.optional = true;
        data['alias'] = this.variableAlias;

        data[`${this.attributeName}DtoId`] = this.contentProviderId;


        if (type == TeVariableTypeEnum.object) {
            data.objectTypeDtoId = objectType.id ? objectType.id : null;
            data.objectTypeDtoName = objectType.name ? objectType.name : null;
        }

        this.variableProviderService.create(data).subscribe(response => {

            this.busy = false;
            this.messageService.showSuccessMessage("Template", "Variable Created");
            this.variableName = '';

            this.refreshVariables();

        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Template", "Error occured");
        });
    }

    handleEditVariableName(id: number) {
        this.objectService.openObjectViaDialog(
            new DtoImportObjectContext([]),
            this.alias,
            id,
            null,
            false,
            false,
            () => {
                this.refreshVariables();
            },
            MvsCrudModeEnum.create
        );
    }

    handleDeleteVariableName(id: number): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteVariableName(id);
            },
            reject: () => {
            },
        });
    }

    deleteVariableName(id: number) {
        this.busy = true;
        this.variableProviderService.delete(id).subscribe(response => {

            this.busy = false;
            this.messageService.showSuccessMessage("Template", "Variable Deleted");

            this.refreshVariables();

        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Template", "Error occured during delete");
        });
    }

    createRootNode(nodeItem: TeContentProviderVariableDto, iconType: string): TreeNode {

        let expandedIcon: string;
        let collapsedIcon: string;

        switch (nodeItem.variableType) {
            case 'simple':
                expandedIcon = 'fa-thin fa-dice-one';
                collapsedIcon = 'fa-thin fa-dice-one';
                break;
            case 'object':
                expandedIcon = 'pi pi-folder-open';
                collapsedIcon = 'pi pi-folder';
                break;
            case 'system':
                expandedIcon = 'fa-thin fa-solar-system';
                collapsedIcon = 'fa-thin fa-solar-system';
                break;
        }


        let node: TreeNode = {
            label: nodeItem.name,
            data: nodeItem,
            expandedIcon: expandedIcon,
            collapsedIcon: collapsedIcon,
            leaf: true,
            children: [{}],
            type: 'actions'
        };

        return node;
    }


    createNode(nodeItem: any, iconType: string): TreeNode {

        // set label for node
        const label = (nodeItem.typeId || nodeItem.typeName) ? nodeItem.typeName : nodeItem.name;

        let icon;

        switch (iconType) {
            case 'joins':
                switch (nodeItem.joinRelationEnum) {
                    case MetaDataJoinRelationEnum.oneToOne: //'oneToOne':
                        icon = 'fa-regular fa-list';
                        break;
                    case MetaDataJoinRelationEnum.manyToOne: //'manyToOne':
                        icon = 'fa-regular fa-table-list';
                        break;
                    case MetaDataJoinRelationEnum.oneToMany: //'oneToMany':
                        icon = 'fa-regular fa-table-cells';
                        break;
                    default:
                        break;
                }
                break;
            case 'generic':
                icon = 'pi pi-th-large';
                break;
            default:
                icon = 'pi pi-folder';
                break;
        }
        const expandedIcon: string = icon == 'pi pi-folder' ? 'pi pi-folder-open' : icon;

        let node: TreeNode = {
            label: label,
            data: nodeItem,
            expandedIcon: expandedIcon,
            collapsedIcon: icon,
            leaf: true,
            children: [{}],
            type: 'actions'
        };

        return node;
    }


    nodeExpandOnMetaData(node: TreeNode, res: MetaDataEntityDto) {

        // declaring variables
        let childrens: any = [];
        let genericType: any;
        let joins: any;

        const attributes = res.attributes;

        // assigning node attributes
        const children = attributes.map((element: any) => {
            // returns attribute object
            return this.setAttributes(element);
        });
        // assigning node sub joins
        joins = res.joins.map((items: any) => this.createNode(items, 'joins'));

        // assigning node generic types if any
        if (res.genericType) {
            genericType = res.genericTypes.map((items: any) => this.createNode(items, 'generic'));
        }

        // merging/concating all attributes joins and generic types
        childrens = res.genericType ? childrens.concat(genericType, children, joins) : childrens.concat(children, joins);


        // assigning childrens to node
        node.children = childrens;
    }

    /**
     * Expand Nodes.
     * @param event
     */
    nodeExpand(event: any) {

        let attributes: any;

        if (event.node.data?.variableType == "system" && !event.node.data.objectTypeDtoId) {

            this.busy = true;

            const systemVariable = <TeContentProviderVariableDto>event.node.data;

            firstValueFrom(this.variableProviderService.expandSystemVariables(systemVariable.alias)).then(
                (res: MetaDataEntityDto) => {
                    this.nodeExpandOnMetaData(event.node, res);
                    this.busy = false;
                });

        } else if (event.node.data.objectTypeDtoId || event.node.data.joinObjectTypeId) {

            let id = event.node.data.objectTypeDtoId ? event.node.data.objectTypeDtoId : event.node.data.joinObjectTypeId;
            this.busy = true;

            firstValueFrom(this.metaService.getById(id, true, true, true, true, true)).then(
                (res: MetaDataEntityDto) => {

                    if (res.joins) {
                        res.joins.sort((a,b) => a.name.localeCompare(b.name));
                    }

                    this.nodeExpandOnMetaData(event.node, res);

                    this.busy = false;
                });

        } else if (event.node.data.typeId) {

            // assigning node attributes
            const children = event.node.data.attributes.map((element: any) => {
                // returns attribute object
                return this.setAttributes(element);
            });

            // assigning generic attributes to childrens in node
            event.node.children = children;

            this.busy = false;

        } else {

            // assigning simple object to variable type simple
            event.node.children = [{
                label: 'Simple Variable',
                data: event.node.data
            }]
            this.busy = false;
        }
    }

    setAttributes(nodeItem: any) {
        // Function for setting attributes in nodes

        const dataType = DataTypeHelper.getInternalDataTypeForFormField(nodeItem);
        const icon = IconClassHelper.getIconClass(dataType);
        // = this.mvsDataTypeIcons(dataType);

        const attribute = {
            // label: nodeItem.attributeName,
            label: nodeItem.label,
            data: nodeItem,
            collapsedIcon: icon,
            leaf: true
        };

        return attribute;
    }

    toggleNodeLabel() {
        this.displayTechnicalNames = !this.displayTechnicalNames;

        for (let node of this.variables) {
            if (node.data.variableType != "object" ) {
                //it's an attribute so we can skip it
                continue;
            }

            this.updateNodeLabels(node);

        }
    }

    updateNodeLabels(node: TreeNode) {

        // const skippedFields = ['simple', 'object', 'system'];

        if (Object.getOwnPropertyNames(node).length === 0 || node.data.dataType) {
            return;
        }

        node.label = this.displayTechnicalNames ? node.data.name : node.data.label;

        if (!node.label) {
            // double check if we don't have label attribute, so we assign name
            node.label = node.data.name;
        }

        if (node.children) {

            for (let item of node.children) {
                this.updateNodeLabels(item);
            }

        }
    }

    // private getCollapsedIcon(nodeItem: any): string {
    //     if (nodeItem.length > 0) {
    //         return this.mvsDataTypeIcons(nodeItem[0].icon);
    //     } else {
    //         return 'pi pi-stop';
    //     }
    // }

    // setAttributes(nodeItem: any) {
    //     // funtion for setting attributes in nodes
    //     const attribute = {
    //         label: nodeItem.attributeName,
    //         data: nodeItem,
    //         collapsedIcon: 'pi pi-stop',
    //         leaf: true
    //     }
    //
    //     return attribute;
    // }

    nodeSelect(selectedNode: any) {
        this.onVariableSelect.emit(selectedNode);
    }

    /**
     * slide menu configuration
     */
    handleConfigurationItems() {

        this.configurationItems = [
            {
                label: 'Simple Variable',
                icon: 'fa-solid fa-plus',
                command: (event: any) => this.toggleDialog(true)
            },
            {
                label: 'Object Variable',
                icon: 'fa-solid fa-plus',
                command: (event: any) => this.toggleDialog(false)
            }

        ];
    }

    toggleDialog(toggle: boolean) {
        this.visible = true;
        this.variableToggle = toggle;
    }

    onCancel() {
        this.visible = false;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

    handleVariableDialogHide() {
        this.variableAlias = null;
        this.variableName = null;
        this.selectedVariableType = null;
    }
}
