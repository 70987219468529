import {Component} from '@angular/core';
import { AppComponent } from './app.component';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html',
    styleUrls: ['./app.rightmenu.component.scss']
})
export class AppRightMenuComponent {
    constructor(public appMain: AppMainComponent, public app: AppComponent) {}

    copyToClipboard(text: string): void {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    copyRouteToClipboard() {
        const fullRoute = window.location.href;

        // Call the copyToClipboard method to copy the route to the clipboard
        this.copyToClipboard(fullRoute);
    }
}
