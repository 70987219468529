<ng-container *ngIf="initialized">
    <div (click)="handleBackToTicket(false)">
        <i class="fa-solid fa-chevron-left cursor"></i> Back To Ticket
    </div>
    <hr>

    <ng-container *ngIf="actionType == 'subTicket'">

    </ng-container>


    <ng-container *ngIf="actionType == 'predefined'">

        <tm-ticket-select-predefine-task
                [ticketId]="ticketId"
                [availableActionsResponse]="availableActionsResponse"
                (onCreateAction)="handleTicketActionsCreated($event)"
        ></tm-ticket-select-predefine-task>

    </ng-container>


    <ng-container *ngIf="actionType == 'individual' || showIndividual">

        <mvs-widget *ngIf="ticketActionWidget"
                    [widgetData]="ticketActionWidget"
                    (onChangedObject)="onCreateTicketAction($event)"
        >

        </mvs-widget>

    </ng-container>


</ng-container>