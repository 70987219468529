import {Component, OnInit} from '@angular/core';
import {MessageService, TreeDragDropService} from "primeng/api";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {AssessmentGroupService} from "../../../../cm/service/api/assessment-group.service";
import {MvsSearchService} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {CustomerContractService} from "../../../../cr/service/api/customer-contract.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {CustomerContactService} from "../../../../cr/service/api/customer-contact.service";
import {CustomerContactPhoneInfoDto} from "../../../../cr/service/dto/customer-contact-phone-info.dto";
import {CallService} from "../../../../pc/service/api/call.service";
import {NsNotificationTypeService} from "../../../../ns/service/api/ns-notification-type.service";
import {
  NsNotificationTypesForRecipientInfoDto
} from "../../../../ns/dto/service/ns-notification-types-for-recipient-info.dto";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {WidgetFactory} from "@kvers/alpha-ui";
import {AgentService} from "../../../../am/service/api/agent.service";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {NsNotificationService} from "../../../../ns/service/api/ns-notification.service";
import {NsNotificationSingleSendRequestDto} from "../../../../ns/dto/service/ns-notification-single-send-request.dto";
import {
  NsNotificationSendRequestRecipientDto
} from "../../../../ns/dto/service/ns-notification-send-request-recipient.dto";
import {NsNotificationSendRequestChannelDto} from "../../../../ns/dto/service/ns-notification-send-request-channel.dto";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-test-form',
  providers: [TreeDragDropService,MessageService],
  templateUrl: './test-marko.page.html',
  styleUrls: ['./test-marko.page.scss']
})
export class TestWorkflowPage extends PageComponent implements OnInit {

  step: number = 0;

  customerObjectIdentifier: ObjectIdentifier;
  relatedPersonObjectIdentifier: ObjectIdentifier;

  customerWidgetData: WidgetData;

  phoneInfo: CustomerContactPhoneInfoDto;

  widgetData: WidgetData;

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected assessService: AssessmentGroupService,
      protected searchService: MvsSearchService,
      protected agentService: AgentService,
      protected ticketService: TicketService,
      protected customerService: CustomerService,
      protected customerContractService: CustomerContractService,
      protected customerContactService: CustomerContactService,
      protected callService: CallService,
      protected documentService: DmDocumentService,
      protected notificationTypeService: NsNotificationTypeService,
      protected notificationService: NsNotificationService,
      protected processService: WfProcessService) {
    super(route, coreService);
  }

  formWidget: WidgetData;

  initComponent() {

    const importFields: MvsFormFieldDto[] = [];


    const formField: MvsFormFieldDto = new MvsFormFieldDto();

    formField.uiHidden = false;
    formField.id = "1500421DtoId";
    formField.dataType = "cr.Customer";
    formField.access = "UPDATE";
    formField.uiLabel = "Kunde";
    formField.relationship = "ManyToOne";

    importFields[formField.id] = formField;

    const formDto = new MvsFormDto();
    formDto.formFields = importFields;


    this.formWidget = null;
    this.formWidget = new WidgetData();
    this.formWidget.name = "Start Parameter";
    this.formWidget.idAlias = 'test.marko.123';

    this.formWidget.uiComponent = 'form';
    this.formWidget.dataProvider = 'transient';
    this.formWidget.dataSource = 'transient';
    this.formWidget.dataProviderObject = '';

    this.formWidget.setParamValue("size", "S");
    this.formWidget.setParamValue("form", formDto);


  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
    this.initialized = true;
  }


}
