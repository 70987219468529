<ng-container *ngIf="initialized">

    <ng-container *ngIf="displayValue == 'list'">
        <!--    THE FOLLOWING WIDGET WILL SHOW THE TABLE WRT GIVEN OBJECT-TYPE AND OBJECT-ID-->
        <mvs-widget
                *ngIf="widgetTableDocument"
                [widgetData]="widgetTableDocument"
                [widgetToolbarButtons]="widgetButtons"
                (onToolbarButtonClick)="handleButtonClick($event)"
                (onObjectSelect)="onObjectSelect($event['data'])"
                [uiStyle]="uiStyle">
            <ng-template
                let-object="object"
                mvs-widget-custom-field-directive
                area="columns"
                label="Action"
                fieldId="addButton"
            >
                <div class="flex gap-2">
                    <p-button
                            (click)="onObjectDelete(object['dto'].id, $event)"
                            icon="fa-regular fa-trash-alt"
                            styleClass="p-button-danger small-icon"
                    ></p-button>
<!--                    <p-button-->
<!--                            (click)="onObjectSelect(object['dto'])"-->
<!--                            icon="fa fa-eye"-->
<!--                    ></p-button>-->
                </div>
            </ng-template>
        </mvs-widget>
    </ng-container>

    <p-card *ngIf="displayValue == 'grid'">
        <div class="mb-4 flex justify-content-between">
            <span class="text-lg font-medium text-700">{{title}}</span>
            <p-button icon="fa fa-list" class="text-center" styleClass="p-button-link w-full h-1rem"
                      (click)="changeView('list')"></p-button>
        </div>

        <div class="grid">
            <section class="lg:col-3 col-12" *ngFor="let item of documentAssignmentList">
                <div class="card px-2">
                    <div class="flex justify-content-between p-2">
                        <h5 class="pdf-card-title text-sm">{{item['documentDto']['dmDocumentTypeDto'].name }}</h5>
                        <div class="flex gap-3">
                            <i
                                    class="pi pi-window-maximize cursor"
                                    (click)="onObjectSelect(item)"
                            ></i>
                            <i
                                    class="fa-regular fa-trash cursor"
                                    (click)="onObjectDelete(item.id, $event)"
                            ></i>
                        </div>
                    </div>
                    <div class="p-1">
                        <mvs-pdf-viewer [objectId]="item.documentDtoId" class="w-full"></mvs-pdf-viewer>
                    </div>
                </div>
            </section>
        </div>
    </p-card>


    <!--    THE FOLLOWING COMPONENT WILL RENDER A DOCUMENT IN DIALOG BOX WHEN AN OBJECT IS SELECTED IN TABLE-->
    <dm-dialog-box-object-document-component
            *ngIf="visible && documentObjectId"
            [visible]="visible"
            [documentObjectId]="documentObjectId"
            (dialogVisibleEvent)="visible = $event"
            [widthSize]="widthSize"
            [viewOptions]="viewOptions"
            [maximizable]="maximizable"
            [dialogDisplayMode]="dialogDisplayMode"
    ></dm-dialog-box-object-document-component>

</ng-container>
