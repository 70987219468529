import {WfProcessDto} from "../../dto/wf-process.dto";
import {WfProcessStepDto} from "../../dto/wf-process-step.dto";
import {WfProcessStepActivityDto} from "../../dto/wf-process-step-activity.dto";
import {WfProcessMetaDto} from "./wf-process-meta.dto";
import {WfProcessStepMetaDto} from "./wf-process-step-meta.dto";
import {WfProcessRuntimeBindingDto} from "./wf-process-runtime-binding.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfActivityFormDefaultDto} from "./wf-activity-form-default.dto";
import {AgentDto} from "../../../am/dto/agent.dto";
import {AgentPoolDto} from "../../../am/dto/agent-pool.dto";
import {WfProcessTypeStepDto} from "../../dto/wf-process-type-step.dto";
import {WfProcessTypeStepHintDto} from "../../dto/wf-process-type-step-hint.dto";

export class WfProcessRuntimeDto extends DtoDetail{

    public process: WfProcessDto;

    // only if meta data was requested
    public meta? : WfProcessMetaDto;
    public stepMeta? : WfProcessStepMetaDto;


    // the leading/ main activity is always the active activity of a step
    public activeStep: WfProcessStepDto;
    public activeStepActivity: WfProcessStepActivityDto;

    public activeStepAgent: AgentDto;
    public activeStepAgentPool: AgentPoolDto;

    public activeStepBindings: WfProcessRuntimeBindingDto[];
    public activeStepFormDefaults: WfActivityFormDefaultDto[];
    public activeStepHints: WfProcessTypeStepHintDto[];

    public static deriveBindings(processRuntime: WfProcessRuntimeDto, stepActivityId: number) : WfProcessRuntimeBindingDto[] {

        if (!processRuntime.activeStepBindings) {
            return null;
        }

        const bindings: WfProcessRuntimeBindingDto[] = [];

        for (let activeStepBinding of processRuntime.activeStepBindings) {
            if (activeStepBinding.processTypeStepActivity == stepActivityId) {
                bindings.push(activeStepBinding);
            }
        }

        return bindings;

    }

    public static deriveFormDefaults(processRuntime: WfProcessRuntimeDto, stepActivityId: number) : WfActivityFormDefaultDto[] {

        if (!processRuntime.activeStepFormDefaults) {
            return null;
        }

        const formDefaults: WfActivityFormDefaultDto[] = [];

        for (let formDefault of processRuntime.activeStepFormDefaults) {
            if (formDefault.processTypeStepActivity == stepActivityId) {
                formDefaults.push(formDefault);
            }
        }

        return formDefaults;

    }



}