import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TeTestCaseService} from "../../service/api/te-test-case.service";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {TeTemplateService} from "../../service/api/te-template.service";
import {TeTemplateGenerateResponseDto} from "../../dto/te-template-generate-response.dto";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TeTemplateVariableService} from "../../service/api/te-template-variable.service";
import {TeContentProviderVariableDto} from "../../service/api/dto/te.content-provider-variable.dto";
import {TeTestCaseVariableService} from "../../service/api/te-test-case-variable.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TeTestCaseDto} from "../../dto/te-test-case.dto";
import {TeTestCaseVariableDto} from "../../dto/te-test-case-variable.dto";
import {ObjectIdentifierWithId} from "@kvers/alpha-core-common";
import {TeVariableTypeEnum} from "../../enum/te-variable-type.enum";
import {DtoDetail} from "@kvers/alpha-core-common";

export type TeTestCaseComponentVariableWithMapping = TeContentProviderVariableDto & { useCaseVariable: TeTestCaseVariableDto | null};

export interface TestCaseChangeEvent {
  dto: DtoDetail,
  variables:  TeTestCaseComponentVariableWithMapping[]
}

@Component({
  selector: 'mvs-te-test-case',
  templateUrl: './te-test-case.component.html',
  styleUrls: ['./te-test-case.component.scss']
})
export class TeTestCaseComponent implements OnInit, OnChanges, OnDestroy {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  selectedTestCase: TeTestCaseDto;
  testCaseDtoList: DtoList;

  templateGenerateResponse: TeTemplateGenerateResponseDto;
  trustedGenerateResponseHtml: SafeHtml;

  occurredException: string;
  occurredExceptionDetails: string;

  templateGeneratePdfResponse: TeTemplateGenerateResponseDto;
  trustedGenerateResponsePdf: SafeHtml;

  testCaseVariables: TeTestCaseVariableDto[];
  variables: TeTestCaseComponentVariableWithMapping[];


  @Input() templateId: number;
  @Output() onSelectTestCase = new EventEmitter<TestCaseChangeEvent>;

  constructor(
      protected coreService: MvsCoreService,
      protected templateService: TeTemplateService,
      protected templateVariableService: TeTemplateVariableService,
      protected testCaseVariablesService: TeTestCaseVariableService,
      protected testCaseService: TeTestCaseService,
      public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.initComponent();
    this.refreshComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.refreshTestCases();

    if (this.templateId) {
      this.initialized = true;
    }

  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["templateId"]) {
      this.refreshComponent();
    }
  }

  /**
   * Refresh Test Cases.
   */
  refreshTestCases() {
    this.busy = true;

    // retrieve test cases
    this.testCaseService.list(ObjectRequestList.createSimple(DtoImportObjectContext.createEmpty())).subscribe(value => {
      this.testCaseDtoList = value;
      this.busy = false;
    });

    // store variables
    this.templateVariableService.listVariables(this.templateId).subscribe(value => {
      this.variables = value.map(entry => ({ ...entry, useCaseVariable: null}));
    });


  }

  /**
   * Generate Test.
   */
  onGenerate() {
    this.generateDocument(false);
  }

  generateDocument(generatePdf: boolean) {
    this.templateGenerateResponse = null;
    this.templateGeneratePdfResponse = null;
    this.occurredException = null;
    this.occurredExceptionDetails = null;

    const objectVariables: {[key: string]: ObjectIdentifierWithId} = {};
    const simpleVariables: {[key: string]: any} = {};

    // fill variables
    if (this.variables) {
      for (let variable of this.variables) {
        if (variable.useCaseVariable) {
          if (variable.variableType == "object") {
            objectVariables[variable.alias] = new ObjectIdentifierWithId(variable.useCaseVariable.objectTypeDtoId, null, variable.useCaseVariable.objectId);
          } else if (variable.variableType == "simple") {
            simpleVariables[variable.alias] = variable.useCaseVariable.simpleValue;
          }
        }
      }
    }

    this.busy = true;

    this.templateService.generateViaTestCase(
        this.templateId,
        this.selectedTestCase.id,
        generatePdf,
        objectVariables,
        simpleVariables).subscribe(value => {

          if (value.errors) {
            this.occurredException = value.exception;
            this.occurredExceptionDetails = value.exceptionDetails;
          } else {
            if (generatePdf) {
              this.templateGeneratePdfResponse = value;
              this.trustedGenerateResponsePdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.templateGeneratePdfResponse.document);
            } else {
              this.templateGenerateResponse = value;
              this.trustedGenerateResponseHtml = this.sanitizer.bypassSecurityTrustHtml(this.templateGenerateResponse.document);
            }
          }

          this.busy = false;
    });
  }

  onHideGeneratedTest() {
    this.templateGenerateResponse = null;
    this.trustedGenerateResponseHtml = null;
  }

  onHideGeneratedPdfTest() {
    this.templateGeneratePdfResponse = null;
    this.trustedGenerateResponsePdf = null;
  }

  onGeneratePdf() {
    this.generateDocument(true);
  }

  refreshTestCaseVariables() {
    this.busy = true;

    const dtoListRequest = new ObjectRequestList(
        false,
        [FilterCriteria.create('testCase', FilterCriteria.cOperatorEqual, this.selectedTestCase.id, null)],
        []
    );

    this.handleSelectTestCase();

    this.testCaseVariablesService.list(dtoListRequest).subscribe(res => {
      this.testCaseVariables = res.entries;
      this.refreshVariableMapping();
      this.busy = false;
    });

  }

  handleSelectTestCase() {
    const event: TestCaseChangeEvent = {
      dto: this.selectedTestCase,
      variables: this.variables
    }
    this.onSelectTestCase.emit(event);
  }

  /**
   * Combine information from the test case with the required import data of the template.
   */
  refreshVariableMapping() {

    if (!this.variables) {
      return;
    }

    for (let variable of this.variables) {

      // set tu null
      variable.useCaseVariable = null;

      if (!this.testCaseVariables) {
        continue;
      }


      for (let testCaseVariable of this.testCaseVariables) {

        // check whether alias is set
        if (testCaseVariable.alias && testCaseVariable.alias !== variable.alias) {
          continue;
        }

        // check whether types are matching
        if (variable.variableType == "object" && ( testCaseVariable.type != TeVariableTypeEnum.object) || variable.objectTypeDtoId != testCaseVariable.objectTypeDtoId){
          continue;
        } else if (variable.variableType == "simple" && testCaseVariable.type != TeVariableTypeEnum.simple){
          continue;
        }


        let isMatch: boolean = false;

        // check for perfect match (including alias)
        if (variable.variableType == "object") {
          if (variable.objectTypeDtoId && variable.objectTypeDtoId == testCaseVariable.objectTypeDtoId) {
            isMatch = true;
          }
        } else if (variable.variableType == "simple") {
          isMatch = true;
        }

        variable.useCaseVariable = testCaseVariable;

        if (testCaseVariable.alias) {
          // perfect match, we can stop any further processing
          break;
        }

      }

    }

  }

  onRefreshTestCases() {

    this.refreshTestCases();

  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }
}
