import {Component, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, Type, ViewChild} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {
  ObjectQuickViewBaseComponent
} from "@kvers/alpha-ui";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {Observable} from "rxjs";
import {WfProcessService} from "../../service/api/wf-process.service";


@Component({
  selector: 'ticket-object-quick-view',
  templateUrl: './wf-process-object-quick-view.component.html'
})
export class WfProcessObjectQuickViewComponent
                  extends ObjectQuickViewBaseComponent
                  implements OnInit, OnChanges {

  dto: WfProcessDto;
  crudService: WfProcessService;


  /**
   * Method called whenever the object changed.
   */
  onObjectChanged() {

    // perform UI specific tasks ...
  }

  /**
   * Change the observable.
   * @protected
   */
  protected getRetrieveObjectObservable() : Observable<any> {

    if(this.size && this.size == 'XL'){
      return  this.crudService.getProcessAndSteps(this.objectIdentifier.objectId, false);
    }

    return this.crudService.getProcessAndLastStep(this.objectIdentifier.objectId, false);
  }

}
