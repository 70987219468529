import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AgentService} from 'src/app/am/service/api/agent.service';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {TicketWatchListService} from "../../service/api/ticket-watch-list.service";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {AgentDto} from "../../../am/dto/agent.dto";
import {debounceTime} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";

@Component({
    selector: 'mvs-ticket-watchlist',
    templateUrl: './ticket-watchlist.component.html',
    styleUrls: ['./ticket-watchlist.component.scss']
})
export class TicketWatchlistComponent
    extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() ticketId: number;
    dtoListWatchlist: DtoList;
    agentList: AgentDto[] = [];
    serviceTicketWatchlist: TicketWatchListService;
    serviceAgent: AgentService;
    selectedAgents: AgentDto[] = [];
    panelOpened: boolean;

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // tickets watchlist (all assigned agents)
        this.serviceTicketWatchlist = <TicketWatchListService>this.coreService.getCrudService("tm.TicketWatchList");

        // available agents!
        this.serviceAgent = <AgentService>this.coreService.getCrudService("am.Agent");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // this.refreshAgentList();

        this.refreshWatchlist();

        this.initialized = true;
    }

    getTicketFilter(): FilterCriteria[] {
        return [FilterCriteria.create("ticket", FilterCriteria.cOperatorEqual, this.ticketId, null)];
    }


    /**
     * refresh agent list
     */
    refreshAgentList() {

        if (this.panelOpened) {
            return;
        }

        this.panelOpened = true;

        const objectRequestList = ObjectRequestList.createSimpleRequestList(false, ObjectRequestSimple.create(false, false, 0), [], [], null, null);

        this.serviceAgent.list(objectRequestList).subscribe(value => {

            this.agentList = <AgentDto[]>value.entries.map((element: any) => {
                    return {
                        name: element.name,
                        id: element.id
                    }
                }
            )

            this.refreshWatchlist();

        })
    }

    /**
     * refresh watch list
     */
    refreshWatchlist() {

        this.selectedAgents.length = null;
        // create list request
        const dtoListRequest = new ObjectRequestList(true, this.getTicketFilter(), [new Sorting("lastModifiedDate", false)]);

        this.serviceTicketWatchlist.list(dtoListRequest).subscribe(value => {
            this.dtoListWatchlist = value;
            // this.agentList = <AgentDto[]>this.dtoListWatchlist.entries;

            this.selectedAgents = <AgentDto[]>this.dtoListWatchlist.entries.map((element: any) => {
                    return {
                        name: element.agentDtoName,
                        id: element.agentDtoId
                    }
                }
            )

            if (!this.panelOpened) {
                const agents = [...this.selectedAgents]
                this.agentList = agents;
            }

        });
    }

    /**
     * add self to watchlist
     */
    addMeToWatchlist() {
        this.serviceAgent.me().subscribe(value => this.createWatchlistEntry(value));
    }

    /**
     * add agents to watchlist
     * @param agent
     */
    createWatchlistEntry(agent: DtoDetail) {
        this.busy = true;
        if (this.dtoListWatchlist.entries) {
            const agentAvailable = this.dtoListWatchlist.entries.find((element: any) => element.agentDtoId == agent['id']);
            if (agentAvailable) {
                this.removeWatchlistEntry(agentAvailable);
                return;
            }
        }
        const dtoDetail = new DtoDetail();

        dtoDetail["ticketDtoId"] = this.ticketId;
        dtoDetail["agentDtoId"] = agent["id"];

        this.serviceTicketWatchlist.create(dtoDetail).subscribe(value => {
            // refresh Ticket Watchlist
            // this.refreshAgentList();
            this.refreshWatchlist();
            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "Agent added");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })

    }

    /**
     * remove agents from watchlist
     * @param agent
     */
    removeWatchlistEntry(agent: DtoDetail) {
        this.busy = true;
        this.serviceTicketWatchlist.delete(agent['id']).subscribe(value => {
            // refresh Ticket Watchlist

            // this.refreshAgentList();
            this.refreshWatchlist();
            this.busy = false;

            this.messageService.showSuccessMessage("Ticket", "Agent removed");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })

    }

    searchObservable: Subscription;
    searchInputSubject = new Subject<string>();

    // filterAgentList(event) {
    //     const filterBy = event.filter;
    //
    //     if (!filterBy) {
    //         this.searchObservable.unsubscribe();
    //         return;
    //     }
    //
    //     if (this.searchObservable) {
    //         this.searchObservable.unsubscribe();
    //     }
    //     this.searchObservable = this.searchInputSubject
    //         .pipe(
    //             debounceTime(500)
    //         )
    //         .subscribe(async () => {
    //
    //             const agents = [...this.selectedAgents]
    //             this.agentList = agents;
    //
    //             const filterCriteria: FilterCriteria = FilterCriteria.create('name', FilterCriteria.cOperatorContainsPattern, '%' + filterBy + '%')
    //
    //             const objectRequestList = ObjectRequestList.createSimpleRequestList(false, ObjectRequestSimple.create(false, false, 0), [filterCriteria], [], null, null);
    //
    //
    //             this.serviceAgent.list(objectRequestList).subscribe(value => {
    //
    //
    //                 // const list = this.dtoListAgents.entries.map((element: any) => {
    //                 //     return {name: element.name, id: element.id}
    //                 // })
    //
    //                 for (let listElement of value.entries) {
    //                     const found = this.selectedAgents.find(res => res.id == listElement.id);
    //
    //                     if (found) {
    //                         continue;
    //                     }
    //                     const element = <AgentDto>listElement;
    //
    //                     this.agentList.push(element);
    //                 }
    //
    //
    //                 this.refreshWatchlist();
    //
    //             })
    //
    //         });
    //     this.searchInputSubject.next(filterBy);
    //
    // }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
