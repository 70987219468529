

<div class="widget">
    <form [formGroup]="form">
        <p-dropdown [options]="emailAddresses" placeholder="Email" optionLabel="address" optionValue="address" formControlName="email"></p-dropdown>
        <p-dropdown [options]="phoneNumbers" placeholder="Telefonnummer" optionLabel="address" optionValue="address" formControlName="phone"></p-dropdown>
    </form>

    <p-button *ngIf="!userCreated" [disabled]="busy" label="Benuter anlegen" (onClick)="handleCreateUser()"></p-button>

</div>