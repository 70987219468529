import {Component, SimpleChanges} from '@angular/core';
import {
    MvsCoreService,
    MvsMessageService,
    ObjectBaseComponent,
    ObserverService, WidgetHelperButton
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {LogicImportService} from "../../../service/api/logic-import.service";
import {LogicExportService} from "../../../service/api/logic-export.service";
import {LogicDto} from "../../../dto/logic.dto";
import {LogicService} from "../../../service/api/logic.service";
import {LogicCheckSyntaxDto} from "../../../dto/logic-check-syntax.dto";
import {LogicCheckSyntaxResponseDto} from "../../../dto/logic-check-syntax-response.dto";
import {LogicLanguage} from "../../../enum/logic-language.enum";
import {LgLogicObjectInterface} from "./lg-logic-object.interface";

@Component({
    selector: 'mvs-lg-logic-object-component',
    templateUrl: './lg-logic-object.component.html'
})
export class LgLogicObjectComponent
    extends ObjectBaseComponent{


    dto: LogicDto;

    script: string;
    language: string;
    currentView: string = 'logic';
    activeTab: number = 0;

    syntaxResponse: LogicCheckSyntaxResponseDto;
    widgetButtons: WidgetHelperButton[];

    handleSidebarButtons() {

        this.widgetButtons = [
            {
                label: null,
                icon: 'fa-regular fa-square-code',
                display: true,
                action: 'logic',
                tooltip: 'Logic'
            }, {
                label: null,
                icon: 'fa-regular fa-flask-vial',
                display: true,
                action: 'testen',
                tooltip: 'Testen'
            },
            {
                label: null,
                icon: 'fa-regular fa-file-import',
                display: true,
                action: 'imports',
                tooltip: 'Imports',
            },
            {
                label: null,
                icon: 'fa-regular fa-file-export',
                display: true,
                action: 'exports',
                tooltip: 'Exports',
            }
        ];

    }

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected observerService: ObserverService,
                protected logicImportService: LogicImportService,
                protected logicExportService: LogicExportService,
                protected logicService: LogicService
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.handleSidebarButtons();
    }


    onObjectChanged() {


        this.setPageTitle(this.dto.name);

        this.script = this.dto.script;

        this.language = LogicLanguage[this.dto.logicLanguage];

    }


    checkSyntax() {
        const codeSyntaxDto: LogicCheckSyntaxDto = new LogicCheckSyntaxDto();

        codeSyntaxDto.logicId = this.objectIdentifier.objectId;
        codeSyntaxDto.logicLanguage = this.dto.logicLanguage;
        codeSyntaxDto.code = this.script;

        this.logicService.checkCodeSyntax(codeSyntaxDto).subscribe(checkSyntax => {
            this.syntaxResponse = checkSyntax;
        })
    }

    updateScript() {

        const logicDto: LogicDto = new LogicDto();
        logicDto.id = this.dto.id;
        logicDto.script = this.script;
        logicDto.logicLanguage = this.dto.logicLanguage;

        this.logicService.update(logicDto).subscribe((res: LogicDto) => {
            this.dto = res;
            this.messageService.showSuccessMessage('Update', 'Logic has benn updated.');
        });

    }

    onContentChange(content: string) {
        this.script = content;
    }

    handleLogicLanguageChange(event: LogicLanguage) {
        this.dto.logicLanguage = event;
    }

    setView(view: string) {
        this.currentView = view;
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

        // this.handleLogicVariables();
    }


}
