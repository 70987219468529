import {Route, Routes} from "@angular/router";
import {TicketTypePage} from "./page/overview/ticket-type/ticket-type.page";
import {TicketDashboardPage} from "./page/overview/ticket-dashboard/ticket-dashboard.page";
import { TicketDashboardMyTeamTicketsPageComponent } from "./page/overview/ticket-dashboard-my-team-tickets/ticket-dashboard-my-team-tickets.page";
import {TmTicketTypeDashboardPage} from "./page/overview/tm-ticket-type-dashboard/tm-ticket-type-dashboard.page";
import {TicketHistoryPage} from "./page/overview/ticket-history/ticket-history.page";
import {TmMeOverviewPage} from "./page/overview/me/tm-me-overview/tm-me-overview.page";
import {TmMeDashboardPage} from "./page/overview/me/tm-me-dashboard/tm-me-dashboard.page";
import {TicketOverviewPage} from "./page/ticket-overview-page/ticket-overview.page";
import {TicketManagementComponent} from "./component/ticket-management/ticket-management.component";
import {TicketControllingComponent} from "./component/ticket-controlling/ticket-controlling.component";

export function TmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'tm', component: TicketOverviewPage},
        {path: 'tm/config', component: TicketTypePage},

        {path: 'tm/me-old', component: TmMeOverviewPage},
        {path: 'tm/me/:id', component: TicketManagementComponent},
        {path: 'tm/me', component: TicketManagementComponent},
        {path: 'tm/me-dashboard', component: TmMeDashboardPage},

        {path: 'tm/team', component: TicketDashboardMyTeamTicketsPageComponent},

        {path: 'tm/dashboard', component: TicketDashboardPage},
        {path: 'tm/history', component: TicketHistoryPage},

        {path: 'tm/ticket-type/:id', component: TmTicketTypeDashboardPage},

        {path: 'tm/controlling', component: TicketControllingComponent,},



    ];

    return routes;

}
