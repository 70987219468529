<ng-container *ngIf="initialized">
    {{objectTypeDto.alias}}
    <!-- Create: Default -->
    <pm-person-search-component
            *ngIf="!objectId"
            [targetObjectType]="objectTypeDto.alias"
            (onPersonSelect)="onPersonSelect($event)"
            [createDefaultDto]="createDefaultDto"
            [formControlOverwrite]="formControlOverwrite"
            [createMode]="1"
    ></pm-person-search-component>

    <mvs-crud-object
            *ngIf="objectId"
            objectType="cr.Customer">

    </mvs-crud-object>

    <mvs-widget
            *ngIf="stepActivityMultiSelectWidget && activityType.activityObjectDto.processMultiple"
            [widgetData]="stepActivityMultiSelectWidget"
    ></mvs-widget>

    <div class="step-buttons"
         *ngIf="objectId">
        <div class="mx-2">
            <p-button
                      icon="pi pi-back"
                      [label]="'Back to create'" (click)="onBackToCreate()" [disabled]="busy"></p-button>
        </div>

    </div>

</ng-container>
