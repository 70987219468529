<ng-container *ngIf="initialized">

    <p-panel>

        <h4>Anonymize Data</h4>
        <p>Data anonymization is only possible within a development or test system.</p>

        <table>
            <tr>
                <th>Category</th>
                <th>Anonymize</th>
            </tr>
            <tr>
                <td>Persons</td>
                <td><p-inputSwitch [(ngModel)]="anonymizePersons"></p-inputSwitch></td>
            </tr>
            <tr>
                <td>Person Details</td>
                <td><p-inputSwitch [(ngModel)]="anonymizePersonDetails"></p-inputSwitch></td>
            </tr>
            <tr>
                <td>Contacts</td>
                <td><p-inputSwitch [(ngModel)]="anonymizeContacts"></p-inputSwitch></td>
            </tr>
            <tr>
                <td>Addresses</td>
                <td><p-inputSwitch [(ngModel)]="anonymizeAddresses"></p-inputSwitch></td>
            </tr>
            <tr>
                <td>History Tables (_AUD)</td>
                <td><p-inputSwitch [(ngModel)]="anonymizeAud"></p-inputSwitch></td>
            </tr>
            <tr>
                <td>Documents</td>
                <td><p-inputSwitch [(ngModel)]="anonymizeDocuments"></p-inputSwitch></td>
            </tr>
        </table>


        <p-button label="Anonymize Data" icon="fa-solid fa-bolt" (click)="onAnonymize($event)" [disabled]="busy"></p-button>

        <p-progressSpinner *ngIf="busy" styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>

    </p-panel>

    <br />

    <div class="grid">
        <div class="col-12">

            <mvs-widget [widgetData]="widgetData"></mvs-widget>

        </div>

    </div>


</ng-container>
