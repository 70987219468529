import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";

@Injectable({
    providedIn: 'root'
})
export class WfProcessTypeStepNextService extends MvsCrudService{

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/wf/wfProcessTypeStepNexts');
    }

}
