import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService, NavigationItem} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    CpProvisionAgentObjectComponent
} from "../../component/object-base/cp-provision-agent-object-component/cp-provision-agent-object.component";

@Injectable({
    providedIn: 'root'
})
export class ProvisionAgentService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cp/provisionAgents')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return CpProvisionAgentObjectComponent;
        }
        return null;
    }



}