import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AgentPoolService} from 'src/app/am/service/api/agent-pool.service';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {TicketService} from "../../../service/api/ticket.service";
import {DtoList} from "@kvers/alpha-core-common";
import {AgentPoolDto} from "../../../../am/dto/agent-pool.dto";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {PeriodHelper} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {
    MvsObjectNavigationProviderGeneric
} from "@kvers/alpha-ui";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ticket-dashboard-my-team-tickets-page',
    templateUrl: './ticket-dashboard-my-team-tickets.page.html',
    styleUrls: ['./ticket-dashboard-my-team-tickets.page.scss']
})

export class TicketDashboardMyTeamTicketsPageComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = 'My Team Tickets';
    agentPoolService: AgentPoolService;
    agentPoolDtoList: DtoList<AgentPoolDto>;
    widgetTickets: WidgetData;
    widgetTicketStatus: WidgetData;
    widgetTicketType: WidgetData;
    widgetTicketUrgency: WidgetData;
    widgetDataTicketStatusPeriod: WidgetData;
    widgetTicketTypeMeHistoryChart: WidgetData;
    filterCriteria: FilterCriteria[] = [];
    tabIndexCheck: boolean;
    tabIndex: number;

    constructor(
        protected route: ActivatedRoute,
        protected navigationService: MvsObjectNavigationService,
        protected coreService: MvsCoreService,
        protected ticketService: TicketService) {

        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        // retrieve agent pool service
        this.agentPoolService = <AgentPoolService>this.coreService.getCrudService("am.AgentPool");

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshAgentPool();

        this.initialized = true;
    }

    /**
     * function to call agent pool service and retrive loggedon agent
     */
    refreshAgentPool() {
        this.agentPoolService.my().subscribe(response => {
            this.agentPoolDtoList = response;

            this.uiWidgetRefreshTickets(true);
        });
    }

    /**
     * Refresh relevant Widget.
     * @param openTickets
     */
    uiWidgetRefreshTickets(openTickets: boolean) {

        let idAlias = "";
        const filterCriteriaList: FilterCriteria[] = [];

        // add all agent pools to filter
        if (!this.agentPoolDtoList || this.agentPoolDtoList.entries.length == 0) {
            return; // stop further processing no agent pool assignments found
        }

        // clear widget data
        this.widgetTickets = null;


        // add condition for agent pools
        filterCriteriaList.push(FilterCriteria.createOrFromArray("assigneeAgentPool", "id", this.agentPoolDtoList.entries));

        // adjust alias and filter depending whether open or closed tickets should be displayed
        if (openTickets) {
            idAlias = 'tm.dashboard.my.team.open.tickets';
            this.ticketService.includeTicketStatusOpenToFilter(filterCriteriaList)
        } else {
            idAlias = 'tm.dashboard.my.team.close.tickets';
            this.ticketService.includeTicketStatusClosedToFilter(filterCriteriaList)
        }

        if (this.filterCriteria && this.filterCriteria.length) {
            for (let criteria of this.filterCriteria) {
                filterCriteriaList.push(criteria);
            }
        }

        const widgetData: WidgetData = new WidgetData();
        // widgetTicketTableMe[index] = new WidgetData();
        widgetData.id = 1;
        widgetData.name = 'Team Tickets';
        widgetData.idAlias = idAlias;

        widgetData.uiComponent = 'table';
        widgetData.dataProvider = 'list';
        widgetData.dataSource = 'entity';
        widgetData.dataProviderObject = 'tm.Ticket';


        widgetData.dataProviderListRequest = TicketService.getComplexSelectionCustomerAndPerson(filterCriteriaList);

        widgetData.setParamValue("size", "S");

        this.widgetTickets = widgetData;


        //show Ticket statuses group by status
        this.widgetTicketStatus = this.createWidgetData(
            'tm.dashboard.my.team.status.distribution',
            'Status',
            'category',
            'status',
            'list',
            'entity.groupBy',
            'tm.Ticket',
            'status',
            ['status'],
            filterCriteriaList
        );

        this.widgetTicketType = this.createWidgetData(
            "tm.dashboard.my.team.tickets.type.distribution",
            "Types",
            "category",
            "type",
            "list",
            "entity.groupBy",
            'tm.Ticket',
            "type",
            ["type"],
            filterCriteriaList
        );

        //show Ticket urgency group by urgency
        this.widgetTicketUrgency = this.createWidgetData(
            'tm.dashboard.my.team.urgency.distribution',
            'Urgency',
            'category',
            'urgency',
            'list',
            'entity.groupBy',
            'tm.Ticket',
            'urgency',
            ['urgency'],
            filterCriteriaList
        );

        this.widgetDataTicketStatusPeriod = this.createWidgetData(
            'tm.dashboard.my.team.ticket.period',
            'Offen',
            'category.period',
            'createdDate',
            'list',
            'entity.groupBy',
            'tm.Ticket',
            'createdDate',
            ['createdDate'],
            filterCriteriaList
        );

    }

    refreshTicketHistoryWidget() {

        const filterCriteriaList: FilterCriteria[] = [];

        filterCriteriaList.push(FilterCriteria.createOrFromArray("assigneeAgentPool", "id", this.agentPoolDtoList.entries));

        this.widgetTicketTypeMeHistoryChart = this.createWidgetData(
            'tm.history.me.ticket.type.history',
            'Ticket History',
            'history.chart',
            'createdDate',
            'list',
            'entity.groupBy',
            'tm.Ticket',
            'type',
            ['type', 'createdDate'],
            filterCriteriaList
        );

        this.widgetTicketTypeMeHistoryChart.setParamValue("historyPeriodMode", "month");
        this.widgetTicketTypeMeHistoryChart.setParamValue("fieldDate", "createdDate");
    }

    /**
     * function to populate list widget with groupBy
     * @param idAlias
     * @param name
     * @param uiComponent
     * @param field
     * @param dataProvider
     * @param dataSource
     * @param dataProviderObject
     * @param groupByField
     * @param filterCriteriaList
     */
    createWidgetData(idAlias, name, uiComponent, field, dataProvider, dataSource, dataProviderObject, attributeName, groupByField: string[], filterCriteriaList): WidgetData {
        const widget = new WidgetData();
        widget.idAlias = idAlias;
        widget.name = name;
        widget.uiComponent = uiComponent;
        widget.dataProvider = dataProvider;
        widget.dataSource = dataSource;
        widget.dataProviderObject = dataProviderObject;

        const groupByAttribute: ObjectRequestListAttribute = new ObjectRequestListAttribute(attributeName, "Anzahl", DtoListAttributeRequestAggregateEnum.count);
        widget.dataProviderListRequest = ObjectRequestListGroupBy.create(true, filterCriteriaList, [], groupByField, [groupByAttribute]);

        widget.setParamValue("fieldCategory", attributeName);
        widget.setParamValue("fieldCategoryCount", `${attributeName}_count`);
        // widget.setParamValue("selectionMode", "single");
        widget.setParamValue("size", "S");

        return widget;
    }

    /**
     * set filter criteria
     * @param objectData
     * @param field
     */
    onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
        if (this.filterCriteria) {
            const found = this.filterCriteria.find(res => res.field == field);
            if (found) {
                return;
            }
        }
        let filterCriteria: FilterCriteria;
        if (field == 'createdDate') {
            const dates = PeriodHelper.getDateRangeForKey(objectData.objectId);
            const startDate = PeriodHelper.convertDateToApiDate(dates.startDate);
            const endDate = PeriodHelper.convertDateToApiDate(dates.endDate);
            filterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, startDate, endDate);
        } else {
            filterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        }

        filterCriteria.label = objectData.data.label;
        this.filterCriteria.push(filterCriteria);
        this.uiWidgetRefreshTickets(!this.tabIndexCheck)
    }

    /**
     * remove objects from filter criteria
     * @param filter
     */
    onRemoveFilterCriteria(filter: FilterCriteria) {
        let index = this.filterCriteria.findIndex(item => item.field === filter.field);

        if (index !== -1) {
            this.filterCriteria.splice(index, 1);
            this.uiWidgetRefreshTickets(!this.tabIndexCheck)
        }

    }

    /**
     * function called when tab view changes
     * @param event
     */
    changeTab(event) {
        this.tabIndex = event.index;
        if (event.index == 0) {
            this.tabIndexCheck = !!event.index;
            this.uiWidgetRefreshTickets(true);
        } else if (event.index == 1) {
            this.tabIndexCheck = !!event.index;
            this.uiWidgetRefreshTickets(false);
        } else if (event.index == 2) {
            this.refreshTicketHistoryWidget();
        }
    }

    /**
     * function to open ticket in sidebar
     * @param objectData
     */
    openTicketDrawer(objectData: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", objectData.objectId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
