import {Component, OnInit, SimpleChanges} from '@angular/core';
import {
  FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {PageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'cm-contract-search',
  templateUrl: './contract-search.page.html',
  styleUrls: ['./contract-search.page.scss']
})
export class ContractSearchPage extends PageComponent implements OnInit {

  objectType: string = 'cm.Contract';
  defaultLabel: string = "Contract Search";

  ngOnInit(): void {
    super.ngOnInit();
    this.initComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
    this.refreshComponent();
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  handleObjectSelect(event: FlexibleSearchDataEvent) {
    const contractId = event.id;
    const url = '/#/cm/contract/' + contractId;
    window.open(url, "_blank");
  }
}


