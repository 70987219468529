import { Injectable } from '@angular/core';
import {DtoDetail, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EuUserService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/eu/euUsers')
  }

  getPictureBinaryById(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}/photo/$`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getProfile(id: number): Observable<any> {
    const url = `${this.apiUrl}/profile/${id}`;
    return this.http.get(url);
  }
}