import {Component, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ContractStatusTypeEntryDto} from "../../../dto/contract-status-type-entry.dto";
import {ContractStatusInternal} from "../../../enum/contract-status-internal.enum";
import {ContractStatusTypeInternal} from "../../../enum/contract-status-type-internal.enum";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ContractStatusTypeService} from "../../../service/api/contract-status-type.service";
import {MvsDashboardPage} from "../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page";
import {ContractStatusService} from "../../../service/api/contract-status.service";
import {PeriodHelper} from "@kvers/alpha-core-common";
import {OptionGroups} from "../../../../core/dashboard/interfaces/mvs-dashboard-option-groups.interface";
import {PagingDto} from "@kvers/alpha-core-common";
import {ContractService} from "../../../service/api/contract.service";
import {GroupItem} from "../../../../core/dashboard/interfaces/mvs-dashboard-group-item.interface";
import {
    ObjectRequestEntityProcessingTypeEnum
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {OptionGroupPlacementEnum} from "../../../../core/dashboard/interfaces/option-group-placement.enum";
import {ContractStatusTypeEntryService} from "../../../service/api/contract-status-type-entry.service";
import {MvsMessageService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cm-contract-dashboard-status',
    templateUrl: '../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page.html',
    styleUrls: ['./cm-contract-dashboard-status.page.scss']
})
export class CmContractDashboardStatusPage
    extends MvsDashboardPage {

    defaultLabel: string = "Offene Statusübersicht";

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    statusTypeEntriesGroupedByStatusType: ContractStatusTypeEntryDto[] = [];


    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected contractStatusTypeService: ContractStatusTypeService,
        protected contractStatusService: ContractStatusService,
        protected contractService: ContractService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService
    ) {

        super(route, router, coreService, navigationService)
    }


    ngOnInit() {
        super.ngOnInit();
    }

    startInit() {
        // we are not calling the super implementation as we need to
        // retrieve some additional information upfront.
        this.loadContractTypes();
    }


    /**
     * Retrieves the relevant contract status type entries (which are not from the main status).
     * This call is only required once.
     */
    loadContractTypes() {

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,

            ObjectRequestComplex.build(false, false,
                ObjectRequestComplexNode.createRelationNode("contractStatusTypeEntriesDto",
                    ObjectRequestRelation.createList(
                        "cm.ContractStatusTypeEntry",
                        "contractStatusType",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                )
            ),
            [FilterCriteria.create('statusTypeInternal', FilterCriteria.cOperatorNotEqual, ContractStatusTypeInternal.main)],
            null,
            null,
            null
        );


        this.contractStatusTypeService.list(objectRequestList).subscribe(res => {

            this.statusTypeEntriesGroupedByStatusType = res.entries.reduce((acc, entry) =>
                acc.concat(entry['contractStatusTypeEntriesDto']), []);

            // now, we continue with the general lifecycle of the Dashboard
            this.initComponent();

        })
    }



    /**
     * Refreshes the component by fetching contract status types and setting the 'initialized' flag.
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getSubNavigationObservable() : Observable<any> {

        const filterCriteria = this.getTopNavigationFilter();

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [filterCriteria],
            [],
            ['statusType'],
            [new ObjectRequestListAttribute('statusType', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.contractStatusService.groupBy(objectRequestList);

    }

    /**
     * Implement logic to fill the following attributes:
     *
     *     mainSelections: MainSelection[]
     *     subSelections: SubSelection[]
     *     typeFilterSelections: GroupItem[]
     *
     * @param value
     */
    storeSubNavigation(value: any) {

        this.subSelections = [];

        this.subSelections = value.entries.map(obj => ({
            key: obj.statusTypeDtoId,
            label: obj.statusTypeDtoName,
            icon: obj['statusTypeDtoImage'],
            count: obj['statusType_count']
        }));

    }

    getOptionGroupsObservable() : Observable<any> {

        const filterCriteria = this.getOptionGroupsFilter();

        if (!filterCriteria) {
            return null;
        }

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [filterCriteria],
            [],
            ['statusTypeEntry'],
            [new ObjectRequestListAttribute('statusTypeEntry', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.latest
        );

        return this.contractStatusService.groupBy(objectRequestList);

    }

    storeOptionGroups(value: any) {

        const groupedItems = value.entries.map(obj => ({
            key: obj.statusTypeEntryDtoId,
            label: obj.statusTypeEntryDtoName,
            count: obj['statusTypeEntry_count']
        }));

        const totalCount = groupedItems.reduce((acc, item) => acc + item.count, 0);

        const groupItemAll = [{
            key: 0,
            label: 'All',
            count: totalCount
        }];

        const optionGroupAll: OptionGroups = {key: 0, label: 'All', groupItems: groupItemAll, placement : OptionGroupPlacementEnum.regular};
        const optionGroup: OptionGroups = {key: 1, label: 'Limited', groupItems: groupedItems, placement : OptionGroupPlacementEnum.regular};

        this.optionGroups = [optionGroupAll, optionGroup];


    }



    /**
     * Helper method to handle filter criteria based on statusTypeGroupByList and subSelectionKey.
     */
    private getTopNavigationFilter(): FilterCriteria {

        const filters: FilterCriteria[] = [];

        for (const item of this.statusTypeEntriesGroupedByStatusType) {
            if (item.statusInternal == ContractStatusInternal.active || item.statusInternal == ContractStatusInternal.pending) {
                filters.push(FilterCriteria.create("statusTypeEntry", FilterCriteria.cOperatorEqual, item.id))
            }
        }

        return FilterCriteria.createOr(...filters);

    }

    private getWidgetFilter(): FilterCriteria {
        const filters: FilterCriteria[] = [];

        for (const item of this.statusTypeEntriesGroupedByStatusType) {
            if (item.statusInternal == ContractStatusInternal.active || item.statusInternal == ContractStatusInternal.pending) {
                if (item.contractStatusTypeDtoId == Number(this.subSelectionKey)) {

                    if (this.optionGroupSelection.key == 0 || item.id == this.optionGroupSelection.key) {
                        filters.push(FilterCriteria.create("statusTypeEntry", FilterCriteria.cOperatorEqual, item.id))
                    }
                }
            }
        }

        if (filters.length == 0) {
            this.messageService.showErrorMessage("Error", "Keine Statuseinträge vorhanden!");
            return null;
        }

        return FilterCriteria.createOr(...filters);
    }

    private getOptionGroupsFilter(): FilterCriteria {

        const filters: FilterCriteria[] = [];

        for (const item of this.statusTypeEntriesGroupedByStatusType) {
            if (item.statusInternal == ContractStatusInternal.active || item.statusInternal == ContractStatusInternal.pending) {
                if (item.contractStatusTypeDtoId == Number(this.subSelectionKey)) {
                    filters.push(FilterCriteria.create("statusTypeEntry", FilterCriteria.cOperatorEqual, item.id))
                }
            }
        }

        if (filters.length == 0) {
            this.messageService.showErrorMessage("Error", "Keine Statuseinträge vorhanden!");
            return null;
        }

        return FilterCriteria.createOr(...filters);

    }

    /**
     * Refreshes the widget based on the provided filter criteria.
     */
    refreshWidget() {

        const filter = this.getWidgetFilter();

        //show contract status by category
        this.widgetData = WidgetFactory.createWidgetListComplex(
            "cm.contract.status.overview.dashbaord.show.contracts",
            "Status",
            "table",
            "list",
            "entity",
            "cm.ContractStatus",
            "Keine Daten vorhanden",

            ObjectRequestList.createWithPaging(
                true,
                [filter],
                [],
                PagingDto.create(0, 10),
                ObjectRequestEntityProcessingTypeEnum.latest),

            ObjectRequestComplex.build(
                false,
                false,
                ObjectRequestComplexNode.createSimpleAttributeNode('contract')
                    .addNodes(
                        ObjectRequestComplexNode.createRelationNode('currentCustomer', ObjectRequestRelation.createJoin('+currentMainCustomer'))
                            .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('person'))),
                ObjectRequestComplexNode.createSimpleAttributeNode('statusTypeEntry')
            ),

            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data")
        );

    }

    getMainNavigationObservable(): Observable<any> {
        //we are not using this method just overriding it
        return null
    }

    getTopRightNavigationObservable(): Observable<any> {
        //we are not using this method just overriding it
        return null
    }

    storeMainOptions(value: any): null {
        //we are not using this method just overriding it
        return null;
    }

    storeTopRightOptions(value: any): null {
        //we are not using this method just overriding it
        return null;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
