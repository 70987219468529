<ng-container *ngIf="initialized">

    <div class="surface-0 p-2" *ngIf="historyData && historyData.entries?.length">
        <!--        <div class="text-right mx-2 my-3">-->
        <!--            <p-button label="Detail View" class="ml-1"-->
        <!--                      [styleClass]="showDetailView ? '' : 'p-button-outlined'" (click)="handleToggleView()"></p-button>-->
        <!--        </div>-->

        @if (showDetailView == true) {

            <div (click)="handleBackNavigation()" class="pointer surface-50 p-2 border-round">
                <i class="fa-solid fa-chevron-left"></i> Back
            </div>

            <mvs-widget *ngIf="detailWidget" [widgetData]="detailWidget">

            </mvs-widget>

            <!--            @for (record of widgetFieldEntries; track record) {-->
                    <!--                <div class="mb-3 flex">-->
                    <!--                    <div class="flex flex-column align-items-center mt-1" style="width:2rem">-->

                    <!--                <span class="bg-primary-800 text-0 flex align-items-center justify-content-center border-round-lg p-2">-->
                    <!--&lt;!&ndash;                    <i class="fa-regular fa-circle-dot text-2xl"></i>&ndash;&gt;-->
                    <!--                      <i [pTooltip]="'Modified'" *ngIf="record.entry.revisionType == 'MOD'"-->
                    <!--                         class="fa-regular fa-pen text-xl cursor"></i>-->
                    <!--                                <i [pTooltip]="'Added'" *ngIf="record.entry.revisionType == 'ADD'"-->
                    <!--                                   class="fa-regular fa-plus text-xl cursor"></i>-->
                    <!--                                <i [pTooltip]="'Deleted'" *ngIf="record.entry.revisionType == 'DEL'"-->
                    <!--                                   class="fa-regular fa-trash text-xl cursor"></i>-->
                    <!--                </span>-->
                    <!--                        <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 4rem"></div>-->
                    <!--                    </div>-->
                    <!--                    <div class="ml-5 surface-card shadow-1 border-round p-3 flex-auto">-->
                    <!--                        <div class="mb-4 mt-2 flex justify-content-between mx-2">-->
                    <!--                            &lt;!&ndash;                        <span class="text-base text-color">Revison ID : {{ record.entry.revisionId }} </span>&ndash;&gt;-->
                    <!--                            <div class="flex align-items-center gap-2" pTooltip="Processed By">-->
                    <!--                                <mvs-form-control-output-user-image [value]="record.entry.processedBy"-->
                    <!--                                                                    [displayInfo]="true"-->
                    <!--                                >-->
                    <!--                                </mvs-form-control-output-user-image>-->
                    <!--&lt;!&ndash;                                <span class="font-medium text-sm text-700">{{ record.entry.entity.lastModifiedBy }}</span>&ndash;&gt;-->
                    <!--                            </div>-->
                    <!--                            <span class="text-color-secondary">{{ record.entry['uiTimestamp'] }}</span>-->
                    <!--                        </div>-->

                    <!--                        <h6 class="text-primary mt-2 mx-2">{{record.entry.objectAlias}}</h6>-->

                    <!--                        <div class="grid word-break">-->

                    <!--                            @for (field of record.formFields; track field) {-->
                    <!--                                <div [ngClass]="columns == 2 ? 'col-6' : 'col-12 px-0'">-->
                    <!--                                    <div class="grid mx-2"-->
                    <!--                                         [ngClass]=" field['uiFieldChanged'] ? 'bg-yellow-100 border-round' : ''">-->
                    <!--                                        <span class="text-color-secondary col-6">{{ field.id }}</span>-->
                    <!--                                        <span class="col-6">-->
                    <!--                                        <mvs-form-field-output-->
                    <!--                                                [value]="record.entry.entity[field.id]"-->
                    <!--                                                [fieldName]="field.uiOutputControl"-->
                    <!--                                                [formField]="field"-->
                    <!--                                        ></mvs-form-field-output>-->
                    <!--                                    </span>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            }-->

                    <!--                            &lt;!&ndash;                        <div class="col-6">&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <div class="grid">&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                <span class="text-color-secondary col-6">Processed By</span>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                <span class="text-color-secondary col-6">&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                   <div class="flex align-items-center gap-2">&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                       <p-avatar shape="circle"&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                                 image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"/>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                       <span class="font-medium text-sm text-700">Jacob Jones</span>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                   </div>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            </span>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            </div>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        </div>&ndash;&gt;-->

                    <!--                        </div>-->


                    <!--                    </div>-->
                    <!--                </div>-->
                    <!--            }-->


        } @else if (showDetailView == false) {

            @for (record of widgetFieldEntries; track record) {
                <div class="mb-3 flex pointer" (click)="handleOpenDetailView(record)">
                    <div class="flex flex-column align-items-center mt-1" style="width:2rem">
                        <span class="text-0 flex align-items-center justify-content-center border-round-lg p-2"
                              [ngClass]="{'bg-primary-800': record.entry.revisionType == 'MOD',
                              'bg-green-500': record.entry.revisionType == 'ADD',
                              'bg-red-500': record.entry.revisionType == 'DEL'}">
                                <i [pTooltip]="'Modified'" *ngIf="record.entry.revisionType == 'MOD'"
                                   class="fa-regular fa-pen text-xl cursor"></i>
                                <i [pTooltip]="'Added'" *ngIf="record.entry.revisionType == 'ADD'"
                                   class="fa-regular fa-plus text-xl cursor"></i>
                                <i [pTooltip]="'Deleted'" *ngIf="record.entry.revisionType == 'DEL'"
                                   class="fa-regular fa-trash text-xl cursor"></i>
                </span>
                        <div class="h-full surface-400 mt-2" style="width: 2px; min-height: 3rem"></div>
                    </div>
                    <div class="ml-5 surface-card hover:surface-hover shadow-1 border-round px-4 py-2 flex-auto">
                        <div class="flex align-items-center justify-content-between">
                            <h6 class="text-primary m-0">{{ record.entry.objectAlias }}</h6>
                            <div class="flex align-items-center gap-3">
                                <span class="text-color-secondary">{{ record.entry['uiTimestamp'] }}</span>
                                <mvs-form-control-output-user-image [value]="record.entry.processedBy">
                                </mvs-form-control-output-user-image>
                            </div>
                        </div>
                        <ul class="flex flex-column m-0 pl-3" *ngIf="record.entry.revisionType == 'MOD'">
                            @for (field of record.formFields; track field) {
                                <li class="pb-2">
                                    <div class="flex gap-2 align-items-center">
                                        <span class="">{{ field.id }}</span>
                                        <span class=""> changed to</span>
                                        <span class="font-bold">

                                            <mvs-form-field-output
                                                    [value]="record.entry.entity[field.id]"
                                                    [fieldName]="field.uiOutputControl"
                                                    [formField]="field"
                                            ></mvs-form-field-output>

                                        </span>
                                    </div>
                                </li>

                            }
                        </ul>

                        <ng-container *ngIf="record.entry.revisionType == 'ADD'">
                            <!--                            <h6 class="text-primary m-0">{{ record.entry.objectAlias }}</h6>-->
                            <ul class="m-0 pl-3">
                                <li class="pb-2">
                                    <div class="flex flex-wrap gap-2">
                                        created with <span class="">id</span>
                                        <span class="font-medium">{{ record.entry.entity.id }}</span>
                                        <ng-container *ngIf="record.entry.entity.name">
                                            and <span class="">name</span>
                                            <span class="font-medium">{{ record.entry.entity.name }}</span>
                                        </ng-container>
                                    </div>
                                </li>
                            </ul>

                        </ng-container>

                    </div>
                </div>
            }

            <!--    <p-button [disabled]="this.actualPageSize == this.pageSize" label="Load Previous" class="ml-1"-->
                    <!--              (click)="onPagePrevious()"></p-button>-->
            <div class="text-center p-3">
                <!--            *ngIf="!recordsLimitReached"-->
                <p-button class="ml-1" styleClass="p-button-text" label="Load More"
                          (click)="onPageNext()"></p-button>
            </div>

        }

    </div>

    <div class="m-4" *ngIf="!historyData || !historyData.entries?.length">
        No history records!
    </div>

</ng-container>
