import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {CrCustomerDto} from "../../../../dto/person.dto";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-additional-information-navigation',
    templateUrl: 'cr-customer-additional-information-navigation.component.html',
})
export class CrCustomerAdditionalInformationNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerDto: CrCustomerDto;
    subForms: MvsFormDto[];
    customerTagsDtoList: DtoList;
    customerCommentsDtoList: DtoList;
    customerTagsWidgetData: WidgetData;
    customerCommentsWidgetData: WidgetData;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerService: CustomerService,
                protected configService: MvsConfigService,) {
        super(coreService, confirmationService, messageService, configService,navigationService);
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getCustomer();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        this.customerCommentsWidgetData = WidgetFactory.createWidgetTransient(
            'cr.customer.additional.navigation.show.customer.comments',
            'Customer Comments',
            'list',
            'transient',
            'transient',
            'cr.CustomerContact',
            this.customerCommentsDtoList
        );

        this.customerTagsWidgetData = WidgetFactory.createWidgetTransient(
            'cr.customer.additional.navigation.show.customer.tags',
            'Customer Tags',
            'list',
            'transient',
            'transient',
            'cr.CustomerContact',
            this.customerTagsDtoList
        );

    }

    getCustomer() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id',FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [])

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customerTags",
                ObjectRequestRelation.createList(
                    "cr.CustomerTag",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("customerComments",
                ObjectRequestRelation.createList(
                    "cr.CustomerComment",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        );

        this.customerService.list(dtoRequest).subscribe(res => {
            this.customerDto = res.entries[0];
            this.subForms = res.form.subForms;

            this.customerTagsDtoList = this.prepareDtoList('customerTags')
            this.customerCommentsDtoList = this.prepareDtoList('customerComments')

            this.refreshWidgets();
        })

    }

    prepareDtoList(targetObject: string) {
        const newDtoList = new DtoList();
        newDtoList.entries = this.customerDto[targetObject];
        newDtoList.form = this.subForms[targetObject];
        MvsCrudService.transformDtoListOutToIn(newDtoList);
        return newDtoList;
    }


}
