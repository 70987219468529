<ng-container *ngIf="initialized">

    @if (size == 'M') {
        <div (click)="handleClick()" class="pointer">
            <mvs-inline-component
                    [objectType]="'cr.Customer'"
                    [name]="dto.personDtoName"
                    [startDate]="dto.startDate"
                    [endDate]="dto.endDate"
                    [status]="EntityStatusEnum[dto.entityStatus]">
            </mvs-inline-component>
        </div>
    }

</ng-container>

