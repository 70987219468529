



<section class="mb-5">
    <h2>Call Actions</h2>
    <h3>Call Dial</h3>

    <mvs-pc-call-dial [calledObject]="customerObjectIdentifier" [callTypeDto]="callTypeDto"></mvs-pc-call-dial>

    <h3>Active Call</h3>
    <mvs-pc-call-active></mvs-pc-call-active>
</section>

<div class="grid">
    <h2>Dialog</h2>
    <p-card class="col-12" styleClass="px-2">
        <div class="flex h-full justify-content-between align-items-center mb-3">
            <div class="text-xl font-semibold">Headline</div>
            <span class="pi pi-info"></span>
        </div>
        <div class="text-l font-semibold">Flex Style</div>
        <a href="https://www.primefaces.org/primeflex/width">https://www.primefaces.org/primeflex/width</a>
        <div class="overflow-scroll">
            <pre [ngClass]="'language-markup'" class="card"><code class="border-0">
&lt;p-dialog header=&quot;Header&quot; [(visible)]=&quot;visible&quot; styleClass=&quot;w-6&quot;&gt;
    &lt;ng-template pTemplate=&quot;header&quot;&gt;
        &lt;span class=&quot;text-xl font-bold&quot;&gt;Demo Dialog&lt;/span&gt;
    &lt;/ng-template&gt;
    &lt;p&gt;
        Lorem ipsum dolor sit amet.
    &lt;/p&gt;
    &lt;ng-template pTemplate=&quot;footer&quot;&gt;
        &lt;p-button
            icon=&quot;pi pi-trash&quot;
            (click)=&quot;visible = false&quot;
            label=&quot;Delete&quot;
            styleClass=&quot;p-button-text p-button-danger&quot;&gt;
        &lt;/p-button&gt;
        &lt;p-button
            icon=&quot;pi pi-times&quot;
            (click)=&quot;visible = false&quot;
            label=&quot;Cancel&quot;
            styleClass=&quot;p-button-text&quot;&gt;
        &lt;/p-button&gt;
        &lt;p-button
            icon=&quot;pi pi-check&quot;
            (click)=&quot;visible = false&quot;
            label=&quot;Ok&quot;
            styleClass=&quot;p-button-text p-button-success&quot;&gt;
        &lt;/p-button&gt;
    &lt;/ng-template&gt;
&lt;/p-dialog&gt;
            </code></pre>
            <p-button (click)="showDialog()" icon="pi pi-external-link" label="Show"></p-button>
            <p-dialog header="Demo Dialog" [(visible)]="visible" styleClass="w-6">
                <p>
                    Lorem ipsum dolor sit amet.
                </p>
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-trash" (click)="visible = false" label="Löschen" styleClass="p-button-text  p-button-danger"></p-button>
                    <p-button icon="pi pi-times" (click)="visible = false" label="Abbrechen" styleClass="p-button-text"></p-button>
                    <p-button icon="pi pi-check" (click)="visible = false" label="Ok" styleClass="p-button-text p-button-success"></p-button>
                </ng-template>
            </p-dialog>
        </div>
    </p-card>
</div>
<h2>Quick View Components</h2>

<div class="grid">
    <div class="col-12 lg:col-4">

        <div class="quickview">
            <div class="header">&LT;Type&GT;&LT;ID&GT;
                <span>&LT;STATUS&GT;</span>
            </div>
            <div class="content">&LT;NAME&GT;
                <span>(OPTIONAL)</span>
            </div>
            <div class="footer">&LT;LAST_MODIFIED_BY&GT;
                <span>&LT;MODIFIED_AT&GT;</span>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <div class="quickview --workflowA">
            <div class="header">Workflow Quickview
                <span>pending</span>
            </div>
            <div class="content">Step 6
                <span>01.08.2023</span>
            </div>
            <div class="footer">Last Interaction
                <span>24.06.2023</span>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <div class="quickview --workflowS">
            <div class="header">Workflow Quickview
                <span>pending</span>
            </div>
            <div class="content">Step 6
                <span>01.08.2023</span>
            </div>
            <div class="footer">Last Interaction
                <span>24.06.2023</span>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <div class="quickview --workflow">
            <div class="header">Workflow Quickview
                <span>pending</span>
            </div>
            <div class="content">Step 6
                <span>01.08.2023</span>
            </div>
            <div class="footer">Last Interaction
                <span>24.06.2023</span>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <div class="quickview --ticket">
            <div class="header">Ticket Quickview
                <span>pending</span>
            </div>
            <div class="content">Customer Call
                <span>21.07.2023</span>
            </div>
            <div class="footer">Last Interaction
                <span>30.06.2023</span>
            </div>
        </div>
    </div>
</div>

<h2>Quick Steps</h2>
<div class="grid">
    <div class="col-12 md:col-4">
        <div class="surface-card shadow-2 border-round flex align-items-center justify-content-between w-full">
            <div class="font-medium text-700 p-2 white-space-nowrap text-overflow-ellipsis">Ticket ID23445-WHEZ-4959</div>
            <button pButton [icon]="'pi pi-search'" class="border-noround-left h-full"></button>
        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="surface-card shadow-2 border-round flex align-items-center justify-content-between w-full">
            <div class="font-medium text-700 p-2 white-space-nowrap text-overflow-ellipsis">Add Ticket</div>
            <button pButton [icon]="'pi pi-plus'" class="border-noround-left h-full"></button>
        </div>
    </div>
</div>

<h2> Calls
</h2>

<section>
    <div class="grid">
        <div class="col-12 md:col-3">
            <h3>Dial Call Component</h3>
            <mvs-call-dial></mvs-call-dial>
        </div>
        <div class="col-12 md:col-3">
            <h3>Active Call Component</h3>
            <mvs-pc-call-active></mvs-pc-call-active>
        </div>
    </div>

</section>