<ng-container *ngIf="initialized">

    <div class="grid mb-3">

        <div class="col-3">
            <div class="mvs-widget">
            <mvs-widget *ngIf="customerContactsWidgetData"
                        [widgetData]="customerContactsWidgetData">
            </mvs-widget>

            <mvs-widget *ngIf="customerPersonObjectWidgetData"
                        [widgetData]="customerPersonObjectWidgetData">
            </mvs-widget>

            <mvs-widget *ngIf="widgetCustomerAddresses"
                        [widgetData]="widgetCustomerAddresses">
            </mvs-widget>

            <mvs-widget *ngIf="widgetCustomerContacts"
                        [widgetData]="widgetCustomerContacts">
            </mvs-widget>
        </div>
        </div>

        <div class="col-6">
            <div class="mvs-widget">
            <mvs-widget *ngIf="customerContractWidgetData"
                        [widgetData]="customerContractWidgetData">
            </mvs-widget>

            <mvs-widget *ngIf="customerRelatedPersonWidget"
                        [widgetData]="customerRelatedPersonWidget">
            </mvs-widget>

            <mvs-object-comments
                    headline="Kommentare"
                    [mainObject]="objectIdentifier"
                    [showFullText]="true"
                    [showGroupedComments]="false"
                    linkedEntityObjectType="cr.CustomerComment"
            ></mvs-object-comments>
            </div>
        </div>

        <div class="col-3">
            <div class="mvs-widget">
            <mvs-widget *ngIf="customerInsurableObjectsWidgetData"
                        [widgetData]="customerInsurableObjectsWidgetData">
            </mvs-widget>

            <mvs-widget *ngIf="widgetCustomerAgents"
                        [widgetData]="widgetCustomerAgents">
            </mvs-widget>
            </div>

        </div>

    </div>

    <!--

    <div class="grid mb-3">

        <div class="col-2 flex flex-column gap-2">
            <div class="mvs-widget h-full">
                <mvs-widget *ngIf="customerInsurableObjectsWidgetData"
                            [widgetData]="customerInsurableObjectsWidgetData"
                            (onObjectSelect)="handleViewInsurableObjects($event,'#target')"
                            [uiStyle]="selectedRelatedPersons"
                >
                </mvs-widget>

            </div>

        </div>

        <div class="col-7 flex flex-column gap-2">
            <div class="mvs-widget pb-0 py-3">
            </div>


            <div class="mvs-widget">
                <mvs-widget *ngIf="customerContractWidgetData"
                            [widgetData]="customerContractWidgetData"
                            (onObjectSelect)="handleViewContract($event,'#target')"
                            [uiStyle]="selectedRelatedPersons">
                </mvs-widget>
            </div>
        </div>

        <div class="col-3 flex flex-column gap-2">
            <div class="flex flex-column gap-3">
                <mvs-widget *ngIf="widgetCustomerAgents" [widgetData]="widgetCustomerAgents"></mvs-widget>
                <div class="mvs-widget">
                    <mvs-widget *ngIf="customerUserWidgetData"
                                [widgetData]="customerUserWidgetData">
                    </mvs-widget>
                </div>


                <div class="mvs-widget h-full">
                    <mvs-object-tags
                            headline="Tags"
                            [mainObject]="objectIdentifier"
                            linkedEntityObjectType="cr.CustomerTag"
                            [readOnly]="true"
                    ></mvs-object-tags>
                </div>

                <div class="mvs-widget">
                    <mvs-widget *ngIf="widgetCustomerComments" [widgetData]="widgetCustomerComments"></mvs-widget>
                </div>

                <div class="mvs-widget">
                    <mvs-pc-object-calls
                            [objectIdentifier]="objectIdentifier"
                            [aliasSuffix]="viewType"
                            widget="timeline"
                    ></mvs-pc-object-calls>
                </div>

            </div>
        </div>

    </div>

    <div class="grid mb-3">
        <div class="col-2 flex flex-column gap-2">

            <div class="mvs-widget px-0 h-full">
            <wf-object-processes
                    [objectIdentifier]="objectIdentifier"
                    [showActive]="false">
            </wf-object-processes>
            </div>

        </div>

        <div class="col-7 flex flex-column gap-2">
           <div class="h-full">
            <tm-object-tickets
                    [objectIdentifier]="objectIdentifier"
                    ticketDrawerLocation="left"
                    [showActive]="false">
            </tm-object-tickets>
           </div>

        </div>

        <div class="col-3 flex flex-column gap-2">
            <div class="mvs-widget h-full">
                <mvs-widget *ngIf="customerSignatureWidgetData"
                            [widgetData]="customerSignatureWidgetData">
                </mvs-widget>
            </div>
        </div>

    </div>

    -->
</ng-container>