<ng-container *ngIf="initialized">

    <p-toast key="fu"></p-toast>
    <p-fileUpload
            [ngClass]="{'sVersionFileUpload':layoutVariation=='s-version'}"
            #fileUploader
            name="demo[]"
            url="./upload.php"
            accept="application/pdf"
            [customUpload]="true"
            [multiple]="true"
            chooseLabel="Auswählen"
            [showUploadButton]="true"
            uploadIcon="pi pi-cloud-upload"
            [showCancelButton]="true"
            (uploadHandler)="onUploadHandler($event, fileUploader)"
            (onSelect)="onSelectHandler(fileUploader)"
            [auto]="false"
            [previewWidth]="50"
            (onClear)="showDragNDrop = true"
            class="w-full">

        <ng-template pTemplate="toolbar">
<!--            [ngClass]="{'':layoutVariation=='s-version', 'card flex justify-content-center':layoutVariation=='m-version'}"-->
            <span class="flex justify-content-between mt-3 gap-2">
                <p-treeSelect
                        *ngIf="showDocumentTypeDropdown && documentTypeHierarchy && documentTypeHierarchy.length > 0"
                        class="md:w-18rem w-full tree-search"
                        containerStyleClass="w-full"
                        [(ngModel)]="selectedDocumentType"
                        [options]="documentTypeHierarchy"
                        placeholder="Dokumenttyp auswählen"
                        [filter]="true"
                        [filterInputAutoFocus]="true"
                        showClear="true"
                ></p-treeSelect>


                <p-selectButton [options]="displayOptions" [(ngModel)]="displayValue" styleClass="p-0"
                                optionLabel="displayValue"
                                optionValue="displayValue">
                    <ng-template let-item>
                        <p-button [icon]="item.icon" class="text-center" styleClass="p-button-link w-full h-1rem"
                                  (click)="changeView(item.displayValue)"></p-button>
                    </ng-template>
                </p-selectButton>

            </span>
        </ng-template>

        <ng-template
            let-file
            let-index="index"
            pTemplate="file">
            <ng-container *ngIf="displayValue == 'list'">
                <table class="w-full mb-2">
                    <tr>
                        <td class="w-4">
                            <div class="flex align-items-center gap-2">
                                <i class="fa fa-file-pdf-o text-3xl text-red-500"></i>
                                <div class="flex flex-column">
                                    <span>{{file.name}}</span>
                                    <span
                                            *ngIf="showPreview"
                                            class="text-sm mt-1 text-blue-500 cursor"
                                            (click)="preview(file); selectedFileIndex = index"
                                    >Preview</span>
                                </div>

                            </div>
                        </td>
                        <td class="w-4 text-center">{{file.size / 1024 | number : '1.2-2'}} KB</td>
                        <td class="text-right w-4">
                            <p-button icon="fa fa-times" (click)="removeFile($event,file, fileUploader)"></p-button>
                        </td>
                    </tr>
                </table>

                <div *ngIf="file['progress']" style="width:100%">
                    <p-progressBar [value]="file.progress.value"
                                   [style]="{'height': '20px', 'position' : 'relative'}"></p-progressBar>
                    <div *ngIf="file.progress.success">Finished!</div>
                    <div *ngIf="file.progress.error">Error occured!</div>
                </div>
            </ng-container>

            <ng-container *ngIf="displayValue == 'grid'">

                <!--                    <div class="lg:col-3 col-12">-->
                <section class="card block shadow-2 m-3" style="width: 275px">
                    <div class="flex justify-content-between gap-2 p-2">
                        <h5 class="pdf-card-title text-sm">{{file.name}}</h5>
                        <div class="flex gap-3">
                            <i
                                    (click)="preview(file); selectedFileIndex = index"
                                    class="pi pi-window-maximize cursor"
                            ></i>
                            <i
                                    class="fa-regular fa-times cursor"
                                    (click)="removeFile($event,file, fileUploader)"
                            ></i>
                        </div>
                    </div>
                    <div class="text-center">

                        <mvs-pdf-viewer
                                [pdfSrc]="file.pdfSrc"
                                [width]="250"
                                [height]="350"
                        ></mvs-pdf-viewer>
                    </div>
                </section>
                <!--                    </div>-->

            </ng-container>

        </ng-template>
        <ng-template pTemplate="content">

            <div class="w-full py-3" style="cursor: copy"
                 (click)="fileUploader.advancedFileInput.nativeElement.click()">
                <div *ngIf="!uploadedFiles.length && showDragNDrop"
                     class="h-full flex flex-column justify-content-center align-items-center">
                    <i class="pi pi-upload text-900 text-2xl mb-3"></i>
                    <span class="font-bold text-900 text-xl mb-3">Datein hochladen</span>
                    <span class="font-medium text-600 text-md text-center">Auswählen oder via Drag'n Drop</span>
                </div>
                <div class="flex flex-wrap gap-5" *ngIf="uploadedFiles.length">
                    <div *ngFor="let file of uploadedFiles; let i = index;"
                         class="h-full relative w-7rem h-7rem border-3 border-transparent border-round hover:bg-primary transition-duration-100 cursor-auto"
                         (mouseenter)="onImageMouseOver(file)" (mouseleave)="onImageMouseLeave(file)"
                         style="padding: 1px;">
                        <!--<img [src]="file.objectURL" [alt]="file.name" class="w-full h-full border-round shadow-2">-->
                        <button [id]="file.name" #buttonEl pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer"
                                style="top: -10px; right: -10px; display: none;"
                                (click)="removeImage($event, file)"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-fileUpload>

    <p-dialog
        #dialogBox
        (onShow)="showDialogMaximized($event,dialogBox)"
        [(visible)]="visible"
        [style]="{height: '100%'}"
        [maximizable]="maximizable"
        appendTo="body">
        <ng-template pTemplate="header">
            <div class="flex justify-content-between w-full">
                <span class="pr-5">{{fileUploader.files[selectedFileIndex].name}}</span>
                <i
                        class="fa-regular fa-trash cursor"
                        (click)="removeFile($event,fileUploader.files[selectedFileIndex], fileUploader)"
                ></i>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="flex justify-content-center align-items-center gap-3">
                <p-button icon="pi pi-angle-left" styleClass="m-0" (click)="previousDocument()"
                          [disabled]="selectedFileIndex == 0"></p-button>

                <span>{{(selectedFileIndex + 1)}} / {{fileUploader.files.length}}</span>

                <p-button icon="pi pi-angle-right" styleClass="m-0" (click)="nextDocument(fileUploader.files.length)"
                          [disabled]="selectedFileIndex == fileUploader.files.length-1"></p-button>
            </div>
        </ng-template>
        <mvs-pdf-viewer [pdfSrc]="fileUploader.files[selectedFileIndex]?.pdfSrc" class="w-full h-full"></mvs-pdf-viewer>
    </p-dialog>

</ng-container>


