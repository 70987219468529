import { NgModule } from '@angular/core';
import {XrReportsPage} from "./page/xr-reports-controlling/xr-reports.page";
import {
  XrReportComponent
} from "./component/xr-report-controlling-agent-activities/xr-report.component";
import {CoreModule} from "../core/core.module";


@NgModule({
  declarations: [
    XrReportsPage,
    XrReportComponent,
  ],
  exports:[
    XrReportsPage,
    XrReportComponent,
  ],
  imports: [
    CoreModule,
  ]
})
export class XrModule { }

 
 
