import {Component} from '@angular/core';
import {
    DtoDetail,
    DtoList,
    FilterCriteria, MvsCrudService, MvsFormDto, ObjectBaseComponent,
    ObjectRequestComplex, ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList, ObjectRequestRelation,
    WidgetData, WidgetDataParam
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {NsNotificationService} from "../../service/api/ns-notification.service";
import {NsNotificationTypeDto} from "../../dto/ns-notification-type.dto";


@Component({
    selector: 'mvs-ns-notification-object-component',
    templateUrl: './ns-notification-object.component.html'
})
export class NsNotificationObjectComponent
    extends ObjectBaseComponent {

    defaultLabel: string = "Notification";
    notificationDialogVisible: boolean = false;

    widgetNotificationData: WidgetData;
    widgetNotificationRecipientData: WidgetData;
    widgetNotificationChannelData: WidgetData;
    widgetNotificationEmailData: WidgetData;
    widgetNotificationEmailRecipientsData: WidgetData;
    widgetNotificationChannelRecipientData: WidgetData;
    notificationService: NsNotificationService;
    notification: DtoList;

    notificationChannels: DtoList;
    notificationRecipients: DtoList;
    notificationEmails: DtoList;
    notificationEmailRecipients: DtoList;
    notificationTypeDto: NsNotificationTypeDto;

    ngOnInit() {
        super.ngOnInit();
    }

    protected getObjectType(): string {
        return "ns.NsNotification";
    }

    onObjectChanged() {
        super.onObjectChanged();
        this.init()
    }

    init() {
        this.notificationService = <NsNotificationService>this.coreService.getCrudService("ns.NsNotification");

        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual , this.objectIdentifier.objectId)],
            []
        );

        dtoRequest.objectRequestComplex =  ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("notificationRecipients",
                ObjectRequestRelation.createList(
                    "ns.NsNotificationRecipient",
                    "notification",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("notificationChannels",
                ObjectRequestRelation.createList(
                    "ns.NsNotificationChannel",
                    "notification",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(ObjectRequestComplexNode.createRelationNode("contentOverwrite", ObjectRequestRelation.createJoin("contentOverwrite"))),

            ObjectRequestComplexNode.createRelationNode("notificationEmails",
                ObjectRequestRelation.createList(
                    "ns.NsNotificationEmail",
                    "notification",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("notificationEmailRecipients",
                ObjectRequestRelation.createList(
                    "ns.NsNotificationEmailRecipient",
                    "notification",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createSimpleAttributeNode('notificationType')
        );

        this.notificationService.list(dtoRequest).subscribe(res => {
            this.notification = res;

            this.prepareData(this.notification.entries[0], this.notification.form);
            // this.getNotificationType();

        })
    }

    prepareData(notificationDto: DtoDetail, form: MvsFormDto) {

        this.notificationRecipients = new DtoList<DtoDetail>();
        this.notificationRecipients.entries = notificationDto['notificationRecipients'];
        this.notificationRecipients.form = form.subForms['notificationRecipients'];

        this.notificationChannels = new DtoList<DtoDetail>();
        this.notificationChannels.entries = notificationDto['notificationChannels'];
        this.notificationChannels.form = form.subForms['notificationChannels'];

        this.notificationEmails = new DtoList<DtoDetail>();
        this.notificationEmails.entries = notificationDto['notificationEmails'];
        this.notificationEmails.form = form.subForms['notificationEmails'];

        this.notificationEmailRecipients = new DtoList<DtoDetail>();
        this.notificationEmailRecipients.entries = notificationDto['notificationEmailRecipients'];
        this.notificationEmailRecipients.form = form.subForms['notificationEmailRecipients'];

        this.notificationTypeDto = notificationDto['notificationTypeDto'];

        this.refreshWidget();

    }

    refreshWidget() {

        this.widgetNotificationData = WidgetFactory.createWidgetTransient(
            'ns.notifications.object.notification.basic',
            'Notification',
            'data',
            'transient',
            'transient',
            'ns.NsNotification',
            this.dto
        )

        this.widgetNotificationRecipientData = WidgetFactory.createWidgetTransient(
            'ns.notifications.object.notification.recipient.basic',
            'Notification Recipients',
            'table',
            'transient',
            'transient',
            'ns.NsNotificationRecipient',
            MvsCrudService.transformDtoListOutToIn(this.notificationRecipients)
        )

        this.widgetNotificationChannelData = WidgetFactory.createWidgetTransient(
            'ns.notifications.object.notification.channel.basic',
            'Notification Channel',
            'table',
            'transient',
            'transient',
            'ns.NsNotificationChannel',
            MvsCrudService.transformDtoListOutToIn(this.notificationChannels)
        )

        if (this.notificationEmails.entries) {
            this.widgetNotificationEmailData = WidgetFactory.createWidgetTransient(
                'ns.notifications.object.notification.emails.basic',
                'Notification Emails',
                'table',
                'transient',
                'transient',
                'ns.NsNotificationEmail',
                MvsCrudService.transformDtoListOutToIn(this.notificationEmails)
            )
        }

        if (this.notificationEmailRecipients.entries) {
            this.widgetNotificationEmailRecipientsData = WidgetFactory.createWidgetTransient(
                'ns.notifications.object.notification.emails.recipients.basic',
                'Notification Emails Recipients',
                'table',
                'transient',
                'transient',
                'ns.NsNotificationEmailRecipient',
                MvsCrudService.transformDtoListOutToIn(this.notificationEmailRecipients)
            )
        }


        // if (this.notificationEmails.entries) {
        //     this.widgetNotificationEmailData = new WidgetData();
        //     this.widgetNotificationEmailData.idAlias = "ns.notifications.object.notification.emails.basic";
        //     this.widgetNotificationEmailData.name = "Notification Emails";
        //     this.widgetNotificationEmailData.uiComponent = "table";
        //     this.widgetNotificationEmailData.dataProvider = "transient";
        //     this.widgetNotificationEmailData.dataSource = "transient";
        //     this.widgetNotificationEmailData.dataTransient = MvsCrudService.transformDtoListOutToIn(this.notificationEmails);
        // }
        //
        // if (this.notificationEmailRecipients.entries) {
        //     this.widgetNotificationEmailRecipientsData = new WidgetData();
        //     this.widgetNotificationEmailRecipientsData.idAlias = "ns.notifications.object.notification.emails.recipients.basic";
        //     this.widgetNotificationEmailRecipientsData.name = "Notification Email Recipients";
        //     this.widgetNotificationEmailRecipientsData.uiComponent = "table";
        //     this.widgetNotificationEmailRecipientsData.dataProvider = "transient";
        //     this.widgetNotificationEmailRecipientsData.dataSource = "transient";
        //     this.widgetNotificationEmailRecipientsData.dataTransient = MvsCrudService.transformDtoListOutToIn(this.notificationEmailRecipients);
        // }

        this.prepareNotificationChannelRecipientWidget();
    }

    prepareNotificationChannelRecipientWidget() {

        const tempFilterCriteria = [];
        for (const item of this.notificationChannels.entries) {
            tempFilterCriteria.push(FilterCriteria.create("notificationChannel", FilterCriteria.cOperatorEqual, item.id))
        }

        const filterCriteria = new FilterCriteria();
        filterCriteria.or = tempFilterCriteria;

        const dtoRequest: ObjectRequestList = new ObjectRequestList(
            true,
            [filterCriteria],
            []
        );

        this.widgetNotificationChannelRecipientData = WidgetFactory.createWidgetList(
            "ns.notifications.object.notification.channel.recipient.basic",
            "Notification Channel Recipients",
            "table",
            "list",
            "entity",
            "ns.NsNotificationChannelRecipient",
            "No Notification Channel Recipient Found",
            dtoRequest,
            WidgetDataParam.create("size" , "S")
        )

    }

    onNotificationTypesClick() {
        const url = '#/ns/configTypes?tid=ns.NsNotificationType:' + this.notificationTypeDto.id + ':object&tid-action=0';
        window.open(url, "_blank");
    }

}
