export enum DataType {

   Integer,
   Long,
   Float,
   Object,
   String,
   Date,
   Time,
   DateTime,
   Double,
   BigDecimal,
}