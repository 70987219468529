import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WfProcessTypeService} from "../../../../service/api/wf-process-type.service";
import {WfProcessService} from "../../../../service/api/wf-process.service";
import {WfProcessStatusInternal} from "../../../../enum/wf-process-status-internal.enum";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'wf-kpi.component.html',
})
export class WfKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalWorkflows: number = 0;
    workflowsGroups: number = 0;
    totalProcess: number = 0;
    completedProcess: number = 0;
    thisWeekProcess: number = 0;
    thisMonthProcess: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Workflow Module'}];
    constructor(
        protected processTypeService: WfProcessTypeService,
        protected processService: WfProcessService,) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalProcessTypes();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalProcessTypes() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['processGroup'],
            [new ObjectRequestListAttribute('processGroup', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.processTypeService.groupBy(objectRequestList).subscribe(res => {
            this.totalWorkflows = res.entries.reduce((acc, item) => acc + item['processGroup_count'], 0);
            this.workflowsGroups = res.entries.length;
            this.getTotalProcess();
        },error => {
            this.getTotalProcess();
        });
    }

    getTotalProcess() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['type'],
            [new ObjectRequestListAttribute('type', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.processService.groupBy(objectRequestList).subscribe(res => {
            this.totalProcess = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.getCompletedProcess();
        },error => {
            this.getCompletedProcess();
        });
    }

    getCompletedProcess() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['processStatusInternal'],
            [new ObjectRequestListAttribute('processStatusInternal', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.processService.groupBy(objectRequestList).subscribe(res => {

            for (let entry of res.entries) {
                if (entry['processStatusInternal'] == WfProcessStatusInternal.completed) {
                    this.completedProcess = entry['processStatusInternal_count']
                }
            }

            this.getThisMonthProcess();
        },error => {
            this.getThisMonthProcess();
        });
    }

    getThisMonthProcess() {
        const objectRequestList = this.getThisMonthRequest('type');
        this.processService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthProcess = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.getThisWeekProcess();
        }, error => {
            this.getThisWeekProcess();
        });
    }

    getThisWeekProcess() {
        const objectRequestList = this.getThisWeekRequest('type');
        this.processService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekProcess = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Workflows', value: this.totalWorkflows,
            },
            {
                label: 'Workflow Groups', value: this.workflowsGroups,
            },
            {
                label: 'Workflow Process Started', value: this.totalProcess,
            },
            {
                label: 'Completed Process', value: this.completedProcess,
            },
            {
                label: 'This Month Process', value: this.thisMonthProcess,
            },
            {
                label: 'This Week Process', value: this.thisWeekProcess,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
