<div class="grid">
    <div class="col-12">
        <div class="flex gap-4 mt-4">
            <p-fileUpload #fileUploader name="file" mode="basic" (onSelect)="onFileSelect($event)" [auto]="false"
                          chooseLabel="Pick a File" [customUpload]="true">
            </p-fileUpload>

            <p-dropdown
                    [options]="messageTypes"
                    [(ngModel)]="selectedMessageType"
                    placeholder="Select Message Type"
                    appendTo="body"
            ></p-dropdown>

            <p-progressSpinner styleClass="w-3rem h-3rem" *ngIf="busy"></p-progressSpinner>
            <p-button [disabled]="!selectedFile || selectedMessageType == undefined || busy" label="Process"
                      (click)="processDocument(fileUploader)"></p-button>
        </div>
    </div>

    <div class="col-12">
        <em-schema-runtime-data
                *ngIf="emSchemaRuntimeDataDto"
                [schemaData]="emSchemaRuntimeDataDto"
        ></em-schema-runtime-data>
    </div>
</div>