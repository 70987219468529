<ng-container *ngIf="initialized && agentPoolDtoList">

    <p-tabView (onChange)="changeTab($event)">
        <p-tabPanel header="Open Tickets">
        </p-tabPanel>
        <p-tabPanel header="Close Tickets">
        </p-tabPanel>
        <p-tabPanel header="History">
        </p-tabPanel>
    </p-tabView>

    <div class="grid" *ngIf="widgetTickets && tabIndex != 2">

        <div class="col-12" *ngIf="filterCriteria.length>0">
            <p-panel header="Filters">
                <div class="flex align-items-center gap-2 flex-wrap">
                    <p-chip
                            *ngFor="let filter of filterCriteria"
                            [label]="filter.field + ': ' + filter.label"
                            [removable]="true"
                            (onRemove)="onRemoveFilterCriteria(filter)"
                    ></p-chip>
                </div>
            </p-panel>
        </div>

        <div class="col-3">
            <mvs-widget
                    *ngIf="widgetTicketType"
                    [widgetData]="widgetTicketType"
                    [importObjectContext]="importObjectContextDto"
                    (onObjectSelect)="onSetFilterCriteria($event,'type')"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetTicketStatus"
                    [widgetData]="widgetTicketStatus"
                    [importObjectContext]="importObjectContextDto"
                    (onObjectSelect)="onSetFilterCriteria($event,'status')"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetTicketUrgency"
                    [widgetData]="widgetTicketUrgency"
                    [importObjectContext]="importObjectContextDto"
                    (onObjectSelect)="onSetFilterCriteria($event,'urgency')"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetDataTicketStatusPeriod"
                    [widgetData]="widgetDataTicketStatusPeriod"
                    [importObjectContext]="importObjectContextDto"
                    (onObjectSelect)="onSetFilterCriteria($event,'createdDate')"
            ></mvs-widget>
        </div>

        <div class="col-9">
            <mvs-widget
                    [widgetData]="widgetTickets"
                    [importObjectContext]="importObjectContextDto"
                    (onObjectSelect)="openTicketDrawer($event)"
            ></mvs-widget>
        </div>
    </div>

    <div class="grid" *ngIf="tabIndex == 2">
        <div class="col-12">
            <mvs-widget
                    *ngIf="widgetTicketTypeMeHistoryChart"
                    [widgetData]="widgetTicketTypeMeHistoryChart"
                    [importObjectContext]="importObjectContextDto"
            ></mvs-widget>
        </div>
    </div>

</ng-container>
