<!--<ng-container *ngIf="initialized">-->

<!--    <p-tabView [(activeIndex)]="tabIndex">-->

<!--        <p-tabPanel header="Agent Info">-->

<!--        </p-tabPanel>-->
<!--        <p-tabPanel header="Agent Calendar">-->

<!--        </p-tabPanel>-->

<!--    </p-tabView>-->

<!--    <ng-container *ngIf="tabIndex == 0">-->
<!--        <mvs-widget-->
<!--                *ngIf="agentWidget"-->
<!--                [widgetData]="agentWidget"-->
<!--        ></mvs-widget>-->

<!--        <mvs-widget-->
<!--                *ngIf="agentUserWidget"-->
<!--                [widgetData]="agentUserWidget"-->
<!--        ></mvs-widget>-->

<!--        <mvs-widget-->
<!--                *ngIf="agentPersonWidget && this.dto?.employeeDtoId"-->
<!--                [widgetData]="agentPersonWidget"-->
<!--        ></mvs-widget>-->

<!--        <mvs-widget-->
<!--                *ngIf="agentContactWidget"-->
<!--                [widgetData]="agentContactWidget"-->
<!--        ></mvs-widget>-->

<!--        <mvs-widget-->
<!--                *ngIf="agentPoolAgentWidget"-->
<!--                [widgetData]="agentPoolAgentWidget"-->
<!--        ></mvs-widget>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="tabIndex == 1">-->
<!--        <am-agent-calendar-management-->
<!--                [selectedAgentIds]="[objectIdentifier.objectId]"-->
<!--        ></am-agent-calendar-management>-->
<!--    </ng-container>-->


<!--</ng-container>-->

@if (initialized) {

    @if (uiMode == 'side') {
    <am-agent-info-detail-object-side
            [objectIdentifier]="objectIdentifier"
            [dto]="dto"
            (onNavigationItems)="handleNavigationItems($event)"
    ></am-agent-info-detail-object-side>
    } @else {
        <am-agent-info-detail-object-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                (onNavigationItems)="handleNavigationItems($event)"
        ></am-agent-info-detail-object-full>
    }
}