import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './si-config.page.html',
})
export class SiConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Search - Configuration ";


    ngOnInit(): void {

        this.objectBrowserRequest = {

            "extractDto": false,
            "createMode": true,

            'entries': [
                {
                    'id'                : 1,
                    'objectAlias'       : 'si.SearchResultObjectType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Search Result Field Configuration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"

                },
                {
                    'id'                : 2,
                    'objectAlias'       : 'si.SearchObjectTypeAttribute',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Object Type Attribute Search configuration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"

                },


            ]

        };

        super.ngOnInit();
    }
}
