import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseComponent,
    ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../core/helper/widget-function-call-back-create";


@Component({
    selector: 'provision-type-object-component',
    templateUrl: './provision-type-object.component.html',
})
export class ProvisionTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    provisionTypeWidget: WidgetData;
    provisionTypeAgentPaymentWidget: WidgetData;
    provisionTypePartnerPaymentWidget: WidgetData;

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.refreshProvisionTypeWidget();
        this.refreshProvisionTypeAgentPaymentWidget();
        this.refreshProvisionTypePartnerPaymentWidget();
    }

    refreshProvisionTypeWidget() {
        this.provisionTypeWidget = WidgetFactory.createWidgetEntityData(
            'cp.provision.type.data.widget',
            'Provision Type',
            'cp.ProvisionType',
            this.objectIdentifier.objectId
        )
    }

    refreshProvisionTypeAgentPaymentWidget() {

        const filterCriteria = FilterCriteria.create('provisionType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('agentPaymentAgent', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentAgent#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentAgentPool', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentAgentPool#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentCategory', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentCategory#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentContractAssignment', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentContractAssignment#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentPartnerPayment', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentPartnerPayment#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentProvisionAgent', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentProvisionAgent#agentPayment')),
            ObjectRequestComplexNode.createRelationNode('agentPaymentProvisionAgentPool', ObjectRequestRelation.createJoin('cp.ProvisionTypeAgentPaymentProvisionAgentPool#agentPayment')),
        );

        this.provisionTypeAgentPaymentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.type.agent.payment.widget.data',
            'Agent Payment',
            'cp.ProvisionTypeAgentPayment',
            'No Data',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'provisionTypeDtoId', fieldValue: this.objectIdentifier.objectId}]

        this.provisionTypeAgentPaymentWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    refreshProvisionTypePartnerPaymentWidget() {

        const filterCriteria = FilterCriteria.create('provisionType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('paymentCategory', ObjectRequestRelation.createJoin('cp.ProvisionTypePartnerPaymentCategory#provisionTypePartnerPayment')),
        );

        this.provisionTypePartnerPaymentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.type.partner.payment.widget.data',
            'Partner Payment',
            'cp.ProvisionTypePartnerPayment',
            'No Data',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'provisionTypeDtoId', fieldValue: this.objectIdentifier.objectId}]

        this.provisionTypePartnerPaymentWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }


    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
