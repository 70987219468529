<ng-container *ngIf="initialized">

    <div class="flex flex-column gap-2 px-3" *ngIf="prefilledLabels?.length">
        <h4 class="mx-0 my-2 px-2">Fields</h4>

        <ng-container *ngFor="let label of prefilledLabels">
            <div class="flex gap-2 px-2 mb-2">
                <div class="pt-1" style="width:14px; height: 14px;">
                    <span [ngStyle]="{'background-color': getLabelColors(label.label)}"
                          class="block {{getLabelColors(label.label)}}"
                          style="width:12px; height: 12px; border-radius: 50%">
                    </span>
                </div>
                <div class="flex flex-column gap-1">
                    <div class="text-lg mb-1">{{ label.label }}
                    </div>
                    <span class="font-medium text-base"
                          *ngIf="label.textPaths">{{ label.textPaths.join(' ') }}</span>
                </div>
            </div>
        </ng-container>
        <div class="flex flex-wrap gap-2 p-3 surface-100 border-round-lg my-2"
             *ngIf="userActions">
            <h5 class="font-medium w-full m-0">Update</h5>
            <p-chip class="cursor" pRipple
                    styleClass="surface-0 border-1 border-300 hover:bg-blue-50"
                    *ngFor="let item of userActions"
                    [label]="item.action"
                    (click)="displayHistory(item)">
            </p-chip>
        </div>
    </div>

    <mvs-widget *ngIf="historyWidget"
                [widgetData]="historyWidget"
                [widgetToolbarButtons]="historyWidgetToolbarButtons"
                (onToolbarButtonClick)="createNewUserAction()"
    >
    </mvs-widget>

    <ng-container *ngIf="actionWidget">
        <p-divider styleClass="my-2"></p-divider>
        <mvs-widget [widgetData]="actionWidget"></mvs-widget>
    </ng-container>


</ng-container>
