import {Component, Input, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CustomerContactPhoneInfoDto} from "../../../cr/service/dto/customer-contact-phone-info.dto";
import {CustomerContactService} from "../../../cr/service/api/customer-contact.service";
import {CoChannelPhoneNumberDto} from "../../../co/dto/service/co-channel-phone-number.dto";
import {ConfirmationService} from "primeng/api";
import {CoUiService} from "../../../co/service/ui/co-ui.service";
import {CoChannelAccessTypeEnum} from "../../../co/enum/co-channel-access-type-enum.enum";
import {CoUiChannelAccessTypeInfoUi} from "../../../co/service/ui/data/co-ui-channel-access-type-info.ui";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CallTypeDto} from "../../../pc/dto/call-type.dto";
import {CallTypeService} from "../../../pc/service/api/call-type.service";
import {CallService} from "../../../pc/service/api/call.service";
import {CallUiService} from "../../../pc/service/ui/call-ui.service";
import {CallDirectionEnum} from "../../../pc/enum/call-direction-enum.enum";
import {CallRecipientTypeEnum} from "../../../pc/enum/call-recipient-type-enum.enum";
import {CallDto} from "../../../pc/dto/call.dto";

@Component({
    selector: 'mvs-call-dial',
    templateUrl: 'mvs-pc-call-dial.component.html',
    styleUrls: ['mvs-pc-call-dial.component.scss']
})
export class MvsPcCallDialComponent {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() calledObject: ObjectIdentifier;        // <Object Alias>: cr.Customer / <ID>: ID of Object
    @Input() callTypeId: number;                    // <Call Type ID>: 1
    @Input() callTypeDto?: CallTypeDto;

    @Input() sourceActionObject?: ObjectIdentifier;

    uiChannelAccessInfo?: CoUiChannelAccessTypeInfoUi;
    uiPreferredIconClass: string = 'fa-solid fa-heart';

    customerContactPhoneInfo: CustomerContactPhoneInfoDto;

    listOutgoingCallTypeDtoList: DtoList<CallTypeDto>;
    selectedCallType: CallTypeDto;

    constructor(
        protected customerContactService: CustomerContactService,
        protected callTypeService: CallTypeService,
        protected callService: CallService,
        protected confirmationService: ConfirmationService,
        protected callUiService: CallUiService,
        protected coUiService: CoUiService,
        protected messageService: MvsMessageService,
    ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve all outgoing call types
        this.callTypeService.list(
            ObjectRequestList.createSimpleRequestList(
                false,
                ObjectRequestSimple.create(false, false, 0),
                [FilterCriteria.create("callDirection", FilterCriteria.cOperatorEqual, CallDirectionEnum.outgoing),
                            FilterCriteria.create("callRecipientType", FilterCriteria.cOperatorEqual, CallRecipientTypeEnum.customer)],
                [ new Sorting("orderPriority", true)],
                null,
                null)).subscribe(value => {

                    this.listOutgoingCallTypeDtoList = value;

                    if (this.listOutgoingCallTypeDtoList && this.listOutgoingCallTypeDtoList.entries && this.listOutgoingCallTypeDtoList.entries.length > 0) {
                        this.selectedCallType = this.listOutgoingCallTypeDtoList.entries[0];
                    }

                    this.refreshComponent();
        });

    }

    refreshUi() {

        this.uiChannelAccessInfo = this.getCallAccessInformation(this.customerContactPhoneInfo.channelAccess);

        if (!this.customerContactPhoneInfo.recipientAddresses) {
            return;
        }

        for (let recipientAddress of this.customerContactPhoneInfo.recipientAddresses) {
            recipientAddress.uiChannelAccessInfo = this.uiChannelAccessInfo;

            if (recipientAddress.preferred) {
                // put in star ....
            }

        }

        this.initialized = true;

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        if(this.calledObject.objectType === 'cr.Customer') {
            this.customerContactService.getPhoneInfo(this.calledObject.objectId).subscribe(value => {
                this.customerContactPhoneInfo = value;

                this.refreshUi();
            });
        }

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["calledObject"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    callDto: CallDto;

    handleStartCall(recipientAddress: CoChannelPhoneNumberDto) {

        if (!this.selectedCallType) {
            this.messageService.showErrorMessage("Typ auswählen", "Bitte Anruftyp auswählen!");
            return;
        }

        // check for active call
        this.callService.getMyActiveCalls().subscribe(activeCalls => {

            if (activeCalls && activeCalls.entries && activeCalls.entries.length > 0) {
                this.messageService.showErrorMessage("Laufender Anruf", "Bitte aktuellen Anruf zuerst beenden!");
            } else {
                this.callService.startCustomerCall(this.selectedCallType.id,this.calledObject.objectId, recipientAddress.phoneNumber).subscribe(value => {
                    this.callDto = value;
                    this.callUiService.refreshHeaderActiveCall();
                });
            }
        });

    }

    protected getCallAccessInformation(access: CoChannelAccessTypeEnum): CoUiChannelAccessTypeInfoUi {

        return this.coUiService.getChannelAccessTypeInfo(access);

    }

    confirmStartCall(e: Event, recipientAddress: CoChannelPhoneNumberDto) {
        this.confirmationService.confirm({
            message: recipientAddress.uiChannelAccessInfo.message,
            accept: () => {
                this.handleStartCall(recipientAddress)
            },
            acceptLabel: "Trotzdem anrufen",
            rejectLabel: "Abbrechen"
        });
    }
}
