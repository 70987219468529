@if (initialized && dto) {
    @if (uiMode == 'side') {
        <cm-contract-object-side-component
                [dto]="dto"
                [transientDto]="dto"
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cm-contract-object-side-component>
    } @else {
        <cm-contract-object-full-component
                [dto]="dto"
                [transientDto]="dto"
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cm-contract-object-full-component>
    }
}