import {Route, Routes} from "@angular/router";
import {DmConfigPage} from "./page/overview/dm-config/dm-config.page";
import {DmDashboardOverviewPage} from "./page/overview/dm-dashboard-overview/dm-dashboard-overview.page";
import {DmDocumentComponent} from "./component/dm-document/dm-document.component";
import {DmDocumentPage} from "./page/overview/dm-document/dm-document.page";
import {DmDashboardActionsPage} from "./page/overview/dm-dashboard-actions-page/dm-dashboard-actions.page";
import {DmDocumentProcessingComponent} from "./component/dm-document-processing/dm-document-processing.component";
import {DmDocumentUploadComponent} from "./component/dm-document-upload/dm-document-upload.component";
import {DmDocumentAnalyzeComponent} from "./component/dm-document-analyze/dm-document-analyze.component";
import {DmDocumentDashboardComponent} from "./component/dm-document-dashboard/dm-document-dashboard.component";
import {DmOverviewPage} from "./page/dm-overview-page/dm-overview.page";

export function DmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'dm', component: DmOverviewPage},
        {path: 'dm/dashboards', component: DmDashboardOverviewPage},
        {path: 'dm/actions', component: DmDashboardActionsPage},
        {path: 'dm/config', component: DmConfigPage},
        {path: 'dm/document', component: DmDocumentPage},
        {path: 'dm/test', component: DmDocumentComponent},
        {path: 'dm/document-process/:id', component: DmDocumentProcessingComponent},
        {path: 'dm/document-process/:id/:view', component: DmDocumentProcessingComponent},
        {path: 'dm/document-upload', component: DmDocumentUploadComponent},
        {path: 'dm/document-analyze', component: DmDocumentAnalyzeComponent},
        {path: 'dm/document-dashboard', component: DmDocumentDashboardComponent},

    ];

    return routes;

}
