import {WfProcessRuntimeDto} from "../../wf/service/dto/wf-process-runtime.dto";
import {WfProcessDto} from "../../wf/dto/wf-process.dto";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {WfProcessMetaDto} from "../../wf/service/dto/wf-process-meta.dto";
import {WfProcessStepMetaDto} from "../../wf/service/dto/wf-process-step-meta.dto";
import {WfProcessStepDto} from "../../wf/dto/wf-process-step.dto";
import {WfProcessStepActivityDto} from "../../wf/dto/wf-process-step-activity.dto";
import {AgentDto} from "../../am/dto/agent.dto";
import {AgentPoolDto} from "../../am/dto/agent-pool.dto";
import {WfProcessRuntimeBindingDto} from "../../wf/service/dto/wf-process-runtime-binding.dto";
import {WfActivityFormDefaultDto} from "../../wf/service/dto/wf-activity-form-default.dto";
import {WfProcessTypeStepHintDto} from "../../wf/dto/wf-process-type-step-hint.dto";
import {WfProcessTypeStepActivityDto} from "../../wf/dto/wf-process-type-step-activity.dto";
import {WfProcessTypeStepActivityObjectDto} from "../../wf/dto/wf-process-type-step-activity-object.dto";
import {TmTicketTypeDto} from "../../tm/dto/tm-ticket-type.dto";
import {TmUrgencyEnum} from "../../tm/enum/tm-urgency.enum";
import {TmStatusEnum} from "../../tm/enum/tm-status.enum";
import {WfProcessTypeFieldDto} from "../../wf/dto/wf-process-type-field.dto";
import {
    WfProcessTypeStepActivityDocumentActivityEnum
} from "../../wf/enum/wf-process-type-step-activity-document-activity-enum.enum";

export const MOCK_DATA: any = [
    {
        "id": 1,
        "text": "Sample Text",
        "amount": 1234.56,
        "numberInt": 100,
        "numberDecimal": 45.67,
        "slider": 50,
        "checkbox": true,
        "select": 1,
        "selectRadio": 2,
        "selectButton": 0,
        "selectRadioIcon": 1,
        "height": '180rem',
        "date": "2024-07-18",
        "dateBirth": "1990-07-18",
        "dateStart": "2024-07-18",
        "rating": 5,
        "icon": "fa fa-icon",
        "textarea": "Sample large text area",
        "color": "green-500",
        "editor": "<p>Some rich text content</p>",
        "phoneNumber": "123-456-7890",
        "mobileNumber": "987-654-3210",
        "dateTime": new Date(),
        "email": "daudkhan316@gmail.com",
        "iban": "DE89 3704 0044 0532 0130 00",
        "inputSwitch": false,
        "multiSelect": [0, 1],
        "selectHierarchy": 11,
        "multiSelectString": '1 2',
        "dateToday": "2024-09-20",
        "selectChip": 2,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    },
    {
        "id": 2,
        "text": "Another Sample Text",
        "amount": 7890.12,
        "numberInt": 250,
        "numberDecimal": 78.90,
        "slider": 70,
        "checkbox": false,
        "select": 2,
        "selectRadio": 1,
        "selectButton": 1,
        "selectRadioIcon": 2,
        "height": '180rem',
        "date": "2023-05-15",
        "dateBirth": "1985-05-15",
        "dateStart": "2023-05-15",
        "rating": 4,
        "icon": "fa fa-star",
        "textarea": "This is another large text area.",
        "color": "blue-500",
        "editor": "<p>Rich text content here.</p>",
        "phoneNumber": "111-222-3333",
        "mobileNumber": "555-666-7777",
        "dateTime": "2023-05-15",
        "email": "johnsmith@gmail.com",
        "iban": "FR14 2004 1010 0505 0001 3M02 606",
        "inputSwitch": true,
        "multiSelect": [1, 2],
        "selectHierarchy": 21,
        "multiSelectString": '1 4',
        "dateToday": "2024-09-24",
        "selectChip": 3,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    },
    {
        "id": 3,
        "text": "Sample Text Three",
        "amount": 3456.78,
        "numberInt": 300,
        "numberDecimal": 23.45,
        "slider": 40,
        "checkbox": true,
        "select": 3,
        "selectRadio": 3,
        "selectButton": 2,
        "selectRadioIcon": 0,
        "height": '180rem',
        "date": "2022-08-10",
        "dateBirth": "1982-08-10",
        "dateStart": "2022-08-10",
        "rating": 3,
        "icon": "fa fa-heart",
        "textarea": "Text content in large area three.",
        "color": "red-600",
        "editor": "<p>Some content in rich text.</p>",
        "phoneNumber": "321-654-9870",
        "mobileNumber": "123-789-4560",
        "dateTime": "2022-08-10",
        "email": "alexdoe@example.com",
        "iban": "ES91 2100 0418 4502 0005 1332",
        "inputSwitch": false,
        "multiSelect": [0, 2],
        "selectHierarchy": 15,
        "multiSelectString": '3 4',
        "dateToday": "2024-09-24",
        "selectChip": 1,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    },
    {
        "id": 4,
        "text": "Fourth Sample Text",
        "amount": 9101.23,
        "numberInt": 450,
        "numberDecimal": 12.34,
        "slider": 80,
        "checkbox": true,
        "select": 0,
        "selectRadio": 2,
        "selectButton": 1,
        "selectRadioIcon": 2,
        "height": '180rem',
        "date": "2021-03-20",
        "dateBirth": "1991-03-20",
        "dateStart": "2021-03-20",
        "rating": 5,
        "icon": "fa fa-user",
        "textarea": "This is the fourth large text area.",
        "color": "purple-400",
        "editor": "<p>Some interesting content.</p>",
        "phoneNumber": "444-555-6666",
        "mobileNumber": "111-222-3333",
        "dateTime": "2021-03-20",
        "email": "maryjane@example.com",
        "iban": "NL91 ABNA 0417 1643 00",
        "inputSwitch": true,
        "multiSelect": [1, 3],
        "selectHierarchy": 19,
        "multiSelectString": '3 1',
        "dateToday": "2024-09-24",
        "selectChip": 0,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    },
    {
        "id": 5,
        "text": "Text Sample Five",
        "amount": 5647.89,
        "numberInt": 150,
        "numberDecimal": 56.78,
        "slider": 30,
        "checkbox": false,
        "select": 4,
        "selectRadio": 1,
        "selectButton": 2,
        "selectRadioIcon": 0,
        "height": '180rem',
        "date": "2020-11-25",
        "dateBirth": "1995-11-25",
        "dateStart": "2020-11-25",
        "rating": 2,
        "icon": "fa fa-music",
        "textarea": "Fifth large text area example.",
        "color": "yellow-700",
        "editor": "<p>Example rich text content here.</p>",
        "phoneNumber": "888-999-0000",
        "mobileNumber": "777-888-9999",
        "dateTime": "2020-11-25",
        "email": "janedoe@example.com",
        "iban": "BE68 5390 0754 7034",
        "inputSwitch": false,
        "multiSelect": [0, 4],
        "selectHierarchy": 23,
        "multiSelectString": '2 3',
        "dateToday": "2024-09-24",
        "selectChip": 4,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    },
    {
        "id": 6,
        "text": "Sixth Sample Text",
        "amount": 1123.45,
        "numberInt": 500,
        "numberDecimal": 67.89,
        "slider": 90,
        "checkbox": true,
        "select": 1,
        "selectRadio": 0,
        "selectButton": 0,
        "selectRadioIcon": 1,
        "height": '180rem',
        "date": "2022-12-30",
        "dateBirth": "1988-12-30",
        "dateStart": "2022-12-30",
        "rating": 4,
        "icon": "fa fa-camera",
        "textarea": "Text in the sixth large area.",
        "color": "orange-300",
        "editor": "<p>Rich text content for example six.</p>",
        "phoneNumber": "999-111-2222",
        "mobileNumber": "555-333-4444",
        "dateTime": "2022-12-30",
        "email": "kevinhart@example.com",
        "iban": "IT60 X054 2811 1010 0000 0123 456",
        "inputSwitch": true,
        "multiSelect": [2, 3],
        "selectHierarchy": 17,
        "multiSelectString": '1 4',
        "dateToday": "2024-09-24",
        "selectChip": 2,
        "flexibleSearchDtoId": 2,
        "flexibleSearchDtoName": "Daud",
        "generalIndexSearchDtoId": 1,
        "generalIndexSearchDtoName": "Haris"
    }
]


export const MOCK_FORM: any = {

    "dtoOnCreate": {},

    "formFields":
        {
            "text": {
                "id": "text",
                "access": "update",
                "uiFieldGroup": "activityTest",
                "order": 1,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "text",
                "uiInputControl": "text",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "amount": {
                "id": "amount",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 2,
                "dataType": "java.math.BigDecimal",
                "uiLabel": "amount",
                "uiInputControl": "amount",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,


            },
            "numberInt": {
                "id": "numberInt",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 3,
                "dataType": "Long",
                "uiLabel": "numberInt",
                "uiInputControl": "numberInt",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,


            },
            "numberDecimal": {
                "id": "numberDecimal",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 4,
                "dataType": "java.math.BigDecimal",
                "uiLabel": "numberDecimal",
                "uiInputControl": "numberDecimal",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,


            },
            "slider": {
                "id": "slider",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 5,
                "dataType": "Long",
                "uiLabel": "slider",
                "uiInputControl": "slider",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,


            },
            "checkbox": {
                "id": "checkbox",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 6,
                "dataType": "Boolean",
                "uiLabel": "checkbox",
                "uiInputControl": "checkbox",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,


            },
            "select": {
                "id": "select",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 7,
                "dataType": "Enum",
                "uiLabel": "select",
                "uiInputControl": "select",
                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },

                "uiNoValueList": false,
                "uiSearchHelp": null,

                "primaryKey": false
            },
            "selectRadio": {
                "id": "selectRadio",
                "access": "update",
                "uiFieldGroup": "activityTest",

                "order": 8,
                "dataType": "Enum",
                "uiLabel": "selectRadio",
                "uiInputControl": "selectRadio",


                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false
            },
            "selectButton": {
                "id": "selectButton",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 9,
                "dataType": "Enum",
                "uiLabel": "selectButton",
                "uiInputControl": "selectButton",

                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false
            },
            "selectRadioIcon": {
                "id": "selectRadioIcon",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 10,
                "dataType": "Enum",
                "uiLabel": "selectRadioIcon",
                "uiInputControl": "selectRadioIcon",

                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false
            },
            // "height": {
            //   "id": "height",
            //   "access": "update",
            //   "uiFieldGroup": "activityTest",
            //
            //
            //   "order": 11,
            //   "dataType": "String",
            //   "uiLabel": "height",
            //   "uiInputControl": "height",
            //   "uiNoValueList": false,
            //   "uiSearchHelp": null,
            //   "primaryKey": false,
            //
            // },
            "date": {
                "id": "date",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 12,
                "dataType": "java.time.LocalDate",
                "uiLabel": "date",
                "uiInputControl": "date",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "dateBirth": {
                "id": "dateBirth",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 13,
                "dataType": "java.time.LocalDate",
                "uiLabel": "dateBirth",
                "uiInputControl": "dateBirth",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "dateStart": {
                "id": "dateStart",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 14,
                "dataType": "java.time.LocalDate",
                "uiLabel": "dateStart",
                "uiInputControl": "dateStart",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "rating": {
                "id": "rating",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 15,
                "dataType": "Long",
                "uiLabel": "rating",
                "uiInputControl": "rating",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "icon": {
                "id": "icon",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 16,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "icon",
                "uiInputControl": "icon",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "textarea": {
                "id": "textarea",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 17,
                "dataType": "String",
                "uiLabel": "textarea",
                "uiInputControl": "textarea",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "color": {
                "id": "color",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 18,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "color",
                "uiInputControl": "color",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "editor": {
                "id": "editor",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 19,
                "dataType": "String",
                "uiLabel": "editor",
                "uiInputControl": "editor",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            // "flexibleSearchDtoId": {
            //   "id": "flexibleSearchDtoId",
            //   "access": "update",
            //   "uiFieldGroup": "activityTest",
            //
            //   "order": 20,
            //   "dataType": "test.agentTestService",
            //   "relationship": "ManyToOne",
            //   "uiLabel": "flexibleSearch",
            //   "uiNoValueList": false,
            //   "uiSearchHelp": "",
            //   "primaryKey": false,
            //
            // },
            // "flexibleSearchDtoName": {
            //   "id": "flexibleSearchDtoName",
            //   "access": "hidden",
            //   "order": 20,
            //   "dataType": "String",
            //   "uiLabel": "text",
            //   "uiNoValueList": false,
            //   "primaryKey": false,
            //
            // },
            // "entityContextAttribute": {
            //   "id": "entityContextAttribute",
            //   "access": "update",
            //   "uiFieldGroup": "activityTest",
            //
            //   "order": 22,
            //   "dataType": "String",
            //   "uiLabel": "entityContextAttribute",
            //   "uiInputControl": "entityContextAttribute",
            //   "uiNoValueList": false,
            //   "uiSearchHelp": null,
            //   "primaryKey": false,
            //
            // },
            "phoneNumber": {
                "id": "phoneNumber",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 24,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "phoneNumber",
                "uiInputControl": "phoneNumber",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "mobileNumber": {
                "id": "mobileNumber",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 25,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "mobileNumber",
                "uiInputControl": "mobileNumber",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "dateTime": {
                "id": "dateTime",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 26,
                "dataType": "java.time.LocalDate",
                "uiLabel": "dateTime",
                "uiInputControl": "dateTime",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "email": {
                "id": "email",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 27,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "email",
                "uiInputControl": "email",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "iban": {
                "id": "iban",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 28,
                "dataType": "String",
                "uiStringMaxLength": 255,
                "uiLabel": "iban",
                "uiInputControl": "iban",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "inputSwitch": {
                "id": "inputSwitch",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 29,
                "dataType": "Boolean",
                "uiLabel": "inputSwitch",
                "uiInputControl": "inputSwitch",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "multiSelect": {
                "id": "multiSelect",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 30,
                "dataType": "Enum",
                "uiLabel": "multiSelect",
                "uiInputControl": "multiSelect",

                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false
            },
            // "generalIndexSearchDtoId": {
            //   "id": "generalIndexSearchDtoId",
            //   "access": "update",
            //   "uiFieldGroup": "activityTest",
            //
            //   "order": 31,
            //   "dataType": "test.agentTestService",
            //   "uiLabel": "generalIndexSearch",
            //   "relationship": "ManyToOne",
            //   "uiNoValueList": false,
            //   "uiSearchHelp": "",
            //   "primaryKey": false,
            //
            // },
            // "generalIndexSearchDtoName": {
            //   "id": "generalIndexSearchDtoName",
            //   "access": "hidden",
            //   "order": 31,
            //   "dataType": "String",
            //   "uiLabel": "generalIndexSearch",
            //   "uiNoValueList": false,
            //   "uiSearchHelp": "",
            //   "primaryKey": false,
            //
            // },
            "selectHierarchy": {
                "id": "selectHierarchy",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 32,
                "dataType": "Enum",
                "uiLabel": "selectHierarchy",
                "uiInputControl": "selectHierarchy",

                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 1,
                            "label": "Option 1",
                            "technicalKey": null,
                            "priority": 0,
                            "image": "fa-sharp fa-solid fa-bolt-lightning"
                        },
                        {
                            "key": 2,
                            "label": "Option 2",
                            "technicalKey": null,
                            "priority": 0,
                            "image": "fa-sharp fa-solid fa-bolt-lightning"
                        },
                        {
                            "key": 3,
                            "label": "Option 3",
                            "technicalKey": null,
                            "priority": 0,
                            "image": "fa-solid fa-comment-xmark"
                        },
                        {
                            "key": 4,
                            "label": "Option 4",
                            "technicalKey": null,
                            "priority": 0,
                            "image": "fa-sharp fa-solid fa-bolt-lightning"
                        },
                    ]
                },
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false
            },
            // "multiSelectString": {
            //   "id": "multiSelectString",
            //   "access": "update",
            //   "uiFieldGroup": "activityTest",
            //
            //   "order": 33,
            //   "dataType": "Enum",
            //   "uiLabel": "multiSelectString",
            //   "uiInputControl": "multiSelectString",
            //
            //   "valueList": {
            //     "hasHierarchy": false,
            //     "entries": [
            //       {
            //         "key": 1,
            //         "label": "Option 1",
            //         "technicalKey": null,
            //         "priority": 0,
            //         "image": "fa-sharp fa-solid fa-bolt-lightning"
            //       },
            //       {
            //         "key": 2,
            //         "label": "Option 2",
            //         "technicalKey": null,
            //         "priority": 0,
            //         "image": "fa-sharp fa-solid fa-bolt-lightning"
            //       },
            //       {
            //         "key": 3,
            //         "label": "Option 3",
            //         "technicalKey": null,
            //         "priority": 0,
            //         "image": "fa-solid fa-comment-xmark"
            //       },
            //       {
            //         "key": 4,
            //         "label": "Option 4",
            //         "technicalKey": null,
            //         "priority": 0,
            //         "image": "fa-sharp fa-solid fa-bolt-lightning"
            //       },
            //     ]
            //   },
            //   "uiNoValueList": false,
            //   "uiSearchHelp": null,
            //   "primaryKey": false
            // },
            "dateToday": {
                "id": "dateToday",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 34,
                "dataType": "java.time.LocalDate",
                "uiLabel": "dateToday",
                "uiInputControl": "dateToday",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

            },
            "selectChip": {
                "id": "selectChip",
                "access": "update",
                "uiFieldGroup": "activityTest",


                "order": 35,
                "dataType": "Enum",
                "uiLabel": "selectChip",
                "uiInputControl": "selectChip",
                "uiNoValueList": false,
                "uiSearchHelp": null,
                "primaryKey": false,

                "valueList": {
                    "hasHierarchy": false,
                    "entries": [
                        {
                            "key": 0,
                            "label": "Multiple",
                            "technicalKey": "open",
                            "priority": 0,
                            "image": "fa-solid fa-folder-open",
                            "color": "blue-800",
                            "backgroundColor": "blue-200"
                        },
                        {
                            "key": 1,
                            "label": "Yes",
                            "technicalKey": "yes",
                            "priority": 1,
                            "image": "fa-solid fa-check",
                            "color": "green-800",
                            "backgroundColor": "green-200"
                        },
                        {
                            "key": 2,
                            "label": "None",
                            "technicalKey": "no",
                            "priority": 2,
                            "image": "fa-solid fa-times",
                            "color": "red-800",
                            "backgroundColor": "red-200"
                        }
                    ]
                },
            },


        },
    "formActions": {
        "DELETE": {
            "action": "DELETE",
            "visible": true,
            "enabled": true
        },
        "DISPLAY": {
            "action": "DISPLAY",
            "visible": true,
            "enabled": true
        },
        "CREATE": {
            "action": "CREATE",
            "visible": true,
            "enabled": true
        },
        "UPDATE": {
            "action": "UPDATE",
            "visible": true,
            "enabled": true
        }
    },
    "formFieldGroups": [
        {
            "name": "AuditGroup",
            "priority": 900,
            "showPanel": null
        },
        {
            "name": "activityTest",
            "priority": 500,
            "formLocation": 0,
            "label": "activityTest"
        }
    ],
    "formComplete": true,
    "objectType": "test.activityTest"
};


export const MOCK_ERRORS = [
    {
        "codes": [
            "required.text",
            "notEmpty.text",
            "mandatory.text",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Text field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "text",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.amount",
            "notNull.amount",
            "mandatory.amount",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Amount field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "amount",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.numberInt",
            "invalidNumber.numberInt",
            "mandatory.numberInt",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Number Integer field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "numberInt",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.numberDecimal",
            "invalidDecimal.numberDecimal",
            "mandatory.numberDecimal",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Number Decimal field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "numberDecimal",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.slider",
            "outOfRange.slider",
            "mandatory.slider",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Slider field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "slider",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.checkbox",
            "mustBeTrue.checkbox",
            "mandatory.checkbox",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Checkbox field must be selected.",
        "objectName": "test.errorOnFieldTest",
        "field": "checkbox",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.select",
            "invalidSelection.select",
            "mandatory.select",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "select",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.selectRadio",
            "invalidOption.selectRadio",
            "mandatory.selectRadio",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select Radio field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "selectRadio",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.selectButton",
            "invalidChoice.selectButton",
            "mandatory.selectButton",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select Button field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "selectButton",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.selectRadioIcon",
            "invalidIcon.selectRadioIcon",
            "mandatory.selectRadioIcon",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select Radio Icon field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "selectRadioIcon",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    // {
    //   "codes": [
    //     "required.height",
    //     "invalidFormat.height",
    //     "mandatory.height",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The Height field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "height",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    {
        "codes": [
            "required.date",
            "invalidDate.date",
            "mandatory.date",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Date field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "date",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "wrong"
    },
    {
        "codes": [
            "required.dateBirth",
            "invalidDate.dateBirth",
            "mandatory.dateBirth",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Date of Birth field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "dateBirth",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.dateStart",
            "invalidDate.dateStart",
            "mandatory.dateStart",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Start Date field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "dateStart",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.rating",
            "invalidRating.rating",
            "mandatory.rating",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Rating field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "rating",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.icon",
            "invalidIcon.icon",
            "mandatory.icon",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Icon field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "icon",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.textarea",
            "notEmpty.textarea",
            "mandatory.textarea",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Textarea field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "textarea",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.color",
            "invalidColor.color",
            "mandatory.color",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Color field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "color",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.editor",
            "invalidEditor.editor",
            "mandatory.editor",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Editor field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "editor",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    // {
    //   "codes": [
    //     "required.flexibleSearchDtoId",
    //     "invalidReference.flexibleSearchDtoId",
    //     "mandatory.flexibleSearchDtoId",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The Flexible Search DTO ID field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "flexibleSearchDtoId",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    // {
    //   "codes": [
    //     "required.flexibleSearchDtoName",
    //     "notEmpty.flexibleSearchDtoName",
    //     "mandatory.flexibleSearchDtoName",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The Flexible Search DTO Name field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "flexibleSearchDtoName",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    // {
    //   "codes": [
    //     "required.entityContextAttribute",
    //     "invalidAttribute.entityContextAttribute",
    //     "mandatory.entityContextAttribute",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The Entity Context Attribute field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "entityContextAttribute",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    {
        "codes": [
            "required.phoneNumber",
            "invalidFormat.phoneNumber",
            "mandatory.phoneNumber",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Phone Number field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "phoneNumber",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.mobileNumber",
            "invalidFormat.mobileNumber",
            "mandatory.mobileNumber",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Mobile Number field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "mobileNumber",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.dateTime",
            "invalidDateTime.dateTime",
            "mandatory.dateTime",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The DateTime field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "dateTime",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.email",
            "invalidEmail.email",
            "mandatory.email",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Email field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "email",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.iban",
            "invalidIban.iban",
            "mandatory.iban",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The IBAN field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "iban",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.inputSwitch",
            "invalidSwitch.inputSwitch",
            "mandatory.inputSwitch",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Input Switch field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "inputSwitch",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.multiSelect",
            "invalidSelection.multiSelect",
            "mandatory.multiSelect",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Multi Select field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "multiSelect",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    // {
    //   "codes": [
    //     "required.generalIndexSearchDtoId",
    //     "invalidReference.generalIndexSearchDtoId",
    //     "mandatory.generalIndexSearchDtoId",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The General Index Search DTO ID field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "generalIndexSearchDtoId",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    // {
    //   "codes": [
    //     "required.generalIndexSearchDtoName",
    //     "notEmpty.generalIndexSearchDtoName",
    //     "mandatory.generalIndexSearchDtoName",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The General Index Search DTO Name field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "generalIndexSearchDtoName",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    {
        "codes": [
            "required.selectHierarchy",
            "invalidHierarchy.selectHierarchy",
            "mandatory.selectHierarchy",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select Hierarchy field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "selectHierarchy",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    // {
    //   "codes": [
    //     "required.multiSelectString",
    //     "invalidSelection.multiSelectString",
    //     "mandatory.multiSelectString",
    //     "required"
    //   ],
    //   "arguments": null,
    //   "defaultMessage": "The Multi Select String field is required.",
    //   "objectName": "test.errorOnFieldTest",
    //   "field": "multiSelectString",
    //   "rejectedValue": null,
    //   "bindingFailure": false,
    //   "code": "required"
    // },
    {
        "codes": [
            "required.dateToday",
            "invalidDate.dateToday",
            "mandatory.dateToday",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Date Today field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "dateToday",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    },
    {
        "codes": [
            "required.selectChip",
            "invalidChip.selectChip",
            "mandatory.selectChip",
            "required"
        ],
        "arguments": null,
        "defaultMessage": "The Select Chip field is required.",
        "objectName": "test.errorOnFieldTest",
        "field": "selectChip",
        "rejectedValue": null,
        "bindingFailure": false,
        "code": "required"
    }
]

// export const PROCESS_DTO: Partial<WfProcessDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const META_DTO: Partial<WfProcessMetaDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const STEP_META_DTO: Partial<WfProcessStepMetaDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_DTO: Partial<WfProcessStepDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_ACTIVITY_DTO: Partial<WfProcessStepActivityDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_AGENT_DTO: Partial<AgentDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_AGENT_POOL_DTO: Partial<AgentPoolDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_BINDING_DTO: Partial<WfProcessRuntimeBindingDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const ACTIVE_STEP_FORM_DEFAULTS_DTO: Partial<WfActivityFormDefaultDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
//
// export const ACTIVE_STEP_HINTS_DTO: Partial<WfProcessTypeStepHintDto> = {
//
//     "id": 138,
//     "createdBy": "um:185:daudkhan316@gmail.com",
//     "createdDate": new Date("2024-10-15T08:02:24.250717Z"),
//     "lastModifiedBy": "um:185:daudkhan316@gmail.com",
//     "lastModifiedDate": new Date("2024-10-15T08:02:24.262453Z"),
//     "name": "PRC0000138",
//     "typeDtoId": 17,
//     "processStatusInternal": 0
//
//
// }
//
// export const RUNTIME_DTO: Partial<WfProcessRuntimeDto> = {
//     process: PROCESS_DTO,
//     meta: META_DTO,
//     stepMeta: STEP_META_DTO,
//
//     activeStep: ACTIVE_STEP_DTO,
//     activeStepActivity: ACTIVE_STEP_ACTIVITY_DTO,
//
//     activeStepAgent: ACTIVE_STEP_AGENT_DTO,
//     activeStepAgentPool: ACTIVE_STEP_AGENT_POOL_DTO,
//
//     activeStepBindings: [ACTIVE_STEP_BINDING_DTO],
//     activeStepFormDefaults: [ACTIVE_STEP_FORM_DEFAULTS_DTO],
//     activeStepHints: [ACTIVE_STEP_HINTS_DTO]
// }


// WfProcessTypeStepActivityDto


export const WF_PROCESS_TYPE_STEP_ACTIVITY_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY CREATE",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO CREATE</p>",
    "activityObjectDto": {
        "processMultiple": false,
        "objectShowList": false,
        "objectTypeDtoId": 101,
        "activityEnum": 0
    },
    "priority": 20,
    "activityType": 0,

}

export const WF_PROCESS_TYPE_STEP_ACTIVITY_DTO_UPDATE: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY UPDATE",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO UPDATE</p>",
    "activityObjectDto": {
        "processMultiple": false,
        "objectShowList": false,
        "objectTypeDtoId": 101,
        "activityEnum": 2
    },
    "priority": 20,
    "activityType": 0,

}

export const BINDINGS: any = {
    "alias": "objectProcess",
    "objectBinding": true,
    "objectType": {
        "id": 101,
        "name": "Test",
        "alias": "test.activityTest"
    },
    "objectId": 2,
    "simpleValue": null
}

export const FORM_DEFAULTS: any = {
    "fieldName": "text",
    "entityFieldName": null,
    "defaultValue": true,
    "fieldValue": "HELLO GOOD TEXT",
    "fieldValueIsNull": null,
    "valueType": null,
    "fieldAccess": 0,
    "includeInFilter": null
}

export const WF_PROCESS_TYPE_STEP_ACTIVITY_SEARCH_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY SEARCH",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO SEARCH</p>",
    "activityObjectDto": {
        "processMultiple": false,
        "objectShowList": false,
        "objectTypeDtoId": 101,
        "objectTypeAlias": 'cr.Customer',
        "activityEnum": 0
    },
    "priority": 20,
    "activityType": 0,

}

export const WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY TICKET",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO TICKET</p>",
    "activityTicketCreateDto": {
        "ticketTypeDtoId": 3,
    },
    "priority": 20,
    "activityType": 4,

}

export const WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_STATUS_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY TICKET STATUS",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO TICKET STATUS</p>",
    "activityTicketStatusDto": {
        "processFieldTicketDtoId": 97,
        "status": 3,
    },
    "priority": 20,
    "activityType": 7,

}

export const WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY DOCUMENT",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO DOCUMENT</p>",
    "activityDocumentDto": {
        "activityEnum": 0,
        "documentTypeUploadDtoId": 57,
    },
    "priority": 20,
    "activityType": 6,

}


export const BINDINGS_DOCUMENT_DTO: any = {

    "alias": "importReferenceObject",
    "objectBinding": true,
    "objectType": {
        "id": 101,
        "name": "Test",
        "alias": "cr.Customer"
    },
    "objectValue": {
        'id':12929
    },
    "objectId": 12929,
    "simpleValue": null

}



export const WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_LIST_DTO: any = {

    "activityMandatory": false,
    "name": "TEST ACTIVITY DOCUMENT LIST",
    "processing": 1,
    "description": "<p>TEST ACTIVITY WITH TEST DATA TO DOCUMENT LIST</p>",
    "activityDocumentDto": {
        "activityEnum": 1,
        "documentTypeUploadDtoId": 57,
    },
    "priority": 20,
    "activityType": 6,

}


