import {Route, Routes} from "@angular/router";
import {ObjectTypePage} from "./page/overview/object-type-page/object-type.page";
import {EntityOverviewPage} from "./page/overview/entity-overview-page/entity-overview.page";
import {ObjectTypeMetaExtensionPage} from "./page/overview/object-type-meta-extension/object-type-meta-extension.page";
import {ConfigComponent} from "./page/overview/config-page/config.component";
import {CcOverviewPage} from "./page/cc-overview-page/cc-overview.page";

export function CcSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'cc', component: CcOverviewPage},
        {path: 'cc/config', component: ConfigComponent},
        {path: 'cc/objectTypes', component: ObjectTypePage},
        {path: 'cc/entities', component: EntityOverviewPage},
        {path: 'cc/metaExtension', component: ObjectTypeMetaExtensionPage},

    ];

    return routes;

}
