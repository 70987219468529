import {Component, OnInit} from "@angular/core";
import {FieldTypeBaseComponent} from "../field-type-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'field-type-side',
    templateUrl: 'field-type-side.component.html',
})

export class FieldTypeSideComponent extends FieldTypeBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'fieldType',
            action: 'fieldType',
            icon: 'fa-regular fa-info-circle',
            tooltip: 'Field Type',
            default: true
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}