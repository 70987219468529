<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-3">
            <div>
                <mvs-widget *ngIf="appointmentTypeWidget" [widgetData]="appointmentTypeWidget"></mvs-widget>
            </div>
            <div>
                <mvs-widget *ngIf="appointmentResultWidget" [widgetData]="appointmentResultWidget"></mvs-widget>
            </div>
        </div>
        <div class="col-9">
            <mvs-widget *ngIf="appointmentsWidget" [widgetData]="appointmentsWidget"></mvs-widget>
        </div>
    </div>
</ng-container>