import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {
  CmContractNotificationTypeObjectComponent
} from "../../components/cm-contract-notification-type-object/cm-contract-notification-type-object.component";

@Injectable({
  providedIn: 'root'
})
export class ContractNotificationTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/contractNotificationTypes')
  }


  /**
   * Get Object Component.
   * @param mode
   */
  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update) : Type<any> {

    if (mode == MvsCrudModeEnum.update || mode == MvsCrudModeEnum.read) {
      return CmContractNotificationTypeObjectComponent;
    }

    return null;
  }


}