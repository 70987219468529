import {DtoDetail} from "@kvers/alpha-core-common";
import { CallTypeDto } from "./call-type.dto";
import { CallRecipientTypeEnum } from "../enum/call-recipient-type-enum.enum";
import { CallResultEnum } from "../enum/call-result-enum.enum";
import {AgentDto} from "../../am/dto/agent.dto";
import {ObjectTypeDto} from "../../cc/dto/object-type.dto";

export class CallDto extends DtoDetail{


    public callTypeDto? : CallTypeDto;
    public callTypeDtoId : number;
    public callTypeDtoName : string;
    public agentDto? : AgentDto;
    public agentDtoId : number;
    public agentDtoName : string;
    public callRecipientType: CallRecipientTypeEnum;
    public objectTypeDto? : ObjectTypeDto;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectId: number;
    public phoneNumber: string;
    public reason: string;
    public startTime: Date;
    public endTime: Date;
    public durationInSeconds: number;
    public callResult: CallResultEnum;

}