<!--<date-selection-dropdown-->
<!--        [defaultOption]="'thisMonth'"-->
<!--        (selectedDateStart)="handleDateStart($event)"-->
<!--&gt;</date-selection-dropdown>-->

<!--<mvs-widget-->
<!--        [widgetData]="objectWidget"-->
<!--&gt;</mvs-widget>-->


<!--<mvs-widget-->
<!--        [widgetData]="ticketTypeWidget"-->
<!--&gt;</mvs-widget>-->


<!--<mvs-widget-->
<!--        [widgetData]="ticketWidget"-->
<!--&gt;-->
<!--    <ng-template let-object="object" mvs-widget-custom-field-directive label="User Access"-->
<!--                 fieldId="userAccess">-->

<!--        <mvs-user-object-access-output-component [objectType]="'tm.Ticket'"  [objectId]="object.dto.id">-->
<!--        </mvs-user-object-access-output-component>-->

<!--    </ng-template>-->
<!--</mvs-widget>-->

<!--<mvs-widget [widgetData]="formWidget"></mvs-widget>-->

<div class="grid">
    <div class="col-4">
        <p-button label="new entry" (click)="showticketNewWidget()"></p-button>
        <mvs-widget *ngIf="ticketNewWidget" [widgetData]="ticketNewWidget"></mvs-widget>
    </div>
    <div class="col-4">
        <p-button label="on refresh" (click)="showticketViewWidget()"></p-button>
        <mvs-widget *ngIf="ticketViewWidget" [widgetData]="ticketViewWidget"></mvs-widget>
    </div>
    <div class="col-4">
        <p-button label="edit enrty" (click)="showticketEditWidget()"></p-button>
        <mvs-widget *ngIf="ticketEditWidget" [widgetData]="ticketEditWidget"></mvs-widget>
    </div>
</div>



<!--<ticket-complete-form [ticketId]="136"></ticket-complete-form>-->