<ng-container *ngIf="!objectId">

    <iframe
            *ngIf="pdfSrc"
            [src]="pdfSrc"
            [width]="width"
            [height]="height"></iframe>

</ng-container>

<ng-container *ngIf="objectId">

    <object
            class="w-full"
            height="500px"
            [data]="pdfSrc"
            type="application/pdf"
            *ngIf="pdfSrc">

        <embed
                [src]="pdfSrc"
                type="application/pdf"/>
    </object>

</ng-container>
