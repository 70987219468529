<div *ngIf="initialized" class="surface-0">
    <mvs-config-header header="Kunden Affiliate Programm" [categoryTypeMode]="'technical'" icon="fa-solid fa-puzzle-piece">
    </mvs-config-header>


    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Konfiguration" leftIcon="fa-solid fa-diagram-project"></p-tabPanel>
        <p-tabPanel header="Zugriffe" leftIcon="fa-solid fa-cubes"></p-tabPanel>
        <!--
        <p-tabPanel header="Kundenwahl" leftIcon="fa-solid fa-square"></p-tabPanel>
        <p-tabPanel header="Kundenauswahl" leftIcon="fa-solid fa-list-ul"></p-tabPanel>
        -->
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid mx-0">
            <div class="col-4">
                <h4 class="px-3">Typ</h4>
                <mvs-widget
                        *ngIf="widgetReferralType"
                        [widgetData]="widgetReferralType"
                        (onObjectSelect)="handleReferralTypeSelect($event)"
                ></mvs-widget>
            </div>
            <div class="col-4">
                <h4>Level</h4>
                <mvs-widget
                        *ngIf="widgetReferralTypeLevel && selectedReferralType"
                        [widgetData]="widgetReferralTypeLevel"
                        (onObjectSelect)="handleReferralTypeLevelSelect($event)"
                        [importObjectContext]="importContextReferralType"
                ></mvs-widget>
            </div>
            <div class="col-4">
                <h4>Optionen</h4>
                <mvs-widget
                        *ngIf="widgetReferralTypeLevelOption && selectedReferralTypeLevel"
                        [widgetData]="widgetReferralTypeLevelOption"
                        [importObjectContext]="importContextReferralTypeLevel"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <!--
    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-8">
                <mvs-widget
                        *ngIf="widgetField"
                        [widgetData]="widgetField"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">
        <div class="grid">
            <div class="col-8">
                <mvs-widget
                        *ngIf="widgetFieldType"
                        [widgetData]="widgetFieldType"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==3">

        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="widgetFieldValueList"
                        [widgetData]="widgetFieldValueList"
                        (onObjectSelect)="handleFieldValueListSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-4">

                <mvs-widget
                        *ngIf="widgetFieldValueListEntry"
                        [widgetData]="widgetFieldValueListEntry"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==4">

        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="widgetObjectType"
                        [widgetData]="widgetObjectType"
                        (onObjectSelect)="handleObjectTypeSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-4">

                <mvs-widget
                        *ngIf="widgetObjectTypeAttribute"
                        [widgetData]="widgetObjectTypeAttribute"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>
    -->

</div>