<div *ngIf="initialized">
    <mvs-config-header header="Termine und Microsoft Bookings Integration"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Termingruppen" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Buchungsarten" leftIcon="fa-thin fa-ballot-check"></p-tabPanel>
        <p-tabPanel header="Terminarten" leftIcon="fa-thin fa-calendar"></p-tabPanel>
        <p-tabPanel header="Microsoft Bookings" leftIcon="fa-brands fa-microsoft"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-6">
                <mvs-widget
                        *ngIf="widgetAppointmentGroup"
                        [widgetData]="widgetAppointmentGroup"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-6">
                <mvs-widget
                        *ngIf="widgetScheduleType"
                        [widgetData]="widgetScheduleType"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">

        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="widgetAppointmentType"
                        [widgetData]="widgetAppointmentType"
                        (onObjectSelect)="onObjectSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-4">

                <mvs-widget
                    *ngIf="widgetAppointmentTypeScheduleType"
                    [widgetData]="widgetAppointmentTypeScheduleType"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>


    <ng-container *ngIf="activeIndex==3">
        <div class="grid">
            <div class="col-6">
                <mvs-widget
                    *ngIf="widgetMsBookingType"
                    [widgetData]="widgetMsBookingType"
                    (onObjectSelect)="onMsBookingTypeSelect($event)"
                ></mvs-widget>
            </div>
            <div class="col-6">
                <as-ms-business-staff-members
                        *ngIf="selectedMsBookingType"
                        [msBookingTypeId]="selectedMsBookingType.id"
                ></as-ms-business-staff-members>
                <br />
                <as-ms-book
                        *ngIf="selectedMsBookingType"
                        [msBookingTypeId]="selectedMsBookingType.id"
                ></as-ms-book>
            </div>
        </div>
    </ng-container>

</div>