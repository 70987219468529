<ng-container *ngIf="dto">

        <div class="w-full mt-3" [formGroup]="formGroup" *ngIf="formGroup">
            <mvs-form-field-toggleable
                    [formGroup]="formGroup"
                    defaultState="display"
                    [formField]="formFieldComment"
                    (onSave)="handleComment($event)"
                    [showUpdateButton]="true"
                    [enableDblClick]="true"
                    [value]="dto.comment">
            </mvs-form-field-toggleable>

        </div>

</ng-container>