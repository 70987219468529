import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
    selector: 'cm-contract-document-upload',
    templateUrl: './cm-contract-document-upload.component.html',
    styleUrls: ['./cm-contract-document-upload.component.scss']
})
export class CmContractDocumentUploadComponent implements OnInit, OnChanges {


    @Input() objectIdentifier: ObjectIdentifier;

    constructor() {
    }

    ngOnInit(): void {
    }


    ngOnChanges(changes: SimpleChanges): void {

    }

}
