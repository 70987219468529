import { Component, OnInit } from '@angular/core';
import {
  MvsCoreService,
  MvsMessageService,
  ObjectRequestList,
  PageComponent,
  PagingDto,
  WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../../service/api/customer.service";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-cr-contact-page',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss']
})
export class ConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  defaultLabel: string = "Customer Related Persons Config";
  widgetCustomerCategoryType: WidgetData;

  batchBusy: boolean = false;

  constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService, protected customerService: CustomerService, protected messageService: MvsMessageService) {
    super(route, coreService);
  }

  batchUpdateNames() {

    if (this.batchBusy) {
      return;
    }
    this.batchBusy= true;

    this.customerService.executeBatchUpdateNames().subscribe(value => {

      this.messageService.showSuccessMessage("Batch", "Kundennamen wurden erfolgreich aktualisiert!");
      this.batchBusy = false;
    }, error => {
      this.messageService.showErrorMessage("Batch", "Fehler aufgetreten!");
      this.batchBusy = false;
    });

  }


  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries': [
        {
          'id': 1,
          'objectAlias': 'cr.CustomerRelatedPersonType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Kunde zu Person Typen (verwandte Personen)",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 2,
          'objectAlias': 'cr.CustomerConsultingType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Beratungstyp",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 3,
          'objectAlias': 'cr.CustomerCategoryType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Kundenkategorie",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 4,
          'objectAlias': 'cr.CustomerBrokerPoaType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Maklervertragstyp",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 5,
          'objectAlias': 'cr.CustomerBrokerPoaTypeReason',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Maklervertragstyp - Grund",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 6,
          'objectAlias': 'cr.CustomerType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Kundentyp",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 7,
          'objectAlias': 'cr.CustomerNotificationType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Notifications 2",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
        {
          'id': 8,
          'objectAlias': 'cr.CustomerCategoryType',
          'level': 1,
          'parentBinding': 'none',
          'dummyParentCreate': true,
          'dummyParentLabel': "Customer Category Type",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Anlegen"
        },
      ]

    }

    super.ngOnInit();
    this.refreshCustomerCategoryType();
  }



  refreshCustomerCategoryType() {
    this.widgetCustomerCategoryType = WidgetFactory.createWidgetList(
        "cr.customer.category.type.widget.simple",
        'Kategorie',
        'list',
        'list',
        'entity',
        'cr.CustomerCategoryType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

}
