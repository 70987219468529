import {Injectable, Type} from '@angular/core';
import {DtoDetail, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiServiceMyInterface} from "@kvers/alpha-core-common";
import {Observable, of, tap} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {
  AmSearchFlexibleAgentPoolComponent
} from "../../search/am-search-flexible-agent-pool/am-search-flexible-agent-pool.component";
import {AgentPoolDto} from "../../dto/agent-pool.dto";

@Injectable({
  providedIn: 'root'
})
export class AgentPoolService extends MvsCrudService implements MvsApiServiceMyInterface{

  private cachedAgentPools: DtoList<AgentPoolDto> | null = null; // Cache storage

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/am/agentPools');
  }

  // my(): Observable<DtoList<AgentPoolDto>> {
  //   const url = `${this.apiUrl}/my`;
  //   return <Observable<DtoList<AgentPoolDto>>>this.getList(url);
  // }

  my(): Observable<DtoList<AgentPoolDto>> {
    if (this.cachedAgentPools) {
      // Return cached data if already fetched
      return of(this.cachedAgentPools);
    }

    // Otherwise, fetch from API and store in cache
    const url = `${this.apiUrl}/my`;
    return this.getList(url).pipe(
        tap((agentPools: DtoList<AgentPoolDto>) => {
          this.cachedAgentPools = agentPools as DtoList<AgentPoolDto>;
        })
    );
  }

  getFlexibleSearchComponent(): Type<any> {
    return AmSearchFlexibleAgentPoolComponent;
  }

}
