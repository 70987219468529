<p>Hello World</p>







<p-card styleClass="p-shadow-2 p-m-2 p-p-3" style="display: flex; align-items: center;">
    <div style="display: flex; align-items: center;">
        <div>
            <i class="fa fa-briefcase" style="font-size: 2rem; color: green; margin-right: 10px;"></i>
        </div>
        <div>
            <h3 style="margin: 0;">{{counts.total}} Jobs</h3>
            <p style="margin: 0; color: gray;">{{counts.running}} Running, {{counts.errors}} Errors</p>
        </div>
    </div>
</p-card>
