import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {
    FieldTypeObjectBaseComponent
} from "../../component/object-component/field-type-object-component/field-type-object-base.component";

@Injectable({
    providedIn: 'root'
})
export class FieldTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/fieldTypes');
    }


    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {

        if (mode != MvsCrudModeEnum.create) {
            return FieldTypeObjectBaseComponent;
        }

        return null;
    }

}
