import {Component, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {WidgetData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ns-notification-type-page',
    templateUrl: './ns-config.page.html',
})
export class NsConfigPage extends PageComponent implements OnInit {

    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Notification Configuration";

    nsNotificationGroupWidget: WidgetData;
    nsNotificationTypeWidget: WidgetData;
    nsNotificationTypeChannelWidget: WidgetData;
    nsMicrosoftTeamsConfiguration: WidgetData;

    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;

    widgetEntries: TabEntries[] = [
        {label: 'Notification Groups', id: 'ns.NsNotificationGroup', type: ''},
        {label: 'Notification Types', id: 'ns.NsNotificationType', type: ''},
        {label: 'Notification Type Channels', id: 'ns.NsNotificationTypeChannelType', type: ''},
        {label: 'MS Teams', id: 'ns.MicrosoftTeamsConfiguration', type: ''},
    ];

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
        this.handleTabSelection(0);

        // Main Structure
        // this.objectBrowserRequest = {
        //
        //   "extractDto": false,
        //   "createMode": true,
        //
        //   'entries' : [
        //     {
        //       'id'                : 1,
        //       'objectAlias'       : 'ns.NsNotificationGroup',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Notification Groups",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Neue Gruppe anlegen"
        //     },
        //     {
        //       'id'                : 2,
        //       'objectAlias'       : 'ns.NsNotificationType',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Notification Types",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Neuen Typ anlegen"
        //     },
        //     {
        //       'id'                : 3,
        //       'objectAlias'       : 'ns.NsNotificationTypeChannelType',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Notification Type Channels",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Anlegen"
        //     }
        //   ]
        // };

    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            if (params['tid']) {
                const object = this.widgetEntries.find(item => item.id == params['tid']);
                if (object) {
                    this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
                    this.activeWidget = object;
                }
            }
        });
    }

    refreshWidgets() {
        this.refreshNsNotificationGroupWidget();
        this.refreshNsNsNotificationTypeWidget();
        this.refreshNsNotificationTypeChannelWidget();
        this.refreshNsMicrosoftTeamsConfigurationWidget();
    }

    refreshNsMicrosoftTeamsConfigurationWidget() {
        this.nsMicrosoftTeamsConfiguration = WidgetFactory.createWidgetList(
            "ns.notification.group.widget.simple.MicrosoftTeamsConfiguration",
            'Microsoft Teams Configuration',
            'table',
            'list',
            'entity',
            'ns.NsMicrosoftTeamsConfiguration',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshNsNotificationGroupWidget() {
        this.nsNotificationGroupWidget = WidgetFactory.createWidgetList(
            "ns.notification.group.widget.simple",
            'Notification Groups',
            'table',
            'list',
            'entity',
            'ns.NsNotificationGroup',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshNsNsNotificationTypeWidget() {
        this.nsNotificationTypeWidget = WidgetFactory.createWidgetList(
            "ns.notification.type.widget.simple",
            'Notification Type',
            'table',
            'list',
            'entity',
            'ns.NsNotificationType',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshNsNotificationTypeChannelWidget() {
        this.nsNotificationTypeChannelWidget = WidgetFactory.createWidgetList(
            "ns.notification.type.channel.widget.simple",
            'Notification Type Channels',
            'table',
            'list',
            'entity',
            'ns.NsNotificationTypeChannelType',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.widgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateSelect(event: ObjectIdentifierData) {
        this.objectType = event.objectType;
        this.objectId = event.objectId;
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    isConfigurationPage(): boolean {
        return true;
    }

}
