import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {WfProcessTypeFieldDto} from "../../../../wf/dto/wf-process-type-field.dto";
import {WfProcessTypeDto} from "../../../../wf/dto/wf-process-type.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivitySideBaseComponent
} from "../../../../wf/component/process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {WfProcessDto} from "../../../../wf/dto/wf-process.dto";
import {WfProcessRuntimeBindingDto} from "../../../../wf/service/dto/wf-process-runtime-binding.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-object-notification',
    templateUrl: './ns-notification-comment-wf-process-step-side-activity.component.html',
})
export class NsNotificationCommentWfProcessStepSideActivityComponent
    extends WfProcessStepActivitySideBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    notificationTypeDtoId: number;
    objectIdentifier: ObjectIdentifier;

    /**
     * Refresh Component.
     */
    async refreshComponent() {

        const importReferenceObject = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "importReferenceObject");

        this.objectIdentifier = new ObjectIdentifier('cr.Customer',  84876);

        if (importReferenceObject) {
            this.objectIdentifier = new ObjectIdentifier(importReferenceObject.objectType.alias, importReferenceObject.objectValue.id);
        }

        if (this.activityType.activityNotificationDto) {
            this.notificationTypeDtoId = this.activityType.activityNotificationDto.notificationTypeDtoId
        }


        this.initialized = true;

    }


}
