import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {UserService} from "../../../../service/api/user.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'um-kpi.component.html',
})
export class UmKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalUser: number = 0;
    thisMonthUser: number = 0;
    thisWeekUser: number = 0;
    activeUsers: number = 0;


    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'User Module'}];

    constructor(
        protected userService: UserService,) {
        super()
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalUsers();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.initialized = true;
    }


    getTotalUsers() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['enabled'],
            [new ObjectRequestListAttribute('enabled', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.userService.groupBy(objectRequestList).subscribe(res => {
            this.totalUser = res.entries.reduce((acc, item) => acc + item['enabled_count'], 0);
            for (let entry of res.entries) {
                if (entry['enabled'] == 1) {
                    this.activeUsers = entry['enabled_count'];
                }
            }
            this.getThisMonthUsers();
        }, error => {
            this.getThisMonthUsers();
        });
    }

    getThisMonthUsers() {
        const objectRequestList = this.getThisMonthRequest('enabled');
        this.userService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthUser = res.entries.reduce((acc, item) => acc + item['enabled_count'], 0);
            this.getThisWeekUsers();
        }, error => {
            this.getThisWeekUsers();
        });
    }

    getThisWeekUsers() {
        const objectRequestList = this.getThisWeekRequest('enabled');
        this.userService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekUser = res.entries.reduce((acc, item) => acc + item['enabled_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Users', value: this.totalUser,
            },
            {
                label: 'Active Users', value: this.activeUsers,
            },
            {
                label: 'This Month Users', value: this.thisMonthUser,
            },
            {
                label: 'This Week Users', value: this.thisWeekUser,
            },
        ]
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
