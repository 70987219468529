<div class="grid">
    <div class="col-12">

        <ng-container *ngIf="ticketAttachments && ticketAttachments.length">

            <table class="w-full">
                <tr>
                    <th>File Name</th>
                    <th>Uploaded By</th>
                    <th>Action</th>
                </tr>
                <tr *ngFor="let attachment of ticketAttachments">
                    <td>
                        <div>
                            <div class="flex align-items-center">
                                <mvs-file-avatar
                                        [attachmentName]="attachment.documentDtoName"></mvs-file-avatar>

                                <div class="mr-0 md:mr-8">
                                    <span class="block text-900 font-medium mb-1 cursor file-name"
                                          (click)="onObjectSelect(attachment.documentDtoId)">{{attachment.documentDtoName}}</span>
                                    <div class="text-600">{{attachment.createdDate | date}}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <mvs-form-control-output-user-image [value]="attachment.createdBy"></mvs-form-control-output-user-image>
                    </td>
                    <td>
                        <button (click)="removeAttachment(attachment.id)"
                                [disabled]="busy" pButton pRipple
                                class="p-button-text p-button-danger p-button-rounded mr-1" icon="pi pi-trash"></button>
                    </td>
                </tr>
            </table>


        </ng-container>

    </div>

    <div class="col-12">
        <mvs-dm-upload
                [objectIdentifier]="objectIdentifier"
                [layoutVariation]='layoutVariation'
                (onDocumentUploaded)="refreshDocuments()"
        ></mvs-dm-upload>
    </div>
</div>


<mvs-dialog-box-object-document-component
        *ngIf="visible && documentDtoId"
        [visible]="visible"
        [documentObjectId]="documentDtoId"
        (dialogVisibleEvent)="visible = $event"
        [viewOptions]="0"
        [widthSize]="'500px'"
></mvs-dialog-box-object-document-component>
