import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiServiceMyInterface} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {
  AmSearchFlexibleAgentPoolComponent
} from "../../search/am-search-flexible-agent-pool/am-search-flexible-agent-pool.component";
import {AgentPoolDto} from "../../dto/agent-pool.dto";

@Injectable({
  providedIn: 'root'
})
export class AgentPoolService extends MvsCrudService implements MvsApiServiceMyInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/am/agentPools');
  }

  my(): Observable<DtoList<AgentPoolDto>> {
    const url = `${this.apiUrl}/my`;
    return <Observable<DtoList<AgentPoolDto>>>this.getList(url);
  }

  getFlexibleSearchComponent(): Type<any> {
    return AmSearchFlexibleAgentPoolComponent;
  }

}
