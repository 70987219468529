<div class="grid">

    <div class="col-12">
        <mvs-date-filter (onDateSelected)="onDateSelected($event)"></mvs-date-filter>
    </div>

    <div class="col-12">
        <mvs-widget
                *ngIf="widgetNotificationType"
                [widgetData]="widgetNotificationType"
        ></mvs-widget>
    </div>

    <mvs-widget
            *ngIf="widgetNotificationChannelHistory"
            [widgetData]="widgetNotificationChannelHistory"
    ></mvs-widget>

    <!--
    <div class="col-8">
        <mvs-widget
                *ngIf="widgetNotificationChannelHistory"
                [widgetData]="widgetNotificationChannelHistory"
        ></mvs-widget>
    </div>
    <div class="col-4">
        <mvs-widget
                *ngIf="widgetNotificationRecipient"
                [widgetData]="widgetNotificationRecipient"
        ></mvs-widget>
    </div>
    -->


    <div class="col-12">
        <mvs-widget
                *ngIf="widgetNotifications"
                [widgetData]="widgetNotifications"
                (onObjectSelect)="handleCategoryStatusSelect($event)"
        ></mvs-widget>
    </div>
</div>