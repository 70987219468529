<ng-container *ngIf="initialized && objectLoaded">
    <div class="grid">
        <div class="col-12 lg:col-6">
            <div class="grid h-full surface-0 border-round shadow-1 m-1 card">
                <div class="flex flex-column gap-1 text-900 mb-3 px-1">
                    <span>
                        <span class="text-xl font-medium">{{ dto.name }}</span>&nbsp;
                        <span class="font-bold text-primary">{{ dto.alias }}</span>
                    </span>
                    <p class="text-s text-600" [innerHTML]="dto.description"></p>
                </div>

                <div *ngFor="let subNavigation of dto.subNavigations" class="col-12 px-0">
                    <mvs-ui-object-navigation-card [navigationItem]="subNavigation"
                                                   [parentId]="objectIdentifier.objectId"
                                                   [parentAlias]="alias"
                                                   url="/ui/nav/sub"></mvs-ui-object-navigation-card>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-3">
            <div class="grid h-full m-1">
                <div class="col-12 p-0">
                    <div class="border-round shadow-1 surface-card p-3 h-full">

                        <div class="text-xl font-medium mb-1">Last Visits</div>
                        <ui-navigation-last-visits [uiNavigationMainDto]="dto"></ui-navigation-last-visits>
                    </div>
                </div>
            </div>
        </div>

        @if(dto.alias == 'tm') {
            <div class="col-12 lg:col-3">
                <div class="grid h-full m-1">
                    <div class="col-12 p-0">
                        <div class="border-round shadow-1 surface-card p-3 h-full">

                            <div class="text-xl font-medium mb-1">Last Ticket Visits</div>
                            @for (ticket of frequentTickets; track ticket.objectId) {
                                <div class="my-3 py-2 px-1 border-round hover:surface-hover flex surface-card align-items-center pointer" (click)="handleOpenTicket(ticket.objectId)">
                                    <i class="fa-ticket fa-regular mr-2 text-700"></i>
                                    <span>{{ticket.label}}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }

        <div class="col-12 lg:col-3">
            <div class="grid h-full m-1">
                <div class="col-12 p-0">
                    <div class="border-round shadow-1 surface-card p-3 h-full">
                        <div class="text-xl font-medium mb-1">Favoriten</div>
                        <ui-navigation-favorites [uiNavigationMainDto]="dto"></ui-navigation-favorites>

                    </div>
                </div>
            </div>
        </div>


    </div>


</ng-container>
