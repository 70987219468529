import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";


@Component({
    selector: 'cc-config-page',
    templateUrl: './config.component.html',
})
export class ConfigComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "CC Config";

    activeIndex: number = 0;


    widgetFieldNamespace: WidgetData;
    widgetField: WidgetData;
    widgetFieldType: WidgetData;
    widgetFieldValueList: WidgetData;
    widgetFieldValueListEntry: WidgetData;

    widgetObjectType: WidgetData;
    widgetObjectTypeAttribute: WidgetData;

    widgetSystemConfig: WidgetData;

    selectedObjectType: DtoDetail;
    selectedFieldValueList: DtoDetail;


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.widgetFieldNamespace = WidgetFactory.createWidgetListBasic(
            "cc.config.field.namespace",
            "Namensraum",
            "list",
            "list",
            "entity",
            "cc.FieldNamespace"
        );

        this.widgetField = WidgetFactory.createWidgetListBasic(
            "cc.config.field",
            "Felder",
            "list",
            "list",
            "entity",
            "cc.Field"
        );

        this.widgetFieldType = WidgetFactory.createWidgetListBasic(
            "cc.config.field.type",
            "Feld- Typen",
            "table",
            "list",
            "entity",
            "cc.FieldType"
        );

        // this.widgetObjectType = WidgetFactory.createWidgetListBasic(
        //     "cc.config.object.type",
        //     "Entitäten (Objekttypen)",
        //     "list",
        //     "list",
        //     "entity",
        //     "cc.ObjectType"
        // );

        this.widgetObjectType = WidgetFactory.createWidgetTableEntity(
            "cc.config.object.type.table",
            "Entitäten (Objekttypen)",
            "cc.ObjectType",
            "No Data",
            ObjectRequestList.createBasic(true, [], [])
        );

        // this.widgetFieldValueList = WidgetFactory.createWidgetListBasic(
        //     "cc.config.field.value.list",
        //     "Wertelisten",
        //     "list",
        //     "list",
        //     "entity",
        //     "cc.FieldValueList"
        // );

        this.widgetFieldValueList = WidgetFactory.createWidgetTableEntity(
            "cc.config.field.value.list",
            "Wertelisten",
            "cc.FieldValueList",
            "No Data",
            ObjectRequestList.createBasic(true, [], [])
        );

        this.widgetSystemConfig = WidgetFactory.createWidgetListBasic(
            "cc.config.system",
            "System Konfiguration",
            "list",
            "list",
            "entity",
            "cc.SystemConfig"
        );

        this.initialized = true;

    }

    handleFieldValueListSelect(event: ObjectIdentifierData) {
        this.selectedFieldValueList = event.data;
        this.refreshFieldValueListEntriesWidget();
    }

    handleObjectTypeSelect(event: ObjectIdentifierData) {
        this.selectedObjectType = event.data;
        this.refreshObjectTypeAttributeWidget();
    }

    refreshObjectTypeAttributeWidget() {
        // this.widgetObjectTypeAttribute = WidgetFactory.createWidgetList(
        //     "cc.config.object.type.attribute",
        //     "Attribute",
        //     "list",
        //     "list",
        //     "entity",
        //     "cc.ObjectTypeAttribute",
        //     "Keine Daten vorhanden",
        //     ObjectRequestList.createBasic(
        //         true,
        //         [FilterCriteria.create("objectType", FilterCriteria.cOperatorEqual, this.selectedObjectType.id)],
        //         [new Sorting("id", false)]
        //     )
        // );

        this.widgetObjectTypeAttribute = WidgetFactory.createWidgetTableEntity(
            "cc.config.object.type.attribute",
            "Attribute",
            "cc.ObjectTypeAttribute",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("objectType", FilterCriteria.cOperatorEqual, this.selectedObjectType.id)],
                [new Sorting("id", false)]
            )
        );
    }


    refreshFieldValueListEntriesWidget() {

        // this.widgetFieldValueListEntry = WidgetFactory.createWidgetList(
        //     "cc.config.field.value.list.entry",
        //     "Werte",
        //     "list",
        //     "list",
        //     "entity",
        //     "cc.FieldValueListEntry",
        //     "Keine Daten vorhanden",
        //     ObjectRequestList.createBasic(
        //         true,
        //         [FilterCriteria.create("fieldValueList", FilterCriteria.cOperatorEqual, this.selectedFieldValueList.id)],
        //         [new Sorting("id", false)]
        //     )
        // );

        this.widgetFieldValueListEntry = WidgetFactory.createWidgetTableEntity(
            "cc.config.field.value.list.entry",
            "Werte",
            "cc.FieldValueListEntry",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("fieldValueList", FilterCriteria.cOperatorEqual, this.selectedFieldValueList.id)],
                [new Sorting("id", false)]
            )
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
