<div class="grid">
    <div class="col-4">
        <mvs-dm-upload
                [objectIdentifier]="objectIdentifier"
                documentTypeId="3387"
        ></mvs-dm-upload>
    </div>
    <div class="col-8">
        <mvs-dm-object-documents
                [objectIdentifier]="objectIdentifier"
                layoutVariation="contract"
        ></mvs-dm-object-documents>
    </div>
</div>




