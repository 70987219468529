import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CallDto} from "../../dto/call.dto";
import {CallRecipientTypeEnum} from "../../enum/call-recipient-type-enum.enum";
import {CallResultEnum} from "../../enum/call-result-enum.enum";
import {DtoList} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivitySideProviderInterface
} from "../../../wf/component/process-activity-side/wf-process-step-activity-side-provider.interface";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
    WfProcessStepActivitySideBaseComponent
} from "../../../wf/component/process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {
    PcCallWfProcessStepActivitySideComponent
} from "../../component/wf/pc-call-wf-process-step-activity-side/pc-call-wf-process-step-activity-side.component";
import {
    WfProcessStepActivitySideGenericComponent
} from "../../../wf/component/process-activity-side/wf-process-step-activity-side-generic/wf-process-step-activity-side-generic.component";
import {PcCallComponent} from "../../component/pc-call/pc-call.component";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
    providedIn: 'root'
})
export class CallService extends MvsCrudService implements WfProcessStepActivitySideProviderInterface {

    constructor(
        protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/pc/calls')
    }


    /**
     * Get my active calls.
     */
    public getMyActiveCalls(): Observable<DtoList> {
        return this.getList(this.apiUrl + '/myActiveCalls');
    }


    /**
     * Get complex selection containing customer and last protocol.
     */
    public getComplexSelectionCustomerAndLastProtocol(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("lastProtocol", ObjectRequestRelation.createLatest("pc.CallProtocol", "call")),
            ObjectRequestComplexNode.createRelationNode("objectCustomer",ObjectRequestRelation.createJoin("+objectCustomer")).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person"))
            );
    }

    /**
     * Start customer call.
     * @param callTypeId
     * @param customerId
     * @param phoneNumber
     * @param reason
     */
    public startCustomerCall(callTypeId: number, customerId: number, phoneNumber: string, reason?: string): Observable<CallDto> {

        const callDto = new CallDto();

        callDto.callTypeDtoId = callTypeId;
        callDto.callRecipientType = CallRecipientTypeEnum.customer.valueOf();
        callDto.objectId = customerId;
        callDto.phoneNumber = phoneNumber;

        if (reason) {
            callDto.reason = reason;
        }

        return <Observable<CallDto>>this.createInternal(callDto, "/startCall");

    }

    /**
     * End customer call.
     * @param callId
     * @param callResult
     * @param reason
     */
    public endCustomerCall(callId: number, callResult: CallResultEnum, reason?: string): Observable<CallDto> {

        const callDto = new CallDto();
        callDto.id = callId;
        callDto.callResult = callResult.valueOf();

        if (reason) {
            callDto.reason = reason;
        }

        return <Observable<CallDto>>this.updateInternal(callDto, "/endCall");

    }

    /**
     * Return specific side component for Workflow.
     * @param activityType
     */
    getActivitySideComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivitySideBaseComponent | null {
        return PcCallWfProcessStepActivitySideComponent;
    }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        return PcCallComponent;
    }


}