@if (initialized) {

    @if (activeNavigationItem.action == 'frequencies') {
        <mvs-widget
                *ngIf="frequencyWidget"
                [widgetData]="frequencyWidget"
        ></mvs-widget>
    }

    @if (activeNavigationItem.action == 'payFrequencies') {
        <mvs-widget
                *ngIf="payFrequencyWidget"
                [widgetData]="payFrequencyWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'paymentMoments') {
        <mvs-widget
                *ngIf="paymentMomentWidget"
                [widgetData]="paymentMomentWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'paymentTypes') {
        <mvs-widget
                *ngIf="paymentTypeWidget"
                [widgetData]="paymentTypeWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'roundingRules') {
        <mvs-widget
                *ngIf="roundingRuleWidget"
                [widgetData]="roundingRuleWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'templateCalculationBases') {
        <mvs-widget
                *ngIf="templateCalculationBaseWidget"
                [widgetData]="templateCalculationBaseWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'templateCancellationBuffers') {
        <mvs-widget
                *ngIf="templateCancellationBufferWidget"
                [widgetData]="templateCancellationBufferWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'templateLiabilities') {
        <mvs-widget
                *ngIf="templateLiabilityWidget"
                [widgetData]="templateLiabilityWidget"
        ></mvs-widget>

    }

}