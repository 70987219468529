import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";
import {JbConfigPagePage} from "./page/jb-config-page/jb-config-page.page";
import {JbJobRunObjectComponent} from "./component/object-base/jb-job-run-object-component/jb-job-run-object.component";
import {
    JbJobRunSideComponent
} from "./component/object-base/jb-job-run-object-component/view/side/jb-job-run-side.component";
import {
    JbJobRunFullComponent
} from "./component/object-base/jb-job-run-object-component/view/full/jb-job-run-full.component";
import {
    JbJobRunBaseComponent
} from "./component/object-base/jb-job-run-object-component/view/jb-job-run-base.component";
import {JbJobStatusComponent} from "./component/jb-job-status/jb-job-status.component";


@NgModule({
    declarations: [
        JbConfigPagePage,
        JbJobRunObjectComponent,
        JbJobRunSideComponent,
        JbJobRunFullComponent,
        JbJobRunBaseComponent,
        JbJobStatusComponent,
    ],
    exports: [
        JbConfigPagePage,
        JbJobRunObjectComponent,
        JbJobRunSideComponent,
        JbJobRunFullComponent,
        JbJobRunBaseComponent,
        JbJobStatusComponent,
    ],
    imports: [
        CoreModule,
        UiModule,
        BlockUIModule,
        ProgressSpinnerModule
    ]
})
export class JbModule implements DynamicModule {

    alias = 'jb';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return null;
    }

    getStatisticsComponent() {
        return null;
    }
}
