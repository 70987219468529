<ng-container *ngIf="initialized">

    <mvs-widget
            *ngIf="personsWidget"
            [widgetData]="personsWidget"
            [importObjectContext]="contextContractAndCustomer"
            [widgetToolbarButtons]="personWidgetToolbarButtons"
            (onToolbarButtonClick)="handlePersonWidgetToolbarClick($event)"
    ></mvs-widget>

    <mvs-widget *ngIf="objectsWidget"
                [widgetData]="objectsWidget"
                [importObjectContext]="contextContractAndCustomer">

    </mvs-widget>
