
<!--<ng-container *ngIf="initialized && objectLoaded">-->


<!--    <p-toolbar>-->

<!--        <div class="p-toolbar-group-left">-->

<!--            <p-progressSpinner-->
<!--                    *ngIf="busy"-->
<!--                    [style]="{width: '25px', height: '25px'}"-->
<!--                    styleClass="custom-spinner"-->
<!--                    strokeWidth="8" fill="var(&#45;&#45;surface-ground)"-->
<!--            ></p-progressSpinner>-->

<!--            <p-toggleButton [(ngModel)]="showBasicData" onLabel="Artefakt Daten" onIcon="fa-sharp fa-solid fa-eye"-->
<!--                            offIcon="fa-sharp fa-solid fa-eye-slash" offLabel="Artefakt Daten"-->
<!--                            (click)="handleToggleBasicData()"></p-toggleButton>-->


<!--        </div>-->

<!--        <div class="p-toolbar-group-right">-->

<!--        </div>-->
<!--    </p-toolbar>-->

<!--    <div class="grid" *ngIf="showBasicData && widgetBasicData">-->
<!--        <div class="col-12">-->
<!--            <mvs-widget [widgetData]="widgetBasicData"></mvs-widget>-->
<!--        </div>-->
<!--    </div>-->



<!--    <div class="grid">-->
<!--        <div class="col-9">-->
<!--            <mvs-te-editor-->
<!--                    [variableProviderService]="crudService"-->
<!--                    alias="te.TeArtefactVariable"-->
<!--                    attributeName="artefact"-->
<!--                    [contentProviderId]="dto.id"-->
<!--                    [content]="dto.content"-->
<!--                    (onSave)="handleEditorOnSave($event)"-->
<!--                    [contentType]="dto.contentTypeEnum"-->
<!--            ></mvs-te-editor>-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--            <div class="grid">-->
<!--                <div class="col-12">-->
<!--                    <mvs-te-variables [variableProviderService]="crudService" alias="te.TeArtefactVariable" attributeName="artefact" [contentProviderId]="dto.id"></mvs-te-variables>-->
<!--                </div>-->
<!--                -->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--</ng-container>-->

@if (initialized && objectLoaded && dto) {

    @if (uiMode == '') {
        <te-artefact-object-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></te-artefact-object-side>
    } @else {
        <te-artefact-object-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></te-artefact-object-full>
    }
}