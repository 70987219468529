<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-2 flex flex-column gap-2">

            <wf-object-processes
                    [objectIdentifier]="objectIdentifier"
                    [showActive]="false"
            >
            </wf-object-processes>

        </div>

        <div class="col-7 flex flex-column gap-2">

            <tm-object-tickets
                    [objectIdentifier]="objectIdentifier"
                    ticketDrawerLocation="left"
                    [showActive]="false"
            >
            </tm-object-tickets>

        </div>

        <div class="col-3">
            <div class="flex flex-column gap-3">

                <div class="mvs-widget" *ngIf="objectIdentifier.objectId">
                    <mvs-ns-notification-types-for-object [objectIdentifier]="objectIdentifier"></mvs-ns-notification-types-for-object>
                </div>





                <mvs-pc-object-calls
                        [objectIdentifier]="objectIdentifier"
                        [aliasSuffix]="viewType"
                ></mvs-pc-object-calls>


            </div>
        </div>

    </div>

</ng-container>