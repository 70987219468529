import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
  ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {
  CmCreateInsurableObjectPersonRelationPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person-relation-person/cm-create-insurable-object-person-relation-person.component";
import {
  CrCreateEmergencyContactComponent
} from "../../component/cr-customer/component/cr-create-emergency-contact/cr-create-emergency-contact.component";

@Injectable({
  providedIn: 'root'
})
export class CustomerEmergencyContactService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerEmergencyContacts');
  }
  getObjectComponent() {
    return CrCreateEmergencyContactComponent;
  }

}
