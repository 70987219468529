<ng-container *ngIf="showOverall && initialized && customerAssessment">
    <div class="grid">
        <div class="col">

    <p-avatar
            class="flex justify-content-center"
            [icon]="getIcons(customerAssessment.assessmentResult)"
            [ngClass]="!showStack ? 'cursor-pointer avatar' :''"
            [style]="{ 'background-color': getColors(customerAssessment.assessmentResult), color: '#f2f2f2' }"
            size="xlarge"
            shape="circle"
            (click)="!showStack ? op.toggle($event) : ''"
    ></p-avatar>

        </div>
    </div>
</ng-container>

<p-overlayPanel
    #op
    disabled="true"
    appendTo="body">

    <mvs-cm-insurance-stack-overview-component
            [customerAssessment]="customerAssessment"
    ></mvs-cm-insurance-stack-overview-component>

</p-overlayPanel>