<!-- ng-container with *ngIf to conditionally render content when the component is initialized -->
<ng-container *ngIf="initialized">

    <div class="mvs-dashboard-top-navigation">
        <!-- PrimeNG menubar component with subSelectionItems as the model -->
        <p-menubar [model]="subSelectionItems" [autoDisplay]="true" styleClass="shadow-1 surface-card">

            <!-- ng-template for the start of the menubar -->
            <ng-template pTemplate="start">

                <!-- Main selection container -->
                <div class="flex align-items-center mvs-dashboard-main-selection ml-3"
                     *ngIf="mainSelections && mainSelections.length">

                    <!-- Main selection label and dropdown icon -->
                    <div class="flex gap-1 flex-column">
                        <div class="flex gap-2 cursor" (click)="menu.toggle($event)">
                            <span class="font-medium w-max">{{mainSelection?.label}} </span>
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>

                        <!-- Sub-selection label -->
                        <span class="text-sm">{{mainSelection?.subLabel}}</span>
                    </div>
                </div>

                <!-- PrimeNG SlideMenu for mainSelectionItems -->
                <p-slideMenu #menu [model]="mainSelectionItems" [popup]="true" [viewportHeight]="200" styleClass="w-max">
                </p-slideMenu>

            </ng-template>

            <!-- ng-template for the end of the menubar -->
            <ng-template pTemplate="end">

                <!-- PrimeNG Dropdown for typeFilterSelections -->
                <div class="flex align-items-center mr-3"
                     *ngIf="mainSelections && mainSelections.length">
                    <p-dropdown
                            *ngIf="typeFilterSelections && typeFilterSelections.length"
                            [options]="typeFilterSelections"
                            [(ngModel)]="typeFilterSelection"
                            optionLabel="label"
                            [showClear]="true"
                            placeholder="Select"
                            (onChange)="onTypeFilterSelectionChange()"
                            (onClear)="onTypeFilterSelectionClear()"
                            [style]="{'border':'1px solid var(--surface-300)'}"
                            appendTo="body">

                        <!-- ng-template for the selected item in the dropdown -->
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="typeFilterSelection">
                                <div>{{ typeFilterSelection.label }} {{ typeFilterSelection.count }}</div>
                            </div>
                        </ng-template>

                        <!-- ng-template for each item in the dropdown -->
                        <ng-template let-filter pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ filter.label }} ({{ filter.count }})</div>
                            </div>
                        </ng-template>

                    </p-dropdown>

                </div>
            </ng-template>
        </p-menubar>
    </div>
</ng-container>
