import {Component, OnInit, SimpleChanges} from '@angular/core';
import {WfProcessStartEvent} from "../../../logic/wf-process-start-event";
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService, ObjectIdentifier, ObjectPageComponent} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-wf-test-page',
    templateUrl: './wf-test.page.html',
    styleUrls: ['./wf-test.page.scss']
})
export class WfTestPage extends ObjectPageComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    processStartEvent: WfProcessStartEvent;

    uiMode: string = "start";

    startedProcess: WfProcessDto;
    startedProcessObjectIdentifier: ObjectIdentifier;


    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService) {

        super(route, coreService);
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

        // this.processStartEvent = new WfProcessStartEvent(21003);

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    handleProcessStart(processStartEvent: WfProcessStartEvent) {

        this.processStartEvent = processStartEvent;


    }

    onProcessStarted(processDto: WfProcessDto) {

        this.startedProcess = processDto;
        this.startedProcessObjectIdentifier = new ObjectIdentifier("wf.WfProcess", processDto.id);

        this.uiSwitchModeToProcess();

    }

    uiSwitchModeToStart() {
        this.uiMode = "start";
    }

    uiSwitchModeToProcess() {
        this.uiMode = "process";
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
