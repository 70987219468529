import {Component, OnInit} from "@angular/core";
import {FilterCriteria, ObjectBaseModeComponent, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'hc-integration-base-component',
    template: ''
})

export class IntegrationAdapterTypeBaseComponent extends ObjectBaseModeComponent implements OnInit {


    integrationAdapterTypeWidget: WidgetData;
    integrationAdapterTypeVariableWidget: WidgetData;

    constructor(protected route: ActivatedRoute) {
        super(route);

    }

    ngOnInit() {

        this.activeNavigationItem = this.navigationItems.find(item => item.default);
        this.onNavigationItems.emit(this.navigationItems);
        super.ngOnInit();

        this.refreshIntegrationAdapterTypeWidget();
        this.refreshIntegrationAdapterTypeVariableWidget();

    }

    refreshIntegrationAdapterTypeWidget() {
        this.integrationAdapterTypeWidget = WidgetFactory.createWidgetEntityData(
            'hc.integration.adapter.type.data.widget',
            'Adapter Type',
            'hc.IntegrationAdapterType',
            this.objectIdentifier.objectId
        )
    }

    refreshIntegrationAdapterTypeVariableWidget() {

        const filterCriteria = FilterCriteria.create('integrationAdapterType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], [])

        this.integrationAdapterTypeVariableWidget = WidgetFactory.createWidgetTableEntity(
            'hc.integration.adapter.type.variable.table.widget',
            'Adapter Type Variable',
            'hc.IntegrationAdapterTypeVariable',
            'No Variable',
            objectRequest
        )
    }

}