import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mvs-dashboard-object',
    templateUrl: './mvs-dashboard-object.component.html',
    styleUrls: ['./mvs-dashboard-object.component.scss']
})
export class MvsDashboardObjectComponent {



}
