import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WfProcessService} from "../../service/api/wf-process.service";

@Component({
    selector: 'wf-object-processes',
    templateUrl: './wf-object-processes.component.html',
    styleUrls: ['./wf-object-processes.component.scss']
})
export class WfObjectProcessesComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() size: string = 'M';
    @Input() showActive: boolean = true;
    wfProcessList: WidgetData;


    constructor(protected objectTypeService: ObjectTypeService,
                protected processService: WfProcessService,
                protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {


        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshProcessesList();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

    refreshProcessesList() {

        this.wfProcessList = new WidgetData();
        this.wfProcessList.idAlias = "test.haris.object.processes.list";
        this.wfProcessList.name = "Workflow Process List";
        this.wfProcessList.uiComponent = "list";
        this.wfProcessList.dataProvider = "list";
        this.wfProcessList.dataSource = "entity";
        this.wfProcessList.dataProviderObject = 'wf.WfProcess';

        this.wfProcessList.dataProviderListRequest = this.processService.getRequestForProcessForObjectViaAlias(
            "cr.Customer",
            this.objectIdentifier.objectId,
            this.showActive
        );
        this.wfProcessList.dataProviderListRequest.objectRequestComplex = this.processService.getLatestProcessStepComplexSelection();
        this.wfProcessList.setParamValue("size", "S");
        this.wfProcessList.setParamValue("objectWidget", "data");

    }

}
