import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-dm-document-upload',
  templateUrl: './dm-document-upload.component.html',
  styleUrls: ['./dm-document-upload.component.scss']
})
export class DmDocumentUploadComponent implements OnInit, OnChanges {

  objectIdentifier: ObjectIdentifier = new ObjectIdentifier('cr.Customer', 84876);

  constructor() {}

  ngOnInit(): void {}


  ngOnChanges(changes: SimpleChanges): void {}

}
