<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetCallResults"
                        (onObjectSelect)="onSetFilterCriteria($event,'callResult')"
                ></mvs-widget>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetCallType"
                        (onObjectSelect)="onSetFilterCriteria($event,'callType')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-12" *ngIf="filterCriteria && filterCriteria.length">
            <p-panel header="Filters">
                <div class="flex align-items-center gap-2 flex-wrap">
                    <p-chip
                            *ngFor="let filter of filterCriteria"
                            [label]="filter.field + ': ' + filter.label"
                            [removable]="true"
                            (onRemove)="onRemoveFilterCriteria(filter)"
                    ></p-chip>
                </div>
            </p-panel>
        </div>

        <div class="col-12 md:col-12">
            <div class="mvs-widget">
                <mvs-widget [widgetData]="widgetCalls" (onObjectSelect)="onRowSelect($event)"></mvs-widget>
            </div>
        </div>
    </div>


</ng-container>