<ng-container *ngIf="initialized && objectLoaded && dto">

    <ng-container *ngIf="viewType == 0">
        <cm-contract-standard-navigation
                [objectIdentifier]="objectIdentifier"
                [importObjectContext]="customerImportObjectContext"
                [dto]="dto"
        ></cm-contract-standard-navigation>
    </ng-container>

    <!--
    <ng-container *ngIf="viewType == 1">
        <cm-contract-documents-navigation
                [objectIdentifier]="objectIdentifier"
        >

        </cm-contract-documents-navigation>
    </ng-container>
    -->

    <ng-container *ngIf="viewType == 5">

        <cm-contract-document-upload
                [objectIdentifier]="objectIdentifier">
        </cm-contract-document-upload>
    </ng-container>

    <ng-container *ngIf="viewType == 6">

        <cm-contract-objects-navigation
                [contractDto]="dto"
                [customerDto]="dto.joinCurrentMainCustomer">
        </cm-contract-objects-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == 7">

        <mvs-ns-notification-types-for-object
                [objectIdentifier]="objectIdentifier"
                [mode]="1"
                class="mvs-widget block"
        ></mvs-ns-notification-types-for-object>

    </ng-container>

    <mvs-ticket [visible]="viewType == 8"
                [objectIdentifier]="{objectType:'cm.Contract', objectId:dto.id}"
                (onTicketsCount)="handleTicketsCount($event)">

    </mvs-ticket>

    <mvs-workflow [visible]="viewType == 9"
                  [objectRequestList]="workflowObjectRequestList"
                  (onWorkflowCount)="handleWorkflowsCount($event)"
    >
    </mvs-workflow>


</ng-container>
