<ng-container *ngIf="initialized">

        <cm-create-new-insurable-object
                [customerId]="customerId"
                [insurableObjectType]="'Others'"
                (onChangedObject)="handleObjectChange($event)"

        >
        </cm-create-new-insurable-object>


</ng-container>