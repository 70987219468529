import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {UiNavigationMainService} from "./ui/service/api/ui-navigation-main.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {UiNavigationMainDto} from "./ui/dto/ui-navigation-main.dto";
import {MenuItem} from "primeng/api";
import {Sorting} from "@kvers/alpha-core-common";

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu" *ngIf="initialized">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true" toolTipShow="false"></li>
        </ul>
    `
})
export class AppMenuComponent implements OnInit {


    model: MenuItem[] = [];

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    uiNavigationMainEntries: DtoList<UiNavigationMainDto>;

    // base MainNavigation url structure
    baseUrl:string = "/ui/nav/";


    constructor(public app: AppComponent,protected uiNavigationMainService: UiNavigationMainService) {}

    ngOnInit() {

        this.initComponent();
        /*
        this.model = [
             {
                 label: 'CRM', icon: 'fa-sharp fa-solid fa-shield-halved', routerLink: ['/ui/nav/1'],

                 items: [
                    {label: 'Contract Management', icon: 'pi pi-fw pi-shield',
                        items: [
                            {label: 'Gesellschaften', icon: 'fa-light fa-building-shield fa-fw', routerLink: ['/cm/insuranceCompanies']},
                            {label: 'Versicherungsstruktur', icon: 'fa-light fa-table-tree fa-fw', routerLink: ['/cm/divisions']},
                            {label: 'Zu versichernde Objekte', icon: 'fa-light fa-circle-nodes fa-fw', routerLink: ['/cm/insurableObjects']},
                            {label: 'Insurance Status Type', icon: 'fa-solid fa-signal fa-fw', routerLink: ['/cm/insuranceStatusTypes']},
                            {label: 'Insurances', icon: 'fa-light fa-file-shield fa-fw', routerLink: ['/cm/insurances']},
                            {label: 'Configuration', icon: 'fa-light fa-sliders-up fa-fw', routerLink: ['/cm/insuranceConfiguration']},
                        ]
                    },
                    {label: 'Address Management', icon: 'fa-regular fa-location-dot fa-fw',
                        items: [
                            {label: 'Addressen', icon: 'fa-solid fa-location-dot fa-fw', routerLink: ['/ad/addresses']},
                            {label: 'Länder', icon: 'fa-regular fa-earth-europe fa-fw', routerLink: ['/ad/countries']},
                            {label: 'Zuweisungen', icon: 'pi pi-fw pi-link', routerLink: ['/ad/addressTypeObjectTypes']},
                        ]
                    },

                    {label: 'Customer Management', icon: 'pi pi-fw pi-user',
                        items: [
                            {label: 'Customers', icon: 'fa-light fa-users fa-fw', routerLink: ['/cr/customers']},
                            {label: 'Customer Forms', icon: 'fa-light fa-user-pen fa-fw', routerLink: ['/cr/customerFormTypes']},
                            {label: 'Onboarding', icon: 'fa-light fa-users-line fa-fw', routerLink: ['/cr/customerOnboardingTypes']},
                            {label: 'Signatures', icon: 'fa-light fa-file-contract fa-fw', routerLink: ['/cr/customerSignatureTypes']},
                            {label: 'Interactions', icon: 'fa-light fa-users-rays fa-fw', routerLink: ['/cr/customerInteractionTypes']},

                        ]
                    },
                     {label: 'Person Management', icon: 'pi pi-fw pi-share-alt',
                         items: [
                             {label: 'Personen', icon: 'pi pi-fw pi-users', routerLink: ['/pm/persons']},
                         ]
                     },
                     {label: 'Contact Management', icon: 'pi pi-fw pi-id-card',
                         items: [
                             {label: 'Kontakt-Feldkonfiguration', icon: 'pi pi-fw pi-id-card', routerLink: ['/cd/contactTypes']},
                             {label: 'Zuweisungen', icon: 'pi pi-fw pi-link', routerLink: ['/cd/contactTypeObjectTypes']}
                         ]
                     },
                 ],
             },
            {
                label: 'Management', icon: 'fa-regular fa-users-gear',

                items: [

                    {label: 'Communication Channels', icon: 'pi pi-fw pi-comments',
                        items: [
                            {label: 'Channel Types', icon: 'pi pi-fw pi-sitemap', routerLink: ['/co/coChannelTypes']},
                        ]
                    },
                    {label: 'Notifications', icon: 'pi pi-fw pi-bell',
                        items: [
                            {label: 'Types', icon: 'pi pi-fw pi-sitemap', routerLink: ['/ns/notificationTypes']},
                        ]
                    },
                    {label: 'Appointment Service', icon: 'pi pi-fw pi-calendar',
                        items: [
                            {label: 'Configuration', icon: 'pi pi-fw pi-sliders-v', routerLink: ['/as/appointmentTypes']},
                        ]
                    },

                    {label: 'Agent Management', icon: 'fa-light fa-user-tie fa-fw',
                        items: [
                            {label: 'Agents', icon: 'fa-solid fa-user-tie fa-fw', routerLink: ['/am/agents']},
                            {label: 'Agent Pools', icon: 'pi pi-fw pi-id-card', routerLink: ['/am/agentPools']},
                            {label: 'Agent Commissions', icon: 'pi pi-fw pi-dollar',
                                items: [
                                    {label: 'TemplateAcquisitionCommission', icon: 'pi pi-fw pi-book', routerLink: ['/ac/templateAcquisitionCommissions']},
                                    {label: 'AgentCancellationBufferAssignment', icon: 'pi pi-fw pi-link', routerLink: ['/ac/agentCancellationBufferAssignments']},
                                    {label: 'AcquisitionCommissionAssignment', icon: 'pi pi-fw pi-link', routerLink: ['/ac/acquisitionCommissionAssignments']}
                                ]
                            },
                        ]
                    },

                    {label: 'Workflows', icon: 'pi pi-fw pi-sitemap',
                        items: [
                            {label: 'Prozesse', icon: 'pi pi-fw pi-sitemap', routerLink: ['wf/processTypes']},
                            {label: 'Aktivitäten', icon: 'pi pi-fw pi-sitemap', routerLink: ['wf/activityTypes']}
                        ]
                    },

                    {label: 'Ticketing', icon: 'pi pi-fw pi-sitemap',
                        items: [
                            {label: 'Ticket Types', icon: 'pi pi-fw pi-sitemap', routerLink: ['tm/ticketTypes']},
                            {label: 'Communication Channels', icon: 'pi pi-fw pi-sitemap', routerLink: ['tm/communicationChannels']}
                        ]
                    },
                    {label: 'Org. Management', icon: 'pi pi-fw pi-sitemap',
                        items: [
                            {label: 'Organisationsstruktur', icon: 'pi pi-fw pi-sitemap', routerLink: ['/om/organizationUnits']},
                            {label: 'Abteilungen', icon: 'pi pi-fw pi-share-alt', routerLink: ['/om/departments']},
                            {label: 'Mitarbeiter', icon: 'pi pi-fw pi-users', routerLink: ['/om/employees']},
                        ]
                    },
                    {label: 'Signature Management', icon: 'pi pi-fw pi-desktop',
                        items: [
                            {label: 'Types', icon: 'pi pi-fw pi-code', routerLink: ['/sm/signatureTypes']},
                        ]
                    },

                ],

            },
            {label: 'Reporting', icon: 'fa-regular fa-file-chart-pie',
                items: [
                    {label: 'Reports', icon: 'pi pi-fw pi-code', routerLink: ['/rp/reports']},
                ]
            },
            {label: 'Core Finance', icon: 'fa-regular fa-coins',

                items: [
                    {label: 'Finance', icon: 'pi pi-fw pi-money-bill',
                        items: [
                            {label: 'Insurance Tax', icon: 'fa-regular fa-abacus', routerLink: ['/cm/insuranceTaxes']},
                            {label: 'CfFrequency', icon: 'pi pi-fw pi-list', routerLink: ['/cf/frequencies']},
                            {label: 'CfPayFrequency', icon: 'pi pi-fw pi-list', routerLink: ['/cf/payFrequencies']},
                            {label: 'CfPaymentMoment', icon: 'pi pi-fw pi-list', routerLink: ['/cf/paymentMoments']},
                            {label: 'CfPaymentType', icon: 'pi pi-fw pi-list', routerLink: ['/cf/paymentTypes']},
                            {label: 'CfRoundingRule', icon: 'pi pi-fw pi-list', routerLink: ['/cf/roundingRules']},
                        ],
                    },
                    {label: 'Finance Template', icon: 'pi pi-fw pi-money-bill',
                        items: [
                            {label: 'CfTemplateCalculationBase', icon: 'pi pi-fw pi-book', routerLink: ['/cf/templateCalculationBases']},
                            {label: 'CfTemplateCancellationBuffer', icon: 'pi pi-fw pi-book', routerLink: ['/cf/templateCancellationBuffers']},
                            {label: 'CfTemplateLiability', icon: 'pi pi-fw pi-book', routerLink: ['/cf/templateLiabilities']},
                        ],
                    },

                ]
            },
            {
                label: 'Administration', icon: 'fa-regular fa-display-code',

                items: [
                    {label: 'Testing', icon: 'pi pi-fw pi-desktop',
                        items: [
                            {label: 'Widgets', icon: 'pi pi-fw pi-code', routerLink: ['/test/widgets']},
                        ]
                    },
                    {label: 'User Front- End', icon: 'pi pi-fw pi-user',
                        items: [
                            {label: 'Registrierung', icon: 'pi pi-fw pi-users', routerLink: ['/cr/customerWelcomes']},
                            {label: 'Texte', icon: 'pi pi-fw pi-users', routerLink: ['/eu/euFrontEndTexts']},
                            {label: 'User Übersicht', icon: 'pi pi-fw pi-users', routerLink: ['/eu/euOverview']},
                        ]
                    },
                    {label: 'UI Management', icon: 'pi pi-fw pi-desktop',
                        items: [
                            {label: 'UI Feldkonfiguration', icon: 'pi pi-fw pi-code', routerLink: ['/ui/fields']},
                            {label: 'UI ComponentBuilder', icon: 'pi pi-fw pi-code', routerLink: ['/ui/componentBuilder']},
                        ]
                    },
                    {label: 'Daten Import', icon: 'pi pi-fw pi-desktop',
                        items: [
                            {label: 'Data Import', icon: 'pi pi-fw pi-code', routerLink: ['/im/dataImport']},
                        ]
                    },
                    {label: 'Document Store', icon: 'pi pi-fw pi-desktop',
                        items: [
                            {label: 'Types', icon: 'pi pi-fw pi-code', routerLink: ['/dm/dmDocumentTypes']},
                        ]
                    },

                    {label: 'Core Components', icon: 'pi pi-fw pi-compass',
                        items: [
                            {label: 'Objekttypen', icon: 'pi pi-fw pi-list', routerLink: ['/cc/objectTypes']},
                            {label: 'Felder', icon: 'pi pi-fw pi-list', routerLink: ['/cc/fields']},
                            // {label: 'UI FieldTypes', icon: 'pi pi-fw pi-home', routerLink: ['/ui/fieldTypes']},
                            // {label: 'UI FieldGroups', icon: 'pi pi-fw pi-home', routerLink: ['/ui/fieldGroups']},
                        ]
                    }
                ],
            },

            /*{
                label: 'Favorites', icon: 'pi pi-fw pi-home',
                items: [
                    {label: 'Dashboard Sales', icon: 'pi pi-fw pi-home', routerLink: ['/'], badge: '4', badgeClass: 'p-badge-info'},
                    {label: 'Dashboard Analytics', icon: 'pi pi-fw pi-home',
                        routerLink: ['/favorites/dashboardanalytics'], badge: '2', badgeClass: 'p-badge-success'}
                ]
            },
            {
                label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                items: [
                    {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'], badge: '6', badgeClass: 'p-badge-danger'},
                    {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
                    {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate']},
                    {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
                    {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'], badge: '6', badgeClass: 'p-badge-help'},
                    {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                    {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                    {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                    {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                    {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                    {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu']},
                    {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                    {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                    {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                    {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
                ]
            },
            {
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
                items: [
                    {label: 'MvsFormDto Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'], badge: '6', badgeClass: 'p-badge-warning'},
                    {label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display']},
                    {label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation']},
                    {label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox']},
                    {label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons']},
                    {label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text']},
                    {label: 'Widgets', icon: 'pi pi-fw pi-star', routerLink: ['utilities/widgets']},
                    {label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid']},
                    {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing']},
                    {label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography']}
                ]
            },
            {
                label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
                items: [
                    {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
                    {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
                    {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']},
                    {label: 'Wizard', icon: 'pi pi-fw pi-star', routerLink: ['/pages/wizard']},
                    {
                        label: 'Landing', icon: 'pi pi-fw pi-globe', badge: '2', badgeClass: 'p-badge-warning',
                        items: [
                            {label: 'Static', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank'},
                            {label: 'Component', icon: 'pi pi-fw pi-globe', routerLink: ['/landing']}
                        ]
                    },
                    {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login']},
                    {label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice']},
                    {label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help']},
                    {label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error']},
                    {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound']},
                    {label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access']},
                    {label: 'Contact Us', icon: 'pi pi-fw pi-pencil', routerLink: ['/contactus']},
                    {label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty']}
                ]
            },
            {
                label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Start', icon: 'pi pi-fw pi-download',
                items: [
                    {
                        label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
                    },
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
                    }
                ]
            }
        ];*/
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.uiNavigationMainService.list(ObjectRequestList.createBasic(false, null, [new Sorting("priority", true)])).subscribe(value => {
            this.uiNavigationMainEntries = <DtoList<UiNavigationMainDto>>value;
            this.buildMenu();
        });

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    private buildMenu() {
        // iterate through and build up Prime MenuItem structure
        for (const mainNavEntry of this.uiNavigationMainEntries.entries) {

            const navItem:MenuItem = {
                label: mainNavEntry.name,
                icon: mainNavEntry.image,
                routerLink: [this.baseUrl+mainNavEntry.id],
            }

            this.model.push(navItem);
        }
        this.refreshComponent();
    }
}
