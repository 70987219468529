import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TicketTypeObjectBaseComponent} from "../ticket-type-object-base.component";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService, MvsMessageService, ObserverService} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem} from "primeng/api";
import {TicketTypeService} from "../../../../../service/api/ticket-type.service";
import {TicketTypeActionService} from "../../../../../service/api/ticket-type-action.service";
import {TicketTypeObjectRoleService} from "../../../../../service/api/ticket-type-object-role.service";
import {TicketTypeObjectTypeService} from "../../../../../service/api/ticket-type-object-type.service";
import {TicketTypeNotificationTypeService} from "../../../../../service/api/ticket-type-notification-type.service";
import {TicketTypeCompleteStatusService} from "../../../../../service/api/ticket-type-complete-status.service";


@Component({
    selector: 'ticket-type-object-component-full',
    templateUrl: 'ticket-type-object-full.component.html',
    styleUrls: ['ticket-type-object-full.component.scss'],
})
export class TicketTypeObjectFullComponent extends TicketTypeObjectBaseComponent implements OnInit {


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected observerService: ObserverService,
        protected ticketTypeService: TicketTypeService,
        protected ticketTypeActionService: TicketTypeActionService,
        protected ticketTypeObjectRoleService: TicketTypeObjectRoleService,
        protected ticketTypeObjectTypeService: TicketTypeObjectTypeService,
        protected ticketTypeNotificationTypeService: TicketTypeNotificationTypeService,
        protected ticketTypeCompletionStatusService: TicketTypeCompleteStatusService,
    ) {
        super(route, coreService, messageService, confirmationService, observerService, ticketTypeService, ticketTypeActionService, ticketTypeObjectRoleService, ticketTypeObjectTypeService, ticketTypeNotificationTypeService, ticketTypeCompletionStatusService);
    }

    /**
     * On Object Change. When object is retrieved
     */
    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {
        this.navigationItems = [
            {
                label: 'tm.TicketType',
                action: 'tm.TicketType',
                icon: 'fa-regular fa-typewriter text-xl',
                tooltip: 'Ticket Type',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeAction',
                action: 'tm.TicketTypeAction',
                icon: 'fa-solid fa-hand-point-right text-xl',
                tooltip: 'Ticket Type Action',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeObjectRole',
                action: 'tm.TicketTypeObjectRole',
                icon: 'fa-solid fa-user-tag text-xl',
                tooltip: 'Ticket Type Object Role',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeObjectType',
                action: 'tm.TicketTypeObjectType',
                icon: 'fa-solid fa-layer-group text-xl',
                tooltip: 'Ticket Type Object Type',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeNotificationType',
                action: 'tm.TicketTypeNotificationType',
                icon: 'fa-regular fa-bell text-xl',
                tooltip: 'Ticket Type Notification Type',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeCompleteStatus',
                action: 'tm.TicketTypeCompleteStatus',
                icon: 'fa-solid fa-check-circle text-xl',
                tooltip: 'Ticket Type Completion Status',
                toggleable: false,
                default: false
            },
            {
                label: 'tm.TicketTypeField',
                action: 'tm.TicketTypeField',
                icon: 'fa-solid fa-list-alt text-xl',
                tooltip: 'Ticket Type Field',
                toggleable: false,
                default: false
            }
        ];

        this.activeNavigationItem = this.navigationItems[0];


        this.handleNavigationItemsChange();
    }


}
