<ng-container *ngIf="initialized && objectLoaded && !busy">

    <div *ngIf="size == 'XL'" class="quickview --ticket">
        <div class="header">{{ dto.alias }}

            <span>{{ dto.form.getFieldValueFromValueList('status', dto.status).label }}</span>
        </div>
        <div class="content">{{ dto.typeDtoName }}
            <span>{{ dto.createdDate | date:'dd.MM.YYYY' }}</span>
        </div>
        <div class="content">{{ dto.assigneeAgentDtoName }}
            <span>{{ dto.lastModifiedDate | date:'dd.MM.YYYY' }}</span>
        </div>
        <div class="content">Due Date
            <span>{{ dto.dueDate | date:'dd.MM.YYYY' }}</span>
        </div>
        <div class="content">Description
            <span [innerHTML]="dto.description | mvsSavePipe:'html'"></span>
        </div>
        <div class="footer">
            <mvs-form-control-output-user-image [value]="dto.createdBy"></mvs-form-control-output-user-image>
            <span>{{ dto.createdDate | date:'dd.MM.YYYY' }}</span>
        </div>
    </div>

    <div *ngIf="size == 'L'" class="quickview --ticket">
        <div class="header">{{ dto.alias }}
            <span>{{ dto.form.getFieldValueFromValueList('status', dto.status).label }}</span>
        </div>
        <div class="content">{{ dto.assigneeAgentDtoName }}
            <span>{{ dto.lastModifiedDate | date:'dd.MM.YYYY' }}</span>
        </div>
        <div class="footer">{{ dto.typeDtoName }}
            <span>{{ dto.createdDate | date:'dd.MM.YYYY' }}</span>
        </div>
    </div>

    <div *ngIf="size == 'M'" class="quickview --ticket">
        <div class="header">{{ dto.alias }}
            <span>{{ dto.form.getFieldValueFromValueList('status', dto.status).label }}</span>
        </div>
        <div class="content">{{ dto.typeDtoName }}
            <span>{{ dto.assigneeAgentDtoName }}</span>
        </div>
    </div>

    <div *ngIf="size == 'S'" class="quickview --ticket">
        <div class="header">{{ dto.alias }}
            <span>{{ dto.form.getFieldValueFromValueList('status', dto.status).label }}</span>
        </div>
    </div>

    <!-- For pinned tickets -->
    <div *ngIf="size == 'XS'" class="flex gap-3">
        <p-avatar [icon]="uiTicketDto.uiIcon" class="cursor-pointer" styleClass="text-xs {{uiTicketDto.uiBgColor}}"
                  shape="circle"></p-avatar>
        <div class="flex flex-column gap-1 cursor">
                <span class="font-medium text-800">
                    {{ uiTicketDto.type }}
                </span>

            <div class="flex flex-column">
                <p class="text-sm text-color-secondary mb-0">{{ uiTicketDto.alias }}</p>
                @if (uiTicketDto.uiObjectsLength) {
                   <div class="flex gap-1 flex-wrap">
                    <p class="text-sm text-color-secondary font-medium mb-0">Related Object ({{uiTicketDto.uiLatestObjectId}})</p>
                    <p class="text-sm text-color-secondary" *ngIf="uiTicketDto.uiExtraObjectsLength">
                        <i class="text-xs fa-regular fa-period mx-1"></i>
                        +{{uiTicketDto.uiExtraObjectsLength }} more
                    </p>
                   </div>
                }
                <!--                <p class="text-sm text-color-secondary ml-1" *ngIf="dto['objectLatestCustomer']">-->
                <!--                    {{dto['objectLatestCustomer'].personDtoName}}-->
                <!--                </p>-->

            </div>
        </div>

    </div>


</ng-container>

<p-skeleton *ngIf="busy" [height]="'3rem'" [width]="'17rem'"></p-skeleton>
