export const testData = [
    {
        "date": "2024-02-01",
        "ticket_type": "Customer Onboarding",
        "ticket_status": "draft",
        "agent": "Haris",
        "ticket_count": 22
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Customer Support",
        "ticket_status": "assigned",
        "agent": "Daud",
        "ticket_count": 13
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Customer Onboarding",
        "ticket_status": "working",
        "agent": "Haris",
        "ticket_count": 42
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Billing",
        "ticket_status": "awaiting_customer",
        "agent": "Haris",
        "ticket_count": 6
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Customer Onboarding",
        "ticket_status": "awaiting_partner",
        "agent": "Daud",
        "ticket_count": 19
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Software Bug",
        "ticket_status": "resolved",
        "agent": "Haris",
        "ticket_count": 40
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Customer Support",
        "ticket_status": "on_hold",
        "agent": "Marko",
        "ticket_count": 19
    },
    {
        "date": "2024-02-01",
        "ticket_type": "Billing",
        "ticket_status": "cancelled",
        "agent": "Ali",
        "ticket_count": 5
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Customer Support",
        "ticket_status": "draft",
        "agent": "Marko",
        "ticket_count": 26
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Technical Issue",
        "ticket_status": "assigned",
        "agent": "Daud",
        "ticket_count": 18
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Technical Issue",
        "ticket_status": "working",
        "agent": "Haris",
        "ticket_count": 10
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Billing",
        "ticket_status": "awaiting_customer",
        "agent": "Haris",
        "ticket_count": 27
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Technical Issue",
        "ticket_status": "awaiting_partner",
        "agent": "Ali",
        "ticket_count": 7
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Billing",
        "ticket_status": "resolved",
        "agent": "Haris",
        "ticket_count": 29
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Customer Onboarding",
        "ticket_status": "on_hold",
        "agent": "Ali",
        "ticket_count": 45
    },
    {
        "date": "2024-02-02",
        "ticket_type": "Software Bug",
        "ticket_status": "cancelled",
        "agent": "Ali",
        "ticket_count": 41
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Customer Support",
        "ticket_status": "draft",
        "agent": "Haris",
        "ticket_count": 7
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Customer Support",
        "ticket_status": "assigned",
        "agent": "Ali",
        "ticket_count": 10
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Customer Support",
        "ticket_status": "working",
        "agent": "Haris",
        "ticket_count": 29
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Technical Issue",
        "ticket_status": "awaiting_customer",
        "agent": "Marko",
        "ticket_count": 45
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Technical Issue",
        "ticket_status": "awaiting_partner",
        "agent": "Daud",
        "ticket_count": 28
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Technical Issue",
        "ticket_status": "resolved",
        "agent": "Daud",
        "ticket_count": 47
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Technical Issue",
        "ticket_status": "on_hold",
        "agent": "Haris",
        "ticket_count": 43
    },
    {
        "date": "2024-02-03",
        "ticket_type": "Customer Support",
        "ticket_status": "cancelled",
        "agent": "Daud",
        "ticket_count": 15
    },
    {
        "date": "2024-02-04",
        "ticket_type": "Customer Support",
        "ticket_status": "cancelled",
        "agent": "Daud",
        "ticket_count": 10
    },
    {
        "date": "2024-02-04",
        "ticket_type": "Customer Support",
        "ticket_status": "on_hold",
        "agent": "Daud",
        "ticket_count": 12
    },
    {
        "date": "2024-02-04",
        "ticket_type": "Customer Support",
        "ticket_status": "assigned",
        "agent": "Daud",
        "ticket_count": 21
    },
    {
        "date": "2024-02-04",
        "ticket_type": "Customer Support",
        "ticket_status": "working",
        "agent": "Daud",
        "ticket_count": 40
    },
    {
        "date": "2024-02-04",
        "ticket_type": "Customer Support",
        "ticket_status": "resolved",
        "agent": "Daud",
        "ticket_count": 19
    }
]
