import {DtoDetail} from "@kvers/alpha-core-common";
import { NsNotificationTypeDto } from "./ns-notification-type.dto";
import {CoChannelTypeDto} from "../../co/dto/co-channel-type.dto";
import {TeTemplateDto} from "../../te/dto/te-template.dto";
import {TeTemplateVariableDto} from "../../te/dto/te-template-variable.dto";

export class NsNotificationTypeChannelTypeDto extends DtoDetail{


    public notificationTypeDto? : NsNotificationTypeDto;
    public notificationTypeDtoId : number;
    public notificationTypeDtoName : string;
    public channelTypeDto? : CoChannelTypeDto;
    public channelTypeDtoId : number;
    public channelTypeDtoName : string;
    public templateDto? : TeTemplateDto;
    public templateDtoId : number;
    public templateDtoName : string;
    public templateRecipientVariableDto? : TeTemplateVariableDto;
    public templateRecipientVariableDtoId : number;
    public templateRecipientVariableDtoName : string;
    public subjectText: string;
    public subjectTemplateDto? : TeTemplateDto;
    public subjectTemplateDtoId : number;
    public subjectTemplateDtoName : string;
    public subjectTemplateRecipientVariableDto? : TeTemplateVariableDto;
    public subjectTemplateRecipientVariableDtoId : number;
    public subjectTemplateRecipientVariableDtoName : string;
    public imageUrl: string;

}