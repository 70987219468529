<p-overlayPanel appendTo="body" #op (onShow)="handleShowOverlay()">
    <div *ngIf="agentActiveObjects && agentActiveObjects?.length">
        <ul class="list-none p-0 m-0">
            <li class="pb-2 pl-2" style="min-width:340px">
                <span>You have <b>{{ agentActiveObjects?.length }}</b> items pinned</span>
            </li>
            @if (overlayLoaded) {

                @for (activeObject of agentActiveObjects; track activeObject.objectId) {
                    <ng-container *ngIf="!activeObject.mainObject">
                        <li class="am-agent-notification-item-box pl-2">

                            <!--                            @if (busy) {-->
                            <!--                                <p-skeleton [height]="'3rem'" [width]="'20rem'"></p-skeleton>-->
                            <!--                            } @else {-->
                            <mvs-object-quick-view [objectId]="activeObject.objectId"
                                                   [objectAlias]="activeObject.objectTypeDto.alias"
                                                   [transientDto]="activeObject.objectInfoDto"
                                                   (click)="openObjectDrawer(activeObject)"
                                                   [size]="'XS'">
                            </mvs-object-quick-view>
                            <!--                        <div class="flex align-items-center gap-2"-->
                            <!--                             (click)="openObjectDrawer(activeObject)">-->
                            <!--                            <div class="am-agent-notification-item-box-icons"-->
                            <!--                                 [style]="{'background':'var(&#45;&#45;' + activeObject.objectTypeDto['rgbaColorBackground'] +')'}">-->
                            <!--                                <i-->
                            <!--                                        [style]="{'color':'var(&#45;&#45;'+ activeObject.objectTypeDto['rgbaColor'] +')'}"-->
                            <!--                                        [class]="activeObject.objectTypeDto['image']"-->
                            <!--                                ></i>-->
                            <!--                            </div>-->
                            <!--                            <div class="flex flex-column justify-content-center">-->
                            <!--                                <span class="am-agent-notification-item-box-name">{{ getDisplayName(activeObject) }}</span>-->
                            <!--                                <span class="am-agent-notification-item-box-date">{{activeObject.startTime | MvsDateAgo}}</span>-->
                            <!--                            </div>-->
                            <!--                        </div>-->

                            <div class="flex justify-content-center align-items-center gap-2">
                                <div
                                        *ngIf="activeObject.objectTypeDto.alias == 'tm.Ticket'"
                                        (click)="activeAgentPinnedObject(activeObject)">
                                    <p-button icon="fa-regular fa-ticket"
                                              styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-success p-button-rounded p-button-text"
                                              severity="success "></p-button>
                                </div>

                                <div (click)="unPinAgentObject(activeObject.id)">

                                    <p-button icon="pi pi-times"
                                              styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                              severity="danger">
                                    </p-button>

                                </div>
                            </div>
                            <!--                            }-->


                        </li>
                    </ng-container>
                }
            }
            <!--            style="padding:14px"-->
            <li>
                <div class="flex justify-content-center align-items-center pt-3">
                    <p-button
                            icon="fa-regular fa-check"
                            label="Mark all as completed"
                            styleClass="p-ripple p-element p-button p-component p-button-secondary p-button-text p-0"
                            (click)="completeAllObjects(op)"></p-button>
                </div>
            </li>
        </ul>
    </div>
</p-overlayPanel>

<div (click)="openOverlayPanel(op, $event)">

    <a class="layout-topbar-action rounded-circle flex justify-content-center align-items-center"
       [@flipLeft]="agentActiveObjects?.length">
        <span class="p-overlay-badge">
            <i class="fa-regular fa-thumbtack text-2xl mr-2 text-500"></i>
            <span class="ml-2 p-badge p-badge-danger p-badge-dot"
                  *ngIf="agentActiveObjects && agentActiveObjects?.length"></span>
        </span>
    </a>
</div>
