import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {AgentPoolService} from "../../../service/api/agent-pool.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {AgentPoolDto} from "../../../dto/agent-pool.dto";
import {TreeNode} from "primeng/api";
import {AgentPoolAgentService} from "../../../service/api/agent-pool-agent.service";
import {AgentService} from "../../../service/api/agent.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {AgentPoolAgentDto} from "../../../dto/agent-pool-agent.dto";
import {iObject} from "@kvers/alpha-ui";


@Component({
    selector: 'am-agent-overview-page',
    templateUrl: './am-agent-overview.page.html',
})
export class AmAgentOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Agents Overview";

    objectIdentifier: ObjectIdentifier;

    agentPoolDtoList: AgentPoolDto[];

    agentPoolTree: TreeNode[];
    selectedNode: TreeNode;

    selectedObject: iObject;

    constructor(protected route: ActivatedRoute,
                protected router: Router,
                protected coreService: MvsCoreService,
                protected agentPoolService: AgentPoolService,
                protected agentPoolAgentService: AgentPoolAgentService,
                protected agentService: AgentService) {
        super(route, coreService)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.route.queryParams.subscribe((params: Params) => {

            if (params && params['objectType'] && params['objectId']) {
                this.objectIdentifier = new ObjectIdentifier(params['objectType'], +params['objectId']);
            }

            if (!this.initialized) {
                this.retrieveAgentPools();
            }
        });

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * retrieve all agent pools
     */
    retrieveAgentPools(): void {
        const objectRequest = new ObjectRequestList(false, [], []);
        this.agentPoolService.list(objectRequest).subscribe(res => {
            this.agentPoolDtoList = res.entries;

            if (!this.agentPoolTree) {
                this.agentPoolTree = [];
            }
            this.agentPoolTree = this.agentPoolDtoList.map((agentPool, index) => this.createRootNode(agentPool, index === 0));

            // sort nodes alphabetically

            this.sortNodes(this.agentPoolTree);

        })
    }

    /**
     * retrieve agent pool agents of selected pool
     * @param node
     */
    retrieveAgentPoolAgent(node: TreeNode): void {

        const filterCriteria = FilterCriteria.create('agentPool', FilterCriteria.cOperatorEqual, node.data.id)

        const objectRequest = new ObjectRequestList(false, [filterCriteria], []);

        node.children = [{}];

        this.agentPoolAgentService.list(objectRequest).subscribe(res => {

            node.children = res.entries.map(agentPool => this.createChildNode(agentPool));

            // sort children nodes alphabetically
            this.sortNodes(this.agentPoolTree);

            this.refreshComponent();

        })
    }


    /**
     * handle selected node
     * @param node
     */

    handleNodeSelect(node: any) {
        this.selectedObject = null; // Resetting any previously selected object

        // Ensuring the selectedNode and its type are defined
        if (!this.selectedNode || !this.selectedNode.type) return;

        const { type, data } = this.selectedNode;
        const objectTypeMap = {
            'pool': 'am.AgentPool',
            'agent': 'am.Agent'
        };

        const objectIdKey = type === 'pool' ? 'id' : type === 'agent' ? 'agentDtoId' : null;
        if (!objectIdKey) return; // Exit if no valid key is found, handle other types as needed

        // Define the object identifier based on node type
        const expectedType = objectTypeMap[type];
        const objectId = data[objectIdKey];

        // Check if we need to update the objectIdentifier
        if (!this.objectIdentifier || this.objectIdentifier.objectType !== expectedType || this.objectIdentifier.objectId !== objectId) {
            this.objectIdentifier = new ObjectIdentifier(expectedType, objectId);
        }

        this.updateQueryParam(); // Update query parameter
    }

    // handleNodeSelect(node: any) {
    //     this.selectedObject = null;
    //     if (this.selectedNode.type == 'pool') {
    //         if (this.objectIdentifier && this.objectIdentifier.objectType == 'am.AgentPool' && this.objectIdentifier.objectId == this.selectedNode.data.id) {
    //
    //         } else {
    //             this.objectIdentifier = new ObjectIdentifier('am.AgentPool', this.selectedNode.data.id);
    //         }
    //     }
    //     if (this.selectedNode.type == 'agent') {
    //         if (this.objectIdentifier && this.objectIdentifier.objectType == 'am.Agent' && this.objectIdentifier.objectId == this.selectedNode.data.agentDtoId) {
    //
    //         } else {
    //             this.objectIdentifier = new ObjectIdentifier('am.Agent', this.selectedNode.data.agentDtoId);
    //         }
    //     }
    //
    //     this.updateQueryParam();
    // }

    /**
     * handle node expand it type is pool
     * @param event
     */
    handleNodeExpand(event: any) {

        const node = event.node;

        if (node.type == 'pool') {
            this.retrieveAgentPoolAgent(node);
        }
    }

    /**
     * sort nodes and its children alphabetically
     * @param nodes
     */
    sortNodes(nodes: TreeNode[]) {
        // Sort the nodes array
        nodes.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        // Recursively sort any children arrays
        nodes.forEach(node => {
            if (node.children && node.children.length > 0) {
                this.sortNodes(node.children);
            }
        });
    }

    /**
     * creates root nodes i.e: pools
     * @param agentPool
     * @param isExpanded
     */
    createRootNode(agentPool: AgentPoolDto, isExpanded: boolean = false): TreeNode {

        let expandedIcon: string = 'pi pi-folder-open';
        let collapsedIcon: string = 'pi pi-folder';

        let node: TreeNode = {
            label: agentPool.name,
            data: agentPool,
            expandedIcon: expandedIcon,
            collapsedIcon: collapsedIcon,
            leaf: true,
            children: [{}],
            type: 'pool',
            expanded: isExpanded,
        };

        if (this.objectIdentifier && this.objectIdentifier.objectId == agentPool.id) {
            this.selectedNode = node;
        } else if (!this.objectIdentifier && isExpanded) {
            this.selectedNode = node;
        }

        if (isExpanded) {
            const event = {node: node}
            this.handleNodeExpand(event);
        }

        return node;
    }

    /**
     * create child nodes i.e: agents
     * @param agent
     */
    createChildNode(agent: AgentPoolAgentDto): TreeNode {

        const node = {
            label: agent.agentDtoName,
            data: agent,
            collapsedIcon: 'fa fa-user',
            leaf: true,
            type: 'agent'
        };

        return node;
    }

    /**
     * handle agent select
     * @param object
     */
    handleAgentSelect(object: iObject) {
        this.selectedObject = object;
        this.selectedNode = null;
        this.objectIdentifier = new ObjectIdentifier('am.Agent', object.id);
        this.updateQueryParam();
    }

    updateQueryParam() {
        this.router.navigate([], {
            queryParams: {objectType: this.objectIdentifier.objectType, objectId: this.objectIdentifier.objectId},
            queryParamsHandling: 'merge' // merge with the current query params
        });
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
