import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {ContractPersonDto} from "../../dto/contract-person.dto";


@Component({
    selector: 'ci-contract-person',
    templateUrl: './ci-contract-person.component.html',
})
export class CiContractPersonComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    tabItems: MenuItem[];
    activeItem: MenuItem | undefined;

    widgetContractPerson: WidgetData;
    widgetPerson: WidgetData;

    dto: ContractPersonDto;

    ngOnInit() {
        super.ngOnInit();

        this.tabItems = [
            { id : "ci.ContractPerson", label : "Zuweisung", icon : "fa-light fa-link"},
            { id : "pm.Person", label : "Person", icon : "fa-sharp fa-light fa-folder-user"},
        ];
        this.activeItem = this.tabItems[0];

    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.widgetContractPerson = WidgetFactory.createWidgetObjectWithCallback(
            "ci.contract.person.assignment.form",
            "Vertragszuweisung",
            "ci.ContractPerson",
            {
                onFunctionObjectUpdate(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField("personDtoId",MvsFormFieldAccessEnum.HIDDEN);
                    overwrite.addField("contractDtoId",MvsFormFieldAccessEnum.HIDDEN);
                    return { formControlOverwrite: overwrite };
                }
            },
            this.dto.id
        );

        this.widgetPerson = WidgetFactory.createWidgetObject(
            "ci.contract.person.assigned.person.form",
            "Person",
            "pm.Person",
            this.dto.personDtoId
        );

    }

}
