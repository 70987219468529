import {Component, OnInit} from "@angular/core";
import {AgentInfoDetailObjectBaseComponent} from "../agent-info-detail-object-base.component";

@Component({
    selector: 'am-agent-info-detail-object-full',
    templateUrl: './agent-info-detail-object-full.component.html',
})

export class AgentInfoDetailObjectFullComponent extends AgentInfoDetailObjectBaseComponent implements OnInit{

    ngOnInit() {
        super.ngOnInit();
    }
}