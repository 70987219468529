import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';

import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {CallDto} from "../../dto/call.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {CallProtocolDto} from "../../dto/call-protocol.dto";
import {CallProtocolStatusEnum} from "../../enum/call-protocol-status.enum";

@Component({
    selector: 'mvs-pc-call',
    templateUrl: './pc-call.component.html',
})
export class PcCallComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    dto: CallDto;

    widgetObject: WidgetData;
    widgetCallProtocols: WidgetData;

    callObjectContext: DtoImportObjectContext;

    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.uiRefresh();

    }

    uiRefresh() {

        // WIDGET - CALL Data
        this.widgetObject = new WidgetData();
        this.widgetObject.idAlias = "pc.call.basic.data";
        this.widgetObject.name = "Anruf";
        this.widgetObject.uiComponent = "data";
        this.widgetObject.dataProvider = "list";
        this.widgetObject.dataSource = "entity";
        this.widgetObject.dataProviderObject = "pc.Call";

        this.widgetObject.setParamValue("size", "S");
        this.widgetObject.setParamValue("objectId",this.objectIdentifier.objectId);

        // create context for call Object
        this.callObjectContext = DtoImportObjectContext.createFromObjectIdentifier(this.objectIdentifier);

        // WIDGET - CALL Protocol
        this.widgetCallProtocols = new WidgetData();
        this.widgetCallProtocols.idAlias = "pc.call.protocols.list";
        this.widgetCallProtocols.name = "Protokolle";
        this.widgetCallProtocols.uiComponent = "list";
        this.widgetCallProtocols.dataProvider = "list";
        this.widgetCallProtocols.dataSource = "entity";
        this.widgetCallProtocols.dataProviderObject = "pc.CallProtocol";
        this.widgetCallProtocols.noDataText = "Keine Protokolle vorhanden";

        this.widgetCallProtocols.dataProviderListRequest = new ObjectRequestList(
            true,
            [ FilterCriteria.create("call", FilterCriteria.cOperatorEqual, this.dto.id)],
            [new Sorting("createdDate", false)]
        );


        this.widgetCallProtocols.functionCallbacks = {

            onFunctionCreateNew : (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new CallProtocolDto();

                    dto.callDtoId = this.dto.id;
                    dto.comment = " ";
                    dto.protocolStatus = CallProtocolStatusEnum.in_progress;

                    // we'll create the protocol automatically no need to enter any information
                    this.coreService.getCrudService("pc.CallProtocol").create(dto).subscribe(value => {
                        // no activity required, the widget will refresh itself showing the created protocol.
                    });

                    return { cancel: true };
        }};

        this.widgetCallProtocols.setParamValue("size", "S");
        this.widgetCallProtocols.setParamValue("selectionMode", "rowSelect");
        this.widgetCallProtocols.setParamValue("objectWidget", "object");
        this.widgetCallProtocols.setParamValue("columns", "3");


    }

}
