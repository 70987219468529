import {DtoDetail} from "@kvers/alpha-core-common";
import {WfActivityTypeObjectActivityEnum} from "../enum/wf-activity-type-object-activity-enum.enum";
import {ObjectTypeDto} from "../../cc/dto/object-type.dto";

export class WfProcessTypeStepActivityObjectDto extends DtoDetail {


    public objectTypeDto?: ObjectTypeDto;
    public objectTypeDtoId: number;
    public objectTypeDtoName: string;
    public activityEnum: WfActivityTypeObjectActivityEnum;
    public processMultiple: boolean;
    public objectShowList: boolean;
    public uiType: WfProcessObjectUiType

}

export enum WfProcessObjectUiType {
    WidgetForm = 0,
    Object = 1
}
