import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DtoList, MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {
  ObjectTicketGroupFilterComponent
} from "../../component/object-ticket-group-filter/object-ticket-group-filter.component";
import {Observable} from "rxjs";
import {TicketGroupFilterDto} from "../../dto/ticket-group-filter.dto";
import {TicketGroupFilterCountDto} from "./dto/ticket-group-filter-count.dto";
import {TicketGroupFilterInfoDto} from "./dto/ticket-group-filter-info.dto";
import {
  TicketFilterGroupListComponent
} from "../../component/ticket-management/components/ticket-filter-group-list-component/ticket-filter-group-list.component";

@Injectable({
  providedIn: 'root'
})
export class TicketGroupFilterService extends MvsCrudService {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketGroupFilters')
  }

  getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return ObjectTicketGroupFilterComponent;
    }
    return null;
  }
  
  getObjectListComponent(mode?: MvsCrudModeEnum): Type<any> {
    return TicketFilterGroupListComponent;
  }

  public getMeGroups() : Observable<DtoList<TicketGroupFilterDto>> {
    return <Observable<DtoList<TicketGroupFilterDto>>><unknown>this.getInternal(this.apiUrl + "/me/groups");
  }

  public getCount(ticketGroupFilterIds: number[]) : Observable<TicketGroupFilterCountDto[]> {

    let ids = "";
    for (let ticketGroupFilterId of ticketGroupFilterIds) {
      if (ids !== "") {
        ids+=","
      }
      ids+="" + ticketGroupFilterId;
    }

    return <Observable<TicketGroupFilterCountDto[]>><unknown>this.getInternal(this.apiUrl +  `/me/groups/count?ids=${ids}`);
  }

  public getGroupInfo(ticketGroupFilterId: number) : Observable<TicketGroupFilterInfoDto> {
    return <Observable<TicketGroupFilterInfoDto>><unknown>this.getInternal(this.apiUrl +  `/me/group/${ticketGroupFilterId}/info`);
  }


}