import {Component, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ImDataImportService} from "../../../service/api/im-data-import.service";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-im-dashboard-page',
  templateUrl: './im-dashboard.page.html',
  styleUrls: ['./im-dashboard.page.scss']
})
export class ImDashboardPage extends PageComponent implements OnInit {

  widgetImports: WidgetData;

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  selectedImportId: number;

  defaultLabel: string = "Import Dashboard";

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected dataImportService: ImDataImportService) {
    super(route,coreService);
  }


  ngOnInit(): void {

    super.ngOnInit();

    this.initComponent();
    this.refreshComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
    this.initImportWidget();
  }

  initImportWidget() {

    this.widgetImports = new WidgetData();
    this.widgetImports.idAlias = "im.dashboard.imports.table";
    this.widgetImports.name = "Durchgeführte Daten-Imports";
    this.widgetImports.uiComponent = "table";
    this.widgetImports.dataProvider = "list";
    this.widgetImports.dataSource = "entity";
    this.widgetImports.dataProviderObject = "im.ImImport";

    //getImportComplexSelectionRuntime
    const importComplexSelectionRuntime = this.dataImportService.getImportComplexSelectionRuntime();


    this.widgetImports.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
        true,
        importComplexSelectionRuntime,
        [],
        [new Sorting("createdDate", false)],
        PagingDto.create(0, 5)
    );

    this.widgetImports.setParamValue("selectionMode", "single");
    this.widgetImports.setParamValue("size", "S");
    //this.widgetImports.uiRefresh = WidgetDataRefresh.createAutoRefresh(true, 5, 20);

  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  onImportSelect(objectIdentifier: ObjectIdentifier) {

    this.selectedImportId = objectIdentifier.objectId;

  }


}
