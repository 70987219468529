import {Component, OnChanges, OnInit, SimpleChanges,} from "@angular/core";
import {EntityStatusEnum, ObjectBaseComponent, WidgetData} from "@kvers/alpha-core-common";
import {TransformedEventInterface, WidgetFactory} from "@kvers/alpha-ui";
import {AppointmentDto} from "../../dto/appointment.dto";

@Component({
    selector: "as-calendar-component",
    templateUrl: "./as-appointment-event.component.html",
    styleUrls: ["./as-appointment-event.component.scss"],
})
export class AsAppointmentEventComponent extends ObjectBaseComponent
    implements OnInit, OnChanges {

    event: TransformedEventInterface | null = null;

    dto: AppointmentDto;

    appointmentWidgetData: WidgetData;

    ngOnInit() {
        super.ngOnInit();
        this.initialized = true;
    }

    onObjectChanged() {
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.appointmentWidgetData = WidgetFactory.createWidgetEntityData(
            'as.appointment.widget.data.basic',
            'Appointment',
            'as.Appointment',
            this.objectIdentifier.objectId
        )

    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (!this.initialized) {
            return;
        }
    }

    protected readonly EntityStatusEnum = EntityStatusEnum;
}

