import {Route, Routes} from "@angular/router";
import {CfFrequencyPage} from "./page/overview/cf-frequency/cf-frequency.page";
import {CfPayFrequencyPage} from "./page/overview/cf-pay-frequency/cf-pay-frequency.page";
import {CfPaymentMomentPage} from "./page/overview/cf-payment-moment/cf-payment-moment.page";
import {CfPaymentTypePage} from "./page/overview/cf-payment-type/cf-payment-type.page";
import {CfRoundingRulePage} from "./page/overview/cf-rounding-rule/cf-rounding-rule.page";
import {
    CfTemplateCalculationBasePage
} from "./page/overview/cf-template-calculation-base/cf-template-calculation-base.page";
import {
    CfTemplateCancellationBufferPage
} from "./page/overview/cf-template-cancellation-buffer/cf-template-cancellation-buffer.page";
import {CfTemplateLiabilityPage} from "./page/overview/cf-template-liability/cf-template-liability.page";

export function CfSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'cf/frequencies', component: CfFrequencyPage},
        {path: 'cf/payFrequencies', component: CfPayFrequencyPage},
        {path: 'cf/paymentMoments', component: CfPaymentMomentPage},
        {path: 'cf/paymentTypes', component: CfPaymentTypePage},
        {path: 'cf/roundingRules', component: CfRoundingRulePage},
        {path: 'cf/templateCalculationBases', component: CfTemplateCalculationBasePage},
        {path: 'cf/templateCancellationBuffers', component: CfTemplateCancellationBufferPage},
        {path: 'cf/templateLiabilities', component: CfTemplateLiabilityPage},


    ];

    return routes;

}
