import {Component, Input, OnInit} from '@angular/core';
import {ObjectIdentifier, EntityStatusEnum} from "@kvers/alpha-core-common";
import { MvsObjectNavigationService} from "@kvers/alpha-ui";
import {TmTicketAppointmentDto} from "../../dto/tm-ticket-appointment.dto";
import {TicketAppointmentService} from "../../service/api/ticket-appointment.service";
import {AppointmentRequestUrgency} from "../../enum/appointment-request-urgency.enum";

@Component({
    selector: 'tm-ticket-appointment-inline-component',
    templateUrl: './tm-ticket-appointment-inline-component.html',
})
export class TmTicketAppointmentInlineComponent implements OnInit {

    @Input() objectIdentifier: ObjectIdentifier;
    icon: string;
    dto: TmTicketAppointmentDto;


    protected readonly EntityStatusEnum = EntityStatusEnum;
    initialized: boolean;
    busy: boolean;

    constructor(protected customerService: TicketAppointmentService, protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit() {
        this.refreshComponent();
    }

    refreshComponent() {
        this.customerService.get(this.objectIdentifier.objectId).subscribe((res: TmTicketAppointmentDto) => {
            this.dto = res;
            this.initialized = true;
        })

        this.icon = this.customerService.getObjectIcon();
    }

    protected readonly AppointmentRequestUrgency = AppointmentRequestUrgency;
}
