import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    FilterCriteria,
    ObjectIdentifier,
    ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation, PagingDto, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {CallRecipientTypeEnum} from "../../../../../pc/enum/call-recipient-type-enum.enum";
import {AppointmentTypeAgentService} from "../../../../../as/service/api/appointment-type-agent.service";
import {AgentDto} from "../../../../../am/dto/agent.dto";
import {AppointmentDto} from "../../../../../as/dto/appointment.dto";

@Component({
    selector: 'tm-ticket-customer-contact',
    templateUrl: 'tm-ticket-customer-contact.component.html',
})
export class TmTicketCustomerContactComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() customerId: number;
    @Input() appointmentTypeId: number;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() ticketActionIdentifier: ObjectIdentifier;

    @Output() onAppointmentCreate: EventEmitter<AppointmentDto> = new EventEmitter();

    customerCallsWidget: WidgetData;


    agents: AgentDto[];

    constructor(protected appointmentTypeAgentService: AppointmentTypeAgentService) {
        if (this.customerId) {
            this.objectIdentifier = new ObjectIdentifier('cu.Customer', this.customerId);
        }

    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.showCustomerCallWidget();
        this.fetchAppointmentTypeAgents();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    fetchAppointmentTypeAgents() {
        const filterCriteria = FilterCriteria.create('appointmentType', FilterCriteria.cOperatorEqual, this.appointmentTypeId);
        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);

        objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('agent')
        );

        this.appointmentTypeAgentService.list(objectRequest).subscribe(res => {
            this.agents = res.entries.map(agentInfo => agentInfo.agentDto);
            this.refreshComponent();
        });
    }

    showCustomerCallWidget() {

        const filterCriteriaList = [];

        filterCriteriaList.push(
            FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create('callRecipientType', FilterCriteria.cOperatorEqual, CallRecipientTypeEnum.customer)
        );

        this.customerCallsWidget = WidgetFactory.createWidgetListEntity(
            'tm.ticket.cr.customer.communication.navigation.show.customer.calls',
            "Anrufe",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(
                    false,
                    false,
                    ObjectRequestComplexNode.createRelationNode("lastProtocol", ObjectRequestRelation.createLatest("pc.CallProtocol", "call")),
                    ObjectRequestComplexNode.createSimpleAttributeNode("agent"),
                    ObjectRequestComplexNode.createSimpleAttributeNode("callType")
                ),
                filterCriteriaList,
                [new Sorting("startTime", false)],
                PagingDto.create(0, 10),
                null)
        );
    }

    handleAppointmentCreate(appointment: AppointmentDto) {
        this.onAppointmentCreate.emit(appointment);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
