import { NgModule } from '@angular/core';

import {CoreModule} from "../core/core.module";
import {UiFieldPage} from "./page/overview/ui-field/ui-field.page";
import {UiFieldTypePage} from "./page/overview/ui-field-type/ui-field-type.page";
import {UiFieldGroupPageComponent} from "./page/overview/ui-field-group/ui-field-group.page";
import {UiNavigationConfigPage} from "./page/overview/ui-navigation-config/ui-navigation-config.page";
import {
  UiObjectNavigationMainComponent
} from "./component/ui-object-navigation-main/ui-object-navigation-main.component";
import {UiObjectNavigationMainPage} from "./page/object/ui-object-navigation-main/ui-object-navigation-main.page";
import {UiObjectNavigationSubPage} from "./page/object/ui-object-navigation-sub/ui-object-navigation-sub.page";
import {UiObjectNavigationSubComponent} from "./component/ui-object-navigation-sub/ui-object-navigation-sub.component";
import {UiObjectNavigationCardComponent} from "./component/ui-object-navigation-card/ui-object-navigation-card.component";
import {UiNavigationSideComponent} from "./component/ui-navigation-side/ui-navigation-side.component";
import {UiNavigationFavoritesComponent} from "./component/ui-navigation-favorites/ui-navigation-favorites.component";
import {
  UiNavigationLastVisitsComponent
} from "./component/ui-navigation-last-visits/ui-navigation-last-visits.component";
import {UiOverviewPage} from "./page/ui-overview-page/ui-overview.page";
import {UiStatisticsComponent} from "./page/ui-overview-page/components/statisticsComponent/ui-statistics.component";
import {UiKpiComponent} from "./page/ui-overview-page/components/kpiComponent/ui-kpi.component";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {AgentKpiComponent} from "../am/page/agent-overview-page/components/kpiComponent/agent-kpi.component";
import {
  AgentStatisticsComponent
} from "../am/page/agent-overview-page/components/statisticsComponent/agent-statistics.component";
import {
  KpiContainerComponent
} from "./page/object/ui-object-navigation-main/kpi-container-conponent/kpi-container.component";
import {
  StatisticsContainerComponent
} from "./page/object/ui-object-navigation-main/statistics-container-conponent/statistics-container.component";
import {UiSettingUserComponent} from "./component/ui-setting-user/ui-setting-user.component";
import {UiConfigPage} from "./component/ui-config/ui-config.page";
// import {WfModule} from "../wf/wf.module";



@NgModule({
  declarations: [
    UiFieldPage,
    UiFieldTypePage,
    UiFieldGroupPageComponent,
    UiNavigationConfigPage,
    UiObjectNavigationMainComponent,
    UiObjectNavigationMainPage,
    UiObjectNavigationSubPage,
    UiObjectNavigationSubComponent,
    UiObjectNavigationCardComponent,
    UiNavigationSideComponent,
    UiNavigationFavoritesComponent,
    UiNavigationLastVisitsComponent,
    UiOverviewPage,
    UiStatisticsComponent,
    UiKpiComponent,
    KpiContainerComponent,
    StatisticsContainerComponent,
    UiSettingUserComponent,
    UiConfigPage,
  ],
  exports: [
    UiFieldPage,
    UiFieldTypePage,
    UiFieldGroupPageComponent,
    UiNavigationConfigPage,
    UiObjectNavigationMainComponent,
    UiObjectNavigationMainPage,
    UiObjectNavigationSubPage,
    UiObjectNavigationSubComponent,
    UiObjectNavigationCardComponent,
    UiNavigationSideComponent,
    UiNavigationFavoritesComponent,
    UiNavigationLastVisitsComponent,
    UiSettingUserComponent,

  ],
  imports: [
    CoreModule,
    // WfModule
  ]
})
export class UiModule implements DynamicModule {

  alias = 'ui';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return UiKpiComponent;
  }

  getStatisticsComponent() {
    return UiStatisticsComponent;
  }
}

