import {Injectable, Type} from '@angular/core';
import {MvsCrudService, NavigationItem} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {UmRoleObjectComponent} from "../../components/object/um-role-object-component/um-role-object.component";

@Injectable({
    providedIn: 'root'
})
export class RoleService extends MvsCrudService {

    navigationItems: NavigationItem[] = [
        { label: 'Config',action: 'Config', icon: 'pi pi-fw pi-cog', toggleable: false, default: true }, // cog for configuration
        { label: 'Module',action: 'Module', icon: 'pi pi-fw pi-sitemap', toggleable: false }, // sitemap for modules (hierarchical structure)
        { label: 'ObjectType',action: 'ObjectType', icon: 'pi pi-fw pi-th-large', toggleable: false }, // grid for object types
        { label: 'Contract',action: 'Contract', icon: 'pi pi-fw pi-file', toggleable: false }, // file for contract
        { label: 'Customer',action: 'Customer', icon: 'pi pi-fw pi-users', toggleable: false }, // users for customer
    ];

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/um/roles')
    }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {

        if (mode == MvsCrudModeEnum.create) {
            return null;
        }
        return UmRoleObjectComponent;
    }

    getObjectComponentNavigationItems(): NavigationItem[] {

        return this.navigationItems;

    }
}