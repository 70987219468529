import {Injectable} from '@angular/core';
import {FilterCriteria, MvsCrudService, ObjectRequestList} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EmSchemaStructureService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/em/emSchemaStructures')
    }

    getChildren(schemaId: number, parentId: number): Observable<any[]> {
        const filterCriteria = [FilterCriteria.create('parentField', FilterCriteria.cOperatorEqual, parentId),FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, schemaId)];
        const objectRequestList = ObjectRequestList.createBasic(false, filterCriteria, []);

        return this.list(objectRequestList).pipe(
            map(response => response.entries)
        );
    }
}