import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {OAuthService} from "angular-oauth2-oidc";
import {AuthTokenRefreshService} from "./core/auth/auth-token-refresh.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    topbarTheme = 'white';

    menuTheme = 'light';

    layoutMode = 'light';

    menuMode = 'slim'; 

    inlineMenuPosition = 'top';

    inputStyle = 'outlined';

    ripple = true;

    isRTL = false;

    isIframe = false;
    loginDisplay = false;

    constructor(
        private primengConfig: PrimeNGConfig,
        private oauthService: OAuthService,
        private router: Router,
        private tokenRefreshService: AuthTokenRefreshService) {

        // AuthTokenRefreshService is instantiated to set up automatic silent refresh

    }

    ngOnInit() {
        this.primengConfig.ripple = true;

        if (this.oauthService.hasValidAccessToken()) {
            const redirectUrl = localStorage.getItem('redirectUrl');

            if (redirectUrl) {
                this.router.navigate(['/']).then(() => {
                    this.router.navigateByUrl(redirectUrl);
                    localStorage.removeItem('redirectUrl');
                });
            }
        }
    }

    login() {
        this.oauthService.initImplicitFlow();
    }

}
