import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SearchObjectRequestDto} from "../../logic/search-object-request.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {SearchIndexRequestDto} from "../../logic/search-index-request.dto";
import {IndexRunDto} from "../../dto/index-run.dto";
import {SearchRequestDto} from "../../logic/search-request.dto";
import {SearchResultDto} from "../../logic/search-result.dto";

@Injectable({
    providedIn: 'root'
})
export class IndexRunService extends MvsCrudService {

    specialCharacterMap = new Map<string, string>([
        ['*', 'cr.Customer'],
        ['#', 'ui.UiPage'],
    ])

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/si/indexRuns')
    }

    /**
     * Start indexing.
     * @param request
     */
    public startIndex(request: SearchIndexRequestDto): Observable<IndexRunDto> {

        return this.http.post<IndexRunDto>(
            this.apiUrl + "/startIndex",
            request);
    }

    public searchRequest(request: SearchRequestDto): Observable<SearchResultDto> {

        return this.http.post<SearchResultDto>(
            this.apiUrl + "/searchRequest",
            request);
    }

    /**
     * Execute Search.
     * @param searchString
     */
    public searchObjects(searchString: string): Observable<any> {

        const url = `${this.apiUrl}/search`;
        return this.getInternal(url, {searchString: searchString});

    }

    /**
     * Retrieve configured result data for searchable object.
     * @param request
     */
    public getSearchableObjectData(request: SearchObjectRequestDto) {
        return this.http.post<DtoList>(
            this.apiUrl + "/getData",
            request);
    }

    public executeSearch(objectTypeAlias: string[], searchString: string, maxHits: number = 20): Observable<SearchResultDto> {

        let initialChar = searchString[0];
        const specialCharacter = this.specialCharacterMap.has(initialChar)
        let objectAlias: string[] = [];
        let objectTypeFound;

        if (specialCharacter) {
            const objectType = this.specialCharacterMap.get(initialChar);
            objectTypeFound = objectTypeAlias.find(alias => alias == objectType);
            searchString = searchString.substring(1);
        }


        if (objectTypeFound) {
            objectAlias.push(objectTypeFound);
        } else {
            objectAlias = objectTypeAlias;
        }

        const searchRequestDto = new SearchRequestDto();
        searchRequestDto.requestedObjectTypeAlias = objectAlias;
        searchRequestDto.maxHits = maxHits;
        searchRequestDto.searchString = searchString;

        return this.searchRequest(searchRequestDto)

    }


}