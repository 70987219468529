import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {TicketObjectDto} from "../../../../dto/ticket-object.dto";

@Component({
    selector: 'tm-ticket-sub-navigation-tasks',
    templateUrl: './tm-ticket-sub-navigation-tasks.component.html',
    styleUrls: ['./tm-ticket-sub-navigation-tasks.component.scss']
})
export class TmTicketSubNavigationTasksComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() mode: string;
    @Input() processMode: boolean = false;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() ticketObjects: TicketObjectDto[];
    @Output() changeMode: EventEmitter<string> = new EventEmitter<string>();


    constructor() {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    handleBackToTicket() {
        this.changeMode.emit('overview')
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
