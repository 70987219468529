import {Component} from '@angular/core';
import {
    FilterCriteria,
    MvsCoreService, MvsMessageService,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, Sorting,
    TabEntries, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {CustomerService} from "../../../service/api/customer.service";
import {MessageService} from "primeng/api";
import {CustomerFormTypeDto} from "../../../dto/customer-form-type.dto";


@Component({
    selector: 'mvs-cr-config',
    templateUrl: './cr-config.page.html',
})
export class CrConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Kundekonfiguration";

    activeNavItem: string;

    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    navigationItems: NavigationItem[] = [
        // {
        //     label: 'cr.CustomerRelatedPersonType',
        //     tooltip: 'Kunde zu Person Typen (verwandte Personen)',
        //     icon: 'fa fa-users', // Changed for related persons
        //     toggleable: false,
        //     default: false
        // },
        {
            label: 'cr.CustomerType',
            action: 'cr.CustomerType',
            tooltip: 'Kundentyp',
            icon: 'fa fa-user-tag', // User tagging for customer types
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerConsultingType',
            action: 'cr.CustomerConsultingType',
            tooltip: 'Beratungstyp',
            icon: 'fa fa-comments', // Changed for consulting type
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerCategoryType',
            action: 'cr.CustomerCategoryType',
            tooltip: 'Kundenkategorie',
            icon: 'fa fa-tag', // Category often represented by a tag
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerBrokerPoaType',
            action: 'cr.CustomerBrokerPoaType',
            tooltip: 'Maklervertragstyp',
            icon: 'fa fa-handshake', // Broker agreements could be a handshake
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerBrokerPoaTypeReason',
            action: 'cr.CustomerBrokerPoaTypeReason',
            tooltip: 'Maklervertragstyp - Grund',
            icon: 'fa fa-lightbulb', // Reason/hint often a lightbulb
            toggleable: false,
            default: false
        },

        {
            label: 'cr.CustomerNotificationType',
            action: 'cr.CustomerNotificationType',
            tooltip: 'Notifications',
            icon: 'fa fa-bell', // Notifications often represented by a bell
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerWelcome',
            action: 'cr.CustomerWelcome',
            tooltip: 'Welcome',
            icon: 'fa fa-smile', // Smile icon to indicate welcome
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerOnboarding',
            action: 'cr.CustomerOnboarding',
            tooltip: 'Onboarding',
            icon: 'fa fa-chalkboard-teacher', // Teacher/board for onboarding
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerInteraction',
            action: 'cr.CustomerInteraction',
            tooltip: 'Interaction',
            icon: 'fa fa-exchange-alt', // Interaction symbolized by exchanges
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerSignature',
            action: 'cr.CustomerSignature',
            tooltip: 'Signature',
            icon: 'fa fa-pen-fancy', // Pen icon for signatures
            toggleable: false,
            default: false
        },
        {
            label: 'cr.CustomerAlias',
            action: 'cr.CustomerAlias',
            tooltip: 'Alias',
            icon: 'fa fa-user-edit', // User edit for alias
            toggleable: false,
            default: false
        },
        {
            label: 'cr.AssessmentGroup',
            action: 'cr.AssessmentGroup',
            tooltip: 'Assessment',
            icon: 'fa fa-tasks', // Tasks/checklists for assessments
            toggleable: false,
            default: false
        },
        {
            label: 'cr.ProfileCheckType',
            action: 'cr.ProfileCheckType',
            tooltip: 'Profile Check',
            icon: 'fa fa-user-check', // Profile check with user checkmark
            toggleable: false,
            default: false
        },
        {
            label: 'name.check',
            action: 'name.check',
            tooltip: 'Namensaufbereitung',
            icon: 'fas fa-id-card',
            toggleable: false,
            default: false
        },
        {
            label: 'customer.form',
            action: 'customer.form',
            tooltip: 'Form Configuration',
            icon: 'fa-solid fa-clipboard-list',
            toggleable: false,
            default: false
        }
    ];

    customerRelatedPersonTypeWidget: WidgetData;
    customerConsultingTypeWidget: WidgetData;
    customerCategoryTypeWidget: WidgetData;
    customerBrokerPoaTypeWidget: WidgetData;
    customerBrokerPoaTypeReasonWidget: WidgetData;
    customerTypeWidget: WidgetData;
    customerNotificationTypeWidget: WidgetData;
    objectWidget: WidgetData;

    customerRegistrationConfigurationWidget: WidgetData;
    customerUserConfigurationWidget: WidgetData;
    customerWelcomeWidget: WidgetData;
    customerInfoPageWidget: WidgetData;
    customerOnboardingTypeWidget: WidgetData;
    customerOnboardingTypeContractTypeWidget: WidgetData;
    customerOnboardingTypeInfoStepWidget: WidgetData;
    customerInteractionTypeWidget: WidgetData;
    customerInteractionTypeInfoStepWidget: WidgetData;
    customerSignatureTypeWidget: WidgetData;
    customerExternalAliasWidget: WidgetData;

    profileCheckTypeWidget: WidgetData;
    profileCheckCompletenessRangeWidget: WidgetData;
    assessmentGroupWidget: WidgetData;
    assessmentGroupCheckWidget: WidgetData;
    assessmentOverwriteOptionWidget: WidgetData;
    assessmentResultRangeWidget: WidgetData;

    //customer forms
    widgetCustomerFormGroup: WidgetData;
    widgetCustomerFormType: WidgetData;
    widgetCustomerFormTypeField: WidgetData;
    selectedCustomerFormType: CustomerFormTypeDto;

    batchBusy: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    public getHeaderName() : string {

        let navigationItem = undefined;

        if (this.activeNavItem) {
            navigationItem = this.navigationItems.find(value => value.label == this.activeNavItem);
        }

        if (!navigationItem) {
            return "Kundenkofiguratin";
        } else {
            return navigationItem.tooltip;
        }
    }

    batchUpdateNames() {

        if (this.batchBusy) {
            return;
        }
        this.batchBusy= true;

        this.customerService.executeBatchUpdateNames().subscribe(value => {

            this.messageService.showSuccessMessage("Batch", "Kundennamen wurden erfolgreich aktualisiert!");
            this.batchBusy = false;
        }, error => {
            this.messageService.showErrorMessage("Batch", "Fehler aufgetreten!");
            this.batchBusy = false;
        });

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
            this.objectWidget = null;
        });
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }


    refreshWidgets() {
        this.refreshCustomerRelatedPersonTypeWidget();
        this.refreshCustomerConsultingTypeWidget();
        this.refreshCustomerCategoryTypeWidget();
        this.refreshCustomerBrokerPoaTypeWidget();
        this.refreshCustomerBrokerPoaTypeReasonWidget();
        this.refreshCustomerTypeWidget();
        this.refreshCustomerNotificationTypeWidget();


        this.refreshCustomerRegistrationConfigurationWidget(); //customer welcome
        this.refreshCustomerUserConfigurationWidget();
        this.refreshCustomerWelcomeWidget();
        this.refreshCustomerInfoPageWidget();

        this.refreshCustomerOnboardingTypeWidget(); //onboarding
        this.refreshCustomerOnboardingTypeContractTypeWidget();
        this.refreshCustomerOnboardingTypeInfoStepWidget();

        this.refreshCustomerInteractionTypeWidget(); //interaction
        this.refreshCustomerInteractionTypeInfoStepWidget();

        this.refreshCustomerSignatureTypeWidget(); //signature

        this.refreshCustomerExternalAliasWidget(); // alias

        this.refreshCustomerNotificationTypeWidget();

        this.refreshProfileWidgets();

        this.refreshAssessmentWidgets();

        this.refreshCustomerFormGroup();
        this.refreshCustomerFormType();
    }

    refreshProfileWidgets(): void {
        this.profileCheckTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.profile.check.type.widget',
            "Profilprüfungen",
            'cr.ProfileCheckType',
            'No Data',
            this.getObjectRequestList());


        this.profileCheckCompletenessRangeWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.profile.check.completeness.range.widget',
            "Bereiche",
            'cr.ProfileCheckCompletenessRange',
            'No Data',
            this.getObjectRequestList());

    }

    refreshAssessmentWidgets(): void {

        this.assessmentGroupWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.assessment.group.widget',
            "Gruppe",
            'cr.AssessmentGroup',
            'No Data',
            this.getObjectRequestList());

        this.assessmentGroupCheckWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.assessment.group.type.widget',
            "Prüfungen pro Gruppe",
            'cr.AssessmentGroupCheck',
            'No Data',
            this.getObjectRequestList());

        this.assessmentOverwriteOptionWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.assessment.overwrite.option',
            "Auswahlwerte für Informationen am Kunden, Haushalt und Personen",
            'cr.AssessmentOverwriteOption',
            'No Data',
            this.getObjectRequestList());

        this.assessmentResultRangeWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.config.assessment.result.range',
            "Ergebnisregeln",
            'cr.AssessmentResultRange',
            'No Data',
            this.getObjectRequestList());

    }



    refreshCustomerRelatedPersonTypeWidget() {
        this.customerRelatedPersonTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.related.person.type.widget',
            "Kunde zu Person Typen (verwandte Personen)",
            'cr.CustomerRelatedPersonType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerRelatedPersonTypeWidget.uiComponent = 'selectable';
    }

    refreshCustomerConsultingTypeWidget() {
        this.customerConsultingTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.consulting.type.widget',
            "Beratungstyp",
            'cr.CustomerConsultingType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerConsultingTypeWidget.uiComponent = 'selectable';
    }

    refreshCustomerCategoryTypeWidget() {
        this.customerCategoryTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.category.type.widget',
            "Maklervertragstyp",
            'cr.CustomerCategoryType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerCategoryTypeWidget.uiComponent = 'selectable';
    }

    refreshCustomerBrokerPoaTypeWidget() {
        this.customerBrokerPoaTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.broker.poa.type.widget',
            "Maklervertragstyp - Grund",
            'cr.CustomerBrokerPoaType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerBrokerPoaTypeWidget.uiComponent = 'selectable';
    }

    refreshCustomerBrokerPoaTypeReasonWidget() {
        this.customerBrokerPoaTypeReasonWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.broker.poa.type.reason.widget',
            "Kundentyp",
            'cr.CustomerBrokerPoaTypeReason',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerBrokerPoaTypeReasonWidget.uiComponent = 'selectable';
    }

    refreshCustomerTypeWidget() {
        this.customerTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.customer.type.widget',
            "Notifications 2",
            'cr.CustomerType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerTypeWidget.uiComponent = 'selectable';
    }

    refreshCustomerNotificationTypeWidget() {
        this.customerNotificationTypeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.config.notification.type.widget',
            "Customer Category Type",
            'cr.CustomerCategoryType',
            'No Data',
            this.getObjectRequestList(),
            WidgetDataParam.create('attributeToDisplay', 'name'));

        this.customerNotificationTypeWidget.uiComponent = 'selectable';
    }

    refreshObjectWidget(object: ObjectIdentifierData) {
        this.objectWidget = WidgetFactory.createWidgetEntityData(
            'cr.customer.config.object.view.' + object.objectType,
            '',
            object.objectType,
            object.objectId
        )
    }

    refreshCustomerRegistrationConfigurationWidget() {
        this.customerRegistrationConfigurationWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.registration.configuration',
            'cr.CustomerRegistrationConfiguration',
            'list',
            'list',
            'entity',
            'cr.CustomerRegistrationConfiguration',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerUserConfigurationWidget() {
        this.customerUserConfigurationWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.user.configuration',
            'cr.CustomerUserConfiguration',
            'list',
            'list',
            'entity',
            'cr.CustomerUserConfiguration',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerWelcomeWidget() {
        this.customerWelcomeWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.welcome',
            'cr.CustomerWelcome',
            'list',
            'list',
            'entity',
            'cr.CustomerWelcome',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerInfoPageWidget() {
        this.customerInfoPageWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.info.page',
            'cr.CustomerInfoPage',
            'list',
            'list',
            'entity',
            'cr.CustomerInfoPage',
            'No Data Available',
            this.getObjectRequestList()
        );
    }


    refreshCustomerOnboardingTypeWidget() {
        this.customerOnboardingTypeWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.onboarding.type',
            'cr.CustomerOnboardingType',
            'list',
            'list',
            'entity',
            'cr.CustomerOnboardingType',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerOnboardingTypeContractTypeWidget() {
        this.customerOnboardingTypeContractTypeWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.onboarding.type.contract.type',
            'cr.CustomerOnboardingTypeContractType',
            'list',
            'list',
            'entity',
            'cr.CustomerOnboardingTypeContractType',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerOnboardingTypeInfoStepWidget() {
        this.customerOnboardingTypeInfoStepWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.onboarding.type.info.step',
            'cr.CustomerOnboardingTypeInfoStep',
            'list',
            'list',
            'entity',
            'cr.CustomerOnboardingTypeInfoStep',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerInteractionTypeWidget() {
        this.customerInteractionTypeWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.interaction.type',
            'cr.CustomerInteractionType',
            'table',
            'list',
            'entity',
            'cr.CustomerInteractionType',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerInteractionTypeInfoStepWidget() {
        this.customerInteractionTypeInfoStepWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.interaction.type.info.step',
            'cr.CustomerInteractionTypeInfoStep',
            'list',
            'list',
            'entity',
            'cr.CustomerInteractionTypeInfoStep',
            'No Data Available',
            this.getObjectRequestList()
        );
    }


    refreshCustomerSignatureTypeWidget() {
        this.customerSignatureTypeWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.signature.type',
            'cr.CustomerSignatureType',
            'list',
            'list',
            'entity',
            'cr.CustomerSignatureType',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerExternalAliasWidget() {
        this.customerExternalAliasWidget = WidgetFactory.createWidgetList(
            'cr.customer.config.cr.customer.external.alias',
            'cr.CustomerExternalAlias',
            'list',
            'list',
            'entity',
            'cr.CustomerExternalAlias',
            'No Data Available',
            this.getObjectRequestList()
        );
    }

    refreshCustomerFormGroup() {
        this.widgetCustomerFormGroup = WidgetFactory.createWidgetList(
            "cr.form.config.form.group",
            "FormularGruppen",
            "list",
            "list",
            "entity",
            "cr.CustomerFormGroup",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
        );
    }

    refreshCustomerFormType() {
        this.widgetCustomerFormType = WidgetFactory.createWidgetList(
            "cr.form.config.form.type",
            "Formulartypen",
            "list",
            "list",
            "entity",
            "cr.CustomerFormType",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
        );
    }

    onFormTypeObjectSelect(event: ObjectIdentifierData) {
        this.selectedCustomerFormType = event.data;
        this.refreshFormFields();
    }

    refreshFormFields() {

        this.widgetCustomerFormTypeField = WidgetFactory.createWidgetList(
            "cr.form.config.form.type.field",
            "Zugewiesene Felder",
            "list",
            "list",
            "entity",
            "cr.CustomerFormTypeField",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, this.selectedCustomerFormType.id)],
                [new Sorting("id", false)]
            )
        );

    }


    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], [])
    }


    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


}


