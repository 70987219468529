import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectBaseComponent} from '@kvers/alpha-core-common';


@Component({
    selector: 'ticket-type-object-component',
    templateUrl: './ticket-type-object.component.html',
    styleUrls: ['./ticket-type-object.component.scss'],
})
export class TicketTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * On Object Change. When object is retrieved
     */
    onObjectChanged() {
        // setting tab title
        const title = `${this.dto.name} - Konfiguration`;

        this.setPageTitle(title);
    }



}
