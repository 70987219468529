import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cd-contact-type-page',
  templateUrl: './contact-type.page.html',
  styleUrls: ['./contact-type.page.scss']
})
export class ContactTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  defaultLabel: string = "Pflege der Kontaktarten";

  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cd.ContactType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'processRecursion'  : true,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Kontatktypen",
          'dummyParentDescription' : "Hier können verschiedene Kontakttypen angelegt werden.",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Kontakt anlegen"

        },
        {
          'id'                : 2,
          'objectAlias'       : 'cd.ContactTypeField',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Felder zu Kontakttypen",
          'dummyParentDescription' : "Felder anlegen.",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Feld anlegen"

        },
        {
          'id'                : 3,
          'objectAlias'       : 'cd.ContactTypeObjectType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Objekt Typen zu Kontakttypen",
          'dummyParentDescription' : "Verfügbar Kontakttypen für Objekttypen anlegen. ",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neue Zuordnung anlegen"

        },
      ]

    };

    super.ngOnInit();


  }
}
