<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-4 flex flex-column gap-2">

            <div class="mvs-widget">
                <mvs-pc-object-calls
                        [objectIdentifier]="objectIdentifier"
                        [aliasSuffix]="viewType"
                ></mvs-pc-object-calls>
            </div>

            <div class="mvs-widget h-full">
                <mvs-dm-object-documents
                        *ngIf="objectIdentifier"
                        [objectIdentifier]="objectIdentifier"
                        [viewOptions]="0"
                        [widthSize]="'500px'"
                ></mvs-dm-object-documents>
            </div>


        </div>

        <div class="col-8">
            <div class="flex flex-column gap-3">
                <tm-object-tickets
                        [objectIdentifier]="objectIdentifier"
                        ticketDrawerLocation="left"
                        [showActive]="false"
                >
                </tm-object-tickets>
            </div>
        </div>

        <div class="col-12">
            <wf-object-processes
                    [objectIdentifier]="objectIdentifier"
                    [showActive]="false"
            >
            </wf-object-processes>
        </div>

        </div>

</ng-container>