import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavigationItem, ObjectBaseComponent} from "@kvers/alpha-core-common";
import {AlphaBatchEntityBaseComponent} from "../alpha-batch-entity-base.component";


@Component({
    selector: 'alpha-batch-entity-side-component',
    templateUrl: 'alpha-batch-entity-side.component.html',
    styleUrls: ['alpha-batch-entity-side.component.scss'],
})
export class AlphaBatchEntitySideComponent extends AlphaBatchEntityBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'alphaIntegrationInfo',
            action: 'alphaIntegrationInfo',
            tooltip: 'Alpha Integration Info',
            icon: 'fa fa-info-circle',
            toggleable: false,
            default: true
        },
        {
            label: 'alphaIntegration',
            action: 'alphaIntegration',
            tooltip: 'Alpha Integration',
            icon: 'fa-solid fa-layer-group',
            toggleable: false,
            default: false
        },
    ];


    ngOnInit() {
        super.ngOnInit();
    }



}
