import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CmCustomerAssessmentDto} from "../../dto/cm-customer-assessment.dto";

@Injectable({
  providedIn: 'root'
})
export class AssessmentGroupService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/assessmentGroups')
  }


  /**
   * Assess customer.
   * @param customerId
   */
  public assessCustomer(customerId: number): Observable<CmCustomerAssessmentDto> {
    const url = `${this.apiUrl}/assessCustomer/${customerId}`;

    return this.http.get<CmCustomerAssessmentDto>(url);
  }

}
