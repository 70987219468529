import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {CallService} from "../../../../service/api/call.service";
import {CallResultEnum} from "../../../../enum/call-result-enum.enum";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'pc-statistics.component.html',
})
export class PcStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    // busyCalls: number = 0;
    // no_answerCalls: number = 0;
    // no_timeCalls: number = 0;
    // successCalls: number = 0;


    callHistoryWidget: WidgetData;
    callTypeWidget: WidgetData;
    callAgentWidget: WidgetData;
    recentCallsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        protected callService: CallService,
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'Call Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getTotalCalls();
        this.refreshCallHistoryWidget();
        this.refreshCallTypeWidget();
        this.refreshCallAgentWidget();
        this.refreshRecentCallsWidget();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/pc/config']);
    }

    getTotalCalls() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['callResult'],
            [new ObjectRequestListAttribute('callResult', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.callService.groupBy(objectRequestList).subscribe(res => {

            // for (let entry of res.entries) {
            //     if (entry['callResult'] == CallResultEnum.busy) {
            //         this.busyCalls = entry['callResult_count'];
            //     } else if (entry['callResult'] == CallResultEnum.no_answer) {
            //         this.no_answerCalls = entry['callResult_count'];
            //     } else if (entry['callResult'] == CallResultEnum.no_time) {
            //         this.no_timeCalls = entry['callResult_count'];
            //     } else if (entry['callResult'] == CallResultEnum.success) {
            //         this.successCalls = entry['callResult_count'];
            //     }
            // }

            this.overviewDto = [
                {label: 'Busy', value: 0 },
                {label: 'No answer', value: 0 },
                {label: 'No time', value: 0 },
                {label: 'Success', value: 0 }
            ];

            for (let entry of res.entries) {
                if (entry['callResult'] === CallResultEnum.busy) {
                    // this.overviewDto.push({label: 'Busy', value: entry['callResult_count'] },)
                    this.overviewDto.find(item => item.label === 'Busy').value = entry['callResult_count'];
                } else if (entry['callResult'] === CallResultEnum.no_answer) {
                    this.overviewDto.find(item => item.label === 'No answer').value = entry['callResult_count'];
                } else if (entry['callResult'] === CallResultEnum.no_time) {
                    this.overviewDto.find(item => item.label === 'No time').value = entry['callResult_count'];
                } else if (entry['callResult'] === CallResultEnum.success) {
                    this.overviewDto.find(item => item.label === 'Success').value = entry['callResult_count'];
                }
            }


        });
    }

    refreshCallHistoryWidget() {
        this.callHistoryWidget = new WidgetData();
        this.callHistoryWidget.idAlias = "pc.overview.call.history.widget";
        this.callHistoryWidget.name = "Registrierungshistorie";

        this.callHistoryWidget.uiComponent = "history.chart";
        this.callHistoryWidget.dataProvider = "list";
        this.callHistoryWidget.dataSource = "entity.groupBy";
        this.callHistoryWidget.dataProviderObject = "pc.Call";
        this.callHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["callType", "createdDate"],
            [new ObjectRequestListAttribute("callType", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.callHistoryWidget.setParamValue("fieldCategory", "callType");
        this.callHistoryWidget.setParamValue("fieldCategoryCount", "callType_count");
        this.callHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.callHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.callHistoryWidget.setParamValue("selectionMode", "single");
        this.callHistoryWidget.setParamValue("size", "S");
    }

    refreshCallTypeWidget() {
        this.callTypeWidget = WidgetFactory.createWidgetGroupBy(
            'pc.overview.call.type.widget',
            'Call Type',
            'category',
            'entity.groupBy',
            'pc.Call',
            'callType',
            'callType_count',
            this.filterCriteria,
            'callType',
            'Anzahl');
    }

    refreshCallAgentWidget() {
        this.callAgentWidget = WidgetFactory.createWidgetGroupBy(
            'pc.overview.call.agents.widget',
            'Call Agents',
            'category',
            'entity.groupBy',
            'pc.Call',
            'agent',
            'agent_count',
            this.filterCriteria,
            'agent',
            'Anzahl');
    }

    refreshRecentCallsWidget() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentCallsWidget = WidgetFactory.createWidgetTableEntity(
            'pc.overview.recently.calls',
            'Recent Calls',
            'pc.Call',
            'No Registrations',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
