// date-utils.ts
export function getStartOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getStartOfWeek(date: Date): Date {
    date.setHours(0, 0, 0, 0); // Set time to the beginning of the day
    const day = date.getDay(); // 0 is Sunday, 1 is Monday, etc.
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(date.setDate(diff));
}

export function getStartOfToday(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0); // Set time to the beginning of the day
    return date;
}

export function getStartOfYear(date: Date): Date {
    return new Date(date.getFullYear(), 0, 1); // January 1st of the current year
}
