<div class="grid">
    <div class="col-12">
        <p-dropdown
                [options]="appointmentTypeList"
                [(ngModel)]="selectedAppointmentType"
                optionLabel="name"
                placeholder="Select Appointment Type"
                (onChange)="handleSelect()"
                [disabled]="busy"
        />
    </div>

    <div class="col-12">

        <p-multiSelect
                *ngIf="agents?.length"
                [options]="agents"
                [(ngModel)]="selectedAgents"
                optionLabel="name"
                placeholder="Select Agents" />


        <p-dropdown
                *ngIf="appointmentTypeAgentList?.length"
                [options]="appointmentTypeAgentList"
                [(ngModel)]="selectedAppointmentTypeAgent"
                optionLabel="name"
                placeholder="Select Agent"
                [disabled]="busy"
        />
    </div>

    <p-chips [(ngModel)]="agents" field="name" />

    <div class="col-12">
        <as-free-slot *ngIf="agents" [agents]="agents"></as-free-slot>
    </div>
</div>