
<ng-container *ngIf="objectLoaded">
    <div class="grid">
        <div class="col-4">
            <mvs-widget *ngIf="objectWidget" [widgetData]="objectWidget"></mvs-widget>

            <p-button (onClick)="showThreads= !showThreads;" label="Show/ Hide Server Threads"></p-button>

            {{processes}}

        </div>
        <div class="col-8">
            <mvs-widget *ngIf="runObjectsWidget" [widgetData]="runObjectsWidget" (onObjectSelect)="onObjectSelected($event)"></mvs-widget>
        </div>
    </div>

    <div class="grid" *ngIf="showThreads">
        <div class="col-12">
            <mvs-process-threads></mvs-process-threads>
        </div>
    </div>

    <div class="grid" *ngIf="selectedObjectTypeId">
        <div class="col-12">
            <p-chip [label]="selectedObjectTypeName" (onRemove)="clearSelection();"></p-chip>
        </div>
        <div class="col-6" *ngIf="objectTokensWidget">
            <mvs-widget *ngIf="objectTokensWidget" [widgetData]="objectTokensWidget"></mvs-widget>
        </div>
        <div class="col-6" *ngIf="objectReferencesWidget">
            <mvs-widget *ngIf="objectReferencesWidget" [widgetData]="objectReferencesWidget"></mvs-widget>
        </div>
    </div>



</ng-container>
