import {DtoDetail} from "@kvers/alpha-core-common";
import { EuUserDto } from "./eu-user.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {ContractTypeDto} from "../../cm/dto/contract-type.dto";
import {PartnerDto} from "../../cm/dto/partner.dto";
import {EuUserAddedInsuranceDocumentDto} from "./eu-user-added-insurance-document.dto";

export enum EuEuUserAddedInsuranceStatusInternal{

    pending,
    in_processing,
    processed,
    not_relevant

}

export class EuUserAddedInsuranceDto extends DtoDetail{


    public userDto? : EuUserDto;
    public userDtoId : number;
    public userDtoName : string;
    public entityStatus: EntityStatusEnum;
    public contractTypeDto? : ContractTypeDto;
    public contractTypeDtoId : number;
    public contractTypeDtoName : string;
    public partnerDto? : PartnerDto;
    public partnerDtoId : number;
    public partnerDtoName : string;
    public insuranceId: string;
    public satisfied: boolean;
    public description: string;
    public documentsDtoId : number;
    public documentsDtoName : string;

    public internalStatus: EuEuUserAddedInsuranceStatusInternal;

    documents?: EuUserAddedInsuranceDocumentDto[];

}