import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {DmDocumentAssignmentDto} from "../../dto/dm-document-assignment.dto";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DmDocumentAssignmentService extends MvsCrudService{
  private _subjectDocumentUpload = new BehaviorSubject('');
  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/dm/dmDocumentAssignments');
  }
  get subjectDocumentUpload(): BehaviorSubject<any> {
    return this._subjectDocumentUpload;
  }

  subjectUpdate(objectType:string) {
    this._subjectDocumentUpload.next(objectType);
  }

  /**
   * Retrieve object document assignments.
   * @param objectTypeId
   * @param objectId
   */
  public listActiveObjectDocumentAssignments(objectTypeId: number, objectId: number): Observable<DtoList<DmDocumentAssignmentDto>> {
    const filterCriteria = this.retrieveFilterCriteriaForActiveAssignments(objectTypeId, objectId);

    const request: ObjectRequestList = ObjectRequestList.createBasic(false, filterCriteria, [new Sorting("createdDate", false)]);

    return this.list(request);

  }

  private retrieveFilterCriteriaForActiveAssignments(objectTypeId: number, objectId: number) {
    const filterCriteria = FilterCriteria.createAnd(
        FilterCriteria.create("objectType", FilterCriteria.cOperatorEqual, objectTypeId),
        FilterCriteria.create("objectId", FilterCriteria.cOperatorEqual, objectId),
        FilterCriteria.create("entityStatus", FilterCriteria.cOperatorEqual, EntityStatusEnum.active),
    );
    return filterCriteria;
  }

  public listActiveObjectDocumentAssignmentsCount(objectTypeId: number, objectId: number): Observable<number> {

    const filterCriteria = this.retrieveFilterCriteriaForActiveAssignments(objectTypeId, objectId);

    const request: ObjectRequestListGroupBy = ObjectRequestListGroupBy.create(
        false,
        filterCriteria,
        [],
        ["objectType", "objectId"],
        [new ObjectRequestListAttribute("objectType", "count", DtoListAttributeRequestAggregateEnum.count)]);

    return this.groupBy(request).pipe(
        map(result => {
          if (!result || !result.entries || result.entries.length == 0 || !result.entries[0] || !result.entries[0]["objectType_count"]) {
            return 0;
          } else {
            return result.entries[0]["objectType_count"];
          }
        })
    );

  }


  public upload(file: File, documentTypeId: number, objectTypeId: number, objectId: number): Observable<HttpEvent<any>> {

    const url = `${this.apiUrl}/upload/${documentTypeId}/${objectTypeId}/${objectId}`;

    return this.uploadInternal(file, url);

  }

  public uploadViaAlias(file: File, documentTypeId: number|any, objectAlias: string, objectId: number): Observable<HttpEvent<any>> {

    const url = `${this.apiUrl}/uploadViaAlias/${documentTypeId}/${objectId}/${objectAlias}`;

    return this.uploadInternal(file, url);

  }


  protected uploadInternal(file: File, url: string) {
    const formData: FormData = new FormData();

    formData.append("file", file);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }



}
