<ng-container *ngIf="initialized">
    <div class="card">
        <p-steps
                [model]="stepItems"
                [readonly]="false"
                [activeIndex]="activeIndex"
                (activeIndexChange)="onActiveIndexChange($event)"></p-steps>


        <ng-container *ngIf="activeIndex == 0 && !busy">

                        <pm-person-search-component
                                (onPersonSelect)="handlePersonSelect($event)"
                                (onContinueWithoutSelection)="handleContinueWithoutSelection($event)"
                                [targetObjectType]="'cr.Customer'"
                                [aliasSuffix]="'customer'"
                                [createMode]="1"
                        ></pm-person-search-component>

<!--            <div class="ml-5">-->
<!--                <div class="ml-5">-->
<!--                    <h3>Choose Person</h3>-->

<!--                    <search-flexible-->
<!--                            [defaultDto]="flexibleSearchResult"-->
<!--                            [objectType]="'pm.Person'"-->
<!--                            (onObjectSelect)="handlePersonObject($event)"-->
<!--                    ></search-flexible>-->

<!--                    <div class="flex justify-content-end align-items-center mt-4 px-3">-->
<!--                        <label class="text-base text-500 mr-3">Person not found? Create new one</label>-->
<!--                        <button pButton type="button" class="mx-2" label="Create New"-->
<!--                                (click)="onCreateNewPersonForm()"></button>-->
<!--                        <button *ngIf="selectedPersonId" pButton type="button" label="Choose"-->
<!--                                (click)="onCreateNewCustomerRelatedPerson()"></button>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

        </ng-container>

        <ng-container *ngIf="activeIndex == 1 && !busy">
        </ng-container>


        <ng-container *ngIf="activeIndex == 2 && !busy">

        </ng-container>

        <p-button *ngIf="personId" (click)="revertChanges($event)" icon="fa-solid fa-clock-rotate-left"></p-button>

    </div>

</ng-container>


<!--
            <h4 *ngIf="loadFormFields">Step 1: Person Recognition</h4>

<p-skeleton *ngIf="!loadFormFields" width="w-full" height="400px"></p-skeleton>

<mvs-crud-object
        objectType="pm.Person"
        [objectId]="personId"
        (onChangedObject)="onPersonSave($event)"
        (lifecycleEvents)="handleLifeCycle($event)"
></mvs-crud-object>
-->
