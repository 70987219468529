
<ng-container *ngIf="initialized">

    <p-dropdown *ngIf="listIncomingCallTypeDtoList" [options]="listIncomingCallTypeDtoList.entries" [(ngModel)]="selectedCallType"
                placeholder="Typ auswählen" optionLabel="name" scrollHeight="385px"></p-dropdown>
    <input pInputText class="mx-2" [(ngModel)]="phoneNumber" placeholder="Eingehende Nummer" />

    <div class="surface-card shadow-1 border-round flex align-items-center justify-content-start p-2 cursor-pointer">

        <p-button label="Eingehenden Anruf starten" icon="fa-light fa-phone-arrow-down-left"  (click)="handleStartCall()"></p-button>

    </div>

</ng-container>

