<p-button icon="fa-solid fa-circle-play" styleClass="p-button-warning" badge="8" badgeClass="p-badge-danger"
          (click)="opWfIndicator.toggle($event)"></p-button>

<p-overlayPanel #opWfIndicator>
    <ng-template pTemplate>
        <h5>Verfügbare Prozesse</h5>

        <div class="flex">
            <div class="flex m-2">
                <p-cascadeSelect
                        *ngIf="processTypesTree"
                        [(ngModel)]="createProcessTypeSelected"
                        [options]="processTypesTree"
                        optionLabel="label"
                        optionGroupLabel="label"
                        [optionGroupChildren]="['children']"
                        [style]="{'minWidth': '14rem'}"
                ></p-cascadeSelect>
            </div>

            <div class="flex m-2">
                <p-button icon="fa-solid fa-play" label="Prozess starten" (click)="handleStartProcess()"
                          [disabled]="!createProcessTypeSelected"></p-button>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>