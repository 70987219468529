import { DtoDetail } from "@kvers/alpha-core-common";
import { TeVariableTypeEnum } from "../enum/te-variable-type.enum";

   export class TeTemplateVariableDto extends DtoDetail {

    public templateDtoId : number;
    public templateDtoName : string;
    public templateDtoImage : string;
    public templateDtoColor : string;
    public name : string;
    public type : TeVariableTypeEnum;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectTypeDtoImage : string;
    public objectTypeDtoColor : string;
    public multiple : Boolean;
    public optional : Boolean;
   }