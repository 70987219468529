import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {PmConfigPage} from "./page/overview/pm-config/pm-config.page";
import {PersonSearchComponent} from "./component/person-search/person-search.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {
  PmCreatePersonWfProcessStepActivityComponent
} from "./component/wf/pm-create-person-wf-process-step-activity/pm-create-person-wf-process-step-activity.component";
import {PmPersonComponent} from "./component/pm-person-component/pm-person.component";
import {PmHouseholdComponent} from "./component/pm-household/pm-household.component";
import {HouseholdConfigPage} from "./page/overview/household-config/household-config.page";
import {PmOverviewPage} from "./page/pm-overview-page/pm-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {PcKpiComponent} from "../pc/page/pc-overview-page/components/kpiComponent/pc-kpi.component";
import {
  PcStatisticsComponent
} from "../pc/page/pc-overview-page/components/statisticsComponent/pc-statistics.component";
import {PmKpiComponent} from "./page/pm-overview-page/components/kpiComponent/pm-kpi.component";
import {PmStatisticsComponent} from "./page/pm-overview-page/components/statisticsComponent/pm-statistics.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
  declarations: [
    PmConfigPage,
    PersonSearchComponent,
    PmCreatePersonWfProcessStepActivityComponent,
    PmPersonComponent,
    PmHouseholdComponent,
    HouseholdConfigPage,
    PmOverviewPage,
    PmKpiComponent,
    PmStatisticsComponent
  ],
  exports:[
    PmConfigPage,
    PersonSearchComponent,
    PmCreatePersonWfProcessStepActivityComponent,
    PmPersonComponent,
    PmHouseholdComponent,
    HouseholdConfigPage,
  ],
    imports: [
        CoreModule,
        ProgressSpinnerModule,
      UiModule,

    ]
})
export class PmModule  implements DynamicModule {

  alias = 'pm';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return PmKpiComponent;
  }

  getStatisticsComponent() {
    return PmStatisticsComponent;
  }

}


