

<mvs-widget
        [widgetData]="tableReportWidgetData"
        [importObjectContext]="importObjectContextDto"
></mvs-widget>


<h4>Test Notification</h4>
<div class="card" style="max-width:400px">
    <div class="p-fluid grid">
        <div class="field col-12">
            <input type="text" pInputText placeholder="Betreff" [(ngModel)]="testMessageSubject">
        </div>
        <div class="field col-12">
            <input type="text" pInputText placeholder="Text" [(ngModel)]="testMessageBody">
        </div>
        <div class="field col-12">
            <input type="text" pInputText placeholder="Token Endgerät" [(ngModel)]="testMessageToken">
        </div>

        <div class="field col-12">
            <p-button label="Nachricht senden" icon="pi pi-bolt" (onClick)="onSendMessage($event)"></p-button>

        </div>
    </div>
</div>
