
<h1>{{reportName}}</h1>


<ng-container *ngIf="initialized">

    <p-panel header="Selektion" *ngIf="selectionFormGroup" [formGroup]="selectionFormGroup">

        <div class="flex" *ngFor="let selectionField of selectionForm.fields">

            <ng-container *ngIf="selectionField.multipleValues">
                <!-- allow entry of multiple values -->
                <label>{{selectionField.formField.uiLabel}}</label>
                <p-multiSelect
                        [options]="selectionField.formField.valueList.entries"
                        [defaultLabel]="selectionField.formField.uiLabel"
                        optionLabel="label"
                        display="chip"
                ></p-multiSelect>

            </ng-container>

            <ng-container *ngIf="!selectionField.multipleValues">
                <mvs-form-field [formField]="selectionField.formField" [formGroup]="selectionFormGroup"></mvs-form-field>
            </ng-container>

        </div>

    </p-panel>

    <p-button (click)="onExecuteReport($event)" label="Run Report"></p-button>

</ng-container>




<p-panel header="Ausgabe">

<!--
    <mvs-widget [widgetData]="reportWidgetData"></mvs-widget>
-->
</p-panel>

