<ng-container *ngIf="initialized">
    <div class="grid">


        <div class="col-12">
            <p-panel header="Table Widget with Paging">
                <mvs-widget
                        [widgetData]="tableWidgetData"
                        (onObjectSelect)="onCustomerSelect($event)"

                ></mvs-widget>
            </p-panel>
        </div>

        <div class="col-4" *ngIf="selectedObject">

            <!-- Widgets when object is selected -->
            <mvs-widget
                    [widgetData]="customerWidgetData"
            ></mvs-widget>

<!--            <mvs-widget
                    [widgetData]="contactsWidgetData"
            ></mvs-widget>

            <mvs-widget
                    [widgetData]="personWidgetData"
            ></mvs-widget> -->

        </div>

        <div class="col-8">
        </div>

    </div>
</ng-container>
