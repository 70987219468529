import {Directive, ElementRef, Input} from '@angular/core';
import {ImResultSeverityEnum} from "../enum/im-result-severity.enum";

@Directive({
    selector: '[imSeverityIcon]'
})
export class ImSeverityIconDirective {

    @Input() severity: ImResultSeverityEnum;

    constructor(private el: ElementRef) {

        let icon = undefined;
        let color = undefined;

        switch (this.severity) {
            case ImResultSeverityEnum.info:
                icon = "fa-regular fa-circle-info";
                break;
            case ImResultSeverityEnum.abort:
                icon = "fa-regular fa-circle-radiation";
                color = "red";
                break;
            case ImResultSeverityEnum.error:
                icon = "fa-regular fa-square-exclamation";
                color = "red";
                break;
            case ImResultSeverityEnum.none:
                break;
            case ImResultSeverityEnum.warning:
                icon = "fa-regular fa-triangle-exclamation";
                color = "yellow";
                break
            default:

        }

        if (icon) {
            this.el.nativeElement.class = icon;
        }

        if (color) {
            this.el.nativeElement.style.color = color;
        }

    }
}