import {DtoDetail} from "@kvers/alpha-core-common";
import {TeArtefactLocationEnum} from "../enum/te-artefact-location.enum";
import {TeArtefactDto} from "./te-artefact.dto";
import {TeTemplateDto} from "./te-template.dto";

export class TeTeTemplateArtefactDto extends DtoDetail {

    public templateDtoId: number;
    public templateDtoName: string;
    public templateDtoImage: string;
    public templateDtoColor: string;
    public artefactDtoId: number;
    public artefactDtoName: string;
    public artefactDtoImage: string;
    public artefactDtoColor: string;
    public locationEnum: TeArtefactLocationEnum;
    public priority: number;

    public artefactDto?: TeArtefactDto;
    public templateDto?: TeTemplateDto
}