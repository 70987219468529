
<mvs-crud-page
        [objectStructureRequest]="objectBrowserRequest"
        mainObjectType="rp.Report"
        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"
        (onChangedObject)="onChangedObject($event)"
        (onMainSelectedObjectChange)="onMainSelectedObjectChange($event)"
        [showActionBar]="false"
        [mainObjectSideNavigationNeverVisible]="true"
>
</mvs-crud-page>
