<ng-container *ngIf="initialized">
    <p-button
            icon="fa-kit fa-regular-ticket-simple-circle-plus"
            [rounded]="true"
            [raised]="true"
            [text]="true"
            (click)="handleCreateTicket($event, op)"/>



    <p-overlayPanel #op>
        <div class="flex gap-3 align-items-center">
            <h4>Select Ticket Type</h4>
            <p-dropdown
                    [options]="ticketTypes"
                    [(ngModel)]="ticketTypeId"
                    [filter]="true"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Ticket Type"/>

            <p-button label="Create" [disabled]="!ticketTypeId" (click)="onCreateTicket($event)"></p-button>

        </div>
    </p-overlayPanel>

</ng-container>

