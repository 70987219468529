import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MessageService} from "primeng/api";
import {DtoDetail, MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {HouseholdPersonService} from "../../service/api/household-person.service";
import {HouseholdPersonDto} from "../../dto/household-person.dto";
import {PmPersonRelationPersonWidgetStyle} from "./uiStyle/pm-person-relation-person-widget-style";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {HouseholdService} from "../../service/api/household.service";

@Component({
    selector: 'pm-household-persons',
    templateUrl: './pm-household.component.html',
    styleUrls: ['./pm-household.component.scss']
})
export class PmHouseholdComponent
    implements OnInit, OnDestroy, OnChanges {


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    value: boolean;
    @Input() customerDto: CustomerDto;
    @Output() selectedRelatedPersonsChanged = new EventEmitter<PmPersonRelationPersonWidgetStyle>();
    @Output() householdPersonsLoaded = new EventEmitter<HouseholdPersonDto[]>();
    householdPersons: HouseholdPersonDto[];
    uiHouseholdPersons: HouseholdPersonDto[];
    broadcastSubscription: Subscription;
    households: HouseholdPersonDto[];
    selectedPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;
    householdPersonsCount: number;
    selectedPersonId: number;

    constructor(public messageService: MessageService,
                protected navigationService: MvsObjectNavigationService,
                protected householdService: HouseholdService,
                protected householdPersonService: HouseholdPersonService,
                protected objectService: MvsUiObjectService,
                protected coreService: MvsCoreService,
                protected configService: MvsConfigService) {}

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshInternal();

    }

    refreshInternal() {

        const request = new ObjectRequestList(
            false,
            [FilterCriteria.create('person', 'EQ', this.customerDto.personDtoId)],
            []
        );

        request.objectRequestComplex = this.getComplexRequest();

        this.householdPersonService.list(request).subscribe(res => {
            this.households = res.entries;
            this.prepareHouseholdPersons();
            this.initialized = true;
        });

    }

    getComplexRequest(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            true,
            true,
            ObjectRequestComplexNode.createSimpleAttributeNode("household").addNodes(
                ObjectRequestComplexNode.createRelationNode('householdPerson', ObjectRequestRelation.createJoin('pm.HouseholdPerson#household'))
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person")
                        .addNodes(
                            ObjectRequestComplexNode.createRelationNode('customerDto', ObjectRequestRelation.createJoin('+crCustomer')),
                            ObjectRequestComplexNode.createRelationNode('relatedPersons', ObjectRequestRelation.createJoin('pm.PersonRelationPerson#personB')),

                        )
                    )
            )
        );
    }

    prepareHouseholdPersons() {
        this.householdPersons = [];

        if (this.households && this.households.length) {
            for (let household of this.households) {
                for (let person of household.householdDto['householdPerson']) {
                    if (person.personDtoId == this.customerDto.personDtoId) {
                        // skip the person from household which is already the main customer in input parameter
                        person['__display'] = false;
                        // continue;
                    }
                    person['label'] = person.personDtoName;
                    person.command = () => this.handleSelectRelatedPerson(person);
                    person.style = {'background-color': person.__color};
                    this.householdPersons.push(person);
                }
            }
        }

        this.householdPersonsLoaded.emit(this.householdPersons);
        this.selectedPersonWidgetStyle = new PmPersonRelationPersonWidgetStyle(this.configService, this.customerDto, []);
        this.createUi();
    }

    createUi() {
        this.uiHouseholdPersons = this.householdPersons.filter(item => item['__display'] != false);
    }

    handleHouseholdClick() {
        for (let item of this.uiHouseholdPersons) {
            this.onSelectRelatedPerson(item);
        }
        this.selectedRelatedPersonsChanged.emit(this.selectedPersonWidgetStyle);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["customerDto"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

    handleSelectRelatedPerson(person: HouseholdPersonDto) {
        this.onSelectRelatedPerson(person);
        this.selectedRelatedPersonsChanged.emit(this.selectedPersonWidgetStyle);
    }

    onSelectRelatedPerson(person: HouseholdPersonDto) {
        person['__selected'] = !person['__selected'];

        const customerId: number = person['personDto']?.['customerDto']?.id;

        if (!customerId) {
            return;
        }

        if (person['__selected'] && customerId) {
            this.selectedPersonWidgetStyle.addRelatedPerson(person, customerId);
        } else {
            this.selectedPersonWidgetStyle.removeRelatedPerson(person);
        }

        person['style'] = this.selectedPersonWidgetStyle.getStyleObjectForAvatar(person);
    }

    removeFromHousehold(personId: number) {
        this.selectedPersonId = personId;
        this.createNewHousehold();
    }

    createNewHousehold() {
        const dto = new DtoDetail();
        this.householdService.create(dto).subscribe(res => {
            const newlyCreatedHouseholdId = res.id;
            this.assignHousehold(newlyCreatedHouseholdId);
        });
    }

    assignHousehold(newlyCreatedHouseholdId: number) {
        const dto = new HouseholdPersonDto();
        dto.householdDtoId = newlyCreatedHouseholdId;
        dto.personDtoId = this.selectedPersonId;
        dto.endDate = new Date();

        this.householdPersonService.create(dto).subscribe(res => {
           console.log('Household person is removed and new is created');
           this.selectedPersonId = null;
        });
    }
}
