import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, MvsCoreService, ObjectRequestList, PagingDto, FilterCriteria, WidgetData, Sorting, EntityStatusEnum,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {ContractService} from "../../service/api/contract.service";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ContractDto} from "../../dto/contract.dto";
import {DatesHelper} from "../../../core/helper/dates-helper";
import {MenuItem} from "primeng/api";


@Component({
    selector: 'mvs-example',
    templateUrl: './contract-overview.page.html',
    styleUrls: ['contract-overview.page.scss']
})
export class ContractOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Contract Overview";

    ngOnInit(): void {
        super.ngOnInit();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.initialized = true;

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
