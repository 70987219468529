import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MsBookingTypeService} from "../../../service/api/ms-booking-type.service";
import {MsBusinessStaffMemberListDto} from "../../../service/dto/ms-business-staff-member-list.dto";


@Component({
    selector: 'as-ms-business-staff-members',
    templateUrl: './ms-business-staff-members.component.html',
})
export class MsBusinessStaffMembersComponent implements OnInit, OnChanges, OnDestroy {

    @Input() msBookingTypeId: number;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    staffMembers: MsBusinessStaffMemberListDto;

    constructor(protected msBookingTypeService: MsBookingTypeService) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.msBookingTypeService.getBusinessStaffMembers(this.msBookingTypeId).subscribe(value => {
            this.staffMembers = value;
            this.initialized = true;
        });

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
