import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {TeVariableProviderService} from "./interfaces/te-variable-provider.service";
import {TeContentProviderVariableDto} from "./dto/te.content-provider-variable.dto";
import {Observable} from "rxjs";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TeTemplateVariableService extends MvsCrudService implements TeVariableProviderService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/te/teTemplateVariables');
  }

  /**
   * Return variables.
   * @param contentProviderId
   */
  listVariables(contentProviderId: number): Observable<TeContentProviderVariableDto[]> {
    return this.http.get<TeContentProviderVariableDto[]>(`${this.apiUrl}/variables/${contentProviderId}`);
  }

  expandSystemVariables(alias: string): Observable<MetaDataEntityDto> {
    return this.http.get<MetaDataEntityDto>(`${this.apiUrl}/variables/expand/${alias}`);
  }

}
