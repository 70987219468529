import {Component, OnInit} from '@angular/core';
import {LgLogicObjectBaseComponent} from "../lg-logic-object-base.component";

@Component({
    selector: 'mvs-lg-logic-object-component-full',
    templateUrl: 'lg-logic-object-full.component.html'
})
export class LgLogicObjectFullComponent extends LgLogicObjectBaseComponent implements OnInit {

    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {
        this.navigationItems = [
            {
                label: 'Logic',
                action: 'Logic',
                tooltip: 'Logic',
                icon: 'fa-regular fa-square-code',
                toggleable: false,
                default: true
            },
            {
                label: 'TestCases',
                action: 'TestCases',
                tooltip: 'Test Cases',
                icon: 'fa-regular fa-flask-vial',
                toggleable: false,
                default: false
            },

        ];

        this.activeNavigationItem = this.navigationItems[0];
        this.handleNavigationItemsChange();
    }
}
