import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {EuRegistrationService} from "../../../../service/api/eu-registration.service";
import {EuUserService} from "../../../../service/api/eu-user.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {getStartOfMonth, getStartOfWeek} from "../../../../../core/helper/date-utils";
import {EuUserDeviceService} from "../../../../service/api/eu-user-device.service";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'eu-kpi.component.html',
})
export class EuKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalUsers: number = 0;
    totalRegistrations: number = 0;
    thisMonthLogins: number = 0;
    thisWeekLogins: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'End User Module'}];

    constructor(
        protected userDeviceService: EuUserDeviceService,
        protected registrationService: EuRegistrationService,
        protected userService: EuUserService,) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalUsers();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalUsers() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['active'],
            [new ObjectRequestListAttribute('active', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.userService.groupBy(objectRequestList).subscribe(res => {
            this.totalUsers = res.entries.reduce((acc, item) => acc + item['active_count'], 0);
            this.getTotalRegistrations();
        }, error => {
            this.getTotalRegistrations();
        });
    }

    getTotalRegistrations() {
        const objectRequestList2 = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['deviceType', "createdDate"],
            [new ObjectRequestListAttribute('deviceType', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.registrationService.groupBy(objectRequestList2).subscribe(res => {
            this.totalRegistrations = res.entries.length;
            this.getThisMonthLogins();
        }, error => {
            this.getThisMonthLogins();
        });
    }

    getThisMonthLogins() {
        const objectRequestList = this.getThisMonthRequest('deviceType');
        this.userDeviceService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthLogins = res.entries.reduce((acc, item) => acc + item['deviceType_count'], 0);
            this.getThisWeekLogins();
        }, error => {
            this.getThisWeekLogins();
        });
    }

    getThisWeekLogins() {
        const objectRequestList = this.getThisWeekRequest('deviceType');
        this.userDeviceService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekLogins = res.entries.reduce((acc, item) => acc + item['deviceType_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Users', value: this.totalUsers,
            },
            {
                label: 'Total Registration', value: this.totalRegistrations,
            },
            {
                label: 'This Month Logins', value: this.thisMonthLogins,
            },
            {
                label: 'This Week Logins', value: this.thisWeekLogins,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
