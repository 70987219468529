import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {ImImportRecordService} from "../../service/api/im-import-record.service";
import {ImImportRecordDto} from "../../dto/im-import-record.dto";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ImDataImportService} from "../../service/api/im-data-import.service";
import {FileHelperLogic} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-im-view-import',
  templateUrl: './im-view-import.component.html',
  styleUrls: ['./im-view-import.component.scss']
})
export class ImViewImportComponent implements OnInit {

  widgetImport: WidgetData;
  widgetImportRecords: WidgetData;
  widgetImportErrorRecords: WidgetData;

  busyDownloading: boolean;

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  @Input() importId: number;


  constructor(
      protected objectService: MvsUiObjectService,
      protected objectTypeService: ObjectTypeService,
      protected importRecordService: ImImportRecordService,
      protected dataImportService: ImDataImportService
  ) {
  }

  ngOnInit(): void {
    this.initComponent();
    this.refreshComponent();
    this.refreshImportErrorRecordsWidget();
  }

  /**
   * Initialize Component.
   */
  initComponent() {

  }

  refreshImportWidget() {

    this.widgetImport = new WidgetData();
    this.widgetImport.idAlias = "im.dashboard.import.view";
    this.widgetImport.name = "Import";
    this.widgetImport.uiComponent = "data";
    this.widgetImport.dataProvider = "list";
    this.widgetImport.dataSource = "entity";
    this.widgetImport.dataProviderObject = "im.ImImport";
    this.widgetImport.setParamValue("objectId", this.importId);

  }

  refreshImportRecordsWidget() {

    const filterCriteria = FilterCriteria.create("imImport", FilterCriteria.cOperatorEqual, this.importId, null);

    this.widgetImportRecords = new WidgetData();
    this.widgetImportRecords.idAlias = "im.dashboard.import.view.records";
    this.widgetImportRecords.name = "Eingespielte Datensätze";
    this.widgetImportRecords.uiComponent = "table";
    this.widgetImportRecords.dataProvider = "list";
    this.widgetImportRecords.dataSource = "entity";
    this.widgetImportRecords.dataProviderObject = "im.ImImportRecord";
    this.widgetImportRecords.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [filterCriteria],
        [new Sorting("id", true)],
        PagingDto.create(0, 20)
    );
    this.widgetImportRecords.setParamValue("selectionMode", "single");
    this.widgetImportRecords.setParamValue("size", "S");

  }

  refreshImportErrorRecordsWidget() {

    const filterCriteriaImport = FilterCriteria.create("imImport", FilterCriteria.cOperatorEqual, this.importId, null);
    const filterCriteriaStatus = FilterCriteria.create("status", FilterCriteria.cOperatorGreaterEqual, 2, null);


    this.widgetImportErrorRecords = new WidgetData();
    this.widgetImportErrorRecords.idAlias = "im.dashboard.import.view.errorRecords";
    this.widgetImportErrorRecords.name = "Fehlerhafte Datensätze";
    this.widgetImportErrorRecords.uiComponent = "table";
    this.widgetImportErrorRecords.dataProvider = "list";
    this.widgetImportErrorRecords.dataSource = "entity";
    this.widgetImportErrorRecords.dataProviderObject = "im.ImImportRecord";
    this.widgetImportErrorRecords.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [filterCriteriaImport, filterCriteriaStatus],
        [new Sorting("id", true)],
        PagingDto.create(0, 20)
    );
    this.widgetImportErrorRecords.setParamValue("selectionMode", "single");
    this.widgetImportErrorRecords.setParamValue("size", "S");

  }


  onImportRecordSelected(objectIdentifier: ObjectIdentifier) {

    this.importRecordService.get(objectIdentifier.objectId).subscribe(value => {
      this.showImportedObject(<ImImportRecordDto>value);
    });

  }

  /**
   * Show imported object
   * @param importedRecord
   */
  async showImportedObject(importedRecord: ImImportRecordDto) {
    let objectTypeDto = await this.objectTypeService.getViaObjectId(importedRecord.objectTypeDtoId);
    this.objectService.openObjectViaDialog(DtoImportObjectContext.createEmpty(), objectTypeDto.alias, importedRecord.objectId, null, true, false, null,
        MvsCrudModeEnum.create);
  }


  /**
   * Download Import Records.
   */
  onDownloadImportRecords() {

    if (this.busyDownloading) {
      return;
    }

    this.busyDownloading = true;

    this.dataImportService.downloadImportRecordsViaUrl(
        this.importId
    ).subscribe(url => {

      this.busyDownloading = false;

      FileHelperLogic.downloadFileViaUrl(url, "import_" + this.importId + ".xlsx");

    }, error => {
      this.busyDownloading = false;
    });
  }


  /**
   * Refresh Component.
   */
  refreshComponent() {

    this.refreshImportWidget();
    this.refreshImportRecordsWidget();

    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["importId"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  onImportSelect(objectIdentifier: ObjectIdentifier) {

    alert(objectIdentifier.objectId);

  }


}
