@if (initialized) {

    @if (activeNavigationItem.action == 'searchResultConfiguration') {
        <mvs-widget
                *ngIf="searchResultFieldConfigWidget"
                [widgetData]="searchResultFieldConfigWidget"
        ></mvs-widget>
    }

    @if (activeNavigationItem.action == 'objectTypeAttributeConfiguration') {
        <mvs-widget
                *ngIf="objectTypeAttributeSearchConfigWidget"
                [widgetData]="objectTypeAttributeSearchConfigWidget"
        ></mvs-widget>

    }

    @if (activeNavigationItem.action == 'reference') {
        <mvs-widget
                *ngIf="referenceWidgetData"
                [widgetData]="referenceWidgetData"
        ></mvs-widget>
        <mvs-widget
                *ngIf="referenceStructureWidget"
                [widgetData]="referenceStructureWidget"
        ></mvs-widget>
    }

    @if (activeNavigationItem.action == 'shortCut') {
        <mvs-widget
                *ngIf="shortCutFieldWidget"
                [widgetData]="shortCutFieldWidget"
        ></mvs-widget>
    }


}