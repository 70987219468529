<ng-container *ngIf="objectIdentifier">


    <!-- Customer Navigation -->
    <div class="surface-0 shadow-1 px-4 py-3 mb-3 cr-customer-sticky-header">
        <div class="flex gap-2 w-full align-items-center">

            <div class="flex gap-2 align-items-center">

                <!--                <div>-->
                <!--                    <img class="w-3rem h-3rem" *ngIf="photoUrl" [src]="photoUrl | mvsSecureBlob">-->
                <!--                </div>-->

                <div>
                    <img *ngIf="photoUrl && (photoUrl | mvsSecureBlob); else noImage" [src]="photoUrl | mvsSecureBlob"
                         class="w-3rem h-3rem">
                    <ng-template #noImage>
                        <i *ngIf="customerDto?.personDto?.gender == 1" class="fa-thin fa-person fa-3x"></i>
                        <i *ngIf="customerDto?.personDto?.gender == 2" class="fa-thin fa-person-dress fa-3x"></i>
                        <i *ngIf="customerDto?.personDto?.gender == 3" class="fa-thin fa-people fa-3x"></i>
                    </ng-template>
                </div>

                <ng-container *ngIf="customerDto">
                    <div class="mr-5">
                        <h5 class="mb-0">{{ customerDto.personDtoName }}</h5>
                        <small>{{ customerDto.alias }}</small>
                    </div>
                </ng-container>

                <!--                <p-megaMenu [model]="navigateViewTypes" styleClass="horizontal flex">-->

                <!--                    <ng-template pTemplate="start">-->
                <!--                        <ng-container *ngIf="customerDto">-->
                <!--                           <div class="mr-5">-->
                <!--                            <h5 class="mb-0">{{customerDto.personDtoName}}</h5>-->
                <!--                            <small>{{customerDto.alias}}</small>-->
                <!--                           </div>-->
                <!--                        </ng-container>-->
                <!--                    </ng-template>-->

                <!--                </p-megaMenu>-->

            </div>

            <div *ngIf="customerDto">
<!--                <mvs-cr-customer-related-persons [customerDto]="customerDto"-->
<!--                                                 (selectedRelatedPersonsChanged)="handleSelectedRelatedPersonsChanged($event)">-->
<!--                </mvs-cr-customer-related-persons>-->

                <pm-household-persons [customerDto]="customerDto"
                                      (selectedRelatedPersonsChanged)="handlePersonRelationPersonsChanged($event)"
                                      (householdPersonsLoaded)="handleHouseholdPersonsLoaded($event)"
                >

                </pm-household-persons>
            </div>

            <!--            <div class="flex flex-wrap gap-2">-->
            <!--                <core-indicator-->
            <!--                        [objectType]="'tm.Ticket'"-->
            <!--                        [label]="'Tickets'"-->
            <!--                        [filter]="ticketIndicatorFilterCriteria"-->
            <!--                        overlayPosition="top-right"-->
            <!--                        class="cr-customer-overlay"-->
            <!--                >-->
            <!--                </core-indicator>-->

            <!--                <core-indicator-->
            <!--                        [objectType]="'wf.WfProcess'"-->
            <!--                        [label]="'Workflows'"-->
            <!--                        [filter]="workflowIndicatorFilterCriteria"-->
            <!--                        [objectRequest]="workflowIndicatorObjectRequest"-->
            <!--                        overlayPosition="top-right"-->
            <!--                >-->
            <!--                </core-indicator>-->
            <!--            </div>-->
        </div>

    </div>

    <!--Customer Filters -->
    <div class="flex mt-1 mb-2" *ngIf="customerFilter.length > 0 && viewType == 0">

        <div class="col-12 flex">
            <h3 class="mb-0">{{ customerName }}</h3>

            <div class="flex align-items-center mx-4 gap-2 flex-wrap">
                <p-chip
                        *ngFor="let filter of customerFilter"
                        [label]="filter.field"
                        [removable]="true"
                        (onRemove)="onRemoveFilterCriteria(filter)"
                ></p-chip>
            </div>

        </div>

    </div>

    <!-- Show Object -->
    <mvs-cr-customer *ngIf="householdPersons"
                     [objectIdentifier]="objectIdentifier"
                     [changedFilter]="changedFilter"
                     [viewType]="viewType"
                     [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
                     [personRelationPersonWidgetStyle]="personRelatedPersonWidgetStyle"
                     [householdPersons]="householdPersons"
                     (onChangeCustomerFilter)="handleCustomerFilter($event)"
                     (onTicketsCount)="handleTicketsCount($event)"
                     (onWorkflowsCount)="handleWorkflowsCount($event)"
    >

    </mvs-cr-customer>

</ng-container>

<p-overlayPanel
    styleClass="ml-4"
    #op
    disabled="true"
    appendTo="body">

    <h3>Create Ticket</h3>

    <mvs-create-ticket-object *ngIf="showCreateTicketOverlay"
                              [objectId]="customerDto.id"
                              [objectAlias]="'cr.Customer'"
                              (onTicketCreation)="handleTicketCreation($event)">
    </mvs-create-ticket-object>

</p-overlayPanel>
