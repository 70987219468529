<h3 class="text-color-secondary mb-4 mt-2">Inputs</h3>
<div class="container card">
    <div class="sidebar">
        <ul class="input-section">
            <li class="input-list" *ngFor="let option of sidebarOptions" (click)="selectOption(option.id)"
                [ngClass]="option.id === selectedOption ? 'bg-primary-100 border-1 border-primary-300 border-round-lg' : ''">
                {{ option.name }}
            </li>
        </ul>
    </div>

    <div class="content col-10 mx-auto">
        <ng-container *ngIf="selectedOption === 'inputs'">
            <!--            <h3 class="my-4">Input Fields</h3>-->
            <h4 class="text-color-secondary">Basic</h4>

            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Text Field">
                        <mvs-form-field-text-field
                                [formGroup]="formGroup"
                                [formField]="nameEditFormField"
                        ></mvs-form-field-text-field>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Email">
                        <mvs-form-field-email
                                [formGroup]="formGroup"
                                [formField]="{id:'emailEdit'}"
                        ></mvs-form-field-email>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Int Number">
                        <mvs-form-field-int-number
                                [formGroup]="formGroup"
                                [formField]="{id:'phoneEdit'}"
                        ></mvs-form-field-int-number>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Float Number">
                        <mvs-form-field-float-number
                                [formGroup]="formGroup"
                                [formField]="{id:'phoneEdit'}"
                        ></mvs-form-field-float-number>
                    </p-fieldset>
                </div>
            </div>


            <h4 class="text-color-secondary mt-4">Custom</h4>
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Iban">
                        <mvs-form-field-iban-field
                                [formGroup]="formGroup"
                                [formField]="{id:'ibanEdit'}"
                        ></mvs-form-field-iban-field>
                    </p-fieldset>
                </div>

                <div class="col-6">
                    <p-fieldset legend="Input Icon">
                        <mvs-form-field-icon
                                [formGroup]="formGroup"
                                [formField]="{id:'iconEdit'}"
                        ></mvs-form-field-icon>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Input Height">
                        <mvs-form-field-height
                                [formGroup]="formGroup"
                                [formField]="{id:'sliderEdit'}"
                        ></mvs-form-field-height>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="General Index Search">
                        <mvs-form-field-general-index-search
                                [formGroup]="formGroup"
                                [formField]="objectTypeEditFormField"
                        ></mvs-form-field-general-index-search>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Flexible Search">
                        <mvs-form-field-flexible-search
                                [formGroup]="formGroup"
                                [formField]="objectTypeEditFormField"
                        ></mvs-form-field-flexible-search>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Entity Context Attribute">
                        <mvs-form-field-entity-context-attribute
                                [formGroup]="formGroup"
                                [formField]="objectTypeEditFormField"
                        ></mvs-form-field-entity-context-attribute>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'textArea'">

            <div class="col-12">
                <h4 class="text-color-secondary">Basic</h4>
                <p-fieldset legend="Text Area">
                    <mvs-form-field-text-area-field
                            [formGroup]="formGroup"
                            [formField]="nameEditFormField"
                    ></mvs-form-field-text-area-field>
                </p-fieldset>
            </div>
            <div class="col-12">
                <h4 class="text-color-secondary mt-4">Custom</h4>
                <p-fieldset legend="Editor">
                    <mvs-form-field-editor
                            [formGroup]="formGroup"
                            [formField]="{id:'nameEdit'}"
                    ></mvs-form-field-editor>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'sliderField'">
            <div class="col-12">
                <p-fieldset legend="Slider">
                    <mvs-form-field-slider
                            [formGroup]="formGroup"
                            [formField]="{id:'sliderEdit'}">
                    </mvs-form-field-slider>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'radioButton'">
            <div class="col-12">
                <p-fieldset legend="Radio Icons">
                    <mvs-form-field-select-radio-icons-only
                            [formGroup]="formGroup"
                            [formField]="radioEditFormField"
                    ></mvs-form-field-select-radio-icons-only>
                </p-fieldset>
            </div>

            <div class="col-12 mt-4">
                <p-fieldset legend="Radio Button">
                    <mvs-form-field-select-radio
                            [formGroup]="formGroup"
                            [formField]="radioEditFormField"
                    ></mvs-form-field-select-radio>
                </p-fieldset>
            </div>
        </ng-container>


        <ng-container *ngIf="selectedOption === 'phoneNumber'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Phone Number">
                        <mvs-form-field-phone-number
                                [formGroup]="formGroup"
                                [formField]="{id:'phoneEdit'}"
                        ></mvs-form-field-phone-number>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Mobile Number">
                        <mvs-form-field-mobile-number
                                [formGroup]="formGroup"
                                [formField]="{id:'phoneEdit'}"
                        ></mvs-form-field-mobile-number>
                    </p-fieldset>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'dropDown'">
            <h4 class="text-color-secondary mb-4">Dropdown</h4>
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Dropdown">
                        <mvs-form-field-select
                                [formGroup]="formGroup"
                                [formField]="radioEditFormField"
                        ></mvs-form-field-select>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Color Dropdown">
                        <mvs-form-field-colors
                                [formGroup]="formGroup"
                                [formField]="{id:'dateEdit'}"
                        ></mvs-form-field-colors>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="MultiSelect Dropdown">
                        <mvs-form-field-multi-select
                                [formGroup]="formGroup"
                                [formField]="radioEditFormField"
                        ></mvs-form-field-multi-select>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="MultiSelect String">
                        <mvs-form-field-multi-select-string
                                [formGroup]="formGroup"
                                [formField]="radioEditFormField"
                        ></mvs-form-field-multi-select-string>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Hierarchy Dropdown">
                        <mvs-form-field-select-hierarchy
                                [formGroup]="formGroup"
                                [formField]="radioEditFormField"
                        ></mvs-form-field-select-hierarchy>
                    </p-fieldset>
                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'chip'">
            <div class="col-12">
                <p-fieldset legend="Chip">
                    <mvs-form-field-select-chip
                            [formGroup]="formGroup"
                            [formField]="radioEditFormField"
                    ></mvs-form-field-select-chip>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'selectButton'">
            <div class="col-12">
                <p-fieldset legend="Select Button">
                    <mvs-form-field-select-button
                            [formGroup]="formGroup"
                            [formField]="radioEditFormField"
                    ></mvs-form-field-select-button>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'rating'">
            <div class="col-12">
                <p-fieldset legend="Rating">
                    <mvs-form-field-rating
                            [formGroup]="formGroup"
                            [formField]="{id:'ratingEdit'}"
                    ></mvs-form-field-rating>
                </p-fieldset>
            </div>
        </ng-container>

        <!--    <div class="col-12">-->

        <!--        <h4>Input Field: Object Type Id Search</h4>-->

        <!--        <div class="grid">-->
        <!--            <div class="col-6">-->
        <!--                <h5>Empty Field</h5>-->

        <!--                &lt;!&ndash;                <mvs-form-control-object-type-id-search&ndash;&gt;-->
        <!--                &lt;!&ndash;                        [formGroup]="formGroup"&ndash;&gt;-->
        <!--                &lt;!&ndash;                        [formField]="objectTypeFormField"&ndash;&gt;-->
        <!--                &lt;!&ndash;                ></mvs-form-control-object-type-id-search>&ndash;&gt;-->
        <!--            </div>-->

        <!--            <div class="col-6">-->
        <!--                <h5>Filled Field</h5>-->

        <!--                &lt;!&ndash;                <mvs-form-control-object-type-id-search&ndash;&gt;-->
        <!--                &lt;!&ndash;                        [formGroup]="formGroup"&ndash;&gt;-->
        <!--                &lt;!&ndash;                        [formField]="objectTypeEditFormField"&ndash;&gt;-->
        <!--                &lt;!&ndash;                ></mvs-form-control-object-type-id-search>&ndash;&gt;-->
        <!--            </div>-->
        <!--        </div>-->

        <!--    </div>-->

        <ng-container *ngIf="selectedOption === 'inputSwitch'">
            <div class="col-12">
                <p-fieldset legend="Input Switch">
                    <mvs-form-field-input-switch
                            [formGroup]="formGroup"
                            [formField]="{id:'switchEdit'}"
                    ></mvs-form-field-input-switch>
                </p-fieldset>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'inputDate'">
            <div class="grid">
                <div class="col-6">
                    <p-fieldset legend="Date Picker">
                        <div class="flex">
                            <mvs-form-field-date-picker
                                    [formGroup]="formGroup"
                                    [formField]="{id:'dateEdit'}"
                            ></mvs-form-field-date-picker>
                        </div>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Input Date (Today)">
                        <div class="flex">
                            <mvs-form-field-date-today
                                    [formGroup]="formGroup"
                                    [formField]="{id:'dateEdit'}"
                            ></mvs-form-field-date-today>
                        </div>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Input Date (Start)">
                        <div class="flex">
                            <mvs-form-field-date-start
                                    [formGroup]="formGroup"
                                    [formField]="{id:'dateEdit'}"
                            ></mvs-form-field-date-start>
                        </div>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Birth Date">
                        <div class="flex">
                            <mvs-form-field-date-birth
                                    [formGroup]="formGroup"
                                    [formField]="{id:'dateEdit'}"
                            ></mvs-form-field-date-birth>
                        </div>
                    </p-fieldset>
                </div>
                <div class="col-6">
                    <p-fieldset legend="Date & Time">
                        <div class="flex">
                            <mvs-form-field-date-time
                                    [formGroup]="formGroup"
                                    [formField]="{id:'dateEdit'}"
                            ></mvs-form-field-date-time>
                        </div>
                    </p-fieldset>
                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'inputCheckbox'">
            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <p-fieldset legend="Checkbox">
                            <mvs-form-field-checkbox
                                    [formGroup]="formGroup"
                                    [formField]="{id:'switchEdit'}"
                            ></mvs-form-field-checkbox>
                        </p-fieldset>
                    </div>
                </div>

            </div>
        </ng-container>

        <!--        <div class="col-12">-->

        <!--            <h4>Input Field: Agent Pool Agent</h4>-->

        <!--            <div class="grid">-->
        <!--                <div class="col-6">-->
        <!--                    <h5>Empty Field</h5>-->

        <!--                    <mvs-form-field-agent-pool-agent-->
        <!--                            [formGroup]="formGroup"-->
        <!--                            [formField]="objectTypeFormField"-->
        <!--                    ></mvs-form-field-agent-pool-agent>-->
        <!--                </div>-->

        <!--                <div class="col-6">-->
        <!--                    <h5>Filled Field</h5>-->

        <!--                    <mvs-form-field-agent-pool-agent-->
        <!--                            [formGroup]="formGroup"-->
        <!--                            [formField]="objectTypeEditFormField"-->
        <!--                    ></mvs-form-field-agent-pool-agent>-->
        <!--                </div>-->
        <!--            </div>-->

        <!--        </div>-->

    </div>

</div>