import {Component, Input} from '@angular/core';
import {
    FilterCriteria,
    MvsCoreService,
    ObjectRequestList,
    ObserverService,
    UiPageVisitResponseDto
} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {UiNavigationMainService} from "../../service/api/ui-navigation-main.service";
import {Observable} from "rxjs";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {UiPageUserService} from "../../service/api/ui-page-user.service";
import {UiPageUserFavoriteService} from "../../service/api/ui-page-user-favorite.service";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ActivatedRoute, Router} from "@angular/router";
import {WfProcessService} from "../../../wf/service/api/wf-process.service";

@Component({
    selector: 'ui-object-navigation-main',
    templateUrl: './ui-object-navigation-main.component.html',
    styleUrls: ['./ui-object-navigation-main.component.scss']
})
export class UiObjectNavigationMainComponent extends ObjectBaseComponent {

    dto: UiNavigationMainDto;
    crudService: UiNavigationMainService;
    frequentTickets: UiPageVisitResponseDto[];
    @Input() alias: string;

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected route: ActivatedRoute,
        protected observerService: ObserverService,
        protected uiNavigationMainService: UiNavigationMainService,
        protected uiPageUserService: UiPageUserService,
        protected navigationService: MvsObjectNavigationService,
        private router: Router
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit(): void {
        this.route.parent.params.subscribe(params => {
            const id = params['id'];
            const mainAlias = params['mainAlias'];

            if (id){
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationMain', id);
                super.ngOnInit();
            }else if (mainAlias){
                this.getObjectViaAlias(mainAlias);
            }

        });
    }


    /**
     * Process on object changed.
     */
    onObjectChanged() {
        super.onObjectChanged();

        this.uiRefreshObject();

        this.getFrequestTickets();

    }

    getObjectViaAlias(alias: string) {
        const filterCriteria = FilterCriteria.create('alias', FilterCriteria.cOperatorEqual, alias);
        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);

        this.uiNavigationMainService.list(objectRequest).subscribe(res => {
            if (res.entries.length > 0) {
                const entry = res.entries[0];
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationMain', entry.id);
                super.ngOnInit();
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    getFrequestTickets() {
        if (this.dto.alias != 'tm') {
            return;
        }

        this.uiPageUserService.getLastVisitsByRoute("tm/ticket/:id", 10).subscribe((value: UiPageVisitResponseDto[]) => {
            this.frequentTickets = value;
        });
    }

    /**
     * Return Observerable for FULL retrieval.
     * @protected
     */
    protected getRetrieveObjectObservable(): Observable<any> {
        return this.crudService.getFull(this.objectIdentifier.objectId);
    }

    handleOpenTicket(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', ticketId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * UI Refresh Activities.
     */
    uiRefreshObject() {

    }

}
