<h1>Pass and Access Codes</h1>
<p>In unserem Bereich für Code-Verwaltung kannst du sowohl Pass Codes als auch Access Codes einfach und sicher handhaben.</p>

<ng-container *ngIf="initialized">

    <p-tabView [(activeIndex)]="activeTabIndex">
        <p-tabPanel header="Übersicht">

            <div class="grid">
                <div class="col-6">
                    <mvs-widget *ngIf="activeTabIndex == 0" [widgetData]="widgetOverviewActiveAccessCodes"></mvs-widget>
                </div>
                <div class="col-6">
                    <mvs-widget *ngIf="activeTabIndex == 0" [widgetData]="widgetOverviewActivePassCodes"></mvs-widget>
                </div>
            </div>

        </p-tabPanel>
        <p-tabPanel header="Pass Codes" leftIcon="fas fa-user-lock">
            <p>
                Diese sind kurzlebige, zeitlich begrenzte Codes, die speziell für dich als unseren Kunden ausgestellt werden. Sie dienen deiner einmaligen Authentifizierung oder der Durchführung spezifischer Aktionen und erhöhen somit die Sicherheit und Integrität unserer Services. Ideal für temporären Zugriff oder als Teil unseres Multi-Faktor-Authentifizierungsprozesses.
            </p>

            <div class="grid">
                <div class="col-12">
                    <mvs-widget *ngIf="activeTabIndex == 1" [widgetData]="widgetTablePassCodes"></mvs-widget>
                </div>
            </div>


        </p-tabPanel>
        <p-tabPanel header="Access Codes" leftIcon="fas fa-key">
            <p>
                Diese Codes sind speziell für die Nutzung unserer APIs vorgesehen. Sie ermöglichen es deinen Drittsystemen, sicher auf unsere Dienste zuzugreifen und stellen eine dauerhafte Integration sicher. Access Codes sind essenziell für die Automatisierung und effiziente Interaktion mit unseren Plattformfunktionen.
            </p>

            <div class="grid">
                <div class="col-12">
                    Active: {{activeTabIndex}}
                    <mvs-widget *ngIf="activeTabIndex == 2" [widgetData]="widgetTableAccessCodes"></mvs-widget>
                </div>
            </div>


        </p-tabPanel>
    </p-tabView>

</ng-container>