<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12">
            <mvs-widget [widgetData]="widgetRegistrationHistory"></mvs-widget>
        </div>
<!--        <div class="col-6">-->
<!--            <mvs-widget [widgetData]="widgetCustomerRegistrationHistory"></mvs-widget>-->
<!--        </div>-->
        <div class="col-12">
            <mvs-widget [widgetData]="widgetRegistrations"></mvs-widget>
        </div>
    </div>


</ng-container>