import {Component, OnInit} from '@angular/core';
import {
    DtoTemplate,
    FilterCriteria,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestRelation, PageComponent,
    Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {ObjectUserAccessService} from "../../../../cc/service/api/object-user-access.service";
import {TicketTypeService} from "../../../../tm/service/api/ticket-type.service";
import {PersonService} from "../../../../pm/service/api/person.service";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {TicketTypeActionService} from "../../../../tm/service/api/ticket-type-action.service";

@Component({
    selector: 'mvs-test-form-ather',
    templateUrl: 'test-ather.page.html',
    styleUrls: ['./test-ather.page.scss']
})
export class TestAtherPage extends PageComponent implements OnInit {

    objectWidget: WidgetData;
    ticketTypeWidget: WidgetData;
    ticketWidget: WidgetData;
    customerWidget: WidgetData;
    formWidget: WidgetData;

    // cases: CaseConfig[] = [
    //     {
    //         id: 'tm.TicketType',
    //         label: 'Ticket Type',
    //         icon: 'fa-regular fa-typewriter text-xl',
    //         tooltip: 'Ticket Type',
    //         anchorId: 'TicketType',
    //         widgetTitle: 'Ticket Type',
    //         dataProperty: 'ticketType',
    //         widgetDataProperty: 'ticketTypeWidget',
    //         objectType: 'tm.TicketType',
    //         objectRequestField: null,
    //         widgetComponent: 'data'
    //     },
    //     {
    //         id: 'tm.TicketTypeAction',
    //         label: 'Ticket Type Action',
    //         icon: 'fa-solid fa-hand-point-right text-xl',
    //         tooltip: 'Ticket Type Action',
    //         anchorId: 'TicketTypeAction',
    //         widgetTitle: 'Ticket Type Action',
    //         dataProperty: 'ticketTypeAction',
    //         widgetDataProperty: 'ticketTypeActionWidget',
    //         objectType: 'tm.TicketTypeAction',
    //         objectRequestField: 'ticketType',
    //         widgetComponent: 'selectable'
    //     },
    //     {
    //         id: 'tm.TicketTypeObjectRole',
    //         label: 'Ticket Type Object Role',
    //         icon: 'fa-solid fa-user-tag text-xl',
    //         tooltip: 'Ticket Type Object Role',
    //         anchorId: 'TicketTypeObjectRole',
    //         widgetTitle: 'Ticket Type Object Role',
    //         dataProperty: 'ticketTypeObjectRole',
    //         widgetDataProperty: 'ticketTypeObjectRoleWidget',
    //         objectType: 'tm.TicketTypeObjectRole',
    //         objectRequestField: 'ticketType',
    //     },
    //     {
    //         id: 'tm.TicketTypeObjectType',
    //         label: 'Ticket Type Object Type',
    //         icon: 'fa-solid fa-layer-group text-xl',
    //         tooltip: 'Ticket Type Object Type',
    //         anchorId: 'TicketTypeObjectType',
    //         widgetTitle: 'Ticket Type Object Type',
    //         dataProperty: 'ticketTypeObjectType',
    //         widgetDataProperty: 'ticketTypeObjectTypeWidget',
    //         objectType: 'tm.TicketTypeObjectType',
    //         objectRequestField: 'ticketType',
    //     },
    //     {
    //         id: 'tm.TicketTypeNotificationType',
    //         label: 'Ticket Type Notification Type',
    //         icon: 'fa-regular fa-bell text-xl',
    //         tooltip: 'Ticket Type Notification Type',
    //         anchorId: 'TicketTypeNotificationType',
    //         widgetTitle: 'Ticket Type Notification Type',
    //         dataProperty: 'ticketTypeNotification',
    //         widgetDataProperty: 'ticketTypeNotificationTypeWidget',
    //         objectType: 'tm.TicketTypeNotificationType',
    //         objectRequestField: 'ticketType',
    //     },
    //     {
    //         id: 'tm.TicketTypeCompleteStatus',
    //         label: 'Ticket Type Completion Status',
    //         icon: 'fa-solid fa-check-circle text-xl',
    //         tooltip: 'Ticket Type Completion Status',
    //         anchorId: 'TicketTypeCompleteStatus',
    //         widgetTitle: 'Ticket Type Completion Status',
    //         dataProperty: 'ticketTypeCompleteStatus',
    //         widgetDataProperty: 'ticketTypeCompleteStatusWidget',
    //         objectType: 'tm.TicketTypeCompleteStatus',
    //         objectRequestField: 'ticketType',
    //     },
    //     {
    //         id: 'tm.TicketTypeField',
    //         label: 'Ticket Type Field',
    //         icon: 'fa-solid fa-list-alt text-xl',
    //         tooltip: 'Ticket Type Field',
    //         anchorId: 'TicketTypeField',
    //         widgetTitle: 'Ticket Type Field',
    //         dataProperty: 'ticketTypeField',
    //         widgetDataProperty: 'ticketTypeFieldWidget',
    //         objectType: 'tm.TicketTypeField',
    //         objectRequestField: 'type',
    //     },
    // ];
    objectViewWidget: WidgetData;
    ticketNewWidget: WidgetData;
    ticketViewWidget: WidgetData;
    ticketEditWidget: WidgetData;

    constructor(protected ticketTypeActionService: TicketTypeActionService,protected personService: PersonService, protected customerService: CustomerService, protected ticketTypeService: TicketTypeService, route: ActivatedRoute, coreService: MvsCoreService, protected ticketService: TicketService, protected objectAccessService: ObjectUserAccessService) {
        super(route, coreService);
    }

    showWidget() {
        this.objectViewWidget = WidgetFactory.createWidgetObject(
            `xzc.object.view.widget`,
            'Object',
            'tm.Ticket',
            23
        );
    }

    showticketNewWidget() {
        this.ticketNewWidget = WidgetFactory.createWidgetObject(
            `tick.newxct.view.widget`,
            'New Ticket',
            'tm.Ticket',
            0
        );
    }

    showticketViewWidget() {
        this.ticketViewWidget = WidgetFactory.createWidgetObject(
            `xzc.xtic.viewobject.view.widget`,
            'Refresh Ticket',
            'tm.Ticket',
            0
        );
    }

    showticketEditWidget() {
        this.ticketEditWidget = WidgetFactory.createWidgetObject(
            `xzcx.tic.eidtobject.view.widget`,
            'Edit Ticket',
            'tm.Ticket',
            149
        );
    }

    ngOnInit() {

        const fill = [FilterCriteria.create('ticketType', FilterCriteria.cOperatorEqual,20),FilterCriteria.create('ticketType', FilterCriteria.cOperatorEqual,18)];

        const ff = FilterCriteria.createOr(...fill);

        const obj = ObjectRequestList.createBasic(false,[ff],[])

        this.ticketTypeActionService.list(obj).subscribe(res => {
            debugger
        })

        const filters: FilterCriteria[] = [];

        filters.push(
            FilterCriteria.create('lastName', FilterCriteria.cOperatorContainsPattern, '%Balusek%', null)
        );


        const complex = ObjectRequestComplex.build(true, false,

            ObjectRequestComplexNode.createRelationNode("customerDto",
                ObjectRequestRelation.createList(
                    "cr.Customer",
                    "person",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ANY)
            )
        );

        const request = ObjectRequestList.createComplexRequestList(
            true,
            complex,
            filters,
            [],
            null
        )

        // this.personService.list(request).subscribe(res => {
        //     debugger
        // })

        const filterc: FilterCriteria[] = [];

        filterc.push(
            FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 5633),
            FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 5640)
        );

        const tt = [{id:5633}, {id:5640}]

        const filor = FilterCriteria.createOrFromArray('id','id',tt)

        const requestc = ObjectRequestList.createBasic(false, [filor], [])

        // this.customerService.list(requestc).subscribe(res => {
        //     debugger
        // })

        // this.ticketService.template(new DtoTemplate()).subscribe(res => {
        //     debugger
        // });

        // this.objectAccessService.actionBye(3,null,'ui.UiNavigationSub').subscribe(res => {
        //     debugger
        // })

        const complexSelection = ObjectRequestComplexNode.createRelationNode("customer", ObjectRequestRelation.createJoin("+objectLatestCustomer"))

        const cusobjectRequest = ObjectRequestList.createBasic(true, [FilterCriteria.create('id', FilterCriteria.cOperatorBetween, 5633, 5640)], [new Sorting('id', false)]);


        // const objectRequest = ObjectRequestList.createComplexRequestList(
        //     true,
        //     ObjectRequestComplex.build(true, false,
        //         complexSelection
        //     ),
        //     [],
        //     [new Sorting('id',false)],
        //     null
        // )

        const filter2 = [
            FilterCriteria.create("type", FilterCriteria.cOperatorEqual, 14),
            FilterCriteria.create("type", FilterCriteria.cOperatorEqual, 15),

        ]

        const filter: FilterCriteria[] = [
            FilterCriteria.create("assigneeAgentPool", FilterCriteria.cOperatorEqual, 2),
            FilterCriteria.create("assigneeAgent", FilterCriteria.cOperatorEqual, 170),

        ]

        const orFilter: FilterCriteria[] = [
            FilterCriteria.createOr(...filter),
            FilterCriteria.createOr(...filter2)]

        const filterCriteria = [
            FilterCriteria.createOr(...filter),
            FilterCriteria.createOr(...filter2),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, 5),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, 7),

        ]

        const objectRequest = ObjectRequestList.createBasic(
            true,
            filterCriteria,
            [],
        )

        // this.ticketService.list(objectRequest).subscribe(res => {
        //     debugger
        // })

        this.ticketWidget = WidgetFactory.createWidgetTableEntity('bnmgscxzttyuasdq', 'ticket', 'tm.Ticket', 'no tickets', objectRequest)
        this.customerWidget = WidgetFactory.createWidgetTableEntity('cuscuscuscs', 'customers', 'cr.Customer', 'no customer', cusobjectRequest)


        //
        // this.userService.me().subscribe(res => {
        //     debugger
        // })

        this.objectWidget = WidgetFactory.createWidgetObject('tantantanqwedsas', 'Person', 'pm.Person', 0)
        this.ticketTypeWidget = WidgetFactory.createWidgetObject('tickettypewidgetaliastest', 'Ticket Type', 'tm.TicketType', 17)

        this.formWidget = WidgetFactory.createWidgetObject('tickettypewidgetaliastest', 'Ticket Type', 'tm.TicketType', 0)
    }

    handleDateStart(startDate: Date | null) {
        console.log('Start Date Received:', startDate);
        // Perform additional actions with the received start date here
    }

}
