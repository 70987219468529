import {Component,  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {CustomerContractService} from "../../service/api/customer-contract.service";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ContractPersonService} from "../../../ci/service/api/contract-person.service";
import {DmDocumentViewOptionsEnumEnum} from "../../../dm/enum/dm-document-view-options.enum";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {ContractInsurableObjectService} from "../../../ci/service/api/contract-insurable-object.service";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ContractDto} from "../../../cm/dto/contract.dto";
import {CustomerContractDto} from "../../dto/customer-contract.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {CustomerDto} from "../../dto/customer.dto";

@Component({
    selector: 'mvs-cr-customer-contract-details',
    templateUrl: './cr-customer-contract-details.component.html',
})
export class CrCustomerContractDetailsComponent
    implements OnInit, OnDestroy, OnChanges {

    @Input() selectedContract: CustomerContractDto;
    @Input() selectedCustomer: CustomerDto;
    @Input() selectedRelatedPersons: CrCustomerRelatedPersonWidgetStyle;
    widgetOverviewContract: WidgetData;
    insuredPersonWidgetData: WidgetData;
    companyContactWidgetData: WidgetData;
    contractStatusWidgetData: WidgetData;
    insurableObjectWidgetData: WidgetData;
    contractPriceWidgetData: WidgetData;
    dmDocumentViewOptionsEnumEnum: typeof DmDocumentViewOptionsEnumEnum = DmDocumentViewOptionsEnumEnum;
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    contractId: number;
    objectIdentifier: ObjectIdentifier;

    contractObjectIdentifier: ObjectIdentifier;

    constructor(protected contractPersonService: ContractPersonService,
                protected messageService: MvsMessageService,
                protected customerContractService: CustomerContractService,
                protected navigationService: MvsObjectNavigationService,
                protected confirmationService: ConfirmationService,
                protected configService: MvsConfigService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected coreService: MvsCoreService,) {
    }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('cid');
        if(id) {
            this.selectedContract = new CustomerContractDto();
            this.contractId = +id;
            this.selectedContract.id = +id;
            this.refreshComponent();
        } else if(this.selectedContract) {
            this.selectedContract['contractDto'] = this.selectedContract as unknown as ContractDto;
            this.selectedContract['contractDtoId'] = this.selectedContract.id;
            this.refreshComponent();
        }
        this.initComponent();
        // this.refreshComponent();
        this.initialized = true;
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // store contract object identifier
        this.contractObjectIdentifier = new ObjectIdentifier("cm.Contract",this.selectedContract.contractDto.id);
        // this.contractObjectIdentifier = new ObjectIdentifier("cm.Contract",this.contractId);

        this.setObjectIdentifier();
        this.showOverviewContract();
        this.showInsuredPerson();
        this.showCompanyContact();
        this.showContractStatus();
        this.showCustomerInsurableObject();
        this.showContractPriceWidgetData();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["selectedContract"]) {
                this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

    setObjectIdentifier() {
        this.objectIdentifier = new ObjectIdentifier('cm.Contract', this.selectedContract.id);
    }


    showOverviewContract(){
        this.widgetOverviewContract = new WidgetData();
        this.widgetOverviewContract.idAlias = "cr.customer.basic.overview.contract";
        this.widgetOverviewContract.name = "Overview Contract";
        this.widgetOverviewContract.uiComponent = "list";
        this.widgetOverviewContract.dataProvider = "list";
        this.widgetOverviewContract.dataSource = "entity";
        this.widgetOverviewContract.dataProviderObject = "cr.CustomerContract";
        this.widgetOverviewContract.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.selectedContract.contractDto.id)],
            [new Sorting("createdDate", true)]
        );
        this.widgetOverviewContract.dataProviderListRequest.objectRequestComplex =
            this.customerContractService.getCustomerContractsComplexSelection(this.selectedCustomer.personDtoId);
        this.widgetOverviewContract.setParamValue("size", "S");
        this.widgetOverviewContract.setParamValue("objectWidget", "data");
        this.widgetOverviewContract.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    showInsuredPerson(){
        this.insuredPersonWidgetData = new WidgetData();
        this.insuredPersonWidgetData.idAlias = "cr.customer.basic.insured.person";
        this.insuredPersonWidgetData.name = "Insured Person";
        this.insuredPersonWidgetData.uiComponent = "list";
        this.insuredPersonWidgetData.dataProvider = "list";
        this.insuredPersonWidgetData.dataSource = "entity";
        this.insuredPersonWidgetData.dataProviderObject = "ci.ContractPerson";
        this.insuredPersonWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual, this.selectedContract.contractDto.id)],
            [new Sorting("createdDate", true)]
        );
        this.insuredPersonWidgetData.dataProviderListRequest.objectRequestComplex = this.contractPersonService.getContractPersonComplexSelection();
        this.insuredPersonWidgetData.setParamValue("size", "S");
        this.insuredPersonWidgetData.setParamValue("objectWidget", "data");
        this.insuredPersonWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    showCompanyContact(){

        this.companyContactWidgetData = new WidgetData();
        this.companyContactWidgetData.idAlias = "cr.customer.basic.company.contact";
        this.companyContactWidgetData.name = "Company Contact";
        this.companyContactWidgetData.uiComponent = "list";
        this.companyContactWidgetData.dataProvider = "list";
        this.companyContactWidgetData.dataSource = "entity";
        this.companyContactWidgetData.dataProviderObject = "cr.CustomerContract";
        this.companyContactWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.selectedContract.contractDto.id)],
            [new Sorting("createdDate", true)]
        );
        // -> ContractPartner -> Partner -> PartnerContact
        this.companyContactWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCompanyContactFromContractComplexSelection();
        this.companyContactWidgetData.setParamValue("size", "S");
        this.companyContactWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    showContractStatus() {
        // CONTRACT STATUS INFORMATION
        this.contractStatusWidgetData = new WidgetData();
        this.contractStatusWidgetData.idAlias = "cr.customer.contract.details.status.complex.widget";
        this.contractStatusWidgetData.name = "cm.ContractStatus";
        this.contractStatusWidgetData.uiComponent = "list";
        this.contractStatusWidgetData.dataProvider = "list";
        this.contractStatusWidgetData.dataSource = "entity";
        this.contractStatusWidgetData.dataProviderObject = "cm.ContractStatus";

        this.contractStatusWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.selectedContract.contractDto.id)],
            []
        );

        this.contractStatusWidgetData.setParamValue("size", "S");
        this.contractStatusWidgetData.setParamValue("objectWidget", "data");
        this.contractStatusWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    showCustomerInsurableObject() {
        // CONTRACT INSURABLE OBJECTS INFORMATION
        this.insurableObjectWidgetData = new WidgetData();
        this.insurableObjectWidgetData.idAlias = "cr.contract.insurable.objects.status.complex.widget";
        this.insurableObjectWidgetData.name = "ci.ContractInsurableObject";
        this.insurableObjectWidgetData.uiComponent = "list";
        this.insurableObjectWidgetData.dataProvider = "list";
        this.insurableObjectWidgetData.dataSource = "entity";
        this.insurableObjectWidgetData.dataProviderObject = "ci.ContractInsurableObject";

        this.insurableObjectWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.selectedContract.contractDto.id)],
            []
        );

        this.insurableObjectWidgetData.setParamValue("size", "S");
        this.insurableObjectWidgetData.setParamValue("objectWidget", "data");
        this.insurableObjectWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        }

        showContractPriceWidgetData() {
            // CONTRACT PRICE INFORMATION
            this.contractPriceWidgetData = new WidgetData();
            this.contractPriceWidgetData.idAlias = "cm.contract.price.agent.complex.widget";
            this.contractPriceWidgetData.name = "Preis";
            this.contractPriceWidgetData.uiComponent = "list";
            this.contractPriceWidgetData.dataProvider = "list";
            this.contractPriceWidgetData.dataSource = "entity";
            this.contractPriceWidgetData.dataProviderObject = "cm.ContractPrice";
            this.contractPriceWidgetData.dataProviderListRequest = new ObjectRequestList(
                true,
                [FilterCriteria.create("contract", FilterCriteria.cOperatorEqual, this.selectedContract.contractDto.id)],
                []
            );
            this.contractPriceWidgetData.setParamValue("size", "S");
            this.contractPriceWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        }

    navigateToNewTab() {
        const objectIdentifier = new ObjectIdentifier('cm.Contract', this.selectedContract.contractDtoId);
        this.navigationService.handleObjectNavigation(objectIdentifier, null, { openNewTab: true});
    }

    widgetProcessToolbarCreate() {
        return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['contractDtoId'] = this.selectedContract.contractDto.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }};
    }
}
