import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";

@Component({
    selector: 'value-list-object-base',
    templateUrl: 'value-list-object-base.component.html',
})

export class ValueListObjectBaseComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}