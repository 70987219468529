import {Component} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-ad-config-page',
    templateUrl: './ad-config.page.html',
})
export class AdConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Address Configuration";

    adAddressTypeWidget: WidgetData;
    adCountryWidget: WidgetData;
    adAddressTypeObjectTypeWidget: WidgetData;

    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;

    widgetEntries: TabEntries[] = [
        {label:'Addresstypen', id: 'ad.AddressType', type: ''},
        {label:'Länder', id: 'ad.Country', type: ''},
        {label:'Address Typen Zuordnung', id: 'ad.AddressTypeObjectType', type: ''},
    ];

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();
        this.refreshWidgets();
        this.subscribeToParams();
        this.objectBrowserRequest = {

            "extractDto": false,
            "createMode": true,

            'entries': [
                {
                    'id'                : 1,
                    'objectAlias'       : 'ad.AddressType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Addresstypen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"

                },
                {
                    'id'                : 2,
                    'objectAlias'       : 'ad.Country',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Länder",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 3,
                    'objectAlias'       : 'ad.AddressTypeObjectType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Address Typen Zuordnung",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
            ]

        };
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            if (params['tid']) {
                const object = this.widgetEntries.find(item => item.id == params['tid']);
                if (object) {
                    this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
                    this.activeWidget = object;
                }
            } else {
                this.activeWidget = this.widgetEntries[0];
                this.activeTabIndex = 0;
            }
        });
    }

    refreshWidgets() {
        this.refreshAdAddressTypeWidgetWidget();
        this.refreshAdCountryWidget();
        this.refreshAdAddressTypeObjectTypeWidget();
    }
    refreshAdAddressTypeWidgetWidget() {
        this.adAddressTypeWidget = WidgetFactory.createWidgetList(
            "ad.address.type.widget.simple",
            "Addresstypen",
            "table",
            "list",
            "entity",
            "ad.AddressType",
            null,
            ObjectRequestList.createBasic(true, [], [])
        );
    }

    refreshAdCountryWidget() {
        this.adCountryWidget = WidgetFactory.createWidgetList(
            "ad.country.widget.simple",
            "Länder",
            "table",
            "list",
            "entity",
            "ad.Country",
            null,
            ObjectRequestList.createBasic(true, [], [])
        );
    }

    refreshAdAddressTypeObjectTypeWidget() {
        this.adAddressTypeObjectTypeWidget = WidgetFactory.createWidgetList(
            "ad.address.type.object.type.widget.simple",
            "Address Typen Zuordnung",
            "table",
            "list",
            "entity",
            "ad.AddressTypeObjectType",
            null,
            ObjectRequestList.createBasic(true, [], [])
        );
    }


    initComponent(): void {
        this.initialized = true;
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.widgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateSelect(event: ObjectIdentifierData) {
        this.objectType = event.objectType;
        this.objectId = event.objectId;
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    isConfigurationPage(): boolean {
        return true;
    }

}
