import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    FilterCriteria, NavigationItem,
    ObjectBaseComponent,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestRelation,
    WidgetData,
} from '@kvers/alpha-core-common';
import {MvsCoreService} from '@kvers/alpha-core-common';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {MvsMessageService} from '@kvers/alpha-core-common';
import {WidgetFactory} from '@kvers/alpha-ui';
import {
    CrCustomerObjectViewTypeUiEnum
} from "../../../../cr/page/object/cr-customer-object-page/data/cr-customer-object-view-type-ui.enum";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {RoleService} from "../../../service/api/role.service";
import {RoleDto} from "../../../dto/role.dto";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'um-role-object-component',
    templateUrl: 'um-role-object.component.html',
    styleUrls: ['um-role-object.component.scss'],
})
export class UmRoleObjectComponent
    extends ObjectBaseComponent
    implements OnInit, OnChanges, OnDestroy {
    busy: boolean; // Indicator whether the component is busy
    initialized: boolean; // Indicator whether the component was initialized

    roleAuthConfigWidget: WidgetData;
    roleAuthModuleWidget: WidgetData;
    roleAuthObjectTypeWidget: WidgetData;
    roleAuthContractWidget: WidgetData;
    roleAuthCustomerWidget: WidgetData;

    queryParamSubscription: Subscription;
    selectedNavigationItem: string;
    navigationItems: NavigationItem[];

    roleDto: RoleDto;
    roleId: number;

    selectedWidget: string = 'Config'; // Set default to 'Module'
    items: MenuItem[]; // Menu items for the sidebar

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected roleService: RoleService,
        protected router: Router,
        protected override observerService: ObserverService
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit(): void {

        this.getNavigationItems();

        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem']) {
                const exists = this.checkNavigationEntryExist(this.navigationItems, params['navItem']);
                if (exists) {
                    this.selectedWidget = params['navItem'];
                } else {
                    this.removeNavItemParam();
                }
            }
        });



        this.initComponent();
        this.initializeMenuItems(); // Initialize menu items
    }

    getNavigationItems() {
        this.navigationItems = this.roleService.getObjectComponentNavigationItems();
    }

    checkNavigationEntryExist(items: NavigationItem[], label: string): boolean {
        const exists = items.find(item => item.label == label);
        return !!exists;

    }

    removeNavItemParam(): void {
        const queryParams = {...this.route.snapshot.queryParams};
        delete queryParams['navItem'];
        this.router.navigate([], {queryParams: queryParams});
    }

    getRoleDto() {
        this.roleService.get(this.objectIdentifier.objectId).subscribe(res => {
            this.roleDto = <RoleDto>res;
        })
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.loadRoleAuthConfig();
        this.loadRoleAuthModule();
        this.loadRoleAuthObjectType();
        this.loadRoleAuthContract();
        this.loadRoleAuthCustomer();
        this.getRoleDto();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Initialize the menu items for the sidebar.
     */
    initializeMenuItems() {
        this.items = [
            {
                label: 'Config',
                icon: 'pi pi-fw pi-th-large',
                command: () => {
                    this.selectedWidget = 'Config';
                },
            },
            {
                label: 'Module',
                icon: 'pi pi-fw pi-th-large',
                command: () => {
                    this.selectedWidget = 'Module';
                },
            },
            {
                label: 'ObjectType',
                icon: 'pi pi-fw pi-cog',
                command: () => {
                    this.selectedWidget = 'ObjectType';
                },
            },
            {
                label: 'Contract',
                icon: 'pi pi-fw pi-file',
                command: () => {
                    this.selectedWidget = 'Contract';
                },
            },
            {
                label: 'Customer',
                icon: 'pi pi-fw pi-users',
                command: () => {
                    this.selectedWidget = 'Customer';
                },
            },
        ];
    }

    getObjectRequestList(): ObjectRequestList {
        return ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,

                ObjectRequestComplexNode.createSimpleAttributeNode("role")
            ),
            [
                FilterCriteria.create(
                    'role',
                    FilterCriteria.cOperatorEqual,
                    this.objectIdentifier.objectId
                ),
            ],
            [],
            null
        );
    }


    loadRoleAuthConfig() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthConfigWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.config.widget',
            'Auth config',
            'um.RoleAuthConfig',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthModule() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthModuleWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.module.widget',
            'Auth module',
            'um.RoleAuthModule',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthObjectType() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthObjectTypeWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.object.type.widget',
            'Auth object type',
            'um.RoleAuthObjectType',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthContract() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthContractWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.contract.widget',
            'Auth contract',
            'cm.RoleAuthContract',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthCustomer() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,
                ObjectRequestComplexNode.createSimpleAttributeNode("role"),
                ObjectRequestComplexNode.createRelationNode("customerRegion",
                    ObjectRequestRelation.createJoinWithBindingType('cr.RoleAuthCustomerRegion#roleAuthCustomer', ObjectRequestComplexRelationBindingEnum.LATEST)
                ),
                ObjectRequestComplexNode.createRelationNode("customerType",
                    ObjectRequestRelation.createJoinWithBindingType('cr.RoleAuthCustomerType#roleAuthCustomer', ObjectRequestComplexRelationBindingEnum.LATEST)
                )
            ),
            [
                FilterCriteria.create(
                    'role',
                    FilterCriteria.cOperatorEqual,
                    this.objectIdentifier.objectId
                ),
            ],
            [],
            null
        );

        this.roleAuthCustomerWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.customer.widget',
            'Auth customer',
            'cr.RoleAuthCustomer',
            'No Data',
            objectRequest
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }
}
