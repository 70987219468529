import {Component, OnInit} from "@angular/core";
import {DmDocumentBaseComponent} from "../dm-document-base.component";

@Component({
    selector: 'dm-document-full',
    templateUrl:'dm-document-full.component.html'
})

export class DmDocumentFullComponent extends DmDocumentBaseComponent implements OnInit {

    ngOnInit() {
        super.ngOnInit();
    }
}