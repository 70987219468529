import { Injectable } from '@angular/core';
import {MvsApiCrudServiceCommentsInterface, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TicketActionCommentService extends MvsCrudService implements MvsApiCrudServiceCommentsInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketActionComments');
  }

  getCommentAttribute(): string {
    return "comment";
  }


  override getLinkedAttribute(objectType: string): string {
    if (objectType === "tm.TicketAction") {
      return "ticketAction";
    }

    return null;
  }

}
