

<p-button title="test meta" (click)="onTestMeta();"></p-button>

<div class="card flex justify-content-center">
    <p-cascadeSelect
            [(ngModel)]="cascadeTestSelected"
            [options]="cascadeTest"
            optionLabel="label"
            optionGroupLabel="label"
            [optionGroupChildren]="['children']"
            [style]="{ minWidth: '14rem' }"
            placeholder="Select a letter"
    ></p-cascadeSelect>
</div>

<div class="card">

    {{cascadeTestSelected}}

</div>






<!--
<h2>Hello World</h2>
<editor [init]="{
  base_url: '/tinymce',
  suffix: '.min',
  plugins: 'code'
}"></editor>
-->

<!--

<h1>Our Tickets</h1>

<mvs-widget
    [widgetData]="ticketWidgetData"
    [importObjectContext]="importObjectContext"
    (onObjectSelect)="ticketSelected($event);"
></mvs-widget>
-->

<!--
<span
            class="mce-mergetag"
            contenteditable="false"
            data-mce-cef-wrappable="true"
            data-mce-mergetag="1">
    <span class="mce-mergetag-affix" data-mce-mergetag-affix="">{{</span>
    Contact.Email
    <span class="mce-mergetag-affix" data-mce-mergetag-affix="">}}</span>
</span>
-->







<!--
<h1>Tickets History</h1>
<mvs-widget
        [widgetData]="ticketHistoryWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>
-->
<!--
<mvs-entity-sorting class="sort-icon text-xs"
                    [objectTypeID]=""
                    filterIcon="fa-regular fa-bars-sort" [readOnly]="false"></mvs-entity-sorting>
-->
































<!--

<h4>Form Widget</h4>

<mvs-widget
        [widgetData]="formWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>

<p-button (click)="showReportField()" label="Test"></p-button>


<h4>Test Widget</h4>

<mvs-widget
        [widgetData]="reportWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>




-->

<!--

<p>Report Table Widget</p>
<mvs-widget
        [widgetData]="tableReportWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>






<p>Chart Widget</p>
<mvs-widget
        [widgetData]="chartWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>







<p>List Widget</p>
<mvs-widget
        [widgetData]="listWidgetData"
        [importObjectContext]="importObjectContext"
></mvs-widget>
-->

<!--
<p>Test Mutable Area</p>

<mvs-mutable-area
    [importObjectContext]="importObjectContext"
    [defaultLayout]="mutableWidgetLayout"
>

</mvs-mutable-area>
-->
