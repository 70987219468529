import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";


@Component({
    selector: 'ci-insurable-object',
    templateUrl: './ci-contract-insurable-object.component.html',
})
export class CiContractInsurableObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {


    tabItems: MenuItem[];
    selectedTabItem: any;

    widgetContractInsurableObject: WidgetData;
    widgetInsurableObject: WidgetData;

    ngOnInit() {
        super.ngOnInit();

        this.tabItems = [
            { id : "ci.ContractObject", label : "Zuweisung", icon : "fa-light fa-link"},
            { id : "ci.InsurableObject", label : "Objekt", icon : "fa-light fa-circle"},
        ];

    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

        WidgetFactory.createWidgetObjectWithCallback(
            "ci.insurable.object.contract.form",
            "Vertragszuweisung",
            "ci.ContractInsurableObject",
            {
                onFunctionObjectDefaultNew(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    return null;
                }
            }
        );

    }

}
