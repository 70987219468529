<div *ngFor="let ticket of ticketList" class="flex align-items-center gap-3">
    <a
            class="underline cursor-pointer text-primary white-space-nowrap"
            [pTooltip]="tmStatusEnum[ticket.status].uiLabel + '\n' + ticket.assigneeAgentDtoName  + '\n' + (ticket.createdDate | MvsDateAgo)"
            tooltipPosition="top"
            (click)="openTicketDrawer(ticket.id)">
        {{ticket.alias}}
    </a>
    <i
            *ngIf="ticket.status"
            [class]="tmStatusEnum[ticket.status].uiImage"
    ></i>
</div>
