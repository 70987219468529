import {Component, OnInit} from '@angular/core';
import {MvsMessageService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-test-marko-sub',
  templateUrl: './test-marko-sub.page.html',
  styleUrls: ['./test-marko-sub.page.scss']
})
export class TestMarkoSubPage implements OnInit {

  step: number = 0;

  constructor(protected messageService: MvsMessageService) {
  }

  ngOnInit(): void {
    this.messageService.showSuccessMessage("Hi", "Hi there!");
  }



}
