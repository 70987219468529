<div class="user-group-avatar">
  <mvs-avatar-list
    [visibleUsers]="visibleLockedUsers"
    [remainingUserCount]="remainingLockedUserCount"
    [maxVisibleAvatars]="maxVisibleAvatars"
    [overlayUsers]="lockedUsers"
    [size]="size"
    (onUserSelected)="handleUserSelected($event)"
  ></mvs-avatar-list>

  <div class="divider" *ngIf="visibleLockedUsers.length > 0 && visibleOtherUsers.length > 0"></div>

  <mvs-avatar-list
    [visibleUsers]="visibleOtherUsers"
    [remainingUserCount]="remainingOtherUserCount"
    [maxVisibleAvatars]="maxVisibleAvatars"
    [overlayUsers]="otherUsers"
    (onUserSelected)="handleUserSelected($event)"
    [size]="size"
  ></mvs-avatar-list>
</div>
