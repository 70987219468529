<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-12" *ngIf="filterCriteria.length>0">
            <p-panel header="Filters">
                <div class="flex align-items-center gap-2 flex-wrap">
                    <p-chip
                            *ngFor="let filter of filterCriteria"
                            [label]="filter.field + ': ' + filter.label"
                            [removable]="true"
                            (onRemove)="onRemoveFilterCriteria(filter)"
                    ></p-chip>
                </div>
            </p-panel>
        </div>

        <div class="col-12 md:col-4">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetTicketType"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="onSetFilterCriteria($event, 'type')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-6 md:col-2">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetTicketStatus"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="onSetFilterCriteria($event, 'status')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-6 md:col-2">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetTicketUrgency"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="onSetFilterCriteria($event, 'urgency')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-6 md:col-2">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetTicketAgent"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="onSetFilterCriteria($event, 'assigneeAgent')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-6 md:col-2">
            <div class="mvs-widget h-full">
                <mvs-widget
                        [widgetData]="widgetTicketAgentPool"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="onSetFilterCriteria($event, 'assigneeAgentPool')"
                ></mvs-widget>
            </div>
        </div>

        <div class="col-12">
            <div class="mvs-widget">
                <mvs-widget
                        [widgetData]="widgetTicketTable"
                        [importObjectContext]="importObjectContextDto"
                        (onObjectSelect)="openTicketDrawer($event)"
                ></mvs-widget>
            </div>
        </div>

    </div>

</ng-container>