import { Component, OnInit } from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-om-department-page',
  templateUrl: './department.page.html',
  styleUrls: ['./department.page.scss']
})
export class DepartmentPage extends PageComponent {

  objectBrowserRequest: any;

  defaultLabel: string = "Abteilungen - Config";

  activeIndex = 0;
  objectDepartmentWidget: WidgetData;
  objectDataWidget: WidgetData;

  ngOnInit(): void {
    this.refreshObjectDepartmentWidget();
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      "entries": [
        {
          'id'                : 1,
          'objectAlias'       : 'om.Department',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Department",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
      ]

    }

    super.ngOnInit();
  }

  refreshObjectDepartmentWidget() {
    this.objectDepartmentWidget = WidgetFactory.createWidgetListEntity(
        'om.object.department.config.widget',
        'Department',
        'om.Department',
        'No Data',
        this.getObjectRequestList());
    this.objectDepartmentWidget.uiComponent = 'selectable';
  }



  getObjectRequestList() {
    return ObjectRequestList.createBasic(true, [], []);
  }

  handleObjectSelect(event: ObjectIdentifierData) {

    this.objectDataWidget = WidgetFactory.createWidgetEntityData(
        `${event.objectType}.om.object.department.widget`,
        '',
        event.objectType,
        event.objectId
    )

  }

  clearWidgets() {
    this.objectDataWidget = null;
  }
}
