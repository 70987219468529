import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-om-organization-unit-page',
  templateUrl: './organization-unit.page.html',
  styleUrls: ['./organization-unit.page.scss']
})
export class OrganizationUnitPage extends PageComponent {

  objectBrowserRequest: any;

  defaultLabel: string = 'Organization Units';

  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,

      "entries": [

        {
          'id'                : 1,
          'objectAlias'       : 'om.OrganizationalUnit',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Organisationseinheiten",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id'                : 1,
          'objectAlias'       : 'om.Position',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Planstellen",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id'                : 1,
          'objectAlias'       : 'om.EmployeePosition',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Zuordnung Mitarbeiter Planstelle",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },

      ]

    }

    super.ngOnInit();
  }
}
