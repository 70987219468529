import { HouseholdDto } from "./household.dto";
import { PersonDto } from "./person.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

export class HouseholdPersonDto extends DtoDetail {


    public householdDto? : HouseholdDto;
    public householdDtoId : number;
    public householdDtoName : string;
    public personDto? : PersonDto;
    public personDtoId : number;
    public personDtoName : string;
    public endDate: Date;

}