import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
  CrCustomerPhoneContactAvailabilityComponent
} from "../../component/cr-customer-phone-contact-availability/cr-customer-phone-contact-availability.component";
import {
  WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
  WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
  CrCustomerPhoneAvailabilityWfProcessStepActivityComponent
} from "../../component/wf/cr-customer-phone-availability-wf-process-step-activity/cr-customer-phone-availability-wf-process-step-activity.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class CustomerPhoneContactAvailabilityService extends MvsCrudService implements WfProcessStepActivityMainProviderInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerPhoneContactAvailabilitys')
  }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    return CrCustomerPhoneContactAvailabilityComponent;
  }

  getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
    return CrCustomerPhoneAvailabilityWfProcessStepActivityComponent;
  }
}