import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectPageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'ui-object-navigation-sub-page',
  templateUrl: './ui-object-navigation-sub.page.html',
  styleUrls: ['./ui-object-navigation-sub.page.scss']
})
export class UiObjectNavigationSubPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

  defaultLabel: string = "Navigation";


  protected getObjectType(): string {
    return "ui.UiNavigationSub";
  }

}
