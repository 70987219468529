import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TenantService} from "@kvers/alpha-auth";
import {TenantDto} from "@kvers/alpha-auth/lib/tenant/tenant.dto";
import {AppComponent} from "../../../app.component";
import {UiThemeInterface} from "./data/ui-theme-interface";
import {UiUserThemeConfigurations} from "./data/ui-user-theme-configurations";
import {UiSettingUserDto} from "../../dto/ui-setting-user.dto";
import {UiSettingUserService} from "../../service/api/ui-setting-user.service";
import {DtoDetail, MvsMessageService} from "@kvers/alpha-core-common";
@Component({
    selector: 'ui-setting-user',
    templateUrl: './ui-setting-user.component.html',
})
export class UiSettingUserComponent implements OnInit, OnChanges, OnDestroy {

    tenantInfo: TenantDto;
    uiDto = new UiUserThemeConfigurations();
    topbarColors: UiThemeInterface[] = [
        {name: 'lightblue', color: '#2E88FF'},
        {name: 'dark', color: '#363636'},
        {name: 'white', color: '#FDFEFF'},
        {name: 'blue', color: '#1565C0'},
        {name: 'deeppurple', color: '#4527A0'},
        {name: 'purple', color: '#6A1B9A'},
        {name: 'pink', color: '#AD1457'},
        {name: 'cyan', color: '#0097A7'},
        {name: 'teal', color: '#00796B'},
        {name: 'green', color: '#43A047'},
        {name: 'lightgreen', color: '#689F38'},
        {name: 'lime', color: '#AFB42B'},
        {name: 'yellow', color: '#FBC02D'},
        {name: 'amber', color: '#FFA000'},
        {name: 'orange', color: '#FB8C00'},
        {name: 'deeporange', color: '#D84315'},
        {name: 'brown', color: '#5D4037'},
        {name: 'grey', color: '#616161'},
        {name: 'bluegrey', color: '#546E7A'},
        {name: 'indigo', color: '#3F51B5'}
    ];

    @Output() onChange: EventEmitter<UiUserThemeConfigurations> = new EventEmitter<UiUserThemeConfigurations>();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    constructor(protected tenantService: TenantService,
                protected app: AppComponent,
                protected messageService: MvsMessageService,
                protected uiSettingUserService: UiSettingUserService,
                ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.tenantInfo = this.tenantService.getTenantInfo();
        const userSettingsJson = localStorage.getItem('ui.userSettings');

        if (userSettingsJson) {
            const userSettings : UiSettingUserDto = JSON.parse(userSettingsJson);
            this.uiDto = JSON.parse(userSettings.layoutJson);
        }

        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    changeTopbarTheme(theme: UiThemeInterface) {
        this.uiDto.topbarTheme = theme;
        this.app.topbarTheme = theme.name;
        this.handleChange();
    }

    handleChange() {
        this.onChange.emit(this.uiDto);
    }

    handleLogoChange(mode: string) {
        this.uiDto.topbarLogo = mode;

        const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;
        const appIcons: NodeListOf<HTMLElement> = document.querySelectorAll('.dark-topbar-icon');


        if (this.uiDto.topbarLogo == 'light') {

            appLogoLink.style.setProperty('filter', 'brightness(5)', 'important');

            appIcons.forEach(icon => {
                icon.style.setProperty('color', 'var(--surface-0)', 'important');
            });
        }
        else {
            appLogoLink.style.removeProperty('filter');
            appIcons.forEach(icon => {
                icon.style.setProperty('color', 'var(--surface-500)', 'important');
            });
        }
        this.handleChange();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        localStorage.removeItem('ui.userSettings');
    }
}
