@if (initialized) {

    @if (activeNavigationItem.action == 'calendar') {
        <mvs-agent-calendar
                *ngIf="agent"
                [agents]="[agent]"
                [agentIds]="[agent.id]"
        ></mvs-agent-calendar>
    }


    @if (activeNavigationItem.action == 'working-time') {
        <mvs-agent-schedule [agent]="agent" *ngIf="agent"></mvs-agent-schedule>
    }


    @if (activeNavigationItem.action == 'user') {

        <div class="flex justify-content-center p-2">
            <div class="login-box">
                <div class="surface-card p-4 border-round w-full">
                    <div class="text-center mb-5">
                        <div class="text-color text-3xl font-medium mb-2">User Profile</div>
                        <!--          <span class="text-color-secondary text-sm">Lade ein Profilbild hoch und erstelle deine Login-Daten.</span>-->
                    </div>
                    <div class="flex flex-column align-items-center flex-or">
                        <!--          <span class="text-color mb-2 text-sm">Profile Picture</span>-->
                        <div class="h-7rem w-7rem border-circle">
                            <img class="h-7rem w-7rem border-circle"
                                 [src]="imageSrc"/>
                        </div>
                        <div class="flex gap-2">
                            <input id="upload" type="file" class="none" hidden accept="image/*"
                                   (change)="onUploadHandler($event)"/>
                            <label for="upload"
                                   class="p-element p-button-rounded p-button-text surface-500 w-2rem h-2rem -mt-3 p-button p-component p-button-icon-only">
                                <span class="p-button-icon text-sm pi pi-upload text-white" aria-hidden="true"></span>
                            </label>

                            <label for="delete"
                                   class="p-element p-button-rounded p-button-text  surface-500 w-2rem h-2rem -mt-3 p-button p-component p-button-icon-only"
                                   *ngIf="imageSrc && !isSvg">
                            <span id="delete" (click)="handleDeleteImage()"
                                  class="p-button-icon text-sm pi pi-trash text-white"></span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <mvs-widget *ngIf="userWidget" [readOnly]="true" [widgetData]="userWidget"></mvs-widget>

        <p-dialog
                header="Bild anpassen"
                [(visible)]="showImageCropperDialog"
                [modal]="true"
                [style]="{width: '50rem', height: '50rem'}"
                (onHide)="resetFileInput()"
        >
            <ng-template pTemplate="content">
                <div class="flex align-items-center h-full">
                    <image-cropper
                            *ngIf="imageChangedEvent"
                            [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true"
                            [aspectRatio]="1/1"
                            format="png"
                            (imageCropped)="imageCropped($event)">
                    </image-cropper>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton label="Übernehmen" icon="pi pi-check"
                        (click)="uploadCroppedImage()"></button>
            </ng-template>
        </p-dialog>

    }


    @if (activeNavigationItem.action == 'agent') {


        <div class="grid">

            <div class="col-12">

                <mvs-crud-object-generic
                        *ngIf="agent?.id"
                        [objectType]="'am.Agent'"
                        [objectId]="agent.id"
                        [formControlOverwrite]="formControlOverwrite"
                        [removeDelete]="true"

                ></mvs-crud-object-generic>
            </div>
<!--            <div class="col-12">-->

<!--                <mvs-widget *ngIf="agentWidget" [widgetData]="agentWidget"></mvs-widget>-->

<!--            </div>-->
            <div class="col-12">

                <mvs-widget *ngIf="agentPoolAgentWidget" [readOnly]="true"
                            [widgetData]="agentPoolAgentWidget"></mvs-widget>

            </div>
        </div>


    }


}
