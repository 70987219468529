import {
    MvsFormFieldAgentPoolAgentComponent
} from "../input/mvs-form-field-agent-pool-agent/mvs-form-field-agent-pool-agent.component";
import {
    MvsUserObjectAccessOutputComponent
} from "../output/mvs-user-object-access-output/mvs-user-object-access-output.component";
import {
    MvsUserObjectAccessOutputConfigComponent
} from "../output/mvs-user-object-access-output/mvs-user-object-access-output-config/mvs-user-object-access-output-config.component";
import {MvsTicketCountOutputComponent} from "../output/mvs-user-ticket-count-output/mvs-ticket-count-output.component";
import {
    MvsTicketCountOutputConfigComponent
} from "../output/mvs-user-ticket-count-output/mvs-ticket-count-output-config/mvs-ticket-count-output-config.component";
import {
    MvsFormFieldOutputUserImageComponent
} from "../output/mvs-form-field-output-user-image/mvs-form-field-output-user-image.component";
import {
    MvsCreateTicketOutputConfigComponent
} from "../output/mvs-create-ticket-output/mvs-create-ticket-output-config/mvs-create-ticket-output-config.component";
import {MvsCreateTicketOutputComponent} from "../output/mvs-create-ticket-output/mvs-create-ticket-output.component";
import {
    MvsLinkReportOutputFieldConfigComponent
} from "../output/mvs-link-report-output-field/mvs-link-report-output-field-config/mvs-link-report-output-field-config.component";
import {
    MvsLinkReportOutputFieldComponent
} from "../output/mvs-link-report-output-field/mvs-link-report-output-field.component";
import {
    MvsFormFieldTinyMceEditorComponent
} from "../input/mvs-form-field-tiny-mce-editor/mvs-form-field-tiny-mce-editor.component";
import {
    MvsNavigateToNewWindowOutputComponent
} from "../output/mvs-navigate-to-new-window-output/mvs-navigate-to-new-window-output.component";
import {
    MvsNavigateToNewWindowOutputConfigComponent
} from "../output/mvs-navigate-to-new-window-output/mvs-navigate-to-new-window-output-config/mvs-navigate-to-new-window-output-config.component";
import {MvsOneToManyOutputComponent} from "../output/mvs-one-to-many-output/mvs-one-to-many-output.component";
import {
    MvsOneToManyOutputConfigComponent
} from "../output/mvs-one-to-many-output/mvs-one-to-many-output-config/mvs-one-to-many-output-config.component";
import {MvsFormFieldOneToManyComponent} from "../input/mvs-form-field-one-to-many/mvs-form-field-one-to-many.component";
import {
    MvsFormFieldOneToManyConfigComponent
} from "../input/mvs-form-field-one-to-many/mvs-form-field-one-to-many-config/mvs-form-field-one-to-many-config.component";
import {
    MvsOneToManyCountOutputComponent
} from "../output/mvs-one-to-many-count-output/mvs-one-to-many-count-output.component";
import {
    MvsOneToManyCountOutputConfigComponent
} from "../output/mvs-one-to-many-count-output/mvs-one-to-many-count-output-config/mvs-one-to-many-count-output-config.component";

export const additionalInputFields = new Map<string, { component: any,configComponent?: any }>([
    ['agentPoolAgent', { component: MvsFormFieldAgentPoolAgentComponent }],
    ['tinyMceEditor', { component: MvsFormFieldTinyMceEditorComponent }],
    ['oneToMany', { component: MvsFormFieldOneToManyComponent, configComponent: MvsFormFieldOneToManyConfigComponent }],
]);

export const additionalOutputFields = new Map<string, { component: any, label: string, visibility?: string, configComponent?: any }>([

    ['userObjectAccess', {component: MvsUserObjectAccessOutputComponent, label: "User ObjectAccess Output", configComponent: MvsUserObjectAccessOutputConfigComponent}],
    ['ticketCount', {component: MvsTicketCountOutputComponent, label: "Ticket Count", configComponent: MvsTicketCountOutputConfigComponent}],
    ['createTicket', {component: MvsCreateTicketOutputComponent, label: "Create Ticket", configComponent: MvsCreateTicketOutputConfigComponent}],
    ['linkReport', {component: MvsLinkReportOutputFieldComponent, label: "Link Report", configComponent: MvsLinkReportOutputFieldConfigComponent}],
    ['navigateTo', {component: MvsNavigateToNewWindowOutputComponent, label: "Navigate To", configComponent: MvsNavigateToNewWindowOutputConfigComponent}],
    ['oneToMany', {component: MvsOneToManyOutputComponent, label: "One To Many", configComponent: MvsOneToManyOutputConfigComponent}],
    ['oneToManyCount', {component: MvsOneToManyCountOutputComponent, label: "One To Many Count", configComponent: MvsOneToManyCountOutputConfigComponent}],
    ['userImage', {component: MvsFormFieldOutputUserImageComponent, label: "User Image"}],
    ['userInitials', {component: MvsFormFieldOutputUserImageComponent, label: "User Initials"}],


]);