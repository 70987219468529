import {Component, OnInit} from "@angular/core";
import {ValueListBaseComponent} from "../value-list-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'value-list-side',
    templateUrl: 'value-list-side.component.html',
})

export class ValueListSideComponent extends ValueListBaseComponent implements OnInit {

    // navigationItems: NavigationItem[] = [
    //     {
    //         label: 'valueList',
    //         action: 'valueList',
    //         icon: 'fa-regular fa-info-circle',
    //         tooltip: 'Value List',
    //         default: true
    //     },
    //     {
    //         label: 'valueListEntries',
    //         action: 'valueListEntries',
    //         icon: 'fa-solid fa-list',
    //         tooltip: 'Value List Entries',
    //         default: false
    //     }
    // ];

    ngOnInit() {
        super.ngOnInit();
    }
}