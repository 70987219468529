@if (initialized) {
    <p-card styleClass="shadow-none">
        <ng-template pTemplate="title">
            <div class="flex justify-content-between">
                <h5 class="font-medium">{{ dto?.appointmentTypeDtoName }}</h5>
                <i class="pi pi-external-link"></i>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
<!--            <mvs-widget *ngIf="appointmentWidgetData" [widgetData]="appointmentWidgetData"></mvs-widget>-->

            <h3 class="mb-4 mt-1">Ticket Alias/Appointment Name</h3>

            <div class="flex flex-column gap-5 text-color-secondary">
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-calendar"></i>
                    <span>Wed 15.01.2025</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-clock"></i>
                    <span>21:00 - 21:45</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-map-marker"></i>
                    <span>Address here...</span>
                </div>
            </div>

            <div class="grid my-4">
                <div class="col-6">
                    <div class="border-round-sm p-1"
                         style="background: var(--bluegray-50);">
                        <mvs-inline-component
                                [name]="'Ticket ID'"
                                [startDate]="20.01.24"
                                [status]="'Aktiv'">
                        </mvs-inline-component>
                    </div>
                </div>
                <div class="col-6">
                    <div class="border-round-sm p-1"
                         style="background: var(--bluegray-50);">
                        <mvs-inline-component
                                [name]="'Ticket ID'"
                                [startDate]="20.01.24"
                                [status]="'Aktiv'">
                        </mvs-inline-component>
                    </div>
                </div>
            </div>


            <!--        <div class="flex align-items-center gap-2  justify-content-between p-3 surface-200 border-round col-6 my-4">-->
            <!--            <div class="flex gap-2">-->
            <!--                <i class="pi pi-ticket text-xl"></i>-->
            <!--                <div class="flex flex-column">-->
            <!--                    <span class="font-medium">Ticket ID</span>-->
            <!--                    <span class="text-color-secondary">{{ '01.10.24' }}</span>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <p-tag styleClass="primary-700 border-round-lg" class="">{{ 'Active' }}</p-tag>-->
            <!--        </div>-->

            <div class="border-1 border-200 p-3">
                <div class="flex gap-3 mb-4">
                    <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png" class="mr-2" size="large" shape="circle" />
                    <div class="flex flex-column">
                        <h5 class="font-medium mb-0">{{ 'Richer Conely' }}</h5>
                        <span class="text-color-secondary">ID: {{ 'ID:00213' }}</span>
                    </div>
                </div>

                <div class="px-2">
                    <div class="flex flex-wrap mb-4">
                        <span class="text-color-secondary w-15rem">Mobile:</span>
                        <span class="font-medium">{{ '0021549111' }}</span>
                    </div>
                    <div class="flex flex-wrap">
                        <span class="text-color-secondary w-15rem">Email Address</span>
                        <span class="font-medium">{{ 'companyemail@exmp.com' }}</span>
                    </div>
                </div>

            </div>
        </ng-template>
    </p-card>
}