<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="ns.NsNotificationType"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->

<div class="bg-white">
    <mvs-config-header header="Notification Configuration" [categoryTypeMode]="'notification'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationGroup'">
            <mvs-widget *ngIf="nsNotificationGroupWidget"
                        [widgetData]="nsNotificationGroupWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationType'">
            <mvs-widget *ngIf="nsNotificationTypeWidget"
                        [widgetData]="nsNotificationTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationTypeChannelType'">
            <mvs-widget *ngIf="nsNotificationTypeChannelWidget"
                        [widgetData]="nsNotificationTypeChannelWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ns.MicrosoftTeamsConfiguration'">
            <mvs-widget *ngIf="nsMicrosoftTeamsConfiguration"
                        [widgetData]="nsMicrosoftTeamsConfiguration">
            </mvs-widget>
        </ng-container>

    </ng-container>

</div>