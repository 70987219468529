<div class="grid">
    <div class="col-3">
        <p-tree
                *ngIf="nodes?.length > 0"
                class="w-full"
                selectionMode="single"
                scrollHeight="80vh"
                [(selection)]="selectedNode"
                [value]="nodes"
                [lazy]="true"
                (onNodeExpand)="handleNodeExpand($event)"
                (onNodeSelect)="onNodeSelect()"
        ></p-tree>
    </div>
    <div class="col-9">

        <mvs-widget
                *ngIf="emSchemaStructureDataWidget"
                [widgetData]="emSchemaStructureDataWidget"
        ></mvs-widget>

        <mvs-widget
                *ngIf="emSchemaStructureListWidget"
                [widgetData]="emSchemaStructureListWidget"
        ></mvs-widget>
    </div>
</div>