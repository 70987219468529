import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent} from "@kvers/alpha-ui";
import {OverviewDto} from "../../dto/overview.dto";
import {Router} from "@angular/router";
import {KpiHeaderDto} from "../../dto/kpi-header.dto";

@Component({
    selector: 'overview',
    templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;
    initialized: boolean;


    // @Input() pendingNotification: number = 0;
    // @Input() processedNotification: number = 0;
    // @Input() deletedNotification: number = 0;
    // @Input() errorNotification: number = 0;
    @Input() itemsArray: OverviewDto[];

    constructor(private router: Router,) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
