<!--<mvs-crud-page [objectStructureRequest]="objectBrowserRequest"-->
<!--               [defaultLabel]="defaultLabel">-->
<!--</mvs-crud-page>-->


<p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidgets()">
    <p-tabPanel header="Customer Types">
        <ng-container *ngIf="activeIndex == 0">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerTypeWidget"
                            [widgetData]="customerTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>
    </p-tabPanel>
    <p-tabPanel header="Related Person Types">
        <ng-container *ngIf="activeIndex == 1">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerRelatedPersonTypeWidget"
                            [widgetData]="customerRelatedPersonTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>

        </ng-container>

    </p-tabPanel>

</p-tabView>