import {Component, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-lg-calculation-logic-type-object-component',
    templateUrl: './lg-calculation-logic-type-object.component.html',
    styleUrls: ['./lg-calculation-logic-type-object.component.scss']
})
export class LgCalculationLogicTypeObjectComponent
    extends ObjectBaseComponent {

    defaultLabel: string = "Calculation Logic Type";


    ngOnInit() {
        super.ngOnInit();
    }

    onObjectChanged() {
        this.busy = false;
        this.setPageTitle(this.dto.name);
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

    }


}
