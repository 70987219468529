import { Component, OnInit } from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-cr-customer-signature-type-page',
  templateUrl: './customer-signature-type-page.page.html',
  styleUrls: ['./customer-signature-type-page.page.scss']
})
export class CustomerSignatureTypePagePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Customer Signature Configuration";


  activeIndex = 0;

  customerSignatureTypeWidget: WidgetData;
  objectDataWidget: WidgetData;

  ngOnInit(): void {

    super.ngOnInit();

    this.refreshCustomerSignatureTypeWidget();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerSignatureType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Signature Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Signature Type"
        }
      ]
    };

    this.subObjectBrowserRequest = null;

  }

  refreshCustomerSignatureTypeWidget() {
    this.customerSignatureTypeWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.onboarding.type.info.step.widget',
        'Signature Types',
        'cr.CustomerSignatureType',
        'No Data',
        this.getObjectRequestList());
    this.customerSignatureTypeWidget.uiComponent = 'selectable';
  }


  getObjectRequestList() {
    return ObjectRequestList.createBasic(true, [], []);
  }

  handleObjectSelect(event: ObjectIdentifierData) {

    this.objectDataWidget = WidgetFactory.createWidgetEntityData(
        `${event.objectType}.cr.customer.signature.type.widget`,
        '',
        event.objectType,
        event.objectId
    )

  }

  clearWidgets() {
    this.objectDataWidget = null;
  }
}
