

<div class="grid p-fluid">

    <div class="col-4">
        <mvs-widget
                [widgetData]="widgetImport"
        ></mvs-widget>
    </div>

    <div class="col-8">
        <p-button label="Alle Datensätze herunterladen" (click)="onDownloadImportRecords()" [disabled]="busyDownloading"><p-progressSpinner *ngIf="busyDownloading" [style]="{width: '20px', height: '20px'}" strokeWidth="8"></p-progressSpinner></p-button>

        <mvs-widget
                [widgetData]="widgetImportRecords"
                (onObjectSelect)="onImportRecordSelected($event)"
        ></mvs-widget>

        <mvs-widget
                [widgetData]="widgetImportErrorRecords"
                (onObjectSelect)="onImportRecordSelected($event)"
        ></mvs-widget>

    </div>
</div>
