import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "@kvers/alpha-auth";
import {HttpErrorInterceptor} from "./error/http.error.intercepter";
import {CircuitBreakerInterceptor} from "./circuit-breaker/circuit.breaker.intercepter";
import {ActivityContextInterceptor} from "./activity-context/interceptor/activity-context.interceptor";

export const coreInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ActivityContextInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: BatchInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CircuitBreakerInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
]
