import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail, DtoList, DtoTemplate, EntityStatusEnum, FilterCriteria,
    MvsCoreService,
    MvsFormValueListEntryDto,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation,
    PageComponent,
    PagingDto, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {CustomerDto} from "../../../../cr/dto/customer.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {AppointmentSelectionDto} from "../../../../as/dto/appointment-selection.dto";
import {TmTicketDto} from "../../../../tm/dto/tm-ticket.dto";
import {TmEntityStatusEnum} from "../../../../tm/enum/tm-entity-status.enum";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {TmUrgencyEnum} from "../../../../tm/enum/tm-urgency.enum";
import {AppointmentDto} from "../../../../as/dto/appointment.dto";
import {AppointmentStatusInternal} from "../../../../as/enum/appointment-status-internal.enum";
import {Address} from "../../../../ad/dto/address";
import {AppointmentRequestUrgencyEnum} from "../../../../as/enum/appointment-request-urgency.enum";
import {AgentService} from "../../../service/api/agent.service";
import {TicketAppointmentService} from "../../../../tm/service/api/ticket-appointment.service";
import {TmTicketAppointmentDto} from "../../../../tm/dto/tm-ticket-appointment.dto";


@Component({
    selector: 'am-customer-appointment-page',
    templateUrl: './am-customer-appointment.page.html',
    styleUrl: "am-customer-appointment.page.scss"
})
export class AmCustomerAppointmentPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    ticketAppointmentWidget: WidgetData;
    customersCoords: { coordinates: [number, number], dto: DtoDetail, initials: string }[];
    items: MenuItem[] | undefined;
    statusList: { id: number, name: string }[] | undefined;
    selectedStatus: MvsFormValueListEntryDto;
    entityStatus: MvsFormValueListEntryDto[];
    testAgents: { coordinates: [number, number], dto: DtoDetail, initials: string }[] = [];
    showSideDrawer: boolean = false;

    appointmentSelectionDtoList: (Partial<AppointmentSelectionDto>)[];

    ticketAppointmentList: DtoList<TmTicketAppointmentDto>;

    constructor(
        protected agentService: AgentService,
        protected customerService: CustomerService,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected router: Router,
        protected ticketAppointmentService: TicketAppointmentService
    ) {
        super(route, coreService)
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();

        this.getTicketAppointments()
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.items = [
            {
                label: 'Filters',
                items: [
                    {
                        label: 'Status',
                        icon: 'pi pi-refresh'
                    },
                    {
                        label: 'History',
                        icon: 'pi pi-upload'
                    },
                    {
                        label: 'Tags',
                        icon: 'pi pi-upload'
                    },
                    {
                        label: 'Last Contacted',
                        icon: 'pi pi-upload'
                    }
                ]
            }
        ];

        this.statusList = [
            {name: 'New York', id: 0},
            {name: 'Rome', id: 1},
            {name: 'London', id: 2},
            {name: 'Istanbul', id: 3},
            {name: 'Paris', id: 4}
        ];

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshCustomer() {

        // const entries: DtoDetail[] = [];
        // for (let ticketAppointment of this.ticketAppointmentList.entries) {
        //     if (ticketAppointment.ticketDto['customer']) {
        //         entries.push(ticketAppointment.ticketDto['customer'])
        //     }
        // }


        // this.customerService.template(new DtoTemplate()).subscribe(res => {

            const dtoList = DtoList.createFromExternal(this.ticketAppointmentList.entries, this.ticketAppointmentList.form);

            this.ticketAppointmentWidget = WidgetFactory.createWidgetTransient(
                'ticket.appointment.list.transient.widget',
                "Appointments",
                'list',
                'transient',
                'entity',
                'tm.TicketAppointment',
                dtoList,
            );

            // this.geocodeAddress()

            this.refreshComponent()
        // })

    }

    handleObjectSelect(object: ObjectIdentifier | ObjectIdentifierData) {
        this.router.navigate([`am/customer-appointment-detail/${object.objectId}`])
    }

    geocodeAddress(): void {

        for (let appointmentSelection of this.appointmentSelectionDtoList) {

            if (!this.customersCoords) {
                this.customersCoords = [];
            }

            this.customersCoords.push({
                coordinates: [appointmentSelection.address.geoLongitude, appointmentSelection.address.geoLatitude],
                dto: appointmentSelection as AppointmentSelectionDto,
                initials: this.getInitials(appointmentSelection.customer.personDtoName)
            });

        }
    }

    /**
     * Function to extract initials from a full name.
     * If the number of initials exceeds 2, it returns the first 2 initials.
     *
     * @param name - The full name from which to extract initials.
     * @returns A string containing up to 2 initials.
     */
    getInitials(name: string): string {
        if (!name) return '';

        // Split the name into words and filter out any empty strings
        const words = name.split(' ').filter(word => word.length > 0);

        // Map each word to its first letter, then join the first two initials
        const initials = words.map(word => word[0].toUpperCase()).join('');

        // Return only the first two initials
        return initials.substring(0, 2);
    }

    handleSideDrawer() {
        this.showSideDrawer = !this.showSideDrawer;
    }

    getTicketAppointments() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,

                ObjectRequestComplexNode.createSimpleAttributeNode("ticket")
                    .addNodes(ObjectRequestComplexNode.createRelationNode("customer", ObjectRequestRelation.createJoin("+objectLatestCustomer"))
                        .addNodes(ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress")))),
                ObjectRequestComplexNode.createSimpleAttributeNode("appointment")
            ),
            [],
            [new Sorting("createdDate", false)],
            null
        );

        this.ticketAppointmentService.list(objectRequest).subscribe(res => {
            this.ticketAppointmentList = res;

            this.refreshCustomer();

        })
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    getAppointmentSelections(): (Partial<AppointmentSelectionDto>)[] {

        const mockTickets: (Partial<TmTicketDto>)[] = [
            {
                id: 1,
                entityStatus: TmEntityStatusEnum.active,
                alias: 'Ticket Alias 1',
                name: 'Sample Ticket 1',
                typeDtoId: 1,
                typeDtoName: 'Type 1',
                typeDtoImage: 'image1.png',
                typeDtoColor: '#FF5733',
                assigneeAgentPoolDtoId: 2,
                assigneeAgentPoolDtoName: 'Agent Pool 1',
                assigneeAgentPoolDtoImage: 'pool-image1.png',
                assigneeAgentPoolDtoColor: '#33FF57',
                assigneeAgentDtoId: 3,
                assigneeAgentDtoName: 'Assignee 1',
                assigneeAgentDtoImage: 'assignee-image1.png',
                assigneeAgentDtoColor: '#3357FF',
                referenceCustomerDtoId: 4,
                referenceCustomerDtoName: 'Customer 1',
                initiatorObjectTypeDtoId: 5,
                initiatorObjectTypeDtoName: 'Object Type 1',
                initiatorObjectTypeDtoImage: 'object-type-image1.png',
                initiatorObjectTypeDtoColor: '#FF3357',
                initiatorObjectId: 6,
                status: TmStatusEnum.assigned,
                urgency: TmUrgencyEnum.high,
                dueDate: new Date(),
                incomingCommunicationChannelDtoId: 7,
                incomingCommunicationChannelDtoName: 'Channel 1',
                incomingCommunicationChannelDtoImage: 'channel-image1.png',
                incomingCommunicationChannelDtoColor: '#57FF33',
                actionCount: 10,
                description: 'Sample Description 1',
                typeDto: undefined,
                ticketObject: []
            },
            {
                id: 2,
                entityStatus: TmEntityStatusEnum.active,
                alias: 'Ticket Alias 2',
                name: 'Sample Ticket 2',
                typeDtoId: 2,
                typeDtoName: 'Type 2',
                typeDtoImage: 'image2.png',
                typeDtoColor: '#FF8C00',
                assigneeAgentPoolDtoId: 3,
                assigneeAgentPoolDtoName: 'Agent Pool 2',
                assigneeAgentPoolDtoImage: 'pool-image2.png',
                assigneeAgentPoolDtoColor: '#8CFF33',
                assigneeAgentDtoId: 4,
                assigneeAgentDtoName: 'Assignee 2',
                assigneeAgentDtoImage: 'assignee-image2.png',
                assigneeAgentDtoColor: '#3399FF',
                referenceCustomerDtoId: 5,
                referenceCustomerDtoName: 'Customer 2',
                initiatorObjectTypeDtoId: 6,
                initiatorObjectTypeDtoName: 'Object Type 2',
                initiatorObjectTypeDtoImage: 'object-type-image2.png',
                initiatorObjectTypeDtoColor: '#FF3399',
                initiatorObjectId: 7,
                status: TmStatusEnum.assigned,
                urgency: TmUrgencyEnum.medium,
                dueDate: new Date(),
                incomingCommunicationChannelDtoId: 8,
                incomingCommunicationChannelDtoName: 'Channel 2',
                incomingCommunicationChannelDtoImage: 'channel-image2.png',
                incomingCommunicationChannelDtoColor: '#33FF99',
                actionCount: 5,
                description: 'Sample Description 2',
                typeDto: undefined,
                ticketObject: []
            },
            {
                id: 3,
                entityStatus: TmEntityStatusEnum.active,
                alias: 'Ticket Alias 3',
                name: 'Sample Ticket 3',
                typeDtoId: 3,
                typeDtoName: 'Type 3',
                typeDtoImage: 'image3.png',
                typeDtoColor: '#FFD700',
                assigneeAgentPoolDtoId: 4,
                assigneeAgentPoolDtoName: 'Agent Pool 3',
                assigneeAgentPoolDtoImage: 'pool-image3.png',
                assigneeAgentPoolDtoColor: '#FFFF33',
                assigneeAgentDtoId: 5,
                assigneeAgentDtoName: 'Assignee 3',
                assigneeAgentDtoImage: 'assignee-image3.png',
                assigneeAgentDtoColor: '#FF33FF',
                referenceCustomerDtoId: 6,
                referenceCustomerDtoName: 'Customer 3',
                initiatorObjectTypeDtoId: 7,
                initiatorObjectTypeDtoName: 'Object Type 3',
                initiatorObjectTypeDtoImage: 'object-type-image3.png',
                initiatorObjectTypeDtoColor: '#33FFCC',
                initiatorObjectId: 8,
                status: TmStatusEnum.assigned,
                urgency: TmUrgencyEnum.low,
                dueDate: new Date(),
                incomingCommunicationChannelDtoId: 9,
                incomingCommunicationChannelDtoName: 'Channel 3',
                incomingCommunicationChannelDtoImage: 'channel-image3.png',
                incomingCommunicationChannelDtoColor: '#3366FF',
                actionCount: 8,
                description: 'Sample Description 3',
                typeDto: undefined,
                ticketObject: []
            },
            {
                id: 4,
                entityStatus: TmEntityStatusEnum.active,
                alias: 'Ticket Alias 4',
                name: 'Sample Ticket 4',
                typeDtoId: 4,
                typeDtoName: 'Type 4',
                typeDtoImage: 'image4.png',
                typeDtoColor: '#800080',
                assigneeAgentPoolDtoId: 5,
                assigneeAgentPoolDtoName: 'Agent Pool 4',
                assigneeAgentPoolDtoImage: 'pool-image4.png',
                assigneeAgentPoolDtoColor: '#FFC0CB',
                assigneeAgentDtoId: 6,
                assigneeAgentDtoName: 'Assignee 4',
                assigneeAgentDtoImage: 'assignee-image4.png',
                assigneeAgentDtoColor: '#00FFFF',
                referenceCustomerDtoId: 7,
                referenceCustomerDtoName: 'Customer 4',
                initiatorObjectTypeDtoId: 8,
                initiatorObjectTypeDtoName: 'Object Type 4',
                initiatorObjectTypeDtoImage: 'object-type-image4.png',
                initiatorObjectTypeDtoColor: '#FF69B4',
                initiatorObjectId: 9,
                status: TmStatusEnum.assigned,
                urgency: TmUrgencyEnum.high,
                dueDate: new Date(),
                incomingCommunicationChannelDtoId: 10,
                incomingCommunicationChannelDtoName: 'Channel 4',
                incomingCommunicationChannelDtoImage: 'channel-image4.png',
                incomingCommunicationChannelDtoColor: '#FFA500',
                actionCount: 3,
                description: 'Sample Description 4',
                typeDto: undefined,
                ticketObject: []
            },
            {
                id: 5,
                entityStatus: TmEntityStatusEnum.active,
                alias: 'Ticket Alias 5',
                name: 'Sample Ticket 5',
                typeDtoId: 5,
                typeDtoName: 'Type 5',
                typeDtoImage: 'image5.png',
                typeDtoColor: '#ADD8E6',
                assigneeAgentPoolDtoId: 6,
                assigneeAgentPoolDtoName: 'Agent Pool 5',
                assigneeAgentPoolDtoImage: 'pool-image5.png',
                assigneeAgentPoolDtoColor: '#800000',
                assigneeAgentDtoId: 7,
                assigneeAgentDtoName: 'Assignee 5',
                assigneeAgentDtoImage: 'assignee-image5.png',
                assigneeAgentDtoColor: '#FFFF00',
                referenceCustomerDtoId: 8,
                referenceCustomerDtoName: 'Customer 5',
                initiatorObjectTypeDtoId: 9,
                initiatorObjectTypeDtoName: 'Object Type 5',
                initiatorObjectTypeDtoImage: 'object-type-image5.png',
                initiatorObjectTypeDtoColor: '#FF4500',
                initiatorObjectId: 10,
                status: TmStatusEnum.assigned,
                urgency: TmUrgencyEnum.medium,
                dueDate: new Date(),
                incomingCommunicationChannelDtoId: 11,
                incomingCommunicationChannelDtoName: 'Channel 5',
                incomingCommunicationChannelDtoImage: 'channel-image5.png',
                incomingCommunicationChannelDtoColor: '#7FFF00',
                actionCount: 6,
                description: 'Sample Description 5',
                typeDto: undefined,
                ticketObject: []
            }
        ];

        const mockAppointments: (Partial<AppointmentDto>)[] = [
            {
                id: 1,
                name: 'Sample Appointment 1',
                appointmentTypeDto: undefined,
                appointmentTypeDtoId: 1,
                appointmentTypeDtoName: 'Type Name 1',
                scheduleTypeDto: undefined,
                scheduleTypeDtoId: 2,
                scheduleTypeDtoName: 'Schedule Name 1',
                aliasId: 'Alias123',
                status: AppointmentStatusInternal.scheduled,
                localDateTime: new Date(),
                durationDtoId: 30,
                durationDtoName: '30 Minutes',
                msBookingDto: undefined,
                msBookingDtoId: 3,
                msBookingDtoName: 'Booking Name 1'
            },
            {
                id: 2,
                name: 'Sample Appointment 2',
                appointmentTypeDto: undefined,
                appointmentTypeDtoId: 2,
                appointmentTypeDtoName: 'Type Name 2',
                scheduleTypeDto: undefined,
                scheduleTypeDtoId: 3,
                scheduleTypeDtoName: 'Schedule Name 2',
                aliasId: 'Alias456',
                status: AppointmentStatusInternal.completed,
                localDateTime: new Date(),
                durationDtoId: 60,
                durationDtoName: '60 Minutes',
                msBookingDto: undefined,
                msBookingDtoId: 4,
                msBookingDtoName: 'Booking Name 2'
            },
            {
                id: 3,
                name: 'Sample Appointment 3',
                appointmentTypeDto: undefined,
                appointmentTypeDtoId: 3,
                appointmentTypeDtoName: 'Type Name 3',
                scheduleTypeDto: undefined,
                scheduleTypeDtoId: 4,
                scheduleTypeDtoName: 'Schedule Name 3',
                aliasId: 'Alias789',
                status: AppointmentStatusInternal.completed,
                localDateTime: new Date(),
                durationDtoId: 90,
                durationDtoName: '90 Minutes',
                msBookingDto: undefined,
                msBookingDtoId: 5,
                msBookingDtoName: 'Booking Name 3'
            },
            {
                id: 4,
                name: 'Sample Appointment 4',
                appointmentTypeDto: undefined,
                appointmentTypeDtoId: 4,
                appointmentTypeDtoName: 'Type Name 4',
                scheduleTypeDto: undefined,
                scheduleTypeDtoId: 5,
                scheduleTypeDtoName: 'Schedule Name 4',
                aliasId: 'Alias101112',
                status: AppointmentStatusInternal.cancelled,
                localDateTime: new Date(),
                durationDtoId: 120,
                durationDtoName: '120 Minutes',
                msBookingDto: undefined,
                msBookingDtoId: 6,
                msBookingDtoName: 'Booking Name 4'
            },
            {
                id: 5,
                name: 'Sample Appointment 5',
                appointmentTypeDto: undefined,
                appointmentTypeDtoId: 5,
                appointmentTypeDtoName: 'Type Name 5',
                scheduleTypeDto: undefined,
                scheduleTypeDtoId: 6,
                scheduleTypeDtoName: 'Schedule Name 5',
                aliasId: 'Alias131415',
                status: AppointmentStatusInternal.scheduled,
                localDateTime: new Date(),
                durationDtoId: 150,
                durationDtoName: '150 Minutes',
                msBookingDto: undefined,
                msBookingDtoId: 7,
                msBookingDtoName: 'Booking Name 5'
            }
        ];

        const mockCustomers: (Partial<CustomerDto>)[] = [
            {
                id: 1,
                alias: 'Customer Alias 1',
                startDate: new Date(),
                endDate: new Date(),
                customerTypeDto: undefined,
                customerTypeDtoId: 1,
                customerTypeDtoName: 'Type Name 1',
                entityStatus: EntityStatusEnum.active,
                customerConsultingTypeDto: undefined,
                customerConsultingTypeDtoId: 2,
                customerConsultingTypeDtoName: 'Consulting Type 1',
                removeFromActionsUntil: new Date(),
                removeFromActionsUntilReason: 'Reason 1',
                personDto: undefined,
                personDtoId: 1,
                personDtoName: 'Person Name 1',
                registrationDtoId: 1,
                registrationDtoName: 'Registration Name 1',
                addresses: [],
                user: undefined,
                customerOnboarding: undefined,
                customerContracts: []
            },
            {
                id: 2,
                alias: 'Customer Alias 2',
                startDate: new Date(),
                endDate: new Date(),
                customerTypeDto: undefined,
                customerTypeDtoId: 2,
                customerTypeDtoName: 'Type Name 2',
                entityStatus: EntityStatusEnum.active,
                customerConsultingTypeDto: undefined,
                customerConsultingTypeDtoId: 3,
                customerConsultingTypeDtoName: 'Consulting Type 2',
                removeFromActionsUntil: new Date(),
                removeFromActionsUntilReason: 'Reason 2',
                personDto: undefined,
                personDtoId: 2,
                personDtoName: 'Person Name 2',
                registrationDtoId: 2,
                registrationDtoName: 'Registration Name 2',
                addresses: [],
                user: undefined,
                customerOnboarding: undefined,
                customerContracts: []
            },
            {
                id: 3,
                alias: 'Customer Alias 3',
                startDate: new Date(),
                endDate: new Date(),
                customerTypeDto: undefined,
                customerTypeDtoId: 3,
                customerTypeDtoName: 'Type Name 3',
                entityStatus: EntityStatusEnum.active,
                customerConsultingTypeDto: undefined,
                customerConsultingTypeDtoId: 4,
                customerConsultingTypeDtoName: 'Consulting Type 3',
                removeFromActionsUntil: new Date(),
                removeFromActionsUntilReason: 'Reason 3',
                personDto: undefined,
                personDtoId: 3,
                personDtoName: 'Person Name 3',
                registrationDtoId: 3,
                registrationDtoName: 'Registration Name 3',
                addresses: [],
                user: undefined,
                customerOnboarding: undefined,
                customerContracts: []
            },
            {
                id: 4,
                alias: 'Customer Alias 4',
                startDate: new Date(),
                endDate: new Date(),
                customerTypeDto: undefined,
                customerTypeDtoId: 4,
                customerTypeDtoName: 'Type Name 4',
                entityStatus: EntityStatusEnum.active,
                customerConsultingTypeDto: undefined,
                customerConsultingTypeDtoId: 5,
                customerConsultingTypeDtoName: 'Consulting Type 4',
                removeFromActionsUntil: new Date(),
                removeFromActionsUntilReason: 'Reason 4',
                personDto: undefined,
                personDtoId: 4,
                personDtoName: 'Person Name 4',
                registrationDtoId: 4,
                registrationDtoName: 'Registration Name 4',
                addresses: [],
                user: undefined,
                customerOnboarding: undefined,
                customerContracts: []
            },
            {
                id: 5,
                alias: 'Customer Alias 5',
                startDate: new Date(),
                endDate: new Date(),
                customerTypeDto: undefined,
                customerTypeDtoId: 5,
                customerTypeDtoName: 'Type Name 5',
                entityStatus: EntityStatusEnum.active,
                customerConsultingTypeDto: undefined,
                customerConsultingTypeDtoId: 6,
                customerConsultingTypeDtoName: 'Consulting Type 5',
                removeFromActionsUntil: new Date(),
                removeFromActionsUntilReason: 'Reason 5',
                personDto: undefined,
                personDtoId: 5,
                personDtoName: 'Person Name 5',
                registrationDtoId: 5,
                registrationDtoName: 'Registration Name 5',
                addresses: [],
                user: undefined,
                customerOnboarding: undefined,
                customerContracts: []
            }
        ];

        const mockAddresses: (Partial<Address>)[] = [
            {
                id: 1,
                addressTypeDto: undefined,
                addressTypeDtoName: 'Home 1',
                addressTypeId: 1,
                countryDto: undefined,
                countryDtoName: 'Country 1',
                countryId: 'PK',
                city: 'City 1',
                region: 'Region 1',
                postalCode: '12345',
                street: '123 Main St 1',
                addressLine2: '',
                addressLine3: '',
                geoLongitude: 9.911234,
                geoLatitude: 53.511086,
            },
            {
                id: 2,
                addressTypeDto: undefined,
                addressTypeDtoName: 'Office 2',
                addressTypeId: 2,
                countryDto: undefined,
                countryDtoName: 'Country 2',
                countryId: 'US',
                city: 'City 2',
                region: 'Region 2',
                postalCode: '67890',
                street: '456 Elm St',
                addressLine2: 'Suite 101',
                addressLine3: '',
                geoLongitude: 9.991256,
                geoLatitude: 53.551021,
            },
            {
                id: 3,
                addressTypeDto: undefined,
                addressTypeDtoName: 'Warehouse 3',
                addressTypeId: 3,
                countryDto: undefined,
                countryDtoName: 'Country 3',
                countryId: 'CA',
                city: 'City 3',
                region: 'Region 3',
                postalCode: '54321',
                street: '789 Maple St',
                addressLine2: '',
                addressLine3: '',
                geoLongitude: 9.991211,
                geoLatitude: 53.591013,
            },
            {
                id: 4,
                addressTypeDto: undefined,
                addressTypeDtoName: 'Store 4',
                addressTypeId: 4,
                countryDto: undefined,
                countryDtoName: 'Country 4',
                countryId: 'GB',
                city: 'City 4',
                region: 'Region 4',
                postalCode: '98765',
                street: '101 Pine St',
                addressLine2: '',
                addressLine3: '',
                geoLongitude: 9.991251,
                geoLatitude: 53.511019,
            },
            {
                id: 5,
                addressTypeDto: undefined,
                addressTypeDtoName: 'Branch 5',
                addressTypeId: 5,
                countryDto: undefined,
                countryDtoName: 'Country 5',
                countryId: 'AU',
                city: 'City 5',
                region: 'Region 5',
                postalCode: '13579',
                street: '202 Oak St',
                addressLine2: '',
                addressLine3: '',
                geoLongitude: 9.951134,
                geoLatitude: 53.551186,
            }
        ];

        const mockSelections: (Partial<AppointmentSelectionDto>)[] = [
            {
                id: 1,
                selectionType: 'Sample Selection Type 1',
                ticket: mockTickets[0] as TmTicketDto,
                appointment: mockAppointments[0] as AppointmentDto,
                customer: mockCustomers[0] as CustomerDto,
                address: mockAddresses[0] as Address,
                appointmentRequestUrgency: AppointmentRequestUrgencyEnum.regular
            },
            {
                id: 2,
                selectionType: 'Sample Selection Type 2',
                ticket: mockTickets[1] as TmTicketDto,
                appointment: mockAppointments[1] as AppointmentDto,
                customer: mockCustomers[1] as CustomerDto,
                address: mockAddresses[1] as Address,
                appointmentRequestUrgency: AppointmentRequestUrgencyEnum.urgent
            },
            {
                id: 3,
                selectionType: 'Sample Selection Type 3',
                ticket: mockTickets[2] as TmTicketDto,
                appointment: mockAppointments[2] as AppointmentDto,
                customer: mockCustomers[2] as CustomerDto,
                address: mockAddresses[2] as Address,
                appointmentRequestUrgency: AppointmentRequestUrgencyEnum.regular
            },
            {
                id: 4,
                selectionType: 'Sample Selection Type 4',
                ticket: mockTickets[3] as TmTicketDto,
                appointment: mockAppointments[3] as AppointmentDto,
                customer: mockCustomers[3] as CustomerDto,
                address: mockAddresses[3] as Address,
                appointmentRequestUrgency: AppointmentRequestUrgencyEnum.urgent
            },
            {
                id: 5,
                selectionType: 'Sample Selection Type 5',
                ticket: mockTickets[4] as TmTicketDto,
                appointment: mockAppointments[4] as AppointmentDto,
                customer: mockCustomers[4] as CustomerDto,
                address: mockAddresses[4] as Address,
                appointmentRequestUrgency: AppointmentRequestUrgencyEnum.regular
            }
        ];

        return mockSelections;
    }

}
