import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {CrCustomerDto} from "../../../../dto/person.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {CallRecipientTypeEnum} from "../../../../../pc/enum/call-recipient-type-enum.enum";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../../../../tm/enum/tm-status.enum";
import {CustomerChannelService} from "../../../../service/api/customer-channel.service";


@Component({
    selector: 'cr-customer-communication-navigation',
    templateUrl: './cr-customer-communication-navigation.component.html',
})
export class CrCustomerCommunicationNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerExternalAliasWidget: WidgetData;
    customerChannelWidget: WidgetData;
    customerAddressWidget: WidgetData;
    customerContactsWidget: WidgetData;
    customerAgentsWidget: WidgetData;

    customerCallsWidget: WidgetData;
    customerCallsCategoryWidget: WidgetData;
    widgetCustomerChannels: WidgetData;

    customerChannelDtoList: DtoList;
    customerAddressDtoList: DtoList;
    customerContactDtoList: DtoList;
    customerAgentDtoList: DtoList;

    customerDto: CrCustomerDto;
    subForms: MvsFormDto[];
    callFilterCriteria: FilterCriteria[] | undefined;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected configService: MvsConfigService,
                protected customerService: CustomerService,
                protected navigationService: MvsObjectNavigationService,
                protected customerChannelService: CustomerChannelService,) {
        super(coreService, confirmationService, messageService, configService,navigationService);
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getCustomer();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    getCustomer() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [])

        /*
        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customerChannels",
                ObjectRequestRelation.createList(
                    "cr.CustomerChannel",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("customerAddresses",
                ObjectRequestRelation.createList(
                    "cr.CustomerAddress",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("customerAgents",
                ObjectRequestRelation.createList(
                    "cr.CustomerAgent",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        );


        this.customerService.list(dtoRequest).subscribe(res => {

            this.customerDto = res.entries[0];
            this.subForms = res.form.subForms;

            this.customerChannelDtoList = this.prepareDtoList('customerChannels')
            this.customerAddressDtoList = this.prepareDtoList('customerAddresses')
            this.customerContactDtoList = this.prepareDtoList('customerContacts')
            this.customerAgentDtoList = this.prepareDtoList('customerAgents')

            this.refreshWidgets();
        })
        */

        this.refreshWidgets();


    }

    prepareDtoList(targetObject: string) {
        const newDtoList = new DtoList();
        newDtoList.entries = this.customerDto[targetObject];
        newDtoList.form = this.subForms[targetObject];
        MvsCrudService.transformDtoListOutToIn(newDtoList);
        return newDtoList;
    }

    refreshWidgets() {
        //this.showCustomerExternalAlias();
        //this.showCustomerChannel();
        //this.showCustomerAddress();
        this.showCustomerContact();
        //this.showCustomerAgents();
        this.showCustomerCallWidget();
        this.showCustomerCallWidgetCategory();
        this.showCustomerChannels();
    }

    showCustomerCallWidgetCategory() {

        this.customerCallsCategoryWidget = WidgetFactory.createWidgetGroupBy(
            "cr.customer.communication.navigation.show.customer.calls.category",
            "Anrufübersicht",
            "category",
            "entity.groupBy",
            "pc.Call",
            "callResult",
            "callResult_count",
            [FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
                FilterCriteria.create('callRecipientType', FilterCriteria.cOperatorEqual, CallRecipientTypeEnum.customer)],
            "callResult",
            "Anzahl"
        );

    }

    showCustomerChannels() {
        this.widgetCustomerChannels = WidgetFactory.createWidgetList(
            "cr.customer.basic.channels",
            "Channels",
            "list",
            "list",
            "entity",
            "cr.CustomerChannel",
            "No related channels found!",
            new ObjectRequestList(
                        true,
                        [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
                        [new Sorting("createdDate", false)]
                    )
        );
            this.widgetCustomerChannels.dataProviderListRequest.objectRequestComplex = this.customerChannelService.getChannelComplexSelection()
            this.widgetCustomerChannels.setParamValue("size", "S");
            this.widgetCustomerChannels.setParamValue("objectWidget", "data");
            this.widgetCustomerChannels.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    showCustomerCallWidget() {

        const filterCriteriaList = [];

        if (this.callFilterCriteria && this.callFilterCriteria.length) {
            filterCriteriaList.push(FilterCriteria.createOr(...this.callFilterCriteria));
        }

        filterCriteriaList.push(
            FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create('callRecipientType', FilterCriteria.cOperatorEqual, CallRecipientTypeEnum.customer)
        );

        this.customerCallsWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.communication.navigation.show.customer.calls',
            "Anrufe",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(
                    false,
                    false,
                    ObjectRequestComplexNode.createRelationNode("lastProtocol", ObjectRequestRelation.createLatest("pc.CallProtocol", "call")),
                    ObjectRequestComplexNode.createSimpleAttributeNode("agent"),
                    ObjectRequestComplexNode.createSimpleAttributeNode("callType")
                ),
                filterCriteriaList,
                [new Sorting("startTime", false)],
                PagingDto.create(0, 10),
                null)
        );

        //


    }

    handleCustomerCallSelect(event: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("pc.Call", event.objectId, "Call", null, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }


    showCustomerExternalAlias() {

        // const dtoRequest: ObjectRequestList = this.getDtoRequest();
        //
        // this.customerExternalAliasWidget = WidgetFactory.createWidgetList(
        //     'cr.customer.communication.navigation.show.customer.external.alias',
        //     'External Alias',
        //     'list',
        //     'list',
        //     'entity',
        //     'cr.CustomerExternalAlias',
        //     null,
        //     dtoRequest
        // );
    }

    showCustomerChannel() {

        this.customerChannelWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.communication.navigation.show.customer.channel',
            'Customer Channels',
            'list',
            'transient',
            'transient',
            'cr.CustomerChannel',
            this.customerChannelDtoList
        );
    }

    showCustomerAddress() {

        this.customerAddressWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.communication.navigation.show.customer.address',
            'Customer Addresses',
            'list',
            'transient',
            'transient',
            'cr.CustomerAddress',
            this.customerAddressDtoList
        );
    }

    showCustomerContact() {

        /*
        this.customerContactsWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.communication.navigation.show.customer.contact',
            'Customer Contact',
            'list',
            'transient',
            'transient',
            'cr.CustomerContact',
            this.customerContactDtoList
        );
         */

        this.customerContactsWidget = new WidgetData();
        this.customerContactsWidget.idAlias = 'cr.customer.communication.navigation.show.customer.contact';
        this.customerContactsWidget.name = "Kontaktdaten";
        this.customerContactsWidget.uiComponent = "list";
        this.customerContactsWidget.dataProvider = "list";
        this.customerContactsWidget.dataSource = "entity";
        this.customerContactsWidget.dataProviderObject = "cr.CustomerContact";
        this.customerContactsWidget.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [new Sorting("createdDate", false)]
        );
        this.customerContactsWidget.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contact"),
        );

        this.customerContactsWidget.setParamValue("size", "S");
        this.customerContactsWidget.setParamValue("objectWidget", "data");
        this.customerContactsWidget.functionCallbacks = this.widgetProcessToolbarCreate();


    }

    widgetProcessToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.dto.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }


    showCustomerAgents() {

        this.customerAgentsWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.communication.navigation.show.customer.agents',
            'Customer Agents',
            'list',
            'transient',
            'transient',
            'cr.CustomerAgent',
            this.customerAgentDtoList
        );
    }

    onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
        if (!this.callFilterCriteria) {
            this.callFilterCriteria = [];
        }

        if (this.callFilterCriteria) {
            const found = this.callFilterCriteria.find(res => res.value == objectData.objectId);
            if (found) {
                return;
            }
        }
        const filterCriteria: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        filterCriteria.label = objectData.data.label;
        this.callFilterCriteria.push(filterCriteria);
        this.showCustomerCallWidget();
    }

    onRemoveFilterCriteria(filter: FilterCriteria) {
        let index = this.callFilterCriteria.findIndex(item => item.value === filter.value);

        if (index !== -1) {
            this.callFilterCriteria.splice(index, 1);
            this.showCustomerCallWidget();
        }

    }


}
