import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiCrudServiceCommentsInterface} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class DmDocumentCommentService extends MvsCrudService implements MvsApiCrudServiceCommentsInterface {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/dm/dmDocumentComments');
  }

  getCommentAttribute(): string {
    return "comment";
  }


  getLinkedAttribute(objectType: string): string {
    if (objectType === "dm.DmDocument") {
      return "document";
    }

    return null;
  }
}
