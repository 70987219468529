


<h2>Object Type: Meta Extension</h2>
<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-12" *ngIf="historyObjectTypes && historyObjectTypes.length > 0">
            <p-chips [(ngModel)]="historyObjectTypes" (onRemove)="onRemoveHistoryObjectType($event)" (onChipClick)="onHistoryChipSelect($event)">
                <ng-template let-item pTemplate="item">
                    <span class="font-bold" *ngIf="selectedObjectType && item.id == selectedObjectType.id">{{ item.alias }}</span>
                    <span *ngIf="!selectedObjectType || item.id != selectedObjectType.id">{{ item.alias }}</span>
                </ng-template>
            </p-chips>
        </div>

        <div class="col-12" *ngIf="metaData">
            <p-button (click)="onBackToOverview();" styleClass="p-button-link" icon="fa-solid fa-backward" label="Back to Overview"></p-button>
        </div>



        <div class="col-3" *ngIf="!metaData">
            <p-listbox
                    [options]="objectTypes.entries"
                    [filter]="true"
                    filterBy="alias"
                    optionLabel="alias"
                    [multiple]="false"
                    (onChange)="onChangeObjectType($event)"
                    [listStyle]="{'max-height':'800px'}"
            >
            </p-listbox>
        </div>

        <div class="col-6" *ngIf="metaData">

            <!-- Show Meta Information -->
            <p-table [value]="metaData.attributes" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm">
                <ng-template pTemplate="caption"> Attributes </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Data Type</th>
                        <th>Label</th>
                        <th>Mandatory</th>
                        <th>Extended</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-attribute>
                    <tr>
                        <td>{{attribute.attributeName}}</td>
                        <td>{{attribute.dataType}}</td>
                        <td>{{attribute.label}}</td>
                        <td>{{attribute.mandatory}}</td>
                        <td>{{attribute.extended}}</td>
                    </tr>
                </ng-template>
            </p-table>


        </div>


        <div class="col-6">

            <!-- Show Extended Joins-->
            <p-table [value]="metaJoinExtensions.entries" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm" *ngIf="metaJoinExtensions">
                <ng-template pTemplate="caption">
                    <i class="fa-solid fa-link"></i> Join Extensions
                    <p-toolbar>
                        <div class="p-toolbar-group-start">
                            <p-button label="New" icon="pi pi-plus" class="mr-2" (click)="onCreateJoinExtension();"></p-button>
                        </div>
                    </p-toolbar>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Join Object Type</th>
                        <th>Join Attribute</th>
                        <th>Rel. Type</th>
                        <th>Binding</th>
                        <th>Multiple Values</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-join>
                    <tr>
                        <td>{{join.name}}</td>
                        <td>{{join.description}}</td>
                        <td><p-button styleClass="p-button-link p-0" (click)="onNavigateToJoinObjectType(join)">{{join.objectRelationDto.targetObjectTypeDtoName}}</p-button></td>
                        <td>{{join.objectRelationDto.linkAttribute}}</td>
                        <td>{{join.objectRelationDto.relationType}}</td>
                        <td>{{join.objectRelationDto.binding}}</td>
                        <td>{{join.multipleValues}} <i *ngIf="join.multipleValues" class="fa-solid fa-table"></i> </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">There are no extensions yet.</td>
                    </tr>
                </ng-template>
            </p-table>


        </div>

        <div class="col-12" *ngIf="metaData">

            <p-table [value]="metaData.joins" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm">
                <ng-template pTemplate="caption"> <i class="fa-solid fa-link"></i> Joins </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Join Object Type</th>
                        <th>Join Attribute</th>
                        <th>Generic</th>
                        <th>Relation</th>
                        <th>Extension via Code <i class="fa-solid fa-code"></i></th>
                        <th>Extension via Config <i class="fa-solid fa-pen-to-square"></i></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-join>
                    <tr>
                        <td>{{join.name}}</td>
                        <td><p-button styleClass="p-button-link p-0" (click)="onNavigateToJoinObjectType(join)">{{join.joinObjectTypeAlias}}</p-button></td>
                        <td>{{join.attributeName}}</td>
                        <td>{{join.generic}}</td>
                        <td>{{metaDataJoinRelationEnum[join.joinRelationEnum]}} <i *ngIf="join.joinRelationEnum == 'manyToMany' || join.joinRelationEnum == 'oneToMany'" class="fa-solid fa-table"></i> </td>
                        <td>{{join.extendedService}}</td>
                        <td>{{join.extendedConfig}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>


    </div>

</ng-container>

<ng-container *ngIf="relationDialogShow">
    <mvs-core-dp-relat [showRelationDialog]="relationDialogShow"
                       [objectTypeAlias]="selectedObjectType.alias"
                       (onHandleSaveRelation)="onRelationSaveDialog($event)"
                       (onHandleUpdateRelation)="onRelationUpdateDialog($event)"
    ></mvs-core-dp-relat>
</ng-container>

