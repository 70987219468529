import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from "@angular/core";
import {
    FilterCriteria,
    MvsCoreService, MvsMessageService,
    ObjectBaseComponent, ObserverService
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {EventInterface, Person} from "@kvers/alpha-ui";

@Component({
    selector: "as-calendar-component",
    templateUrl: "./as-calendar.component.html",
    styleUrls: ["./as-calendar.component.scss"],
})
export class AsCalendarComponent extends ObjectBaseComponent
    implements OnInit, OnChanges {

    initialized: boolean;

    @Input() module: string;
    @Input() refreshTrigger: number | string | boolean;
    @Input() roleBasedFilter: FilterCriteria[];

    persons: Person[] = [];


    events: EventInterface[];

    @Output() onDataLoaded = new EventEmitter<void>();

    filterCriteria: FilterCriteria[];

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected observerService: ObserverService,
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit() {

        if (this.params) {
            const test = JSON.parse(this.params.module);


            const rawEvents = test['events'];
            this.events = rawEvents.map(this.parseEvent);


            this.persons = test['person'];
        }

        this.refresh();
        this.initialized = true;
    }


    /**
     * On Object Change.
     */
    refresh() {

    }

    parseEvent(event: any): EventInterface {
        return {
            ...event,
            preEventDto: {
                start: new Date(event.preEventDto.start),
                end: new Date(event.preEventDto.end)
            },
            mainEventDto: {
                start: new Date(event.mainEventDto.start),
                end: new Date(event.mainEventDto.end)
            },
            postEventDto: {
                start: new Date(event.postEventDto.start),
                end: new Date(event.postEventDto.end)
            }
        };
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (!this.initialized) {
            return;
        }
        this.refresh();
    }

}

