import {Component, OnChanges, OnInit} from '@angular/core';
import {NavigationItem, ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem} from "primeng/api";
import {TeArtefactDto} from "../../dto/te-artefact.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {TeTemplateDto} from "../../dto/te-template.dto";
import {TeTemplateService} from "../../service/api/te-template.service";
import {TinymceAutosave} from '../../logic/tinymce-autosave';
import {DtoDetail} from "@kvers/alpha-core-common";
import {TestCaseChangeEvent, TeTestCaseComponentVariableWithMapping} from "../te-test-case/te-test-case.component";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {TeTemplateGenerateResponseDto} from "../../dto/te-template-generate-response.dto";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ObjectIdentifierWithId} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {TeTestCaseService} from "../../service/api/te-test-case.service";
import {DtoList} from "@kvers/alpha-core-common";
import {TeTemplateVariableService} from "../../service/api/te-template-variable.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TeTestCaseVariableService} from "../../service/api/te-test-case-variable.service";
import {TeTestCaseVariableDto} from "../../dto/te-test-case-variable.dto";
import {TeVariableTypeEnum} from "../../enum/te-variable-type.enum";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-te-template-object',
    templateUrl: './te-template-object.component.html',
    styleUrls: ['./te-template-object.component.scss']
})
export class TeTemplateObjectComponent
    extends ObjectBaseComponent
    implements OnInit, OnChanges {

    dto: TeTemplateDto;
    crudService: TeTemplateService;

    widgetBasicData: WidgetData;
    showBasicData: boolean;
    private autoSave = new TinymceAutosave();
    leftSidebarOpen: boolean = false;
    rightSidebarOpen: boolean = false;
    selectedTestCase: TestCaseChangeEvent;
    activeTabEditor: number = 1;
    activeTab1: number = 0;
    queryParamSubscription: Subscription;
    selectedNavigationItem: string;
    navigationItems: NavigationItem[];

    templateGenerateResponse: TeTemplateGenerateResponseDto;
    trustedGenerateResponseHtml: SafeHtml;
    occurredException: string;
    occurredExceptionDetails: string;

    testCaseDtoList: DtoList;
    variables: TeTestCaseComponentVariableWithMapping[];
    testCaseVariables: TeTestCaseVariableDto[];

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected route: ActivatedRoute,
        protected router: Router,
        public sanitizer: DomSanitizer,
        protected templateVariableService: TeTemplateVariableService,
        protected testCaseVariablesService: TeTestCaseVariableService,
        protected templateService: TeTemplateService,
        protected testCaseService: TeTestCaseService,
        protected confirmationService: ConfirmationService,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.getNavigationItems();

        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem']) {
                const exists = this.checkNavigationEntryExist( this.navigationItems, params['navItem']);
                if (exists) {
                    this.selectedNavigationItem = params['navItem'];
                    this.onNavItemSelection();
                } else {
                    this.removeNavItemParam();
                }
            } else {
                this.selectedNavigationItem = null;
            }
        });

        this.refreshTemplateBasicData();
        this.refreshTestCases();
    }


    /**
     * Save content.
     * @param content
     */
    handleEditorOnSave(content: string) {

        const dto = new TeArtefactDto();
        dto.id = this.dto.id;
        dto.content = content;

        this.crudService.update(dto).subscribe({
            next: (value) => {
                this.messageService.showSuccessMessageSaved();
                console.log('Updated content data into API');
                let editorContentFromCookie = this.autoSave.getAutoSaveCookie('te.TeTemplateVariable' + this.dto.id);
                if (editorContentFromCookie && dto.content === editorContentFromCookie?.content) {
                    this.autoSave.deleteAutoSaveCookie('te.TeTemplateVariable' + this.dto.id);
                }

                if (this.selectedNavigationItem == 'Selected Case') {
                    this.generateDocument();
                }

            },
            error: (error) => {
                this.messageService.showErrorMessage('Error', 'Something went wrong');
            }
        });

    }


    refreshTemplateBasicData() {
        this.widgetBasicData = new WidgetData();
        this.widgetBasicData.idAlias = "te.config.template.view";
        this.widgetBasicData.name = "Template";
        this.widgetBasicData.uiComponent = "data";
        this.widgetBasicData.dataProvider = "list";
        this.widgetBasicData.dataSource = "entity";
        this.widgetBasicData.dataProviderObject = this.objectIdentifier.objectType;
        this.widgetBasicData.setParamValue("objectId", this.objectIdentifier.objectId);
    }

    toggleLeftSidebar() {
        this.leftSidebarOpen = !this.leftSidebarOpen;
    }

    toggleRightSidebar() {
        this.rightSidebarOpen = !this.rightSidebarOpen;
    }

    handleSelectTestCase(event: TestCaseChangeEvent) {
        this.selectedTestCase = event;

        // const exists = this.templateService.navigationItems.find(item => item.label == 'Selected Case');
        const exists = this.checkNavigationEntryExist( this.templateService.navigationItems, 'Selected Case');

        if (!exists) {
            this.templateService.navigationItems.push({ label: 'Selected Case', icon: 'fa-regular fa-file-pen' });
        }

    }

    checkNavigationEntryExist(items: NavigationItem[],label: string): boolean {
        const exists = items.find(item => item.label == label);
        if (exists) {
            return true;
        }
        return false;
    }

    getNavigationItems() {
        this.navigationItems = this.templateService.getObjectComponentNavigationItems();
    }

    removeNavItemParam(): void {
        const queryParams = { ...this.route.snapshot.queryParams };
        delete queryParams['navItem'];
        this.router.navigate([], { queryParams: queryParams });
    }

    generateDocument() {

        this.templateGenerateResponse = null;
        this.occurredException = null;
        this.occurredExceptionDetails = null;

        const objectVariables: { [key: string]: ObjectIdentifierWithId } = {};
        const simpleVariables: { [key: string]: any } = {};

        // fill variables
        if (this.selectedTestCase.variables) {
            for (let variable of this.selectedTestCase.variables) {
                if (variable.useCaseVariable) {
                    if (variable.variableType == "object") {
                        objectVariables[variable.alias] = new ObjectIdentifierWithId(variable.useCaseVariable.objectTypeDtoId, null, variable.useCaseVariable.objectId);
                    } else if (variable.variableType == "simple") {
                        simpleVariables[variable.alias] = variable.useCaseVariable.simpleValue;
                    }
                }
            }
        }

        this.busy = true;

        this.templateService.generateViaTestCase(
            this.dto.id,
            this.selectedTestCase.dto.id,
            false,
            objectVariables,
            simpleVariables).subscribe(value => {

            if (value.errors) {
                this.occurredException = value.exception;
                this.occurredExceptionDetails = value.exceptionDetails;
            } else {
                this.templateGenerateResponse = value;
                this.trustedGenerateResponseHtml = this.sanitizer.bypassSecurityTrustHtml(this.templateGenerateResponse.document);
            }
            this.busy = false;
        });
    }

    onNavItemSelection() {
        if (this.selectedNavigationItem == 'Selected Case') {
            if (!this.templateGenerateResponse) {
                this.generateDocument();
            }
        }
    }

    refreshTestCases() {
        this.testCaseService.list(ObjectRequestList.createSimple(DtoImportObjectContext.createEmpty())).subscribe(value => {
            this.testCaseDtoList = value;
            this.busy = false;
        });
    }

    refreshTestCaseVariables() {

        const dtoListRequest = new ObjectRequestList(
            false,
            [FilterCriteria.create('testCase', FilterCriteria.cOperatorEqual, this.selectedTestCase.dto.id, null)],
            []
        );

        this.testCaseVariablesService.list(dtoListRequest).subscribe(res => {
            this.testCaseVariables = res.entries;
            this.refreshVariableMapping();
        });

    }

    refreshVariableMapping() {

        if (!this.selectedTestCase.variables) {
            return;
        }

        for (let variable of this.selectedTestCase.variables) {

            // set tu null
            variable.useCaseVariable = null;

            if (!this.testCaseVariables) {
                continue;
            }


            for (let testCaseVariable of this.testCaseVariables) {

                // check whether alias is set
                if (testCaseVariable.alias && testCaseVariable.alias !== variable.alias) {
                    continue;
                }

                // check whether types are matching
                if (variable.variableType == "object" && ( testCaseVariable.type != TeVariableTypeEnum.object) || variable.objectTypeDtoId != testCaseVariable.objectTypeDtoId){
                    continue;
                } else if (variable.variableType == "simple" && testCaseVariable.type != TeVariableTypeEnum.simple){
                    continue;
                }


                let isMatch: boolean = false;

                // check for perfect match (including alias)
                if (variable.variableType == "object") {
                    if (variable.objectTypeDtoId && variable.objectTypeDtoId == testCaseVariable.objectTypeDtoId) {
                        isMatch = true;
                    }
                } else if (variable.variableType == "simple") {
                    isMatch = true;
                }

                variable.useCaseVariable = testCaseVariable;

                if (testCaseVariable.alias) {
                    // perfect match, we can stop any further processing
                    break;
                }

            }

        }

    }

}
