import {Route, Routes} from "@angular/router";
import {AssessmentGroupConfigPage} from "./page/overview/assessment-group-config/assessment-group-config.page";
import {PartnerConfigPage} from "./page/overview/partner-config/partner-config.page";
import {CmContractComponent} from "./components/cm-contract/cm-contract.component";
import {
    CmContractStatusTypeOverviewPage
} from "./page/overview/cm-contract-status-type-overview/cm-contract-status-type-overview.page";
import {
    CmContractStatusOverviewPage
} from "./page/overview/cm-contract-status-overview/cm-contract-status-overview.page";
import {CmContractPage} from "./page/overview/cm-contract-page/cm-contract.page";
import {ContractSearchPage} from "./page/overview/contract-search/contract-search.page";
import {ContractHistoryPage} from "./page/overview/cm-contract-history-page/contract-history.page";
import {
    CmCreateInsurableObjectPersonComponent
} from "./components/cm-create-insurable-object-person/cm-create-insurable-object-person.component";
import {CmConfigPage} from "./page/overview/cm-config/cm-config.page";
import {
    CmContractDashboardStatusPage
} from "./page/overview/cm-contract-dashboard-status/cm-contract-dashboard-status.page";
import {CmContractLastPage} from "./page/object/cm-contract-last/cm-contract-last.page";
import {
    CmContractCombinedFormCreatePage
} from "./page/cm-contract-combined-form-create/cm-contract-combined-form-create.page";
import {ContractOverviewPage} from "./page/contract-overview-page/contract-overview.page";

export function CmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [


        // {path: 'cm', component: ContractOverviewPage},

        {path: 'cm/config', component: CmConfigPage},

        {path: 'cm/assessmentGroupConfig', component: AssessmentGroupConfigPage},
        {path: 'cm/test/:id', component: CmContractComponent},
        {path: 'cm/contract-status-type/:id', component: CmContractStatusTypeOverviewPage},
        {path: 'cm/contract-status', component: CmContractStatusOverviewPage},
        {path: 'cm/contract-dashboard-status', component: CmContractDashboardStatusPage},



        {path: 'cm/contract/:id/:view', component: CmContractPage},
        {path: 'cm/contract/:id', component: CmContractPage},

        {path: 'cm/partners', component: PartnerConfigPage},
        {path: 'cm/last', component: CmContractLastPage},

        {path: 'cm/contractSearch', component: ContractSearchPage},
        {path: 'cm/contractHistory', component: ContractHistoryPage},

        {path: 'cm/create-relation/:cus', component: CmCreateInsurableObjectPersonComponent},


        {path: 'cm/contractCreate/:customerId', component: CmContractCombinedFormCreatePage},



    ];

    return routes;

}
