import {Component, OnInit} from '@angular/core';
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {UmUserService} from "../../../../um/service/api/um-user.service";
import {MetaService} from "@kvers/alpha-core-common";
import {MessageService, TreeDragDropService} from "primeng/api";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MetaEntityRequestDto} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-test-widget',
  providers: [TreeDragDropService,MessageService],
  templateUrl: './test-widget.page.html',
  styleUrls: ['./test-widget.page.scss']
})
export class TestWidgetPage implements OnInit {

  importObjectContext: DtoImportObjectContext;
  initialized: boolean;

  ticketWidgetData: WidgetData;
  ticketHistoryWidgetData: WidgetData;

  cascadeTest: any[];
  cascadeTestSelected: any;

  constructor(
      protected dragDropService: TreeDragDropService,
      protected umService: UmUserService,
      protected metaService: MetaService,
      protected objectService: MvsUiObjectService) {
    this.initTestData();
    this.initialized = true;
  }

  onTestMeta() {

    this.metaService.getByAlias("cr.Customer",true, true, false).subscribe(value => {

    });

  }

  ngOnInit(): void {

    this.initTestData();

    this.initTestCascade();

  }

  initTestCascade() : void {

    this.metaService.list(new MetaEntityRequestDto()).subscribe(value => {

    });

    this.cascadeTest =
        [
            { key : "A", label : "A Value", children: [ { key : "A1", label : "A1 Value" }, { key : "A2", label : "A2 Value" } ] },
            { key : "B", label : "B Value", children: [ { key : "B1", label : "B1 Value" }, { key : "B2", label : "B2 Value" } ] },
            { key : "C", label : "C Value", children: [ { key : "C1", label : "C1 Value" }, { key : "C2", label : "C2 Value" } ] },
        ];

    this.cascadeTestSelected = this.cascadeTest[0].children[0];

  }


  showReportField(): void {

    this.objectService.openObjectViaDialog(
        new DtoImportObjectContext([]),
        "rp.ReportField",
        69,
        null,
        false,
        false,
        () => {
          alert("update happened!")
        },
        MvsCrudModeEnum.create
        );

  }

  initTestData(): void {

    this.ticketWidgetData = new WidgetData();

    this.ticketWidgetData.idAlias = "test.widget.ticket";
    this.ticketWidgetData.uiComponent = "table";
    this.ticketWidgetData.dataProvider = "list";
    this.ticketWidgetData.dataSource = "entity";
    this.ticketWidgetData.dataProviderObject = "tm.Ticket";

    this.ticketWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [ FilterCriteria.createSingleCondition("status", FilterCriteria.cOperatorEqual, TmStatusEnum.working, null) ],
        [new Sorting("createdDate", false)],
        PagingDto.create(0,5)
    );

    // set size to S
    this.ticketWidgetData.setParamValue("size", "S");
    this.ticketWidgetData.setParamValue("selectionMode", "single");


    this.ticketHistoryWidgetData = new WidgetData();

    this.ticketHistoryWidgetData.idAlias = "test.widget.history.ticket";
    this.ticketHistoryWidgetData.uiComponent = "history.chart";
    this.ticketHistoryWidgetData.dataProvider = "list";
    this.ticketHistoryWidgetData.dataSource = "entity.groupBy";
    this.ticketHistoryWidgetData.dataProviderObject = "tm.Ticket";

    this.ticketHistoryWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["type", "createdDate"],
        [ new ObjectRequestListAttribute("type", "Type", DtoListAttributeRequestAggregateEnum.count)]
    );

    this.ticketHistoryWidgetData.setParamValue("fieldCategory", "type");
    this.ticketHistoryWidgetData.setParamValue("fieldCategoryCount", "type_count");
    this.ticketHistoryWidgetData.setParamValue("historyPeriodMode", "month");
    this.ticketHistoryWidgetData.setParamValue("fieldDate", "createdDate");


    // <p>{{Contact.Email}}</p>

    // convert to Editor
    // search {{ change <span>{{</span>
    // search }} change <span>}}</span>



  }

  ticketSelected(objectIdentifier: ObjectIdentifier) {
    alert(objectIdentifier.objectId);
  }











/*

  initTestDataExtend(): void {

    // Table Widget
    this.tableWidgetData = new WidgetData();
    this.tableWidgetData.id = 1;
    this.tableWidgetData.idAlias = "1";
    this.tableWidgetData.name = "Kunden";
    this.tableWidgetData.uiComponent = "table";
    this.tableWidgetData.dataProvider = "list";
    this.tableWidgetData.dataSource = "entity";
    this.tableWidgetData.dataProviderObject = "cr.Customer";
    this.tableWidgetData.setParamValue("size", "S");




    // Table Widget Report
    this.tableReportWidgetData = new WidgetData();
    this.tableReportWidgetData.id = 1;
    this.tableReportWidgetData.idAlias = "1";
    this.tableReportWidgetData.name = "Kunden";
    this.tableReportWidgetData.uiComponent = "table";
    this.tableReportWidgetData.dataProvider = "list";
    this.tableReportWidgetData.dataSource = "report";
    this.tableReportWidgetData.dataProviderObject = "2";
    this.tableReportWidgetData.setParamValue("size", "S");


    // Chart Widget
    this.chartWidgetData = new WidgetData();
    this.chartWidgetData.id = 2;
    this.chartWidgetData.idAlias = "2";
    this.chartWidgetData.name = "Kundenchart";
    this.chartWidgetData.uiComponent = "chart";
    this.chartWidgetData.dataProvider = "list";
    this.chartWidgetData.dataSource = "entity";
    this.chartWidgetData.dataProviderObject = "cr.Customer";
    this.chartWidgetData.setParamValue("chartType", "pie");
    this.chartWidgetData.setParamValue("chartField1st", "customerTypeDtoName");
    this.chartWidgetData.setParamValue("chartField1stLabel", "customerTypeDtoName");
    this.chartWidgetData.setParamValue("chartAggregateFunction", "count");



    // List Widget Data
    this.listWidgetData = new WidgetData();
    this.listWidgetData.id = 4;
    this.listWidgetData.idAlias = "4";
    this.listWidgetData.name = "Kundenliste";
    this.listWidgetData.uiComponent = "list";
    this.listWidgetData.dataProvider = "list";
    this.listWidgetData.dataSource = "entity";
    this.listWidgetData.dataProviderObject = "cr.Customer";

    // List Widget Data (OS)
    this.listOsWidgetData = new WidgetData();
    this.listOsWidgetData.id = 5;
    this.listOsWidgetData.idAlias = "20";
    this.listOsWidgetData.name = "User des Kunden";
    this.listOsWidgetData.uiComponent = "list";
    this.listOsWidgetData.dataProvider = "list";
    this.listOsWidgetData.dataSource = "os";
    this.listOsWidgetData.dataProviderObject = "cr.Customer[10638]#cr.CustomerUser#eu.EuUser";
    this.listOsWidgetData.setParamValue("objectWidget", "data");

    this.listOsWidgetData.setParamValue("defaultFormFields", ["email",
                                                                          "emailConfirmed",
                                                                          "phoneNumber",
                                                                          "phoneNumberConfirmed",
                                                                          "active"]
    );



    this.mutableWidgetLayout = new MutableAreaLayoutData();

    const mutableAreaChart = MutableAreaItem.createWithSizes("col-4", this.chartWidgetData);
    mutableAreaChart.eventOnFilter = "filter123";

    const mutableAreaTable = MutableAreaItem.createWithSizes("col-12", this.tableWidgetData)
    mutableAreaTable.eventHandlerOnFilter = "filter123";

    const mutableAreaTableOs = MutableAreaItem.createWithSizes("col-12", this.listOsWidgetData)

    //this.mutableWidgetLayout.items.push(mutableAreaChart);
    //this.mutableWidgetLayout.items.push(mutableAreaTable);
    this.mutableWidgetLayout.items.push(mutableAreaTableOs);

  }

 */


}
