import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessTypeStepNextDto} from "../../../dto/wf-process-type-step-next.dto";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {DtoList} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-wf-process-step-activity-choose-next-step',
    templateUrl: './wf-process-step-activity-choose-next-step.component.html',
    styleUrls: ['./wf-process-step-activity-choose-next-step.component.scss']
})
export class WfProcessStepActivityChooseNextStepComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    nextSteps: DtoList<WfProcessTypeStepNextDto>;

    ngOnInit() {
        this.refreshComponent();
    }

    refreshComponent() {
        this.nextSteps = this.processRuntime.stepMeta.activeStepNextSteps;
    }

    onStepSelect(step: WfProcessTypeStepNextDto) {

        this.activityType.processTypeStepNextDtoId = step.nextProcessTypeStepDtoId;
        // this.activityType.processTypeStepNextDto = step;
        this.busy = true;
        const event: WfProcessStepActivityEvent = new WfProcessStepActivityEvent(null, this.activityType);
        this.onNextStepSelect.emit(event);
    }


}
