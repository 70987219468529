<ng-container *ngIf="initialized">
    <p-panel
            #ticketPanel
            [toggleable]="true"
            [collapsed]="false"
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
             class="panel-blank"
            [iconPos]="'end'">
        <ng-template pTemplate="header">
            <span class="text-lg text-900 font-normal w-full h-full cursor" (click)="collapsedChange(ticketPanel)">Unteraufgaben
<!--               <small class="font-semibold">({{dtoListSubTickets?.entries.length}})</small>-->
            </span>
        </ng-template>

        <ng-container *ngIf="ticketList && ticketList.entries && ticketList.entries.length">
            <table class="w-full">
                <tr>
                    <th>Ticket</th>
                    <th>Agent Pool</th>
                    <th>Agent</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                <tr *ngFor="let subTicket of dtoListSubTickets?.entries">
                    <td>
                        <div class="flex align-items-center">

                            <div class="mr-0 md:mr-8 li-hover cursor-pointer"
                                 (click)="changeDisplayMode('display-sub-ticket'); eventSubTicketIdMethod(subTicket.subTicketDtoId)">
                                <span class="block text-900 font-medium mb-1">{{subTicket.subTicketDtoName}}</span>
                                <div class="text-600">{{subTicket.createdDate | date}}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{{subTicket.subTicketDto?.assigneeAgentPoolDtoName ? subTicket.subTicketDto?.assigneeAgentPoolDtoName : 'Not Assigned'}}</td>
                    <td>
                        <mvs-agent-avatar
                                [agentName]="subTicket.subTicketDto?.assigneeAgentDtoName ? subTicket.subTicketDto?.assigneeAgentDtoName : 'Not Assigned'"
                        ></mvs-agent-avatar>
                        {{subTicket.subTicketDto?.assigneeAgentDtoName ? (subTicket.subTicketDto?.assigneeAgentDtoName | titlecase) : 'Not Assigned'}}
                    </td>
                    <td>

                        <p-tag [severity]="handleTagColor(subTicket.subTicketDto?.status)"
                               [value]="ticketTemplate.formFields.status.valueList.entries[subTicket.subTicketDto?.status]?.label"></p-tag>
                    </td>
                    <td>
                        <button
                                (click)="onDelete(subTicket)"
                                [disabled]="busy"
                                pButton pRipple
                                class="p-button-text p-button-danger p-button-rounded mr-1"
                                icon="pi pi-trash">
                        </button>
                    </td>
                </tr>

            </table>
        </ng-container>
        <p-button styleClass="p-button-text" icon="fa fa-regular fa-plus fa-fw" label="Add SubTask" [disabled]="busy"
                  (click)="changeDisplayMode('add-sub-ticket')">
        </p-button>
    </p-panel>
</ng-container>