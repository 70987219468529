import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    ObjectIdentifierData,
    ObjectRequestList,
    PageComponent,
    WidgetData, WidgetToolbarCallbackInterface,
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'lg-config-page',
    templateUrl: './lg-config-page.page.html',
    styleUrls: ['./lg-config-page.page.scss']
})
export class LgConfigPagePage extends PageComponent implements OnInit, OnDestroy, OnChanges {

    activeIndex: number = 0;
    lgGroupWidget: WidgetData;
    lgLogicWidget: WidgetData;
    lgLogicImportWidget: WidgetData;
    lgLogicExportWidget: WidgetData;

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.refreshWidgets();
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    refreshWidgets() {
        this.refreshGroupWidget();
        this.refreshLogicWidget();
    }

    clearWidgets() {
        this.lgLogicImportWidget = null;
        this.lgLogicExportWidget = null;
    }

    refreshGroupWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.lgGroupWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-group-widget',
            'Group',
            'lg.LogicGroup',
            'No Data',
            objectRequest
        );
    }

    refreshLogicWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.lgLogicWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-widget',
            'Logic',
            'lg.Logic',
            'No Data',
            objectRequest
        );
        this.lgLogicWidget.uiComponent = 'selectable';
    }

    refreshLogicImportWidget(logicId: number) {

        const filterCriteria = FilterCriteria.create('logic', FilterCriteria.cOperatorEqual, logicId)

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.lgLogicImportWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-import-widget',
            'Logic Import',
            'lg.LogicImport',
            'No Data',
            objectRequest
        );
        this.lgLogicImportWidget.functionCallbacks = this.widgetToolbarCreate('logicDtoId', logicId);
    }

    refreshLogicExportWidget(logicId: number) {

        const filterCriteria = FilterCriteria.create('logic', FilterCriteria.cOperatorEqual, logicId)
        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.lgLogicExportWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-export-widget',
            'Logic Export',
            'lg.LogicExport',
            'No Data',
            objectRequest
        );
        this.lgLogicExportWidget.functionCallbacks = this.widgetToolbarCreate('logicDtoId', logicId);
    }


    handleLogicSelect(object: ObjectIdentifierData) {
        this.refreshLogicImportWidget(object.objectId);
        this.refreshLogicExportWidget(object.objectId);
    }

    widgetToolbarCreate(fieldName: string, fieldValue: number) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[fieldName] = fieldValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(fieldName, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}
