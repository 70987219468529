import {Component, OnInit} from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-cm-insurance-configuration-page',
    templateUrl: './assessment-group-config.page.html',
    styleUrls: ['./assessment-group-config.page.scss']
})
export class AssessmentGroupConfigPage extends PageComponent implements OnInit {
    objectBrowserRequest: any;

    defaultLabel: string = "Assessment Group";

    activeIndex = 0;

    contractAssessmentGroupWidget: WidgetData;
    contractAssessmentGroupContractTypeWidget: WidgetData;
    objectDataWidget: WidgetData;

    ngOnInit(): void {

        this.refreshWidgets();

        super.ngOnInit();

        this.objectBrowserRequest = {

            'extractDto': false,
            "createMode": true,

            'entries': [
                {
                    'id': 1,
                    'objectAlias': 'cm.AssessmentGroup',
                    'level': 1,
                    'parentBinding': 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Bewertungsgruppen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Bewertungsgruppe anlegen"
                },
                {
                    'id': 2,
                    'objectAlias': 'cm.AssessmentGroupContractType',
                    'level': 2,
                    'parentBinding': 'none',
                    // 'parentId': 1,
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Zuordnung Versicherungstypen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Zuordnung anlegen"
                },
            ]
        }
    }

    refreshWidgets() {
        this.refreshCustomerTypeWidget();
        this.refreshCustomerRelatedPersonTypeTypeWidget();
    }

    refreshCustomerTypeWidget() {
        this.contractAssessmentGroupWidget = WidgetFactory.createWidgetListEntity(
            'cm.assessment.group.widget',
            'Bewertungsgruppen',
            'cm.AssessmentGroup',
            'No Data',
            this.getObjectRequestList());
        this.contractAssessmentGroupWidget.uiComponent = 'selectable';
    }


    refreshCustomerRelatedPersonTypeTypeWidget() {
        this.contractAssessmentGroupContractTypeWidget = WidgetFactory.createWidgetListEntity(
            'cm.assessment.group.contract.type.widget',
            'Zuordnung Versicherungstypen',
            'cm.AssessmentGroupContractType',
            'No Data',
            this.getObjectRequestList());
        this.contractAssessmentGroupContractTypeWidget.uiComponent = 'selectable';
    }



    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], []);
    }

    handleObjectSelect(event: ObjectIdentifierData) {

        this.objectDataWidget = WidgetFactory.createWidgetEntityData(
            `${event.objectType}.cm.contract.group.widget`,
            '',
            event.objectType,
            event.objectId
        )

    }

    clearWidgets() {
        this.objectDataWidget = null;
    }

}
