import {Component, Input, OnInit} from "@angular/core";
import {FilterCriteria, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'cc-field-value-list-and-entries',
    templateUrl: 'field-value-list-and-entries.component.html',
})

export class FieldValueListAndEntriesComponent implements OnInit {

    @Input() fieldValueListId: number;

    fieldValueListWidget: WidgetData;
    fieldValueListEntriesWidget: WidgetData;


    ngOnInit() {

        this.fieldValueListWidget = WidgetFactory.createWidgetEntityData(
            'cc.field.type.value.list.widget.data',
            'Value List',
            'cc.FieldValueList',
            this.fieldValueListId
        );

        const filterCriteria = FilterCriteria.create('fieldValueList', FilterCriteria.cOperatorEqual, this.fieldValueListId);

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.fieldValueListEntriesWidget = WidgetFactory.createWidgetTableEntity(
            'cc.field.type.value.list.entries.widget.data',
            'Value List Entries',
            'cc.FieldValueListEntry',
            'No Values',
            objectRequestList
        );

    }
}