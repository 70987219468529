import {Component, OnInit} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsCoreService, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, PagingDto,
    TabEntries,
    WidgetData, WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-te-config-page',
    templateUrl: './te-config.page.html',
    styleUrls: ['./te-config.page.scss']
})
export class TeConfigPage extends PageComponent implements OnInit {

    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Template Configuration";

    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    activeNavItem: string;
    uiWidgetEntries: TabEntries[];

    templateGroupsWidget: WidgetData;
    templateWidget: WidgetData;
    artefactGroupsWidget: WidgetData;
    artefactWidget: WidgetData;
    testCasesWidget: WidgetData;
    vordefinierteSystemvariablenWidget: WidgetData;

    navigationItems: NavigationItem[] = [
        {label: 'Templates',action: 'Templates', tooltip: 'Templates', icon: 'fa fa-info-circle', toggleable: false, default: false},
        {label: 'Artefacts',action: 'Artefacts', tooltip: 'Artefacts', icon: 'fa fa-list-alt', toggleable: false, default: false},
        {label: 'TestCases',action: 'TestCases', tooltip: 'Test Cases', icon: 'fa fa-star', toggleable: false, default: false},
        {
            label: 'VordefinierteSystemvariablen',
            action: 'VordefinierteSystemvariablen',
            tooltip: 'Vordefinierte Systemvariablen',
            icon: 'fa fa-ban',
            toggleable: false,
            default: false
        },
    ];

    widgetEntries: TabEntries[] = [
        {label: 'Template Groups', id: 'te.TeTemplateGroup', type: 'Templates'},
        // {label: 'Templates', id: 'te.TeTemplate', type: 'Templates'},
        {label: 'Artefact Groups', id: 'te.TeArtefactGroup', type: 'Artefacts'},
        // {label: 'Artefacts', id: 'te.TeArtefact', type: 'Artefacts'},
        {label: 'Test Cases', id: 'te.TeTestCase', type: 'TestCases'},
        {
            label: 'Vordefinierte Systemvariablen',
            id: 'te.TeSystemVariablePredefined',
            type: 'VordefinierteSystemvariablen'
        },

    ];

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {

        // Main Structure
        this.objectBrowserRequest = {

            "extractDto": false,
            "createMode": true,

            'entries' : [
                {
                    'id'                : 1,
                    'objectAlias'       : 'te.TeTemplateGroup',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Template Groups",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 2,
                    'objectAlias'       : 'te.TeTemplate',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Templates",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 3,
                    'objectAlias'       : 'te.TeArtefactGroup',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Artefact Groups",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 4,
                    'objectAlias'       : 'te.TeArtefact',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Artefacts",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 5,
                    'objectAlias'       : 'te.TeTestCase',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Test Cases",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 6,
                    'objectAlias'       : 'te.TeSystemVariablePredefined',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Vordefinierte Systemvariablen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                }


            ]
        };


        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();


    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.initialized = false;
            let objectType = params['tid'];
            if (params['navItem']) {
                if (this.activeNavItem && this.activeNavItem !== params['navItem']) {
                    this.refreshWidgets();
                    objectType = this.getFirstActiveWidget(params['navItem'])?.id;
                    this.router.navigate([], {
                        queryParams: {tid: objectType, 'tid-action': 2},
                        queryParamsHandling: 'merge'
                    });
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }
                this.activeNavItem = params['navItem'];
                this.uiWidgetEntries = this.getRelevantGroupedEntries(this.activeNavItem);
                if (!objectType) {
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }

            }

            if (objectType) {
                const object = this.uiWidgetEntries.find(item => item.id === objectType);
                if (object) {
                    this.activeTabIndex = this.uiWidgetEntries.findIndex(item => item.id === object.id);
                    this.activeWidget = object;
                }
            }
            //should be solved after primeng library update (tabPanel not resetting properly)
            setTimeout(() => {
                this.initialized = true;
            }, 10);
        });
    }

    refreshWidgets() {
        this.refreshTemplateGroupsWidget();
        this.refreshArtefactGroupsWidget();
        this.testCaseWidget();
        this.refreshVordefinierteSystemvariablenWidget();
    }

    getFirstActiveWidget(currentNavItem: string) {
        const relevantEntries = this.getRelevantGroupedEntries(currentNavItem);
        if (relevantEntries) {
            return relevantEntries[0];
        }
        return null;
    }

    getRelevantGroupedEntries(currentNavItem: string) {
        return this.widgetEntries.filter(item => item.type === currentNavItem);
    }

    getObjectRequest(filterCriteria: FilterCriteria[] = []): ObjectRequestList {
        return ObjectRequestList.createWithPaging(true, filterCriteria, [], PagingDto.create(0, 20));
    }

    refreshTemplateGroupsWidget() {
        this.templateGroupsWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.template.group',
            'Template Groups',
            'te.TeTemplateGroup',
            'No Data',
            this.getObjectRequest());
        this.templateGroupsWidget.uiComponent = 'selectable';
    }

    refreshTemplateWidget(filterCriteria: FilterCriteria[], groupId:number) {
        this.templateWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.template',
            'Template',
            'te.TeTemplate',
            'No Data',
            this.getObjectRequest(filterCriteria));


        this.templateWidget.functionCallbacks = this.widgetToolbarCreate('templateGroup',groupId);
    }

    refreshArtefactGroupsWidget() {
        this.artefactGroupsWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.artefact.groups',
            'Artefact Groups',
            'te.TeArtefactGroup',
            'No Data',
            this.getObjectRequest());
        this.artefactGroupsWidget.uiComponent = 'selectable';
    }

    refreshArtefactWidget(filterCriteria: FilterCriteria[], groupId:number) {
        this.artefactWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.artefact',
            'Artefacts',
            'te.TeArtefact',
            'No Data',
            this.getObjectRequest(filterCriteria));
        this.artefactWidget.functionCallbacks = this.widgetToolbarCreate('artefactGroup',groupId);
    }

    testCaseWidget() {
        this.testCasesWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.test.cases',
            'Test Cases',
            'te.TeTestCase',
            'No Data',
            this.getObjectRequest());
    }

    refreshVordefinierteSystemvariablenWidget() {
        this.vordefinierteSystemvariablenWidget = WidgetFactory.createWidgetTableEntity(
            'te.config.te.vordefinierte.systemvariablen',
            'Vordefinierte Systemvariablen',
            'te.TeSystemVariablePredefined',
            'No Data',
            this.getObjectRequest());
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.uiWidgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateGroupSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('templateGroup', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshTemplateWidget(filter, event.objectId);
    }

    handleArtefactGroupSelect(event: ObjectIdentifierData) {
        const filter = [FilterCriteria.create('artefactGroup', FilterCriteria.cOperatorEqual, event.objectId)];
        this.refreshArtefactWidget(filter, event.objectId);
    }

    handleSelect(event: ObjectIdentifierData) {
        const navigationEntry =MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit)
        // this.navigationService.navigateTo(navigationEntry);
        this.navigationService.openObjectInNewTab(navigationEntry);
    }


    widgetToolbarCreate(idField: string, idValue: number) {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[`${idField}DtoId`] = idValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(`${idField}DtoId`, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }


    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }



}
