<!--<ng-container *ngIf="initialized">-->
<div #canvas class="bpmn-container"></div>
<!--</ng-container>-->

<p-dialog header="Header" [(visible)]="visible" [style]="{width: '50vw'}">
    <ng-container *ngIf="selectedActivity">

        <p-tabView>
            <p-tabPanel header="Step">

                <ng-container *ngIf="visible">
                    <mvs-widget
                            *ngIf="wfProcessTypeStepWidget"
                            [widgetData]="wfProcessTypeStepWidget"
                            (onChangedObject)="handelChangeObject($event.after.id)"
                    ></mvs-widget>
                </ng-container>

            </p-tabPanel>

            <p-tabPanel header="Activities">
                <ng-container *ngIf="visible">
                    <div class="grid">
                        <div class="col-2">

                            <mvs-widget
                                    *ngIf="wfProcessTypeStepActivityListWidget"
                                    [widgetData]="wfProcessTypeStepActivityListWidget"
                            ></mvs-widget>
                        </div>

                        <div class="col-10">
                            <mvs-widget
                                    *ngIf="wfProcessTypeStepActivityWidget"
                                    [widgetData]="wfProcessTypeStepActivityWidget"
                            ></mvs-widget>
                        </div>
                    </div>
                </ng-container>
            </p-tabPanel>

            <p-tabPanel header="Hints">
                <ng-container *ngIf="visible">
                    <mvs-widget
                            *ngIf="wfProcessTypeStepHintWidget"
                            [widgetData]="wfProcessTypeStepHintWidget"
                    ></mvs-widget>
                </ng-container>
            </p-tabPanel>

            <p-tabPanel header="Next Steps">
                <ng-container *ngIf="visible">

                    <div class="grid">
                        <div class="col-2">

                            <mvs-widget
                                    *ngIf="wfProcessTypeStepNextListWidget"
                                    [widgetData]="wfProcessTypeStepNextListWidget"
                            ></mvs-widget>
                        </div>
                        <div class="col-10">

                            <mvs-widget
                                    *ngIf="wfProcessTypeStepNextWidget"
                                    [widgetData]="wfProcessTypeStepNextWidget"
                            ></mvs-widget>
                        </div>
                    </div>

                </ng-container>
            </p-tabPanel>
        </p-tabView>

    </ng-container>
</p-dialog>