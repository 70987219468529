import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {TicketActionDto} from "../../../../dto/ticket-action.dto";
import {TicketTypeActionTypeInternal} from "../../../../enum/ticket-type-action-type-internal.enum";
import {TicketActionStatusInternal} from "../../../../enum/ticket-action-status-internal.enum";
import {SlideMenu} from "primeng/slidemenu";
import {MenuItem} from "primeng/api";
import {DtoTemplate, MvsFormValueListEntryDto, ObjectIdentifier} from "@kvers/alpha-core-common";
import {TicketActionService} from "../../../../service/api/ticket-action.service";
import {UiTicketActionDto} from "./data/ui-ticket-action.dto";
import {TicketActionAvailableResponseDto} from "../../../../service/api/dto/ticket-action-available-response.dto";
import {TmTicketTypeActionInternalTypeEnum} from "../../../../enum/tm-ticket-type-action-internal-type.enum";


@Component({
    selector: 'tm-ticket-actions',
    templateUrl: './tm-ticket-actions.component.html',
    styleUrls: ['./tm-ticket-actions.component.scss']
})
export class TmTicketActionsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ticketActionsList: TicketActionDto[]
    @Input() ticketId: number;
    @Input() processMode: boolean = false;
    @Input() availableActionsResponse: TicketActionAvailableResponseDto;
    @Input() addActions: boolean = true;
    uiTicketActionsList: UiTicketActionDto[];
    actionStatus: MvsFormValueListEntryDto[];

    objectIdentifier: ObjectIdentifier;

    buttonItems: MenuItem[];

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    toggle: boolean;

    @ViewChild('menu') sliderMenu: SlideMenu;
    @Output() onAddTicketAction = new EventEmitter<string>;
    @Output() onInitiateProcess = new EventEmitter<ObjectIdentifier>;
    @Output() onActionStatusChange = new EventEmitter<TicketActionDto>;


    constructor(protected ticketActionService: TicketActionService) {
    }

    ngOnInit(): void {

        this.objectIdentifier = new ObjectIdentifier('tm.Ticket', this.ticketId);

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        if (this.processMode == true) {
            this.toggle = true;
        }

        this.retrieveTemplate();

    }


    toggleSlideMenu(event: MouseEvent) {
        this.sliderMenu.toggle(event)
    }

    initializeUiTicketActions(): void {
        const uiTicketActions: UiTicketActionDto[] = [];

        if (!this.ticketActionsList || !this.ticketActionsList.length) {
            this.uiTicketActionsList = uiTicketActions;
            return;
        }

        this.ticketActionsList = this.ticketActionsList.sort((a, b) => a.id - b.id);

        for (const action of this.ticketActionsList) {
            const uiAction = new UiTicketActionDto();

            uiAction.ticketAction = action;
            uiAction.showComments = false;
            uiAction.status = TicketActionStatusInternal[action.statusInternal];
            uiAction.commentsIdentifier = new ObjectIdentifier('tm.TicketAction', action.id);

            if (action.ticketTypeActionDto?.internalType == +TicketTypeActionTypeInternal.process) {
                uiAction.isProcess = true;
            } else if (action.ticketTypeActionDto?.internalType == +TicketTypeActionTypeInternal.ticket) {
                uiAction.isTicket = true;
            } else if (action.ticketTypeActionDto?.internalType == +TicketTypeActionTypeInternal.notification) {
                uiAction.isNotification = true;
            } else {
                uiAction.isProcess = false;
                uiAction.isTicket = false;
                uiAction.isNotification = false;
            }

            if (uiAction.isProcess) {

                if (action.processDtoId) {
                    uiAction.isStarted = true;
                } else {
                    uiAction.isStarted = false;
                }

                uiAction.icon = uiAction.isStarted ? 'fa-regular fa-circle-pause text-0' : 'fa-regular fa-circle-play text-0';
                uiAction.buttonClass = uiAction.isStarted ? 'bg-primary-700' : 'bg-green-400';
                uiAction.tooltip = uiAction.isStarted ? 'Resume Process' : 'Start Process';
            }

            if (uiAction.isTicket) {

                if (action.actionTicketDtoId) {
                    uiAction.isStarted = true;
                } else {
                    uiAction.isStarted = false;
                }

                uiAction.icon = uiAction.isStarted ? 'fa-regular fa-play-pause text-0' : 'fa-regular fa-circle-play text-0';
                uiAction.buttonClass = uiAction.isStarted ? 'bg-green-400' : 'bg-primary-700';
                uiAction.tooltip = uiAction.isStarted ? 'Resume Ticket' : 'Start Ticket';
            }

            if (uiAction.isNotification) {

                if (action.notificationDtoId) {
                    uiAction.isStarted = true;
                } else {
                    uiAction.isStarted = false;
                }

                uiAction.icon = uiAction.isStarted ? 'pi pi-send text-0' : 'pi pi-send text-0';
                uiAction.buttonClass = uiAction.isStarted ? 'bg-primary-700' : 'bg-green-400';
                uiAction.tooltip = uiAction.isStarted ? 'Show Notification' : 'Send Notification';
            }

            if (action.ticketTypeActionDto?.internalType != +TicketTypeActionTypeInternal.process && action.ticketTypeActionDto?.internalType != +TicketTypeActionTypeInternal.ticket && action.ticketTypeActionDto?.internalType != +TicketTypeActionTypeInternal.notification) {
                uiAction.showSimple = true;
            }

            uiTicketActions.push(uiAction);
        }

        this.uiTicketActionsList = uiTicketActions;
    }

    prepareContextMenu() {

        if (!this.availableActionsResponse) {
            return;
        }

        const predefinedProcessMenuItem: MenuItem = {
            label: 'Predefined',
            icon: 'fa-regular fa-sharp fa-circle-info fa-lg',
            type: 'regular',
            id: 'predefined'
        };

        this.buttonItems = [];

        let hasPredefinedProcess = false;

        for (let action of this.availableActionsResponse.availableActions) {
            const internalType = action.ticketTypeAction.internalType;

            if (internalType === TmTicketTypeActionInternalTypeEnum.user_defined) {
                this.buttonItems.push({
                    label: action.ticketTypeAction.name,
                    icon: action.ticketTypeAction.image || 'pi pi-search',
                    type: 'regular',
                    id: 'individual.' + action.ticketTypeAction.id
                });
            } else if (internalType === TmTicketTypeActionInternalTypeEnum.simple ||
                internalType === TmTicketTypeActionInternalTypeEnum.ticket ||
                internalType === TmTicketTypeActionInternalTypeEnum.process ||
                internalType === TmTicketTypeActionInternalTypeEnum.notification) {
                hasPredefinedProcess = true;
            }
        }

        if (hasPredefinedProcess) {
            this.buttonItems.unshift(predefinedProcessMenuItem);
        }

        for (let item of this.buttonItems) {
            item.command = () => this.handleAddTicketAction(item);
        }

    }

    triggerManualAction(action: UiTicketActionDto) {

        if (action.isNotification) {
            this.handleActiveProcess(action);
            return;
        }

    }

    triggerAction(action: UiTicketActionDto) {

        if (this.busy) {
            return;
        }

        // the action was already started or finished goto active process
        if (action.isStarted) {
            this.handleActiveProcess(action);
            return;
        }

        this.busy = true;

        // trigger action
        this.ticketActionService.triggerAction(action.ticketAction.id).subscribe((value: TicketActionDto) => {
            if (!action.isNotification) {
                this.postTriggerAction(action);
            }else {
                this.onActionStatusChange.emit(value)
            }
            this.busy = false;
        }, error => {
            this.busy = false;
        });
    }

    postTriggerAction(action: UiTicketActionDto) {
        this.handleActiveProcess(action);
    }

    retrieveTemplate() {
        this.ticketActionService.template(new DtoTemplate()).subscribe(res => {
            const status = res.getFormField('statusInternal');
            this.actionStatus = status.valueList.entries;
        })
    }

    handleAddTicketAction(item: MenuItem) {
        this.onAddTicketAction.emit(item.id);
    }

    toggleShowComments(action: UiTicketActionDto) {
        action.showComments = !action.showComments;
    }


    handleActiveProcess(action: UiTicketActionDto) {
        const identifier = new ObjectIdentifier("tm.TicketAction", action.ticketAction.id);
        this.onInitiateProcess.emit(identifier);
    }

    _handleActiveProcess(actionType: TmTicketTypeActionInternalTypeEnum, objectId: number) {
        let objectType: string;

        if (actionType == TmTicketTypeActionInternalTypeEnum.process) {
            objectType = 'wf.WfProcess';
        } else if (actionType == TmTicketTypeActionInternalTypeEnum.ticket) {
            objectType = 'tm.Ticket';
        } else if (actionType == TmTicketTypeActionInternalTypeEnum.notification) {
            objectType = 'tm.TicketAction';
        }

        const identifier = new ObjectIdentifier(objectType, objectId);
        this.onInitiateProcess.emit(identifier);
    }

    handleStatusChange(event: any, action: UiTicketActionDto) {
        const dto = new TicketActionDto();
        dto.id = action.ticketAction.id;
        dto.statusInternal = event.value;
        this.ticketActionService.update(dto).subscribe(res => {
            action.status = TicketActionStatusInternal[dto.statusInternal];
            this.onActionStatusChange.emit(<TicketActionDto>res)
        });
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initializeUiTicketActions();
        this.prepareContextMenu();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    protected readonly TmTicketTypeActionInternalTypeEnum = TmTicketTypeActionInternalTypeEnum;
}
