export enum DmDocumentStatusInternalEnum{

    uploaded,
    to_be_checked,
    assigned,
    approved,
    rejected,
    completed,
    removed,
    error

}