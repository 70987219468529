<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-3">
            <mvs-widget
                    *ngIf="contractStatusWidgetData"
                    [widgetData]="contractStatusWidgetData"
                    (onObjectSelect)="handleCategoryStatusSelect($event)"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="contractStatusPeriodWidgetData"
                    [widgetData]="contractStatusPeriodWidgetData"
                    (onObjectSelect)="handleCategoryStatusPeriodSelect($event)"
            ></mvs-widget>
        </div>

        <div class="col-9">
            <mvs-widget
                    *ngIf="contractsWidgetData"
                    [widgetData]="contractsWidgetData"
                    (onObjectDataChange)="handleObjectDataChange($event)"
            ></mvs-widget>
        </div>

    </div>

</ng-container>
