import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";
import {UiFieldService} from "../../../../service/api/ui-field.service";
import {UiPageService} from "../../../../service/api/ui-page.service";


@Component({
    selector: 'mvs-example',
    templateUrl: 'ui-kpi.component.html',
})
export class UiKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalFields: number = 0;
    totalPages: number = 0;
    thisMonthFields: number = 0;
    thisWeekFields: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'UI Module'}];

    constructor(
        protected uiFieldService: UiFieldService,
        protected uiPageService: UiPageService
    ) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalFields();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalFields() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['defaultAttributeSetting'],
            [new ObjectRequestListAttribute('defaultAttributeSetting', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.uiFieldService.groupBy(objectRequestList).subscribe(res => {
            this.totalFields = res.entries.reduce((acc, item) => acc + item['defaultAttributeSetting_count'], 0);
            this.getTotalPages();
        }, error => {
            this.getTotalPages();
        });
    }

    getTotalPages() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['pageRoute'],
            [new ObjectRequestListAttribute('pageRoute', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.uiPageService.groupBy(objectRequestList).subscribe(res => {
            this.totalPages = res.entries.reduce((acc, item) => acc + item['pageRoute_count'], 0);
            this.getThisMonthFields();
        }, error => {
            this.getThisMonthFields();
        });
    }

    getThisMonthFields() {
        const objectRequestList = this.getThisMonthRequest('defaultAttributeSetting');
        this.uiFieldService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthFields = res.entries.reduce((acc, item) => acc + item['defaultAttributeSetting_count'], 0);
            this.getThisWeekFields();
        }, error => {
            this.getThisWeekFields();
        });
    }

    getThisWeekFields() {
        const objectRequestList = this.getThisWeekRequest('defaultAttributeSetting');
        this.uiFieldService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekFields = res.entries.reduce((acc, item) => acc + item['defaultAttributeSetting_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Fields', value: this.totalFields,
            },
            {
                label: 'Total Pages', value: this.totalPages,
            },
            {
                label: 'This Month Fields', value: this.thisMonthFields,
            },
            {
                label: 'This Week Fields', value: this.thisWeekFields,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
