import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiCrudServiceTagsInterface} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class CustomerTagService extends MvsCrudService implements MvsApiCrudServiceTagsInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerTags');
  }


  /**
   * Return linked attribute (required to resolve 1:N relationships).
   * @param objectType
   */
  getLinkedAttribute(objectType: string): string {

    if (objectType === "cr.Customer") {
      return "customer";
    } else {
      return super.getLinkedAttribute(objectType);
    }
  }

  getTagAttribute(): string {
    return "tag";
  }

  getTagCategoryAttribute(): string {
    return "tagCategoryEnum";
  }

  hasTagCategory(): boolean {
    return true;
  }
}
