export class ConditionsHelper {
    public static extractSubPath(input: string, attributePath: string): string {
        const pattern = /\${([^!}]*)[^}]*}/;
        const match = input.match(pattern);

        if (match && match[1]) {
            let cleanPath = match[1].replace(/[\(\)]/g, '');

            let path = cleanPath.replace(attributePath + '.', '');

            const parts = path.split('.');
            if (parts.length > 1) {
                return parts[parts.length - 2];
            }
        }
        return '-';
    }

    public static insertNullChecks(fullPath: string) {
        const parts = fullPath.split('.');
        let pathWithChecks = parts.slice(0, -1).join('.');
        const lastPart = parts[parts.length - 1];

        // Construct the condition for the last part with proper parentheses
        if (lastPart.includes('?number')) {
            const propertyWithoutNumber = lastPart.replace('?number', '');
            pathWithChecks = `(${pathWithChecks}.${propertyWithoutNumber})?exists && (${pathWithChecks}.${lastPart})`;
        } else {
            pathWithChecks = `(${pathWithChecks}.${lastPart})?exists && (${pathWithChecks}.${lastPart})`;
        }

        return pathWithChecks;
    }


}