
<ng-container *ngIf="initialized">
    <a #hiddenLink style="display: none;"></a>

    <div class="grid">
        <div class="col-2">
            <!-- show customers created the last few days -->
            <mvs-widget
                    *ngIf="createdCustomersWidgetData"
                    [widgetData]="createdCustomersWidgetData"
                    (onObjectSelect)="onCustomerSelect($event)"
            ></mvs-widget>
        </div>
        <div class="col-10">
            <mvs-cr-create-object-customer></mvs-cr-create-object-customer>
        </div>

    </div>

</ng-container>
