import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {FieldPage} from "./page/overview/field-page/field.page";
import {ObjectTypePage} from "./page/overview/object-type-page/object-type.page";
import {EntityBrowserComponent} from "./component/mvs-entity-browser/entity-browser.component";
import {EntityOverviewPage} from "./page/overview/entity-overview-page/entity-overview.page";
import {ObjectTypeMetaExtensionPage} from "./page/overview/object-type-meta-extension/object-type-meta-extension.page";
import {ObjectTypeObjectComponent} from "./component/object-type-object-component/object-type-object.component";
import {ConfigComponent} from "./page/overview/config-page/config.component";
import {AsModule} from "../as/as.module";
import {CcOverviewPage} from "./page/cc-overview-page/cc-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {CcStatisticsComponent} from "./page/cc-overview-page/components/statisticsComponent/cc-statistics.component";
import {CcKpiComponent} from "./page/cc-overview-page/components/kpiComponent/cc-kpi.component";
import {UiModule} from "../ui/ui.module";
import {
    FieldTypeObjectBaseComponent
} from "./component/object-component/field-type-object-component/field-type-object-base.component";
import {
    FieldTypeBaseComponent
} from "./component/object-component/field-type-object-component/view/field-type-base.component";
import {
    FieldTypeSideComponent
} from "./component/object-component/field-type-object-component/view/side/field-type-side.component";
import {
    FieldTypeFullComponent
} from "./component/object-component/field-type-object-component/view/full/field-type-full.component";
import {
    FieldValueListAndEntriesComponent
} from "./component/object-component/field-type-object-component/shared/field-value-list-and-entries/field-value-list-and-entries.component";
import {
    ValueListObjectBaseComponent
} from "./component/object-component/value-list-object-component/value-list-object-base.component";
import {
    ValueListBaseComponent
} from "./component/object-component/value-list-object-component/view/value-list-base.component";
import {
    ValueListSideComponent
} from "./component/object-component/value-list-object-component/view/side/value-list-side.component";
import {
    ValueListFullComponent
} from "./component/object-component/value-list-object-component/view/full/value-list-full.component";
import {
    MetaExtensionObjectComponent
} from "./component/object-component/meta-extension-object-component/meta-extension-object.component";
import {
    MetaExtensionBaseComponent
} from "./component/object-component/meta-extension-object-component/view/meta-extension-base.component";
import {
    MetaExtensionSideComponent
} from "./component/object-component/meta-extension-object-component/view/side/meta-extension-side.component";
import {
    MetaExtensionFullComponent
} from "./component/object-component/meta-extension-object-component/view/full/meta-extension-full.component";

@NgModule({
    declarations: [
        FieldPage,
        ObjectTypePage,
        EntityBrowserComponent,
        EntityOverviewPage,
        ObjectTypeMetaExtensionPage,
        ObjectTypeObjectComponent,
        ConfigComponent,
        CcOverviewPage,
        CcStatisticsComponent,
        CcKpiComponent,
        FieldTypeObjectBaseComponent,
        FieldTypeBaseComponent,
        FieldTypeSideComponent,
        FieldTypeFullComponent,
        FieldValueListAndEntriesComponent,
        ValueListObjectBaseComponent,
        ValueListBaseComponent,
        ValueListSideComponent,
        ValueListFullComponent,
        MetaExtensionObjectComponent,
        MetaExtensionBaseComponent,
        MetaExtensionSideComponent,
        MetaExtensionFullComponent,

    ],
    exports: [
        FieldPage,
        ObjectTypePage,
        EntityBrowserComponent,
        EntityOverviewPage,
        ObjectTypeMetaExtensionPage,
        ObjectTypeObjectComponent,
        FieldTypeObjectBaseComponent,
        FieldTypeBaseComponent,
        FieldTypeSideComponent,
        FieldTypeFullComponent,
        FieldValueListAndEntriesComponent,
        ValueListObjectBaseComponent,
        ValueListBaseComponent,
        ValueListSideComponent,
        ValueListFullComponent,
        MetaExtensionObjectComponent,
        MetaExtensionBaseComponent,
        MetaExtensionSideComponent,
        MetaExtensionFullComponent,
    ],
    imports: [
        CoreModule,
        AsModule,
        UiModule,
    ]
})
export class CcModule implements DynamicModule {

    alias = 'cc';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return CcKpiComponent;
    }

    getStatisticsComponent() {
        return CcStatisticsComponent;
    }
}
