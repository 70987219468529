import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, FilterCriteria,
    MvsCoreService, ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {AppointmentService} from "../../../../../as/service/api/appointment.service";
import {AppointmentStatusInternal} from "../../../../../as/enum/appointment-status-internal.enum";
import {getStartOfMonth} from "../../../../../core/helper/date-utils";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'agent-statistics.component.html',
})
export class AgentStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    appointmentCompleted: number = 0;
    pendingAppointment: number = 0;
    scheduledAppointment: number = 0;
    cancelledAppointment: number = 0;

    overviewDto: OverviewDto[];

    appointmentsPerMonthWidget: WidgetData;
    agentCategoryByAgentPoolWidget: WidgetData;
    appointmentTypeWidget: WidgetData;
    recentlyJoinedAgentsWidget: WidgetData;

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected appointmentService: AppointmentService,) {
        super(route, coreService)
    }

    ngOnInit(): void {

        this.items = [
            {label: 'Agent Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getAppointmentResult();
        this.refreshAppointmentsPerMonthWidgets();
        this.refreshAgentCategoryByAgentPoolWidgets();
        this.refreshAppointmentTypeWidgets();
        this.refreshRecentlyJoinedAgents();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/am/config']);
    }


    getAppointmentResult() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.appointmentService.groupBy(objectRequestList).subscribe(res => {

            this.overviewDto = [
                { label: 'Appointment Completed', value: 0 },
                { label: 'Pending Appointments', value: 0 },
                { label: 'Scheduled Appointments', value: 0 },
                { label: 'Cancelled Appointments', value: 0 }
            ];

            for (const entry of res.entries) {
                if (entry['status'] === AppointmentStatusInternal.completed) {
                    this.overviewDto.find(item => item.label === 'Appointment Completed').value = entry['status_count'];
                } else if (entry['status'] === AppointmentStatusInternal.pending) {
                    this.overviewDto.find(item => item.label === 'Pending Appointments').value = entry['status_count'];
                } else if (entry['status'] === AppointmentStatusInternal.scheduled) {
                    this.overviewDto.find(item => item.label === 'Scheduled Appointments').value = entry['status_count'];
                } else if (entry['status'] === AppointmentStatusInternal.cancelled) {
                    this.overviewDto.find(item => item.label === 'Cancelled Appointments').value = entry['status_count'];
                }
            }



        });

    }

    refreshAppointmentsPerMonthWidgets() {
        this.appointmentsPerMonthWidget = new WidgetData();
        this.appointmentsPerMonthWidget.idAlias = "am.agent.overview.appointments.per.month.widget";
        this.appointmentsPerMonthWidget.name = "Appointments Per Month";
        this.appointmentsPerMonthWidget.uiComponent = "history.chart";
        this.appointmentsPerMonthWidget.dataProvider = "list";
        this.appointmentsPerMonthWidget.dataSource = "entity.groupBy";
        this.appointmentsPerMonthWidget.dataProviderObject = "as.Appointment";
        this.appointmentsPerMonthWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["appointmentType", "createdDate"],
            [new ObjectRequestListAttribute("appointmentType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.appointmentsPerMonthWidget.setParamValue("fieldCategory", "appointmentType");
        this.appointmentsPerMonthWidget.setParamValue("fieldCategoryCount", "appointmentType_count");
        this.appointmentsPerMonthWidget.setParamValue("historyPeriodMode", "year");
        this.appointmentsPerMonthWidget.setParamValue("fieldDate", "createdDate");
        this.appointmentsPerMonthWidget.setParamValue("selectionMode", "single");
        this.appointmentsPerMonthWidget.setParamValue("size", "S");
    }

    refreshAgentCategoryByAgentPoolWidgets() {
        this.agentCategoryByAgentPoolWidget = WidgetFactory.createWidgetGroupBy(
            'ag.agent.overview.agent.category.by.agent.pool.widget',
            'Agent Category By Agent Pool',
            'category',
            'entity.groupBy',
            'am.AgentPoolAgent',
            'agentPool',
            'agentPool_count',
            this.filterCriteria,
            'agentPool',
            'Anzahl');
    }

    refreshAppointmentTypeWidgets() {
        this.appointmentTypeWidget = WidgetFactory.createWidgetGroupBy(
            'ag.agent.overview.appointment.type.widget',
            'Appointment Types',
            'category',
            'entity.groupBy',
            'as.Appointment',
            'appointmentType',
            'appointmentType_count',
            this.filterCriteria,
            'appointmentType',
            'Anzahl');
    }

    refreshRecentlyJoinedAgents() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())

        const complexRequest = ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createSimpleAttributeNode('user'))

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            true,
            complexRequest,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
            null
        );
        this.recentlyJoinedAgentsWidget = WidgetFactory.createWidgetTableEntity(
            'ag.agent.overview.recently.joined.agents',
            'Recently Joined Agents',
            'am.Agent',
            'No Agents',
            objectRequestList
        )
    }



    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
