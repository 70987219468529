import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    EntityStatusEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy, ObjectRequestRelation,
    PageComponent,
    PagingDto,
    Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {CustomerService} from "../../../../service/api/customer.service";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'customer-statistics.component.html',
})
export class CustomerStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    newCustomers: number = 0;
    activeCustomers: number = 0;
    customerInteraction: number = 0;
    customerChurnRate: number = 0;

    customerTypeWidget: WidgetData;
    customerDistributionBrokerageWidget: WidgetData;
    customerDistributionByConsultationTypeWidget: WidgetData;
    customerListWithMostOpenTicketsWidget: WidgetData;
    customerGrowthByQuarterWidget: WidgetData;


    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            { label: 'Agent Module' },
        ];

        this.home = { icon: 'pi pi-home', routerLink: '/' };

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshCustomerTypeWidgets();
        this.refreshCustomerDistributionBrokerageWidgets();
        this.refreshCustomerDistributionConsultationTypeWidgets();
        this.refreshCustomerWithMostOpenTicketsWidgets();
        this.getCustomerOverviewThisMonth();
        this.getCustomerInteractionThisMonth();

        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/cr/config']);
    }

    refreshCustomerTypeWidgets() {
        this.customerTypeWidget = new WidgetData();
        this.customerTypeWidget.idAlias = "cr.customer.overview.customer.type.widget";
        this.customerTypeWidget.name = "Customer Type";
        this.customerTypeWidget.uiComponent = "history.chart";
        this.customerTypeWidget.dataProvider = "list";
        this.customerTypeWidget.dataSource = "entity.groupBy";
        this.customerTypeWidget.dataProviderObject = "cr.Customer";
        this.customerTypeWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["customerType", "createdDate"],
            [new ObjectRequestListAttribute("customerType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.customerTypeWidget.setParamValue("fieldCategory", "customerType");
        this.customerTypeWidget.setParamValue("fieldCategoryCount", "customerType_count");
        this.customerTypeWidget.setParamValue("historyPeriodMode", "year");
        this.customerTypeWidget.setParamValue("fieldDate", "createdDate");
        this.customerTypeWidget.setParamValue("selectionMode", "single");
        this.customerTypeWidget.setParamValue("size", "S");
    }

    refreshCustomerDistributionBrokerageWidgets() {
        this.customerDistributionBrokerageWidget = WidgetFactory.createWidgetGroupBy(
            'cr.customer.overview.customer.distribution.brokerage.widget',
            'Customer Distribution By Brokerage Reason',
            'category',
            'entity.groupBy',
            'cr.Customer',
            'brokerPoaTypeReason',
            'brokerPoaTypeReason_count',
            this.filterCriteria,
            'brokerPoaTypeReason',
            'Anzahl');
    }

    refreshCustomerDistributionConsultationTypeWidgets() {
        this.customerDistributionByConsultationTypeWidget = WidgetFactory.createWidgetGroupBy(
            'cr.customer.overview.customer.distribution.consultation.type.widget',
            'Customer Distribution by Consultation Type',
            'category',
            'entity.groupBy',
            'cr.Customer',
            'customerConsultingType',
            'customerConsultingType_count',
            this.filterCriteria,
            'customerConsultingType',
            'Anzahl');
    }

    refreshCustomerWithMostOpenTicketsWidgets() {

        const complexRequest = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("tmActiveTickets", ObjectRequestRelation.createJoin("+tmActiveTickets")),
        )

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            complexRequest,
            this.filterCriteria,
            [],
            PagingDto.create(0, 5));
        // new Sorting('+tmActiveTickets', false)

        this.customerListWithMostOpenTicketsWidget = WidgetFactory.createWidgetTableEntity(
            'cr.customer.most.open.tickets',
            'Customer List with Most Open Tickets',
            'cr.Customer',
            'No Data',
            objectRequest);
    }

    getCustomerOverviewThisMonth() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.customerService.groupBy(objectRequestList).subscribe(res => {

            this.newCustomers = res.entries.length;

            res.entries.forEach(item => {
                if (item['entityStatus'] === EntityStatusEnum.active) {
                    this.activeCustomers += item['entityStatus_count'];
                } else if (item['entityStatus'] === EntityStatusEnum.discarded || item['entityStatus'] == EntityStatusEnum.deleted || (item['entityStatus'] === EntityStatusEnum.obsolete)) {
                    this.customerChurnRate += item['entityStatus_count'];
                }
            });

            // Initialize overviewDto with default values
            this.overviewDto = [
                { label: 'New Customers', value: 0 },
                { label: 'Active Customers', value: 0 },
                { label: 'Customer Interactions', value: 0 },
                { label: 'Customer Churn Rate', value: 0 }
            ];


            for (let item of res.entries) {
                if (item['entityStatus'] === EntityStatusEnum.active) {
                    this.overviewDto.find(entry => entry.label === 'Active Customers').value += item['entityStatus_count'];
                } else if (
                    item['entityStatus'] === EntityStatusEnum.discarded ||
                    item['entityStatus'] === EntityStatusEnum.deleted ||
                    item['entityStatus'] === EntityStatusEnum.obsolete
                ) {
                    this.overviewDto.find(entry => entry.label === 'Customer Churn Rate').value += item['entityStatus_count'];
                }
            }

        });

    }

    getCustomerInteractionThisMonth() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [FilterCriteria.create('lastModifiedDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.customerService.groupBy(objectRequestList).subscribe(res => {
            this.customerInteraction = res.entries.length;
        });

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
