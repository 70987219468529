@if (initialized) {
    <mvs-widget
            *ngIf="provisionTypeWidget"
            [widgetData]="provisionTypeWidget"
    ></mvs-widget>


    <mvs-widget
            *ngIf="provisionTypeAgentPaymentWidget"
            [widgetData]="provisionTypeAgentPaymentWidget"
    ></mvs-widget>


    <mvs-widget
            *ngIf="provisionTypePartnerPaymentWidget"
            [widgetData]="provisionTypePartnerPaymentWidget"
    ></mvs-widget>
}