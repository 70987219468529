
<div class="relative">
<!--    margin-bottom: 45px;-->
<!--    overflow: auto;-->
<!--    /* max-height: 100%; */-->
<!--    /* width: 100%; */-->
<!--    /* display: block; */-->
<!--    max-height: 220px;-->
    <div id="editor-container" #editor class=""
         style="max-height: 250px; overflow: auto"></div>

    <div *ngIf="busy" class="absolute right-0 left-0 top-0 bottom-0 flex align-items-center justify-content-center w-full">
        <p-progressSpinner class="flex w-5rem h-5rem" ></p-progressSpinner>
    </div>

    <div class="quill-footer w-full">
        <div class="w-full flex justify-content-between px-3">
            <div class="flex align-items-center gap-2">
                @if (isEndUserTicket) {
                    <p-checkbox [(ngModel)]="euVisible" [binary]="true"></p-checkbox>
                    <span class="text-600">EU visible</span>
                }

            </div>
            <p-button
                    (click)="saveContent()" [disabled]="buttonDisabled()" style="background: transparent !important;"
                    icon="pi pi-send"
                    styleClass="p-button-rounded p-button-ion-only p-1">
            </p-button>

        </div>
    </div>
</div>
<!--<div class="toolbar mt-3 justify-content-between">-->
<!--    <div>-->
<!--        &lt;!&ndash;       <p-button *ngIf="recoveredText"&ndash;&gt;-->
<!--        &lt;!&ndash;                 pTooltip="Use recovered value"&ndash;&gt;-->
<!--        &lt;!&ndash;                 tooltipPosition="left"&ndash;&gt;-->
<!--        &lt;!&ndash;                 class="align-self-center"&ndash;&gt;-->
<!--        &lt;!&ndash;                 styleClass="w-2rem h-2rem p-button-rounded"&ndash;&gt;-->
<!--        &lt;!&ndash;                 icon="fa-regular fa-arrow-rotate-right text-sm"&ndash;&gt;-->
<!--        &lt;!&ndash;                 (click)="loadRecoveredValue()">&ndash;&gt;-->
<!--        &lt;!&ndash;       </p-button>&ndash;&gt;-->
<!--    </div>-->

<!--    <div class="flex gap-3">-->
<!--        &lt;!&ndash;    <p-button *ngIf="recognition; else startButton"&ndash;&gt;-->
<!--        &lt;!&ndash;              (click)="stopSpeechToText()"&ndash;&gt;-->
<!--        &lt;!&ndash;              icon="fa-regular fa-circle-stop"&ndash;&gt;-->
<!--        &lt;!&ndash;              styleClass="p-button-text p-button-rounded bg-red-500 text-white">&ndash;&gt;-->
<!--        &lt;!&ndash;    </p-button>&ndash;&gt;-->

<!--        &lt;!&ndash;    <ng-template #startButton>&ndash;&gt;-->
<!--        &lt;!&ndash;        <p-button&ndash;&gt;-->
<!--        &lt;!&ndash;                (click)="startSpeechToText()"&ndash;&gt;-->
<!--        &lt;!&ndash;                icon="fa-regular fa-microphone"&ndash;&gt;-->
<!--        &lt;!&ndash;                styleClass="p-button-text p-button-rounded">&ndash;&gt;-->
<!--        &lt;!&ndash;        </p-button>&ndash;&gt;-->
<!--        &lt;!&ndash;    </ng-template>&ndash;&gt;-->
<!--        <p-button (click)="saveContent()" [disabled]="buttonDisabled()" [label]="iconLabel"></p-button>-->
<!--    </div>-->

<!--</div>-->
