import { Component, OnChanges, OnInit } from '@angular/core';
import { ObjectBaseComponent } from "@kvers/alpha-core-common";
import { MvsCoreService } from "@kvers/alpha-core-common";
import { MvsMessageService } from "@kvers/alpha-core-common";
import { ConfirmationService } from "primeng/api";
import { TeArtefactDto } from "../../dto/te-artefact.dto";
import { TeArtefactService } from "../../service/api/te-artefact.service";
import { WidgetData } from "@kvers/alpha-core-common";
import { TinymceAutosave } from '../../logic/tinymce-autosave';

@Component({
  selector: 'mvs-te-artefact-object',
  templateUrl: './te-artefact-object.component.html',
  styleUrls: ['./te-artefact-object.component.scss']
})
export class TeArtefactObjectComponent
  extends ObjectBaseComponent
  implements OnInit, OnChanges {

  dto: TeArtefactDto;
  crudService: TeArtefactService;

  widgetBasicData: WidgetData;
  showBasicData: boolean;
  private autoSave =  new TinymceAutosave();


  /**
   * Save content.
   * @param content
   */
  handleEditorOnSave(content: string) {

    const dto = new TeArtefactDto();
    dto.id = this.dto.id;
    dto.content = content;

    this.crudService.update(dto).subscribe({
      next: (value) => {
        this.messageService.showSuccessMessageSaved();
        console.log('Updated content data into API');
        let editorContentFromCookie = this.autoSave.getAutoSaveCookie('te.TeArtefactVariable'+this.dto.id);
        if (editorContentFromCookie && dto.content === editorContentFromCookie?.content) {
          this.autoSave.deleteAutoSaveCookie('te.TeTemplateVariable'+this.dto.id);
          this.dto = <TeArtefactDto>value; // take over value
        }
      },
      error: (error) => {
        this.messageService.showErrorMessage('Error', 'Something went wrong');
      }
    });

  }

  handleToggleBasicData() {

    if (this.showBasicData) {

      this.widgetBasicData = new WidgetData();
      this.widgetBasicData.idAlias = "te.config.artefact.view";
      this.widgetBasicData.name = "Artefakt";
      this.widgetBasicData.uiComponent = "data";
      this.widgetBasicData.dataProvider = "list";
      this.widgetBasicData.dataSource = "entity";
      this.widgetBasicData.dataProviderObject = this.objectIdentifier.objectType;
      this.widgetBasicData.setParamValue("objectId", this.objectIdentifier.objectId);

    } else {
      this.widgetBasicData = null;
    }
  }
}
