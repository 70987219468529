import {Component, OnInit,} from '@angular/core';
import {FilterCriteria, MvsCoreService, ObjectRequestList, PageComponent, WidgetData,} from "@kvers/alpha-core-common";
import {MenuItem, TreeNode} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {EmSchemaStructureService} from "../../../../em/service/api/em-schema-structure.service";
import {
    MvsConditionBuilderService,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {AppointmentService} from "../../../../as/service/api/appointment.service";
import {AppointmentFreeSlot, FreeSlotRequestDto} from "../../../../as/service/dto/free-slot-request.dto";

@Component({
    selector: 'mvs-test-form-ather',
    templateUrl: 'test-ather.page.html',
    styleUrls: ['./test-ather.page.scss']
})
export class TestAtherPage extends PageComponent implements OnInit {

    breadCrumb: MenuItem[] = [];
    personAddressWidget: WidgetData;

    nodes: TreeNode[] = [];
    expandedNode: TreeNode;
    selectedNode: TreeNode;

    freeSlots: Map<number, AppointmentFreeSlot[]> = new Map();

    agents = [{id: 157, name: 'Marko'}, {id: 5, name: 'Carlo'}]

    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected appointmentService: AppointmentService,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected conditionBuilderService: MvsConditionBuilderService,
        protected schemaStructureService: EmSchemaStructureService,) {
        super(route, coreService);
    }

    fetchFreeSlots() {
        const now = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(now.getDate() + 1); // Example: Next day

        const requestDto: FreeSlotRequestDto = {
            agentIds: [157, 5],
            timeFrames: [
                {
                    start: now.toISOString(),   // Converts JavaScript Date to Instant-compatible format
                    end: tomorrow.toISOString()
                }
            ],
            appointmentTypeId: 2
        };

        this.appointmentService.getFreeSlots(requestDto).subscribe(
            (response) => {
                this.freeSlots = response;
                console.log('Fetched Free Slots:', this.freeSlots);
            },
            (error) => {
                console.error('Error fetching free slots:', error);
            }
        );
    }

    ngOnInit(): void {
        super.ngOnInit();


        this.breadCrumb = [
            { label: "Home", url: "/home" },
            { label: "Dashboard", url: "/dashboard" },
        ];

        this.personAddressWidget = WidgetFactory.createWidgetObject(
            'asdaseqweqwe', 'person address', 'pm.PersonAddress', 0
        )

        // FilterCriteria.create('structureType', FilterCriteria.cOperatorEqual, 0)
        const filterCriteria = [FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, 602), FilterCriteria.create('structureType', FilterCriteria.cOperatorEqual, 0)];
        const objectRequestList = ObjectRequestList.createBasic(false, filterCriteria, []);

        // this.schemaStructureService.list(objectRequestList).subscribe(res => {
        //     this.nodes = this.convertToTreeNodes(res.entries); // Convert API data
        // })

    }

    updateBreadcrumbs() {
        this.breadCrumb = [
            { label: "Home", url: "/home" },
            { label: "Dashboard", url: "/dashboard" },
            { label: "New Meta Page", url: "/meta/new" } // Dynamically added breadcrumb
        ];
    }

    showMetaPreSelect() {

        const params: any = {
            breadcrumbs: [
                { id:'cr.Customer', label: "Customer"},
                { id:'pm.Person', label: "Person"},
            ]
        };

        const entry = MvsObjectNavigationEntry.createNavigationEntry('cc.ObjectTypeMetaJoinExtension', 0, 'object', null, null, null, MvsObjectNavigationActionEnum.any, 'side', params);
        this.navigationService.navigateTo(entry, 'right')
    }

    showMeta() {

        const entry = MvsObjectNavigationEntry.createNavigationEntry('cc.ObjectTypeMetaJoinExtension', 0, 'object', null, null, null, MvsObjectNavigationActionEnum.any, 'side');
        this.navigationService.navigateTo(entry, 'right')
    }

    showBuilder() {


        this.conditionBuilderService.showConditionBuilderEntityDialog(
            null,
            243,
            'am.Agent',
            false,
            (filterCriteria: FilterCriteria[]) => {
            }
        );
    }


    navigateContract() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry('cm.Contract', 51404, 'object', null, null, null, MvsObjectNavigationActionEnum.any, 'side');
        this.navigationService.navigateTo(navigationEntry, 'right');
    }

    /**
     * Converts API data into PrimeNG TreeNode format with lazy loading support.
     */
    convertToTreeNodes(apiData: any[]): TreeNode[] {
        const fieldMap: Map<number, TreeNode> = new Map(); // Stores all unique schema fields
        const structureMap: Map<number, TreeNode[]> = new Map(); // Maps parent fields to child nodes
        const rootNodes: TreeNode[] = [];

        // Step 1: Create TreeNode for each schema field in schemaStructure
        apiData.forEach(entry => {
            // Ensure parent field is added to fieldMap
            if (entry.parentFieldDtoId && !fieldMap.has(entry.parentFieldDtoId)) {
                fieldMap.set(entry.parentFieldDtoId, {
                    key: entry.parentFieldDtoId.toString(),
                    label: entry.parentFieldDtoName,
                    data: {
                        id: entry.parentFieldDtoId,
                        name: entry.parentFieldDtoName,
                        schemaStructureId: entry.id // Use the current entry's id for the parent
                    },
                    children: [],
                    leaf: false,
                    expanded: false,
                    icon: "fa-solid fa-folder",
                    type: "PARENT"
                });
            }

            // Ensure child field is added to fieldMap
            if (entry.childFieldDtoId && !fieldMap.has(entry.childFieldDtoId)) {
                fieldMap.set(entry.childFieldDtoId, {
                    key: entry.childFieldDtoId.toString(),
                    label: entry.childFieldDtoName || entry.name,
                    data: {
                        id: entry.childFieldDtoId,
                        name: entry.childFieldDtoName || entry.name,
                        schemaStructureId: entry.id // Use the current entry's id for the child
                    },
                    children: [],
                    leaf: true,
                    expanded: false,
                    icon: "fa-solid fa-file",
                    type: "CHILD"
                });
            }

            // Step 2: Store child nodes under their parent field in structureMap
            if (entry.parentFieldDtoId && entry.childFieldDtoId) {
                const parentNode = fieldMap.get(entry.parentFieldDtoId);
                const childNode = fieldMap.get(entry.childFieldDtoId);

                if (!structureMap.has(entry.parentFieldDtoId)) {
                    structureMap.set(entry.parentFieldDtoId, []);
                }

                structureMap.get(entry.parentFieldDtoId)?.push(childNode!);

                // Update the parent node to indicate it's not a leaf
                if (parentNode) {
                    parentNode.leaf = false;
                }
            }
        });

        // Step 3: Assign children to parent nodes
        structureMap.forEach((children, parentId) => {
            const parentNode = fieldMap.get(parentId);
            if (parentNode) {
                parentNode.children = children;
            }
        });

        // Step 4: Identify root nodes (entries where structureType === 0)
        apiData.forEach(entry => {
            if (entry.structureType === 0 && entry.parentFieldDtoId) {
                const rootNode = fieldMap.get(entry.parentFieldDtoId);
                if (rootNode) {
                    rootNodes.push(rootNode);
                }
            }
        });

        return rootNodes;
    }


    onNodeExpand(event: any) {
        const node = event.node;

        // Check if children are already loaded
        if (node.children && node.children.length > 0) {
            return; // Children already loaded, do nothing
        }

        // Load children dynamically
        const filterCriteria = [FilterCriteria.create('parentField', FilterCriteria.cOperatorEqual, node.data.id)];
        const objectRequestList = ObjectRequestList.createBasic(false, filterCriteria, []);

        this.schemaStructureService.list(objectRequestList).subscribe(res => {
            res.entries.shift();
            const childNodes = this.convertToNode(res.entries);
            node.children = childNodes; // Assign the loaded children to the node
            node.leaf = childNodes.length === 0; // Update leaf status
        });
    }

    onNodeSelect(event: any) {

    }

    convertToNode(apiData: any[]): TreeNode[] {
        const fieldMap: Map<number, TreeNode> = new Map(); // Stores all unique schema fields
        const structureMap: Map<number, TreeNode[]> = new Map(); // Maps parent fields to child nodes
        const childNodes: TreeNode[] = [];

        // Step 1: Create TreeNode for each schema field in schemaStructure
        apiData.forEach(entry => {
            // Ensure child field is added to fieldMap
            if (entry.childFieldDtoId && !fieldMap.has(entry.childFieldDtoId)) {
                fieldMap.set(entry.childFieldDtoId, {
                    key: entry.childFieldDtoId.toString(),
                    label: entry.childFieldDtoName || entry.name,
                    data: {
                        id: entry.childFieldDtoId,
                        name: entry.childFieldDtoName || entry.name,
                        schemaStructureId: entry.id
                    },
                    children: [],
                    leaf: false, // Will be updated later
                    expanded: false,
                    icon: "fa-solid fa-file",
                    type: "CHILD"
                });
            }

            // Ensure parent field is added to fieldMap
            if (entry.parentFieldDtoId && !fieldMap.has(entry.parentFieldDtoId)) {
                fieldMap.set(entry.parentFieldDtoId, {
                    key: entry.parentFieldDtoId.toString(),
                    label: entry.parentFieldDtoName,
                    data: {
                        id: entry.parentFieldDtoId,
                        name: entry.parentFieldDtoName,
                        schemaStructureId: entry.id
                    },
                    children: [],
                    leaf: false, // Parents should not be leaves
                    expanded: false,
                    icon: "fa-solid fa-folder",
                    type: "PARENT"
                });
            }

            // Step 2: Store child nodes under their parent field in structureMap
            if (entry.parentFieldDtoId && entry.childFieldDtoId) {
                const parentNode = fieldMap.get(entry.parentFieldDtoId);
                const childNode = fieldMap.get(entry.childFieldDtoId);

                if (!structureMap.has(entry.parentFieldDtoId)) {
                    structureMap.set(entry.parentFieldDtoId, []);
                }

                structureMap.get(entry.parentFieldDtoId)?.push(childNode!);

                // Ensure the parent node is expandable
                if (parentNode) {
                    parentNode.leaf = false;
                    parentNode.icon = "fa-solid fa-folder"; // Folder icon for expandable nodes
                }
            }
        });

        // Step 3: Assign children to their respective parents
        structureMap.forEach((children, parentId) => {
            const parentNode = fieldMap.get(parentId);
            if (parentNode) {
                parentNode.children = children;
                parentNode.leaf = false; // Parent nodes should be expandable
            }
        });

        // Step 4: Identify **only child nodes** (entries where `structureType === 1`)
        apiData.forEach(entry => {
            if (entry.structureType === 1 && entry.childFieldDtoId) {
                const childNode = fieldMap.get(entry.childFieldDtoId);
                if (childNode) {
                    childNodes.push(childNode);
                }
            }
        });

        return childNodes;
    }

    // convertToNode(apiData: any[]): TreeNode[] {
    //
    //     const nodes: TreeNode[] = [];
    //
    //     apiData.forEach(entry => {
    //         const node = {
    //             key: entry.childFieldDtoId.toString(),
    //             label: entry.childFieldDtoName || entry.name,
    //             data: {
    //                 id: entry.childFieldDtoId,
    //                 name: entry.childFieldDtoName || entry.name,
    //                 schemaStructureId: entry.id
    //             },
    //             children: [], // Initially empty, will be loaded lazily
    //             leaf: true, // Assume it's a leaf node
    //             expanded: false,
    //             icon: "fa-solid fa-file",
    //             type: "CHILD"
    //         }
    //
    //         nodes.push(node);
    //
    //     });
    //
    //     return nodes;
    // }
}
