import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";
import {LgConfigPagePage} from "./page/lg-config-page/lg-config-page.page";
import {LgLogicObjectComponent} from "./component/object-components/logic-object-component/lg-logic-object.component";
import {LgLogicPagePage} from "./page/lg-logic-page/lg-logic-page.page";
import {TeModule} from "../te/te.module";
import {DragDropModule} from "primeng/dragdrop";
import {LgVariablesComponent} from "./component/lg-variables/lg-variables.component";
import {CodeEditorComponent} from "./component/code-editor/code-editor.component";
import {LgTestCaseComponent} from "./component/lg-test-case/lg-test-case.component";
import {
    LgCalculationLogicTypeObjectComponent
} from "./component/object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.component";
import {LgCalculationTestCaseComponent} from "./component/object-components/calculation-logic-type-object-component/shared/lg-calculation-test-case/lg-calculation-test-case.component";
import {LgCalculationLogicPagePage} from "./page/lg-calculation-logic-page/lg-calculation-logic-page.page";
import {LgLogicPageComponent} from "./component/logic-page-component/lg-logic-page.component";
import {
    LgCalculationLogicTypeStepComponent
} from "./component/object-components/calculation-logic-type-object-component/shared/calculation-logic-type-step-component/lg-calculation-logic-type-step.component";
import {
    LgLogicObjectFullComponent
} from "./component/object-components/logic-object-component/view/lg-logic-object-component-full/lg-logic-object-full.component";
import {
    LgLogicObjectSideComponent
} from "./component/object-components/logic-object-component/view/lg-logic-object-component-side/lg-logic-object-side.component";
import {
    LgLogicObjectBaseComponent
} from "./component/object-components/logic-object-component/view/lg-logic-object-base.component";
import {TmModule} from "../tm/tm.module";
import {
    LgCalculationLogicTypeObjectBaseComponent
} from "./component/object-components/calculation-logic-type-object-component/view/lg-calculation-logic-type-object-base.component";
import {
    LgCalculationLogicTypeObjectSideComponent
} from "./component/object-components/calculation-logic-type-object-component/view/lg-calculation-logic-type-object-side/lg-calculation-logic-type-object-side.component";
import {
    LgCalculationLogicTypeObjectFullComponent
} from "./component/object-components/calculation-logic-type-object-component/view/lg-calculation-logic-type-object-full/lg-calculation-logic-type-object-full.component";


@NgModule({
    declarations: [
        LgConfigPagePage,
        LgLogicObjectComponent,
        LgLogicPagePage,
        LgVariablesComponent,
        CodeEditorComponent,
        LgTestCaseComponent,
        LgCalculationLogicTypeObjectComponent,
        LgCalculationTestCaseComponent,
        LgCalculationLogicPagePage,
        LgLogicPageComponent,
        LgCalculationLogicTypeStepComponent,
        LgLogicObjectFullComponent,
        LgLogicObjectSideComponent,
        LgLogicObjectBaseComponent,
        LgCalculationLogicTypeObjectBaseComponent,
        LgCalculationLogicTypeObjectSideComponent,
        LgCalculationLogicTypeObjectFullComponent,
    ],
    exports: [
        LgConfigPagePage,
        LgLogicObjectComponent,
        LgLogicPagePage,
        LgVariablesComponent,
        CodeEditorComponent,
        LgTestCaseComponent,
        LgCalculationLogicTypeObjectComponent,
        LgCalculationTestCaseComponent,
        LgCalculationLogicPagePage,
        LgLogicPageComponent,
        LgCalculationLogicTypeStepComponent,
        LgLogicObjectFullComponent,
        LgLogicObjectSideComponent,
        LgLogicObjectBaseComponent,
        LgCalculationLogicTypeObjectBaseComponent,
        LgCalculationLogicTypeObjectSideComponent,
        LgCalculationLogicTypeObjectFullComponent,

    ],
    imports: [
        CoreModule,
        UiModule,
        BlockUIModule,
        ProgressSpinnerModule,
        TeModule,
        DragDropModule,
        TmModule,
    ]
})
export class LgModule implements DynamicModule {

    alias = 'lg';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return null;
    }

    getStatisticsComponent() {
        return null;
    }
}
