import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TreeNode} from "primeng/api";
import {EmSchemaStructureDto} from "../../../../../../dto/em-schema-structure.dto";
import {EmSchemaRuntimeDataDto} from "../../../../../../service/dto/em-schema-runtime-data.dto";
import {EmSchemaRuntimeDataNodeDto} from "../../../../../../service/dto/em-schema-runtime-data-node.dto";
import {EmSchemaFieldTypeInternal} from "../../../../../../enum/em-schema-field-type-internal.enum";
import {EmSchemaFieldDto} from "../../../../../../dto/em-schema-field.dto";

interface TableColumns {
    id: number;
    name: string;
    alias: string;
}

@Component({
    selector: 'em-schema-runtime-data',
    templateUrl: 'schema-runtime.component.html',
    styleUrls: ['schema-runtime.component.scss']
})
export class SchemaRuntimeComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() schemaData: EmSchemaRuntimeDataDto;
    schemaTreeData: TreeNode[] = []; // Tree Data for PrimeNG
    selectedNode: TreeNode = null; // Track Selected Node
    previousNode: TreeNode = null; // Track Selected Node
    tableColumns: TableColumns[] = []; // Columns for dynamic table
    tableData: any[] = []; // Data for Table

    navigationHistory: { treeNode: TreeNode, showSelected: boolean }[] = [];
    singleNavigationHistory: { treeNode: TreeNode }[] = [];
    rootNodeIds: Set<number>;
    highlightedValues: Map<string, any> = new Map();

    selectedField: EmSchemaFieldDto;

    ngOnInit(): void {
        // this.rootNodeIds = new Set(
        //     this.schemaData.schema.structures
        //         .filter(struct => struct.structureType === 0)
        //         .map(struct => struct.id)
        // );

        if (!this.schemaData || !this.schemaData.schema || !this.schemaData.rootNodes) {
            return;
        }

        // Assign structure and field to each node recursively
        this.schemaData.rootNodes.forEach(node => this.enrichNode(node));

        this.rootNodeIds = new Set(
            Object.values(this.schemaData.schema.structures) // Convert map to an array
                .filter(struct => struct.structureType === 0)
                .map(struct => struct.id)
        );

        this.initComponent();

    }

    /**
     * Recursively assigns structure and field to the schema data nodes.
     */
    private enrichNode(node: EmSchemaRuntimeDataNodeDto): void {
        if (!node) return;

        // Convert "structures" and "fields" to arrays and find the correct entry
        const structuresArray = Object.values(this.schemaData.schema.structures);
        const fieldsArray = Object.values(this.schemaData.schema.fields);

        // Retrieve structure and field using `find()` instead of `.get()`
        node.structure = structuresArray.find(s => s.id === node.structureId) || undefined;
        node.field = fieldsArray.find(f => f.id === node.fieldId) || undefined;

        // Recursively process children
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                child.parent = node; // Assign parent reference
                this.enrichNode(child);
            });
        }
    }

    /**
     * Initialize Component with Test Data
     */
    initComponent() {
        // Extract and build root nodes dynamically
        const rootNodes: EmSchemaRuntimeDataNodeDto[] = this.getRootNodes();
        // this.schemaTreeData = [{
        //     label: this.schemaData.schema.schema.name,
        //     expanded: false,
        //     children: this.transformToTree(rootNodes),
        // }];

        this.schemaTreeData = this.transformToTree(rootNodes)

        this.previousNode = this.schemaTreeData[0];

        this.refreshComponent();
    }


    transformToTree(nodes: EmSchemaRuntimeDataNodeDto[], parentNode?: TreeNode): TreeNode[] {
        return nodes.filter(node => node.field?.fieldTypeInternal === 1) // Keep only complex nodes or nodes with children
            .map(node => {
                const isComplex = node.field.fieldTypeInternal === EmSchemaFieldTypeInternal.COMPLEX;
                const label = isComplex ? node.field.name : `${node.field.name}: ${node.value ?? ''}`;
                const alias = node.field.alias;

                let icon = isComplex ? 'fas fa-project-diagram' : 'fas fa-file-alt';

                let treeNode = {
                    label,
                    alias: alias,
                    data: node || null,
                    expanded: false,
                    type: isComplex ? 'complex' : 'simple',
                    icon: icon
                };

                if (parentNode) {
                    treeNode['parent'] = parentNode
                }

                treeNode['children'] = this.transformToTree(node.children ?? [], treeNode);


                return treeNode;
            });
    }

    /**
     * Retrieves root nodes by filtering structures with `structureType: 0`.
     */
    getRootNodes(): EmSchemaRuntimeDataNodeDto[] {
        return this.schemaData.rootNodes.filter((runtimeNode: EmSchemaRuntimeDataNodeDto) => this.rootNodeIds.has(runtimeNode.structure.id));
    }


    onNodeSelect(event: any): void {

        if (!this.selectedNode || this.selectedNode.type !== 'complex') {
            return;
        }

        if (this.previousNode != this.singleNavigationHistory[this.singleNavigationHistory.length - 1]) {
            this.singleNavigationHistory.push({treeNode: this.previousNode});
        }

        this.previousNode = this.selectedNode;
        this.updateTableData(this.selectedNode, event.showSelected);
    }

    goBack(): void {
        if (this.navigationHistory.length > 0) {
            const previousNode = this.navigationHistory.pop();
            this.selectedNode = previousNode.treeNode;
            this.updateTableData(previousNode.treeNode, previousNode.showSelected);
        }

    }

    goBackSingleNavigationHistory(): void {
        if (this.singleNavigationHistory.length > 0) {
            const previousNode = this.singleNavigationHistory.pop();
            this.handleNodeFocus(previousNode.treeNode, true);
        }

    }

    updateTableData(node: TreeNode, showSelected: boolean): void {
        const selectedFieldId = node.data.field.id;
        const complexFieldName: string = node.data.field.name;

        // const relatedStructures: EmSchemaStructureDto[] = this.schemaData.schema.structures.filter(
        //     (s: any) => s.parentFieldDtoId === selectedFieldId
        // );


        const relatedStructures: EmSchemaStructureDto[] = Object.values(this.schemaData.schema.structures)
            .filter((s: EmSchemaStructureDto) => s.parentFieldDtoId === selectedFieldId);

        const tableColumns: TableColumns[] = relatedStructures
            .filter(s => s.childFieldDtoId != null && this.schemaData?.schema?.fields[s.childFieldDtoId]?.fieldTypeInternal === 0)
            .map(s => ({
                id: s.childFieldDtoId,
                name: this.schemaData.schema.fields[s.childFieldDtoId].name,
                alias: this.schemaData.schema.fields[s.childFieldDtoId].alias
            }));


        const nestedComplexFields: TableColumns[] = relatedStructures
            .filter(s => s.childFieldDtoId != null && this.schemaData?.schema?.fields[s.childFieldDtoId]?.fieldTypeInternal === 1)
            .map(s => ({
                id: s.childFieldDtoId,
                name: this.schemaData.schema.fields[s.childFieldDtoId].name,
                alias: this.schemaData.schema.fields[s.childFieldDtoId].alias
            }));

        let tableData = this.extractComplexNodeData(this.schemaTreeData, complexFieldName, tableColumns, nestedComplexFields);

        // Filter only selected node if "Show Only Selected" is clicked
        if (showSelected) {
            tableData = tableData.filter(row => this.isMatchingRecord(row, node.data.value, tableColumns));
        }

        this.tableColumns = [
            ...tableColumns.map(c => ({id: c.id, name: c.name, alias: c.alias})),
            ...nestedComplexFields.map(n => ({id: n.id, name: n.name, alias: n.alias}))
        ];
        this.tableData = tableData;
    }


    isMatchingRecord(row: any, selectedValue: any, tableColumns: any[]): boolean {
        return tableColumns.every(col => {
            if (typeof selectedValue[col.alias] === 'object') return true; // Ignore complex fields
            return row[col.alias] === selectedValue[col.alias];
        });
    }


    extractComplexNodeData(nodes: TreeNode[], complexFieldName: string, tableColumns: TableColumns[], nestedComplexFields: TableColumns[]) {
        let results = [];

        for (const node of nodes) {
            if (node.label === complexFieldName) {
                let row = {};
                tableColumns.forEach(col => {

                    const foundNode = node?.data?.children.find(item => item.field.alias == col.alias);

                    row[col.alias] = foundNode?.value ?? '-';
                });

                // Find complex fields anywhere in the subtree
                nestedComplexFields.forEach(field => {
                    const foundNode = this.findNestedNode(node, field.alias);
                    if (foundNode) {
                        row[field.alias] = {
                            type: 'link',
                            label: 'View ' + field.name,
                            target: foundNode
                        };
                    }
                });

                results.push(row);
            }

            if (node.children?.length) {
                results = results.concat(this.extractComplexNodeData(node.children, complexFieldName, tableColumns, nestedComplexFields));
            }
        }

        return results;
    }

    /**
     * Recursively searches for a nested node by alias.
     */
    findNestedNode(node: TreeNode, alias: string): TreeNode | null {
        if (node['alias'] === alias) return node;

        if (node.children) {
            for (let child of node.children) {
                const found = this.findNestedNode(child, alias);
                if (found) return found;
            }
        }

        return null;
    }

    isHighlightedLeaf(node: TreeNode): boolean {

        for (const [alias, value] of this.highlightedValues) {
            if (node.data.field.alias == alias && node.data.value == value) {
                return true;
            }
        }
        return false;
    }


    highlightTreeNode(value: any, alias: string) {

        this.highlightedValues.clear();
        this.highlightedValues.set(alias, value);
        this.findTreeNode(this.schemaTreeData, alias, value);
    }

    findTreeNode(nodes: TreeNode[], alias: string, value: any): TreeNode | null {
        for (let node of nodes) {
            if (node.data?.value?.[alias] === value) {
                node.expanded = true;
                return node;
            }

            if (node?.children?.length) {
                const found = this.findTreeNode(node.children, alias, value);
                if (found) {
                    node.expanded = true;
                    return found;
                }
            }
        }

        return null;
    }

    handleNodeFocus(node: TreeNode, onBack: boolean = false) {

        if (this.previousNode && this.previousNode !== node && !onBack) {
            if (this.previousNode != this.singleNavigationHistory[this.singleNavigationHistory.length - 1]) {
                this.singleNavigationHistory.push({treeNode: this.previousNode});
            }
        }

        this.selectedNode = node;
        this.previousNode = node;

        if (!this.singleNavigationHistory.length) {
            this.previousNode = this.schemaTreeData[0];
        }

        this.expandAndScrollToNode(node);
    }

    handleShowNode(node: EmSchemaRuntimeDataNodeDto) {
        this.selectedField = node.field;
    }

    expandAndScrollToNode(targetNode: TreeNode) {
        if (!targetNode) return;
        this.expandParents(targetNode);
        setTimeout(() => this.scrollToNode(targetNode['alias']), 100); // Ensure UI updates before scrolling


    }

    expandParents(node: TreeNode) {
        if (!node) return;
        node.expanded = true;

        if (node.parent) {
            this.expandParents(node.parent);
        }
    }

    scrollToNode(nodeAlias: string) {
        setTimeout(() => {
            const element = document.querySelector(`[data-node-alias="${nodeAlias}"]`);
            if (element) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }, 100);
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    protected readonly Object = Object;
}
