<!--<p-button label="show Optional Activities" (click)="handleAllOptionalActivities(); op.toggle($event)"></p-button>-->

<!--<p-overlayPanel #op>-->
<!--    <div *ngFor="let activity of allOptionalActivityForStep">-->
<!--        <span>{{activity.processTypeStepActivityDtoName + ' => ' + activity.objectTypeDtoName}}</span>-->
<!--    </div>-->

<!--</p-overlayPanel>-->

<p-dialog header="Processed Objects" [(visible)]="visible" [style]="{width: '50vw'}" (onHide)="showDialog()"
          (onShow)="handleAllOptionalActivities()">

    <div *ngFor="let activity of allOptionalActivityForStep">
        <span>{{activity.processTypeStepActivityDtoName + ' => ' + activity.objectTypeDtoName}}</span>
    </div>

</p-dialog>