
<ng-container *ngIf="initialized">

<section>
    <div class="grid">

        <div class="col-12">

            <p-steps [model]="steps" [activeIndex]="stepIndex" [readonly]="true"></p-steps>

            <ng-container *ngIf="stepIndex == 0">
                <search-flexible objectType="cr.Customer" (onObjectSelect)="handleCustomerSelect($event)"></search-flexible>
            </ng-container>

            <ng-container *ngIf="stepIndex == 1">
                <div class="surface-card shadow-2 p-4">
                    <div class="grid">
                        <div class="col-6 col-offset-3 flex flex-column gap-3">
                            <ng-container *ngFor="let callType of callTypeList.entries">
                                <p-button [styleClass]="'w-full p-4 flex justify-content-center'" pRipple (click)="handleStartCall(callType)">{{callType.name}}</p-button>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </ng-container>

        </div>

        <!--
        <div class="col-12 md:col-4 col-offset-4">

        </div>
        -->
    </div>
</section>
</ng-container>