import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ObjectBrowserResultDto} from "@kvers/alpha-core-common";
import {environment} from "../../../../../environments/environment";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ReportService} from "../../../service/api/report.service";
import {ObjectTypeId} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-rp-report-page',
  templateUrl: './rp-test-config.page.html',
  styleUrls: ['./rp-test-config.page.scss']
})
export class RpTestConfigPage implements OnInit {

  reportMeta: any;
  mainObject: ObjectTypeId;
  reportMetaBusy: boolean;

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  constructor(
      protected http: HttpClient,
      protected reportService: ReportService) {
  }

  ngOnInit(): void {

    this.initCrudTree();
  }

  onChangedObject(changeInformation: ObjectChangeInformation) {

  }

  initCrudTree() {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries': [
        {
          'id': 1,
          'objectAlias': 'rp.Report',
          'level': 1,
          'dummyParentCreate': true,
          'dummyParentLabel': "Report",
          'parentBinding': 'none',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Report anlegen"
        },
        {
          'id': 2,
          'objectAlias': 'rp.ReportObject',
          'level': 2,
          'parentBinding': 'mandatory',
          'parentId': 1,
          'dummyParentCreate': true,
          'dummyParentLabel': "Objekte",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Neues Objekt anlegen"
        },
        {
          'id': 3,
          'objectAlias': 'rp.ReportObjectLink',
          'level': 2,
          'parentBinding': 'mandatory',
          'parentId': 1,
          'dummyParentCreate': true,
          'dummyParentLabel': "Verknüpfung",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Neue Verknüpfung anlegen"
        },
        {
          'id': 4,
          'objectAlias': 'rp.ReportField',
          'level': 2,
          'parentBinding': 'mandatory',
          'parentId': 1,
          'dummyParentCreate': true,
          'dummyParentLabel': "Felder",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel': "Neues Feld anlegen"
        }
      ]
    };

  }

}
