<ng-container *ngIf="initialized">
    <form [formGroup]="widgetUiField">
        <div [formGroupName]="'uiConfigData'">
            <div class="grid">

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="fieldType">Object Type</label>
                        <p-dropdown
                                [options]="fieldTypes"
                                formControlName="fieldType"
                                id="fieldType"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                placeholder="Select Object Type"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="outputFieldToDisplay">Field To Display</label>
                        <p-dropdown
                                [options]="filteredFormFields"
                                formControlName="outputFieldToDisplay"
                                id="outputFieldToDisplay"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="uiLabel"
                                placeholder="Select Field"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="outputFieldToUpdate">Field To Update</label>
                        <p-dropdown
                                [options]="filteredFormFields"
                                formControlName="outputFieldToUpdate"
                                id="outputFieldToUpdate"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="uiLabel"
                                placeholder="Select Field"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </form>

</ng-container>


<ng-container *ngIf="!initialized">
    <p-progressSpinner ariaLabel="Processing"/>
</ng-container>
