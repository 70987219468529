import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {WfProcessTypeService} from "../../service/api/wf-process-type.service";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {RecursionHelperLogic} from "@kvers/alpha-core-common";
import {WfProcessStartEvent} from "../../logic/wf-process-start-event";

@Component({
  selector: 'mvs-wf-indicator',
  templateUrl: './wf-indicator.component.html',
  styleUrls: ['./wf-indicator.component.scss']
})
export class WfIndicatorComponent implements OnInit {

  @Input() objectType: string;
  @Input() objectId: number;
  @Input() importContext: DtoImportObjectContext;

  @Output() onStartProcess = new EventEmitter<WfProcessStartEvent>()

  processTypes: DtoList<WfProcessTypeDto>;

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized


  processTypesTree: any[];
  createProcessTypeSelected: any;


  constructor(protected wfProcessTypeService: WfProcessTypeService) {

  }

  ngOnInit(): void {
    this.initComponent();
    this.refreshComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {

    this.busy = true;

    this.wfProcessTypeService.list(ObjectRequestList.createSimple(this.importContext)).subscribe(value => {

      // take over process Types
      this.processTypes = <DtoList<WfProcessTypeDto>>value;

      // refresh UI
      this.uiRefresh();

      this.busy = false;
      this.initialized = true;

    });

  }

  uiRefresh() {

    // retrieve roots
    const recursionTree = new RecursionHelperLogic(
        this.processTypes.entries,
        "parentDtoId",
        "id",
        "name",
        "label",
        "entry",
        "children");

    this.processTypesTree = recursionTree.extractTree();

  }

  handleStartProcess() {

    // make sure that the process type was selected
    if (!this.createProcessTypeSelected || !this.createProcessTypeSelected["entry"]) {
      return; // stop further processing
    }

    const startEvent = new WfProcessStartEvent(this.createProcessTypeSelected["entry"].id);

    this.onStartProcess.emit(startEvent);

  }



  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["objectType"] || changes["objectId"] || changes["importContext"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

}
