<ng-container *ngIf="initialized">

    <!-- Enter Comments -->
    <div class="text-lg font-medium p-3" *ngIf="headline">{{ headline }}</div>
    <div class="py-1 px-2">
        <div class="formgroup-inline align-items-center border-1 test rounded-normal"
             [class.custom-field-disabled]="busy"
             [ngClass]="busy || busyLinkedEntity || commentInvalid || !newComment ? 'border-400' : 'border-blue-700'"
             (click)="inputCommentNewText.nativeElement.focus()">
            <div class="col-12">
                    <textarea
                        #commentNewText
                        [disabled]="busy || busyLinkedEntity"
                        pInputTextarea
                        [(ngModel)]="newComment"
                        (ngModelChange)="onCommentTextChange($event)"
                        placeholder="Kommentar eingeben"
                        class="w-full textarea-blank"
                        (keyup)="onAdjustTextArea($event)"
                    ></textarea>
            </div>
            <div class="col-12 flex justify-content-end">
                <button
                        [disabled]="busy || busyLinkedEntity || commentInvalid || !newComment"
                        (click)="createComment()"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-send"
                        class="p-button-rounded -mt-5"
                ></button>
            </div>
        </div>
        <p class="p-2 m-0 text-500" [ngClass]="linkedEntityData?.entries.length > 0 ? 'text-800' : 'text-500'">
            {{ linkedEntityData?.entries.length }} Kommentar
        </p>
    </div>

    <ng-container *ngIf="linkedEntityData?.entries.length > 0">
        <p-toolbar styleClass="surface-100 mx-2">
            <div class="p-toolbar-group-start">

                <div style="max-width:70%" *ngIf="showSearch">
                <span style="max-width:70%" class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" (keyup)="onSearch()" pInputText [(ngModel)]="searchTerm"/>
                </span>
                </div>

                <ng-container *ngIf="!showSearch">
                    <p-toggleButton [(ngModel)]="showGroupedComments"
                                    (click)="onToggleCommentsView()"
                                    [pTooltip]="showFullText ? 'Show ungrouped comments' : 'Show grouped comments' "
                                    offIcon="fa-regular fa-object-group"
                                    onIcon="fa-regular fa-object-ungroup"
                                    styleClass="p-1 py-2">

                    </p-toggleButton>

                    <p-button icon="fa-sharp fa-regular fa-list"
                              (onClick)="toggleFullText()"
                              [pTooltip]="showFullText? 'Show Less Text' : 'Show Full Text' "
                              class="ml-2"
                              [styleClass]="showFullText ? 'p-1 py-2' : 'p-togglebutton p-button-text-only p-1 py-2'">

                    </p-button>
                </ng-container>
            </div>

            <div class="p-toolbar-group-end">
                <p-button (onClick)="onToggleSearchButton()" icon="pi pi-search" class="mr-2"
                          styleClass="p-1 py-2"></p-button>
            </div>
        </p-toolbar>


        <div class="p-2" *ngIf="linkedEntityData">
            <mvs-extend-entries [showGrouped]="showGroupedComments"
                                [attributes]="['createdBy', 'date']"
                                [entries]="modifiedEntries"
                                defaultSize="10">

                <!--            for ungrouped comments-->
                <ng-template let-comment="entry" mvs-template-directive area="entry">
                    <!--                    <div class="border-top-1 border-gray-400 p-2"></div>-->
                    <div class="flex border-bottom-1 border-300 py-2">



                        <div class="w-full">
                            <div class="flex align-items-center justify-content-between">
                                <span class="block text-900 col-8">
                                <mvs-form-control-output-user-image
                                        [value]="comment['lastModifiedBy']"
                                        [displayInfo]="true">
                                </mvs-form-control-output-user-image>
                                </span>
                                <span class="text-sm  text-500 font-normal col-4 text-right">
                                    <i class="fa-solid fa-circle ml-1 text-400" style="font-size: 0.25rem"></i>
                                    {{ comment['createdDate'] | date: 'dd.MM.yyyy h:mm a' }}
                                </span>
                            </div>

                            <div style="word-break: break-all; white-space: pre-wrap; margin-left: 3rem" *ngIf="comment[commentAttribute]">
                                <mvs-form-control-text-show-more
                                        [showFull]="showFullText"
                                        [text]="comment[commentAttribute]">
                                </mvs-form-control-text-show-more>
                            </div>
                        </div>

                    </div>


                </ng-template>

                <!--            for grouped comments -->
                <ng-template let-comment="entry" mvs-template-directive area="groupedEntry">
                    <div class="flex border-bottom-1 border-300 py-3">
                        <mvs-form-control-output-user-image
                                [value]="comment['lastModifiedBy']">
                        </mvs-form-control-output-user-image>


                        <div class="px-3 w-full comments-container">
                            <div class="text-sm pb-2 text-800 font-medium">
                                <i class="pi pi-calendar mr-1 text-xs"></i>
                                <span>{{ comment['createdDate'] | MvsDateAgo }}</span>&nbsp;
                            </div>
                            <mvs-form-control-text-show-more
                                    [showFull]="showFullText"
                                    [text]="comment.comments">
                            </mvs-form-control-text-show-more>
                        </div>


                    </div>


                    <!--                        <div class="border-top-1 border-gray-400 p-2"></div>-->
                    <!--                    </div>-->
                </ng-template>

            </mvs-extend-entries>
        </div>

    </ng-container>

</ng-container>


