import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './cm-contract-notification-type-object.component.html',
})
export class CmContractNotificationTypeObjectComponent
    extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    widgetBasic: WidgetData;
    widgetRestrictContractType: WidgetData;
    widgetRestrictGroup: WidgetData;


    /**
     * On Object Change.
     */
    onObjectChanged() {

        // create filter
        const filterCriteria = FilterCriteria.create("contractNotificationType", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        this.widgetBasic = WidgetFactory.createWidgetEntityData(
            "cm.contract.notification.type.basic",
            "Benachrichtigungstyp",
            "cm.ContractNotificationType",
            this.objectIdentifier.objectId
        );

        this.widgetRestrictContractType = WidgetFactory.createWidgetList(
            "cm.contract.notification.type.restrictType",
            "Vertragstypen",
            "table",
            "list",
            "entity",
            "cm.ContractNotificationTypeRestrictContractType",
            "Keine Enschränkung vorhanden",
            ObjectRequestList.createBasic(true, [filterCriteria], [])
        );

        this.widgetRestrictGroup = WidgetFactory.createWidgetList(
            "cm.contract.notification.type.restrictGroup",
            "Gruppen",
            "table",
            "list",
            "entity",
            "cm.ContractNotificationTypeRestrictGroup",
            "Keine Enschränkung vorhanden",
            ObjectRequestList.createBasic(true, [filterCriteria], [])
        );

    }

}
