import {Component} from '@angular/core';
import {
    MvsCoreService,
    MvsMessageService,
    NavigationItem,
    ObjectRequestList,
    PageComponent,
    WidgetData
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-cf-config',
    templateUrl: 'cf-config.page.html',
})
export class CfConfigPage extends PageComponent {


    defaultLabel: string = "CF Management";
    activeNavigationItem: NavigationItem;

    frequencyWidget: WidgetData;
    payFrequencyWidget: WidgetData;
    paymentMomentWidget: WidgetData;
    paymentTypeWidget: WidgetData;
    roundingRuleWidget: WidgetData;
    templateCalculationBaseWidget: WidgetData;
    templateCancellationBufferWidget: WidgetData;
    templateLiabilityWidget: WidgetData;

    queryParamSubscription: Subscription;

    navigationItems: NavigationItem[] = [
        {
            label: 'frequencies',
            action: 'frequencies',
            tooltip: 'Frequencies',
            icon: 'fa-solid fa-wave-square', // Represents periodicity
            toggleable: false,
            default: true
        },
        {
            label: 'payFrequencies',
            action: 'payFrequencies',
            tooltip: 'Pay Frequencies',
            icon: 'fa-solid fa-calendar-alt', // Represents scheduled payments
            toggleable: false,
            default: false
        },
        {
            label: 'paymentMoments',
            action: 'paymentMoments',
            tooltip: 'Payment Moments',
            icon: 'fa-solid fa-stopwatch', // Represents timing
            toggleable: false,
            default: false
        },
        {
            label: 'paymentTypes',
            action: 'paymentTypes',
            tooltip: 'Payment Types',
            icon: 'fa-solid fa-money-bill-wave', // Represents payment methods
            toggleable: false,
            default: false
        },
        {
            label: 'roundingRules',
            action: 'roundingRules',
            tooltip: 'Rounding Rules',
            icon: 'fa-solid fa-percent', // Represents rounding calculations
            toggleable: false,
            default: false
        },
        {
            label: 'templateCalculationBases',
            action: 'templateCalculationBases',
            tooltip: 'Template Calculation Bases',
            icon: 'fa-solid fa-calculator', // Represents calculations
            toggleable: false,
            default: false
        },
        {
            label: 'templateCancellationBuffers',
            action: 'templateCancellationBuffers',
            tooltip: 'Template Cancellation Buffers',
            icon: 'fa-solid fa-file-excel', // Represents buffer or cancellations
            toggleable: false,
            default: false
        },
        {
            label: 'templateLiabilities',
            action: 'templateLiabilities',
            tooltip: 'Template Liabilities',
            icon: 'fa-solid fa-balance-scale', // Represents liabilities or legal balances
            toggleable: false,
            default: false
        }
    ];


    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        const objectRequestList = ObjectRequestList.createBasic(true, [], []);

        this.frequencyWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.frequency.widget.data',
            'Frequencies',
            'cf.CfFrequency',
            'No Data',
            objectRequestList
        );

        this.payFrequencyWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.payFrequency.widget.data',
            'Pay Frequencies',
            'cf.CfPayFrequency',
            'No Data',
            objectRequestList
        );

        this.paymentMomentWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.paymentMoment.widget.data',
            'Payment Moments',
            'cf.CfPaymentMoment',
            'No Data',
            objectRequestList
        );

        this.paymentTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.paymentType.widget.data',
            'Payment Types',
            'cf.CfPaymentType',
            'No Data',
            objectRequestList
        );

        this.roundingRuleWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.roundingRule.widget.data',
            'Rounding Rules',
            'cf.CfRoundingRule',
            'No Data',
            objectRequestList
        );

        this.templateCalculationBaseWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.templateCalculationBase.widget.data',
            'Template Calculation Bases',
            'cf.CfTemplateCalculationBase',
            'No Data',
            objectRequestList
        );

        this.templateCancellationBufferWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.templateCancellationBuffer.widget.data',
            'Template Cancellation Buffers',
            'cf.CfTemplateCancellationBuffer',
            'No Data',
            objectRequestList
        );

        this.templateLiabilityWidget = WidgetFactory.createWidgetTableEntity(
            'cf.config.templateLiability.widget.data',
            'Template Liabilities',
            'cf.CfTemplateLiability',
            'No Data',
            objectRequestList
        );

    }


    subscribeToParams() {


        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            const navItem = params['navItem'];

            if (navItem) {
                this.activeNavigationItem = this.navigationItems.find(item => item.action == navItem);
            } else {
                this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            }
        });
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }


}


