<div class="grid m-0">
    <div class="col-12">
        <div class="grid">
            <div class="col-12 pt-0">
                <p-panel>
                    <ng-template pTemplate="header">
                        <div class="flex justify-content-between align-items-center w-full">
                            <div class="flex align-items-center gap-4">
                                <p-toggleButton *ngIf="document?.analyzed" [(ngModel)]="toggleDocument"
                                                onLabel="HTML View"
                                                offLabel="PDF View"></p-toggleButton>
                            </div>
                        </div>
                    </ng-template>

                    <!-- Document HTML View-->
                    <ng-container *ngIf="toggleDocument && document?.analyzed && formData">
                        <app-form-recognizer id="top" [formData]="formData"
                                             (keyValuePairEvent)="handleKayValuePairs($event)"
                                             [keyValuePairInput]="selectedKeyValue"></app-form-recognizer>
                    </ng-container>

                    <!-- Document PDF View-->
                    <ng-container *ngIf="!toggleDocument || !document?.analyzed">

                        <ng-container *ngIf="isPdf; else imageTemplate">
                            <object
                                    class="w-full"
                                    style="height: 95vh"
                                    [data]="documentPdfSource"
                                    type="application/pdf">
                                <embed [src]="documentPdfSource" type="application/pdf"/>
                            </object>
                        </ng-container>
                        <ng-template #imageTemplate>
                            <img [src]="documentPdfSource" class="w-full" alt="Image Document" *ngIf="isImage"/>
                        </ng-template>
                    </ng-container>

                </p-panel>
            </div>
        </div>
    </div>
</div>