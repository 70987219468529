import { Component, OnInit } from '@angular/core';
import {
  MvsCoreService,
  ObjectIdentifierData,
  ObjectRequestList,
  TabEntries,
  WidgetData
} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {
  MvsObjectNavigationActionEnum,
  MvsObjectNavigationEntry,
  MvsObjectNavigationService,
  WidgetFactory
} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mvs-pm-household-config',
  templateUrl: './household-config.page.html',
  styleUrls: ['./household-config.page.scss']
})
export class HouseholdConfigPage extends PageComponent {

  householdPersonWidget: WidgetData;
  householdWidget: WidgetData;

  defaultLabel: string = "Household Configuration";
  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label:'Household', id: 'pm.Household', type: ''},
    {label:'Household Person', id: 'pm.HouseholdPerson', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }


  ngOnInit(): void {

    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();
  }



  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }


  refreshWidgets() {
    this.refreshHouseholdWidget();
    this.refreshHouseholdPersonWidget();
    this.initialized = true;
  }

  refreshHouseholdWidget() {
    const objectRequest = new ObjectRequestList(true,[],[]);

    this.householdWidget = WidgetFactory.createWidgetList(
        'household.config.page.refresh.household.widget',
        'Household',
        'table',
        'list',
        'entity',
        'pm.Household',
        'No data found!',
        objectRequest
    );
  }
  refreshHouseholdPersonWidget() {
    const objectRequest = new ObjectRequestList(true,[],[]);

    this.householdPersonWidget = WidgetFactory.createWidgetList(
        'household.config.page.refresh.household.person.widget',
        'Household Person',
        'table',
        'list',
        'entity',
        'pm.HouseholdPerson',
        'No data found!',
        objectRequest
    );
  }



  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }


}
