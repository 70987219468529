import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AsAppointmentEventComponent} from "../../component/as-appointment-event/as-appointment-event.component";
import {Observable} from "rxjs";
import {AppointmentFreeSlot, FreeSlotRequestDto} from "../dto/free-slot-request.dto";

@Injectable({
    providedIn: 'root'
})
export class AppointmentService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/as/appointments')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return AsAppointmentEventComponent;
        }
        return null;
    }

    // Function to fetch free slots
    getFreeSlots(requestDto: FreeSlotRequestDto): Observable<Map<number, AppointmentFreeSlot[]>> {
        const url = `${this.apiUrl}/freeslot`;

        for (let timeFrame of requestDto.timeFrames) {

        }

        return this.http.post<Map<number, AppointmentFreeSlot[]>>(url, requestDto);
    }


    /**
     * Converts a Date object to CET LocalDateTime string (YYYY-MM-DDTHH:mm:ss).
     */
    private formatToCET(date: Date): string {
        const offset = 1; // CET is UTC+1 in standard time (adjust for DST if needed)
        const cetDate = new Date(date.getTime() + offset * 60 * 60 * 1000);

        return cetDate.toISOString().slice(0, 19).replace('T', 'T'); // Removes milliseconds & Z
    }

    /**
     * Fetch appointments from the calendar.
     */
    getCalendarAppointments(agentIds: number[], startUtc: Date, endUtc: Date): Observable<any> {
        const url = `${this.apiUrl}/getCalendar`;

        // Convert Date objects to ISO strings
        const formattedStart = startUtc.toISOString();
        const formattedEnd = endUtc.toISOString();

        // Convert array of agentIds to a comma-separated string
        const agentIdsString = agentIds.join(',');

        // Set query parameters
        const params = new HttpParams()
            .set('agentIds', agentIdsString)
            .set('startUtc', formattedStart)
            .set('endUtc', formattedEnd);

        // Make HTTP GET request
        return this.http.get<any>(url, {params});
    }


    scheduleAppointment(
        appointmentTypeId: number,
        agentId: number,
        customerId: number,
        startDate: Date,
        endDate: Date,
        referenceObjectTypeAlias?: string,
        referenceObjectTypeId?: number,
        referenceObjectId?: number,
    ): Observable<any> {

        const url = `${this.apiUrl}/schedule/${appointmentTypeId}`;

        // Create new UTC dates with the same hours/minutes as the local dates
        const startUtc = startDate.toISOString(); // Converts to UTC string
        const endUtc = endDate.toISOString(); // Converts to UTC string

        // Build query parameters
        let params = new HttpParams()
            .set('agentId', agentId.toString())
            .set('customerId', customerId.toString())
            .set('startUtc', startUtc)
            .set('endUtc', endUtc);

        if (referenceObjectTypeAlias) {
            params = params.set('refObjectTypeAlias', referenceObjectTypeAlias);
        }

        if (referenceObjectTypeId !== undefined && referenceObjectTypeId !== null) {
            params = params.set('refObjectTypeId', referenceObjectTypeId.toString());
        }

        if (referenceObjectId !== undefined && referenceObjectId !== null) {
            params = params.set('refObjectId', referenceObjectId.toString());
        }

        // Make the HTTP POST request
        return this.http.post<any>(
            url,
            null, // No request body, using query params instead
            {params}
        );
    }


    /**
     * Creates a UTC date string with the same hours/minutes as the local date
     */
    private createUtcDateWithSameHours(localDate: Date): string {
        // Create a new Date using UTC with the same year, month, day, hour, minute, second
        const utcDate = new Date(Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate(),
            localDate.getHours(),
            localDate.getMinutes(),
            localDate.getSeconds()
        ));

        // Return as ISO string
        return utcDate.toISOString();
    }

    /**
     * Move appointment to another date.
     */
    moveAppointmentDate(
        appointmentId: number,
        start: Date,
        end: Date
    ): Observable<any> {
        const url = `${this.apiUrl}/move-date/${appointmentId}`;

        // Create new UTC dates with the same hours/minutes as the local dates
        const startUtc = this.createUtcDateWithSameHours(start);
        const endUtc = this.createUtcDateWithSameHours(end);

        // Append parameters as query params
        const params = new HttpParams()
            .set('startUtc', startUtc)
            .set('endUtc', endUtc);

        return this.http.post<any>(url, {}, {params});
    }


    /**
     * Move appointment to another agent.
     */
    moveAppointmentAgent(
        appointmentId: number,
        agentId: number,
        start?: string,
        end?: string
    ): Observable<any> {
        const url = `${this.apiUrl}/move-agent/${appointmentId}/${agentId}`;

        let params = new HttpParams();
        if (start) params = params.set('start', start);
        if (end) params = params.set('end', end);

        return this.http.post<any>(url, {}, {params});
    }

    /**
     * Delete an appointment.
     */
    deleteAppointment(appointmentId: number): Observable<any> {
        const url = `${this.apiUrl}/delete/${appointmentId}`;
        return this.http.delete(url, {responseType: 'text'}); // Expecting a string response
    }

    createEvent(agentId: number, event: any): Observable<any> {
        return this.http.post(`${MvsCrudService.baseUrl}/events/create/${agentId}`, event);
    }


}