import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DmDocumentService} from "../../../dm/service/api/dm-document.service";
import {DmDocumentDto} from "../../../dm/dto/dm-document.dto";

@Component({
    selector: 'mvs-xx-dialog-box-object-document-component',
    templateUrl: './dialog-box-object-documents.component.html',
    styleUrls: ['./dialog-box-object-documents.component.scss']
})
export class XxDialogBoxObjectDocumentComponent implements OnInit {


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() visible: boolean;
    @Input() documentObject: any;
    @Output() dialogVisibleEvent = new EventEmitter<boolean>();
    formData: any;
    toggleDocument: boolean;

    document: DmDocumentDto;
    documentPdfSource: SafeResourceUrl;

    constructor(protected documentService: DmDocumentService, private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {




    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.loadDocument();
        this.loadDocumentPdf();

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    onDialogHide() {
        this.dialogVisibleEvent.emit(false);
    }

    loadDocument() {
        this.documentService.get(this.documentObject.data.documentDtoName).subscribe(
            (res: DmDocumentDto) => {
                this.document = res;
                this.toggleDocument = res.analyzed;
                if (res.analyzed) {
                    this.loadFormData();
                }
            }
        )
    }

    loadFormData() {
        this.documentService.getFormData(this.documentObject.data.documentDtoName).subscribe(res => {
            this.formData = res;
        })
    }

    loadDocumentPdf() {
        this.documentService.downloadViaUrl(this.documentObject.data.documentDtoName).subscribe(value => {
            this.documentPdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(value + "#view=FitV&navpanes=0");
        });
    }
}
