import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, EventEmitter,} from '@angular/core';
import {DtoDetail, ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {UmUserService} from '../../service/api/um-user.service';
import {FieldStorageService} from "@kvers/alpha-ui";
import {UiCommentEvent} from "../../../core/components/mvs-quill-editor/data/ui-comment-event";

@Component({
    selector: 'mvs-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() documentTypeId: number;
    @Input() serviceInterface: MvsCrudService;
    @Input() referenceId: number;
    @Input() hideLabel: boolean;
    @Input() euVisible: boolean = false;
    @Output() onComment = new EventEmitter();
    @Output() onInputChange = new EventEmitter<string>();
    userNamesList: DtoList[];
    newComment: string;
    recoveredText: string;
    localStorageKey: string;
    content: string;
    focused: boolean;
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(
        protected coreService: MvsCoreService,
        protected userService: UmUserService,
        protected messageService: MvsMessageService,
        protected fieldStorageService: FieldStorageService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve list of all active users (required for the mentions within the comments)
        this.userService.getAllActiveUsers().subscribe(value => {
            let dtoListUsers: any = value;
            this.userNamesList = dtoListUsers.entries.map(
                (element: any) => element.name
            )
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        // this.retrieveUnsavedData();
        this.initialized = true;
    }

    /**
     * create comment
     */
    createComment(event: UiCommentEvent) {
        if (!event.comment || event.comment.trim().length === 0) {
            return;
        }

        this.busy = true;
        this.newComment = event.comment;
        const dtoDetail = new DtoDetail();

        dtoDetail["ticketDtoId"] = this.referenceId;
        dtoDetail["entityStatus"] = 3;
        dtoDetail["comment"] = this.newComment;
        dtoDetail['euVisible'] = event.euVisible;

        this.serviceInterface.create(dtoDetail).subscribe(value => {

            this.newComment = "";
            this.clearLocalStorage();
            // refresh Ticket comments
            this.onComment.emit();

            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "Comment created");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })


    }

    handleInputChange(comment: string) {
        this.newComment = comment;
        this.handleStoreLocalStorage();
        this.onInputChange.emit(this.newComment);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    handleStoreLocalStorage() {
        this.localStorageKey = this.fieldStorageService.generateKey('tm.TicketComment', 0, 'comment');
        this.fieldStorageService.saveToLocalStorage(this.localStorageKey, this.newComment);
    }

    // retrieveUnsavedData() {
    //     this.localStorageKey =  this.fieldStorageService.generateKey('tm.TicketComment', 0, 'comment');
    //     const value = this.fieldStorageService.getFromLocalStorage(this.localStorageKey);
    //     if (value) {
    //         this.recoveredText = value;
    //     }
    // }

    loadRecoveredValue() {
        this.content = this.recoveredText;
    }

    clearLocalStorage() {
        this.fieldStorageService.clearFieldData(this.localStorageKey);
        this.recoveredText = null;
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
