import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ActivatedRoute, Params} from "@angular/router";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {Observable, Subscription, tap} from "rxjs";
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {TreeNode} from "primeng/api";
import {cl} from "@fullcalendar/core/internal-common";
import {TicketCommentService} from "../../../tm/service/api/ticket-comment.service";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cc-entity-browser',
  templateUrl: './entity-browser.component.html',
  styleUrls: ['./entity-browser.component.scss']
})
export class EntityBrowserComponent extends PageComponent implements OnInit, OnDestroy, OnChanges {

  objectTypes: DtoList;
  selectedObjectType: DtoDetail;

  rootForm: MvsFormDto;
  tsClassCode: string;

  rootNodes: TreeNode[] = [];

  constructor(
      protected route: ActivatedRoute,
      protected objectTypeService: ObjectTypeService,
      protected messageService: MvsMessageService,
      protected coreService: MvsCoreService,
      protected metaService: MetaService) {
    super(route, coreService);
  }

  /**
   * Initialization.
   */
  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();

  }


  onChange(event: any) {

    this.refreshObjectType(event.value);

  }

  refreshObjectType(objectType: DtoDetail) {
    this.selectedObjectType = objectType;

    this.busy = true;
    this.rootNodes = [];

    const templateObserver = this.getTemplate(this.selectedObjectType["alias"]);

    if (templateObserver) {

      templateObserver.subscribe(value => {
        this.busy = false;
        this.rootNodes.push(this.convertFormToNode(value));
      });

    } else {
      this.busy = false;
    }

  }

  retrieveMeta(node: any) {

    this.tsClassCode = "Loading ...";

    this.metaService.getByAlias(node["form"]["objectTypeAlias"]).subscribe(value => {
      this.tsClassCode = JSON.stringify(value);
    });

  }

  generateApiAccess(node: any) {

    const split = node["form"]["objectTypeAlias"].split(".");

    let className = split[0].charAt(0).toUpperCase() + split[0].charAt(1);
    className = className + split[1];

    let serviceClass = split[1] + "Service";

    let tsScript = "Option 1)\r\r" +
        "constructor(\r" +
        "       protected coreService: MvsCoreService) {\r" +
        "       }\r\r";

    tsScript = tsScript +
        "       initComponent() {\r" +
        "           // retrieve specific service\r"+
        "           this.specificService = <" + serviceClass + ">this.coreService.getCrudService(\"" + node["form"]["objectTypeAlias"] + "\");\r\r"+
        "           this.specificService.list...";

    tsScript = tsScript + "\r\r\r\rOption 2)\r\r" +
        "constructor(\r" +
        "       protected specificService: " + serviceClass +") {\r" +
        "       }\r\r";

    this.tsClassCode = tsScript;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.tsClassCode);
  }

  getTsEnumClass(field: MvsFormFieldDto, featureModule: string) : { enumName: string, enumTs: string } {

    if (!field.valueList || !field.valueList.entries || field.valueList.entries.length == 0) {
      return null;
    }

    const splitFieldId = field.dataType.split(".");

    const enumName = splitFieldId[splitFieldId.length - 1];

    let enumCode = "";

    for (let entry of field.valueList.entries) {
      if (enumCode !== "") {
        enumCode = enumCode + ",\r"
      }
      enumCode = enumCode + " " + entry.technicalKey;
    }

    enumCode = "export enum " + featureModule + enumName + "{\r\r" + enumCode + "\r\r}";

    return { enumName : featureModule + enumName, enumTs : enumCode};

  }

  generateTsClass(node: any) {

    const form: MvsFormDto = node["form"];

    const split = form["objectTypeAlias"].split(".");
    let featureModule = split[0].charAt(0).toUpperCase() + split[0].charAt(1);
    let className = featureModule + split[1];

    let tsClass = "export class " + className + "Dto extends DtoDetail {" + "\r\r";

    let enumDefinitions = "";

    const formFields = [];

    for (let formFieldId in form.formFields) {
      const formField = form.formFields[formFieldId];
      formFields.push(formField);
    }

    formFields.sort((a, b) => a.order - b.order);


    for (let formField of formFields) {

      if (formField.id == "id" ||
          formField.dataType == "cc.model.ActivityContext" ||
          formField.id == "createdBy" ||
          formField.id == "createdDate" ||
          formField.id == "lastModifiedBy" ||
          formField.id == "lastModifiedDate") {
        continue;
      }

      let dataTypeTs = formField.dataType;

      if (formField.id.startsWith("gen_")) {
        continue; // generic fields are not displayed
      }

      if (formField.id.endsWith("DtoId")) {
        dataTypeTs = "number";
      } else if (formField.id.endsWith("DtoName")) {
        dataTypeTs = "string";
      } else if (dataTypeTs.endsWith("Enum") || (formField.valueList && formField.valueList.entries && formField.valueList.entries.length > 0)) {

        const tsEnumClass = this.getTsEnumClass(formField, featureModule);

        if (tsEnumClass) {
          if (enumDefinitions !== "") {
            enumDefinitions = enumDefinitions + "\r\r";
          }
          enumDefinitions = enumDefinitions + tsEnumClass.enumTs;
          dataTypeTs = tsEnumClass.enumName;
        }

      } else {
        switch (formField.dataType) {
          case "String":
            dataTypeTs = "string";
            break;
          case "java.time.Instant":
            dataTypeTs = "Date";
            break;
          case "java.time.LocalDate":
            dataTypeTs = "Date";
            break;
          case "java.math.BigDecimal":
            dataTypeTs = "number";
            break;
          case "java.lang.Integer":
            dataTypeTs = "number";
            break;
          case "java.lang.Long":
            dataTypeTs = "number";
            break;
        }
      }

      tsClass = tsClass + " public " + formField.id + " : " + dataTypeTs + ";\r";
    }

    tsClass = tsClass + "}";

    if (enumDefinitions !== "") {
      tsClass = enumDefinitions + "\r\r" + tsClass;
    }

    this.tsClassCode = tsClass;

  }

  convertFormToNode(form: MvsFormDto): TreeNode {
    const node = {};

    node["label"] = form["objectTypeAlias"];
    node["type"] = "form";
    node["styleClass"] = "form";
    node["expanded"] = true;

    // convert form fields
    node["fields"] = [];
    node["relats"] = [];
    for (let formFieldId in form.formFields) {

      const formField = form.formFields[formFieldId];

      if (formField.id.endsWith("DtoId")) {
        node["relats"].push({
          objectTypeAlias: formField.dataType,
          idField: formField.id,
          relationship : formField.relationship,
          nameField : formField.id.replace("DtoId", "DtoName")
        });
      } else if (formField.id.endsWith("DtoName") || formField.id.endsWith("DtoImage") || formField.id.endsWith("DtoColor") || formField.id.endsWith("DtoColorBackground")) {
       // not required
      } else {
        node["fields"].push(formField);
      }

    }
    node["fields"].sort((a, b) => a.order - b.order);


    node["form"] = form;
    node["objectTypeAlias"] = form["objectTypeAlias"];

    node["children"] = [];

    return node;

  }

  /**
   * Get sub templates.
   * @param form
   * @param objectTypeAlias
   */
  getSubTemplates(node: any, objectTypeAlias: string) {

    // check if the value already exists
    if (node["children"]) {
      const exists = node["children"].find(form => {
        return form["objectTypeAlias"] == objectTypeAlias;
      });

      if (exists) {
        return;
      }

    }

    this.busy = true;

    const templateObserver = this.getTemplate(objectTypeAlias);

    if (templateObserver) {

      templateObserver.subscribe(value => {
        if (!node["children"]) {
          node["children"] = [];
        }
        node["children"].push(this.convertFormToNode(value));

        this.busy = false;
      });
    } else {
      this.busy = false;
    }


  }



  getTemplate(objectTypeAlias: string) : Observable<MvsFormDto>|null {

    const crudService = this.coreService.getCrudService(objectTypeAlias);

    if (!crudService) {
      this.messageService.showErrorMessage("No CRUD Service", "CRUD service missing for " + objectTypeAlias);
      return null;
    }

    return crudService.template(new DtoTemplate()).pipe(tap(value => {value["objectTypeAlias"] = objectTypeAlias}));
  }





  /**
   * Init component.
   */
  initComponent() {

    this.objectTypeService.list(ObjectRequestList.createSimple(null)).subscribe(value => {
      this.objectTypes = value;
      this.objectTypes.sortBy("alias", true);
      this.initialized = true;
    });

  }


  ngOnChanges(changes: SimpleChanges): void {

  }


  /**
   * Clean up.
   */
  ngOnDestroy(): void {

  }


}
