import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {IndexRunDto} from "../../dto/index-run.dto";
import {IndexRunStatus} from "../../enum/index-run-status.enum";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'si-index-run-object',
    templateUrl: './si-index-run.component.html',
})
export class SiIndexRunComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    dto: IndexRunDto;

    runObjectsWidget: WidgetData;
    objectWidget: WidgetData;
    objectTokensWidget: WidgetData;
    objectReferencesWidget: WidgetData;

    processes: any;

    selectedObjectTypeId: number;
    selectedObjectTypeName: string;

    showThreads: boolean;

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected http: HttpClient,
        protected override observerService: ObserverService) {
        super(coreService, messageService, confirmationService, observerService);
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.initWidgets();
    }

    initWidgets() {

        let refreshEnabled = false;

        if (this.dto.indexRunStatus == IndexRunStatus.active) {
            refreshEnabled = true;
        }

        this.objectWidget = new WidgetData();
        this.objectWidget.idAlias = "si.dashboard.indexRun.main";
        this.objectWidget.name = "Index Run";
        this.objectWidget.uiComponent = "data";
        this.objectWidget.dataProvider = "transient";
        this.objectWidget.dataSource = "transient";
        this.objectWidget.dataProviderObject = "si.IndexRun";
        this.objectWidget.dataTransient = this.dto;
        this.objectWidget.setParamValue("objectId", this.dto.id);
        this.objectWidget.setParamValue("size", "S");




        this.runObjectsWidget = new WidgetData();
        this.runObjectsWidget.idAlias = "si.dashboard.indexRun.objects";
        this.runObjectsWidget.name = "Objects";
        this.runObjectsWidget.uiComponent = "table";
        this.runObjectsWidget.dataProvider = "list";
        this.runObjectsWidget.dataSource = "entity";
        this.runObjectsWidget.dataProviderObject = "si.IndexRunObject";
        this.runObjectsWidget.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            [FilterCriteria.create("indexRun", FilterCriteria.cOperatorEqual, this.dto.id)],
            [new Sorting("id",false)],
            PagingDto.create(0, 10));

        if (refreshEnabled)  {
            this.runObjectsWidget.uiRefresh = WidgetDataRefresh.createAutoRefresh(true, 5, 1000);
        }
        this.runObjectsWidget.setParamValue("size", "S");
        this.runObjectsWidget.setParamValue("objectWidget", "data");
        this.runObjectsWidget.setParamValue("selectionMode", "rowSelect");


    }

    onObjectSelected(event: ObjectIdentifierData) {

        this.selectedObjectTypeId = event.data["objectTypeDtoId"];
        this.selectedObjectTypeName = event.data["objectTypeDtoName"];

        this.refreshObjectTypeWidgets();

    }
    clearSelection() {
        this.selectedObjectTypeId = undefined;
        this.selectedObjectTypeName = undefined;
        this.objectTokensWidget = undefined;
        this.objectReferencesWidget = undefined;

    }
    refreshObjectTypeWidgets() {

        this.objectTokensWidget = WidgetFactory.createWidgetTableEntity(
            "si.dashboard.indexRun.objects.token",
            `Tokens for Object ${this.selectedObjectTypeId}`,
            "si.IndexedToken",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, this.selectedObjectTypeId)],
                [new Sorting("id",true)],
                PagingDto.create(0, 20))
        );

        this.objectReferencesWidget = WidgetFactory.createWidgetTableEntity(
            "si.dashboard.indexRun.objects.reference",
            `References for Object ${this.selectedObjectTypeId}`,
            "si.IndexedReference",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, this.selectedObjectTypeId)],
                [new Sorting("id",true)],
                PagingDto.create(0, 20))
        );

    }



    showProcesses() {
        this.showThreads = true;
    }


}
