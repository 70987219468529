<ng-container>

    <div class="grid surface-0 h-full mt-2">
        <div class="col-12 p-0">
            <div class="grid h-full">

                <div class="col-8">
                    <div class="shadow-1 h-full">
                        <div class="flex flex-column h-full">
                            <div class="flex justify-content-between align-items-center p-3 surface-50" *ngIf="documentList && documentList.length">
                                <h4 class="m-0 px-3">Document</h4>

                                <div class="flex align-items-center gap-3">
                                     {{selectedDocumentIndex + 1}} / {{documentList?.length}}
                                    <p-button icon="fa fa-arrow-left text-sm" styleClass="p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                                              [disabled]="selectedDocumentIndex == 0"
                                              [pTooltip]="'Previous'"
                                              (click)="handlePreviousDocument()"></p-button>

                                    <p-button icon="fa fa-arrow-right text-sm" styleClass="p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                                              [disabled]="selectedDocumentIndex == documentList?.length - 1"
                                              [pTooltip]="'Next'"
                                              (click)="handleNextDocument()"></p-button>
                                </div>
                            </div>

                            <div class="p-3 text-center h-full" [ngClass]="selectedFile ? 'file-display ' : ''">
                                <canvas #canvas [ngClass]="selectedFile ? '' : 'w-full h-full'" class="surface-50"
                                        (click)="onCanvasClick($event)"></canvas>
                            </div>

                            <div class="grid p-2 pt-3" *ngIf="selectedFile">
                                <div class="lg:col-6 text-right">
                                    <div class="flex align-items-center gap-2 justify-content-end">
                                        <p-button (click)="onChangePage('previous')"
                                                  icon="fa fa-chevron-left text-primary"
                                                  [disabled]="pageNumber == 1"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200">
                                        </p-button>
                                        <span class="align-self-center">Page {{ pageNumber }} of {{ totalPages }}</span>
                                        <p-button (click)="onChangePage('next')"
                                                  icon="fa fa-chevron-right text-primary"
                                                  [disabled]="pageNumber == totalPages"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200">
                                        </p-button>
                                    </div>
                                </div>
                                <div class="lg:col-6 text-right align-self-center">
                                    <div class="flex align-items-center gap-2 justify-content-end">
                                        <p-button (click)="zoomIn()"
                                                  icon="pi pi-search-plus text-sm text-primary"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200"></p-button>
                                        <p-button (click)="zoomOut()"
                                                  icon="pi pi-search-minus text-sm text-primary"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200"></p-button>
                                        <p-button (click)="fullScreen()"
                                                  icon="fa fa-solid fa-compress-wide text-sm text-primary"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200"></p-button>
                                        <p-button (click)="rotateClockwise()"
                                                  icon="fa fa-solid fa-arrow-rotate-right text-sm text-primary"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200"></p-button>
                                        <p-button (click)="rotateCounterClockwise()"
                                                  icon="fa fa-solid fa-arrow-rotate-left text-sm text-primary"
                                                  styleClass="w-2rem h-2rem surface-100 hover:surface-200"></p-button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-4" *ngIf="initialized">
                    <div class="shadow-1 h-full">
                        <div class="flex justify-content-between mb-2 p-3 border-bottom-1 border-300 align-items-center">
                            <div class="flex gap-2 align-items-center">
                                <p-button icon="fa fa-arrow-left" styleClass="p-button-text"
                                          *ngIf="activeTab == 2 || activeTab == 3"
                                          (click)="handleTabNavigation()"></p-button>

                                <h4 class="m-0 font-medium" *ngIf="activeTab == 1">Assign Document</h4>
                                <h4 class="m-0 font-medium" *ngIf="activeTab == 2">Update Details</h4>
                                <h4 class="m-0 font-medium" *ngIf="activeTab == 3">Create Contract</h4>
                            </div>
                            <p-button styleClass="p-button-text" label="Create Ticket"
                                      icon="pi pi-ticket"></p-button>
                        </div>

                        <div style="height: calc(100vh - 255px); overflow: auto">
                            <div class="p-3 pb-0">
                                <ng-container *ngIf="activeTab == 1">

                                    <div class="flex w-full align-items-center justify-content-between mb-5">
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-search"></i>
                                        <input
                                                type="text"
                                                pInputText
                                                placeholder="Search customer or contract"
                                                class="w-full bg-transparent"/>
                                    </span>
                                    </div>

                                    <div class="my-4">
                                        <div class="flex justify-content-between align-items-center gap-1">
                                            <h6 class="font-medium text-base my-2">Matching Customers</h6>
                                            <p-progressSpinner styleClass="w-2rem h-2rem" *ngIf="busy"/>
                                        </div>

                                        <p class="text-center text-700" *ngIf="!matchingCustomers?.length">No
                                            Customers</p>

                                        <!--                                    [ngClass]="selectedCustomer?.id == customer.id || tempSelectedCustomer?.id == customer.id ? 'active-list' : ''"-->
                                        <!--                                    [ngClass]="(tempSelectedCustomer ? tempSelectedCustomer?.id == customer.id : selectedCustomer?.id == customer.id)  ? 'active-list' : ''"-->
                                        <div class="list-section px-1" *ngIf="matchingCustomers?.length">
                                            <div *ngFor="let customer of matchingCustomers"
                                                 [ngClass]="tempSelectedCustomer?.id == customer.id ? 'active-list' : ''"
                                                 class="grid border-1 border-200 border-round-lg p-2 my-2 align-items-center cursor list-hov">
                                                <div class="col-11" (click)="onSearchCustomerContracts(customer)">
                                                    <div class="flex justify-content-between align-items-center">
                                                        <div class="flex gap-2 align-items-center">
                                                            <p-avatar
                                                                    image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
                                                                    [icon]="!customer['image'] ? 'pi pi-user' : ''"
                                                                    styleClass="mr-2"
                                                                    shape="circle"/>
                                                            <span class="font-medium">{{ customer.personDto.firstName }} {{ customer.personDto.lastName }}</span>

                                                        </div>
                                                        <span class="text-sm text-color-secondary">ID: {{ customer.alias }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-1 hover:surface-0 text-center border-round-lg hov-expand-icon"
                                                     (click)="handleNewTabNavigation('cr.Customer', customer.id)">
                                                    <i class="pi pi-window-maximize"></i>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="my-4">
                                        <div class="flex justify-content-between align-items-center gap-1">
                                            <h6 class="font-medium text-base">Matching Contracts</h6>
                                            <p-button styleClass="p-button-text" *ngIf="tempSelectedCustomer"
                                                      label="Add Contract"
                                                      (click)="handleAddContract()"
                                                      icon="fa-regular fa-plus-circle"></p-button>

                                            <p-progressSpinner *ngIf="busy" styleClass="w-2rem h-2rem"/>
                                        </div>
                                        <p class="text-center text-700" *ngIf="!matchingContracts?.length">No
                                            Contract</p>
                                        <div class="list-section px-1" *ngIf="matchingContracts?.length">
                                            <!--                                        [ngClass]="selectedContract?.id == contract.id  || tempSelectedContract?.id == contract.id  ? 'active-list' : ''"-->
                                            <!--                                        [ngClass]="(tempSelectedContract ? tempSelectedContract?.id == contract.id : selectedContract?.id == contract.id)  ? 'active-list' : ''"-->
                                            <div *ngFor="let contract of matchingContracts"
                                                 [ngClass]="tempSelectedContract?.id == contract.id ? 'active-list' : ''"
                                                 class="grid border-1 border-200 border-round-lg p-3 my-2 align-items-center cursor list-hov ">
                                                <div class="col-11" (click)="onContractClick(contract)">
                                                    <div class="flex justify-content-between align-items-center">
                                                        <div class="flex gap-2 align-items-center">
                                                            <i class="fa fa-car text-primary"></i>
                                                            <span class="font-medium">{{ contract.contractIdentifier }}</span>
                                                        </div>
                                                        <span class="text-color-secondary text-sm">ID: {{ contract.id }}</span>
                                                    </div>
                                                </div>

                                                <div class="col-1 hover:surface-0 text-center border-round-lg hov-expand-icon"
                                                     (click)="handleNewTabNavigation('cm.Contract', contract.id)">
                                                    <i class="pi pi-window-maximize"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- continue button -->
                                    <!--                                    <div class="text-right p-3">-->

                                    <!--                                    <p-button *ngIf="documentAssignment && activeTab == 1"-->
                                    <!--                                              class="mr-2"-->
                                    <!--                                              (click)="onNextTab()"-->
                                    <!--                                              label="Next">-->
                                    <!--                                    </p-button>-->

                                    <!--                                        <p-button [disabled]="!tempSelectedCustomer"-->
                                    <!--                                                  (click)="handleNavigation()"-->
                                    <!--                                                  [label]="documentAssignment ? 'Update' : 'Assign'"-->
                                    <!--                                                  icon="fa fa-arrow-right"-->
                                    <!--                                                  iconPos="right">-->
                                    <!--                                        </p-button>-->
                                    <!--                                    </div>-->
                                </ng-container>
                            </div>

                            <!-- matched customer/contract-->
                            <ng-container *ngIf="activeTab == 2">


                                <p-tabView>
                                    <p-tabPanel header="Basic">

                                        <!-- Basic Actions -->
                                        <ng-container>
                                            <div class="grid border-1 border-200 border-round-lg py-2 px-3 mx-3 my-2 align-items-center">
                                                <ng-container *ngIf="selectedCustomer">
                                                    <div class="col-4 py-0">
                                                        <div class="flex gap-2">
                                                            <p-avatar
                                                                    image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
                                                                    [icon]="!selectedCustomer['image'] ? 'pi pi-user' : ''"
                                                                    styleClass="mr-2"
                                                                    shape="circle"/>
                                                            <div class="flex flex-column gap-1">
                                                                <span class="font-medium">{{ selectedCustomer.personDto.firstName }} {{ selectedCustomer.personDto.lastName }}</span>
                                                                <span class="text-color-secondary text-base">ID: {{ selectedCustomer.alias }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>


                                                <div class="col-4 py-0 flex align-items-center" *ngIf="selectedContract">
                                                    <p-divider *ngIf="selectedContract && selectedCustomer"
                                                               layout="vertical"/>
                                                    <div class="flex gap-3">
                                                        <i class="fa fa-car pt-1"></i>
                                                        <div class="flex flex-column gap-1">
                                                            <span class="font-medium">{{ selectedContract.contractIdentifier }}</span>
                                                            <span class="text-color-secondary text-base">ID: {{ selectedContract.id }}</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col py-0 flex align-items-center">
                                                    <p-divider layout="vertical"/>
                                                    <div class="flex flex-column gap-1">
                                                        <p-button (click)="menu.toggle($event)"
                                                                  styleClass="p-button-text text-color p-1" iconPos="right"
                                                                  label="Document Status"
                                                                  icon="fa-regular fa-chevron-down text-sm text-primary"/>

                                                        <ng-container *ngFor="let item of enumValues">
                                                            <p-tag *ngIf="item.key == this.documentDto.status"
                                                                   class="text-center"
                                                                   [icon]="item['icon']" [value]="item.label"
                                                                   styleClass="bg-{{item.backgroundColor}} text-{{item.color}} gap-1 px-2" />
                                                        </ng-container>


<!--                                                        <span class="text-color-secondary px-1 text-base">{{ selectedStatus }}</span>-->
                                                    </div>
                                                </div>
                                            </div>

                                            <document-process-actions
                                                    [userActions]="userActions"
                                                    [selectedContract]="selectedContract"
                                                    [selectedCustomer]="selectedCustomer"
                                                    [prefilledLabels]="prefilledLabels"
                                                    [fields]="documentFields">
                                            </document-process-actions>

<!--                                            <div class="flex flex-column gap-2 px-3" *ngIf="prefilledLabels?.length">-->
<!--                                                <h4 class="mx-0 my-2 px-2">Fields</h4>-->

<!--                                                <ng-container *ngFor="let label of prefilledLabels">-->
<!--                                                    <div class="flex gap-2 px-2 mb-2">-->
<!--                                                        <div class="pt-1" style="width:14px; height: 14px;">-->
<!--                                                                <span [ngStyle]="{'background-color': getLabelColors(label.label)}"-->
<!--                                                                      class="block"-->
<!--                                                                      style="width:12px; height: 12px; border-radius: 50%"></span>-->
<!--                                                        </div>-->
<!--                                                        <div class="flex flex-column gap-1">-->
<!--                                                            <div class="text-lg mb-1">{{ label.label }}-->
<!--                                                            </div>-->
<!--                                                            <span class="font-medium text-base"-->
<!--                                                                  *ngIf="label.textPaths">{{ label.textPaths.join(' ') }}</span>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </ng-container>-->
<!--                                                <div class="flex flex-wrap gap-2 p-3 surface-100 border-round-lg my-2"-->
<!--                                                     *ngIf="userActions">-->
<!--                                                    <h5 class="font-medium w-full m-0">Update</h5>-->
<!--                                                    <p-chip class="cursor" pRipple-->
<!--                                                            icon="fa-solid fa-circle text-red-500"-->
<!--                                                            styleClass="surface-0 border-1 border-300 hover:bg-blue-50"-->
<!--                                                            *ngFor="let item of userActions"-->
<!--                                                            [label]="item.action"-->
<!--                                                            (click)="displayHistory(item)">-->
<!--                                                    </p-chip>-->
<!--                                                </div>-->
<!--                                            </div>-->

<!--                                            <mvs-widget *ngIf="historyWidget"-->
<!--                                                        [widgetData]="historyWidget"-->
<!--                                                        [widgetToolbarButtons]="historyWidgetToolbarButtons"-->
<!--                                                        (onToolbarButtonClick)="createNewUserAction()"-->
<!--                                            >-->
<!--                                            </mvs-widget>-->

<!--                                            <ng-container *ngIf="actionWidget">-->
<!--                                                <p-divider styleClass="my-2"></p-divider>-->
<!--                                                <mvs-widget [widgetData]="actionWidget"></mvs-widget>-->
<!--                                            </ng-container>-->

                                        </ng-container>

                                    </p-tabPanel>

                                    <p-tabPanel header="Data">
                                        <!-- Widgets -->
                                        <cm-contract-person-object-widgets
                                                *ngIf="selectedCustomer && selectedContract"
                                                [customerDto]="selectedCustomer"
                                                [contractDto]="selectedContract">
                                        </cm-contract-person-object-widgets>
                                    </p-tabPanel>
                                </p-tabView>

                            </ng-container>

                            <!-- Create new Contract -->
                            <ng-container *ngIf="activeTab == 3">

                                <div class="flex gap-3 border-1 border-200 border-round-lg p-2 m-3 align-items-center">
                                    <ng-container *ngIf="tempSelectedCustomer">
                                        <div class="flex gap-2 align-items-center">
                                            <p-avatar
                                                    image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
                                                    [icon]="!tempSelectedCustomer['image'] ? 'pi pi-user' : ''"
                                                    styleClass="mr-2"
                                                    shape="circle"/>
                                            <div class="flex flex-column gap-1">
                                                <span class="font-medium">{{ tempSelectedCustomer.personDto.firstName }} {{ tempSelectedCustomer.personDto.lastName }}</span>
                                                <span class="text-color-secondary text-sm">{{ tempSelectedCustomer.alias }}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="activeTab == 3">
                                    <h4>Add Contract</h4>
                                    <mvs-crud-object
                                            [objectType]="'cm.Contract'"
                                            (onChangedObject)="handleContractCreate($event)"

                                    ></mvs-crud-object>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="flex gap-2 justify-content-end p-3" *ngIf="activeTab == 1">

                            <p-button label="Skip" styleClass="p-button-text surface-50 px-4" (click)="activeTab = 2"
                                      *ngIf="activeTab == 1 && documentAssignment"></p-button>

                            <p-button [disabled]="!tempSelectedCustomer"
                                      (click)="handleNavigation()"
                                      [label]="documentAssignment ? 'Update' : 'Assign'"
                                      icon="fa fa-arrow-right"
                                      iconPos="right">
                            </p-button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-contextMenu styleClass="mt-3 w-auto" #cm [model]="contextMenuItems" [baseZIndex]="1000"></p-contextMenu>
    <p-menu #menu [model]="enumValues" [popup]="true"/>
</ng-container>
