import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    AlphaBatchEntityObjectBaseComponent
} from "../../component/object-base-components/alpha-batch-entity-object-base-component/alpha-batch-entity-object-base.component";

@Injectable({
    providedIn: 'root'
})
export class IntegrationAlphaBatchEntityService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/ha/integrationAlphaBatchEntitys')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return AlphaBatchEntityObjectBaseComponent;
        }
        return null;
    }
}