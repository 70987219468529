import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {AgentPoolService} from 'src/app/am/service/api/agent-pool.service';
import {AgentService} from 'src/app/am/service/api/agent.service';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {TmTicketObjectDto} from '../../dto/tm-ticket-object.dto';
import {TmTicketTypeObjectTypeDto} from '../../dto/tm-ticket-type-object-type.dto';
import {TmStatusEnum} from '../../enum/tm-status.enum';
import {TicketObjectService} from '../../service/api/ticket-object.service';
import {TicketTypeObjectTypeService} from '../../service/api/ticket-type-object-type.service';
import {TicketService} from '../../service/api/ticket.service';
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ticket-indicator',
    templateUrl: './ticket-indicator.component.html',
    styleUrls: ['./ticket-indicator.component.scss']
})

export class TicketIndicatorComponent
    extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    marginStyle = {};

    @Input() objectTypeId: number;
    @Input() objectId: number;
    @Input() overlayPosition: string;

    serviceTicketObject: TicketObjectService;
    serviceTicketTypeObjectType: TicketTypeObjectTypeService;
    serviceTicket: TicketService;
    serviceAgent: AgentService;
    serviceAgentPool: AgentPoolService;
    objectList: DtoList<TmTicketObjectDto>;
    ticketTypeObjectTypeList: DtoList;
    ticketTypeList: any;
    resolvedTicketCount: number;
    ticketExist: boolean = false;
    alias: string;
    name: string;
    selectedTicketType: number;
    loggedOnAgent: DtoDetail;
    loggedOnAgentPool: DtoDetail;

    constructor(protected coreService: MvsCoreService,
                protected messageService: MvsMessageService,
                protected confirmationService: ConfirmationService,
                protected navigationService: MvsObjectNavigationService,
                protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve ticket type service
        this.serviceTicketObject = <TicketObjectService>this.coreService.getCrudService("tm.TicketObject");

        // retrieve ticket type object type service
        this.serviceTicketTypeObjectType = <TicketTypeObjectTypeService>this.coreService.getCrudService("tm.TicketTypeObjectType");

        // retrieve ticket service
        this.serviceTicket = <TicketService>this.coreService.getCrudService("tm.Ticket");

        // retrieve agent service
        this.serviceAgent = <AgentService>this.coreService.getCrudService("am.Agent");

        // retrieve agent pool service
        this.serviceAgentPool = <AgentPoolService>this.coreService.getCrudService("am.AgentPool");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshTicketObjects();

        this.refreshAgent();

        this.refreshAgentPool();

        this.refreshTicketTypeObjectType();

        this.overlayPositions();

        this.initialized = true;
    }

    overlayPositions() {
        switch (this.overlayPosition) {
            case 'top-left':

                this.marginStyle = {'margin-left': '-50rem', 'margin-top': '0px'};
                break;

            case 'top-right':

                this.marginStyle = {'margin-right': '0', 'margin-top': '0px'};
                break;

            case 'bottom-left':

                this.marginStyle = {'margin-left': '-50rem', 'margin-top': '30rem'};
                break;

            case 'bottom-right':

                this.marginStyle = {'margin-right': '0', 'margin-top': '30rem'};
                break;

            default:
                break;
        }
    }

    getObjectFilter(): FilterCriteria[] {
        return [FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, this.objectTypeId, null), FilterCriteria.create("objectId", FilterCriteria.cOperatorEqual, this.objectId, null)];
    }

    openTicketDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", ticketId, null,"Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    refreshAgent() {
        this.serviceAgent.me().subscribe(res => {
            this.loggedOnAgent = res;
        })
    }

    refreshAgentPool() {
        this.serviceAgentPool.my().subscribe(res => {
            this.loggedOnAgentPool = res.entries[0];
        })
    }

    refreshTicketObjects() {

        const dtoListRequest = new ObjectRequestList(false, this.getObjectFilter(), [new Sorting("lastModifiedDate", false)]);
        dtoListRequest.objectRequestSimple = ObjectRequestSimple.create(true, true,1);
        this.serviceTicketObject.list(dtoListRequest).subscribe(
            ticketObjects => {
                this.objectList = <DtoList<TmTicketObjectDto>>ticketObjects;
                this.resolvedTicketCounter();
            }
        );

    }

    refreshTicketTypeObjectType() {

        const dtoListRequest = new ObjectRequestList(false, [FilterCriteria.create("objectType", FilterCriteria.cOperatorEqual, this.objectTypeId, null)], [new Sorting("lastModifiedDate", false)]);
        dtoListRequest.objectRequestSimple = ObjectRequestSimple.create(true, true,1);

        this.serviceTicketTypeObjectType.list(dtoListRequest).subscribe(
            (response: DtoList) => {
                this.ticketTypeObjectTypeList = response;
                this.ticketTypeList = response.entries.map((entry: TmTicketTypeObjectTypeDto) => {
                    return {id: entry.ticketTypeDtoId, name: entry.ticketTypeDtoName}
                })
            }
        )

    }


    resolvedTicketCounter() {
        this.resolvedTicketCount = 0;
        if (this.objectList.entries.length) {
            this.ticketExist = true;

            this.resolvedTicketCount = this.objectList.entries.reduce(
                (accumulator: any, currentValue: any) =>
                    currentValue.ticketDto.status != TmStatusEnum.resolved
                        ? accumulator = accumulator + 1 : accumulator, 0);
        }
    }

    createTicket() {
        this.busy = true;
        const ticket: TmTicketDto = new TmTicketDto();

        ticket.alias = this.alias;
        ticket.name = this.name;
        ticket.typeDtoId = this.selectedTicketType;
        ticket.assigneeAgentPoolDtoId = this.loggedOnAgentPool?.id ? this.loggedOnAgentPool?.id : null;
        ticket.assigneeAgentDtoId = this.loggedOnAgent.id;

        alert("REFERNECE Object not implemented!");


        this.serviceTicket.create(ticket).subscribe(
            value => {
                // refresh Ticket objects
                this.refreshTicketObjects();
                this.busy = false;
                this.openTicketDrawer(value.id);
                this.messageService.showSuccessMessage("Ticket", "Ticket Created");

                this.createTicketObjects(value.id, this.objectTypeId, this.objectId);
                this.alias = '';
                this.name = '';
            }, error => {

                this.busy = false;
                this.messageService.showErrorMessage("Ticket", "Error occured");
            });
        // }
    }

    createTicketObjects(ticketDtoId: number, objectTypeDtoId: number, objectId: number) {
        this.busy = true;

        const ticketObject: TmTicketObjectDto = new TmTicketObjectDto();

        ticketObject.ticketDtoId = ticketDtoId;
        ticketObject.objectTypeDtoId = objectTypeDtoId;
        ticketObject.objectId = objectId;

        this.serviceTicketObject.create(ticketObject).subscribe(value => {
            // refresh Ticket objects
            this.refreshTicketObjects();
            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "Object added");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        });
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (this.objectId || this.objectTypeId) {
            this.refreshComponent();
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
