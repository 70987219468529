import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ContractService} from "../../service/api/contract.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ContractDto} from "../../dto/contract.dto";

interface iObject {
    id: number,
    name: String
}

@Component({
    selector: 'cm-contract-navigation-contract',
    templateUrl: './cm-contract-navigation-dropdown.component.html',
})
export class CmContractNavigationDropdownComponent implements OnInit {


    @Input() paramName: string = 'contracts';
    objectList: iObject[];
    selectedObject: iObject;
    currentIndex: number;
    @Output() onSelect = new EventEmitter<number>;
    initialized: boolean;

    constructor(protected route: ActivatedRoute, protected router: Router, protected contractService: ContractService) {
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            if (params[this.paramName]) {
                const idList: { id: number }[] = params[this.paramName].split(',').map(id => ({id: +id}));
                if (idList && idList.length) {
                    this.resolveContractNames(idList);
                }
            }
        });

    }

    handleSelect(): void {
        this.onSelect.emit(this.selectedObject.id)
    }

    gotoPrevious() {
        const currentIndex = this.objectList.indexOf(this.selectedObject);
        if (currentIndex > 0) {
            const previousObject = this.objectList[currentIndex - 1];
            this.selectedObject = previousObject;
            this.onSelect.emit(previousObject.id);
        } else {
            // Handle if there's no previous ID, maybe wrap around to the last item or any other logic.
        }
    }

    gotoNext() {
        const currentIndex = this.objectList.indexOf(this.selectedObject);
        if (currentIndex < this.objectList.length - 1) {
            const nextObject = this.objectList[currentIndex + 1];
            this.selectedObject = nextObject;
            this.onSelect.emit(nextObject.id);
        } else {
            // Handle if there's no next ID, maybe wrap around to the first item or any other logic.
        }
    }

    resolveContractNames(ids: { id: number }[]) {
        let filterCriteria = FilterCriteria.createOrFromArray('id', 'id', ids);
        const objectRequestList = new ObjectRequestList(false, [filterCriteria], []);
        this.contractService.list(objectRequestList).subscribe((res: DtoList<ContractDto>) => {

            if(!this.objectList){
                this.objectList = [];
            }
            for (let item of res.entries){
                let contractName = '';
                if(!item.alias && !item.contractTypeDtoName && !item.startDate) {
                    contractName = item.id+'';
                }
                else {
                    if(item.alias) {
                        if(contractName != '') {
                            contractName +=  ' ';
                        }
                        contractName +=  item.alias
                    }
                        if(item.contractTypeDtoName) {
                            if(contractName != '') {
                            contractName += ' ';
                        }
                          contractName += item.contractTypeDtoName
                    }
                    if(item.startDate) {
                        if(contractName != '') {
                            contractName += ' ';
                        }
                        contractName +=  item.startDate;
                    }
                }
                const obj = {id:item.id, name: contractName}
                this.objectList.push(obj);
            }
            const currentParams = {...this.route.snapshot.params};
            const currentId = Number(currentParams['id']);
            this.selectedObject = this.objectList.find(item => item.id == currentId);
            this.currentIndex = this.objectList.findIndex(item => item.id == currentId);
            this.initialized = true;
        })
    }


}
