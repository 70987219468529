import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectPageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'mvs-pc-call-page',
  templateUrl: './pc-call.page.html'
})
export class PcCallPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

  defaultLabel: string = "Anruf";


  protected getObjectType(): string {
    return "pc.Call";
  }

}
