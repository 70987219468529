import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsCreateTicketOutputInterface} from "./interface/mvs-create-ticket-output.interface";
import {
    DtoDetail,
    DtoList,
    MvsCoreService,
    MvsMessageService,
    ObjectRequestList,
    ObjectTypeService
} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../../tm/service/api/ticket.service";
import {ConfirmationService} from "primeng/api";
import {TmTicketTypeDto} from "../../../../../tm/dto/tm-ticket-type.dto";
import {TicketTypeService} from "../../../../../tm/service/api/ticket-type.service";
import {OverlayPanel} from "primeng/overlaypanel";

@Component({
    selector: 'mvs-create-ticket-output-component',
    templateUrl: './mvs-create-ticket-output.component.html',
})
export class MvsCreateTicketOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsCreateTicketOutputInterface {


    objectType: string;
    objectId: number;
    ticketTypeId: number;
    ticketTypes: DtoDetail[];

    busy: boolean;
    initialized: boolean;
    @ViewChild("op", {static: true}) createTicketOverlay: OverlayPanel;



    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected messageService: MvsMessageService,
                protected coreService: MvsCoreService,
                protected ticketService: TicketService,
                protected ticketTypeService: TicketTypeService,
                private confirmationService: ConfirmationService) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectType = this.formField['uiConfigData']['objectType'];
        this.ticketTypeId = this.formField['uiConfigData']['ticketType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];
        this.objectId = this.dto[objectIdField];

        this.refreshComponent();

    }

    handleCreateTicket(event: MouseEvent, op: OverlayPanel) {
        event.stopPropagation();

        if (!this.ticketTypeId) {
            this.askTicketType(event, op);
        } else {
            this.onCreateTicket();
        }
    }

    onCreateTicket() {
        this.confirmationService.confirm({
            message: 'Möchten Sie wirklich ein neues Ticket erstellen?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // Call the ticket creation logic
                this.ticketService.createTicketForObject(this.ticketTypeId, this.objectId, this.objectType).subscribe(res => {
                    // this.messageService.add({ severity: 'success', summary: '', detail: 'Ticket created' });
                    const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
                        'tm.Ticket', res.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any
                    );
                    if (this.createTicketOverlay) {
                        this.createTicketOverlay.hide();
                    }
                    this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
                }, error => {

                });
            },
            reject: () => {
            }
        });
    }

    askTicketType(event: MouseEvent, op: OverlayPanel) {

        if (this.ticketTypes && !this.ticketTypes.length) {
            op.toggle(event);
            return;
        }

        this.ticketTypeService.list(new ObjectRequestList(false, [], [])).subscribe((res: DtoList<TmTicketTypeDto>) => {
            const entries = res.entries;
            this.ticketTypes = entries.filter(item => item.active == true && item.instanceAble == true);
            op.toggle(event);
            this.initialized = true;
        })
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

            this.refreshComponent();

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
