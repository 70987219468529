import {Component, OnInit} from '@angular/core';
import {LgCalculationLogicTypeObjectBaseComponent} from "../lg-calculation-logic-type-object-base.component";

@Component({
    selector: 'mvs-lg-calculation-logic-type-object-side',
    templateUrl: 'lg-calculation-logic-type-object-side.component.html'
})
export class LgCalculationLogicTypeObjectSideComponent extends LgCalculationLogicTypeObjectBaseComponent implements OnInit {


}
