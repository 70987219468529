<ng-container *ngIf="initialized">

    <div class="mvs-widget">

        <div class="flex justify-content-end mb-2">
                <p-toggleButton [(ngModel)]="showActive" (onChange)="this.handleObjectTypeId()"
                                [styleClass]=""
                                onIcon="fa-regular fa-angles-up"
                                offIcon="fa-regular fa-angles-down"
                >

                </p-toggleButton>
        </div>

        <mvs-widget
                *ngIf="objectTicketList"
                [widgetData]="objectTicketList"
                (onFieldValueSelected)="onFieldValueSelected($event)"
        ></mvs-widget>

    </div>

</ng-container>