import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {MvsCrudOneToManyComponent} from "@kvers/alpha-ui";
import {
  DtoDetail,
  MvsApiCrudServiceCommentsInterface,
  MvsCrudService,
  SearchArrayHelper
} from "@kvers/alpha-core-common";
import {formatDate} from "@angular/common";

@Component({
  selector: 'mvs-object-comments',
  templateUrl: './mvs-object-comments.component.html',
  styleUrls: ['./mvs-object-comments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MvsObjectCommentsComponent
  extends MvsCrudOneToManyComponent {

  @ViewChild('commentNewText', {read: ElementRef}) inputCommentNewText: ElementRef<HTMLTextAreaElement>;

  @Input() headline: string;
  @Input() showFullText: boolean = false;
  @Input() showGroupedComments: boolean = false;

  newComment: string;
  commentAttribute: string;
  commentInvalid: boolean = true;
  groupedComments: any;
  searchTerm: string;
  showSearch: boolean = false;
  modifiedEntries: any;

  /**
   * Check linked Entity Service.
   * @param service
   * @protected
   */
  protected override checkLinkedEntityService(service: MvsCrudService): boolean {

    if ("getCommentAttribute" in service) {

      let commentService: MvsApiCrudServiceCommentsInterface;
      commentService = <MvsApiCrudServiceCommentsInterface><unknown>service;

      this.commentAttribute = commentService.getCommentAttribute();
      return true;

    } else {
      return false;
    }

  }

  /**
   * Validate comment while entered by user (to enable/ disable send button).
   * @param event
   */
  onCommentTextChange(event: any) {
    this.commentInvalid = !this.checkCommentTextValid(this.newComment);
  }

  /**
   * Validate Comment.
   * @param text
   */
  checkCommentTextValid(text: string): boolean {
    return this.commentAttribute && this.newComment.replace(/\s/g, '').length > 0;
  }

  /**
   * Create new comment.
   */
  createComment() {
    if (!this.busy) {


      // make sure something was entered
      if (!this.checkCommentTextValid(this.newComment)) {
        return;
      }

      // prepare DTO
      const newDto = new DtoDetail();
      newDto[this.commentAttribute] = this.newComment;


      // create DTO
      this.actionCreateLinkedEntity(newDto);
      // if(this.showGrouppedComments){
      //   this.groupComments();
      // }


    }
  }

  onAdjustTextArea(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25) + "px";
  }

  // onKeyUp() {
  //   // Reset the save timeout when the user types something
  //   clearTimeout(this.saveTimeout);
  //   if(this.checkCommentTextValid(this.newComment)){
  //     this.saveTimeout = setTimeout(() => this.createComment(), 5000);
  //   }
  // }

  groupComments() {

    let commentsArray = this.linkedEntityData.entries;
    for (const item of commentsArray) {
      item['date'] = formatDate(item.createdDate, 'yyyy-MM-dd', 'en-US');
    }

    this.groupedComments = Object.entries(
      commentsArray.reduce((acc, comment) => {
        const key = `${comment.createdBy}--${comment['date']}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(comment['comment']);
        return acc;
      }, {})
    ).map(([key, value]) => ({
      key,
      comments: (value as string[]).join('\n')
    }));

    for (const item of this.groupedComments) {
      item['lastModifiedBy'] = item.key.substring(0, item.key.indexOf('--'));
      const parts = item.key.split('--');
      item['createdDate'] = parts[1];
    }

    this.modifiedEntries = this.groupedComments;
  }

  ungroupComments() {
    this.modifiedEntries = this.linkedEntityData.entries;
  }


  override ngOnInit() {
    super.ngOnInit();


  }

  override refreshUi() {
    super.refreshUi();
    this.onToggleCommentsView();
    this.clearCommentField();
  }

  clearCommentField() {
    this.newComment = null;
  }

  onToggleCommentsView() {

    if (this.showGroupedComments) {
      this.groupComments();
    } else {
      this.ungroupComments();
    }
  }

  onToggleSearchButton() {
    this.showSearch = !this.showSearch;
  }

  split(str, index) {
    return [str.slice(0, index), str.slice(index)];
  }

  onSearch() {
    const searchArrayHelper = new SearchArrayHelper();
    let array: any = searchArrayHelper.getFilterEntries(this.linkedEntityData.entries,
      ['comment'], this.searchTerm, 'pink');
    this.modifiedEntries = array;

    // if(!this.searchTerm){
    //   this.modifiedEntries = this.linkedEntityData.entries;
    // }
    // this.modifiedEntries = this.linkedEntityData.entries.filter(value =>
    //     value['comment'].toLowerCase().includes(this.searchTerm.toLowerCase()));
    // const regex = new RegExp(this.searchTerm, 'gi');
    //
    //
    // this.modifiedEntries = this.modifiedEntries.map(item => {
    //   const comment = item['comment'];
    //   const modifiedComment = comment.replace(regex, '<span style="background: yellow">$&</span>');
    //   return { ...item, comment: modifiedComment };
    // });
  }

  toggleFullText() {
    this.showFullText = !this.showFullText
  }


}
