import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {TmTicketActionDto} from '../../dto/tm-ticket-action.dto';
import {COMMENT_SETTING} from '../../enum/comment-setting.enum';
import {TicketActionService} from '../../service/api/ticket-action.service';
import {TicketTypeActionService} from '../../service/api/ticket-type-action.service';
import {ConfirmationService, MenuItem} from "primeng/api";
import {ContextMenu} from "primeng/contextmenu";
import {OverlayPanel} from "primeng/overlaypanel";
import {TmTicketTypeActionDto} from "../../dto/tm-ticket-type-action.dto";
import {UmUserService} from "../../../um/service/api/um-user.service";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ticket-type-action',
    templateUrl: './ticket-type-action.component.html',
    styleUrls: ['./ticket-type-action.component.scss']
})
export class TicketTypeActionComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    enum: typeof COMMENT_SETTING = COMMENT_SETTING;
    @Input() ticketId: number;
    @Input() dtoTicketType: DtoDetail;
    @Output() onAction = new EventEmitter();
    @ViewChild('op') overlayPanel: OverlayPanel;
    actionComment: string;
    ticketTypeList: any = [];
    menuItem: MenuItem[];
    serviceTicketTypeAction: TicketTypeActionService;
    serviceTicketAction: TicketActionService;
    selectedAction: TmTicketTypeActionDto;
    userNamesList: DtoList[];

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected userService: UmUserService,
        protected override observerService: ObserverService) {
        super(coreService, messageService, confirmationService, observerService)
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve ticket-type service
        this.serviceTicketTypeAction = <TicketTypeActionService>this.coreService.getCrudService("tm.TicketTypeAction");

        // retrieve ticket-actions service
        this.serviceTicketAction = <TicketActionService>this.coreService.getCrudService("tm.TicketAction");

        // retrieve list of all active users (required for the mentions within the comments)
        this.userService.getAllActiveUsers().subscribe(value => {
            let dtoListUsers: any = value;
            this.userNamesList = dtoListUsers.entries.map(
                (element: any) => element.name
            )
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshTicketTypeActionType();

        this.initialized = true;
    }

    /**
     * refresh ticket type action type
     */
    refreshTicketTypeActionType() {
        this.serviceTicketTypeAction.listActionsFromTicketType(this.dtoTicketType).subscribe(
            element => {
                this.menuItem = [];
                this.ticketTypeList = element.entries;

                this.ticketTypeList.forEach(item => {
                    const itemValue = {
                        id: item.id,
                        label: item.name,
                        commentSetting: item.commentSetting,
                        command: (event) => {
                            this.selectedAction = item;
                            this.overlayPanel.toggle(event.originalEvent);
                        }
                    }
                    this.menuItem.push(itemValue)
                })

            }
        )
    }

    /**
     * add or create ticket action
     * @param ticketTypeActionId
     */
    createTicketAction(ticketTypeActionId: number) {

        this.busy = true;
        const ticketAction: TmTicketActionDto = new TmTicketActionDto()
        ticketAction.ticketDtoId = this.ticketId;
        ticketAction.ticketTypeActionDtoId = ticketTypeActionId;
        ticketAction.comment = this.actionComment;

        this.serviceTicketAction.create(ticketAction).subscribe(value => {

            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "ticket action created");
            this.actionComment = "";
            this.onAction.emit();
            this.overlayPanel.hide();
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
            this.overlayPanel.hide();
        })

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
