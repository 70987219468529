import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";


@Component({
    selector: 'am-appointment-dashboard-page',
    templateUrl: './am-appointment-dashboard.page.html',
    styleUrl: "am-appointment-dashboard.page.scss"
})
export class AmAppointmentDashboardPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    periods: { id: number, name: string }[] = [
        {id: 1, name: 'Today'},
        {id: 2, name: 'This Week'},
        {id: 3, name: 'This Month'},
        {id: 4, name: 'Previous Month'},
        {id: 5, name: 'This Year'},
    ];

    selectedPeriod: { id: number, name: string };

    items = Array(8).fill({kpi: 20, title: 'The Title'});

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService
    ) {
        super(route, coreService)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
