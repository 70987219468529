import {DtoDetail} from "@kvers/alpha-core-common";
import { TicketDto } from "./ticket.dto";
import { TicketTypeDto } from "./ticket-type.dto";

export class TicketDataDto extends DtoDetail{


    public ticketDto? : TicketDto;
    public ticketDtoId : number;
    public ticketDtoName : string;
    public typeDto? : TicketTypeDto;
    public typeDtoId : number;
    public typeDtoName : string;
    public comment: string;

}