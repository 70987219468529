export const environment = {
    production: false,
    debug: true,
    name: "local",

    API_URL: "https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa",
    MS_graph_endpoint : "https://graph.microsoft.com/v1.0/me",
    oauth2_issuer: "https://mvsalphaplatform-mvs-auth-dev.azuremicroservices.io", // "http://localhost:8081",

    // gateway is not used, the tenant information is passed directly
    gateway: "https://alpha-prod-gateway.calmforest-6dbf5289.germanywestcentral.azurecontainerapps.io", // http://localhost:8082,

    tenant_alias: null,
    tenant_name: null,
    tenant_logo: null

};

