import {Component} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetData} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ticket-history-page',
    templateUrl: './ticket-history.page.html',
    styleUrls: ['./ticket-history.page.scss']
})
export class TicketHistoryPage extends PageComponent {

    defaultLabel: string = 'Ticket History';
    widgetTicketTypeHistoryChart: WidgetData;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Overwritten method from Page.
     * @protected
     */
    protected preInit() {
        this.initialized = true;
    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshTicketHistoryWidget();
    }

    /**
     * refresh history chart widget
     */
    refreshTicketHistoryWidget() {
        this.widgetTicketTypeHistoryChart = new WidgetData();
        this.widgetTicketTypeHistoryChart.id = 1;
        this.widgetTicketTypeHistoryChart.idAlias = "tm.history.ticket.type.history";
        this.widgetTicketTypeHistoryChart.name = "Ticket History";

        this.widgetTicketTypeHistoryChart.uiComponent = "history.chart";
        this.widgetTicketTypeHistoryChart.dataProvider = "list";
        this.widgetTicketTypeHistoryChart.dataSource = "entity.groupBy";
        this.widgetTicketTypeHistoryChart.dataProviderObject = "tm.Ticket";
        this.widgetTicketTypeHistoryChart.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["type", "createdDate"],
            [new ObjectRequestListAttribute("type", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.widgetTicketTypeHistoryChart.setParamValue("fieldCategory", "type");
        this.widgetTicketTypeHistoryChart.setParamValue("fieldCategoryCount", "type_count");
        this.widgetTicketTypeHistoryChart.setParamValue("historyPeriodMode", "month");
        this.widgetTicketTypeHistoryChart.setParamValue("fieldDate", "createdDate");
        this.widgetTicketTypeHistoryChart.setParamValue("selectionMode", "single");
        this.widgetTicketTypeHistoryChart.setParamValue("size", "S");
    }

}
