import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    FilterCriteria,
    MvsObjectService,
    NavigationItem,
    ObjectBaseModeComponent,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../../../core/helper/widget-function-call-back-create";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {EmSchemaFieldDto} from "../../../../dto/em-schema-field.dto";
import {TreeNode} from "primeng/api";
import {EmSchemaStructureService} from "../../../../service/api/em-schema-structure.service";
import {EmSchemaFieldTypeInternal} from "../../../../enum/em-schema-field-type-internal.enum";
import {EmSchemaCardinality} from "../../../../enum/em-schema-cardinality.enum";
import {TreeNodeExpandEvent} from "primeng/tree";
import {en} from "@fullcalendar/core/internal-common";


@Component({
    selector: 'em-schema-object-component-base',
    template: '',
})
export class EmSchemaObjectBaseComponent extends ObjectBaseModeComponent implements OnInit, OnChanges, OnDestroy {


    emSchemaWidget: WidgetData;
    emSchemaFieldWidget: WidgetData;
    emSchemaFieldValueWidget: WidgetData;
    emSchemaStructureWidget: WidgetData;

    selectField: EmSchemaFieldDto
    broadcastSubscription: Subscription;
    nodes: TreeNode[] = [];

    navigationItems = [
        {
            label: 'em.EmSchema',
            action: 'em.EmSchema',
            icon: 'fa-regular fa-circle-info text-xl',
            tooltip: 'Schema',
            toggleable: false,
            default: true
        },
        {
            label: 'em.EmSchemaField',
            action: 'em.EmSchemaField',
            icon: 'fa-regular fa-list-tree text-xl',
            tooltip: 'Schema Fields',
            toggleable: false,
            default: false
        },
        {
            label: 'em.EmSchemaStructure',
            action: 'em.EmSchemaStructure',
            icon: 'fa-regular fa-database text-xl',
            tooltip: 'Schema Structure',
            toggleable: false,
            default: false
        },
        {
            label: 'em.EmSchemaRuntimeData',
            action: 'em.EmSchemaRuntimeData',
            icon: 'fas fa-database text-xl',
            tooltip: 'Schema Runtime Data',
            toggleable: false,
            default: false
        }
    ];

    constructor(
        protected route: ActivatedRoute,
        protected objectService: MvsObjectService,
        protected schemaStructureService: EmSchemaStructureService) {
        super(route);
    }

    ngOnInit() {

        super.ngOnInit();

        this.refreshWidgets();

        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.objectType == "em.EmSchemaFieldComment") {
                this.refreshEmSchemaFieldWidget();
            }

            if (change.objectType == "em.EmSchemaFieldValueComment") {
                this.handleRefreshEmSchemaFieldValueWidget();
            }
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    refreshWidgets() {
        this.refreshEmSchemaWidget();
        this.refreshEmSchemaFieldWidget();
        this.refreshEmSchemaStructureWidget();

    }

    refreshEmSchemaWidget() {
        this.emSchemaWidget = WidgetFactory.createWidgetEntityData(
            'em.schema.data.widget',
            'Em Schema',
            'em.EmSchema',
            this.objectIdentifier.objectId
        );
    }

    refreshEmSchemaFieldWidget() {

        const filterCriteria = FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('fieldCommentDto', ObjectRequestRelation.createJoin('em.EmSchemaFieldComment#schemaField')),
        );


        this.emSchemaFieldWidget = WidgetFactory.createWidgetTableEntity(
            'em.schema.field.table.widget',
            'Schema Fields',
            'em.EmSchemaField',
            'No Data',
            objectRequestList
        );


        const fields: WidgetToolbarCreateInterface[] = [{
            fieldName: 'schemaDtoId',
            fieldValue: this.objectIdentifier.objectId
        }]

        this.emSchemaFieldWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    refreshEmSchemaFieldValueWidget(fieldId: number) {

        const filterCriteria = [
            FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create('field', FilterCriteria.cOperatorEqual, fieldId)
        ];

        const objectRequestList = ObjectRequestList.createBasic(true, filterCriteria, []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('fieldValueCommentDto', ObjectRequestRelation.createJoin('em.EmSchemaFieldValueComment#schemaFieldValue')),
        );


        this.emSchemaFieldValueWidget = WidgetFactory.createWidgetTableEntity(
            'em.schema.field.value.table.widget',
            'Schema Field Value',
            'em.EmSchemaFieldValue',
            'No Data',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{
            fieldName: 'schemaDtoId',
            fieldValue: this.objectIdentifier.objectId
        },
            {
                fieldName: 'fieldDtoId',
                fieldValue: fieldId
            }]

        this.emSchemaFieldValueWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);

    }

    refreshEmSchemaStructureWidget() {

        const filterCriteria = FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.emSchemaStructureWidget = WidgetFactory.createWidgetTableEntity(
            'em.schema.structure.table.widget',
            'Schema Structure',
            'em.EmSchemaStructure',
            'No Data',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{
            fieldName: 'schemaDtoId',
            fieldValue: this.objectIdentifier.objectId
        }]

        this.emSchemaStructureWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    handleFieldSelect(object: ObjectIdentifierData) {
        this.selectField = object.data;
        this.handleRefreshEmSchemaFieldValueWidget();
    }

    handleRefreshEmSchemaFieldValueWidget() {
        if (this.selectField.hasValueList) {
            this.refreshEmSchemaFieldValueWidget(this.selectField.id);
        } else {
            this.emSchemaFieldValueWidget = null;
        }
    }

    onNavigationItemChange(navigationItem: NavigationItem) {
        this.clearWidgets();
        if (navigationItem.action == 'em.EmSchemaStructure') {
            const filterCriteria = [FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId), FilterCriteria.create('structureType', FilterCriteria.cOperatorEqual, 0)];
            const objectRequestList = ObjectRequestList.createBasic(false, filterCriteria, []);

            this.schemaStructureService.list(objectRequestList).subscribe(res => {
                this.nodes = this.convertToTreeNodes(res.entries); // Convert API data
            })
        }

    }


    clearWidgets() {
        this.emSchemaFieldValueWidget = null;
    }

    ngOnDestroy() {

        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

    convertToTreeNodes(apiData: any[]): TreeNode[] {
        const fieldMap: Map<number, TreeNode> = new Map();
        const rootNodes: TreeNode[] = [];

        // Step 1: Create nodes from schema structures
        apiData.forEach(entry => {
            if (!fieldMap.has(entry.parentFieldDtoId)) {
                fieldMap.set(entry.parentFieldDtoId, {
                    key: entry.parentFieldDtoId,
                    label: entry.parentFieldDtoName,
                    data: {
                        id: entry.parentFieldDtoId,
                        name: entry.parentFieldDtoName,
                        schemaStructureId: entry.id,
                        schemaStructure: entry
                    },
                    children: [],
                    leaf: false,  // Initially not a leaf (as it may have children)
                    expanded: false,
                    icon: "fa-solid fa-folder",
                    type: "PARENT",
                });
            }
        });

        // Step 2: Identify root nodes (structureType === 0)
        apiData.forEach(entry => {
            if (entry.structureType === 0 && entry.parentFieldDtoId) {
                const rootNode = fieldMap.get(entry.parentFieldDtoId);
                if (rootNode) {
                    rootNode.icon = "fas fa-sitemap";
                    rootNodes.push(rootNode);
                }
            } else if (entry.structureType === 0) {
                rootNodes.push({
                    key: entry.childFieldDtoId.toString(),
                    label: entry.childFieldDtoName,
                    data: {
                        id: entry.childFieldDtoId,
                        name: entry.childFieldDtoName,
                        schemaStructureId: entry.id,
                        schemaStructure: entry
                    },
                    children: [],
                    leaf: false,
                    expanded: false,
                    icon: "fas fa-sitemap",
                    type: "PARENT",
                });
            }
        });

        return rootNodes;
    }

    onNodeExpand(event:  TreeNodeExpandEvent) {
        const node: TreeNode = event.node;

        // Prevent unnecessary API calls
        if (node.children && node.children.length > 0) return;

        const filterCriteria = [
            FilterCriteria.create('schema', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create('parentField', FilterCriteria.cOperatorEqual, node.data.id),
            FilterCriteria.create('id', FilterCriteria.cOperatorNotEqual, node.data.schemaStructureId),
        ];

        const objectRequestList = ObjectRequestList.createBasic(false, filterCriteria, []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createSimpleAttributeNode('childField'))

        this.schemaStructureService.list(objectRequestList).subscribe(res => {
            const children = res.entries
                .filter(entry => entry.parentFieldDtoId === node.data.id) // Exclude parent
                .map(entry => {
                    const isComplex = entry.childFieldDto?.fieldTypeInternal === EmSchemaFieldTypeInternal.COMPLEX;
                    const isSimple = entry.childFieldDto?.fieldTypeInternal === EmSchemaFieldTypeInternal.SIMPLE;

                    // Assign Cardinality Labels and Icons
                    let cardinalityLabel = "";
                    let cardinalityIcon = "";

                    switch (entry.cardinality) {
                        case EmSchemaCardinality.ONE_TO_ONE:
                            cardinalityLabel = "1:1";
                            cardinalityIcon = "fa-solid fa-link";
                            break;
                        case EmSchemaCardinality.ONE_TO_MANY:
                            cardinalityLabel = "1:M";
                            cardinalityIcon = "fa-solid fa-project-diagram";
                            break;
                        case EmSchemaCardinality.ZERO_TO_ONE:
                            cardinalityLabel = "0:1";
                            cardinalityIcon = "fa-solid fa-user-clock";
                            break;
                        case EmSchemaCardinality.ZERO_TO_MANY:
                            cardinalityLabel = "0:M";
                            cardinalityIcon = "fa-solid fa-users";
                            break;
                        default:
                            cardinalityLabel = "";
                            cardinalityIcon = "";
                    }

                    return {
                        key: `${entry.childFieldDtoId?.toString()}:${entry.id.toString()}`,
                        label: `${entry.childFieldDtoName || entry.name} ${cardinalityLabel}`,
                        data: {
                            id: entry.childFieldDtoId || entry.id,
                            name: entry.childFieldDtoName || entry.name,
                            schemaStructureId: entry.id,
                            cardinality: entry.cardinality,
                            schemaStructure: entry
                        },
                        children: [],
                        leaf: isSimple,
                        expanded: false,
                        icon: isComplex ? "fa-solid fa-layer-group" : "fas fa-file-alt",
                        type: isComplex ? "COMPLEX" : "SIMPLE",
                    };
                });
            node.children = children;
            node.expanded = true;
        });
    }



}
