import {Route, Routes} from "@angular/router";
import {BasicConfigPage} from "./page/overview/basic-config/basic-config.page";

export function CiSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'ci/config', component: BasicConfigPage},


    ];

    return routes;

}
