import { Component, OnInit } from '@angular/core';
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ObjectTypeId} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'mvs-ui-field-page',
  templateUrl: './ui-field.page.html',
  styleUrls: ['./ui-field.page.scss']
})
export class UiFieldPage extends PageComponent  {
    objectBrowserRequest: any;
    subObjectBrowserRequest: any;


    selectedObjectType: ObjectTypeId;

    formConfigureObjectType: string;
    formConfigureShow: boolean = false;
    formConfigureBusy: boolean;
    formConfigureForm: MvsFormDto;

  objetTypeService: ObjectTypeService;

  defaultLabel: string = "Field Configuration";

  ngOnInit(): void {

    super.ngOnInit();

    this.initCrudTree();

    this.objetTypeService = <ObjectTypeService>this.coreService.getCrudService('cc.ObjectType');

  }

  onChangedObject(changeInformation: ObjectChangeInformation) {
    this.refreshFormConfigure();
  }

  onMainSelectedObjectChange(objectTypeId: ObjectTypeId) {

    this.selectedObjectType = objectTypeId;

    this.refreshFormConfigure();
  }

  refreshFormConfigure() {


    this.formConfigureBusy = true;
    this.formConfigureShow = true;


    this.objetTypeService.get(this.selectedObjectType.objectId).subscribe(objectTypeDto => {

      this.formConfigureObjectType = objectTypeDto["alias"];

      // retrieve corresponding CRUD service depending on the object type
      const crudService = this.coreService.getCrudService(objectTypeDto["alias"]);

      if (crudService) {

        // retrieve the template information
        crudService.template(new DtoTemplate()).subscribe(value => {
          this.formConfigureForm = value;
          this.formConfigureBusy = false;
        });

      } else {
        // this object is without CRUD service, so we'll remove the fields and the access to the configuration
        this.formConfigureShow = false;
        this.formConfigureBusy = false;
        this.formConfigureForm = null;
      }

    });


  }


  initCrudTree() : void {

    this.objectBrowserRequest = {

      'extractDto'    : false,
      "createMode": true,

      'entries' : [

        {
          'id'                : 1,
          'objectAlias'       : 'cc.ObjectType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Objekte und Datenfelder",
          'newEntryNodeCreate': false

        },
        {
          'id'                : 2,
          'objectAlias'       : 'ui.UiFieldType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Datentypen',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Datentyp anlegen",
        },
        {
          'id'                : 3,
          'objectAlias'       : 'ui.UiField',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Datenfelder",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Feld anlegen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'ui.UiFieldGroup',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Feldgruppen',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id'                : 5,
          'objectAlias'       : 'ui.UiMutableArea',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Mutable Area',
          'newEntryNodeCreate': true
        },
        {
          'id'                : 6,
          'objectAlias'       : 'ui.UiDashboard',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Dashboards',
          'newEntryNodeCreate': true
        }
      ]

    };

  }

}
