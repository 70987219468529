    import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    MvsFormControlOverwrite,
    ObjectBaseComponent,
    ObjectChangeInformation
} from "@kvers/alpha-core-common";
    import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
    import {MvsCoreService} from "@kvers/alpha-core-common";
    import {MvsMessageService} from "@kvers/alpha-core-common";
    import {ConfirmationService} from "primeng/api";
    import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'ticket-quick-create',
    templateUrl: './ticket-quick-create.component.html',
})
export class TicketQuickCreateComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    constructor(protected navigationService: MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected messageService: MvsMessageService,
                protected confirmationService: ConfirmationService,
                protected observerService: ObserverService
                ) {
        super(coreService,messageService,confirmationService,observerService);
    }

    showFull: boolean = false;
    createDefaultDto: DtoDetail;
    tempCreateDefaultDto: DtoDetail;
    baseFormControlOverwrite: MvsFormControlOverwrite = MvsFormControlOverwrite.createHideAll('typeDtoId','name','assigneeAgentDtoId','assigneeAgentPoolDtoId','status','urgency','dueDate');
    formControlOverwrite: MvsFormControlOverwrite;

    refreshComponent() {
        this.formControlOverwrite = this.baseFormControlOverwrite;
        this.initialized = true;
    }

    handleFormChange(dto: DtoDetail) {
        this.tempCreateDefaultDto = dto;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    handleChangeView() {
        this.showFull = !this.showFull;
        if (this.showFull == true) {
            this.formControlOverwrite = null;
        } else {
            this.formControlOverwrite = this.baseFormControlOverwrite;
        }
        this.createDefaultDto = this.tempCreateDefaultDto;

    }

    handleObjectChange(event: ObjectChangeInformation) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", event.after.id, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

}
