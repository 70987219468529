import {FieldSearchableRating} from "../enum/field-searchable-rating.enum";

export class SearchRequestDto {

    public requestedObjectTypeIds: number[]  = [];
    public requestedObjectTypeAlias: string[]  = [];

    public splitResultsByType: boolean;
    public maxHits: number;
    public minSearchRating: FieldSearchableRating;
    public searchString: string;

}