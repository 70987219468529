<div class="grid">

    <div class="col-2">
        <!--        <form (ngSubmit)="onSearchViaName()">-->
        <!--            <div class="flex flex-column gap-3">-->

        <!--                <div class="flex flex-column gap-2">-->
        <!--                    <label for="name" class="text-500 text-sm">Name</label>-->
        <!--                    <input id="name" name="name" type="text" pInputText [(ngModel)]="name"/>-->
        <!--                </div>-->

        <!--                <div class="flex justify-content-end">-->
        <!--                    <p-button type="submit" label="Search"></p-button>-->
        <!--                </div>-->

        <!--            </div>-->
        <!--        </form>-->

        <form [formGroup]="searchForm" (ngSubmit)="onSearchViaName()">
            <div class="flex flex-column gap-3">
                <div class="flex flex-column gap-2">
                    <label for="name" class="text-500 text-sm">Name</label>
                    <input id="name" name="name" type="text" pInputText formControlName="name">
                </div>

                <div class="flex justify-content-end">
                    <p-button type="submit" label="Search"></p-button>
                </div>
            </div>
        </form>


    </div>

    <div class="col-10">
        <mvs-widget
                *ngIf="widgetTableViaBasicData"
                [widgetData]="widgetTableViaBasicData"
                (onObjectSelect)="handleObjectSelect($event)"
        ></mvs-widget>

        <mvs-display-message-before-result *ngIf="!widgetTableViaBasicData"></mvs-display-message-before-result>
    </div>

</div>


<!--        <p-tabPanel header="Search by Name">-->

<!--            <div class="grid mt-5">-->

<!--                <div class="col-12">-->
<!--                    <div class="flex gap-5 align-items-center">-->
<!--                        <div class="flex gap-2 align-items-center">-->
<!--                            <label for="name">Name</label>-->
<!--                            <input id="name" type="text" pInputText [(ngModel)]="name"-->
<!--                                   (input)="onInputFieldDetails()"/>-->
<!--                        </div>-->


<!--                        <p-button label="Search" (click)="onSearchViaName()"-->
<!--                                  [disabled]="!name"></p-button>-->
<!--                    </div>-->

<!--                </div>-->

<!--                <div class="col-12 mt-5">-->
<!--                    <mvs-widget-->
<!--                            *ngIf="widgetTableViaBasicData"-->
<!--                            [widgetData]="widgetTableViaBasicData"-->
<!--                            (onObjectSelect)="handleObjectSelect($event)"-->
<!--                    ></mvs-widget>-->
<!--                </div>-->

<!--            </div>-->
<!--        </p-tabPanel>-->
<!--    </p-tabView>-->