import {Route, Routes} from "@angular/router";
import {UiFieldPage} from "./page/overview/ui-field/ui-field.page";
import {UiNavigationConfigPage} from "./page/overview/ui-navigation-config/ui-navigation-config.page";
import {UiObjectNavigationMainPage} from "./page/object/ui-object-navigation-main/ui-object-navigation-main.page";
import {UiObjectNavigationSubPage} from "./page/object/ui-object-navigation-sub/ui-object-navigation-sub.page";
import {UiOverviewPage} from "./page/ui-overview-page/ui-overview.page";
import {
    UiObjectNavigationMainComponent
} from "./component/ui-object-navigation-main/ui-object-navigation-main.component";
import {UiObjectNavigationSubComponent} from "./component/ui-object-navigation-sub/ui-object-navigation-sub.component";
import {UiConfigPage} from "./component/ui-config/ui-config.page";

export function UiSubRoutes(): Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [


        {
            path: ':mainAlias',
            component: UiObjectNavigationMainPage,
            children: [
                {path: '', component: UiObjectNavigationMainComponent},
                {path: 'subNav/:subAlias', component: UiObjectNavigationSubComponent},
            ],
        },

        // {path: 'ui', component: UiOverviewPage},
        {path: 'ui/fields', component: UiFieldPage},
        {path: 'ui/config', component: UiConfigPage},
        {path: 'ui/navigation-config', component: UiNavigationConfigPage},
        // {path: 'ui/nav/:id', component: UiObjectNavigationMainPage},
        // {path: 'ui/nav/sub/:id', component: UiObjectNavigationSubPage},
        {
            path: 'ui/nav/:id',
            component: UiObjectNavigationMainPage,
            children: [
                {path: '', component: UiObjectNavigationMainComponent},
                {path: 'sub/:subId', component: UiObjectNavigationSubComponent},
            ],
        },

    ];

    return routes;

}
