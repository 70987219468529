import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges, Type,
    ViewChild
} from "@angular/core";
import {WfProcessStepActivityMainDirective} from "./wf-process-step-activity-main.directive";
import {WfProcessTypeStepActivityService} from "../../service/api/wf-process-type-step-activity.service";
import {WfProcessStepActivityEvent} from "../../logic/wf-process-step-activity.event";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {
    WfProcessStepActivityBaseComponent
} from "./wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
    WfProcessStepActivitySideBaseComponent
} from "../process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessTypeFieldDto} from "../../dto/wf-process-type-field.dto";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WfActivityTypeEnum} from "../../enum/wf-activity-type.enum";
import {WfProcessRuntimeDto} from "../../service/dto/wf-process-runtime.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-main',
    template: '<ng-template mvs-wf-process-step-activity-main-directive></ng-template>'
})
export class WfProcessStepActivityMainComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnDestroy, OnChanges {

    @ViewChild(WfProcessStepActivityMainDirective, {static: true}) mainActivityComponent!: WfProcessStepActivityMainDirective;

    constructor(
        protected processTypeStepActivityService: WfProcessTypeStepActivityService,
        protected coreService: MvsCoreService,
        protected objectTypeService: ObjectTypeService,
        protected messageService: MvsMessageService) {

        super(coreService, messageService);
    }

    /**
     * Initialization.
     */
    ngOnInit(): void {
        super.ngOnInit();
        this.refreshComponent();
        this.initialized = true;
    }


    /**
     * Clean up.
     */
    ngOnDestroy(): void {

    }

    ngOnChanges(changes: SimpleChanges) {

        if (!this.initialized) {
            return;
        }

        if (changes["activityType"]) {
            this.refreshComponent();
        }

    }

    /**
     * Refresh Component.
     */
    async refreshComponent() {
        if (!this.activityType) {
            return;
        }

        let crudService = null;


        const activityComponent = this.processTypeStepActivityService.getActivityComponent(crudService,this.coreService, this.activityType);


        const viewContainerRef = this.mainActivityComponent.viewContainerRef;
        viewContainerRef.clear();

        let componentRef = null;

        componentRef = viewContainerRef.createComponent<WfProcessStepActivityBaseComponent>(<Type<any>>activityComponent);
        componentRef.instance.processRuntime = this.processRuntime;
        componentRef.instance.processMeta = this.processMeta;
        componentRef.instance.stepType = this.stepType;
        componentRef.instance.activityType = this.activityType;
        componentRef.instance.activity = this.activity;
        componentRef.instance.bindings = WfProcessRuntimeDto.deriveBindings(this.processRuntime, this.activityType.id);
        componentRef.instance.formDefaults = WfProcessRuntimeDto.deriveFormDefaults(this.processRuntime, this.activityType.id);



        // passthrough
        componentRef.instance.onAction.subscribe((event: WfProcessStepActivityEvent) => {
            this.onAction.emit(event);
        });

        componentRef.instance.onSkip.subscribe((event: WfProcessStepActivityEvent) => {
            this.onSkip.emit(event);
        });

        componentRef.instance.onNextStepSelect.subscribe((event: WfProcessStepActivityEvent) => {
            this.onNextStepSelect.emit(event);
        });

        componentRef.instance.onProcessCreated.subscribe((event: WfProcessStepActivityEvent) => {
            this.onProcessCreated.emit(event);
        });

    }


}
