<ng-container *ngIf="initialized && !connectedField">
    <div class="card quickview --workflowS">
        <span>Activity not possible because connected field not defined yet</span>
    </div>
</ng-container>
<ng-container *ngIf="initialized && connectedField">

    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>
    <!--   needs to be commented / waiting for approval from daud to use these buttons only once, for now they are duplicating in every component-->
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <p-button
                    *ngIf="objectId && activityType.processing == 1 && activityType.activityObjectDto.activityEnum == WfActivityTypeObjectActivityEnum.create"
                    icon="pi pi-back"
                    [label]="'Back to create'" (click)="onBackToCreate($event)" [disabled]="busy"></p-button>
        </div>
    </p-toolbar>


    <!--    <mvs-refresh [refreshType]="activityType.refreshType" (onRefresh)="onRefresh()"></mvs-refresh>-->

    <ng-container *ngIf="activityType.activityObjectDto.activityEnum != 4">
        <mvs-widget *ngIf="objectWidget && activityType.activityObjectDto.uiType == WfProcessObjectUiType.WidgetForm"
                    [widgetData]="objectWidget"
                    (onChangedObject)="handleObject($event)"
        >
        </mvs-widget>

        <mvs-object
                *ngIf="objectTypeDto?.alias && activityType.activityObjectDto.uiType == WfProcessObjectUiType.Object"
                [objectType]="objectTypeDto.alias"
                [objectId]="objectId"
                [defaultCreateDto]="createDefaultDto"
                [formControlOverwrite]="formControlOverwrite"
                (onChangedObject)="handleObject($event)"
        ></mvs-object>

    </ng-container>

    <mvs-widget
            *ngIf="stepActivityMultiSelectWidget && (activityType.activityObjectDto.objectShowList || activityType.activityObjectDto.activityEnum == 4)"
            [widgetData]="stepActivityMultiSelectWidget"
            (onObjectSelect)="onWidgetSelectEntry($event)"
    ></mvs-widget>


</ng-container>
