import {APP_INITIALIZER, NgModule} from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppInlineMenuComponent} from './app.inlinemenu.component';
import {AppRightMenuComponent} from './app.rightmenu.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';


import {MenuService} from './app.menu.service';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {AdModule} from "./ad/ad.module";
import {CmModule} from "./cm/cm.module";
import {CiModule} from "./ci/ci.module";
import {UiModule} from "./ui/ui.module";
import {CoreModule} from "./core/core.module";
import {coreInterceptorProviders} from "./core/core.interceptor.providers";
import {ConfirmationService, MessageService} from "primeng/api";

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare";
import {faTextSlash} from "@fortawesome/free-solid-svg-icons/faTextSlash";
import {faArrowDown19} from "@fortawesome/free-solid-svg-icons/faArrowDown19";
import {faPaintRoller} from "@fortawesome/free-solid-svg-icons/faPaintRoller";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faCalendarMinus} from "@fortawesome/free-solid-svg-icons/faCalendarMinus";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import {TmModule} from "./tm/tm.module";
import {CfModule} from "./cf/cf.module";
import {AcModule} from "./ac/ac.module";
import {AmModule} from "./am/am.module";
import {AsModule} from "./as/as.module";
import {CcModule} from "./cc/cc.module";
import {CdModule} from "./cd/cd.module";
import {CoModule} from "./co/co.module";
import {CrModule} from "./cr/cr.module";
import {DmModule} from "./dm/dm.module";
import {EuModule} from "./eu/eu.module";
import {ImModule} from "./im/im.module";
import {NsModule} from "./ns/ns.module";
import {OmModule} from "./om/om.module";
import {OsModule} from "./os/os.module";
import {PmModule} from "./pm/pm.module";
import {RpModule} from "./rp/rp.module";
import {SmModule} from "./sm/sm.module";
import {TestModule} from "./test/test.module";
import {UmModule} from "./um/um.module";
import {WfModule} from "./wf/wf.module";
import {TeModule} from "./te/te.module";
import {XxModule} from "./xx/xx.module";
import {XrModule} from "./xr/xr.module";
import {PcModule} from "./pc/pc.module";

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {WsModule} from "./ws/ws.module";
import {SiModule} from "./si/si.module";
import {IgModule} from "./ig/ig.module";
import {XuModule} from "./xu/xu.module";
import {AuthConfigService, AuthConfiguration, AuthModule, OAuthModule, OAuthService} from "@kvers/alpha-auth";
import {authInitializerFn} from "@kvers/alpha-auth";
import {TenantService} from "@kvers/alpha-auth";
import {AuthTokenRefreshService} from "./core/auth/auth-token-refresh.service";
import {XhModule} from "./xh/xh.module";
import { extendDtoServiceMap, MvsMessageService} from "@kvers/alpha-core-common";
import {projectDtoServiceMap} from "./core/dto/project.dto.service.map";
import {environment} from "../environments/environment";
import {AlphaObjectNavigationService} from "./core/navigation/alpha-object-navigation.service";
import {ADDITIONAL_OUTPUT_FIELDS, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {OAuthStorage} from "angular-oauth2-oidc";
import {additionalInputFields, additionalOutputFields} from "./core/form/field/service/project-form-field.service";
import {ADDITIONAL_INPUT_FIELDS} from "@kvers/alpha-ui";
import {LgModule} from "./lg/lg.module";
import {CpModule} from "./cp/cp.module";
import {HcModule} from "./hc/hc.module";
import {JbModule} from "./jb/jb.module";
import {HgModule} from "./hg/hg.module";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const authConfig: AuthConfiguration = {
    gateway: environment.gateway,
    API_URL: environment.API_URL,
    oauth2_issuer: environment.oauth2_issuer,
    tenant_alias: environment.tenant_alias
};

@NgModule({
    imports: [

        BrowserModule,
        OAuthModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CoreModule,
        AcModule,
        AdModule,
        AmModule,
        AsModule,
        CcModule,
        CdModule,
        CfModule,
        CmModule,
        CiModule,
        CoModule,
        CpModule,
        CrModule,
        DmModule,
        EuModule,
        IgModule,
        ImModule,
        LgModule,
        HcModule,
        HgModule,
        JbModule,
        NsModule,
        OmModule,
        OsModule,
        PcModule,
        PmModule,
        RpModule,
        SmModule,
        SiModule,
        TestModule,
        TmModule,
        TeModule,
        UiModule,
        UmModule,
        WfModule,
        WsModule,
        XhModule,
        XxModule,
        XrModule,
        XuModule,

        FontAwesomeModule,
        AuthModule.forRoot(authConfig)
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppRightMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,


    ],
    providers: [
        MvsMessageService,
        TenantService,
        AuthTokenRefreshService,
        {provide: OAuthStorage, useValue: localStorage},
        {
            provide: APP_INITIALIZER,
            useFactory: authInitializerFn,
            deps: [OAuthService, TenantService, AuthConfigService],
            multi: true
        },

        // use navigation with #
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: MvsObjectNavigationService, useClass: AlphaObjectNavigationService },
        {
            provide: ADDITIONAL_INPUT_FIELDS,
            useValue: additionalInputFields,
        },

        {
            provide: ADDITIONAL_OUTPUT_FIELDS,
            useValue: additionalOutputFields,
        },



        MenuService,
        AppBreadcrumbService,
        MessageService,
        coreInterceptorProviders,
        ConfirmationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(faLibrary: FaIconLibrary) {
        faLibrary.addIcons(
            faSquare,
            faSave,
            faCopy,
            faFile,
            faTrash,
            faPenToSquare,
            faTextSlash,
            faArrowDown19,
            faPaintRoller,
            faEyeSlash,
            faCircle,
            faFilter,
            faCalendar,
            faCalendarCheck,
            faCalendarDay,
            faCalendarPlus,
            faCalendarMinus);

        // The constructor will be called automatically, ensuring the services are registered
        extendDtoServiceMap(Array.from(projectDtoServiceMap.entries()));

    }

}
