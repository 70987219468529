import {Component, OnInit} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsFormValueListDto} from "@kvers/alpha-core-common";
import {DmDocumentViewOptionsEnumEnum} from "../../../../dm/enum/dm-document-view-options.enum";

@Component({
    selector: 'mvs-xx-table-page',
    templateUrl: './xx-table.page.html',
    styleUrls: ['./xx-table.page.scss']
})
export class XxTablePage implements OnInit {
    dates: string[] = ['15.09.2023', '20.10.2023', '05.08.2023', '30.12.2023', '30.08.2023', '29.08.2023', '31.08.2023',''];

    dmDocumentViewOptionsEnumEnum: typeof DmDocumentViewOptionsEnumEnum = DmDocumentViewOptionsEnumEnum;
    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    simpleEntityTableWidgetData: WidgetData;

    formFieldRange: MvsFormFieldDto;
    formFieldIntegerRange: MvsFormFieldDto;
    formFieldEnum: MvsFormFieldDto;

    singleChipValue: string = "Chip";
    doubleChipValue: string = "Double Chip";
    multipleChipValue: string = "Multiple Chip Value";

    date1: Date = new Date();

    date2: Date = new Date();
    date3: Date = new Date(this.date1.getFullYear() - 100, this.date1.getMonth() + 2, this.date1.getDate());

    initialized: boolean;
    username1: string = "fe603336-c4b6-427c-aad8-8b939971f78f";
    showDetails: boolean = false;

    phone1: string = "+923139634862";
    phone2: string = "03139634862";

    email1: string = "harisiqbal848@gmail.com";
    email2: string = "markomilakovic@alpha.klaus.de";
    link1: string = "https://google.com.pk";
    link2: string = "https://www.yahoo.com";
    value1: string = "Click Here!!!";
    value2: string = "Any Value";

    constructor() {
    }

    ngOnInit(): void {

        this.initWidgets();

        this.initTestData();
        this.initialized = true;
    }

    initTestData() {

        // use for range field display as progress
        this.formFieldRange = new MvsFormFieldDto();
        this.formFieldRange.uiDoubleSteps = 1;
        this.formFieldRange.uiDoubleMinValue = 1;
        this.formFieldRange.uiDoubleMaxValue = 100;

        this.formFieldIntegerRange = new MvsFormFieldDto();
        this.formFieldIntegerRange.uiDoubleSteps = 1;
        this.formFieldIntegerRange.uiDoubleMinValue = 50;
        this.formFieldIntegerRange.uiDoubleMaxValue = 150;


        this.formFieldEnum = new MvsFormFieldDto();
        this.formFieldEnum.valueList = new MvsFormValueListDto();

        this.formFieldEnum.valueList.entries = [
            {
                key: 1,
                label: "First Value",
                technicalKey: "1",
                image: "fa-solid fa-house",
                priority: 1
            },
            {
                key: 2,
                label: "Second Value",
                technicalKey: "1",
                image: "fa-solid fa-bars",
                priority: 2
            },
            {
                key: 3,
                label: "Third Value",
                technicalKey: "1",
                image: "fa-brands fa-slack",
                priority: 3
            },
        ];


        this.date2.setDate(this.date1.getDate() - 1);

    }

    initWidgets() {

        this.simpleEntityTableWidgetData = new WidgetData();
        this.simpleEntityTableWidgetData.idAlias = "xx.table.page.simpleEntity";
        this.simpleEntityTableWidgetData.name = "xx.XxSimpleTestEntity";
        this.simpleEntityTableWidgetData.uiComponent = "table";
        this.simpleEntityTableWidgetData.dataProvider = "list";
        this.simpleEntityTableWidgetData.dataSource = "entity";
        this.simpleEntityTableWidgetData.dataProviderObject = "xx.XxSimpleTestEntity";
        this.simpleEntityTableWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            [],
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5)
        );
        this.simpleEntityTableWidgetData.setParamValue("selectionMode", "single");
        this.simpleEntityTableWidgetData.setParamValue("size", "S");


    }

    onTextClick() {
        alert("Parent component prompt");
        //any specific logic to implement
    }

    showObject(event) {
        console.log('objectType and object', event);
    }


}
