<div class="bg-white">
    <mvs-config-header header="Template Engine"
                       icon="fa-solid fa-spell-check">
    </mvs-config-header>

    <div class="mt-3">
        <ul class="flex align-items-center overflow-x-auto gap-4 px-2 pt-3 pb-1 list-none border-bottom-1 border-300">

            <li class="line-height-4" *ngFor="let item of widgetEntries">
                <span
                        (click)="handleTabSelection(item)"
                        class="cursor"
                        [ngClass]="{'border-bottom-3 border-primary pb-2 text-700': activeWidget?.id == item.id}">
                    {{item.label}}
                </span>
            </li>
        </ul>
    </div>

    <ng-container *ngIf="activeWidget">


        <ng-container *ngIf="activeWidget.id == 'te.TeTemplateGroup'">
            <mvs-widget *ngIf="templateGroupWidget"
                        [widgetData]="templateGroupWidget">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'te.TeTemplate'">
            <mvs-widget *ngIf="templateWidget"
                        [widgetData]="templateWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'te.TeArtefactGroup'">
            <mvs-widget *ngIf="artefactGroupWidget"
                        [widgetData]="artefactGroupWidget">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'te.TeArtefact'">
            <mvs-widget *ngIf="artefactWidget"
                        [widgetData]="artefactWidget">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'te.TeTestCase'">
            <mvs-widget *ngIf="testCaseWidget"
                        [widgetData]="testCaseWidget">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'te.TeSystemVariablePredefined'">
            <mvs-widget *ngIf="systemVariableWidget"
                        [widgetData]="systemVariableWidget">
            </mvs-widget>
        </ng-container>



    </ng-container>


</div>