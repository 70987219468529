<!--<ng-container *ngIf="initialized">-->
<!--    &lt;!&ndash;    new design&ndash;&gt;-->
<!--    <div class="p-0">-->
<!--        <div class="col-12 card p-0 m-0" *ngIf="dto">-->
<!--            <div class=""-->
<!--                 style="height:75px; background: linear-gradient(to right, #407192, #40C8C8); border-radius: 6px 6px 0 0;"></div>-->
<!--            <div class="p-5 surface-section">-->
<!--                <div class="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"-->
<!--                     style="top:-65px; margin-bottom:-60px">-->
<!--                    <div class="flex gap-3">-->
<!--                        <div style="width:130px; height: 130px; border-radius: 10px"-->
<!--                             class="surface-card shadow-2 flex align-items-center justify-content-center">-->
<!--                            <img *ngIf="dto?.image" [ngSrc]="dto.image" alt="Image" width="120" height="120"-->
<!--                                 style="object-fit: contain">-->
<!--                        </div>-->
<!--                        <div class="flex flex-column align-self-end mb-1">-->
<!--                            <div class="text-900 text-3xl font-medium mb-1">{{ dto?.name }}</div>-->
<!--                            <div class="text-color-secondary">-->
<!--                                <div *ngIf="dto?.description" [innerHTML]="dto?.description | mvsSavePipe:'html'"></div>-->
<!--                                &lt;!&ndash;                            <p>{{dto?.description}} </p>&ndash;&gt;-->
<!--                                <span class="font-medium">{{ dto.id }} | <span-->
<!--                                        *ngIf="dto?.partnerTypeDtoName">{{ dto?.partnerTypeDtoName }}</span> </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="mt-4 flex flex-wrap gap-3">&ndash;&gt;-->
<!--&lt;!&ndash;                        <button pButton pRipple label="Website" icon="pi pi-link" class=""></button>&ndash;&gt;-->
<!--&lt;!&ndash;                        <button pButton pRipple icon="pi pi-ellipsis-v"&ndash;&gt;-->
<!--&lt;!&ndash;                                class="p-button-outlined p-button-rounded"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div>-->
<!--            <p-tabView class="partner-tabs">-->
<!--                <p-tabPanel>-->
<!--                    <ng-template pTemplate="header">-->
<!--                        <div class="flex pt-1">-->
<!--                            <span>Basic Info </span>-->
<!--                        </div>-->
<!--                    </ng-template>-->
<!--                    <div class="grid m-0">-->

<!--                        <div class="lg:col-4 col-12">-->
<!--                            <div class="flex flex-column gap-3">-->
<!--                                <div class="mvs-widget pt-0">-->
<!--                                    <mvs-widget-->
<!--                                            *ngIf="partnerWidget"-->
<!--                                            [widgetData]="partnerWidget"-->
<!--                                    >-->
<!--                                    </mvs-widget>-->
<!--                                </div>-->

<!--                                <div class="mvs-widget pt-0">-->
<!--                                    <mvs-widget-->
<!--                                            *ngIf="partnerCategoryWidget"-->
<!--                                            [widgetData]="partnerCategoryWidget"-->
<!--                                            [importObjectContext]="partnerImportObjectContext"-->
<!--                                    ></mvs-widget>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="lg:col-4 col-12">-->

<!--                            <div class="mvs-widget pt-0">-->
<!--                                <mvs-widget *ngIf="partnerContactWidget"-->
<!--                                            [importObjectContext]="partnerImportObjectContext"-->
<!--                                            [widgetData]="partnerContactWidget">-->
<!--                                </mvs-widget>-->
<!--                            </div>-->

<!--                        </div>-->

<!--                        <div class="lg:col-4 col-12">-->
<!--                            <div class="mvs-widget pt-0">-->
<!--                                <mvs-widget *ngIf="partnerAddressWidget"-->
<!--                                            [importObjectContext]="partnerImportObjectContext"-->
<!--                                            [widgetData]="partnerAddressWidget"></mvs-widget>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->

<!--                </p-tabPanel>-->
<!--                <p-tabPanel>-->
<!--                    <ng-template pTemplate="header">-->
<!--                        <div class="flex pt-1">-->
<!--                            <span>Partner Group </span>-->
<!--                        </div>-->
<!--                    </ng-template>-->
<!--                    <div class="grid">-->
<!--                        <div class="lg:col-3 col-12">-->
<!--                            <div class="mvs-widget">-->
<!--                                <mvs-crud-one-to-many-multi-select-->
<!--                                        [mainObject]="objectIdentifier"-->
<!--                                        linkedEntityObjectType="cm.PartnerGroup"-->
<!--                                        linkedEntityTargetObjectType="cm.Group"-->
<!--                                        linkedEntityTargetAttributeName="group">-->
<!--                                </mvs-crud-one-to-many-multi-select>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="lg:col-9 col-12">-->
<!--                            <div class="mvs-widget">-->
<!--                                <mvs-crud-one-to-many-multi-table-->
<!--                                        [mainObject]="objectIdentifier"-->
<!--                                        linkedEntityObjectType="cm.PartnerContractType"-->
<!--                                        linkedEntityTargetAttributeName="partner"-->
<!--                                        placeholder="Vertragstypen"-->
<!--                                        header="Partner Contract Type"-->
<!--                                        [widgetUiType]="'list'"-->
<!--                                ></mvs-crud-one-to-many-multi-table>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </p-tabPanel>-->

<!--                <p-tabPanel>-->
<!--                    <ng-template pTemplate="header">-->
<!--                        <div class="flex gap-2 align-items-center pt-1">-->
<!--                            <span>Partner Link </span>-->
<!--                            <p-badge styleClass="tab-value" *ngIf="partnerLinkCount" [value]="partnerLinkCount"></p-badge>-->
<!--                        </div>-->
<!--                    </ng-template>-->

<!--                    <div class="grid">-->
<!--                        <div class="lg:col-3 col-12">-->
<!--                            <div class="mvs-widget pt-0" *ngIf="partnerSelectableWidget">-->
<!--                                <mvs-widget-->
<!--                                        [widgetData]="partnerSelectableWidget"-->
<!--                                        (onObjectSelect)="handlePartnerLinkSelect($event)"-->
<!--                                ></mvs-widget>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                        <div class="lg:col-9 col-12">-->
<!--                            <div class="col-12 grid p-0">-->

<!--                                <div class="col-12">-->
<!--                                    <div class="mvs-widget pt-0" *ngIf="partnerDataWidget">-->
<!--                                        <mvs-widget-->
<!--                                                    [widgetData]="partnerDataWidget"-->
<!--                                                    (onObjectSelect)="handlePartnerLinkSelect($event)">-->
<!--                                        </mvs-widget>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                &lt;!&ndash;                                                               <div class="col-4 pt-0">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                   <mvs-crud-one-to-many-multi-table&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           [mainObject]="objectIdentifier"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           linkedEntityObjectType="cm.PartnerLink"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           linkedEntityTargetAttributeName="partner"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           placeholder="Vertragstypen"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           header="Partner Link"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           [uiStyle]="partnerLinkWidgetStyle"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           [widgetUiType]="'list'"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                           (onObjectSelect)="handlePartnerLinkSelect($event)"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                                   ></mvs-crud-one-to-many-multi-table>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                               </div>&ndash;&gt;-->


<!--                                <div class="col-12">-->
<!--                                    <div class="mvs-widget pt-0" *ngIf="partnerLinkWidget">-->
<!--                                        <mvs-widget-->
<!--                                                    [widgetData]="partnerLinkWidget">-->
<!--                                        </mvs-widget>-->
<!--                                    </div>-->
<!--                                </div>-->


<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </p-tabPanel>-->
<!--            </p-tabView>-->
<!--        </div>-->
<!--    </div>-->

<!--</ng-container>-->

@if (initialized && dto) {
    @if (uiMode == 'side') {
        <cm-partner-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cm-partner-side>

    } @else {
        <cm-partner-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
        ></cm-partner-full>
    }
}