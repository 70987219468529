import {DtoDetail} from "@kvers/alpha-core-common";

export class RpReportObjectDto extends DtoDetail {

    public reportDtoId : number;
    public reportDtoName : string;
    public reportDtoImage : string;
    public reportDtoColor : string;
    public name : string;
    public description : string;
    public startObject : Boolean;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectTypeDtoImage : string;
    public objectTypeDtoColor : string;
    public objectId : number;
    public reportFilterDtoId : number;
    public reportFilterDtoName : string;
    public reportFilterDtoImage : string;
    public reportFilterDtoColor : string;
    public reportSortingDtoId : number;
    public reportSortingDtoName : string;
    public reportSortingDtoImage : string;
    public reportSortingDtoColor : string;
    public edit:boolean = false;
    public objectGenericTypeId: number;
}