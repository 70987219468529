import {DtoDetail} from "@kvers/alpha-core-common";
import {TeEntityStatusEnum} from "../enum/te-entity-status.enum";
import {TeContentTypeEnum} from "../enum/te-content-type.enum";
import {TeTemplateVariableDto} from "./te-template-variable.dto";

export class TeTemplateDto extends DtoDetail {

    public name: string;
    public entityStatus: TeEntityStatusEnum;
    public templateGroupDtoId: number;
    public templateGroupDtoName: string;
    public templateGroupDtoImage: string;
    public templateGroupDtoColor: string;
    public description: string;
    public contentTypeEnum: TeContentTypeEnum;
    public content: string;
    public image: string;
    public rgbaColor: string;

    public templateVariables?: TeTemplateVariableDto[];

}