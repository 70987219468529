import {Route, Routes} from "@angular/router";
import {InputFieldsPage} from "./page/input-fields-overview-page/input-fields.page";
import {OutputFieldsPage} from "./page/output-fields-overview-page/output-fields.page";
import {WidgetsPage} from "./page/widgets-overview-page/widgets.page";


export function XuSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        {path: 'xu/input', component: InputFieldsPage},
        {path: 'xu/output', component: OutputFieldsPage},
        {path: 'xu/widget', component: WidgetsPage},
    ];

    return routes;

}
