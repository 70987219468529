import {Component, Input, OnInit} from "@angular/core";
import {FileSelectEvent} from "primeng/fileupload";
import {HgPartnerService} from "../../../../../service/api/hg-partner.service";
import {EmSchemaRuntimeDataDto} from "../../../../../../em/service/dto/em-schema-runtime-data.dto";
import {HgGdvMessageTypeEnum, HgGdvMessageTypeLabels} from "../../../../../enum/hg-gdv-message-type.enum";
import {SelectItem} from "primeng/api";

@Component({
    selector: 'hg-gdv-test-component',
    templateUrl: 'hg-test.component.html',
})

export class HgTestComponent implements OnInit {

    @Input() hgPartnerId: number

    busy: boolean;

    emSchemaRuntimeDataDto: EmSchemaRuntimeDataDto;
    selectedFile: File;
    messageTypes: SelectItem[] = [];
    selectedMessageType: number;

    constructor(
        protected hgPartnerService: HgPartnerService) {
        this.initializeMessageTypes();
    }

    ngOnInit() {

    }

    /**
     * Populate the dropdown with message types.
     */
    initializeMessageTypes() {
        this.messageTypes = Object.keys(HgGdvMessageTypeEnum)
            .filter((key) => !isNaN(Number(key))) // Only get numeric keys
            .map((key) => ({
                label: HgGdvMessageTypeLabels[Number(key) as HgGdvMessageTypeEnum], // UI label
                value: Number(key)
            }));
    }

    onFileSelect(event: FileSelectEvent) {
        this.selectedFile = event.files[0];
    }

    processDocument(fileUploader: any) {

        if (this.busy || !this.selectedFile || this.selectedMessageType == undefined) {
            return;
        }

        this.busy = true

        this.hgPartnerService.processFile(this.hgPartnerId, this.selectedMessageType, this.selectedFile).subscribe(res => {
            this.emSchemaRuntimeDataDto = res;
            this.busy = false;
            // this.selectedFile = null;
            // fileUploader.clear();
        }, error => {
            this.emSchemaRuntimeDataDto = null;
            this.busy = false;
            // this.selectedFile = null;
            // fileUploader.clear();
        });
    }

}