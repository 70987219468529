<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="cr.customerWelcome"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->


<p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidgets()">
    <p-tabPanel header="Onboarding Type">
        <ng-container *ngIf="activeIndex == 0">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerOnboardingTypeWidget"
                            [widgetData]="customerOnboardingTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>
    </p-tabPanel>
    <p-tabPanel header="Default Contract Types">
        <ng-container *ngIf="activeIndex == 1">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerOnboardingTypeContractTypeWidget"
                            [widgetData]="customerOnboardingTypeContractTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>

        </ng-container>

    </p-tabPanel>
    <p-tabPanel header="Info Steps">
        <ng-container *ngIf="activeIndex == 2">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerOnboardingTypeInfoStepWidget"
                            [widgetData]="customerOnboardingTypeInfoStepWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>

    </p-tabPanel>

</p-tabView>