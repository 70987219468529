import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
  ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UiNavigationMainService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiNavigationMains')
  }

  /**
   * Retrieve complete Main Navigation.
   * @param id
   * @param includeTemplate
   */
  public getFull(id: number, includeTemplate: boolean = false): Observable<UiNavigationMainDto> {

    const complexSelection = this.getComplexSelectionFull();

    const objectRequestList = ObjectRequestList.createComplexRequestList(
        includeTemplate,
        complexSelection,
        [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
        null,
        null,
        null);


    return this.list(objectRequestList).pipe(
        map(
            value => {
              return <UiNavigationMainDto>value.entries[0];
            }
        ));
  }

  /**
   * Get Complex Selection Full.
   */
  public getComplexSelectionFull() : ObjectRequestComplex {

    return ObjectRequestComplex.build(false, false,
        ObjectRequestComplexNode.createRelationNode(
            "subNavigations",
            ObjectRequestRelation.createList(
                "ui.UiNavigationSub",
                "navigationMain",
                [FilterCriteria.create("hidden", FilterCriteria.cOperatorNotEqual, true)],
                [new Sorting("priority")],
                ObjectRequestComplexRelationBindingEnum.ALL))
            .addNodes(
                ObjectRequestComplexNode.createRelationNode(
                    "pages",
                    ObjectRequestRelation.createList(
                        "ui.UiNavigationSubPage",
                        "navigationSub",
                        [FilterCriteria.create("hidden", FilterCriteria.cOperatorNotEqual, true)],
                        [new Sorting("priority")],
                        ObjectRequestComplexRelationBindingEnum.ALL))
                    .addNodes( ObjectRequestComplexNode.createSimpleAttributeNode("page")
                    )
            )
    );


  }

}