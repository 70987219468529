import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {JbJobRunService} from "../../service/api/jb-job-run.service";
import {DtoList, FilterCriteria, ObjectRequestList} from "@kvers/alpha-core-common";
import {JbJobStatus} from "../../enum/jb-job-status.enum";
import {Subscription} from "rxjs";
import {JbJobRunDto} from "../../dto/jb-job-run.dto";

@Component({
    selector: "jb-job-status",
    templateUrl: "jb-job-status.component.html"
})

export class JbJobStatusComponent implements OnInit, OnChanges, OnDestroy {

    private sseSubscription: Subscription | null = null;

    counts: { total: number, errors: number, running: number} = {total: 0, errors: 0, running: 0};
    jobs: DtoList<JbJobRunDto>;

    busy: boolean;
    pendingLoad = false;

    constructor(protected jobRunService: JbJobRunService) {
    }

    ngOnInit() {

        // get initial counts
        this.listJobs();

        // subscribe for changes
        this.sseSubscription = this.jobRunService.getStreamJobUpdates().subscribe(value => {
            // something changed
            console.log(value);
            this.listJobs();
        });

    }

    listJobs() {

        if (this.busy) {
            this.pendingLoad = true;
            return;
        }
        this.busy = true;

        const filterCriteria = new FilterCriteria();

        // filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('jobStatus', FilterCriteria.cOperatorEqual, JbJobStatus.running));
        // filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('jobStatus', FilterCriteria.cOperatorEqual, JbJobStatus.error));
        filterCriteria.addFilterCriteriaToAnd(FilterCriteria.create('startTime', FilterCriteria.cOperatorBetween, '${D-TODAY}', '9999-12-31'));

        const objectRequestList = ObjectRequestList.createBasic(false, [filterCriteria], [])

        this.jobRunService.list(objectRequestList).subscribe(res => {
            this.jobs = res;

            this.counts = {total: 0, errors: 0, running: 0};

            // implement count
            if (this.jobs) {
                for (let job of this.jobs.entries) {
                    this.counts.total += 1;
                    if (job.jobStatus == JbJobStatus.running) {
                        this.counts.running += 1;
                    } else if (job.jobStatus == JbJobStatus.error) {
                        this.counts.errors += 1;
                    }
                }
            }

            this.busy = false ;
            if (this.pendingLoad) {
                this.pendingLoad = false;
                this.listJobs();
            }

        }, error => {
            this.busy = false;
        });

    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngOnDestroy() {

        if (this.sseSubscription != null) {
            this.sseSubscription.unsubscribe();
            this.jobRunService.closeStreamJobUpdates();
        }
    }

}