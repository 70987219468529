<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-3">


            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Ticket Details">
                    <mvs-widget *ngIf="customerWidget" [widgetData]="customerWidget"></mvs-widget>
                </p-accordionTab>
                <p-accordionTab header="Times">
                    Times
                </p-accordionTab>
                <p-accordionTab header="Previous History">
                    Previous History
                </p-accordionTab>
            </p-accordion>

        </div>
        <div class="col-9">

            <div *ngIf="!showCalendar"
                 class="flex justify-content-between">
                <input
                        pInputText
                        class="p-inputtext p-component p-element"
                        placeholder="123 New Street, Humburg"
                >

                <p-dropdown
                        [options]="options"
                        [(ngModel)]="selectedOption"
                        (onChange)="handleOptionChange()"
                        placeholder="Select an Option"/>
            </div>

            <as-agent-map *ngIf="customersCoords && !showCalendar" [customersCoords]="[customersCoords]"></as-agent-map>

            <scheduler-component
                    [schedulerData]="schedulerData"
                    [enableSelection]="true"
                    *ngIf="schedulerData && showCalendar"
                    (onFindSlot)="handleFindSlot($event)"
                    (onShowEvent)="handleEventSelect($event)"
                    (onMoveEvent)="handleEventMove($event)"
                    (onDeleteEvent)="handleDeleteEvent($event)"
                    (dateRangeChanged)="handleDateRangeChanged($event)"
                    [startHour]="6"
                    [endHour]="20"
                    [showDelete]="true"
                    [showOnlineOnsite]="true"
                    [showTicket]="true"
            ></scheduler-component>

        </div>
    </div>

    <!--    TODO need to adjust appointment type based on selection-->

    <p-sidebar [(visible)]="visible" [style]="{width: '800px'}" [modal]="false" position="right">
        <as-free-slot
                *ngIf="agents && visible"
                [agents]="agents"
                [customerId]="customerDto.id"
                [selectedPeriod]="selectedPeriod"
                [appointmentTypeId]="selectedAppointmentTypeId"
                (onAppointmentCreate)="fetchAppointments()"
        ></as-free-slot>
    </p-sidebar>



</ng-container>