import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeDto} from "./wf-process-type.dto";
import {WfProcessStepDto} from "./wf-process-step.dto";
import {WfProcessTypeStepNextDto} from "./wf-process-type-step-next.dto";
import {WfProcessTypeStepActivityDto} from "./wf-process-type-step-activity.dto";
import {WfProcessTypeStepDto} from "./wf-process-type-step.dto";
import {WfProcessTypeFieldDto} from "./wf-process-type-field.dto";
import {WfProcessStatusInternal} from "../enum/wf-process-status-internal.enum";
import {WfActivityTypeEnum} from "../enum/wf-activity-type.enum";

export class WfProcessDto extends DtoDetail {

    public name : string;
    public typeDtoId : number;
    public typeDtoName : string;
    public typeDtoImage : string;
    public typeDtoColor : string;
    public description : string;
    public processStatusInternal:WfProcessStatusInternal;


    public typeDto?: WfProcessTypeDto;
    public steps?: WfProcessStepDto[];

    public lastStep?: WfProcessStepDto;


    /**
     * Retrieve process type field value.
     * @param process
     * @param processTypeField
     */
    public static getFieldValueViaProcessTypeFieldId(process: WfProcessDto, processTypeFieldId: number) : any {
        return process["gen_" + processTypeFieldId + "DtoId"];
    }

    public static getFieldValueViaProcessTypeField(process: WfProcessDto, processTypeField: WfProcessTypeFieldDto) : any {
        return WfProcessDto.getFieldValueViaProcessTypeFieldId(process, processTypeField.id);
    }


    /**
     * Get Step Type from ID.
     * @param process
     * @param stepTypeId
     */
    public static getStepType(process: WfProcessDto, stepTypeId: number) : WfProcessTypeStepDto |null {

        return WfProcessTypeDto.getStepById(stepTypeId, process.typeDto);
    }


    /**
     * Get next step type via an activity.
     * @param process
     * @param activity
     */
    public static getNextStepFromActivity(process: WfProcessDto, activity: WfProcessTypeStepActivityDto, activityType?:WfActivityTypeEnum) : WfProcessTypeStepNextDto | null {

        return WfProcessDto.getNextStepType(process, activity.processTypeStepDtoId, activity.processTypeStepNextDtoId, activityType);
    }

    /**
     * Get next step type.
     * @param process
     * @param stepTypeId
     * @param nextStepId
     */
    public static getNextStepType(process: WfProcessDto, stepTypeId: number, nextStepId: number, activityType?:WfActivityTypeEnum) : WfProcessTypeStepNextDto | null {

        if (!process.typeDto) {
            return null;
        }

        const stepById = WfProcessTypeDto.getStepById(stepTypeId, process.typeDto);

        if (stepTypeId) {
            return WfProcessTypeStepDto.getNextStepById(nextStepId, stepById, activityType);
        }

        return null;
    }



    /**
     * Get latest step.
     * @param process
     */
    public static getLatestStep(process: WfProcessDto) : WfProcessStepDto | null {

        if (!process.steps) {
            return null;
        }

        let latestStep: WfProcessStepDto = null;

        for (let step of process.steps) {
            if (!latestStep || step.lastModifiedDate > latestStep.lastModifiedDate) {
                latestStep = step;
            }
        }

        return latestStep;

    }


}