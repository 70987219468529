import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ReferralTypeDto} from "../../../dto/referral-type.dto";
import {ReferralTypeLevelDto} from "../../../dto/referral-type-level.dto";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";



@Component({
    selector: 'cs-config-page',
    templateUrl: './cs-config.page.html',
})
export class CsConfigPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "CS Config";

    activeIndex: number = 0;


    widgetReferralType: WidgetData;
    widgetReferralTypeLevel: WidgetData;
    widgetReferralTypeLevelOption: WidgetData;

    importContextReferralType: DtoImportObjectContext;
    importContextReferralTypeLevel: DtoImportObjectContext;

    selectedReferralType: ReferralTypeDto;
    selectedReferralTypeLevel: ReferralTypeLevelDto;

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.widgetReferralType = WidgetFactory.createWidgetListBasic(
            "cs.config.referral.type",
            "Typ",
            "list",
            "list",
            "entity",
            "cs.ReferralType"
        );

        this.initialized = true;

    }

    handleReferralTypeSelect(event: ObjectIdentifierData) {
        this.selectedReferralType = event.data;
        this.importContextReferralType = DtoImportObjectContext.createFromObjectIdentifiers(event);
        this.selectedReferralTypeLevel = undefined;

        this.refreshReferralTypeLevelWidget();
    }

    handleReferralTypeLevelSelect(event: ObjectIdentifierData) {
        this.selectedReferralTypeLevel = event.data;
        this.importContextReferralTypeLevel = DtoImportObjectContext.createFromObjectIdentifiers(event);
        this.refreshReferralTypeLevelOptionWidget();
    }

    refreshReferralTypeLevelWidget() {

        this.widgetReferralTypeLevel = WidgetFactory.createWidgetList(
            "cs.config.referral.type.level",
            "Level",
            "list",
            "list",
            "entity",
            "cs.ReferralTypeLevel",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("referralType", FilterCriteria.cOperatorEqual, this.selectedReferralType.id)],
                [new Sorting("id", false)]
            )
        );

    }


    refreshReferralTypeLevelOptionWidget() {

        this.widgetReferralTypeLevelOption = WidgetFactory.createWidgetList(
            "cs.config.referral.type.level.option",
            "Optionen",
            "list",
            "list",
            "entity",
            "cs.ReferralTypeLevelOption",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("referralTypeLevel", FilterCriteria.cOperatorEqual, this.selectedReferralTypeLevel.id)],
                [new Sorting("id", false)]
            )
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
