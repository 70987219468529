import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TicketGroupFilterRestrictionAgentService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketGroupFilterRestrictionAgents')
  }

  public getLinkedAttribute(objectType: string) : string {
    return 'ticketGroupFilter';
  }
}