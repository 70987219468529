import {Component, EventEmitter, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseModeComponent, ObjectRequestList,
     WidgetData,
} from "@kvers/alpha-core-common";
import { MenuItem} from "primeng/api";
import {CalculationLogicTypeDto} from "../../../../dto/calculation-logic-type.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../../../core/helper/widget-function-call-back-create";

@Component({
    selector: 'mvs-lg-calculation-logic-type-object-component-base',
    template: ''
})
export class LgCalculationLogicTypeObjectBaseComponent extends ObjectBaseModeComponent implements OnInit {


    dto: CalculationLogicTypeDto;
    calculationLogicTypeWidgetData: WidgetData;
    calculationLogicTypeFieldWidgetData: WidgetData;

    @Output() onBreadcrumbItems: EventEmitter<MenuItem[]> = new EventEmitter();

    ngOnInit() {

        this.loadNavigationItems();

        super.ngOnInit();

        this.refreshWidgets();

        const menuItem: MenuItem = {label: this.dto.name}

            this.onBreadcrumbItems.emit([menuItem]);

    }

    refreshWidgets() {
        this.refreshCalculationLogicTypeWidgetData();
        this.refreshCalculationLogicTypeFieldWidgetData();
    }

    refreshCalculationLogicTypeWidgetData() {
        this.calculationLogicTypeWidgetData = this.getCalculationLogicTypeWidget(this.objectIdentifier.objectId);
    }

    refreshCalculationLogicTypeFieldWidgetData() {
        this.calculationLogicTypeFieldWidgetData = this.getCalculationLogicTypeFieldWidget(this.objectIdentifier.objectId);
    }

    getCalculationLogicTypeWidget(id: number): WidgetData {
        return WidgetFactory.createWidgetEntityData(
            'lg.calculation.logic.type.widget',
            'Calculation Logic Type',
            'lg.CalculationLogicType',
            id
        );
    }

    getCalculationLogicTypeFieldWidget(id: number): WidgetData {
        const filterCriteria = FilterCriteria.create('type', FilterCriteria.cOperatorEqual, id)

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        const widgetData: WidgetData = WidgetFactory.createWidgetTableEntity(
            'lg.calculation.logic.type.field.widget',
            'Calculation Logic Type Field',
            'lg.CalculationLogicTypeField',
            'No Result',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'typeDtoId', fieldValue: id}]

        widgetData.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
        return widgetData;

    }

    /**
     * load and initialize navigations
     */
    loadNavigationItems() {
        this.navigationItems = [
            {
                label: 'Calculation-Logic-Fields',
                action: 'Calculation-Logic-Fields',
                tooltip: 'Logic Calculation',
                icon: 'fa-regular fa-typewriter',
                toggleable: false,
                default: true
            },
            {
                label: 'TestCases',
                action: 'TestCases',
                tooltip: 'Test Cases',
                icon: 'fa-regular fa-flask-vial',
                toggleable: false,
                default: false
            },
            {
                label: 'Info',
                action: 'Info',
                tooltip: 'Info',
                icon: 'fa-regular fa-circle-info',
                toggleable: false,
                default: false
            },
        ];

        this.activeNavigationItem = this.navigationItems[0];
        this.handleNavigationItemsChange();
    }


    handleNavigationItemsChange(): void {
        this.onNavigationItems.emit(this.navigationItems);
    }


}
