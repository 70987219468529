import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {XxConfigPage} from "./page/overview/config/xx-config.page";
import {XxTablePage} from "./page/overview/tables/xx-table.page";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {XxWidgetPage} from "./page/overview/widgets/xx-widget.page";
import {CmModule} from "../cm/cm.module";
import {XxTestComponentComponent} from "./page/testComponent/test-component.component";
import {XxObjectDocumentComponent} from "./page/object-documents/object-documents.component";
import {
  XxDialogBoxObjectDocumentComponent
} from "./page/dialog-box-object-documents/dialog-box-object-documents.component";
import {DmModule} from "../dm/dm.module";
import {XxUserComponent} from "./observer/component/xx-user.component";

@NgModule({
  declarations: [
    XxConfigPage,
    XxTablePage,
    XxWidgetPage,
    XxTestComponentComponent,
    XxObjectDocumentComponent,
    XxDialogBoxObjectDocumentComponent,
    XxUserComponent,
  ],
  exports:[
    XxConfigPage,
    XxTablePage,
    XxWidgetPage,
    XxTestComponentComponent,
    XxObjectDocumentComponent,
    XxDialogBoxObjectDocumentComponent,
    XxUserComponent,
  ],
  imports: [
    CoreModule,
    ProgressSpinnerModule,
    CmModule,
    DmModule
  ]
})
export class XxModule { }

 
 
