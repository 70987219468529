import {Component} from '@angular/core';
import {
    MvsCoreService, MvsMessageService,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, Sorting,
    TabEntries, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {MessageService} from "primeng/api";
import {BiproServiceService} from "../../../service/api/bipro-service.service";


@Component({
    selector: 'mvs-hb-config',
    templateUrl: './hb-config.page.html',
})
export class HbConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Bipro Configuration";

    activeNavItem: string = "hb";

//     ['hb.BiproType', {route: 'about:blank',service: BiproServiceTypeService}],
//     ['hb.BiproVersion', {route: 'about:blank',service: BiproVersionService}],
// ['hb.BiproService', {route: 'about:blank',service: BiproServiceService}],



//     ['hb.BiproPartnerAuthentication', {route: 'about:blank',service: BiproPartnerAuthenticationService}],
//     ['hb.BiproPartner', {route: 'about:blank',service: BiproPartnerService}],

    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    navigationItems: NavigationItem[] = [
        {
            label: 'basic',
            action: 'basic',
            tooltip: 'Basic Configuration',
            icon: 'fa-solid fa-link', // Represents connections or adapters
            toggleable: false,
            default: false
        },
        {
            label: 'service',
            action: 'service',
            tooltip: 'Partner Service',
            icon: 'fa-solid fa-lightbulb', // Represents connections or adapters
            toggleable: false,
            default: false
        },
        {
            label: 'partner',
            action: 'partner',
            tooltip: 'Bipro Partner Configuration',
            icon: 'fa-solid fa-server', // Represents systems or infrastructure
            toggleable: false,
            default: false
        }
    ];

    managedEntities: { module: string, alias: string, label: string, widgetData?: WidgetData }[] = [
        { module: 'basic', alias: 'hb.BiproType', label: 'BIPRO Typen' },
        { module: 'basic', alias: 'hb.BiproVersion', label: 'BIPRO Versionen' },
        { module: 'basic', alias: 'hb.BiproFieldType', label: 'BIPRO Field Types' },
        { module: 'service', alias: 'hb.BiproService', label: 'Partner Services' },
        { module: 'partner', alias: 'hb.BiproPartner', label: 'BIPRO Partner' },
        { module: 'partner', alias: 'hb.BiproPartnerAuthentication', label: 'BIPRO Authentication' },
    ];

    batchBusy: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected biproService: BiproServiceService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        for (let managedEntity of this.managedEntities) {

            managedEntity.widgetData = WidgetFactory.createWidgetTableEntity(
                "config." + managedEntity.alias + ".simple",
                managedEntity.label,
                managedEntity.alias,
                "Keine Daten vorhanden",
                ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
            );

        }


    }

    createBiproService() {

        if (this.busy) {
            return;
        }
        this.busy = true;

        let observable = this.biproService.createBiproService({
            biproServiceTypeId: 2,
            biproPartnerAuthenticationId: 2,
            biproPartnerId: 1,
            serviceDefinitionUrl: "https://bipro-ws-t.stuttgarter.de/pks_test/3.2/TransferService_2.6.1.1.2"
        }).subscribe(value => {
            this.busy = false;
        }, error => {
            this.busy = false;
        });

    }

    // Method to get filtered entities
    getFilteredManagedEntities() {
        return this.managedEntities.filter(entity => entity.module === this.activeNavItem);
    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
        });
    }

    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], [])
    }


    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


}


