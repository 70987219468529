import {Component} from '@angular/core';
import {
    FilterCriteria,
    MvsCoreService,
    ObjectIdentifier,
    ObjectRequestList,
    ObserverService
} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {Observable} from "rxjs";
import {UiNavigationSubDto} from "../../dto/ui-navigation-sub.dto";
import {UiNavigationSubService} from "../../service/api/ui-navigation-sub.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'ui-object-navigation-sub',
    templateUrl: './ui-object-navigation-sub.component.html',
    styleUrls: ['./ui-object-navigation-sub.component.scss']
})
export class UiObjectNavigationSubComponent extends ObjectBaseComponent {

    dto: UiNavigationSubDto;
    crudService: UiNavigationSubService;
    routerLink: string;

    viaAlias: boolean;

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected route: ActivatedRoute,
        protected observerService: ObserverService,
        protected uiNavigationSubService: UiNavigationSubService,
        private router: Router
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const id = params['subId'];
            const subAlias = params['subAlias'];

            if (id) {
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationSub', id);
                this.viaAlias = false;
                super.ngOnInit();
            } else if (subAlias) {
                this.viaAlias = true;
                this.getSubObjectViaAlias(subAlias);
            }
        });

        this.route.parent.params.subscribe(params => {

            const mainAlias = params['mainAlias'];
            if (mainAlias) {
                this.routerLink = `/${mainAlias}`;
            }
        });
    }

    /**
     * Return Observerable for FULL retrieval.
     * @protected
     */
    protected getRetrieveObjectObservable(): Observable<any> {
        return this.crudService.getFull(this.objectIdentifier.objectId);
    }


    onObjectChanged() {
        super.onObjectChanged();
        if (!this.viaAlias) {
            this.routerLink = `/ui/nav/${this.dto.navigationMainDtoId}`;
        }
    }

    getSubObjectViaAlias(alias: string) {
        const filterCriteria = FilterCriteria.create('alias', FilterCriteria.cOperatorEqual, alias);
        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);

        this.uiNavigationSubService.list(objectRequest).subscribe(res => {
            if (res.entries.length > 0) {
                const entry = res.entries[0];
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationSub', entry.id);
                super.ngOnInit();
            } else {
                this.router.navigate(['/']);
            }
        });
    }

}
