import {DtoDetail} from "@kvers/alpha-core-common";
import { ContractDto } from "./contract.dto";
import { ContractFormerContractEnum } from "../enum/contract-former-contract-enum.enum";

export class ContractFormerDto extends DtoDetail{


    public contractDto? : ContractDto;
    public contractDtoId : number;
    public contractDtoName : string;
    public contractFormerDto? : ContractDto;
    public contractFormerDtoId : number;
    public contractFormerDtoName : string;
    public assignment: ContractFormerContractEnum;

}