import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ObjectTypeService} from "../../../../cc/service/api/object-type.service";
import {CustomerContractService} from "../../../../cr/service/api/customer-contract.service";
import {
    DtoDetail,
    DtoImportObjectContext,
    DtoImportObjectContextEntry,
    DtoListAttributeRequestAggregateEnum,
    DtoTemplate,
    FilterCriteria,
    MetaService,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    ObjectRequestRelation,
    PageComponent, PagingDto,
    Sorting,
    UiPageTypeEnum,
    UiPageUserService,
    WidgetData,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {
    MvsFormFieldService,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory,
    WidgetService
} from "@kvers/alpha-ui";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {HouseholdService} from "../../../../pm/service/api/household.service";
import {HouseholdPersonService} from "../../../../pm/service/api/household-person.service";
import {HouseholdDto} from "../../../../pm/dto/household.dto";
import {HouseholdPersonDto} from "../../../../pm/dto/household-person.dto";
import {PersonService} from "../../../../pm/service/api/person.service";
import {PersonRelationPersonDto} from "../../../../pm/dto/person-relation-person.dto";
import {PersonRelationPersonService} from "../../../../pm/service/api/person-relation-person.service";
import {TicketTypeService} from "../../../../tm/service/api/ticket-type.service";
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {TestHarisService} from "./service/test-haris.service";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {CustomerEmergencyContactService} from "../../../../cr/service/api/customer-emergency-contact.service";
import {xxUser} from "../../../../xx/observer/interface/xx-user.interface";
import {TicketTypeObjectRoleService} from "../../../../tm/service/api/ticket-type-object-role.service";
import {TicketObjectService} from "../../../../tm/service/api/ticket-object.service";
import {TicketActionService} from "../../../../tm/service/api/ticket-action.service";
import {TicketTypeActionService} from "../../../../tm/service/api/ticket-type-action.service";
import {TicketActionDto} from "../../../../tm/dto/ticket-action.dto";
import {
    NsNotificationChannelRecipientService
} from "../../../../ns/service/api/ns-notification-channel-recipient.service";
import {TicketActionCommentService} from "../../../../tm/service/api/ticket-action-comment.service";
import {UmUserService} from "../../../../um/service/api/um-user.service";
import {UserProfileHelper} from "../../../../um/components/user-profile/helper/user-profile-helper";
import {ContractPersonService} from "../../../../ci/service/api/contract-person.service";
import {CustomerTypeService} from "../../../../cr/service/api/customer-type.service";
import {ActivityContextHolderService} from "../../../../core/activity-context/activity-context-holder.service";
import {ObserverService} from "../../../../xx/observer/service/observer.service";
import {ObjectUserAccessService} from "../../../../cc/service/api/object-user-access.service";
import {ImDataImportService} from "../../../../im/service/api/im-data-import.service";
import {ContactService} from "../../../../cd/service/api/contact.service";
import {Messages} from "primeng/messages";


@Component({
    selector: 'mvs-test-form-haris',
    templateUrl: './test-haris.page.html',
    styleUrls: ['./test-haris.page.scss']
})
export class TestHarisPage extends PageComponent implements OnInit, AfterViewInit {

    customerContractWidgetData: WidgetData;
    customerCallsCategoryWidget: WidgetData;
    groupByField: string = 'customerType';
    // groupByField: string = 'alias';
    objectRequestList: ObjectRequestListGroupBy;
    ticketIdentifier = new ObjectIdentifier('tm.Ticket', 35);
    ticketObjectId: number;
    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorObjectRequest: ObjectRequestList;
    objectIdentifier = new ObjectIdentifier('tm.Ticket', 81);

    householdPersonWidget: WidgetData;
    widgetUserRole: WidgetData;
    widgetUserRole1: WidgetData;
    widgetUser: WidgetData;
    householdWidget: WidgetData;
    personRelationPersonWidget: WidgetData;
    personRelationPersonObjectContext = new DtoImportObjectContext(
        [new DtoImportObjectContextEntry('cr.Customer', 5637)]);
    formGroup: FormGroup;
    fieldDisabled: boolean = false;
    imgSrc: string;
    showHistory: boolean = false;

    @ViewChildren('tooltipRefs') tooltipRefs!: QueryList<ElementRef>;
    @ViewChild('messages') messages: Messages;

    buttons = [
        {
            label: 'Active Ticket',
            icon: 'fa-regular fa-ticket',
            tooltip: 'Click here to activate the ticket.',
        },
        {
            label: 'Pinned Ticket',
            icon: 'fa-regular fa-thumbtack',
            tooltip: 'Click here to pin the ticket.',
        },
    ];

    mockUser1: xxUser = {id: 1, firstName: 'John', lastName: 'Doe'};
    mockUser2: xxUser = {id: 2, firstName: 'Jane', lastName: 'Smith'};


    ticketData = {id: 1, name: 'Flight Reservation'};
    customerData = {id: 2, name: 'John Doe'};


    // householdImportObjectContext =
    //     new DtoImportObjectContext([
    //         new DtoImportObjectContextEntry('cr.Customer',5637)]);

    constructor(
        protected route: ActivatedRoute,
        protected formFieldService: MvsFormFieldService,
        protected widgetService: WidgetService,
        protected processService: WfProcessService,
        protected objectService: ObjectTypeService,
        protected customerContractService: CustomerContractService,
        protected workflowService: WfProcessService,
        protected navigationService: MvsObjectNavigationService,
        protected householdService: HouseholdService,
        protected householdPersonService: HouseholdPersonService,
        protected personRelationPersonService: PersonRelationPersonService,
        protected ticketService: TicketService,
        protected ticketActionCommentService: TicketActionCommentService,
        protected ticketTypeObjectRoleService: TicketTypeObjectRoleService,
        protected ticketObjectService: TicketObjectService,
        protected ticketTypeService: TicketTypeService,
        protected ticketActionService: TicketActionService,
        protected ticketTypeActionService: TicketTypeActionService,
        protected http: HttpClient,
        protected testHarisService: TestHarisService,
        protected customerEmergencyContactService: CustomerEmergencyContactService,
        protected metaService: MetaService,
        protected contactService: ContactService,
        protected customerService: CustomerService,
        protected customerTypeService: CustomerTypeService,
        protected notificationChannelRecipientService: NsNotificationChannelRecipientService,
        protected contractPersonService: ContractPersonService,
        protected personService : PersonService,
        protected activityContextHolderService: ActivityContextHolderService,
        protected observerService: ObserverService,
        protected objectUserAccessService: ObjectUserAccessService,
        protected userService: UmUserService,
        protected dataImportService: ImDataImportService,
        private uiPageUserService: UiPageUserService,
        protected coreService: MvsCoreService,) {
        super(route, coreService);
    }

    closeMessage() {
        this.messages.value = [];
        this.messages.cd.detectChanges();
    }

    createAction() {
        const dto = new TicketActionDto();
        dto.ticketDtoId = 81;
        dto.ticketTypeActionDtoId = 8;
        dto.ticketObjectDtoId = null;
        this.ticketActionService.create(dto).subscribe(res => {
            debugger
        });
    }

    showTooltipForIndex(index: number): void {
        const tooltipElement = this.tooltipRefs.toArray()[index]?.nativeElement;
        if (tooltipElement) {
            const mouseEnterEvent = new Event('mouseenter');
            tooltipElement.dispatchEvent(mouseEnterEvent);

            setTimeout(() => {
                const mouseLeaveEvent = new Event('mouseleave');
                tooltipElement.dispatchEvent(mouseLeaveEvent);
            }, 3000); // Tooltip visible for 3 seconds
        }
    }

    addTicketIdentifier() {
        const identifier = new ObjectIdentifier('tm.Ticket',10);
        this.observerService.addObject(identifier);
    }

    getMainObject() {
        // console.log(this.agentActiveObjectService.getActiveTicket());
    }

    performChange(type: string) {
        let identifier: ObjectIdentifier
        if (type == 'customer') {
            identifier = new ObjectIdentifier('cr.Customer',1)
        } else {
            identifier = new ObjectIdentifier('tm.Ticket',10)
        }


        this.observerService.informActivity(identifier);
    }

    ngOnInit() {
        super.ngOnInit();

        // this.messages.value = [{ severity: 'success', detail: 'You are in active ticket' }];

        const objectRequest = new ObjectRequestList(false,
            [],
            []
        );
        objectRequest.paging = PagingDto.create(1,10);
        // // objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        // //     ObjectRequestComplexNode.createRelationNode("objectType",
        // //         ObjectRequestRelation.createList(
        // //             "cc.ObjectType",
        // //             "objectType",
        // //             [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 333)],
        // //             [],
        // //             ObjectRequestComplexRelationBindingEnum.ALL)
        // //     )
        // // )
        //
        // this.uiPageUserService.pageVisit(this.route.routeConfig.path, 'Ticket', UiPageTypeEnum.object, 'tm.Ticket', null,108);
        // this.contactService.list(objectRequest).subscribe(value => {
        //     console.log(value);
        // });
        //
        // this.objectUserAccessService.list(objectRequest).subscribe(res => {
        //     console.log(res);
        // })
        //
        // // 'tm.Ticket:1;tm.Ticket:2'
        // const ids = 'tm.Ticket:12';
        // // 'tm.Ticket', 12
        // this.objectUserAccessService.listObjects(ids).subscribe(res => {
        //     console.log(res);
        // },error => {
        //     console.log(error);
        // })



        // const identifier = new ObjectIdentifier('cr.Customer',1);
        // this.observerService.addObject(identifier);
        //
        // this.observerService.refreshData.subscribe(res => {
        //     console.log(res);
        // })

        // this.customerService.list(objectRequest).subscribe(res => {
        //     debugger
        // }, error => {
        //     debugger
        // })



        // this.customerService.template(new DtoTemplate()).subscribe(res => {
        //
        // })

        // const dto = new ContractPersonDto();

        // this.personRelationPersonService.delete(38).subscribe(res => {
        //     debugger
        // })

        // for (let i = 22796; i < 22808; i++) {
        //     this.contractPersonService.delete(i);
        // }
        // dto.id = 11871;

        // dto['contractDtoId'] = 51334
        // dto['personDtoId'] = 3178;
        // dto.contractPersonTypeDtoId = 1;
        // dto.owner = false;
        // dto.insured = false;
        //
        // this.contractPersonService.create(dto).subscribe(res => {
        //     console.log(res);
        // },error => {
        //     console.log(error)
        // })

        // this.ticketActionCommentService.delete(52).subscribe(res => {
        //     console.log('deleted');
        // })

        // this.metaService.getByAlias('cr.Customer',false,true).subscribe(res => {
        //     console.log(res);
        // })

        // const dto = new DtoDetail();
        // dto.id = 4258;
        //
        // this.notificationChannelRecipientService.update(dto).subscribe(res => {
        //     debugger
        // },error =>  {
        //     debugger
        // })

        // const dto = new TicketActionDto();
        // dto.ticketDtoId = 35;
        // dto.ticketTypeActionDtoId = 4;
        //
        // // this.ticketActionService.list(new ObjectRequestList(false, [], [])).subscribe(res => {
        // //     console.log('ticketActionService', res);
        // // })
        //
        // this.ticketActionService.create(dto).subscribe(res => {
        //     console.log('ticketActionService', res);
        // })

        // this.ticketTypeActionService.list(new ObjectRequestList(false, [], [])).subscribe(res => {
        //     console.log('ticketTypeActionService', res);
        // })

        // const dto = new TicketObjectDto();
        // dto.id = 17;
        // dto.ticketTypeObjectRoleDtoId = 2;
        // this.ticketObjectService.update(dto).subscribe(res => {
        //     debugger
        // })

        // this.testBatchService();
        this.refreshWidget();

        // this.customerEmergencyContactService.list(new ObjectRequestList(true, [],[])).subscribe(res => {
        //
        // });


        //  this.refreshComponent();
        //
        // this.ticketIndicatorFilterCriteria = [
        //     FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, 5637),
        //     FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved),
        //     FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled)
        //
        // ];
        //
        // this.formGroup = new FormGroup({
        //     controlName: new FormControl('date')
        // });

        // this.ticketService.listObject(5637,'cr.Customer').subscribe(res => {
        // },error => {
        // });

        // this.ticketTypeService.list(new ObjectRequestList(false,[],[])).subscribe(res => {
        //     console.log(res);
        // })

        // this.ticketService.createTicketForObject(3,5637,'cr.Customer').subscribe(res => {
        //     debugger
        // },error => {
        //     debugger
        // });

        // this.workflowIndicatorObjectRequest = this.processService.getRequestForProcessForObjectViaAlias(
        //     this.objectIdentifier.objectType,
        //     this.objectIdentifier.objectId,
        //     true
        // );


        // const request = new ObjectRequestList(
        //     false,
        //     [FilterCriteria.create('person', 'EQ', 1)],
        //     []
        // );
        // request.objectRequestComplex = ObjectRequestComplex.build(
        //     true,
        //     true,
        //     ObjectRequestComplexNode.createSimpleAttributeNode("household").addNodes(
        //         ObjectRequestComplexNode.createRelationNode('householdPerson',
        //             ObjectRequestRelation.createJoin('pm.HouseholdPerson#household')
        //         )
        //     )
        // );
        // this.householdPersonService.list(request).subscribe(res => {
        //     console.log(res.entries);
        // });

        // const dto = new HouseholdDto();
        // dto.id = 1;
        // dto.name = `Father's household`;
        //
        // this.householdService.update(dto).subscribe(res => {
        //
        // })

        // this.getHouseholdService();
        // this.createHousehold();
        // this.createHouseholdPerson();
        // this.createPersonRelationPerson();

        // const crudService = this.coreService.getCrudService('pm.PersonRelationPerson');
        // if (!crudService) {
        //     return;
        // }
        //
        // crudService.list(new ObjectRequestList(false, [],[])).subscribe(res => {
        //     debugger
        // })


        // this.binarySearch([1, 2, 7, 9, 11, 17, 22, 24, 25], 22);

        this.initialized = true;
    }

    getPicture() {
        this.userService.getPictureBinaryById(100000).subscribe(res => {
            UserProfileHelper.getImageSrcFromBlob(res).then(value => {
                this.imgSrc = value;
            });
        }, error => {
        });
    }

    delPicture() {
        this.userService.delete(100000).subscribe(res => {
        }, error => {
        });
    }

    testBatchService() {
        const ids = [1, 2, 3, 1, 1, 2, 3, 2, 2, 2];

        for (let id of ids) {
            this.customerService.get(id).subscribe(res => {

            });
        }
    }

    toggleField() {
        this.fieldDisabled = !this.fieldDisabled;
    }

    widgetProcessToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['ticketDtoId'] = 35;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    // showCustomerHouseholdPerson() {
    //     this.customerHouseholdPerson = WidgetFactory.createWidgetList(
    //         'cr.customer.contract.navigation.customer.household.person.1',
    //         'Household Persons',
    //         'listDataExpandable',
    //         'list',
    //         'entity',
    //         'pm.HouseholdPerson',
    //         'No data found',
    //         new ObjectRequestList(true,
    //             [FilterCriteria.create('person', FilterCriteria.cOperatorEqual,this.dto.personDtoId)],
    //             [])
    //     );
    //     // this.customerHouseholdPerson.dataProviderListRequest.objectRequestComplex = this.getComplexRequest();
    // }

    getComplexRequest(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode("household")
                .addNodes(
                    ObjectRequestComplexNode.createRelationNode('householdPerson', ObjectRequestRelation.createJoin('pm.HouseholdPerson#household'))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person")
                            // .addNodes(
                            //     ObjectRequestComplexNode.createRelationNode('customerDto', ObjectRequestRelation.createJoin('+crCustomer')),
                            //     ObjectRequestComplexNode.createRelationNode('relatedPersons', ObjectRequestRelation.createJoin('pm.PersonRelationPerson#personB')),
                            //
                            // )
                        )
                )
        );
    }

    testObjectIdentifier = [new ObjectIdentifier('tm.Ticket', 81)];
    setActivityContext() {
        this.activityContextHolderService.setContext(this.testObjectIdentifier);
    }

    removeActivityContext() {
        // this.activityContextHolderService.removeObject(this.testObjectIdentifier);
        this.activityContextHolderService.clearContext();
    }

    requestAPI() {
        this.customerService.template(new DtoTemplate()).subscribe(res => {

        });
    }

    customerContractToolbarCreate() {
        return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['ticketDtoId'] = 81;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }};
    }

    refreshWidget() {
        // this.widgetUserRole = WidgetFactory.createWidgetList(
        //     "test.haris.tm.config.user.role.test.1235551",
        //     "Ticket Objects",
        //     "list",
        //     "list",
        //     "entity",
        //     "pm.HouseholdPerson",
        //     "Nothing found",
        //     ObjectRequestList.createBasic(true,
        //         [],
        //         []
        //     )
        // );

        const dto = new DtoDetail();
        dto['ticketDtoId'] = 81;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

        this.widgetUserRole = WidgetFactory.createWidgetList(
            'test.haris.cr.customer.contract.navigation.customer.household.person.1.2.person.23',
            'Customer Action',
            'list',
            'list',
            'entity',
            'cd.Contact',
            'No data found',
            new ObjectRequestList(true,
                [FilterCriteria.create('id', FilterCriteria.cOperatorBetween, 29550,29560)],
                []),
        );

        this.widgetUserRole1 = WidgetFactory.createWidgetList(
            'cr.customer.contract.navigation.customer.household.person.1.2.person.23',
            'Customer Action 2',
            'table',
            'list',
            'entity',
            'cr.Customer',
            'No data found',
            new ObjectRequestList(true,
                [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 5637)],
                []),
        );

        // this.widgetUserRole.functionCallbacks = this.customerContractToolbarCreate();

        // this.widgetUserRole.dataProviderListRequest.objectRequestComplex = this.getComplexRequest();

        this.customerContractWidgetData = new WidgetData();
        this.customerContractWidgetData.idAlias = "test.haris.cr.customer.basic.contract.1"; //"test.haris.cr.customer.basic.contract.1"
        this.customerContractWidgetData.name = "Verträge";
        this.customerContractWidgetData.uiComponent = "table";
        this.customerContractWidgetData.dataProvider = "list";
        this.customerContractWidgetData.dataSource = "entity";
        this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";


        this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            [FilterCriteria.create('customer', 'EQ', 5637)],
            [new Sorting('lastModifiedDate', false)],
            null
        );

        this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex =
            ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createSimpleAttributeNode('contract'));


        // this.widgetUser = WidgetFactory.createWidgetList(
        //     "test.haris.um.config.user",
        //     "User",
        //     "table",
        //     "list",
        //     "entity",
        //     "um.User",
        //     "Keine Daten vorhanden",
        //     ObjectRequestList.createBasic(true, [FilterCriteria.create('id', 'EQ',83)], [new Sorting("username", true)])
        // );

        // this.widgetUserRole.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(false, false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('ticket'))

        // this.widgetUserRole.functionCallbacks = this.widgetProcessToolbarCreate();

        // this.widgetUserRole = WidgetFactory.createWidgetList(
        //     'test.haris.cr.customer.standard.navigation.customer.emergency.contacts.basic.1',
        //     'Emergency Contacts',
        //     'list',
        //     'list',
        //     'entity',
        //     'cr.CustomerEmergencyContact',
        //     'No data found',
        //     new ObjectRequestList(true, [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],[])
        // );

    }

    handleClickImage(object: any) {
        console.log(object);
    }

    getHouseholdService() {
        const request = new ObjectRequestList(false, [], []);

        this.householdService.list(request).subscribe(res => {
            debugger
        })

        this.householdPersonService.list(request).subscribe(res => {
            debugger
        })
    }

    createHousehold() {
        const dto = new HouseholdDto();
        dto.name = 'Test household';

        this.householdService.create(dto).subscribe(res => {
            debugger
        })

    }

    createHouseholdPerson() {
        const dto = new HouseholdPersonDto();
        dto.householdDtoId = 1;
        dto.personDtoId = 16237;
        dto.endDate = new Date();

        this.householdPersonService.create(dto).subscribe(res => {
            debugger
        });
    }

    createPersonRelationPerson() {
        const dto = new PersonRelationPersonDto();
        dto.personADtoId = 5637;
        dto.relationTypeADtoId = 1;
        dto.personBDtoId = 16229;
        dto.relationTypeBDtoId = 2;
        dto.endDate = new Date();

        this.personRelationPersonService.create(dto).subscribe(res => {
            debugger
        });
    }

    //[1,2,3,4,5,6,7,8]
    //S      M      E
    //

    binarySearch(array: number[], element: number) {

        let start: number = 0;
        let end: number = array.length - 1;
        const middle = Math.floor((start + end) / 2);

        if (array[middle] == element) {
            console.log(middle);
            return middle;
        } else {
            if (array[middle] < element) {
                start = middle + 1;
            } else {
                end = middle - 1;
            }
            array = array.slice(start, end + 1);
            return this.binarySearch(array, element)
        }

    }

    deleteTicketObjects() {
        // this.partnerCategoryService.delete(this.ticketObjectId).subscribe(res => {
        //     debugger
        // }, error => {
        //     debugger
        // })
    }

    ngAfterViewInit(): void {
    }

    refreshComponent() {

        this.showAllCustomerContracts();

        // const request = new ObjectRequestList(
        //     true,
        //     [],
        //     []
        // );
        // request.objectRequestComplex = ObjectRequestComplex.build(
        //     true,
        //     true,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('parent')
        // );
        // this.documentService.list(request).subscribe(res => {
        //     debugger
        // }, error => {
        //     debugger
        // })

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    widgetCustomerInsuranceToolbarFunction() {
        return {
            onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['personDtoId'] = 5637;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    showAllCustomerContracts() {

        const objectRequest = new ObjectRequestList(true, [], []);

        this.householdPersonWidget = WidgetFactory.createWidgetList(
            'cm.create.insurable.household.object.create.form.widget',
            'Household Person',
            'table',
            'list',
            'entity',
            'pm.HouseholdPerson',
            'No data found!',
            objectRequest
        );

        // this.householdPersonWidget.functionCallbacks = this.widgetCustomerInsuranceToolbarFunction();


        this.householdWidget = WidgetFactory.createWidgetList(
            'cm.create.household.person.object.create.form.widget',
            'Household',
            'table',
            'list',
            'entity',
            'pm.Household',
            'No data found!',
            objectRequest
        );

        // this.personRelationPersonWidget = WidgetFactory.createWidgetList(
        //     'cm.create.household.person.object.relation.person.create.form.widget',
        //     'Person Relation Person',
        //     'table',
        //     'list',
        //     'entity',
        //     'pm.PersonRelationPerson',
        //     'No data found!',
        //     objectRequest
        // );

        this.personRelationPersonWidget = WidgetFactory.createWidgetList(
            'cr.customer.standard.navigation.related.persons.object.pm.related.person.1.2',
            'Person Related Person',
            'list',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'No data found',
            new ObjectRequestList(true, [FilterCriteria.create('personA', 'EQ', 5637)], [])
        );
        this.personRelationPersonWidget.functionCallbacks = this.personRelationPersonToolbar();


        // this.customerContractWidgetData = WidgetFactory.createWidgetListComplex(
        //     "cr.customer.basic.contact.13623",
        //     "Kundeninfo",
        //     "table",
        //     "list",
        //     "entity",
        //     "cr.Customer",
        //     "Keine Kontakdaten vorhanden",
        //     new ObjectRequestList(true, [],[]),
        //     null,
        //     WidgetDataParam.create("size", "S"),
        //     WidgetDataParam.create("objectWidget", "data"),
        // );
        //
        // this.customerContractWidgetData.dataProviderListRequest.paging = PagingDto.create(0,5);

        // this.customerContractWidgetData = WidgetFactory.createWidgetObject(
        //     "cr.customer.basic.contact.1362",
        //     "Form",
        //     'cr.Customer'
        // )

        // this.customerContractWidgetData.dataProviderListRequest.paging = PagingDto.create(0, 20);


        // this.customerCallsCategoryWidget = WidgetFactory.createWidgetGroupBy(
        //     "cr.customer.communication.navigation.show.customer.calls.category",
        //     "Anrufübersicht",
        //     "table",
        //     "entity.groupBy",
        //     "pc.Call",
        //     "callResult",
        //     "callResult_count",
        //     [FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, 5637),
        //     ],
        //     "callResult",
        //     "Anzahl"
        // );
        //
        // this.customerContractWidgetData = new WidgetData();
        // this.customerContractWidgetData.idAlias = "cr.customer.basic.contract";
        // this.customerContractWidgetData.name = "Verträge";
        // this.customerContractWidgetData.uiComponent = "table";
        // this.customerContractWidgetData.dataProvider = "list";
        // this.customerContractWidgetData.dataSource = "entity";
        // this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";
        //
        // // this.customerContractWidgetData.groupBy = this.groupByContractWidgetData;
        //
        // const filterCriteria = [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, 5637)];
        // this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
        //     true,
        //     filterCriteria,
        //     [new Sorting('lastModifiedDate', false)],
        //     PagingDto.create(0, 7)
        // );
        //
        // this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCustomerContractsComplexSelection();
        // // this.customerContractWidgetData.setParamValue("selectionMode", "rowSelect");
        // this.customerContractWidgetData.setParamValue("size", "S");
        // // this.customerContractWidgetData.setParamValue("mode", MvsCrudModeEnum.read);
        // this.customerContractWidgetData.setParamValue("objectWidget", "data");
        // // this.customerContractWidgetData.setParamValue("objectId", "109870");
        // if (this.selectedRelatedPersons) {
        //     this.selectedRelatedPersons.setSelectedPerson(null);
        // }
        //
        // this.customerContractWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        //
        // this.createContractToolbarButtons();

        // this.customerCallsCategoryWidget = WidgetFactory.createWidgetGroupBy(
        //     "cr.customer.communication.navigation.show.customer.calls.category.1",
        //     "Anrufübersicht",
        //     "category",
        //     "entity.groupBy",
        //     "cr.Customer",
        //     "customerType",
        //     "customerType_count",
        //     [],
        //     "customerType",
        //     "Anzahl"
        // );


        // const filter = FilterCriteria.create('entityStatus', 'EQ', 1);
        // const objectRequest = this.getObjectRequestGroupBy(null);
        // this.getCustomerRequestObservable(objectRequest).subscribe(res => {
        //
        //     const relevantEntry = res.entries[0];
        //     if (relevantEntry) {
        //         // this.loadCustomerData(relevantEntry);
        //     }
        // },error => {
        // });

    }

    personRelationPersonToolbar() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = 5637;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    getObjectRequestGroupBy(filter: FilterCriteria[]): ObjectRequestListGroupBy {
        const objectRequestList = new ObjectRequestListGroupBy(true, filter, []);
        objectRequestList.groupBy = [this.groupByField];
        objectRequestList.attributes = [new ObjectRequestListAttribute(this.groupByField, this.groupByField, DtoListAttributeRequestAggregateEnum.count)];
        return objectRequestList;
    }

    getCustomerRequestObservable(objectRequestList: ObjectRequestListGroupBy) {
        // return this.customerService.groupBy(objectRequestList);
    }

    loadCustomerData(entry: DtoDetail) {
        let groupByFieldValue: string;

        if (entry[this.groupByField + 'DtoId']) {
            groupByFieldValue = entry[this.groupByField + 'DtoId'];
        } else if (entry[this.groupByField + 'DtoName']) {
            groupByFieldValue = entry[this.groupByField + 'DtoName'];
        }

        if (!groupByFieldValue) {
            return;
        }

        const filter = FilterCriteria.create(this.groupByField, FilterCriteria.cOperatorEqual, groupByFieldValue);
        // const objectRequest = this.getObjectRequestList([filter]);
        const objectRequest = new ObjectRequestList(
            true,
            [filter],
            []
        );
        // this.customerService.list(objectRequest).subscribe(res => {
        //
        // });
    }

    onSetFilterCriteria(event: ObjectIdentifierData, type: string) {
    }

    handlePersonCreated(event: any) {
        debugger
    }

    handleFormChange(event: DtoDetail) {
        console.log(event);
    }

    handleNav() {
        const object = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', null,null, null, null, null, MvsObjectNavigationActionEnum.any, 'light');
        this.navigationService.navigateTo(object, 'right');
    }

    trigger() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', 235, null, "Object", null, null, MvsObjectNavigationActionEnum.edit);
        this.navigationService.navigateTo(navigationEntry,'right');
    }


    protected readonly ObjectIdentifier = ObjectIdentifier;
}