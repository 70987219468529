import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectIdentifierWithId} from "@kvers/alpha-core-common";

export class TeTemplateGenerateRequestDto {

    constructor(
        public testMode: boolean,
        public useTestCase: boolean,
        public testCaseId: number,
        public generatePdf: boolean,
        public objectVariables: {[key: string]: ObjectIdentifierWithId},
        public simpleVariables: {[key: string]: any}
    ) {
    }


}