import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerDto } from "./customer.dto";

export class CustomerCommentDto extends DtoDetail{


    public customerDto? : CustomerDto;
    public customerDtoId : number;
    public customerDtoName : string;
    public comment: string;

}