@if (initialized) {
    <div class="grid">
        <div class="col-12">
            <div class="flex justify-content-between align-items-center w-full"
                 *ngIf="appointmentType">
                <label class="font-medium w-full">Appointment Type</label>
                <p-dropdown
                        [options]="appointmentType"
                        [(ngModel)]="appointmentTypeId"
                        optionValue="id"
                        optionLabel="name"
                        placeholder="Select appointment type"
                        class="w-full"
                        styleClass="w-full"
                        appendTo="body"
                        (onChange)="fetchAppointmentsAndSlots()"
                />
            </div>
        </div>
        <div class="col-12">

            <scheduler-component
                    [schedulerData]="schedulerData"
                    [disableDrag]="true"
                    [disable]="true"
                    (onSelectFreeSlot)="handleFreeSlot($event)"
                    (onShowEvent)="handleEventClick($event)"
                    (onChangeModeAndTime)="handleChangeModeAndTime($event)"
                    (dateRangeChanged)="handleDateRangeChanged($event)"
                    [startHour]="6"
                    [endHour]="20"
                    [showOnlineOnsite]="true"
            ></scheduler-component>
        </div>
    </div>


    <p-dialog [(visible)]="createEventDialog" [style]="{ 'min-width': '720px' }" header="Create Appointment" modal>
        @if (createEventDialog) {
            <div class="p-3 flex flex-column gap-4">


                <!-- SECTION 1: Select Slot & Agent -->
                <div class="card p-3 bg-surface-100 border-round shadow-sm">
                    <div class="flex justify-content-between align-items-center mb-2">
                        <span class="font-semibold">Book from available free slots</span>
                        <div class="flex align-items-center gap-2">
                            <span class="text-sm text-color-secondary">Manual override</span>
                            <p-inputSwitch [(ngModel)]="overwriteSlot"/>
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-3">
                        <div class="flex flex-column gap-1 flex-1 min-w-14rem">
                            <label class="text-sm">Free Slot</label>
                            <p-dropdown
                                    [options]="allFreeSlots"
                                    [(ngModel)]="selectedFreeSlot"
                                    [disabled]="overwriteSlot"
                                    (onChange)="handleSlotChange()"
                                    placeholder="Select a free slot"
                                    class="w-full"
                                    appendTo="body"
                            >
                                <ng-template let-slot pTemplate="item">
                                    {{ slot?.data?.eventData?.timeSlot?.start | date: 'HH:mm' }}
                                    - {{ slot?.data?.eventData?.timeSlot?.end | date: 'HH:mm' }}
                                </ng-template>
                                <ng-template let-slot pTemplate="selectedItem">
                                    {{ slot?.data?.eventData?.timeSlot?.start | date: 'HH:mm' }}
                                    - {{ slot?.data?.eventData?.timeSlot?.end | date: 'HH:mm' }}
                                </ng-template>
                            </p-dropdown>
                        </div>

                        <div class="flex flex-column gap-1 flex-1 min-w-14rem">
                            <label class="text-sm">Agent</label>
                            <p-dropdown
                                    [options]="selectedFreeSlot?.personDto"
                                    [(ngModel)]="selectedSlotAgent"
                                    [disabled]="overwriteSlot"
                                    (onChange)="handleAgentChange()"
                                    optionLabel="name"
                                    placeholder="Select agent"
                                    class="w-full"
                                    appendTo="body"
                            />
                        </div>
                    </div>
                </div>

                <!-- SECTION 2: Manual Override -->
                <div *ngIf="overwriteSlot" class="card p-3 bg-surface-100 border-round shadow-sm">
                    <div class="font-semibold mb-2">Manual Time Selection</div>
                    <div class="flex flex-wrap gap-3">

                        <div class="flex flex-column gap-1">
                            <label class="font-medium">Appointment Type</label>
                            <p-dropdown
                                    [options]="appointmentType"
                                    [(ngModel)]="appointmentTypeId"
                                    [disabled]="!overwriteSlot"
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Select appointment type"
                                    class="w-full"
                                    appendTo="body"
                            />
                        </div>

                        <div class="flex flex-column gap-1 flex-1 min-w-14rem">
                            <label class="text-sm">Start Time</label>
                            <p-calendar
                                    [(ngModel)]="currentSelectionStart"
                                    [timeOnly]="true"
                                    [showTime]="true"
                                    hourFormat="24"
                                    class="w-full"
                                    appendTo="body"
                            />
                        </div>

                        <div class="flex flex-column gap-1 flex-1 min-w-14rem">
                            <label class="text-sm">End Time</label>
                            <p-calendar
                                    [(ngModel)]="currentSelectionEnd"
                                    [timeOnly]="true"
                                    [showTime]="true"
                                    hourFormat="24"
                                    class="w-full"
                                    appendTo="body"
                            />
                        </div>

                        <div class="flex flex-column gap-1 flex-1 min-w-14rem">
                            <label class="text-sm">Agent</label>
                            <p-dropdown
                                    [options]="agents"
                                    [(ngModel)]="selectedAgent"
                                    optionLabel="name"
                                    placeholder="Select agent"
                                    class="w-full"
                                    appendTo="body"
                            />
                        </div>
                    </div>
                </div>

                <!-- SECTION 4: Action Button -->
                <div class="flex justify-content-end">
                    <p-button
                            label="Create Event"
                            icon="pi pi-check"
                            [disabled]="!appointmentTypeId || !selectedAgent || !currentSelectionStart || !currentSelectionEnd"
                            (click)="handleCreateEvent()"
                            class="p-button-success"
                    />
                </div>
            </div>
        }
    </p-dialog>

}