@if (initialized && dto) {
    @if (uiMode == 'side') {
        <field-type-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></field-type-side>
    } @else {
        <field-type-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></field-type-full>
    }
}