import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";

@Component({
  selector: 'ns-config-test',
  templateUrl: './ns-config-test.page.html',
  styleUrls: ['./ns-config-test.page.scss']
})
export class NsConfigTestPage extends PageComponent implements OnInit, OnDestroy {

  notificationGroupWidget: WidgetData;
  notificationTypeWidget: WidgetData;
  notificationTypeChannelWidget: WidgetData;
  objectType: string;
  objectId: number;
  activeWidget: TabEntries;
  queryParamSubscription: Subscription;

  widgetEntries: TabEntries[] = [
    {label:'Notification Groups', id: 'ns.NsNotificationGroup', type: ''},
    {label:'Notification Types', id: 'ns.NsNotificationType', type: ''},
    {label:'Notification Type Channels', id: 'ns.NsNotificationTypeChannelType', type: ''}
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }


  ngOnInit(): void {

    super.ngOnInit();
    this.subscribeToParams();
    this.refreshWidgets();


  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshTemplateGroupWidget();
    this.refreshArtefactGroupWidget();
    this.refreshTemplateWidget();
  }

  refreshTemplateGroupWidget() {
    this.notificationGroupWidget =  WidgetFactory.createWidgetList(
        "ns.config.notification.group.widget.simple",
        'Notification Groups',
        'table',
        'list',
        'entity',
        'ns.NsNotificationGroup',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshTemplateWidget() {
    this.notificationTypeWidget =  WidgetFactory.createWidgetList(
        "ns.config.notification.type.widget.simple",
        'Notification Types',
        'table',
        'list',
        'entity',
        'ns.NsNotificationType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshArtefactGroupWidget() {
    this.notificationTypeChannelWidget =  WidgetFactory.createWidgetList(
        "ns.config.notification.type.channel.widget.simple",
        'Notification Type Channels',
        'table',
        'list',
        'entity',
        'ns.NsNotificationTypeChannelType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  handleTabSelection(item: TabEntries) {
    this.activeWidget = item;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));


  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;

    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }

}
