import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {DataType} from "../../../../enum/data-type.enum";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {FieldTypeService} from "../../../../service/api/field-type.service";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'cc-statistics.component.html',
})
export class CcStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    fieldHistoryWidget: WidgetData;
    fieldTypeObjectTypeWidget: WidgetData;
    fieldValueObjectTypeWidget: WidgetData;
    recentlyAddedFieldsWidget: WidgetData;

    totalObjectFields:number = 0;
    totalDateFields:number = 0;
    totalStringFields:number = 0;
    totalIntegerFields:number = 0;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        protected fieldTypeService: FieldTypeService,
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'CC Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getTotalFieldTypesGroupByDataType();
        this.refreshFieldHistoryWidget();
        this.refreshFieldTypeObjectTypeWidget();
        this.refreshFieldValueObjectTypeWidget();
        this.refreshAddedFields();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/cc/config']);
    }



    getTotalFieldTypesGroupByDataType() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['dataType'],
            [new ObjectRequestListAttribute('dataType', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.fieldTypeService.groupBy(objectRequestList).subscribe(res => {
            this.overviewDto = [
                { label: 'Total Object Fields', value: 0 },
                { label: 'Total Date Fields', value: 0 },
                { label: 'Total String Fields', value: 0 },
                { label: 'Total Integer Fields', value: 0 }
            ];

            for (let entry of res.entries) {
                if (entry['dataType'] === DataType.Object) {
                    this.overviewDto.find(item => item.label === 'Total Object Fields').value = entry['dataType_count'];
                } else if (entry['dataType'] === DataType.Date) {
                    this.overviewDto.find(item => item.label === 'Total Date Fields').value = entry['dataType_count'];
                } else if (entry['dataType'] === DataType.String) {
                    this.overviewDto.find(item => item.label === 'Total String Fields').value = entry['dataType_count'];
                } else if (entry['dataType'] === DataType.Integer) {
                    this.overviewDto.find(item => item.label === 'Total Integer Fields').value = entry['dataType_count'];
                }
            }

        });
    }


    refreshFieldHistoryWidget() {
        this.fieldHistoryWidget = new WidgetData();
        this.fieldHistoryWidget.idAlias = "cc.overview.fields.history.widget";
        this.fieldHistoryWidget.name = "Field History";
        this.fieldHistoryWidget.uiComponent = "history.chart";
        this.fieldHistoryWidget.dataProvider = "list";
        this.fieldHistoryWidget.dataSource = "entity.groupBy";
        this.fieldHistoryWidget.dataProviderObject = "cc.FieldType";
        this.fieldHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["dataType", "createdDate"],
            [new ObjectRequestListAttribute("dataType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.fieldHistoryWidget.setParamValue("fieldCategory", "dataType");
        this.fieldHistoryWidget.setParamValue("fieldCategoryCount", "dataType_count");
        this.fieldHistoryWidget.setParamValue("historyPeriodMode", "year");
        this.fieldHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.fieldHistoryWidget.setParamValue("selectionMode", "single");
        this.fieldHistoryWidget.setParamValue("size", "S");
    }

    refreshFieldTypeObjectTypeWidget() {
        this.fieldTypeObjectTypeWidget = WidgetFactory.createWidgetGroupBy(
            'cc.overview.field.type.object.type.widget',
            'Field Type Object Type',
            'category',
            'entity.groupBy',
            'cc.FieldType',
            'dataTypeObjectType',
            'dataTypeObjectType_count',
            this.filterCriteria,
            'dataTypeObjectType',
            'Anzahl');
    }

    refreshFieldValueObjectTypeWidget() {
        this.fieldValueObjectTypeWidget = WidgetFactory.createWidgetGroupBy(
            'cc.overview.field.type.object.value.widget',
            'Field Value Object Type',
            'category',
            'entity.groupBy',
            'cc.ObjectTypeAttribute',
            'objectType',
            'objectType_count',
            this.filterCriteria,
            'objectType',
            'Anzahl');
    }

    refreshAddedFields() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedFieldsWidget = WidgetFactory.createWidgetTableEntity(
            'cc.overview.recently.added.fields',
            'Recently Added Fields',
            'cc.FieldType',
            'No Fields',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
