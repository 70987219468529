<p-tabView #tabView [activeIndex]="tabIndex" (onChange)="onOptionalComponentSelect($event)">
    <ng-container *ngFor="let activity of stepActivities; let i = index">
        <p-tabPanel [header]="activity.objectTypeDtoName + activity.objectId">
            <ng-template [ngTemplateOutlet]="activityTemplate" [ngTemplateOutletContext]="{ index: i }"></ng-template>
        </p-tabPanel>
    </ng-container>
</p-tabView>

<ng-template #activityTemplate let-index>
    <ng-container *ngIf="stepActivities && stepActivities[index]">
        <ng-container [ngTemplateOutlet]="stepActivities[index].templateRef"></ng-container>
    </ng-container>
</ng-template>