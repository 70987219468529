import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-ns-notification-type-page',
  templateUrl: './ns-sms-config.page.html'
})
export class NsSmsConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "SMS Konfiguration";


  ngOnInit(): void {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'ns.NsSmsConfiguration',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "SMS Konfiguration",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
      ]
    };


  }
}