<ng-container *ngIf="initialized">

    <div class="flex flex-column gap-3 pt-2">

<!--        <ng-container *ngIf="suggestedTicketObjectLabel">-->
<!--            <p-button [label]="suggestedTicketObjectLabel"-->
<!--                      [icon]="busy ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"-->
<!--                      (click)="handleAddSuggestedObject()"-->
<!--            >-->

<!--            </p-button>-->
<!--        </ng-container>-->

        <ng-container *ngIf="ticketObjects && ticketObjects.length">
        <h4 class="m-0 px-3 text-lg font-medium">Related Objects</h4>
        <div class="grid px-3">
            <div *ngFor="let object of ticketObjects" [ngClass]="fullPage ? 'col-4' : 'col-6'">
              <mvs-object-quick-view
                      class="border-round-sm block" style="background: var(--bluegray-50);"
                      [ngStyle]="{'border-left': '4px solid var(--' + (object?.ticketTypeObjectRoleDto?.rgbaColor ? object.ticketTypeObjectRoleDto.rgbaColor : 'bluegray-50') + ')'}"
                        [objectIdentifier]="object['objectIdentifier']"
                        [mode]="'inline'"
                        [size]="'M'"
                        [widgetToolbarButtons]="ticketObjectToolbarButtons"
                        (onToolbarButtonClick)="handleTicketTypeToolbarButton($event, object['objectIdentifier'])">
                </mvs-object-quick-view>

            </div>
        </div>
        </ng-container>




<!--        <div class="flex justify-content-end px-3">-->
<!--            <p-button label="Request Appointment" (click)="handleRequestAppointment()"></p-button>-->
<!--        </div>-->

<!--        <div class="flex flex-column gap-3 px-3"-->
<!--             *ngIf="ticketAppointmentList?.entries?.length">-->
<!--&lt;!&ndash;            <h4 class="m-0 text-lg font-semibold">Appointments</h4>&ndash;&gt;-->
<!--            <mvs-object-quick-view *ngFor="let appointment of ticketAppointmentList.entries"-->
<!--                                   class="border-round-sm" style="background: var(&#45;&#45;bluegray-50)"-->
<!--                                   [objectIdentifier]="{objectType: 'tm.TicketAppointment', objectId: appointment.id}"-->
<!--                                   [mode]="'inline'"-->
<!--                                   [size]="'M'">-->
<!--            </mvs-object-quick-view>-->

<!--        </div>-->
    </div>
</ng-container>