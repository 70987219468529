<ng-container *ngIf="showStack && initialized && customerAssessment">
    <!--    <div class="flex justify-content-between gap-5 overflow-x-auto">-->
    <div class="grid overflow-x-auto">

        <!--        <div class="flex gap-3" *ngFor="let assessment of customerAssessment.customerAssessments">-->
        <div class="col" *ngFor="let assessment of customerAssessment.customerAssessments">
            <div class="flex gap-2 flex-column lg:flex-row">
                <p-avatar
                        [icon]="assessment.assessmentGroup.image"
                        class="cursor-pointer avatar"
                        [style]="{ 'background-color': getColors(assessment.assessmentResult), color: '#f2f2f2' }"
                        size="xlarge"
                        shape="circle"
                        (click)="togglePopupMenu(assessment,$event)"
                ></p-avatar>
                <span class="text-700 font-medium align-self-center">{{assessment.assessmentGroup.name}}</span>
            </div>
        </div>
    </div>

    <p-overlayPanel
            [style]="{maxHeight:'20rem', overflow:'auto'}"
            #overlayPanel
            disabled="true"
            appendTo="body">

        <ng-template pTemplate="content">
            <p *ngFor="let item of overlayPanelItems">{{item}}</p>
        </ng-template>

    </p-overlayPanel>

</ng-container>
