import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CallTypeService} from "../../../../service/api/call-type.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {CallDirectionEnum} from "../../../../enum/call-direction-enum.enum";
import {CallTypeDto} from "../../../../dto/call-type.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {CallService} from "../../../../service/api/call.service";
import {
    FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CallUiService} from "../../../../service/ui/call-ui.service";

@Component({
    selector: 'mvs-pc-incoming-call-page',
    templateUrl: './pc-incoming-call.page.html',
})
export class PcIncomingCallPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Eingehende Anrufe";

    callTypeList: DtoList<CallTypeDto>;

    searchString: string;
    isSearched: boolean;

    selectedCustomerId: number;

    steps: MenuItem[];
    stepIndex: number = 0;

    constructor(
        protected callTypeService:CallTypeService,
        protected callService: CallService,
        protected route:ActivatedRoute,
        protected router: Router,
        protected coreService:MvsCoreService,
        protected callUiService: CallUiService,
        protected messageService: MvsMessageService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.steps = [ { label: "Kunden auswählen" }, { label: "Anruf starten" } ];

        const incomingCallsTypeFilter = [FilterCriteria.create("callDirection", FilterCriteria.cOperatorEqual, CallDirectionEnum.incoming)];

        this.callTypeService.list(ObjectRequestList.createBasic(false,incomingCallsTypeFilter,[])).subscribe(value => {
            this.callTypeList = <DtoList<CallTypeDto>>value;

            this.refreshComponent();
        })


    }

    handleCustomerSelect(event: FlexibleSearchDataEvent) {
        this.selectedCustomerId = event.id;
        this.stepIndex = 1;
    }

    handleStartCall(callType: CallTypeDto) {

        // check for active call
        this.callService.getMyActiveCalls().subscribe(activeCalls => {

            if (activeCalls && activeCalls.entries && activeCalls.entries.length > 0) {
                this.messageService.showErrorMessage("Laufender Anruf", "Bitte aktuellen Anruf zuerst beenden!");
            } else {
                this.callService.startCustomerCall(callType.id, this.selectedCustomerId, "").subscribe(value => {
                    this.callUiService.refreshHeaderActiveCall();
                    // navigate to customer
                    this.router.navigateByUrl('cr/customers/' + this.selectedCustomerId);
                });
            }
        });

    }

    startCall(event: CallTypeDto) {

        // this.callService.startCustomerCall(event.id, )

    }



    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
