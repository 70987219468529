import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OptionGroups} from "../../interfaces/mvs-dashboard-option-groups.interface";
import {GroupItem} from "../../interfaces/mvs-dashboard-group-item.interface";


@Component({
    selector: 'mvs-dashboard-side-options',
    templateUrl: './mvs-dashboard-side-options.component.html',
    styleUrls: ['./mvs-dashboard-side-options.component.scss']
})
export class MvsDashboardSideOptionsComponent implements OnInit {

    @Input() width: string;
    @Input() isCollapsable: boolean;

    @Input() optionGroups: OptionGroups[];

    @Input() selectedItem: GroupItem;
    @Output() selectedItemChange = new EventEmitter<GroupItem>();

    @Input() isCollapsed: boolean = false;
    @Output() isCollapsedChange = new EventEmitter<boolean>();
    initialized: boolean;

    constructor(private router: Router, private route: ActivatedRoute) {

    }


    ngOnInit(): void {

        this.refreshComponent();
        this.initialized = true;

    }

    refreshComponent() {
    }


    setSelectedOption(option: GroupItem): void {
        this.selectedItem = option;
        this.selectedItemChange.emit(option);
    }


    toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsedChange.emit(this.isCollapsed);
    }

    collapsedChange(ticketPanel) {
        ticketPanel.animating = true;
        ticketPanel.collapsed = !ticketPanel.collapsed;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }
        this.refreshComponent();
    }

}
