import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class CustomerExternalAliasService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerExternalAliass')
  }

  /**
   * Get all aliases for a specific system which are enabled.
   * @param systemId
   */
  public getSystemTransferEnabled(systemId: number) : Observable<DtoList> {

    let url = this.apiUrl + "/viaSystemTransferEnabled/" + systemId;

    return this.getList(url);
  }

}