<ng-container *ngIf="navigationItems && initialized">

    <div
            class="mt-5">

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--*************************************      TicketType      ************************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketType'">
                <div class="col-12">

                    <!--  Widget to display ticket type data  -->
                    <mvs-widget
                            *ngIf="ticketTypeWidget && !isTicketTypeUpdate"
                            [widgetData]="ticketTypeWidget"
                    ></mvs-widget>
                </div>

            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--**********************************      TicketTypeAction      *********************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeAction'">
                <div class="col-4">

                    <p-accordion [multiple]="true" *ngIf="ticketTypeActionList && ticketTypeActionList.length">
                        <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                            <p-accordionTab
                                    [header]="item.name"
                                    *ngIf="getItemData(item, ticketTypeActionList, 'ticketTypeDtoId').length > 0"
                            >
                                <p-table [value]="getItemData(item, ticketTypeActionList, 'ticketTypeDtoId')">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-field>
                                        <tr (click)="handleActionRowSelect(field)" class="pointer">
                                            <td>{{ field.id }}</td>
                                            <td>{{ field.name }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-accordionTab>

                        </ng-container>
                    </p-accordion>

                    <mvs-widget
                            *ngIf="ticketTypeActionWidget"
                            [widgetData]="ticketTypeActionWidget"
                            (onObjectSelect)="handleTicketTypeAction($event)"
                    ></mvs-widget>

                </div>

                <div class="col-8">


                    <!--  Widget to display data  -->
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>

                    <mvs-widget
                            *ngIf="ticketTypeActionTicketMappingWidget && ticketTypeActionInternalType == TicketTypeActionTypeInternal.ticket"
                            [widgetData]="ticketTypeActionTicketMappingWidget"
                    ></mvs-widget>

                    <mvs-widget
                            *ngIf="ticketTypeActionProcessMappingWidget && ticketTypeActionInternalType == TicketTypeActionTypeInternal.process"
                            [widgetData]="ticketTypeActionProcessMappingWidget"
                    ></mvs-widget>

                </div>
            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--********************************      TicketTypeObjectRole      *******************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeObjectRole'">
                <div class="col-4">

                    <p-accordion [multiple]="true"
                                 *ngIf="ticketTypeObjectRoleList && ticketTypeObjectRoleList.length">
                        <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                            <p-accordionTab
                                    [header]="item.name"
                                    *ngIf="getItemData(item, ticketTypeObjectRoleList, 'ticketTypeDtoId').length > 0"
                            >
                                <p-table [value]="getItemData(item, ticketTypeObjectRoleList, 'ticketTypeDtoId')">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-field>
                                        <tr (click)="handleRowSelect('tm.TicketTypeObjectRole',field.id)"
                                            class="pointer">
                                            <td>{{ field.id }}</td>
                                            <td>{{ field.name }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-accordionTab>
                        </ng-container>
                    </p-accordion>

                    <mvs-widget
                            *ngIf="ticketTypeObjectRoleWidget"
                            [widgetData]="ticketTypeObjectRoleWidget"
                            (onObjectSelect)="handleObjectViewWidget($event)"
                    ></mvs-widget>

                </div>

                <div class="col-8">

                    <!--  Widget to display data  -->
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>
                </div>
            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--********************************      TicketTypeObjectType      *******************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeObjectType'">
                <div class="col-4">

                    <p-accordion [multiple]="true"
                                 *ngIf="ticketTypeObjectTypeList && ticketTypeObjectTypeList.length">
                        <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                            <p-accordionTab
                                    [header]="item.name"
                                    *ngIf="getItemData(item, ticketTypeObjectTypeList, 'ticketTypeDtoId').length > 0"
                            >
                                <p-table [value]="getItemData(item, ticketTypeObjectTypeList, 'ticketTypeDtoId')">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-field>
                                        <tr (click)="handleRowSelect('tm.TicketTypeObjectType',field.id)"
                                            class="pointer">
                                            <td>{{ field.id }}</td>
                                            <td>{{ field.name }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-accordionTab>
                        </ng-container>
                    </p-accordion>

                    <mvs-widget
                            *ngIf="ticketTypeObjectTypeWidget"
                            [widgetData]="ticketTypeObjectTypeWidget"
                            (onObjectSelect)="handleObjectViewWidget($event)"
                    ></mvs-widget>

                </div>

                <!--  Widget to display data  -->
                <div class="col-8">
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>

                </div>
            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--****************************      TicketTypeNotificationType      *****************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeNotificationType'">
                <div class="col-4">

                    <p-accordion [multiple]="true"
                                 *ngIf="ticketTypeNotificationTypeList && ticketTypeNotificationTypeList.length">
                        <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                            <p-accordionTab
                                    [header]="item.name"
                                    *ngIf="getItemData(item, ticketTypeNotificationTypeList, 'ticketTypeDtoId').length > 0"
                            >
                                <p-table
                                        [value]="getItemData(item, ticketTypeNotificationTypeList, 'ticketTypeDtoId')">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-field>
                                        <tr (click)="handleRowSelect('tm.TicketTypeNotificationType',field.id)"
                                            class="pointer">
                                            <td>{{ field.id }}</td>
                                            <td>{{ field.name }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-accordionTab>
                        </ng-container>
                    </p-accordion>

                    <mvs-widget
                            *ngIf="ticketTypeNotificationTypeWidget"
                            [widgetData]="ticketTypeNotificationTypeWidget"
                            (onObjectSelect)="handleTicketTypeNotificationType($event)"
                    ></mvs-widget>

                </div>

                <div class="col-8">

                    <!--  Widget to display data  -->
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>


                    <mvs-widget
                            *ngIf="ticketTypeNotificationTypeMappingWidget"
                            [widgetData]="ticketTypeNotificationTypeMappingWidget"
                    ></mvs-widget>
                </div>
            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--*****************************      TicketTypeCompleteStatus      ******************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeCompleteStatus'">
                <div class="col-4">

                    <p-accordion [multiple]="true"
                                 *ngIf="ticketTypeCompletionList && ticketTypeCompletionList.length">
                        <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                            <p-accordionTab
                                    [header]="item.name"
                                    *ngIf="getItemData(item, ticketTypeCompletionList, 'ticketTypeDtoId').length > 0"
                            >
                                <p-table [value]="getItemData(item, ticketTypeCompletionList, 'ticketTypeDtoId')">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-field>
                                        <tr (click)="handleRowSelect('tm.TicketTypeCompleteStatus',field.id)"
                                            class="pointer">
                                            <td>{{ field.id }}</td>
                                            <td>{{ field.name }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-accordionTab>
                        </ng-container>
                    </p-accordion>

                    <mvs-widget
                            *ngIf="ticketTypeCompletionStatusWidget"
                            [widgetData]="ticketTypeCompletionStatusWidget"
                            (onObjectSelect)="handleObjectViewWidget($event)"
                    ></mvs-widget>

                </div>

                <div class="col-8">

                    <!--  Widget to display data  -->
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>

                </div>
            </div>

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--**********************************      TicketTypeField      **********************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="grid" *ngIf="activeNavigationItem?.action == 'tm.TicketTypeField'">
                <div class="col-4">
                    <mvs-widget
                            *ngIf="ticketTypeFieldWidget"
                            [widgetData]="ticketTypeFieldWidget"
                            (onObjectSelect)="handleObjectViewWidget($event)"
                    ></mvs-widget>
                </div>

                <div class="col-8">

                    <!--  Widget to display data  -->
                    <mvs-widget
                            *ngIf="objectViewWidget && !isCreateMode"
                            [widgetData]="objectViewWidget"
                    ></mvs-widget>
                </div>
            </div>
    </div>


</ng-container>

