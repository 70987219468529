import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerTypeDto } from "./customer-type.dto";
import { CustomerConsultingTypeDto } from "./customer-consulting-type.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {PmPersonDto} from "./pm-person.dto";
import {CustomerAddressDto} from "./customer-address.dto";
import {CustomerUserDto} from "./customer-user.dto";
import {CustomerOnboardingDto} from "./customer-onboarding.dto";
import {CustomerContractDto} from "./customer-contract.dto";


export class CustomerDto extends DtoDetail{


    public alias: string;
    public startDate: Date;
    public endDate: Date;
    public customerTypeDto? : CustomerTypeDto;
    public customerTypeDtoId : number;
    public customerTypeDtoName : string;
    public entityStatus: EntityStatusEnum;
    public customerConsultingTypeDto? : CustomerConsultingTypeDto;
    public customerConsultingTypeDtoId : number;
    public customerConsultingTypeDtoName : string;
    public removeFromActionsUntil: Date;
    public removeFromActionsUntilReason: string;
    public personDto? : PmPersonDto;
    public personDtoId : number;
    public personDtoName : string;
    public registrationDtoId : number;
    public registrationDtoName : string;


    public addresses?: CustomerAddressDto[];

    user?: CustomerUserDto;
    customerOnboarding?: CustomerOnboardingDto;
    customerContracts?: CustomerContractDto[];

}