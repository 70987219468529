import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsFormValueListDto} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './input-fields.page.html',
    styleUrls: ['./input-fields.page.scss']
})
export class InputFieldsPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Input Fields";

    formGroup: FormGroup;
    valueList: MvsFormValueListDto;
    nameFormField: MvsFormFieldDto;
    nameEditFormField: MvsFormFieldDto;
    radioFormField: MvsFormFieldDto;
    radioEditFormField: MvsFormFieldDto;
    objectTypeFormField: MvsFormFieldDto;
    objectTypeEditFormField: MvsFormFieldDto;
    selectedOption = 'inputs';
    sidebarOptions = [
        { id: 'inputs', name: 'Inputs' },
        { id: 'textArea', name: 'Text Area' },
        { id: 'dropDown', name: 'DropDown' },
        { id: 'inputDate', name: 'Input Date' },
        { id: 'phoneNumber', name: 'Input Phone/Mobile' },
        { id: 'sliderField', name: 'Slider' },
        { id: 'radioButton', name: 'Radio' },
        { id: 'inputCheckbox', name: 'Checkbox' },
        { id: 'inputSwitch', name: 'Input Switch' },
        { id: 'selectButton', name: 'SelectButton' },
        { id: 'chip', name: 'Chip' },
        { id: 'rating', name: 'Rating' },
    ];
    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService, private fb: FormBuilder) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.valueList = new MvsFormValueListDto();

        this.valueList.entries = [
            {
                key: 1,
                label: 'a',
                image: 'fa fa-trash',
                color: 'red-800',
                backgroundColor: 'red-200'
            },
            {
                key: 2,
                label: 'b',
                image: 'fa fa-home',
                color: 'green-800',
                backgroundColor: 'green-200'
            },
            {
                key: 3,
                label: 'c',
                image: 'fa fa-user',
                color: 'blue-800',
                backgroundColor: 'blue-200'
            },
            {
                key: 4,
                label: 'd',
                image: 'fa fa-circle',
                color: 'yellow-800',
                backgroundColor: 'yellow-200'
            },
        ];

        this.formGroup = this.fb.group({
            name: new FormControl('', [Validators.required]),
            nameEdit: new FormControl('Edit', [Validators.required]),
            slider: new FormControl('', [Validators.required]),
            sliderEdit: new FormControl(50, [Validators.required]),
            radio: new FormControl('', [Validators.required]),
            radioEdit: new FormControl(2, [Validators.required]),
            rating: new FormControl('', [Validators.required]),
            ratingEdit: new FormControl(3, [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            phoneEdit: new FormControl('03139634862', [Validators.required]),
            objectDtoId: new FormControl('', [Validators.required]),
            objectDtoName: new FormControl('', [Validators.required]),
            objectEditDtoId: new FormControl(84876, [Validators.required]),
            objectEditDtoName: new FormControl('Richard', [Validators.required]),
            switch: new FormControl('', [Validators.required]),
            switchEdit: new FormControl(true, [Validators.required]),
            icon: new FormControl('fa fa-home', [Validators.required]),
            iconEdit: new FormControl('fa fa-user', [Validators.required]),
            iban: new FormControl('', [Validators.required]),
            ibanEdit: new FormControl('PK94ABPA0010054981800010', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            emailEdit: new FormControl('daudkhan316@gmail.com', [Validators.required]),
            date: new FormControl(new Date(), [Validators.required]),
            dateEdit: new FormControl(new Date(), [Validators.required]),
            color: new FormControl('', [Validators.required]),
            colorEdit: new FormControl('red-500', [Validators.required]),
        })

        this.nameFormField = new MvsFormFieldDto();
        this.nameFormField.id = 'name';

        this.nameEditFormField = new MvsFormFieldDto();
        this.nameEditFormField.id = 'nameEdit';

        this.radioFormField = new MvsFormFieldDto();
        this.radioFormField.id = 'radio';
        this.radioFormField.valueList = this.valueList;

        this.radioEditFormField = new MvsFormFieldDto();
        this.radioEditFormField.id = 'radioEdit';
        this.radioEditFormField.valueList = this.valueList;

        this.objectTypeFormField = new MvsFormFieldDto();
        this.objectTypeFormField.id = 'objectDtoId';
        this.objectTypeFormField.dataType = 'cr.Customer';


        this.objectTypeEditFormField = new MvsFormFieldDto();
        this.objectTypeEditFormField.id = 'objectEditDtoId';
        this.objectTypeEditFormField.dataType = 'cr.Customer';

        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    selectOption(id: string) {
        this.selectedOption = id;
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
