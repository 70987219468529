import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {
    AcquisitionCommissionAssignmentPage
} from "./page/overview/acquisition-commission-assignment/acquisition-commission-assignment.page";
import {
    TemplateAcquisitionCommissionPage
} from "./page/overview/template-acquisition-commission/template-acquisition-commission.page";
import {
    AgentCancellationBufferAssignmentPage
} from "./page/overview/agent-cancellation-buffer-assignment-page/agent-cancellation-buffer-assignment.page";

@NgModule({
    declarations: [
        AcquisitionCommissionAssignmentPage,
        TemplateAcquisitionCommissionPage,
        AgentCancellationBufferAssignmentPage,
    ],
    exports: [
        AcquisitionCommissionAssignmentPage,
        TemplateAcquisitionCommissionPage,
        AgentCancellationBufferAssignmentPage,
    ],
    imports: [
        CoreModule
    ]
})
export class AcModule { }
