<ng-container *ngIf="initialized">

    <p-contextMenu
            [global]="false"
            #contextMenu
            [model]="itemsForVariable"
            [target]="mainContent"
    ></p-contextMenu>

    <div #mainContent class="flex justify-content-end gap-2 py-2">

        <p-progressSpinner *ngIf="busy"
                           styleClass="w-2rem h-2rem"
                           animationDuration=".5s">
        </p-progressSpinner>

<!--        <p-button-->
<!--                *ngIf="selectedTestCase && selectedTestCase.dto"-->
<!--                styleClass="p-button-outlined"-->
<!--                (click)="generateDocument()"-->
<!--                icon="fa-sharp fa-solid fa-flask-vial"-->
<!--                iconPos="right"-->
<!--                [label]="selectedTestCase.dto['name']">-->
<!--        </p-button>-->

        <p-splitButton
                (onClick)="handleSave()"
                icon="fa-light fa-floppy-disk-circle-arrow-right"
                label="Speichern"
                [model]="toolbarButtonList" styleClass="p-button-outlined"
        />

        <!--        <p-button-->
        <!--                (click)="showHistory()"-->
        <!--                label="Show history"-->
        <!--        ></p-button>-->

        <!--        <p-button-->
        <!--                (click)="navigateToFreeMarker()"-->
        <!--                label="Freemarker Doku"-->
        <!--        ></p-button>-->

        <!--        <p-button-->
        <!--                (click)="switchEditor()"-->
        <!--                label="Editor wechseln"-->
        <!--        ></p-button>-->

        <!--        <p-button-->
        <!--                (click)="handleSave($event)"-->
        <!--                label="Speichern"-->
        <!--                icon="fa-regular fa-floppy-disk-circle-arrow-right"-->
        <!--        ></p-button>-->
    </div>

    <!--    <textarea [cols]="80" [rows]="40" *ngIf="editor == 'textArea'" [(ngModel)]="editorContent"></textarea>-->

    <code-mirror-editor
            class="mt-3"
            *ngIf="editor == 'textArea'"
            [editorContent]="editorContent"
            (contentChanged)="handleCodeMirrorContentChange($event)"
    >
    </code-mirror-editor>

    <editor
            *ngIf="editor == 'tinyMCE'"
            #tinyEditor
            id="templateEngineTinyEditor"
            (onDrop)="handleOnDrop(); contextMenu.toggle($event)"
            [init]="tinyMceConfig"
            [(ngModel)]="editorContent"
    ></editor>


    <mvs-te-variable-dialog
            *ngIf="openVariableDialog"
            [variableProviderService]="variableProviderService"
            [alias]="alias"
            [attributeName]="attributeName"
            [contentProviderId]="contentProviderId"
            [openDialog]="openVariableDialog"
            (onConfirmSelectVariable)="handleVariableSelect($event)"
            (onCloseDialog)="handleCloseDialog($event)">
    </mvs-te-variable-dialog>

    <!--    <p-button (click)="showTableConditionDialog = true" icon="pi pi-external-link" label="Show"></p-button>-->

    <!--    <p-dialog [(visible)]="showTableConditionDialog"-->
    <!--              [modal]="true"-->
    <!--              *ngIf="showTableConditionDialog"-->
    <!--              [style]="{width: '50vw', height:'80vw'}"-->
    <!--              [draggable]="false"-->
    <!--              [resizable]="false">-->

    <!--        <te-table-info-conditions-->
    <!--                [attributesList]="tableAttributes"-->
    <!--                [attributePath]="tableAttributePath"-->
    <!--                [condition]="tableCondition"-->
    <!--                (handleTableCondition)="handleTableCondition($event)">-->
    <!--        </te-table-info-conditions>-->

    <!--    </p-dialog>-->

    <mvs-sidebar
            *ngIf="showTableConditionDialog"
            sidebarPosition="right-40%"
            [isSidebarOpen]="showTableConditionDialog"
            (check)="showTableConditionDialog = $event">

        <ng-template mvs-sidebar-directive>

            <div class="px-3">
                <te-table-info-conditions
                        [tableAttributes]="tableAttributes"
                        [attributePath]="tableAttributePath"
                        [tableName]="tableName"
                        [condition]="tableCondition"
                        [parentObjectTypeName]="tableObjectTypeName"
                        (handleTableCondition)="handleTableCondition($event)">
                </te-table-info-conditions>

            </div>
        </ng-template>

    </mvs-sidebar>

    <mvs-sidebar
            *ngIf="showHighlightConditionDialog"
            sidebarPosition="right-40%"
            [isSidebarOpen]="showHighlightConditionDialog"
            (check)="showHighlightConditionDialog = $event">

        <ng-template mvs-sidebar-directive>

            <div class="px-3">
                <te-table-info-conditions
                        [attributePath]="tableAttributePath"
                        [tableName]="tableName"
                        [condition]="inlineConditions.condition"
                        [parentObjectTypeName]="inlineConditions.info"
                        [conditionType]="'inline'"
                        (handleTableCondition)="handleInlineCondition($event)"
                >
                </te-table-info-conditions>

            </div>
        </ng-template>

    </mvs-sidebar>



<!--    <p-sidebar [(visible)]="showTestResultSidebar" appendTo="body" position="right" [style]="{'width':'40%'}">-->
<!--        <h3>Test Result</h3>-->

<!--        <div class="col-12" *ngIf="occurredException">-->
<!--            <p-message severity="error" [text]="occurredException"></p-message>-->

<!--            <p-message *ngIf="occurredExceptionDetails" severity="error" [text]="occurredExceptionDetails"></p-message>-->
<!--        </div>-->

<!--        <div class="col-12" *ngIf="templateGenerateResponse">-->

<!--            <div style="border:1px solid silver;padding:10px;width:100%" [innerHTML]="trustedGenerateResponseHtml"-->
<!--                 *ngIf="trustedGenerateResponseHtml">-->
<!--            </div>-->

<!--        </div>-->


<!--    </p-sidebar>-->


</ng-container>
