import {UiPageTypeEnum} from "../enum/ui-page-type.enum";

export class UiPageVisitRequestDto {

    public pageRoute: string;
    public defaultLabel: string;

    public pageType: UiPageTypeEnum;

    public objectTypeAlias: string;
    public objectTypeId: number;

    public objectId: number;


}