import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DtoList, MvsCrudService} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common/lib/dto/dto.detail";
import {TicketGroupFilterAgentFavoriteDto} from "../../dto/ticket-group-filter-agent-favorite.dto";

@Injectable({
  providedIn: 'root'
})
export class TicketGroupFilterAgentFavoriteService extends MvsCrudService {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketGroupFilterAgentFavorites')
  }

  public getFavorites(): Observable<DtoList<TicketGroupFilterAgentFavoriteDto>> {
    return <Observable<DtoList<TicketGroupFilterAgentFavoriteDto>>><unknown>this.getInternal(this.apiUrl + "/me/list");
  }

  public addFavorite(ticketGroupFilterId: number) : Observable<any> {
    return this.getInternal(this.apiUrl + `/me/add/${ticketGroupFilterId}`);
  }

  public removeFavorite(ticketGroupFilterId: number) : Observable<any> {
    return this.getInternal(this.apiUrl + `/me/remove/${ticketGroupFilterId}`);
  }


}