import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerInteractionService} from "../../../../service/api/customer-interaction.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {CustomerInteractionDto} from "../../../../dto/customer-interaction.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-interactions-navigation',
    templateUrl: './cr-customer-interaction-navigation.component.html',
})
export class CrCustomerInteractionNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    customerInteractionDtoList: DtoList;
    customerInteractionDocumentsDtoList: DtoList;
    customerFormDtoList: DtoList;


    customerInteractionWidget: WidgetData;
    customerInteractionDocumentsWidget: WidgetData;
    customerFormWidget: WidgetData;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerInteractionService: CustomerInteractionService,
                protected configService: MvsConfigService,
                ) {
        super(coreService, confirmationService, messageService, configService,navigationService);
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getInteractionData();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

    getInteractionData() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
            )

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customerInteractionDocuments",
                ObjectRequestRelation.createList(
                    "cr.CustomerInteractionDocument",
                    "customerInteraction",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createSimpleAttributeNode('customerForm')
        );


        this.customerInteractionService.list(dtoRequest).subscribe(res => {

            this.customerInteractionDtoList = new DtoList();
            this.customerInteractionDtoList.entries = res.entries;
            this.customerInteractionDtoList.form = res.form;

            this.customerFormDtoList = new DtoList();
            this.customerFormDtoList.form = res.form.subForms['customerFormDto'];
            this.customerFormDtoList.entries = [];
            for (let item of this.customerInteractionDtoList.entries) {
                this.customerFormDtoList.entries.push(item['customerFormDto']);
            }

            this.customerInteractionDocumentsDtoList = new DtoList();
            this.customerInteractionDocumentsDtoList.form = res.form.subForms['customerInteractionDocuments'];
            this.customerInteractionDocumentsDtoList.entries = [];
            for (let item of this.customerInteractionDtoList.entries) {
                const documents = item['customerInteractionDocumentsDtoList'];

                if (documents && documents.length) {
                    for (let document of documents) {
                        this.customerInteractionDocumentsDtoList.entries.push(document);
                    }
                }
            }

            this.refreshWidgets();

        })



    }

    refreshWidgets() {

        this.customerInteractionWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.interaction.navigation.show.customer.interactions',
            'Customer Interactions',
            'list',
            'transient',
            'transient',
            'cr.CustomerInteraction',
            MvsCrudService.transformDtoListOutToIn(this.customerInteractionDtoList)
        );

        this.customerFormWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.interaction.navigation.show.customer.form',
            'Customer Form',
            'list',
            'transient',
            'transient',
            'cr.CustomerForm',
            MvsCrudService.transformDtoListOutToIn(this.customerFormDtoList)
        );

        this.customerInteractionDocumentsWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.interaction.navigation.show.customer.documents.interactions',
            'Customer Interaction Documents',
            'list',
            'transient',
            'transient',
            'cr.CustomerInteractionDocument',
            MvsCrudService.transformDtoListOutToIn(this.customerInteractionDocumentsDtoList)
        );

    }



}
