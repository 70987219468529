import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, distinctUntilChanged, interval, Observable, of, timer} from "rxjs";
import {CoreApiResponseDto, MvsCrudService, ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectUserAccessDto} from "../../dto/object-user-access.dto";
import {map, switchMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ObjectUserAccessService extends MvsCrudService {

    private objectIdentifiers = new Set<string>();
    private objectDataMap = new Map<string, ObjectUserAccessDto[]>();
    private REFRESH_INTERVAL = 10000;

    // Observable for components to subscribe to
    private dataSubject = new BehaviorSubject<Map<string, any>>(new Map());

    private batchTimeout: any = null;
    private refreshTimer: any = null;
    private pendingIdentifiers = new Set<string>();

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/objectUserAccesss');
        this.startRefreshTimer();
    }


    actionRead(
        objectId: number,
        objectTypeId?: number,
        objectAliasId?: string
    ): Observable<CoreApiResponseDto> {
        const url = `${this.apiUrl}/action/read`;
        let params = new HttpParams().set('objectId', objectId.toString());
        if (objectTypeId) params = params.set('objectTypeId', objectTypeId.toString());
        if (objectAliasId) params = params.set('objectAliasId', objectAliasId);

        return this.http.get<CoreApiResponseDto>(url, {params});
    }

    actionWrite(
        objectId: number,
        objectTypeId?: number,
        objectAliasId?: string
    ): Observable<CoreApiResponseDto> {
        const url = `${this.apiUrl}/action/write`;
        let params = new HttpParams().set('objectId', objectId.toString());
        if (objectTypeId) params = params.set('objectTypeId', objectTypeId.toString());
        if (objectAliasId) params = params.set('objectAliasId', objectAliasId);

        return this.http.get<CoreApiResponseDto>(url, {params});
    }

    actionBye(
        objectId: number,
        objectTypeId?: number,
        objectAliasId?: string
    ): Observable<CoreApiResponseDto> {
        const url = `${this.apiUrl}/action/bye`;
        let params = new HttpParams().set('objectId', objectId.toString());
        if (objectTypeId) params = params.set('objectTypeId', objectTypeId.toString());
        if (objectAliasId) params = params.set('objectAliasId', objectAliasId);

        return this.http.get<CoreApiResponseDto>(url, {params});
    }

    listObject(objectTypeAlias: string, objectId: number): Observable<ObjectUserAccessDto[]> {
        const endPoint = `/list/object?objectAliasId=${objectTypeAlias}&objectId=${objectId}`;

        return this.http.get<ObjectUserAccessDto[]>(this.apiUrl + endPoint).pipe(
        );
    }

    listObjects(objectString: string): Observable<ObjectUserAccessDto[]> {
        const endPoint = `/list/objects?objectString=${objectString}`;
        return this.http.get<ObjectUserAccessDto[]>(this.apiUrl + endPoint).pipe(
        );
    }

    refreshActivity() {
        if (this.objectIdentifiers.size === 0) {
            return of(new Map<string, ObjectUserAccessDto[]>());
        }

        const objectString = Array.from(this.objectIdentifiers).join(';');

        return this.listObjects(objectString).pipe(
            map((data: any) => {
                const updatedDataMap = new Map<string, ObjectUserAccessDto[]>();

                if (data && data.entries) {
                    data.entries.forEach(item => {
                        const key = `${item.objectTypeDtoAlias}:${item.objectId}`;
                        if (!updatedDataMap.has(key)) {
                            updatedDataMap.set(key, []);
                        }
                        const itemList = updatedDataMap.get(key);
                        if (!itemList.some(existingItem => existingItem.id === item.id)) {
                            itemList.push(item);
                        }
                    });
                }

                // Return the updated data map
                return updatedDataMap;
            })
        );
    }

    register(objectIdentifier: ObjectIdentifier) {
        const key = this.getKey(objectIdentifier);
        this.objectIdentifiers.add(key);
        this.pendingIdentifiers.add(key);

        if (this.batchTimeout) {
            clearTimeout(this.batchTimeout);
        }
        this.batchTimeout = setTimeout(() => {
            this.processBatch();
        }, 2000);
    }

    private processBatch() {
        if (this.pendingIdentifiers.size === 0) return;

        const objectString = Array.from(this.pendingIdentifiers).join(';');
        this.pendingIdentifiers.clear();

        this.listObjects(objectString).subscribe((data) => {
            this.updateObjectDataMap(data);
            this.dataSubject.next(this.objectDataMap);
            this.resetRefreshTimer();
        });
    }

    private resetRefreshTimer() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        this.startRefreshTimer();
    }

    private startRefreshTimer() {
        this.refreshTimer = setInterval(() => {
            this.refreshActivity().subscribe((data) => {
                this.objectDataMap = data;
                this.dataSubject.next(this.objectDataMap);
            });
        }, this.REFRESH_INTERVAL);
    }

    private updateObjectDataMap(data: any): Map<string, ObjectUserAccessDto[]> {
        const updatedDataMap = new Map<string, ObjectUserAccessDto[]>();

        if (data && data.entries) {
            data.entries.forEach((item) => {
                const key = `${item.objectTypeDtoAlias}:${item.objectId}`;
                if (!updatedDataMap.has(key)) {
                    updatedDataMap.set(key, []);
                }
                const itemList = updatedDataMap.get(key);
                if (!itemList.some((existingItem) => existingItem.id === item.id)) {
                    itemList.push(item);
                }
            });
        }

        this.objectDataMap = updatedDataMap;
        return updatedDataMap;
    }

    unregister(objectIdentifier: ObjectIdentifier) {
        const key = this.getKey(objectIdentifier);
        this.objectIdentifiers.delete(key);
    }

    getData(): Observable<Map<string, any>> {
        return this.dataSubject.asObservable();
    }

    getKey(objectIdentifier: ObjectIdentifier): string {
        return `${objectIdentifier.objectType}:${objectIdentifier.objectId}`;
    }

    setIntervalTime(intervalTime: number) {
        this.REFRESH_INTERVAL = intervalTime;
    }


}