import {Component, OnInit} from '@angular/core';
import {ActivityTestService} from "../../../services/activity-test.service";
import {MvsCoreService, ObjectRequestList, PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {
    BINDINGS, BINDINGS_DOCUMENT_DTO,
    FORM_DEFAULTS, WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_DTO, WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_LIST_DTO,
    WF_PROCESS_TYPE_STEP_ACTIVITY_DTO,
    WF_PROCESS_TYPE_STEP_ACTIVITY_DTO_UPDATE,
    WF_PROCESS_TYPE_STEP_ACTIVITY_SEARCH_DTO,
    WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_DTO, WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_STATUS_DTO
} from "../../../services/data";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";

@Component({
    selector: 'mvs-test-activity',
    templateUrl: './test-workflow-activities.page.html',
    styleUrls: ['./test-workflow-activities.page.scss']
})
export class TestWorkflowActivitiesPage extends PageComponent implements OnInit {
    objectTypeDto: ObjectTypeDto | unknown = {id: 101, name: 'Test', alias: 'test.activityTest'};
    activeIndex = 0;

    // ['test.activityTest', {route: 'about:blank',service: ActivityTestService}],

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected activityTestService: ActivityTestService) {
        super(route, coreService);

    }

    ngOnInit(): void {

    }


    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_DTO;
    protected readonly BINDINGS = BINDINGS;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_DTO_UPDATE = WF_PROCESS_TYPE_STEP_ACTIVITY_DTO_UPDATE;
    protected readonly FORM_DEFAULTS = FORM_DEFAULTS;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_SEARCH_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_SEARCH_DTO;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_DTO;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_STATUS_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_STATUS_DTO;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_DTO;
    protected readonly BINDINGS_DOCUMENT_DTO = BINDINGS_DOCUMENT_DTO;
    protected readonly WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_LIST_DTO = WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_LIST_DTO;
}
