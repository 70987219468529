import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {Injectable, OnDestroy} from "@angular/core";
import {interval, Subject, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ObserverService implements OnDestroy{
    private activityMap: Map<string, { isActive: boolean; timestamp: number }> = new Map();
    refreshData = new Subject<ObjectIdentifier>();
    private checkInterval: Subscription;

    constructor() {
        this.checkInterval = interval(1000).subscribe(() => this.checkActivity());
    }

    addObject(identifier: ObjectIdentifier) {
        this.informActivity(identifier);
    }

    informActivity(objectIdentifier: ObjectIdentifier) {
        const key = this.getKey(objectIdentifier);
        const now = Date.now();

        this.activityMap.set(key, { isActive: true, timestamp: now });
    }

    private checkActivity() {
        const now = Date.now();
        this.activityMap.forEach((record, key) => {
            if (record.isActive && now - record.timestamp >= 4000) {
                const identifier =  this.getIdentifierFromKey(key);
                this.refreshData.next(identifier);

                record.isActive = false;
                record.timestamp = now;
            }
        });
    }

    private getKey(identifier: ObjectIdentifier): string {
        return `${identifier.objectType}:${identifier.objectId}`;
    }

    private getIdentifierFromKey(key: string): ObjectIdentifier {
        const keyPaths = key.split(':');
        return new ObjectIdentifier(keyPaths[0], +keyPaths[1]);
    }

    ngOnDestroy() {
        if (this.checkInterval) {
            this.checkInterval.unsubscribe();
        }
    }
}