import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";
import {LgConfigPagePage} from "./page/lg-config-page/lg-config-page.page";


@NgModule({
    declarations: [
        LgConfigPagePage
    ],
    exports: [
        LgConfigPagePage
    ],
    imports: [
        CoreModule,
        UiModule,
        BlockUIModule,
        ProgressSpinnerModule
    ]
})
export class LgModule implements DynamicModule {

    alias = 'lg';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return null;
    }

    getStatisticsComponent() {
        return null;
    }
}
