import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FilterService, MenuItem, MessageService, TreeDragDropService} from "primeng/api";
import {WfProcessStepDto} from "../../../../wf/dto/wf-process-step.dto";
import {
    DtoDetail,
    FilterCriteriaChangeEvent,
    MvsCoreService,
    ObjectRequestSimple,
    PagingDto
} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {FilterAttributeGroup} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {DataTypeHelper} from "@kvers/alpha-core-common";
import {FilterAttribute} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {GroupByUiTypeEnum} from "@kvers/alpha-core-common";
import {KpiHeaderDto} from "../../../../core/overview/dto/kpi-header.dto";
import {OverviewDto} from "../../../../core/overview/dto/overview.dto";
import {MvsConditionBuilderService, MvsFilterHelper} from "@kvers/alpha-ui";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {ProfileCheckResultDto} from "../../../../cr/service/dto/profile-check-result.dto";
import {ProfileCheckResultEntryDto} from "../../../../cr/service/dto/profile-check-result-entry.dto";
import {ProfileCheckCompletenessRangeDto} from "../../../../cr/dto/profile-check-completeness-range.dto";
import {CustomerAssessmentResultDto} from "../../../../cr/service/dto/customer-assessment-result.dto";
import {CustomerAssessmentResultGroupDto} from "../../../../cr/service/dto/customer-assessment-result-group.dto";
import {AssessmentResultRangeDto} from "../../../../cr/dto/assessment-result-range.dto";
import {
    CustomerAssessmentResultGroupEntryDto
} from "../../../../cr/service/dto/customer-assessment-result-group-entry.dto";
import {AssessmentGroupDto} from "../../../../cr/dto/assessment-group.dto";
import {TenantService} from "@kvers/alpha-auth";
import {AutoComplete} from "primeng/autocomplete";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'mvs-test-yasir',
    providers: [TreeDragDropService, MessageService],
    templateUrl: './test-yasir.page.html',
    styleUrls: ['./test-yasir.page.scss']
})
export class TestYasirPage implements OnInit, AfterViewInit {
    profileCheckData: ProfileCheckResultDto;
    contractHealthData: CustomerAssessmentResultDto;
    // contractGroupData: AssessmentGroupDto;
    // contractCheckData: CustomerAssessmentResultGroupEntryDto[];
    // stepItems: MenuItem[] & { runtimeSteps: DtoDetail }[];
    // stepItems= [] ;
    stepperActiveStep: number = 0;
    busy: boolean;
    sidebar: boolean = true;
    historyDetail: boolean = true;
    showStepName: boolean = true;
    showButton: number | null = null;
    tenantLogo?: string = undefined;

    widgetObject: WidgetData;
    objectIdentifier: ObjectIdentifier = new ObjectIdentifier('wf.WfProcess',74);
    // kpiCards: KpiHeaderDto[] = [
    //     { label: 'Total Documents', value: '100', otherInfo: 'Info1', otherInfoClass: 'class1', route: '/' },
    //     { label: 'Total Document Types', value: '10', otherInfo: 'Info2', otherInfoClass: 'class2', route: '/' },
    //     { label: 'Total Document Groups', value: '5', otherInfo: 'Info3', otherInfoClass: 'class3', route: '/' },
    //     { label: 'Total Assignment Types', value: '3', otherInfo: 'Info4', otherInfoClass: 'class4', route: '/' }
    // ];
    //
    overViewData: OverviewDto[] = [
        { label: 'Notification Overview', value: 100 },
        { label: 'Pending Notification', value: 10 },
        { label: 'Processed Notification', value: 5 },
        { label: 'Deleted Notification', value: 3 }
    ];
    pendingNotification: number = 5;
    processedNotification: number = 7;
    deletedNotification: number = 3;
    errorNotification: number = 2;
    users = [
        {id: 1, name: 'Marko', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 2, name: 'Salman', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 3, name: 'Ather', role: 1, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 4, name: 'Yasir', role: 0, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 5, name: 'Carlo', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 4, name: 'Daud', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 5, name: 'Haris', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        // ... more users
    ];
    widgetButtons = [
        {
            label: null,
            icon: 'fa-regular fa-file-lines bg-primary-100 text-primary p-2 border-round-sm line-height-2',
            display: true,
            action: 'default',
            tooltip: 'Comment History'
        }, {
            label: null,
            icon: 'fa-sharp fa-light fa-diagram-subtask',
            display: true,
            action: 'sub-task',
            tooltip: 'Sub Task',
            type: 'badge'

        }, {
            label: null,
            icon: 'fa-regular fa-paperclip-vertical',
            display: true,
            action: 'attachment',
            tooltip: 'Attachment',
            type: 'badge'
        },
        {
            label: null,
            icon: 'fa-regular fa-ticket',
            display: true,
            action: 'activePinTicket',
            tooltip: 'Active Ticket'
        },
        {
            label: null,
            icon: 'fa-regular fa-bell',
            display: true,
            action: 'notifications',
            tooltip: 'Notifications'
        },
        {
            label: null,
            icon: 'fa-regular fa-tags',
            display: true,
            action: 'tags',
            tooltip: 'Tags'
        },
        {
            label: null,
            icon: 'fa-regular fa-thumbtack text-700',
            display: true,
            action: 'pinTicket',
            tooltip: 'Pin Ticket'
        }

    ];
    dataWithIcons = [
        {
            icon: "pi pi-phone",
            heading: "Phone",
            value: "921354268",
            color: "text-green-500",
            backgroundColor: "bg-green-100"
        },
        {
            icon: "fas fa-envelope",
            heading: "Email",
            value: "asd@gmail.com",
            color: "text-blue-500",
            backgroundColor: "bg-blue-100"
        },
        {
            icon: "fas fa-home",
            heading: "Address",
            value: "1234 Street Name, City, Country",
            color: "text-purple-500",
            backgroundColor: "bg-purple-100"
        },
        {
            icon: "fas fa-birthday-cake",
            heading: "Birthday",
            value: "January 1, 1990",
            color: "text-red-500",
            backgroundColor: "bg-red-100"
        },
        {
            icon: "fas fa-user",
            heading: "Username",
            value: "user123",
            color: "text-yellow-600",
            backgroundColor: "bg-yellow-100"
        },
        {
            icon: "fas fa-globe",
            heading: "Website",
            value: "https://www.example.com",
            color: "text-cyan-500",
            backgroundColor: "bg-cyan-100"
        },
        {
            icon: "fas fa-building",
            heading: "Company",
            value: "Company Name Inc.",
            color: "text-orange-500",
            backgroundColor: "bg-orange-100"
        },
        {
            icon: "fas fa-graduation-cap",
            heading: "Education",
            value: "University Degree",
            color: "text-teal-500",
            backgroundColor: "bg-teal-100"
        }
];

    profileData = [
        { title: "ID", value: 62124 },
        { title: "name", value: "Carlo's Company" },
        { title: "shortName", value: "Carlo's Company" },
        { title: "partnerTypeDtoName", value: "Kreditinstitut" },
        { title: "Angelegt am", value: "06.07.2023 10:43:33" },
        { title: "Geändert von", value: "root" }
    ];
    selectableItems = [
        { label: 'A', title: 'Item 1', color: 'bg-purple-500'},
        { label: 'B', title: 'Item 2', color: 'bg-red-500'},
        { label: 'C', title: 'Item 3', color: 'bg-yellow-500'},
        { label: 'D', title: 'Item 4', color: 'bg-cyan-500' },
        { label: 'E', title: 'Item 5', color: 'bg-orange-500' }
    ];
    selectedItem = 0;
    tabItems: MenuItem[] | undefined;

    activeItem: MenuItem | undefined;

    customers: any[];
    representatives: any[];
    statuses: any[];
    loading: boolean = false;
    value!: number;
    color: string;
    themeMode: any[] = [
        { name: 'default', value: 1 },
        { name: 'light', value: 2 },
        { name: 'dark', value: 3 }
    ];
    topbarColors = [
        {name: 'lightblue', color: '#2E88FF'},
        {name: 'dark', color: '#363636'},
        {name: 'white', color: '#FDFEFF'},
        {name: 'blue', color: '#1565C0'},
        {name: 'deeppurple', color: '#4527A0'},
        {name: 'purple', color: '#6A1B9A'},
        {name: 'pink', color: '#AD1457'},
        {name: 'cyan', color: '#0097A7'},
        {name: 'teal', color: '#00796B'},
        {name: 'green', color: '#43A047'},
        {name: 'lightgreen', color: '#689F38'},
        {name: 'lime', color: '#AFB42B'},
        {name: 'yellow', color: '#FBC02D'},
        {name: 'amber', color: '#FFA000'},
        {name: 'orange', color: '#FB8C00'},
        {name: 'deeporange', color: '#D84315'},
        {name: 'brown', color: '#5D4037'},
        {name: 'grey', color: '#616161'},
        {name: 'bluegrey', color: '#546E7A'},
        {name: 'indigo', color: '#3F51B5'}
    ];



    searchText: string = '';
    filteredSuggestions: any[] = [];
    allSuggestions = [
        { label: 'fn:', value: 'First Name' },
        { label: 'cr:', value: 'Customer Name' },
        { label: 'ln:', value: 'Last Name' },
    ];

    searchResults: any[] = []; // Holds search results
    highlightPrefix: string = ''; // Store the actual prefix
    mockData = [
        { type: 'fn', name: 'John Doe', age: 30 },
        { type: 'fn', name: 'Jane Doe', age: 25 },
        { type: 'cr', name: 'Credit Score A', score: 750 },
        { type: 'cr', name: 'Credit Score B', score: 680 },
        { type: 'ln', name: 'Loan A', amount: 5000 },
        { type: 'ln', name: 'Loan B', amount: 10000 }
    ];

    @ViewChild('editableDiv', { static: false }) editableDiv!: ElementRef;
    constructor(protected conditionBuilderService: MvsConditionBuilderService,
                private filterService: FilterService,
                protected coreService: MvsCoreService,
                protected customerService: CustomerService,
                protected metaService: MetaService,
                private tenantService: TenantService,
                private sanitizer: DomSanitizer,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {

        this.tenantLogo = this.tenantService.getTenantInfo().logo;


        this.refreshComponent();
        this.tabItems = [
            { label: 'Home', icon: 'pi pi-fw pi-home' },
            { label: 'Calendar', icon: 'pi pi-fw pi-calendar' },
            { label: 'Edit', icon: 'pi pi-fw pi-pencil' },
            { label: 'Documentation', icon: 'pi pi-fw pi-file' },
            { label: 'Settings', icon: 'pi pi-fw pi-cog' }
        ];
        this.checkProfile();
        this.assessCustomer();
        this.activeItem = this.tabItems[0];
        // let runtimeSteps:DtoDetail[] = []
        // for (let i=0; i<this.stepItems.length; i++) {
        //     runtimeSteps[i]['status']=0;
        // }

        // this.stepItems = [
        //     {
        //         label: 'Select Type',
        //         // runtimeSteps: runtimeSteps[0]
        //
        //     },
        //     {
        //         label: 'Check if exist',
        //         // runtimeSteps: runtimeSteps[1]
        //     },
        //     {
        //         label: 'Select Person',
        //         // runtimeSteps: runtimeSteps[2]
        //     },
        //     {
        //         label: 'Create New Customer',
        //         // runtimeSteps: runtimeSteps[3]
        //     },
        //     {
        //         label: 'Create Customer RElation',
        //         // runtimeSteps: runtimeSteps[4]
        //     }
        // ];
        this.showWidget();
        this.loading = true;

        this.customers = [
            {
                id: 1,
                name: 'John Doe',
                country: { name: 'USA', code: 'us' },
                representative: { name: 'Amy Elsner', image: 'amyelsner.png' },
                status: 'new',
                verified: true
            },
            {
                id: 2,
                name: 'Jane Smith',
                country: { name: 'Germany', code: 'de' },
                representative: { name: 'Anna Fali', image: 'annafali.png' },
                status: 'qualified',
                verified: false
            },
            // Add more customers as needed
        ];

        this.representatives = [
            { name: 'Amy Elsner', image: 'amyelsner.png' },
            { name: 'Anna Fali', image: 'annafali.png' },
        ];

        this.statuses = [
            { label: 'New', value: 'new' },
            { label: 'Qualified', value: 'qualified' },
            { label: 'Unqualified', value: 'unqualified' },
            { label: 'Negotiation', value: 'negotiation' },
            { label: 'Renewal', value: 'renewal' },
            { label: 'Proposal', value: 'proposal' },
            // Add more statuses as needed
        ];

        this.loading = false;
    }


    text: string = '';
    highlightedText: SafeHtml = '';



//     latest
    @ViewChild('inputField') inputField!: ElementRef<HTMLInputElement>;
    @ViewChild('highlights') highlights!: ElementRef<HTMLDivElement>;

    // textContent: string = "This is a Demo Highlight Example";
    textContent: string = "";
    handleKeyDown(event: KeyboardEvent) {
        if (!this.showDropdown) return;

        const { key } = event;
        if (key === 'ArrowDown' || key === 'ArrowUp') {
            this.selectedIndex = (this.selectedIndex + (key === 'ArrowDown' ? 1 : -1) + this.filteredSuggestions.length) % this.filteredSuggestions.length;
            event.preventDefault();
        } else if (key === 'Enter' && this.selectedIndex >= 0) {
            this.insertSuggestion(this.filteredSuggestions[this.selectedIndex].label);
            this.selectedIndex = 0;
            event.preventDefault();
        }
    }
    // handleInput() {
    //     this.textContent = this.textContent.replace(/([\w!@#$%^&*()-+=~`<>?/{}[\]\\]+:)(\S)/g, '$1 $2');
    //     this.showDropdown = /\s:$/.test(this.textContent) || this.textContent.trim() === ':';
    //     this.filteredSuggestions = this.showDropdown ? this.allSuggestions : [];
    //
    //     if (this.highlights) {
    //         this.highlights.nativeElement.innerHTML = this.textContent
    //             .replace(/\b([^\s]+:)\s?/g, '<mark>$1</mark> ')
    //             .replace(/ /g, '&nbsp;');
    //     }
    // }

    // handleInput() {
    //     this.textContent = this.textContent.replace(/([^\s]+:)(\S)/g, '$1 $2'); // Ensure space after `:` for all characters
    //     this.showDropdown = /\s:$/.test(this.textContent) || this.textContent.trim() === ':';
    //     this.filteredSuggestions = this.showDropdown ? this.allSuggestions : [];
    //
    //     if (this.highlights) {
    //         this.highlights.nativeElement.innerHTML = this.textContent
    //             .replace(/([^\s]+:)\s?/g, '<mark>$1</mark> ') // Highlight words + special characters ending with `:`
    //             .replace(/ /g, '&nbsp;'); // Preserve spaces
    //     }
    // }


    handleInput() {
        this.textContent = this.textContent.replace(/([^\s]+:)(\S)/g, '$1 $2'); // Ensure space after `:` for all characters
        this.showDropdown = /\s:$/.test(this.textContent) || this.textContent.trim() === ':';
        this.filteredSuggestions = this.showDropdown ? this.allSuggestions : [];

        // Extract allowed labels as a Set for quick lookup
        const allowedLabels = new Set(this.allSuggestions.map(s => s.label));

        if (this.highlights) {
            this.highlights.nativeElement.innerHTML = this.textContent
                .split(' ') // Split text into words to process each separately
                .map(word => allowedLabels.has(word) ? `<mark>${word}</mark>` : word) // Highlight only valid shortcuts
                .join('&nbsp;'); // Preserve spaces between words
        }
    }



    // Insert selected suggestion into the input field
    insertSuggestion(suggestion: string) {
        this.textContent = this.textContent.replace(/:\s*$/, suggestion + ' ');
        this.showDropdown = false;
        this.handleInput();
    }




    //
    // handleInput() {
    //     this.textContent = this.textContent.replace(/(\w+:)(\S)/g, '$1 $2'); // Ensure space after `:`
    //     this.showDropdown = /\s:$/.test(this.textContent) || this.textContent.trim() === ':'; // Show dropdown for `:` alone or after space
    //     this.filteredSuggestions = this.showDropdown ? [...this.allSuggestions] : [];
    //
    //     if (this.highlights) {
    //         this.highlights.nativeElement.innerHTML = this.textContent
    //             .replace(/\b([\w!@#$%^&*()-+=~`<>?/{}[\]\\]+:)\s?/g, '<mark>$1</mark> ') // Highlight `:`
    //             .replace(/&nbsp;/g, ' ') // Preserve spaces
    //             .replace(/\s{2,}/g, ' '); // Remove extra spaces
    //     }
    // }





    // handleInput() {
    //     // Ensure space after `:` only if there isn't one already
    //     this.textContent = this.textContent.replace(/(\w+:)(\S)/g, '$1 $2').replace(/(\w+:)\s+/g, '$1 ');
    //
    //     // Show dropdown when `:` is the last typed character
    //     this.showDropdown = this.textContent.endsWith(':');
    //     if (this.showDropdown) {
    //         this.filteredSuggestions = [...this.allSuggestions]; // Show all options
    //     }
    //
    //     // Apply highlighting while keeping the space outside the `<mark>` tag
    //     if (this.highlights) {
    //         const highlightedText = this.textContent
    //             .replace(/\b([\w!@#$%^&*()-+=~`<>?/{}[\]\\]+:)\s?/g, '<mark>$1</mark> ') // Ensure exactly one space after `:`
    //             .replace(/&nbsp;/g, ' ') // Convert `&nbsp;` back to normal space when needed
    //             .replace(/\s{2,}/g, ' '); // Remove extra spaces if they exist
    //
    //         this.highlights.nativeElement.innerHTML = highlightedText;
    //     }
    // }




    // insertSuggestion(suggestion: string) {
    //     this.textContent = this.textContent.replace(/:\s*$/, suggestion + ' '); // Replace `:` with selected suggestion
    //     this.showDropdown = false;
    //     this.handleInput(); // Update highlights
    // }




    // applyHighlights(text: string): string {
    //     return text
    //         .replace(/\b[\w!@#$%^&*()-+=~`<>?/{}[\]\\]+:\s?/g, '<mark>$&</mark>') // Highlight words ending with `:`
    //         .replace(/ /g, '&nbsp;'); // Preserve spaces in the `.highlights` div
    // }

    // handleInput() {
    //     // Ensure space after `:` only if there isn't one already
    //     this.textContent = this.textContent.replace(/(\w+:)(\S)/g, '$1 $2').replace(/(\w+:)\s+/g, '$1 ');
    //
    //     // Apply highlighting while keeping the space outside the `<mark>` tag
    //     if (this.highlights) {
    //         const highlightedText = this.textContent
    //             .replace(/\b([\w!@#$%^&*()-+=~`<>?/{}[\]\\]+:)\s?/g, '<mark>$1</mark> ') // Ensure exactly one space after `:`
    //             .replace(/&nbsp;/g, ' ') // Convert `&nbsp;` back to normal space when needed
    //             .replace(/\s{2,}/g, ' '); // Remove extra spaces if they exist
    //
    //         this.highlights.nativeElement.innerHTML = highlightedText;
    //     }
    // }



    togglePerspective() {
        const container = this.inputField.nativeElement.closest('.container');
        if (container) {
            container.classList.toggle('perspective');
        }
    }
//




//     new



    @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
    @ViewChild('backdrop') backdrop!: ElementRef<HTMLDivElement>;
    // @ViewChild('highlights') highlights!: ElementRef<HTMLDivElement>;

    // textContent: string = `This demo shows how to highlight bits of text within a textarea. Alright, that's a lie. You can't actually render markup inside a textarea. However, you can fake it by carefully positioning a div behind the textarea and adding your highlight markup there.`;


    // applyHighlights(text: string): string {
    //     text = text
    //         .replace(/\n$/g, '\n\n') // Preserve newline formatting
    //         .replace(/[A-Z][a-z]+/g, '<mark>$&</mark>'); // Highlight capitalized words
    //
    //     return text;
    // }

    // handleInput() {
    //     if (this.highlights && this.textarea) {
    //         const highlightedText = this.applyHighlights(this.textContent);
    //         this.highlights.nativeElement.innerHTML = highlightedText;
    //     }
    // }

    handleScroll() {
        if (this.textarea && this.backdrop) {
            this.renderer.setProperty(this.backdrop.nativeElement, 'scrollTop', this.textarea.nativeElement.scrollTop);
            this.renderer.setProperty(this.backdrop.nativeElement, 'scrollLeft', this.textarea.nativeElement.scrollLeft);
        }
    }

    // togglePerspective() {
    //     const container = this.textarea.nativeElement.closest('.container');
    //     if (container) {
    //         container.classList.toggle('perspective');
    //     }
    // }






//




updateHighlightedText(event: Event): void {
        const inputText = (event.target as HTMLDivElement).innerText;
        const highlighted = inputText.replace(/(cr:\S*)/g, '<mark>$1</mark>');
        this.highlightedText = this.sanitizer.bypassSecurityTrustHtml(highlighted);
    }



    searchString: string = ''; // User input
    showDropdown: boolean = false; // Controls visibility of the dropdown
    selectedIndex: number = -1; // Tracks selected item in dropdown



    onSearch(event: KeyboardEvent) {
        const match = this.searchString.match(/^(fn|cr|ln):/);

        if (match) {
            this.highlightPrefix = match[0]; // Store detected shortkey
            this.searchString = ''; // Clear input field so the user can type freely
            this.showDropdown = false; // Hide dropdown after selection
            this.searchResults = []; // Reset results until the user types further
        } else {
            this.showDropdown = this.searchString.trim() === ':'; // Show dropdown when ":" is typed
            this.filteredSuggestions = this.showDropdown ? [...this.allSuggestions] : [];
        }

        this.updateSearchResults(); // Trigger search filtering
    }

    updateSearchResults() {
        const query = this.searchString.trim().toLowerCase();
        const prefixMatch = this.highlightPrefix.match(/^(fn|cr|ln):/);

        if (prefixMatch && query.length > 0) {
            const type = prefixMatch[1]; // Get selected shortkey type (`fn`, `cr`, `ln`)

            // Filter results based on type and input query
            this.searchResults = this.mockData.filter(item =>
                item.type === type &&
                Object.values(item).some(val =>
                    typeof val === 'string' && val.toLowerCase().includes(query)
                )
            );
        } else {
            this.searchResults = []; // Show nothing until input is provided after shortkey
        }
    }

    onSelectSuggestion(event: any) {
        if (event && event.label) {
            this.highlightPrefix = event.label.substring(1) + ':'; // Move shortkey to input group
            this.searchString = ''; // Reset input field for user to type
            this.searchResults = []; // Clear previous results
        }
    }


    onKeydown(event: KeyboardEvent) {
        if (this.showDropdown && this.filteredSuggestions.length > 0) {
            if (event.key === 'ArrowDown') {
                if (this.selectedIndex < this.filteredSuggestions.length - 1) {
                    this.selectedIndex++; // Move highlight down
                }
                event.preventDefault();
            } else if (event.key === 'ArrowUp') {
                if (this.selectedIndex > 0) {
                    this.selectedIndex--; // Move highlight up
                }
                event.preventDefault();
            } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
                this.selectShortkey(this.filteredSuggestions[this.selectedIndex]);
                this.selectedIndex = -1; // Reset selection after choosing
                this.showDropdown = false;
                event.preventDefault();
            }
        }

        // **IMPORTANT: Do NOT reset selectedIndex on key release**
        // This ensures the highlight remains even after the key is unpressed.

        // Remove shortkey when Backspace is pressed in an empty input field
        if (event.key === 'Backspace' && this.searchString === '' && this.highlightPrefix) {
            this.highlightPrefix = ''; // Clear the shortkey display
            this.showDropdown = false; // Hide dropdown
            // this.selectedIndex = -1; // Reset selection
            event.preventDefault();
        }
    }







// Function to select a shortkey from dropdown
    selectShortkey(option: any) {
        this.highlightPrefix = option.label; // Move to input group
        this.searchString = ''; // Clear input field
        this.showDropdown = false; // Hide dropdown
    }

// Clears shortkey and input
    clearInput() {
        this.searchString = '';
        this.highlightPrefix = '';
    }

    showWidget() {
        this.widgetObject = new WidgetData();
        this.widgetObject.idAlias = "test.haris.form.test";
        this.widgetObject.name = "Customer";
        this.widgetObject.uiComponent = "object";
        this.widgetObject.dataProvider = "list";
        this.widgetObject.dataSource = "entity";
        this.widgetObject.dataProviderObject = "cr.Customer";
        this.widgetObject.dataProviderListRequest = new ObjectRequestList(
            true,
            [],
            []
        );
        this.widgetObject.setParamValue("size", "S");
        this.widgetObject.setParamValue("objectId", "84876");

    }

    onComponentCompleted(step: number) {
        // this.stepItems[this.stepperActiveStep].runtimeSteps['status'] = 1;
        this.stepperActiveStep = step;
    }

    newFilterCriteria: FilterCriteria[] | undefined;

    onShowViaAlias() {

        this.conditionBuilderService.showConditionBuilderEntityDialog(
            this.newFilterCriteria,
            null,
            "cr.Customer",
            false,
            (filterCriteria: FilterCriteria[]) => {
                alert("save was pressed");

                return filterCriteria.length >= 2;

            },
            (filterCriteria: FilterCriteria[]) => {

                if (filterCriteria && filterCriteria.length >= 1) {
                    return confirm("Are you sure?");

                } else {
                    return true;
                }

            },
            (filterCriteria: FilterCriteria[]) => {

                if (filterCriteria && filterCriteria.length >= 1) {
                    return confirm("Are you sure You want to Delete?");

                } else {
                    return true;
                }

            }
        );

    }

    onShowViaId() {

        this.conditionBuilderService.showConditionBuilderEntityDialog(
            this.newFilterCriteria,
            3001,
            null,
            false
        );

    }

    filterCriteria: FilterCriteria[] | undefined;
    filterAttributeGroups: FilterAttributeGroup[] | undefined;
    readOnly: boolean | undefined;
    initialized: boolean;

    refreshComponent() {


        let observableMetaData: Observable<MetaDataEntityDto> = this.metaService.getByAlias('cr.Customer', true, true, false);


        observableMetaData.subscribe(res => {

            const simpleAttributes: any = res.attributes;
            const joins = res.joins.filter((entry: any) => entry.joinRelationEnum === MetaDataJoinRelationEnum.manyToOne);

            const joinedAttributes = simpleAttributes.concat(joins);

            this.filterAttributeGroups = MvsFilterHelper.convertToAttributeGroup(joinedAttributes);
            this.initialized = true;

        });

    }

    ticketTypes = [
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        // Add more items
    ];

    maxVisibleTickets = 0;
    showMoreButton = false;
    showAll = false;

    @ViewChild('container', { static: false }) container!: ElementRef;
    @ViewChildren('ticketElement') ticketElements!: QueryList<ElementRef>;

    ngAfterViewInit() {
        this.calculateVisibleTickets();
        this.handleInput();
    }

    calculateVisibleTickets() {
        const containerWidth = this.container.nativeElement.offsetWidth;
        let currentWidth = 0;

        this.ticketElements.forEach((element, index) => {
            const ticketWidth = element.nativeElement.offsetWidth;
            if (currentWidth + ticketWidth <= containerWidth) {
                currentWidth += ticketWidth;
                this.maxVisibleTickets = index + 1;
            }
        });

        this.showMoreButton = this.maxVisibleTickets < this.ticketTypes.length;
    }
    checkProfile() {
        this.customerService.checkProfile(5637).subscribe(res => {
            this.profileCheckData = res;
            console.log(res);
        });
    }

    assessCustomer() {
        this.customerService.assessCustomer(5637).subscribe(res => {
            this.contractHealthData = res;
            console.log(res);
        });
    }

    handleGetData() {

        const customerService = this.coreService.getCrudService<CustomerService>("cr.Customer");


        customerService.list(
            ObjectRequestList.createSimpleRequestList(true, ObjectRequestSimple.create(true, false, 1, "cr.CustomerType"),
                [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [], PagingDto.createSize(1))).subscribe(value => {

        });


         }


    toggleShowMore() {
        this.showAll = !this.showAll;
        this.maxVisibleTickets = this.showAll ? this.ticketTypes.length : this.maxVisibleTickets;
    }

    handleFilter(event: any) {
        console.log(event);
    }

    handleFilterChange(event: FilterCriteriaChangeEvent) {
        console.log(event);
    }

    protected readonly GroupByUiType = GroupByUiTypeEnum;
}
