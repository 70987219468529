import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerService} from "../../service/api/customer.service";
import {CustomerDto} from "../../dto/customer.dto";
import {ObjectIdentifier, WidgetHelperButton, EntityStatusEnum} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";

@Component({
    selector: 'cr-customer-inline-component',
    templateUrl: './cr-customer-inline-component.html',
})
export class CrCustomerInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    icon: string;
    dto: CustomerDto;
    toolbarButtons: WidgetHelperButton[] = [
        {
            label: 'Open in background',
            icon: 'fa-regular fa-send-backward',
            display: true,
            action: 'background',
            tooltip: 'Open in background'
        },
        {
            label: 'Open in new window',
            icon: 'fa-regular fa-arrow-up-right-from-square',
            display: true,
            action: 'newWindow',
            tooltip: 'Open in new window'
        }
    ];

    protected readonly EntityStatusEnum = EntityStatusEnum;
    initialized: boolean;
    busy: boolean;

    constructor(protected customerService: CustomerService, protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit() {
        this.refreshComponent();
    }

    refreshComponent() {
        this.customerService.get(this.objectIdentifier.objectId).subscribe((res: CustomerDto) => {
            this.dto = res;
            this.initialized = true;
        });



        this.icon = this.customerService.getObjectIcon();
    }

    handleButtonClick(event: WidgetHelperButton) {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        if (event.action == 'background') {
            this.navigationService.addOverlay(navigationEntry)
        } else if (event.action == 'newWindow') {
            this.navigationService.handleObjectNavigation(this.objectIdentifier,null,{openNewTab:true});
        }
    }

    handleClick() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.addOverlay(navigationEntry)
    }

    ngOnChanges(changes: SimpleChanges) {

    }
}
