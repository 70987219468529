import {ImImportRequestActivityEnum} from "../enum/im-import-request-activity.enum";
import {ImRequestThreadingDto} from "../logic/im-request-threading.dto";

export class ImImportRequestDto {

    public importService: string;
    public importTypeId: number;
    public importSubTypeId: number;
    public importActionId: number;
    public externalSystemId: number;
    public activity: ImImportRequestActivityEnum;
    public description: string;
    public requestThreading: ImRequestThreadingDto;
    public onlyReportErrorRecords: boolean;

}
