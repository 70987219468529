import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeDto} from "./wf-process-type.dto";
import {WfProcessTypeStepDto} from "./wf-process-type-step.dto";
import {WfProcessStepStatusEnum} from "../enum/wf-process-step-status.enum";
import {WfProcessStepActivityDto} from "./wf-process-step-activity.dto";
import {AgentPoolDto} from "../../am/dto/agent-pool.dto";
import {AgentDto} from "../../am/dto/agent.dto";

export class WfProcessStepDto extends DtoDetail {

    public processDto?: WfProcessTypeDto;
    public processDtoId: number;
    public processDtoName: string;

    public processTypeStepDto?: WfProcessTypeStepDto;
    public processTypeStepDtoId: number;
    public processTypeStepDtoName: string;

    public status: WfProcessStepStatusEnum;

    public assignmentAgentPoolDto?: AgentPoolDto;
    public assignmentAgentPoolDtoId: number;
    public assignmentAgentPoolDtoName: string;

    public assignmentAgentDto?: AgentDto;
    public assignmentAgentDtoId: number;
    public assignmentAgentDtoName: string;

    public activities?: WfProcessStepActivityDto[];


}