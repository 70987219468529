<div *ngIf="initialized">
    <mvs-config-header header="Datenmodellerweiterungen" icon="fa-solid fa-puzzle-piece">
    </mvs-config-header>


    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Namensräume" leftIcon="fa-light fa-diagram-project"></p-tabPanel>
        <p-tabPanel header="Felder" leftIcon="fa-light fa-square"></p-tabPanel>
        <p-tabPanel header="Feldtypen" leftIcon="fa-light fa-cubes"></p-tabPanel>
        <p-tabPanel header="Wertelisten" leftIcon="fa-light fa-list-ul"></p-tabPanel>
        <p-tabPanel header="Entitäten und Attribute" leftIcon="fa-light fa-database"></p-tabPanel>
        <p-tabPanel header="System" leftIcon="fa-light fa-wrench"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-6">
                <mvs-widget
                        *ngIf="widgetFieldNamespace"
                        [widgetData]="widgetFieldNamespace"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-8">
                <mvs-widget
                        *ngIf="widgetField"
                        [widgetData]="widgetField"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetFieldType"
                        [widgetData]="widgetFieldType"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==3">

        <div class="grid">
            <div class="col-5">
                <mvs-widget
                        *ngIf="widgetFieldValueList"
                        [widgetData]="widgetFieldValueList"
                        (onObjectSelect)="handleFieldValueListSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-7">

                <mvs-widget
                        *ngIf="widgetFieldValueListEntry"
                        [widgetData]="widgetFieldValueListEntry"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==4">

        <div class="grid">
            <div class="col-5">
                <mvs-widget
                        *ngIf="widgetObjectType"
                        [widgetData]="widgetObjectType"
                        (onObjectSelect)="handleObjectTypeSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-7">

                <mvs-widget
                        *ngIf="widgetObjectTypeAttribute"
                        [widgetData]="widgetObjectTypeAttribute"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==5">

        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="widgetSystemConfig"
                        [widgetData]="widgetSystemConfig"
                ></mvs-widget>

            </div>
        </div>

    </ng-container>


</div>