@if (initialized) {
    @if (uiMode == 'side') {
        <hc-integration-adapter-side-component
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></hc-integration-adapter-side-component>
    } @else {

        <hc-integration-adapter-full-component
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></hc-integration-adapter-full-component>
    }

}
