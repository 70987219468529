import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {WfProcessTypeStepProcessingEnum} from "../../../enum/wf-process-type-step-processing.enum";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {WfProcessTypeFieldDto} from "../../../dto/wf-process-type-field.dto";
import {WfProcessTypeDto} from "../../../dto/wf-process-type.dto";
import {WfProcessService} from "../../../service/api/wf-process.service";
import {WfProcessRuntimeDto} from "../../../service/dto/wf-process-runtime.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-sub-process-object',
    templateUrl: './wf-process-step-activity-sub-process-object.component.html',
})
export class WfProcessStepActivitySubProcessObjectComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    objectIdentifier: ObjectIdentifier;
    processDto: WfProcessRuntimeDto;

    protected processService: WfProcessService;

    ngOnInit() {

        this.processService = <WfProcessService>this.coreService.getCrudService("wf.WfProcess");
        this.refreshComponent();

    }

    refreshComponent() {

        if (this.activityType.activityStartProcessDto.startProcessTypeDtoId) {
            this.objectIdentifier = new ObjectIdentifier('wf.WfProcess', this.activityType.activityStartProcessDto.startProcessTypeDtoId);
            // this.processService.resume(this.activityType.activityStartProcessDto.startProcessTypeDtoId).subscribe(res => {
            //     this.processDto = <WfProcessRuntimeDto>res;
            // })
        }
    }

    /**
     * handle when a process is created
     * @param event
     */
    handleProcessCreated(event: WfProcessDto) {
        this.objectIdentifier = new ObjectIdentifier('wf.WfProcess', event.id);

        if (this.activityType.processing === WfProcessTypeStepProcessingEnum.optional) {
            this.onOptionalActivityChangeEvent.emit(new WfProcessStepActivityEvent(this.objectIdentifier, this.activityType));
        } else if (this.activityType.processing === WfProcessTypeStepProcessingEnum.main) {
            this.updateProcess(this.objectIdentifier.objectId);

        }
    }


    /**
     * update the process when a new sub process is created
     * @param connectedObjectId
     */
    updateProcess(connectedObjectId: number) {
        // this.busy = true;
        // const field: WfProcessTypeFieldDto = WfProcessTypeDto.getFieldById(this.activityType.objectProcessTypeFieldDtoId, this.process.typeDto)
        //
        // const genericField = `gen_${field.id}DtoId`
        //
        // const processDto: WfProcessDto = new WfProcessDto();
        // processDto.id = this.process.id;
        // processDto[genericField] = connectedObjectId;
        //
        // this.processService.update(processDto).subscribe(() => {
        //     if (connectedObjectId) {
        //         this.connectedObjectId = connectedObjectId;
        //     } else {
        //         this.connectedObjectId = null;
        //     }
        //     this.busy = false
        //     this.onProcessCreated.emit(new WfProcessStepActivityEvent(this.startedProcessObjectIdentifier, this.activityType));
        // })
    }
}
