import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {
    ValueListObjectBaseComponent
} from "../../component/object-component/value-list-object-component/value-list-object-base.component";

@Injectable({
    providedIn: 'root'
})
export class FieldValueListService extends MvsCrudService{

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/fieldValueLists');
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {

        if (mode != MvsCrudModeEnum.create) {
            return ValueListObjectBaseComponent;
        }

        return null;
    }
}
