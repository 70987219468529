import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ImImportDto} from "../../../dto/im-import.dto";
import {PageComponent} from "@kvers/alpha-core-common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-im-import-page',
  templateUrl: './im-import.page.html',
  styleUrls: ['./im-import.page.scss']
})
export class ImImportPage extends PageComponent implements OnInit {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  uploadedImportId: number;

  defaultLabel: string = "Import durchführen";

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService) {
    super(route, coreService);
  }



  ngOnInit(): void {

    super.ngOnInit();

    this.initComponent();
    this.refreshComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
  }



  /**
   * Store uploaded import ID and show the results.
   * @param imImport
   */
  handleDataImported(imImport: ImImportDto) {
    this.uploadedImportId = imImport.id;
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }




}
