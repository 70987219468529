import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";
import {catchError, tap} from "rxjs/operators";
import {MvsObjectActionEnum} from "@kvers/alpha-core-common";
import {UiFieldConfigurationInfoDto} from "../../dto/ui-field-configuration-info.dto";

@Injectable({
  providedIn: 'root'
})
export class UiFieldService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/fields')
  }

  /**
   * Get single Entity Object via ID. The returned DtoDetail will already be transformed.
   * @param id
   */
  public getFieldConfigurations(objectType: string, attributeName: string, layoutId: string): Observable<UiFieldConfigurationInfoDto[]> {

    // set-up URL
    let url = `${this.apiUrl}/getFieldConfiguration?attributeName=${attributeName}`;

    if (objectType) {
      url+="&objectTypeAlias=" + objectType;
    }

    if (layoutId) {
      url+="&layoutId=" + layoutId;
    }

    return this.http.get<UiFieldConfigurationInfoDto[]>(url);

  }

}
