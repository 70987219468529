@if (initialized) {
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex align-items-center mb-3">
                    <p-checkbox *ngFor="let day of days" [(ngModel)]="day.selected" [binary]="true"
                                [label]="day.label"></p-checkbox>
                </div>
                <div *ngFor="let day of days" class="flex flex-column gap-2">
                    @if (day.selected) {

                        <div class="flex align-items-center gap-8">

                            <p-checkbox [(ngModel)]="day.isFreeDay" (onChange)="toggleFreeDay(day)"
                                        [binary]="true"></p-checkbox>

                            <span class="w-2rem">{{ day.label }}</span>

                            <p-calendar
                                    inputId="calendar-timeonly"
                                    [(ngModel)]="day.schedule.fromHours"
                                    [timeOnly]="true"
                                    placeholder="00:00"/>
                            <span>to</span>

                            <p-calendar
                                    inputId="calendar-timeonly"
                                    [(ngModel)]="day.schedule.toHours"
                                    [timeOnly]="true"
                                    placeholder="00:00"/>

                            <div class="p-inputgroup gap-2">
                                <input
                                        pInputText
                                        [readOnly]="true"
                                        [style]="{'min-width':'300px'}"
                                        [(ngModel)]="day.schedule.startLocationDtoName"
                                        (paste)="onPaste($event, day)"
                                >

                                <span class="p-inputgroup-addon cursor-pointer" *ngIf="day.schedule.startLocationDtoName"
                                      (click)="copy(day.schedule.startLocationDtoId, day.schedule.startLocationDtoName)">
                            <i class="fa-regular fa-clipboard"></i>
                            </span>

                                <span class="p-inputgroup-addon cursor-pointer" (click)="addLocation(day)">
                            <i class="fa-solid fa-map-marker-plus"></i>
                            </span>

                            </div>
                        </div>
                    }
                </div>

                <button pButton label="Save" icon="pi pi-save" class="p-button" [disabled]="busy || enableSaveButton()"
                        (click)="handleSaveAll()"></button>
            </div>

        </div>
        <div class="col-12">

            <mvs-widget *ngIf="agentScheduleDayWidget" [widgetData]="agentScheduleDayWidget"></mvs-widget>
        </div>
    </div>
}