import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MainSelection} from "../../interfaces/mvs-dashboard-main-selection.interface";
import {SubSelection} from "../../interfaces/mvs-dashboard-sub-selection.interface";
import {GroupItem} from "../../interfaces/mvs-dashboard-group-item.interface";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'mvs-dashboard-top-navigation',
    templateUrl: './mvs-dashboard-top-navigation.component.html',
    styleUrls: ['./mvs-dashboard-top-navigation.component.scss']
})
export class MvsDashboardTopNavigationComponent implements OnInit, OnChanges {

    // Input properties for main selections, sub-selections, and type filter selections
    @Input() mainSelections: MainSelection[];
    @Input() subSelections: SubSelection[];
    @Input() typeFilterSelections: GroupItem[];

    // Input and Output properties for selected main, sub, and type filter selections
    @Input() mainSelection: MainSelection;
    @Output() mainSelectionChange = new EventEmitter<MainSelection>();

    @Input() subSelection: SubSelection;
    @Output() subSelectionChange = new EventEmitter<SubSelection>();

    @Input() typeFilterSelection: GroupItem;
    @Output() typeFilterSelectionChange = new EventEmitter<GroupItem>();

    // Arrays to store menu items for main and sub-selections
    mainSelectionItems: MenuItem[];
    subSelectionItems: MenuItem[];

    // Flags to track initialization status and active index
    initialized: boolean;
    activeIndex: number;


    ngOnInit(): void {
        this.refreshComponent();
    }

    /**
     *  // Method to refresh the component, called on initialization and input changes
     */

    refreshComponent() {
        this.refreshMainSelectionItems();
        this.refreshSubSelectionItems();
        this.getSubSelectionKey();
        this.initialized = true;
    }

    /**
     * Method to refresh main selection items based on input
     */
    refreshMainSelectionItems() {

        this.mainSelectionItems = [];

        if (!this.mainSelections) {
            return
        }

        if (this.mainSelections && !this.mainSelections.length) {
            return
        }

        for (let selection of this.mainSelections) {

            const item = {
                label: selection.label,
                key: selection.key,
                subLabel: selection.subLabel,
                command: () => {
                    this.mainSelection = selection;
                    this.mainSelectionChange.emit(this.mainSelection);
                }
            }
            this.mainSelectionItems.push(item)
        }

    }

    /**
     * Method to refresh sub-selection items based on input
     */
    refreshSubSelectionItems() {
        this.subSelectionItems = [];

        if (!this.subSelections) {
            return;
        }

        // Populate subSelectionItems array using the processSelections method
        this.subSelectionItems = this.processSelections(this.subSelections);
    }

    /**
     * Recursive method to process sub-selection items and create menu structure
     * @param selections
     * @param parent
     */
    processSelections(selections: SubSelection[], parent = null) {
        const result = [];

        for (let selection of selections) {

            const item = {
                label: selection.label,
                key: selection.key,
                badge: selection.count,
                icon: selection.icon,
                badgeStyleClass: 'p-badge p-component p-badge-no-gutter mx-2',
                command: () => {
                    this.subSelection = selection;
                    this.subSelectionChange.emit(this.subSelection);
                },
                parent: parent
            };

            if (this.subSelection == selection) {

                const style = {
                    'background': 'var(--primary-lightest-color, #E6F1F6)',
                    'border-radius':'4px'
                };

                item['style'] = style;
                item.icon += ' mvs-dashboard-selected-item'

                // Apply the style to all parent items
                let currentParent = parent;
                while (currentParent) {
                    currentParent.style = style;
                    currentParent.icon += ' mvs-dashboard-selected-item';
                    currentParent = currentParent.parent;
                }
            }

            // If there are nested items, recursively process them
            if (selection.items && selection.items.length > 0) {
                item['items'] = this.processSelections(selection.items, item);
            }

            // Add the item to the result array
            result.push(item);
        }

        return result;
    }

    /**
     * Method to get the active index based on the selected subSelection
     */
    getSubSelectionKey() {
        if (this.subSelection) {
            this.activeIndex = this.subSelections.findIndex(item => item.key == this.subSelection.key);
        } else {
            this.activeIndex = 0;
        }
    }

    /**
     * Event handler for type filter selection change
     */
    onTypeFilterSelectionChange() {
        this.typeFilterSelectionChange.emit(this.typeFilterSelection);
    }

    /**
     * Event handler for type filter selection clear
     */
    onTypeFilterSelectionClear() {
        this.typeFilterSelectionChange.emit(this.typeFilterSelection);
    }

    /**
     * Lifecycle hook to handle input changes
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }
        this.refreshComponent();
    }


}
