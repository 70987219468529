import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {SearchIndexRequestDto} from "../../../logic/search-index-request.dto";
import {IndexRunService} from "../../../service/api/index-run.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'si-dashboard',
    templateUrl: './si-dashboard.component.html',
})
export class SiDashboardComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Search Index Dashboard";

    indexWidget: WidgetData;

    form: FormGroup;

    objectTypes: ObjectTypeDto[];

    selectedIndexRun: ObjectIdentifier;


    constructor(protected route: ActivatedRoute,
                protected router: Router,
                protected coreService: MvsCoreService,
                protected fb: FormBuilder,
                protected objectTypeService: ObjectTypeService,
                protected indexSearchService: IndexRunService,
                protected messageService: MvsMessageService
                ) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

        this.initWidgets();

        this.initFormGroup();

        // retrieve all object types
        this.objectTypeService.list(ObjectRequestList.createBasic(false, [], [new Sorting("alias", true)])).subscribe(value => {
            this.objectTypes = value.entries;
        });

    }

    onSubmit() {

        if (this.form.status == "VALID") {

            // requestedObjectTypeIds: number[], limitProcessingCount: number, resolveSources: boolean, threadCount: number)
            const searchIndexRequestDto = new SearchIndexRequestDto();
            searchIndexRequestDto.requestedObjectTypeIds = this.form.value["requestedObjectTypeIds"];
            searchIndexRequestDto.limitProcessingCount = this.form.value["limitProcessingCount"];
            searchIndexRequestDto.resolveSources = this.form.value["resolveSources"];
            searchIndexRequestDto.threadCount = this.form.value["threadCount"];
            searchIndexRequestDto.threadPackageSize  = this.form.value["threadPackageSize"];
            searchIndexRequestDto.disableReferenceCreation = this.form.value["disableReferenceCreation"];

            // start index search
            this.indexSearchService.startIndex(searchIndexRequestDto).subscribe(value => {

                this.navigateToIndexRun(value.id);
            }, error => {
                // error message would have already been shown (no further activity required)
            });

        } else {
            this.messageService.showErrorMessage("Eingabe", "Bitte selektion füllen");
        }

    }

    initFormGroup() {
        this.form = this.fb.group({
            requestedObjectTypeIds: [null],
            limitProcessingCount: [0, Validators.required],
            resolveSources: [true],
            threadCount: [0, Validators.required],
            threadPackageSize : [1000, Validators.required],
            disableReferenceCreation : [false]
        });
    }


    initWidgets() {
        this.indexWidget = new WidgetData();
        this.indexWidget.idAlias = "si.dashboard.indexes";
        this.indexWidget.name = "Index Runs";
        this.indexWidget.uiComponent = "table";
        this.indexWidget.dataProvider = "list";
        this.indexWidget.dataSource = "entity";
        this.indexWidget.dataProviderObject = "si.IndexRun";
        this.indexWidget.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            [],
            [new Sorting("runStart",false)],
            PagingDto.create(0, 10));

        this.indexWidget.setParamValue("selectionMode", "rowSelect");
        this.indexWidget.setParamValue("size", "S");
        this.indexWidget.setParamValue("objectWidget", "data");

    }

    onSelect(event: ObjectIdentifier) {
        this.navigateToIndexRun(event.objectId);
    }

    navigateToIndexRun(id: number) {
        this.router.navigate(['si', 'indexRun', id]);
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
