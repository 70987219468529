import {
    Component,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MessageService, TreeNode} from "primeng/api";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {MvsApiServiceUploadInterface} from "@kvers/alpha-core-common";
import {DmDocumentDto} from "../../dto/dm-document.dto";
import {DomSanitizer} from "@angular/platform-browser";
import {FileUpload} from "primeng/fileupload";
import {Dialog} from "primeng/dialog";
import {DmDocumentAssignmentService} from "../../service/api/dm-document-assignment.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DmDocumentTypeService} from "../../service/api/dm-document-type.service";
import {DtoBag} from "@kvers/alpha-core-common";
import {DmDocumentGroupDto} from "../../dto/dm-document-group.dto";
import {DmDocumentTypeDto} from "../../dto/dm-document-type.dto";

interface DmUploadDocumentFile {
    name: string;
    objectURL: string;
}

// <!-- DEPRECATRED -->

@Component({
    selector: 'mvs-dm-upload',
    templateUrl: './dm-upload.component.html',
    styleUrls: ['./dm-upload.component.scss']
})
export class DmUploadComponent implements OnInit, OnChanges, OnDestroy {

    // @Input() uploadInterface: MvsApiServiceUploadInterface;
    // @Input() referenceId: number;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() documentTypeId: number;
    @Input() hideDocumentTypeSelection: number;

    @Input() layoutVariation: string = 'm-version';

    @Input() showPreview: boolean = true;
    @Input() isDocumentTypeSelectionMode: boolean = true;

    @Input() showDocumentTypeDropdown: boolean = true;

    @Output() onDocumentUploaded: EventEmitter<DmDocumentDto> = new EventEmitter<DmDocumentDto>();
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

    maximizable: boolean = true;
    progress: any[] = [];
    documentPreviewSrc: any[] = [];
    selectedFileIndex: number = 0;
    busy: boolean;
    initialized: boolean;
    visible: boolean;
    showDragNDrop: boolean = true;
    displayValue: string = 'list';
    displayOptions: any[] = [
        {icon: 'fa fa-list', displayValue: 'list'},
        {icon: 'fa fa-grid', displayValue: 'grid'}
    ];

    loadedDocumentTypesForAlias: string;
    documentTypeHierarchy: TreeNode[];
    selectedDocumentType: TreeNode;

    // documentUploadSubscription: Subscription;

    constructor(
        protected coreService: MvsCoreService,
        protected mvsMessageService: MvsMessageService,
        private messageService: MessageService,
        private documentAssignmentService: DmDocumentAssignmentService,
        private documentTypeService: DmDocumentTypeService,
        private sanitizer: DomSanitizer) {
    }

    uploadedFiles: any[] = [];

    ngOnInit(): void {

        this.initComponent();

        this.refreshComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        // this.documentUploadSubscription = this.documentAssignmentService.subjectDocumentUpload.subscribe(value => {
        // });
    }

    onUploadHandler(event: any, fileUpload: FileUpload) {

        this.progress = [];

        // if not documentTypeId && mode is predefinedId OR if no document type is selected and mode is not predefinedId THEN return
        if ((this.isDocumentTypeSelectionMode && (!this.selectedDocumentType || !this.selectedDocumentType.key)) || (!this.isDocumentTypeSelectionMode && !this.documentTypeId)) {
            this.mvsMessageService.showErrorMessage("Fehler", "Bitte einen Dokumenttypen auswählen");
            return;
        }

        let i = 0;
        for (let file of event.files) {
            this.upload(i++, file, fileUpload);
        }
    }


    onImageMouseOver(file: DmUploadDocumentFile) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'flex' : null;
        })
    }

    onImageMouseLeave(file: DmUploadDocumentFile) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'none' : null;
        })
    }

    removeImage(event: Event, file: any) {
        event.stopPropagation();
        this.uploadedFiles = this.uploadedFiles.filter(i => i !== file);
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {


        // check whether the information was already loaded
        if (this.loadedDocumentTypesForAlias == this.objectIdentifier.objectType) {
            this.initialized = true;
            return;
        }

        this.loadedDocumentTypesForAlias = null;
        this.documentTypeHierarchy = [];

        if (this.showDocumentTypeDropdown) {


            this.documentTypeService.getDocumentTypesByObjectTypeAlias(this.objectIdentifier.objectType).subscribe(dtoBag => {

                // calculate the tree
                const documentGroups = DtoBag.extractList<DmDocumentGroupDto>(dtoBag, "dm.DmDocumentGroup");
                const documentTypes = DtoBag.extractList<DmDocumentTypeDto>(dtoBag, "dm.DmDocumentType");

                for (let documentGroup of documentGroups.entries) {

                    const treeNodeGroup: TreeNode = {
                        label: documentGroup.name,
                        data: documentGroup,
                        icon: documentGroup.image,
                        children: [],
                        selectable: false,
                        key: "G" + documentGroup.id
                    };

                    for (let documentType of documentTypes.entries) {
                        if (documentType.documentGroupDtoId != documentGroup.id) {
                            continue;
                        }

                        const treeNodeType: TreeNode = {
                            label: documentType.name,
                            data: documentType,
                            icon: documentType.image,
                            children: [],
                            selectable: true,
                            key: documentType.id + ""
                        };

                        if (this.documentTypeId == documentType.id) {
                            //this.selectedDocumentType = [treeNodeType];
                            this.selectedDocumentType = treeNodeType;
                        }

                        treeNodeGroup.children.push(treeNodeType);
                    }

                    this.documentTypeHierarchy.push(treeNodeGroup);

                }
            });
        }

        this.initialized = true;


    }

    upload(idx: number, file: File, fileUpload: FileUpload): void {

        file["progress"] = this.progress[idx] = {visible: true, value: 0, success: false, error: false};

        if (file) {

            let documentKey: any;
            if (this.selectedDocumentType) {
                documentKey = this.selectedDocumentType.key
            } else {
                documentKey = this.documentTypeId;
            }

            this.documentAssignmentService.uploadViaAlias(file, documentKey, this.objectIdentifier.objectType, this.objectIdentifier.objectId).subscribe({
                next: (event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.progress[idx].value = Math.round(100 * event.loaded / event.total);
                    } else if (event instanceof HttpResponse) {
                        this.progress[idx].value = 100;
                        this.progress[idx].success = 'Uploaded';

                        // emit event
                        // this.onDocumentUploaded.emit(file);

                        this.documentAssignmentService.subjectUpdate(this.objectIdentifier.objectType);
                        this.onDocumentUploaded.emit(event.body);

                        const index = fileUpload.files.indexOf(file);

                        fileUpload.files.splice(index, 1);
                        fileUpload.cd.detectChanges();

                    }
                },
                error: (err: any) => {
                    this.progress[idx].value = 0;
                    this.progress[idx].error = 0;
                }
            });
        }
    }

    onSelectHandler(event) {
        this.showDragNDrop = false;
        for (let file of event.files) {
            const selectedFile = file;
            const reader = new FileReader();

            // Read the PDF file as an ArrayBuffer
            reader.readAsArrayBuffer(selectedFile);

            reader.onload = (e: any) => {
                // Convert the ArrayBuffer to a data URL
                const pdfDataUrl = e.target.result;
                file.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + this.arrayBufferToBase64(pdfDataUrl))

            };

        }

    }


    arrayBufferToBase64(buffer: ArrayBuffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    preview(file) {
        const selectedFile = file;
        const reader = new FileReader();

        // Read the PDF file as an ArrayBuffer
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = (e: any) => {
            // Convert the ArrayBuffer to a data URL
            const pdfDataUrl = e.target.result;
            this.documentPreviewSrc = [];
            this.documentPreviewSrc.push(this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + this.arrayBufferToBase64(pdfDataUrl)));
        }
        this.visible = true;
    }

    removeFile(event: Event, file: File, uploader: FileUpload) {
        const index = uploader.files.indexOf(file);
        uploader.remove(event, index);
        this.onSelectHandler(uploader);
    }

    changeView(selectedOption: string) {
        this.displayValue = selectedOption;
    }

    showDialogMaximized(event, dialog: Dialog) {
        if (this.displayValue == 'grid') {
            this.maximizable = false;
            dialog.maximize();
        } else {
            this.maximizable = true;
        }
    }

    nextDocument(totalLength) {
        this.selectedFileIndex = Math.min(this.selectedFileIndex + 1, totalLength - 1);

    }

    previousDocument() {
        this.selectedFileIndex = Math.max(this.selectedFileIndex - 1, 0);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"] || changes["documentTypeId"]) {
            this.refreshComponent();
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        // this.documentUploadSubscription.unsubscribe();
    }


}
