<div class="bg-white mt-5" *ngIf="initialized">

    <mvs-config-header header="Templates Configuration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

<!--    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">-->
<!--        <ng-container *ngFor="let widgetEntry of uiWidgetEntries; let i = index">-->
<!--            <p-tabPanel>-->
<!--                <ng-template pTemplate="header">-->

<!--                    <div class="flex align-items-center gap-2">-->
<!--                        <span class="font-bold white-space-nowrap m-0">-->
<!--                                    {{ widgetEntry.label }}-->
<!--                        </span>-->
<!--                    </div>-->

<!--                </ng-template>-->
<!--            </p-tabPanel>-->
<!--        </ng-container>-->
<!--    </p-tabView>-->


    <ng-container *ngIf="activeNavItem && activeNavItem == 'Templates'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="templateGroupsWidget"
                                [widgetData]="templateGroupsWidget"
                                (onObjectSelect)="handleTemplateGroupSelect($event)">
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">
                <mvs-widget *ngIf="templateWidget"
                            [widgetData]="templateWidget"
                            (onObjectSelect)="handleSelect($event)">
                </mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem && activeNavItem == 'Artefacts'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="artefactGroupsWidget"
                                [widgetData]="artefactGroupsWidget"
                                (onObjectSelect)="handleArtefactGroupSelect($event)">
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">
                <mvs-widget *ngIf="artefactWidget"
                            [widgetData]="artefactWidget"
                            (onObjectSelect)="handleSelect($event)"
                >
                </mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem && activeNavItem == 'TestCases'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="testCasesWidget"
                                [widgetData]="testCasesWidget" >
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem && activeNavItem == 'VordefinierteSystemvariablen'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="vordefinierteSystemvariablenWidget"
                                [widgetData]="vordefinierteSystemvariablenWidget" >
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">

            </div>
        </div>
    </ng-container>
</div>