import {Component, OnInit} from '@angular/core';
import {LgCalculationLogicTypeObjectBaseComponent} from "../lg-calculation-logic-type-object-base.component";

@Component({
    selector: 'mvs-lg-calculation-logic-type-object-full',
    templateUrl: 'lg-calculation-logic-type-object-full.component.html'
})
export class LgCalculationLogicTypeObjectFullComponent extends LgCalculationLogicTypeObjectBaseComponent implements OnInit {


}
