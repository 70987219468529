<ng-container *ngIf="initialized">
    <div class="grid">
        <div class="col-12">
            <mvs-widget
                    *ngIf="widgetTicketTypeHistoryChart"
                    [widgetData]="widgetTicketTypeHistoryChart"
                    [importObjectContext]="importObjectContextDto"
            ></mvs-widget>
        </div>
    </div>
</ng-container>