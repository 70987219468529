import {Component, Input,} from '@angular/core';
import {UiPageUserFavoriteService} from "../../service/api/ui-page-user-favorite.service";
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {UiNavigationFrequentPagesComponent} from "../ui-navigation-frequent-pages/ui-navigation-frequent-pages.component";
import {Observable} from "rxjs";

@Component({
    selector: 'ui-navigation-favorites',
    templateUrl: '../ui-navigation-frequent-pages/ui-navigation-frequent-pages.component.html',
})
export class UiNavigationFavoritesComponent extends UiNavigationFrequentPagesComponent{

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    icon: string = 'fa-light fa-star';

    @Input() uiNavigationMainDto! : UiNavigationMainDto;

    // service needs to provide an UiPageVisitResponseDto[]
    constructor(protected uiPageUserFavoriteService: UiPageUserFavoriteService,) {
        super(uiPageUserFavoriteService);
    }


    getData(): Observable<any> {
        return this.uiPageUserFavoriteService.getFavorites();
    }


}
