<div class="layout-topbar shadow-4 h-auto align-items-center">

    <ng-container *ngIf="displayStack && displayStack.length">
        <div class="flex justify-content-between align-items-center px-3 w-full">
            <div class="topbar flex gap-3 surface-0">
                <ng-container *ngIf="objectLabel">
                    <span class="overlay-item" (click)="onNavigateToMainPage()">{{ objectLabel }}</span>
                    <span> | </span>
                </ng-container>
                <ng-container *ngFor="let entry of displayStack; let i = index">
                    <div class="flex align-items-center border-round-md"
                         [ngClass]="i == activeOverlayIndex ? 'bg-primary-50' : 'surface-50'">
                <span (click)="toggleOverlay(i)"
                      class="overlay-item">
            <!--        displayStack-->
                    {{ entry['label'] }}
                </span>
                        <p-button [icon]="'fa fa-xmark'" styleClass="p-button-text"
                                  (click)="handleRemoveItem(i)"></p-button>
                    </div>
                    <!--                <span *ngIf="i < displayStack.length - 1"> | </span>-->
                </ng-container>
            </div>
            <p-button styleClass="hover:surface-200 text-600 surface-50" icon="fa fa-times text-xl"
                      (click)="onNavigateToMainPage()"></p-button>
        </div>
    </ng-container>

    <ng-container *ngIf="!displayStack || !displayStack.length">
        <div class="layout-topbar-left">
            <a class="layout-topbar-logo w-14rem cursor" (click)="handleHomePageNavigation()">
                <img id="app-logo" src="{{tenantLogo}}" alt="ultima-layout" class="p-3" style="height: 4.25rem">
            </a>

            <!--    left sidebar open/close mobile icon. sidebar data moved to right side in burger menu     -->

            <!--        <a class="layout-menu-button shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>-->
            <!--            <i class="pi pi-chevron-right"></i>-->
            <!--        </a>-->

            <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
                <i class="pi pi-ellipsis-v fs-large"></i>
            </a>
        </div>

        <div class="layout-topbar-right pl-2" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
            <div class="layout-topbar-actions-left gap-3">
                <mvs-command-menu-search></mvs-command-menu-search>
                <!--<p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu>-->
                <ng-container *ngFor="let button of buttons">
                    <core-indicator
                            [objectType]="button.objectType"
                            [label]="button.label"
                            [filter]="button.filter"
                            [widgetType]="button.widgetType"
                            [overlayPosition]="button.overlayPosition"
                            [objectRequest]="button.objectRequest">
                    </core-indicator>
                </ng-container>
            </div>

            <div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items">

<!--                    <ul class="flex gap-5 mr-3">-->
                        <li class="layout-topbar-item layout-search-item">
                            <!--
                            <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple>
                                <i class="pi pi-search fs-large"></i>
                            </a>

                            <div class="layout-search-panel p-inputgroup" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.search" (@topbarActionPanelAnimation.done)="onSearchAnimationEnd($event)">
                                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                <input #searchInput type="text" pInputText placeholder="Search" (click)="appMain.searchClick = true;" (keydown)="appMain.onSearchKeydown($event)">
                                <span class="p-inputgroup-addon">
                                    <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="appMain.searchClick = false;"></button>
                                </span>
                            </div>

                            -->


                            <mvs-pc-header-call-active></mvs-pc-header-call-active>

                        </li>
                        <li class="layout-topbar-item">
                            <am-agent-main-active-objects></am-agent-main-active-objects>
                        </li>

<!--                    </ul>-->

                    <li class="layout-topbar-item">
                        <a class="layout-topbar-action rounded-circle justify-content-center"
                           pRipple (click)="op.toggle($event)">
                            <i class="fa-regular fa-plus-circle text-2xl text-500 cursor"></i>
                        </a>
                        <p-overlayPanel #op>
                            <div class="flex flex-column gap-3">
                                <p-button styleClass="p-button-text text-800" (click)="createTicket()"
                                          icon="fa-regular fa-ticket" label="Create Ticket"></p-button>
                                <p-button styleClass="p-button-text text-800" icon="fa-regular fa-user-plus" label="Create Person"></p-button>
                                <p-button styleClass="p-button-text text-800" icon="fa-regular fa-file" label="Create Contract"></p-button>
                            </div>
                        </p-overlayPanel>
                    </li>

                    <li class="layout-topbar-item incoming-call">
                        <a pRipple class="layout-topbar-action rounded-circle hover:surface-200"
                           routerLink="/pc/incomingCall">
                            <i class="fa-regular fa-phone text-2xl text-500"></i>
                        </a>
                    </li>

                    <li class="layout-topbar-item">
                        <am-agent-active-objects></am-agent-active-objects>
                    </li>

                    <!--       settings/ logout menu commented 20/may/24         -->

                    <!--                <li class="layout-topbar-item">-->
                    <!--                    <a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle"-->
                    <!--                       (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>-->
                    <!--                        <img [src]="'https://graph.microsoft.com/v1.0/me/photos/48x48/$value' | mvsSecureBlob"-->
                    <!--                             alt="avatar" style="width: 32px; height: 32px;border-radius:50%; border: 1px solid var(&#45;&#45;surface-300);">-->
                    <!--                    </a>-->

                    <!--                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'"-->
                    <!--                        *ngIf="appMain.activeTopbarItem === 'profile'">-->
                    <!--                        <li class="layout-topbar-action-item">-->
                    <!--                            <a class="flex flex-row align-items-center" pRipple>-->
                    <!--                                <i class="pi pi-cog" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>-->
                    <!--                                <span>Settings</span>-->
                    <!--                            </a>-->
                    <!--                        </li>-->
                    <!--                        <li class="layout-topbar-action-item">-->
                    <!--                            <a class="flex flex-row align-items-center" pRipple>-->
                    <!--                                <i class="pi pi-file-o" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>-->
                    <!--                                <span>Terms of Usage</span>-->
                    <!--                            </a>-->
                    <!--                        </li>-->
                    <!--                        <li class="layout-topbar-action-item">-->
                    <!--                            <a class="flex flex-row align-items-center" pRipple>-->
                    <!--                                <i class="pi pi-compass" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>-->
                    <!--                                <span>Support</span>-->
                    <!--                            </a>-->
                    <!--                        </li>-->
                    <!--                        <li class="layout-topbar-action-item">-->
                    <!--                            <a class="flex flex-row align-items-center" pRipple>-->
                    <!--                                <i class="pi pi-power-off" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>-->
                    <!--                                <span>Logout</span>-->
                    <!--                            </a>-->
                    <!--                        </li>-->
                    <!--                    </ul>-->
                    <!--                </li>-->

                    <li (click)="handleNavigationToProfile()" class="cursor align-self-center">
                        <img class="h-2rem w-2rem border-circle mx-2"
                             [src]="profilePicture ?? 'assets/layout/images/blank_user.png'"/>
                    </li>

                    <li class="layout-topbar-item">
                        <a class="layout-topbar-action rounded-circle justify-content-center"
                           (click)="appMain.onRightMenuButtonClick($event)" pRipple>
                            <i class="fa-light fa-bars fs-large"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

</div>
