import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoList,
    MetaDataEntityDto, MetaDataJoinDto,
    MetaDataJoinRelationEnum, MetaService,
    MvsCoreService,
    MvsMessageService,
    ObjectBaseModeComponent, ObjectRequestList,
    ObjectTypeService, ObserverService,
} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem, TreeNode} from "primeng/api";
import {ObjectTypeDto} from "../../../../dto/object-type.dto";
import {ObjectTypeMetaJoinExtensionDto} from "../../../../dto/object-type-meta-join-extension.dto";
import {ActivatedRoute} from "@angular/router";
import {ObjectTypeMetaJoinExtensionService} from "../../../../service/api/object-type-meta-join-extension.service";


interface MyMenuItem extends MenuItem {
    join: MetaDataJoinDto;
}

@Component({
    selector: 'cc-meta-extension-base',
    template: '',
})
export class MetaExtensionBaseComponent extends ObjectBaseModeComponent implements OnInit {


    breadCrumb: MyMenuItem[] | null = null;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    objectTypes: DtoList<ObjectTypeDto>;
    selectedObjectType: ObjectTypeDto;

    historyObjectTypes: ObjectTypeDto[] = [];

    metaData: MetaDataEntityDto;

    defaultLabel: string = "Object Type Meta Extension";

    relationDialogShow: boolean;

    metaJoinExtensions: DtoList<ObjectTypeMetaJoinExtensionDto>;
    metaDataJoinRelationEnum = MetaDataJoinRelationEnum;

    navigationHistory: MyMenuItem[] = []; // Stack for navigation history
    backButtonEnabled: boolean = false; // Controls back button visibility

    @Input() params: MenuItem[];
    treeNodes: TreeNode[] = [];


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected objectTypeService: ObjectTypeService,
        protected messageService: MvsMessageService,
        protected metaService: MetaService,
        protected objectTypeMetaJoinExtensionService: ObjectTypeMetaJoinExtensionService,) {
        super(route);
    }


    ngOnInit() {

        if (this.navigationItems?.length) {
            this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            this.onNavigationItems.emit(this.navigationItems);
        }

        super.ngOnInit();

        this.initComponent();
        this.refreshComponent();
    }


    onRelationSaveDialog(event: any) {
        this.relationDialogShow = event.showRelationDialog;
    }

    onRelationUpdateDialog(event: any) {
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve object types
        this.objectTypeService.list(ObjectRequestList.createSimple(null)).subscribe(value => {
            this.objectTypes = <DtoList<ObjectTypeDto>>value;
            this.objectTypes.sortBy("alias", true);

            if (this.params) {
                const breadCrumbItems = this.params['breadcrumbs'];
                if (breadCrumbItems?.length > 0) {

                    this.breadCrumb = [];

                    for (let breadCrumbItem of breadCrumbItems) {
                        const objectTypeDto = this.objectTypes.entries.find(objectType => objectType.alias == breadCrumbItem.id);

                        if (objectTypeDto) {
                            this.handleBreadCrumbUpdate(objectTypeDto, null);
                        }

                    }
                    const item = this.breadCrumb[this.breadCrumb.length - 1];
                    if (item) {
                        const find = this.objectTypes.entries.find(objectType => objectType.alias == item.id);
                        if (find) {
                            this.changeSelectedObjectType(find);
                        }
                    }
                }

            }

            this.initialized = true;
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // only refresh the component if an object type was selected
        if (!this.selectedObjectType) {
            this.metaData = null;
            return;
        }

        this.busy = true;

        // retrieve meta Meta information
        this.metaService.getById(this.selectedObjectType.id, true, true, true, true, true).subscribe(value => {
            this.busy = false;
            this.metaData = value;
        });

        // retrieve external joins
        this.metaJoinExtensions = null;

        this.objectTypeMetaJoinExtensionService.listByObjectTypeId(this.selectedObjectType.id).subscribe(value => {
            this.metaJoinExtensions = <DtoList<ObjectTypeMetaJoinExtensionDto>>value;
        });


    }

    onCreateJoinExtension() {
        this.relationDialogShow = true;
    }

    /**
     * On Change Object Type
     * @param event
     */
    onChangeObjectType(event: any) {

        const objectType = event.value;

        this.breadCrumb = [];


        this.handleBreadCrumbUpdate(objectType, null);

        this.changeSelectedObjectType(event.value);


    }

    changeSelectedObjectType(objectType: ObjectTypeDto, isBreadCrumbSelect: boolean = false) {


        this.selectedObjectType = objectType;

        if (isBreadCrumbSelect) {
            // Step 1: Find the index of the selected object
            const index = this.breadCrumb.findIndex(item => item.id === objectType.alias);

            if (index !== -1) {
                // Step 2: Remove all items after the selected index
                const slicedItems = this.breadCrumb.slice(0, index + 1);
                slicedItems[slicedItems.length - 1].join = null;
                this.breadCrumb = [...slicedItems]; // Force Angular change detection
            }
        }


        // add entry to history
        this.addToHistory(this.selectedObjectType);

        // refresh component
        this.refreshComponent();
    }


    /**
     * Add to history.
     * @param objectType
     */
    addToHistory(objectType: ObjectTypeDto) {

        const objectTypeExists = this.historyObjectTypes.find(value => (objectType.id == value.id));

        if (!objectTypeExists) {

            // copy history objects (in order to trigger the control refresh)
            const historyEntries = this.historyObjectTypes;
            this.historyObjectTypes = [];
            this.historyObjectTypes.push(objectType);
            this.historyObjectTypes.push(...historyEntries);
        }

    }

    onNavigateToJoinObjectType(join: MetaDataJoinDto) {

        this.handleNavigationState();

        const objectTypeDto = this.objectTypes.entries.find(value => value.id == join.joinObjectTypeId);

        this.handleBreadCrumbUpdate(objectTypeDto, join);

        if (objectTypeDto) {
            this.changeSelectedObjectType(objectTypeDto);
        }

    }

    onHistoryChipSelect(event: any) {
        this.changeSelectedObjectType(event.value);
    }

    onRemoveHistoryObjectType(event: any) {

        const index = this.historyObjectTypes.findIndex(value => event.value.id == value.id);

        if (index || index === 0) {
            this.historyObjectTypes.slice(index, index);
        }

    }

    onBackToOverview() {
        this.selectedObjectType = null;
        this.metaData = null;
    }

    goBack() {
        if (this.navigationHistory.length > 0) {
            const previousObject: any = this.navigationHistory.pop();
            const lastItem = previousObject[previousObject.length - 1];
            this.handleBreadCrumbAssignmentUpdate(previousObject);
            this.backButtonEnabled = this.navigationHistory.length > 0; // Disable button if no history
            this.changeSelectedObjectType(lastItem.data);
        }
    }

    handleNavigationState() {

        if ((this.selectedObjectType || this.breadCrumb)) {
            // Push current state to history before changing selection

            // Create a deep copy of breadCrumb to prevent reference issues
            const breadCrumbCopy: MyMenuItem = this.breadCrumb
                ? JSON.parse(JSON.stringify(this.breadCrumb))
                : [];

            const breadCrumb = [...this.breadCrumb]
            this.navigationHistory.push(breadCrumbCopy);
            if (this.selectedObjectType) {
                this.backButtonEnabled = true; // Enable back button
            }
        }
    }

    handleBreadCrumbUpdate(objectType: ObjectTypeDto, join: MetaDataJoinDto) {


        this.breadCrumb.push({
            id: objectType.alias,
            label: objectType.name,
            data: objectType,
            command: () => this.changeSelectedObjectType(objectType, true),
            join: null
        });


        this.handleBreadCrumbAssignmentUpdate(this.breadCrumb, join);


    }

    handleBreadCrumbAssignmentUpdate(items: MyMenuItem[], join?: MetaDataJoinDto) {
        items.forEach((item) => item.styleClass = null);
        items[items.length - 1].styleClass = 'text-primary font-semibold';
        if (items.length - 2 >= 0 && join) {
            items[items.length - 2].join = join;
        }
        this.breadCrumb = [...items];
        this.treeNodes = this.buildTreeNodes();
    }


    buildTreeNodes() {
        const tree = [];
        let lastNode = null;

        for (const entity of this.breadCrumb) {
            if (entity.join) {

                const node = {
                    label: entity.label,
                    expanded: tree,
                    children: entity.join
                        ? [{label: entity.join.joinObjectTypeAlias + " → " + entity.join.name}]
                        : []
                };

                if (lastNode) {
                    lastNode.children.push(node);
                } else {
                    tree.push(node);
                }

                lastNode = node;
            }
        }
        return tree;
    }


    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {

    }

}
