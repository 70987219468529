import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UmUserService} from "../../service/api/um-user.service";
import {DmDocumentUploadDto} from "../../../dm/service/dto/dm-document-upload.dto";
import {MvsCoreService, MvsMessageService, PageComponent} from "@kvers/alpha-core-common";
import {UserProfileHelper} from "./helper/user-profile-helper";
import {ActivatedRoute} from "@angular/router";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'user-profile-id',
    templateUrl: './user-profile-id.component.html',
    styleUrls: ['./user-profile-id.component.css']
})
export class UserProfileIdComponent implements OnInit {

    @Input() userId: number;
    @Input() readonly: boolean = false;
    @Output() onObjectChanged = new EventEmitter<number>();
    imageSrc: string;
    tempImageSrc: string | undefined;
    imageChangedEvent: any = '';
    isSvg: boolean = false;
    croppedImage: any = '';
    showImageCropperDialog = false;

    initialized: boolean;

    constructor(protected userService: UmUserService,
                protected messageService: MvsMessageService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    getUserPicture() {
        if (!this.userId) {
            return;
        }
        this.isSvg = false;
        this.userService.getPictureBinaryById(this.userId).subscribe((res: Blob) => {
            //convert the blob to relevant type
            if (res.type == 'image/svg+xml') {
                this.isSvg = true;
            }
            UserProfileHelper.getImageSrcFromBlob(res).then(imageSrc => {
                this.imageSrc = imageSrc;
                if (this.initialized) {
                    this.onObjectChanged.emit(this.userId);
                }
                this.initialized = true;
            });
        }, error => {

        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getUserPicture();
        // this.initialized = true;
    }

    handleDeleteImage() {
        this.userService.delPictureById(this.userId).subscribe(res => {
            this.refreshComponent();
        }, error => {
        });
    }


    onUploadHandler(event: any) {
        this.imageChangedEvent = event;
        this.showImageCropperDialog = true;
    }

    imageCropped(event: any): void {
        this.croppedImage = event.base64;

        if (!this.croppedImage && event.blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.croppedImage = reader.result;
            };
            reader.readAsDataURL(event.blob);
        }

        this.tempImageSrc = event.objectUrl;
    }

    uploadCroppedImage() {
        this.showImageCropperDialog = false;

        const blob = this.dataURItoBlob(this.croppedImage);
        const file = new File([blob], "cropped-image.png", {type: blob.type});

        this.convertFileToBase64AndGetType(file).then(document => {

            const documentDto = new DmDocumentUploadDto();
            documentDto.pictureBase64 = document.fileBase64;
            documentDto.pictureMimeType = document.fileBase64MimeType;

            this.userService.setPictureById(this.userId, documentDto).subscribe({
                next: (response) => {
                    this.refreshComponent();
                    this.resetFileInput();
                },
                error: (error) => {
                }
            });
        });
    }

    dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type: mimeString});
    }

    convertFileToBase64AndGetType(file: File): Promise<{
        fileBase64: string;
        fileBase64MimeType: string;
        name: string
    }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const result = event.target?.result;
                const base64 = typeof result === 'string' ? result.split(',')[1] : ''; // Getting the Base64 content
                resolve({
                    fileBase64: base64,
                    fileBase64MimeType: file.type,
                    name: file.name
                });
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    resetFileInput() {
        //reset file input in case a user decides not to upload an image and then choose same picture because change event doesn't trigger on same file
        const input: HTMLInputElement = document.getElementById('upload') as HTMLInputElement;
        if (input) {
            input.value = '';
        }
    }

}
