

<div class="grid">
    <div class="col-12">

        <mvs-crud-page
                [objectStructureRequest]="objectBrowserRequest"
                mainObjectType="cc.ObjectTypeDto"
                [mainObjectTypeStructureRequest]="subObjectBrowserRequest"
                (onMainSelectedObjectChange)="onMainSelectedObjectChange($event)"
                (onChangedObject)="onChangedObject($event)"
        >
        </mvs-crud-page>

    </div>

    <!--
    <div class="col-6">

        <p-panel header="Konfiguration">
            <p-button icon="pi pi-refresh" *ngIf="!formConfigureBusy && formConfigureShow" (onClick)="refreshFormConfigure()"></p-button>
            <p-button icon="pi pi-spin pi-spinner" *ngIf="formConfigureBusy && formConfigureShow" disabled="true"></p-button>

            Object Type {{formConfigureObjectType}}

            <mvs-form-configure-fields
                    *ngIf="formConfigureShow && !formConfigureBusy && formConfigureObjectType"
                    [layoutId]=""
                    [form]="formConfigureForm"
                    [objectType]="formConfigureObjectType"
                    [readonly]="false"
            ></mvs-form-configure-fields>
        </p-panel>
    </div>
    -->
</div>
