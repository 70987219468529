import { DtoDetail } from "@kvers/alpha-core-common";

export class TmTicketActionDto extends DtoDetail {

    public ticketDtoId : number;
    public ticketDtoName : string;
    public ticketDtoImage : string;
    public ticketDtoColor : string;
    public ticketTypeActionDtoId : number;
    public ticketTypeActionDtoName : string;
    public ticketTypeActionDtoImage : string;
    public ticketTypeActionDtoColor : string;
    public comment : string;
   }