
import {MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {PartnerLinkDto} from "../../../dto/partner-link.dto";
import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";

export class CmPartnerWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;

    constructor(protected configService: MvsConfigService,
                protected selectedPartner: PartnerLinkDto) {
        super();
        this.configColors = this.configService.getColors();
    }

    addSelectedPartner(partner: PartnerLinkDto) {
        // const nextColor = this.configColors.getNextColor();
        partner['__color'] = '#AB47BC80';
        this.selectedPartner = partner;
    }

    removeRelatedPerson() {
        this.selectedPartner = null;
    }


    getRowStyle(rowDto: PartnerLinkDto, rowData: any): object {
        if (rowDto.id == this.selectedPartner.id) {
            return {
                "border" : "2px",
                "background-color" : this.selectedPartner['__color']
            };
        }
        return {};
    }

    public clone(): CmPartnerWidgetStyle {

        const widgetStyle = new CmPartnerWidgetStyle(this.configService,this.selectedPartner);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;

        return widgetStyle;
    }

}
