<ng-container *ngIf="initialized">

        <cm-update-insurable-object
                [customerId]="customerId"
                [insurableObjectId]="insurableObjectId"
                [importObjectContext]="importObjectContext"
                [defaultCreateDto]="dto"
                [objectIdentifier]="objectIdentifier"
                (onChangedObject)="handleObjectChange($event)"

        >
        </cm-update-insurable-object>


</ng-container>