import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {KpiHeaderDto} from "../../dto/kpi-header.dto";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'kpi-header',
    templateUrl: './kpi-header.component.html',
    styleUrls:['kpi-header.component.scss']
})
export class KpiHeaderComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;
    initialized: boolean;
    @Input() breadcrumb: MenuItem[];

    @Input() itemsArray: KpiHeaderDto[];

    @Input() configRoute: string;

    home = {icon: 'pi pi-home', routerLink: '/'};


    constructor(private router: Router,) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    navigateToConfig() {
        this.router.navigate([this.configRoute]);
    }

    navigateTo(path: string) {
        if (!path) {
            return;
        }
        this.router.navigate([path]);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
