import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-ns-notification-type-page',
  templateUrl: './ns-email-config.page.html'
})
export class NsEmailConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Email Konfiguration";
 

  ngOnInit(): void {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'ns.NsEmailConfiguration',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Email Konfiguration",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
      ]
    };


  }
}
