
<ng-container *ngIf="initialized && objectLoaded">

    <div class="col-12" *ngIf="documentStatusFilter && documentStatusFilter.length > 0">
        <p-panel header="Filters">
            <div class="flex align-items-center gap-2 flex-wrap">
                <p-chip
                        *ngFor="let filter of documentStatusFilter"
                        [label]="filter.field + ': ' + filter.label"
                        [removable]="true"
                        (onRemove)="onRemoveFilterCriteria(filter)"
                ></p-chip>
            </div>
        </p-panel>
    </div>

    <div class="grid">

        <div class="col-4 xs:col-12">
<!--            <mvs-dm-upload-->
<!--                    *ngIf="uploadInterface"-->
<!--                    [uploadInterface]="uploadInterface"-->
<!--                    [documentTypeId]="dto.mainDocumentTypeDtoId"-->
<!--                    (onDocumentUploaded)="onDocumentUploaded($event)"-->
<!--            ></mvs-dm-upload>-->

            <mvs-direct-upload
                    [documentTypeId]="dto.mainDocumentTypeDtoId"
                    (onDocumentUploaded)="onDocumentUploaded($event)">
            </mvs-direct-upload>

            <br />
            <mvs-widget
                    *ngIf="widgetDocumentStatus"
                    [widgetData]="widgetDocumentStatus"
                    (onObjectSelect)="onDocumentSelect($event)"
            ></mvs-widget>

        </div>


        <div class="col-8 xs:col-12">
            <mvs-widget
                    *ngIf="widgetUnassignedDocuments"
                    [widgetData]="widgetUnassignedDocuments"
                    (onObjectSelect)="onProcessDocuments($event)"
                    (onObjectsSelect)="onDocumentsSelected($event)"
            ></mvs-widget>
        </div>

    </div>


    <div class="grid">
        <div class="col-12">

            <mvs-widget
                    *ngIf="widgetDocumentTypeHistory"
                    [widgetData]="widgetDocumentTypeHistory"
                    (onObjectSelect)="onDocumentSelect($event)"
            ></mvs-widget>

        </div>
    </div>

    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12">
            <ng-container *ngIf="showDocument">
                <mvs-dm-document-component
                        [objectIdentifier]="showDocumentObjectIdentifier"
                ></mvs-dm-document-component>
            </ng-container>
        </div>
    </div>

<!--
    <p-sidebar
            id="dmDashBoardSideBar"
            [(visible)]="showDocument"
            position="right"
            [style]="{width:'40em'}"
    >

    </p-sidebar>
    -->

</ng-container>
