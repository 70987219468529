export enum CmContractObjectViewTypeUiEnum {
    standard,
    documents,
    sales,
    accounting,
    registration,
    documentUpload,
    objects,
    notifications,
    genericTickets,
    genericWorkflows,

}