import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class XhAnonymizationDataService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/xh/anonymizationDatas');
  }

  /**
   * Anonymize Data.
   * @param id
   * @param light
   * @protected
   */
  public anonymizeData(persons: boolean, personDetails: boolean, addresses: boolean, contacts: boolean, aud: boolean, documents: boolean): Observable<DtoDetail> {

    const url = `${this.apiUrl}/anonymize?persons=${persons}&personDetails=${personDetails}&addresses=${addresses}&contacts=${contacts}&aud=${aud}&documents=${documents}`;

    return this.http.get<DtoDetail>(url);

  }



}
