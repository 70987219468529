<span *ngIf="!hideLabel" class="text-lg text-800 font-medium p-3">Kommentare</span>
<div class="p-3">
    <div
            *ngIf="!focused"
            class="formgroup-inline align-items-center">
        <textarea
                placeholder="Enter your comment"
                class="w-full"
                (focusin)="focused = true"
                type="text"
                pInputText>
        </textarea>
    </div>
    <div
            *ngIf="focused"
            class="formgroup-inline align-items-center border-1 rounded-normal"
            [class.custom-field-disabled]="busy">
        <div class="col-12">

                    <textarea
                            [disabled]="busy"
                            pInputTextarea
                            [mention]="userNamesList"
                            [(ngModel)]="newComment"
                            (input)="handleInputChange()"
                            placeholder="Enter your comment"
                            class="w-full h-full textarea-blank"
                    ></textarea>
        </div>
        <div class="col-12 flex justify-content-end">
            <div class="flex">
                <button
                        *ngIf="newComment"
                        [disabled]="busy"
                        (click)="createComment()"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-send"
                        class="p-button-rounded -mt-5"
                ></button>

                <p-button pTooltip="Use recovered value" tooltipPosition="left"
                          *ngIf="recoveredText && !newComment"
                          (click)="loadRecoveredValue()"
                          class="align-self-center"
                          styleClass="w-2rem h-2rem p-button-rounded"
                          icon="fa-regular fa-arrow-rotate-right text-sm">
                </p-button>
            </div>

        </div>
    </div>
</div>