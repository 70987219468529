import {AfterViewInit, Component, Input, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {ModuleRegistryService} from "../../../../../core/interface/module-registration.service";


@Component({
    selector: 'statistics-container-component',
    templateUrl: './statistics-container.component.html',
    styleUrls: ['./statistics-container.component.scss']
})
export class StatisticsContainerComponent implements AfterViewInit {


    @ViewChild('statisticsComponentContainer', {read: ViewContainerRef, static: false})
    statisticsComponentContainer!: ViewContainerRef;

    @Input() alias: string;

    initialized: boolean;

    constructor(
        private moduleRegistry: ModuleRegistryService,) {
    }

    ngAfterViewInit(): void {
        this.getComponents(this.alias);
    }

    getComponents(alias: string): void {
        const dynamicModule = this.moduleRegistry.getModule(alias);

        if (dynamicModule) {
            const statisticsComponent = dynamicModule.getStatisticsComponent();

            this.statisticsComponentContainer.clear();
            this.statisticsComponentContainer.createComponent(statisticsComponent);
        } else {
            console.error('No module found for alias:', alias);
        }
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.initialized) {
            this.getComponents(this.alias);
        }
    }

}
