import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'co-statistics.component.html',
})
export class CoStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    channelTypeContactTypeHistoryWidget: WidgetData;
    channelTypeInternalTypeWidget: WidgetData;
    channelTypePriorityWidget: WidgetData;
    recentlyAddedChannelsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'CO Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.refreshChannelTypeContactTypeHistoryWidget();
        this.refreshChannelTypeInternalTypeWidget();
        this.refreshChannelTypePriorityWidget();
        this.refreshAddedChannels();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/co/config']);
    }

    refreshChannelTypeContactTypeHistoryWidget() {
        this.channelTypeContactTypeHistoryWidget = new WidgetData();
        this.channelTypeContactTypeHistoryWidget.idAlias = "co.overview.channel.type.contact.type.history.widget";
        this.channelTypeContactTypeHistoryWidget.name = "Channel Type Contact Type History";
        this.channelTypeContactTypeHistoryWidget.uiComponent = "history.chart";
        this.channelTypeContactTypeHistoryWidget.dataProvider = "list";
        this.channelTypeContactTypeHistoryWidget.dataSource = "entity.groupBy";
        this.channelTypeContactTypeHistoryWidget.dataProviderObject = "co.CoChannelTypeContactType";
        this.channelTypeContactTypeHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["channelType", "createdDate"],
            [new ObjectRequestListAttribute("channelType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.channelTypeContactTypeHistoryWidget.setParamValue("fieldCategory", "channelType");
        this.channelTypeContactTypeHistoryWidget.setParamValue("fieldCategoryCount", "channelType_count");
        this.channelTypeContactTypeHistoryWidget.setParamValue("historyPeriodMode", "year");
        this.channelTypeContactTypeHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.channelTypeContactTypeHistoryWidget.setParamValue("selectionMode", "single");
        this.channelTypeContactTypeHistoryWidget.setParamValue("size", "S");
    }

    refreshChannelTypeInternalTypeWidget() {
        this.channelTypeInternalTypeWidget = WidgetFactory.createWidgetGroupBy(
            'co.overview.channel.type.internal.type.widget',
            'Channel Type Internal Type',
            'category',
            'entity.groupBy',
            'co.CoChannelType',
            'internalType',
            'internalType_count',
            this.filterCriteria,
            'internalType',
            'Anzahl');
    }

    refreshChannelTypePriorityWidget() {
        this.channelTypePriorityWidget = WidgetFactory.createWidgetGroupBy(
            'co.overview.channel.type.priority.widget',
            'Channel Type Priority',
            'category',
            'entity.groupBy',
            'co.CoChannelType',
            'priority',
            'priority_count',
            this.filterCriteria,
            'priority',
            'Anzahl');
    }

    refreshAddedChannels() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedChannelsWidget = WidgetFactory.createWidgetTableEntity(
            'co.overview.recently.added.channels',
            'Recently Added Channel',
            'co.CoChannelType',
            'No Fields',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
