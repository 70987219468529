import { DtoDetail } from "@kvers/alpha-core-common";
import { TmEntityStatusEnum } from "../enum/tm-entity-status.enum";
import { TmStatusEnum } from "../enum/tm-status.enum";
import { TmUrgencyEnum } from "../enum/tm-urgency.enum";
import {TmTicketTypeDto} from "./tm-ticket-type.dto";
import {TmTicketObjectDto} from "./tm-ticket-object.dto";
import {CustomerDto} from "../../cr/dto/customer.dto";
import {TicketObjectDto} from "./ticket-object.dto";

export class TmTicketDto extends DtoDetail {

    public entityStatus : TmEntityStatusEnum;
    public alias : string;
    public name : string;
    public typeDtoId : number;
    public typeDtoName : string;
    public typeDtoImage : string;
    public typeDtoColor : string;
    public assigneeAgentPoolDtoId : number;
    public assigneeAgentPoolDtoName : string;
    public assigneeAgentPoolDtoImage : string;
    public assigneeAgentPoolDtoColor : string;
    public assigneeAgentDtoId : number;
    public assigneeAgentDtoName : string;
    public assigneeAgentDtoImage : string;
    public assigneeAgentDtoColor : string;

    public referenceCustomerDtoId: number;
    public referenceCustomerDtoName: string;
    public referenceCustomerDto?: CustomerDto;

    public initiatorObjectTypeDtoId : number;
    public initiatorObjectTypeDtoName : string;
    public initiatorObjectTypeDtoImage : string;
    public initiatorObjectTypeDtoColor : string;
    public initiatorObjectId : number;
    public status : TmStatusEnum;
    public urgency : TmUrgencyEnum;
    public dueDate : Date;
    public incomingCommunicationChannelDtoId : number;
    public incomingCommunicationChannelDtoName : string;
    public incomingCommunicationChannelDtoImage : string;
    public incomingCommunicationChannelDtoColor : string;
    public actionCount : number;
    public description : string;

    public typeDto?: TmTicketTypeDto;
    public ticketObject: TicketObjectDto[];
   }