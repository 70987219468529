import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {DmDocumentViewOptionsEnumEnum} from "../../../../../dm/enum/dm-document-view-options.enum";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {
    CmContractNavigationBaseComponentComponent
} from "../cm-contract-navigation-base-component/cm-contract-navigation-base-component.component";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {ContractFormerService} from "../../../../service/api/contract-former.service";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ContractFormerDto} from "../../../../dto/contract-former.dto";
import {en} from "@fullcalendar/core/internal-common";
import {PersonRelationPersonService} from "../../../../../pm/service/api/person-relation-person.service";


@Component({
    selector: 'cm-contract-objects-navigation',
    templateUrl: './cm-contract-objects-navigation.component.html',
})
export class CmContractObjectsNavigationComponent extends CmContractNavigationBaseComponentComponent implements OnInit, OnChanges, OnDestroy {
    dmDocumentViewOptionsEnumEnum = DmDocumentViewOptionsEnumEnum;

    // personsWidget: WidgetData;
    // objectsWidget: WidgetData;
    personsContractFormerWidget: WidgetData;
    objectsContractFormerWidget: WidgetData;

    customerPersonsWidget: WidgetData;
    personWidgetToolbarButtons: WidgetHelperButton[];

    customerObjectsWidget: WidgetData;

    contextContractAndCustomer: DtoImportObjectContext;
    contextCustomer: DtoImportObjectContext;

    constructor(protected objectService: MvsUiObjectService,
                protected contractFormerService: ContractFormerService,
                protected personRelationPersonService: PersonRelationPersonService,) {
        super();
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.retrieveFormerContracts();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    retrieveFormerContracts() {
        const filterCriteria = [FilterCriteria.create('contract', FilterCriteria.cOperatorEqual, this.contractDto.id)]
        const dtoRequest: ObjectRequestList = new ObjectRequestList(false, filterCriteria, null);

        this.contractFormerService.list(dtoRequest).subscribe(res => {
            const entries = res.entries;
            if (entries && entries.length) {
                this.createContractFormerFilter(entries);
            }
        })
    }

    createContractFormerFilter(formerContracts: ContractFormerDto[]) {
        let filterCriteria = FilterCriteria.createOrFromArray('contract','contractFormerDtoId',formerContracts);

        this.showFormerContractWidgets([filterCriteria]);
    }

    refreshWidgets() {

        // create import object context
        this.contextContractAndCustomer = DtoImportObjectContext.createFromObjectIdentifiers(
            new ObjectIdentifier("cm.Contract", this.contractDto.id),
            new ObjectIdentifier("cr.Customer", this.customerDto.id)
        );

        this.contextCustomer = DtoImportObjectContext.createFromObjectIdentifiers(
            new ObjectIdentifier("cr.Customer", this.customerDto.id)
        );

        const filterCriteria = FilterCriteria.create("contract", FilterCriteria.cOperatorEqual, this.contractDto.id);

        // create widget to display the persons
        // this.personsWidget = WidgetFactory.createWidgetList(
        //     "cm.contract.objects.navigation.persons",
        //     "Personen",
        //     "list",
        //     "list",
        //     "entity",
        //     "ci.ContractPerson",
        //     "Keine versicherten Personen vorhanden",
        //     ObjectRequestList.createComplexRequestList(
        //         true,
        //         ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("person", ObjectRequestRelation.createJoin("person"))),
        //         [filterCriteria],
        //         [],
        //         null)
        // );

        // if (this.customerDto && this.customerDto.personDtoId) {
        //     this.personWidgetToolbarButtons = [{
        //         icon: 'fa-light fa-user-plus',
        //         label: '',
        //         display: true,
        //         action: 'assignCustomer',
        //         tooltip: 'Kunden hinzufügen'
        //     }];
        // }


        // create widget to display the insurable objects
        // this.objectsWidget = WidgetFactory.createWidgetList(
        //     "cm.contract.objects.navigation.objects",
        //     "Objekte",
        //     "list",
        //     "list",
        //     "entity",
        //     "ci.ContractInsurableObject",
        //     "Keine versicherten Objekte vorhanden",
        //     ObjectRequestList.createComplexRequestList(
        //         true,
        //         ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("insurableObject", ObjectRequestRelation.createJoin("insurableObject"))),
        //         [filterCriteria],
        //         [],
        //         null)
        // );

        // retrieve main customer
        if (this.customerDto) {

            const filterCriteriaCustomer = FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.customerDto.id);

            // this.customerPersonsWidget = WidgetFactory.createWidgetList(
            //     "cm.contract.objects.navigation.customer.persons",
            //     "Personen",
            //     "list",
            //     "list",
            //     "entity",
            //     "cr.CustomerRelatedPerson",
            //     "Keine versicherten Personen vorhanden",
            //     ObjectRequestList.createComplexRequestList(
            //         true,
            //         ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("person", ObjectRequestRelation.createJoin("person"))),
            //         [filterCriteriaCustomer],
            //         [],
            //         null)
            // );

            const filter = this.personRelationPersonService.getPersonRelationFilterOr(this.customerDto.personDtoId);
            this.customerPersonsWidget = WidgetFactory.createWidgetList(
                "cm.contract.objects.navigation.customer.persons.relation.person",
                "Personen",
                "list",
                "list",
                "entity",
                "pm.PersonRelationPerson",
                "Keine versicherten Personen vorhanden",
                ObjectRequestList.createComplexRequestList(
                    true,
                    ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("person", ObjectRequestRelation.createJoin("personB"))),
                    [filter],
                    [],
                    null)
            );



            this.customerObjectsWidget = WidgetFactory.createWidgetList(
                "cm.contract.objects.navigation.customer.objects",
                "Objekte",
                "list",
                "list",
                "entity",
                "cr.CustomerInsurableObject",
                "Keine versicherten Objekte vorhanden",
                ObjectRequestList.createComplexRequestList(
                    true,
                    ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("insurableObject", ObjectRequestRelation.createJoin("insurableObject"))),
                    [filterCriteriaCustomer],
                    [],
                    null)
            );


        }


    }

    /**
     * Create ContractPerson assignment using the customer.
     * @param event
     */
    handlePersonWidgetToolbarClick(event: WidgetHelperButton) {

        const context = this.contextContractAndCustomer.copy();
        context.addEntry(new DtoImportObjectContextEntry("pm.Person", this.customerDto.personDtoId));

        if (event.action === 'assignCustomer') {
            this.objectService.openObjectViaDialog(
                context,
                "ci.ContractPerson",
                0,
                null,
                false,
                false,
                () => {
                    //
                },
                MvsCrudModeEnum.create
            );
        }

    }


    showFormerContractWidgets(filterCriteria : FilterCriteria[]) {

        this.personsContractFormerWidget = WidgetFactory.createWidgetList(
            "cm.contract.objects.navigation.persons.contract.former",
            "Personen",
            "list",
            "list",
            "entity",
            "ci.ContractPerson",
            "Keine versicherten Personen vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true,false,
                    ObjectRequestComplexNode.createRelationNode("person", ObjectRequestRelation.createJoin("person"))),
                filterCriteria,
                [],
                null)
        );

        this.objectsContractFormerWidget = WidgetFactory.createWidgetList(
            "cm.contract.objects.navigation.objects.contract.former",
            "Objekte",
            "list",
            "list",
            "entity",
            "ci.ContractInsurableObject",
            "Keine versicherten Objekte vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true,false,
                    ObjectRequestComplexNode.createRelationNode("insurableObject", ObjectRequestRelation.createJoin("insurableObject"))),
                filterCriteria,
                [],
                null)
        );


    }

}
