import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsTicketCountOutputInterface} from "./interface/mvs-ticket-count-output.interface";
import {DtoList, MvsCoreService, ObjectTypeService} from "@kvers/alpha-core-common";
import {TmTicketDto} from "../../../../../tm/dto/tm-ticket.dto";
import {TmStatusEnum} from "../../../../../tm/enum/tm-status.enum";
import {TicketService} from "../../../../../tm/service/api/ticket.service";

@Component({
    selector: 'mvs-ticket-count-output-component',
    templateUrl: './mvs-ticket-count-output.component.html',
})
export class MvsTicketCountOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsTicketCountOutputInterface {


    @Input() objectType: string;
    @Input() objectId: number;
    @Input() includeIndirect: boolean = true;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    pendingCount: number;
    completedCount: number;


    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected coreService: MvsCoreService,
                protected ticketService: TicketService,) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {

        this.initComponent();

    }

    getTickets() {
        this.ticketService.listTicketObjects(this.objectId, this.objectType, this.includeIndirect).subscribe((res: DtoList<TmTicketDto>) => {
            this.getTicketsCount(res.entries);
            // this.ticketListWidget(res);
            this.initialized = true;
        });
    }


    getTicketsCount(dtoList: TmTicketDto[]) {
        this.completedCount = 0;
        this.pendingCount = 0;

        for (let item of dtoList) {
            if (item.status == TmStatusEnum.resolved || item.status == TmStatusEnum.on_hold || item.status == TmStatusEnum.cancelled) {
                this.completedCount++;
            } else {
                this.pendingCount++;
            }
        }

    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectType = this.formField['uiConfigData']['objectType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];
        this.objectId = this.dto[objectIdField];
        const includeIndirectField = this.formField['uiConfigData']['includeIndirect'];
        this.includeIndirect = includeIndirectField ? includeIndirectField : true;


        this.getTickets();

    }

    onIconClick() {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectType, this.objectId, null, "Object", null, null, 'tm.Ticket');
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'left');
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
