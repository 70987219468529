import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PageComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";


@Component({
    selector: 'cm-contract-combined-form-create',
    templateUrl: './cm-contract-combined-form-create.page.html',
})
export class CmContractCombinedFormCreatePage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Vertragsseite";

    customerId: number;
    variation: 'Antrag' | 'Fremd' = 'Antrag';

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.route.params.subscribe(params => {
            if (params['customerId']) {
                this.customerId = +params['customerId'];
                this.refreshComponent();
            }
        })

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
