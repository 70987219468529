import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'am-agent-info-detail-object',
    templateUrl: './agent-info-detail-object.component.html',
})
export class AgentInfoDetailObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {


    agentWidget: WidgetData;
    agentPersonWidget: WidgetData;
    agentUserWidget: WidgetData;
    agentContactWidget: WidgetData;
    agentPoolAgentWidget: WidgetData;

    tabIndex: number = 0;


    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.refreshWidgets();
    }


    /**
     * refresh all widgets
     */
    refreshWidgets() {
        this.refreshAgentWidget();
        this.refreshAgentContactWidget();
        this.refreshAgentPoolAgentWidget();
        this.refreshAgentUserWidget();
        if (this.dto && this.dto['employeeDtoId']) {
            this.refreshAgentPersonWidget();
        }
    }

    /**
     * refresh agent widget
     */
    refreshAgentWidget() {
        this.agentWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.info.detail',
            'Agent',
            'am.Agent',
            this.objectIdentifier.objectId,
        );
    }

    /**
     * refresh agent contact widget
     */
    refreshAgentContactWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.agentContactWidget = WidgetFactory.createWidgetList(
            'am.agent.contact.info.detail',
            'Agent Contact',
            'list',
            'list',
            'entity',
            'am.AgentContact',
            'No data found',
            dtoRequest
        );
    }

    /**
     * refresh agent pool agent widget
     */
    refreshAgentPoolAgentWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.agentPoolAgentWidget = WidgetFactory.createWidgetList(
            'am.agent.pool.agent.info.detail',
            'Agent Pools',
            'list',
            'list',
            'entity',
            'am.AgentPoolAgent',
            'No data found',
            dtoRequest
        );
    }

    /**
     * refresh agent user widget
     */
    refreshAgentUserWidget() {

        this.agentUserWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.user.info.detail',
            'Agent User',
            'am.Agent',
            this.objectIdentifier.objectId,
        );

        this.agentUserWidget.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("user")),
            null,
            null,
            null,
            null);
    }

    /**
     * refresh agent person widget
     */
    refreshAgentPersonWidget() {

        this.agentPersonWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.person.info.detail',
            'Agent Person',
            'am.Agent',
            this.objectIdentifier.objectId,
        );

        this.agentPersonWidget.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("employee").addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person"))),
            null,
            null,
            null,
            null);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
