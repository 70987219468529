import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {AdConfigPage} from "./page/overview/ad-config/ad-config.page";
import {AdOverviewPage} from "./page/ad-overview-page/ad-overview.page";
import {AdStatisticsComponent} from "./page/ad-overview-page/components/statisticsComponent/ad-statistics.component";
import {AdKpiComponent} from "./page/ad-overview-page/components/kpiComponent/ad-kpi.component";
import {UiModule} from "../ui/ui.module";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {AgentKpiComponent} from "../am/page/agent-overview-page/components/kpiComponent/agent-kpi.component";
import {
    AgentStatisticsComponent
} from "../am/page/agent-overview-page/components/statisticsComponent/agent-statistics.component";


@NgModule({
    declarations: [
        AdConfigPage,
        AdOverviewPage,
        AdStatisticsComponent,
        AdKpiComponent
    ],
    exports: [
        AdConfigPage
    ],
    imports: [
        CoreModule,
        UiModule
    ]
})
export class AdModule implements DynamicModule {

    alias = 'ad';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return AdKpiComponent;
    }

    getStatisticsComponent() {
        return AdStatisticsComponent;
    }
}
