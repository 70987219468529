import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {xxUser} from "../interface/xx-user.interface";
// import {ObjectObserverService} from "../service/object-observer.service";
import {ObservedObject} from "../interface/observed-object";
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
    selector: 'xx-user-mock',
    templateUrl: './xx-user.component.html'
})
export class XxUserComponent implements OnInit, OnDestroy {

    @Input() user: xxUser;
    userSubscription: Subscription;
    objectIdentifier: ObjectIdentifier;

    constructor() {
    }

    ngOnInit(): void {
        this.objectIdentifier = new ObjectIdentifier('xxUser', this.user.id);

        // if (this.user) {
        //     this.userSubscription = this.subscribeToObserver().subscribe((observedObject: ObservedObject) => {
        //         this.refreshObject(observedObject);
        //     });
        // }
    }

    refreshObject(observedObject: ObservedObject) {
        const updatedObjectIdentifier = new ObjectIdentifier(observedObject.objectType, observedObject.objectId);
        const isRelevant = ObjectIdentifier.compare(this.objectIdentifier, updatedObjectIdentifier);
        if (isRelevant && observedObject.updatedData) {
            this.user = observedObject.updatedData;
        }

    }

    subscribeToObserver() {
        // return this.observerService.addToObserver(this.objectIdentifier);
    }

    // onMockChange(): void {
    //     if (this.user) {
    //         this.observerService.updateObject(this.objectIdentifier, {
    //             id: this.user.id,
    //             firstName: 'Haris',
    //             lastName: this.user.lastName
    //         });
    //     }
    // }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        // Remove observer for the user
        // if (this.user) {
        //     this.observerService.removeObserver(this.objectIdentifier);
        // }
    }
}
