import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-cm-insurable-object-page',
  templateUrl: './insurable-object.page.html',
  styleUrls: ['./insurable-object.page.scss']
})
export class InsurableObjectPage implements OnInit {
    objectBrowserRequest: any;

  constructor() { }

  ngOnInit(): void {

    this.objectBrowserRequest = {

      'extractDto'    : false,
      "createMode": true,


      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cm.InsurableObjectType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'processRecursion'  : true,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Zu versichernde Objekte",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "anlegen",
          'sortingList' : [
            {
              'name' : 'id',
              'ascending' : false
            }
          ]
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cm.InsurableObjectTypeField',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Datenfelder zu Objekten",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "anlegen"
        }
      ]

    }
  }

}
