import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {HgConfigPage} from "./page/overview/hc-config/hg-config.page";
import {
  HgGdvPartnerBaseComponent
} from "./component/hg-gdv-partner-object-component/view/hg-gdv-partner-base.component";
import {HgGdvPartnerObjectComponent} from "./component/hg-gdv-partner-object-component/hg-gdv-partner-object.component";
import {
  HgGdvPartnerSideComponent
} from "./component/hg-gdv-partner-object-component/view/side/hg-gdv-partner-side.component";
import {
  HgGdvPartnerFullComponent
} from "./component/hg-gdv-partner-object-component/view/full/hg-gdv-partner-full.component";
import {LgModule} from "../lg/lg.module";
import {EmModule} from "../em/em.module";
import {
  HgTestComponent
} from "./component/hg-gdv-partner-object-component/view/shared/hg-test-component/hg-test.component";


@NgModule({
  declarations: [
    HgConfigPage,
    HgGdvPartnerBaseComponent,
    HgGdvPartnerObjectComponent,
    HgGdvPartnerSideComponent,
    HgGdvPartnerFullComponent,
    HgTestComponent,

  ],
  exports:[
    HgConfigPage,
    HgGdvPartnerBaseComponent,
    HgGdvPartnerObjectComponent,
    HgGdvPartnerSideComponent,
    HgGdvPartnerFullComponent,
    HgTestComponent,
  ],
  imports: [
    CoreModule,
    LgModule,
    EmModule,

  ]
})
export class HgModule { }
 
 
 
