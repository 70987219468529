import {Route, Routes} from "@angular/router";
import {PcConfigPage} from "./page/overview/pc-config/pc-config.page";
import {PcMyCallsPage} from "./page/overview/pc-me/pc-my-calls/pc-my-calls.page";
import {PcQmCallControlPage} from "./page/overview/pc-qm/pc-qm-call-control/pc-qm-call-control.page";
import {PcMyTeamCallsPage} from "./page/overview/pc-team/pc-my-team-calls/pc-my-team-calls.page";
import {PcIncomingCallPage} from "./page/overview/pc-me/pc-incoming-call/pc-incoming-call.page";
import {PcTeamDashboardPage} from "./page/overview/pc-team/pc-team-dashboard/pc-team-dashboard.page";
import {PcQmCallsPage} from "./page/overview/pc-qm/pc-qm-calls/pc-qm-calls.page";
import {PcQmDashboardPage} from "./page/overview/pc-qm/pc-qm-dashboard/pc-qm-dashboard.page";
import {PcOverviewPage} from "./page/pc-overview-page/pc-overview.page";

export function PcSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     *
     * Use Cases (User Groups)
     * ------------------------------------------------------------------
     * View on myself:
     * <package>/me
     *
     * View on my Team	-> Access to my team information
     * <package>/team
     *
     * View on managed Team -> Access to managed team
     * <package>/mt
     *
     * Quality Management -> Full Access to all:
     * pc/qm
     *
     * Configuration Pages:
     * pc/config
     *
     * General rules, we only use lower case and use the - to separate
     * words within the link.
     *
     *
     *
     */
    const routes = [

        // {path: 'pc', component: PcOverviewPage},
        {path: 'pc/config', component: PcConfigPage},

        {path: 'pc/me', component: PcMyCallsPage},
        {path: 'pc/me-incoming-call', component: PcIncomingCallPage},

        {path: 'pc/team', component: PcMyTeamCallsPage},
        {path: 'pc/team-dashboard', component: PcTeamDashboardPage},  //TODO Change Component Name accordingly

        {path: 'pc/qm', component: PcQmCallsPage},                      //TODO same as PcQmCallsPage but without any agent pool/ agent restriction -> add agent Pool to selectable criteria
        {path: 'pc/qm-call-control', component: PcQmCallControlPage},         //TODO Adjust the component name
        {path: 'pc/qm-dashboard', component: PcQmDashboardPage},    //TODO Change Component Name accordingly

    ];

    return routes;

}
