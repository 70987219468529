import {Injectable} from '@angular/core';
import {WfActivityProcessingResultFieldDto} from "./dto/wf-activity-processing-result-field.dto";
import {MenuItem} from "primeng/api";
import {WfProcessTypeStepDto} from "../dto/wf-process-type-step.dto";
import {WfProcessMetaDto} from "./dto/wf-process-meta.dto";
import {WfProcessStepDto} from "../dto/wf-process-step.dto";
import {
    FilterCriteria,
    ObjectIdentifier,
    ObjectRequestList,
    PagingDto,
    Sorting,
    WidgetData,
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Injectable({
    providedIn: 'root'
})
export class WfProcessRuntimeService {

    //
    public static activityProcessingResult: WfActivityProcessingResultFieldDto[];


    public static resetResult(): void {
        this.activityProcessingResult = null;
    }

    public static setResult(result: WfActivityProcessingResultFieldDto[]) {
        this.activityProcessingResult = result;
    }

    /**
     * return all steps as MenuItems
     * @param processMeta
     */
    getSteps(processMeta: WfProcessMetaDto): MenuItem[] & { runtimeSteps: WfProcessTypeStepDto }[] {
        const menuItems: MenuItem[] & { runtimeSteps: WfProcessTypeStepDto }[] = [];

        for (let step of processMeta.steps.entries) {
            const stepItem: MenuItem & { step: WfProcessTypeStepDto } = {
                label: step.name,
                step: step
            };
            menuItems.push(stepItem);
        }

        return menuItems
    }

    /**
     * return active step
     * @param steps
     * @param processTypeStepDtoId
     */
    getActiveStep(steps: MenuItem[] & { runtimeSteps: WfProcessTypeStepDto }[], processTypeStepDtoId: number): number {
        return steps.findIndex((element: MenuItem & {
            step: WfProcessStepDto
        }) => element?.step?.id == processTypeStepDtoId);
    }

    /**
     * return process step widget
     * @param step
     */
    getProcessesStepWidget(step: WfProcessStepDto): WidgetData {
        const filterCriteriaList = [];

        filterCriteriaList.push(
            FilterCriteria.createSingleCondition(
                'processTypeStep',
                FilterCriteria.cOperatorEqual,
                step.processTypeStepDtoId,
                null));

        filterCriteriaList.push(
            FilterCriteria.createSingleCondition(
                'process',
                FilterCriteria.cOperatorEqual,
                step.processDtoId,
                null));

        const objectRequestList = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5)
        )

        return WidgetFactory.createWidgetTableEntity('wf.processed.steps.widget', 'Processes Steps', 'wf.WfProcessStep', '', objectRequestList);
    }

    /**
     * return completed process widget
     * @param objectIdentifier
     */
    getCompletedProcessWidget(objectIdentifier: ObjectIdentifier, typeId: number): WidgetData {
        return WidgetFactory.createWidgetEntityData(`wf.process.completed.workflow.data.${typeId}`, 'Completed Process', objectIdentifier.objectType, objectIdentifier.objectId,);
    }

    /**
     * return completed process steps list widget
     * @param objectId
     */
    getCompletedProcessListWidget(objectId: number, typeId: number): WidgetData {
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            [FilterCriteria.create('process', FilterCriteria.cOperatorEqual, objectId)],
            [new Sorting("createdDate", false)]
        );

        return WidgetFactory.createWidgetListEntity(`wf.process.completed.workflow.list.${typeId}`, 'Completed Process List', 'wf.WfProcessStep', 'No Data', objectRequestList);
    }


}
