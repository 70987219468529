import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {PcConfigPage} from "./page/overview/pc-config/pc-config.page";
import {PcCallDialComponent} from "./component/pc-call-dial/pc-call-dial.component";
import {PcCallActiveComponent} from "./component/pc-call-active/pc-call-active.component";
import {PcHeaderCallActiveComponent} from "./component/pc-header-call-active/pc-header-call-active.component";
import {
  PcCallWfProcessStepActivitySideComponent
} from "./component/wf/pc-call-wf-process-step-activity-side/pc-call-wf-process-step-activity-side.component";
import {PcCallComponent} from "./component/pc-call/pc-call.component";
import {PcCallPage} from "./page/object/pc-call-page/pc-call.page";
import {PcMyCallsPage} from "./page/overview/pc-me/pc-my-calls/pc-my-calls.page";
import {PcQmCallControlPage} from "./page/overview/pc-qm/pc-qm-call-control/pc-qm-call-control.page";
import {PcObjectCallsComponent} from "./component/pc-object-calls/pc-object-calls.component";
import {PcMyTeamCallsPage} from "./page/overview/pc-team/pc-my-team-calls/pc-my-team-calls.page";
import {PcIncomingCallPage} from "./page/overview/pc-me/pc-incoming-call/pc-incoming-call.page";
import {PcTeamDashboardPage} from "./page/overview/pc-team/pc-team-dashboard/pc-team-dashboard.page";
import {
  PcCallIncomingAtObjectComponent
} from "./component/pc-call-incoming-at-object/pc-call-incoming-at-object.component";
import {PcQmCallsPage} from "./page/overview/pc-qm/pc-qm-calls/pc-qm-calls.page";
import {PcQmDashboardPage} from "./page/overview/pc-qm/pc-qm-dashboard/pc-qm-dashboard.page";
import {PcOverviewPage} from "./page/pc-overview-page/pc-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {
  NotificationKpiComponent
} from "../ns/page/notification-overview-page/components/kpiComponent/notification-kpi.component";
import {
  NotificationStatisticsComponent
} from "../ns/page/notification-overview-page/components/statisticsComponent/notification-statistics.component";
import {PcStatisticsComponent} from "./page/pc-overview-page/components/statisticsComponent/pc-statistics.component";
import {PcKpiComponent} from "./page/pc-overview-page/components/kpiComponent/pc-kpi.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
  declarations: [
    PcConfigPage,
    PcCallDialComponent,
    PcCallIncomingAtObjectComponent,
    PcCallActiveComponent,
    PcHeaderCallActiveComponent,
    PcCallWfProcessStepActivitySideComponent,
    PcCallComponent,
    PcCallPage,
    PcMyCallsPage,
    PcQmCallControlPage,
    PcObjectCallsComponent,
    PcMyTeamCallsPage,
    PcIncomingCallPage,
    PcTeamDashboardPage,
    PcQmCallsPage,
    PcQmDashboardPage,
    PcOverviewPage,
    PcStatisticsComponent,
    PcKpiComponent
  ],
  exports:[
    PcConfigPage,
    PcCallDialComponent,
    PcCallIncomingAtObjectComponent,
    PcCallActiveComponent,
    PcHeaderCallActiveComponent,
    PcCallWfProcessStepActivitySideComponent,
    PcCallComponent,
    PcCallPage,
    PcMyCallsPage,
    PcQmCallControlPage,
    PcObjectCallsComponent,
    PcMyTeamCallsPage,
    PcIncomingCallPage,
    PcTeamDashboardPage,
    PcQmCallsPage,
    PcQmDashboardPage,
  ],
    imports: [
        CoreModule,
      UiModule,

    ]
})
export class PcModule implements DynamicModule {

  alias = 'pc';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return PcKpiComponent;
  }

  getStatisticsComponent() {
    return PcStatisticsComponent;
  }

}

