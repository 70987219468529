import {Component, Input} from '@angular/core';
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {UiNavigationFrequentPagesComponent} from "../ui-navigation-frequent-pages/ui-navigation-frequent-pages.component";
import {UiPageUserService} from "../../service/api/ui-page-user.service";
import {Observable} from "rxjs";

@Component({
    selector: 'ui-navigation-last-visits',
    templateUrl: '../ui-navigation-frequent-pages/ui-navigation-frequent-pages.component.html',
})
export class UiNavigationLastVisitsComponent extends UiNavigationFrequentPagesComponent{

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    icon: string = 'fa-regular fa-clock-rotate-left';

    @Input() uiNavigationMainDto! : UiNavigationMainDto;


    // service needs to provide an UiPageVisitResponseDto[]
    constructor(protected uiPageUserService: UiPageUserService,) {
        super(uiPageUserService);
    }

    getData(): Observable<any> {
        return this.uiPageUserService.getLastVisits(30);
    }

}
