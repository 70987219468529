import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {FieldService} from "../../../../service/api/field.service";
import {FieldTypeService} from "../../../../service/api/field-type.service";
import {ObjectTypeService} from "../../../../service/api/object-type.service";
import {ObjectTypeAttributeService} from "../../../../service/api/object-type-attribute.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'cc-kpi.component.html',
})
export class CcKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalField: number = 0;
    totalFieldTypes: number = 0;
    totalAttributes: number = 0;
    totalObjectTypes: number = 0;

    kpiHeaderDto: KpiHeaderDto[];

    items: MenuItem[] | undefined = [{label: 'CC Module'}];

    constructor(
        protected fieldService: FieldService,
        protected fieldTypeService: FieldTypeService,
        protected objectTypeService: ObjectTypeService,
        protected objectTypeAttributeService: ObjectTypeAttributeService,) {
        super()
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalFields();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    getTotalFields() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['type'],
            [new ObjectRequestListAttribute('type', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.fieldService.groupBy(objectRequestList).subscribe(res => {
            this.totalField = res.entries.reduce((acc, item) => acc + item['processGroup_count'], 0);
            this.getTotalFieldTypes();
            },error => {
            this.getTotalFieldTypes();
        });
    }

    getTotalFieldTypes() {
        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.fieldTypeService.list(objectRequestList).subscribe(res => {
            this.totalFieldTypes = res.entries.length;
            this.getTotalObjects();
        },error => {
            this.getTotalObjects();
        });
    }


    getTotalObjects() {
        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.objectTypeService.list(objectRequestList).subscribe(res => {
            this.totalObjectTypes = res.entries.length;
            this.getTotalAttributes();
        },error => {
            this.getTotalAttributes();
        });

    }

    getTotalAttributes() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['objectType'],
            [new ObjectRequestListAttribute('objectType', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.objectTypeAttributeService.groupBy(objectRequestList).subscribe(res => {
            this.totalAttributes = res.entries.reduce((acc, item) => acc + item['objectType_count'], 0);
            this.initKpiHeaderDto();
            },error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {
        this.kpiHeaderDto = [
            {
                label: 'Total Fields', value: this.totalField,
            },
            {
                label: 'Total Field Type', value: this.totalFieldTypes,
            },
            {
                label: 'Total Object Type', value: this.totalObjectTypes,
            },
            {
                label: 'Total Attributes', value: this.totalAttributes,
            }
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
