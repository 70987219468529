import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-pc-object-calls',
    templateUrl: './pc-object-calls.component.html',
})
export class PcObjectCallsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    widgetData: WidgetData;

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() aliasSuffix: any = "";
    @Input() widget: string = "list";

    constructor(protected objectTypeService: ObjectTypeService) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    async refreshComponent() {

        const viaObjectType = await this.objectTypeService.getViaObjectType(this.objectIdentifier.objectType);

        const filterCriteria = FilterCriteria.createAnd(
            FilterCriteria.create("objectType", FilterCriteria.cOperatorEqual, viaObjectType.id),
            FilterCriteria.create("objectId", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId));

        this.widgetData = new WidgetData();
        this.widgetData.idAlias = "pc.object.calls.list." + this.objectIdentifier.objectType + "." + this.widget + "." + this.aliasSuffix;
        this.widgetData.name = "Anrufe";
        this.widgetData.uiComponent = this.widget;
        this.widgetData.dataProvider = "list";
        this.widgetData.dataSource = "entity";
        this.widgetData.dataProviderObject = "pc.Call";

        this.widgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)]
        );

        this.widgetData.dataProviderListRequest.objectRequestComplex =
            ObjectRequestComplex.build(
                true,
                false,
                ObjectRequestComplexNode.createRelationNode("lastProtocol", ObjectRequestRelation.createLatest("pc.CallProtocol", "call")));

        this.widgetData.setParamValue("size", "S");
        this.widgetData.setParamValue("objectWidget", "data");


        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
