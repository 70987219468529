<div (click)="handleBackToTicket()">
    <i class="fa-solid fa-chevron-left"></i> Back To Ticket
</div>
<hr>

<div class="confirmation-container">
    <h2>Confirmation!</h2>

    <!-- Complete Status Dropdown -->
    <div class="form-group">
        <label for="complete-status">Complete Status</label>
        <p-dropdown
                [options]="statusOptions"
                [(ngModel)]="selectedStatus"
                placeholder="Select status"
                id="complete-status"
                styleClass="w-full">
        </p-dropdown>
    </div>

    <!-- Completion Date Input -->
    <div class="form-group">
        <label for="completion-date">Completion Date</label>
        <input
                pInputText
                type="text"
                [(ngModel)]="completionDate"
                id="completion-date"
                [disabled]="true">
    </div>

    <!-- Comments Text Area -->
    <div class="form-group">
        <label for="comments">Comments</label>
        <textarea
                pInputTextarea
                [(ngModel)]="comments"
                id="comments"
                placeholder="Write your comment..."></textarea>
    </div>
</div>
