import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier, PageComponent, WidgetHelperButton} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {GroupItem} from "../../interfaces/mvs-dashboard-group-item.interface";
import {OptionGroups} from "../../interfaces/mvs-dashboard-option-groups.interface";
import {MainSelection} from "../../interfaces/mvs-dashboard-main-selection.interface";
import {SubSelection} from "../../interfaces/mvs-dashboard-sub-selection.interface";
import {WidgetData} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";

/**
 * Lifecycle:
 *
 *      ngOnInit ->
 *              initComponent
 *                  -> refreshTopNavigation -> getTopNavigationObservable
 *                      -> ASYNC storeTopNavigation()
 *                              -> loadOptionGroups -> getLoadOptionGroupsObservable
 *                                  -> ASYNC storeOptionGroups()
 *
 *
 *
 *
 */
@Component({
    selector: 'mvs-dashboard-page-extended',
    templateUrl: './mvs-dashboard.page.html',
    styleUrls: ['./mvs-dashboard.page.scss']
})
export class MvsDashboardPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    // Properties for default label, keys, collapsed state, and widget data
    defaultLabel: string
    optionKey: number;
    subSelectionKey: string;
    mainSelectionKey: number;
    typeFilterSelectionKey: number;
    isCollapsed: boolean;
    widgetData: WidgetData;
    postInitialized: boolean;


    // Arrays for available options and current selections
    mainSelections: MainSelection[];
    subSelections: SubSelection[];
    typeFilterSelections: GroupItem[];
    optionGroups: OptionGroups[];
    widgetToolbarButtons: WidgetHelperButton[];

    // Current selection objects
    mainSelection: MainSelection;
    subSelection: SubSelection;
    typeFilterSelection: GroupItem;
    optionGroupSelection: GroupItem;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService
    ) {
        super(route, coreService);

        // Subscribe to route changes
        this.route.queryParams.subscribe((params) => {

            // Determine changes in various navigation keys
            const mainNavigationChange = (this.mainSelectionKey != params['mainSelection']);
            const subNavigationChanged = (this.subSelectionKey != params['subSelection']);
            const sideNavigationChange = (this.optionKey != params['option']);
            const rightNavigationChange = (this.typeFilterSelectionKey != params['typeFilter']);

            // Access and store the keys
            this.subSelectionKey = params['subSelection'];
            this.mainSelectionKey = params['mainSelection'];
            this.typeFilterSelectionKey = params['typeFilter'];
            this.optionKey = params['option'];


            // If the component is already initialized, perform necessary refreshes
            if (this.initialized) {

                if (mainNavigationChange) {
                    this.refreshMainNavigationUi();
                    this.loadSubNavigation();
                } else if (subNavigationChanged) {
                    this.refreshSubNavigationUi();
                    this.loadTopRightNavigation();
                } else if (rightNavigationChange) {
                    this.refreshTopRightNavigationUi();
                    this.loadOptionGroups();
                } else if (sideNavigationChange) {
                    this.refreshWidget();
                }

            }

        });

    }

    /**
     * Method to refresh the widget
     */
    refreshWidget() {

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.startInit();
    }

    /**
     * Method to initiate component initialization
     */
    startInit() {
        this.initComponent();
    }

    /**
     * Method to initialize the component
     */
    initComponent() {


        if (this.mainSelectionKey == 0 && this.subSelectionKey == 'g_0' && (!this.optionKey || this.optionKey == -1)) {
            this.refreshWidget();
        } else {
            this.postInitialized = true;
        }

        this.loadMainNavigation();
    }

    /**
     * Method to load main navigation options
     */
    loadMainNavigation() {
        const loadTopLeftObservable = this.getMainNavigationObservable();

        if (loadTopLeftObservable) {
            loadTopLeftObservable.subscribe(value => {
                this.storeMainOptions(value);
                this.refreshMainNavigationUi();
                this.loadSubNavigation();

            });
        } else {
            this.storeMainOptions(null);
            this.refreshMainNavigationUi();
            this.loadSubNavigation();
        }
    }

    /**
     * Method to refresh sub-navigation
     */
    loadSubNavigation() {
        this.getSubNavigationObservable().subscribe(value => {

            // store top navigation
            this.storeSubNavigation(value);

            this.refreshSubNavigationUi();


            // update UI
            this.loadTopRightNavigation();

        });
    }

    /**
     * Method to load top-right navigation
     */
    loadTopRightNavigation() {
        const loadTopRightObservable = this.getTopRightNavigationObservable();

        if (loadTopRightObservable) {
            loadTopRightObservable.subscribe(value => {
                this.storeTopRightOptions(value);
                this.refreshTopRightNavigationUi();

                // load option groups
                this.loadOptionGroups();

            });
        } else {

            this.storeTopRightOptions(null);
            this.refreshTopRightNavigationUi();
            // load option groups
            this.loadOptionGroups();
        }
    }

    /**
     * Method to load option groups
     */
    loadOptionGroups() {

        const loadOptionGroupsObservable = this.getOptionGroupsObservable();

        if (loadOptionGroupsObservable) {
            loadOptionGroupsObservable.subscribe(value => {
                this.storeOptionGroups(value);

                this.refreshOptionGroupsUi();

                if (this.postInitialized) {
                    this.refreshWidget();
                }

                this.postInitialized = true;

                this.refreshComponent();

            });
        }

    }


    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getMainNavigationObservable(): Observable<any> {
        alert("getMainNavigationObservable implementation missing");
        return null;
    }

    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getSubNavigationObservable(): Observable<any> {
        alert("getSubNavigationObservable implementation missing");
        return null;
    }

    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getTopRightNavigationObservable(): Observable<any> {
        alert("getTopRightNavigationObservable implementation missing");
        return null;
    }

    /**
     * Abstract method to get the observable for loading option groups
     */
    getOptionGroupsObservable(): Observable<any> {
        alert("getOptionGroupsObservable implementation missing");
        return null;
    }

    /**
     * Abstract method to store main navigation
     * @param value
     */
    storeMainOptions(value: any) {
        alert("storeMainOptions implementation missing");
        return null;
    }

    /**
     * Abstract method to store sub navigation
     * @param value
     */
    storeSubNavigation(value: any) {
        alert("storeSubNavigation implementation missing");
        return null;
    }

    /**
     * Abstract method to store top right options
     * @param value
     */
    storeTopRightOptions(value: any) {
        alert("storeTopRightOptions implementation missing");
        return null;
    }

    /**
     * Abstract method to store option groups
     * @param value
     */
    storeOptionGroups(value: any) {
        alert("storeOptionGroups implementation missing");
        return null;
    }

    /**
     * Method to refresh UI for main navigation
     */
    refreshMainNavigationUi() {

        if (this.mainSelections && this.mainSelections.length) {
            this.mainSelection = this.findAndSet(this.mainSelections, this.mainSelectionKey, event => this.handleMainSelectionChange(event));
            this.subSelection = null;

            if (!this.mainSelection && this.mainSelections.length > 0 && this.mainSelections[0]) {
                this.mainSelection = this.mainSelections[0];
            }
        }
    }

    /**
     * Method to refresh UI for sub-navigation
     */
    refreshSubNavigationUi() {

        this.subSelection = this.findAndSet(this.subSelections, this.subSelectionKey, event => this.handleSubSelectionChange(event));
        this.typeFilterSelection = null;
        this.optionGroupSelection = null;
    }

    /**
     * Method to refresh UI for top-right navigation
     */
    refreshTopRightNavigationUi() {
        if (this.typeFilterSelections && this.typeFilterSelections.length) {
            this.typeFilterSelection = this.findAndSet(this.typeFilterSelections, this.typeFilterSelectionKey, event => this.handleFilterSelectionChange(event));
            this.optionGroupSelection = null;
        }
    }

    /**
     * Method to refresh UI based on option groups
     */
    refreshOptionGroupsUi() {

        // if (!this.initialized) {
        //     return;
        // }

        if (this.optionGroups && this.optionGroups.length > 0) {
            for (let optionGroup of this.optionGroups) {
                for (let groupItem of optionGroup.groupItems) {
                    if (groupItem.key == this.optionKey) {
                        this.optionGroupSelection = groupItem;
                        break;
                    }
                }
            }
        }

        if (!this.optionGroupSelection && this.optionGroups.length > 0 && this.optionGroups[0].groupItems && this.optionGroups[0].groupItems.length > 0) {
            this.optionGroupSelection = this.optionGroups[0].groupItems[0];
        }

    }


    /**
     * Method to find and set an entry based on the key
     * @param entriesArray
     * @param selectedKey
     * @param navigateFunction
     */
    findAndSet(entriesArray: any[], selectedKey: any, navigateFunction: (event: any) => void): any {

        let selectedEntry = null;

        if (entriesArray && selectedKey) {
            selectedEntry = this.findEntry(entriesArray, selectedKey);
            // selectedEntry = entriesArray.find(value => value["key"] == selectedKey);
        }

        if (!selectedEntry && entriesArray && entriesArray.length > 0) {
            selectedEntry = entriesArray[0];
            navigateFunction(selectedEntry);
        }

        return selectedEntry;

    }

    /**
     * Method to find an entry based on the key in a nested array
     * @param entriesArray
     * @param selectedKey
     */
    findEntry(entriesArray, selectedKey) {
        let foundEntry = null;

        function searchInSubmenus(subMenus: SubSelection[]) {
            if (!subMenus) {
                return;
            }

            for (const submenu of subMenus) {

                if (submenu.key == selectedKey) {
                    foundEntry = submenu;
                    break;  // Break out of the loop if the entry is found
                }
                if (submenu.items) {
                    searchInSubmenus(submenu.items);
                }
            }
        }

        for (const entry of entriesArray) {

            if (entry.key == selectedKey) {
                foundEntry = entry;
                break;  // Break out of the loop if the entry is found
            }

            if (entry.items) {
                searchInSubmenus(entry.items);
            }

            if (foundEntry) {
                break;  // Break out of the loop if the entry is found in submenus
            }
        }

        return foundEntry;
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Method to handle main selection change
     * @param event
     */
    handleMainSelectionChange(event: MainSelection) {


        let subSelectionKey = null;

        if (this.subSelections && this.subSelections.length) {
            subSelectionKey = this.subSelections[0].key
        }

        // Add mainSelection key to the route
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {mainSelection: event.key, subSelection: subSelectionKey, option: null, typeFilter: -1},
            queryParamsHandling: 'merge',
        });
    }

    /**
     * Method to handle sub selection change
     * @param event
     */
    handleSubSelectionChange(event: SubSelection) {

        if (!this.subSelectionKey) {
            this.subSelectionKey = event.key;
        }


        // Add subSelection key to the route
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {subSelection: event.key, option: null, typeFilter: -1},
            queryParamsHandling: 'merge',
        });
    }

    /**
     * Method to handle filter selection change
     * @param event
     */
    handleFilterSelectionChange(event: GroupItem) {

        // Add subSelection key to the route
        if (event) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {typeFilter: event.key, option: null},
                queryParamsHandling: 'merge',
            });
        } else {
            // Add subSelection key to the route
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {typeFilter: null, option: null},
                queryParamsHandling: 'merge',
            });
        }
    }

    /**
     * Method to handle option group selection change
     * @param event
     */
    handleOptionGroupSelectionChange(event: GroupItem) {

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {option: event.key},
            queryParamsHandling: 'merge',
        });
    }


    /**
     * Method to handle collapse state change
     * @param isCollapsed
     */
    onCollapseStateChange(isCollapsed: boolean) {
        this.isCollapsed = isCollapsed;
    }

    handleRowAction(object: ObjectIdentifier, location: 'left' | 'right' | 'bottom' | 'main' = 'right', openObject: boolean = false) {

        if (!openObject) {
            return;
        }

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(object.objectType, object.objectId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, location);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }


        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleToolbarButtonClick(event: WidgetHelperButton) {
    }


}
