import { Component, OnInit } from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-cr-customer-onboarding-type-page',
  templateUrl: './customer-onboarding-type.page.html',
  styleUrls: ['./customer-onboarding-type.page.scss']
})
export class CustomerOnboardingTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Customer Onboarding Config";

  activeIndex = 0;

  customerOnboardingTypeWidget: WidgetData;
  customerOnboardingTypeContractTypeWidget: WidgetData;
  customerOnboardingTypeInfoStepWidget: WidgetData;
  objectDataWidget: WidgetData;

  ngOnInit(): void {

    super.ngOnInit();

    this.refreshWidgets();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerOnboardingType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Onboarding Type",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Onboarding Type"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerOnboardingTypeContractType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Default Contract Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Contract Type"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'cr.CustomerOnboardingTypeInfoStep',
          'level'             : 1,
          'parentBinding'     : 'none',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Info Steps",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Info Step"
        },
      ]
    };

    this.subObjectBrowserRequest = null;

  }

  refreshWidgets() {
    this.refreshCustomerOnboardingTypeWidget();
    this.refreshCustomerOnboardingTypeContractTypeWidget();
    this.refreshCustomerOnboardingTypeInfoStepWidget();
  }


  refreshCustomerOnboardingTypeWidget() {
    this.customerOnboardingTypeWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.onboarding.type.widget',
        'Onboarding Type',
        'cr.CustomerOnboardingType',
        'No Data',
        this.getObjectRequestList());
    this.customerOnboardingTypeWidget.uiComponent = 'selectable';
  }


  refreshCustomerOnboardingTypeContractTypeWidget() {
    this.customerOnboardingTypeContractTypeWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.onboarding.type.contract.type.widget',
        'Default Contract Types',
        'cr.CustomerOnboardingTypeContractType',
        'No Data',
        this.getObjectRequestList());
    this.customerOnboardingTypeContractTypeWidget.uiComponent = 'selectable';
  }

  refreshCustomerOnboardingTypeInfoStepWidget() {
    this.customerOnboardingTypeInfoStepWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.onboarding.type.info.step.widget',
        'Info Steps',
        'cr.CustomerOnboardingTypeInfoStep',
        'No Data',
        this.getObjectRequestList());
    this.customerOnboardingTypeInfoStepWidget.uiComponent = 'selectable';
  }


  getObjectRequestList() {
    return ObjectRequestList.createBasic(true, [], []);
  }

  handleObjectSelect(event: ObjectIdentifierData) {

    this.objectDataWidget = WidgetFactory.createWidgetEntityData(
        `${event.objectType}.cr.customer.onboarding.widget`,
        '',
        event.objectType,
        event.objectId
    )

  }

  clearWidgets() {
    this.objectDataWidget = null;
  }
}
