import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {
  CmCreateInsurableObjectPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person/cm-create-insurable-object-person.component";
import {CiContractPersonComponent} from "../../components/ci-contract-person-component/ci-contract-person.component";
import {
  CmCreateInsurableObjectPersonRelationPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person-relation-person/cm-create-insurable-object-person-relation-person.component";

@Injectable({
  providedIn: 'root'
})
export class ContractPersonService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ci/contractPersons')
  }

  getContractPersonComplexSelection(): ObjectRequestComplex {
    return ObjectRequestComplex.build(true, false,
        ObjectRequestComplexNode.createSimpleAttributeNode("person")
            .addNode(ObjectRequestComplexNode.createSimpleAttributeNode("personDetail"))
    );
  }


  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {

    if (mode == MvsCrudModeEnum.create) {
      // return CmCreateInsurableObjectPersonComponent;
      return CmCreateInsurableObjectPersonRelationPersonComponent;
    } else if (mode == MvsCrudModeEnum.update) {
      return CiContractPersonComponent;
    }

    return null;
  }

}