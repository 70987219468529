import {MenuItem} from "primeng/api";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {variableTypesEnum} from "./component/te-editor/te-editor.component";
import {
    ConditionsHelper
} from "./component/te-editor/components/table-info-conditions/conditions-helper/conditions-helper";

export interface TeItem extends MenuItem {
    label?: string;
    template?: string;
    startTemplate?: string;
    endTemplate?: string;
}

export interface TeMenuItem extends MenuItem {
    label?: string;
    items?: TeItem[];
}

export class TeEditorExpressions {
    ifOptions: TeMenuItem[];
    forOptions: TeMenuItem[];
    teVariables: TeMenuItem[];
    tableIfOptions: TeMenuItem[];
    loopVariable: string;

    constructor() {
        this.ifOptions = [
            {
                label: "if Conditions",
                items: [
                    {
                        label: "== Equal",
                        // used &#47 character code instead of forward slash because editor consider that it's a closing tag with </
                        template: '<#if XXX == "XXX">{{list}}\n\n&lt;&#47#if&gt;',
                        // template: '<span data-info="dataObject"><#if XXX == "XXX"> {{list}}\n\n&lt&#47#if&gt</span>',
                    },
                    {
                        label: "!= Equal",
                        template: '<#if XXX != "XXX">\n\n<&#47#if>',
                    },
                    {
                        label: "< Lower Then",
                        template: '<#if XXX lt "XXX">\n\n<&#47#if>',
                    },
                    {
                        label: "<= Lower Or Equal",
                        template: '<#if XXX lte "XXX">\n\n<&#47#if>',
                    },
                    {
                        label: "> Greater Then",
                        template: '<#if XXX gt "XXX">\n\n<&#47#if>',
                    },
                    {
                        label: ">= Greater Or Equal",
                        template: '<#if XXX gte "XXX">\n\n<&#47#if>',
                    },
                    {
                        label: "Not Null",
                        template: '<#if XXX ??>\n\n<&#47#if>',
                    },
                    {
                        label: "Null & Not Null",
                        template: '<#if XXX ??>\nNot Null\n<#else>\nNull\n<&#47#if>',
                    },

                ],
            },
            {
                label: "if-else Conditions",
                items: [
                    {
                        label: "== Equal",
                        template:
                            '<#if XXX == "XXX">\nXXX found\n<#else>\nNo XXX found\n<&#47#if>',
                    },
                    {
                        label: "!= Not Equal",
                        template:
                            '<#if XXX != "XXX">\nXXX found\n<#else>\nNo XXX found\n<&#47#if>',
                    },
                    {
                        label: "< Lower Than",
                        template:
                            '<#if XXX lt "XXX">\nLower\n<#else>\nGreater\n<&#47#if>',
                    },
                    {
                        label: "<= Lower Than Equal",
                        template:
                            '<#if XXX lte "XXX">\nLower and equal\n<#else>\nGreater\n<&#47#if>',
                    },
                    {
                        label: "> Greater Than",
                        template:
                            '<#if XXX gt "XXX">\nGreater\n<#else>\nLower\n<&#47#if>',
                    },
                    {
                        label: ">= Greater Or Equal",
                        template:
                            '<#if XXX gte "XXX">\nGreater and equal\n<#else>\nLower\n<&#47#if>',
                    },
                    {
                        label: "Null",
                        template: '<#if XXX ??>\nNot Null\n<#else>\nNull\n<&#47#if>',
                    },
                ],
            },
        ];


        this.tableIfOptions = [{
            label: "if Conditions",
            items: [{
                label: "== Equal",
                startTemplate: '<#if XXX == "XXX">\n\n',
                endTemplate: '<&#47#if>'
            }]
        }];


        this.forOptions = [
            {
                label: "loop Iterations",
                items: [
                    {
                        label: "Create Table",
                        template: '<#list users as x>',
                    },
                    {
                        label: "For Loop",
                        template: '<#list users as x>\n<p>${x}\n<#else>\n<p>No users\n<p>&lt;&#47#list&gt;',
                    },
                ],
            },
        ];
        this.teVariables = [
            {
                label: "Customer",
                items: [
                    {
                        label: "Identification",
                    },
                    {
                        label: "LastLogin",
                    },
                    {
                        label: "Username",
                    },
                    {
                        label: "Verfication Stamp",
                    },
                ],
            },
            {
                label: "Person",
                items: [
                    {
                        label: "Last Name",
                    },
                    {
                        label: "First Name",
                    },
                    {
                        label: "Birth Name",
                    },
                    {
                        label: "Birth Date",
                    },
                    {
                        label: "Age",
                    },
                ],
            },
            {
                label: "CustomerAddress",
                items: [
                    {
                        label: "Address",
                    },
                ],
            },
        ];
    }


    replaceVariable(template?: string, dragNodeValue?: any, dataType?: any, dragNode?: any, attributeNodes?: string): string {
        let replaceDataType: string;
        if (dataType === 'number') {
            replaceDataType = template.replace('"XXX"', '0');

            let newValue: string = '';

            const paths = dragNodeValue.split('.');
            if (paths.length <= 2) {
                newValue = dragNodeValue;
            } else {
                let firstDotIndex = dragNodeValue.indexOf('.');
                newValue = attributeNodes.slice(firstDotIndex + 1);
            }
            newValue = `${newValue}?number`;
            const conditionsWithChecks = ConditionsHelper.insertNullChecks(newValue);
            return replaceDataType.replace('XXX', conditionsWithChecks);
        // } else if (dragNode["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany && attributeNodes) {
        } else if ((dragNode["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany || dragNode["node"].parent.data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany) && attributeNodes) {

            let lastDotIndex = attributeNodes.lastIndexOf('.');
            let lastNode = attributeNodes.slice(lastDotIndex + 1, attributeNodes.length);
            this.loopVariable = lastNode.charAt(0).toLowerCase() + lastNode.slice(1);
            let replaceLoopVariable = template.replace('x', this.loopVariable);

            return replaceLoopVariable.replace('users', dragNodeValue);
        }

        const conditionsWithChecks = ConditionsHelper.insertNullChecks(dragNodeValue);

        return template.replace('XXX', conditionsWithChecks);
    }

    createTemplateVariable(attributeNodes: string, dataType: string, mode: variableTypesEnum): string {
        let draggedNode;
        if (mode == variableTypesEnum.SIMPLE) {
            draggedNode = this.createSimpleTemplateVariable(attributeNodes, dataType);
        } else if (mode == variableTypesEnum.CHANGEABLE) {
            draggedNode = this.createChangeableTemplateVariable(attributeNodes, dataType);
        } else if (mode == variableTypesEnum.CHECK_NULL) {
            draggedNode = this.createCheckNullTemplateVariable(attributeNodes);
        } else if (mode == variableTypesEnum.DATE_FIELD) {
            draggedNode = this.createDateFieldTemplateVariable(attributeNodes);
        }
        return draggedNode;
    }

    createSimpleTemplateVariable(attributeNodes: string, dataType: string): string {
        let concatCurlyBracesWithAttributeNodes;
        if (dataType == 'Date') {
            concatCurlyBracesWithAttributeNodes = `<span id="kTemplateVariable" contenteditable="false" ><span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">{{</span>${attributeNodes}<span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">!"Date Not Available"?string("dd.MM.yyyy")}}</span></span>`;
        } else {
            concatCurlyBracesWithAttributeNodes = `<span id="kTemplateVariable" contenteditable="false" ><span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">{{</span>${attributeNodes}<span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">}}</span></span>`;
        }

        return concatCurlyBracesWithAttributeNodes;
    }

    createChangeableTemplateVariable(attributeNodes: string, dataType: string): string {
        let concatCurlyBracesWithAttributeNodes;
        if (dataType == 'Date') {
            concatCurlyBracesWithAttributeNodes = this.createDateFieldTemplateVariable(attributeNodes);
        } else {
            concatCurlyBracesWithAttributeNodes ='${' + attributeNodes + '}';
        }
        return concatCurlyBracesWithAttributeNodes;
    }

    createCheckNullTemplateVariable(attributeNodes: string): string {

        const concatCurlyBracesWithAttributeNodes ='${' + attributeNodes + '!"Exception Text"}';

        return concatCurlyBracesWithAttributeNodes;
    }

    createDateFieldTemplateVariable(attributeNodes: string): string {
        let concatCurlyBracesWithAttributeNodes = '${' + attributeNodes + '!"Date Not Available"?string("dd.MM.yyyy")}';

        return concatCurlyBracesWithAttributeNodes;
    }


}
