<editor
    #editor
    [(ngModel)]="content"
    [init]="initOptions"
></editor>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<!-- Buttons -->
<button (click)="saveContent()">Save</button>
<button (click)="loadContent()">Load</button>

