import { Injectable } from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCrudService,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy
} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TicketRelTicketService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketRelTickets');
  }

  public listSubtasksCount(ticketId: number): Observable<number> {

    const filterCriteria = FilterCriteria.createAnd(
        FilterCriteria.create("ticket", FilterCriteria.cOperatorEqual, ticketId),
    );

    const request: ObjectRequestListGroupBy = ObjectRequestListGroupBy.create(
        false,
        filterCriteria,
        [],
        ["ticket"],
        [new ObjectRequestListAttribute("ticket", "count", DtoListAttributeRequestAggregateEnum.count)]
    );

    return this.groupBy(request).pipe(
        map(result => {
          if (!result || !result.entries || result.entries.length == 0 || !result.entries[0] || !result.entries[0]["ticket_count"]) {
            return 0;
          } else {
            return result.entries[0]["ticket_count"];
          }
        })
    );

  }
}
