<div class="flex flex-column px-3">
    <div class="flex justify-content-between align-items-center my-2">
        <span class="text-lg text-900 font-normal">Tags</span>
        <span class="flex gap-3">
        <span class="cursor" *ngFor="let tag of mostlyUsedTags" (click)="createTag(tag['tag'])"><p-chip
             styleClass="surface-200 text-800 text-sm"   [label]="tag['tag']"></p-chip></span>
    </span>
    </div>
    <div class="formgroup-inline">
        <div class="col-12 p-0">
            <p-chips [(ngModel)]="tags" (onAdd)="createTag($event.value)" [disabled]="busy" [styleClass]="'w-full'"
                     (onRemove)="removeTag($event.value)">
                <ng-template let-item pTemplate="item">
                    {{item.tag}}
                </ng-template>
            </p-chips>
        </div>
    </div>

</div>