import {Component, OnInit, SimpleChanges} from "@angular/core";
import {
    FilterCriteria,
    MvsCoreService,
    MvsMessageService,
    NavigationItem,
    ObjectBaseModeComponent,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestRelation,
    ObserverService,
    WidgetData
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {RoleDto} from "../../../../dto/role.dto";
import {ConfirmationService, MenuItem} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {RoleService} from "../../../../service/api/role.service";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: "um-role-object-view",
    template: ''
})

export class UmRoleObjectBaseComponent extends ObjectBaseModeComponent implements OnInit {

    busy: boolean; // Indicator whether the component is busy
    initialized: boolean; // Indicator whether the component was initialized

    roleAuthConfigWidget: WidgetData;
    roleAuthModuleWidget: WidgetData;
    roleAuthObjectTypeWidget: WidgetData;
    roleAuthContractWidget: WidgetData;
    roleAuthCustomerWidget: WidgetData;

    queryParamSubscription: Subscription;
    navigationItems: NavigationItem[];

    roleDto: RoleDto;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected roleService: RoleService,
        protected router: Router,
        protected observerService: ObserverService
    ) {
        super(route);
    }

    ngOnInit(): void {

        this.handleNavigationItems();
        this.onNavigationItems.emit(this.navigationItems);
        super.ngOnInit();


        this.initComponent();
    }

    handleNavigationItems() {
        this.activeNavigationItem = this.navigationItems[0];
    }



    getRoleDto() {
        this.roleService.get(this.objectIdentifier.objectId).subscribe(res => {
            this.roleDto = <RoleDto>res;
        })
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.loadRoleAuthConfig();
        this.loadRoleAuthModule();
        this.loadRoleAuthObjectType();
        this.loadRoleAuthContract();
        this.loadRoleAuthCustomer();
        this.getRoleDto();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }



    getObjectRequestList(): ObjectRequestList {
        return ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false
            ),
            [
                FilterCriteria.create(
                    'role',
                    FilterCriteria.cOperatorEqual,
                    this.objectIdentifier.objectId
                ),
            ],
            [],
            null
        );
    }


    loadRoleAuthConfig() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthConfigWidget = WidgetFactory.createWidgetTableEntity(
            'um.role.auth.config.widget',
            'Auth config',
            'um.RoleAuthConfig',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthModule() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthModuleWidget = WidgetFactory.createWidgetTableEntity(
            'um.role.auth.module.widget',
            'Auth module',
            'um.RoleAuthModule',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthObjectType() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthObjectTypeWidget = WidgetFactory.createWidgetTableEntity(
            'um.role.auth.object.type.widget',
            'Auth object type',
            'um.RoleAuthObjectType',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthContract() {
        const objectRequest = this.getObjectRequestList();

        this.roleAuthContractWidget = WidgetFactory.createWidgetTableEntity(
            'um.role.auth.contract.widget',
            'Auth contract',
            'cm.RoleAuthContract',
            'No Data',
            objectRequest
        );
    }

    loadRoleAuthCustomer() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,
                ObjectRequestComplexNode.createSimpleAttributeNode("role"),
                ObjectRequestComplexNode.createRelationNode("customerRegion",
                    ObjectRequestRelation.createJoinWithBindingType('cr.RoleAuthCustomerRegion#roleAuthCustomer', ObjectRequestComplexRelationBindingEnum.LATEST)
                ),
                ObjectRequestComplexNode.createRelationNode("customerType",
                    ObjectRequestRelation.createJoinWithBindingType('cr.RoleAuthCustomerType#roleAuthCustomer', ObjectRequestComplexRelationBindingEnum.LATEST)
                )
            ),
            [
                FilterCriteria.create(
                    'role',
                    FilterCriteria.cOperatorEqual,
                    this.objectIdentifier.objectId
                ),
            ],
            [],
            null
        );

        this.roleAuthCustomerWidget = WidgetFactory.createWidgetListEntity(
            'um.role.auth.customer.widget',
            'Auth customer',
            'cr.RoleAuthCustomer',
            'No Data',
            objectRequest
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

}