import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseModeComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'as-appointment-booking-base',
    template: '',
})
export class AppointmentBookingBaseComponent extends ObjectBaseModeComponent implements OnInit {


    ngOnInit() {
        super.ngOnInit();
    }


}
