<ng-container *ngIf="initialized">

    <ng-container *ngIf="uiMode==='search'">
        <div class="grid">

            <div class="col-2">

                <form [formGroup]="myForm">
                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="lastname" class="text-500 text-sm">Nachname</label>
                            <input
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    pinputtext=""
                                    class="p-inputtext p-component p-element"
                                    placeholder="Enter last name"
                                    formControlName="lastName"
                            >
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="firstname" class="text-500 text-sm">Vorname</label>
                            <input
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    pinputtext=""
                                    class="p-inputtext p-component p-element"
                                    placeholder="Enter first name"
                                    formControlName="firstName"
                            >
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="gender" class="text-500 text-sm">Geschlecht</label>
                            <p-dropdown
                                    id="gender"
                                    [options]="genderOptions"
                                    formControlName="gender"
                                    optionLabel="key"
                                    optionValue="value"
                                    styleClass="w-full"
                            ></p-dropdown>
                        </div>


                        <div class="flex justify-content-end pt-3">
                            <p-button
                                    type="submit"
                                    [disabled]="busy"
                                    pRipple
                                    label="Suche"
                                    icon="fa fa-search"
                                    (click)="onPersonDetails()"
                            ></p-button>
                        </div>
                    </div>
                </form>


            </div>

            <div class="col-10">
                <mvs-widget
                        *ngIf="widgetPersonTable"
                        [widgetData]="widgetPersonTable"
                        (onDataLoad)="onDataLoad($event)"
                        (onObjectSelect)="handleObjectSelect($event)"
                >
                    <ng-template
                        let-object="object"
                        mvs-widget-custom-field-directive
                        area="columns"
                        label="Auswahl"
                        fieldId="personSelectButton">
                        <p-button label="Auswahl" (click)="onPersonSelection(object.row)"></p-button>
                    </ng-template>

                </mvs-widget>

                <mvs-display-message-before-result *ngIf="!widgetPersonTable"></mvs-display-message-before-result>
            </div>

            <div class="col-12">
                <!--                <div class="flex justify-content-end">-->
                <!--               <div class="flex flex-column justify-content-end align-items-end ">-->
                <!--                   <p-button-->
                <!--                           type="submit"-->
                <!--                           [disabled]="busy"-->
                <!--                           pRipple-->
                <!--                           label="Search"-->
                <!--                           icon="fa fa-search"-->
                <!--                           (click)="onPersonDetails()"-->
                <!--                   ></p-button>-->

                <div *ngIf="createMode && createMode === 1">
                    <div class="flex justify-content-end align-items-center mt-4 px-3">

                        <label class="text-base text-500 mr-3">Person not found? Create new one</label>
                        <button pButton type="button" class="mx-2" label="Neu erstellen"
                                (click)="onShowCreateTargetObject()"></button>
                        <!--                                                <button *ngIf="selectedPersonId" pButton type="button" label="Choose"-->
                        <!--                                                        (click)="onCreateNewCustomerRelatedPerson()"></button>-->

                    </div>
                </div>
                <!--               </div>-->

                <!--                    <p-button-->
                <!--                            class="mr-3"-->
                <!--                            label="Create"-->
                <!--                            icon="fa fa-plus"-->
                <!--                            *ngIf="createMode && createMode === 1"-->
                <!--                            (click)="onShowCreateTargetObject()"-->
                <!--                    ></p-button>-->

                <!--                    <p-button-->
                <!--                            type="submit"-->
                <!--                            [disabled]="busy"-->
                <!--                            pRipple-->
                <!--                            label="Search"-->
                <!--                            icon="fa fa-search"-->
                <!--                            (click)="onPersonDetails()"-->
                <!--                    ></p-button>-->
            </div>
            <!--            </div>-->

        </div>

    </ng-container>


    <ng-container *ngIf="uiMode==='createTarget' || uiMode==='createPerson'">


        <p-fieldset>
            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <span class="pi pi-user mr-2"></span>
                    <span class="font-bold text-lg">Create {{targetObjectType}}</span>
                </div>
            </ng-template>

            <ng-container *ngIf="uiMode==='createPerson'">
                <mvs-crud-object
                        [objectType]="'pm.Person'"
                        (onChangedObject)="onPersonObjectCreate($event)"
                        [defaultCreateDto]="personDefaultCreateDto"
                        [enableBack]="true"
                        (onBackEvent)="uiMode='search'"
                ></mvs-crud-object>
            </ng-container>

            <ng-container *ngIf="uiMode==='createTarget'">

                <!--                <p-button label="Back" (click)="uiMode='search'"></p-button>-->

                <mvs-crud-object
                        [objectType]="targetObjectType"
                        [defaultCreateDto]="targetObjectDefaultCreateDto"
                        [formControlOverwrite]="formControlOverwrite"
                        (onChangedObject)="onTargetObjectCreate($event)"
                        [enableBack]="true"
                        (onBackEvent)="uiMode='search'"
                ></mvs-crud-object>

            </ng-container>


        </p-fieldset>

    </ng-container>

</ng-container>



