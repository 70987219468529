<ng-container *ngIf="initialized">

    <div class="col-12" *ngIf="filterCriteria.length>0">
        <p-panel header="Filters">
            <div class="flex align-items-center gap-2 flex-wrap">
                <p-chip
                        *ngFor="let filter of filterCriteria"
                        [label]="filter.field + ': ' + filter.label"
                        [removable]="true"
                        (onRemove)="onRemoveFilterCriteria(filter)"
                ></p-chip>
            </div>
        </p-panel>
    </div>

    <div class="grid">

        <div class="col-3">
            <mvs-widget *ngIf="processWidgetCategory"
                        [widgetData]="processWidgetCategory"
                        (onObjectSelect)="onSetFilterCriteria($event, 'type')">
            </mvs-widget>

            <mvs-widget *ngIf="processWidgetCategoryPeriod"
                        [widgetData]="processWidgetCategoryPeriod"
                        (onObjectSelect)="handlePeriod($event)"
            >
            </mvs-widget>
        </div>

        <div class="col-9">
            <mvs-widget *ngIf="dashboardWidget"
                        [widgetData]="dashboardWidget"
                        (onObjectSelect)="handleNavigation($event)"

            >
            </mvs-widget>
        </div>


    </div>



</ng-container>