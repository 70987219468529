import {Route, Routes} from "@angular/router";
import {XhAnonymizePage} from "./page/overview/anonymize/xh-anonymize.page";


export function XhSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        {path: 'xh/anonymize', component: XhAnonymizePage},
    ];

    return routes;

}
