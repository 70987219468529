<ng-container *ngIf="initialized">
    <div class="flex align-items-center justify-content-between p-3 pb-0">
        <h4 class="m-0">Ticket</h4>
        <div class="flex gap-3">
        <p-inputSwitch [ngModel]="!showFull" (onChange)="handleChangeView()"></p-inputSwitch>
        <label class="text-700 text-sm">Quick View</label>
        </div>

        <!--        <p-button [label]="showFull ? 'Show Quick' : 'Full View'" (click)="handleChangeView()"></p-button>-->
    </div>

        <mvs-crud-object [objectType]="'tm.Ticket'"
                         [objectId]="0"
                         [formControlOverwrite]="formControlOverwrite"
                         [defaultCreateDto]="createDefaultDto"
                         (onFormChange)="handleFormChange($event)"
                         (onChangedObject)="handleObjectChange($event)"
        >
        </mvs-crud-object>

</ng-container>