<ng-container *ngIf="initialized">

    <p-card>
         <mvs-stepper
                [stepItems]="stepItems"
                [activeStep]="activeStep"
                [busy]="busy"
                [showStepName]="true">
         </mvs-stepper>

        <div class="mt-4">
            <ng-container *ngIf="activeStep == 0">
                <mvs-widget *ngIf="widgetObject"
                            [widgetData]="widgetObject"
                            [uiStyle]="cmInsurableObjectWidgetStyle"
                            (onObjectSelect)="handleInsurableObjectSelect($event)">
                </mvs-widget>

                <div class="flex justify-content-end align-items-center mt-4 px-3">
                    <label class="text-base text-500 mr-3">Objekt existiert noch nicht? </label>
                    <button pButton type="button" label="Anlegen" (click)="handleCreateInsurableObject()"></button>
                </div>
            </ng-container>

            <ng-container *ngIf="activeStep == 1">
                <mvs-widget *ngIf="formWidget"
                            [widgetData]="formWidget"
                            [importObjectContext]="importObjectContext"
                            (onChangedObject)="handleCreateObject($event)"
                >
                </mvs-widget>
            </ng-container>

            <ng-container *ngIf="activeStep == 2">
                <mvs-widget *ngIf="customerInsurableObjectWidget"
                            [importObjectContext]="importObjectContext"
                            [widgetData]="customerInsurableObjectWidget"
                            (onChangedObject)="handleCustomerInsurableObjectCreated($event)"
                >
                </mvs-widget>
            </ng-container>

            <ng-container *ngIf="activeStep == 3">
                <mvs-widget *ngIf="contractInsurableObjectWidget"
                            [widgetData]="contractInsurableObjectWidget"
                            [importObjectContext]="importObjectContext"
                            (onChangedObject)="handleContractInsurableObjectCreated($event)">
                </mvs-widget>
            </ng-container>

        </div>
    </p-card>
</ng-container>