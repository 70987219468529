<form [formGroup]="addressForm" id="address-form" action="" method="get" autocomplete="off">
    <label class="full-field">
        <input formControlName="shipAddress" #shipAddress id="ship-address" name="ship-address" required
               autocomplete="off"/>
    </label>


    <button type="button" class="my-button">Save address</button>

    <input type="reset" value="Clear form"/>
</form>