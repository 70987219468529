import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {EuUserAddedInsuranceDocumentDto} from "../../../../eu/dto/eu-user-added-insurance-document.dto";
import {EuUserAddedInsuranceService} from "../../../../eu/service/api/eu-user-added-insurance.service";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {EuUserAddedInsuranceDto} from "../../../../eu/dto/eu-user-added-insurance.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {MvsFormValueListEntryDto} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-manage-registration-document',
    templateUrl: './cr-manage-registration-document.component.html',
    styleUrls: ['cr-manage-registration-document.component.scss']
})
export class CrManageRegistrationDocumentComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() userId: number;
    @Input() selectedUserAddedInsuranceId: number;
    @Input() direction: 'prev' | 'next';

    @Input() selectedDocument: EuUserAddedInsuranceDocumentDto;
    @Output() selectedDocumentChange = new EventEmitter<EuUserAddedInsuranceDocumentDto>();
    @Input() showSideDrawer: boolean;

    @Output() showSideDrawerChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() lastDocument: boolean;
    @Output() lastDocumentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectedUserAddedInsuranceIdChange: EventEmitter<number> = new EventEmitter<number>();

    selectedUserAddedInsurance: EuUserAddedInsuranceDto;
    userAddedInsuranceWidget: WidgetData;
    currentIndex: number = 0;
    statusEntries: Map<number, MvsFormValueListEntryDto>;
    userAddedInsuranceStatusInternal: MvsFormValueListEntryDto[];

    disableNext: boolean = true;
    disablePrevious: boolean = true;

    constructor(
        protected messageService: MvsMessageService,
        protected documentService: DmDocumentService,
        protected userAddedInsuranceService: EuUserAddedInsuranceService) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        if (this.statusEntries) {
            this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorEqual, false);
        } else {

            this.documentService.template(new DtoTemplate()).subscribe(res => {
                const entries: MvsFormValueListEntryDto[] = res.getFormField('status').valueList.entries;
                this.statusEntries = new Map(entries.map(entry => [entry.key, entry]));

                if (!this.initialized) {
                    this.handleCheckForPreviousNextInsurance(FilterCriteria.cOperatorGreaterThan, true, () => {
                        this.handleCheckForPreviousNextInsurance(FilterCriteria.cOperatorLowerThan, false, () => {
                            this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorEqual, false);
                        })
                    })
                } else {
                    this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorEqual, false);
                }

            }, error => {
                this.messageService.showErrorMessage('', error);
            });
        }
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * get the next added insurance for selected user
     */

    gotoNextAddedInsurance() {
        this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorLowerThan);
    }

    /**
     * get the previous added insurance for selected user
     */

    gotoPreviousAddedInsurance() {
        this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorGreaterThan, true, true);
    }

    /**
     * getting the user added insurance
     * @param operator
     * @param init
     * @param ascending
     */

    /**
     * handle current insurance
     * @param operator
     * @param init
     * @param ascending
     */
    handleUpcomingUserAddedInsurance(operator: string, init: boolean = true, ascending: boolean = false) {


        if (this.busy) {
            return;
        }
        this.busy = true;

        const filterCriteria = [
            FilterCriteria.create('user', FilterCriteria.cOperatorEqual, this.userId),
            FilterCriteria.create('id', operator, this.selectedUserAddedInsuranceId)
        ]

        const objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('documents', ObjectRequestRelation.createJoin('eu.EuUserAddedInsuranceDocument#userAddedInsurance'))
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('document')));


        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            objectRequestComplex,
            filterCriteria,
            [new Sorting("id", ascending)],
            PagingDto.create(0, 2));

        this.userAddedInsuranceService.list(objectRequest).subscribe(res => {

            this.currentIndex = 0;
            this.handleLastDocumentEvent(false);
            this.userAddedInsuranceStatusInternal = res.form.getFormField('internalStatus').valueList.entries;

            if (this.initialized && init) {
                this.disableNext = false;
                this.disablePrevious = false;
                if (res.entries.length <= 1) {
                    if (ascending) {
                        this.disablePrevious = true;
                    } else {
                        this.disableNext = true;
                    }
                }
            }

            if (res?.entries?.length) {

                this.selectedUserAddedInsurance = res.entries[0];
                this.selectedUserAddedInsuranceId = res.entries[0].id;
                this.selectedUserAddedInsuranceIdChange.emit(this.selectedUserAddedInsuranceId);
                if (this.selectedUserAddedInsurance?.documents?.length) {
                    this.handleSelectedDocumentEvent(this.selectedUserAddedInsurance?.documents[0]);
                    if (this.selectedUserAddedInsurance?.documents?.length == 1) {
                        this.handleLastDocumentEvent(true);
                    }
                } else {
                    this.handleLastDocumentEvent(false);
                    this.handleSelectedDocumentEvent(null);
                }
                this.refreshUserAddedInsuranceWidget();
                this.refreshComponent();
            } else {
                this.handleSelectedDocumentEvent(null);
            }
            this.busy = false;
        }, error => {
            if (ascending) {
                this.disableNext = true;
            } else {
                this.disablePrevious = true;
            }
            this.busy = false;
            this.messageService.showErrorMessage('', error);
        });


    }


    /**
     * check whether there is a previous or next insurance
     * @param operator
     * @param ascending
     * @param callBack
     */
    handleCheckForPreviousNextInsurance(operator: string, ascending: boolean = false, callBack: () => void) {

        const filterCriteria = [
            FilterCriteria.create('user', FilterCriteria.cOperatorEqual, this.userId),
            FilterCriteria.create('id', operator, this.selectedUserAddedInsuranceId)
        ]


        const objectRequest = ObjectRequestList.createComplexRequestList(
            false,
            null,
            filterCriteria,
            [new Sorting("id", ascending)],
            PagingDto.create(0, 2));

        this.userAddedInsuranceService.list(objectRequest).subscribe(res => {


            if (res.entries.length >= 1) {
                if (ascending) {
                    this.disablePrevious = false;
                } else {
                    this.disableNext = false;
                }

            }

            callBack();

        });


    }

    /**
     * on selecting document
     * @param document
     */
    onDocumentSelect(document: EuUserAddedInsuranceDocumentDto) {
        this.handleSelectedDocumentEvent(document);
        const documents = this.selectedUserAddedInsurance.documents;
        this.currentIndex = documents.findIndex(obj => obj.id === this.selectedDocument.id);
    }

    /**
     * navigate to document
     * @param direction
     */
    gotoDocument(direction: 'next' | 'prev') {

        if (this.selectedUserAddedInsurance?.documents?.length && this.selectedUserAddedInsurance?.documents?.length > 1) {


            if (direction === 'prev' && this.currentIndex == 0) {
                return;
            }

            if (direction === 'next' && this.currentIndex == this.selectedUserAddedInsurance?.documents?.length - 1) {
                return;
            }

            if (this.busy) {
                return;
            }

            this.busy = true;
            const documents = this.selectedUserAddedInsurance.documents;
            const currentIndex = documents.findIndex(obj => obj.id === this.selectedDocument.id);

            let newIndex: number;

            if (direction === 'next') {
                newIndex = (currentIndex + 1) % documents.length;
            } else if (direction === 'prev') {
                newIndex = (currentIndex - 1 + documents.length) % documents.length;
            } else {
                console.error('Invalid direction specified');
                return;
            }

            if (newIndex == this.selectedUserAddedInsurance?.documents?.length - 1) {
                this.handleLastDocumentEvent(true);
            } else {
                this.handleLastDocumentEvent(false);
            }

            this.currentIndex = newIndex;
            this.handleSelectedDocumentEvent(documents[newIndex])
        }
    }

    /**
     * user added insurance widget
     */
    refreshUserAddedInsuranceWidget() {
        this.userAddedInsuranceWidget = WidgetFactory.createWidget(
            'cr-manage-registration-selected-user-added-insurance-widget',
            "User Added Insurance",
            'data',
            'list',
            'entity',
            'eu.EuUserAddedInsurance',
            new WidgetDataParam("objectId", this.selectedUserAddedInsurance.id)
        )

    }


    handleDocumentLoaded() {
        this.busy = false;
    }

    /**
     * handle side drawer
     */
    handleSideDrawer() {
        this.showSideDrawer = !this.showSideDrawer;
        this.showSideDrawerChange.emit(this.showSideDrawer);
    }

    /**
     * update insurance status
     * @param key
     */
    handleInsuranceStatus(key: number) {

        if (this.busy) {
            return
        }

        this.busy = true;

        const dto = new EuUserAddedInsuranceDto();
        dto.id = this.selectedUserAddedInsurance.id;
        dto.internalStatus = key;

        this.userAddedInsuranceService.update(dto).subscribe(() => {
            this.busy = false;
            this.handleUpcomingUserAddedInsurance(FilterCriteria.cOperatorEqual, false);
        }, () => {
            this.busy = false;
        })
    }

    /**
     * emit event on last document
     * @param isLast
     */
    handleLastDocumentEvent(isLast: boolean) {
        this.lastDocument = isLast;
        this.lastDocumentChange.emit(this.lastDocument);
    }

    /**
     * emit event on document select
     * @param document
     */
    handleSelectedDocumentEvent(document: EuUserAddedInsuranceDocumentDto) {
        this.selectedDocument = document;
        this.selectedDocumentChange.emit(this.selectedDocument);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["direction"]) {
            if (!this.direction) {
                return;
            }
            this.gotoDocument(this.direction);
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
