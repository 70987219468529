import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";

interface UiEnumValueInfo {
    uiLabel: string;
    uiImage: string;
}

@Component({
    selector: 'tm-ticket-output-field',
    templateUrl: './tm-ticket-output-field.component.html',
    styleUrls: ['./tm-ticket-output-field.component.scss']
})
export class TmTicketOutputFieldComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() dtoList: DtoList;
    ticketList: TmTicketDto[]
    // ticketStatus : TmStatusEnum;

    tmStatusEnum: Record<TmStatusEnum, UiEnumValueInfo> = {
        [TmStatusEnum.draft]: { uiLabel: 'Entwurf', uiImage: 'fa-solid fa-pen-ruler' },
        [TmStatusEnum.assigned]: { uiLabel: 'Zugeordnet', uiImage: 'fa-solid fa-brake-warning' },
        [TmStatusEnum.working]: { uiLabel: 'In Arbeit', uiImage: 'fa-sharp fa-solid fa-spinner' },
        [TmStatusEnum.awaiting_customer]: { uiLabel: 'Warte auf Kunden', uiImage: 'fa-solid fa-circle-pause' },
        [TmStatusEnum.awaiting_insurance_company]: { uiLabel: 'Warte auf Partner', uiImage: 'fa-solid fa-circle-pause' },
        [TmStatusEnum.resolved]: { uiLabel: 'Abgeschlossen', uiImage: 'fa-solid fa-flag-checkered' },
        [TmStatusEnum.on_hold]: { uiLabel: 'Zurückgelegt', uiImage: 'fa-sharp fa-solid fa-hand' },
        [TmStatusEnum.cancelled]: { uiLabel: 'Abgebrochen', uiImage: 'fa-solid fa-ban' }
    };


    constructor(protected navigationService: MvsObjectNavigationService, protected objectService: ObjectTypeService, protected coreService: MvsCoreService) {
        super(navigationService,objectService,coreService);
    }

    ngOnInit(): void {
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        if (this.dtoList['activeTickets']) {
            this.ticketList = this.dtoList['activeTickets'] as TmTicketDto[];
        } else {
            this.ticketList = this.dtoList.entries as TmTicketDto[];
        }
        // this.ticketList = this.dtoList.entries as TmTicketDto[];
        // this.ticketStatus = this.dtoList.form.formFields['status']['valueList']['entries'];

    }

    openTicketDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", ticketId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
