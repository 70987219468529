export enum WfActivityTypeEnum {

    object,
    select,
    notification,
    start_process,
    create_ticket,
    choose_next_step,
    document_upload_and_list,
    ticket_status

}