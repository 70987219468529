import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {TestWidgetPage} from "./page/overview/test-widget/test-widget.page";
import {TestTemplatingPage} from "./page/overview/test-templating/test-templating.page";
import {MentionModule} from "angular-mentions";
import {DragDropModule} from "primeng/dragdrop";
import {TestFormPage} from "./page/overview/test-form/test-form.page";
import {CmModule} from "../cm/cm.module";
import {TestDesignsPage} from "./page/overview/test-designs/test-designs.page";
import {TestMarkoPage} from "./page/overview/test-marko/test-marko.page";
import {TestDaudPage} from "./page/overview/test-daud/test-daud.page";
import {CrModule} from "../cr/cr.module";
import {TestHarisPage} from "./page/overview/test-haris/test-haris.page";
import {CiModule} from "../ci/ci.module";
import {TmModule} from "../tm/tm.module";
import {TestYasirPage} from "./page/overview/test-yasir/test-yasir.page";
import {AmModule} from "../am/am.module";
import {PcModule} from "../pc/pc.module";
import {WfModule} from "../wf/wf.module";
import {NsModule} from "../ns/ns.module";
import {WsModule} from "../ws/ws.module";
import {TestAtherPage} from "./page/overview/test-ather/test-ather.page";
import {HierarchyEntryWrapperComponent} from "./page/overview/test-daud/hierarchy-entry-wrapper/hierarchy-entry-wrapper.component";
import {
    HierarchyEntryContentComponent
} from "./page/overview/test-daud/hierarchy-entry-content/hierarchy-entry-content.component";
import {TestWorkflowPage} from "./page/overview/test-workflow/test-workflow.page";
import {TreeSelectModule} from "primeng/treeselect";
import {DmModule} from "../dm/dm.module";
import {TestAddressPage} from "./page/overview/test-address/test-address.page";
import {TestMarkoSubPage} from "./page/overview/test-marko/components/test-marko-sub.page";
import {TestMarkoSubDirective} from "./page/overview/test-marko/directives/test-marko-sub.directive";
import {FullCalendarModule} from "@fullcalendar/angular";
import {TestBpmnPage} from "./page/overview/test-bpmn/test-bpmn.page";
import {PmModule} from "../pm/pm.module";
import {XxModule} from "../xx/xx.module";
import {UmModule} from "../um/um.module";
import {TestWorkflowActivitiesPage} from "./page/overview/test-workflow-activities/test-workflow-activities.page";
import {TestEditorPage} from "./page/overview/test-editor/test-editor.page";
import {
    CodeMirrorTestComponent
} from "./page/overview/test-haris/components/code-mirror-test/code-mirror-test.component";
import {TestCalendarPage} from "./page/overview/test-calendar/test-calendar.page";
import {TestDocumentPage} from "./page/overview/test-document/test-document.page";
import {RecursiveNodeComponent} from "./page/overview/test-ather/recursive-node-component/recursive-node.component";
import {UiModule} from "../ui/ui.module";
import {JbModule} from "../jb/jb.module";
import {AsModule} from "../as/as.module";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";

@NgModule({
    declarations: [
        TestWidgetPage,
        TestTemplatingPage,
        TestFormPage,
        TestDesignsPage,
        // ObjectQuickViewComponent,
        TestMarkoPage,
        TestHarisPage,
        TestDaudPage,
        TestYasirPage,
        TestAtherPage,
        HierarchyEntryWrapperComponent,
        HierarchyEntryContentComponent,
        TestWorkflowPage,
        TestAddressPage,
        TestMarkoSubPage,
        TestMarkoSubDirective,
        TestAddressPage,
        TestBpmnPage,
        TestWorkflowActivitiesPage,
        TestEditorPage,
        CodeMirrorTestComponent,
        TestCalendarPage,
        TestDocumentPage,
        RecursiveNodeComponent,
    ],
    exports: [
        TestWidgetPage,
        TestTemplatingPage,
        TestFormPage,
        TestDesignsPage,
        // ObjectQuickViewComponent,
        TestMarkoPage,
        TestHarisPage,
        TestDaudPage,
        TestYasirPage,
        TestAtherPage,
        HierarchyEntryWrapperComponent,
        HierarchyEntryContentComponent,
        TestWorkflowPage,
        TestAddressPage,
        TestAddressPage,
        TestMarkoSubPage,
        TestMarkoSubDirective,
        TestBpmnPage,
        TestWorkflowActivitiesPage,
        TestEditorPage,
        CodeMirrorTestComponent,
        TestCalendarPage,
        TestDocumentPage,
        RecursiveNodeComponent,
    ],
    imports: [
        CoreModule,
        MentionModule,
        DragDropModule,
        CmModule,
        CrModule,
        CiModule,
        TmModule,
        AmModule,
        PcModule,
        AmModule,
        WfModule,
        NsModule,
        WsModule,
        DmModule,
        TreeSelectModule,
        FullCalendarModule,
        PmModule,
        XxModule,
        UmModule,
        UiModule,
        JbModule,
    ]
})
export class TestModule {
}



