import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, MvsCoreService, ObjectRequestList, PagingDto, FilterCriteria, WidgetData, Sorting, EntityStatusEnum,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {ContractService} from "../../../../service/api/contract.service";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'cm-statistics.component.html',
})
export class CmStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Contract Overview";

    pendingContracts: number = 0;
    activeContractsThisMonth: number = 0;
    obsoleteContracts: number = 0;
    terminatedContracts: number = 0;
    contractSignedPerMonthWidget: WidgetData;
    contractStatusBreakdownWidget: WidgetData;
    contractExpirationWidget: WidgetData;
    contractsConversion: number;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected contractService: ContractService) {
        super(route, coreService)
    }


    ngOnInit(): void {


        this.items = [
            { label: 'Agent Module' },
        ];

        this.home = { icon: 'pi pi-home', routerLink: '/' };
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {


        this.getContractStatusThisMonth();
        this.refreshContractsSignedPerMonth();
        this.refreshContractExpirationWidget();
        this.refreshContractStatusBreakdownWidget();

        this.initialized = true;

    }

    navigateToConfig() {
        this.router.navigate(['/cm/config']);
    }

    getContractStatusThisMonth() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.contractService.groupBy(objectRequestList).subscribe(res => {

            this.overviewDto = [
                { label: 'Pending Contracts', value: 0 },
                { label: 'Active Contracts', value: 0 },
                { label: 'Obsolete Contracts', value: 0 },
                { label: 'Terminated Contracts', value: 0 }
            ];

            for (let item of res.entries) {
                if (item['entityStatus'] === EntityStatusEnum.draft || item['entityStatus'] === EntityStatusEnum.pending) {
                    this.overviewDto.find(entry => entry.label === 'Pending Contracts').value += item['entityStatus_count'];
                } else if (item['entityStatus'] === EntityStatusEnum.active) {
                    this.overviewDto.find(entry => entry.label === 'Active Contracts').value += item['entityStatus_count'];
                } else if (item['entityStatus'] === EntityStatusEnum.discarded || item['entityStatus'] === EntityStatusEnum.deleted) {
                    this.overviewDto.find(entry => entry.label === 'Terminated Contracts').value += item['entityStatus_count'];
                } else if (item['entityStatus'] === EntityStatusEnum.obsolete) {
                    this.overviewDto.find(entry => entry.label === 'Obsolete Contracts').value += item['entityStatus_count'];
                }
            }

        });

    }



    refreshContractsSignedPerMonth() {

        this.contractSignedPerMonthWidget = new WidgetData();
        this.contractSignedPerMonthWidget.idAlias = "cm.contract.overview.contract.per.month";
        this.contractSignedPerMonthWidget.name = "Contracts Signed per Month ";
        this.contractSignedPerMonthWidget.uiComponent = "history.chart";
        this.contractSignedPerMonthWidget.dataProvider = "list";
        this.contractSignedPerMonthWidget.dataSource = "entity.groupBy";
        this.contractSignedPerMonthWidget.dataProviderObject = "cm.Contract";
        this.contractSignedPerMonthWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["group", "startDate"],
            [new ObjectRequestListAttribute("group", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.contractSignedPerMonthWidget.setParamValue("fieldCategory", "group");
        this.contractSignedPerMonthWidget.setParamValue("fieldCategoryCount", "group_count");
        this.contractSignedPerMonthWidget.setParamValue("historyPeriodMode", "year");
        this.contractSignedPerMonthWidget.setParamValue("fieldDate", "startDate");
        this.contractSignedPerMonthWidget.setParamValue("selectionMode", "single");
        this.contractSignedPerMonthWidget.setParamValue("size", "S");

    }

    refreshContractStatusBreakdownWidget() {
        this.contractStatusBreakdownWidget = WidgetFactory.createWidgetGroupBy(
            'cm.contract.overview.contract.per.month.period',
            'Contracts Status Breakdown',
            'category',
            'entity.groupBy',
            'cm.Contract',
            'entityStatus',
            'entityStatus_count',
            this.filterCriteria,
            'entityStatus',
            'Anzahl');
    }

    refreshContractExpirationWidget() {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;
        const lastDayOfCurrentMonth = DatesHelper.calculateLastDayOfMonth(currentYear, currentMonth);
        const firstDayOfCurrentMonth = DatesHelper.calculateFirstDayOfCurrentMonth();


        const objectRequestList = ObjectRequestList.createWithPaging(
            true,
            [FilterCriteria.create('plannedEndDate', FilterCriteria.cOperatorBetween, firstDayOfCurrentMonth, lastDayOfCurrentMonth)],
            [new Sorting('plannedEndDate', false)],
            PagingDto.create(0, 5)
        );

        this.contractExpirationWidget = WidgetFactory.createWidgetTableEntity(
            'cm.contract.overview.contract.expiration',
            'Upcoming Contract Expirations',
            'cm.Contract',
            'No Contract to expire',
            objectRequestList)
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
