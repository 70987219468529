import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CallProtocolStatusEnum} from "../../../../enum/call-protocol-status.enum";
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService, ObjectIdentifierData, ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestListAttribute, ObjectRequestListGroupBy, ObjectRequestRelation,
    PageComponent,
    PagingDto, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationProviderGeneric,
    MvsObjectNavigationService
} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-call-control',
    templateUrl: './pc-qm-call-control.page.html',
})
export class PcQmCallControlPage extends PageComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    defaultLabel: string = "Anrufkontrolle";


    widgetAgentCancelledProtocols: WidgetData;
    widgetAgentCompletedProtocols: WidgetData;
    widgetAgentMissingProtocols: WidgetData;


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());
            this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.uiRefresh();

        this.initialized = true;
    }

    uiRefresh() {

        const missingProtocols = [ FilterCriteria.create("protocolStatus", FilterCriteria.cOperatorLowerEqual, CallProtocolStatusEnum.completed)];

        // Missing protocols
        this.widgetAgentMissingProtocols = new WidgetData();
        this.widgetAgentMissingProtocols.idAlias = "pc.call.control.agent.missing.protocol";
        this.widgetAgentMissingProtocols.name = "Fehlende Protokolle";
        this.widgetAgentMissingProtocols.uiComponent = "table";
        this.widgetAgentMissingProtocols.dataProvider = "list";
        this.widgetAgentMissingProtocols.dataSource = "entity";
        this.widgetAgentMissingProtocols.dataProviderObject = "pc.CallProtocol";
        this.widgetAgentMissingProtocols.noDataText = "Keine fehlenden Protokolle";

        this.widgetAgentMissingProtocols.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            missingProtocols,
            [new Sorting("createdDate", false)],
            PagingDto.create(0,20));

        this.widgetAgentMissingProtocols.dataProviderListRequest.objectRequestComplex =
            ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createSimpleAttributeNode("call")
                .addNodes(ObjectRequestComplexNode.createRelationNode('Customer', ObjectRequestRelation.createJoin('+objectCustomer'))));

        this.widgetAgentMissingProtocols.setParamValue("size", "S");
        this.widgetAgentMissingProtocols.setParamValue("selectionMode", "rowSelect");


        this.widgetAgentCompletedProtocols = new WidgetData();
        this.widgetAgentCompletedProtocols.idAlias = "pc.call.control.agent.created.protocolls";
        this.widgetAgentCompletedProtocols.name = "Erstellte Protokolle";
        this.widgetAgentCompletedProtocols.uiComponent = "category";
        this.widgetAgentCompletedProtocols.dataProvider = "list";
        this.widgetAgentCompletedProtocols.dataSource = "entity.groupBy";
        this.widgetAgentCompletedProtocols.dataProviderObject = "pc.CallProtocol";
        this.widgetAgentCompletedProtocols.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["createdBy"],
            [ new ObjectRequestListAttribute("createdBy", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
        this.widgetAgentCompletedProtocols.setParamValue("fieldCategory", "createdBy");
        this.widgetAgentCompletedProtocols.setParamValue("fieldCategoryCount", "createdBy_count");
        //this.widgetCallResults.setParamValue("selectionMode", "single");
        this.widgetAgentCompletedProtocols.setParamValue("size", "S");



        this.widgetAgentCancelledProtocols = new WidgetData();
        this.widgetAgentCancelledProtocols.idAlias = "pc.call.control.agent.cancelled.protocolls";
        this.widgetAgentCancelledProtocols.name = "Übersprungene Protokolle";
        this.widgetAgentCancelledProtocols.uiComponent = "category";
        this.widgetAgentCancelledProtocols.dataProvider = "list";
        this.widgetAgentCancelledProtocols.dataSource = "entity.groupBy";
        this.widgetAgentCancelledProtocols.dataProviderObject = "pc.CallProtocol";
        this.widgetAgentCancelledProtocols.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["createdBy"],
            [ new ObjectRequestListAttribute("createdBy", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
        this.widgetAgentCancelledProtocols.setParamValue("fieldCategory", "createdBy");
        this.widgetAgentCancelledProtocols.setParamValue("fieldCategoryCount", "createdBy_count");
        //this.widgetCallResults.setParamValue("selectionMode", "single");
        this.widgetAgentCancelledProtocols.setParamValue("size", "S");




    }

    onRowSelect(objectIdentifierData: ObjectIdentifierData) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifierData.objectType, objectIdentifierData.objectId, "Call", null, null,null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

}
