import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {DmDocumentTypeService} from "../../../service/api/dm-document-type.service";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {DmDocumentService} from "../../../service/api/dm-document.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {FormControl} from "@angular/forms";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsFormGroup} from "@kvers/alpha-core-common";
import {MvsFormValueListEntryDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";


@Component({
    selector: 'dm-dashboard-action-page',
    templateUrl: './dm-dashboard-actions.page.html',
})
export class DmDashboardActionsPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Dokumentennachbearbeitung";

    widgetDocuments: WidgetData;

    documentTemplate: MvsFormDto;

    selectionFormGroup: MvsFormGroup;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected documentTypeService: DmDocumentTypeService,
        protected documentService: DmDocumentService,
        // private titleService: Title
    ) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.documentService.template(DtoTemplate.createEmpty()).subscribe(value => {

            this.documentTemplate = value;

            this.initSelection();

            this.refreshComponent();
        });

    }

    /**
     * Create the options for the selections.
     */
    initSelection() {

        // create the form group
        this.selectionFormGroup = new MvsFormGroup({
            dateOrigin: new FormControl('createdDate'),
            startDate: new FormControl(''),
            endDate: new FormControl(''),
            types : new FormControl<number[] | null>([]),
            statuses : new FormControl<number[] | null>([])
        });


        // create the form
        this.selectionFormGroup.prepareTransient(
            MvsFormFieldDto.createBasic("dateOrigin", "select", "Datumsfeld")
                .createValueList(
                    MvsFormValueListEntryDto.createBasic("createdDate", "Datum der Anlage"),
                    MvsFormValueListEntryDto.createBasic("lastModifiedDate", "Datum der letzten Änderung"),
                    MvsFormValueListEntryDto.createBasic("startDate", "Vertragsbeginn"),
                    MvsFormValueListEntryDto.createBasic("endDate", "Vertragsende"),
                ),
            MvsFormFieldDto.createBasic("startDate", "date", "Von"),
            MvsFormFieldDto.createBasic("endDate", "date", "Bis"),
            MvsFormFieldDto.createBasic("types", "multiSelect", "Dokumenttyp")
                .createValueList(...this.documentTemplate.getFormField("dmDocumentTypeDtoId").valueList.entries),
            MvsFormFieldDto.createBasic("statuses", "multiSelect", "Status")
                .createValueList(...this.documentTemplate.getFormField("status").valueList.entries),

        );

    }

    updateResult() {

        const filters: FilterCriteria[] = [];
        const dateOrigin = this.selectionFormGroup.get("dateOrigin").value;
        const startDate = this.selectionFormGroup.get("startDate").value;
        const endDate = this.selectionFormGroup.get("endDate").value;

        if (startDate && endDate) {
            filters.push(FilterCriteria.create(dateOrigin, FilterCriteria.cOperatorBetween, startDate, endDate));
        } else if (startDate) {
            filters.push(FilterCriteria.create(dateOrigin, FilterCriteria.cOperatorGreaterEqual, startDate));
        } else if (endDate) {
            filters.push(FilterCriteria.create(dateOrigin, FilterCriteria.cOperatorLowerEqual, endDate));
        }

        const types: number[] = this.selectionFormGroup.get("types").value;
        if (types && types.length > 0) {
            filters.push(FilterCriteria.createOr(...types.map(value => FilterCriteria.create("dmDocumentType", FilterCriteria.cOperatorEqual, value))));
        }

        const statuses: number[] = this.selectionFormGroup.get("statuses").value;
        if (statuses && statuses.length > 0) {
            filters.push(FilterCriteria.createOr(...types.map(value => FilterCriteria.create("dmDocumentType", FilterCriteria.cOperatorEqual, value))));
        }

        // documents
        this.widgetDocuments = WidgetFactory.createWidgetListRefresh(
            "dm.dashboard.actions.documents",
            "Dokumente",
            "table",
            "list",
            "entity",
            "dm.DmDocument",
            "Keine Dokumente gefunden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(false, false,
                        /*
                        ObjectRequestComplexNode.createSimpleAttributeNode("dmDocumentType"),

                        ObjectRequestComplexNode.createRelationNode(
                            "comments",
                            ObjectRequestRelation.createGroupBy(
                                "dm.DmDocumentComment",
                                "document",
                                ObjectRequestListGroupBy.create(
                                    true,
                                    [],
                                    [],
                                    ["document"],
                                    [new ObjectRequestListAttribute("document", "Anzahl Kommentare", DtoListAttributeRequestAggregateEnum.count)])
                            )
                        ),
                         */

                        ObjectRequestComplexNode.createRelationNode(
                            "assignment",
                            ObjectRequestRelation.createJoinWithBindingType("dm.DmDocumentAssignment#document", ObjectRequestComplexRelationBindingEnum.LATEST)
                        ),

                ),
                filters,
                [new Sorting(dateOrigin, false)],
                PagingDto.create(0, 10),
                null),
            WidgetDataRefresh.createSimple()
            );


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
