import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ChannelConfigPage} from "./page/overview/channel-config/channel-config.page";
import {CoOverviewPage} from "./page/co-overview-page/co-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {CmKpiComponent} from "../cm/page/contract-overview-page/components/kpiComponent/cm-kpi.component";
import {
  CmStatisticsComponent
} from "../cm/page/contract-overview-page/components/statisticsComponent/cm-statistics.component";
import {CoKpiComponent} from "./page/co-overview-page/components/kpiComponent/co-kpi.component";
import {CoStatisticsComponent} from "./page/co-overview-page/components/statisticsComponent/co-statistics.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
  declarations: [
    ChannelConfigPage,
    CoOverviewPage,
    CoKpiComponent,
    CoStatisticsComponent
  ],
  exports:[
    ChannelConfigPage,
  ],
    imports: [
        CoreModule,
        UiModule
    ]
})
export class CoModule  implements DynamicModule {

  alias = 'co';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return CoKpiComponent;
  }

  getStatisticsComponent() {
    return CoStatisticsComponent;
  }
}


