import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {CfFrequencyPage} from "./page/overview/cf-frequency/cf-frequency.page";
import {CfPayFrequencyPage} from "./page/overview/cf-pay-frequency/cf-pay-frequency.page";
import {CfPaymentTypePage} from "./page/overview/cf-payment-type/cf-payment-type.page";
import {CfPaymentMomentPage} from "./page/overview/cf-payment-moment/cf-payment-moment.page";
import {CfRoundingRulePage} from "./page/overview/cf-rounding-rule/cf-rounding-rule.page";
import {CfTemplateLiabilityPage} from "./page/overview/cf-template-liability/cf-template-liability.page";
import {
    CfTemplateCancellationBufferPage
} from "./page/overview/cf-template-cancellation-buffer/cf-template-cancellation-buffer.page";
import {
    CfTemplateCalculationBasePage
} from "./page/overview/cf-template-calculation-base/cf-template-calculation-base.page";


@NgModule({
    declarations: [
        CfFrequencyPage,
        CfPayFrequencyPage,
        CfPaymentTypePage,
        CfPaymentMomentPage,
        CfRoundingRulePage,
        CfTemplateLiabilityPage,
        CfTemplateCancellationBufferPage,
        CfTemplateCalculationBasePage,

    ],
    exports: [
        CfFrequencyPage,
        CfPayFrequencyPage,
        CfPaymentTypePage,
        CfPaymentMomentPage,
        CfRoundingRulePage,
        CfTemplateLiabilityPage,
        CfTemplateCancellationBufferPage,
        CfTemplateCalculationBasePage,

    ],
    imports: [
        CoreModule
    ]
})
export class CfModule { }
