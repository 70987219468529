import {Component, OnInit} from '@angular/core';
import {MvsCoreService, PageComponent} from "@kvers/alpha-core-common";
import {EventInterface, Person, SchedulerData} from "@kvers/alpha-ui";
import {ActivatedRoute, Route} from "@angular/router";

@Component({
    selector: 'mvs-test-form-ather',
    templateUrl: 'test-calendar.page.html',
    styleUrls: ['./test-calendar.page.scss']
})
export class TestCalendarPage extends PageComponent implements OnInit {

    showSideDrawer: boolean;

    schedulerData: SchedulerData;

    events: EventInterface[];

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initEvents();

    }


    handleSideDrawer() {
        this.showSideDrawer = !this.showSideDrawer;
    }

    initEvents() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Start from midnight

        this.schedulerData = {
            "agents": [
                {
                    "id": 1,
                    "name": "Agent A",
                    "color": "#FF5733",
                    "bgColor": "#FFE0CC",
                    "events": [
                        {
                            "id": 101,
                            "title": "Client Meeting",
                            start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                            end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                            preBufferTime: 'PT15M',
                            postBufferTime: 'PT15M',
                            isFreeSlot:false,

                        },
                        {
                            "id": 102,
                            "title": "Team Sync",
                            start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                            end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                            preBufferTime: 'PT15M',
                            postBufferTime: 'PT15M',
                            isFreeSlot:false,
                        }
                    ]
                },
                {
                    "id": 2,
                    "name": "Agent B",
                    "color": "#33A1FF",
                    "bgColor": "#CCE5FF",
                    "events": [
                        {
                            "id": 201,
                            "title": "Project Review",
                            start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                            end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                            preBufferTime: 'PT20M',
                            postBufferTime: 'PT15M',
                            isFreeSlot:false,
                        }
                    ]
                }
            ]
        }
    }

}
