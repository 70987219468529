import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TreeNode} from "primeng/api";
import {EmSchemaRuntimeDataNodeDto} from "../../../../../../service/dto/em-schema-runtime-data-node.dto";

@Component({
    selector: 'em-schema-node-data',
    templateUrl: 'schema-node.component.html',
    styleUrls: ['schema-node.component.scss']
})
export class SchemaNodeComponent implements OnInit, OnChanges, OnDestroy {

    initialized: boolean;
    busy: boolean;

    @Input() node: TreeNode;
    @Output() nodeFocused = new EventEmitter<TreeNode>(); // Emits the selected node
    @Output() onShowNode = new EventEmitter<{node:EmSchemaRuntimeDataNodeDto, event: MouseEvent}>(); // Emits the selected node
    @Output() onMouseLeave = new EventEmitter<MouseEvent>(); // Emits the selected node

    ngOnInit(): void {
        this.initComponent();
    }

    hasSimpleChildren(children: TreeNode[]): boolean {
        // return children.some(child => !child?.children?.length);
        return !!this.node?.children?.length;
    }

    hasComplexChildren(children: TreeNode[]): boolean {
        return children.some(child => child.type === 'complex');
    }

    // Emit event to notify parent that a node was clicked
    focusOnNode(node: TreeNode) {
        this.nodeFocused.emit(node);
    }

    showNodeInfo(node: EmSchemaRuntimeDataNodeDto, event: MouseEvent) {
        this.onShowNode.emit({node, event});
    }

    handleMouseLeave(event:MouseEvent) {
        this.onMouseLeave.emit(event);
    }

    /**
     * Initialize Component with Test Data
     */
    initComponent() {
        this.refreshComponent();
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
