import {DtoDetail} from "@kvers/alpha-core-common";
import {PmPersonGender} from "../enum/pm-person-gender.enum";
import {PmPersonDetailDto} from "./pm-person-detail.dto";
import {CustomerDto} from "./customer.dto";
import {PersonRelationPersonDto} from "../../pm/dto/person-relation-person.dto";

export class PmPersonDto extends DtoDetail {

    public personTypeDtoId : number;
    public personTypeDtoName : string;
    public personTypeDtoImage : string;
    public personTypeDtoColor : string;
    public gender : PmPersonGender;
    public firstName : string;
    public lastName : string;
    public personDetailDtoId : number;
    public personDetailDtoName : string;
    public personDetailDtoImage : string;
    public personDetailDtoColor : string;
    public customerDto? : CustomerDto;
    public personRelationPerson? : PersonRelationPersonDto;


    public personDetailDto?: PmPersonDetailDto;

}