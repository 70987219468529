@if (initialized) {
    @for (data of extractedData; track data; let i = $index) {
        <mvs-form-field-output
                [value]="data"
                [formField]="outputField"
        ></mvs-form-field-output>

        @if (i < extractedData.length - 1) {
            , <!-- Add comma except for the last item -->
            <span> </span> <!-- Single space after comma -->
        }
    }
}