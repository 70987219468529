import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService, ObjectPageComponent} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-dm-document-page',
    templateUrl: './dm-document.page.html',
    styleUrls: ['./dm-document.page.scss']
})
export class DmDocumentPage extends ObjectPageComponent implements OnInit {

    // busy: boolean;  // indicator whether the component is busy
    // initialized: boolean; // indicator whether the component was initialized

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService) {

        super(route, coreService)
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
