import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterCriteria, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {TreeNode} from "primeng/api";
import {TreeNodeExpandEvent} from "primeng/tree";

@Component({
    selector: 'em-schema-structure',
    templateUrl: 'schema-structure.component.html',
    styleUrls: ['schema-structure.component.scss']
})
export class SchemaStructureComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    selectedNode: TreeNode;
    @Input() nodes: TreeNode[];

    @Output() onNodeExpand: EventEmitter<TreeNodeExpandEvent> = new EventEmitter();

    emSchemaStructureDataWidget: WidgetData;
    emSchemaStructureListWidget: WidgetData;


    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component with Test Data
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    onNodeSelect() {
        this.refreshSchemaStructureWidgets();
    }

    handleNodeExpand(event: TreeNodeExpandEvent) {
        this.onNodeExpand.emit(event);
    }

    refreshSchemaStructureWidgets() {

        this.emSchemaStructureDataWidget = WidgetFactory.createWidgetEntityData(
            'em.schema.structure.data.widget',
            'Schema Structure',
            'em.EmSchemaStructure',
            this.selectedNode.data.schemaStructureId
        );

        this.emSchemaStructureListWidget = null;

        // let childData: any[] = [];
        // for (let child of this.selectedNode.children) {
        //     childData.push(child.data)
        // }

        // if (!childData.length) {
        //     return
        // }

        // const filterCriteria = FilterCriteria.createOrFromArray('id', 'schemaStructureId', childData);
        const filterCriteria = [
            FilterCriteria.create('id', FilterCriteria.cOperatorNotEqual, this.selectedNode?.data?.schemaStructureId),
            FilterCriteria.create('parentField', FilterCriteria.cOperatorEqual, this.selectedNode?.data?.id),  ];

        const objectRequestList = ObjectRequestList.createBasic(true, filterCriteria, []);

        // setTimeout(() => {
            this.emSchemaStructureListWidget = WidgetFactory.createWidgetListEntity(
                'em.schema.structure.list.widget',
                'Schema Structure List',
                'em.EmSchemaStructure',
                'No Data',
                objectRequestList
            );
        // }, 0)

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
