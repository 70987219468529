import {DtoDetail} from "@kvers/alpha-core-common";
import { AgentDto } from "./agent.dto";
import {AmWeekdayEnum} from "../enum/am-week-day.enum";
import {AmTimeZoneEnum} from "../enum/am-time-zone.enum";

export class AgentScheduleWeekDto extends DtoDetail{


    public agentDto? : AgentDto;
    public agentDtoId : number;
    public agentDtoName : string;
    public weekday : AmWeekdayEnum;
    public fromHours : any;
    public toHours : any;
    public timeZone : AmTimeZoneEnum;
    public startLocationDtoId : number;
    public startLocationDtoName : string;

}