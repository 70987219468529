@if(initialized) {

    <mvs-widget *ngIf="ticketGroupFilterWidget"
                [widgetData]="ticketGroupFilterWidget">
    </mvs-widget>

    <mvs-widget *ngIf="selectionTicketGroupWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="selectionTicketGroupWidget">

    </mvs-widget>

    <mvs-widget *ngIf="selectionTicketTypeWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="selectionTicketTypeWidget">

    </mvs-widget>

    <mvs-widget *ngIf="selectionAgentPoolWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="selectionAgentPoolWidget">

    </mvs-widget>

    <mvs-widget *ngIf="selectionAgentWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="selectionAgentWidget">

    </mvs-widget>

    <mvs-widget *ngIf="restrictAgentPoolWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="restrictAgentPoolWidget">

    </mvs-widget>

    <mvs-widget *ngIf="restrictAgentWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="restrictAgentWidget">

    </mvs-widget>

    <mvs-widget *ngIf="selectionStatusWidget"
                [importObjectContext]="importObjectContext"
                [widgetData]="selectionStatusWidget">

    </mvs-widget>

}