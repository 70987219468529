import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DmDocumentService} from "../../service/api/dm-document.service";
import {DmDocumentDto} from "../../dto/dm-document.dto";
import {DmDocumentViewOptionsEnumEnum} from "../../enum/dm-document-view-options.enum";
import {Dialog} from "primeng/dialog";

@Component({
    selector: 'dm-dialog-box-object-document-component',
    templateUrl: './dialog-box-object-documents.component.html',
    styleUrls: ['./dialog-box-object-documents.component.scss']
})
export class DialogBoxObjectDocumentComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() viewOptions: DmDocumentViewOptionsEnumEnum;
    @Input() widthSize: string;
    @Input() visible: boolean;
    @Input() documentObjectId: number;
    @Input() maximizable: boolean = true;
    @Input() dialogDisplayMode: string;
    @Output() dialogVisibleEvent = new EventEmitter<boolean>();
    formData: any;
    toggleDocument: boolean;

    document: DmDocumentDto;
    documentPdfSource: SafeResourceUrl;

    constructor(protected documentService: DmDocumentService, private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.loadDocument();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    onDialogHide() {
        this.dialogVisibleEvent.emit(false);
    }

    /**
     * the following function will be called whenever we call the component to load document and its respective html data
     * if it is analyzed
     */
    loadDocument() {
        this.documentService.get(this.documentObjectId).subscribe(
            (res: DmDocumentDto) => {
                this.document = res;
                this.toggleDocument = res.analyzed;
                if (res.analyzed) {
                    this.loadFormData();
                } else {
                    this.loadDocumentPdf();
                }
            }
        )
    }

    /**
     * the following function will be called if the document is analyzed to get formData (to render html)
     */
    loadFormData() {
        this.documentService.getFormData(this.documentObjectId).subscribe(res => {
            this.formData = res;
        })
    }

    /**
     * the following function is called to render document in pdf form
     */
    loadDocumentPdf() {
        if (this.documentPdfSource) {
            return;
        }

        let viewDoc: string = "#view=FitV&navpanes=0";

        if (this.viewOptions == DmDocumentViewOptionsEnumEnum.fullWidth) {
            viewDoc = "#view=FitH&navpanes=0";
        }

        this.documentService.downloadViaUrl(this.documentObjectId).subscribe(value => {
            this.documentPdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(value + viewDoc);
        });
    }

    showDialogMaximized(event, dialog: Dialog) {
        if (this.dialogDisplayMode == 'maxOnly') {
            dialog.maximize();
        }else {
        }
    }
}
