import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cc-object-type-page',
  templateUrl: './object-type.page.html',
  styleUrls: ['./object-type.page.scss']
})
export class ObjectTypePage extends PageComponent implements OnInit {

  defaultLabel: string = "Objekt Typen und Erweiterungen";

  objectBrowserRequest: any
  subObjectBrowserRequest: any;

  ngOnInit(): void {

    this.objectBrowserRequest = {
      'extractDto' : false,
      "createMode": true,

      'entries' : [
        {
          'id' : 1,
          'objectAlias' : 'cc.ObjectType',
          'level' : 1,
          'parentBinding' : 'none'
        },
        {
          'id' : 2,
          'objectAlias' : 'cc.ObjectTypeAttribute',
          'level' : 1,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Attribute',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        }
        ]
    };



  }

}
