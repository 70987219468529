import {Component, OnInit} from "@angular/core";
import {NavigationItem, ObjectBaseModeComponent} from "@kvers/alpha-core-common";
import {CmPartnerBaseComponent} from "../cm-partner-base.component";

@Component({
    selector: 'cm-partner-side',
    templateUrl:'cm-partner-side.component.html'
})

export class CmPartnerSideComponent extends CmPartnerBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'cm.Partner',
            action: 'cm.Partner',
            tooltip: 'Partner Basic Info',
            icon: 'fa-solid fa-id-card',
            toggleable: false,
            default: true
        },
        {
            label: 'cm.PartnerGroup',
            action: 'cm.PartnerGroup',
            tooltip: 'Partner Group',
            icon: 'fa-solid fa-users',
            toggleable: false,
            default: false
        },
        {
            label: 'cm.PartnerLink',
            action: 'cm.PartnerLink',
            tooltip: 'Partner Link',
            icon: 'fa-solid fa-link',
            toggleable: false,
            default: false
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}