import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AppointmentBookingBaseComponent} from "../appointment-booking-base.component";


@Component({
    selector: 'as-appointment-booking-full',
    templateUrl: 'appointment-booking-full.component.html',
})
export class AppointmentBookingFullComponent extends AppointmentBookingBaseComponent implements OnInit{


    ngOnInit() {
        super.ngOnInit();
    }

}
