import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {
  CmCreateNewInsurableObjectObjectComponent
} from "../../../cm/components/cm-create-new-insurable-object/object/cm-create-related-person-object/cm-create-new-insurable-object-object.component";
import {
  CmUpdateInsurableObjectObjectComponent
} from "../../../cm/components/cm-update-insurable-object/object/cm-update-insurable-object-object/cm-update-insurable-object-object.component";
import {
  CmCreateInsurableObjectOthersComponent
} from "../../../cm/components/cm-create-insurable-object-others/cm-create-insurable-object-others.component";

@Injectable({
  providedIn: 'root'
})
export class CustomerInsurableObjectService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerInsurableObjects');
  }

  getCustomerInsurableObjectsComplexSelection(): ObjectRequestComplex {
    return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("insurableObject")
    );
  }

  getInsurableObjectContractComplexSelection(): ObjectRequestComplex {
    return ObjectRequestComplex.build(true, false,
        ObjectRequestComplexNode.createSimpleAttributeNode("insurableObject")
    );
  }

  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode == MvsCrudModeEnum.create) {
      return CmCreateInsurableObjectOthersComponent;
    } else if (mode == MvsCrudModeEnum.update) {
      return CmUpdateInsurableObjectObjectComponent;
    }
    return null;
  }
}
