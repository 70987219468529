import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    NavigationItem,
    ObjectBaseComponent,
} from "@kvers/alpha-core-common";


@Component({
    selector: 'cp-provision-agent-object-component',
    templateUrl: './cp-provision-agent-object.component.html',
})
export class CpProvisionAgentObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {


    navigationItems: NavigationItem[];
    activeNavigationItem: NavigationItem;

    /**
     * On Object Change.
     */
    onObjectChanged() {
    }

    getNavigationItems(): NavigationItem[] {
        return this.navigationItems;
    }


    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
