<ng-container *ngIf="initialized">

    <p-splitButton class="button-pill mx-2"
                   styleClass="filter-btn"
                   [label]=" householdPersonsCount ? 'Household (' + householdPersonsCount + ')' : 'Household'"
                   icon="fa-sharp fa-solid fa-house mx-2"
                   menuStyleClass="split-button-menu-hover"
                   [model]="uiHouseholdPersons"
                   (onClick)="handleHouseholdClick()"
    >

    </p-splitButton>

</ng-container>