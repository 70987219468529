import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {UserService} from "../../../../../um/service/api/user.service";
import {UmUserDto} from "../../../../../um/dto/um-user.dto";
import {UserProfileHelper} from "../../../../../um/components/user-profile/helper/user-profile-helper";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsCoreService, ObjectTypeService} from "@kvers/alpha-core-common";
import {UmUserService} from "../../../../../um/service/api/um-user.service";


@Component({
  selector: 'mvs-form-control-output-user-image',
  template: `        <div [ngClass]="displayInfo ? 'flex gap-2 align-items-center' : ''">
    <img class="h-2rem w-2rem border-circle"
                          [src]="imageSrc"
                          [pTooltip]="username"
                          tooltipPosition="top"
  />
  <span *ngIf="displayInfo">{{username}}</span>
  </div>
  `
})
export class MvsFormFieldOutputUserImageComponent
  extends MvsFormFieldOutputBaseComponent
  implements OnInit, OnDestroy, OnChanges {

  constructor(protected navigationService: MvsObjectNavigationService,
              protected objectService: ObjectTypeService,
              protected coreService: MvsCoreService,
              protected umUserService: UmUserService
              ) {
    super(navigationService, objectService, coreService);
  }

  @Input() displayInfo: boolean = false;
  imageSrc: string;
  username: string;

  /**
   * Initialize component.
   * @constructor
   */
  override ngOnInit() {

    super.ngOnInit();

    this.refreshComponent();

    this.initialized = true;

  }

  /**
   * Process on changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (!this.initialized) {
      return;
    }

    this.refreshComponent();

  }

  override refreshComponent() {
    const userId = this.getUserId();
    if (!userId || typeof userId != 'number') {
      return;
    }

    this.umUserService.getUserByUserId(userId).subscribe((res: UmUserDto) => {
      this.username = `${res.firstName} ${res.lastName}`;
      this.getUserImage(userId);
    });

  }

  getUserImage(userId: number) {
    this.umUserService.getPictureBinaryById(userId).subscribe(
      (value: any) => {

        UserProfileHelper.getImageSrcFromBlob(value).then(imageSrc => {
          this.imageSrc = imageSrc;
          this.initialized = true;
        });

      });
  }

  getUserId(): number {
    const paths = this.value.split(':');
    return +paths[1];
  }


}
