import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ContractStructurePage} from "./page/overview/contract-structure/contract-structure.page";
import {InsurableObjectPage} from "./page/overview/insurable-object/insurable-object.page";
import {AssessmentGroupConfigPage} from "./page/overview/assessment-group-config/assessment-group-config.page";
import {CmCustomerAssessmentComponent} from "./components/cm-customer-assessment/cm-customer-assessment.component";
import {
    CmInsuranceStackOverviewComponent
} from "./components/cm-insurance-stack-overview/cm-insurance-stack-overview.component";
import {
    CmInsuranceOverallOverviewComponent
} from "./components/cm-insurance-overall-overview/cm-insurance-overall-overview.component";
import {PartnerConfigPage} from "./page/overview/partner-config/partner-config.page";
import {CmPartnerComponent} from "./components/cm-partner/cm-partner.component";
import {
    CmSearchFlexibleContractComponent
} from "./search/cm-search-flexible-contract/cm-search-flexible-contract.component";
import {CmContractComponent} from "./components/cm-contract/cm-contract.component";
import {DmModule} from "../dm/dm.module";
import {
    CmContractStatusTypeOverviewPage
} from "./page/overview/cm-contract-status-type-overview/cm-contract-status-type-overview.page";
import {
    CmContractStatusOverviewPage
} from "./page/overview/cm-contract-status-overview/cm-contract-status-overview.page";
import {CmContractPage} from "./page/overview/cm-contract-page/cm-contract.page";
import {ContractSearchPage} from "./page/overview/contract-search/contract-search.page";
import {ContractHistoryPage} from "./page/overview/cm-contract-history-page/contract-history.page";
import {
    CmCreateNewInsurableObjectComponent
} from "./components/cm-create-new-insurable-object/cm-create-new-insurable-object.component";
import {
    CmCreateInsurableObjectOthersComponent
} from "./components/cm-create-insurable-object-others/cm-create-insurable-object-others.component";
import {
    CmCreateInsurableObjectPersonComponent
} from "./components/cm-create-insurable-object-person/cm-create-insurable-object-person.component";
import {
    CmCreateNewInsurableObjectObjectComponent
} from "./components/cm-create-new-insurable-object/object/cm-create-related-person-object/cm-create-new-insurable-object-object.component";
import {
    CmContractStandardNavigationComponent
} from "./components/cm-contract/components/cm-contract-standard-navigation/cm-contract-standard-navigation.component";
import {
    CmContractDocumentsNavigationComponent
} from "./components/cm-contract/components/cm-contract-documents-navigation/cm-contract-documents-navigation.component";
import {
    CmContractDocumentUploadComponent
} from "./components/cm-contract-document-upload/cm-contract-document-upload.component";
import {CmConfigPage} from "./page/overview/cm-config/cm-config.page";
import {
    CmContractDashboardStatusPage
} from "./page/overview/cm-contract-dashboard-status/cm-contract-dashboard-status.page";
import {
    CmContractNotificationTypeObjectComponent
} from "./components/cm-contract-notification-type-object/cm-contract-notification-type-object.component";
import {
    CmUpdateInsurableObjectComponent
} from "./components/cm-update-insurable-object/cm-update-insurable-object.component";
import {
    CmUpdateInsurableObjectObjectComponent
} from "./components/cm-update-insurable-object/object/cm-update-insurable-object-object/cm-update-insurable-object-object.component";
import {
    CmContractObjectsNavigationComponent
} from "./components/cm-contract/components/cm-contract-objects-navigation/cm-contract-objects-navigation.component";
import {
    CmContractNavigationBaseComponentComponent
} from "./components/cm-contract/components/cm-contract-navigation-base-component/cm-contract-navigation-base-component.component";
import {
    CmContractNavigationDropdownComponent
} from "./components/cm-contract-navigation-dropdown/cm-contract-navigation-dropdown.component";
import {CmContractLastPage} from "./page/object/cm-contract-last/cm-contract-last.page";
import {NsModule} from "../ns/ns.module";
import {
    CmContractCombinedFormCreatePage
} from "./page/cm-contract-combined-form-create/cm-contract-combined-form-create.page";
import {
    CmCreateContractCombinedFormComponent
} from "./components/cm-create-contract-combined-form/cm-create-contract-combined-form.component";
import {NgOptimizedImage} from "@angular/common";
import {CmContractInlineComponent} from "./components/cm-contract-inline-component/cm-contract-inline-component";
import {
    CmCreateInsurableObjectPersonRelationPersonComponent
} from "./components/cm-create-insurable-object-person-relation-person/cm-create-insurable-object-person-relation-person.component";
import {TmModule} from "../tm/tm.module";
import {ContractOverviewPage} from "./page/contract-overview-page/contract-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {CcKpiComponent} from "../cc/page/cc-overview-page/components/kpiComponent/cc-kpi.component";
import {
    CcStatisticsComponent
} from "../cc/page/cc-overview-page/components/statisticsComponent/cc-statistics.component";
import {
    CmStatisticsComponent
} from "./page/contract-overview-page/components/statisticsComponent/cm-statistics.component";
import {CmKpiComponent} from "./page/contract-overview-page/components/kpiComponent/cm-kpi.component";
import {UiModule} from "../ui/ui.module";
import {
    ContractBaseTypeComponent
} from "./components/object-base-components/contract-base-type-component/contract-base-type.component";
import {CmPartnerSideComponent} from "./components/cm-partner/view/side/cm-partner-side.component";
import {CmPartnerFullComponent} from "./components/cm-partner/view/full/cm-partner-full.component";
import {CmPartnerBaseComponent} from "./components/cm-partner/view/cm-partner-base.component";
import {
    ContractObjectFullComponent
} from "./components/object-base-components/contract-component/view/full/contract-object-full.component";
import {
    ContractObjectSideComponent
} from "./components/object-base-components/contract-component/view/side/contract-object-side.component";
import {
    ContractObjectComponent
} from "./components/object-base-components/contract-component/contract-object.component";
import {
    ContractObjectBaseComponent
} from "./components/object-base-components/contract-component/view/contract-object-base.component";

@NgModule({
    declarations: [
        ContractStructurePage,
        InsurableObjectPage,
        AssessmentGroupConfigPage,
        CmCustomerAssessmentComponent,
        CmInsuranceStackOverviewComponent,
        CmInsuranceOverallOverviewComponent,
        PartnerConfigPage,
        CmPartnerComponent,
        CmSearchFlexibleContractComponent,
        CmContractComponent,
        CmContractStatusTypeOverviewPage,
        CmContractStatusOverviewPage,
        CmContractPage,
        ContractSearchPage,
        ContractHistoryPage,
        CmCreateNewInsurableObjectComponent,
        CmCreateInsurableObjectOthersComponent,
        CmCreateInsurableObjectPersonComponent,
        CmCreateNewInsurableObjectObjectComponent,
        CmContractStandardNavigationComponent,
        CmContractDocumentsNavigationComponent,
        CmContractDocumentUploadComponent,
        CmConfigPage,
        CmContractDashboardStatusPage,
        CmContractNotificationTypeObjectComponent,
        CmUpdateInsurableObjectComponent,
        CmUpdateInsurableObjectObjectComponent,
        CmContractObjectsNavigationComponent,
        CmContractNavigationBaseComponentComponent,
        CmContractNavigationDropdownComponent,
        CmContractLastPage,
        CmContractCombinedFormCreatePage,
        CmCreateContractCombinedFormComponent,
        CmContractInlineComponent,
        CmCreateInsurableObjectPersonRelationPersonComponent,
        ContractOverviewPage,
        CmStatisticsComponent,
        CmKpiComponent,
        ContractBaseTypeComponent,
        CmPartnerSideComponent,
        CmPartnerFullComponent,
        CmPartnerBaseComponent,
        ContractObjectFullComponent,
        ContractObjectSideComponent,
        ContractObjectComponent,
        ContractObjectBaseComponent,

    ],
    exports: [
        ContractStructurePage,
        InsurableObjectPage,
        AssessmentGroupConfigPage,
        CmCustomerAssessmentComponent,
        CmInsuranceStackOverviewComponent,
        CmInsuranceOverallOverviewComponent,
        PartnerConfigPage,
        CmPartnerComponent,
        CmSearchFlexibleContractComponent,
        CmContractComponent,
        CmContractStatusTypeOverviewPage,
        CmContractStatusOverviewPage,
        CmContractPage,
        ContractSearchPage,
        ContractHistoryPage,
        CmCreateNewInsurableObjectComponent,
        CmCreateInsurableObjectOthersComponent,
        CmCreateInsurableObjectPersonComponent,
        CmCreateNewInsurableObjectObjectComponent,
        CmContractStandardNavigationComponent,
        CmContractDocumentsNavigationComponent,
        CmContractDocumentUploadComponent,
        CmConfigPage,
        CmContractDashboardStatusPage,
        CmContractNotificationTypeObjectComponent,
        CmUpdateInsurableObjectComponent,
        CmUpdateInsurableObjectObjectComponent,
        CmContractObjectsNavigationComponent,
        CmContractNavigationBaseComponentComponent,
        CmContractNavigationDropdownComponent,
        CmContractLastPage,
        CmContractCombinedFormCreatePage,
        CmCreateContractCombinedFormComponent,
        CmContractInlineComponent,
        CmCreateInsurableObjectPersonRelationPersonComponent,
        ContractBaseTypeComponent,
        ContractObjectFullComponent,
        ContractObjectSideComponent,
        ContractObjectComponent,
        ContractObjectBaseComponent,

    ],
    imports: [
        CoreModule,
        DmModule,
        NsModule,
        NgOptimizedImage,
        UiModule,
        TmModule,
    ]
})
export class CmModule implements DynamicModule {

    alias = 'cm';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return CmKpiComponent;
    }

    getStatisticsComponent() {
        return CmStatisticsComponent;
    }
}