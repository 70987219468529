export enum HgGdvMessageTypeEnum {
    HANDBOOK,
    INVENTORY,
    BUSINESS_TRANSACTION,
    PAYMENT_COLLECTION,
    CLAIM_INFORMATION,
    CLAIM_SETTLEMENT,
    FAQ
}

export const HgGdvMessageTypeLabels: Record<HgGdvMessageTypeEnum, string> = {
    [HgGdvMessageTypeEnum.HANDBOOK]: "Gliederung Handbuch",
    [HgGdvMessageTypeEnum.INVENTORY]: "logische Struktur Bestand",
    [HgGdvMessageTypeEnum.BUSINESS_TRANSACTION]: "logische Struktur Geschäftsvorfall",
    [HgGdvMessageTypeEnum.PAYMENT_COLLECTION]: "logische Struktur Inkasso",
    [HgGdvMessageTypeEnum.CLAIM_INFORMATION]: "logische Struktur Schadeninformation",
    [HgGdvMessageTypeEnum.CLAIM_SETTLEMENT]: "logische Struktur Schadenabrechnung",
    [HgGdvMessageTypeEnum.FAQ]: "Häufig gestellte Fragen (FAQ)"
};