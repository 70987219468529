import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";

@Component({
    selector: 'mvs-wf-process-step-activity-object-customer-phone-contact-availability',
    templateUrl: './cr-customer-phone-availability-wf-process-step-activity.component.html',
})
export class CrCustomerPhoneAvailabilityWfProcessStepActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {


    /**
     * refresh component
     */
    async refreshComponent() {
        this.initialized = true;
    }


}
