
<ng-container *ngIf="initialized && objectLoaded">

    <div *ngIf="size == 'S'" class="quickview --workflowS">
        <div class="header">{{dto.name}}
            <span>{{dto.typeDtoName}}</span>

        </div>
    </div>

    <div *ngIf="size == 'M'" class="quickview --workflow">
        <div class="header">{{dto.name}}
            <span>{{dto.typeDtoName}}</span>
        </div>
        <div class="content">Actual Step
            <span>{{dto.lastStep.processTypeStepDtoName}}</span>
        </div>
    </div>

    <div *ngIf="size == 'L'" class="quickview --workflowL">
        <div class="header">{{dto.name}}
            <span>{{dto.typeDtoName}}</span>
        </div>
        <div class="content">Actual Step
            <span>{{dto.lastStep.processTypeStepDtoName}}</span>
        </div>
        <div class="content">Actual Agent
            <span><mvs-form-control-output-user-image [value]="dto.lastStep.lastModifiedBy"></mvs-form-control-output-user-image></span>
        </div>
        <div class="footer">Last Interaction
            <span>{{dto.lastModifiedDate | date: 'dd.MM.YYYY'}}</span>
        </div>
    </div>

    <div *ngIf="size == 'XL'" class="quickview --workflowA">
        <div class="header">{{dto.name}}
            <span>{{dto.typeDtoName}}</span>
        </div>
        <div class="content" *ngFor="let step of dto.steps">
            Step {{step.id}}
            <span>{{step.processTypeStepDtoName}}</span>
        </div>
        <div class="footer">Last Interaction
            <span>{{dto.lastModifiedDate | date: 'dd.MM.YYYY'}}</span>
        </div>
    </div>

</ng-container>
