
<!--<mvs-widget *ngIf="widgetData" [widgetData]="widgetData"></mvs-widget>-->


<p-button (click)="getMyTickets()" [label]="'Get Tickets'"></p-button>
<!--
<mvs-object objectType="cm.Contract" [objectId]="null"></mvs-object>

<p-button (click)="deriveTicketActions()" [label]="'Get Actions'"></p-button>
-->

<!--<p-button (click)="testNewSelect()" [label]="'Run Filter'"></p-button>-->

<!--
<p-button (click)="getLastVisits()" [label]="'Last visits'"></p-button>
-->

<!--<mvs-ns-notification-types-for-object [objectIdentifier]="user22"></mvs-ns-notification-types-for-object>-->

<!--<mvs-ns-notification-send [mode]="1" [objectIdentifier]="ticket" [notificationSourceTypeId]="52"></mvs-ns-notification-send>-->

<!--<mvs-ns-notification-types-for-object [objectIdentifier]="ticket" [mode]="NsNotificationTypesModeEnum.object"></mvs-ns-notification-types-for-object>-->

<!--<p-button label="GROUPS" (click)="getGroups()"></p-button>-->
<!--<p-button label="COUNTS" (click)="getCounts()"></p-button>-->
<!--<p-button label="GROUP INFO" (click)="getGroupInfo()"></p-button>-->

<!--<mvs-widget *ngIf="customerContractWidgetData" [widgetData]="customerContractWidgetData"></mvs-widget>-->

<!--<p-button label="CHECK PROFILE" (click)="checkProfile()"></p-button>-->
<!--<p-button label="ASSESS CUSTOMER" (click)="assessCustomer()"></p-button>-->

<!--<p-button label="HOUSEHOLD" (click)="getHousehold()"></p-button> -->

<!--<p-button label="Analyze" (click)="analyze()"></p-button>-->

<!--<mvs-crud-object objectType="cr.AssessmentGroupCheck"></mvs-crud-object>-->

<!--<jb-job-status></jb-job-status>-->
