<h3 class="text-color-secondary mb-4 mt-2">Widgets</h3>
<div class="container card">
    <div class="sidebar">
        <ul class="input-section">
            <li class="input-list" *ngFor="let option of sidebarOptions" (click)="selectWidget(option.id)"
                [ngClass]="option.id === displayWidget  ? 'bg-primary-100 border-1 border-primary-300 border-round-lg' : ''">
                {{ option.name }}
            </li>
        </ul>
    </div>

    <div class="content col-10 mx-auto">
        <div class="grid">

            <div class="col-12"
                 *ngIf="displayWidget == 1">
                <mvs-widget
                        [widgetData]="objectCreateWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 2">
                <mvs-widget
                        [widgetData]="objectUpdateWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 3">
                <mvs-widget
                        [widgetData]="dataWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 4">
                <mvs-widget
                        [widgetData]="listWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 5">
                <mvs-widget
                        [widgetData]="tableWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 6">
                <mvs-widget
                        [widgetData]="cardWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-4"
                 *ngIf="displayWidget == 7">
                <mvs-widget
                        [widgetData]="chartWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 8">
                <mvs-widget
                        [widgetData]="timeLineWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 9">
                <mvs-widget
                        [widgetData]="categoryWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 10">
                <mvs-widget
                        [widgetData]="historyChartWidgetData"
                ></mvs-widget>
            </div>

            <div class="col-12"
                 *ngIf="displayWidget == 11">
                <mvs-widget
                        [widgetData]="historyPeriodWidgetData"
                ></mvs-widget>
            </div>

        </div>
    </div>
</div>

