import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerDto } from "./customer.dto";
import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";
// import { CustomerPhoneContactAvailabilityValueEnum } from "../enum/customer-phone-contact-availability-value-enum.enum";

export class CustomerPhoneContactAvailabilityDto extends DtoDetail{


    public customerDto? : CustomerDto;
    public customerDtoId : number;
    public customerDtoName : string;
    public mondayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public mondayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public mondayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public mondayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public tuesdayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public tuesdayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public tuesdayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public tuesdayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public wednesdayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public wednesdayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public wednesdayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public wednesdayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public thursdayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public thursdayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public thursdayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public thursdayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public fridayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public fridayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public fridayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public fridayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public saturdayMorning: CustomerPhoneContactAvailabilityValueEnum;
    public saturdayNoon: CustomerPhoneContactAvailabilityValueEnum;
    public saturdayAfternoon: CustomerPhoneContactAvailabilityValueEnum;
    public saturdayEvening: CustomerPhoneContactAvailabilityValueEnum;
    public comment: string;

}