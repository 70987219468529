import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeStepDto} from "./wf-process-type-step.dto";
import {WfProcessTypeStepNextTypeEnum} from "../enum/wf-process-type-step-next-type.enum";

export class WfProcessTypeStepNextDto extends DtoDetail {

    public processTypeStepDto?: WfProcessTypeStepDto;
    public processTypeStepDtoId: number;
    public processTypeStepDtoName: string;

    public name: string;
    public description: string;
    public position: number;
    public stepNextType: WfProcessTypeStepNextTypeEnum;

    public nextProcessTypeStepDto?: WfProcessTypeStepDto;
    public nextProcessTypeStepDtoId: number;
    public nextProcessTypeStepDtoName: string;


}