import {Injectable} from "@angular/core";
// import {BatchRequestHandler} from "../../../../../core/batch-interceptor/batch-request-handler.interface";
import {HttpClient, HttpRequest, HttpResponse} from "@angular/common/http";
import {FilterCriteria, MvsCrudService, ObjectRequestList} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TestHarisService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, '/cr/customers')
    }
    shouldBatch(url: string): boolean {
        return url.includes('/cr/customers');
    }
    combinationLogic(requests: HttpRequest<any>[]): any {
        const filterCriteria = [];
        for (let item of requests) {
            const urlPaths = item.url.split('/');
            filterCriteria.push(FilterCriteria.create('id',FilterCriteria.cOperatorEqual,+urlPaths[urlPaths.length - 1]));
        }
        const request = new ObjectRequestList(false, filterCriteria, []);
        return request;
    }

    separationLogic(response: HttpResponse<any>, requests: HttpRequest<any>[]): any[] {
        return requests.map(req => {
            const id = req.params.get('id');
            const filteredResponse = response.body.filter(user => user.id === id);
            return filteredResponse;
        });
    }

}