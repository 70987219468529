import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {
  CmCreateInsurableObjectOthersComponent
} from "../../../cm/components/cm-create-insurable-object-others/cm-create-insurable-object-others.component";

@Injectable({
  providedIn: 'root'
})
export class ContractInsurableObjectService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ci/contractInsurableObjects')
  }

  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode == MvsCrudModeEnum.create) {
      return CmCreateInsurableObjectOthersComponent;
    } else {
      return null; // use default
    }

  }

}