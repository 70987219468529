<div class="bg-white mt-5">
    <mvs-config-header header="End User Web App Configuration"
                       icon="pi pi-cog"
                       [description]="'test description'"
                       [links]="navigationEntries">
    </mvs-config-header>

    <div class="mt-3">
        <ul class="flex align-items-center overflow-x-auto gap-3 px-2 py-3 list-none border-bottom-1 border-300">

            <li *ngFor="let item of widgetEntries">
                <button pButton pRipple
                        (click)="handleTabSelection(item)"
                        [label]="item.label"
                        [ngClass]="{'p-button-text text-700': activeWidget?.id != item.id}">
                </button>
            </li>
        </ul>
    </div>

    <ng-container *ngIf="activeWidget">

        <div *ngIf="activeWidget.id == 'text'">
            <mvs-widget
                    *ngIf="textWidget"
                    [widgetData]="textWidget">
            </mvs-widget>
        </div>

        <div *ngIf="activeWidget.id == 'color'">
            <mvs-widget
                    *ngIf="colorWidget"
                    [widgetData]="colorWidget">
            </mvs-widget>
        </div>

        <div *ngIf="activeWidget.id == 'ticket'">
            <mvs-widget
                    *ngIf="ticketWidget"
                    [widgetData]="ticketWidget">
            </mvs-widget>
        </div>

        <div *ngIf="activeWidget.id == 'promotion'">
            <mvs-widget
                    *ngIf="promotionsWidget"
                    [widgetData]="promotionsWidget">
            </mvs-widget>
        </div>


        <div class="grid" *ngIf="activeWidget.id == 'staticNotifications'">

            <div class="col-3">
                <mvs-widget
                        [widgetData]="staticNotificationsWidget"
                        (onObjectSelect)="handleObjectSelect($event)"
                ></mvs-widget>
            </div>

            <div class="col-9">

                <div class="grid">
                    <div class="col-12">
                        <mvs-widget
                                *ngIf="staticNotificationsContractPersonWidget"
                                [widgetData]="staticNotificationsContractPersonWidget">
                        </mvs-widget>

                    </div>

                    <div class="col-12">


                        <mvs-widget
                                *ngIf="staticNotificationsContractTypeWidget"
                                [widgetData]="staticNotificationsContractTypeWidget">
                        </mvs-widget>
                    </div>

                    <div class="col-12">

                        <mvs-widget
                                *ngIf="staticNotificationsInsurableObjectTypeWidget"
                                [widgetData]="staticNotificationsInsurableObjectTypeWidget">
                        </mvs-widget>
                    </div>


                </div>
            </div>


        </div>


    </ng-container>


</div>
