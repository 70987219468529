import {DtoDetail} from "@kvers/alpha-core-common";
import {DmDocumentStatusInternalEnum} from "../enum/dm-document-status-internal.enum";
import {DmEntityStatusEnum} from "../enum/dm-entity-status.enum";

export class DmDocumentDto extends DtoDetail {

    public aliasId : string;
    public dmDocumentTypeDtoId : number;
    public dmDocumentTypeDtoName : string;
    public dmDocumentTypeDtoImage : string;
    public dmDocumentTypeDtoColor : string;
    public dmDocumentStorageDtoId : number;
    public dmDocumentStorageDtoName : string;
    public dmDocumentStorageDtoImage : string;
    public dmDocumentStorageDtoColor : string;
    public entityStatus : DmEntityStatusEnum;
    public status : DmDocumentStatusInternalEnum;
    public externalId : string;
    public mimeType : string;
    public mimeTypeInfo : string;
    public fileSize : number;
    public analyzed : boolean;
}