import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {SearchIndexRequestDto} from "../../../logic/search-index-request.dto";
import {IndexRunService} from "../../../service/api/index-run.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {SearchRequestDto} from "../../../logic/search-request.dto";
import {SearchResultDto} from "../../../logic/search-result.dto";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";


@Component({
    selector: 'si-browse',
    templateUrl: './si-browse.component.html',
})
export class SiBrowseComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Browse Indexed Data";

    indexWidget: WidgetData;

    formSearch: FormGroup;
    formBrowseObjectType: FormGroup;
    formBrowseObject: FormGroup;

    widgetBrowseObjectTypeTokens: WidgetData;
    widgetBrowseObjectTypeReferences: WidgetData;

    widgetBrowseObjectTokens: WidgetData;
    widgetBrowseObjectReferences: WidgetData;

    widgetBrowseObjectReferenceTokens: WidgetData;

    objectTypes: ObjectTypeDto[];

    selectedIndexRun: ObjectIdentifier;

    ratings: { label: string, key: number}[];

    searchResult: SearchResultDto;

    tabItems: MenuItem[];
    activeItem: MenuItem | undefined;

    searchHidden: boolean = false;


    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected fb: FormBuilder,
                protected objectTypeService: ObjectTypeService,
                protected indexSearchService: IndexRunService,
                protected messageService: MvsMessageService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.tabItems = [
            { id : "search", label : "Search", icon : "fa-thin fa-magnifying-glass"},
            { id : "browseObjectType", label : "Browse by Type", icon : "fa-sharp fa-thin fa-print-magnifying-glass"},
            { id : "browseObject", label : "Browse by Object", icon : "fa-sharp fa-thin fa-magnifying-glass-waveform"},

        ];

        this.activeItem = this.tabItems[0];

        this.ratings = [
            { label: "poor", key : 0},
            { label: "fair", key : 1},
            { label: "ok", key : 2},
            { label: "good", key : 3},
            { label: "veryGood", key : 4},
            { label: "perfect", key : 5},
            { label: "excellent", key : 6}
        ];

        this.refreshComponent();

        this.initFormGroupSearch();
        this.initFormGroupBrowseObjectType();
        this.initFormGroupBrowseObject();

        // retrieve all object types
        this.objectTypeService.list(ObjectRequestList.createBasic(false, [], [new Sorting("alias", true)])).subscribe(value => {
            this.objectTypes = value.entries;
            this.initialized = true;
        });

    }

    handleSubmitBrowseByObjectType() {
        if (this.formBrowseObjectType.status == "VALID") {
            this.refreshBrowseObjectTypeWidgets();
        }
    }

    initFormGroupBrowseObjectType() {
        this.formBrowseObjectType = this.fb.group({
            requestedObjectTypeIds: new FormControl<number[] | null>(null,[Validators.required]),
        });
    }

    initFormGroupBrowseObject() {
        this.formBrowseObject = this.fb.group({
            objectType: new FormControl<number | null>(null,[Validators.required]),
            objectId: new FormControl<number | null>(null,[Validators.required]),
        });
    }

    refreshBrowseObjectTypeWidgets() {

        const selectedObjectTypes: number[] = this.formBrowseObjectType.get("requestedObjectTypeIds").value;

        // create or Filter
        const filterCriteria = FilterCriteria.createOrFromArrayFunction("referenceObjectTypeId", selectedObjectTypes, item => item + "");

        this.widgetBrowseObjectTypeTokens = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.type.token",
            `Tokens`,
            "si.IndexedToken",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [filterCriteria],
                [new Sorting("id",true)],
                PagingDto.create(0, 50))
        );

        this.widgetBrowseObjectTypeReferences = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.type.reference",
            `References`,
            "si.IndexedReference",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [filterCriteria],
                [new Sorting("id",true)],
                PagingDto.create(0, 50))
        );

    }

    handleBrowseObjectSelectedReferences(selectedEntries: ObjectIdentifierData[]) {

        const filterCriteria = new FilterCriteria();

        this.widgetBrowseObjectReferenceTokens = null;

        if (!selectedEntries || selectedEntries.length == 0) {
            return;
        }

        for (let selectedEntry of selectedEntries) {
            filterCriteria.addFilterCriteriaToOr(
                FilterCriteria.createAndInBrackets(
                    FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, selectedEntry.data["sourceObjectTypeId"]),
                    FilterCriteria.create("referenceObjectId", FilterCriteria.cOperatorEqual, selectedEntry.data["sourceObjectId"])
                )
            );
        }

        this.widgetBrowseObjectReferenceTokens = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.type.reference.token",
            `Tokens`,
            "si.IndexedToken",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [filterCriteria],
                [new Sorting("id",true)],
                PagingDto.create(0, 50))
        );

    }


    handleSubmitBrowseByObject() {
        if (this.formBrowseObject.status == "VALID") {
            this.refreshBrowseObjectWidgets();
        }
    }

    refreshBrowseObjectWidgets() {

        const objectType: number = this.formBrowseObject.get("objectType").value;
        const objectId: number = this.formBrowseObject.get("objectId").value;

        // create or Filter
        const filterCriteria: FilterCriteria[] =
            [
                FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, objectType),
                FilterCriteria.create("referenceObjectId", FilterCriteria.cOperatorEqual, objectId),
            ];


        this.widgetBrowseObjectTokens = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.token",
            `Tokens`,
            "si.IndexedToken",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                filterCriteria,
                [new Sorting("id",true)],
                PagingDto.create(0, 50))
        );

        this.widgetBrowseObjectReferences = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.reference",
            `References`,
            "si.IndexedReference",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                filterCriteria,
                [new Sorting("id",true)],
                PagingDto.create(0, 50)),
            WidgetDataParam.create("selectionMode", "multi")
        );


    }

    onSubmit() {

        if (this.formSearch.status == "VALID") {

            // requestedObjectTypeIds: number[], limitProcessingCount: number, resolveSources: boolean, threadCount: number)
            const searchRequestDto = new SearchRequestDto();

            searchRequestDto.requestedObjectTypeIds = this.formSearch.value["requestedObjectTypeIds"];
            searchRequestDto.splitResultsByType = this.formSearch.value["splitResultsByType"];
            searchRequestDto.maxHits = this.formSearch.value["maxHits"];
            searchRequestDto.minSearchRating = this.formSearch.value["minSearchRating"];
            searchRequestDto.searchString = this.formSearch.value["searchString"];

            // start index search
            this.indexSearchService.searchRequest(searchRequestDto).subscribe(value => {


                for (let searchResultEntry of value.searchResultEntries) {
                    searchResultEntry["dataFieldsList"] = [];

                    for (let dataFieldsKey in searchResultEntry.dataFields) {
                        searchResultEntry["dataFieldsList"].push(searchResultEntry.dataFields[dataFieldsKey]);
                    }

                }

                this.searchResult = value;

            }, error => {
                // error message would have already been shown (no further activity required)
            });

        } else {
            this.messageService.showErrorMessage("Eingabe", "Bitte selektion füllen");
        }

    }

    initFormGroupSearch() {

        this.formSearch = this.fb.group({
            requestedObjectTypeIds: [null],
            splitResultsByType: [false],
            maxHits: [20, Validators.required],
            minSearchRating: [null],
            searchString: ["Max", Validators.required]
        });
    }


    onSelect(event: any) {
        this.selectedIndexRun = event;
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        // this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
