<ng-container *ngIf="initialized">

    <mvs-widget
            *ngIf="widgetTableDocument"
            [widgetData]="widgetTableDocument"
            (onObjectSelect)="onObjectSelect($event)"
    ></mvs-widget>

    <mvs-xx-dialog-box-object-document-component
            *ngIf="visible && documentObject"
            [visible]="visible"
            [documentObject]="documentObject"
            (dialogVisibleEvent)="visible = $event"
    ></mvs-xx-dialog-box-object-document-component>

</ng-container>
