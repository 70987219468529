import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CategoryModel} from "../../../rp/dto/category.dto";
import {ProvisionRequestRuntimeDto} from "../dto/provision-request-runtime.dto";

@Injectable({
    providedIn: 'root'
})
export class ProvisionRequestService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cp/provisionRequests')
    }

    //TODO: Enable Controller
    /*
        @GetMapping("/simulate/single/{contractId}")
      public ProvisionRequestRuntimeDto simulateSingle(
              @PathVariable Long contractId) {

          ProvisionRequestRuntime runtime = this.provisionProcessorService.simulate(List.of(contractId));
          ProvisionRequestRuntimeDto dto = this.provisionDtoService.convert(runtime);
          return dto;
      }
     */

    simulateSingle(contractID: number): Observable<ProvisionRequestRuntimeDto> {
        return this.getGeneric<ProvisionRequestRuntimeDto>(`/simulate/single/${contractID}`);
    }


}