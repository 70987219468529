import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
  TmTicketAppointmentInlineComponent
} from "../../component/tm-ticket-appointment-inline-component/tm-ticket-appointment-inline-component";

@Injectable({
  providedIn: 'root'
})
export class TicketAppointmentService
    extends MvsCrudService {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketAppointments');
  }
  getObjectIcon(): string {
    return 'pi pi-calendar';
  }

  getInlineComponent() {
    return TmTicketAppointmentInlineComponent;
  }
}
