<ng-container *ngIf="initialized">

    <ng-container *ngIf="historyList.length">
        <div class="col-12 flex flex-column row-gap-3 px-3 mb-6 py-0" style="max-height: 400px; overflow: auto">

            @for (item of historyList; track item.id) {
                <ng-container
                        *ngIf="item['uiType'] == 'comment' && (selectedAction == enumCommentHistory['All Activity'] || selectedAction == enumCommentHistory.Comment)">

                    <!--                                        <div class="flex">-->

                    <!--                                            <mvs-form-control-output-user-image [value]="item['createdBy']"-->
                    <!--                                                                                [displayInfo]="true">-->
                    <!--                                            </mvs-form-control-output-user-image>-->

                    <div class="w-full">
                        <div class="grid mx-0">
                                <span class="block text-900 col-8">
                                     <mvs-form-control-output-user-image [value]="item['createdBy']"
                                                                         [displayInfo]="true">
                                     </mvs-form-control-output-user-image>
                                    <!-- {{item['createdBy'] | titlecase}} -->
                                </span>
                            <span class="block text-700 col-4 text-right">{{ item['createdDate'] | mvsDateFormat }}</span>
                        </div>

                        <span class="block text-800 line-height-3"
                              style="word-break: break-all; white-space: pre-wrap; margin-left: 3rem">{{ item['comment'] }}</span>
                    </div>
                    <!--                                        </div>-->
                </ng-container>

<!--                <ng-container-->
<!--                        *ngIf="item['uiType'] == 'visit'">-->
<!--                    <div class="w-full">-->
<!--                        <div class="grid mx-0">-->
<!--                                <span class="block text-900 col-8">-->
<!--                                     <mvs-form-control-output-user-image [value]="item['createdBy']"-->
<!--                                                                         [displayInfo]="true">-->
<!--                                     </mvs-form-control-output-user-image>-->
<!--                                </span>-->
<!--                            <span class="block text-700 col-4 text-right">{{ item['createdDate'] | mvsDateFormat }}</span>-->
<!--                        </div>-->

<!--                        <span class="block text-800 line-height-3"-->
<!--                              style="word-break: break-all; white-space: pre-wrap; margin-left: 3rem">-->
<!--                               visited-->
<!--                            </span>-->
<!--                    </div>-->
<!--                </ng-container>-->

                <ng-container
                        *ngIf="item['uiType'] == 'action' && (selectedAction == enumCommentHistory['All Activity'] || selectedAction == enumCommentHistory.History)">
<!--                    <div class="w-full">-->

                        <div class="w-full">
                            <div class="grid mx-0">
                                <span class="block text-900 col-8">
                                     <mvs-form-control-output-user-image [value]="item['createdBy']"
                                                                         [displayInfo]="true">
                                     </mvs-form-control-output-user-image>
                                </span>
                                <span class="block text-700 col-4 text-right">{{ item['createdDate'] | mvsDateFormat }}</span>
                            </div>

                            <span class="block text-800 line-height-3"
                                  style="word-break: break-all; white-space: pre-wrap; margin-left: 3rem">
                               created action
                                <span class="text-primary">{{ item['ticketTypeActionDtoName'] }}</span>
                                 <div class="block text-900 mt-1"
                                         style="word-break: break-all;">{{ item['comment'] }}</div>
                            </span>
                        </div>




<!--                        <div class="flex align-items-center gap-3">-->

<!--                            <mvs-form-control-output-user-image [value]="item['createdBy']" [displayInfo]="true"></mvs-form-control-output-user-image>-->

<!--                            <div class="grid w-full">-->
<!--                            <span class="block text-900 col-8">-->
<!--&lt;!&ndash;                                <span class="font-medium">{{item['createdBy'] | titlecase}}</span>&ndash;&gt;-->
<!--                                    created action:-->
<!--                                <span class="text-primary">{{ item['ticketTypeActionDtoName'] }}</span>-->
<!--                            </span>-->
<!--                                <span class="block text-700 col-4 text-right">{{ item['createdDate'] | mvsDateFormat }}</span>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                        <span-->
<!--                                class="block text-900 mt-2"-->
<!--                                style="word-break: break-all;">{{ item['comment'] }}</span>-->
<!--                    </div>-->
                </ng-container>
            }

        </div>
    </ng-container>

</ng-container>