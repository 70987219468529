    import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    ObjectBaseComponent,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation
} from "@kvers/alpha-core-common";
    import {Observable} from "rxjs";


@Component({
    selector: 'cm-contract-object-component',
    templateUrl: 'contract-object.component.html',
})
export class ContractObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    ngOnInit() {
        super.ngOnInit();
    }

    protected getRetrieveObjectObservable(): Observable<any> {

        const dtoRequest: ObjectRequestList = new ObjectRequestList(
            false,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []);

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            false,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode('contractType'),
            ObjectRequestComplexNode.createRelationNode("joinCurrentMainPartner", ObjectRequestRelation.createJoin("+currentMainPartner")),
            ObjectRequestComplexNode.createRelationNode("joinCurrentMainCustomer", ObjectRequestRelation.createJoin("+currentMainCustomer"))
                .addNodes(ObjectRequestComplexNode.createRelationNode("personDto", ObjectRequestRelation.createJoin("person")))
        );
        return this.crudService.list(dtoRequest);
    }

    protected storeNewDto(dto: any) {
        this.dto = dto.entries[0];
        super.storeNewDto(this.dto);

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
