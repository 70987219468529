import {Component, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";

@Component({
    selector: 'hc-integration-object-base-component',
    templateUrl: 'integration-type-object-base.component.html',
    styleUrls: ['integration-type-object-base.component.scss'],
})

export class IntegrationTypeObjectBaseComponent extends ObjectBaseComponent implements OnInit, OnChanges {

    name: string = "Integration Type Object";

    ngOnInit() {
        this.initialized = true;
        this.handleName(this.name);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

}