<ng-container *ngIf="initialized">
  <p-dialog
    header="Variable"
    [(visible)]="openDialog"
    [style]="{ width: '50vw', height: '85%', background: 'white' }"
    (onHide)="handleCancel()"
  >
    <mvs-te-variables
      [variableProviderService]="variableProviderService"
      [alias]="alias"
      [attributeName]="attributeName"
      [contentProviderId]="contentProviderId"
      [isAddButtonShown]="false"
      [isTitleShown]="false"
      [treeWidth]="false"
      [selectMode]="'single'"
      [dragMode]="false"
      (onVariableSelect)="handleVariableSelect($event)"
    ></mvs-te-variables>
    
    <ng-template pTemplate="footer">
      <div class="justify-content-center flex">
        <p-button
          [disabled]="!isVariableSelected"
          icon="pi pi-check"
          (click)="handleConfirmVariableSelect()"
          label="Confirm"
          styleClass="p-button-text"
        ></p-button>

        <p-button
          icon="pi pi-times"
          (click)="handleConfirmClose()"
          label="Close"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
