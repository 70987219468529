import {DtoDetail} from "@kvers/alpha-core-common";
import {PersonDto} from "./person.dto";
import {PersonRelationTypeDto} from "./person-relation-type.dto";

export class PersonRelationPersonDto extends DtoDetail {


    public personADto? : PersonDto;
    public personADtoId : number;
    public personADtoName : string;
    public relationTypeADto? : PersonRelationTypeDto;
    public relationTypeADtoId : number;
    public relationTypeADtoName : string;
    public personBDto? : PersonDto;
    public personBDtoId : number;
    public personBDtoName : string;
    public relationTypeBDto? : PersonRelationTypeDto;
    public relationTypeBDtoId : number;
    public relationTypeBDtoName : string;
    public endDate: Date;
    public personDto: PersonDto;

}