import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ObjectBrowserResultDto} from "@kvers/alpha-core-common";
import {environment} from "../../../../../environments/environment";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ReportService} from "../../../service/api/report.service";
import {ObjectTypeId} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mvs-rp-report-page',
  templateUrl: './rp-config.page.html',
  styleUrls: ['./rp-config.page.scss']
})
export class RpConfigPage extends PageComponent implements OnInit {

  reportMeta: any;
  mainObject: ObjectTypeId;
  reportMetaBusy: boolean;

  reportWidget: WidgetData;
  reportWidgetBusy: boolean;

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  constructor(
      protected http: HttpClient,
      protected reportService: ReportService,
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {

    this.initCrudTree();
  }

  onChangedObject(changeInformation: ObjectChangeInformation) {

    this.refreshReportMeta();
    this.refreshWidget();

  }

  onMainSelectedObjectChange(objectTypeId: ObjectTypeId) {
    this.mainObject = objectTypeId;
    this.refreshReportMeta();
    this.refreshWidget();
  }


  /**
   * Create Node for Report Object.
   * @param reportObject
   */
  uiReportMetaCreateReportObjectNode(reportObject: any, reportRuntime: any) : any {

    const node = {};

    node["label"] = reportObject["name"];
    node["type"] = "ReportObject";
    node["styleClass"] = "report-object";
    node["description"] = reportObject["description"];
    node["objectType"] = reportObject["objectTypeDtoName"]
    node["children"] = [];
    node["expanded"] = true;

    // derive all fields
    node["fields"] = [];

    if (reportRuntime["reportFields"]) {
      for (const reportField of reportRuntime["reportFields"]) {
        if (reportField["reportObjectDtoId"] == reportObject["id"]) {
          node["fields"].push(reportField);
        }
      }
    }

    return node;

  }

  uiReportMetaAddAttribute(icon: string, name: string, value: any) : any {

    let internalValue = value;

    if (!internalValue) {
      internalValue = "<not set>";
    }
    return {
          icon : icon,
          name : name,
          value : internalValue
      };
  }
  /**
   * Create Node for Report Object Link.
   * @param reportObject
   */
  uiReportMetaCreateReportObjectLinkNode(reportObjectLink: any) : any {

    const node = {};

    node["label"] = reportObjectLink["name"];
    node["type"] = "ReportObjectLink";
    node["styleClass"] = "report-object-link";
    node["description"] = reportObjectLink["description"];
    node["expanded"] = true;

    node["children"] = [];

    node["attributes"] = [];

    var linkType = "";

    if (reportObjectLink["linkType"]) {
      if (reportObjectLink["linkType"] == 0) {
        linkType = "1:1";
      } else if (reportObjectLink["linkType"] == 1) {
        linkType = "1:N";
      }
    }

    var linkJoin = "";

    if (reportObjectLink["linkJoin"]) {
      if (reportObjectLink["linkJoin"] == 0) {
        linkJoin = "Main";
      } else if (reportObjectLink["linkJoin"] == 1) {
        linkJoin = "Side";
      }
    }

    node["attributes"].push( this.uiReportMetaAddAttribute("", "Prio:", reportObjectLink["priority"]));
    node["attributes"].push( this.uiReportMetaAddAttribute("", "Link Typ:", linkType));
    node["attributes"].push( this.uiReportMetaAddAttribute("", "Join:", linkJoin));
    node["attributes"].push( this.uiReportMetaAddAttribute("", "Attribut:", reportObjectLink["linkAttribute"]));
    node["attributes"].push( this.uiReportMetaAddAttribute("", "Mehrere:", (reportObjectLink["multiple"] ? "ja": "nein")));
    node["attributes"].push( this.uiReportMetaAddAttribute("", "Optional:", (reportObjectLink["optional"] ? "ja" : "nein")));

    return node;

  }

  uiResolveReportMetaNodes(parentNode: any[], reportRuntime: any, reportObject: any, processedObjects: any[]) {

    if (processedObjects[reportObject["id"]]) {

      const errorNode = {};
      // create error node
      errorNode["label"] = "Rekursion!";
      errorNode["type"] = "error";
      errorNode["styleClass"] = "error";
      errorNode["description"] = "Bitte Konfiguration ändern, da eine Rekursion erkannt wurde.";
      errorNode["expanded"] = true;

      parentNode.push(errorNode);

      return;
    }


    let reportObjectNode = this.uiReportMetaCreateReportObjectNode(reportObject, reportRuntime);

    parentNode.push(reportObjectNode);
    processedObjects[reportObject["id"]] = true;


    // retrieve connected objects
    if (reportRuntime["reportObjectLinks"]) {
      for (const reportObjectLink of reportRuntime["reportObjectLinks"]) {
        if (reportObjectLink["reportObjectDtoId"] == reportObject["id"]) {

          // this link is relevant, add link
          const linkNode = this.uiReportMetaCreateReportObjectLinkNode(reportObjectLink);
          reportObjectNode["children"].push(linkNode);

          // retrieve connected target object
          for (let reportTargetObject of reportRuntime["reportObjects"]) {
            if (reportTargetObject["id"] == reportObjectLink["targetObjectDtoId"]) {
              this.uiResolveReportMetaNodes(linkNode["children"], reportRuntime, reportTargetObject, processedObjects);
              break;
            }
          }
        }
      }
    }

  }

  uiRefreshReportMeta(reportRuntime: any) {

    console.log(reportRuntime);

    this.reportMeta = [];

    // retrieve root object
    let startReportObject = null;

    for (let reportObject of reportRuntime["reportObjects"]) {
      if (reportObject["startObject"]) {
        startReportObject = reportObject;
        break;
      }
    }

    const processedObjects = [];

    if (startReportObject) {
      this.uiResolveReportMetaNodes(this.reportMeta, reportRuntime, startReportObject, processedObjects);
    }



  }

  refreshReportMeta() {

    if (!this.mainObject) {
      return;
    }

    this.reportMetaBusy = true;

    // load meta data
    this.reportService.getMeta(this.mainObject.objectId, new ObjectRequestList(false, null, null)).subscribe(value => {
      this.uiRefreshReportMeta(value);
      this.reportMetaBusy = false;
    });

  }

  refreshWidget() {

    // Widget Contracts Report
    this.reportWidget = new WidgetData();
    this.reportWidget.id = 1;
    this.reportWidget.idAlias = "Test.Test.REPORT";
    this.reportWidget.name = "Test Report";
    this.reportWidget.uiComponent = "table";
    this.reportWidget.dataProvider = "list";
    this.reportWidget.dataSource = "report";
    this.reportWidget.dataProviderObject = this.mainObject.objectId + "";
    this.reportWidget.setParamValue("size", "S");

  }


  initCrudTree() {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'rp.ReportGroup',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Reportgruppe",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Reportgruppe anlegen"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'rp.Report',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Report",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Report anlegen"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'rp.ReportFilter',
          'level'             : 3,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Report Filter",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Filter anlegen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'rp.ReportSorting',
          'level'             : 4,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Report Sortierung",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Sortierung anlegen"
        }
      ]
    };


    this.subObjectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'rp.Report',
          'level'             : 1,
          'parentBinding'     : 'none'
        },
        {
          'id'                : 2,
          'objectAlias'       : 'rp.ReportObject',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Objekte",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Objekt anlegen"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'rp.ReportObjectLink',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Verknüpfung",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neue Verknüpfung anlegen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'rp.ReportField',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Felder",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Feld anlegen"
        }
      ]
    };

  }

}
