import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {MvsApiServiceUploadInterface} from "@kvers/alpha-core-common";
import {
  WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {
  DmDocumentUploadWfProcessStepActivityComponent
} from "../../component/wf/dm-document-upload-wf-process-step-activity/dm-document-upload-wf-process-step-activity.component";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
  WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {DmDocumentComponent} from "../../component/dm-document/dm-document.component";


@Injectable({
  providedIn: 'root'
})
export class DmDocumentService extends MvsCrudService implements MvsApiServiceUploadInterface, WfProcessStepActivityMainProviderInterface {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/dm/dmDocuments');
  }


  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return DmDocumentComponent;
    } else {
      return super.getObjectComponent(mode);
    }
  }

  /**
     * Upload document.
     * @param file
     * @param documentTypeId
     */
  public upload(file: File, documentTypeId: number): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();

    formData.append("file", file);

    const req = new HttpRequest('POST', `${this.apiUrl}/upload/${documentTypeId}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }


  /**
   * Analyze document.
   * @param id
   */
  public analyze(id: number): Observable<DtoDetail> {

      const url = `${this.apiUrl}/analyze/${id}`;
      return this.getInternal(url);

  }

  /**
   * Retrieve Form Data. This method will only return a result, if the document was analyzed before!
   * @param id
   */
  public getFormData(id: number): Observable<DtoDetail> {

    const url = `${this.apiUrl}/formData/${id}`;
    return this.getInternal(url);

  }

  /**
   * Analyze a list of documents.
   * @param ids
   */
  public analyzeDocuments(ids: number[]): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/analyzeDocuments',ids);
  }


  /**
   * Download document.
   * @param id
   */
  public download(id: number) : Observable<Blob> {
    const url = `${this.apiUrl}/data/${id}/$`;

    return this.http.get<Blob>(url, { responseType : 'blob' as 'json'});

  }

  /**
   * Download document via ID.
   * @param id
   */
  public downloadViaUrl(id: number) : Observable<string> {

    const url = `${this.apiUrl}/data/${id}/$`;

    return this.http.get<Blob>(url, { responseType : 'blob' as 'json'})
        .pipe(
            map(value => {
              let file = new Blob([value], { type: 'application/pdf' })
              return URL.createObjectURL(file);
            })
        );

  }

  getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
    return DmDocumentUploadWfProcessStepActivityComponent;
  }

  getObjectTopBarVisibility(): boolean {
    return false;
  }


}
