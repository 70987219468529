<ng-container *ngIf="customerAssessment && initialized">
    <div class="flex">

        <!-- Insurance Stack Overview-->
            <p-panel
                    [header]="showTitle ? stackTitle : ''"
                    class="panel-blank pb-0 col-12 md:col-9 lg:col-10"
                    *ngIf="showStack">

                <mvs-cm-insurance-stack-overview-component
                        [showStack]="showStack"
                        [customerAssessment]="customerAssessment"
                ></mvs-cm-insurance-stack-overview-component>

            </p-panel>
        <!-- Overall Overview-->
        <p-panel
                [header]="showTitle ? overallTitle : ''"
                class="panel-blank pb-0 col-6 md:col-3 lg:col-2"
                *ngIf="showOverall">

            <mvs-cm-insurance-overall-overview-component
                    [showOverall]="showOverall"
                    [showStack]="showStack"
                    [customerAssessment]="customerAssessment"
            ></mvs-cm-insurance-overall-overview-component>

        </p-panel>
    </div>
</ng-container>
