import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent, WidgetFactory} from "@kvers/alpha-ui";
import {ProvisionRequestService} from "../../service/api/provision-request.service";
import {ProvisionRequestRuntimeDto} from "../../service/dto/provision-request-runtime.dto";
import {SearchResultDto} from "../../../si/logic/search-result.dto";
import {
    DtoList,
    DtoTemplate, MvsFormDto,
    MvsFormFieldDto, MvsMessageService,
    ObjectTypeDto,
    UiPageUserService,
    UiPageVisitResponseDto, WidgetData
} from "@kvers/alpha-core-common";
import {SearchResultListInterface} from "../../../core/home-page/interface/search-result-list.interface";
import {SearchResultEntryDto} from "../../../si/logic/search-result-entry.dto";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProvisionRequestDto} from "../../dto/provision-request.dto";
import {ProvisionProcessingLogEntryDto} from "../../service/dto/provision-processing-log-entry.dto";

@Component({
    selector: 'provision-contract-simulate',
    templateUrl: './provision-contract-simulate.component.html',
    styleUrls: ['./provision-contract-simulate.component.scss']
})
export class ProvisionContractSimulateComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    recentContractList: UiPageVisitResponseDto[];
    provisionRequestRuntimeDto: ProvisionRequestRuntimeDto;

    formGroup: FormGroup;
    contractFormField: MvsFormFieldDto;
    showSearch = true; // Toggle between search and recent contracts
    provisionRequestFormDto: MvsFormDto;
    provisionRequestWidget: WidgetData;

    uiMode: 'side' | 'full';

    contractId: number;

    constructor(
        protected uiPageUserService: UiPageUserService,
        protected provisionRequestService: ProvisionRequestService,
        protected messageService: MvsMessageService,
        private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.formGroup = this.fb.group({
            contractDtoId: new FormControl('', [Validators.required]),
        });

        this.contractFormField = new MvsFormFieldDto();
        this.contractFormField.id = 'contractDtoId';
        this.contractFormField.dataType = 'cm.Contract';

        this.provisionRequestService.template(new DtoTemplate()).subscribe(res => {
            this.provisionRequestFormDto = res;
        })

        this.getRecentContracts();
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * retrieving recent contracts routed
     */
    getRecentContracts() {
        this.uiPageUserService.getLastVisitsByRoute("cm/contract/:id", 10).subscribe(value => {
            this.recentContractList = value;

        });
    }


    handleObjectSelect(id: number) {
        this.contractId = id;
    }

    handleContractSimulate(id: number) {

        if (!this.contractId) {
            return;
        }

        this.provisionRequestService.simulateSingle(id).subscribe(res => {
            // this.provisionRequestRuntimeDto = res;

            this.provisionRequestRuntimeDto = res;

            // Convert object properties into Maps
            this.provisionRequestRuntimeDto.contractProvisionGroupMap = new Map(
                Object.entries(res.contractProvisionGroupMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.contractProvisionCategoryMap = new Map(
                Object.entries(res.contractProvisionCategoryMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.contractEligibilityMap = new Map(
                Object.entries(res.contractEligibilityMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.provisionPartnerMap = new Map(
                Object.entries(res.provisionPartnerMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.calculatedProvisionTypeMap = new Map(
                Object.entries(res.calculatedProvisionTypeMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.calculatedProvisionBagMap = new Map(
                Object.entries(res.calculatedProvisionBagMap || {}).map(([key, value]) => [Number(key), value])
            );

            this.provisionRequestRuntimeDto.processingLog = new Map(
                Object.entries(res.processingLog || {}).map(([key, value]) => [Number(key), value as ProvisionProcessingLogEntryDto[]])
            );


        },error => {
            this.provisionRequestRuntimeDto = null;
            this.provisionRequestWidget = null;
            this.messageService.showErrorMessage('Error','Error getting provision request');
        });
    }

    getCalculatedRequests(contractId: number): ProvisionRequestDto[] {

        this.refreshProvisionRequestWidget(contractId);

        return this.provisionRequestRuntimeDto.calculatedRequests.entries.filter(req => req.contractDtoId === contractId);
    }

    refreshProvisionRequestWidget(contractId: number): WidgetData {

        const dtoList: DtoList = new DtoList();
        dtoList.entries = this.provisionRequestRuntimeDto.calculatedRequests.entries.filter(req => req.contractDtoId === contractId);
        dtoList.form = this.provisionRequestFormDto;

        return WidgetFactory.createWidgetTransient(
            'cp.provision.contract.simulate.calculated.request',
            'Calculated Request',
            'list',
            'transient',
            'transient',
            'cp.ProvisionRequest',
            dtoList,
        );


    }

    getProcessingLog(requestId: number): ProvisionProcessingLogEntryDto[] | undefined {
        return this.provisionRequestRuntimeDto.processingLog.get(requestId);
    }

    toggleView() {
        this.contractId = null;
        this.formGroup.get('contractDtoId').setValue(undefined);
        this.showSearch = !this.showSearch;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
