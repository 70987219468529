import {Route, Routes} from "@angular/router";
import {SiDashboardComponent} from "./page/overview/si-dashboard/si-dashboard.component";
import {SiIndexRunObjectPage} from "./page/object/si-index-run-object-page/si-index-run-object.page";
import {SiBrowseComponent} from "./page/overview/si-browse/si-browse.component";
import {SiConfigPage} from "./page/overview/si-config/si-config.page";
import {SiOverviewPage} from "./page/si-overview-page/si-overview.page";


export function SiSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        // {path: 'si', component: SiOverviewPage},
        {path: 'si/config', component: SiConfigPage},
        {path: 'si/dashboard', component: SiDashboardComponent},
        {path: 'si/browse', component: SiBrowseComponent},
        {path: 'si/indexRun/:id', component: SiIndexRunObjectPage},
    ];

    return routes;

}
