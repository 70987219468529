import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'si-statistics.component.html',
})
export class SiStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    pending: number = 0;
    processed: number = 0;
    inProcess: number = 0;
    unfinished: number = 0;


    appointmentHistoryWidget: WidgetData;
    appointmentByTypeWidget: WidgetData;
    appointmentTypeByGroupWidget: WidgetData;
    recentlyAddedAppointmentsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'AS Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshAppointmentHistoryWidget();
        this.refreshAppointmentByTypeWidget();
        this.refreshAppointmentTypeByGroupWidget();
        this.refreshAddedAppointment();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/ui/config']);
    }



    refreshAppointmentHistoryWidget() {
        this.appointmentHistoryWidget = new WidgetData();
        this.appointmentHistoryWidget.idAlias = "as.appointment.overview.history.widget";
        this.appointmentHistoryWidget.name = "Appointment History";

        this.appointmentHistoryWidget.uiComponent = "history.chart";
        this.appointmentHistoryWidget.dataProvider = "list";
        this.appointmentHistoryWidget.dataSource = "entity.groupBy";
        this.appointmentHistoryWidget.dataProviderObject = "as.Appointment";
        this.appointmentHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["status", "createdDate"],
            [new ObjectRequestListAttribute("status", "Status", DtoListAttributeRequestAggregateEnum.count)]);

        this.appointmentHistoryWidget.setParamValue("fieldCategory", "status");
        this.appointmentHistoryWidget.setParamValue("fieldCategoryCount", "status_count");
        this.appointmentHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.appointmentHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.appointmentHistoryWidget.setParamValue("selectionMode", "single");
        this.appointmentHistoryWidget.setParamValue("size", "S");
    }

    refreshAppointmentByTypeWidget() {
        this.appointmentByTypeWidget = WidgetFactory.createWidgetGroupBy(
            'as.appointment.overview.by.type.widget',
            'Appointment By Type',
            'category',
            'entity.groupBy',
            'as.Appointment',
            'appointmentType',
            'appointmentType_count',
            this.filterCriteria,
            'appointmentType',
            'Anzahl');
    }

    refreshAppointmentTypeByGroupWidget() {
        this.appointmentTypeByGroupWidget = WidgetFactory.createWidgetGroupBy(
            'as.appointment.overview.by.group.widget',
            'Appointment Type By Group',
            'category',
            'entity.groupBy',
            'as.AppointmentType',
            'appointmentGroup',
            'appointmentGroup_count',
            this.filterCriteria,
            'appointmentGroup',
            'Anzahl');
    }

    refreshAddedAppointment() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedAppointmentsWidget = WidgetFactory.createWidgetTableEntity(
            'as.appointment.overview.recently.added.appointment',
            'Recent Appointments',
            'as.Appointment',
            'No Address',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
