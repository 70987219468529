import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CrCustomerDto} from "../../../../dto/person.dto";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {CustomerUserService} from "../../../../service/api/customer-user.service";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {
    EuUserAddedInsuranceDocumentService
} from "../../../../../eu/service/api/eu-user-added-insurance-document.service";
import {EuUserAddedInsuranceDocumentDto} from "../../../../../eu/dto/eu-user-added-insurance-document.dto";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-registration-navigation',
    templateUrl: './cr-customer-registration-navigation.component.html',
})
export class CrCustomerRegistrationNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerDto: CrCustomerDto;
    subForms: MvsFormDto[];

    customerUsersDtoList: DtoList;
    userAddedInsuranceDocumentDtoList: DtoList<EuUserAddedInsuranceDocumentDto>;

    customerUsersWidgetData: WidgetData;
    customerSignaturesWidgetData: WidgetData;
    customerUserDeviceWidgetData: WidgetData;

    customerRegistrationWidgetData: WidgetData;
    euUserWidgetData: WidgetData;

    customerOnboardingWidgetData: WidgetData;

    userAddedInsurance: WidgetData;

    selectedUserAddedInsurance: ObjectIdentifierData;


    ngOnInit(): void {
        this.initComponent();
    }

    constructor(
        protected userAddedInsuranceDocumentService: EuUserAddedInsuranceDocumentService,
        protected customerUserService: CustomerUserService,
        protected navigationService: MvsObjectNavigationService,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected customerService: CustomerService,
        protected configService: MvsConfigService,
    ) {
        super(coreService, confirmationService, messageService, configService, navigationService);
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        const filterCriteria = FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        this.customerUserService.list(ObjectRequestList.createBasic(false, [filterCriteria], null)).subscribe(res => {
            this.customerUsersDtoList = res
            if (this.customerUsersDtoList?.entries?.length) {
                this.refreshWidgets();
            }
            this.refreshComponent();
        })

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * refresh Widgets
     */
    refreshWidgets() {

        const filterCriteria = [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)]

        this.refreshCustomerUsersWidget(filterCriteria);

        this.refreshCustomerOnboardingWidget();

        this.refreshCustomerRegistrationWidget(filterCriteria);

        this.refreshCustomerSignatureWidget(filterCriteria);

        this.refreshCustomerUserDeviceWidget();

        this.refreshRegistrationWidget();

        this.userAddedInsurances();


    }


    userAddedInsurances() {

        const filterCriteria: FilterCriteria = FilterCriteria.createOrFromArray('user', 'userDtoId', this.customerUsersDtoList.entries);

        const endUserComplexRequest = new ObjectRequestList(
            true,
            [filterCriteria],
            [new Sorting("createdDate", false)]
        );

        this.userAddedInsurance = WidgetFactory.createWidgetList(
            'cr.customer.registration.navigation.show.user.added.insurances',
            'User Insurances',
            'list',
            'list',
            'entity',
            'eu.EuUserAddedInsurance',
            null,
            endUserComplexRequest
        );
    }

    refreshCustomerUsersWidget(filterCriteria: FilterCriteria[]) {
        const complexRequest = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customerUsers",
                ObjectRequestRelation.createList(
                    "cr.CustomerUser",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        )

        this.customerUsersWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.registration.navigation.show.customer.users",
            "Customer Users",
            "list",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                complexRequest,
                filterCriteria,
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 10)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
        );
    }

    refreshCustomerOnboardingWidget() {
        const filterCriteria = [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)]
        this.customerOnboardingWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.registration.navigation.show.customer.onboarding",
            "Customer Onboarding",
            "list",
            "list",
            "entity",
            "cr.CustomerOnboarding",
            "Keine Kunden gefunden!",
            ObjectRequestList.createWithPaging(true, filterCriteria, [new Sorting("createdDate", false)], PagingDto.create(0, 10))),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S")
        ;
    }


    refreshCustomerRegistrationWidget(filterCriteria: FilterCriteria[]) {
        const complexRequest = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode('registration')
        )

        this.customerRegistrationWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.registration.navigation.show.customer.registration",
            "Customer Registration",
            "list",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                complexRequest,
                filterCriteria,
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 10)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
        );

        // const list = FilterCriteria.create('customer', FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)
        //
        // const complexRequest = ObjectRequestComplexNode.createRelationNode("registration",
        //     ObjectRequestRelation.createList(
        //         "cr.Customer",
        //         "registration",
        //         [list],
        //         null,
        //         ObjectRequestComplexRelationBindingEnum.ALL)
        // )
        //
        //
        // this.customerRegistrationWidgetData = WidgetFactory.createWidgetList(
        //     "cr.customer.registration.navigation.show.customer.registration",
        //     "Customer Registration",
        //     "list",
        //     "list",
        //     "entity",
        //     "eu.EuRegistration",
        //     "Keine Kunden gefunden!",
        //
        //     ObjectRequestList.createComplexRequestList(
        //         true,
        //         ObjectRequestComplex.build(false, false, complexRequest),
        //         null,
        //         [new Sorting("createdDate", false)],
        //         PagingDto.create(0, 10)),
        //     WidgetDataParam.create("selectionMode", "rowSelect"),
        //     WidgetDataParam.create("size", "S"),
        // );
    }

    refreshCustomerSignatureWidget(filterCriteria: FilterCriteria[]) {
        const complexRequest = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customerSignatures",
                ObjectRequestRelation.createList(
                    "cr.CustomerSignature",
                    "customer",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        )

        this.customerSignaturesWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.registration.navigation.show.customer.signatures",
            "Customer Signature",
            "list",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                complexRequest,
                filterCriteria,
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 10)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
        );
    }

    refreshRegistrationWidget() {

        const filterCriteria: FilterCriteria = FilterCriteria.createOrFromArray('id', 'userDtoId', this.customerUsersDtoList.entries);

        const endUserComplexRequest = new ObjectRequestList(
            true,
            [filterCriteria],
            [new Sorting("createdDate", false)]
        );

        this.euUserWidgetData = WidgetFactory.createWidgetList(
            'cr.customer.registration.navigation.show.eu.user',
            'Eu User',
            'list',
            'list',
            'entity',
            'eu.EuUser',
            null,
            endUserComplexRequest
        );
    }

    refreshCustomerUserDeviceWidget() {

        const filterCriteria: FilterCriteria = FilterCriteria.createOrFromArray('user', 'userDtoId', this.customerUsersDtoList.entries);

        this.customerUserDeviceWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.registration.navigation.show.customer.user.devices",
            "Customer Devices",
            "list",
            "list",
            "entity",
            "eu.EuUserDevice",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                null,
                [filterCriteria],
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 10)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
        );
    }


    handleObjectSelect(object: ObjectIdentifierData) {
        this.selectedUserAddedInsurance = object
        const filterCriteria = FilterCriteria.create('userAddedInsurance', FilterCriteria.cOperatorEqual, object.objectId);

        this.userAddedInsuranceDocumentService.list(ObjectRequestList.createBasic(false, [filterCriteria], null)).subscribe(res => {
            this.userAddedInsuranceDocumentDtoList = res;
        })

    }

    onDocumentSelect(document: EuUserAddedInsuranceDocumentDto) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
            "dm.DmDocument",
            document.documentDtoId,
            null,
            "Dokument " + document.name,
            null,
            null,
            MvsObjectNavigationActionEnum.any);

        this.navigationService.navigateTo(mvsObjectNavigationEntry, "left");
    }

    onDocumentDelete(id: number, event: MouseEvent) {
        event.stopPropagation();
        this.confirmationService.confirm({
            target: event.target,
            message: 'Soll das Dokument sowie die Zuordnung gelöscht werden?',
            icon: 'pi pi-exclamation-triangle',

            accept: () => {
                this.userAddedInsuranceDocumentService.delete(id).subscribe(() => {
                    this.messageService.showInfoMessage('Deleted', 'Dokument wurde gelöscht.');
                    this.handleObjectSelect(this.selectedUserAddedInsurance);
                })
            },

            reject: () => {
                this.messageService.showErrorMessage('Delete Fail', 'Dokument wurde gelöscht.');
            }
        });
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }


}
