import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CmCustomerAssessmentDto} from "../../dto/cm-customer-assessment.dto";
import {CmCustomerAssessmentResultEnum} from "../../enum/cm-customer-assessment-result.enum";
import {CustomerAssessmentResultColorsEnum} from "../../enum/cm-customer-assessment-result-colors.enum";
import {Menu} from "primeng/menu";
import {CmCustomerAssessmentForGroupDto} from "../../dto/cm-customer-assessment-for-group.dto";

@Component({
    selector: 'mvs-cm-insurance-stack-overview-component',
    templateUrl: './cm-insurance-stack-overview.component.html',
    styleUrls: ['./cm-insurance-stack-overview.component.scss']
})
export class CmInsuranceStackOverviewComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() customerAssessment: CmCustomerAssessmentDto;
    @Input() showStack: boolean = true;
    @ViewChild("overlayPanel") overlayPanel!: Menu;

    overlayPanelItems: string[] = [];

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    getColors(value: number): string {

        let color: string;

        switch (value) {
            case CmCustomerAssessmentResultEnum.not_applicable :
                break;
            case CmCustomerAssessmentResultEnum.poor :
                color = CustomerAssessmentResultColorsEnum.poor;
                break;
            case CmCustomerAssessmentResultEnum.below_average :
                color = CustomerAssessmentResultColorsEnum.below_average;
                break;
            case CmCustomerAssessmentResultEnum.average :
                color = CustomerAssessmentResultColorsEnum.average;
                break;
            case CmCustomerAssessmentResultEnum.good :
                color = CustomerAssessmentResultColorsEnum.good;
                break;
            case CmCustomerAssessmentResultEnum.excellent :
                color = CustomerAssessmentResultColorsEnum.excellent;
                break;

        }
        return color;
    }

    togglePopupMenu(assessment: CmCustomerAssessmentForGroupDto, event: any) {
        // checks if the insurances for different groups are available
        if (!assessment.customerInsurances) {
            return;
        }

        // initialize the array as empty
        this.overlayPanelItems = [];

        const insurances: any = assessment.customerInsurances

        // retrieving insurances and storing the insurance name in popupMenuItems array
        this.overlayPanelItems = insurances.entries.map(entry => {
                return entry.insuranceDtoName
        });

        // open the menu dialog
        this.overlayPanel.toggle(event);

    }

}
