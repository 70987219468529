import {Component, OnDestroy, OnInit} from "@angular/core";
import {UmUserService} from "../../service/api/um-user.service";
import {DmDocumentUploadDto} from "../../../dm/service/dto/dm-document-upload.dto";
import {
    FilterCriteria,
    MvsCoreService, MvsFormControlOverwrite,
    MvsMessageService,
    NavigationItem,
    ObjectRequestList,
    PageComponent,
    WidgetData, WidgetDataParam
} from "@kvers/alpha-core-common";
import {UserProfileHelper} from "./helper/user-profile-helper";
import {ActivatedRoute} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import {AgentService} from "../../../am/service/api/agent.service";
import {AgentDto} from "../../../am/dto/agent.dto";
import {UmUserDto} from "../../dto/um-user.dto";


@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends PageComponent implements OnInit, OnDestroy {

    imageSrc: string;
    tempImageSrc: string | undefined;
    imageChangedEvent: any = '';
    isSvg: boolean = false;
    croppedImage: any = '';
    showImageCropperDialog = false;

    initialized: boolean;

    queryParamSubscription: Subscription;

    activeNavigationItem: NavigationItem;
    navigationItems: NavigationItem[] = [
        {
            label: 'calendar',
            action: 'calendar',
            tooltip: 'Calendar',
            icon: 'fa-solid fa-calendar-alt', // Represents a calendar or scheduling
            toggleable: false,
            default: true
        },
        {
            label: 'working-time',
            action: 'working-time',
            tooltip: 'Working Time',
            icon: 'fa-solid fa-clock', // Represents work hours or time tracking
            toggleable: false,
            default: false
        },
        {
            label: 'user',
            action: 'user',
            tooltip: 'User',
            icon: 'fa-solid fa-user', // Represents a person or user profile
            toggleable: false,
            default: false
        },
        {
            label: 'agent',
            action: 'agent',
            tooltip: 'Agent',
            icon: 'fa-solid fa-user-tie', // Represents an agent or professional role
            toggleable: false,
            default: false
        }
    ];

    agent: AgentDto;
    user: UmUserDto;
    startDate: Date;
    endDate: Date;

    formControlOverwrite: MvsFormControlOverwrite = new MvsFormControlOverwrite();

    userWidget: WidgetData;
    agentWidget: WidgetData;
    agentPoolAgentWidget: WidgetData;

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected navigationService: MvsObjectNavigationService,
                protected agentService: AgentService,
                protected userService: UmUserService,
                protected messageService: MvsMessageService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscribeToParams();
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.formControlOverwrite = MvsFormControlOverwrite.createReadOnlyAll('name', 'shortName');
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem']) {

                const navItem = params['navItem'];

                if (navItem) {
                    this.activeNavigationItem = this.navigationItems.find(item => item.action === navItem);
                } else {
                    this.activeNavigationItem = this.navigationItems.find(item => item.default === true);
                }
            } else {
                this.activeNavigationItem = this.navigationItems.find(item => item.default === true);
            }

            this.handleNavigationChange();
        });
    }

    getUserPicture() {
        this.isSvg = false;
        this.userService.getPictureBinary().subscribe((res: Blob) => {
            //convert the blob to relevant type
            if (res.type == 'image/svg+xml') {
                this.isSvg = true;
            }
            UserProfileHelper.getImageSrcFromBlob(res).then(imageSrc => {
                this.imageSrc = imageSrc;
                this.userService.profilePictureBroadcast(this.imageSrc);
            });
        }, error => {
            this.initialized = true;
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    handleDeleteImage() {
        this.userService.deletePicture().subscribe(res => {
            this.refreshComponent();
        }, error => {
        });
    }


    onUploadHandler(event: any) {
        this.imageChangedEvent = event;
        this.showImageCropperDialog = true;
    }

    imageCropped(event: any): void {
        this.croppedImage = event.base64;

        if (!this.croppedImage && event.blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.croppedImage = reader.result;
            };
            reader.readAsDataURL(event.blob);
        }

        this.tempImageSrc = event.objectUrl;
    }

    uploadCroppedImage() {
        this.showImageCropperDialog = false;

        const blob = this.dataURItoBlob(this.croppedImage);
        const file = new File([blob], "cropped-image.png", {type: blob.type});

        this.convertFileToBase64AndGetType(file).then(document => {

            const documentDto = new DmDocumentUploadDto();
            documentDto.pictureBase64 = document.fileBase64;
            documentDto.pictureMimeType = document.fileBase64MimeType;

            this.userService.setPicture(documentDto).subscribe({
                next: (response) => {
                    this.refreshComponent();
                },
                error: (error) => {
                }
            });
        });
    }

    dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type: mimeString});
    }

    convertFileToBase64AndGetType(file: File): Promise<{
        fileBase64: string;
        fileBase64MimeType: string;
        name: string
    }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const result = event.target?.result;
                const base64 = typeof result === 'string' ? result.split(',')[1] : ''; // Getting the Base64 content
                resolve({
                    fileBase64: base64,
                    fileBase64MimeType: file.type,
                    name: file.name
                });
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    resetFileInput() {
        //reset file input in case a user decides not to upload an image and then choose same picture because change event doesn't trigger on same file
        const input: HTMLInputElement = document.getElementById('upload') as HTMLInputElement;
        if (input) {
            input.value = '';
        }
    }


    getAgent() {

        if (!this.agent) {
            this.agentService.me().subscribe(agent => {
                this.agent = agent;
                this.refreshWidgets();
            });
        }


    }

    refreshWidgets() {

        this.agentWidget = WidgetFactory.createWidgetObject(
            'um.agent.basic.widget.object',
            'Agent',
            'am.Agent',
            this.agent.id,
            WidgetDataParam.create('formControlOverwrite', this.formControlOverwrite)
        );


        const objectRequestList = ObjectRequestList.createBasic(true, [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.agent.id)], []);

        this.agentPoolAgentWidget = WidgetFactory.createWidgetTableEntity(
            'um.agent.pool.agent.widget.table',
            'Agent Pool Agent',
            'am.AgentPoolAgent',
            'No Data',
            objectRequestList
        );
    }

    getUser() {

        if (this.user) {
            this.refreshUserWidget();
        } else {
            this.userService.me().subscribe(user => {
                this.user = <UmUserDto>user;
                this.refreshUserWidget();
            });
        }

    }

    refreshUserWidget() {
        this.userWidget = WidgetFactory.createWidgetEntityData(
            'um.user.basic.widget.data',
            'User',
            'um.User',
            this.user.id
        );
    }


    handleNavigationChange() {
        if (this.activeNavigationItem.action === 'calendar') {
            this.getAgent();
        }
        if (this.activeNavigationItem.action === 'working-time') {
            this.getAgent();
        }
        if (this.activeNavigationItem.action === 'user') {
            this.getUser();
            this.getUserPicture();
        }
        if (this.activeNavigationItem.action === 'agent') {
            this.getAgent();
        }
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

}
