import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoList,
    MvsMessageService,
    ObjectIdentifierData,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {TicketService} from "../../service/api/ticket.service";
import {TmTicketTypeDto} from "../../dto/tm-ticket-type.dto";
import {TicketTypeService} from "../../service/api/ticket-type.service";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-create-ticket-object',
    templateUrl: './mvs-create-object-ticket.component.html',
})
export class MvsCreateObjectTicketComponent implements OnInit, OnChanges, OnDestroy {

    @Input() objectId: number;
    @Input() objectAlias: string;
    @Output() onTicketCreation = new EventEmitter<ObjectIdentifierData>();

    ticketTypes: TmTicketTypeDto[];
    ticketTypeId: number;
    initialized: boolean;
    busy: boolean;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected ticketTypeService: TicketTypeService,
                protected messageService: MvsMessageService,
                protected navigationService: MvsObjectNavigationService,
                protected ticketService: TicketService) {
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.getAllTicketTypes();
        this.refreshComponent();
    }

    getAllTicketTypes() {
        this.ticketTypeService.list(new ObjectRequestList(false, [], [])).subscribe((res: DtoList<TmTicketTypeDto>) => {
            const entries = res.entries;
            this.ticketTypes = entries.filter(item => item.active == true && item.instanceAble == true);

            this.initialized = true;
        })
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectId"] || changes["objectAlias"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleCreateTicket() {
        this.ticketService.createTicketForObject(this.ticketTypeId, this.objectId, this.objectAlias).subscribe(res => {
            this.messageService.showSuccessMessage('', 'Ticket created');
            this.openTicketInDrawer(res.id);
            this.onTicketCreation.emit(new ObjectIdentifierData('tm.Ticket', res.id, res));
        }, error => {
        })
    }

    openTicketInDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', ticketId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }


}
