import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './si-overview.page.html',
})
export class SiOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {
    defaultLabel: string = "SI Overview";

    ngOnInit(): void {
        super.ngOnInit();
    }
    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
