import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-eu-registration-object',
    templateUrl: './eu-registration-object.component.html',
})
export class EuRegistrationObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    widgetRegistration: WidgetData;

    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.widgetRegistration = new WidgetData();
        this.widgetRegistration.idAlias = "eu.registration.object.data";
        this.widgetRegistration.name = "Registrierung";
        this.widgetRegistration.uiComponent = "data";
        this.widgetRegistration.dataProvider = "transient";
        this.widgetRegistration.dataSource = "transient";
        this.widgetRegistration.dataTransient = this.dto;
        this.widgetRegistration.dataProviderObject = "Eu.EuRegistration";

        this.widgetRegistration.setParamValue("size", "S");
        this.widgetRegistration.setParamValue("objectId",this.objectIdentifier.objectId);

    }

}
