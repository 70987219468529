@if (activeNavigationItem.action == 'cm.PartnerType') {
    <mvs-widget
            *ngIf="refreshPartnerTypeWidget"
            [widgetData]="refreshPartnerTypeWidget"
            (onObjectSelect)="handleObjectSelect($event)"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'cm.Partner') {
    <mvs-widget
            *ngIf="refreshPartnerWidget"
            [widgetData]="refreshPartnerWidget"
            (onObjectSelect)="handleObjectSelect($event)"
    ></mvs-widget>
}