import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NsNotificationTypesModeEnum} from "../ns-notification-types-for-object/enums/ns-notification-types-mode.enum";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {NsNotificationObjectReferenceDto} from "../../dto/service/ns-notification-object-reference.dto";
import {NsNotificationService} from "../../service/api/ns-notification.service";
import {NsNotificationRequestDto} from "../../dto/service/ns-notification-request.dto";
import {NsNotificationTypeOverviewDto} from "../../dto/service/ns-notification-type-overview.dto";
import {
    NsNotificationChannelRecipientContentDto
} from "../../dto/service/ns-notification-channel-recipient-content.dto";
import {Clipboard} from "@angular/cdk/clipboard";
import {ConfirmationService, MenuItem} from "primeng/api";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NsNotificationAttachmentDto} from "../../dto/service/ns-notification-attachment.dto";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {NsNotificationSingleSendRequestDto} from "../../dto/service/ns-notification-single-send-request.dto";
import {NsNotificationSendRequestChannelDto} from "../../dto/service/ns-notification-send-request-channel.dto";
import {NsNotificationStatusEnum} from "../../enum/ns-notification-status-enum.enum";
import {NsNotificationDto} from "../../dto/ns-notification.dto";
import {
    MvsFormFieldToggleableState
} from "@kvers/alpha-ui";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {CoChannelTypeEnum} from "../../../co/enum/co-channel-type-enum.enum";

interface NsNotificationSendContent {
    defaultState: MvsFormFieldToggleableState
};

@Component({
    selector: 'mvs-ns-notification-send',
    templateUrl: './ns-notification-send.component.html',
    styleUrls: ['./ns-notification-send.component.scss']
})
export class NsNotificationSendComponent implements OnInit, OnChanges, OnDestroy {

    @Input() mode: NsNotificationTypesModeEnum = NsNotificationTypesModeEnum.recipient;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() notificationSourceTypeId: number;

    @Output() onSendNotification: EventEmitter<NsNotificationDto> = new EventEmitter<NsNotificationDto>();

    lastSendNotification: NsNotificationDto;

    notificationOnSendException: boolean = false;
    notificationTechnicalException: string | undefined = undefined;

    notificationChannelException: boolean;
    notificationChannelExceptionMessage: string | undefined = undefined;

    notificationTypeInfo: NsNotificationTypeOverviewDto;

    selectedChannelId: number;
    channelRecipientContent: NsNotificationChannelRecipientContentDto;

    sideButtons: MenuItem[] = [];

    formGroupContentBody: FormGroup;
    formGroupContentSubject: FormGroup;

    formFieldContentBody: MvsFormFieldDto & NsNotificationSendContent;
    formFieldContentSubject: MvsFormFieldDto & NsNotificationSendContent;

    editEnabled : boolean = false;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(
        protected notificationService: NsNotificationService,
        protected messageService: MvsMessageService,
        protected clipboard: Clipboard,
        protected fb: FormBuilder,
        protected navigationService: MvsObjectNavigationService,
        protected confirmationService: ConfirmationService,
    ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    copyBodyToClipboard() {

        //remove innerHTML tags
        const contentWithoutTags = this.channelRecipientContent.contentBody.replace(/<[^>]*>/g, '');
        this.clipboard.copy(contentWithoutTags);
    }

    handleEnableEdit() {
        // enable overwrite
        this.formFieldContentBody.defaultState = "change";
        this.formFieldContentSubject.defaultState = "change";

        this.editEnabled = true;
    }

    handleDisableEdit() {

        // reset the texts
        this.setFormGroupContentBody();
        this.setFormGroupContentSubject();

        this.editEnabled = false;
    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent(false);
    }


    /**
     * Create a request depending on the data selection.
     * @protected
     */
    protected getRequest() : NsNotificationRequestDto {

        let recipient: NsNotificationObjectReferenceDto | null = null;
        let notificationObject: NsNotificationObjectReferenceDto | null = null;

        if (this.mode == NsNotificationTypesModeEnum.recipient) {
            recipient = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        } else {
            notificationObject = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        }

        return {
            objectBased: (this.mode ==  NsNotificationTypesModeEnum.object),
            referenceObject : notificationObject,
            referenceRecipient: recipient,
            notificationTypeSourceId: this.notificationSourceTypeId
        };
    }

    /**
     * Refresh Component.
     */
    refreshComponent(refreshAfterSend: boolean) {

        if (!refreshAfterSend) {
            this.notificationOnSendException = false;
            this.notificationTechnicalException = null;
        }

        this.busy = true;

        const request = this.getRequest();

        // retrieve notification type info
        this.notificationService.getNotificationTypeInfo(request).subscribe(value => {
            this.notificationTypeInfo = value;
            this.initialized = true;
            this.busy = false;

            if (this.notificationTypeInfo.channels && this.notificationTypeInfo.channels.length > 0) {

                if (this.selectedChannelId) {
                    this.changeChannel(this.selectedChannelId, refreshAfterSend);
                } else {
                    this.changeChannel(this.notificationTypeInfo.channels[0].id);
                }

            }

            // only update buttons if needed
            if (!refreshAfterSend) {
                if(!this.sideButtons) {
                    this.sideButtons = [];
                }

                const sideMenuItems = [];

                for (let channel of this.notificationTypeInfo.channels) {
                    const obj: MenuItem = {
                        id: channel.id+'',
                        icon: channel.channelTypeDto.image,
                        tooltip: channel.channelTypeDto.name
                    }
                    sideMenuItems.push(obj);
                }

                this.sideButtons = sideMenuItems;
            }

        }, error => {
            // error toast would have already been displayed
            this.busy = false;
        });

    }

    handleSideMenuChannelChange(event: MenuItem) {
        this.changeChannel(+event.id);
    }

    changeChannel(channelId: number, refreshAfterSend: boolean = false) {

        if (this.busy) {
            return;
        }

        this.busy = true;

        // clear possible previous exception
        this.notificationChannelException = false;
        this.notificationChannelExceptionMessage = undefined;

        // disable edit (default)
        this.editEnabled = false;

        this.selectedChannelId = channelId;

        const request = this.getRequest();

        this.notificationService.getNotificationChannelInfo(this.selectedChannelId, request).subscribe(value => {
            this.channelRecipientContent = value;

            if (!refreshAfterSend) {
                this.setFormGroupContentBody();
                this.setFormGroupContentSubject();
            }

            this.busy = false;
        }, error => {
            this.busy = false;
            this.notificationChannelException = true;

            if (error && error.error && error.error.data) {
                this.notificationChannelExceptionMessage = error.error.data;
            } else {
                this.notificationChannelExceptionMessage = "Fehler aufgetreten!";
            }
            this.clearChannel();
        });
    }

    clearChannel() {
        this.channelRecipientContent = undefined;
        this.formGroupContentBody = undefined;
        this.formGroupContentSubject = undefined;
    }


    onNotificationSend() {

        if (this.busy) {
            return;
        }

        if (this.channelRecipientContent.history && this.channelRecipientContent.history.last) {
            this.confirmSendEmail(this.channelRecipientContent.history.last);
        } else {
            this.sendEmail();
        }

    }


    sendEmail() {

        if (this.busy) {
            return;
        }

        this.busy = true;
        this.notificationOnSendException = false;
        this.notificationTechnicalException = null;


        let sendRequest: NsNotificationSingleSendRequestDto = null;

        const request = this.getRequest();

        if (this.mode == NsNotificationTypesModeEnum.object) {
            sendRequest = NsNotificationSingleSendRequestDto.createObjectBased(
                request.notificationTypeSourceId,
                request.referenceObject,
                {
                    objectTypeAlias: this.channelRecipientContent.channelAddressContacts[0].recipient.recipientObject.objectType,
                    objectId: this.channelRecipientContent.channelAddressContacts[0].recipient.recipientObject.objectId,
                },
                NsNotificationSendRequestChannelDto.createSimple(this.selectedChannelId)
            );

        } else {
            sendRequest =
                NsNotificationSingleSendRequestDto.createSimple(
                    request.notificationTypeSourceId,
                    {
                        objectTypeAlias: this.objectIdentifier.objectType,
                        objectId: this.objectIdentifier.objectId
                    },
                    NsNotificationSendRequestChannelDto.createSimple(this.selectedChannelId));
        }


        if (this.editEnabled) {

            if (this.formFieldContentSubject.defaultState == "change") {
                sendRequest.channels[0].overwriteSubject = this.formGroupContentSubject.get("contentSubject").value;
            }

            if (this.formFieldContentBody.defaultState == "change") {
                sendRequest.channels[0].overwriteBody = this.formGroupContentBody.get("contentBody").value;
            }

        }

        this.notificationService.sendSingle(
            sendRequest
        ).subscribe(value => {

            this.lastSendNotification = value;

            this.onSendNotification.emit(value);

            if (value.notificationStatus != NsNotificationStatusEnum.processed) {
                this.notificationOnSendException = true;
            } else {
                this.messageService.showSuccessMessage("Gesendet!", null);
            }

            this.refreshComponent(true);
            this.busy = false;
        }, error => {
            // technical exception occured, notification could not be sent at all
            this.notificationTechnicalException = error.error?.data;
            this.busy = false;
        });
    }

    confirmSendEmail(createdDate) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Die Benachrichtigung wurde bereits versendet. Trotzdem senden?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.sendEmail();
            },
            reject: () => {
            },
        });
    }

    setFormGroupContentBody() {

        let inputControl = "textarea";

        if (this.channelRecipientContent.channelTypeDto.internalType == CoChannelTypeEnum.email ||
            this.channelRecipientContent.channelTypeDto.internalType == CoChannelTypeEnum.newsletter) {
            // inputControl = "editor";
            inputControl = "tinyMceEditor";
        }

        this.formFieldContentBody =
            {
                id: 'contentBody',
                uiInputControl: inputControl,
                defaultState: 'display'
            };

        this.formGroupContentBody = this.fb.group({
            contentBody: [this.formFieldContentBody]
        });

        this.formGroupContentBody.patchValue({contentBody: this.channelRecipientContent.contentBody});
    }

    setFormGroupContentSubject() {

        this.formFieldContentSubject =
            {
                id: 'contentSubject',
                uiInputControl: 'text',
                defaultState: 'display'
            };

        this.formGroupContentSubject = this.fb.group({
            contentSubject: [this.formFieldContentSubject]
        });

        this.formGroupContentSubject.patchValue({contentSubject: this.channelRecipientContent.contentSubject});
    }

    /**
     * Show Attachment.
     * @param attachment
     */
    handleShowAttachment(attachment: NsNotificationAttachmentDto) {
        this.navigationService.handleObjectNavigation(new ObjectIdentifier("dm.DmDocument", attachment.documentDto.id), null, {openNewTab: true});
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["mode"] || changes["objectIdentifier"] || changes["notificationSourceTypeId"]) {
            this.refreshComponent(false);
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
