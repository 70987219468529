import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-eu-registration-overview-page',
    templateUrl: './eu-registration-overview.page.html',
})
export class EuRegistrationOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Übersicht der Registrierungen";

    widgetRegistrationHistory: WidgetData;
    widgetCustomerRegistrationHistory: WidgetData;
    widgetRegistrations: WidgetData;

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.uiRefresh();
        this.initialized = true;
    }

    uiRefresh() {

        this.widgetRegistrations = new WidgetData();
        this.widgetRegistrations.idAlias = "eu.registration.overview.table";
        this.widgetRegistrations.name = "Registrierungen";
        this.widgetRegistrations.uiComponent = "table";
        this.widgetRegistrations.dataProvider = "list";
        this.widgetRegistrations.dataSource = "entity";
        this.widgetRegistrations.dataProviderObject = "eu.EuRegistration";
        this.widgetRegistrations.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            null,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 10)
        );

        this.widgetRegistrations.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("customer",
                                    ObjectRequestRelation.createLatest("cr.Customer","registration"))
        );


        this.widgetRegistrations.setParamValue("size", "S");
        this.widgetRegistrations.setParamValue("objectWidget", "data");

        // registration history
        this.widgetRegistrationHistory = new WidgetData();
        this.widgetRegistrationHistory.idAlias = "eu.registration.overview.history";
        this.widgetRegistrationHistory.name = "Registrierungshistorie";

        this.widgetRegistrationHistory.uiComponent = "history.chart";
        this.widgetRegistrationHistory.dataProvider = "list";
        this.widgetRegistrationHistory.dataSource = "entity.groupBy";
        this.widgetRegistrationHistory.dataProviderObject = "eu.EuRegistration";
        this.widgetRegistrationHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["deviceType", "createdDate"],
            [new ObjectRequestListAttribute("deviceType", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.widgetRegistrationHistory.setParamValue("fieldCategory", "deviceType");
        this.widgetRegistrationHistory.setParamValue("fieldCategoryCount", "deviceType_count");
        this.widgetRegistrationHistory.setParamValue("historyPeriodMode", "month");
        this.widgetRegistrationHistory.setParamValue("fieldDate", "createdDate");
        this.widgetRegistrationHistory.setParamValue("selectionMode", "single");
        this.widgetRegistrationHistory.setParamValue("size", "S");


        // TODO: will add later once the filter criteria is extended to check for null values

        // not null registration history
        // this.widgetCustomerRegistrationHistory = new WidgetData();
        // this.widgetCustomerRegistrationHistory.idAlias = "cr.customer.eu.registration.overview.history";
        // this.widgetCustomerRegistrationHistory.name = "Registrierungshistorie";
        //
        // this.widgetCustomerRegistrationHistory.uiComponent = "history.chart";
        // this.widgetCustomerRegistrationHistory.dataProvider = "list";
        // this.widgetCustomerRegistrationHistory.dataSource = "entity.groupBy";
        // this.widgetCustomerRegistrationHistory.dataProviderObject = "cr.Customer";
        // this.widgetCustomerRegistrationHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
        //     true,
        //     [],
        //     [],
        //     ["createdBy","createdDate"],
        //     [new ObjectRequestListAttribute("createdBy", "createdBy", DtoListAttributeRequestAggregateEnum.count)]);
        //
        // this.widgetCustomerRegistrationHistory.setParamValue("fieldCategory", "createdBy");
        // this.widgetCustomerRegistrationHistory.setParamValue("fieldCategoryCount", "createdBy_count");
        // this.widgetCustomerRegistrationHistory.setParamValue("historyPeriodMode", "month");
        // this.widgetCustomerRegistrationHistory.setParamValue("fieldDate", "createdDate");
        // this.widgetCustomerRegistrationHistory.setParamValue("selectionMode", "single");
        // this.widgetCustomerRegistrationHistory.setParamValue("size", "S");
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
