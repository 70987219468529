import { Injectable } from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class ActivityContextHolderService {

  private activityContext: ObjectIdentifier[] = [];

  /**
   * Sets the activity context.
   * @param context
   */
  setContext(context: ObjectIdentifier[]): void {
    this.activityContext = context;
  }

  /**
   * Push into activity context
   */
  addObject(context: ObjectIdentifier) {
    this.activityContext.push(context);
  }

  /**
   * Change objectId if objectType is already present else addObject (push into activityContext)
   */
  mergeObject(context: ObjectIdentifier) {
    const existingObjectIndex = this.checkExistence(context);

    if (existingObjectIndex) {
      // update the objectId if the objectType is already present
      this.activityContext[existingObjectIndex].objectId = context.objectId;
    } else {
      // add the object if objectType is not found
      this.addObject(context);
    }
  }

  /**
   * Remove context if found
   */
  removeObject(context: ObjectIdentifier) {
    const index = this.checkExistence(context);
    if (index > -1) {
      this.activityContext.splice(index,1);
    }
  }

  /**
   * Returns index if object identifier is found
   */
  private checkExistence(context: ObjectIdentifier): number {
    const existingObjectIndex = this.activityContext.findIndex(item => item.objectType == context.objectType);
    if (existingObjectIndex > -1) {
      return existingObjectIndex;
    }
    return null;
  }

  /**
   * Retrieves the current activity context.
   */
  getContext(): string | null {
    let formattedContext: string | null = null;

    for (const context of this.activityContext) {
      if (context.objectType && context.objectId !== undefined) {
        if (formattedContext) {
          formattedContext += ';';
        } else {
          formattedContext = ''; // Initialize formattedContext as an empty string on the first valid entry
        }
        formattedContext += `${context.objectType}:${context.objectId}`;
      }
    }

    return formattedContext;
  }

  /**
   * Clears the activity context.
   */
  clearContext(): void {
    this.activityContext = [];
  }
}
