import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PeriodHelper} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-ig-dashboard',
    templateUrl: './ig-dashboard.component.html',
})
export class IgDashboardComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Integration Codes: Dashboard";

    widgetOverviewActivePassCodes: WidgetData;
    widgetOverviewActiveAccessCodes: WidgetData;

    widgetHistoryPassCodes: WidgetData;
    widgetCategoryPassCodes: WidgetData;
    widgetTablePassCodes: WidgetData;

    widgetHistoryAccessCodes: WidgetData;
    widgetCategoryAccessCodes: WidgetData;
    widgetTableAccessCodes: WidgetData;


    activeTabIndex: number;


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        const currentLocalDateTime = PeriodHelper.getCurrentLocalDateTime();

        const activeCodesFilter: FilterCriteria[] = [
            FilterCriteria.create("expiryDate", FilterCriteria.cOperatorGreaterEqual, currentLocalDateTime ),
            FilterCriteria.create("deactivateToken", FilterCriteria.cOperatorEqual, "false" ),
        ];

        // set-up Overview Widgets
        this.widgetOverviewActivePassCodes = WidgetFactory.createWidgetList(
            "ig.dashboard.overview.active.pass.codes",
            "Aktive Pass-Codes",
            "list",
            "list",
            "entity",
            "ig.IgPassToken",
            "Keine aktiven Pass Codes vorhanden",
            ObjectRequestList.createBasic(
                true,
                activeCodesFilter,
                [new Sorting("expiryDate", true)])
        );

        this.widgetOverviewActivePassCodes = WidgetFactory.createWidgetList(
            "ig.dashboard.overview.active.access.codes",
            "Aktive Access-Codes",
            "list",
            "list",
            "entity",
            "ig.IgAccessToken",
            "Keine aktiven Access Codes vorhanden",
            ObjectRequestList.createBasic(
                true,
                activeCodesFilter,
                [new Sorting("expiryDate", true)])
        );


        // set-up pass code widgets
        this.widgetTablePassCodes = WidgetFactory.createWidgetList(
            "ig.dashboard.tab.pass.pass.codes",
            "Ausgestellte Pass Codes",
            "list",
            "list",
            "entity",
            "ig.IgPassToken",
            "Keine aktiven Pass Codes vorhanden",
            ObjectRequestList.createBasic(
                true,
                [],
                [new Sorting("expiryDate", true)])
        );



        // set-up access code widgets
        this.widgetTableAccessCodes = WidgetFactory.createWidgetList(
            "ig.dashboard.tab.access.access.codes",
            "Ausgestellte Access Codes",
            "list",
            "list",
            "entity",
            "ig.IgAccessToken",
            "Keine aktiven Access Codes vorhanden",
            ObjectRequestList.createBasic(
                true,
                [],
                [new Sorting("expiryDate", true)])
        );




        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
