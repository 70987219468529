<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12 md:col-4">
            <div class="mvs-widget h-full">
                <mvs-widget [widgetData]="widgetAgentCompletedProtocols"></mvs-widget>
            </div>
        </div>

        <div class="col-12 md:col-4">
            <div class="mvs-widget h-full">
                <mvs-widget [widgetData]="widgetAgentCancelledProtocols"></mvs-widget>
            </div>
        </div>

        <div class="col-12">
            <div class="mvs-widget h-full">
                <mvs-widget [widgetData]="widgetAgentMissingProtocols" (onObjectSelect)="onRowSelect($event)"></mvs-widget>
            </div>
        </div>
    </div>


</ng-container>