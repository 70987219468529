import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
  MsCalendarEventResponseDto,
  MsRequestCalendarPeriodDto,
  MsRequestCalendarPeriodWithAgents
} from "./dto/ms/ms-calendar.types";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CalendarConfigurationService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/am/calendarConfigurations')
  }


  public getCalendar(agentId: number, request: MsRequestCalendarPeriodDto) : Observable<MsCalendarEventResponseDto> {
    return this.postGeneric(request, `/getCalendar/${agentId}`)
  }

  public getCalendars(request: MsRequestCalendarPeriodWithAgents) : Observable<{[ key: number]: MsCalendarEventResponseDto}> {
    return this.postGeneric(request, `/getCalendars`)
  }



}