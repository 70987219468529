import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DtoDetail, ObjectIdentifier} from "@kvers/alpha-core-common";
import {CommentHistoryEnum, TicketHistoryComponent} from "../../../ticket-comment-history/ticket-comment-history.component";
import {TicketCommentService} from "../../../../service/api/ticket-comment.service";

@Component({
    selector: 'tm-ticket-comment-history',
    templateUrl: './tm-ticket-comment-history.component.html',
    styleUrls: ['./tm-ticket-comment-history.component.scss']
})
export class TmTicketCommentHistoryComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    actions: any[] | undefined;
    selectedAction: CommentHistoryEnum = 1;
    toggle: boolean;
    @Input() processMode: boolean = false;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() ticketTypeDto: DtoDetail;
    @Input() objectTypeId: number;
    @Output() onCommentChange: EventEmitter<string> = new EventEmitter();


    @ViewChild(TicketHistoryComponent) ticketCommentHistory: TicketHistoryComponent;

    constructor(protected ticketCommentService:TicketCommentService) {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        if (this.processMode == true) {
            this.toggle = true;
        }
        this.loadActions();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    loadActions() {
        this.actions = [
            {name: CommentHistoryEnum[CommentHistoryEnum["All Activity"]], code: CommentHistoryEnum["All Activity"]},
            {name: CommentHistoryEnum[CommentHistoryEnum.Comment], code: CommentHistoryEnum.Comment},
            {name: CommentHistoryEnum[CommentHistoryEnum.History], code: CommentHistoryEnum.History},
        ];
    }

    /**
     * refresh ticket comments
     */
    refreshHistory() {
        this.ticketCommentHistory.refreshHistory();
    }

    handleCommentChange(event: string) {
        this.onCommentChange.emit(event)
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
