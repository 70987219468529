<div *ngIf="initialized">
    <mvs-config-header header="Termine und Microsoft Bookings Integration"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Agenten" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Agentenpools" leftIcon="fa-thin fa-ballot-check"></p-tabPanel>
        <p-tabPanel header="Pool Zuweisung" leftIcon="fa-thin fa-ballot-check"></p-tabPanel>
        <p-tabPanel header="Kalender Konfiguration" leftIcon="fa-thin fa-calendar"></p-tabPanel>
        <p-tabPanel header="Benachrichtigungen" leftIcon="fa-thin fa-envelope"></p-tabPanel>
        <p-tabPanel header="Feiertagskalender" leftIcon="fa-thin fa-envelope"></p-tabPanel>
        <p-tabPanel header="Berater - Feiertage" leftIcon="fa-thin fa-envelope"></p-tabPanel>
        <p-tabPanel header="Berater - Working Hours" leftIcon="fa-thin fa-envelope"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetAgent"
                        [widgetData]="widgetAgent"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetAgentPool"
                        [widgetData]="widgetAgentPool"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetAgentPoolAgent"
                        [widgetData]="widgetAgentPoolAgent"
                ></mvs-widget>

            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==3">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetCalendarConfiguration"
                        [widgetData]="widgetCalendarConfiguration"
                ></mvs-widget>

            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==4">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetNotificationConfiguration"
                        [widgetData]="widgetNotificationConfiguration"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==5">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetHolidayCalendar"
                        [widgetData]="widgetHolidayCalendar"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="widgetHolidayCalendarDate"
                        [widgetData]="widgetHolidayCalendarDate"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==6">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetAgentHolidayCalendar"
                        [widgetData]="widgetAgentHolidayCalendar"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==7">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetAgentScheduleWeek"
                        [widgetData]="widgetAgentScheduleWeek"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="widgetAgentScheduleDay"
                        [widgetData]="widgetAgentScheduleDay"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


</div>