import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsCreateTicketOutputInterface} from "./interface/mvs-create-ticket-output.interface";
import {MvsCoreService, MvsMessageService, ObjectTypeService} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../../tm/service/api/ticket.service";

@Component({
    selector: 'mvs-create-ticket-output-component',
    templateUrl: './mvs-create-ticket-output.component.html',
})
export class MvsCreateTicketOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsCreateTicketOutputInterface {


    objectType: string;
    objectId: number;
    ticketTypeId: number;

    busy: boolean;
    initialized: boolean;


    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected messageService: MvsMessageService,
                protected coreService: MvsCoreService,
                protected ticketService: TicketService,) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {

        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectType = this.formField['uiConfigData']['objectType'];
        this.ticketTypeId = this.formField['uiConfigData']['ticketType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];
        this.objectId = this.dto[objectIdField];

        this.refreshComponent();

    }

    onIconClick(event: MouseEvent) {
        event.stopPropagation();
        this.ticketService.createTicketForObject(this.ticketTypeId, this.objectId, this.objectType).subscribe(res => {
            this.messageService.showSuccessMessage('', 'Ticket created');
            const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', res.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
            this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
        }, error => {
        })


    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
