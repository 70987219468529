import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ContractService} from "../../../service/api/contract.service";


@Component({
    selector: 'mvs-cm-contract-last',
    templateUrl: './cm-contract-last.page.html',
})
export class CmContractLastPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Vertragsseite";
    createdContractsWidgetData: WidgetData;


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected contractService: ContractService,
        protected navigationService: MvsObjectNavigationService,
        protected router: Router) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

        this.refreshWidgetCreatedCustomers();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    refreshWidgetCreatedCustomers() {

        this.createdContractsWidgetData = WidgetFactory.createWidgetList(
            "cm.contract.last.view.all.contracts",
            "Alle Verträge",
            "table",
            "list",
            "entity",
            "cm.Contract",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                this.contractService.getCustomerWithAgentComplexRequest(),
                [],
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 40)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
        );

    }

    onContractSelect(selectedContract: ObjectIdentifier) {
        //open contract in new tab
        this.navigationService.handleObjectNavigation(new ObjectIdentifier(selectedContract.objectType, selectedContract.objectId), null, {openNewTab: true});
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
