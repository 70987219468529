import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {CustomerContractRequestCreateUserDto} from "../dto/customer-contract-request-create-user.dto";
import {Observable} from "rxjs";
import {CustomerUserDto} from "../../dto/customer-user.dto";

@Injectable({
  providedIn: 'root'
})
export class CustomerUserService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerUsers')
  }

  /**
   * Create User for customer.
   * @param customerId
   * @param data
   */
  public createUser(customerId: number, data: CustomerContractRequestCreateUserDto) : Observable<CustomerUserDto> {
    return this.postBasic<CustomerUserDto>(data, `/${customerId}/createUser`);
  }

}