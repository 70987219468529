import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from "@angular/core";
import {AgentDto} from "../../../am/dto/agent.dto";
import {MsCalendarTypeService} from "../../service/api/ms-calendar-type.service";
import {FreeSlot, Person, SchedulerData, TransformedEventInterface} from "@kvers/alpha-ui";
import {ColorGeneratorService} from "../../service/color-generator.service";
import {AppointmentFreeSlot, FreeSlotRequestDto, TimeFrame} from "../../service/dto/free-slot-request.dto";
import {AppointmentService} from "../../service/api/appointment.service";
import {forkJoin} from "rxjs";
import {AppointmentTypeDto} from "../../dto/appointment-type.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {AppointmentTypeService} from "../../service/api/appointment-type.service";
import {AppointmentDto} from "../../dto/appointment.dto";

@Component({
    selector: "as-free-slot",
    templateUrl: "./as-free-slot.component.html",
    styleUrls: ["./as-free-slot.component.scss"],
})
export class AsFreeSlotComponent
    implements OnInit, OnChanges {

    @Input() agents: AgentDto[];
    @Input() referenceObjectTypeAlias: string;
    @Input() referenceObjectTypeId: number;
    @Input() referenceObjectId: number;
    @Input() customerId: number;
    @Input() appointmentTypeId: number;
    @Input() selectedPeriod: { startTime: Date, endTime: Date }[];

    @Output() onEventClick: EventEmitter<TransformedEventInterface> = new EventEmitter();
    @Output() onAppointmentCreate: EventEmitter<AppointmentDto> = new EventEmitter();


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    startDate: Date = new Date(new Date().setHours(0, 0, 0, 0)); // Start of today (midnight)
    endDate: Date = new Date(new Date().setHours(23, 59, 59, 999)); // End of today (23:59:59)

    agentEvents: Record<string, any[]>;
    schedulerData: SchedulerData;
    appointmentType: AppointmentTypeDto[];
    createEventDialog: boolean;


    allFreeSlots: FreeSlot[];
    selectedFreeSlot: FreeSlot;


    slotPersons: Person[];
    selectedAgent: AgentDto;
    selectedSlotAgent: Person;
    selectedPerson: Person;


    currentSelectionStart: Date;
    currentSelectionEnd: Date;

    overwriteSlot: boolean;

    constructor(
        protected appointmentTypeService: AppointmentTypeService,
        protected appointmentService: AppointmentService,
        protected colorGeneratorService: ColorGeneratorService) {
    }


    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.getAppointmentTypes();
        this.fetchAppointmentsAndSlots();

        for (let agent of this.agents) {
            // Generate random colors for the agent
            const colors = this.colorGeneratorService.generateColorPair();
            agent['color'] = colors.color;
            agent['bgColor'] = colors.bgColor;
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    handleEventClick(event: TransformedEventInterface) {
        this.onEventClick.emit(event);
    }

    handleFreeSlot(event: { allFreeSlots: FreeSlot[], selectedFreeSlot: FreeSlot }) {

        this.currentSelectionStart = new Date(event.selectedFreeSlot?.data?.eventData?.timeSlot?.start);
        this.currentSelectionEnd = new Date(event.selectedFreeSlot?.data?.eventData?.timeSlot?.end);
        this.slotPersons = event.selectedFreeSlot.personDto;
        this.selectedFreeSlot = event.selectedFreeSlot;
        this.allFreeSlots = event.allFreeSlots;

        if (this.slotPersons.length == 1) {
            this.selectedPerson = this.slotPersons[0];
            this.selectedSlotAgent = this.slotPersons[0];
            this.selectedAgent = this.agents.find(agent => agent.id === this.selectedPerson.id);
        } else {
            this.selectedSlotAgent = null;
            this.selectedPerson = null;
            this.selectedAgent = null;
        }


        if (!this.appointmentType?.length) {
            this.getAppointmentTypes();
        } else {
            this.createEventDialog = true;

        }

    }

    fetchAppointmentsAndSlots(): void {
        const agentIds: number[] = this.agents.map(agent => agent.id);
        let timeFrames: TimeFrame[] = [];

        if (this.selectedPeriod?.length) {
            timeFrames = this.selectedPeriod.map(period => ({
                start: period.startTime.toISOString(),
                end: period.endTime.toISOString()
            }));
        } else {
            timeFrames = [{
                start: this.startDate.toISOString(),
                end: this.endDate.toISOString()
            }];
        }

        const requestDto: FreeSlotRequestDto = {
            agentIds,
            timeFrames,
            appointmentTypeId: this.appointmentTypeId
        };

        forkJoin({
            appointments: this.appointmentService.getCalendarAppointments(agentIds, this.startDate, this.endDate),
            freeSlots: this.appointmentService.getFreeSlots(requestDto)
        }).subscribe(
            ({appointments, freeSlots}) => {
                this.agentEvents = appointments;

                const mergedEventAndFreeSlots: Record<number, AppointmentFreeSlot[]> = {};

                // Merge appointments
                Object.keys(appointments).forEach(key => {
                    mergedEventAndFreeSlots[key] = [...(appointments[key] || [])];
                });

                // Merge free slots
                Object.keys(freeSlots['freeSlots']).forEach(key => {
                    const slots = freeSlots['freeSlots'][key] || [];
                    slots.forEach(item => item.isFreeSlot = true);

                    if (mergedEventAndFreeSlots[key]) {
                        mergedEventAndFreeSlots[key] = [...mergedEventAndFreeSlots[key], ...slots];
                    } else {
                        mergedEventAndFreeSlots[key] = [...slots];
                    }
                });

                this.schedulerData = this.transformToSchedulerData(this.agents, mergedEventAndFreeSlots);
                this.refreshComponent();
            },
            (error) => {
                this.schedulerData = null;
                this.refreshComponent();
            }
        );
    }

    handleDateRangeChanged(range: { start: Date, end: Date }) {

        this.startDate = range.start;
        this.endDate = range.end;

        const filteredAgentsWithEmptySlots: Record<number, AppointmentFreeSlot[]> = {};


        this.agents.forEach(agent => {
            filteredAgentsWithEmptySlots[agent.id] = [];
        });

        this.schedulerData = this.transformToSchedulerData(this.agents, filteredAgentsWithEmptySlots);

        this.fetchAppointmentsAndSlots();

    }

    transformToSchedulerData(rawAgents: AgentDto[], freeSlots: Record<number, AppointmentFreeSlot[]>): SchedulerData {
        const transformedAgents: Person[] = [];

        rawAgents.forEach((agent) => {
            const agentId = agent.id.toString(); // Convert to string to match keys in freeSlots
            const agentEvents = freeSlots[agentId] || []; // Find matching events

            const transformedPerson: Person = {
                id: agent.id,
                name: agent.name,
                color: agent['color'], // Using agent's existing color
                bgColor: agent['bgColor'], // Using agent's existing background color
                events: agentEvents.map((event, index) => {

                    if (event.isFreeSlot) {
                        return {
                            id: agent.id * 1000 + index, // Generate a unique ID for the event
                            title: "Free Slot", // Placeholder title
                            start: new Date(event.actualOrRecommendedSlot?.start), // Extracting timeSlot start time
                            end: new Date(event.actualOrRecommendedSlot?.end), // Extracting timeSlot end time
                            preBufferTime: 0,
                            postBufferTime: 0,
                            isBestRecommendedSlot: event.isBestRecommendedSlot, // Added for tracking best recommended slots
                            isFreeSlot: event.isFreeSlot,
                            eventData: event,
                        }
                    } else {
                        const eventData = event?.appointmentEvent?.event;
                        const appointmentData = event?.appointmentEvent?.appointment;
                        const customer = {id: event?.customer?.id, name: event?.customer?.calculatedName};

                        return {
                            id: appointmentData?.id || eventData?.eventId, // Generate a unique ID for the event
                            title: eventData?.subject, // Placeholder title
                            start: new Date(
                                `${eventData?.startAsLocalDateTime}Z`
                                || appointmentData?.startTime
                            ),
                            end: new Date(
                                `${eventData?.endAsLocalDateTime}Z`
                                || appointmentData?.endTime
                            ),
                            preBufferTime: event?.appointmentEvent?.preBufferTime || "PT0M",
                            postBufferTime: event?.appointmentEvent?.postBufferTime || "PT0M",
                            location: eventData?.location?.displayName || "Unknown Location",
                            isOnlineMeeting: eventData?.isOnlineMeeting || false,
                            status: appointmentData?.statusEnumName || "Unknown",
                            color: appointmentData?.statusEnumColor || "gray-800",
                            bgColor: appointmentData?.statusEnumColorBackground || "gray-200",
                            eventData: event,
                            customer: customer
                        }
                    }

                })
            };

            transformedAgents.push(transformedPerson);
        });

        return {agents: transformedAgents};
    }

    handleSlotChange() {

        this.currentSelectionStart = new Date(this.selectedFreeSlot?.data?.eventData?.timeSlot?.start);
        this.currentSelectionEnd = new Date(this.selectedFreeSlot?.data?.eventData?.timeSlot?.end);

        if (this.selectedFreeSlot.personDto.length == 1) {
            this.selectedSlotAgent = this.selectedFreeSlot.personDto[0];
        } else {
            this.selectedSlotAgent = null;
        }
    }

    handleCreateEvent() {

        if (!this.appointmentTypeId || !this.selectedAgent || !this.currentSelectionStart || !this.currentSelectionEnd) {
            return;
        }

        this.appointmentService.scheduleAppointment(this.appointmentTypeId, this.selectedAgent.id, this.customerId, this.currentSelectionStart, this.currentSelectionEnd, this.referenceObjectTypeAlias, this.referenceObjectTypeId, this.referenceObjectId).subscribe(res => {
            this.onAppointmentCreate.emit(res);
            this.fetchAppointmentsAndSlots();
            this.createEventDialog = false;
        });

    }

    getAppointmentTypes() {
        this.appointmentTypeService.list(ObjectRequestList.createBasic(false, [], [])).subscribe(response => {
            this.appointmentType = response.entries;
        });
    }

    handleChangeModeAndTime(modeTime: { viewMode: 'Day' | 'Week' | 'Month', selectedDate: Date }) {

    }

    handleAgentChange() {
        this.selectedAgent = this.agents.find((agent) => agent.id === this.selectedSlotAgent.id);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }

        this.initComponent();
    }


}

