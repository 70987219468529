<ng-container *ngIf="activityType.activityType == 4">

    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>

    <p-toolbar>

        <div class="p-toolbar-group-start">
            <p-button *ngIf="objectId && activityType.activityType == 0 && activityType.processing == 1"
                      icon="pi pi-back"
                      [label]="'Back to create'" (click)="onBackToCreate()" [disabled]="busy"></p-button>
        </div>

    </p-toolbar>


    <ng-container *ngIf="objectId">
        <mvs-object
                [objectType]="'tm.Ticket'"
                [objectId]="objectId"
        ></mvs-object>
    </ng-container>

    <ng-container *ngIf="!objectId">
        <mvs-widget *ngIf="objectWidget"
                    [widgetData]="objectWidget"
                    (onChangedObject)="handleObject($event)"
        >
        </mvs-widget>

    </ng-container>


</ng-container>

<ng-container *ngIf="activityType.activityType == 7">

    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>

    <div class="p-toolbar-group-start">
        <p-button
                [label]="'Toggle Ticket'"
                (click)="showTicketObjectForm = !showTicketObjectForm"
                [disabled]="busy"
        ></p-button>
    </div>

    <ng-container *ngIf="showTicketObjectForm">
        <mvs-widget *ngIf="objectWidget"
                    [widgetData]="objectWidget"
                    (onChangedObject)="handleObject($event)"
        >

        </mvs-widget>
    </ng-container>

    <ng-container *ngIf="objectId && !showTicketObjectForm">
        <mvs-object
                [objectType]="'tm.Ticket'"
                [objectId]="objectId"
        ></mvs-object>
    </ng-container>

</ng-container>


