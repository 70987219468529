import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'as-appointment-booking-object-component',
    templateUrl: 'appointment-booking-object.component.html',
})
export class AppointmentBookingObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {


    /**
     * On Object Change.
     */
    onObjectChanged() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
