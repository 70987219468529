import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CallUiService} from "../../service/ui/call-ui.service";


@Component({
    selector: 'mvs-pc-header-call-active',
    templateUrl: './pc-header-call-active.component.html',
})
export class PcHeaderCallActiveComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    refreshTimeStamp: Date;

    constructor(protected callUiService: CallUiService) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        // we need to register to the service
        this.callUiService.registerHeaderActiveCallComponent(this);
    }

    /**
     * Refresh Component.
     */
    public refreshComponent() {

        // refresh time stamp (which will refresh the lower component as well)
        this.refreshTimeStamp = new Date();

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
