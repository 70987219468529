import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {IndexRunService} from "../../../service/api/index-run.service";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {SearchRequestDto} from "../../../logic/search-request.dto";
import {SearchResultDto} from "../../../logic/search-result.dto";
import {MenuItem, TreeNode} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {IndexedReferenceDto} from "../../../dto/indexed-reference.dto";
import {IndexedReferenceService} from "../../../service/api/indexed-reference.service";
import {UiAgentAssignmentEnum} from "../../../../tm/component/ticket-management/data/ui-agent-assignment.enum";
import {SearchResultDataDto} from "../../../logic/search-result-data.dto";


@Component({
    selector: 'si-browse',
    templateUrl: './si-browse.component.html',
    styleUrls: ['./si-browse.component.scss'],
})
export class SiBrowseComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Browse Indexed Data";

    formSearch: FormGroup;
    formBrowseObjectType: FormGroup;
    formBrowseObject: FormGroup;

    widgetBrowseObjectTypeTokens: WidgetData;
    widgetBrowseObjectTypeReferences: WidgetData;

    widgetBrowseObjectReferenceTokens: WidgetData;

    objectTypes: ObjectTypeDto[];

    ratings: { label: string, key: number }[];

    searchResult: SearchResultDto;

    tabItems: MenuItem[];
    activeItem: MenuItem | undefined;

    searchHidden: boolean = false;

    treeData: TreeNode[] = [];
    selectedNode: TreeNode;

    hideSelection: boolean = false;

    columns: string[];

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected fb: FormBuilder,
                protected objectTypeService: ObjectTypeService,
                protected indexSearchService: IndexRunService,
                protected messageService: MvsMessageService,
                protected indexedReferenceService: IndexedReferenceService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.tabItems = [
            {id: "search", label: "Search", icon: "fa-thin fa-magnifying-glass"},
            {id: "browseObjectType", label: "Browse by Type", icon: "fa-sharp fa-thin fa-print-magnifying-glass"},
            {id: "browseObject", label: "Browse by Object", icon: "fa-sharp fa-thin fa-magnifying-glass-waveform"},

        ];

        this.activeItem = this.tabItems[0];

        this.ratings = [
            {label: "poor", key: 0},
            {label: "fair", key: 1},
            {label: "ok", key: 2},
            {label: "good", key: 3},
            {label: "veryGood", key: 4},
            {label: "perfect", key: 5},
            {label: "excellent", key: 6}
        ];

        this.refreshComponent();

        this.initFormGroupSearch();
        this.initFormGroupBrowseObjectType();
        this.initFormGroupBrowseObject();

        // retrieve all object types
        this.objectTypeService.list(ObjectRequestList.createBasic(false, [], [new Sorting("alias", true)])).subscribe(value => {
            this.objectTypes = value.entries;
            this.initialized = true;
        });

    }


    handleSubmitBrowseByObjectType() {
        if (this.formBrowseObjectType.status == "VALID") {
            this.refreshBrowseObjectTypeWidgets();
        }
    }

    initFormGroupBrowseObjectType() {
        this.formBrowseObjectType = this.fb.group({
            requestedObjectTypeIds: new FormControl<number[] | null>(null, [Validators.required]),
        });
    }

    initFormGroupBrowseObject() {
        this.formBrowseObject = this.fb.group({
            objectType: new FormControl<number | null>(null, [Validators.required]),
            objectId: new FormControl<number | null>(null, [Validators.required]),
        });
    }

    refreshBrowseObjectTypeWidgets() {

        const selectedObjectTypes: number[] = this.formBrowseObjectType.get("requestedObjectTypeIds").value;

        // create or Filter
        const filterCriteria = FilterCriteria.createOrFromArrayFunction("referenceObjectTypeId", selectedObjectTypes, item => item + "");

        this.widgetBrowseObjectTypeTokens = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.type.token",
            `Tokens`,
            "si.IndexedToken",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [filterCriteria],
                [new Sorting("id", true)],
                PagingDto.create(0, 50))
        );

        this.widgetBrowseObjectTypeReferences = WidgetFactory.createWidgetTableEntity(
            "si.browse.object.type.reference",
            `References`,
            "si.IndexedReference",
            "Keine Tokens vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                [filterCriteria],
                [new Sorting("id", true)],
                PagingDto.create(0, 50))
        );

    }


    onSubmit() {

        if (this.formSearch.status == "VALID") {

            const searchRequestDto = new SearchRequestDto();

            searchRequestDto.requestedObjectTypeIds = this.formSearch.value["requestedObjectTypeIds"];
            searchRequestDto.splitResultsByType = this.formSearch.value["splitResultsByType"];
            searchRequestDto.maxHits = this.formSearch.value["maxHits"];
            searchRequestDto.minSearchRating = this.formSearch.value["minSearchRating"];
            searchRequestDto.searchString = this.formSearch.value["searchString"];

            // start index search
            this.indexSearchService.searchRequest(searchRequestDto).subscribe(value => {


                // Step 1: Convert dataFields to array format & attach to searchResultEntries
                value.searchResultEntries.forEach(entry => {
                    entry["dataFieldsList"] = Object.values(entry.dataFields);
                });

                this.searchResult = value;

                const uniqueFieldMap = new Map<string, { minDistance: number; fields: SearchResultDataDto[] }>();

                // Step 2: Process searchResultEntries
                this.searchResult.searchResultEntries.forEach(entry => {
                    // Sort directly within the array (avoiding redundant copies)
                    entry["dataFieldsList"].sort((a, b) => a.distance - b.distance || a.fieldName.localeCompare(b.fieldName));

                    entry["dataFieldsList"].forEach(dataField => {
                        let fieldData = uniqueFieldMap.get(dataField.fieldName);

                        if (!fieldData) {
                            fieldData = { minDistance: dataField.distance, fields: [] };
                            uniqueFieldMap.set(dataField.fieldName, fieldData);
                        }
                    });
                });

                // Step 3: Sort field names based on min distance first, then alphabetically
                this.columns = Array.from(uniqueFieldMap.entries())
                    .sort((a, b) => a[1].minDistance - b[1].minDistance || a[0].localeCompare(b[0]))
                    .map(([fieldName]) => fieldName);


            }, error => {
                this.searchResult = null;
                this.columns = null;

            });

        } else {
            this.messageService.showErrorMessage("Eingabe", "Bitte selektion füllen");
        }

    }

    initFormGroupSearch() {

        this.formSearch = this.fb.group({
            requestedObjectTypeIds: [null],
            splitResultsByType: [false],
            maxHits: [20, Validators.required],
            minSearchRating: [null],
            searchString: ["Max", Validators.required]
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        // this.initialized = true;
    }

    handleChange(object: { objectType: ObjectTypeDto, objectId: number }) {
        this.getIndexedReferences(object.objectType.id, object.objectId);
    }

    getIndexedReferences(objectTypeId: number, objectId: number) {


        // create or Filter
        const filterCriteria: FilterCriteria[] =
            [
                FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, objectTypeId),
                FilterCriteria.create("referenceObjectId", FilterCriteria.cOperatorEqual, objectId),
            ];

        const objectRequestList = ObjectRequestList.createBasic(
            true,
            filterCriteria,
            [new Sorting("id", true)]);

        this.indexedReferenceService.list(objectRequestList).subscribe(value => {
            this.treeData = this.buildTree(objectTypeId, objectId, value.entries);

            if (this.treeData.length > 0) {
                this.selectedNode = this.treeData[0]; // Set root as selected node
                this.getIndexedReferenceIndexToken();
            }
        });
    }


    /**
     * Converts IndexedReferenceDto list into a hierarchical tree structure.
     */
    buildTree(rootType: number, rootId: number, data: IndexedReferenceDto[]): TreeNode[] {
        // Step 1: Create a dictionary to store nodes with referenceObjectTypeId and referenceObjectId
        const nodes: Record<string, TreeNode> = {};

        // Step 2: Convert list into objects and store them
        data.forEach(item => {
            const key = `${item.sourceObjectTypeId}-${item.sourceObjectId}`; // Unique key for type-id combination

            if (!nodes[key]) {
                nodes[key] = {
                    label: `${item['link']} (Type: ${item.sourceObjectTypeId}, ID: ${item.sourceObjectId})`,
                    data: item,
                    children: [],
                    expanded: true
                };
            }
        });

        // Step 3: Assign children to parents
        const tree: TreeNode[] = [
            {
                label: `Root: Object Type ${rootType} - ID ${rootId}`,
                data: {
                    referenceObjectTypeId: rootType,
                    referenceObjectId: rootId,
                    sourceObjectTypeId: rootType,
                    sourceObjectId: rootId
                },
                children: []
            }
        ];

        data.forEach(item => {
            const childKey = `${item.sourceObjectTypeId}-${item.sourceObjectId}`;
            const parentKey = `${item.referenceObjectTypeId}-${item.referenceObjectId}`;

            if (nodes[childKey]) {
                if (nodes[parentKey]) {
                    nodes[parentKey].children?.push(nodes[childKey]);
                } else {
                    // If no parent found, attach directly to root
                    tree[0].children?.push(nodes[childKey]);
                }
            }
        });

        return tree;
    }

    getIndexedReferenceIndexToken() {
        this.widgetBrowseObjectReferenceTokens = null;
        setTimeout(() => {
            const filterCriteria = new FilterCriteria();

            filterCriteria.addFilterCriteriaToOr(
                FilterCriteria.createAndInBrackets(
                    FilterCriteria.create("referenceObjectTypeId", FilterCriteria.cOperatorEqual, this.selectedNode.data["sourceObjectTypeId"]),
                    FilterCriteria.create("referenceObjectId", FilterCriteria.cOperatorEqual, this.selectedNode.data["sourceObjectId"])
                )
            );

            this.widgetBrowseObjectReferenceTokens = WidgetFactory.createWidgetTableEntity(
                "si.browse.object.type.reference.token",
                `Tokens`,
                "si.IndexedToken",
                "Keine Tokens vorhanden",
                ObjectRequestList.createWithPaging(
                    true,
                    [filterCriteria],
                    [new Sorting("id", true)],
                    PagingDto.create(0, 50))
            );
        }, 100);


    }

    handleHideSection() {
        this.hideSelection = !this.hideSelection;
    }

    getCellStyle(dataField: any): any {
        if (!dataField || !dataField.matched) {
            return {}; // No styling if not matched
        }

        let rating = dataField.rating || 0; // Default rating if missing
        let alpha = Math.min(1, Math.max(0.2, rating / 100)); // Normalize alpha value (0.2 - 1)

        return {
            "background-color": `rgba(60, 240, 70, ${alpha})`, // Green with dynamic transparency
            "color": "#fff", // White text for contrast
        };
    }

    getRatingStyle(rating: number): any {

        let alpha = Math.min(1, Math.max(0.2, rating / 100)); // Normalize alpha value (0.2 - 1)

        return {
            "background-color": `rgba(60, 240, 70, ${alpha})`, // Green with dynamic transparency
        };
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    protected readonly UiAgentAssignmentEnum = UiAgentAssignmentEnum;
}
