import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {AgentService} from "../../../service/api/agent.service";
import {CalendarConfigurationService} from "../../../service/api/calendar-configuration.service";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {AgentDto} from "../../../dto/agent.dto";
import {
    MsCalendarEventResponseDto,
    MsRequestCalendarPeriodWithAgents
} from "../../../service/api/dto/ms/ms-calendar.types";


@Component({
    selector: 'mvs-example',
    templateUrl: './am-test.page.html',
})
export class AmTestPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "AM Test Page";

    agents: AgentDto[];
    selectedAgents: AgentDto[];

    // singleCalendar: MsCalendarEventResponseDto;
    // multiCalendar: {[ key: number]: MsCalendarEventResponseDto};

    constructor(
        protected agentService: AgentService,
        protected calendarService: CalendarConfigurationService,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
    ) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.agentService.list(ObjectRequestList.createBasic(false,null, null)).subscribe(value => {
            this.agents = value.entries;
            this.refreshComponent();
        });

    }

    handleAgentSelectChange(event: any) {
//         this.singleCalendar = null;
//         this.multiCalendar = null;
// debugger;
//         if (!this.selectedAgents || this.selectedAgents.length == 0){
//             return;
//         }
//
//         if (this.selectedAgents.length == 1) {
//
//             this.calendarService.getCalendar(this.selectedAgents[0], { startDateTime: "2024-03-01 00:00:00", endDateTime: "2024-03-31 23:00:00"}).subscribe(value => {
//                 this.singleCalendar = value;
//             });
//
//         } else {
//
//             this.calendarService.getCalendars(
//                 { startDateTime: "2024-03-01 00:00:00", endDateTime: "2024-03-31 23:00:00", agents: this.selectedAgents}).subscribe(value => {
//                this.multiCalendar = value;
//             });
//
//         }


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
