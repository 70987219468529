import {Component, EventEmitter, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    MvsCoreService,
    MvsMessageService,
    ObjectBaseModeComponent,
    ObserverService,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {LogicLanguage} from "../../../../enum/logic-language.enum";
import {LogicCheckSyntaxDto} from "../../../../dto/logic-check-syntax.dto";
import {LogicDto} from "../../../../dto/logic.dto";
import {LogicCheckSyntaxResponseDto} from "../../../../dto/logic-check-syntax-response.dto";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService, MenuItem} from "primeng/api";
import {LogicImportService} from "../../../../service/api/logic-import.service";
import {LogicExportService} from "../../../../service/api/logic-export.service";
import {LogicService} from "../../../../service/api/logic.service";

@Component({
    selector: 'mvs-lg-logic-object-component-base',
    template: ''
})
export class LgLogicObjectBaseComponent extends ObjectBaseModeComponent implements OnInit {


    dto: LogicDto;

    script: string;
    language: string;
    currentView: string = 'logic';
    activeTab: number = 0;

    syntaxResponse: LogicCheckSyntaxResponseDto;
    widgetButtons: WidgetHelperButton[];

    @Output() onBreadcrumbItems: EventEmitter<MenuItem[]> = new EventEmitter();

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected observerService: ObserverService,
                protected logicImportService: LogicImportService,
                protected logicExportService: LogicExportService,
                protected logicService: LogicService
    ) {
        super(route);
    }

    ngOnInit() {
        // initialize navigation items
        this.loadSideBarMenuItems();

        super.ngOnInit();

        this.onObjectChanged();

        this.initialized = true;
    }


    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {

    }


    onObjectChanged() {
        this.script = this.dto.script;
        this.language = LogicLanguage[this.dto.logicLanguage];

        const item: MenuItem[] = [{
            icon: this.dto['image'],
            label: this.dto['name'],
            id: this.dto.id as unknown as string,
        }];

        this.onBreadcrumbItems.emit(item);
    }


    checkSyntax() {
        const codeSyntaxDto: LogicCheckSyntaxDto = new LogicCheckSyntaxDto();

        codeSyntaxDto.logicId = this.objectIdentifier.objectId;
        codeSyntaxDto.logicLanguage = this.dto.logicLanguage;
        codeSyntaxDto.code = this.script;

        this.logicService.checkCodeSyntax(codeSyntaxDto).subscribe(checkSyntax => {
            this.syntaxResponse = checkSyntax;
        })
    }

    updateScript() {

        const logicDto: LogicDto = new LogicDto();
        logicDto.id = this.dto.id;
        logicDto.script = this.script;
        logicDto.logicLanguage = this.dto.logicLanguage;

        this.logicService.update(logicDto).subscribe((res: LogicDto) => {
            this.dto = res;
            this.messageService.showSuccessMessage('Update', 'Logic has benn updated.');
        });

    }

    onContentChange(content: string) {
        this.script = content;
    }

    handleLogicLanguageChange(event: LogicLanguage) {
        this.dto.logicLanguage = event;
    }

    setView(view: string) {
        this.currentView = view;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }

    }

    handleNavigationItemsChange(): void {
        this.onNavigationItems.emit(this.navigationItems);
    }


}
