import {WfProcessTypeFlowTypeEnum} from "../enum/wf-process-type-flow-type.enum";
import {WfProcessTypeStepActivityDto} from "./wf-process-type-step-activity.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeStepNextDto} from "./wf-process-type-step-next.dto";
import {WfProcessTypeStepHintDto} from "./wf-process-type-step-hint.dto";
import {WfActivityTypeEnum} from "../enum/wf-activity-type.enum";

export class WfProcessTypeStepDto extends DtoDetail {

    public processTypeDtoId : number;
    public processTypeDtoName : string;
    public processTypeDtoImage : string;
    public processTypeDtoColor : string;
    public position : number;
    public name : string;
    public flowType : WfProcessTypeFlowTypeEnum;
    public predecessorProcessTypeStepDtoId : number;
    public predecessorProcessTypeStepDtoName : string;
    public predecessorProcessTypeStepDtoImage : string;
    public predecessorProcessTypeStepDtoColor : string;
    public bpmDataJson : string;


    public activities?: WfProcessTypeStepActivityDto[];
    public nextSteps?: WfProcessTypeStepNextDto[];
    public stepHints?: WfProcessTypeStepHintDto[];

    public static getNextStepById(id: number, stepType: WfProcessTypeStepDto, activityType?:WfActivityTypeEnum) : WfProcessTypeStepNextDto | null {

        if (activityType == WfActivityTypeEnum.choose_next_step) {
            if (stepType.nextSteps) {
                for (let nextStep of stepType.nextSteps) {
                    if (nextStep.nextProcessTypeStepDtoId === id) {
                        return nextStep;
                    }
                }
            }
        } else {
            if (stepType.nextSteps) {
                for (let nextStep of stepType.nextSteps) {
                    if (nextStep.id === id) {
                        return nextStep;
                    }
                }
            }
        }


        return null;
    }


    public static getFlowTypeById(activityForStep: WfProcessTypeStepActivityDto, stepType: WfProcessTypeStepDto[]) : WfProcessTypeFlowTypeEnum | null {

            for (let step of stepType) {
                if (step.id === activityForStep.processTypeStepDtoId) {
                    return step.flowType;
                }
            }
        return null;
    }

}