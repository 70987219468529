import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cm-Contract-structure-structure-page',
  templateUrl: './contract-structure.page.html',
  styleUrls: ['./contract-structure.page.scss']
})
export class ContractStructurePage extends PageComponent implements OnInit {
  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Vertragsstruktur";

  ngOnInit(): void {

    super.ngOnInit();

    this.objectBrowserRequest = {

      'extractDto'    : false,
      "createMode"    : true,

      'entries' : [
        {
          'id'                : 2,
          'objectAlias'       : 'cm.ContractType',
          'level'             : 2,
          'parentBinding'     : 'none',
          'processRecursion'  : true,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Vertragstypen",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Typen anlegen"
        }
      ]

    };

    const dontcare = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cm.ContractType',
          'level'             : 1,
          'parentBinding'     : 'none',
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cm.ContractTypeField',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Felder",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Feld anlegen"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'cm.ContractTariff',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Tarife",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Tarif anlegen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'cm.ContractTypeInsurableObjectType',
          'level'             : 3,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Versicherte Objekte",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Objekt zuweisen"
        }
      ]
    };

  }

}
