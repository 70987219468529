<div class="bg-white">
    <mvs-config-header header="Notifications"
                       icon="fa-solid fa-spell-check">
    </mvs-config-header>

    <div class="mt-3">
        <ul class="flex align-items-center overflow-x-auto gap-3 px-2 py-3 list-none border-bottom-1 border-300">

            <li *ngFor="let item of widgetEntries">
                <button pButton pRipple
                        (click)="handleTabSelection(item)"
                        [label]="item.label"
                        [ngClass]="{'p-button-text text-700': activeWidget?.id != item.id}">
                </button>
            </li>
        </ul>
    </div>

    <ng-container *ngIf="activeWidget">


        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationGroup'">
            <mvs-widget *ngIf="notificationGroupWidget"
                        [widgetData]="notificationGroupWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

<!--        (onObjectSelect)="handleTemplateSelect($event)"-->
        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationType'">
            <mvs-widget *ngIf="notificationTypeWidget"
                        [widgetData]="notificationTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ns.NsNotificationTypeChannelType'">
            <mvs-widget *ngIf="notificationTypeChannelWidget"
                        [widgetData]="notificationTypeChannelWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>



    </ng-container>


</div>