import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {PeriodHelper} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../service/api/customer.service";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-cr-create-customer-page',
    templateUrl: './cr-customer-create.page.html',
})
export class CrCustomerCreatePage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('hiddenLink') hiddenLink: ElementRef;

    defaultLabel: string = "Kundenanlage";

    createdCustomersWidgetData: WidgetData;


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService,
        protected objectNavigationService: MvsObjectNavigationService,
        protected router: Router) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

        this.refreshWidgetCreatedCustomers();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    refreshWidgetCreatedCustomers(){

        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);

        const currentDateApi = PeriodHelper.convertDateToApiDate(currentDate);
        const sevenDaysAgoApi = PeriodHelper.convertDateToApiDate(sevenDaysAgo);

        const filterPeriod = FilterCriteria.create("createdDate", FilterCriteria.cOperatorBetween, sevenDaysAgoApi, currentDateApi);

        this.createdCustomersWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.create.previosly.created.customers",
            "Kunden angelegt in den letzten 7 Tagen",
            "list",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kunden im Zeitraum angelegt",

            ObjectRequestList.createComplexRequestList(
                true,
                this.customerService.getCustomerWithPersonComplexSelectionWoText(),
                [filterPeriod],
                [new Sorting("createdBy", false)],
                PagingDto.create(0,20)),

            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data")

        );

    }

    onCustomerSelect(selectedCustomer: ObjectIdentifier) {

        this.objectNavigationService.handleObjectNavigation(new ObjectIdentifier('cr.Customer', selectedCustomer.objectId), null, { openNewTab: true });
        /*
        const url = this.router.createUrlTree(["/cr/customers/" + selectedCustomer.objectId]).toString();

        // Create a link element
        const link = this.hiddenLink.nativeElement;
        link.href = url;
        link.target = '_blank';

        // Simulate a click on the link to open a new tab
        link.click();

        debugger;
        */
    }



    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
