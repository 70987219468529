import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {XrCrossReportSelectionFormDto} from "../../logic/xr-cross-report-selection-form.dto";
import {XrReportService} from "../../service/api/xr-report.service";
import {XrCrossReportSelectionDto} from "../../logic/xr-cross-report-selection.dto";
import {MvsFormControlService} from "@kvers/alpha-ui";
import {MvsFormGroup} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-xr-report',
    templateUrl: './xr-report.component.html',
})
export class XrReportComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() reportName: string;

    selectionForm: XrCrossReportSelectionFormDto;
    selection: XrCrossReportSelectionDto;
    selectionFormGroup: MvsFormGroup;
    // reportWidgetData: WidgetData;


    constructor(
        protected reportService: XrReportService,
        protected formService: MvsFormControlService
    ) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

    }


    refreshReport() {

        // retrieve selection
        this.reportService.getSelection(this.reportName).subscribe(value => {
            this.selectionFormGroup = this.formService.buildFormGroup(XrCrossReportSelectionFormDto.convertToForm(value));
            this.selectionForm = value;
            this.initialized = true;

        });


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

        this.refreshReport();

    }


    onExecuteReport(event: any) {

        this.reportService.executeReport(this.reportName).subscribe(value => {

        });

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
