import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {PmPersonDto} from "../../../cr/dto/pm-person.dto";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {PersonAddressDto} from "../../dto/person-address.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {PersonContactDto} from "../../dto/person-contact.dto";


@Component({
    selector: 'pm-person',
    templateUrl: './pm-person.component.html',
})
export class PmPersonComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    tabItems: MenuItem[];
    activeItem: MenuItem | undefined;

    widgetPerson: WidgetData;
    widgetPersonAddress: WidgetData;
    widgetPersonContact: WidgetData;

    contextPerson: DtoImportObjectContext;

    dto: PmPersonDto;

    ngOnInit() {
        super.ngOnInit();

        this.tabItems = [
            { id : "pm.Person", label : "Person", icon : 'fa-light fa-person'},
            { id : "pm.PersonAddress", label : "Adresse", icon : 'fa-light fa-location-dot'},
            { id : "pm.PersonContact", label : "Kontaktdaten", icon : 'fa-light fa-address-book'},
        ];
        this.activeItem = this.tabItems[0];

    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.widgetPerson = WidgetFactory.createWidgetObject(
            "pm.person.object.form",
            "Person",
            "pm.Person",
            this.dto.id
        );

        // create person context
        this.contextPerson = DtoImportObjectContext.createFromObjectIdentifier(this.objectIdentifier)

        // create filter
        const filterPerson = FilterCriteria.create("person", FilterCriteria.cOperatorEqual, this.dto.id);

        // create widget for Address
        this.widgetPersonAddress = WidgetFactory.createWidgetListWithDetailEntityAndCallback(
            "pm.person.object.address.form",
            "Adressen",
            "pm.PersonAddress",
            "Keine Addressdaten vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createRelationNode("address", ObjectRequestRelation.createJoin("address"))),
                [filterPerson],
                [],
                null),

            {
                onFunctionObjectUpdate(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField("personDtoId",MvsFormFieldAccessEnum.HIDDEN);
                    return { formControlOverwrite: overwrite };
                },
                onFunctionObjectDefaultNew(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField("personDtoId",MvsFormFieldAccessEnum.HIDDEN);

                    const personAddressDto = new PersonAddressDto();
                    personAddressDto.startDate = new Date();
                    personAddressDto.entityStatus = EntityStatusEnum.active;

                    return { formControlOverwrite: overwrite, dto: personAddressDto };
                }
            }
        );

        // create widget for Contact
        this.widgetPersonContact = WidgetFactory.createWidgetListWithDetailEntityAndCallback(
            "pm.person.object.contact.form",
            "Kontaktdaten",
            "pm.PersonContact",
            "Keine Kontaktdaten vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createRelationNode("contact", ObjectRequestRelation.createJoin("contact"))),
                [filterPerson],
                [],
                null),

            {
                onFunctionObjectUpdate(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField("personDtoId",MvsFormFieldAccessEnum.HIDDEN);
                    return { formControlOverwrite: overwrite };
                },
                onFunctionObjectDefaultNew(widgetData: WidgetData): WidgetToolbarCallbackInterface {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField("personDtoId",MvsFormFieldAccessEnum.HIDDEN);

                    const personContactDto = new PersonContactDto();
                    personContactDto.startDate = new Date();
                    personContactDto.entityStatus = EntityStatusEnum.active;

                    return { formControlOverwrite: overwrite, dto: personContactDto };
                }
            }
        );

    }

}
