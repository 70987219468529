import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";


@Component({
  selector: 'mvs-am-config',
  templateUrl: './am-config.page.html',
  styleUrls: ['./am-config.page.scss']
})
export class AmConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Agenten, Agentenpools und Kalenderkonfiguration";

  widgetAgent: WidgetData;
  widgetAgentPool: WidgetData;
  widgetAgentPoolAgent: WidgetData;
  widgetCalendarConfiguration: WidgetData;
  widgetNotificationConfiguration: WidgetData;

  activeIndex: number = 0;

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
  }

  initComponent(): void {

    this.widgetAgent = WidgetFactory.createWidgetList(
        "am.config.agent",
        "Agenten",
        "table",
        "list",
        "entity",
        "am.Agent",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("name", true)])
    );

    this.widgetAgentPool = WidgetFactory.createWidgetList(
        "am.config.agent.pool",
        "Agentenpools",
        "table",
        "list",
        "entity",
        "am.AgentPool",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("name", true)])
    );

    this.widgetAgentPoolAgent = WidgetFactory.createWidgetList(
        "am.config.agent.pool.agent",
        "Zuweisung Agent zu Pool",
        "table",
        "list",
        "entity",
        "am.AgentPoolAgent",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetCalendarConfiguration = WidgetFactory.createWidgetList(
        "am.config.active.configuration",
        "Aktive Konfiguration",
        "list",
        "list",
        "entity",
        "am.CalendarConfiguration",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [])
    );

    this.widgetNotificationConfiguration = WidgetFactory.createWidgetList(
        "am.config.agent.notification.type",
        "Benachrichtigungen",
        "table",
        "list",
        "entity",
        "am.AgentNotificationType",
        "Keine Benachrichtigungen vorhanden",
        ObjectRequestList.createBasic(true, [], [])
    );

    this.initialized = true;

  }

}
