import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { TeVariableComponentBase } from '../../logic/te-variable-component-base';

@Component({
    selector: 'mvs-te-variable-dialog',
    templateUrl: "./te-variables-dialog.component.html",
    styleUrls: ["./te-variables-dialog.component.scss"],
})
export class TeVariableDialogComponent
    extends TeVariableComponentBase
    implements OnInit, OnChanges, OnDestroy {

    @Input() openDialog: boolean;

    @Output() onConfirmSelectVariable: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
    @Output() onCloseDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    isVariableSelected: boolean = false;
    selectedNode: TreeNode;

    constructor(protected confirmationService: ConfirmationService) {
        super();
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    handleVariableSelect(selectedNode: TreeNode): void {
        
        if (selectedNode["node"] && !selectedNode["node"].children) {
            this.isVariableSelected = true;
            this.selectedNode = selectedNode;
        } else {
            this.isVariableSelected = false;
        }
    }

    handleConfirmVariableSelect(): void {
        
        if (this.isVariableSelected) {
            this.onConfirmSelectVariable.emit(this.selectedNode);
            this.openDialog = false;
            this.onCloseDialog.emit(false);
        }
        else {
            return;
        }
    }

    handleCancel(): void {
        this.openDialog = false;
        this.onCloseDialog.emit(false);
    }

    handleConfirmClose(): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to close?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.handleCancel();
            },
            reject: () => {
            },
        });
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {}
}
