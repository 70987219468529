import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {TeConfigPage} from "./page/overview/config/te-config.page";
import {TeTemplateObjectComponent} from "./component/te-template/te-template-object.component";
import {TeArtefactObjectComponent} from "./component/te-artefact/te-artefact-object.component";
// import {EditorModule} from "primeng/editor";
import {TreeModule} from 'primeng/tree';
import { TreeDragDropService } from 'primeng/api';
import { DragDropModule } from "primeng/dragdrop";
import {TeEditorComponent} from "./component/te-editor/te-editor.component";
import { TeVariablesComponent } from './component/te-variables/te-variables.component';
import { EmbeddedArtefactsComponent } from './component/embedded-artefacts/embedded-artefacts.component';
import { TeVariableService } from './service/te-variable.service';
import {ContextMenuModule} from 'primeng/contextmenu';
import { TeTestCaseComponent } from './component/te-test-case/te-test-case.component';
import { TeTestCaseSetupComponent } from './component/te-test-case-setup/te-test-case-setup.component';
import { TeTestCaseResultComponent } from './component/te-test-case-result/te-test-case-result.component';
import { TeVariableDialogComponent } from './component/te-variables-dialog/te-variables-dialog.component';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {TeConfigTestPage} from "./page/overview/config-test/te-config-test.page";
import {
    TableInfoConditionsComponent
} from "./component/te-editor/components/table-info-conditions/table-info-conditions.component";
import {CmModule} from "../cm/cm.module";
import {NsModule} from "../ns/ns.module";
import {
    CodeMirrorEditorComponent
} from "./component/te-editor/components/code-mirror-editor/code-mirror-editor.component";
import {TeOverviewPage} from "./page/te-overview-page/te-overview.page";
import {TeKpiComponent} from "./page/te-overview-page/components/kpiComponent/te-kpi.component";
import {TeStatisticsComponent} from "./page/te-overview-page/components/statisticsComponent/te-statistics.component";
import {UiModule} from "../ui/ui.module";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {AgentKpiComponent} from "../am/page/agent-overview-page/components/kpiComponent/agent-kpi.component";
import {
    AgentStatisticsComponent
} from "../am/page/agent-overview-page/components/statisticsComponent/agent-statistics.component";



@NgModule({
    imports: [
        CoreModule,
        // EditorModule,
        TreeModule,
        DragDropModule,
        ContextMenuModule,
        ProgressSpinnerModule,
        CmModule,
        NsModule,
        UiModule
    ],

    declarations: [
        TeConfigPage,
        TeTemplateObjectComponent,
        TeArtefactObjectComponent,
        TeEditorComponent,
        TeVariablesComponent,
        EmbeddedArtefactsComponent,
        TeTestCaseComponent,
        TeTestCaseSetupComponent,
        TeTestCaseResultComponent,
        TeVariableDialogComponent,
        TeConfigTestPage,
        TableInfoConditionsComponent,
        CodeMirrorEditorComponent,
        TeOverviewPage,
        TeKpiComponent,
        TeStatisticsComponent
    ],
    exports: [
        TeConfigPage,
        TeTemplateObjectComponent,
        TeArtefactObjectComponent,
        TeEditorComponent,
        TeVariablesComponent,
        TeVariableDialogComponent,
        TeConfigTestPage,
        TableInfoConditionsComponent,
        CodeMirrorEditorComponent,
    ],
    providers: [
        TreeDragDropService,
        TeVariableService,
        ProgressSpinnerModule
    ]

})
export class TeModule implements DynamicModule {

    alias = 'te';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return TeKpiComponent;
    }

    getStatisticsComponent() {
        return TeStatisticsComponent;
    }
}

