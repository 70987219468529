import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MessageService, TreeDragDropService} from "primeng/api";
import {ObjectIdentifier, ObjectTypeService, PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {CustomerContractService} from "../../../../cr/service/api/customer-contract.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {UiPageUserService} from "../../../../ui/service/api/ui-page-user.service";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {
  NsNotificationTypesModeEnum
} from "../../../../ns/component/ns-notification-types-for-object/enums/ns-notification-types-mode.enum";
import {TicketTypeService} from "../../../../tm/service/api/ticket-type.service";
import {TicketGroupFilterService} from "../../../../tm/service/api/ticket-group-filter.service";


@Component({
  selector: 'mvs-test-form',
  providers: [TreeDragDropService,MessageService],
  templateUrl: './test-marko.page.html',
  styleUrls: ['./test-marko.page.scss']
})
export class TestMarkoPage extends PageComponent implements OnInit {

  widgetData: WidgetData;

  user22: ObjectIdentifier;
  ticket: ObjectIdentifier;

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected visitService: UiPageUserService,
      protected ticketService: TicketService,
      protected objectTypeService: ObjectTypeService,
      protected customerContractService: CustomerContractService,
      protected ticketGroupFilterService: TicketGroupFilterService,
  ) {
    super(route, coreService);
  }

  formWidget: WidgetData;
  processWidget: WidgetData;
  customerContactsWidgetData: WidgetData;

  initComponent() {

    this.user22 = new ObjectIdentifier("um.User", 22);

// http://localhost:4301/#/tm/me-dashboard?mainSelection=0&subSelection=t_33&typeFilter=-1&tid-sbr=tm.Ticket:249&tid-sbr-action=0
    this.ticket = new ObjectIdentifier("tm.Ticket", 297);

    this.refreshWidget();

  }

  getGroups() {

    this.ticketGroupFilterService.getMeGroups().subscribe(value => {
      debugger;
    });


  }

  getCounts() {

    this.ticketGroupFilterService.getCount([1,2]).subscribe(value => {
      debugger;
    });

  }

  getGroupInfo() {
    this.ticketGroupFilterService.getGroupInfo(1).subscribe(value => {
      debugger;
    });
  }




  testNewSelect() {

    // this.objectTypeService.getInternalReturnList("/mvsa/cc/objectUserAccesss/list/object?objectTypeAlias=tm.Ticket&objectId=12").subscribe(result => {
    //
    // });

    // this.objectTypeService.list(ObjectRequestList.createBasic(true, [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, "tm.Ticket")],[])).subscribe(value =>
    //     {
    //
    //     }
    // );
  }

  deriveTicketActions() {
    this.ticketService.getAvailableActions(35).subscribe(value => {
      console.log(value);
    });
  }

  getLastVisits() {
    this.visitService.pageVisit("", "Home");
  }

  refreshWidget() {

    this.widgetData = WidgetFactory.createWidget(
        "test.marko.widget",
        "Filter",
        "table",
        "list",
        "entity",
        "tm.TicketGroupFilter"
    );

  }

  handleGetData() {

    const customerService = this.coreService.getCrudService<CustomerService>("cr.Customer");


    customerService.list(
        ObjectRequestList.createSimpleRequestList(true, ObjectRequestSimple.create(true, false, 1, "cr.CustomerType"),
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [], PagingDto.createSize(1))).subscribe(value => {

    });


/*
    customerService.list(
        ObjectRequestList.createComplexRequestList(
            false,
            ObjectRequestComplex.build(false, true,
                ObjectRequestComplexNode.createSimpleAttributeNode("id"),
                ObjectRequestComplexNode.createSimpleAttributeNode("alias"),
                ObjectRequestComplexNode.createSimpleAttributeNode("endDate")),
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [], PagingDto.createSize(1)
        )).subscribe(value => {

    });

 */
    /*
    customerService.list(
        ObjectRequestList.createComplexRequestList(
            false,
            ObjectRequestComplex.build(false,false,
                ObjectRequestComplexNode.createRelationNode("person",ObjectRequestRelation.createJoin("person"))
                    .addNodes(ObjectRequestComplexNode.createRelationNode("personDetail",ObjectRequestRelation.createJoin("personDetail"))),
                ObjectRequestComplexNode.createRelationNode("contracts", ObjectRequestRelation.createJoin("cr.CustomerContract#customer")),
                ObjectRequestComplexNode.createRelationNode("address", ObjectRequestRelation.createJoin("+mainAddress")),
            ),
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [], PagingDto.createSize(1))).subscribe(value => {

    });


        /*
        .createBasic(false, [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [])).subscribe(value => {

    });
         */

    /*
    customerService.get(84876, null, false, false, 0).subscribe(value => {
      console.log("Hello World");
    });
     */




  }


  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
    this.initialized = true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


  protected readonly NsNotificationTypesModeEnum = NsNotificationTypesModeEnum;
}
