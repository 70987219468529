import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'im-statistics.component.html',
})
export class ImStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    pending: number = 0;
    processed: number = 0;
    inProcess: number = 0;
    unfinished: number = 0;


    importHistoryWidget: WidgetData;
    importsBySystemWidget: WidgetData;
    importRecordsByImportWidget: WidgetData;
    recentlyAddedImportsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;
    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'IM Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
        this.overviewDto = [
            { label: 'Pending Registrations', value: 0 },
            { label: 'Processed Registrations', value: 0 },
            { label: 'In-Process Registrations', value: 0 },
            { label: 'Unfinished Registrations', value: 0 }
        ];
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshAppointmentHistoryWidget();
        this.refreshAppointmentByTypeWidget();
        this.refreshAppointmentTypeByGroupWidget();
        this.refreshAddedAppointment();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/ui/config']);
    }



    refreshAppointmentHistoryWidget() {
        this.importHistoryWidget = new WidgetData();
        this.importHistoryWidget.idAlias = "im.import.overview.history.widget";
        this.importHistoryWidget.name = "Imports History";

        this.importHistoryWidget.uiComponent = "history.chart";
        this.importHistoryWidget.dataProvider = "list";
        this.importHistoryWidget.dataSource = "entity.groupBy";
        this.importHistoryWidget.dataProviderObject = "im.ImImport";
        this.importHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["severity", "createdDate"],
            [new ObjectRequestListAttribute("severity", "severity", DtoListAttributeRequestAggregateEnum.count)]);

        this.importHistoryWidget.setParamValue("fieldCategory", "severity");
        this.importHistoryWidget.setParamValue("fieldCategoryCount", "severity_count");
        this.importHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.importHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.importHistoryWidget.setParamValue("selectionMode", "single");
        this.importHistoryWidget.setParamValue("size", "S");
    }

    refreshAppointmentByTypeWidget() {
        this.importsBySystemWidget = WidgetFactory.createWidgetGroupBy(
            'im.import.overview.by.type.widget',
            'Imports By System',
            'category',
            'entity.groupBy',
            'im.ImImport',
            'externalSystem',
            'externalSystem_count',
            this.filterCriteria,
            'externalSystem',
            'Anzahl');
    }

    refreshAppointmentTypeByGroupWidget() {
        this.importRecordsByImportWidget = WidgetFactory.createWidgetGroupBy(
            'im.import.overview.records.by.import.widget',
            'Import Records By Import',
            'category',
            'entity.groupBy',
            'im.ImImportRecord',
            'status',
            'status_count',
            this.filterCriteria,
            'status',
            'Anzahl');
    }

    refreshAddedAppointment() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedImportsWidget = WidgetFactory.createWidgetTableEntity(
            'im.import.overview.recently.added.imports',
            'Recent Imports',
            'im.ImImport',
            'No Address',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
