import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";

@Component({
    selector: 'mvs-xx-object-document-component',
    templateUrl: './object-documents.component.html',
    styleUrls: ['./object-documents.component.scss']
})
export class XxObjectDocumentComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectTypeId: number;
    @Input() objectTypeAlias: string;
    @Input() objectId: number;
    visible:boolean;
    documentObject:any;

    widgetTableDocument: WidgetData;
    protected objectTypeService: ObjectTypeService;

    constructor(protected coreService: MvsCoreService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectTypeService = <ObjectTypeService>this.coreService.getCrudService("cc.ObjectType");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshAssignedDocuments()

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshAssignedDocuments() {

        if (!this.objectTypeId) {
            this.getObjectType(this.objectTypeAlias);
            return;
        }

        this.widgetTableDocument = new WidgetData();
        this.widgetTableDocument.id = 1;
        this.widgetTableDocument.name = 'Documents Assignees';
        this.widgetTableDocument.idAlias = 'document.show.object.documents';

        this.widgetTableDocument.uiComponent = 'table';
        this.widgetTableDocument.dataProvider = 'list';
        this.widgetTableDocument.dataSource = 'entity';
        this.widgetTableDocument.dataProviderObject = 'dm.DmDocumentAssignment';

        const filterCriteriaList = [];

        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectType', FilterCriteria.cOperatorEqual, this.objectTypeId, null));
        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectId', FilterCriteria.cOperatorEqual, this.objectId, null));

        this.widgetTableDocument.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("lastModifiedDate", false)],
            PagingDto.create(0, 5)
        )

        this.widgetTableDocument.setParamValue("selectionMode", "single");
        this.widgetTableDocument.setParamValue("size", "S");
    }

    getObjectType(objectTypeAlias: string) {
        this.objectTypeService.getViaObjectType(objectTypeAlias).then((res: any) => {
            this.objectTypeId = res.id;
            this.refreshAssignedDocuments();
        })
    }

    onObjectSelect(event) {
        this.documentObject = event;
        this.visible = true;
    }
}
