import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-cr-customer-external-alias-page',
  templateUrl: './customer-external-alias.page.html',
  styleUrls: ['./customer-external-alias.page.scss']
})
export class CustomerExternalAliasPage implements OnInit {

  objectBrowserRequest: any;

  constructor() {
  }

  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerExternalAlias',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "External Alias",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create"
        },

      ]

    }
  }
}
