<ng-container *ngIf="initialized && activeCall">
    <div class="border-300 border-1 border-round cursor-pointer surface-ground">
        <div class="flex align-items-center justify-content-between topbar-call">
            <div class="flex justify-content-center bg-green-100 text-green-800 border-round border-noround-right h-full" (click)="handleShowCall()">
                <i class="p-3 fa-solid fa-phone-volume text-xl"></i>
            </div>
            <div class="flex">
                <div class=" flex align-items-center justify-content-start p-2">
                    <div class="flex-column justify-content-center align-items-center">
                        <div class="font-semibold text-900 text-sm">{{calledObject['personDtoName']}}</div>
                        <div class="text-600 text-sm">{{calledObject['alias']}}</div>
                    </div>
                </div>
                <div class=" flex align-items-center justify-content-start p-2 mx-2">
                    <div class="flex-column justify-content-center align-items-center">
                        <div class="font-semibold text-900 text-sm">{{activeCall.callTypeDtoName}}</div>
                        <div *ngIf="timer"
                             class="text-600 text-sm">
                            {{timer.hours}}:{{timer.minutes}}:{{timer.seconds}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex align-items-center justify-content-center bg-red-500 text-white border-round border-noround-left h-full end-call-display">
                <i class="fa-solid fa-circle-phone-hangup fa-fw text-2xl" (click)="confirmDialogShown = true"></i>
            </div>
        </div>
    </div>

     <p-dialog
             [closable]="false"
             [modal]="true"
             position="top"
             [draggable]="false"
             [(visible)]="confirmDialogShown">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Anruf abschließen</span>
        </ng-template>
        <div class="flex flex-column gap-3">
            <button type="button" pButton icon="fa-regular fa-phone-xmark fa-fw"
                    class="p-button-danger" label="Nicht erreichbar"
                    (click)="handleEndCall(callResultEnum.no_answer)">

            </button>
            <button type="button" pButton icon="fa-regular fa-phone-xmark fa-fw"
                    class="p-button-warning" label="Besetzt"
                    (click)="handleEndCall(callResultEnum.busy)">

            </button>
            <button type="button" pButton icon="fa-regular fa-forward fa-fw"
                    class="p-button-primary" label="Keine Zeit"
                    (click)="handleEndCall(callResultEnum.no_time)">

            </button>
            <button type="button" pButton icon="fa-regular fa-circle-phone fa-fw"
                    class="p-button-success" label="Anruf erfolgreich"
                    (click)="handleEndCall(callResultEnum.success)">

            </button>

        </div>

        <ng-template pTemplate="footer">
            <div class="flex flex-column">
                <button
                        type="button"
                        pButton
                        icon="fa-regular fa-xmark fa-fw"
                        (click)="confirmDialogShown = false"
                        label="Abbrechen"
                        class="p-button-text mr-0">
                </button>
            </div>
        </ng-template>
    </p-dialog>


</ng-container>

