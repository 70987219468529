import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldDto, MvsFormGroup, ObjectRequestList,} from "@kvers/alpha-core-common";
import {MvsCreateTicketOutputInterface} from "../interface/mvs-create-ticket-output.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {TicketTypeService} from "../../../../../../tm/service/api/ticket-type.service";
import {TicketTypeDto} from "../../../../../../tm/dto/ticket-type.dto";

@Component({
    selector: 'mvs-create-ticket-output-config-component',
    templateUrl: './mvs-create-ticket-output-config.component.html',
})
export class MvsCreateTicketOutputConfigComponent implements OnInit, OnChanges, OnDestroy, MvsCreateTicketOutputInterface {

    @Input() objectType: string;
    @Input() objectId: number;
    @Input() ticketTypeId: number;

    @Input() formGroup: FormGroup;
    @Input() formFields: MvsFormFieldDto[];
    @Input() widgetUiField: MvsFormGroup;

    ticketTypeList: TicketTypeDto[];

    objectIdOptions: { label: string; value: any }[] = [];

    initialized: boolean;

    constructor(protected ticketTypeService: TicketTypeService, protected changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        this.initializeUiConfigData();

        this.getTicketTypes();

        this.objectIdOptions = this.getObjectIdOptions();

        this.initialized = true;
    }


    initializeUiConfigData(): void {

        let uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        if (!uiConfigData) {
            uiConfigData = new FormGroup({});
            this.widgetUiField.addControl('uiConfigData', uiConfigData);
        }

        // Ensure objectType control exists
        if (!uiConfigData.get('objectType')) {
            uiConfigData.addControl('objectType', new FormControl(''));
        }

        // Ensure objectId control exists
        if (!uiConfigData.get('objectId')) {
            uiConfigData.addControl('objectId', new FormControl(null));
        }

        // Ensure includeIndirect control exists
        if (!uiConfigData.get('ticketType')) {
            uiConfigData.addControl('ticketType', new FormControl(null));
        }
    }

    getObjectIdOptions(): { label: string; value: any }[] {
        return this.formFields.map((field) => ({
            label: field.uiLabel,
            value: field.id,
        }));
    }

    handleObjectType(objectType: string) {

        const uiConfigData = this.widgetUiField.get('uiConfigData');

        if (uiConfigData && uiConfigData.get('objectType')) {
            uiConfigData.get('objectType')?.setValue(objectType);
        }
    }

    getTicketTypes() {
        const objectRequestList = ObjectRequestList.createBasic(false,[],[]);

        this.ticketTypeService.list(objectRequestList).subscribe(res => {
            this.ticketTypeList = res.entries;
            this.changeDetectorRef.detectChanges();
        })

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }
}
