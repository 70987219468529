import {Injectable, Type} from '@angular/core';
import {
    MvsCrudModeEnum,
    MvsCrudService,
} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    LgCalculationLogicTypeObjectComponent
} from "../../component/object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.component";

@Injectable({
    providedIn: 'root'
})
export class CalculationLogicTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/lg/calculationLogicTypes')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return LgCalculationLogicTypeObjectComponent
        }
        return null;
    }


}