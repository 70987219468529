

<div class="grid p-fluid">

    <div class="col-12">
        <mvs-widget
                [widgetData]="widgetImports"
                (onObjectSelect)="onImportSelect($event)"
        ></mvs-widget>
    </div>

    <div class="col-12">
        <ng-container *ngIf="selectedImportId">
            <mvs-im-view-import [importId]="selectedImportId"></mvs-im-view-import>
        </ng-container>

    </div>


</div>






