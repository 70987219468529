import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {AgentActiveObjectDto} from "../../dto/agent-active-object.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";

@Injectable({
    providedIn: 'root'
})
export class AgentActiveObjectService extends MvsCrudService {

    private _subjectAgentActiveObjects = new BehaviorSubject<AgentActiveObjectDto[]>([]);


    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/am/agentActiveObjects')

        this.getMyActive().subscribe(res => {
            this._subjectAgentActiveObjects.next(res.entries);
        });
    }

    /**
     * get active objects for agent
     * @param agentId
     */
    public getActiveObjectsForAgent(agentId: number): Observable<DtoList> {

        const request: ObjectRequestList = new ObjectRequestList(
            false,
            [
                FilterCriteria.create("agent", FilterCriteria.cOperatorEqual, agentId, null),
                FilterCriteria.create("status", FilterCriteria.cOperatorEqual, 0, null)
            ],
            []);

        return this.list(request);
    }

    public start(objectAlias: string, objectId: number, mainObject: boolean = false): void {

        const dtoDetail = new DtoDetail();
        dtoDetail["objectAlias"] = objectAlias;
        dtoDetail["objectId"] = objectId;
        dtoDetail["mainObject"] = mainObject;

        this.createInternalReturnDtoList(dtoDetail, "/start").subscribe(res => {
            this._subjectAgentActiveObjects.next(<AgentActiveObjectDto[]>res.entries);
        });

    }

    public completeMainObject(id: number): void {
        this.completeInternal(id, "/completeMainObject");
    }

    public complete(id: number): void {
        this.completeInternal(id, "/complete");
    }

    private completeInternal(id: number, endPoint: string) {

        this.getInternalReturnList(endPoint + "/" + id).subscribe(res => {
            this._subjectAgentActiveObjects.next(<AgentActiveObjectDto[]>res.entries);
        });
    }

    public getMyActive(): Observable<DtoList<AgentActiveObjectDto>> {

        const url = `${this.apiUrl}/myActive`;

        return <Observable<DtoList<AgentActiveObjectDto>>>this.getList(url);
    }

    public completeAll(): void {

        this.getInternalReturnList("/completeAll").subscribe(res => {
            this._subjectAgentActiveObjects.next(<AgentActiveObjectDto[]>res.entries);
        });

    }


    /**
     * handleObjectData() is used to retrieve objectType and based ob objectId retrieve whole object
     * @param coreService
     */
    handleObjectData(coreService: MvsCoreService) {
        for (let agentActiveObject of this._subjectAgentActiveObjects.value) {

            const alias = agentActiveObject.objectTypeDto.alias;

            const crudService = coreService.getCrudService(alias);

            crudService.get(agentActiveObject.objectId,null,false,false,0).subscribe(res => {
                agentActiveObject.objectDto = res;
            });
        }
    }

    getActiveTicket(): AgentActiveObjectDto {
        const entries = this.subjectAgentActiveObjects.getValue();
        const mainObject = entries.find(item => item.mainObject == true);
        return mainObject;
    }

    get subjectAgentActiveObjects(): BehaviorSubject<AgentActiveObjectDto[]> {
        return this._subjectAgentActiveObjects;
    }
}