export enum TmStatusEnum{

    draft,
    assigned,
    working,
    awaiting_customer,
    awaiting_insurance_company,
    resolved,
    on_hold,
    cancelled
   
   }