<ng-container *ngIf="initialized">


    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>

    <ng-container *ngIf="activeItem?.id == 'search'">
        <div class="grid">
            <p-button *ngIf="searchHidden" label="Show search" class="my-3" (click)="searchHidden=false;"></p-button>

            <div class="col-3 transition-col" *ngIf="!searchHidden" [class.hidden-selection]="hideSelection">
                <p-panel title="Start Indexing">

                    <form [formGroup]="formSearch" (ngSubmit)="onSubmit()">

                        <div class="flex flex-column gap-3">
                            <div class="flex flex-column gap-2">
                                <label for="requestedObjectTypeIds" class="text-500 text-sm">Index Object Types</label>
                                <p-multiSelect
                                        id="requestedObjectTypeIds"
                                        formControlName="requestedObjectTypeIds"
                                        [options]="objectTypes"
                                        optionLabel="alias"
                                        optionValue="id"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="splitResultsByType" class="text-500 text-sm">Split results by type (the max
                                    hit configuration will be applied for each type)</label>
                                <p-checkbox id="splitResultsByType" formControlName="splitResultsByType"
                                            [binary]="true"></p-checkbox>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="maxHits" class="text-500 text-sm">Max Hits</label>
                                <p-inputNumber type="number" id="maxHits" formControlName="maxHits"
                                               class="form-control"></p-inputNumber>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="minSearchRating" class="text-500 text-sm">Min search rating</label>
                                <p-multiSelect
                                        id="minSearchRating"
                                        formControlName="minSearchRating"
                                        [options]="ratings"
                                        optionLabel="label"
                                        optionValue="key"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="searchString" class="text-500 text-sm">Search String</label>
                                <input pInputText id="searchString" formControlName="searchString" class="form-control">
                            </div>

                            <div class="flex justify-content-between">
                                <p-button label="Hide" (click)="searchHidden=true"></p-button>
                                <p-button type="submit" label="Search"
                                          [disabled]="formSearch.status != 'VALID'"></p-button>
                            </div>

                        </div>


                    </form>

                </p-panel>
            </div>
            <div class="{{ searchHidden ? 'col-12' : 'col-8' }}  smooth-transition" *ngIf="searchResult"
                 [ngClass]="hideSelection ? 'md:col-12' : 'md:col-9'">
                <p-panel>
                    <div class="flex justify-content-between">
                        <p-button styleClass="p-button-text text-600" (click)="handleHideSection()"
                                  icon="pi pi-bars"></p-button>

                        <div class="flex align-items-center gap-4">

                            <p-chip styleClass="w-max p-1 border-round-xl pointer bg-primary-100 text-primary border-1 border-primary-500">
                                <i class="mx-1 pi pi-list"></i>
                                <span class="mx-1"> Result Count:</span>
                                <p-badge [value]="searchResult.resultCount" class="mx-1"/>
                            </p-chip>

                            <p-chip styleClass="w-max p-1 border-round-xl pointer bg-primary-100 text-primary border-1 border-primary-500">
                                <i class="mx-1 pi pi-exclamation-circle"></i>
                                <span class="mx-1"> Result Limited :</span>
                                <span class="mx-1 flex justify-content-center align-items-center bold" style="min-height: 21px">{{searchResult.resultLimited}}</span>
                            </p-chip>


                        </div>
                    </div>

                    <p-table [value]="searchResult.searchResultEntries" [tableStyle]="{ 'min-width': '50rem' }"
                             paginator="true"
                             [rows]="10"
                             [rowsPerPageOptions]="[10, 20, 50, 100]">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Object Type</th>
                                <th>ICON</th>
                                <th>ID</th>
                                <th>Rating</th>
                                <th>Result Name</th>
                                <th *ngFor="let column of columns" class="df-header">
                                    {{ column }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-entry>
                            <tr>
                                <td>{{ entry.objectTypeId }} {{ searchResult.objectTypes[entry.objectTypeId].alias }}</td>
                                <td><i class="{{ searchResult.objectTypes[entry.objectTypeId].icon }}"></i></td>
                                <td>{{ entry.objectId }}</td>
                                <td [ngStyle]="getRatingStyle(entry.rating)">{{ entry.rating }}</td>
                                <td>{{ entry.resultName }}</td>
                                <td *ngFor="let column of columns"
                                    [ngStyle]="getCellStyle(entry.dataFields[column])">
                                    <ng-container *ngIf="entry.dataFields[column]">
                                        <table class="df-inner-table">
                                            <tr class="flex flex-column">
                                                <td class="df-label">Token:</td>
                                                <td class="df-value">{{ entry.dataFields[column].originalValue }}</td>
                                            </tr>
                                            <tr *ngIf="entry.dataFields[column].rating" class="flex flex-column">
                                                <td class="df-label">Rating:</td>
                                                <td class="df-value">{{ entry.dataFields[column].rating }}</td>
                                            </tr>
                                            <tr *ngIf="entry.dataFields[column].matched" class="flex flex-column">
                                                <td class="df-label">Match:</td>
                                                <td class="df-value">{{ entry.dataFields[column].matched }}</td>
                                            </tr>
                                            <tr *ngIf="entry.dataFields[column].matchedOriginalValue" class="flex flex-column">
                                                <td class="df-label">Matched Value:</td>
                                                <td class="df-value">{{ entry.dataFields[column].matchedOriginalValue }}</td>
                                            </tr>
                                            <tr class="flex gap-1">
                                                <td class="df-label">Distance:</td>
                                                <td class="df-value">{{ entry.dataFields[column].distance }}</td>
                                            </tr>
                                        </table>

                                    </ng-container>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-panel>


            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="activeItem?.id == 'browseObjectType'">

        <div class="grid">
            <div class="col-12">
                <p-panel title="Object Type">
                    <form [formGroup]="formBrowseObjectType" (ngSubmit)="handleSubmitBrowseByObjectType()">
                        <div class="flex gap-3" style="align-items: end; height: 100%;">
                            <div class="flex flex-column gap-2 flex-grow-1">
                                <label for="browseObjectTypes" class="text-500 text-sm">Object Types</label>
                                <p-multiSelect
                                        id="browseObjectTypes"
                                        formControlName="requestedObjectTypeIds"
                                        [options]="objectTypes"
                                        optionLabel="alias"
                                        optionValue="id"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>
                            <div class="flex align-items-end justify-content-end">
                                <p-button type="submit" label="Search"
                                          [disabled]="formBrowseObjectType.status != 'VALID'"></p-button>
                            </div>
                        </div>
                    </form>
                </p-panel>
            </div>

            <div class="col-12">
                <mvs-widget *ngIf="widgetBrowseObjectTypeTokens"
                            [widgetData]="widgetBrowseObjectTypeTokens"></mvs-widget>
            </div>

            <div class="col-12">
                <mvs-widget *ngIf="widgetBrowseObjectTypeReferences"
                            [widgetData]="widgetBrowseObjectTypeReferences"></mvs-widget>
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="activeItem?.id == 'browseObject'">

        <div class="grid">
            <div class="col-12">

                <mvs-entity-search (onChange)="handleChange($event)"></mvs-entity-search>

            </div>

            <div class="col-4">
                <p-tree [value]="treeData" selectionMode="single" [(selection)]="selectedNode"
                        (onNodeSelect)="getIndexedReferenceIndexToken()">
                    <ng-template let-node pTemplate="default">
                        <span class="pi pi-folder" *ngIf="node.children && node.children.length"></span>
                        <span class="pi pi-file" *ngIf="!node.children || node.children.length === 0"></span>
                        {{ node.label }}
                    </ng-template>
                </p-tree>
            </div>

            <div class="col-8">

                <mvs-widget
                        *ngIf="widgetBrowseObjectReferenceTokens"
                        [widgetData]="widgetBrowseObjectReferenceTokens"
                ></mvs-widget>
            </div>


        </div>

    </ng-container>


</ng-container>









