export enum TeEntityStatusEnum{

    draft,
    pending,
    discarded,
    active,
    obsolete,
    deleted

}
