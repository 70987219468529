import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TmStatusEnum} from "../../../enum/tm-status.enum";
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService, ObjectIdentifierData,
    ObjectPageComponent, ObjectRequestList, ObjectRequestListAttribute,
    ObjectRequestListGroupBy, PagingDto,
    WidgetData
} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ticket-type-dashboard-page',
    templateUrl: './tm-ticket-type-dashboard.page.html',
    styleUrls: ['./tm-ticket-type-dashboard.page.scss']
})
export class TmTicketTypeDashboardPage extends ObjectPageComponent implements OnInit {


    widgetTicketStatus: WidgetData;
    widgetTicket: WidgetData;
    filterCriteria: FilterCriteria[] = [];

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();


    }

    /**
     * Overwritten method from Page.
     * @protected
     */
    protected preInit() {
        this.initialized = true;
    }


    protected getObjectType(): string {
        return 'tm.TicketType';
    }

    refreshComponent() {

        this.filterCriteria.push(
            FilterCriteria.create('type', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create('status', FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved)
        )

        this.refreshTicketStatus(this.filterCriteria);
        this.refreshTickets(this.filterCriteria);

        this.initialized = true;
    }

    refreshTicketStatus(filterCriteria: FilterCriteria[]) {
        this.widgetTicketStatus = new WidgetData();
        this.widgetTicketStatus.id = 1;
        this.widgetTicketStatus.idAlias = "tm.dashboard.tickets.status.distribution";
        this.widgetTicketStatus.name = "Status";
        this.widgetTicketStatus.uiComponent = "category";
        this.widgetTicketStatus.dataProvider = "list";
        this.widgetTicketStatus.dataSource = "entity.groupBy";
        this.widgetTicketStatus.dataProviderObject = "tm.Ticket";
        this.widgetTicketStatus.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            filterCriteria,
            [],
            ["status"],
            [new ObjectRequestListAttribute("status", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.widgetTicketStatus.setParamValue("fieldCategory", "status");
        this.widgetTicketStatus.setParamValue("fieldCategoryCount", "status_count");
        this.widgetTicketStatus.setParamValue("selectionMode", "single");
        this.widgetTicketStatus.setParamValue("size", "S");
    }

    refreshTickets(filterCriteria: FilterCriteria[]) {
        this.widgetTicket = new WidgetData();
        this.widgetTicket.idAlias = "tm.ticket.type.overview.show.tickets";
        this.widgetTicket.name = "Ticket";
        this.widgetTicket.uiComponent = "table";
        this.widgetTicket.dataProvider = "list";
        this.widgetTicket.dataSource = "entity";
        this.widgetTicket.dataProviderObject = "tm.Ticket";
        this.widgetTicket.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [],
            PagingDto.create(0, 10));

        this.widgetTicket.setParamValue("size", "S");
        this.widgetTicket.setParamValue("objectWidget", "data");
    }


    handleTicketStatusSelect(object: ObjectIdentifierData) {

        const filterCriteria = [];

        for (const item of this.filterCriteria) {
            filterCriteria.push(item);
        }

        filterCriteria.push(FilterCriteria.create('status', FilterCriteria.cOperatorEqual, object.objectId))
        this.refreshTickets(filterCriteria);

    }
}
