<ng-container *ngIf="initialized">

    <p-accordion [activeIndex]="[1]" [multiple]="true">
        <p-accordionTab>
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full font-bold">
                    Zuweisung
                </span>
            </ng-template>
            <mvs-widget *ngIf="customerInsurableObjectForm"
                        [importObjectContext]="importObjectContext"
                        [widgetData]="customerInsurableObjectForm">

            </mvs-widget>
        </p-accordionTab>

        <p-accordionTab>
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full font-bold">
                    Versichertes Objekt/ Risiko
                </span>
            </ng-template>
            <mvs-widget *ngIf="insurableObjectForm"
                        [widgetData]="insurableObjectForm"
                        [importObjectContext]="importObjectContext"
            >
            </mvs-widget>
        </p-accordionTab>
    </p-accordion>





</ng-container>