import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PeriodHelper} from "@kvers/alpha-core-common";


@Component({
  selector: 'wf-dashboard-overview',
  templateUrl: './wf-dashboard-overview.page.html',
})
export class WfDashboardOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

  defaultLabel: string = "Workflow";
  dashboardWidget: WidgetData;
  processWidgetCategory: WidgetData;
  processWidgetCategoryPeriod: WidgetData;
  filterCriteria: FilterCriteria[] = [];


  ngOnInit(): void {
    super.ngOnInit();
    this.initComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
    this.refreshComponent();
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.refreshWidgetCategory();
    this.refreshWidgetTable();
    this.refreshWidgetCategoryPeriod();
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  refreshWidgetTable(filterCriteria?: FilterCriteria[]) {
    this.dashboardWidget = WidgetFactory.createWidgetList(
        "wf.dashboard.all.workflows.table",
        "Workflows",
        "table",
        "list",
        "entity",
        "wf.WfProcess",
        "No Workflows found!",
        ObjectRequestList.createWithPaging(
            true,
            this.filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0,10)
        )
    );
  }

  refreshWidgetCategory() {
    this.processWidgetCategory = WidgetFactory.createWidgetList(
        'wf.dashboard.processes.category',
        'wf.WfProcess',
        'category',
        'list',
        'entity.groupBy',
        'wf.WfProcess',
        'No processes found',
        ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["type"],
            [ new ObjectRequestListAttribute("type", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),
        WidgetDataParam.create('fieldCategory','type'),
        WidgetDataParam.create('fieldCategoryCount','type_count'),
    )

  }

  refreshWidgetCategoryPeriod(filterCriteria? : FilterCriteria[]) {
    this.processWidgetCategoryPeriod = WidgetFactory.createWidgetList(
        'wf.dashboard.processes.category.period',
        'wf.WfProcess',
        'category.period',
        'list',
        'entity.groupBy',
        'wf.WfProcess',
        'No processes found',
        ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["type","createdDate"],
            [ new ObjectRequestListAttribute("createdDate", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),
        WidgetDataParam.create('fieldCategory','createdDate'),
        WidgetDataParam.create('fieldCategoryCount','createdDate_count'),
    )
  }

  handleNavigation(event: ObjectIdentifierData) {
    const processId = event.objectId;
    const url = '/#/wf/process/' + processId;
    window.open(url, "_blank");
  }

  handleProcess(event: ObjectIdentifierData){

    let filterCriteria = new Array<FilterCriteria>;
    filterCriteria.push(FilterCriteria.create('type',FilterCriteria.cOperatorEqual,event.objectId));
    this.refreshWidgetTable(filterCriteria);
    this.refreshWidgetCategoryPeriod(filterCriteria);
  }

  onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
    if (this.filterCriteria) {
      const found = this.filterCriteria.find(item => item.field == field);
      if (found) {
        return;
      }
    }
    const filterCriteria: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
    filterCriteria.label = objectData.data.label;
    this.filterCriteria.push(filterCriteria);
    this.initComponent();
  }

  onRemoveFilterCriteria(filter: FilterCriteria) {
    let index = this.filterCriteria.findIndex(item => item.field === filter.field);

    if (index !== -1) {
      this.filterCriteria.splice(index, 1);
      this.initComponent();
    }
  }

  handlePeriod(event) {
    const dates = PeriodHelper.getDateRangeForKey(event.objectId);
    const startDate = PeriodHelper.convertDateToApiDate(dates.startDate);
    const endDate = PeriodHelper.convertDateToApiDate(dates.endDate);
    const filterCriteria: FilterCriteria = FilterCriteria.create('createdDate', FilterCriteria.cOperatorBetween, startDate,endDate);
    filterCriteria.label = event.data.label;
    this.filterCriteria.push(filterCriteria);
    this.refreshWidgetTable();
  }

}
