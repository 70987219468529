<ng-container *ngIf="initialized">

    <ng-container *ngIf="customerUsersDtoList?.entries?.length">

        <p-tabView>
            <p-tabPanel header="User">

                <div class="grid">
                    <div class="col-4">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="customerUsersWidgetData"
                                    [widgetData]="customerUsersWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="customerSignaturesWidgetData"
                                    [widgetData]="customerSignaturesWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="customerUserDeviceWidgetData"
                                    [widgetData]="customerUserDeviceWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Registration">

                <div class="grid">
                    <div class="col-6">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="customerRegistrationWidgetData"
                                    [widgetData]="customerRegistrationWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="euUserWidgetData"
                                    [widgetData]="euUserWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Onboarding">

                <div class="grid">
                    <div class="col-12">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="customerOnboardingWidgetData"
                                    [widgetData]="customerOnboardingWidgetData">
                            </mvs-widget>
                        </div>
                    </div>
                </div>

            </p-tabPanel>

            <p-tabPanel header="Insurance">

                <div class="grid">
                    <div class="col-6">
                        <div class="h-full mvs-widget">
                            <mvs-widget
                                    *ngIf="userAddedInsurance"
                                    [widgetData]="userAddedInsurance"
                                    (onObjectSelect)="handleObjectSelect($event)"
                            >
                            </mvs-widget>
                        </div>
                    </div>
                    <div class="col-6">
                            <p-card *ngIf="userAddedInsuranceDocumentDtoList?.entries?.length">
                                <div class="grid">
                                    <section class="lg:col-3 col-12" *ngFor="let document of userAddedInsuranceDocumentDtoList?.entries">
                                        <div class="card px-2">
                                            <div class="flex justify-content-between p-2">
                                                <h5 class="pdf-card-title text-sm">{{document?.name }}</h5>
                                                <div class="flex gap-3">
                                                    <i
                                                            class="pi pi-window-maximize cursor"
                                                            (click)="onDocumentSelect(document)"
                                                    ></i>
                                                    <i
                                                            class="fa-regular fa-trash cursor"
                                                            (click)="onDocumentDelete(document.id, $event)"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="p-1">
                                                <mvs-pdf-viewer [objectId]="document.documentDtoId" class="w-full"></mvs-pdf-viewer>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </p-card>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>

    </ng-container>

    <ng-container *ngIf="!customerUsersDtoList?.entries?.length">
        <cr-create-user [customerId]="objectIdentifier.objectId" (onUserCreated)="initComponent()"></cr-create-user>
    </ng-container>


</ng-container>