import {Component,  OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";

interface NavigationEntries {
    label: string;
    url: string;
    icon?: string;
}

@Component({
    selector: 'eu-config-webapp',
    templateUrl: './eu-config-page-webapp.component.html',
})
export class EuConfigPageWebappComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {


    navigationEntries: NavigationEntries[];

    textWidget: WidgetData;
    colorWidget: WidgetData;
    ticketWidget: WidgetData;
    promotionsWidget: WidgetData;
    staticNotificationsWidget: WidgetData;
    staticNotificationsContractPersonWidget: WidgetData;
    staticNotificationsContractTypeWidget: WidgetData;
    staticNotificationsInsurableObjectTypeWidget: WidgetData;

    widgetEntries: TabEntries[] = [
        {id:'text', label:'Texte', type: ''},
        {id:'color', label:'Farben', type: ''},
        {id:'ticket', label:'Ticket Konfiguration', type: ''},
        {id:'staticNotifications', label:'Statische Benachrichtigungen', type: ''},
        {id:'promotion', label:'Promotion', type: ''}
    ];
    activeWidget: TabEntries;



    ngOnInit(): void {

        this.navigationEntries = [
            {label: 'Welcome', url: 'cr/customerWelcomes'},
            {label: 'Onboarding', url: 'cr/customerOnboardingTypes'},
            {label: 'Forms', url: 'cr/customerFormTypes'},
            {label: 'Signatures', url: 'cr/customerSignatureTypes'},
            {label: 'Interactions', url: 'cr/customerInteractionTypes'},
            {label: 'External Alias', url: 'cr/customerExternalAlias'},
        ];

        this.refreshTextWidget();
        this.refreshColorWidget();
        this.refreshTicketWidget();
        this.refreshPromotionWidget();
        this.refreshStaticNotificationWidget();

    }

    refreshTextWidget() {
        this.textWidget =  WidgetFactory.createWidgetList(
            "eu.config.text.widget.simple",
            'Texte',
            'table',
            'list',
            'entity',
            'eu.EuFrontEndText',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshColorWidget() {
        this.colorWidget =  WidgetFactory.createWidgetList(
            "eu.config.color.widget.simple",
            'Farben',
            'table',
            'list',
            'entity',
            'eu.EuThemeColor',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshTicketWidget() {
        this.ticketWidget =  WidgetFactory.createWidgetList(
            "eu.config.ticket.widget.simple",
            'Ticket Konfiguration',
            'list',
            'list',
            'entity',
            'eu.EuTmConfiguration',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshPromotionWidget() {
        this.promotionsWidget =  WidgetFactory.createWidgetList(
            "eu.config.promotion.widget.simple",
            'Promotions',
            'list',
            'list',
            'entity',
            'eu.EuPromotion',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshStaticNotificationWidget() {
        this.staticNotificationsWidget =  WidgetFactory.createWidgetList(
            "eu.config.static.notifications.widget.simple",
            'Statische Benachrichtigungen',
            'list',
            'list',
            'entity',
            'eu.EuStaticNotification',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    handleObjectSelect(event: ObjectIdentifierData) {
        const filterCriteria = FilterCriteria.create('staticNotification',FilterCriteria.cOperatorEqual, event.objectId);
        this.refreshNotificationContractPersonWidget(filterCriteria);
        this.refreshNotificationContractTypeWidget(filterCriteria);
        this.refreshNotificationInsurableObjectTypeWidget(filterCriteria);


    }

    refreshNotificationContractPersonWidget(filterCriteria: FilterCriteria) {
        this.staticNotificationsContractPersonWidget =  WidgetFactory.createWidgetList(
            "eu.config.static.notifications.contract.person.widget.simple",
            'Statische Benachrichtigungen - Nach Vertragspersonen',
            'list',
            'list',
            'entity',
            'eu.EuStaticNotificationContractPersonType',
            'No record found!',
            ObjectRequestList.createWithPaging(true, [filterCriteria], [], PagingDto.create(0, 20))
        );
    }

    refreshNotificationContractTypeWidget(filterCriteria: FilterCriteria) {
        this.staticNotificationsContractTypeWidget =  WidgetFactory.createWidgetList(
            "eu.config.static.notifications.contract.type.widget.simple",
            "Statische Benachrichtigungen - Nach Vertragstyp",
            'list',
            'list',
            'entity',
            'eu.EuStaticNotificationContractType',
            'No record found!',
            ObjectRequestList.createWithPaging(true, [filterCriteria], [], PagingDto.create(0, 20))
        );
    }

    refreshNotificationInsurableObjectTypeWidget(filterCriteria: FilterCriteria) {
        this.staticNotificationsInsurableObjectTypeWidget =  WidgetFactory.createWidgetList(
            "eu.config.static.notifications.insurable.object.widget.simple",
            "Statische Benachrichtigungen - Nach Versicherten Objekttyp",
            'list',
            'list',
            'entity',
            'eu.EuStaticNotificationInsurableObjectType',
            'No record found!',
            ObjectRequestList.createWithPaging(true, [filterCriteria], [], PagingDto.create(0, 20))
        );
    }

    handleTabSelection(item: TabEntries) {
        this.activeWidget = item;

        if (this.activeWidget.id == 'staticNotifications') {
            //refresh subwidgets on first load
            this.staticNotificationsContractPersonWidget = null;
            this.staticNotificationsContractTypeWidget = null;
            this.staticNotificationsInsurableObjectTypeWidget = null;
        }
    }


}
