import {Injectable} from '@angular/core';
import {DtoDetail, DtoList, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ActivityContextService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/activityContexts')
    }

    getActivityContextHistory(objectId: number, pageFrom: number = 0, pageSize: number = 10, objectTypeId?: number, objectTypeAlias?: string): Observable<any> {
        let url = `/query/history?objectId=${objectId}&pageFrom=${pageFrom}&pageSize=${pageSize}`;

        if (objectTypeId) {
            url += `&objectTypeId=${objectTypeId}`
        } else if (objectTypeAlias) {
            url += `&objectTypeAlias=${objectTypeAlias}`
        }

        return this.http.get(this.apiUrl + url);
    }
}