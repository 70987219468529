<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-12">
            <div class="flex justify-content-between">
                <h3>Appointment Dashboard</h3>
                <p-dropdown
                        [options]="periods"
                        [(ngModel)]="selectedPeriod"
                        optionLabel="name"
                        placeholder="Select a Period">
                </p-dropdown>
            </div>
        </div>

        <div class="col-12 xl:col-3" *ngFor="let item of items">
            <p-card>
                <ng-template pTemplate="content">
                    <p>{{ item.title }}</p>
                    <h3>{{ item.kpi }}</h3>
                </ng-template>
            </p-card>
        </div>
    </div>
</ng-container>