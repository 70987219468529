<div *ngIf="initialized">
    <mvs-config-header header="Kundenformulare"
                       icon="fa-sharp fa-thin fa-keyboard"
                       [links]="navigationEntries"
    >
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Gruppen" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Formulare" leftIcon="fa-thin fa-ballot-check"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-6">
                <mvs-widget
                        *ngIf="widgetCustomerFormGroup"
                        [widgetData]="widgetCustomerFormGroup"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="widgetCustomerFormType"
                        [widgetData]="widgetCustomerFormType"
                        (onObjectSelect)="onFormTypeObjectSelect($event)"
                ></mvs-widget>

            </div>
            <div class="col-4">

                <mvs-widget
                        *ngIf="widgetCustomerFormTypeField"
                        [widgetData]="widgetCustomerFormTypeField"
                ></mvs-widget>

            </div>
        </div>
    </ng-container>


</div>