import {AfterViewInit, Component, Input, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {ModuleRegistryService} from "../../../../../core/interface/module-registration.service";


@Component({
    selector: 'kpi-container-component',
    templateUrl: './kpi-container.component.html',
    styleUrls: ['./kpi-container.component.scss']
})
export class KpiContainerComponent implements AfterViewInit {

    @ViewChild('kpiComponentContainer', {read: ViewContainerRef, static: false})
    kpiComponentContainer!: ViewContainerRef;

    @Input() alias: string;

    initialized: boolean;

    constructor(
        private moduleRegistry: ModuleRegistryService,) {
    }

    ngAfterViewInit(): void {
        this.getComponents(this.alias);
    }

    getComponents(alias: string): void {
        const dynamicModule = this.moduleRegistry.getModule(alias);

        if (dynamicModule) {
            const kpiComponent = dynamicModule.getKpiComponent();
            this.kpiComponentContainer.clear();
            this.kpiComponentContainer.createComponent(kpiComponent);
        } else {
            console.error('No module found for alias:', alias);
        }
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.initialized) {
            this.getComponents(this.alias);
        }
    }

}
