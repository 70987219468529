import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    TicketTypeObjectComponent
} from "../../component/object-component/ticket-type-object-component/ticket-type-object.component";

@Injectable({
    providedIn: 'root'
})
export class TicketTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/tm/ticketTypes');
    }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {

        if (mode != MvsCrudModeEnum.create) {
            return TicketTypeObjectComponent;
        }
        return null
    }

}
