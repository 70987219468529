import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {DmConfigPage} from "./page/overview/dm-config/dm-config.page";
import {DmDashboardComponent} from "./component/dm-dashboard/dm-dashboard.component";
import {DmUploadComponent} from "./component/upload/dm-upload.component";
import {DmDocumentComponent} from "./component/dm-document/dm-document.component";
import {
  DmFormRecognizerDataComponent,
  FormRecognizerCanvasDirective
} from "./component/dm-form-recognizer-data/dm-form-recognizer-data.component";
import {DmDashboardOverviewPage} from "./page/overview/dm-dashboard-overview/dm-dashboard-overview.page";
import {DmDocumentPage} from "./page/overview/dm-document/dm-document.page";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ObjectDocumentComponent} from "./component/object-documents/object-documents.component";
import {
  DialogBoxObjectDocumentComponent
} from "./component/dialog-box-object-documents/dialog-box-object-documents.component";
import {DmDocumentPdfViewerComponent} from "./component/dm-document-pdf-viewer/dm-document-pdf-viewer.component";
import {
  DmDocumentUploadWfProcessStepActivityComponent
} from "./component/wf/dm-document-upload-wf-process-step-activity/dm-document-upload-wf-process-step-activity.component";
import {WfModule} from "../wf/wf.module";
import {DmDashboardActionsPage} from "./page/overview/dm-dashboard-actions-page/dm-dashboard-actions.page";
import {DmDocumentProcessingComponent} from "./component/dm-document-processing/dm-document-processing.component";
import {ContextMenuModule} from "primeng/contextmenu";
import { DmDocumentUploadComponent } from './component/dm-document-upload/dm-document-upload.component';
import { DmDocumentAnalyzeComponent } from './component/dm-document-analyze/dm-document-analyze.component';
import { DmDocumentDashboardComponent } from './component/dm-document-dashboard/dm-document-dashboard.component';
import {DocumentProcessActionsComponent} from "./component/document-process-actions/document-process-actions.component";
import {CrModule} from "../cr/cr.module";
import {DmOverviewPage} from "./page/dm-overview-page/dm-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {CoKpiComponent} from "../co/page/co-overview-page/components/kpiComponent/co-kpi.component";
import {
  CoStatisticsComponent
} from "../co/page/co-overview-page/components/statisticsComponent/co-statistics.component";
import {DmStatisticsComponent} from "./page/dm-overview-page/components/statisticsComponent/dm-statistics.component";
import {DmKpiComponent} from "./page/dm-overview-page/components/kpiComponent/dm-kpi.component";
import {UiModule} from "../ui/ui.module";
import {DmDocumentFullComponent} from "./component/dm-document/view/dm-document-full/dm-document-full.component";
import {DmDocumentSideComponent} from "./component/dm-document/view/dm-document-side/dm-document-side.component";
import {DmDocumentBaseComponent} from "./component/dm-document/view/dm-document-base.component";


@NgModule({
  declarations: [
    DmConfigPage,
    DmDashboardComponent,
    DmUploadComponent,
    DmDocumentComponent,
    DmFormRecognizerDataComponent,
    DmDashboardOverviewPage,
    FormRecognizerCanvasDirective,
    DmDocumentPage,
    ObjectDocumentComponent,
    DialogBoxObjectDocumentComponent,
    DmDocumentPdfViewerComponent,
    DmDocumentUploadWfProcessStepActivityComponent,
    DmDashboardActionsPage,
    DmDocumentProcessingComponent,
    DmDocumentUploadComponent,
    DmDocumentAnalyzeComponent,
    DmDocumentDashboardComponent,
    DocumentProcessActionsComponent,
    DmOverviewPage,
    DmKpiComponent,
    DmStatisticsComponent,
    DmDocumentFullComponent,
    DmDocumentSideComponent,
    DmDocumentBaseComponent,
  ],
  exports:[
    DmConfigPage,
    DmDashboardComponent,
    DmUploadComponent,
    DmDocumentComponent,
    DmFormRecognizerDataComponent,
    DmDashboardOverviewPage,
    FormRecognizerCanvasDirective,
    DmDocumentPage,
    ObjectDocumentComponent,
    DialogBoxObjectDocumentComponent,
    DmDocumentPdfViewerComponent,
    DmDocumentUploadWfProcessStepActivityComponent,
    DmDashboardActionsPage,
    DmDocumentProcessingComponent,
    DocumentProcessActionsComponent,
    DmDocumentFullComponent,
    DmDocumentSideComponent,
    DmDocumentBaseComponent,
  ],
  imports: [
    CoreModule,
    WfModule,
    ProgressSpinnerModule,
    ContextMenuModule,
    UiModule,
  ]
})
export class DmModule implements DynamicModule {

  alias = 'dm';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return DmKpiComponent;
  }

  getStatisticsComponent() {
    return DmStatisticsComponent;
  }
}

