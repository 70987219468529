@if (activeNavigationItem?.action == 'jb.JbJobRun') {

    <mvs-widget
            *ngIf="jobRunDataWidget"
            [widgetData]="jobRunDataWidget"
    ></mvs-widget>
}


@if (activeNavigationItem?.action == 'jb.JbJobRunEntryLog') {

    <mvs-widget
            *ngIf="jobRunLogEntryTableWidget"
            [widgetData]="jobRunLogEntryTableWidget"
    ></mvs-widget>
}
