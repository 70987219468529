import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AsFreeSlotComponent} from "../../component/as-free-slot/as-free-slot.component";

@Injectable({
  providedIn: 'root'
})
export class MsCalendarTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/as/msCalendarTypes')
  }

  // getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
  //   return AsFreeSlotComponent;
  // }

  /**
   * Get available free slots (formatted to CET LocalDateTime).
   */
  getFreeSlots(agentIds: number[], from?: Date, to?: Date): Observable<any> {
    const url = `${this.apiUrl}/freeslot`;

    let params = new HttpParams();

    // Append agentIds
    agentIds.forEach((id) => {
      params = params.append('agentIds', id.toString());
    });

    // Create new UTC dates with the same hours/minutes as the local dates
    // const startUtc = this.createUtcDateWithSameHours(from);
    // const endUtc = this.createUtcDateWithSameHours(to);


    // if (startUtc) params = params.set('from', startUtc);
    // if (endUtc) params = params.set('to', endUtc);



    // Format Date to CET LocalDateTime (YYYY-MM-DDTHH:mm:ss)
    if (from) params = params.set('from', this.formatToCET(from));
    if (to) params = params.set('to', this.formatToCET(to));

    return this.http.get<any>(url, { params });
  }

  /**
   * Creates a UTC date string with the same hours/minutes as the local date
   */
  private createUtcDateWithSameHours(localDate: Date): string {
    // Create a new Date using UTC with the same year, month, day, hour, minute, second
    const utcDate = new Date(Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes(),
        localDate.getSeconds()
    ));

    // Return as ISO string
    return utcDate.toISOString();
  }

  /**
   * Converts a Date object to CET LocalDateTime string (YYYY-MM-DDTHH:mm:ss).
   */
  private formatToCET(date: Date): string {
    const offset = 1; // CET is UTC+1 in standard time (adjust for DST if needed)
    const cetDate = new Date(date.getTime() + offset * 60 * 60 * 1000);

    return cetDate.toISOString().slice(0, 19).replace('T', 'T'); // Removes milliseconds & Z
  }
}