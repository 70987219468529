<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="dm.DmDocumentType"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--&gt;-->
<!--</mvs-crud-page>-->

<div class="bg-white mt-5" *ngIf="initialized">
    <mvs-config-header header="Document Configuration" [categoryTypeMode]="'document'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of uiWidgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2">
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'dm.DmDocumentStorage'">
            <mvs-widget *ngIf="dmDocumentStorageWidget"
                        [widgetData]="dmDocumentStorageWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'dm.DmFormRecognizerType'">
            <mvs-widget *ngIf="dmFormRecognizerTypeWidget"
                        [widgetData]="dmFormRecognizerTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'dm.DmMimeType'">
            <mvs-widget *ngIf="dmMimeTypeWidget"
                        [widgetData]="dmMimeTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'dm.DmDashboard'">
            <mvs-widget *ngIf="dmDashboardWidget"
                        [widgetData]="dmDashboardWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'dm.DmAssignmentType'">
            <mvs-widget *ngIf="dmAssignmentTypeWidget"
                        [widgetData]="dmAssignmentTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'dm.DmAssignmentTypeObjectType'">
            <mvs-widget *ngIf="dmAssignmentTypeObjectTypeWidget"
                        [widgetData]="dmAssignmentTypeObjectTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="activeNavItem == 'documentType'">

        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="dmDocumentGroupWidget"
                                [widgetData]="dmDocumentGroupWidget"
                                (onObjectSelect)="handleDocumentGroupSelect($event)">
                    </mvs-widget>
                </div>

            </div>

            <div class="col-9">
                <div class="flex flex-column gap-2 shadow-1">
                    <mvs-widget *ngIf="dmDocumentTypeWidget"
                                [widgetData]="dmDocumentTypeWidget"
                                (onObjectSelect)="handleDocumentTypeSelect($event)">
                    </mvs-widget>
                    <mvs-widget *ngIf="dmDocumentTypeMimeTypeWidget"
                                [widgetData]="dmDocumentTypeMimeTypeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>
                </div>
            </div>
        </div>

    </ng-container>

</div>
