import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, Input, SimpleChanges} from "@angular/core";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {CustomerRelatedPersonDto} from "../../../dto/customer-related-person.dto";
import {CustomerDto} from "../../../dto/customer.dto";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../dto/customer-contract.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ContractDto} from "../../../../cm/dto/contract.dto";
import {HouseholdPersonDto} from "../../../../pm/dto/household-person.dto";

@Component({
    selector: 'mvs-customer-object-base-component',
    template: 'Base Component'
})
export class ObjectSubBaseComponent
    implements OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() changedFilter: FilterCriteria;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() dto: CustomerDto & ContractDto;
    @Input() householdPersons: HouseholdPersonDto[];

    @Output() onChangeCustomerFilter: EventEmitter<FilterCriteria[]> = new EventEmitter<FilterCriteria[]>();

    contractBottomBar: boolean = false;
    selectedContract: DtoDetail;
    selectedRelatedPersons: CrCustomerRelatedPersonWidgetStyle;
    selectedItem: DtoDetail;
    // selectedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    // filterCriteria: FilterCriteria[] = [];

    initialized: boolean = false;
    busy: boolean = false;

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected configService: MvsConfigService,
        protected navigationService: MvsObjectNavigationService
    ) {
    }


    refreshComponent() {

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["dto"] || changes["objectIdentifier"]) {
            this.refreshComponent();
        }

    }


    handleViewContract(object: ObjectIdentifierData, id) {

        this.contractBottomBar = true;
        this.selectedContract = object.data;


        if (this.selectedRelatedPersons) {
            this.selectedRelatedPersons.setSelectedPerson(this.selectedContract['customerDtoId']);
        }

        this.selectedItem = object.data;

        // this.scrollToDiv(id);
        let doc = document.querySelector(id)
        if (doc) {
            doc.classList.add('scroll-margin');
            doc.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }

    // onRelatedPersonSelect(object: ObjectIdentifierData){
    //
    //     const person: CustomerRelatedPersonDto = <CustomerRelatedPersonDto>object.data;
    //
    //     person['__selected'] = !person['__selected'];
    //
    //     const filterId = person.personDtoName + ' (' + person.relatedPersonTypeDtoName + ')';
    //
    //     this.setFilterCriteria(object, filterId);
    //
    //     const customerId = person.personDto?.customerDto?.id;
    //
    //     if(!customerId){
    //         return;
    //     }
    //
    //     //create new instance if we don't have one
    //     if (!this.selectedPersonWidgetStyle) {
    //         const customerDto: CustomerDto = new CustomerDto();
    //         customerDto.id = this.objectIdentifier.objectId;
    //
    //         this.selectedPersonWidgetStyle = new CrCustomerRelatedPersonWidgetStyle(this.configService, customerDto, []);
    //     }
    //
    //     if (person['__selected'] && customerId) {
    //         this.selectedPersonWidgetStyle.addRelatedPerson(person,customerId);
    //     } else {
    //         this.selectedPersonWidgetStyle.removeRelatedPerson(person);
    //     }
    //
    //     this.selectedPersonWidgetStyle = this.selectedPersonWidgetStyle.clone();
    //
    //     person['__style'] = this.selectedPersonWidgetStyle.getStyleObjectForAvatar(person);
    //
    //     this.handleRelatedPersonSelectionChange(this.selectedPersonWidgetStyle);
    //
    // }
    //
    // handleRelatedPersonSelectionChange(event: CrCustomerRelatedPersonWidgetStyle){
    //     this.selectedRelatedPersons = event;
    //     this.showAllCustomerContracts();
    //     this.showCustomerInsurableObjects();
    // }
    //
    // showAllCustomerContracts() {
    //
    // }
    //
    // showCustomerInsurableObjects() {
    //
    // }
    //
    // setFilterCriteria(objectData: ObjectIdentifierData, field: string) {
    //
    //     if (this.filterCriteria) {
    //         const found = this.filterCriteria.find(res => res.field == field);
    //
    //         if (found) {
    //             const index = this.filterCriteria.findIndex(value => value.field == found.field);
    //             this.filterCriteria.splice(index,1);
    //             this.onChangeCustomerFilter.emit(this.filterCriteria);
    //             return;
    //         }
    //     }
    //
    //     const filterCriteria: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
    //     filterCriteria.label = objectData.data.label;
    //     this.filterCriteria.push(filterCriteria);
    //
    //     this.onChangeCustomerFilter.emit(this.filterCriteria);
    //
    // }

    handleRowSelection(object: ObjectIdentifierData, id) {
        // if (object.event == 'singleClick') {
        //     this.handleViewContract(object, id);
        // } else if (object.event == 'middleClick' || object.event == 'middleClickCtrl' || object.event == 'singleClickCtrl') {
        //     this.handleContractMiddleClick(object);
        // }
    }

    handleContractMiddleClick(event: ObjectIdentifierData, queryParam?: string) {
        const contractDto = event.data;
        this.navigateToNewTab(contractDto, queryParam);
    }

    navigateToNewTab(object: CustomerContractDto, queryParam?: string) {
        const objectIdentifier = new ObjectIdentifier('cm.Contract', object.contractDtoId);
        this.navigationService.handleObjectNavigation(objectIdentifier, null, {
            openNewTab: true,
            queryParam: queryParam
        });
    }

    handleContractsDtoList(object: { dtoList: DtoList, objectIdentifierData: ObjectIdentifierData }, id: string) {

        if (object.objectIdentifierData.event == 'singleClick') {
            this.handleViewContract(object.objectIdentifierData, id);
        } else if (object.objectIdentifierData.event == 'middleClick' || object.objectIdentifierData.event == 'middleClickCtrl' || object.objectIdentifierData.event == 'singleClickCtrl') {

            const ids = object.dtoList.entries.map((obj: CustomerContractDto) => obj.contractDtoId);
            const contractIds = ids.join(',');
            const queryParam = `?contracts=${contractIds}`;
            this.handleContractMiddleClick(object.objectIdentifierData, queryParam);
        }

    }


}