<div class="box"
     [class.top-border]="showTopBorder"
     [class.bottom-border]="showBottomBorder"
     [class.left-border]="showLeftBorder"
     [class.right-border]="showRightBorder">
    <div class="content-wrapper">
        <div class="content">
            <mvs-hierarchy-entry-content-component></mvs-hierarchy-entry-content-component>
        </div>
    </div>

    <!-- Conditional lines -->
    <div class="line-top" [class.active]="showTopLine"></div>
    <div class="line-bottom" [class.active]="showBottomLine"></div>
    <div class="line-left" [class.active]="showLeftLine"></div>
    <div class="line-right" [class.active]="showRightLine"></div>

    <!-- Intersection lines -->
    <div class="line-top-right" [class.active]="showTopRightLine"></div>
    <div class="line-bottom-right" [class.active]="showBottomRightLine"></div>
    <div class="line-top-left" [class.active]="showTopLeftLine"></div>
    <div class="line-bottom-left" [class.active]="showBottomLeftLine"></div>

    <!-- Conditional half borders -->
    <div class="half-border-top-bottom top-right" [class.active]="showTopRightHalfBorder"></div>
    <div class="half-border-top-bottom top-left" [class.active]="showTopLeftHalfBorder"></div>

    <div class="half-border-top-bottom bottom-right" [class.active]="showBottomRightHalfBorder"></div>
    <div class="half-border-top-bottom bottom-left" [class.active]="showBottomLeftHalfBorder"></div>

    <div class="half-border-left-right right-top" [class.active]="showRightTopHalfBorder"></div>
    <div class="half-border-left-right right-bottom" [class.active]="showRightBottomHalfBorder"></div>

    <div class="half-border-left-right left-top" [class.active]="showLeftTopHalfBorder"></div>
    <div class="half-border-left-right left-bottom" [class.active]="showLeftBottomHalfBorder"></div>

</div>
