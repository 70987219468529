import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Subscription, timer} from "rxjs";
import {MvsSearchFlexibleBaseComponent} from "@kvers/alpha-ui";
import {MvsSearchFlexibleResultData} from "@kvers/alpha-ui";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'am-search-flexible-agent',
    templateUrl: './am-search-flexible-agent.component.html',
})
export class AmSearchFlexibleAgentComponent
    extends MvsSearchFlexibleBaseComponent
    implements OnInit {


    name: string;
    widgetTableViaBasicData: WidgetData;
    subscription: Subscription;
    searchForm: FormGroup;

    constructor(private fb: FormBuilder) {
        super();
        this.searchForm = this.fb.group({
            name: ['']
        });
    }

    ngOnInit() {

    }


    /**
     * search Via Contract (eg: alias, contractIdentifier)
     */
    onSearchViaName() {
        const formValues = this.searchForm.value;
        if (!formValues.name) {
            return;
        }

        const filterCriteria: FilterCriteria[] = [];

        if (formValues.name) {
            filterCriteria.push(
                FilterCriteria.create('name', FilterCriteria.cOperatorContainsPattern, '%' + formValues.name + '%', null)
            );
        }

        this.onSearchAction.emit(formValues);

        this.loadTableViaName(null, filterCriteria);
    }

    /**
     * method to return the widget table instance
     * @param dataProviderObject
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTable(dataProviderObject: string, complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]): WidgetData {

        const widgetData = new WidgetData();
        widgetData.idAlias = 'am.search.flexible.agents.' + dataProviderObject;
        widgetData.name = 'Agents';
        widgetData.uiComponent = 'table';
        widgetData.dataProvider = 'list';
        widgetData.dataSource = 'entity';
        widgetData.dataProviderObject = dataProviderObject;

        widgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(true, filterCriteria, [new Sorting('createdDate', false)], PagingDto.create(0, 8));
        widgetData.dataProviderListRequest.objectRequestComplex = complexSelection;
        widgetData.setParamValue("selectionMode", "rowSelect");
        widgetData.setParamValue("rowSelectionMode", "sticky");
        widgetData.setParamValue('size', 'S');

        return widgetData;
    }

    /**
     * method to load Contracts info using contact basic data
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaName(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaBasicData = this.loadTable('am.Agent', complexSelection, filterCriteria);
    }

    /**
     * trigger the event when object is selected
     * @param event
     */
    handleObjectSelect(event: ObjectIdentifierData) {

        const result: MvsSearchFlexibleResultData =
            MvsSearchFlexibleResultData.create(event, event.data["name"]);

        this.onObjectSelect.emit(result);

    }

    onInputFieldDetails() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = timer(1000).subscribe(() => {

            this.onSearchViaName();
            this.subscription = null;
        });
    }

}
