import {Component, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {DashboardDto} from "../../dto/dashboard.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {UiDashboardService} from "../../../../ui/service/api/ui-dashboard.service";

@Component({
  selector: 'mvs-dashboard-page',
  templateUrl: './core-dashboard.component.html',
  styleUrls: ['./core-dashboard.component.scss']
})
export class CoreDashboardComponent extends PageComponent {

  id: number; // Dashboard ID

  dashboardDto: DashboardDto;
  crudDashboardService: UiDashboardService;


  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
  ) {
    super(route, coreService);
  }


  /**
   * Initialize component.
   */
  ngOnInit() {
    super.ngOnInit();
  }


  protected preInit() {
    super.preInit();

    // retrieve
    this.crudDashboardService = <UiDashboardService>this.coreService.getCrudService("ui.UiDashboard");

  }

  /**
   * Check whether a parameter changed.
   * @param params
   * @protected
   */
  protected processParams(params: any) {

    if (params["id"]) {

      // the ID might have changed
      if (!this.id || this.id != params["id"]) {
        this.id = params["id"];
        this.refreshComponent();
      }

    }

  }

  /**
   * Refresh Component. Load Dashboard from anew, if the ID changed.
    * @protected
   */
  protected refreshComponent() {

    if (this.id) {
      this.busy = true;

      // retrieve Dashboard information
      this.crudDashboardService.get(this.id).subscribe(value => {
        this.dashboardDto = <DashboardDto>value;

        // perform UI calculations (if needed)
        this.refreshComponentUi();

      },error => {

        // an error occured
        this.initialized = false;
        this.busy = false;
      });

    } else {
      this.initialized = false;
    }

  }

  /**
   * Refresh Component UI.
   * @protected
   */
  protected refreshComponentUi() {


    this.busy = false;
    this.initialized = true; // in case it's not already true
  }


}
