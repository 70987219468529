<div class="bg-white">
    <mvs-config-header header="Logic Configuration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidgets()">
        <p-tabPanel header="Groups" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Logics" leftIcon="fa-solid fa-diagram-venn"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="lgGroupWidget"
                        [widgetData]="lgGroupWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="lgLogicWidget"
                        [widgetData]="lgLogicWidget"
                        (onObjectSelect)="handleLogicSelect($event)"
                ></mvs-widget>
            </div>

            <div class="col-8">
                <mvs-widget
                        *ngIf="lgLogicImportWidget"
                        [widgetData]="lgLogicImportWidget"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="lgLogicExportWidget"
                        [widgetData]="lgLogicExportWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


</div>