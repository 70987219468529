@if (initialized && dto) {

    @if (uiMode == 'side') {
        <mvs-lg-logic-object-component-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></mvs-lg-logic-object-component-side>
    } @else {
        <mvs-lg-logic-object-component-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
                (onBreadcrumbItems)="handleBreadcrumbItems($event)"
        ></mvs-lg-logic-object-component-full>
    }
}
