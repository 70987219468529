
<p-toolbar>

    <div class="p-toolbar-group-left">

        <p-progressSpinner *ngIf="blockedDocument" [style]="{width: '25px', height: '25px'}" styleClass="custom-spinner"
            strokeWidth="8" fill="var(--surface-ground)"></p-progressSpinner>

        <p-toggleButton [(ngModel)]="showWidgetReportData" onLabel="Report Daten" onIcon="fa-sharp fa-solid fa-eye" class="mx-2"
            offIcon="fa-sharp fa-solid fa-eye-slash" offLabel="Report Daten"
            (click)="handleToggleReportData()"></p-toggleButton>

        <p-toggleButton onLabel="Testen" onIcon="fa-sharp fa-solid fa-eye" offIcon="fa-sharp fa-solid fa-eye-slash"
            offLabel="Testen" (click)="checkTest()" class="mx-2"></p-toggleButton>

        <p-toggleButton onLabel="Ausgabe (Attribute)" onIcon="fa-sharp fa-solid fa-eye"
            offIcon="fa-sharp fa-solid fa-eye-slash" offLabel="Ausgabe (Attribute)"
            (click)="onGetAttributes()" class="mx-2"></p-toggleButton>

    </div>

    <div class="p-toolbar-group-right">

    </div>
</p-toolbar>

<div class="grid" *ngIf="showWidgetReportData && widgetReport">
    <div class="col-12">
        <mvs-widget [widgetData]="widgetReport"></mvs-widget>
    </div>
</div>

<div class="" style="display: flex;">

    <div class="p-5 col-lg-4 col-5 fadein animation-duration-1000 sidebar-width" >
    <div class="card sticky-sidebar" *ngIf="showSidebar">
       
       <div class="entity-detail-sidebar-header">
        <h4 class="word-break">
            {{selectedUiReportObject.reportObject.name}}
        </h4> 
          <span class="text-right cursor" (click)="showSidebar = false"><i class="fa fa-times"></i></span>
       </div>
     
        <p-tabView (activeIndexChange)="handleLoadSideBarMetaInfo($event)">

            <p-tabPanel header="Basic">
               <div class="name-edit"  *ngIf=" !selectedUiReportObject.reportObject.edit">
                 {{selectedUiReportObject.reportObject.name}}    
                  <span class="cursor" (click)="selectedUiReportObject.reportObject.edit = !selectedUiReportObject.reportObject.edit" >
                    <i class="fa fa-edit grow"></i>
                 </span>
                 </div>


                    <!-- name input field -->
                    <div class="name-edit" *ngIf="selectedUiReportObject.reportObject?.edit == true">
                        <input
                        style="word-break: break-word; width:85%;" class="text-sm"
                        type="text" pInputText [(ngModel)]="selectedUiReportObject.reportObject.name" />

                        <span class="cursor" (click)="onEditReportObjectName(selectedUiReportObject.reportObject)"><i class="fa fa-check mx-3 grow"></i></span>
                    </div>


                            <div class="report-sidebar-button-row">
                               
                            <button   pButton
                            class="attribute-button mr-2 text-sm pointer" type="button" label="Sort" icon="pi pi-bars">
                        </button>

                        <button   pButton label="Filter"
                        class="attribute-button mr-2 text-sm pointer" type="button" icon="pi pi-filter">
                    </button>
                            </div>
                                     <!-- box filter -->
                    <!-- <button pTooltip="filter" *ngIf="column.node.reportObject" pButton class="n-filter text-sm pointer"
                        [ngClass]="column.node.reportObject.reportFilterDtoId ? 'blue-btn' : '' " type="button"
                        (click)="onShowFilter(column.node.reportObject)" icon="pi pi-filter"></button> -->

                    <!-- box sorting -->
                    <!-- <button pTooltip="sort" *ngIf="column.node.reportObject"
                        [ngClass]="column.node.reportObject.reportSortingDtoId ? 'blue-btn' : '' "
                        (click)="onShowSortings(column.node.reportObject, true)" pButton
                        class="sort-icon text-sm pointer" type="button" icon="pi pi-bars">
                    </button> -->


                        <!-- attribute button -->
                    <!-- <button *ngIf="column.node.reportObject" pButton class="attribute-btn text-sm"  type="button"
                        label="Attributes" (click)="onGetMetaInfo(column.node,'attributes')">
                    </button> -->
             
        
            </p-tabPanel>

            <p-tabPanel header="Links">
                <div class="mt-3">
                
                 <p class="relation-title">Relation:  <span class="mx-3 text-black">1:N</span></p>
                

                 <div class="button-row">

                    <button pButton
                    class="attribute-button mr-2 text-sm pointer" type="button" label="Link" icon="pi pi-link">
                </button>
        
                <button   pButton label="D-Link"
                class="attribute-button mr-2 text-sm pointer" type="button" icon="fa-regular fa-link-slash">
            </button>
                    
                    <button pButton
                    class="attribute-button mr-2 text-sm pointer" type="button" label="Sort" icon="pi pi-bars">
                </button>

                <button   pButton label="Filter"
                class="attribute-button mr-2 text-sm pointer" type="button" icon="pi pi-filter">
            </button>

    
                    </div>

                  </div>
             </p-tabPanel>

            <p-tabPanel header="Attributes">
                <p-table [value]="attributes" selectionMode="single" class="overlay-custom" [(selection)]="selectedAttribute"
                (onRowSelect)="onAddAttributestoField($event)" styleClass="height-overlay">
                <ng-template pTemplate="header">
                    <tr class="text-sm">
                        <th>Attribute Name</th>
                        <th>Data Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-attribute>
                    <tr class="text-sm" [pSelectableRow]="rowData"
                        *ngIf="attribute.attributeName != null && attribute.attributeName != '' ">
                        <td>
                            <i [mvsDataTypeIcons]="attribute.icon"> </i>
                            {{attribute.attributeName}}
                        </td>
                        <td>{{attribute.dataType}}</td>
                    </tr>
                </ng-template>
            </p-table>
            </p-tabPanel>


            <p-tabPanel header="Joins">
             
                <!-- fadeinleft -->
                    <p-table class="scalein animation-duration-500" [value]="products" selectionMode="single" *ngIf="!selectedProduct" [(selection)]="selectedProduct"
                    (onRowSelect)="checkIfGeneric()" [paginator]="true" [rows]="7">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Entity</th>
                            <th>Join</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-product>
                        <tr [pSelectableRow]="rowData" *ngIf="product.name != null && product.name != '' ">
                            <td>{{product.name}}</td>
                            <td>{{product.joinRelationEnum}}</td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- fadeinleft -->
                <div class="mt-4 scalein animation-duration-500" *ngIf="selectedProduct">
             

<div class="mb-3"><i (click)="onResetJoins()" class="fa fa-arrow-left cursor"></i></div>

                    <ng-container>
                        <table>
                            <tr>
                                <th>{{selectedProduct.name}}</th>
                                <th>({{selectedProduct.joinRelationEnum}})</th>
                            </tr>
                        </table>
                    </ng-container>
                    <!-- (onRowSelect)="onLinkEntity($event)" -->
                    <ng-container >
                        <p-table [value]="linkJoin" selectionMode="single" [(selection)]="selectedLinkJoin">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Join Type&nbsp;
                                        <i class="pi pi-info-circle pointer" tooltipPosition="right"
                                            pTooltip="Choose the link join (Main/Inner or Side/Outer)"></i>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-join>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{join.joinName}}</td>
                                </tr>
                            </ng-template>
                
                        </p-table>
                        <ng-container *ngIf="displayGenericTypeModal">
                            <p-table class="p-3" [value]="genericTypes" selectionMode="single" [(selection)]="selectedGenericType"
                                (onRowSelect)="reportName = selectedGenericType.typeName">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Generic Types&nbsp;
                                            <i class="pi pi-info-circle pointer" tooltipPosition="right"
                                                pTooltip="Choose the generic type"></i>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-generic>
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{generic.typeName}}</td>
                                    </tr>
                                </ng-template>
                
                            </p-table>
                        </ng-container>
                
                        <ng-container
                            *ngIf="selectedProduct.joinRelationEnum == 'oneToMany' || selectedProduct.joinRelationEnum == 'manyToOne'">
                            <div class="p-2">
                                <p class="p-2">Do you want to link all records?</p>
                                <p-inputSwitch class="p-2" [(ngModel)]="isMultiple"></p-inputSwitch>
                            </div>
                        </ng-container>
                
                        <ng-container
                            *ngIf="selectedProduct.joinRelationEnum == 'oneToMany' || selectedProduct.joinRelationEnum == 'manyToOne'">
                            <p-table [value]="linkType" selectionMode="single" [(selection)]="selectedLinkType"
                                (onRowSelect)="updateLinkRecords()">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Link Type</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-link>
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{link.linkName}}</td>
                                    </tr>
                                </ng-template>
                
                            </p-table>
                        </ng-container>
                
                        <div class="p-3">
                            <span class="font-bold">Name</span> : <input class="text-sm" type="text" pInputText
                                [(ngModel)]="reportName" />
                        </div>
                
                    </ng-container>
                
                    <ng-container >
                        <button pButton type="button" *ngIf="selectedLinkJoin && reportName
                     && (!selectedProduct.genericType || (selectedProduct.genericType && selectedGenericType))
                     && (selectedProduct.joinRelationEnum == 'oneToOne' || this.selectedLinkType)" label="Create"
                            class="p-button-secondary bg-gray" (click)="onLinkEntity()"></button>
                    </ng-container>


                </div>
              
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

    <div class="main-entity-section">

        <p-skeleton width="10rem" styleClass="mb-2" *ngIf="!rows"></p-skeleton>

<section class="p-5" *ngIf="node">
    <div *ngFor="let row of rows" class="inline-flex flex-nowrap card-container blue-container p-4 text-sm">
        <ng-container *ngFor="let column of row.columns" class="p-3" style="display: flex"> 
            <ng-container *ngIf="column.node">
                <div class="boxItem grow"
                (click)="handleSetSidebar(column.node)"
                [class.active-box]="selectedUiReportObject?.reportObject?.id == column.node.reportObject.id"
                [ngClass]="!column.node.reportObject ? 'boxItemEmpty' : '' "
                    *ngIf="column.type == 'reportObject'" style="position: relative">
                 

                    <i *ngIf="selectedUiReportObject?.reportObject?.id == column.node.reportObject.id" class="fa fa-eye view-pos"></i>

                    <!-- <u class="view-pos text-sm"
                    (click)="handleSetSidebar(column.node)"> view</u> -->
                    <!-- entity name nd input -->

                    <ng-container pTooltip="Name" *ngIf="column.node.reportObject">
                        <!-- (dblclick)="column.node.reportObject.edit = !column.node.reportObject.edit" -->
                        <span class="report-name">{{column.node.reportObject.name}}</span>

                        <p>{{column.node.peerLevel}}--{{column.node.level}}</p>


                            <!-- name input field -->
                        <!-- <input *ngIf="column.node.reportObject?.edit == true"
                            (blur)="onEditReportObjectName(column.node.reportObject)"
                            style="word-break: break-word; width:85%;margin-left: 7%;height:44%" class="text-sm"
                            type="text" pInputText [(ngModel)]="column.node.reportObject.name" /> -->
                    </ng-container>

                    <!--  Add Start Object -->
                    <!-- <ng-container *ngIf="!column.node.reportObject">
                        <span class="pointer grow" (click)="onViewAllObjectTypes()">
                            Add Start Object &nbsp; <i class="pi pi-plus"></i>
                        </span>
                    </ng-container> -->

                    <!--entity start icon -->
                    <!-- <button pTooltip="starting point" *ngIf="column.node.reportObject && column.node.startObject" disabled pButton
                        class="start-btn" type="button">
                        <span class="inner-circle"></span>
                    </button> -->

                    <!-- d-link -->
                    <!-- <button pTooltip="delete" *ngIf="!column.node.startObject" pButton pRipple type="button"
                        icon="fa-regular fa-link-slash" class="p-button-rounded delete-btn text-size"
                        (click)="onConfirmDeleteLink($event,column.node.reportObject.id, column.node.reportLink?.id)"></button> -->


                    <!-- box filter -->
                    <!-- <button pTooltip="filter" *ngIf="column.node.reportObject" pButton class="n-filter text-sm pointer"
                        [ngClass]="column.node.reportObject.reportFilterDtoId ? 'blue-btn' : '' " type="button"
                        (click)="onShowFilter(column.node.reportObject)" icon="pi pi-filter"></button> -->

                    <!-- box sorting -->
                    <!-- <button pTooltip="sort" *ngIf="column.node.reportObject"
                        [ngClass]="column.node.reportObject.reportSortingDtoId ? 'blue-btn' : '' "
                        (click)="onShowSortings(column.node.reportObject, true)" pButton
                        class="sort-icon text-sm pointer" type="button" icon="pi pi-bars">
                    </button> -->


                        <!-- attribute button -->
                    <!-- <button *ngIf="column.node.reportObject" pButton class="attribute-btn text-sm"  type="button"
                        label="Attributes" (click)="onGetMetaInfo(column.node,'attributes')">
                    </button> -->

                        <!-- link icon -->
                    <!-- <button pTooltip="link" *ngIf="column.node.reportObject" pButton pRipple type="button" icon="pi pi-link"
                        class="p-button-rounded link-btn text-size"
                        (click)="onGetMetaInfo(column.node,'joins')"></button>

                 <button pButton pRipple type="button" *ngIf="column.node.linkedObjects.length"
                        class="p-button-rounded linked-objects"></button> -->
                
                    </div>






                <div *ngIf="column.type == 'link'" style="position: relative">

                    <span class="link-connection"
                        [ngClass]="column.node?.reportLink?.linkJoinLabel == 'Side' ? 'dashed-border' : '' ">
                        <span *ngIf="column.connectionLine == 'down' || column.connectionLine == 'middle' "
                            class="connector-down"></span>
                        <span *ngIf="column.connectionLine == 'up' || column.connectionLine == 'middle' "
                            class="connector-up"></span>
                    </span>


                    <!-- relation button -->
                    <!-- <button class="relation-btn grow text-sm" pButton type="button"
                        [label]="column.node?.reportLink?.linkTypeLabel" disabled>
                    </button> -->


                    <!-- relation button red with filter and sorting -->
                    <!-- <button class="red-btn" style="z-index: 0 !important;" pButton type="button"
                        *ngIf="column.node.reportLink.linkType == linkTypeEnum.OneToMany" disabled>
                        <button (click)="onShowLinkedSortings(column.node.reportLink)" pButton
                            class="sort-btn CustomFilterIcon text-sm pointer" type="button" icon="pi pi-bars"></button>

                        <button pButton class="filter-btn CustomFilterIcon text-sm pointer" type="button"
                            (click)="onShowLinkedFilter(column.node.reportLink)" icon="pi pi-filter"></button>
                    </button> -->

                </div>
            </ng-container>

            <span class="link-margin" *ngIf="!column.node"> </span>
        </ng-container>
    </div>
</section>

</div>

</div>








<!--<p-button type="button" (click)="onGetAttributes();" icon="pi pi-cog"-->
<!--          *ngIf="!attributesSidebar" styleClass="layout-config-button" class="position">Attributes List</p-button>-->
<p-overlayPanel #joins [showCloseIcon]="true" [style]="{width: '450px'}">
    <ng-template pTemplate>
        <p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct"
            (onRowSelect)="checkIfGeneric()" [paginator]="true" [rows]="7">
            <ng-template pTemplate="header">
                <tr>
                    <th>Entity</th>
                    <th>Join</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData" *ngIf="product.name != null && product.name != '' ">
                    <td>{{product.name}}</td>
                    <td>{{product.joinRelationEnum}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<p-dialog header="Link Join" [(visible)]="displayLinkModal" [modal]="true" [style]="{width: '50vw', height: '40vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ng-template pTemplate>
        <p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct"
            (onRowSelect)="checkIfGeneric()">
            <ng-template pTemplate="header">
                <tr>
                    <th>Entity</th>
                    <th>Join</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData" *ngIf="product.name != null && product.name != '' ">
                    <td>{{product.name}}</td>
                    <td>{{product.joinRelationEnum}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="displayLinkTypeModal" [modal]="true" [style]="{width: '50vw', height: '40vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="reset()">
    <ng-template pTemplate="header">
        <table>
            <tr>
                <th>{{selectedProduct.name}}</th>
                <th>({{selectedProduct.joinRelationEnum}})</th>
            </tr>
        </table>
    </ng-template>
    <!-- (onRowSelect)="onLinkEntity($event)" -->
    <ng-template pTemplate>
        <p-table [value]="linkJoin" selectionMode="single" [(selection)]="selectedLinkJoin">
            <ng-template pTemplate="header">
                <tr>
                    <th>Join Type&nbsp;
                        <i class="pi pi-info-circle pointer" tooltipPosition="right"
                            pTooltip="Choose the link join (Main/Inner or Side/Outer)"></i>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-join>
                <tr [pSelectableRow]="rowData">
                    <td>{{join.joinName}}</td>
                </tr>
            </ng-template>

        </p-table>
        <ng-container *ngIf="displayGenericTypeModal">
            <p-table class="p-3" [value]="genericTypes" selectionMode="single" [(selection)]="selectedGenericType"
                (onRowSelect)="reportName = selectedGenericType.typeName">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Generic Types&nbsp;
                            <i class="pi pi-info-circle pointer" tooltipPosition="right"
                                pTooltip="Choose the generic type"></i>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-generic>
                    <tr [pSelectableRow]="rowData">
                        <td>{{generic.typeName}}</td>
                    </tr>
                </ng-template>

            </p-table>
        </ng-container>

        <ng-container
            *ngIf="selectedProduct.joinRelationEnum == 'oneToMany' || selectedProduct.joinRelationEnum == 'manyToOne'">
            <div class="p-2">
                <p class="p-2">Do you want to link all records?</p>
                <p-inputSwitch class="p-2" [(ngModel)]="isMultiple"></p-inputSwitch>
            </div>
        </ng-container>

        <ng-container
            *ngIf="selectedProduct.joinRelationEnum == 'oneToMany' || selectedProduct.joinRelationEnum == 'manyToOne'">
            <p-table [value]="linkType" selectionMode="single" [(selection)]="selectedLinkType"
                (onRowSelect)="updateLinkRecords()">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Link Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-link>
                    <tr [pSelectableRow]="rowData">
                        <td>{{link.linkName}}</td>
                    </tr>
                </ng-template>

            </p-table>
        </ng-container>

        <div class="p-3">
            <span class="font-bold">Name</span> : <input class="text-sm" type="text" pInputText
                [(ngModel)]="reportName" />
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton type="button" *ngIf="selectedLinkJoin && reportName
     && (!selectedProduct.genericType || (selectedProduct.genericType && selectedGenericType))
     && (selectedProduct.joinRelationEnum == 'oneToOne' || this.selectedLinkType)" label="Create"
            class="p-button-secondary bg-gray" (click)="onLinkEntity()"></button>
    </ng-template>


</p-dialog>


<p-overlayPanel #attributesPanel [showCloseIcon]="true" [style]="{width: '500px'}">
    <ng-template pTemplate>
        <p-table [value]="attributes" selectionMode="single" class="overlay-custom" [(selection)]="selectedAttribute"
            (onRowSelect)="onAddAttributestoField($event)" styleClass="height-overlay">
            <ng-template pTemplate="header">
                <tr class="text-sm">
                    <th>Attribute Name</th>
                    <th>Data Type</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-attribute>
                <tr class="text-sm" [pSelectableRow]="rowData"
                    *ngIf="attribute.attributeName != null && attribute.attributeName != '' ">
                    <td>
                        <i [mvsDataTypeIcons]="attribute.icon"> </i>
                        {{attribute.attributeName}}
                    </td>
                    <td>{{attribute.dataType}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<p-sidebar #sidebar [(visible)]="attributesSidebar" [style]="{'width':'33%', 'height':'90vh', 'top': '65px'}" [blockScroll]="true"
    [showCloseIcon]="true" [baseZIndex]="1000" position="right" class="report-attr-sidebar" styleClass="layout-config p-sidebar-sm fs-small p-0"
    [modal]="false">
    <div class="layout-config-panel flex flex-column">
        <div class="px-3 pt-3">
            <h5>Attributes List</h5>
            <div *ngIf="attributeList.length > 0">
                <p-table *ngIf="attributeList" [value]="attributeList" class="overlay-custom"
                    [reorderableColumns]="true" (onRowReorder)="changeAttributePriority()">
                    <ng-template pTemplate="header">
                        <tr class="text-sm">
                            <th style="width:3rem"></th>
                            <th>Attribute</th>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-atr let-rowIndex="rowIndex">
                        <tr [pReorderableRow]="rowIndex" [pSelectableRow]="rowData" class="text-sm">
                            <td>
                                <span class="pi pi-bars" pReorderableRowHandle></span>
                            </td>
                            <td>{{atr.reportObjectDtoName}} <br> <small>{{atr.attributeName}}</small></td>
                            <td>
                                <i [mvsDataTypeIcons]="atr.icon"> </i>
                                {{atr.name != undefined ? atr.name : atr.attributeName}}
                            </td>
                            <td>
                                <span class="pi pi-pencil p-2 pointer" (click)="onEditAttributesField(atr.id)"></span>
                                <span class="pi pi-trash pointer" (click)="onConfirmDeleteAttribute(atr.id)"></span>
                            </td>

                        </tr>
                    </ng-template>
                </p-table>
            </div>


            <div *ngIf="attributeList.length == 0" class="py-5">
                <span>No attributes added so far!</span>
            </div>


        </div>

        <hr class="mb-0" />
    </div>
</p-sidebar>

<!--<p-blockUI [blocked]="blockedDocument"></p-blockUI>-->

<p-overlayPanel #genericTypesPanel [showCloseIcon]="true" [style]="{'width':'350px'}">
    <ng-template pTemplate>
        <p-table [value]="genericTypes" class="overlay-custom" selectionMode="single"
            [(selection)]="selectedGenericType"
            [style]="{'overflow':'auto','width':'300px','padding': 0 ,'border':'none'}"
            (onRowSelect)="openJoinTypeOverlay($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Type Name</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-generic>
                <tr [pSelectableRow]="rowData">
                    <td>{{generic.typeName}}</td>
                </tr>
            </ng-template>

        </p-table>
    </ng-template>
</p-overlayPanel>

<p-sidebar #bottomBar [(visible)]="testBottomBar" [blockScroll]="true" [showCloseIcon]="true" [baseZIndex]="1000"
    position="bottom" styleClass="layout-config p-sidebar-md fs-small p-0" [modal]="false">
    <div class="p-3">
        <h4>Test Widget</h4>
        <ng-container *ngIf="testBottomBar && reportWidgetData">
            <mvs-widget [widgetData]="reportWidgetData" [importObjectContext]="importObjectContext"></mvs-widget>
        </ng-container>

    </div>

</p-sidebar>

<p-dialog header="Add new Start Object" [(visible)]="displayStartObjectModal" [modal]="true"
    [style]="{width: '40vw', height: '40vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true">
    <p-table #dt *ngIf="objectTypes" [value]="objectTypes.entries" selectionMode="single"
        [globalFilterFields]="['alias','name']">
        <ng-template pTemplate="caption">
            <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" />
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Entity</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-type>
            <tr class="pointer" (click)="onCreateStartObject(type)">
                <td>{{type.alias}}</td>
            </tr>
        </ng-template>

    </p-table>
</p-dialog>