import { Component, OnInit } from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsFormValueListDto} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {EQUALS} from "@angular/cdk/keycodes";
import {CrCustomerDto} from "../../../../cr/dto/person.dto";
import {CustomerService} from "../../../../cr/service/api/customer.service";

@Component({
  selector: 'mvs-xx-widget-page',
  templateUrl: './xx-widget.page.html',
  styleUrls: ['./xx-widget.page.scss']
})
export class XxWidgetPage implements OnInit {

  initialized: boolean;

  tableWidgetData: WidgetData;

  customerWidgetData: WidgetData;

  personWidgetData: WidgetData;

  contactsWidgetData: WidgetData;

  selectedObject: ObjectIdentifier;

  customerDto: CrCustomerDto;

  constructor(protected customerService: CustomerService) {
  }

  ngOnInit(): void {

    this.initWidgets();

    this.initialized = true;
  }

  onCustomerSelect(objectIdentifier: ObjectIdentifier) {

    // load customer details
    this.customerService.get(objectIdentifier.objectId).subscribe(value => {
      this.customerDto = <CrCustomerDto>value;
      this.selectedObject = objectIdentifier;
      this.refreshWidgets();
    });

  }


  refreshWidgets() {

    // HEADER INFORMATION
    this.customerWidgetData = new WidgetData();
    this.customerWidgetData.idAlias = "xx.widget.customer.data";
    this.customerWidgetData.name = "Customer: " + this.selectedObject.objectId;
    this.customerWidgetData.uiComponent = "data";
    this.customerWidgetData.dataProvider = "list";
    this.customerWidgetData.dataSource = "entity";
    this.customerWidgetData.dataProviderObject = "cr.Customer";
    this.customerWidgetData.setParamValue("objectId", this.selectedObject.objectId);

    this.customerWidgetData.setParamValue("showHeader", true);
    this.customerWidgetData.setParamValue("headerText", "Header Text");
    this.customerWidgetData.setParamValue("showSubHeader", true);
    this.customerWidgetData.setParamValue("subHeaderText", "Sub Header Text");


    // CUSTOMER CONTACT
    let filterCriteria = FilterCriteria.create("customer", FilterCriteria.cOperatorEqual,this.selectedObject.objectId, null );

    this.contactsWidgetData = new WidgetData();
    this.contactsWidgetData.idAlias = "xx.widget.contacts.simple";
    this.contactsWidgetData.name = "cr.CustomerContact";
    this.contactsWidgetData.uiComponent = "list";
    this.contactsWidgetData.dataProvider = "list";
    this.contactsWidgetData.dataSource = "entity";
    this.contactsWidgetData.dataProviderObject = "cr.CustomerContact";
    this.contactsWidgetData.dataProviderListRequest = new ObjectRequestList(
        true,
        [ filterCriteria ],
        [new Sorting("createdDate", false)]
    );
    this.contactsWidgetData.setParamValue("size", "S");
    this.contactsWidgetData.setParamValue("objectWidget", "data");


    // PERSON INFORMATION
    this.personWidgetData = new WidgetData();
    this.personWidgetData.idAlias = "xx.widget.person.data";
    this.personWidgetData.name = "Person: " + this.customerDto.personDtoId;
    this.personWidgetData.uiComponent = "data";
    this.personWidgetData.dataProvider = "list";
    this.personWidgetData.dataSource = "entity";
    this.personWidgetData.dataProviderObject = "pm.Person";
    this.personWidgetData.setParamValue("objectId", this.customerDto.personDtoId);

    this.personWidgetData.setParamValue("showHeader", true);
    this.personWidgetData.setParamValue("headerText", "Header Text");
    this.personWidgetData.setParamValue("showSubHeader", true);
    this.personWidgetData.setParamValue("subHeaderText", "Sub Header Text");



  }


  initWidgets() {

    this.tableWidgetData = new WidgetData();
    this.tableWidgetData.idAlias = "xx.widget.table.simple";
    this.tableWidgetData.name = "cr.Customer";
    this.tableWidgetData.uiComponent = "table";
    this.tableWidgetData.dataProvider = "list";
    this.tableWidgetData.dataSource = "entity";
    this.tableWidgetData.dataProviderObject = "cr.Customer";
    this.tableWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [],
        [new Sorting("createdDate", false)],
        PagingDto.create(0, 5)
    );
    this.tableWidgetData.setParamValue("selectionMode", "single");
    this.tableWidgetData.setParamValue("size", "S");


  }



}
