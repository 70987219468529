<ng-container *ngIf="initialized">
    <form [formGroup]="widgetUiField">
        <div [formGroupName]="'uiConfigData'">
            <div class="grid">
                <div class="col-12">
                    <h4>User Object Access Config</h4>
                </div>
                <div class="col-6">
                    <label for="objectType">Object Type</label>
                    <mvs-object-type  id="objectType" [objectTypeAlias]="widgetUiField.get('uiConfigData').get('objectType').value" (objectTypeChange)="handleObjectType($event)"></mvs-object-type>
                </div>
                <div class="col-6">
                    <div class="flex flex-column">
                    <label for="objectId">Object ID</label>
                    <p-dropdown
                            [options]="objectIdOptions"
                            id="objectId"
                            formControlName="objectId"
                            class="w-full"
                            styleClass="w-full"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select Object ID"
                    >
                    </p-dropdown>
                </div>
                </div>
            </div>
        </div>
    </form>

</ng-container>