<div class="grid">
    <div class="col-4 flex flex-column gap-2">
        <mvs-dm-upload class="mvs-widget h-full"
                [objectIdentifier]="objectIdentifier"
                documentTypeId="3387"
        ></mvs-dm-upload>
    </div>
    <div class="col-8 flex flex-column gap-2">
        <mvs-dm-object-documents class="mvs-widget h-full"
                [objectIdentifier]="objectIdentifier"
                layoutVariation="customer"
        ></mvs-dm-object-documents>
    </div>
</div>
