import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {DtoDetail} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TicketActionService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketActions');
  }

  /**
   * Retrieve all TicketActions via the Ticket Type.
   * @param dtoTicketType
   */
  listActionsFromTicket(dtoTicket: DtoDetail) : Observable<DtoList> {

    return this.listByAttribute("TicketType", dtoTicket["id"]);

  }

  triggerAction(id: number) : Observable<DtoDetail> {

    return this.postInternal({}, `/${id}/trigger`);

  }


}

