import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CombinedFormsArray, MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {
    DtoDetail,
    DtoImportObjectContext,
    EntityStatusEnum,
    FilterCriteria,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestRelation,
    PeriodHelper, WidgetData,
    WidgetDataParam, WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../cr/dto/customer-contract.dto";
import {CustomerEntityAssignmentType} from "../../../cr/enum/customer-entity-assignment-type.enum";
import {CustomerContractService} from "../../../cr/service/api/customer-contract.service";
import {ContractDto} from "../../dto/contract.dto";
import {ContractService} from "../../service/api/contract.service";
import {ContractBaseTypeService} from "../../service/api/contract-base-type.service";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'cr-create-contract-combined-form',
    templateUrl: './cm-create-contract-combined-form.component.html',
})
export class CmCreateContractCombinedFormComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() variation: "Antrag" | "Fremd";
    @Input() customerId: number;
    @Input() usedIn: 'standAlone' | 'sidebar' = 'standAlone';
    @Input() agentId: number;

    @Input() contractId: number = 0;

    @Output() onObjectChanged: EventEmitter<ObjectChangeInformation> = new EventEmitter<ObjectChangeInformation>();
    @Output() onCreateNew: EventEmitter<number> = new EventEmitter<number>();

    isAllComplete: boolean;

    contractPartnerId: number = 0;
    contractFormerId: number = 0;
    contractStatusId: number = 0;
    contractAgentId: number = 0;
    contractPriceId: number = 0;

    combinedFormArray: CombinedFormsArray[];
    objectIdentifier: ObjectIdentifier;
    formerContractRequired: boolean;
    contractStartDate: Date;
    formerContractCount = 0;

    constructor(
        protected contractService: ContractService,
        protected customerContractService: CustomerContractService,
        protected contractBaseTypeService: ContractBaseTypeService,
        private confirmationService: ConfirmationService,
        protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.combinedFormArray = [this.prePopulateForm()];
        if (this.contractId) {
            this.combinedFormArray[0].complete = true;

            this.getContract();
        } else {
            this.refreshComponent();
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * populate combineArray with contract screen
     */
    prePopulateForm(): CombinedFormsArray {

        const dto = new ContractDto();
        if (this.variation == 'Antrag') {
            dto['baseCategoryDtoId'] = 1;
        } else {
            dto['baseCategoryDtoId'] = 2;
        }

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('baseCategoryDtoId', MvsFormFieldAccessEnum.READ);


        return {
            id: 'cm.Contract',
            label: 'Vertrag',
            icon: 'fa-regular fa-file-contract text-xl',
            tooltip: 'Vertrag',
            anchorId: "contract",
            widgetInformation: WidgetFactory.createWidgetObject(
                `cm.create.contract.combined.form.create.contract.${this.variation}`,
                'Vertrag',
                'cm.Contract',
                this.contractId,
                WidgetDataParam.create('createDefaultDto', dto),
                WidgetDataParam.create('formControlOverwrite', formControlOverwrite)
            )
        }

    }

    /**
     * handle object change emitted
     * @param object
     */
    handleObjectChange(object: ObjectChangeInformation) {
        if (object.objectType == 'cm.Contract' && !object.before) {

            this.contractId = object?.after?.id;
            this.handleContractUpdate(<ContractDto>object?.after, object);

        } else if (object.objectType == 'cm.ContractFormer') {
            this.askForAdditionalFormerContractStep(event);
        }

        this.isAllComplete = this.combinedFormArray.every(item => item?.complete == true);

        if (object.objectType != 'cm.Contract') {
            this.onObjectChanged.emit(object);
        }
    }

    askForAdditionalFormerContractStep(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: 'Willst du noch einen weiteren "vorherigen Vertrag" erfassen??',
            header: 'Former Contract',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: "none",
            rejectIcon: "none",
            acceptButtonStyleClass: "p-button-success",
            rejectButtonStyleClass: "p-button-danger",
            accept: () => {
                this.handleAdditionalContractFormerAdd();
            },
            reject: () => {
            }
        });
    }

    handleAdditionalContractFormerAdd() {
        const context = DtoImportObjectContext.createFromObjectIdentifiers(
            this.objectIdentifier,
            new ObjectIdentifier("cr.Customer", this.customerId),
        );
        this.formerContractCount++; // incrementing for uniqueness

        const dto = new DtoDetail();
        dto['startDate'] = this.contractStartDate;

        const form = this.getContractFormer(context, dto);
        form.anchorId += `-${this.formerContractCount}`; // just to scroll to a unique form
        form.id += `-${this.formerContractCount}`; // just to highlight unique icon
        const lastEntryIndex = this.calculateLastFormerContractIndex();
        this.combinedFormArray.splice(lastEntryIndex, 0, form); // add additional entry

    }

    calculateLastFormerContractIndex(): number {
        const formerContractEntries = this.combinedFormArray.filter(item => item.label == 'Contract Former');
        const lastId = formerContractEntries[formerContractEntries.length - 1].id;

        const index = this.combinedFormArray.findIndex(item => item.id == lastId);
        if (index > -1) {
            return index;
        }
        return null;

    }

    handleContractUpdate(contractDto?: ContractDto, object?: ObjectChangeInformation) {

        this.objectIdentifier = new ObjectIdentifier('cm.Contract', this.contractId);
        this.contractStartDate = contractDto?.startDate;

        const context = DtoImportObjectContext.createFromObjectIdentifiers(
            this.objectIdentifier,
            new ObjectIdentifier("cr.Customer", this.customerId),
        );

        this.contractBaseTypeService.get(contractDto.baseTypeDtoId).subscribe(res => {
            this.formerContractRequired = res['formerContractRequired'];
            console.log(this.formerContractRequired);

            this.createCustomerContract(this.objectIdentifier, object);

            const defaultDto: DtoDetail = new DtoDetail();
            defaultDto['startDate'] = this.contractStartDate;

            this.postPopulateForm(context, defaultDto);
        });

    }


    handleObjectChangeForm(object: CombinedFormsArray) {

    }

    /**
     * populate array based on variation after contract is create
     * @param objectContext
     * @param defaultDto
     */
    postPopulateForm(objectContext: DtoImportObjectContext, defaultDto: DtoDetail) {

        if (this.variation == "Antrag") {
            this.combinedFormArray = [...this.combinedFormArray, ...this.postPopulateFormArray(objectContext, defaultDto)];
        } else if (this.variation == "Fremd") {
            this.combinedFormArray = [...this.combinedFormArray, ...this.postPopulateFormArray(objectContext, defaultDto)];
        }

        this.isAllComplete = this.combinedFormArray.every(item => item?.complete == true);
    }

    /**
     * create customer contract after contract has been created
     * @param objectIdentifier
     */
    createCustomerContract(objectIdentifier: ObjectIdentifier, object?: ObjectChangeInformation) {

        const filterCriteria = [
            FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.customerId),
            FilterCriteria.create('contract', FilterCriteria.cOperatorEqual, objectIdentifier.objectId)
        ];

        const objectRequest = new ObjectRequestList(false, filterCriteria, null);

        this.customerContractService.list(objectRequest).subscribe(res => {

            if (!res?.entries?.length) {

                const customerContractDto = new CustomerContractDto();
                customerContractDto.customerDtoId = this.customerId;
                customerContractDto.contractDtoId = objectIdentifier.objectId;
                customerContractDto.assignmentType = CustomerEntityAssignmentType.main;
                customerContractDto.entityStatus = EntityStatusEnum.active;

                this.customerContractService.create(customerContractDto).subscribe((res: CustomerContractDto) => {

                    this.onObjectChanged.emit(object);
                });
            }
        });


    }

    /**
     * returns form array
     * @param objectContext
     * @param defaultDto
     */
    postPopulateFormArray(objectContext: DtoImportObjectContext, defaultDto: DtoDetail): CombinedFormsArray[] {
        let combinedArray: CombinedFormsArray[] = [];

        if (this.formerContractRequired) {
            this.formerContractCount++;
            const formerContractForm = this.getContractFormer(objectContext, defaultDto);
            combinedArray.push(formerContractForm);
        }

        //combinedArray = combinedArray.filter(entry => !(this.variation == "Fremd" && (entry.id == 'cm.ContractStatus' || entry.id == 'cm.ContractPrice')));

        const contractPartnerWidget = WidgetFactory.createWidgetObject(
            `cm.create.contract.combined.form.create.contract.partner.${this.variation}`,
            'Vertragspartner',
            'cm.ContractPartner',
            this.contractPartnerId,
            // WidgetDataParam.create('createDefaultDto', Object.assign({}, defaultDto))
        );

        contractPartnerWidget.functionCallbacks = this.contractPartnerFunctionCallback();

        combinedArray.push(
            {
                id: 'cm.ContractPartner',
                label: 'Partner',
                icon: 'fa-regular fa-handshake text-xl',
                tooltip: 'Partner',
                anchorId: "partner",
                complete: !!this.contractPartnerId,
                widgetInformation: contractPartnerWidget,
                importObjectContext: objectContext
            }
        );

        // if (this.variation == "Antrag") {
        //     combinedArray.push(
        //         {
        //             id: 'cm.ContractStatus',
        //             label: 'Hauptstatus',
        //             icon: 'fa-regular fa-bars-progress text-xl',
        //             tooltip: 'Hauptstatus',
        //             anchorId: "status",
        //             widgetInformation: WidgetFactory.createWidgetObject(
        //                 `cm.create.contract.combined.form.create.contract.status.${this.variation}`,
        //                 'Hauptstatus',
        //                 'cm.ContractStatus',
        //                 0,
        //                 WidgetDataParam.create('createDefaultDto', Object.assign({}, defaultDto))),
        //             importObjectContext: objectContext
        //         }
        //     );
        // }

        const defaultDtoSubStatus = new DtoDetail({});
        defaultDtoSubStatus['startDate'] = PeriodHelper.convertDateToApiDate(new Date());

        // combinedArray.push({
        //     id: 'cm.ContractSubStatus',
        //     label: 'Antragsstatus',
        //     icon: 'fa-regular fa-bars-progress text-xl',
        //     tooltip: 'Antragsstatus',
        //     anchorId: "subStatus",
        //     complete: !!this.contractStatusId,
        //     widgetInformation: WidgetFactory.createWidgetObject(
        //         `cm.create.contract.combined.form.create.contract.sub.status.${this.variation}`,
        //         'Antragsstatus',
        //         'cm.ContractStatus',
        //         this.contractStatusId,
        //         WidgetDataParam.create('createDefaultDto', defaultDtoSubStatus)),
        //     importObjectContext: objectContext
        // });

        if (this.variation == "Antrag") {
            combinedArray.push({
                id: 'cm.ContractPrice',
                label: 'Preis',
                icon: 'fa-regular fa-tag text-xl',
                tooltip: 'Preis',
                anchorId: "price",
                complete: !!this.contractPriceId,
                widgetInformation: WidgetFactory.createWidgetObject(
                    `cm.create.contract.combined.form.create.contract.price.${this.variation}`,
                    'Vertragspreis',
                    'cm.ContractPrice',
                    this.contractPriceId,
                    WidgetDataParam.create('createDefaultDto', Object.assign({}, defaultDto))),
                importObjectContext: objectContext
            });
        }

        const dto = Object.assign({}, defaultDto);
        dto['agentDtoId'] = this.agentId;

        combinedArray.push({
            id: 'cm.ContractAgent',
            label: 'Berater',
            icon: 'fa-regular fa-user-tie text-xl',
            tooltip: 'Berater',
            anchorId: "agent",
            complete: !!this.contractAgentId,
            widgetInformation: WidgetFactory.createWidgetObject(
                `cm.create.contract.combined.form.create.contract.agent.${this.variation}`,
                'Vertragsagent',
                'cm.ContractAgent',
                this.contractAgentId,
                WidgetDataParam.create('createDefaultDto', dto)),
            importObjectContext: objectContext
        });


        return combinedArray;
    }

    contractPartnerFunctionCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['startDate'] = PeriodHelper.convertDateToApiDate(new Date());

                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('startDate', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };

        // return {
        //     onFunctionObjectUpdate(widgetData: WidgetData): WidgetToolbarCallbackInterface {
        //         const dto = new DtoDetail();
        //         dto['startDate'] = PeriodHelper.convertDateToApiDate(new Date());
        //         const overwrite = new MvsFormControlOverwrite();
        //         overwrite.addField("startDate", MvsFormFieldAccessEnum.UPDATE);
        //         // overwrite.addField("contractDtoId", MvsFormFieldAccessEnum.HIDDEN);
        //         return {formControlOverwrite: overwrite};
        //     }
        // }
    }

    getContractFormer(objectContext: DtoImportObjectContext, defaultDto: DtoDetail) {
        //TODO: Yasir change icon
        return {
            id: 'cm.ContractFormer',
            label: 'Contract Former',
            icon: 'fa-regular fa-file-contract text-xl',
            tooltip: 'Contract Former',
            anchorId: "former",
            complete: !!this.contractFormerId,
            widgetInformation: WidgetFactory.createWidgetObject(
                `cm.create.contract.combined.form.create.contract.former.${this.variation}`,
                'Contract Former',
                'cm.ContractFormer',
                this.contractFormerId,
                WidgetDataParam.create('createDefaultDto', Object.assign({}, defaultDto))),
            importObjectContext: objectContext
        }
    }


    /**
     * create new contract
     */
    createNewContract() {
        this.isAllComplete = false;
        this.objectIdentifier = null;
        this.contractId = 0;
        this.combinedFormArray = [this.prePopulateForm()];
        this.onCreateNew.emit(this.contractId);
    }

    /**
     * navigate to contract
     */
    navigateToContract() {
        this.navigationService.handleObjectNavigation(this.objectIdentifier, null, {openNewTab: true})
    }

    getContract() {

        const filterCriteria = FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.contractId);

        const complexRequest = ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createRelationNode("contractPartner",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractPartner#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractStatus",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractStatus#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractPrice",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractPrice#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractAgent",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractAgent#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ));

        const dtoRequest = ObjectRequestList.createComplexRequestList(false, complexRequest, [filterCriteria], [], null)

        this.contractService.list(dtoRequest).subscribe(res => {
            const contract = <ContractDto>res.entries[0];
            this.contractPartnerId = contract?.contractPartner?.id;
            this.contractStatusId = contract?.contractStatus?.id;
            this.contractAgentId = contract?.contractAgent?.id;
            this.contractPriceId = contract?.contractPrice?.id;

            this.handleContractUpdate(contract);
            this.refreshComponent();
        })
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }

        if (changes["customerId"] || changes["contractId"]) {
            if (!this.contractId) {
                this.createNewContract();
            }
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
