import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivityContextHistoryDto} from "./data/activity-context-history.interface";
import {
    DataTypeHelper, DtoList,
    FilterAttributeDateTypeEnum, MvsCrudService,
    MvsFormDto,
    MvsFormFieldDto, MvsFormValueListEntryDto,
    MvsObjectService,
    ObjectChangeInformationActionEnum, WidgetData, WidgetDataParam
} from "@kvers/alpha-core-common";
import {HistoryEntryDto} from "./data/history-entry.dto";
import {ActivityContextService} from "../../../cc/service/api/activity-context.service";
import {RevisionType} from "./data/revision-type.enum";
import {Subscription} from "rxjs";
import {WidgetFactory} from "@kvers/alpha-ui";

interface EntryRecord {
    formFields: MvsFormFieldDto[],
    entry: HistoryEntryDto
}

@Component({
    selector: 'mvs-context-history',
    templateUrl: './mvs-history.component.html',
    styleUrls: ['./mvs-history.component.scss'],
})
export class MvsHistoryComponent implements OnInit, OnChanges, OnDestroy {

    @Input() objectId: number;
    @Input() objectTypeId: number;
    @Input() objectTypeAlias: string;
    @Input() pageFrom: number = 0;
    @Input() pageSize: number = 3;
    showDetailView: boolean = false;

    @Input() columns: number = 2;
    actualPageSize: number;
    historyData: ActivityContextHistoryDto;
    detailWidget: WidgetData;

    widgetFieldEntries: EntryRecord[];
    recordsLimitReached: boolean = false;
    broadcastSubscription: Subscription;

    initialized: boolean;
    busy: boolean;

    constructor(protected contextService: ActivityContextService, protected objectService: MvsObjectService) {
    }

    ngOnInit() {
        this.actualPageSize = this.pageSize;
        this.refreshComponent();
        this.handleBroadcastChange();
    }

    refreshComponent() {
        this.getHistoryData();
    }

    handleBroadcastChange() {
        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.action == ObjectChangeInformationActionEnum.updated) {
                this.refreshComponent();
            }
        });
    }

    getHistoryData() {
        this.busy = true;
        this.contextService.getActivityContextHistory(this.objectId, this.pageFrom, this.actualPageSize,this.objectTypeId, this.objectTypeAlias).subscribe(
            (res) => {
            this.historyData = res;
            if (res.entries && res.entries.length%this.actualPageSize == 0) {
                this.recordsLimitReached = false;
            } else {
                this.recordsLimitReached = true;
            }
            this.refreshInternal();
            this.busy = false;
        });
    }

    onPagePrevious() {
        if (this.actualPageSize == this.pageSize) {
            return;
        }
        // this.pageFrom = this.pageFrom - 1;
        this.actualPageSize = this.actualPageSize - this.pageSize;
        this.getHistoryData();

    }

    onPageNext() {
        // this.pageFrom = this.pageFrom + 1;
        this.actualPageSize = this.actualPageSize + this.pageSize;
        this.getHistoryData();
    }

    refreshInternal() {
        const historyEntries = structuredClone(this.historyData.entries);
        this.widgetFieldEntries = [];

        for (let item of historyEntries) {
            const form = this.getForm(item.objectAlias);

            const recordEntry: EntryRecord = {formFields: [], entry: item};

            for (let formField in form.formFields) {

                const field: MvsFormFieldDto = form.formFields[formField];

                if (formField.endsWith('DtoId')) {
                    continue;
                }

                if (formField.endsWith('Json')) {
                    field.uiOutputControl = 'json';
                }

                let actualFieldName: string = field.id;

                if (formField.endsWith('DtoName') && (field.relationship == "ManyToOne" || field.relationship == "OneToMany")) {
                    actualFieldName =  formField.replace('DtoName','');
                }

                const dataType = DataTypeHelper.getInternalDataType(field.dataType);

                if (dataType == FilterAttributeDateTypeEnum.dateTime) {
                    field.uiOutputControl = 'dateInstant';
                } else if (dataType == FilterAttributeDateTypeEnum.boolean) {
                    // if its an enum then get its value from valueList
                    if (field.valueList && field.valueList.entries) {
                        item.entity[formField] = this.getLabelFromValueList(item.entity[formField], field.valueList.entries);
                    }
                }

                let skip: boolean = false;

                if (item.revisionType == 'ADD') {
                    recordEntry.formFields.push(field);
                } else {

                    if (this.showDetailView == false) {
                        const exists = item.changedAttributes.includes(actualFieldName);

                        if (!exists) {
                            skip = true;
                        }
                    }
                    if (skip) {
                        continue;
                    }
                    recordEntry.formFields.push(field);
                }

                if (item.changedAttributes.includes(formField) || item.changedAttributes.includes(actualFieldName)) {
                    field['uiFieldChanged'] = true;
                }

            }
            recordEntry.entry['uiTimestamp'] = this.convertTimestampToDate(recordEntry.entry.timestamp);

            if (recordEntry.formFields.length == 0) {
                // if no formFields then no need to display empty object on the page
                continue;
            }

            this.widgetFieldEntries.push(recordEntry);

        }
        this.initialized = true;
    }

    getForm(objectAlias: string): MvsFormDto {
        const form = this.historyData.forms[objectAlias];
        if (form) {
            return form;
        }
        return null;
    }

    getLabelFromValueList(key: number, entries: MvsFormValueListEntryDto[]): string {
        if (key == undefined || !entries?.length) {
            return null;
        }
        const value = entries.find(item => item.key == key).label;
        return value;
    }

    handleOpenDetailView(record: EntryRecord) {
        const form = this.getForm(record.entry.objectAlias);
        const dtoList = new DtoList();
        dtoList.form = form;
        dtoList.entries = [record.entry.entity];

        // MvsCrudService.transformDtoListOutToIn(record.entry.entity, form);
        this.detailWidget = WidgetFactory.createWidgetTransient(
            'history.widget.detail.view',
            record.entry.objectAlias,
            'list',
            'transient',
            'transient',
            record.entry.objectAlias,
            MvsCrudService.transformDtoListOutToIn(dtoList),
        );
        this.showDetailView = true;
    }

    convertTimestampToDate(timestamp: number): string {
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }

        if (changes["objectType"] || changes["objectTypeAlias"] || changes["objectId"]) {
            this.refreshComponent();
        }
    }


    handleToggleView() {
        this.showDetailView = !this.showDetailView;

        this.refreshInternal();

    }

    ngOnDestroy() {
        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

    handleBackNavigation() {
        this.detailWidget = null;
        this.showDetailView = false;
    }

    protected readonly RevisionType = RevisionType;
}
