
<!-- Navigation Targets for App Config -->
<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div class="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
        <div class="font-medium text-3xl text-900">Endkunden APP Konfiguration</div>
        <div class="mt-3 md:mt-0">
            <a pButton pRipple label="WebApp Starten" class="p-button-outlined mr-2" icon="pi pi-external-link" href="https://alpha-klaus.assortcloud.com/" target="_blank"></a>
            <a pButton pRipple label="Dokumentation" class="p-button p-button-warning" icon="pi pi-external-link" href="https://klausversichertde.sharepoint.com/:p:/s/alphaklaus/EUwbkF6sDpxLpBNd0vHJoE0BbPLz5EVLc3QuB7IFkK0xTw?e=mD2pgx" target="_blank"></a>
        </div>
    </div>
</div>

<br />
<br />

<div class="surface-ground">
        <div class="grid">
                <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                                <div class="p-4">
                                        <div class="flex align-items-center">
                                            <span class="inline-flex border-circle align-items-center justify-content-center bg-green-100 mr-3" style="width:38px;height:38px">
                                                <i class="pi pi-globe text-xl text-green-600"></i>
                                            </span>
                                            <span class="text-900 font-medium text-2xl">Text Repository</span>
                                        </div>
                                        <div class="text-900 my-3 text-xl font-medium">Pflege der Anzeigetexte</div>
                                        <p class="mt-0 mb-3 text-700 line-height-3">Die einzelnen Texte innerhalb der Anwendungen können direkt geändert werden und sind sofort innerhalb der APP sichtbar. Unterschieden wird zwischen Feldbezeichnungen und Langtexten.</p>
                                </div>
                                <div class="px-4 py-3 surface-100 text-right">
                                        <a icon="pi pi-arrow-right" label="Pflege starten" class="p-button p-ripple p-button-rounded p-button-success" href="/#/eu/euFrontEndTexts">Konfigurieren</a>
                                </div>
                        </div>
                </div>
                <div class="col-12 lg:col-4 p-3">
                    <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                        <div class="p-4">
                            <div class="flex align-items-center">
                                                <span class="inline-flex border-circle align-items-center justify-content-center bg-purple-100 mr-3" style="width:38px;height:38px">
                                                    <i class="fa-sharp fa-solid fa-paint-roller text-xl text-purple-300"></i>
                                                </span>
                                <span class="text-900 font-medium text-2xl">Theme Farben</span>
                            </div>
                            <div class="text-900 my-3 text-xl font-medium">Pflege der Farben innerhalb der Mobile App</div>
                            <p class="mt-0 mb-3 text-700 line-height-3">In diesem Menüpunkt können die Farben der Mobile App beeinflusst werden.</p>
                        </div>
                        <div class="px-4 py-3 surface-100 text-right">
                            <a icon="pi pi-arrow-right" label="Pflege starten" class="p-button p-ripple p-button-rounded p-button-help" href="/#/eu/euThemeColors">Konfigurieren</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                                <div class="p-4">
                                        <div class="flex align-items-center">
                        <span class="inline-flex border-circle align-items-center justify-content-center bg-yellow-100 mr-3" style="width:38px;height:38px">
                            <i class="pi pi-globe text-xl text-yellow-600"></i>
                        </span>
                                                <span class="text-900 font-medium text-2xl">Registrierung</span>
                                        </div>
                                        <div class="text-900 my-3 text-xl font-medium">Konfiguration des Registrierungs- Prozesses</div>
                                        <p class="mt-0 mb-3 text-700 line-height-3">Einstellung rund um den Regiestrierungs- und Anmeldeprozess können in diesem Menüpunkt konfiguriert werden.</p>
                                </div>
                                <div class="px-4 py-3 surface-100 text-right">
                                    <a icon="pi pi-arrow-right" class="p-button p-ripple p-button-rounded p-button-warning" href="/#/cr/customerWelcomes">Konfigurieren</a>
                                </div>
                        </div>
                </div>
                <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                                <div class="p-4">
                                        <div class="flex align-items-center">
                        <span class="inline-flex border-circle align-items-center justify-content-center bg-purple-100 mr-3" style="width:38px;height:38px">
                            <i class="pi pi-globe text-xl text-purple-600"></i>
                        </span>
                                                <span class="text-900 font-medium text-2xl">Onboarding</span>
                                        </div>
                                        <div class="text-900 my-3 text-xl font-medium">Definition des Onboarding Prozesses</div>
                                        <p class="mt-0 mb-3 text-700 line-height-3">Der Onboarding- Prozess kann im Detail konfiguriert werden. Dieser Menüpunkt enthält auch die Informationen die einem User angezeigt werden.</p>
                                </div>
                                <div class="px-4 py-3 surface-100 text-right">
                                    <a icon="pi pi-arrow-right" class="p-button p-ripple p-button-rounded p-button-help" href="/#/cr/customerOnboardingTypes">Konfigurieren</a>
                                </div>
                        </div>
                </div>
                <div class="col-12 lg:col-4 p-3">
                    <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                        <div class="p-4">
                            <div class="flex align-items-center">
                            <span class="inline-flex border-circle align-items-center justify-content-center bg-pink-100 mr-3" style="width:38px;height:38px">
                                <i class="pi pi-globe text-xl text-pink-600"></i>
                            </span>
                                <span class="text-900 font-medium text-2xl">Custer Interaction Types</span>
                            </div>
                            <div class="text-900 my-3 text-xl font-medium">Service Konfiguration</div>
                            <p class="mt-0 mb-3 text-700 line-height-3">Innerhalb der Enduser- Anwendungen können verschiedene Service bereitgestellt werden (wie z.B. Adressänderung, Schadensmeldung, Autopilot usw.)</p>
                        </div>
                        <div class="px-4 py-3 surface-100 text-right">
                            <a icon="pi pi-arrow-right" class="p-button p-ripple p-button-rounded p-button-danger" href="/#/cr/customerInteractionTypes">Konfigurieren</a>
                        </div>
                    </div>
                </div>
        </div>
</div>



