import {Injectable} from '@angular/core';
import {MvsCrudService, ObjectChangeInformation} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Observable, of, shareReplay, Subject, Subscription} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";
import {catchError, map, tap} from "rxjs/operators";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsApiServiceMeInterface} from "@kvers/alpha-core-common";
import {UmUserDto} from "../../dto/um-user.dto";
import {ArrayHelper} from "@kvers/alpha-core-common";
import {DmDocumentUploadDto} from "../../../dm/service/dto/dm-document-upload.dto";


@Injectable({
    providedIn: 'root'
})
export class UmUserService extends MvsCrudService implements MvsApiServiceMeInterface {

    usersPerOid: Map<number, DtoDetail> = new Map<number, DtoDetail>();
    profileSubscription = new Subject<string>();
    userObservablesPerOid: Map<number, Observable<DtoDetail>> = new Map<number, Observable<DtoDetail>>();

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/um/users')
    }

    getPictureBinary(): Observable<any> {
        const url = `${this.apiUrl}/me/photo/$`;
        return this.http.get(url, {responseType: "blob"});
    }

    setPicture(requestDto: DmDocumentUploadDto | any): Observable<any> {
        const url = `/me/picture`;
        return this.createInternal(requestDto, url);
    }

    deletePicture(): Observable<any> {
        const url = `${this.apiUrl}/me/picture`;
        return this.http.delete(url);
    }

    getPictureBinaryById(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/photo/$`;
        return this.http.get(url, { responseType: 'blob' });
    }

    setPictureById(id: number, requestDto: DmDocumentUploadDto | any): Observable<any> {
        const url = `/${id}/picture`;
        return this.createInternal(requestDto, url);
    }

    delPictureById(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/picture`;
        return this.http.delete(url);
    }

    /**
     * Return all active users.
     */
    public getAllActiveUsers(): Observable<DtoList> {

        const dtoListRequest = new ObjectRequestList(false, [FilterCriteria.create("enabled", FilterCriteria.cOperatorEqual, true, null)], null);
        return this.list(dtoListRequest);

    }

    /**
     * Get user's agent assignment.
     */
    me(): Observable<DtoDetail> {
        const url = `${this.apiUrl}/me`;
        return this.getInternal(url);
    }

    getUserByUserId(oid: number) {

        // Check if observable for the user already exists
        if (this.userObservablesPerOid.has(oid)) {
            return this.userObservablesPerOid.get(oid);
        }

        if (this.hasEntry(oid)) {

            // return already retrieved result
            return new Observable<DtoDetail>(subscriber => {
                subscriber.next(this.usersPerOid.get(oid));
            });

        } else {

            // retrieve user and buffer both the DTO and Observable
            const observable = <Observable<UmUserDto>>this.get(oid).pipe(map((value: DtoDetail) => {

                    // buffer information
                    this.setUsers(oid, value);
                    return value;
                }),
                shareReplay(1) // to make sure sharing of observable results (1 means it stores last one value)
            );

            this.userObservablesPerOid.set(oid, observable);
            return observable;
        }
    }


    /**
     * Get User via Oid.
     * @param oid
     */
    // public getUserByOid(oid: string): Observable<UmUserDto> {
    //
    //     const dtoListRequest = new ObjectRequestList(false, [FilterCriteria.create("username", FilterCriteria.cOperatorEqual, oid, null)], null);
    //
    //     // Check if observable for the user already exists
    //     if (this.userObservablesPerOid.has(oid)) {
    //         return this.userObservablesPerOid.get(oid);
    //     }
    //
    //     if (this.hasEntry(oid)) {
    //
    //         // return already retrieved result
    //         return new Observable<UmUserDto>(subscriber => {
    //             subscriber.next(this.usersPerOid.get(oid));
    //         });
    //
    //     } else {
    //
    //         // retrieve user and buffer both the DTO and Observable
    //         const observable = <Observable<UmUserDto>>this.list(dtoListRequest).pipe(map((value: DtoList<UmUserDto>) => {
    //
    //                 let dto: UmUserDto = null;
    //
    //                 if (value.entries.length > 0) {
    //                     dto = value.entries[0];
    //                 }
    //
    //                 // buffer information
    //                 this.setUsers(oid, dto);
    //                 return dto;
    //             }),
    //             shareReplay(1) // to make sure sharing of observable results (1 means it stores last one value)
    //         );
    //
    //         this.userObservablesPerOid.set(oid, observable);
    //         return observable;
    //     }
    //
    // }

    // public getUserByOids(oids: string[]): Observable<DtoList<UmUserDto>> {
    //
    //     const filter: FilterCriteria[] = [];
    //     let filterCriteria: FilterCriteria;
    //
    //     const distinctOids = ArrayHelper.getDistinctValues(oids);
    //
    //     for (let item of distinctOids) {
    //         if (!this.hasEntry(item)) {
    //             if (!filterCriteria) {
    //                 filterCriteria = new FilterCriteria();
    //             }
    //             filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('username', FilterCriteria.cOperatorEqual, item));
    //         }
    //     }
    //
    //     if (filterCriteria) {
    //         filter.push(filterCriteria);
    //     }
    //
    //     const dtoListRequest = new ObjectRequestList(
    //         false,
    //         filter,
    //         null);
    //
    //     if (filter && filter.length) {
    //         // retrieve user and buffer data
    //         return <Observable<DtoList<UmUserDto>>>this.list(dtoListRequest).pipe(map((res: DtoList<UmUserDto>) => {
    //
    //             let dto: DtoList<UmUserDto> = null;
    //
    //             // if (res.entries.length >= 0) {
    //                 dto = res;
    //
    //                 // buffer information
    //                 // for (const item of dto.entries) {
    //                 //
    //                 //     // Set records in this.usersPerOid if already not exists
    //                 //     this.setUsers(item['username'], item);
    //                 //
    //                 // }
    //
    //                 // buffer information
    //                 for (let item of distinctOids) {
    //                     const object = dto.entries.find(value => value.username == item);
    //
    //                     // Set records in this.usersPerOid if already not exists
    //                     this.setUsers(item, object);
    //
    //                 }
    //
    //             // } else {
    //             //     dto = null;
    //             // }
    //
    //             const records = this.retrieveRelevantRecords(oids);
    //
    //             return records;
    //
    //         }));
    //     } else {
    //
    //         // return already retrieved result
    //         return new Observable<DtoList<UmUserDto>>(subscriber => {
    //             const records = this.retrieveRelevantRecords(oids);
    //             subscriber.next(records);
    //         });
    //
    //     }
    // }

    setUsers(id: number, dto: DtoDetail) {

        if (!this.hasEntry(id)) {
            this.usersPerOid.set(id, dto);
        }
    }

    hasEntry(id: number): boolean {

        if (this.usersPerOid.has(id)) {
            return true;
        }
        return false;

    }

    // retrieveRelevantRecords(ids: string[]): DtoList<UmUserDto> {
    //
    //     const entries: UmUserDto[] = [];
    //     const dto = new DtoList<UmUserDto>();
    //
    //     for (let item of ids) {
    //         if (this.hasEntry(item)) {
    //             const value = this.usersPerOid.get(item);
    //             entries.push(value)
    //         }
    //     }
    //
    //     dto.entries = entries;
    //     return dto;
    //
    // }

    profilePictureBroadcast(imgSource: string) {
        this.profileSubscription.next(imgSource);
    }

}
