import {Component, OnInit} from '@angular/core';
import {MessageService, TreeDragDropService} from "primeng/api";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CrCustomerDto} from "../../../../cr/dto/cr-customer.dto";
import {AssessmentGroupService} from "../../../../cm/service/api/assessment-group.service";
import {CmCustomerAssessmentDto} from "../../../../cm/dto/cm-customer-assessment.dto";
import {MvsSearchService} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {PmPersonDto} from "../../../../cr/dto/pm-person.dto";
import {PmPersonDetailDto} from "../../../../cr/dto/pm-person-detail.dto";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-test-form',
  providers: [TreeDragDropService,MessageService],
  templateUrl: './test-form.page.html',
  styleUrls: ['./test-form.page.scss']
})
export class TestFormPage extends PageComponent implements OnInit {

  dto: CrCustomerDto;

  entries: any[];

  widgetDataList: WidgetData[];
  searchTerm: string;

  customerAssessment: CmCustomerAssessmentDto;

  defaultDto: PmPersonDto;
  formOverwrite: MvsFormControlOverwrite;

  objectIdentifierTicket: ObjectIdentifier;

  objectIdentifierAgent: ObjectIdentifier;

  objectIdentifierProcess: ObjectIdentifier;


  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected assessService: AssessmentGroupService,
      protected searchService: MvsSearchService,
      protected documentService: DmDocumentService) {
    super(route, coreService);
  }




  ngOnInit(): void {
    super.ngOnInit();
    this.initComponent();
  }

  protected initComponent() {

    // set import object context
    this.importObjectContextDto = new DtoImportObjectContext([ new DtoImportObjectContextEntry("pm.PersonType", 2071)]);

    this.defaultDto = new PmPersonDto();
    this.defaultDto.firstName = "Jane";
    this.defaultDto.lastName = "Doe";
    //this.defaultDto.personTypeDtoId = 2071;
    this.defaultDto.gender = 2;

    this.defaultDto.personDetailDto = new PmPersonDetailDto();
    this.defaultDto.personDetailDto.birthName = "Miller";
    this.defaultDto.personDetailDto.maritalStatus = 1;


    this.formOverwrite = new MvsFormControlOverwrite();
    //this.formOverwrite.addField("personTypeDtoId", MvsFormFieldAccessEnum.HIDDEN);
    this.formOverwrite.addField("gender", MvsFormFieldAccessEnum.READ);
    this.formOverwrite.addField("firstName", MvsFormFieldAccessEnum.READ);
    this.formOverwrite.addField("lastName", MvsFormFieldAccessEnum.READ);

    const subFormOverwrite = this.formOverwrite.addSubForm("personDetailDto");
    subFormOverwrite.addField("birthName", MvsFormFieldAccessEnum.READ);


    this.objectIdentifierTicket = new ObjectIdentifier("tm.Ticket", 35);
    //this.objectIdentifierTicket = new ObjectIdentifier("tm.Ticket", 20931);
    this.objectIdentifierAgent = new ObjectIdentifier("am.Agent", 25311);
    this.objectIdentifierProcess = new ObjectIdentifier("wf.WfProcess", 8);


    this.initialized = true;

  }

  onSearch(event:any) {
    this.testSearch();
  }

  async testSearch() {

    let dtoListPromise = await this.searchService.searchEntity("ui.UiPage", this.searchTerm);

    this.widgetDataList = [];

    for (let entry of dtoListPromise.entries) {

      const widgetBasicData = new WidgetData();
      widgetBasicData.idAlias = "test.dummy.123";
      widgetBasicData.name = "Found Page";
      widgetBasicData.uiComponent = "data";
      widgetBasicData.dataProvider = "list";
      widgetBasicData.dataSource = "entity";
      widgetBasicData.dataProviderObject = "ui.UiPage";
      widgetBasicData.setParamValue("objectId", entry.id);

      this.widgetDataList.push(widgetBasicData);

    }


    console.log(dtoListPromise);

  }

  onAnalyzeDocument() {

    this.documentService.analyze(19533).subscribe(value => {
      console.log(value);
    });

  }

  onGetDocumentForm() {
    this.documentService.getFormData(20595).subscribe(value => {
      console.log(value);
    });
  }


  /*
  onAssessCustomer() {

    this.assessService.assessCustomer(84538).subscribe((value: CmCustomerAssessmentDto) => {
      this.customerAssessment = value;
    });
  }
  */


}
