
<ng-container *ngIf="initialized">

    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2 full-w">
                <span class="font-bold">Selektion</span>
            </div>
        </ng-template>
        <div class="grid">
            <div class="col-12">

                <mvs-form [formGroup]="selectionFormGroup">
                </mvs-form>

                <p-button label="Suchen" (onClick)="updateResult($event)"></p-button>

            </div>
        </div>
    </p-panel>

    <mvs-widget *ngIf="widgetDocuments" [widgetData]="widgetDocuments"></mvs-widget>

</ng-container>







