import { Component, OnInit } from '@angular/core';
import {MvsSearchService} from "@kvers/alpha-ui";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-dm-document-type-page',
  templateUrl: './dm-dashboard-overview.page.html',
  styleUrls: ['./dm-dashboard-overview.page.scss']
})
export class DmDashboardOverviewPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  widgetDocumentStatus: WidgetData;
  widgetDocumentTypeHistory: WidgetData;
  widgetDocumentTypes: WidgetData;
  widgetMimeTypes: WidgetData;

  defaultLabel: string = "Document Dashboard";

  constructor(
      protected searchService: MvsSearchService,
      protected objectTypeService: ObjectTypeService,
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService
  ) {
    super(route, coreService);
  }

  ngOnInit(): void {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'dm.DmDashboard',
          'level'             : 1,
          'parentBinding'     : 'none'
        }
      ]
    };

    // set-up widgets
    this.refreshWidgetDocumentTypeHistory();
    this.refreshWidgetStatuses();
    this.refreshWidgetDocumentTypes();
    this.refreshWidgetMimeTypes();


  }

  refreshWidgetMimeTypes() {

    this.widgetMimeTypes = new WidgetData();
    this.widgetMimeTypes.id = 1;
    this.widgetMimeTypes.idAlias = "dm.dashboard.category.mimetypes";
    this.widgetMimeTypes.name = "Mime Types";
    this.widgetMimeTypes.uiComponent = "category";
    this.widgetMimeTypes.dataProvider = "list";
    this.widgetMimeTypes.dataSource = "entity.groupBy";
    this.widgetMimeTypes.dataProviderObject = "dm.DmDocument";
    this.widgetMimeTypes.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["mimeType"],
        [ new ObjectRequestListAttribute("mimeType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetMimeTypes.setParamValue("fieldCategory", "mimeType");
    this.widgetMimeTypes.setParamValue("fieldCategoryCount", "mimeType_count");
    this.widgetMimeTypes.setParamValue("selectionMode", "single");
    this.widgetMimeTypes.setParamValue("size", "S");

  }

  refreshWidgetDocumentTypes() {

    this.widgetDocumentTypes = new WidgetData();
    this.widgetDocumentTypes.id = 1;
    this.widgetDocumentTypes.idAlias = "dm.dashboard.category.documenttypes";
    this.widgetDocumentTypes.name = "Document Types";
    this.widgetDocumentTypes.uiComponent = "category";
    this.widgetDocumentTypes.dataProvider = "list";
    this.widgetDocumentTypes.dataSource = "entity.groupBy";
    this.widgetDocumentTypes.dataProviderObject = "dm.DmDocument";
    this.widgetDocumentTypes.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["dmDocumentType"],
        [ new ObjectRequestListAttribute("dmDocumentType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetDocumentTypes.setParamValue("fieldCategory", "dmDocumentType");
    this.widgetDocumentTypes.setParamValue("fieldCategoryCount", "dmDocumentType_count");
    this.widgetDocumentTypes.setParamValue("selectionMode", "single");
    this.widgetDocumentTypes.setParamValue("size", "S");

  }



  refreshWidgetStatuses() {

    this.widgetDocumentStatus = new WidgetData();
    this.widgetDocumentStatus.id = 1;
    this.widgetDocumentStatus.idAlias = "dm.dashboard.category.status";
    this.widgetDocumentStatus.name = "Status";
    this.widgetDocumentStatus.uiComponent = "category";
    this.widgetDocumentStatus.dataProvider = "list";
    this.widgetDocumentStatus.dataSource = "entity.groupBy";
    this.widgetDocumentStatus.dataProviderObject = "dm.DmDocument";
    this.widgetDocumentStatus.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["status"],
        [ new ObjectRequestListAttribute("status", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetDocumentStatus.setParamValue("fieldCategory", "status");
    this.widgetDocumentStatus.setParamValue("fieldCategoryCount", "status_count");
    this.widgetDocumentStatus.setParamValue("selectionMode", "single");
    this.widgetDocumentStatus.setParamValue("size", "S");

  }


  refreshWidgetDocumentTypeHistory() {

    this.widgetDocumentTypeHistory = new WidgetData();
    this.widgetDocumentTypeHistory.id = 1;
    this.widgetDocumentTypeHistory.idAlias = "dm.dashboard.history.documenttypes";
    this.widgetDocumentTypeHistory.name = "Anlage Dokumenttypen";
    this.widgetDocumentTypeHistory.uiComponent = "history.chart";
    this.widgetDocumentTypeHistory.dataProvider = "list";
    this.widgetDocumentTypeHistory.dataSource = "entity.groupBy";
    this.widgetDocumentTypeHistory.dataProviderObject = "dm.DmDocument";
    this.widgetDocumentTypeHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["dmDocumentType", "createdDate"],
        [ new ObjectRequestListAttribute("dmDocumentType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetDocumentTypeHistory.setParamValue("fieldCategory", "dmDocumentType");
    this.widgetDocumentTypeHistory.setParamValue("fieldCategoryCount", "dmDocumentType_count");
    this.widgetDocumentTypeHistory.setParamValue("historyPeriodMode", "month");
    this.widgetDocumentTypeHistory.setParamValue("fieldCategory", "dmDocumentType");
    this.widgetDocumentTypeHistory.setParamValue("fieldDate", "createdDate");
    this.widgetDocumentTypeHistory.setParamValue("selectionMode", "single");
    this.widgetDocumentTypeHistory.setParamValue("size", "S");

  }



}
