import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiServiceMeInterface} from "@kvers/alpha-core-common";
import {Observable, of, tap} from "rxjs";
import {AmSearchFlexibleAgentComponent} from "../../search/am-search-flexible-agent/am-search-flexible-agent.component";
import {AgentDto} from "../../dto/agent.dto";

@Injectable({
  providedIn: 'root'
})
export class AgentService extends MvsCrudService implements MvsApiServiceMeInterface{

  private cachedAgent: AgentDto | null = null;

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/am/agents');
  }

  /**
   * Get user's agent assignment.
   */
  // me(): Observable<AgentDto> {
  //   const url = `${this.apiUrl}/me`;
  //   return <Observable<AgentDto>>this.getInternal(url);
  // }

  me(): Observable<AgentDto> {
    if (this.cachedAgent) {
      // Return cached data if already fetched
      return of(this.cachedAgent);
    }

    // Otherwise, fetch from API and store it
    const url = `${this.apiUrl}/me`;
    return this.getInternal(url).pipe(
        tap(agent => this.cachedAgent = agent) // Save fetched data
    );
  }

  getFlexibleSearchComponent(): Type<any> {
    return AmSearchFlexibleAgentComponent;
  }

}
