import {Route, Routes} from "@angular/router";
import {ChannelConfigPage} from "./page/overview/channel-config/channel-config.page";
import {CoOverviewPage} from "./page/co-overview-page/co-overview.page";

export function CoSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        // {path: 'co', component: CoOverviewPage},
        {path: 'co/config', component: ChannelConfigPage},

    ];

    return routes;

}
