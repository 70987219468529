import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class CustomerAddressService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerAddresses');
  }

  public getAddressComplexSelection(): ObjectRequestComplex {

    return ObjectRequestComplex.build(true, false,
        ObjectRequestComplexNode.createSimpleAttributeNode("address"),
    );
  }

}
