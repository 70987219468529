
import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {CustomerDto} from "../../../dto/customer.dto";
import {CustomerRelatedPersonDto} from "../../../dto/customer-related-person.dto";
import {MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../dto/customer-contract.dto";

export class CrCustomerRelatedPersonWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;

    constructor(protected configService: MvsConfigService,
                protected mainCustomer: CustomerDto,
                protected relatedPersons: CustomerRelatedPersonDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    public hasRelatedPersons() : boolean {
        return (this.relatedPersons && this.relatedPersons.length > 0);
    }

    public getRelatedPersons() : CustomerRelatedPersonDto[] {
        return this.relatedPersons;
    }

    getRelatedPerson(id: number) : CustomerRelatedPersonDto {
        const dto: CustomerRelatedPersonDto = this.relatedPersons.find(item => item.id == id);
        return dto;
    }

    addRelatedPerson(relatedPerson: CustomerRelatedPersonDto,customerId: number) {

        if(!customerId){
            return;
        }

        if(!relatedPerson['__color']) {
            const nextColor = this.configColors.getNextColor();
            relatedPerson['__color'] = nextColor + '80';
            relatedPerson['__backgroundColor'] = nextColor + '20';
        }
        this.relatedPersons.push(relatedPerson);
        this.relatedPersons = structuredClone(this.relatedPersons);


    }

    removeRelatedPerson(relatedPerson: CustomerRelatedPersonDto) {
        const index = this.relatedPersons.findIndex(item => item.id == relatedPerson.id);
        if(index > -1){
            this.relatedPersons.splice(index,1);
        }
    }


    getRowStyle(rowDto: CustomerContractDto, rowData: any): object {

        if (rowDto.customerDtoId == this.mainCustomer.id) {
            return {};
        } else {

            for (let myRelatedPerson of this.relatedPersons) {
                // check if this related person is a customer

                if (!myRelatedPerson.personDto) {
                    continue;
                }

                let customerId: number;

                if (myRelatedPerson.personDto.customerDto) {
                    customerId = myRelatedPerson.personDto.customerDto.id;

                } else if (myRelatedPerson.personDto['relatedCustomer']) {
                    customerId = myRelatedPerson.personDto['relatedCustomer'].id;
                }

                if (customerId === rowDto.customerDtoId) {
                    return {
                        "border" : "2px",
                        "background-color" : myRelatedPerson['__color']
                    };
                }
            }
            return {};
        }
    }

    getListStyle(rowDto: CustomerContractDto, rowData: any): object {

        if (rowDto['__selected'] && !this.getRelatedPerson(rowDto.id)) {
            //if not exist in relatedPersons then no style is applied
            rowDto['__selected'] = false;
            return {};

         } else if (this.getRelatedPerson(rowDto.id)) {
            //widget is refreshed and we are again applying the previous style
            const relatedPerson = this.getRelatedPerson(rowDto.id);
            return   {
                "border-left" : "4px solid " + relatedPerson['__color'],
                "border-radius" : '6px',
                "display" : 'block'
            };

        } else if (rowDto['__color'] && rowDto['__selected']) {
          return   {
              "border-left" : "4px solid " + rowDto['__color'],
              "border-radius" : '6px',
              "display" : 'block'
          };

        } else if (rowDto.customerDtoId == this.mainCustomer.id) {
            return {};

        } else {

            for (let myRelatedPerson of this.relatedPersons) {
                // check if this related person is a customer

                if (!myRelatedPerson.personDto) {
                    continue;
                }

                let customerId: number;

                if (myRelatedPerson.personDto.customerDto) {
                    customerId = myRelatedPerson.personDto.customerDto.id;

                } else if (myRelatedPerson.personDto['relatedCustomer']) {
                    customerId = myRelatedPerson.personDto['relatedCustomer'].id;
                }

                if (customerId === rowDto.customerDtoId) {
                    return {
                        "border-left" : "4px solid " + myRelatedPerson['__color'],
                        "border-radius" : '6px',
                        "display" : 'block'
                    };
                }
            }
            return {};
        }
    }

    getHeaderStyle(){
        let headerStyle: object = {};
        if (this.selectedContractCustomerId) {
            for (const item of this.relatedPersons) {
                if(item.personDto.customerDto.id == this.selectedContractCustomerId) {
                    headerStyle =  {'background-color' : item['__color']} ;
                }
            }
        }
        return headerStyle;
    }

    setSelectedPerson(customerId: number) {
        this.selectedContractCustomerId = customerId;
    }

    getStyleObjectForAvatar(relatedPerson: CustomerRelatedPersonDto) {

        const customerId = relatedPerson.personDto?.customerDto?.id;

        let avatarStyle = {};

        if (!customerId) {
            avatarStyle = {"background-color": "var(--surface-500)", "border" : "1px solid #8080800f",
                'opacity':'0.65', 'font-weight' : 'bold'};

        } else if (!relatedPerson['__selected']) {
            avatarStyle = {"background-color": "var(--primary-100)", "border" : "2px solid #a5a5a5d6", 'cursor': 'pointer'};

        } else {
            avatarStyle = {"background-color": relatedPerson['__color'], "border" : "2px solid"+ relatedPerson['__color'], 'cursor': 'pointer'};
        }

        return avatarStyle;

    }

    public clone(): CrCustomerRelatedPersonWidgetStyle {

        const widgetStyle = new CrCustomerRelatedPersonWidgetStyle(this.configService,this.mainCustomer, this.relatedPersons);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;

        return widgetStyle;
    }

}
