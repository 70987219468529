<ng-container *ngIf="initialized">

    <p-dialog
            (onShow)="showDialogMaximized($event,dialogBox)" #dialogBox
            header="Header"
            [style]="{width: widthSize,height: '600px'}"
            [(visible)]="visible"
            (onHide)="onDialogHide()"
            appendTo="body"
            [maximizable]="maximizable"
    >
        <ng-template pTemplate="header">
            <div class="flex justify-content-between align-items-center w-full">
                <span class="font-semibold text-xl">Document</span>
                <p-toggleButton *ngIf="document?.analyzed" [(ngModel)]="toggleDocument" (click)="loadDocumentPdf()"
                                onLabel="HTML View"
                                offLabel="PDF View"></p-toggleButton>
            </div>
        </ng-template>

        <!-- Document HTML View-->
        <ng-container *ngIf="toggleDocument && document.analyzed && formData">
            <app-form-recognizer
                    id="top"
                    [formData]="formData"
                    [viewOptions]="viewOptions"
            ></app-form-recognizer>
        </ng-container>

        <!-- Document PDF View-->
        <ng-container *ngIf="!toggleDocument || !document.analyzed">
            <object class="w-full h-full" [data]="documentPdfSource" type="application/pdf"
                    *ngIf="this.documentPdfSource">
                <embed [src]="documentPdfSource" type="application/pdf"/>
            </object>
        </ng-container>

    </p-dialog>
</ng-container>
