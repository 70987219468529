<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-4 flex flex-column gap-2">

            <mvs-widget  class="mvs-widget h-full" *ngIf="customerTagsWidgetData"
                        [widgetData]="customerTagsWidgetData"
            >
            </mvs-widget>

        </div>

        <div class="col-8 flex flex-column gap-2">

            <mvs-widget class="mvs-widget h-full" *ngIf="customerCommentsWidgetData"
                        [widgetData]="customerCommentsWidgetData"
            >
            </mvs-widget>

        </div>



    </div>

</ng-container>