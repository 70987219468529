import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {
    DtoImportObjectContextEntry,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestRelation,
    WidgetData, WidgetHelperButton
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {W} from "@fullcalendar/core/internal-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerAddressService} from "../../../../service/api/customer-address.service";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {CustomerChannelService} from "../../../../service/api/customer-channel.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerInsurableObjectService} from "../../../../service/api/customer-insurable-object.service";
import {CustomerRelatedPersonService} from "../../../../service/api/customer-related-person.service";
import {CustomerRelatedPersonDto} from "../../../../dto/customer-related-person.dto";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../../dto/customer-contract.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {PersonRelationPersonDto} from "../../../../../pm/dto/person-relation-person.dto";
import {ContractPersonRelationPersonWidgetStyle} from "./uiStyle/contract-person-relation-person-widget-style";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {InsurableObjectWidgetStyle} from "./uiStyle/insurable-object-widget-style";
import {HouseholdPersonService} from "../../../../../pm/service/api/household-person.service";
import {HouseholdPersonDto} from "../../../../../pm/dto/household-person.dto";
import {
    MouseHoverEvent
} from "@kvers/alpha-ui/lib/widget/component/runtime/implementations/mvs-widget-list-data-expandable/data/mouse-hover-event";
import {PersonRelationPersonService} from "../../../../../pm/service/api/person-relation-person.service";


@Component({
    selector: 'cr-customer-contracts-navigation',
    templateUrl: './cr-customer-contracts-navigation.component.html',
})
export class CrCustomerContractsNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;
    @Input() customerRelatedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;

    selectedRelatedPersons: CrCustomerRelatedPersonWidgetStyle;
    customerContractWidgetData: WidgetData;
    customerInsurableObjectsWidgetData: WidgetData;
    personRelationPersonWidget: WidgetData;
    customerRelatedPersonWidget: WidgetData;
    customerHouseholdPerson: WidgetData;
    contractFilters: FilterCriteria[];
    filterCriteria: FilterCriteria[] = [];
    householdDtoIds: number[];

    widgetButtons: WidgetHelperButton[] = [
        {
            label: null,
            icon: 'fa-sharp fa-regular fa-check-double',
            display: true,
            action: 'selectAll',
            tooltip: 'Select All'

        }
    ];


    contractObjectContext: DtoImportObjectContext;
    personRelationPersonObjectContext: DtoImportObjectContext;

    contractPersonWidgetStyle: ContractPersonRelationPersonWidgetStyle;

    // selectedInsurableObjectWidgetStyle: InsurableObjectWidgetStyle;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected customerService: CustomerService,
        protected customerContractService: CustomerContractService,
        protected customerInsurableObjectsService: CustomerInsurableObjectService,
        protected configService: MvsConfigService,
        protected householdPersonService: HouseholdPersonService,
        protected personRelationPersonService: PersonRelationPersonService,
        protected customerRelatedPersonService: CustomerRelatedPersonService) {
        super(coreService, confirmationService, messageService, configService, navigationService);

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.contractPersonWidgetStyle = new ContractPersonRelationPersonWidgetStyle(this.configService, this.dto, []);
        // this.selectedInsurableObjectWidgetStyle = new InsurableObjectWidgetStyle(this.configService, this.dto, []);
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.contractObjectContext = this.importObjectContext;
        this.personRelationPersonObjectContext = new DtoImportObjectContext([new DtoImportObjectContextEntry('cr.Customer', this.dto.personDtoId)]);
        this.prepareHouseholdWidgetStyle();
        this.getHousehold();
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }

        if (changes["customerRelatedPersonWidgetStyle"]) {
            this.setCustomerRelatedPerson();
        }
    }

    prepareHouseholdWidgetStyle() {
        for (let item of this.householdPersons) {
            this.contractPersonWidgetStyle.handleHouseholdPerson(item);
        }
    }

    getHousehold() {
        const objectRequest = new ObjectRequestList(false, [FilterCriteria.create('person', FilterCriteria.cOperatorEqual, this.dto.personDtoId)], []);
        this.householdPersonService.list(objectRequest).subscribe((res: DtoList<HouseholdPersonDto>) => {
            this.prepareHouseholdIds(res.entries);
        })
    }

    prepareHouseholdIds(entries: HouseholdPersonDto[]) {
        this.householdDtoIds = [];
        let filterCriteria = new FilterCriteria();
        for (let item of entries) {
            const newFilter = FilterCriteria.create('household', FilterCriteria.cOperatorEqual, item.householdDtoId);
            filterCriteria.addFilterCriteriaToOr(newFilter);
        }
        this.showCustomerHouseholdPerson(filterCriteria);


    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {
        this.showAllCustomerContracts();
        this.showCustomerInsurableObjects();
        this.getCustomerRelatedPerson();
        this.showRelationPersonRelation();
    }

    setCustomerRelatedPerson() {
        this.selectedRelatedPersons = this.customerRelatedPersonWidgetStyle;
        this.refreshComponent();
    }

    showAllCustomerContracts(filter?: FilterCriteria[]) {
        this.customerContractWidgetData = new WidgetData();
        if (this.selectedRelatedPersons && this.selectedRelatedPersons.hasRelatedPersons()) {
            this.customerContractWidgetData.idAlias = "cr.customer.basic.contract.multi";
        } else {
            this.customerContractWidgetData.idAlias = "cr.customer.basic.contract";
        }
        this.customerContractWidgetData.name = "Verträge";
        this.customerContractWidgetData.uiComponent = "table";
        this.customerContractWidgetData.dataProvider = "list";
        this.customerContractWidgetData.dataSource = "entity";
        this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";

        let filterCriteria: FilterCriteria[];

        if (!filter || !filter.length) {
            filterCriteria = this.getCustomerRelatedPersonFilter();
        } else {
            filterCriteria = filter;
        }


        this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [new Sorting('lastModifiedDate', false)],
            PagingDto.create(0, 5)
        );

        this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCustomerContractsComplexSelection(this.dto.personDtoId);
    }

    getCustomerRelatedPerson() {
        this.customerRelatedPersonWidget = new WidgetData();
        if (this.selectedRelatedPersons && this.selectedRelatedPersons.hasRelatedPersons()) {
            this.customerRelatedPersonWidget.idAlias = "cr.customer.contract.navigation.show.related.persons.object.multi";
        } else {
            this.customerRelatedPersonWidget.idAlias = "cr.customer.contract.navigation.show.related.persons.object";
        }
        this.customerRelatedPersonWidget.name = "Verwandte Personen";
        this.customerRelatedPersonWidget.uiComponent = "list";
        this.customerRelatedPersonWidget.dataProvider = "list";
        this.customerRelatedPersonWidget.dataSource = "entity";
        this.customerRelatedPersonWidget.dataProviderObject = "cr.CustomerRelatedPerson";

        let filter = this.getCustomerRelatedPersonFilter();
        this.customerRelatedPersonWidget.dataProviderListRequest = this.customerRelatedPersonService.getCustomerRelatedPersonWithFilterComplexRequest(filter, true);
        this.customerRelatedPersonWidget.functionCallbacks = this.widgetProcessToolbarCreate();

    }

    showCustomerInsurableObjects() {
        this.customerInsurableObjectsWidgetData = new WidgetData();
        if (this.selectedRelatedPersons && this.selectedRelatedPersons.hasRelatedPersons()) {
            this.customerInsurableObjectsWidgetData.idAlias = "cr.customer.contract.navigation.insurable-objects.multi";
        } else {
            this.customerInsurableObjectsWidgetData.idAlias = "cr.customer.contract.navigation.insurable-objects";
        }
        // this.customerInsurableObjectsWidgetData.idAlias = "cr.customer.basic.insurable-objects";
        this.customerInsurableObjectsWidgetData.name = "Versicherte Objekte";
        this.customerInsurableObjectsWidgetData.uiComponent = "listDataExpandable";
        this.customerInsurableObjectsWidgetData.dataProvider = "list";
        this.customerInsurableObjectsWidgetData.dataSource = "entity";
        this.customerInsurableObjectsWidgetData.dataProviderObject = "cr.CustomerInsurableObject";
        this.customerInsurableObjectsWidgetData.noDataText = "Keine versicherten Objekte vorhanden";

        let filterCriteria = this.getCustomerRelatedPersonFilter();

        this.customerInsurableObjectsWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)]
        );

        this.customerInsurableObjectsWidgetData.dataProviderListRequest.objectRequestComplex =
            this.customerInsurableObjectsService.getCustomerInsurableObjectsComplexSelection();

        this.customerInsurableObjectsWidgetData.setParamValue("size", "S");
        this.customerInsurableObjectsWidgetData.setParamValue("selectionMode", "rowSelect");
        this.customerInsurableObjectsWidgetData.setParamValue("objectWidget", "data");
        this.customerInsurableObjectsWidgetData.setParamValue("columns", "3");
    }

    showRelationPersonRelation() {
        const filter = this.personRelationPersonService.getPersonRelationFilterOr(this.dto.personDtoId);
        this.personRelationPersonWidget = WidgetFactory.createWidgetList(
            'cr.customer.contract.navigation.related.persons.object.pm.related.person.basic',
            'Person Related Person',
            'listDataExpandable',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'No data found',
            new ObjectRequestList(true,
                [filter],
                [])
        );
        this.personRelationPersonWidget.functionCallbacks = this.personRelationPersonToolbar();
    }

    showCustomerHouseholdPerson(filter: FilterCriteria) {
        this.customerHouseholdPerson = WidgetFactory.createWidgetList(
            'cr.customer.contract.navigation.customer.household.person.1',
            'Household Persons',
            'listDataExpandable',
            'list',
            'entity',
            'pm.HouseholdPerson',
            'No data found',
            new ObjectRequestList(true,
                [filter],
                [])
        );
        this.customerHouseholdPerson.dataProviderListRequest.objectRequestComplex = this.getComplexRequest();
    }

    getComplexRequest(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode("person").addNodes(
                ObjectRequestComplexNode.createRelationNode('customerDto', ObjectRequestRelation.createJoin('+crCustomer'))
            )
        );
    }

    getCustomerRelatedPersonFilter(): FilterCriteria[] {
        let filterCriteria = new Array<FilterCriteria>();
        const mainCustomerFilterCriteria = FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        if (this.selectedRelatedPersons && this.selectedRelatedPersons.hasRelatedPersons()) {

            const orFilter = new FilterCriteria();
            orFilter.addFilterCriteriaToOr(mainCustomerFilterCriteria);

            const relatedPersons = this.selectedRelatedPersons.getRelatedPersons();

            for (const item of relatedPersons) {
                // make sure that this person has a customer record
                let customerId: number = null;
                if (item.personDto['relatedCustomer']) {
                    customerId = item.personDto['relatedCustomer'].id;

                } else if (item.personDto['customerDto']) {
                    customerId = item.personDto['customerDto'].id;
                }
                if (item.personDto && customerId) {
                    const fieldCriteria = new FilterCriteria();
                    fieldCriteria.field = 'customer';
                    fieldCriteria.value = customerId;
                    orFilter.addFilterCriteriaToOr(FilterCriteria.create(fieldCriteria.field, FilterCriteria.cOperatorEqual, fieldCriteria.value, null));
                }
            }

            filterCriteria.push(orFilter);

        } else {
            filterCriteria.push(mainCustomerFilterCriteria);
        }

        return filterCriteria;
    }


    onRemoveRelatedPersonFilter(person: CustomerRelatedPersonDto) {
        this.selectedRelatedPersons.removeRelatedPerson(person);
        this.selectedRelatedPersons = this.selectedRelatedPersons.clone();
        this.refreshComponent();
    }

    prepareFilterCriteria(field: string, objectId: number, label: string) {
        if (!this.contractFilters) {
            this.contractFilters = [];
        }

        const found = this.contractFilters.find(item => item.field == field && item.value == objectId);
        if (found) {
            return;
        }
        const filter: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectId);
        this.contractFilters.push(filter);

        this.onSetFilterCriteria();

    }

    onSetFilterCriteria() {

        const filterCriteria = [];
        const andFilter = new FilterCriteria();

        for (const item of this.contractFilters) {
            const fieldCriteria = new FilterCriteria();
            fieldCriteria.field = item.field;
            fieldCriteria.value = item.value;
            andFilter.addFilterCriteriaToAnd(FilterCriteria.create(fieldCriteria.field, FilterCriteria.cOperatorEqual, fieldCriteria.value, null));
        }
        filterCriteria.push(andFilter);
        this.showAllCustomerContracts(filterCriteria);
    }


    handleRelatedPerson(object: ObjectIdentifierData) {
        if (!object.data.personDto.customerDto) {
            return;
        }

        const objectId = object.data.personDto.customerDto.id;
        const field = 'customer';
        const label = object.data.personDtoName;
        this.prepareFilterCriteria(field, objectId, label)

    }

    handleHoverPersonRelationPerson(event: MouseHoverEvent) {
        if (event.mouseBehaviour == 'enter') {
            if (event.dto['personADtoId'] == this.dto.personDtoId) {
                this.contractPersonWidgetStyle.selectedId = event.dto['personBDtoId'];
            } else {
                this.contractPersonWidgetStyle.selectedId = event.dto['personADtoId'];
            }
        } else {
            this.contractPersonWidgetStyle.selectedId = null;
        }
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }

    handleHoverInsurableObject(event: MouseHoverEvent) {
        if (event.mouseBehaviour == 'enter') {
            this.contractPersonWidgetStyle.selectedId = event.dto['insurableObjectDto'].id;
        } else {
            this.contractPersonWidgetStyle.selectedId = null;
        }
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }

    handleRelationPersonSelect(object: ObjectIdentifierData) {
        this.contractPersonWidgetStyle.handleHouseholdPerson(object.data);
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }

    handleInsurableObjectSelect(object: ObjectIdentifierData) {
        // this.selectedInsurableObjectWidgetStyle.handleInsurableObject(object.data);
        // this.selectedInsurableObjectWidgetStyle = this.selectedInsurableObjectWidgetStyle.clone();
    }

    widgetProcessToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.dto.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);
                formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    personRelationPersonToolbar() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = this.dto.personDtoId;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    handleHouseholdPersonSelect(event: ObjectIdentifierData) {
        this.contractPersonWidgetStyle.handleHouseholdPerson(event.data);
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }

    handleContractPersonToolbarClick(button: WidgetHelperButton) {
        if (button.action == 'selectAll') {
            // select all household persons

            for (let item of this.householdPersons) {
                const hasHouseholdPerson = this.contractPersonWidgetStyle.getHouseholdPerson(item.id);
                if (!hasHouseholdPerson) {
                    this.contractPersonWidgetStyle.addHouseholdPerson(item);
                }
            }


        }
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }


}
