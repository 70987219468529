import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from "@angular/core";
import {
    FilterCriteria,
    MvsCoreService, MvsMessageService,
    ObjectBaseComponent,
    ObjectRequestList, ObserverService, PagingDto,
    WidgetData,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ReportService} from "../../service/api/report.service";
import {ReportModel} from "../../dto/report-model.dto";
import {CategoryModel} from "../../dto/category.dto";

@Component({
    selector: "rp-report-component",
    templateUrl: "./rp-report.component.html",
    styleUrls: ["./rp-report.component.scss"],
})
export class RpReportComponent extends ObjectBaseComponent
    implements OnInit, OnChanges {

    initialized: boolean;
    reportWidgetData: WidgetData;
    toolbarButtons: WidgetHelperButton[];

    @Input() module: string;
    @Input() categoryId: number;
    @Input() reportId: number;
    @Input() report: ReportModel;
    @Input() category: CategoryModel;
    @Input() refreshTrigger: number | string | boolean;
    @Input() roleBasedFilter: FilterCriteria[];

    @Output() onDataLoaded = new EventEmitter<void>();

    filterCriteria: FilterCriteria[];

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected observerService: ObserverService,
        protected reportService: ReportService,
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit() {

        if (this.params) {
            this.module = this.params.module;
            this.reportId = Number(this.params.report);
            this.filterCriteria = this.params.filters;
            this.getCategory();
            this.getReport();
        }

        this.initToolbarButtons();

        this.refresh();
        this.initialized = true;
    }

    initToolbarButtons() {
        this.toolbarButtons = [{
            icon: 'fa-solid fa-download',
            label: '',
            display: true,
            action: 'download',
            tooltip: 'download'
        }];
    }

    getCategory() {
        this.reportService.getCategories(this.module).subscribe(res => {
            this.category = res.find(category => category.id === this.categoryId);
        });
    }

    getReport() {
        this.reportService.getReports(this.module, this.categoryId).subscribe(res => {
            this.report = res.find(report => report.id === this.reportId);
        });
    }

    /**
     * On Object Change.
     */
    refresh() {

        let filter = this.filterCriteria;

        if (this.roleBasedFilter) {
            if (!filter) {
                filter = [];
            }
            filter.push(...this.roleBasedFilter);
        }

        const objectRequest = ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 80));
        const dataProviderObject = `${this.module}.${this.categoryId}.${this.reportId}`

        this.reportWidgetData = WidgetFactory.createWidgetTableEntity(
            `rp.report.${this.module}.${this.reportId}`,
            'Report',
            dataProviderObject,
            'No Record',
            objectRequest);

        this.reportWidgetData.dataSource = "report";

    }

    handleToolbarClick(event: WidgetHelperButton) {

        if (event.action === 'download') {


            if (this.busy) {
                return;
            }
            this.busy = true;

            this.toolbarButtons[0].icon = 'fa-sharp-duotone fa-solid fa-loader fa-spin';
            // const objectRequest = ObjectRequestList.createBasic(true, [], []);
            const objectRequest = this.reportWidgetData.dataProviderListRequest;

            this.reportService.download(this.module, this.reportId, objectRequest)
                .subscribe((blob: Blob) => {

                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    const today = new Date();
                    const formattedDate = `${String(today.getDate()).padStart(2, '0')}_${String(today.getMonth() + 1).padStart(2, '0')}_${today.getFullYear()}`;

                    link.download = `${this.module}_${this.category.name}_${this.report.name}_${formattedDate}.xlsx`;
                    link.click();

                    // revoke the URL to free memory
                    URL.revokeObjectURL(blobUrl);


                    this.toolbarButtons[0].icon = 'fa-solid fa-download';
                    this.busy = false;
                }, error => {

                    this.toolbarButtons[0].icon = 'fa-solid fa-download';
                    this.busy = false;
                });
        }
    }


    handleDataLoad(event: boolean) {
        this.onDataLoaded.emit();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (!this.initialized) {
            return;
        }
        this.refresh();
    }

}

