import {Component, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges, EventEmitter} from '@angular/core';
import {DtoList, ObjectIdentifier, ObjectIdentifierData, WidgetData} from "@kvers/alpha-core-common";
import {TicketService} from "../../service/api/ticket.service";
import {TicketDto} from "../../dto/ticket.dto";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'tm-object-related-tickets',
    templateUrl: './tm-object-related-tickets.component.html',
    styleUrls: ['./tm-object-related-tickets.component.scss']
})
export class TmObjectRelatedTicketsComponent
    implements OnInit, OnChanges, OnDestroy {

    busy: boolean;
    initialized: boolean;

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() indirectTickets: boolean = true;

    @Output() onTicketSelect: EventEmitter<ObjectIdentifierData> = new EventEmitter<ObjectIdentifierData>()

    ticketsList: TicketDto[];
    objectRelatedTicketsWidget: WidgetData;


    constructor(protected ticketService: TicketService) {
    }

    ngOnInit(): void {
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

    }

    refreshComponent() {
        this.loadTickets();
    }

    loadTickets() {
        this.ticketService.listTicketObjects(this.objectIdentifier.objectId, this.objectIdentifier.objectType, this.indirectTickets).subscribe(res => {
            this.ticketsList = <TicketDto[]>res.entries;

            this.loadWidget(res)

            this.initialized = true;

        })
    }

    loadWidget(dtoList: DtoList) {
        this.objectRelatedTicketsWidget = WidgetFactory.createWidgetTransient(
            'tm.object.related.tickets.widget',
            "Tickets",
            'table',
            'transient',
            'entity',
            'tm.Ticket',
            dtoList,
        );
    }

    handleObjectSelect(event: ObjectIdentifierData) {

        this.onTicketSelect.emit(event);

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return
        }

        this.refreshComponent();
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {


    }

}
