import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {TicketTypePage} from "./page/overview/ticket-type/ticket-type.page";
import {TicketComponent} from "./component/ticket/ticket.component";
import {TicketDashboardPage} from "./page/overview/ticket-dashboard/ticket-dashboard.page";
import {UmModule} from "../um/um.module";
import { DmModule } from '../dm/dm.module';
import { HistoryComponent } from './component/history/history.component';
import { CcModule } from '../cc/cc.module';
import { TicketIndicatorComponent } from './component/ticket-indicator/ticket-indicator.component';
import { SubTicketComponent } from './component/sub-ticket/sub-ticket.component';
import { TicketHistoryComponent } from './component/ticket-comment-history/ticket-comment-history.component';
import { TicketWatchlistComponent } from './component/ticket-watchlist/ticket-watchlist.component';
import { TicketTypeActionComponent } from './component/ticket-type-action/ticket-type-action.component';
import { TicketDashboardMyTeamTicketsPageComponent } from './page/overview/ticket-dashboard-my-team-tickets/ticket-dashboard-my-team-tickets.page';
import {TicketObjectQuickViewComponent} from "./component/ticket-object-quick-view/ticket-object-quick-view.component";
import {TmObjectTicketsComponent} from "./component/object-tickets/tm-object-tickets.component";
import {TmActiveTicketComponent} from "./component/active-ticket/tm-active-ticket.component";
import {MyTicketsComponent} from "./component/my-tickets/my-tickets.component";
import {TmTicketTypeDashboardPage} from "./page/overview/tm-ticket-type-dashboard/tm-ticket-type-dashboard.page";
import {TmTicketOutputFieldComponent} from "./component/tm-ticket-output-field/tm-ticket-output-field.component";
import {
    TmTicketWfProcessStepActivityComponent
} from "./component/wf/tm-ticket-wf-process-step-activity/tm-ticket-wf-process-step-activity.component";
import {TicketHistoryPage} from "./page/overview/ticket-history/ticket-history.page";
import {
    TmTicketDocumentUploadComponent
} from "./component/tm-ticket-document-upload/tm-ticket-document-upload.component";
import {TmMeOverviewPage} from "./page/overview/me/tm-me-overview/tm-me-overview.page";
import {TmMeDashboardPage} from "./page/overview/me/tm-me-dashboard/tm-me-dashboard.page";
import {
    TmTicketAppointmentInlineComponent
} from "./component/tm-ticket-appointment-inline-component/tm-ticket-appointment-inline-component";
import {MvsCreateObjectTicketComponent} from "./component/create-object-ticket/mvs-create-object-ticket.component";
import {NsModule} from "../ns/ns.module";
import {
    TmTicketSelectPredefinedTaskComponent
} from "./component/ticket/ticket-components/tm-ticket-select-predefined-task/tm-ticket-select-predefined-task.component";
import {
    TmTicketCreateNewTaskComponent
} from "./component/ticket/ticket-components/tm-ticket-create-new-task/tm-ticket-create-new-task.component";
import {TmTicketCompletionComponent} from "./component/ticket/ticket-components/tm-ticket-completion/tm-ticket-completion.component";
import {TmTicketAttachmentComponent} from "./component/ticket/ticket-components/tm-ticket-attachement/tm-ticket-attachment.component";
import {
    TmTicketCommentHistoryComponent
} from "./component/ticket/ticket-components/tm-ticket-comment-history/tm-ticket-comment-history.component";
import {TmTicketAddSubTicketComponent} from "./component/ticket/ticket-components/tm-ticket-add-sub-ticket/tm-ticket-add-sub-ticket.component";
import {
    TmTicketSubNavigationTasksComponent,
} from "./component/ticket/ticket-components/tm-ticket-sub-navigation-tasks/tm-ticket-sub-navigation-tasks.component";
import {
    TmTicketLandingComponentsComponent
} from "./component/ticket/ticket-components/tm-ticket-landing-components/tm-ticket-landing-components.component";
import {
    TmTicketActionsComponent
} from "./component/ticket/ticket-components/tm-ticket-actions/tm-ticket-actions.component";
import {
    CreateTmTicketActionsComponent
} from "./component/ticket/ticket-components/create-tm-ticket-actions/create-tm-ticket-actions.component";
import {
    TicketTypeObjectComponent
} from "./component/object-component/ticket-type-object-component/ticket-type-object.component";
import {WfModule} from "../wf/wf.module";
import {
    TmObjectRelatedTicketsComponent
} from "./component/tm-object-related-tickets/tm-object-related-tickets.component";
import {TicketOverviewPage} from "./page/ticket-overview-page/ticket-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {
    TmStatisticsComponent
} from "./page/ticket-overview-page/components/statisticsComponent/tm-statistics.component";
import {TmKpiComponent} from "./page/ticket-overview-page/components/kpiComponent/tm-kpi.component";
import {UiModule} from "../ui/ui.module";
import {TicketQuickCreateComponent} from "./component/ticket-quick-create/ticket-quick-create.component";
import {TicketCompleteFormComponent} from "./component/ticket-complete-form/ticket-complete-form.component";
import {
    ObjectTicketGroupFilterComponent
} from "./component/object-ticket-group-filter/object-ticket-group-filter.component";
import {TicketManagementComponent} from "./component/ticket-management/ticket-management.component";
import {
    TicketFilterGroupListComponent
} from "./component/ticket-management/components/ticket-filter-group-list-component/ticket-filter-group-list.component";


@NgModule({
    imports: [
        CoreModule,
        UmModule,
        DmModule,
        CcModule,
        NsModule,
        WfModule,
        UiModule,
    ],

    declarations: [
        TicketTypePage,
        TicketComponent,
        TicketDashboardPage,
        HistoryComponent,
        TicketIndicatorComponent,
        SubTicketComponent,
        TicketHistoryComponent,
        TicketWatchlistComponent,
        TicketTypeActionComponent,
        TmMeOverviewPage,
        TicketDashboardMyTeamTicketsPageComponent,
        TicketObjectQuickViewComponent,
        TmObjectTicketsComponent,
        TmActiveTicketComponent,
        MyTicketsComponent,
        TmTicketTypeDashboardPage,
        TmTicketOutputFieldComponent,
        TmTicketWfProcessStepActivityComponent,
        TicketHistoryPage,
        TmTicketDocumentUploadComponent,
        TmMeDashboardPage,
        TmTicketAppointmentInlineComponent,
        MvsCreateObjectTicketComponent,
        TmTicketSelectPredefinedTaskComponent,
        TmTicketCreateNewTaskComponent,
        TmTicketCompletionComponent,
        TmTicketAttachmentComponent,
        TmTicketCommentHistoryComponent,
        TmTicketAddSubTicketComponent,
        TmTicketSubNavigationTasksComponent,
        TmTicketLandingComponentsComponent,
        TicketTypeObjectComponent,
        TmTicketLandingComponentsComponent,
        TmTicketActionsComponent,
        CreateTmTicketActionsComponent,
        TmObjectRelatedTicketsComponent,
        TicketOverviewPage,
        TmStatisticsComponent,
        TmKpiComponent,
        TicketQuickCreateComponent,
        TicketCompleteFormComponent,
        ObjectTicketGroupFilterComponent,
        TicketManagementComponent,
        TicketFilterGroupListComponent,
    ],
    exports: [
        TicketTypePage,
        TicketComponent,
        TicketDashboardPage,
        TicketObjectQuickViewComponent,
        TmObjectTicketsComponent,
        TmActiveTicketComponent,
        TmTicketOutputFieldComponent,
        TmTicketWfProcessStepActivityComponent,
        TicketHistoryPage,
        TmTicketDocumentUploadComponent,
        TmTicketAppointmentInlineComponent,
        MvsCreateObjectTicketComponent,
        TmTicketActionsComponent,
        CreateTmTicketActionsComponent,
        TmTicketSelectPredefinedTaskComponent,
        TicketHistoryComponent,
        TmObjectRelatedTicketsComponent,
        TicketQuickCreateComponent,
        TicketCompleteFormComponent,
        ObjectTicketGroupFilterComponent,
        TicketManagementComponent,
        TicketFilterGroupListComponent,
    ],

})
export class TmModule  implements DynamicModule {

    alias = 'tm';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return TmKpiComponent;
    }

    getStatisticsComponent() {
        return TmStatisticsComponent;
    }

}