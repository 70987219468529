import { Component } from '@angular/core';

@Component({
  selector: 'mvs-dm-document-analyze',
  templateUrl: './dm-document-analyze.component.html',
  styleUrls: ['./dm-document-analyze.component.scss']
})
export class DmDocumentAnalyzeComponent {

}
