import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {CallService} from "../../../../service/api/call.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'pc-kpi.component.html',
})
export class PcKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalCalls: number = 0;
    thisMonthCalls: number = 0;
    thisWeekCalls: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Call Module'}];


    constructor(
        protected callService: CallService,) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalCalls();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalCalls() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['callResult'],
            [new ObjectRequestListAttribute('callResult', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.callService.groupBy(objectRequestList).subscribe(res => {
            this.totalCalls = res.entries.reduce((acc, item) => acc + item['callResult_count'], 0);
            this.getThisMonthCalls();
        }, error => {
            this.getThisMonthCalls();
        });
    }

    getThisMonthCalls() {
        const objectRequestList = this.getThisMonthRequest('callResult');

        this.callService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthCalls = res.entries.reduce((acc, item) => acc + item['callResult_count'], 0);
            this.getThisWeekCalls();
        }, error => {
            this.getThisWeekCalls();
        });
    }

    getThisWeekCalls() {

        const objectRequestList = this.getThisWeekRequest('callResult');

        this.callService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekCalls = res.entries.reduce((acc, item) => acc + item['callResult_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Calls', value: this.totalCalls,
            },
            {
                label: 'This Month Calls', value: this.thisMonthCalls,
            },
            {
                label: 'This Week Calls', value: this.thisWeekCalls,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
