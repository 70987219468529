@if (initialized) {
    <div class="grid">


        <!-- Recent Contracts -->
        <div class="col-12 p-4">

            <div>

                <h5 *ngIf="!showSearch">Recent Contracts</h5>

                <h5 *ngIf="showSearch">Search Contracts</h5>
            </div>

            <div class="flex w-full align-items-center gap-3">

                <div *ngIf="!showSearch" class="w-full">
                    <p-dropdown
                            styleClass="w-full"
                            class="w-full"
                            [options]="recentContractList"
                            [(ngModel)]="contractId"
                            optionLabel="label"
                            optionValue="objectId"
                            placeholder="Select a recent contract"
                            (onChange)="handleObjectSelect(contractId)">
                    </p-dropdown>
                </div>

                <!-- Search Contracts -->
                <div *ngIf="showSearch" class="w-full">
                    <mvs-form-field-flexible-search
                            class="custom-autocomplete"
                            [formGroup]="formGroup"
                            [formField]="contractFormField"
                            (onObjectSelect)="handleObjectSelect($event)">
                    </mvs-form-field-flexible-search>
                </div>

                <!--                [label]="showSearch ? 'Search Contracts' : 'Recent Contracts'"-->
                <p-button
                        [icon]="showSearch ? 'pi pi-search' : 'pi pi-clock'"
                        [outlined]="!showSearch" (click)="toggleView()">

                </p-button>

                <p-button
                        label="Simulate"
                        (click)="handleContractSimulate(contractId)"
                        [disabled]="!contractId"
                ></p-button>

            </div>

        </div>


        <div class="col-12">
            <p-tabView *ngIf="provisionRequestRuntimeDto?.contracts?.entries?.length">
                <p-tabPanel *ngFor="let contract of provisionRequestRuntimeDto.contracts.entries"
                            [header]="'Contract: ' + contract.id">

                    <div class="grid">
                        <!--                        <div class="col-9">-->
                        <!--                            <div class="contract-details">-->
                        <!--                                <h3>Contract Details</h3>-->
                        <!--                                <p><strong>ID:</strong> {{ contract.id }}</p>-->
                        <!--                                <p><strong>Name:</strong> {{ contract.categoryDtoName }}</p>-->
                        <!--                                &lt;!&ndash;                        <p><strong>Description:</strong> {{ contract.description }}</p>&ndash;&gt;-->
                        <!--                            </div>-->

                        <!--                            <div class="contract-mappings">-->
                        <!--                                <h3>Provision Details</h3>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.contractProvisionGroupMap?.get(contract.id) as group">-->
                        <!--                                    <h4>Provision Group</h4>-->
                        <!--                                    <p><strong>Name:</strong> {{ group.name }}</p>-->
                        <!--                                    <p><strong>Description:</strong> {{ group.description }}</p>-->
                        <!--                                </div>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.contractProvisionCategoryMap?.get(contract.id) as category">-->
                        <!--                                    <h4>Provision Category</h4>-->
                        <!--                                    <p><strong>Name:</strong> {{ category.name }}</p>-->
                        <!--                                    <p><strong>Description:</strong> {{ category.description }}</p>-->
                        <!--                                    <p><strong>Entity Status:</strong> {{ category.entityStatus }}</p>-->
                        <!--                                    <p><strong>Requires Former-->
                        <!--                                        Contract:</strong> {{ category.requiresFormerContract ? 'Yes' : 'No' }}</p>-->
                        <!--                                </div>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.contractEligibilityMap?.get(contract.id) as eligibility">-->
                        <!--                                    <h4>Eligibility Status</h4>-->
                        <!--                                    <p>{{ eligibility }}</p>-->
                        <!--                                </div>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.provisionPartnerMap?.get(contract.id) as partner">-->
                        <!--                                    <h4>Provision Partner</h4>-->
                        <!--                                    <p><strong>Name:</strong> {{ partner.name }}</p>-->
                        <!--                                </div>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.calculatedProvisionTypeMap?.get(contract.id) as provisionType">-->
                        <!--                                    <h4>Calculated Provision Type</h4>-->
                        <!--                                    <p><strong>Name:</strong> {{ provisionType.name }}</p>-->
                        <!--                                    <p><strong>Description:</strong> {{ provisionType.description }}</p>-->
                        <!--                                    <p><strong>Entity Status:</strong> {{ provisionType.entityStatus }}</p>-->
                        <!--                                </div>-->

                        <!--                                <div *ngIf="provisionRequestRuntimeDto?.calculatedProvisionBagMap?.get(contract.id) as provisionBag">-->
                        <!--                                    <h4>Calculated Provision Bag</h4>-->
                        <!--                                    <div *ngIf="provisionBag.calculatedPartnerPayments.length">-->
                        <!--                                        <h5>Partner Payments</h5>-->
                        <!--                                        <ul>-->
                        <!--                                            <li *ngFor="let payment of provisionBag.calculatedPartnerPayments">-->
                        <!--                                                {{ payment | json }}-->
                        <!--                                            </li>-->
                        <!--                                        </ul>-->
                        <!--                                    </div>-->

                        <!--                                    <div *ngIf="provisionBag.calculatedAgentPayments.length">-->
                        <!--                                        <h5>Agent Payments</h5>-->
                        <!--                                        <ul>-->
                        <!--                                            <li *ngFor="let agentPayment of provisionBag.calculatedAgentPayments">-->
                        <!--                                                {{ agentPayment | json }}-->
                        <!--                                            </li>-->
                        <!--                                        </ul>-->
                        <!--                                    </div>-->

                        <!--                                    <div *ngIf="provisionBag.calculatedAgentOneTimePayments">-->
                        <!--                                        <h5>One-Time Agent Payments</h5>-->
                        <!--                                        <p>{{ provisionBag.calculatedAgentOneTimePayments | json }}</p>-->
                        <!--                                    </div>-->
                        <!--                                </div>-->

                        <!--                                &lt;!&ndash; Calculated Requests Section &ndash;&gt;-->
                        <!--                                <div *ngIf="getCalculatedRequests(contract.id)?.length">-->
                        <!--                                    <h3>Calculated Requests</h3>-->
                        <!--                                    <div *ngFor="let request of getCalculatedRequests(contract.id)"-->
                        <!--                                         class="request-details">-->
                        <!--                                        <p><strong>Request ID:</strong> {{ request.id }}</p>-->
                        <!--                                        <p><strong>Request Type:</strong> {{ request.requestType }}</p>-->

                        <!--                                    </div>-->
                        <!--                                </div>-->

                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-9">
<!--                            [toggleable]="true"-->
                            <p-panel header="Contract Details">
                                <div class="p-grid p-align-start">
                                    <div class="p-col-6">
                                        <p><strong>ID:</strong> {{ contract.id }}</p>
                                        <p><strong>Name:</strong> {{ contract.categoryDtoName }}</p>
                                    </div>
                                </div>
                            </p-panel>
                            <br>

<!--                            [toggleable]="true"-->
                            <p-panel header="Provision Details">
                                <div class="p-grid p-align-start">

                                    <!-- Provision Group -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.contractProvisionGroupMap?.get(contract.id) as group">
                                        <p-fieldset legend="Provision Group" [toggleable]="true">
                                            <p><strong>Name:</strong> {{ group.name }}</p>
                                            <p><strong>Description:</strong> {{ group.description }}</p>
                                        </p-fieldset>
                                    </div>

                                    <!-- Provision Category -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.contractProvisionCategoryMap?.get(contract.id) as category">
                                        <p-fieldset legend="Provision Category" [toggleable]="true">
                                            <p><strong>Name:</strong> {{ category.name }}</p>
                                            <p><strong>Description:</strong> {{ category.description }}</p>
                                            <p><strong>Entity Status:</strong> {{ category.entityStatus }}</p>
                                            <p><strong>Requires Former Contract:</strong> {{ category.requiresFormerContract ? 'Yes' : 'No' }}</p>
                                        </p-fieldset>
                                    </div>

                                    <!-- Eligibility Status -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.contractEligibilityMap?.get(contract.id) as eligibility">
                                        <p-fieldset legend="Eligibility Status" [toggleable]="true">
                                            <p>{{ eligibility }}</p>
                                        </p-fieldset>
                                    </div>

                                    <!-- Provision Partner -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.provisionPartnerMap?.get(contract.id) as partner">
                                        <p-fieldset legend="Provision Partner" [toggleable]="true">
                                            <p><strong>Name:</strong> {{ partner.name }}</p>
                                        </p-fieldset>
                                    </div>

                                    <!-- Calculated Provision Type -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.calculatedProvisionTypeMap?.get(contract.id) as provisionType">
                                        <p-fieldset legend="Calculated Provision Type" [toggleable]="true">
                                            <p><strong>Name:</strong> {{ provisionType.name }}</p>
                                            <p><strong>Description:</strong> {{ provisionType.description }}</p>
                                            <p><strong>Entity Status:</strong> {{ provisionType.entityStatus }}</p>
                                        </p-fieldset>
                                    </div>

                                    <!-- Calculated Provision Bag -->
                                    <div class="p-col-12" *ngIf="provisionRequestRuntimeDto?.calculatedProvisionBagMap?.get(contract.id) as provisionBag">
                                        <p-fieldset legend="Calculated Provision Bag" [toggleable]="true">

                                            <div *ngIf="provisionBag.calculatedPartnerPayments.length">
                                                <h5>Partner Payments</h5>
                                                <ul>
                                                    <li *ngFor="let payment of provisionBag.calculatedPartnerPayments">
                                                        {{ payment | json }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div *ngIf="provisionBag.calculatedAgentPayments.length">
                                                <h5>Agent Payments</h5>
                                                <ul>
                                                    <li *ngFor="let agentPayment of provisionBag.calculatedAgentPayments">
                                                        {{ agentPayment | json }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div *ngIf="provisionBag.calculatedAgentOneTimePayments">
                                                <h5>One-Time Agent Payments</h5>
                                                <p>{{ provisionBag.calculatedAgentOneTimePayments | json }}</p>
                                            </div>

                                        </p-fieldset>
                                    </div>

                                    <!-- Calculated Requests -->
                                    <div class="p-col-12" *ngIf="getCalculatedRequests(contract.id)?.length">
                                        <p-fieldset legend="Calculated Requests" [toggleable]="true">
<!--                                            <div *ngFor="let request of getCalculatedRequests(contract.id)">-->
<!--                                                <p><strong>Request ID:</strong> {{ request.id }}</p>-->
<!--                                                <p><strong>Request Type:</strong> {{ request.requestType }}</p>-->
<!--                                            </div>-->
                                            <mvs-widget *ngIf="refreshProvisionRequestWidget(contract.id)" [widgetData]="refreshProvisionRequestWidget(contract.id)"></mvs-widget>
                                        </p-fieldset>
                                    </div>


                                </div>
                            </p-panel>
                        </div>

                        <div class="col-3">

                            <div *ngIf="getProcessingLog(contract.id)?.length">
                                <h4>Processing Log</h4>
                                <p-table [value]="getProcessingLog(contract.id)" [paginator]="true" [rows]="10"
                                         responsiveLayout="scroll" class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 15%">Log Type</th>
                                            <th style="width: 10%">Severity</th>
                                            <th>Message</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-logEntry>
                                        <tr>
                                            <td>
                                                <p-chip [label]="logEntry.logType" class="p-mr-2"></p-chip>
                                            </td>

                                            <td>
                                                <p-tag *ngIf="logEntry.severity === 'WARNING'" value="Warning"
                                                       severity="warning">
                                                </p-tag>
                                                <p-tag *ngIf="logEntry.severity === 'ERROR'" value="Error"
                                                       severity="danger">
                                                </p-tag>
                                            </td>

                                            <td>
                                                <span>{{ logEntry.message }}</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>


                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
}