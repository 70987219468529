import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class UiMutableAreaService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiMutableAreas')
  }

  /**
   * Get single Entity Object via ID. The returned DtoDetail will already be transformed.
   * @param id
   */
  public getExtByAlias(alias: string): Observable<DtoDetail> {

    const url = `${this.apiUrl}/ext/alias/${alias}`;

    return this.getInternal(url);

  }

  public getExtById(id: number): Observable<DtoDetail> {

    const url = `${this.apiUrl}/ext/id/${id}`;

    return this.getInternal(url);

  }

}
