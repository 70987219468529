import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria, MvsCrudModeEnum,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum, NavigationItem,
    ObjectBaseComponent,
    ObjectBaseModeComponent,
    ObjectChangeInformation,
    ObjectChangeInformationActionEnum,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation, WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {IntegrationAlphaBatchEntityService} from "../../../../service/api/integration-alpha-batch-entity.service";
import {IntegrationAlphaBatchEntityDto} from "../../../../dto/integration-alpha-batch-entity.dto";
import {IntegrationTypeStepDto} from "../../../../../hc/dto/integration-type-step.dto";
import {IntegrationAlphaBatchEntityStepDto} from "../../../../dto/integration-alpha-batch-entity-step.dto";
import {MvsUiObjectService, WidgetFactory} from "@kvers/alpha-ui";
import {
    IntegrationAlphaBatchEntityStepActivityDto
} from "../../../../dto/integration-alpha-batch-entity-step-activity.dto";
import {
    IntegrationAlphaBatchEntityStepActivityRequestDto
} from "../../../../dto/integration-alpha-batch-entity-step-activity-request.dto";
import {MenuItem} from "primeng/api";


@Component({
    selector: 'alpha-batch-entity-base-component',
    template: '',
})
export class AlphaBatchEntityBaseComponent extends ObjectBaseModeComponent implements OnInit {

    integrationAlphaBatchEntityDto: IntegrationAlphaBatchEntityDto;
    integrationAlphaBatchEntityWidget: WidgetData;


    constructor(
        protected route: ActivatedRoute,
        protected integrationAlphaBatchEntityService: IntegrationAlphaBatchEntityService,
        protected objectService: MvsUiObjectService,) {
        super(route);
    }

    ngOnInit() {

        this.activeNavigationItem = this.navigationItems[0];
        this.onNavigationItems.emit(this.navigationItems);

        super.ngOnInit();

        this.refreshWidgets();
        this.initialized = true;
    }

    refreshWidgets() {
        this.integrationAlphaBatchEntityWidget = WidgetFactory.createWidgetEntityData(
            'ha.integration.alpha.batch.entity.widget',
            'Integration Alpha batch entity',
            'ha.IntegrationAlphaBatchEntity',
            this.objectIdentifier.objectId
        )
    }

    onNavigationItemChange(navigationItem: NavigationItem) {
        if (navigationItem?.action == 'alphaIntegration') {
            this.getIntegrationAlphaBatchEntity();
        }
    }

    getIntegrationAlphaBatchEntity() {


        const filterCriteria = FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequestList = ObjectRequestList.createBasic(false, [filterCriteria], []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode('integrationAlphaBatchEntityStep', ObjectRequestRelation.createJoin('ha.IntegrationAlphaBatchEntityStep#batchImport')).addNodes(
                ObjectRequestComplexNode.createRelationNode('integrationAlphaBatchEntityStepActivity', ObjectRequestRelation.createJoin('ha.IntegrationAlphaBatchEntityStepActivity#step')).addNodes(
                    ObjectRequestComplexNode.createRelationNode('integrationAlphaBatchEntityStepActivityRequest', ObjectRequestRelation.createJoin('ha.IntegrationAlphaBatchEntityStepActivityRequest#stepActivity'))
                ),
            ),
        );


        this.integrationAlphaBatchEntityService.list(objectRequestList).subscribe(res => {
            this.integrationAlphaBatchEntityDto = res.entries[0];
        });
    }


    handleStep(step?: IntegrationAlphaBatchEntityStepDto) {

        const id = step?.id ? step?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['batchImportDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('batchImportDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "ha.IntegrationAlphaBatchEntityStep",
            id,
            defaultDto,
            false,
            false,
            (event: ObjectChangeInformation) => {
                this.getIntegrationAlphaBatchEntity();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }

    handleStepActivity(step?: IntegrationAlphaBatchEntityStepDto, stepActivity?: IntegrationAlphaBatchEntityStepActivityDto) {

        const id = stepActivity?.id ? stepActivity?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['stepDtoId'] = step.id;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('stepDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "ha.IntegrationAlphaBatchEntityStepActivity",
            id,
            defaultDto,
            false,
            false,
            (event: ObjectChangeInformation) => {
                this.getIntegrationAlphaBatchEntity();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }

    handleStepActivityRequest(stepActivity?: IntegrationAlphaBatchEntityStepActivityDto, stepActivityRequest?: IntegrationAlphaBatchEntityStepActivityRequestDto) {

        const id = stepActivityRequest?.id ? stepActivityRequest?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['stepActivityDtoId'] = stepActivity.id;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('stepActivityDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "ha.IntegrationAlphaBatchEntityStepActivityRequest",
            id,
            defaultDto,
            false,
            false,
            (event: ObjectChangeInformation) => {
                this.getIntegrationAlphaBatchEntity();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }
}
