import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { EditorState } from '@codemirror/state';
import { javascript } from '@codemirror/lang-javascript';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import {EditorView} from "@codemirror/view";
import {basicSetup} from "codemirror";

@Component({
  selector: 'app-code-mirror-test',
  templateUrl: './code-mirror-test.component.html',
  styleUrls: ['./code-mirror-test.component.scss']
})
export class CodeMirrorTestComponent implements AfterViewInit {
  @ViewChild('editorContainer', { static: true }) editorContainer!: ElementRef;

  editor!: EditorView;

  // Schema for autocompletion
  variableSchema = {
    customer: {
      attributes: ['id', 'name', 'address'],
      joins: ['customerType', 'orders']
    },
    customerType: {
      attributes: ['typeName', 'typeCode']
    }
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeEditor();
  }

  initializeEditor(): void {
    // Custom completion source
    const customCompletionSource = (context: CompletionContext) => {
      const word = context.matchBefore(/\w*\./);
      console.log('Context:', context); // Debugging
      console.log('Matched word:', word?.text);

      if (word) {
        const variableName = word.text.slice(0, -1);
        const schema = this.variableSchema[variableName];
        console.log('Schema:', schema);

        if (schema) {
          const suggestions = [...schema.attributes, ...schema.joins].map(attr => ({
            label: attr,
            type: schema.joins.includes(attr) ? 'property' : 'attribute',
            info: `Belongs to ${variableName}`,
          }));
          console.log('Suggestions:', suggestions);
          return { from: word.to, options: suggestions };
        }
      }
      return null;
    };


    // Initialize CodeMirror
    this.editor = new EditorView({
      state: EditorState.create({
        doc: 'customer',
        extensions: [
          basicSetup,
          javascript(),
          autocompletion({
            override: [customCompletionSource],
          }),
        ],
      }),
      parent: this.editorContainer.nativeElement,
    });
  }
}
