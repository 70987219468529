@if (initialized) {

<!--    <object-component-navigation-full [navigationItem]="navigationItems"></object-component-navigation-full>-->

    <div class="grid">
        <div class="col-8">
            <mvs-widget
                    *ngIf="provisionAgentWidget"
                    [widgetData]="provisionAgentWidget">
            </mvs-widget>

            <mvs-widget
                    *ngIf="provisionAgentGroupWidget"
                    [widgetData]="provisionAgentGroupWidget">
            </mvs-widget>
        </div>

        <div class="col-4">
            <mvs-object-comments
                    headline="Kommentare"
                    [mainObject]="objectIdentifier"
                    linkedEntityObjectType="cp.ProvisionAgentComment"
            ></mvs-object-comments>
        </div>
    </div>

}
