<ng-container *ngIf="activity">
    <ng-container>
        <ng-template [ngTemplateOutlet]="activityTemplate" [ngTemplateOutletContext]="{}"></ng-template>
    </ng-container>

    <ng-template #activityTemplate>
        <ng-container *ngIf="activity">
            <ng-container [ngTemplateOutlet]="activity.templateRef"></ng-container>
        </ng-container>
    </ng-template>
</ng-container>
