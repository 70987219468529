import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-promo',
    templateUrl: './cr-customer-promo.component.html',
})
export class CrCustomerPromoComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerReferredFrom: WidgetData;
    customerReferrals: WidgetData;
    customerReferralOptions: WidgetData;
    customerReferralRedeems: WidgetData;


    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerService: CustomerService,
                protected customerContractService: CustomerContractService,
                protected configService: MvsConfigService,
                ) {
        super(coreService, confirmationService, messageService, configService,navigationService);

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        const customerFilter = [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        const referredFromCustomerFilter = [FilterCriteria.create("referredFromCustomer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];

        this.customerReferredFrom = WidgetFactory.createWidgetListEntity(
            "cr.customer.promo.referred.from",
            "Weiterempfohlen von",
            "cs.CustomerReferral",
            "Ohne Weiterempfehlung",
            ObjectRequestList.createBasic(true, customerFilter, [])
        );

        this.customerReferrals = WidgetFactory.createWidgetListEntity(
            "cr.customer.promo.referrals",
            "Weiterempfehlungen",
            "cs.CustomerReferral",
            "Keine Weiterempfehlungen",
            ObjectRequestList.createBasic(true, referredFromCustomerFilter, [])
        );

        this.customerReferralOptions = WidgetFactory.createWidgetListEntity(
            "cr.customer.promo.referral.options",
            "Auswahl Geschenke",
            "cs.CustomerLevelOption",
            "Keine Auswahl getroffen",
            ObjectRequestList.createBasic(true, customerFilter, [])
        );

        this.customerReferralRedeems = WidgetFactory.createWidgetListEntity(
            "cr.customer.promo.referral.redeems",
            "Eingelöste Geschenke",
            "cs.CustomerReferralRedeem",
            "Keine Einlösung",
            ObjectRequestList.createBasic(true, customerFilter, [])
        );

    }

}
