import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UiNavigationMainService} from "../../service/api/ui-navigation-main.service";
import {UiNavigationMainDto} from "../../dto/ui-navigation-main.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";

@Component({
    selector: 'ui-navigation-side',
    templateUrl: './ui-navigation-side.component.html',
})
export class UiNavigationSideComponent implements OnInit, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    uiNavigationMainEntries: DtoList<UiNavigationMainDto>;

    constructor(protected uiNavigationMainService: UiNavigationMainService) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.uiNavigationMainService.list(ObjectRequestList.createBasic(false, null, null)).subscribe(value => {
           this.uiNavigationMainEntries = <DtoList<UiNavigationMainDto>>value;
            this.refreshComponent();
        });

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
