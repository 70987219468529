import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MessageService} from "primeng/api";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CustomerRelatedPersonService} from "../../service/api/customer-related-person.service";
import {CustomerRelatedPersonDto} from "../../dto/customer-related-person.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {CrCustomerRelatedPersonWidgetStyle} from "./uiStyle/cr-customer-related-person-widget-style";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerDto} from "../../dto/customer.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {CustomerService} from "../../service/api/customer.service";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {SplitButton} from "primeng/splitbutton";
import {Observable, Subscription} from "rxjs";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsUiObjectService} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-cr-customer-related-persons',
    templateUrl: './cr-customer-related-persons.component.html',
    styleUrls: ['./cr-customer-related-persons.component.scss']
})
export class CrCustomerRelatedPersonsComponent
    implements OnInit, OnDestroy, OnChanges {


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    value: boolean;
    IsSelectAll : boolean;
    splitButtonItems: any;
    @Input() customerDto: CustomerDto;
    @Output() selectedRelatedPersonsChanged = new EventEmitter<CrCustomerRelatedPersonWidgetStyle>();


    // customerRelatedPersons: any[];
    selectedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    customerRelatedPersons: CustomerRelatedPersonDto[];
    selectedRelatedPerson: DtoDetail;
    broadcastSubscription: Subscription;

    constructor(public messageService: MessageService,
                protected navigationService: MvsObjectNavigationService,
                protected customerService:CustomerService,
                protected objectService:MvsUiObjectService,
                protected coreService: MvsCoreService,
                protected configService: MvsConfigService,) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshInternal();

    }

    refreshInternal() {

        this.getCustomerRelatedPersons(this.customerDto.id).subscribe(value => {
            this.customerRelatedPersons = value.entries[0]['relatedPersonCustomers'];
            this.prepareUiData(this.customerRelatedPersons);
            this.selectedPersonWidgetStyle = new CrCustomerRelatedPersonWidgetStyle(this.configService, this.customerDto, []);
            this.initialized = true;

        });
    }

    prepareUiData(relatedPersons: CustomerRelatedPersonDto[]) : CustomerRelatedPersonDto[]  {
        if (relatedPersons) {
            for (const item of relatedPersons) {
                let icon: string = '';

                // preparing ui names since we cant add icons to label in split buttons

                if (item['sameHousehold']) {
                    icon = ' <i class="fa-sharp fa-solid fa-house mx-2"></i>'
                }
                const fullName = item.personDto.firstName + ' ' + item.personDto.lastName;

                // TODO: Create union type
                item['uiCustomerName'] = fullName + ' (' + item.relatedPersonTypeDto.name + ')' + icon;
            }
        }
        return relatedPersons;

    }

    getCustomerRelatedPersons(customerId: number): Observable<DtoList> {
        const complexSelection = ObjectRequestComplex.build(false, false,
            //ObjectRequestComplexNode.createRelationNode("emergencyContacts",ObjectRequestRelation.createJoin("+emergencyContacts")),
            ObjectRequestComplexNode.createRelationNode("relatedPersonCustomers", ObjectRequestRelation.createJoin("+relatedPersonCustomers"))
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person")
                    .addNodes(ObjectRequestComplexNode.createRelationNode("relatedCustomer",ObjectRequestRelation.createJoinWithBindingType("cr.Customer#person", ObjectRequestComplexRelationBindingEnum.LATEST))
                    ))
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('relatedPersonType'))
        );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            true,
            complexSelection,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual , customerId)],
            [],
            null,
            null)

        return this.customerService.list(objectRequestList);
    }



    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["customerDto"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

    handleSelectRelatedPerson(person:CustomerRelatedPersonDto, event: MouseEvent){

        if (event.ctrlKey || event.metaKey || event.button == 1){
            //ctrl is pressed, open customer in new tab
            this.handleNavigation(person.personDto["relatedCustomer"].id);

        } else {

            person['__selected'] = !person['__selected'];
            const customerId: number = person.personDto['relatedCustomer'].id;

            if(!customerId){
                return;
            }

            if (person['__selected'] && customerId) {
                this.selectedPersonWidgetStyle.addRelatedPerson(person,customerId);
            } else {
                this.selectedPersonWidgetStyle.removeRelatedPerson(person);
            }

            person['__style'] = this.selectedPersonWidgetStyle.getStyleObjectForAvatar(person);

            this.changeArrayReference(person);
            this.selectedRelatedPersonsChanged.emit(this.selectedPersonWidgetStyle);
        }

    }

    changeArrayReference(person: CustomerRelatedPersonDto){
        const index = this.customerRelatedPersons.findIndex(item => item == person);
        const newPerson = structuredClone(person);
        if(index > -1) {
            this.customerRelatedPersons.splice(index,1,newPerson);
        }
    }

    onAllAvatarSelect() {
        this.IsSelectAll = !this.IsSelectAll ;
        for (const person of this.customerRelatedPersons) {
            this.handleSelectRelatedPerson(person, null);
        }
    }

    handleButtonClick(person:CustomerRelatedPersonDto): void {

        this.splitButtonItems = [];
        const customerId = person.personDto['relatedCustomer'].id;

        this.getCustomerRelatedPersons(customerId).subscribe(res => {
            const records = res.entries[0]['relatedPersonCustomers'];
            const buttonItems = [];
            this.prepareUiData(records);

            if (records) {
                for (let item of records) {
                    const fullName = item.personDto.firstName + ' ' + item.personDto.lastName + ' (' + item.relatedPersonTypeDto.name + ')';

                    const button = {
                        label: fullName,
                        id: item.personDto["relatedCustomer"].id,
                        icon: 'fa-regular fa-arrow-up-right-from-square text-color-secondary split-button-menu-icon',
                        iconPos: 'right',
                        command:() => this.handleNavigation(button.id)
                    }
                    buttonItems.push(button);
                }
            } else {
                const button = { label: 'No related persons found!' };
                buttonItems.push(button);

            }
            this.splitButtonItems = buttonItems;
        });
    }

    handleNavigation(customerId: number) {
        this.navigationService.handleObjectNavigation(new ObjectIdentifier('cr.Customer', customerId),null, {openNewTab: true});
    }

}
