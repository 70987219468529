import {Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {Calendar, CalendarOptions, DatesSetArg, EventClickArg, EventInput} from "@fullcalendar/core";
import {CalendarConfigurationService} from "../../service/api/calendar-configuration.service";
import {MsCalendarEventDto, MsCalendarEventResponseDto} from "../../service/api/dto/ms/ms-calendar.types";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import {AgentDto} from "../../dto/agent.dto";
import {PrimeNGConfig} from "primeng/api";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {DatePipe} from "@angular/common";
import {AgentService} from "../../service/api/agent.service";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";

@Component({
    selector: 'am-agent-calendar-management',
    templateUrl: './agent-calendar-management.component.html',
    styleUrls: ['agent-calendar-management.component.scss']
})
export class AgentCalendarManagementComponent implements OnInit, OnChanges {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() selectedAgents: AgentDto[];
    @Input() selectedAgentIds: number[];

    calendarOptions: CalendarOptions;
    calendarRef: Calendar;
    calendarEvents: EventInput[];
    calendarResources: { id: string, title: string }[];
    calendar: { [key: number]: MsCalendarEventResponseDto };
    selectedDate: Date = new Date();

    viewType: 'resourceTimeGridWeek' | 'resourceTimeGridDay' = 'resourceTimeGridDay';


    constructor(
        private datePipe: DatePipe,
        protected calendarService: CalendarConfigurationService,
        private primengConfig: PrimeNGConfig,
        protected agentService: AgentService,) {
    }

    ngOnInit() {

        this.primengConfig.setTranslation(MvsConfigService.germanConfig);

        this.initComponent();
    }

    /**
     * initialize component
     */
    initComponent() {


        if (this.selectedAgentIds) {

            const filterCriteriaList: FilterCriteria[] = [];

            for (let selectedAgentId of this.selectedAgentIds) {
                filterCriteriaList.push(FilterCriteria.create('id', FilterCriteria.cOperatorEqual, selectedAgentId));
            }

            const filterCriteria = FilterCriteria.createOr(...filterCriteriaList);

            const objectRequest = new ObjectRequestList(false, [filterCriteria], []);

            this.agentService.list(objectRequest).subscribe(res => {
                this.selectedAgents = res.entries;

                this.retrieveAgentCalendarInfo();
            })

        } else if (this.selectedAgents) {
            this.retrieveAgentCalendarInfo();

        }

    }

    /**
     * refresh component
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * initialize and configure full calendar
     */

    configureFullCalendar() {

        this.calendarOptions = {
            plugins: [resourceTimeGridPlugin],
            initialView: this.viewType,
            slotMinTime: '08:00:00', // Start time of the calendar view
            slotMaxTime: '18:00:00', // End time of the calendar view
            weekends: false, // This hides the weekends
            locale: 'de', // Set the locale to German
            timeZone: 'Europe/Berlin', // Set the timezone to Berlin
            headerToolbar: {
                // left: 'prev,next today', // Buttons for navigating through dates
                // center: 'title', // Where the current date / view title is displayed
                right: 'resourceTimeGridWeek,resourceTimeGridDay' // Buttons for changing views
            },
            resources: this.calendarResources,
            datesAboveResources: true,
            events: this.calendarEvents,
            eventClick: (info: EventClickArg) => {
                alert('Event: ' + info.event.title + ', Resource: ' + info.event.getResources().map((resource) => {
                    return resource.title;
                }).join(", "));
            },
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            contentHeight: 'auto', // Or try specific pixel value like 600 to see how it affects the layout
            datesSet: (dateInfo: DatesSetArg) => {
                this.handleDateSet(dateInfo)
            }
        };

        if (this.selectedDate && this.initialized) {
            this.calendarOptions.initialDate = this.adjustDate(this.selectedDate);
        }

        let calendarEl = document.getElementById('calendar');
        this.calendarRef = new Calendar(calendarEl, this.calendarOptions);
        this.calendarRef.render();

        // to increase or decrease the height of cell
        document.querySelectorAll('.fc-timegrid-slot, .fc-timegrid-slot-label').forEach(function (slot) {
            if (slot instanceof HTMLElement) {
                slot.style.height = '40px'; // Adjust as needed
            }
        });


    }

    handleDateSet(dateInfo: DatesSetArg) {

        // Check if viewType is not set or if the current view type has changed
        if (!this.viewType || this.viewType !== dateInfo.view.type) {
            // Ensure the new view type is one of the specific views we are interested in
            if (dateInfo.view.type === 'resourceTimeGridWeek' || dateInfo.view.type === 'resourceTimeGridDay') {
                this.viewType = dateInfo.view.type; // Update the current view type
                this.retrieveAgentCalendarInfo(); // Call the function to handle new data retrieval
            }
        }
    }

    /**
     * retrieve agent calendar info
     */

    retrieveAgentCalendarInfo() {
        this.calendar = null;
        if (!this.selectedAgents || this.selectedAgents.length == 0) {
            return;
        }

        //TODO: will change it to day selection and week selection
        const date = this.getWeekStartAndEndDate(this.selectedDate)

        const ids: number[] = this.selectedAgents.map(object => object.id);

        this.calendarService.getCalendars(
            {
                startDateTime: date.startDateTime,
                endDateTime: date.endDateTime,
                agents: ids
            }).subscribe(value => {
            this.calendar = value;
            this.initCalendarEvent();
            this.initCalendarResource();
            this.configureFullCalendar();
            this.refreshComponent();
        });
    }

    /**
     * initialize calendar events
     */

    initCalendarEvent() {

        this.calendarEvents = [];
        this.calendarEvents = this.extractEvents(this.calendar);

    }

    /**
     * initialize calendar event resources
     */

    initCalendarResource() {

        if (!this.calendarResources) {
            this.calendarResources = [];
        }

        this.calendarResources = this.selectedAgents.map(agent => ({id: agent.id.toString(), title: agent.name}));

    }

    /**
     * transform event to respected format
     * @param key
     * @param event
     */
    transformEvent(key: string, event: MsCalendarEventDto): EventInput {
        return {
            title: event.subject,
            start: event.start.dateTime,
            end: event.end.dateTime,
            allDay: event.allDay,
            resourceId: key, // Associates this event with Room A
        };
    }

    /**
     * extract events from response received from API
     * @param data
     */

    extractEvents(data: { [key: number]: MsCalendarEventResponseDto }): EventInput[] {
        return Object.entries(data).flatMap(([key, obj]) =>
            obj.value.map(event => this.transformEvent(key, event))
        );
    }

    /**
     * navigate full calendar to selected date
     */
    navigateToSelectedDate() {
        this.calendarRef.gotoDate(this.adjustDate(this.selectedDate));

        this.retrieveAgentCalendarInfo();

    }

    /**
     * adjust date for selected date it shows previous date
     * @param date
     */
    adjustDate(date: Date): Date {
        const adjustedDate = new Date(Number(date));
        adjustedDate.setDate(date.getDate() + 1);
        return adjustedDate
    }

    /**
     * format date
     * @param dateStr
     * @param timeStr
     */

    formatDate(dateStr: string, timeStr: string = '00:00:00'): string {
        const date = new Date(dateStr);
        // Use DatePipe to format the date as 'yyyy-MM-dd' and manually add ' 00:00:00'
        return this.datePipe.transform(date, 'yyyy-MM-dd') + ' ' + timeStr;
    }

    /**
     * get week start and end date
     * @param date
     */
    getWeekStartAndEndDate(date: Date): { startDateTime: string, endDateTime: string } {

        if (this.viewType == 'resourceTimeGridWeek') {


            // Clone the original date to avoid modifying it
            let tempDate = new Date(date.getTime());

            // Calculate the difference between the current day and the first day of the week (Sunday)
            // Get current day of the week (0-6, Sunday-Saturday)
            const differenceToStart = tempDate.getDay(); // Difference to the start of the week (Sunday)

            // Calculate start of the week. Set to Sunday
            let startOfWeek = new Date(tempDate);
            startOfWeek.setDate(tempDate.getDate() - differenceToStart);

            // Calculate end of the week. Set to Saturday
            let endOfWeek = new Date(startOfWeek);

            endOfWeek.setDate(startOfWeek.getDate() + 6);

            // Set time to start and end of day for clarity
            startOfWeek.setHours(0, 0, 0, 0); // Set start of the week to 00:00:00 for start of the day
            endOfWeek.setHours(23, 59, 59, 999); // Set end of the week to 23:59:59 for end of the day

            const startDateTime = this.formatDate(startOfWeek.toString());
            const endDateTime = this.formatDate(endOfWeek.toString(), '23:00:00');

            return {startDateTime, endDateTime};
        } else {
            const startDateTime = this.formatDate(date.toString());
            const endDateTime = this.formatDate(date.toString(), '23:00:00');

            return {startDateTime, endDateTime};
        }
    }


    /**
     * change detection
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.initComponent();
    }
}
