import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsCoreService, ObjectIdentifier, ObjectTypeService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ObjectUserAccessService} from "../../../../../cc/service/api/object-user-access.service";
import {ObjectUserAccessDto} from "../../../../../cc/dto/object-user-access.dto";
import {UserObjectAccess} from "../../../../components/mvs-user-group-avatar/interface/user-object-access.interface";
import {ObjectUserAccessType} from "../../../../../cc/enum/object-user-access-type.enum";
import {UserObjectAccessRoleEnum} from "../../../../components/mvs-user-group-avatar/enum/user-object-access-role.enum";
import {MvsUserObjectAccessOutputInterface} from "./interface/mvs-user-object-access-output.interface";

@Component({
    selector: 'mvs-user-object-access-output-component',
    templateUrl: './mvs-user-object-access-output.component.html',
})
export class MvsUserObjectAccessOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsUserObjectAccessOutputInterface {


    @Input() objectType: string;
    @Input() objectId: number;
    userData: ObjectUserAccessDto[];
    private subscription!: Subscription;

    userObjectAccess: UserObjectAccess[];


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected coreService: MvsCoreService,
                protected userAccessService: ObjectUserAccessService) {
        super(navigationService, objectService, coreService);
    }

    ngOnInit(): void {
        if (!this.objectType || !this.objectId) {
            this.objectType = this.formField['uiConfigData']['objectType'];
            const field = this.formField['uiConfigData']['objectId'];
            this.objectId = this.dto[field];
        }

        const objectIdentifier = new ObjectIdentifier(this.objectType, this.objectId)

        this.userAccessService.register(objectIdentifier);
        this.subscription = this.userAccessService.getData().subscribe(dataMap => {

            const identifierKey = `${objectIdentifier.objectType}:${objectIdentifier.objectId}`;
            const data = dataMap.get(identifierKey);

            if (this.isUserDataSame(this.userData, data)) {
                return; // No change in user data, so skip further processing
            }

            this.userData = data;

            // Map ObjectUserAccessType to UserObjectAccessRoleEnum
            const accessTypeMap = {
                [ObjectUserAccessType.read]: UserObjectAccessRoleEnum.READ,
                [ObjectUserAccessType.write]: UserObjectAccessRoleEnum.WRITE,
                [ObjectUserAccessType.exclusive]: UserObjectAccessRoleEnum.LOCK,
            };

            this.userObjectAccess = [];

            if (this.userData?.length) {
                for (let userData of this.userData) {

                    const accessRole = accessTypeMap[userData.accessType];
                    this.userObjectAccess.push({id: userData.userDtoId, role: accessRole});
                }
            }
        });
        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    private isUserDataSame(prevData: any[], newData: any[]): boolean {
        if (prevData === newData) {
            return true; // Same reference
        }

        if (!prevData || !newData || prevData.length !== newData.length) {
            return false; // Different length or one is null/undefined
        }

        // Compare each object in the arrays
        return prevData.every((prev, index) => {
            const current = newData[index];
            return prev.userDtoId === current.userDtoId && prev.accessType === current.accessType;
        });
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.userAccessService.unregister(new ObjectIdentifier(this.objectType, this.objectId));
        this.subscription.unsubscribe();
    }
}
