import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WfProcessService} from "../../../../service/api/wf-process.service";
import {WfProcessStatusInternal} from "../../../../enum/wf-process-status-internal.enum";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'wf-statistics.component.html',
})
export class WfStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    inProgressProcess: number = 0;
    waitingProcess: number = 0;
    cancelledProcess: number = 0;
    completedProcess: number = 0;


    workflowProcessWidget: WidgetData;
    workflowTypeWidget: WidgetData;
    workflowResultWidget: WidgetData;
    recentlyAddedWorkflowsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        protected processService: WfProcessService,
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'Workflow Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getCompletedProcess();
        this.refreshWorkflowProcessWidget();
        this.refreshProcessTypeWidgets();
        this.refreshProcessResultWidgets();
        this.refreshAddedWorkflows();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/wf/config']);
    }

    getCompletedProcess() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['processStatusInternal'],
            [new ObjectRequestListAttribute('processStatusInternal', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.processService.groupBy(objectRequestList).subscribe(res => {

            this.overviewDto = [
                { label: 'Completed Process', value: 0 },
                { label: 'In Progress Process', value: 0 },
                { label: 'Waiting Process', value: 0 },
                { label: 'Cancelled Process', value: 0 }
            ];

            for (let entry of res.entries) {
                if (entry['processStatusInternal'] === WfProcessStatusInternal.completed) {
                    this.overviewDto.find(item => item.label === 'Completed Process').value = entry['processStatusInternal_count'];
                } else if (entry['processStatusInternal'] === WfProcessStatusInternal.in_progress) {
                    this.overviewDto.find(item => item.label === 'In Progress Process').value = entry['processStatusInternal_count'];
                } else if (entry['processStatusInternal'] === WfProcessStatusInternal.waiting) {
                    this.overviewDto.find(item => item.label === 'Waiting Process').value = entry['processStatusInternal_count'];
                } else if (entry['processStatusInternal'] === WfProcessStatusInternal.cancelled) {
                    this.overviewDto.find(item => item.label === 'Cancelled Process').value = entry['processStatusInternal_count'];
                }
            }


        });
    }

    refreshWorkflowProcessWidget() {
        this.workflowProcessWidget = new WidgetData();
        this.workflowProcessWidget.idAlias = "wf.workflow.overview.workflow.process.widget";
        this.workflowProcessWidget.name = "WorkFlow Process Per Month";
        this.workflowProcessWidget.uiComponent = "history.chart";
        this.workflowProcessWidget.dataProvider = "list";
        this.workflowProcessWidget.dataSource = "entity.groupBy";
        this.workflowProcessWidget.dataProviderObject = "wf.WfProcess";
        this.workflowProcessWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["type", "createdDate"],
            [new ObjectRequestListAttribute("type", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.workflowProcessWidget.setParamValue("fieldCategory", "type");
        this.workflowProcessWidget.setParamValue("fieldCategoryCount", "type_count");
        this.workflowProcessWidget.setParamValue("historyPeriodMode", "year");
        this.workflowProcessWidget.setParamValue("fieldDate", "createdDate");
        this.workflowProcessWidget.setParamValue("selectionMode", "single");
        this.workflowProcessWidget.setParamValue("size", "S");
    }

    refreshProcessTypeWidgets() {
        this.workflowTypeWidget = WidgetFactory.createWidgetGroupBy(
            'wf.workflow.overview.workflow.type.widget',
            'Workflow Groups',
            'category',
            'entity.groupBy',
            'wf.WfProcessType',
            'processGroup',
            'processGroup_count',
            this.filterCriteria,
            'processGroup',
            'Anzahl');
    }

    refreshProcessResultWidgets() {
        this.workflowResultWidget = WidgetFactory.createWidgetGroupBy(
            'wf.workflow.overview.workflow.process.result.widget',
            'Workflow Process Results',
            'category',
            'entity.groupBy',
            'wf.WfProcess',
            'processTypeResult',
            'processTypeResult_count',
            this.filterCriteria,
            'processTypeResult',
            'Anzahl');
    }

    refreshAddedWorkflows() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedWorkflowsWidget = WidgetFactory.createWidgetTableEntity(
            'wf.workflow.overview.recently.added.workflows',
            'Recently Added Workflows',
            'wf.WfProcessType',
            'No Workflows',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
