import {Component, OnInit} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    PageComponent,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {TicketGroupDto} from "../../../dto/ticket-group.dto";

@Component({
    selector: 'mvs-contact-page',
    templateUrl: './ticket-type.page.html',
    styleUrls: ['./ticket-type.page.scss']
})
export class TicketTypePage extends PageComponent implements OnInit {

    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Ticket Type Configuration";

    communicationChannelWidget: WidgetData;
    ticketGroupWidget: WidgetData;
    ticketTypeGroupWidget: WidgetData;
    ticketTypeWidget: WidgetData;
    ticketGroupFilter: WidgetData;

    selectedTicketTypeGroup: ObjectIdentifierData;

    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    widgetEntries: TabEntries[] = [
        {label: 'Communication Channels', id: 'tm.TmCommunicationChannel', type: ''},
        {label: 'Ticket Groups', id: 'tm.TicketGroup', type: ''},
        {label: 'Ticket Typ', id: 'tm.TicketType', type: ''},
        {label: 'Ticket Group Filter', id: 'tm.TicketGroupFilter', type: ''},
    ];


    selectedTicketTypeId: number;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
        this.handleTabSelection(0);


        // Main Structure
        // converted to widgets 25/07/2024

        // this.objectBrowserRequest = {
        //
        //   "extractDto": false,
        //   "createMode": true,
        //
        //   'entries' : [
        //     {
        //       'id'                : 1,
        //       'objectAlias'       : 'tm.TmCommunicationChannel',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Communication Channels",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Channel"
        //     },
        //     {
        //       'id'                : 2,
        //       'objectAlias'       : 'tm.TicketGroup',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Ticket Groups",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Group"
        //     },
        //     {
        //       'id'                : 3,
        //       'objectAlias'       : 'tm.TicketType',
        //       'level'             : 1,
        //       'parentBinding'     : 'none',
        //       'processRecursion'  : true,
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Ticket Types",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Type"
        //     }
        //   ]
        // };
        //
        // this.subObjectBrowserRequest = {
        //
        //   "extractDto": false,
        //   "createMode": true,
        //
        //   'entries' : [
        //     {
        //       'id'                : 1,
        //       'objectAlias'       : 'tm.TicketType',
        //       'level'             : 1,
        //       'parentBinding'     : 'none'
        //     },
        //     {
        //       'id'                : 2,
        //       'objectAlias'       : 'tm.TicketTypeField',
        //       'level'             : 2,
        //       'parentBinding'     : 'mandatory',
        //       'parentId'          : 1,
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Fields",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Field"
        //     },
        //     {
        //       'id'                : 3,
        //       'objectAlias'       : 'tm.TicketTypeAction',
        //       'level'             : 2,
        //       'parentBinding'     : 'mandatory',
        //       'parentId'          : 1,
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Actions",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Action"
        //     },
        //     {
        //       'id'                : 4,
        //       'objectAlias'       : 'tm.TicketTypeObjectType',
        //       'level'             : 2,
        //       'parentBinding'     : 'mandatory',
        //       'parentId'          : 1,
        //       'dummyParentCreate' : true,
        //       'dummyParentLabel'  : "Reference Object Types",
        //       'newEntryNodeCreate': true,
        //       'newEntryNodeLabel' : "Create Reference Type"
        //     }
        //   ]
        // };

    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            if (params['tid']) {
                const object = this.widgetEntries.find(item => item.id == params['tid']);
                if (object) {
                    this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
                    this.activeWidget = object;
                }
            }
        });
    }

    refreshWidgets() {
        this.refreshCommunicationChannelWidget();
        this.refreshTicketGroupWidget();
        this.refreshTicketTypeGroupWidget();
        this.refreshTicketGroupFilter();
    }


    refreshCommunicationChannelWidget() {
        this.communicationChannelWidget = WidgetFactory.createWidgetList(
            "tm.config.communication.channel.widget.simple",
            'Communication Channels',
            'table',
            'list',
            'entity',
            'tm.TmCommunicationChannel',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshTicketGroupWidget() {
        this.ticketGroupWidget = WidgetFactory.createWidgetList(
            "tm.config.ticket.group.widget.simple",
            'Ticket Groups',
            'table',
            'list',
            'entity',
            'tm.TicketGroup',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshTicketTypeGroupWidget() {
        this.ticketTypeGroupWidget = WidgetFactory.createWidgetList(
            "tm.config.ticket.type.group.widget.simple",
            'Ticket Groups',
            'selectable',
            'list',
            'entity',
            'tm.TicketGroup',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshTicketTypeWidget(id: number) {
        this.ticketTypeWidget = WidgetFactory.createWidgetList(
            "tm.config.ticket.type.simple",
            'Ticket Ticket',
            'treeTable',
            'list',
            'entity',
            'tm.TicketType',
            null,
            ObjectRequestList.createWithPaging(true, [FilterCriteria.create('ticketGroup', FilterCriteria.cOperatorEqual, id)], [], PagingDto.create(0, 20))
        );

        this.ticketTypeWidget.functionCallbacks = this.widgetTicketTypeToolbarFunction();
    }

    refreshTicketGroupFilter() {
        this.ticketGroupFilter = WidgetFactory.createWidget(
            "tm.config.ticket.group.filter.simple",
            "Ticket Group Filter",
            "table",
            "list",
            "entity",
            "tm.TicketGroupFilter"
        );
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.widgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateSelect(event: ObjectIdentifierData) {
        this.objectType = event.objectType;
        this.objectId = event.objectId;
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    isConfigurationPage(): boolean {
        return true;
    }

    openObjectInNewTab(event: MouseEvent) {
        const navigationObject = MvsObjectNavigationEntry.createNavigationEntry('tm.TicketType', this.selectedTicketTypeId, 'object', null, null, null, MvsObjectNavigationActionEnum.edit, 'light');
        this.navigationService.openObjectInNewTab(navigationObject);
    }

    openObjectInNewTabId(event: ObjectIdentifierData) {
        this.selectedTicketTypeId = event.objectId;


    }

    onGroupSelect(event: ObjectIdentifierData) {

        this.selectedTicketTypeGroup = event;

        this.refreshTicketTypeWidget(event.objectId);
    }


    widgetTicketTypeToolbarFunction() {
        return this.widgetToolbarCreate('ticketGroup', this.selectedTicketTypeGroup.objectId);
    }

    widgetToolbarCreate(idField: string, idValue: number) {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[`${idField}DtoId`] = idValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(`${idField}DtoId`, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}
