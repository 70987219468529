import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
  ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {
  ObjectDialogInterface
} from "@kvers/alpha-ui";
import {
  CmCreateInsurableObjectPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person/cm-create-insurable-object-person.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {
  CrCustomerRelatedPersonComponent
} from "../../component/cr-customer-related-person/cr-customer-related-person.component";

@Injectable({
  providedIn: 'root'
})
export class CustomerRelatedPersonService extends MvsCrudService implements ObjectDialogInterface {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerRelatedPersons');
  }

  getObjectAddComponent() {
        return CmCreateInsurableObjectPersonComponent;
  }

  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {

    if (mode == MvsCrudModeEnum.create) {
      return CmCreateInsurableObjectPersonComponent;
    } else if (mode == MvsCrudModeEnum.update) {
      return CrCustomerRelatedPersonComponent;
    }
    return null;
  }

  public getCustomerRelatedPersonComplexSelection() : ObjectRequestComplex {

    return  ObjectRequestComplex.build(false, false,
        ObjectRequestComplexNode.createSimpleAttributeNode('relatedPersonType'),
        ObjectRequestComplexNode.createSimpleAttributeNode("person")
            .addNodes(
                ObjectRequestComplexNode.createRelationNode("relatedCustomer", ObjectRequestRelation.createJoinWithBindingType("cr.Customer#person", ObjectRequestComplexRelationBindingEnum.LATEST)),
                ObjectRequestComplexNode.createRelationNode("personDetail", ObjectRequestRelation.createJoin("personDetail")),
            )
        );


  }

  public getCustomerRelatedPersonComplexRequest (id: number, includeTemplate: boolean = false) : ObjectRequestList {
    return ObjectRequestList.createComplexRequestList(
        includeTemplate,
        this.getCustomerRelatedPersonComplexSelection(),
        [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, id)],
        null,
        null,
        null);
  }

  public getCustomerRelatedPersonWithFilterComplexRequest (filter: FilterCriteria[], includeTemplate: boolean = false) : ObjectRequestList {
    return ObjectRequestList.createComplexRequestList(
        includeTemplate,
        this.getCustomerRelatedPersonComplexSelection(),
        filter,
        null,
        null,
        null);
  }

}
