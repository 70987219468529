import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  CmSearchFlexibleContractComponent
} from "../../search/cm-search-flexible-contract/cm-search-flexible-contract.component";
import {ContractDto} from "../../dto/contract.dto";
import {CmContractInlineComponent} from "../../components/cm-contract-inline-component/cm-contract-inline-component";
import {CmContractPage} from "../../page/overview/cm-contract-page/cm-contract.page";
import {
  MvsCrudModeEnum,
  MvsCrudService,
  ObjectRequestComplex,
  ObjectRequestComplexNode,
  ObjectRequestComplexRelationBindingEnum,
  ObjectRequestRelation
} from "@kvers/alpha-core-common";
import {MvsCoreGetNameInterface} from "@kvers/alpha-ui";
import {CmContractComponent} from "../../components/cm-contract/cm-contract.component";
import {
  ContractObjectComponent
} from "../../components/object-base-components/contract-component/contract-object.component";

@Injectable({
  providedIn: 'root'
})
export class ContractService
    extends MvsCrudService
    implements MvsCoreGetNameInterface<ContractDto>{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/contracts')
  }


  getFlexibleSearchComponent(): Type<any> {
    return CmSearchFlexibleContractComponent;
  }

  getContractsComplexSelection() : ObjectRequestComplex {
    return ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createSimpleAttributeNode('contract'));
  }

  getCustomerWithAgentComplexRequest() : ObjectRequestComplex {
    return ObjectRequestComplex.build(false, false,
        ObjectRequestComplexNode.createSimpleAttributeNode("contractType")
            .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")),
        ObjectRequestComplexNode.createRelationNode("currentMainPartner", ObjectRequestRelation.createJoin("+currentMainPartner")),
        ObjectRequestComplexNode.createRelationNode("currentPrice", ObjectRequestRelation.createJoin("+currentPrice"))
            .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("priceFrequency")),
        ObjectRequestComplexNode.createRelationNode("currentStatus", ObjectRequestRelation.createJoin("+currentStatus")),
        ObjectRequestComplexNode.createRelationNode("contractFormer",
            ObjectRequestRelation.createList(
                "cm.ContractFormer",
                "contract",
                null,
                null,
                ObjectRequestComplexRelationBindingEnum.LATEST)
        ).addNodes(ObjectRequestComplexNode.createRelationNode('contract', ObjectRequestRelation.createJoin('contract')))
    )
  }

  getObjectNameFromDto(dto: ContractDto): string {
    return dto.alias;
  }

  getInlineComponent(): Type<any> {
    return CmContractInlineComponent;
  }

  getObjectIcon(): string {
    return 'pi pi-file';
  }

  getObjectPageComponent(): Type<any> {
    return CmContractPage;
  }

  getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      // return CmContractComponent;
      return ContractObjectComponent;
    }
    return null;
  }

  getObjectLabels(): string[] {
    return ['contractTypeDtoName', 'contractIdentifier', 'categoryDtoName'];
  }




}