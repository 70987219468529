import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    DtoDetail,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum, ObjectChangeInformation,
    WidgetData, WidgetDataParam,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {TicketActionStatusInternal} from "../../../../enum/ticket-action-status-internal.enum";
import {TicketActionDto} from "../../../../dto/ticket-action.dto";
import {TmCreateTicketAction} from "./data/tm-create-ticket-action";
import {TicketActionAvailableResponseDto} from "../../../../service/api/dto/ticket-action-available-response.dto";
import {TicketService} from "../../../../service/api/ticket.service";


@Component({
    selector: 'create-tm-ticket-actions',
    templateUrl: './create-tm-ticket-actions.component.html',
    styleUrls: ['./create-tm-ticket-actions.component.scss']
})
export class CreateTmTicketActionsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() actionType: string;
    @Input() ticketId: number;
    @Output() changeMode = new EventEmitter<TmCreateTicketAction>();
    @Input() availableActionsResponse: TicketActionAvailableResponseDto;
    showIndividual: boolean = false;

    ticketActionWidget: WidgetData;
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    constructor(protected ticketService: TicketService,) {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getTicketTypeAction();
        this.refreshWidgets();
        this.initialized = true;
    }

    refreshWidgets() {

        const ticketTypeActionDtoId = this.getTicketTypeAction();


        const dto = new TicketActionDto();
        dto.ticketDtoId = this.ticketId;
        dto.statusInternal = TicketActionStatusInternal.pending;
        dto.ticketTypeActionDtoId = ticketTypeActionDtoId;
        dto.mandatory = true;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.HIDDEN);
        formControlOverwrite.addField('ticketObjectDtoId', MvsFormFieldAccessEnum.HIDDEN);
        formControlOverwrite.addField('statusInternal', MvsFormFieldAccessEnum.HIDDEN);
        formControlOverwrite.addField('actionTicketDtoId', MvsFormFieldAccessEnum.HIDDEN);
        formControlOverwrite.addField('ticketTypeActionDtoId', MvsFormFieldAccessEnum.HIDDEN);
        formControlOverwrite.addField('processDtoId', MvsFormFieldAccessEnum.HIDDEN);

        this.ticketActionWidget = WidgetFactory.createWidgetList(
            'create.tm.ticket.action.individual',
            'Ticket Action',
            'object',
            'list',
            'entity',
            'tm.TicketAction',
            'No ticket actions found!',
            null,
            WidgetDataParam.create('createDefaultDto', dto),
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite)
        );

    }

    getTicketTypeAction(): number {
        const paths = this.actionType.split('.');
        if (paths[0] == 'individual') {
            this.showIndividual = true;
            const action = this.availableActionsResponse.availableActions.find(item => item.ticketTypeAction.id == +paths[1]);
            if (!action) {
                return null;
            }
            return action.ticketTypeAction.id;
        }
        return null;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleBackToTicket(forceRefresh: boolean) {
        const event = new TmCreateTicketAction();
        event.mode = 'overview';
        event.forceRefresh = forceRefresh;
        this.changeMode.emit(event);
    }

    onCreateTicketAction(event: ObjectChangeInformation) {
        this.handleBackToTicket(true);
    }

    handleTicketActionsCreated(event: boolean) {
        this.handleBackToTicket(true);
    }
}
