import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'si-kpi.component.html',
})
export class SiKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalAppointments: number = 0;
    totalAppointmentType: number = 0;
    thisMonthAppointment: number = 0;
    thisWeekAppointment: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'AS Module'}];

    constructor(
    ) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalAppointments();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalAppointments() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        // this.appointmentService.groupBy(objectRequestList).subscribe(res => {
        //     this.totalAppointments = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
        //     this.getTotalAppointmentType();
        // }, error => {
        //     this.getTotalAppointmentType();
        // });
    }

    getTotalAppointmentType() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['locationInternal'],
            [new ObjectRequestListAttribute('locationInternal', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        // this.appointmentTypeService.groupBy(objectRequestList).subscribe(res => {
        //     this.totalAppointmentType = res.entries.reduce((acc, item) => acc + item['locationInternal_count'], 0);
        //     this.getThisMonthAppointment();
        // }, error => {
        //     this.getThisMonthAppointment();
        // });
    }

    getThisMonthAppointment() {
        const objectRequestList = this.getThisMonthRequest('status');
        // this.appointmentService.groupBy(objectRequestList).subscribe(res => {
        //     this.thisMonthAppointment = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
        //     this.getThisWeekAppointment();
        // }, error => {
        //     this.getThisWeekAppointment();
        // });
    }

    getThisWeekAppointment() {
        const objectRequestList = this.getThisWeekRequest('status');
        // this.appointmentService.groupBy(objectRequestList).subscribe(res => {
        //     this.thisWeekAppointment = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
        //     this.initKpiHeaderDto();
        // }, error => {
        //     this.initKpiHeaderDto();
        // });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Appointments', value: this.totalAppointments,
            },
            {
                label: 'Total Appointments Type', value: this.totalAppointmentType,
            },
            {
                label: 'This Month Appointments', value: this.thisMonthAppointment,
            },
            {
                label: 'This Week Appointments', value: this.thisWeekAppointment,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
