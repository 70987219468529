import {Component, OnInit} from '@angular/core';
import {ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-ui-page',
    templateUrl: './ui-navigation-config.page.html',
    styleUrls: ['./ui-navigation-config.page.scss']
})
export class UiNavigationConfigPage
    extends PageComponent
    implements OnInit {

    // objectBrowserRequest: any;

    defaultLabel: string = 'UI Page Configuration';
    activeIndex: number = 0;


    uiPageWidget: WidgetData;
    uiNavigationMainWidget: WidgetData;
    uiNavigationSubWidget: WidgetData;
    uiNavigationSubPageWidget: WidgetData;
    objectWidget: WidgetData;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponents();
    }

    initComponents(): void {
        this.refreshUiPageWidget();
        this.refreshUiNavigationMainWidget();
        this.refreshUiNavigationSubWidget();
        this.refreshUiNavigationSubPageWidget();
        this.initialized = true;
    }

    refreshUiPageWidget() {
        this.uiPageWidget = WidgetFactory.createWidgetList(
            "ui.config.page.widget",
            "Page",
            "table",
            "list",
            "entity",
            "ui.UiPage",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );
    }

    refreshUiNavigationMainWidget() {
        this.uiNavigationMainWidget = WidgetFactory.createWidgetList(
            "ui.config.navigation.main.table.widget",
            "Main Navigation",
            "table",
            "list",
            "entity",
            "ui.UiNavigationMain",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );
    }

    refreshUiNavigationSubWidget() {
        this.uiNavigationSubWidget = WidgetFactory.createWidgetList(
            "ui.config.navigation.sub.table.widget",
            "Sub Navigation",
            "table",
            "list",
            "entity",
            "ui.UiNavigationSub",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );
    }


    refreshUiNavigationSubPageWidget() {
        this.uiNavigationSubPageWidget = WidgetFactory.createWidgetList(
            "ui.config.navigation.sub.page.table.widget",
            "Assign Page To Sub Navigation",
            "table",
            "list",
            "entity",
            "ui.UiNavigationSubPage",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );
    }


    refreshObjectWidget(objectType: string, objectId: number) {
        return;
        const name = objectId ? 'Update' : 'Create';
        this.objectWidget = WidgetFactory.createWidgetObject(
            `${objectType}.ui.config.page.widget`,
            name,
            objectType,
            objectId,);
    }

    clearWidget() {
        this.objectWidget = null;
    }


}
