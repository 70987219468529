import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';

@Component({
    selector: 'mvs-wf-process-step-side-activity-object-create',
    templateUrl: './wf-process-step-side-activity-object-create.component.html',
})
export class WfProcessStepSideActivityObjectCreateComponent {

    @Input() activity;
    @Output() onTabChange = new EventEmitter<any>();


}
