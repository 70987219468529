import { Component, Input } from "@angular/core";
import { MvsCrudService } from "@kvers/alpha-core-common";
import {TeVariableProviderService} from "../service/api/interfaces/te-variable-provider.service";
import {TeContentTypeEnum} from "../enum/te-content-type.enum";

@Component({
  selector: "te-variable-component-base",
  template: "Template variable component base",
})
export class TeVariableComponentBase {
  @Input() contentType: TeContentTypeEnum;
  @Input() contentProviderId: number;
  @Input() variableProviderService: MvsCrudService & TeVariableProviderService;
  @Input() alias: string;
  @Input() attributeName: string;
}
