import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MsBookingTypeService} from "../../../service/api/ms-booking-type.service";
import {PrimeNGConfig} from "primeng/api";
import {formatDate} from "@angular/common";
import {MsDateSlotDto} from "../../../service/dto/ms-date-slot.dto";
import {MsDatePeriodSlotDto} from "../../../service/dto/ms-date-period-slot.dto";
import {er} from "@fullcalendar/core/internal-common";

@Component({
    selector: 'as-ms-book',
    templateUrl: './ms-book.component.html',
    styleUrls: ['ms-book.component.scss']
})
export class MsBookComponent implements OnInit, OnChanges, OnDestroy {

    @Input() msBookingTypeId: number;
    @Input() date: Date = new Date();

    dates: MsDateSlotDto[];
    slots: MsDatePeriodSlotDto[];

    selectedSlot: MsDatePeriodSlotDto;

    today: Date = new Date();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    germanConfig = {
        firstDayOfWeek: 1, // Monday
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        today: 'Heute',
        clear: 'Klar'
    };

    constructor(
        protected msBookingTypeService: MsBookingTypeService,
        protected primengConfig: PrimeNGConfig) {
    }

    ngOnInit(): void {
        this.primengConfig.setTranslation(this.germanConfig);
        this.initComponent();
    }

    schedule() {

    }



    onSelect(slot: MsDatePeriodSlotDto) {
        this.selectedSlot = slot;
    }

    handleMonthChange(event: any) {
        let monthDate = new Date(event.year, event.month - 1, 1);
        this.fetchBookingDaysForMonth(monthDate);
    }

    isDayFree(day: { day: number, otherMonth: boolean }): boolean {

        if (day.otherMonth) {
            return false;
        }
        const freeSlotsExist = this.dates.some(freeDay => freeDay.dateConverted.getDate() == day.day);

        return freeSlotsExist;
    }


    /**
     * Fetch booking days for month.
     * @param date
     */
    fetchBookingDaysForMonth(date: Date) {

        if (!this.date) {
            this.date = new Date();
        }

        let start = new Date(date.getFullYear(), date.getMonth(), 1);
        let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        // pre-load date
        if (!this.initialized) {
            this.handleSelectDate(this.date);
        }

        // retrieve period slots
        this.msBookingTypeService.getOpenSlotDays(this.msBookingTypeId, start, end).subscribe(value => {
            this.dates = value;

            for (let periodDate of this.dates) {
                periodDate.dateConverted = new Date(periodDate.date);
            }
            
            this.initialized = true;
        });



    }

    handleSelectDate(event: Date) {
        this.busy = true;

        this.msBookingTypeService.getOpenSlotsForDay(this.msBookingTypeId, event).subscribe(value => {

            for (let slot of value) {
                slot.startTimeFormatted = this.convertToHoursAndMinutes(slot.startTime);
                slot.endTimeFormatted = this.convertToHoursAndMinutes(slot.endTime);
            }

            this.slots = value;

            this.busy = false;
        }, error => {
            this.busy = false;
        });

    }

    convertToHoursAndMinutes(isoString: string): string {
        const date = new Date(isoString);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        // Format the hours and minutes to ensure two digits
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.fetchBookingDaysForMonth(this.date);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
