import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EmSchemaObjectBaseComponent} from "../em-schema-object-base.component";


@Component({
    selector: 'em-schema-object-component-full',
    templateUrl: 'em-schema-object-full.component.html',
})
export class EmSchemaObjectFullComponent extends EmSchemaObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
