import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent,
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {KpiHeaderDto} from "./dto/kpi-header.dto";
import {getStartOfMonth, getStartOfWeek} from "../helper/date-utils";


@Component({
    selector: 'mvs-example',
    template: '',
})
export class OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    initialized: boolean;
    kpiHeaderDto: KpiHeaderDto[];

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    getThisMonthRequest(groupBy: string): ObjectRequestListGroupBy {
        return ObjectRequestListGroupBy.create(
            false,
            [FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, getStartOfMonth(new Date()))],
            [],
            [groupBy],
            [new ObjectRequestListAttribute(groupBy, "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );
    }

    getThisWeekRequest(groupBy: string): ObjectRequestListGroupBy {
        return ObjectRequestListGroupBy.create(
            false,
            [FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, getStartOfWeek(new Date()))],
            [],
            [groupBy],
            [new ObjectRequestListAttribute(groupBy, "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
