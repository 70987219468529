@if (initialized) {

    <div class="surface-0 shadow-1 p-3">
        <div>
            <span><h4 class="mb-0">Ticket Management</h4></span>
            <span></span>
            <span></span>
        </div>

        <span></span>
    </div>

    <div class="grid mt-3">

        <div class="col-3 surface-0 shadow-1">

            <div class="flex justify-content-between">
                <h4>Filters</h4>
                <p-button label="Create Filter" (click)="handleModifyFilters()"></p-button>
            </div>

            <div class="p-3 w-full">

                <!-- Favorite Groups -->
                <div class="favorites-section">
                    <div class="favorites-header justify-content-between align-items-center">
                        <div><i class="pi pi-star"></i> <span>Favourites</span></div>
                        <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"
                                class="p-button-rounded p-button-text mvs-wigdet-menu-btn"
                                (click)="menu.toggle($event)"
                        >
                        </button>
                    </div>

                    @if (favoriteGroupFilters && favoriteGroupFilters.length) {
                        <div class="group-list">
                            @for (group of favoriteGroupFilters; track group.id) {
                                <div class="group-item mt-1 p-2"
                                     [ngClass]="{'bg-primary-100': selectedFilter?.id == group.id,'bg-gray-100': group.id == groupContextMenuId}"
                                     (contextmenu)="onUiSelectedLayoutContextMenu($event, group, true)"
                                     (click)="getFilterInfo(group, 'favorite')">
                                    <div class="group-title pointer">
                                        {{ group.name }}
                                    </div>
                                    <div class="group-badges align-items-center">
                                        <p-badge value="{{ group.groupFilterCountDto?.meCount }}"
                                                 [pTooltip]="'My tickets'"
                                                 (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMe, group)"
                                                 styleClass="badge-style pointer"></p-badge>

                                        <p-badge value="{{ group.groupFilterCountDto?.poolsCount }}"
                                                 (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMyPool, group)"
                                                 [pTooltip]="'My pool tickets'"
                                                 severity="success"
                                                 styleClass="badge-style pointer"></p-badge>

                                        <p-badge value="{{ group.groupFilterCountDto?.poolsUnassignedCount }}"
                                                 (click)="handleBadgeCountClick(UiAgentAssignmentEnum.unassigned, group)"
                                                 [pTooltip]="'Unassigned Tickets'"
                                                 severity="warning"
                                                 styleClass="badge-style pointer"></p-badge>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <div class="p-3" *ngIf="!favoriteGroupFilters || !favoriteGroupFilters.length">
                        No favorites added so far!
                    </div>

                </div>

                <!-- All Groups -->
                <div class="favorites-section mt-3">
                    <p-panel
                        #panel
                        expandIcon="pi pi-chevron-up"
                        collapseIcon="pi pi-chevron-down"
                        class="panel-blank"
                        [iconPos]="'end'"
                        [toggleable]="true"
                        [collapsed]="true"
                        (collapsedChange)="handleCollapseGroups($event)">

                        <ng-template pTemplate="header">
                            <div class="favorites-header pointer">
                                <span (click)="panel.toggle($event)">My Groups</span>
                            </div>
                        </ng-template>

                        <div class="group-list" *ngIf="groupFilters && groupFilters.length">
                            @for (group of groupFilters; track group.id) {
                                @if (group.favorite == false) {
                                    <div class="group-item mt-1 p-2 pointer"
                                         [ngClass]="{'bg-primary-100': selectedFilter?.id == group.id,'bg-gray-100': group.id == groupContextMenuId}"
                                         (contextmenu)="onUiSelectedLayoutContextMenu($event, group, false)"
                                         (click)="getFilterInfo(group, 'all')">
                                        <div class="group-title">
                                            @if (group.favorite == false) {
                                            } &nbsp;{{ group.name }}
                                        </div>
                                        @if (group.groupFilterCountDto) {
                                            <div class="group-badges align-items-center">
                                                <p-badge value="{{ group.groupFilterCountDto?.meCount }}"
                                                         (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMe, group)"
                                                         [pTooltip]="'My tickets'"
                                                         styleClass="badge-style"></p-badge>

                                                <p-badge value="{{ group.groupFilterCountDto?.poolsCount }}"
                                                         (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMyPool, group)"
                                                         [pTooltip]="'My Pool tickets'"
                                                         severity="success"
                                                         styleClass="badge-style"></p-badge>

                                                <p-badge value="{{ group.groupFilterCountDto?.poolsUnassignedCount }}"
                                                         (click)="handleBadgeCountClick(UiAgentAssignmentEnum.unassigned, group)"
                                                         [pTooltip]="'Unassigned Tickets'"
                                                         severity="warning"
                                                         styleClass="badge-style"></p-badge>
                                            </div>
                                        }
                                    </div>
                                }
                            }
                        </div>

                        <div *ngIf="!groupFilters || !groupFilters.length">
                            No filters available!
                        </div>

                    </p-panel>

                </div>

            </div>
        </div>

        <div class="col-9">

            @if (selectedFilterInfo) {
                <div class="flex gap-3 flex-wrap justify-content-between flex">
                    <div>
                        @for (ticketType of selectedFilterInfo.ticketTypeCounts | slice: 0:6; track ticketType.ticketType.id) {

                            <p-chip styleClass="p-1 border-round-sm pointer {{ticketType.uiSelected ? 'bg-primary-200' : 'surface-200'}}"
                                    (click)="handleTicketTypeSelect(ticketType, 'main')">
                                <span class="mx-2"> {{ ticketType.ticketType.name }}</span>

                                @if (selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
                                    <p-badge [value]="ticketType.count.meCount" class="mx-1"/>

                                } @else if (selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
                                    <p-badge [value]="ticketType.count.poolsCount" class="mx-1"/>

                                } @else if (selectedAssignment == UiAgentAssignmentEnum.unassigned) {
                                    <p-badge [value]="ticketType.count.poolsUnassignedCount" class="mx-1"/>
                                }

                            </p-chip>

                        }

                        @if (selectedFilterInfo.ticketTypeCounts?.length > 4) {
                            <p-chip styleClass="p-1 border-round-sm pointer"
                                    (click)="overlay.toggle($event)">
                                <span class="mx-2"> Show All </span>
                            </p-chip>
                        }

                    </div>
                    <p-dropdown
                            [options]="assignmentOptions"
                            [(ngModel)]="selectedAssignment"
                            placeholder="Select Assignment"
                            optionLabel="label"
                            optionValue="value"
                            (onChange)="handleChangeAssignmentType($event)"
                    >
                    </p-dropdown>


                </div>

                @if (ticketWidget) {
                    <mvs-widget [widgetData]="ticketWidget"
                                (onObjectSelect)="handleObjectSelect($event)">

                    </mvs-widget>
                }

            } @else if (showFilterText) {
                <div class="flex gap-3 flex-wrap">
                    Please select a group to display its data or add it to your favourites for quicker access.
                    Simply right-click on your groups list to add a group to your favourites.
                </div>

            }

        </div>

    </div>

    <p-sidebar [(visible)]="manageFavoritesSidebar" position="right" styleClass="w-30rem">
        <h3>Manage Favorites</h3>
        @if (favoriteGroupFilters && favoriteGroupFilters.length) {
            <div class="group-list" cdkDropList
                 [cdkDropListData]="favoriteGroupFilters"
                 (cdkDropListDropped)="adjustPriority($event)">
                @for (group of favoriteGroupFilters; track group.id) {
                    <div class="group-item flex justify-content-between align-items-center mt-1 p-2">
                        <div class="group-title">
                            <i cdkDrag class="fa-solid fa-grip-dots-vertical cursor-move"></i> {{ group.name }}
                        </div>
                        <div class="group-badges align-items-center">

                            @if (group.access == TicketGroupFilterAccess.public_access) {
                                <p-badge value="Public" severity="success" styleClass="badge-style"></p-badge>
                            } @else if (group.access == TicketGroupFilterAccess.private_access) {
                                <p-badge value="Private" styleClass="badge-style"></p-badge>
                            }

                            <p-button styleClass="p-button-text text-red-500" (click)="removeFromFavorite(group)">
                                Remove
                            </p-button>

                        </div>
                    </div>
                }
            </div>
        }
    </p-sidebar>

    <p-contextMenu [appendTo]="'body'"
                   #cmLayout
                   [model]="uiLayoutContextMenuItems"
                   (onHide)="onUiHideContextMenu()">
    </p-contextMenu>

    <p-slideMenu #menu
                 [model]="slideMenuItems"
                 [popup]="true"
                 [menuWidth]="175"
                 class="sort-icon"
                 appendTo="body"
    >
    </p-slideMenu>

    <p-overlayPanel #overlay [style]="{ width: '45%'}">

        <p-button label="Clear All" (click)="clearAllTicketTypeSelections()"></p-button>

        @if (overlayTicketTypeCounts && overlayTicketTypeCounts.length) {
            @for (ticketType of overlayTicketTypeCounts; track ticketType.ticketType.id) {
                <p-chip styleClass="p-1 border-round-sm pointer {{ticketType.uiSelected ? 'bg-primary-200' : 'surface-200'}}"
                        (click)="handleTicketTypeSelect(ticketType, 'overlay')">
                    <span class="mx-2"> {{ ticketType.ticketType.name }}   </span>
                    @if (selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
                        <p-badge [value]="ticketType.count.meCount" class="mx-1"/>

                    } @else if (selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
                        <p-badge [value]="ticketType.count.poolsCount" class="mx-1"/>

                    } @else if (selectedAssignment == UiAgentAssignmentEnum.unassigned) {
                        <p-badge [value]="ticketType.count.poolsUnassignedCount" class="mx-1"/>
                    }
                </p-chip>

            }
        }

    </p-overlayPanel>


}