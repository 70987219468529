<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-widget
                        *ngIf="widgetCallResults"
                        [widgetData]="widgetCallResults"
                        (onObjectSelect)="onSetFilterCriteria($event,'callResult')"
                ></mvs-widget>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-widget
                        *ngIf="widgetCallType"
                        [widgetData]="widgetCallType"
                        (onObjectSelect)="onSetFilterCriteria($event,'callType')"
                ></mvs-widget>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-widget
                        *ngIf="widgetCallAgent"
                        [widgetData]="widgetCallAgent"
                        (onObjectSelect)="onSetFilterCriteria($event,'agent')"
                ></mvs-widget>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="mvs-widget h-full">
                <mvs-dashboard-duration-filter
                        displayLabel="Dauer"
                        placeHolder="Dauer auswählen"
                        (onSelect)="handleDurationFilter($event)"
                        (onClear)="clearDuration()"
                >
                </mvs-dashboard-duration-filter>
                <mvs-dashboard-agent-pool-filter
                        placeHolder="AgentPool auswählen"
                        (onSelect)="handleAgentPoolFilter($event)"
                        (onClear)="clearAgentPool()"
                ></mvs-dashboard-agent-pool-filter>
            </div>
        </div>


        <div class="col-12" *ngIf="filterCriteria && filterCriteria.length">
            <p-panel header="Filters">
                <div class="flex align-items-center gap-2 flex-wrap">
                    <p-chip
                            *ngFor="let filter of filterCriteria"
                            [label]="(filter.field ?  filter.field + ': ' : '') + filter.label"
                            [removable]="true"
                            (onRemove)="onRemoveFilterCriteria(filter)"
                    ></p-chip>
                </div>
            </p-panel>
        </div>

        <div class="col-12 md:col-12">
            <div class="mvs-widget">
                <mvs-widget
                        *ngIf="widgetCalls"
                        [widgetData]="widgetCalls"
                        (onObjectSelect)="onRowSelect($event)"></mvs-widget>
            </div>
        </div>
    </div>


</ng-container>