import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {InputFieldsPage} from "./page/input-fields-overview-page/input-fields.page";
import {OutputFieldsPage} from "./page/output-fields-overview-page/output-fields.page";
import {WidgetsPage} from "./page/widgets-overview-page/widgets.page";


@NgModule({
  declarations: [
    InputFieldsPage,
    OutputFieldsPage,
    WidgetsPage,
  ],
  exports:[
    InputFieldsPage,
    OutputFieldsPage,
    WidgetsPage,
  ],
  imports: [
    CoreModule,
  ]
})
export class XuModule { }

 
 
