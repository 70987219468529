import {Component, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {AgentService} from "../../../../../am/service/api/agent.service";
import {AgentDto} from "../../../../../am/dto/agent.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {PagingDto} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationProviderGeneric
} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {CallService} from "../../../../service/api/call.service";

@Component({
    selector: 'mvs-example',
    templateUrl: './pc-my-calls.page.html',
})
export class PcMyCallsPage extends PageComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    defaultLabel: string = "Meine Anrufe";

    agentDto: AgentDto;

    widgetCallResults: WidgetData;
    widgetCallType: WidgetData;
    widgetCalls: WidgetData;

    filterCriteria: FilterCriteria[] = [];

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected agentService: AgentService,
        protected callService: CallService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.agentService.me().subscribe(value => {
            this.agentDto = value;
            this.refreshComponent();
        });

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.uiRefresh();

        this.initialized = true;
    }

    uiRefresh() {

        const filterCriteriaList = [ FilterCriteria.create("agent", FilterCriteria.cOperatorEqual, this.agentDto.id)];

        if (this.filterCriteria && this.filterCriteria.length) {
            for (let filterCriterion of this.filterCriteria) {
                filterCriteriaList.push(filterCriterion);
            }
        }

        // TABLE Widget with all Calls
        this.widgetCalls = WidgetFactory.createWidgetListComplex(
            "pc.my.calls.table",
            "Meine Anrufe",
            "table",
            "list",
            "entity",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                filterCriteriaList,
                [new Sorting("createdDate", false)],
                PagingDto.create(0,20)),
            this.callService.getComplexSelectionCustomerAndLastProtocol(),
            WidgetDataParam.create("selectionMode", "rowSelect")
        );

        this.widgetCallType = WidgetFactory.createWidgetList(
            "pc.my.calls.type.categories",
            "Call type",
            "category",
            "list",
            "entity.groupBy",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                filterCriteriaList,
                [],
                ["callType"],
                [new ObjectRequestListAttribute("callType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]),

            WidgetDataParam.create("fieldCategory", "callType"),
            WidgetDataParam.create("fieldCategoryCount", "callType_count")
        );

        // Category Chart - Call Success Rate
        this.widgetCallResults = WidgetFactory.createWidgetList(
            "pc.my.calls.result.categories",
            "Erfolgshistorie",
            "category",
            "list",
            "entity.groupBy",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                filterCriteriaList,
                [],
                ["callResult"],
                [ new ObjectRequestListAttribute("callResult", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),

            WidgetDataParam.create("fieldCategory", "callResult"),
            WidgetDataParam.create("fieldCategoryCount", "callResult_count")
        );

    }

    onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
        if (this.isFilterCriteriaExists(field)) {
            return;
        }

        const filterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        filterCriteria.label = objectData.data.label;
        this.filterCriteria.push(filterCriteria);

        this.uiRefresh();
    }

    onRemoveFilterCriteria(filter: FilterCriteria) {
        const index = this.filterCriteria.findIndex(item => item.field === filter.field);
        if (index !== -1) {
            this.filterCriteria.splice(index, 1);
            this.uiRefresh();
        }
    }

    private isFilterCriteriaExists(field: string): FilterCriteria {
        return this.filterCriteria.find(res => res.field === field);
    }

    onRowSelect(objectIdentifierData: ObjectIdentifierData) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifierData.objectType, objectIdentifierData.objectId, "Call", null, null,null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

}
