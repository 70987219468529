import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {DtoDetail, MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../../cr/dto/customer-contract.dto";
import {HouseholdPersonDto} from "../../../dto/household-person.dto";
import {CustomerDto} from "../../../../cr/dto/customer.dto";
import {PersonRelationPersonDto} from "../../../dto/person-relation-person.dto";

export class PmPersonRelationPersonWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;

    constructor(protected configService: MvsConfigService,
                protected mainCustomer: CustomerDto,
                protected relatedPersons: HouseholdPersonDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    public hasRelatedPersons(): boolean {
        return (this.relatedPersons && this.relatedPersons.length > 0);
    }

    public getRelatedPersons(): HouseholdPersonDto[] {
        return this.relatedPersons;
    }

    getRelatedPerson(id: number): HouseholdPersonDto {
        const dto: HouseholdPersonDto = this.relatedPersons.find(item => item.id == id);
        return dto;
    }

    addRelatedPerson(relatedPerson: HouseholdPersonDto, customerId: number) {

        if (!customerId) {
            return;
        }

        if (!relatedPerson['__color']) {
            const nextColor = this.configColors.getNextColor();
            relatedPerson['__color'] = nextColor + '80';
            relatedPerson['__backgroundColor'] = nextColor + '10';
        }
        this.relatedPersons.push(relatedPerson);
        // this.relatedPersons = structuredClone(this.relatedPersons);


    }

    removeRelatedPerson(relatedPerson: HouseholdPersonDto) {
        const index = this.relatedPersons.findIndex(item => item.id == relatedPerson.id);
        if (index > -1) {
            this.relatedPersons.splice(index, 1);
        }
    }


    getRowStyle(rowDto: CustomerContractDto, rowData: any): object {

        if (rowDto.customerDtoId == this.mainCustomer.id) {
            return {};
        } else {

            for (let myRelatedPerson of this.relatedPersons) {
                // check if this related person is a customer

                if (!myRelatedPerson.personDto) {
                    continue;
                }

                let customerId: number;

                if (myRelatedPerson.personDto.customerDto) {
                    customerId = myRelatedPerson.personDto.customerDto.id;

                } else if (myRelatedPerson.personDto['relatedCustomer']) {
                    customerId = myRelatedPerson.personDto['relatedCustomer'].id;
                }

                if (customerId === rowDto.customerDtoId) {
                    return {
                        "border": "2px",
                        "background-color": myRelatedPerson['__color']
                    };
                }
            }
            return {};
        }
    }

    getListStyle(rowDto: PersonRelationPersonDto, rowData: any): object {

        for (let myRelatedPerson of this.relatedPersons) {
            if (rowDto.personBDtoId == myRelatedPerson.personDtoId) {
                return {
                    "border-left": "4px solid " + myRelatedPerson['__color'],
                    "border-radius": '6px',
                    "display": 'block'
                };
            }
        }

        return {};


        // if (rowDto['__selected'] && !this.getRelatedPerson(rowDto.id)) {
        //     //if not exist in relatedPersons then no style is applied
        //     rowDto['__selected'] = false;
        //     return {};
        //
        //  } else if (this.getRelatedPerson(rowDto.id)) {
        //     //widget is refreshed and we are again applying the previous style
        //     const relatedPerson = this.getRelatedPerson(rowDto.id);
        //     return   {
        //         "border-left" : "4px solid " + relatedPerson['__color'],
        //         "border-radius" : '6px',
        //         "display" : 'block'
        //     };
        //
        // } else if (rowDto['__color'] && rowDto['__selected']) {
        //   return   {
        //       "border-left" : "4px solid " + rowDto['__color'],
        //       "border-radius" : '6px',
        //       "display" : 'block'
        //   };
        //
        // } else if (rowDto.customerDtoId == this.mainCustomer.id) {
        //     return {};
        //
        // } else {
        //
        //     for (let myRelatedPerson of this.relatedPersons) {
        //         // check if this related person is a customer
        //
        //         if (!myRelatedPerson.personDto) {
        //             continue;
        //         }
        //
        //         let customerId: number;
        //
        //         if (myRelatedPerson.personDto.customerDto) {
        //             customerId = myRelatedPerson.personDto.customerDto.id;
        //
        //         } else if (myRelatedPerson.personDto['relatedCustomer']) {
        //             customerId = myRelatedPerson.personDto['relatedCustomer'].id;
        //         }
        //
        //         if (customerId === rowDto.customerDtoId) {
        //             return {
        //                 "border-left" : "4px solid " + myRelatedPerson['__color'],
        //                 "border-radius" : '6px',
        //                 "display" : 'block'
        //             };
        //         }
        //     }
        //     return {};
        // }
    }

    getHeaderStyle() {
        let headerStyle: object = {};
        if (this.selectedContractCustomerId) {
            for (const item of this.relatedPersons) {
                if (item.personDto.customerDto.id == this.selectedContractCustomerId) {
                    headerStyle = {'background-color': item['__color']};
                }
            }
        }
        return headerStyle;
    }

    setSelectedPerson(customerId: number) {
        this.selectedContractCustomerId = customerId;
    }

    getStyleObjectForAvatar(relatedPerson: HouseholdPersonDto) {

        const customerId = relatedPerson.personDto?.customerDto?.id;

        let avatarStyle = {};

        if (!customerId) {
            avatarStyle = {
                "background-color": "var(--surface-500)", "border": "1px solid #8080800f",
                'opacity': '0.65', 'font-weight': 'bold'
            };

        } else if (!relatedPerson['__selected']) {
            avatarStyle = {};

        } else {
            avatarStyle = {
                "background-color": relatedPerson['__color'],
                "border": "1px solid" + relatedPerson['__color'],
                'cursor': 'pointer',
                'margin-bottom': '0.3rem'
            };
        }

        return avatarStyle;

    }

    public clone(): PmPersonRelationPersonWidgetStyle {

        const widgetStyle = new PmPersonRelationPersonWidgetStyle(this.configService, this.mainCustomer, this.relatedPersons);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;

        return widgetStyle;
    }

}
