import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MsBusinessListDto} from "../dto/ms-business-list.dto";
import {Observable} from "rxjs";
import {MsServiceListDto} from "../dto/ms-service-list.dto";
import {MsBusinessStaffMemberListDto} from "../dto/ms-business-staff-member-list.dto";
import {MsDateSlotDto} from "../dto/ms-date-slot.dto";
import {MsDatePeriodSlotDto} from "../dto/ms-date-period-slot.dto";
import {PeriodHelper} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class MsBookingTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/as/msBookingTypes')
  }

  public getBusinessList(typeId: number) : Observable<MsBusinessListDto> {
    return this.getGeneric<MsBusinessListDto>(`/${typeId}/businesses`);
  }

  public getServiceList(typeId: number) : Observable<MsServiceListDto> {
    return this.getGeneric<MsServiceListDto>(`/${typeId}/services`);
  }

  public getBusinessStaffMembers(typeId: number) : Observable<MsBusinessStaffMemberListDto> {
    return this.getGeneric<MsBusinessStaffMemberListDto>(`/${typeId}/businessStaffMembers`);
  }

  public getOpenSlotDays(typeId: number, startDate: Date, endDate: Date) : Observable<MsDateSlotDto[]> {

    const startDateApi = PeriodHelper.convertDateToApiDate(startDate);
    const endDateApi = PeriodHelper.convertDateToApiDate(endDate);

    return this.getGeneric<MsDateSlotDto[]>(`/${typeId}/openSlotDays?startDate=${startDateApi}&endDate=${endDateApi}`);
  }

  public getOpenSlotsForDay(typeId: number, date: Date) : Observable<MsDatePeriodSlotDto[]> {
    const dateApi = PeriodHelper.convertDateToApiDate(date);
    return this.getGeneric<MsDatePeriodSlotDto[]>(`/${typeId}/openSlotsForDay?date=${dateApi}`);
  }


}