import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NsHlrLookupDataDto} from "../../logic/ns-hlr-lookup-data.dto";
import {
    WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {
    NsNotificationCommentWfProcessStepMainActivityComponent
} from "../../component/wf/ns-notification-comment-wf-process-step-main-activity/ns-notification-comment-wf-process-step-main-activity.component";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
    WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
    WfProcessStepActivitySideProviderInterface
} from "../../../wf/component/process-activity-side/wf-process-step-activity-side-provider.interface";
import {
    WfProcessStepActivitySideBaseComponent
} from "../../../wf/component/process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {
    NsNotificationCommentWfProcessStepSideActivityComponent
} from "../../component/wf/ns-notification-comment-wf-process-step-side-activity/ns-notification-comment-wf-process-step-side-activity.component";
import {NsNotificationSingleSendRequestDto} from "../../dto/service/ns-notification-single-send-request.dto";
import {NsNotificationDto} from "../../dto/ns-notification.dto";
import {NsNotificationRequestDto} from "../../dto/service/ns-notification-request.dto";
import {NsNotificationTypeOverviewDto} from "../../dto/service/ns-notification-type-overview.dto";
import {
    NsNotificationChannelRecipientContentDto
} from "../../dto/service/ns-notification-channel-recipient-content.dto";
import {NsNotificationTypesOverviewDto} from "../../dto/service/ns-notification-types-overview.dto";
import {
    NsNotificationObjectComponent
} from "../../component/notification-object-component/ns-notification-object.component";

@Injectable({
    providedIn: 'root'
})
export class NsNotificationService extends MvsCrudService implements WfProcessStepActivityMainProviderInterface, WfProcessStepActivitySideProviderInterface {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/ns/nsNotifications')
    }

    /**
     * Format Phone Number.
     * @param phoneNumber
     */
    public formatPhoneNumber(phoneNumber: string): Observable<{ formattedNumber: string }> {

        const url = `${this.apiUrl}/formatMobileNumber/${phoneNumber}`;
        return this.http.get<{ formattedNumber: string }>(url);

    }

    public hlrLookup(phoneNumber: string): Observable<NsHlrLookupDataDto> {

        const url = `${this.apiUrl}/hlrLookup/${phoneNumber}`;
        return this.http.get<NsHlrLookupDataDto>(url);

    }

    public getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode == MvsCrudModeEnum.create) {
            return null;
        }
        return NsNotificationObjectComponent;
    }

    public getNotificationTypeInfo(request: NsNotificationRequestDto): Observable<NsNotificationTypeOverviewDto> {
        return this.postBasic<NsNotificationTypeOverviewDto>(request, `/type/info`);
    }

    public getNotificationTypesInfo(request: NsNotificationRequestDto): Observable<NsNotificationTypesOverviewDto> {
        return this.postBasic<NsNotificationTypesOverviewDto>(request, `/types/info`);
    }

    public getNotificationChannelInfo(channelId: number, request: NsNotificationRequestDto): Observable<NsNotificationChannelRecipientContentDto> {
        return this.postBasic<NsNotificationChannelRecipientContentDto>(request, `/channel/${channelId}/info`);
    }

    /**
     * Send Simple.
     * @param sendRequestDto
     */
    public sendSingle(sendRequestDto: NsNotificationSingleSendRequestDto): Observable<NsNotificationDto> {
        return <Observable<NsNotificationDto>>this.postInternal(sendRequestDto, "/sendSingle");
    }

    getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
        return NsNotificationCommentWfProcessStepMainActivityComponent;
    }

    getActivitySideComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivitySideBaseComponent | null {
        return NsNotificationCommentWfProcessStepSideActivityComponent;
    }




}