import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsCoreService, ObjectPageComponent,
    ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation, PagingDto,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {CustomerDto} from "../../../../cr/dto/customer.dto";


@Component({
    selector: 'am-customer-appointment-detail-page',
    templateUrl: './am-customer-appointment-detail.page.html',
    styleUrl: "am-customer-appointment-detail.page.scss"
})
export class AmCustomerAppointmentDetailPage extends ObjectPageComponent implements OnInit, OnChanges, OnDestroy {

    customersCoords: { coordinates: [number, number], dto: DtoDetail, initials:string };
    customerWidget: WidgetData;
    customerDto: CustomerDto;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService,
    ) {
        super(route, coreService)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent()
    }

    protected getObjectType(): string {
        return 'cr.Customer'
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshCustomer()
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshCustomer() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode("person"),
                ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress"))
                // .addNodes(ObjectRequestComplexNode.createRelationNode("customerAddress", ObjectRequestRelation.createJoin("cr.CustomerAddress#customer")))
            ),
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [],
            null
        )


        this.customerService.list(objectRequest).subscribe(res => {
            this.customerDto = res.entries[0];

            this.customerWidget = WidgetFactory.createWidgetTransient(
                'cr.customer.list.transient.widget',
                "Customers",
                'list',
                'transient',
                'entity',
                'cr.Customer',
                res,
            );

            this.geocodeAddress()
        })

    }

    geocodeAddress(): void {


        const addressDto = this.customerDto['mainAddress']

        const address = {
            street: addressDto.street,
            postalCode: addressDto.postalCode,
            city: addressDto.city,
            country: addressDto.country ? addressDto.country : 'Germany'
        };

        // Construct the query for geocoding
        const query = `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`;

        // Use TomTom's Search API to geocode the address
        fetch(
            `https://api.tomtom.com/search/2/geocode/${encodeURIComponent(query)}.json?key=POQwSkANG2wVgN1qMbook38s5EMkN7pG`
        )
            .then(response => response.json())
            .then(data => {
                const position = data.results[0].position;

                this.customersCoords = {coordinates:[position.lon, position.lat], dto:this.customerDto, initials :this.getInitials(this.customerDto.personDtoName)};
                if (this.customersCoords) {
                    this.refreshComponent();
                }
            })
            .catch(error => console.error('Error fetching geocode:', error));


    }


    /**
     * Function to extract initials from a full name.
     * If the number of initials exceeds 2, it returns the first 2 initials.
     *
     * @param name - The full name from which to extract initials.
     * @returns A string containing up to 2 initials.
     */
    getInitials(name: string): string {
        if (!name) return '';

        // Split the name into words and filter out any empty strings
        const words = name.split(' ').filter(word => word.length > 0);

        // Map each word to its first letter, then join the first two initials
        const initials = words.map(word => word[0].toUpperCase()).join('');

        // Return only the first two initials
        return initials.substring(0, 2);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
