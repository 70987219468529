@if (initialized) {

    <p-tabView>
        <p-tabPanel header="Call Customer">
            <mvs-call-dial
                    [calledObject]="objectIdentifier"
            ></mvs-call-dial>

            <mvs-customer-phone-contact-availability
                    [customerObjectId]="objectIdentifier.objectId">
            </mvs-customer-phone-contact-availability>

            <mvs-widget
                    *ngIf="customerCallsWidget"
                    [widgetData]="customerCallsWidget">
            </mvs-widget>
        </p-tabPanel>
        <p-tabPanel header="Book Appointment">
             <as-free-slot
                     *ngIf="agents"
                     [agents]="agents"
                     [customerId]="objectIdentifier.objectId"
                     [appointmentTypeId]="appointmentTypeId"
                     (onAppointmentCreate)="handleAppointmentCreate($event)"
                     [referenceObjectTypeAlias]="ticketActionIdentifier.objectType"
                     [referenceObjectId]="ticketActionIdentifier.objectId"
             ></as-free-slot>
        </p-tabPanel>
    </p-tabView>


}