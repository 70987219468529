import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {DmDashboardComponent} from "../../component/dm-dashboard/dm-dashboard.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class DmDashboardService extends MvsCrudService {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/dm/dmDashboards');
  }


  /**
   * The Dashboard Component is used to display the Dashboard.
   */
  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    return DmDashboardComponent;
  }
}
