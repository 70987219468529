import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldOutputBaseComponent, MvsFormFieldService, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsOneToManyOutputInterface} from "./interface/mvs-one-to-many-output.interface";
import {MvsCoreService, MvsFormFieldDto, ObjectTypeService} from "@kvers/alpha-core-common";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'mvs-one-to-many-output-component',
    templateUrl: './mvs-one-to-many-output.component.html',
})
export class MvsOneToManyOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsOneToManyOutputInterface {


    objectId: number;

    busy: boolean;
    initialized: boolean;

    outputField: MvsFormFieldDto;

    extractedData: any[] = [];


    constructor(
        protected datePipe: DatePipe,
        protected formFieldService: MvsFormFieldService,
        protected navigationService: MvsObjectNavigationService,
        protected objectService: ObjectTypeService,
        protected coreService: MvsCoreService) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.extractData();
        this.refreshComponent();

    }

    extractData() {
        const objectField: MvsFormFieldDto = this.formField['uiConfigData']['outputField'];
        const keys = objectField.absoluteId.split('#');

        objectField.uiOutputControl = this.formFieldService.getDefaultOutputFieldName(objectField);


        this.outputField = objectField;

        const extractNestedValue = (obj: any, keyPath: string[]) => {
            return keyPath.reduce((acc, key) => acc?.[key], obj);
        };

        let fieldValues = Array.isArray(this.dto[keys[0]])
            ? this.dto[keys[0]].map(item => extractNestedValue(item, keys.slice(1)))
            : extractNestedValue(this.dto, keys);

        // Process fieldValue based on conditions
        for (let i = 0; i < fieldValues?.length; i++) {
            let fieldValueElement = fieldValues[i];

            if (MvsFormFieldDto.hasValueList(objectField)) {
                const valueFromValueList = MvsFormFieldDto.getValueFromValueList(objectField, fieldValueElement);
                if (valueFromValueList) {
                    fieldValues[i] = valueFromValueList.label;
                }
            } else if (fieldValueElement) {
                if (MvsFormFieldDto.isDate(objectField)) {
                    fieldValues[i] = this.datePipe.transform(fieldValueElement, "MM.dd.yyyy");
                } else if (MvsFormFieldDto.isDateTime(objectField)) {
                    fieldValues[i] = this.datePipe.transform(fieldValueElement, "MM.dd.yyyy HH:mm:ss");
                }
            }
        }

        // Store extracted value
        this.extractedData = fieldValues;
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
