import {Component, OnInit} from "@angular/core";
import {IntegrationTypeBaseComponent} from "../integration-type-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'hc-integration-side-component',
    templateUrl: 'integration-type-side.component.html',
    styleUrls: ['integration-type-side.component.scss'],
})

export class IntegrationTypeSideComponent extends IntegrationTypeBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'integrationTypeInfo',
            action: 'integrationTypeInfo',
            tooltip: 'Integration Type Info',
            icon: 'fa fa-info-circle',
            toggleable: false,
            default: true
        },
        {
            label: 'integrationType',
            action: 'integrationType',
            tooltip: 'Integration Type Step',
            icon: 'fa-solid fa-layer-group',
            toggleable: false,
            default: true
        },
        {
            label: 'integrationTypeVariable',
            action: 'integrationTypeVariable',
            tooltip: 'Integration Type Variable',
            icon: 'fa-solid fa-code-branch',
            toggleable: false,
            default: false
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}