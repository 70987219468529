import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {TeArtefactObjectComponent} from "../../component/te-artefact/te-artefact-object.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TeArtefactService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/te/teArtefacts');
  }



  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return TeArtefactObjectComponent;
    }

    if (mode == MvsCrudModeEnum.create) {
      return null
    }

    return null;
  }


}
