import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ObjectTypeService} from "../../../../cc/service/api/object-type.service";
import {CustomerContractService} from "../../../../cr/service/api/customer-contract.service";
import {
    DtoDetail,
    DtoListAttributeRequestAggregateEnum,
    DtoTemplate,
    FilterCriteria,
    MetaService,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    ObjectRequestRelation,
    PageComponent,
    Sorting,
    WidgetData, WidgetDataParam,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory,
} from "@kvers/alpha-ui";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {HouseholdService} from "../../../../pm/service/api/household.service";
import {HouseholdPersonService} from "../../../../pm/service/api/household-person.service";
import {PersonRelationPersonDto} from "../../../../pm/dto/person-relation-person.dto";
import {PersonRelationPersonService} from "../../../../pm/service/api/person-relation-person.service";
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {TicketActionService} from "../../../../tm/service/api/ticket-action.service";
import {TicketActionDto} from "../../../../tm/dto/ticket-action.dto";
import {UmUserService} from "../../../../um/service/api/um-user.service";
import {UserProfileHelper} from "../../../../um/components/user-profile/helper/user-profile-helper";
import {ActivityContextHolderService} from "../../../../core/activity-context/activity-context-holder.service";
import {ObserverService} from "../../../../xx/observer/service/observer.service";
import {Messages} from "primeng/messages";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
import {TicketObjectService} from "../../../../tm/service/api/ticket-object.service";
import {AgentService} from "../../../../am/service/api/agent.service";
import {AgentPoolService} from "../../../../am/service/api/agent-pool.service";

@Component({
    selector: 'mvs-test-form-haris',
    templateUrl: './test-haris.page.html',
    styleUrls: ['./test-haris.page.scss']
})
export class TestHarisPage extends PageComponent implements OnInit {

    customerContractWidgetData: WidgetData;
    groupByField: string = 'customerType';
    objectRequestList: ObjectRequestListGroupBy;
    objectIdentifier = new ObjectIdentifier('tm.Ticket', 494);

    householdPersonWidget: WidgetData;
    widgetUserRole: WidgetData;
    widgetUserRole1: WidgetData;
    householdWidget: WidgetData;
    personRelationPersonWidget: WidgetData;
    formGroup: FormGroup;
    fieldDisabled: boolean = false;
    imgSrc: string;
    formField: any;

    content: string = '';

    categories = [

        {

            id: 1,

            name: 'Electronics',

            children: [

                {

                    id: 2,

                    name: 'Computers',

                    children: [

                        {id: 3, name: 'Laptops', children: []},

                        {id: 4, name: 'Desktops', children: []}

                    ]

                },

                {

                    id: 5,

                    name: 'Smartphones',

                    children: [

                        {id: 6, name: 'Android Phones', children: []},

                        {
                            id: 7, name: 'iPhones', children: [
                                {id: 13, name: 'phone A', children: []},
                                {id: 14, name: 'phone B', children: []},
                                {id: 15, name: 'phone C', children: []},
                            ]
                        }

                    ]

                }

            ]

        },

        {

            id: 8,

            name: 'Books',

            children: [

                {

                    id: 9,

                    name: 'Fiction',

                    children: [

                        {id: 10, name: 'Fantasy', children: []},

                        {id: 11, name: 'Mystery', children: []}

                    ]

                },

                {

                    id: 12,

                    name: 'Non-Fiction',

                    children: []

                }

            ]

        }

    ];

    // files: any;

    searchString: string;
    searchStringId: number;
    searchedRecords: string[] = [];

    // Called when Quill editor is created
    onEditorCreated(quill: any) {
        // this.quill = quill;
    }

    handleSearch() {
        this.searchedRecords = [];
        this.searchInData(this.categories);
    }

    searchInData(categories: any = this.categories) {
        if (!this.searchString || this.searchString == '') {
            return;
        }

        for (let item of categories) {
            if (item.name.toLowerCase().includes(this.searchString)) {
                this.searchedRecords.push(item.name);
            }

            if (item.children && item.children.length > 0) {
                this.searchInData(item.children);
            }

        }

    }
    onNodeExpand(node: any) {
        node.expanded = !node.expanded;
    }

    handleChildren() {
        const children = this.getChildren(this.searchStringId);
        console.log(children);
    }

    getChildren(parentId: number, categories: any = this.categories) {

        for (let item of categories) {
            if (parentId == item.id) {
                return item.children;
            }
            if (item.children && item.children.length > 0) {
                return this.getChildren(parentId, item.children)
            }
        }
    }

    buttons = [
        {
            label: 'Active Ticket',
            icon: 'fa-regular fa-ticket',
            tooltip: 'Click here to activate the ticket.',
        },
        {
            label: 'Pinned Ticket',
            icon: 'fa-regular fa-thumbtack',
            tooltip: 'Click here to pin the ticket.',
        },
    ];

    @ViewChildren('tooltipRefs') tooltipRefs!: QueryList<ElementRef>;
    @ViewChild('messages') messages: Messages;

    constructor(
        protected route: ActivatedRoute,
        protected processService: WfProcessService,
        protected objectService: ObjectTypeService,
        protected customerContractService: CustomerContractService,
        protected navigationService: MvsObjectNavigationService,
        protected ticketService: TicketService,
        protected ticketActionService: TicketActionService,
        protected documentService: DmDocumentService,
        protected http: HttpClient,
        protected metaService: MetaService,
        protected customerService: CustomerService,
        protected activityContextHolderService: ActivityContextHolderService,
        protected observerService: ObserverService,
        protected userService: UmUserService,
        protected agentService: AgentService,
        protected agentPoolService: AgentPoolService,
        protected coreService: MvsCoreService,) {
        super(route, coreService);
    }

    deleteTicket() {
        this.ticketService.deleteTicket(88).subscribe(res => {
        }, error => {

        })
    }

    fetchAgentDetails() {
        this.agentService.me().subscribe(res => {
           console.log(res);
        });
    }

    fetchAgentPoolDetails() {
        this.agentPoolService.my().subscribe(res => {
            console.log(res);
        });
    }

    cancelTicket() {
        this.ticketService.cancelTicket(80).subscribe(res => {
        }, error => {

        })
    }

    createAction() {
        const dto = new TicketActionDto();
        dto.ticketDtoId = 81;
        dto.ticketTypeActionDtoId = 8;
        dto.ticketObjectDtoId = null;
        this.ticketActionService.create(dto).subscribe(res => {
            debugger
        });
    }

    showTooltipForIndex(index: number): void {
        const tooltipElement = this.tooltipRefs.toArray()[index]?.nativeElement;
        if (tooltipElement) {
            const mouseEnterEvent = new Event('mouseenter');
            tooltipElement.dispatchEvent(mouseEnterEvent);

            setTimeout(() => {
                const mouseLeaveEvent = new Event('mouseleave');
                tooltipElement.dispatchEvent(mouseLeaveEvent);
            }, 3000);
        }
    }

    addTicketIdentifier() {
        const identifier = new ObjectIdentifier('tm.Ticket', 10);
        this.observerService.addObject(identifier);
    }

    getMainObject() {
        // console.log(this.agentActiveObjectService.getActiveTicket());
    }

    getCustomerContracts() {
        this.customerContractService.getCustomerContracts(5637).subscribe(res => {
            debugger
            console.log(res);
        })
    }

    performChange(type: string) {
        let identifier: ObjectIdentifier
        if (type == 'customer') {
            identifier = new ObjectIdentifier('cr.Customer', 1)
        } else {
            identifier = new ObjectIdentifier('tm.Ticket', 10)
        }


        this.observerService.informActivity(identifier);
    }

    ticketActionWidget: WidgetData;

    imgUrl = {
        "changingThisBreaksApplicationSecurity": "blob:http://localhost:4200/6cc58a87-d38e-4d4b-b4b5-6edacbebf021#view=FitH&navpanes=0"
    }

    getCustomer() {
        const objectRequest = new ObjectRequestList(false, [FilterCriteria.create('id', 'EQ', 5637)],[]);
        objectRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("lastSuccessfulCall", ObjectRequestRelation.createJoin("+lastSuccessfulCall")),
            ObjectRequestComplexNode.createRelationNode("lastLoginDevice", ObjectRequestRelation.createJoin("+lastLoginDevice"))
        );

        this.customerService.list(objectRequest).subscribe(res => {
            debugger
            console.log(res);
        })
    }

    async ngOnInit() {

        // await this.objectService.getViaObjectId(119).then(res => {
        //     console.log(res);
        //     debugger
        // })

        super.ngOnInit();



        // this.documentService.downloadViaUrl(233324).subscribe(res => {
        //     debugger
        // });


        // this.formGroup = new FormGroup({
        //     contentBody: new FormControl(this.template)}
        // )
        //
        // this.formField = {id: 'contentBody'};
        //
        // console.log(this.formGroup);


        // this.messages.value = [{ severity: 'success', detail: 'You are in active ticket' }];

        // const objectRequest = new ObjectRequestList(true,
        //     [
        //         FilterCriteria.create('id', 'EQ', 51391),
        //         FilterCriteria.create('objectType', 'EQ', 42)
        //     ],
        //     []
        // );
        //
        // objectRequest.objectRequestComplex = ObjectRequestComplex.build(
        //     true,
        //     false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('ticket')
        //     );
        //
        // this.ticketObjectService.list(objectRequest).subscribe(res => {
        //     console.log(res);
        // })

        // const crudService = this.coreService.getCrudService('cr.CustomerEmergencyContact');
        //
        // crudService.list(objectRequest).subscribe(res => {
        //     debugger
        // })

        // this.ticketActionWidget = WidgetFactory.createWidgetList(
        //     'test.haris.ticket.action.1.2.11.2.122222',
        //     'Ticket Actions',
        //     'table',
        //     'list',
        //     'entity',
        //     'tm.TicketTypeAction',
        //     'Keine Daten vorhanden',
        //     objectRequest
        // );

        // objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        //     ObjectRequestComplexNode.createRelationNode('objectRoles', ObjectRequestRelation.createJoin('tm.TicketTypeObjectRole#ticketType')));
        // this.ticketTypeService.list(objectRequest).subscribe(res => {
        //     console.log(res);
        //     const filtered = res.entries.filter(item => item['objectRoles'] != undefined);
        //     console.log('filtered', filtered);
        // })
        // // objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        // //     ObjectRequestComplexNode.createRelationNode("objectType",
        // //         ObjectRequestRelation.createList(
        // //             "cc.ObjectType",
        // //             "objectType",
        // //             [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 333)],
        // //             [],
        // //             ObjectRequestComplexRelationBindingEnum.ALL)
        // //     )
        // // )
        //
        // this.uiPageUserService.pageVisit(this.route.routeConfig.path, 'Ticket', UiPageTypeEnum.object, 'tm.Ticket', null,108);
        // this.contactService.list(objectRequest).subscribe(value => {
        //     console.log(value);
        // });
        //
        // this.objectUserAccessService.list(objectRequest).subscribe(res => {
        //     console.log(res);
        // })
        //
        // // 'tm.Ticket:1;tm.Ticket:2'
        // const ids = 'tm.Ticket:12';
        // // 'tm.Ticket', 12
        // this.objectUserAccessService.listObjects(ids).subscribe(res => {
        //     console.log(res);
        // },error => {
        //     console.log(error);
        // })


        // const identifier = new ObjectIdentifier('cr.Customer',1);
        // this.observerService.addObject(identifier);
        //
        // this.observerService.refreshData.subscribe(res => {
        //     console.log(res);
        // })

        // this.customerService.list(objectRequest).subscribe(res => {
        //     debugger
        // }, error => {
        //     debugger
        // })


        // this.customerService.template(new DtoTemplate()).subscribe(res => {
        //
        // })

        // const dto = new ContractPersonDto();

        // this.personRelationPersonService.delete(38).subscribe(res => {
        //     debugger
        // })

        // for (let i = 22796; i < 22808; i++) {
        //     this.contractPersonService.delete(i);
        // }
        // dto.id = 11871;

        // dto['contractDtoId'] = 51334
        // dto['personDtoId'] = 3178;
        // dto.contractPersonTypeDtoId = 1;
        // dto.owner = false;
        // dto.insured = false;
        //
        // this.contractPersonService.create(dto).subscribe(res => {
        //     console.log(res);
        // },error => {
        //     console.log(error)
        // })

        // this.ticketActionCommentService.delete(52).subscribe(res => {
        //     console.log('deleted');
        // })

        // this.metaService.getByAlias('cr.Customer',false,true).subscribe(res => {
        //     console.log(res);
        // })

        // const dto = new DtoDetail();
        // dto.id = 4258;
        //
        // this.notificationChannelRecipientService.update(dto).subscribe(res => {
        //     debugger
        // },error =>  {
        //     debugger
        // })

        // const dto = new TicketActionDto();
        // dto.ticketDtoId = 35;
        // dto.ticketTypeActionDtoId = 4;
        //
        // // this.ticketActionService.list(new ObjectRequestList(false, [], [])).subscribe(res => {
        // //     console.log('ticketActionService', res);
        // // })
        //
        // this.ticketActionService.create(dto).subscribe(res => {
        //     console.log('ticketActionService', res);
        // })

        // this.ticketTypeActionService.list(new ObjectRequestList(false, [], [])).subscribe(res => {
        //     console.log('ticketTypeActionService', res);
        // })

        // const dto = new TicketObjectDto();
        // dto.id = 17;
        // dto.ticketTypeObjectRoleDtoId = 2;
        // this.ticketObjectService.update(dto).subscribe(res => {
        //     debugger
        // })

        // this.testBatchService();
        this.refreshWidget();

        // this.customerEmergencyContactService.list(new ObjectRequestList(true, [],[])).subscribe(res => {
        //
        // });


        //  this.refreshComponent();
        //
        // this.ticketIndicatorFilterCriteria = [
        //     FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, 5637),
        //     FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved),
        //     FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled)
        //
        // ];
        //
        // this.formGroup = new FormGroup({
        //     controlName: new FormControl('date')
        // });

        // this.ticketService.listObject(5637,'cr.Customer').subscribe(res => {
        // },error => {
        // });

        // this.ticketTypeService.list(new ObjectRequestList(false,[],[])).subscribe(res => {
        //     console.log(res);
        // })

        // this.ticketService.createTicketForObject(3,5637,'cr.Customer').subscribe(res => {
        //     debugger
        // },error => {
        //     debugger
        // });

        // this.workflowIndicatorObjectRequest = this.processService.getRequestForProcessForObjectViaAlias(
        //     this.objectIdentifier.objectType,
        //     this.objectIdentifier.objectId,
        //     true
        // );


        // const request = new ObjectRequestList(
        //     false,
        //     [FilterCriteria.create('person', 'EQ', 1)],
        //     []
        // );
        // request.objectRequestComplex = ObjectRequestComplex.build(
        //     true,
        //     true,
        //     ObjectRequestComplexNode.createSimpleAttributeNode("household").addNodes(
        //         ObjectRequestComplexNode.createRelationNode('householdPerson',
        //             ObjectRequestRelation.createJoin('pm.HouseholdPerson#household')
        //         )
        //     )
        // );
        // this.householdPersonService.list(request).subscribe(res => {
        //     console.log(res.entries);
        // });

        // const dto = new HouseholdDto();
        // dto.id = 1;
        // dto.name = `Father's household`;
        //
        // this.householdService.update(dto).subscribe(res => {
        //
        // })

        // this.getHouseholdService();
        // this.createHousehold();
        // this.createHouseholdPerson();
        // this.createPersonRelationPerson();

        // const crudService = this.coreService.getCrudService('pm.PersonRelationPerson');
        // if (!crudService) {
        //     return;
        // }
        //
        // crudService.list(new ObjectRequestList(false, [],[])).subscribe(res => {
        //     debugger
        // })


        // this.binarySearch([1, 2, 7, 9, 11, 17, 22, 24, 25], 22);

        this.initialized = true;
    }

    getPicture() {
        this.userService.getPictureBinaryById(100000).subscribe(res => {
            UserProfileHelper.getImageSrcFromBlob(res).then(value => {
                this.imgSrc = value;
            });
        }, error => {
        });
    }

    delPicture() {
        this.userService.delete(100000).subscribe(res => {
        }, error => {
        });
    }

    testBatchService() {
        const ids = [1, 2, 3, 1, 1, 2, 3, 2, 2, 2];

        for (let id of ids) {
            this.customerService.get(id).subscribe(res => {

            });
        }
    }

    toggleField() {
        this.fieldDisabled = !this.fieldDisabled;
    }

    widgetProcessToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['ticketDtoId'] = 35;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    // showCustomerHouseholdPerson() {
    //     this.customerHouseholdPerson = WidgetFactory.createWidgetList(
    //         'cr.customer.contract.navigation.customer.household.person.1',
    //         'Household Persons',
    //         'listDataExpandable',
    //         'list',
    //         'entity',
    //         'pm.HouseholdPerson',
    //         'No data found',
    //         new ObjectRequestList(true,
    //             [FilterCriteria.create('person', FilterCriteria.cOperatorEqual,this.dto.personDtoId)],
    //             [])
    //     );
    //     // this.customerHouseholdPerson.dataProviderListRequest.objectRequestComplex = this.getComplexRequest();
    // }

    getComplexRequest(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode("household")
                .addNodes(
                    ObjectRequestComplexNode.createRelationNode('householdPerson', ObjectRequestRelation.createJoin('pm.HouseholdPerson#household'))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person")
                            // .addNodes(
                            //     ObjectRequestComplexNode.createRelationNode('customerDto', ObjectRequestRelation.createJoin('+crCustomer')),
                            //     ObjectRequestComplexNode.createRelationNode('relatedPersons', ObjectRequestRelation.createJoin('pm.PersonRelationPerson#personB')),
                            //
                            // )
                        )
                )
        );
    }

    testObjectIdentifier = [new ObjectIdentifier('tm.Ticket', 81)];

    setActivityContext() {
        this.activityContextHolderService.setContext(this.testObjectIdentifier);
    }

    removeActivityContext() {
        // this.activityContextHolderService.removeObject(this.testObjectIdentifier);
        this.activityContextHolderService.clearContext();
    }

    requestAPI() {
        this.customerService.template(new DtoTemplate()).subscribe(res => {

        });
    }

    customerContractToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['ticketDtoId'] = 81;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    widgetCustomerContacts: WidgetData;

    refreshWidget() {
        // this.widgetUserRole = WidgetFactory.createWidgetList(
        //     "test.haris.tm.config.user.role.test.1235551",
        //     "Ticket Objects",
        //     "list",
        //     "list",
        //     "entity",
        //     "pm.HouseholdPerson",
        //     "Nothing found",
        //     ObjectRequestList.createBasic(true,
        //         [],
        //         []
        //     )
        // );

        // this.widgetCustomerContacts = new WidgetData();
        // this.widgetCustomerContacts.idAlias = "cr.customer.basic.contacts";
        // this.widgetCustomerContacts.name = "Kontaktdaten";
        // this.widgetCustomerContacts.uiComponent = "list";
        // this.widgetCustomerContacts.dataProvider = "list";
        // this.widgetCustomerContacts.dataSource = "entity";
        // this.widgetCustomerContacts.dataProviderObject = "cr.CustomerContact";
        // this.widgetCustomerContacts.dataProviderListRequest = new ObjectRequestList(
        //     true,
        //     [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
        //     [new Sorting("createdDate", false)]
        // );
        // this.widgetCustomerContacts.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode("contact"),
        // );
        // this.widgetCustomerContacts.setParamValue("size", "S");
        // this.widgetCustomerContacts.setParamValue("objectWidget", "data");

        this.widgetCustomerContacts = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.status',
            "Selection Status",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionStatus',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter', FilterCriteria.cOperatorEqual, 2)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "status")
        );


        const dto = new DtoDetail();
        dto['ticketDtoId'] = 81;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('ticketDtoId', MvsFormFieldAccessEnum.READ);

        this.widgetUserRole = WidgetFactory.createWidgetList(
            'test.haris.cr.customer.contract.navigation.customer.household.person.1.2.person.23',
            'Customer Action',
            'list',
            'list',
            'entity',
            'cd.Contact',
            'No data found',
            new ObjectRequestList(true,
                [FilterCriteria.create('id', FilterCriteria.cOperatorBetween, 29550, 29560)],
                []),
        );

        this.widgetUserRole1 = WidgetFactory.createWidgetList(
            'cr.customer.contract.navigation.customer.household.person.1.2.person.23',
            'Customer Action 2',
            'table',
            'list',
            'entity',
            'cr.Customer',
            'No data found',
            new ObjectRequestList(true,
                [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 5637)],
                []),
        );

        // this.widgetUserRole.functionCallbacks = this.customerContractToolbarCreate();

        // this.widgetUserRole.dataProviderListRequest.objectRequestComplex = this.getComplexRequest();

        this.customerContractWidgetData = new WidgetData();
        this.customerContractWidgetData.idAlias = "test.haris.cr.customer.basic.contract.1"; //"test.haris.cr.customer.basic.contract.1"
        this.customerContractWidgetData.name = "Verträge";
        this.customerContractWidgetData.uiComponent = "table";
        this.customerContractWidgetData.dataProvider = "list";
        this.customerContractWidgetData.dataSource = "entity";
        this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";


        this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            [FilterCriteria.create('customer', 'EQ', 5637)],
            [new Sorting('lastModifiedDate', false)],
            null
        );

        this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex =
            ObjectRequestComplex.build(true, false, ObjectRequestComplexNode.createSimpleAttributeNode('contract'));


        // this.widgetUser = WidgetFactory.createWidgetList(
        //     "test.haris.um.config.user",
        //     "User",
        //     "table",
        //     "list",
        //     "entity",
        //     "um.User",
        //     "Keine Daten vorhanden",
        //     ObjectRequestList.createBasic(true, [FilterCriteria.create('id', 'EQ',83)], [new Sorting("username", true)])
        // );

        // this.widgetUserRole.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(false, false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('ticket'))

        // this.widgetUserRole.functionCallbacks = this.widgetProcessToolbarCreate();

        // this.widgetUserRole = WidgetFactory.createWidgetList(
        //     'test.haris.cr.customer.standard.navigation.customer.emergency.contacts.basic.1',
        //     'Emergency Contacts',
        //     'list',
        //     'list',
        //     'entity',
        //     'cr.CustomerEmergencyContact',
        //     'No data found',
        //     new ObjectRequestList(true, [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],[])
        // );

    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }

    widgetCustomerInsuranceToolbarFunction() {
        return {
            onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['personDtoId'] = 5637;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    showAllCustomerContracts() {

        const objectRequest = new ObjectRequestList(true, [], []);

        this.householdPersonWidget = WidgetFactory.createWidgetList(
            'cm.create.insurable.household.object.create.form.widget',
            'Household Person',
            'table',
            'list',
            'entity',
            'pm.HouseholdPerson',
            'Keine Daten vorhanden!',
            objectRequest
        );

        // this.householdPersonWidget.functionCallbacks = this.widgetCustomerInsuranceToolbarFunction();


        this.householdWidget = WidgetFactory.createWidgetList(
            'cm.create.household.person.object.create.form.widget',
            'Household',
            'table',
            'list',
            'entity',
            'pm.Household',
            'Keine Daten vorhanden!',
            objectRequest
        );

        // this.personRelationPersonWidget = WidgetFactory.createWidgetList(
        //     'cm.create.household.person.object.relation.person.create.form.widget',
        //     'Person Relation Person',
        //     'table',
        //     'list',
        //     'entity',
        //     'pm.PersonRelationPerson',
        //     'Keine Daten vorhanden!',
        //     objectRequest
        // );

        this.personRelationPersonWidget = WidgetFactory.createWidgetList(
            'cr.customer.standard.navigation.related.persons.object.pm.related.person.1.2',
            'Verknüpfte Personen',
            'list',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'No data found',
            new ObjectRequestList(true, [FilterCriteria.create('personA', 'EQ', 5637)], [])
        );
        this.personRelationPersonWidget.functionCallbacks = this.personRelationPersonToolbar();


        // this.customerContractWidgetData = WidgetFactory.createWidgetListComplex(
        //     "cr.customer.basic.contact.13623",
        //     "Kundeninfo",
        //     "table",
        //     "list",
        //     "entity",
        //     "cr.Customer",
        //     "Keine Kontakdaten vorhanden",
        //     new ObjectRequestList(true, [],[]),
        //     null,
        //     WidgetDataParam.create("size", "S"),
        //     WidgetDataParam.create("objectWidget", "data"),
        // );
        //
        // this.customerContractWidgetData.dataProviderListRequest.paging = PagingDto.create(0,5);

        // this.customerContractWidgetData = WidgetFactory.createWidgetObject(
        //     "cr.customer.basic.contact.1362",
        //     "Form",
        //     'cr.Customer'
        // )

        // this.customerContractWidgetData.dataProviderListRequest.paging = PagingDto.create(0, 20);


        // this.customerCallsCategoryWidget = WidgetFactory.createWidgetGroupBy(
        //     "cr.customer.communication.navigation.show.customer.calls.category",
        //     "Anrufübersicht",
        //     "table",
        //     "entity.groupBy",
        //     "pc.Call",
        //     "callResult",
        //     "callResult_count",
        //     [FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, 5637),
        //     ],
        //     "callResult",
        //     "Anzahl"
        // );
        //
        // this.customerContractWidgetData = new WidgetData();
        // this.customerContractWidgetData.idAlias = "cr.customer.basic.contract";
        // this.customerContractWidgetData.name = "Verträge";
        // this.customerContractWidgetData.uiComponent = "table";
        // this.customerContractWidgetData.dataProvider = "list";
        // this.customerContractWidgetData.dataSource = "entity";
        // this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";
        //
        // // this.customerContractWidgetData.groupBy = this.groupByContractWidgetData;
        //
        // const filterCriteria = [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, 5637)];
        // this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
        //     true,
        //     filterCriteria,
        //     [new Sorting('lastModifiedDate', false)],
        //     PagingDto.create(0, 7)
        // );
        //
        // this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCustomerContractsComplexSelection();
        // // this.customerContractWidgetData.setParamValue("selectionMode", "rowSelect");
        // this.customerContractWidgetData.setParamValue("size", "S");
        // // this.customerContractWidgetData.setParamValue("mode", MvsCrudModeEnum.read);
        // this.customerContractWidgetData.setParamValue("objectWidget", "data");
        // // this.customerContractWidgetData.setParamValue("objectId", "109870");
        // if (this.selectedRelatedPersons) {
        //     this.selectedRelatedPersons.setSelectedPerson(null);
        // }
        //
        // this.customerContractWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        //
        // this.createContractToolbarButtons();

        // this.customerCallsCategoryWidget = WidgetFactory.createWidgetGroupBy(
        //     "cr.customer.communication.navigation.show.customer.calls.category.1",
        //     "Anrufübersicht",
        //     "category",
        //     "entity.groupBy",
        //     "cr.Customer",
        //     "customerType",
        //     "customerType_count",
        //     [],
        //     "customerType",
        //     "Anzahl"
        // );


        // const filter = FilterCriteria.create('entityStatus', 'EQ', 1);
        // const objectRequest = this.getObjectRequestGroupBy(null);
        // this.getCustomerRequestObservable(objectRequest).subscribe(res => {
        //
        //     const relevantEntry = res.entries[0];
        //     if (relevantEntry) {
        //         // this.loadCustomerData(relevantEntry);
        //     }
        // },error => {
        // });

    }

    personRelationPersonToolbar() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = 5637;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    getObjectRequestGroupBy(filter: FilterCriteria[]): ObjectRequestListGroupBy {
        const objectRequestList = new ObjectRequestListGroupBy(true, filter, []);
        objectRequestList.groupBy = [this.groupByField];
        objectRequestList.attributes = [new ObjectRequestListAttribute(this.groupByField, this.groupByField, DtoListAttributeRequestAggregateEnum.count)];
        return objectRequestList;
    }

    getCustomerRequestObservable(objectRequestList: ObjectRequestListGroupBy) {
        // return this.customerService.groupBy(objectRequestList);
    }

    loadCustomerData(entry: DtoDetail) {
        let groupByFieldValue: string;

        if (entry[this.groupByField + 'DtoId']) {
            groupByFieldValue = entry[this.groupByField + 'DtoId'];
        } else if (entry[this.groupByField + 'DtoName']) {
            groupByFieldValue = entry[this.groupByField + 'DtoName'];
        }

        if (!groupByFieldValue) {
            return;
        }

        const filter = FilterCriteria.create(this.groupByField, FilterCriteria.cOperatorEqual, groupByFieldValue);
        // const objectRequest = this.getObjectRequestList([filter]);
        const objectRequest = new ObjectRequestList(
            true,
            [filter],
            []
        );
        // this.customerService.list(objectRequest).subscribe(res => {
        //
        // });
    }

    onSetFilterCriteria(event: ObjectIdentifierData, type: string) {
    }

    handlePersonCreated(event: any) {
        debugger
    }

    handleFormChange(event: DtoDetail) {
        console.log(event);
    }

    handleNav() {
        const object = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', null, null, null, null, null, MvsObjectNavigationActionEnum.any, 'side');
        this.navigationService.navigateTo(object, 'right');
    }

    trigger() {
        // const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', 235, null, "Object", null, null, MvsObjectNavigationActionEnum.edit);
        // this.navigationService.navigateTo(navigationEntry,'right');
        const crudService = this.coreService.getCrudService('tm.TicketTypeObjectRole');
        const dto = new DtoDetail();
        dto.id = 2;
        dto['image'] = 'pi pi-user';

        crudService.update(dto).subscribe(res => {
            console.log('done');
        })

    }


    protected readonly ObjectIdentifier = ObjectIdentifier;
}