import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {NsNotificationService} from "../../../../service/api/ns-notification.service";
import {NsNotificationStatusEnum} from "../../../../enum/ns-notification-status-enum.enum";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: './notification-statistics.component.html',
})
export class NotificationStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {


    pendingNotification: number = 0;
    processedNotification: number = 0;
    deletedNotification: number = 0;
    errorNotification: number = 0;

    notificationHistoryWidget: WidgetData;
    notificationTypeWidget: WidgetData;
    notificationChannelWidget: WidgetData;
    recentlySentNotificationsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        protected notificationService: NsNotificationService,
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'Notification Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getTotalNotification();
        this.refreshNotificationHistoryWidget();
        this.refreshNotificationTypeWidget();
        this.refreshNotificationChannelWidget();
        this.refreshRecentlySentNotifications();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/ns/config']);
    }


    getTotalNotification() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['notificationStatus'],
            [new ObjectRequestListAttribute('notificationStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.notificationService.groupBy(objectRequestList).subscribe(res => {

            this.overviewDto = [
                { label: 'Pending Notification', value: 0 },
                { label: 'Processed Notification', value: 0 },
                { label: 'Deleted Notification', value: 0 },
                { label: 'Error Notification', value: 0 }
            ];

            for (let entry of res.entries) {
                if (entry['notificationStatus'] === NsNotificationStatusEnum.pending) {
                    this.overviewDto.find(item => item.label === 'Pending Notification').value = entry['notificationStatus_count'];
                } else if (entry['notificationStatus'] === NsNotificationStatusEnum.processed) {
                    this.overviewDto.find(item => item.label === 'Processed Notification').value = entry['notificationStatus_count'];
                } else if (entry['notificationStatus'] === NsNotificationStatusEnum.deleted) {
                    this.overviewDto.find(item => item.label === 'Deleted Notification').value = entry['notificationStatus_count'];
                } else if (entry['notificationStatus'] === NsNotificationStatusEnum.error) {
                    this.overviewDto.find(item => item.label === 'Error Notification').value = entry['notificationStatus_count'];
                }
            }

        });
    }

    refreshNotificationHistoryWidget() {
        this.notificationHistoryWidget = new WidgetData();
        this.notificationHistoryWidget.idAlias = "ns.notification.overview.notification.history.widget";
        this.notificationHistoryWidget.name = "Notification History";
        this.notificationHistoryWidget.uiComponent = "history.chart";
        this.notificationHistoryWidget.dataProvider = "list";
        this.notificationHistoryWidget.dataSource = "entity.groupBy";
        this.notificationHistoryWidget.dataProviderObject = "ns.NsNotification";
        this.notificationHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["notificationStatus", "createdDate"],
            [new ObjectRequestListAttribute("notificationStatus", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.notificationHistoryWidget.setParamValue("fieldCategory", "notificationStatus");
        this.notificationHistoryWidget.setParamValue("fieldCategoryCount", "notificationStatus_count");
        this.notificationHistoryWidget.setParamValue("historyPeriodMode", "year");
        this.notificationHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.notificationHistoryWidget.setParamValue("selectionMode", "single");
        this.notificationHistoryWidget.setParamValue("size", "S");
    }

    refreshNotificationTypeWidget() {
        this.notificationTypeWidget = WidgetFactory.createWidgetGroupBy(
            'ns.notification.overview.notification.type.widget',
            'Notification Type',
            'category',
            'entity.groupBy',
            'ns.NsNotificationType',
            'group',
            'group_count',
            this.filterCriteria,
            'group',
            'Anzahl');
    }

    refreshNotificationChannelWidget() {
        this.notificationChannelWidget = WidgetFactory.createWidgetGroupBy(
            'ns.notification.overview.notification.channel.widget',
            'Notification Channels',
            'category',
            'entity.groupBy',
            'ns.NsNotificationTypeChannelType',
            'channelType',
            'channelType_count',
            this.filterCriteria,
            'channelType',
            'Anzahl');
    }

    refreshRecentlySentNotifications() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlySentNotificationsWidget = WidgetFactory.createWidgetTableEntity(
            'ns.notification.overview.recently.sent.notification',
            'Recently Sent Notification',
            'ns.NsNotification',
            'No Notification',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
