<ng-container *ngIf="objectIdentifier && contractDto">

    <!--    <p-dropdown *ngIf="contracts" [options]="contracts" (onChange)="onRowClick(this.objectIdentifier.objectId)" [(ngModel)]="this.objectIdentifier.objectId" [showClear]="true" placeholder="Select a City"></p-dropdown>-->


    <!--    <p-tabMenu [model]="navigateViewTypes" [activeItem]="activeViewTypeItem"></p-tabMenu>-->

    <!--    <p-megaMenu [model]="navigateViewTypes" styleClass="horizontal flex">-->

    <!--    </p-megaMenu>-->

    <!-- Contract Navigation -->
    <div class="flex justify-content-between w-full align-items-center surface-0 shadow-1 px-4 py-3 my-3 contract-sticky-header">

        <div class="flex align-items-center">

            <div class="flex gap-3 align-items-center">
                <i *ngIf="contractDto && contractDto.contractTypeDto && contractDto.contractTypeDto.image"
                   class="fa-2x {{contractDto.contractTypeDto.image}}"></i>

            <ng-container *ngIf="contractDto">
                <div class="mr-5">
                    <h5 class="mb-0">{{ contractDto.contractTypeDto?.name }}</h5>
                    <small *ngIf="contractDto.joinCurrentMainCustomer">
                        <a href="#/cr/customers/{{contractDto.joinCurrentMainCustomer.id}}/0"
                           target="_blank">{{ contractDto.joinCurrentMainCustomer?.personDto?.firstName }} {{ contractDto.joinCurrentMainCustomer?.personDto?.lastName }}</a>
                    </small>
                </div>
            </ng-container>
            </div>

            <div class="flex gap-3 align-items-center">
                <p-divider  layout="vertical"  styleClass="border-1 border-300" *ngIf="contractDto.joinCurrentMainPartner"></p-divider>
                <img class="w-3rem h-3rem border-1 border-300 p-1 border-round-lg"
                     *ngIf="contractDto.joinCurrentMainPartner" [src]="contractDto.joinCurrentMainPartner.image"
                     alt="Contract Partner Image"/>
                <div class="flex flex-column  gap-1">
                <span class="font-medium" *ngIf="contractDto.joinCurrentMainPartner" pTooltip="Contract Partner" tooltipPosition="bottom">
                    {{ contractDto.joinCurrentMainPartner.name }}
                </span>
                    <span class="surface-100 border-round-lg p-2 w-max" pTooltip="Contract Identifier"
                          tooltipPosition="bottom">{{ contractDto.contractIdentifier }}</span>
                </div>
            </div>

            <!--                <p-megaMenu [model]="navigateViewTypes" styleClass="horizontal flex">-->

            <!--                    <ng-template pTemplate="start">-->
            <!--                        <ng-container *ngIf="contractDto">-->
            <!--                            <div class="mr-5">-->
            <!--                            <h5 class="mb-0">{{contractDto.contractTypeDto?.name}}</h5>-->
            <!--                            <small *ngIf="contractDto.joinCurrentMainCustomer">-->
            <!--                                <a href="#/cr/customers/{{contractDto.joinCurrentMainCustomer.id}}/0" target="_blank">{{contractDto.joinCurrentMainCustomer?.personDto?.firstName}} {{contractDto.joinCurrentMainCustomer?.personDto?.lastName}}</a>-->
            <!--                            </small>-->
            <!--                            </div>-->
            <!--                        </ng-container>-->
            <!--                    </ng-template>-->

            <!--                </p-megaMenu>-->
        </div>

        <div class="flex align-items-center">
            <cm-contract-navigation-contract class="surface-100 p-2 border-round-lg"
                    (onSelect)="handleIdSelect($event)">
            </cm-contract-navigation-contract>
        </div>


        <!--        <div class="flex flex-wrap">-->
        <!--            <core-indicator-->
        <!--                    [objectType]="'tm.Ticket'"-->
        <!--                    [label]="'Tickets'"-->
        <!--                    [filter]="ticketIndicatorFilterCriteria"-->
        <!--                    overlayPosition="top-right"-->
        <!--                    class="cr-customer-overlay"-->
        <!--            >-->
        <!--            </core-indicator>-->

        <!--            <core-indicator-->
        <!--                    [objectType]="'wf.WfProcess'"-->
        <!--                    [label]="'Workflows'"-->
        <!--                    [filter]="workflowIndicatorFilterCriteria"-->
        <!--                    [objectRequest]="workflowIndicatorObjectRequest"-->
        <!--                    overlayPosition="top-right"-->
        <!--            >-->
        <!--            </core-indicator>-->
        <!--        </div>-->
    </div>

    <!-- Show Object -->
    <mvs-cm-contract
            [objectIdentifier]="objectIdentifier"
            [isOverlay]="isOverlay"
            [viewType]="viewType"
            [transientDto]="contractDto"
            (onTicketsCount)="handleTicketsCount($event)"
            (onWorkflowsCount)="handleWorkflowsCount($event)"
    ></mvs-cm-contract>

</ng-container>

<p-overlayPanel
        styleClass="ml-4"
        #op
        disabled="true"
        appendTo="body">

    <h3>Create Ticket</h3>

    <mvs-create-ticket-object *ngIf="showCreateTicketOverlay"
                              [objectId]="contractDto.id"
                              [objectAlias]="'cm.Contract'"
                              (onTicketCreation)="handleTicketCreation($event)">
    </mvs-create-ticket-object>

</p-overlayPanel>
