import {Component, OnInit} from "@angular/core";
import {TeArtefactObjectBaseComponent} from "../te-artefact-object-base.component";

@Component({
    selector: 'te-artefact-object-full',
    templateUrl: './te-artefact-object-full.component.html',
})

export class TeArtefactObjectFullComponent extends TeArtefactObjectBaseComponent implements OnInit{

    ngOnInit() {
        super.ngOnInit();
    }

}