import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../dto/object-type.dto";
import {DtoTemplate} from "@kvers/alpha-core-common";


@Component({
    selector: 'cc-object-type-object',
    templateUrl: './object-type-object.component.html',
})
export class ObjectTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    dto: ObjectTypeDto;

    tabItems: MenuItem[];
    activeItem: MenuItem | undefined;

    widgetObjectType: WidgetData;
    objectTypeForm: MvsFormDto;

    ngOnInit() {
        super.ngOnInit();

        this.tabItems = [
            { id : "basic", label : "Objekt Typ", icon : "fa-light fa-link"},
            { id : "fieldConfig", label : "Feldkonfiguration", icon : "fa-sharp fa-light fa-wrench"},
        ];
        this.activeItem = this.tabItems[0];

    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

        this.objectTypeForm = null;

        this.widgetObjectType = WidgetFactory.createWidgetObject(
            "cc.object.type.object.component.base",
            "Objekt Typ",
            "cc.ObjectType",
            this.dto.id
        );

        // retrieve form information
        this.coreService.getCrudService(this.dto.alias).template(DtoTemplate.createEmpty()).subscribe(value => {
            this.objectTypeForm = value;
        });
    }

}
