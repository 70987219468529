import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {WfProcessStepActivityBaseComponent} from "../wf-process-step-activity-base/wf-process-step-activity-base.component";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {DtoDetail, MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WfProcessRuntimeBindingDto} from "../../../service/dto/wf-process-runtime-binding.dto";
import {WfActivityTypeObjectActivityEnum} from "../../../enum/wf-activity-type-object-activity-enum.enum";
import {WidgetData} from "@kvers/alpha-core-common";
import {WfActivityFormDefaultDto} from "../../../service/dto/wf-activity-form-default.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {WfProcessTypeStepActivityService} from "../../../service/api/wf-process-type-step-activity.service";
import {WfProcessRuntimeService} from "../../../service/wf-process-runtime.service";
import {WfActivityProcessingResultFieldDto} from "../../../service/dto/wf-activity-processing-result-field.dto";
import {WfBindingFieldActionEnum} from "../../../enum/wf-binding-field-action.enum";
import {WfProcessObjectUiType} from "../../../dto/wf-process-type-step-activity-object.dto";


/**
 * TODO: Describe which activities are processed here ...
 */
@Component({
    selector: 'mvs-wf-process-step-activity-object',
    templateUrl: './wf-process-step-activity-object.component.html',
})
export class WfProcessStepActivityObjectComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    connectedField: boolean = true;
    stepActivityMultiSelectWidget: WidgetData;
    objectWidget: WidgetData;
    @Input() objectTypeDto: ObjectTypeDto;
    listFilterCriteria: FilterCriteria[];
    objectId: number = 0;
    constBindingFieldPrefix: string = "f_";

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected objectTypeService: ObjectTypeService,
        protected activityTypeService: WfProcessTypeStepActivityService) {

        super(coreService, messageService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.handleObjectRefresh()
    }

    handleObjectRefresh() {

        this.busy = true;
        // initialize filters
        this.listFilterCriteria = [];

        // initialize defaultDto
        this.createDefaultDto = new DtoDetail({});
        this.formControlOverwrite = new MvsFormControlOverwrite();

        // retrieve processed object in case of NOT CREATE
        if (this.activityType.activityObjectDto.activityEnum !== WfActivityTypeObjectActivityEnum.create) {
            const processedObjectBinding = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "objectProcess");

            //processedObjectBinding.objectValue
            if (processedObjectBinding) {
                this.objectId = processedObjectBinding.objectId;

                this.handleResult(new ObjectIdentifier(processedObjectBinding.objectType.alias, processedObjectBinding.objectId));
            }

        }

        // process field bindings
        WfActivityFormDefaultDto.processBindings(this.bindings, this.createDefaultDto, this.constBindingFieldPrefix, this.listFilterCriteria);

        //TODO: call static runtime method processBindingsAndFormDefaults();
        WfActivityFormDefaultDto.processFormDefaults(this.formDefaults, this.createDefaultDto, this.listFilterCriteria, this.formControlOverwrite);

        if (this.activityType.activityObjectDto.objectShowList || this.activityType.activityObjectDto.activityEnum == WfActivityTypeObjectActivityEnum.select) {
            this.refreshStepActivityMultiSelectWidgetTable(this.listFilterCriteria);
        }
        this.refreshObject();

        this.busy = false;
        this.initialized = true;
    }


    /**
     * get created object
     * @param event
     */
    handleObject(event: ObjectChangeInformation) {
        this.objectId = event.after.id;
        this.refreshStepActivityMultiSelectWidgetTable(this.listFilterCriteria);


        this.handleResult(new ObjectIdentifier(event.objectType, event.after.id))


    }

    handleResult(objectIdentifier: ObjectIdentifier) {

        const {bindingExportObject, wfBindingFieldActionEnum} = this.getAlias()

        const result: WfActivityProcessingResultFieldDto = {
            alias: bindingExportObject,
            action: wfBindingFieldActionEnum,
            valueObjectTypeAlias: objectIdentifier.objectType,
            valueObjectId: objectIdentifier.objectId
        };

        WfProcessRuntimeService.setResult([result])
    }


    getAlias(): { bindingExportObject: string, wfBindingFieldActionEnum: WfBindingFieldActionEnum } {
        switch (this.activityType.activityObjectDto.activityEnum) {
            case WfActivityTypeObjectActivityEnum.create:
                return {
                    bindingExportObject: 'objectCreated',
                    wfBindingFieldActionEnum: WfBindingFieldActionEnum.created
                };
            case WfActivityTypeObjectActivityEnum.remove:
                return {
                    bindingExportObject: 'objectDeletedObjectTypeId',
                    wfBindingFieldActionEnum: WfBindingFieldActionEnum.deleted
                };
            case WfActivityTypeObjectActivityEnum.update:
                return {
                    bindingExportObject: 'objectUpdated',
                    wfBindingFieldActionEnum: WfBindingFieldActionEnum.updated
                };
            case WfActivityTypeObjectActivityEnum.display:
                return {bindingExportObject: '', wfBindingFieldActionEnum: WfBindingFieldActionEnum.selected};
            case WfActivityTypeObjectActivityEnum.select:
                return {
                    bindingExportObject: 'objectSelected',
                    wfBindingFieldActionEnum: WfBindingFieldActionEnum.selected
                };
            case WfActivityTypeObjectActivityEnum.review:
                return {
                    bindingExportObject: 'objectReviewed',
                    wfBindingFieldActionEnum: WfBindingFieldActionEnum.selected
                };
        }
    }

    /**
     * Change the processed object whenever the user selects something from the list.
     * @param objectIdentifier
     */
    onWidgetSelectEntry(objectIdentifier: ObjectIdentifier) {
        this.objectId = objectIdentifier.objectId;

        this.handleResult(objectIdentifier);

        this.refreshObject();

    }

    /**
     * Got back to create.
     * @param event
     */
    onBackToCreate(event: any) {
        this.objectId = 0;
        this.objectWidget = null;
        WfProcessRuntimeService.resetResult()
        setTimeout(() => {
            this.refreshObject();
        }, 0);

    }


    async refreshStepActivityMultiSelectWidgetTable(filterCriteriaList: FilterCriteria[]) {

        // retrieve object Type DTO
        if (!this.objectTypeDto || this.objectTypeDto.id !== this.activityType.activityObjectDto.objectTypeDtoId) {
            this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.activityObjectDto.objectTypeDtoId);
        }

        this.stepActivityMultiSelectWidget = this.activityTypeService.refreshStepActivityMultiSelectWidgetTable(this.activityType, this.objectTypeDto.alias, filterCriteriaList);

    }


    /**
     * form widget to show display objects
     */
    async refreshObject() {

        // retrieve object Type DTO
        if (!this.objectTypeDto || this.objectTypeDto.id !== this.activityType.activityObjectDto.objectTypeDtoId) {
            this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.activityObjectDto.objectTypeDtoId);
        }

        const alias = 'wf.processed.object.widget.' + this.activityType.id;

        this.objectWidget = WidgetFactory.createWidgetObject(alias, '', this.objectTypeDto.alias, this.objectId,
            WidgetDataParam.create("formControlOverwrite", this.formControlOverwrite),
            WidgetDataParam.create("mandatoryDefaultValues", true),
            WidgetDataParam.create("createDefaultDto", this.createDefaultDto))
    }


    protected readonly WfActivityTypeObjectActivityEnum = WfActivityTypeObjectActivityEnum;
    protected readonly MvsCrudModeEnum = MvsCrudModeEnum;
    protected readonly WfProcessObjectUiType = WfProcessObjectUiType;
}
