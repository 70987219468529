import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    EmSchemaObjectComponent
} from "../../component/object-component/em-schema-object-component/em-schema-object.component";

@Injectable({
    providedIn: 'root'
})
export class EmSchemaService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/em/emSchemas')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return EmSchemaObjectComponent;
        }
        return null;
    }
}