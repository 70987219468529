import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http'

import { Observable, throwError } from 'rxjs'

import { retry, catchError } from 'rxjs/operators'
import {MessageService} from "primeng/api";
import {Injectable} from "@angular/core";
import {SUPPRESS_ERROR_HANDLER} from "@kvers/alpha-core-common";


@Injectable({
    providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private messageService: MessageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)

            .pipe(

                // do not retry!
                //retry(1),

                catchError((error: HttpErrorResponse) => {

                    // const suppressErrorHandling = request.context.get(SUPPRESS_ERROR_HANDLER);
                    if (error.error?.codes) {
                        return throwError(error);
                    }

                    // if (suppressErrorHandling) {
                    //     // Skip error handling for this request
                    //     return throwError(error);
                    // }

                    let err = {error: "", errorMessage:''}

                    if (error.error instanceof ErrorEvent) {
                        // client side error
                        err.errorMessage = `CLIENT: ${error.error.message}`;
                    } else {
                        let apiMessage = null;

                        if (error["error"]) {
                            apiMessage = error["error"];
                        }

                        // server side error
                        err.error = `${error.status} | ${error.name}`;
                        let url = new URL(error.url)
                        err.errorMessage = url.pathname

                        if(error.status === 0){
                            if(navigator.onLine){
                                err.errorMessage = 'Request could not send'
                            }
                            else {
                                err.errorMessage = 'no internet connection'
                            }
                        } else if(error.status === 500){
                            err.errorMessage = 'internal server error'
                        } else if(error.status === 403){
                            err.errorMessage = 'authentication failed'
                        } else if(error.status === 401){
                            // we do not need an error message for the microsoft LDAP calls
                            if (error.url == "https://graph.microsoft.com/v1.0/me" || error.url == "https://graph.microsoft.com/v1.0/me/photos/48x48/$value") {
                                err.errorMessage = ""; // no error message required
                            } else {
                                err.errorMessage = 'authentication/ authorization failed'
                            }
                        } else if(error.status === 404 && error.url.includes('photo/$')) {
                            // TODO: need to discuss with Marko how to handle this error
                            err.errorMessage = ''
                        }else{
                            if (error["error"] && error["error"]["data"]) {
                                // show the error DATA
                                err.errorMessage = error["error"]["data"];
                            } else {
                                err.errorMessage = error.message
                            }
                        }

                    }

                    if(err.errorMessage != ''){
                        this.messageService.add({severity:'error', summary: `${err.error}`, detail: err.errorMessage});
                    }
                    return throwError(error)
                })
            )
    }
}
