export class UserProfileHelper {

    public static getImageSrcFromBlob(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (blob.type === 'image/png') {
                    // PNG: return the data URL
                    resolve(reader.result as string); // Data URL for PNG
                } else if (blob.type === 'image/svg+xml') {
                    // SVG: base64 encode the SVG and return as data URL
                    const svgBase64 = btoa(reader.result as string); // Base64 encode SVG
                    resolve(`data:image/svg+xml;base64,${svgBase64}`); // Data URL for SVG
                } else {
                    reject('Unsupported image type');
                }
            };

            // Handle the appropriate blob processing based on type
            if (blob.type === 'image/png') {
                reader.readAsDataURL(blob); // Convert PNG Blob to Data URL
            } else if (blob.type === 'image/svg+xml') {
                reader.readAsText(blob); // Convert SVG Blob to text
            } else {
                reject('Unsupported image type');
            }
        });
    }

}