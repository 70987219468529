import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-xx-config-page',
  templateUrl: './xx-config.page.html',
  styleUrls: ['./xx-config.page.scss']
})
export class XxConfigPage implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  constructor() {
  }

  ngOnInit(): void {

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'xx.XxSimpleTestEntity',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Simple Test",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Simple Test"
        },



      ]
    };


  }
}
