import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {DtoDetail, MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerDto} from "../../../../../dto/customer.dto";
import {HouseholdPersonDto} from "../../../../../../pm/dto/household-person.dto";
import {PersonRelationPersonDto} from "../../../../../../pm/dto/person-relation-person.dto";
import {CustomerContractDto} from "../../../../../dto/customer-contract.dto";
import {ContractInsurableObjectDto} from "../../../../../../ci/dto/contract-insurable-object.dto";
import {ContractPersonDto} from "../../../../../../ci/dto/contract-person.dto";

export class ContractPersonRelationPersonWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;
    selectedId: number;

    constructor(protected configService: MvsConfigService,
                protected mainCustomer: CustomerDto,
                protected householdPersons: HouseholdPersonDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    public hasHouseholdPersons(): boolean {
        return (this.householdPersons && this.householdPersons.length > 0);
    }

    public getHouseholdPersons(): HouseholdPersonDto[] {
        return this.householdPersons;
    }

    public getHouseholdPerson(id: number): HouseholdPersonDto {
        const dto: HouseholdPersonDto = this.householdPersons.find(item => item.id == id);
        return dto;
    }

    public handleHouseholdPerson(householdPerson: HouseholdPersonDto) {

        const exists = this.getHouseholdPerson(householdPerson.id);
        if (!exists) {
            this.addHouseholdPerson(householdPerson);
        } else {
            this.removeHouseholdPerson(householdPerson);
        }
    }

    public addHouseholdPerson(householdPerson: HouseholdPersonDto) {

        if (!householdPerson.personDto.customerDto) {
            return;
        }

        if (!householdPerson['__color']) {
            const nextColor = this.configColors.getNextColor();
            householdPerson['__color'] = nextColor + '80';
            householdPerson['__backgroundColor'] = nextColor + '10';
        }
        this.householdPersons.push(householdPerson);


    }

    protected removeHouseholdPerson(householdPerson: HouseholdPersonDto) {
        const index = this.householdPersons.findIndex(item => item.id == householdPerson.id);
        if (index > -1) {
            this.householdPersons.splice(index, 1);
        }
    }


    getRowStyle(rowDto: CustomerContractDto, rowData: any): object {

        const contractDto = rowDto?.contractDto;

        if (!contractDto) {
            return {};
        }

        if (contractDto.contractPerson) {

            for (let householdPerson of this.householdPersons) {
                for (let person of contractDto.contractPerson) {
                    if (householdPerson.personDtoId == person.personDtoId) {
                        return {
                            "border": "2px",
                            "background-color": householdPerson['__color']
                        };
                    }
                }
            }
        }

        if (!this.selectedId) {
            return {}

        } else if (contractDto.contractInsurableObject && contractDto.contractPerson) {

            const insurableObjectClass = this.checkInsurableObjectClass(contractDto.contractInsurableObject);
            const contractPersonClass = this.checkContractPersonClass(contractDto.contractPerson);

            //checking if object is not empty
            if (Object.keys(insurableObjectClass).length || Object.keys(contractPersonClass).length) {
                return this.getRowStyleClass();
            }

        } else if (contractDto.contractInsurableObject) {
            return this.checkInsurableObjectClass(rowDto?.contractDto.contractInsurableObject)


        } else if (contractDto.contractPerson) {
            return this.checkContractPersonClass(rowDto?.contractDto.contractPerson);
        }
        return {};

    }

    protected checkInsurableObjectClass(insurableObject: ContractInsurableObjectDto[]): object {
        for (let object of insurableObject) {
            if (object.insurableObjectDto?.id == this.selectedId) {
                return this.getRowStyleClass();
            }
        }
        return {};
    }

    protected checkContractPersonClass(contractPersons: ContractPersonDto[]): object {
        for (let object of contractPersons) {
            if (object.personDtoId == this.selectedId) {
                return this.getRowStyleClass();
            }
        }
        return {};
    }

    getRowStyleClass(): object {
        return {
            "border": "2px",
            "background-color": 'var(--blue-100)'
        };
    }

    // getListStyle(rowDto: any, rowData: any): object {
    //
    //     if (rowDto['personADtoId']) {
    //         for (let myRelatedPerson of this.relatedPersons) {
    //             if (rowDto.personADtoId == myRelatedPerson.personADtoId && rowDto.personBDtoId == myRelatedPerson.personBDtoId && rowDto.id == myRelatedPerson.id) {
    //                 return {
    //                     "border-left": "4px solid " + myRelatedPerson['__color'],
    //                     "border-radius": '6px',
    //                     "display": 'block'
    //                 };
    //             }
    //         }
    //     }
    //
    //     return {};
    // }

    getHeaderStyle() {
        let headerStyle: object = {};
        if (this.selectedContractCustomerId) {
            for (const item of this.householdPersons) {
                if (item.personDto.customerDto.id == this.selectedContractCustomerId) {
                    headerStyle = {'background-color': item['__color']};
                }
            }
        }
        return headerStyle;
    }

    getExpandableListStyle(rowDto: HouseholdPersonDto): object {

        for (let item of this.householdPersons) {
            if (rowDto.personDtoId == item.personDtoId) {
                return {
                    "border-left": "4px solid " + item['__color'],
                    "border-radius": '6px',
                    "display": 'block'
                };
            }
        }
        return {};
    }

    public clone(): ContractPersonRelationPersonWidgetStyle {

        const widgetStyle = new ContractPersonRelationPersonWidgetStyle(this.configService, this.mainCustomer, this.householdPersons);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;
        widgetStyle.selectedId = this.selectedId;

        return widgetStyle;
    }

}
