<!--<p-toolbar>-->
<!--    <div class="p-toolbar-group-start">-->
<!--        <p-button *ngIf="activityType.activityStartProcessDto.startProcessTypeDtoId  && activityType.processing == 1"-->
<!--                  icon="pi pi-back"-->
<!--                  [label]="'Back to create'" (click)="onBackToCreate()" [disabled]="busy"></p-button>-->
<!--    </div>-->

<!--</p-toolbar>-->

<ng-container *ngIf="processRuntime.process.processStatusInternal != 2">

    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>

    <mvs-wf-process-start
            [processTypeId]="this.activityType.activityStartProcessDto.startProcessTypeDtoId"
            (onProcessCreated)="handleProcessCreated($event)"
    ></mvs-wf-process-start>
</ng-container>

<ng-container *ngIf="processRuntime.process.processStatusInternal == 2">

    <span>Sub Process Completed</span>

    <!--    <mvs-wf-process-->
    <!--            [objectIdentifier]="startedProcessObjectIdentifier"-->
    <!--    ></mvs-wf-process>-->

</ng-container>