import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    MvsCoreService,
    NavigationItem,
    ObjectIdentifierData,
    ObjectRequestList,
    PageComponent,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import icons from "bpmn-js/lib/features/align-elements/AlignElementsIcons";
import right = icons.right;

@Component({
    selector: 'mvs-cm-insurance-structure-structure-page',
    templateUrl: './partner-config.page.html',
    styleUrls: ['./partner-config.page.scss']
})
export class PartnerConfigPage extends PageComponent implements OnInit, OnDestroy {

    queryParamSubscription: Subscription
    activeNavigationItem: NavigationItem;
    navigationItems: NavigationItem[] = [
        {
            label: 'cm.PartnerType',
            action: 'cm.PartnerType',
            tooltip: 'Partnertyp',
            icon: 'fa-solid fa-user-tag',
            toggleable: false,
            default: true
        },
        {
            label: 'cm.Partner',
            action: 'cm.Partner',
            tooltip: 'Partner',
            icon: 'fa-solid fa-handshake',
            toggleable: false,
            default: false
        },
    ];

    defaultLabel: string = "Partner Konfiguration";

    refreshPartnerTypeWidget: WidgetData;
    refreshPartnerWidget: WidgetData;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.refreshWidgets();
        this.subscribeToParams();

    }

    refreshWidgets() {
        this.refreshPartnerWidget = WidgetFactory.createWidgetTableEntity(
            'cm.partner.config.table.widget',
            'Partner',
            'cm.Partner',
            'No Partner',
            ObjectRequestList.createBasic(true, [], [])
        );

        this.refreshPartnerTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cm.partner.type.config.table.widget',
            'Partnertyp',
            'cm.PartnerType',
            'No Partnertyp',
            ObjectRequestList.createBasic(true, [], [])
        );
    }

    handleObjectSelect(object: ObjectIdentifierData) {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(object.objectType, object.objectId, 'object', null, null, null, MvsObjectNavigationActionEnum.any, 'side');
        this.navigationService.navigateTo(navigationEntry, 'right');
    }

    subscribeToParams() {

        this.activeNavigationItem = this.navigationItems[0];

        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            if (params['navItem']) {
                const action = params['navItem'];
                const found = this.navigationItems.find(item => item.action == action);

                if (found) {
                    this.activeNavigationItem = found;
                }

            }
        });
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }
}
