<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-12" *ngIf="testCaseDtoList">
            <p-panel header="Auswahl Test Case">
                <div class="flex flex-wrap gap-2 align-items-center">
                    <p-dropdown
                            [options]="testCaseDtoList.entries"
                            [(ngModel)]="selectedTestCase"
                            placeholder="Select a test case"
                            optionLabel="name"
                            (onChange)="refreshTestCaseVariables()"
                            class="col-5 p-0" styleClass="w-full">
                    </p-dropdown>

                    <p-button [disabled]="busy" icon="fa-solid fa-arrows-rotate text-color-secondary"
                              styleClass="surface-200" (click)="onRefreshTestCases()"></p-button>

                    <p-button [disabled]="busy" icon="fa-sharp fa-solid fa-flask-vial" label="View Result"
                              (click)="onGenerate()" *ngIf="selectedTestCase"></p-button>
                    <p-button [disabled]="busy" icon="fa-solid fa-file-pdf" label="View Pdf" (click)="onGeneratePdf()"
                              *ngIf="selectedTestCase"></p-button>

                </div>
            </p-panel>

        </div>

        <div class="col-12">

            <p-panel header="Mapping Variablen" styleClass="h-full">
                <div *ngIf="variables" class="p-grid p-nogutter">
                    <ng-container *ngFor="let variable of variables">
                        <div *ngIf="variable.variableType != 'system'" class="flex">
                            <div class="m-2 align-self-center" style="width:20rem">{{ variable.name }}
                                - {{ variable.alias }}
                            </div>
                            <div class="m-2 p-error" *ngIf="!variable.useCaseVariable">Mapping fehlt</div>
                            <div class="m-2 p-2 p-positive border-round-md font-medium"
                                 [ngStyle]="{backgroundColor:'var(--green-200)', color: 'var(--green-800)'}"
                                 *ngIf="variable.useCaseVariable">{{ variable.useCaseVariable.name }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </p-panel>


        </div>

        <div class="col-12" *ngIf="occurredException">
            <p-message severity="error" [text]="occurredException"></p-message>

            <p-message *ngIf="occurredExceptionDetails" severity="error" [text]="occurredExceptionDetails"></p-message>
        </div>

        <div class="col-12" *ngIf="templateGenerateResponse">
            <div class="w-full text-right">
                <p-button [disabled]="busy" icon="fa-regular fa-times text-color-secondary text-xl"
                          styleClass="surface-300" (click)="onHideGeneratedTest()"></p-button>
            </div>

            <div style="border:1px solid silver;padding:10px;width:100%" [innerHTML]="trustedGenerateResponseHtml"
                 *ngIf="trustedGenerateResponseHtml">
            </div>

        </div>

        <div class="col-12" *ngIf="templateGeneratePdfResponse">
            <div class="w-full text-right">
                <p-button [disabled]="busy" icon="fa-regular fa-times text-color-secondary text-xl"
                          styleClass="surface-300" (click)="onHideGeneratedPdfTest()"></p-button>
            </div>
            <ng-container *ngIf="templateGeneratePdfResponse.errorOccurred">
                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                {{ templateGeneratePdfResponse.exception }}
            </ng-container>

            <ng-container *ngIf="!templateGeneratePdfResponse.errorOccurred">
                <iframe width="100%" height="800px" [src]="trustedGenerateResponsePdf"></iframe>
            </ng-container>


        </div>
    </div>

</ng-container>