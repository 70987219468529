import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-eu-app-config',
  templateUrl: './eu-app-config.page.html',
  styleUrls: ['./eu-app-config.page.scss']
})
export class EuAppConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "App Configuration Page";

  constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit()
  }
}
