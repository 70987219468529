import {Component, OnInit} from "@angular/core";
import {UmRoleObjectBaseComponent} from "../um-role-object-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: "um-role-object-full",
    templateUrl: "./um-role-object-full.component.html",
})

export class UmRoleObjectFullComponent extends UmRoleObjectBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        { label: 'Config',action: 'Config', icon: 'pi pi-fw pi-cog', toggleable: false, default: true }, // cog for configuration
        { label: 'Module',action: 'Module', icon: 'pi pi-fw pi-sitemap', toggleable: false }, // sitemap for modules (hierarchical structure)
        { label: 'ObjectType',action: 'ObjectType', icon: 'pi pi-fw pi-th-large', toggleable: false }, // grid for object types
        { label: 'Contract',action: 'Contract', icon: 'pi pi-fw pi-file', toggleable: false }, // file for contract
        { label: 'Customer',action: 'Customer', icon: 'pi pi-fw pi-users', toggleable: false }, // users for customer
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}