import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, Type,
    ViewChild
} from "@angular/core";
import {WfProcessStepActivitySideDirective} from "./wf-process-step-activity-side.directive";
import {
    WfProcessStepActivitySideBaseComponent
} from "./wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessTypeStepActivityService} from "../../service/api/wf-process-type-step-activity.service";
import {WfProcessStepActivityEvent} from "../../logic/wf-process-step-activity.event";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {WfProcessRuntimeDto} from "../../service/dto/wf-process-runtime.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-side',
    template: '<ng-template mvs-wf-process-step-activity-side-directive></ng-template>'
})
export class WfProcessStepActivitySideComponent
    extends WfProcessStepActivitySideBaseComponent
    implements OnInit, OnDestroy, OnChanges {

    @ViewChild(WfProcessStepActivitySideDirective, {static: true}) sideActivityComponent!: WfProcessStepActivitySideDirective;

    constructor(
        protected processTypeStepActivityService: WfProcessTypeStepActivityService,
        protected coreService: MvsCoreService,
        protected objectTypeService: ObjectTypeService) {

        super(coreService);
    }

    /**
     * Initialization.
     */
    ngOnInit(): void {
        this.refreshComponent();
        this.initialized = true;
    }


    /**
     * Clean up.
     */
    ngOnDestroy(): void {

    }

    ngOnChanges(changes: SimpleChanges) {

        if (!this.initialized) {
            return;
        }

        if (changes["fieldName"] || changes["formField"] || changes["size"] || changes["value"]) {
            this.refreshComponent();
        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        let crudService = null;
        const activitySideComponent = this.processTypeStepActivityService.getActivitySideComponent(crudService,this.coreService, this.activityType);

        const viewContainerRef = this.sideActivityComponent.viewContainerRef;
        viewContainerRef.clear();

        let componentRef = null;

        componentRef = viewContainerRef.createComponent<WfProcessStepActivitySideBaseComponent>(<Type<any>>activitySideComponent);
        componentRef.instance.processRuntime = this.processRuntime;
        componentRef.instance.processMeta = this.processMeta;
        componentRef.instance.stepType = this.stepType;
        componentRef.instance.activityType = this.activityType;
        componentRef.instance.bindings = WfProcessRuntimeDto.deriveBindings(this.processRuntime, this.activityType.id);
        componentRef.instance.formDefaults = WfProcessRuntimeDto.deriveFormDefaults(this.processRuntime, this.activityType.id);

        // passthrough
        componentRef.instance.onAction.subscribe((event: WfProcessStepActivityEvent) => {
            this.onAction.emit(event);
        });

        this.initialized = true;

    }


}
