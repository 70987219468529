import {
    AfterContentInit, AfterViewInit,
    Component, Directive,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnInit, Output, QueryList,
    Renderer2, SimpleChanges,
    ViewChild, ViewChildren, ViewContainerRef
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DmDocumentViewOptionsEnumEnum} from "../../enum/dm-document-view-options.enum";


@Directive({selector: '[form-recognizer-canvas]'})
export class FormRecognizerCanvasDirective {
    constructor(public viewContainerRef: ViewContainerRef) {
    }
}

@Component({
    selector: 'app-form-recognizer',
    templateUrl: `./dm-form-recognizer-data.component.html`,
    styleUrls: ['./dm-form-recognizer-data.component.scss']
})
export class DmFormRecognizerDataComponent implements OnInit, AfterContentInit, AfterViewInit {

    @Input() id: string;
    @ViewChild('rootElement', {read: ElementRef}) canvasElement: ElementRef<HTMLElement>;
    @Output() keyValuePairEvent = new EventEmitter<any>();
    @Input() keyValuePairInput: any;
    @Input() viewOptions: DmDocumentViewOptionsEnumEnum
    // @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;

    parentControlName: string;

    // 210 mm × 297
    @Input() formData: any;
    // pageHeight: number[] = [];
    pageWidth: number[] = [];
    pageRatio: number[] = [];
    canvasWidthGlobal: number;
    windowWidth: number = window.innerWidth;
    fullScreen: boolean;
    zoom: number = 1;
    htmlPages: string[] = [];
    totalPages: number;

    constructor(private http: HttpClient, private renderer: Renderer2) {
        this.parentControlName = "renderDocument_first";
    }

    ngAfterViewInit(): void {
        this.loadFormData();
    }

    ngAfterContentInit(): void {

    }


    ngOnInit() {
        this.parentControlName = "renderDocument_" + this.id;
        // this.initFormData();
    }

    async loadFormData() {

        this.renderFormData(this.formData);
    }

    renderFormData(formData) {


        let html = "";

        //const ctx = this.canvas.nativeElement.getContext('2d');
        //const canvasWidth = this.canvas.nativeElement.width;
//      const canvasHeight = this.canvas.nativeElement.height;


        //const rootElement = document.getElementById(this.parentControlName );
        const rootElement = this.canvasElement.nativeElement;

        const parentElement = rootElement.parentElement;
        const canvasWidth = parentElement.offsetWidth;
        const canvasHeight = rootElement.offsetHeight;

        this.canvasWidthGlobal = canvasWidth;

        /*
    const newDiv = document.createElement("div");
    newDiv.innerHTML = "HEllo world";
    rootElement.insertAdjacentElement("beforebegin", newDiv);
    rootElement.innerHTML = "Hallo Welt";

 */


        //ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);


        const keyValuePairs = formData['keyValuePairs'];
        const pages = formData['pages'];

        if (keyValuePairs && keyValuePairs.length) {
            this.keyValuePairEvent.emit(keyValuePairs);

            for (let keyValuePair of keyValuePairs) {

                if (!keyValuePair["value"]) {
                    continue;
                }

                if (keyValuePair["key"]["content"] != this.keyValuePairInput?.key?.content && keyValuePair["value"]["content"] != this.keyValuePairInput?.value?.content) {
                    continue;
                }

                const polygon = keyValuePair["value"]["boundingRegions"][0]["boundingPolygon"];

                const page = pages[keyValuePair["value"]["boundingRegions"][0]["pageNumber"] - 1];


                const factorX = canvasWidth / page['width'];
                const factorY = canvasHeight / page['height'];


                const x = this.round2Decimals(polygon[0].x * factorX);
                const width = this.round2Decimals(polygon[1].x * factorX - x);

                const y = this.round2Decimals(polygon[0].y * factorY);
                const height = this.round2Decimals(polygon[3].y * factorY - y);

                const widthHtml = ";width:" + width / canvasWidth * 100 + "%";
                const heightHtml = ";height:" + height / canvasHeight * 100 + "%";

                const addHtml = "<div style='position:absolute;top:" + y / canvasHeight * 100 + "%;left:" + x / canvasWidth * 100 + "%" + widthHtml + heightHtml + ";background-color:rgba(255,0,0,0.2);'></div>";

                html += addHtml;


            }
        }

        let index = 0;

        for (const page of pages) {

            this.pageRatio[index] = page.width / page.height;

            let pageHeight: number = 0;
            const factorX = canvasWidth / page['width']
            const factorY = canvasHeight / page['height']


            const lines = page['lines'];


            for (const line of lines) {

                const text = line['content'];
                const x = this.round2Decimals(line['boundingPolygon'][0].x * factorX);
                const width = this.round2Decimals(line['boundingPolygon'][1].x * factorX - x);

                const y = this.round2Decimals(line['boundingPolygon'][0].y * factorY);
                const height = this.round2Decimals(line['boundingPolygon'][3].y * factorY - y);

                // CALCULATING PAGE HEIGHT
                pageHeight += height;

                // CALCULATING FONT SIZE
                // windowWidth is total width of screen
                // CALCULATING REGULAR FONT SIZE
                const fontVw = this.round2Decimals(
                    Math.min((width / this.windowWidth * 100) / line["spans"][0].length / 0.8, (height / canvasHeight * 100) / 1.25)
                );

                // CALCULATING FONT SIZE FOR VERTICAL TEXT
                const verticalFontSize = (line['boundingPolygon'][0].y / (this.windowWidth / canvasWidth * 100)) * 8;

                // if fontVw < 0 this means that text is vertical, so we will use verticalFontSize to set fontSize
                const fontSize = fontVw < 0 ? verticalFontSize : fontVw;

                const fontHtml = ";font-size:" + fontSize + "vw";
                const widthHtml = ";width:" + width / canvasWidth * 100 + "%";
                const heightHtml = ";height:" + height / canvasHeight * 100 + "%";

                // IF FONT IS VERTICAL THE ROTATION "transform: rotate(270deg)" CONDITION WILL APPLY
                const rotation = fontVw < 0 ? 'transform: rotate(270deg);' : '';

                const addHtml = "<div class='clickable-text' style='position:absolute;" + rotation + "top:" + y / canvasHeight * 100 + "%;left:" + x / canvasWidth * 100 + "%" + fontHtml + widthHtml + heightHtml + ";background-color:rgba(255,0,0,0.2);"     + "'>" + text + "</div>";

                html += addHtml;

                // TO DRAW BORDERS AROUND TEXT
                // const addRectangle = "<div style='position:absolute;top:" + y / canvasHeight * 100 + "%;left:" + x / canvasWidth * 100 + "%;width:" + width / canvasWidth * 100 + "%;height:" + height / canvasHeight * 100 + "%;border: 1px solid black" + "'></div>";
                // html += addRectangle;

            }

            this.pageWidth[index] = canvasWidth * this.pageRatio[index];
            // this.pageWidth[index] = pageHeight * this.pageRatio[index];
            this.htmlPages[index] = html;
            html = '';
            index++;
        }

        this.totalPages = this.htmlPages.length;
        this.changePage(0);

        this.attachClickEvents();

    }

    attachClickEvents() {
        const rootElement = this.canvasElement.nativeElement;
        const clickableElements = rootElement.querySelectorAll('.clickable-text');
        clickableElements.forEach(element => {
            element.addEventListener('click', (event) => this.handleTextClick(event));
        });
    }

    handleTextClick(event: Event) {
        console.log(event.target['innerHTML']);
    }

    changePage(pageNumber: number) {

        if (pageNumber == null || pageNumber == undefined) {
            return;
        }

        pageNumber = pageNumber - 1;

        if (pageNumber >= this.htmlPages.length) {
            pageNumber = this.htmlPages.length - 1;
        }

        if (pageNumber < 0) {
            pageNumber = 0;
        }


        // PAGE RENDERING AND ASSIGNING THE REQUIRED HTML TO CANVAS
        this.canvasElement.nativeElement.innerHTML = this.htmlPages[pageNumber];

        // SETTING PAGE WIDTH
        this.renderer.setStyle(this.canvasElement.nativeElement, 'width', (this.pageWidth[pageNumber] / this.canvasWidthGlobal * 100) + '%');

        if (this.viewOptions == DmDocumentViewOptionsEnumEnum.fullWidth) {
            this.handleFullScreen();
        }

    }

    handleFullScreen() {

        this.fullScreen = !this.fullScreen;
        this.zoom = 1;
        const width = this.fullScreen ? 100 : (this.pageWidth[0] / this.canvasWidthGlobal * 100);
        this.renderer.setStyle(this.canvasElement.nativeElement, 'width', width + '%');
        this.renderer.setStyle(this.canvasElement.nativeElement, 'transform', `translate(-50%, 0%) scale(${this.zoom})`);
    }

    handleZoom() {
        // this.renderer.setStyle(this.canvasElement.nativeElement, 'top', `${this.zoom * 10}%`);
        // this.renderer.setStyle(this.canvasElement.nativeElement, 'left', `${50 + this.zoom}%`);
        // ${this.zoom * 10}
        this.renderer.setStyle(this.canvasElement.nativeElement, 'transform', `translate(-50%, 0%) scale(${this.zoom})`);
    }

    round2Decimals(amount: number): number {
        return Math.round(amount * 100) / 100;
    }

    ngOnChanges(changes: SimpleChanges): void {

        // if (!this.initialized) {
        //     return;
        // }

        if (changes["keyValuePairInput"]) {
            this.ngAfterViewInit();
        }
    }

}
