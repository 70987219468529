import {
    AfterViewChecked,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {WfProcessDto} from "../../dto/wf-process.dto";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem} from "primeng/api";
import {WfProcessService} from "../../service/api/wf-process.service";
import {WfProcessTypeStepDto} from "../../dto/wf-process-type-step.dto";
import {WfProcessTypeStepActivityDto} from "../../dto/wf-process-type-step-activity.dto";
import {WfActivityTypeEnum} from "../../enum/wf-activity-type.enum";
import {WfProcessStepActivityDirective} from "./directive/wf-process-step-activity.directive";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {WfProcessStepActivityEvent} from "../../logic/wf-process-step-activity.event";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {WfProcessStepStatusEnum} from "../../enum/wf-process-step-status.enum";
import {WfProcessStepDto} from "../../dto/wf-process-step.dto";
import {WfProcessStepService} from "../../service/api/wf-process-step.service";
import {WidgetData} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {TabView} from "primeng/tabview";
import {
    WfProcessStepOptionalActivityObjectCreateComponent
} from "../process-activity/wf-process-step-optional-activity-object-create/wf-process-step-optional-activity-object-create.component";
import {WfProcessStepActivityService} from "../../service/api/wf-process-step-activity.service";
import {WfProcessStepActivityDto} from "../../dto/wf-process-step-activity.dto";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {TmStatusEnum} from "../../../tm/enum/tm-status.enum";
import {WfProcessTypeStepHintDto} from "../../dto/wf-process-type-step-hint.dto";
import {WfHintTypeLayout} from "../../enum/wf-hint-type-layout.enum";
import {DtoDetail} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivitySidePanelComponent
} from "../process-activity-side/wf-process-step-activity-side-panel/wf-process-step-activity-side-panel.component";
import {WfProcessTypeStepActivityService} from "../../service/api/wf-process-type-step-activity.service";
import {
    WfProcessStepActivitySideBaseComponent
} from "../process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessStatusInternal} from "../../enum/wf-process-status-internal.enum";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {WfProcessMetaDto} from "../../service/dto/wf-process-meta.dto";
import {WfProcessRuntimeDto} from "../../service/dto/wf-process-runtime.dto";
import {WfProcessRuntimeService} from "../../service/wf-process-runtime.service";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-wf-process',
    templateUrl: './wf-process.component.html',
    styleUrls: ['./wf-process.component.scss']
})
export class WfProcessComponent extends ObjectBaseComponent implements OnInit, OnChanges, AfterViewChecked {

    dto: WfProcessDto;
    crudService: WfProcessService;
    stepItems: MenuItem[] & { runtimeSteps: WfProcessTypeStepDto }[];
    currentStep: WfProcessStepDto;
    processedStepWidget: WidgetData;
    mainActivityForStep: WfProcessTypeStepActivityDto;
    optionalActivityForStep: WfProcessTypeStepActivityDto[] = [];
    processedStepActivities: WfProcessStepActivityDto[] = [];
    activeStepType: WfProcessTypeStepDto;
    combinedActivities: (WfProcessTypeStepActivityDto | WfProcessStepActivityDto)[] = [];
    tabView: TabView;
    listHintTypeBefore: WfProcessTypeStepHintDto[];
    listHintTypeLeft: WfProcessTypeStepHintDto[];
    listHintTypeRight: WfProcessTypeStepHintDto[];
    listHintTypeBelow: WfProcessTypeStepHintDto[];
    processDataWidget: WidgetData;
    processTypeListWidget: WidgetData;
    stepType: WfProcessTypeStepDto;
    mainActivityDisplay: string;
    tabIndex: number;
    stepperActiveStep: number;
    visible: boolean;
    showSideActivity: boolean;
    metaData: WfProcessMetaDto;
    processMeta: WfProcessMetaDto;
    processRuntime: WfProcessRuntimeDto;
    selectedHint: WfProcessTypeStepHintDto;
    mode: string = 'show-process';
    subProcessObjectIdentifier: ObjectIdentifier;

    @Input() processMode: boolean = false;
    @Input() breadCrumbItems: MenuItem[] | undefined = [];
    @Output() onProcessCreated: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();
    @Output() onProcessComplete: EventEmitter<WfProcessDto> = new EventEmitter<WfProcessDto>();

    @ViewChild(WfProcessStepActivityDirective, {static: true}) stepActivityComponent!: WfProcessStepActivityDirective;
    @ViewChild(WfProcessStepOptionalActivityObjectCreateComponent) wfProcessStepOptionalActivityObjectCreateComponent: WfProcessStepOptionalActivityObjectCreateComponent;
    @ViewChild(WfProcessStepActivitySidePanelComponent) wfProcessShowStepActivitiesComponent: WfProcessStepActivitySidePanelComponent;
    @ViewChild('sideActivityRef', {read: ViewContainerRef}) sideActivityRef: ViewContainerRef;

    /**
     * Constructor.
     */
    constructor(
        protected processRuntimeService: WfProcessRuntimeService,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected objectTypeService: ObjectTypeService,
        protected processStepService: WfProcessStepService,
        protected objectService: MvsUiObjectService,
        protected processStepActivityService: WfProcessStepActivityService,
        protected processService: WfProcessService,
        protected processTypeStepActivityService: WfProcessTypeStepActivityService,
        protected override observerService: ObserverService
    ) {
        super(coreService, messageService, confirmationService, observerService);

    }


    ngOnInit(): void {
        super.ngOnInit();
    }

    async ngAfterViewChecked() {
        // accessed the #tabView reference in the wfProcessStepOptionalActivityObjectCreateComponent component
        if (this.wfProcessStepOptionalActivityObjectCreateComponent?.tabView) {
            this.tabView = this.wfProcessStepOptionalActivityObjectCreateComponent.tabView;
        }
    }

    /**
     * Method is processed whenever the object is changed.
     */
    onObjectChanged() {
        // continue with the process
        this.resumeProcess();
    }


    /**
     * push steps into stepItems
     */
    uiRefreshStepItems() {
        this.stepItems = this.processRuntimeService.getSteps(this.processMeta)
    }


    uiRefresh() {
        // check whether the process is COMPLETE
        if (this.processRuntime.process.processStatusInternal == WfProcessStatusInternal.completed) {
            this.onProcessComplete.emit(this.processRuntime.process)
            this.refreshCompletedProcessWidgetTable()
        } else {
            // display next step
            this.uiRefreshStepItems();
            this.uiRefreshActiveStep();
        }
    }


    /**
     * Process UI for active step.
     */


    uiRefreshActiveStep() {

        this.activeStepType = this.processRuntime.stepMeta.stepType;
        this.mainActivityForStep = WfProcessTypeStepActivityDto.getMainActivityForStep(this.processRuntime.stepMeta.activities.entries);
        // retrieving optional activities
        this.optionalActivityForStep = WfProcessTypeStepActivityDto.getOptionalActivityForStep(this.processRuntime.stepMeta.activities.entries);
        // retrieving processed activities
        this.processedStepActivities = WfProcessStepActivityDto.getProcessStepActivity(this.currentStep);

        // retrieving name and status for processed activities
        this.handleNameAndStatusForProcessedActivities()
        // update hints for stepper
        this.handleStepHints();
        // update active step for stepper
        this.handleActiveStep();
        this.handleMainActivityClasses();
    }

    async handleNameAndStatusForProcessedActivities() {
        // retrieving name and status for processed activities
        if (this.processedStepActivities && this.processedStepActivities.length) {
            for (const activity of this.processedStepActivities) {
                const objectTypeDto: ObjectTypeDto = await this.objectTypeService.getViaObjectId(activity.objectTypeDtoId);
                const CrudService: MvsCrudService = this.coreService.getCrudService(objectTypeDto.alias);

                CrudService.get(activity.objectId).subscribe(dto => {
                    activity['name'] = dto['name'];
                    if (CrudService.objectType === "tm.Ticket") {
                        const statusValue: string = dto['status'];
                        const statusEnumValue = TmStatusEnum[statusValue];
                        if (statusEnumValue) {
                            activity['status'] = statusEnumValue;
                        }
                    }
                })
            }
        }
    }

    handleStepHints() {
        if (this.processRuntime.activeStepHints) {
            this.listHintTypeBefore = this.processRuntime.activeStepHints.filter((hint: WfProcessTypeStepHintDto) => (hint.hintTypeDto.layout == WfHintTypeLayout.top))
            this.listHintTypeLeft = this.processRuntime.activeStepHints.filter((hint: WfProcessTypeStepHintDto) => (hint.hintTypeDto.layout == WfHintTypeLayout.left))
            this.listHintTypeRight = this.processRuntime.activeStepHints.filter((hint: WfProcessTypeStepHintDto) => (hint.hintTypeDto.layout == WfHintTypeLayout.right))
            this.listHintTypeBelow = this.processRuntime.activeStepHints.filter((hint: WfProcessTypeStepHintDto) => (hint.hintTypeDto.layout == WfHintTypeLayout.bottom))
        } else {
            this.listHintTypeBefore = null;
            this.listHintTypeLeft = null;
            this.listHintTypeRight = null;
            this.listHintTypeBelow = null;
        }
    }

    handleActiveStep() {
        // update active step for stepper
        this.stepperActiveStep = this.processRuntimeService.getActiveStep(this.stepItems, this.currentStep.processTypeStepDtoId);
    }

    /**
     * Process UI for optional step.
     */

    async uiRefreshOptionalSteps(activityType: WfProcessTypeStepActivityDto) {
        const viewContainer = this.sideActivityRef;
        // clear existing component
        viewContainer.clear();
        let crudService = null;
        const activityComponent = this.processTypeStepActivityService.getActivityComponent(crudService, this.coreService, activityType);

        const componentRef = viewContainer.createComponent<WfProcessStepActivitySideBaseComponent>(<Type<any>>activityComponent);
        componentRef.instance.processRuntime = this.processRuntime;
        componentRef.instance.processMeta = this.processMeta;
        componentRef.instance.stepType = this.stepType;
        componentRef.instance.activityType = activityType;
        componentRef.instance.bindings = WfProcessRuntimeDto.deriveBindings(this.processRuntime, activityType.id);
        componentRef.instance.formDefaults = WfProcessRuntimeDto.deriveFormDefaults(this.processRuntime, activityType.id);
    }


    async uiRefreshObjects() {
        // Handle tab change event here
        const activeStepType = WfProcessTypeDto.getStepById(this.currentStep.processTypeStepDtoId, this.dto.typeDto);

        const viewContainerRefs: ViewContainerRef[] = [];

        if (this.processedStepActivities) {

            for (let i = 0; i < this.processedStepActivities.length; i++) {

                let wfProcessStepActivityDto;
                let objectId: number;

                objectId = this.processedStepActivities[i].objectId;
                wfProcessStepActivityDto = WfProcessTypeStepActivityDto.getProcessTypeStepActivityById(this.processedStepActivities[i].processTypeStepActivityDtoId, this.optionalActivityForStep);

                const viewContainerRef = this.tabView.tabs[i].viewContainer;
                viewContainerRefs.push(viewContainerRef);

                // *************************************************************************************************** //

                let objectType: ObjectTypeDto = null;
                let objectTypeAlias: string = null;

                if (this.processedStepActivities[i].objectTypeDtoId) {
                    objectType = await this.objectTypeService.getViaObjectId(this.processedStepActivities[i].objectTypeDtoId);
                    if (objectType) {
                        objectTypeAlias = objectType.alias;
                    }
                }
                let crudService = null;
                if (objectTypeAlias) {
                    crudService = this.coreService.getCrudService(objectTypeAlias);
                }

                const activityType = WfProcessTypeStepActivityDto.getProcessTypeStepActivityById(this.processedStepActivities[i].processTypeStepActivityDtoId, this.optionalActivityForStep)

                const activityComponent = this.processTypeStepActivityService.getActivityComponent(crudService, this.coreService, activityType);

                // *************************************************************************************************** //


                // Check if the current tab is the selected tab
                if (i === this.tabIndex) {

                    viewContainerRefs[i].clear();

                    let componentRef = viewContainerRef.createComponent<WfProcessStepActivitySideBaseComponent>(<Type<any>>activityComponent);

                    this.handleComponentRefUi(componentRef, activeStepType, wfProcessStepActivityDto, objectId);
                }

            }
        }

        // Clear the previously rendered components in all tabs except the selected tab
        this.clearComponentsInTab(viewContainerRefs)
    }

    clearComponentsInTab(viewContainerRefs: ViewContainerRef[]) {
        for (let i = 0; i < viewContainerRefs.length; i++) {
            if (i !== this.tabIndex) {
                viewContainerRefs[i].clear();
            }
        }
    }

    /**
     * render corresponding component on tab change
     * @param index
     */
    onTabChange(index: number) {
        this.tabIndex = index;
        this.uiRefreshObjects();
    }

    onProcessedObjectSelect(activityType: WfProcessStepActivityDto) {
        let index = 0;

        for (let objectActivity of this.processedStepActivities) {
            if (objectActivity == activityType) {
                break;
            }
            index++;
        }

        this.onTabChange(index)
    }

    /**
     * show corresponding tab when clicked on optional activity options from sidebar
     * @param activityType
     */
    onOptionalComponentSelect(activityType: WfProcessTypeStepActivityDto) {

        this.showSideActivity = true;

        this.uiRefreshOptionalSteps(activityType);
    }

    /**
     * handle component reference
     * @param componentRef
     * @param activeStepType
     * @param activityForStep
     * @param objectId
     * @private
     */
    private handleComponentRefUi(componentRef, activeStepType: WfProcessTypeStepDto, activityForStep: WfProcessTypeStepActivityDto, objectId?: number) {

        if (componentRef) {

            componentRef.instance.stepType = activeStepType;
            componentRef.instance.process = this.dto;
            componentRef.instance.activityType = activityForStep;

            if (activityForStep.activityType == WfActivityTypeEnum.start_process) {
                componentRef.instance.onOptionalActivityChangeEvent.subscribe((event: WfProcessStepActivityEvent) => {
                    this.processOptionalActivityEvent(event);
                })

            }

        } else {
            alert("Requested Activity Step Type not supported yet!");
        }
    }


    /**
     * Process optional activity event.
     * @param event
     * @protected
     */
    protected async processOptionalActivityEvent(event: WfProcessStepActivityEvent) {

        const wfProcessStepActivityDto: WfProcessStepActivityDto = new WfProcessStepActivityDto();
        const objectTypeDto: ObjectTypeDto = await this.objectTypeService.getViaObjectType(event.objectIdentifier.objectType);

        wfProcessStepActivityDto.objectTypeDtoId = objectTypeDto.id;
        wfProcessStepActivityDto.objectId = event.objectIdentifier.objectId;
        wfProcessStepActivityDto.processTypeStepActivityDtoId = event.stepActivity.id;
        wfProcessStepActivityDto.processStepDtoId = this.currentStep.id;

        this.processStepActivityService.create(wfProcessStepActivityDto).subscribe(() => {
            this.retrieveObject();
        })

    }

    processNextStepActivityEvent(event: WfProcessStepActivityEvent) {
        // this.nextStep(event.stepActivity);
    }


    /**
     * Continue with the current step.
     * @protected
     */
    protected resumeProcess() {
        let metaRequired: boolean = true;

        if (this.metaData && this.metaData.type.id == this.dto.id) {
            metaRequired = false;
        }

        this.processService.resume(this.dto.id, metaRequired).subscribe(value => {
            this.onResumeProcess(value);
        });

    }

    onResumeProcess(value: WfProcessRuntimeDto) {
        // store process meta information, if provided
        if (value.meta) {
            this.processMeta = value.meta;
        }

        // store process runtime
        this.processRuntime = value;

        // remove meta data to make sure that we are not using it from the process instance
        this.processRuntime.meta = null;

        // store current step
        this.currentStep = this.processRuntime.activeStep;

        this.uiRefresh();
    }


    /**
     * if process ends will complete the process
     */
    completeProcess() {
        const process: WfProcessDto = new WfProcessDto();
        process.id = this.dto.id;
        process.processStatusInternal = WfProcessStatusInternal.completed
        this.processService.update(process).subscribe(res => {
            this.dto = <WfProcessDto>res;
            this.onProcessCreated.emit();
            this.refreshCompletedProcessWidgetTable();
        })
    }

    /**
     * the stepSelected function is called when user click on step
     * @param step
     */
    onStepSelected(step: DtoDetail) {

        const wfProcessStep = <WfProcessStepDto>step
        this.uiRefreshProcessedStepWidget(wfProcessStep)
    }


    /**
     * widget to be rendered in overlay panel if click on step
     * @param step
     */
    uiRefreshProcessedStepWidget(step: WfProcessStepDto) {

        this.processedStepWidget = this.processRuntimeService.getProcessesStepWidget(step);

    }

    /**
     * move to selected step
     * @param processedStep
     * @protected
     */
    protected handleStepChange(processedStep: DtoDetail) {

        const wfProcessedStep = <WfProcessStepDto>processedStep;

        this.clearStepsAndActivities();

        // only process if the selected step is not the current step
        if (processedStep.id === this.currentStep.id) {
            return;
        }

        this.busy = true;

        if (this.currentStep) {
            // set current STEP to cancelled
            this.processStepService.changeStepStatus(this.currentStep, WfProcessStepStatusEnum.CANCELLED).subscribe(() => {
                // previous step cancelled
                this.processStepService.changeStepStatus(wfProcessedStep, WfProcessStepStatusEnum.STARTED).subscribe(() => {
                    // selected step started
                    this.busy = false;
                    super.refreshComponent();
                });
            });

            return;
        }


    }

    clearStepsAndActivities() {
        this.processedStepActivities = null;
        this.optionalActivityForStep = null;
        this.activeStepType = null;
        this.processedStepActivities = null;
        this.optionalActivityForStep = null;
        this.activeStepType = null;
    }

    /**
     * toggle showAllProcessedActivitiesComponentVisibility to true or false
     * @param event
     */
    onDialogBoxVisibility(event: boolean) {
        this.visible = event;
    }


    /**
     * to set the class for main activity based of hints and optional activities
     */
    handleMainActivityClasses() {

        this.mainActivityDisplay = 'col-12';

        if (this.optionalActivityForStep?.length > 0) {
            this.mainActivityDisplay = 'col-8';
        }

    }


    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

        super.ngOnDestroy();

    }


    refreshCompletedProcessWidgetTable() {
        this.processDataWidget = this.processRuntimeService.getCompletedProcessWidget(this.objectIdentifier, this.processMeta.type.id);
        this.processTypeListWidget = this.processRuntimeService.getCompletedProcessListWidget(this.objectIdentifier.objectId, this.processMeta.type.id);
    }

    onContinue(processRuntimeDto: WfProcessRuntimeDto) {

        this.onResumeProcess(processRuntimeDto);
    }

    handleProcessCreated(event: WfProcessStepActivityEvent) {
        this.onProcessCreated.emit(event);
        this.ngOnInit();
    }

    handleProcessCreatedEvent() {
        this.uiRefreshActiveStep();
    }


}