import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DtoTemplate, MvsCoreService, MvsFormFieldDto} from "@kvers/alpha-core-common";
import {FormControl, FormGroup} from "@angular/forms";
import {MvsFormFieldInputConfigBaseComponent} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-form-field-one-to-many-config',
    templateUrl: 'mvs-form-field-one-to-many-config.component.html',
})
export class MvsFormFieldOneToManyConfigComponent extends MvsFormFieldInputConfigBaseComponent implements OnInit {


    initialized: boolean;

    objectIdOptions: { label: string; value: any }[] = [];
    linkedEntityTargetAttribute: { id: string; uiLabel: string }[];
    linkedEntityTargetField: { id: string; uiLabel: string }[];

    constructor(protected coreService: MvsCoreService) {
        super();
    }

    ngOnInit(): void {

        this.initializeUiConfigData();
        this.objectIdOptions = this.getObjectIdOptions();
        this.initialized = true;
    }


    initializeUiConfigData(): void {

        let uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        if (!uiConfigData) {
            uiConfigData = new FormGroup({});
            this.widgetUiField.addControl('uiConfigData', uiConfigData);
        }

        // Ensure objectType control exists
        if (!uiConfigData.get('objectType')) {
            uiConfigData.addControl('objectType', new FormControl(null));
        }

        // Ensure objectId control exists
        if (!uiConfigData.get('objectId')) {
            uiConfigData.addControl('objectId', new FormControl(null));
        }

        // Ensure linkedEntityObjectType control exists
        if (!uiConfigData.get('linkedEntityObjectType')) {
            uiConfigData.addControl('linkedEntityObjectType', new FormControl(null));
        }

        // Ensure linkedEntityTargetObjectType control exists
        if (!uiConfigData.get('linkedEntityTargetObjectType')) {
            uiConfigData.addControl('linkedEntityTargetObjectType', new FormControl(null));
        }

        // Ensure linkedEntityTargetAttributeName control exists
        if (!uiConfigData.get('linkedEntityTargetAttributeName')) {
            uiConfigData.addControl('linkedEntityTargetAttributeName', new FormControl(null));
        }

        // Ensure linkedEntityTargetAttributeName control exists
        if (!uiConfigData.get('fieldName')) {
            uiConfigData.addControl('fieldName', new FormControl(null));
        }

    }


    getObjectIdOptions(): { label: string; value: any }[] {
        return Object.values(this.formFields)
            .map(field => ({label: field.uiLabel, value: field.id}));
    }


    handleObjectType(objectType: string, controlName: string) {

        const uiConfigData = this.widgetUiField.get('uiConfigData');

        if (uiConfigData && uiConfigData.get(controlName)) {
            uiConfigData.get(controlName)?.setValue(objectType);
        }

        if (controlName == 'linkedEntityObjectType') {
            this.coreService.getCrudService(objectType).template(new DtoTemplate()).subscribe(res => {

                // Convert to array
                this.linkedEntityTargetAttribute = Object.values(res.formFields)
                    .filter(({id}) => !/DtoName|DtoImage|DtoColor|createdBy|createdDate|lastModifiedBy|lastModifiedDate|DtoColorBackground/.test(id)) // Exclude DtoName, DtoColor, DtoColorBackground
                    .map(({id, uiLabel}) => ({id, uiLabel}));


                this.linkedEntityTargetField = Object.values(res.formFields)
                    .filter(({id}) => !/DtoImage|DtoColor|createdBy|createdDate|lastModifiedBy|lastModifiedDate|DtoColorBackground/.test(id)) // Exclude DtoName, DtoColor, DtoColorBackground
                    .map(({id, uiLabel}) => ({id, uiLabel}));
            });

        }

    }

}
