import {Component} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../../enum/tm-status.enum";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {TicketService} from "../../../service/api/ticket.service";

@Component({
    selector: 'mvs-ticket-dashboard-page',
    templateUrl: './ticket-dashboard.page.html',
    styleUrls: ['./ticket-dashboard.page.scss']
})
export class TicketDashboardPage extends PageComponent {


    defaultLabel: string = 'Ticket Dashboard';
    widgetTicketTable: WidgetData;
    widgetTicketType: WidgetData;
    widgetTicketStatus: WidgetData;
    widgetTicketUrgency: WidgetData;
    widgetTicketAgent: WidgetData;
    widgetTicketAgentPool: WidgetData;
    filterCriteria: FilterCriteria[] = [];

    constructor(
        protected route: ActivatedRoute,
        protected navigationService: MvsObjectNavigationService,
        protected router: Router,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();

        // to open ticket drawer
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        // refresh component
        this.initComponent();

    }

    /**
     * Overwritten method from Page.
     * @protected
     */
    protected preInit() {
        this.initialized = true;
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        const filterCriteriaList = [];

        if (this.filterCriteria && this.filterCriteria.length) {
            for (let criteria of this.filterCriteria) {
                filterCriteriaList.push(criteria);
            }
        }

        filterCriteriaList.push(FilterCriteria.createSingleCondition("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved, null));


        this.widgetTicketTable = new WidgetData();
        this.widgetTicketTable.id = 1;
        this.widgetTicketTable.idAlias = "tm.dashboard.tickets";
        this.widgetTicketTable.name = "Tickets";

        this.widgetTicketTable.uiComponent = "table";
        this.widgetTicketTable.dataProvider = "list";
        this.widgetTicketTable.dataSource = "entity";
        this.widgetTicketTable.dataProviderObject = "tm.Ticket";

        this.widgetTicketTable.dataProviderListRequest = TicketService.getComplexSelectionCustomerAndPerson(filterCriteriaList);


        this.widgetTicketTable.setParamValue("size", "S");


        this.widgetTicketType = this.createWidget(
            "tm.dashboard.tickets.type.distribution",
            "Types",
            "category",
            "entity.groupBy",
            "tm.Ticket",
            "type",
            "type_count",
            filterCriteriaList,
            "type",
            "Anzahl"
        );

        this.widgetTicketStatus = this.createWidget(
            "tm.dashboard.tickets.status.distribution",
            "Status",
            "category",
            "entity.groupBy",
            "tm.Ticket",
            "status",
            "status_count",
            filterCriteriaList,
            "status",
            "Anzahl"
        );

        this.widgetTicketUrgency = this.createWidget(
            "tm.dashboard.tickets.urgency.distribution",
            "Urgencies",
            "category",
            "entity.groupBy",
            "tm.Ticket",
            "urgency",
            "urgency_count",
            filterCriteriaList,
            "urgency",
            "Anzahl"
        );

        this.widgetTicketAgent = this.createWidget(
            "tm.dashboard.tickets.agent.distribution",
            "Agent",
            "category",
            "entity.groupBy",
            "tm.Ticket",
            "assigneeAgent",
            "assigneeAgent_count",
            filterCriteriaList,
            "assigneeAgent",
            "Anzahl"
        );

        this.widgetTicketAgentPool = this.createWidget(
            "tm.dashboard.tickets.agent.pool.distribution",
            "Agent Pool",
            "category",
            "entity.groupBy",
            "tm.Ticket",
            "assigneeAgentPool",
            "assigneeAgentPool_count",
            filterCriteriaList,
            "assigneeAgentPool",
            "Anzahl"
        );

    }

    /**
     * function to populate list widget with groupBy
     * @param idAlias
     * @param name
     * @param uiComponent
     * @param dataSource
     * @param dataProviderObject
     * @param fieldCategory
     * @param fieldCategoryCount
     * @param filterCriteriaList
     * @param groupingAttribute
     * @param attributeLabel
     */
    createWidget(
        idAlias: string,
        name: string,
        uiComponent: string,
        dataSource: string,
        dataProviderObject: string,
        fieldCategory: string,
        fieldCategoryCount: string,
        filterCriteriaList: FilterCriteria[],
        groupingAttribute: string,
        attributeLabel: string
    ) {
        const widget = new WidgetData();
        widget.id = 1;
        widget.idAlias = idAlias;
        widget.name = name;
        widget.uiComponent = uiComponent;
        widget.dataProvider = "list";
        widget.dataSource = dataSource;
        widget.dataProviderObject = dataProviderObject;
        widget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            filterCriteriaList,
            [],
            [groupingAttribute],
            [new ObjectRequestListAttribute(groupingAttribute, attributeLabel, DtoListAttributeRequestAggregateEnum.count)]
        );
        widget.setParamValue("fieldCategory", fieldCategory);
        widget.setParamValue("fieldCategoryCount", fieldCategoryCount);
        widget.setParamValue("size", "S");
        return widget;
    }


    /**
     * open the selected ticket on sidebar
     * @param object
     */
    openTicketDrawer(object: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", object.objectId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }


    /**
     * set filter criteria
     * @param objectData
     * @param field
     */
    onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
        if (this.filterCriteria) {
            const found = this.filterCriteria.find(res => res.field == field);
            if (found) {
                return;
            }
        }
        const filterCriteria: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        filterCriteria.label = objectData.data.label;
        this.filterCriteria.push(filterCriteria);
        this.initComponent();
    }

    /**
     * remove objects from filter criteria
     * @param filter
     */
    onRemoveFilterCriteria(filter: FilterCriteria) {
        let index = this.filterCriteria.findIndex(item => item.field === filter.field);

        if (index !== -1) {
            this.filterCriteria.splice(index, 1);
            this.initComponent();
        }

    }

}
