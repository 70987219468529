import { Injectable } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ObjectRequestList } from "@kvers/alpha-core-common";
import { FilterCriteria } from "@kvers/alpha-core-common";
import { MetaService } from "@kvers/alpha-core-common";
import { Sorting } from "@kvers/alpha-core-common";
import { MvsCrudService } from "@kvers/alpha-core-common";
import { MvsCoreService } from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TeVariableService {

    files3: TreeNode[] = [];
    nodes: TreeNode[] = [];
    crudService: MvsCrudService;
    alias: string;
    loading: any;

    public treeNodes$ = new BehaviorSubject([]);

  constructor(protected coreService: MvsCoreService, protected metaService: MetaService, protected toast: MessageService) {}

  refreshVariables(templateId?: any, attributeName?: any) {

    const dtoListRequest = new ObjectRequestList(false, [FilterCriteria.create(attributeName, FilterCriteria.cOperatorEqual, templateId, null)], [new Sorting("lastModifiedDate", false)]);

    this.crudService.list(dtoListRequest).subscribe((response: any) => {
      this.nodes = response.entries;

      this.files3 = response.entries.map((items: any) => this.createNode(items));
      this.setTreeNodes(this.files3);
    });
  }

  setAlias(alias: any){
    this.crudService = <MvsCrudService>this.coreService.getCrudService(alias);
  }

  nodeExpand(event: any) {
    console.log('Node Expand', event);
    let attributes: any;
    if (event.node.data.objectTypeDtoId) {
      this.loading = true;
      this.metaService.getById(event.node.data.objectTypeDtoId, true, false, false).subscribe((res) => {
        attributes = res.attributes;
        event.node.children = [];
        event.node.children = attributes.map((element: any) => {
          return {
            label: element.attributeName,
            data: event.node.data
            // data: element,
            // icon: 'pi pi-file'
          }
        });
        this.toast.add({ severity: 'info', summary: 'Children Loaded', detail: event.node.label });
        this.loading = false;
      });
    }
  }

  setTreeNodes(treeNodes: any) {
    this.treeNodes$.next(treeNodes);
  }

  createNode(nodeItem: any): TreeNode {
    let node: TreeNode = {
      label: nodeItem.name,
      data: nodeItem,
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      children: [{}]
    };

    return node;
  }

}
