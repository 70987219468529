import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorGeneratorService{

    // Function to convert HEX to HSL
    hexToHSL(hex: string): { h: number; s: number; l: number } {
        let r = parseInt(hex.substring(1, 3), 16) / 255;
        let g = parseInt(hex.substring(3, 5), 16) / 255;
        let b = parseInt(hex.substring(5, 7), 16) / 255;

        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h = 0, s = 0, l = (max + min) / 2;

        if (max !== min) {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            if (max === r) h = (g - b) / d + (g < b ? 6 : 0);
            else if (max === g) h = (b - r) / d + 2;
            else if (max === b) h = (r - g) / d + 4;
            h *= 60;
        }

        return {h, s, l};
    }

// Function to convert HSL back to HEX
    hslToHex(h: number, s: number, l: number): string {
        let hueToRGB = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        let r = hueToRGB(p, q, h / 360);
        let g = hueToRGB(p, q, h / 360 + 1 / 3);
        let b = hueToRGB(p, q, h / 360 + 2 / 3);

        return `#${Math.round(r * 255).toString(16).padStart(2, "0")}${Math.round(g * 255).toString(16).padStart(2, "0")}${Math.round(b * 255).toString(16).padStart(2, "0")}`;
    }

// Function to generate a random primary color and a matching lighter background color
    generateColorPair(): { color: string; bgColor: string } {
        const baseColor = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0");
        const hsl = this.hexToHSL(baseColor);

        // Adjust lightness for background (lighter but same color family)
        const lighterBgColor = this.hslToHex(hsl.h, hsl.s * 0.7, Math.min(0.85, hsl.l + 0.3)); // Increase lightness

        return {color: baseColor, bgColor: lighterBgColor};
    }


}
