import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerService} from "../../service/api/customer.service";
import {CustomerDto} from "../../dto/customer.dto";
import {
    ObjectIdentifier,
    EntityStatusEnum,
    DtoImportObjectContext,
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";

@Component({
    selector: 'cr-customer-inline-component',
    templateUrl: './cr-customer-inline-component.html',
})
export class CrCustomerInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() size: string = 'M';
    icon: string;
    dto: CustomerDto;

    protected readonly EntityStatusEnum = EntityStatusEnum;
    ticketId: number;
    initialized: boolean;
    busy: boolean;

    constructor(protected customerService: CustomerService,
                protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService
                ) {
    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        this.icon = this.customerService.getObjectIcon();
        this.refreshComponent();

    }

    refreshComponent() {
        this.customerService.get(this.objectIdentifier.objectId).subscribe((res: CustomerDto) => {
            this.dto = res;
            this.initialized = true;
        });
    }
    handleClick() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.addOverlay(navigationEntry)
    }

    ngOnChanges(changes: SimpleChanges) {

    }
}
