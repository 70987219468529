import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ImImportDataServiceInfoDto} from "../../dto/im-import-data-service-info.dto";
import {ImImportTypeDto} from "../../dto/im-import-type.dto";
import {ImImportActionDto} from "../../dto/im-import-action.dto";
import {ImImportRequestDto} from "../../dto/im-import-request.dto";
import {ImProcessingInformationDto} from "../../dto/im-processing-information.dto";
import {ImImportRequestActivityEnum} from "../../enum/im-import-request-activity.enum";
import {map} from "rxjs/operators";
import {ImImportSubTypeDto} from "../../dto/im-import-sub-type.dto";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ImImportDto} from "../../dto/im-import.dto";
import {ImSystemDto} from "../../dto/im-system.dto";
import {ImRequestThreadingDto} from "../../logic/im-request-threading.dto";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
  ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {ImImportRecordStatusEnum} from "../../enum/im-import-record-status.enum";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class ImDataImportService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/im/imDataImport');
  }

  /**
   * List all Data Import Services.
   * @protected
   */
  public listServices(): Observable<ImImportDataServiceInfoDto[]> {

    const url = this.apiUrl + "/listServices";

    return this.http.get<ImImportDataServiceInfoDto[]>(url);

  }

  /**
   * List systems.
   */
  public listSystems(): Observable<ImSystemDto[]> {

    const url = this.apiUrl + "/listSystems";

    return this.http.get<ImSystemDto[]>(url);

  }

  /**
   * List all import types.
   * @param importService
   * @protected
   */
  public listImportTypes(importService: string) : Observable<ImImportTypeDto[]> {

    const url = this.apiUrl + "/listTypes/" + importService;

    return this.http.get<ImImportTypeDto[]>(url);

  }

  /**
   * List all import sub types.
   * @param importService
   * @param importType
   */
  public listImportSubTypes(importService: string, importType: number) : Observable<ImImportSubTypeDto[]> {

    const url = this.apiUrl + "/listSubTypes/" + importService + "/" + importType;

    return this.http.get<ImImportSubTypeDto[]>(url);

  }


  /**
   * Retrieve import actions.
   * @param importService
   * @param importType
   */
  public listImportActions(importService: string, importType: number, importSubType: number) : Observable<ImImportActionDto[]> {

    const url = this.apiUrl + "/listActions/" + importService + "/" + importType + "/" + importSubType;

    return this.http.get<ImImportActionDto[]>(url);

  }


  /**
   * Execute Import Check.
   * @param importService
   * @param importType
   * @param importSubType
   * @param importAction
   * @param file
   */
  public executeImportCheck(
      importService: string,
      importType: number,
      importSubType: number,
      importAction: number,
      importSystem: number,
      file: File) : Observable<ImProcessingInformationDto> {

    return this.executeImportActivity(importService, importType, importSubType, importAction, ImImportRequestActivityEnum.checkData, importSystem, file, false);

  }

  /**
   * Execute Import.
   * @param importService
   * @param importType
   * @param importSubType
   * @param importAction
   * @param file
   */
  public executeImport(
      importService: string,
      importType: number,
      importSubType: number,
      importAction: number,
      importSystem: number,
      file: File,
      onlyReportErrorRecords: boolean,
      requestThreading?: ImRequestThreadingDto) : Observable<ImProcessingInformationDto | ImImportDto> {

    return this.executeImportActivity(importService, importType, importSubType, importAction, ImImportRequestActivityEnum.importData, importSystem, file, onlyReportErrorRecords, requestThreading);

  }

  /**
   * Execute import activity check/ import.
   * @param importService
   * @param importType
   * @param importSubType
   * @param importAction
   * @param activity
   * @param importSystem
   * @param file
   * @param requestThreading
   */
  public executeImportActivity(
      importService: string,
      importType: number,
      importSubType: number,
      importAction: number,
      activity: ImImportRequestActivityEnum,
      importSystem: number,
      file: File,
      onlyReportErrorRecords: boolean,
      requestThreading?: ImRequestThreadingDto) : Observable<ImProcessingInformationDto> {

    const url = this.apiUrl + "/executeActivity";

    const request: ImImportRequestDto = new ImImportRequestDto();

    request.importService = importService;
    request.importTypeId = importType;
    request.importActionId = importAction;
    request.importSubTypeId = importSubType;
    request.externalSystemId = importSystem;
    request.activity = activity;
    request.requestThreading = requestThreading;
    request.onlyReportErrorRecords = onlyReportErrorRecords;

    let formData = new FormData();
    formData.append("request", new Blob([JSON.stringify(request)], { type: "application/json"}));
    formData.append("file", file);

    return this.http.post<ImProcessingInformationDto>(url, formData);

  }

  /**
   * Download Imported Records via URL.
   * @param importId
   */
  public downloadImportRecordsViaUrl(
      importId: number) : Observable<string> {

    let url = `${this.apiUrl}/downloadImportRecords/${importId}`;

    const header = new HttpHeaders().set('Accept', 'application/octet-stream');

    return this.http.get<Blob>(url, { headers: header, responseType : 'blob' as 'json'})
        .pipe(
            map(value => {
              let file = new Blob([value], { type: 'application/xlsx' })
              return URL.createObjectURL(file);
            })
        );

  }


  public downloadTemplateViaUrl(
                  importService: string,
                  importType: number,
                  importSubType: number,
                  importAction: number,
                  withData: boolean,
                  filterCriteria?: FilterCriteria[]) : Observable<string> {


    let url = `${this.apiUrl}/template?importService=${importService}&importTypeId=${importType}&importSubTypeId=${importSubType}&importTypeActionId=${importAction}&showData=${withData}`;

    if (filterCriteria && filterCriteria.length > 0) {
      const filterString = encodeURIComponent(JSON.stringify(filterCriteria));
      url+=`&filterJson=${filterString}`;
    }

    const header = new HttpHeaders().set('Accept', 'application/octet-stream');

    return this.http.get<Blob>(url, { headers: header, responseType : 'blob' as 'json'})
        .pipe(
            map(value => {
              let file = new Blob([value], { type: 'application/xlsx' })
              return URL.createObjectURL(file);
            })
        );

  }

  getImportComplexSelectionRuntime(): ObjectRequestComplex {
    return ObjectRequestComplex.build(true, false,
        ObjectRequestComplexNode.createRelationNode("statusError",
                ObjectRequestRelation.createGroupBy(
                    "im.ImImportRecord",
                    "imImport",
                    ObjectRequestListGroupBy.create(
                        true,
                        [FilterCriteria.create("status", FilterCriteria.cOperatorBetween, ImImportRecordStatusEnum.error, ImImportRecordStatusEnum.exception)],
                        [],
                        ["status"],
                        [new ObjectRequestListAttribute("status", "Anzahl Fehler", DtoListAttributeRequestAggregateEnum.count)]
            ))
        ),
        ObjectRequestComplexNode.createRelationNode("statusSuccess",
            ObjectRequestRelation.createGroupBy(
                "im.ImImportRecord",
                "imImport",
                ObjectRequestListGroupBy.create(
                    true,
                    [FilterCriteria.create("status", FilterCriteria.cOperatorEqual, ImImportRecordStatusEnum.imported)],
                    [],
                    ["status"],
                    [new ObjectRequestListAttribute("status", "Anzahl Success", DtoListAttributeRequestAggregateEnum.count)]
                ))
        ),
        ObjectRequestComplexNode.createRelationNode("statusProcessing",
            ObjectRequestRelation.createGroupBy(
                "im.ImImportRecord",
                "imImport",
                ObjectRequestListGroupBy.create(
                    true,
                    [FilterCriteria.create("status", FilterCriteria.cOperatorEqual, ImImportRecordStatusEnum.processing)],
                    [],
                    ["status"],
                    [new ObjectRequestListAttribute("status", "Anzahl in Verarbeitung", DtoListAttributeRequestAggregateEnum.count)]
                ))
        )
    );
  }


}
