
<ng-container *ngIf="initialized && notificationTypeInfo">

    <!--
            HEADER

            NG CONTAINER
            -->

    <!-- Move to separate component -->
    <ng-container>

        <div class="flex justify-content-between w-full">
            <div class="flex gap-3 align-items-center">

                <p-button label="Senden"
                          (click)="onNotificationSend()"
                          [disabled]="busy"
                          *ngIf="channelRecipientContent && channelRecipientContent.channelAddressContacts && channelRecipientContent.channelAddressContacts.length > 0">
                </p-button>
                <div *ngIf="busy">
                    <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
            <div class="cursor-auto">
                <h5 class="mb-1 text-color-secondary">{{notificationTypeInfo.notificationTypeDto.name}}</h5>
                <p class="text-center text-xs"> {{notificationTypeInfo.notificationTypeDto.description}} </p>
            </div>
            <div class="flex align-items-center">

                <p-tag styleClass="surface-200 text-primary" *ngIf="channelRecipientContent && channelRecipientContent.history?.count">
                                        <span class="text-base p-2">{{channelRecipientContent.history?.count}} mal versendet
                                            (zuletzt - {{channelRecipientContent.history?.last | date: 'medium':'':'de-DE'}})</span>
                </p-tag>

                <span class="cursor" (click)="handleEnableEdit()" *ngIf="channelRecipientContent && !editEnabled">
                    <i class="fa-light fa-edit text-xl mx-3 text-color-secondary pt-1"></i>
                </span>

                <span class="cursor" (click)="handleDisableEdit()" *ngIf="channelRecipientContent && editEnabled">
                    <i class="fa-light fa-trash-undo text-xl mx-3 text-color-secondary pt-1"></i>
                </span>

                <span class="cursor" (click)="copyBodyToClipboard()" *ngIf="channelRecipientContent">
                    <i class="fa-light fa-copy text-xl mx-3 text-color-secondary pt-1"></i>
                </span>

            </div>
        </div>
    </ng-container>

    <!-- notification template body -->
    <ng-container>

        <ng-container *ngIf="notificationOnSendException">
            <p-message text="Beim Senden der Benachrichtigung ist ein Fehler aufgetreten." severity="error"></p-message>
            <br />
            <a *ngIf="lastSendNotification" [routerLink]="['/ns/notification/' + lastSendNotification.id]" target="_blank" class="p-component" role="button">
                <i class="fas fa-exclamation-circle"></i>
                <span class="p-button-label p-c">Zeige mir den Fehler</span>
            </a>
        </ng-container>


        <div class="flex w-full h-full mx-0">

            <ng-container *ngIf="sideButtons && sideButtons.length > 0">  <div class="pr-4 pt-4 border-200 border-right-1">
                <mvs-side-drawer [menuItems]="sideButtons"
                                 [showLabel]="false"
                                 (onObjectSelect)="handleSideMenuChannelChange($event)">
                </mvs-side-drawer>

            </div>
            </ng-container>

            <ng-container *ngIf="notificationChannelException">
                <p-message [text]="notificationChannelExceptionMessage" severity="error"></p-message>
            </ng-container>


            <div class="pt-4 w-full px-4 h-full overflow-y-auto" *ngIf="channelRecipientContent">
                <div class="formgrid grid m-0">

                    <div class="mb-3 col-12 p-fluid">
                        <div class="flex gap-3 align-items-center">
                            <h6 class="ns-recipient mb-0">An </h6>
                            <ng-container
                                    *ngIf="channelRecipientContent?.channelAddressContacts && channelRecipientContent?.channelAddressContacts.length">
                                <div class="w-full border-bottom-1 border-200">
                                    <p-dropdown [options]="channelRecipientContent.channelAddressContacts"
                                                [disabled]="false"
                                                styleClass="border-none inline-flex"
                                                optionLabel="recipient.recipientAddress"></p-dropdown>
                                </div>
                            </ng-container>
                            <ng-container
                                    *ngIf="!channelRecipientContent?.channelAddressContacts || !channelRecipientContent?.channelAddressContacts.length">
                                No address found!
                            </ng-container>
                        </div>
                    </div>

                    <div class="mb-3 col-12 p-fluid border-bottom-1 border-200 py-3" *ngIf="channelRecipientContent.contentHasSubject">
                        <div class="">
                            <div [formGroup]="formGroupContentSubject" *ngIf="formGroupContentSubject">
                                <mvs-form-field-toggleable
                                        [formGroup]="formGroupContentSubject"
                                        [defaultState]="formFieldContentSubject.defaultState"
                                        [formField]="formFieldContentSubject"
                                        [value]="channelRecipientContent?.contentSubject">
                                </mvs-form-field-toggleable>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container>
                    <!-- Content Body   -->
                    <div class="grid">
                        <div class="col-12">

                            <!--                ns-dialog-content mx-auto-->
                            <div class=""
                                 [ngClass]="channelRecipientContent.channelTypeDto.internalType == 1 ? 'ns-dialog-email-content col-12' : formFieldContentBody.defaultState == 'display' ?  'col-6 ns-sms-content' :  'col-12' "
                                 *ngIf="channelRecipientContent.contentHasBody">

                                <ng-container *ngIf="channelRecipientContent?.attachments">
                                    <!--                        <div>Anhänge:</div>-->
                                    <div class="flex flex-wrap gap-2" *ngFor="let attachment of channelRecipientContent.attachments">
                                        <div class="ns-attachment-box hover:bg-primary-50" (click)="handleShowAttachment(attachment)">
                                            <!--                                <p-button (onClick)="handleShowAttachment(attachment)">{{ attachment.fileName }}</p-button>-->
                                            <div class="flex gap-2 mb-2">
                                                <i class="fa-regular fa-file-pdf text-xl text-primary"></i>
                                                <a>{{ attachment.fileName }}</a>
                                            </div>
                                            <p>{{attachment.documentDto.dmDocumentTypeDtoName}} {{attachment.documentDto.createdDate |mvsDateFormat}}</p>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="ns-email-body" [formGroup]="formGroupContentBody" *ngIf="formGroupContentBody">
                                    <mvs-form-field-toggleable
                                            [formGroup]="formGroupContentBody"
                                            [defaultState]="formFieldContentBody.defaultState"
                                            [formField]="formFieldContentBody"
                                            [value]="channelRecipientContent?.contentBody"
                                    >
                                    </mvs-form-field-toggleable>
                                </div>
                            </div>
                        </div>
                    </div>


                </ng-container>
            </div>
        </div>

    </ng-container>

</ng-container>