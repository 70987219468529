import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {AgentService} from "../../../am/service/api/agent.service";
import {UmUserService} from "../../../um/service/api/um-user.service";
import {UmUserDto} from "../../../um/dto/um-user.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";

@Component({
    selector: 'wf-process-output-field',
    templateUrl: './wf-process-output-field.component.html',
    styleUrls: ['./wf-process-output-field.component.scss']
})
export class WfProcessOutputFieldComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() dtoList: DtoList;
    processList: WfProcessDto[];
    userName: string = '';


    constructor(protected userService: UmUserService, protected navigationService: MvsObjectNavigationService, protected objectService: ObjectTypeService, protected coreService: MvsCoreService) {
        super(navigationService,objectService,coreService);
    }

    ngOnInit(): void {
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        if (this.dtoList['activeProcess']) {
            this.processList = this.dtoList['activeProcess'] as WfProcessDto[];
        } else {
            this.processList = this.dtoList.entries as WfProcessDto[];
        }

    }

    openTicketDrawer(processId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("wf.WfProcess", processId, null, "Process", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'bottom');
    }

    getUserByOid(userOid: string) {
        // this.userService.getUserByOid(userOid).subscribe((res:UmUserDto) => {
        //     this.userName = res?.firstName + ' ' + res?.lastName;
        // })
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
