import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AgentDto} from "../../../am/dto/agent.dto";
import {AgentScheduleWeekService} from "../../../am/service/api/agent-schedule-week.service";
import {FilterCriteria, MvsMessageService, ObjectRequestList} from "@kvers/alpha-core-common";
import {AgentScheduleWeekDto} from "../../../am/dto/agent-schedule-week.dto";
import {AmWeekdayEnum} from "../../../am/enum/am-week-day.enum";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry, MvsObjectNavigationService,
    Person,
    SchedulerData,
    TransformedEventInterface
} from "@kvers/alpha-ui";
import {AgentService} from "../../../am/service/api/agent.service";
import {ColorGeneratorService} from "../../../as/service/color-generator.service";
import {AppointmentService} from "../../../as/service/api/appointment.service";
import {ParamRequest} from "@kvers/alpha-ui/lib/navigation/mvs-object-navigation-entry-param.interface";
import {AppointmentTypeDto} from "../../../as/dto/appointment-type.dto";
import {AppointmentTypeService} from "../../../as/service/api/appointment-type.service";
import {CustomerDto} from "../../../cr/dto/customer.dto";

@Component({
    selector: 'mvs-agent-calendar',
    templateUrl: 'agent-calendar.component.html',
    styleUrls: ['agent-calendar.component.scss']
})
export class AgentCalendarComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() agentIds: number[];
    @Input() agents: AgentDto[];
    @Input() customerId: number;

    @Output() onFindSlot: EventEmitter<{ startTime: Date, endTime: Date }[]> = new EventEmitter<{
        startTime: Date,
        endTime: Date
    }[]>();
    @Output() onShowEvent: EventEmitter<TransformedEventInterface> = new EventEmitter<TransformedEventInterface>();
    @Output() onMoveEvent: EventEmitter<TransformedEventInterface> = new EventEmitter<TransformedEventInterface>();
    @Output() onDeleteEvent: EventEmitter<TransformedEventInterface> = new EventEmitter<TransformedEventInterface>();
    @Output() onCurrentSelection: EventEmitter<{ startTime: Date, endTime: Date }> = new EventEmitter<{
        startTime: Date,
        endTime: Date
    }>();
    @Output() onChangeModeAndTime: EventEmitter<{
        viewMode: 'Day' | 'Week' | 'Month',
        selectedDate: Date
    }> = new EventEmitter<{ viewMode: 'Day' | 'Week' | 'Month', selectedDate: Date }>();
    @Output() dateRangeChanged: EventEmitter<{ start: Date, end: Date }> = new EventEmitter<{
        start: Date,
        end: Date
    }>();


    schedulerData: SchedulerData;

    startDate: Date;
    endDate: Date;
    currentSelectionStart: Date;
    currentSelectionEnd: Date;


    selectedAgent: AgentDto;

    appointmentType: AppointmentTypeDto[];
    selectedAppointmentType: AppointmentTypeDto;


    createEventDialog: boolean;

    subject:string;
    allowNewTimeProposals:boolean;
    isOnlineMeeting:boolean;

    constructor(
        protected agentService: AgentService,
        protected colorGeneratorService: ColorGeneratorService,
        protected appointmentService: AppointmentService,
        protected appointmentTypeService: AppointmentTypeService,
        protected navigationService: MvsObjectNavigationService,
        protected messageService: MvsMessageService,
    ) {
    }

    ngOnInit(): void {
        this.setTodayRange();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        if (this.agents?.length) {
            this.onAgentRetrieval();
        } else if (this.agentIds?.length) {
            this.getAgents(this.agentIds);
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    getAgents(agentIds: number[]) {

        let agentIdArray: { id: number }[] = [];
        for (let agentId of agentIds) {
            agentIdArray.push({id: Number(agentId)})
        }

        let filterCriteria = FilterCriteria.createOrFromArray('id', 'id', agentIdArray)


        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);
        this.agentService.list(objectRequest).subscribe(response => {
            this.agents = response.entries;

            this.onAgentRetrieval();

        })
    }

    onAgentRetrieval() {

        for (let agent of this.agents) {
            // Generate random colors for the agent
            const colors = this.colorGeneratorService.generateColorPair();
            agent['color'] = colors.color;
            agent['bgColor'] = colors.bgColor;
        }


        this.fetchAppointments();
    }

    fetchAppointments() {

        // Extract IDs as number[]
        const agentIds: number[] = this.agents.map(agent => agent.id);

        this.appointmentService.getCalendarAppointments(agentIds, this.startDate, this.endDate)
            .subscribe(
                data => {
                    this.schedulerData = this.transformToSchedulerData(this.agents, data);

                    this.refreshComponent();
                },
                error => {
                    this.schedulerData = null;
                }
            );
    }

    setTodayRange(now: Date = new Date()) {

        // Set start of the day in UTC (00:00:00 UTC)
        this.startDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0));

        // Set end of the day in UTC (23:59:59 UTC)
        this.endDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59));
    }

    transformToSchedulerData(rawAgents: AgentDto[], rawEvents: Record<string, any[]>): SchedulerData {
        const transformedAgents: Person[] = [];

        rawAgents.forEach((agent) => {
            const id = agent['id']; // Get the agent's email
            const agentEvents = rawEvents[id] || []; // Find matching events


            const transformedPerson: Person = {
                id: agent.id,
                name: agent.name,
                color: agent['color'],
                bgColor: agent['bgColor'],
                events: agentEvents.map((event, index) => {

                    const eventData = event?.appointmentEvent?.event;
                    const appointmentData = event?.appointmentEvent?.appointment;

                    return {
                        id: appointmentData?.id || eventData?.eventId, // Generate a unique ID for the event
                        title: eventData?.subject, // Placeholder title
                        start: new Date(
                            eventData?.start?.dateTime
                            || appointmentData?.startTime
                        ),
                        end: new Date(
                            eventData?.end?.dateTime
                            || appointmentData?.endTime
                        ),
                        preBufferTime: event?.appointmentEvent?.preBufferTime || "PT0M",
                        postBufferTime: event?.appointmentEvent?.postBufferTime || "PT0M",
                        location: eventData?.location?.displayName || "Unknown Location",
                        isOnlineMeeting: eventData?.isOnlineMeeting || false,
                        status: appointmentData?.statusEnumName || "Unknown",
                        color: appointmentData?.statusEnumColor || "gray-800",
                        bgColor: appointmentData?.statusEnumColorBackground || "gray-200",
                        isFreeSlot: false
                    }
                })
            };

            transformedAgents.push(transformedPerson);
        });

        return {agents: transformedAgents};
    }

    handleFindSlot(slots: { startTime: Date, endTime: Date }[]) {

    }

    handleEventSelect(event: TransformedEventInterface) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('as.Appointment', event.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any, 'full');
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');

    }

    handleEventMove(event: TransformedEventInterface) {

        this.appointmentService.moveAppointmentDate(event.id, event.start, event.end).subscribe(res => {
            this.fetchAppointments();
        }, error => {
            this.fetchAppointments();
        })

    }

    handleDeleteEvent(event: TransformedEventInterface) {

        this.appointmentService.deleteAppointment(event.id).subscribe(res => {
            this.fetchAppointments();
            this.messageService.showSuccessMessage('Delete', 'success delete')
        });
    }

    handleCurrentSelection(currentSelection: { startTime: Date, endTime: Date }) {

        this.currentSelectionStart = currentSelection.startTime;
        this.currentSelectionEnd = currentSelection.endTime;

        if (!this.appointmentType?.length) {
            this.getAppointmentTypes();
        } else {
            this.createEventDialog = true;

        }
    }

    getAppointmentTypes() {
        this.appointmentTypeService.list(ObjectRequestList.createBasic(false, [], [])).subscribe(response => {
            this.appointmentType = response.entries;
            this.createEventDialog = true;
        })
    }

    handleCreateAppointment() {

        if (!this.selectedAppointmentType || !this.selectedAgent || !this.currentSelectionStart || !this.currentSelectionEnd) {
            return
        }
        this.appointmentService.scheduleAppointment(this.selectedAppointmentType.id, this.selectedAgent.id, this.customerId, this.currentSelectionStart, this.currentSelectionEnd).subscribe(res => {
            this.fetchAppointments();
            this.createEventDialog = false;
        });

    }

    createEvent() {
        const agentId = this.selectedAgent.id; // Assuming agent object exists
        const eventDto = {
            allowNewTimeProposals: true, // Change this as needed
            start: {
                dateTime: this.currentSelectionStart, // Set start time
                timeZone: "UTC"
            },
            end: {
                dateTime: this.currentSelectionEnd, // 1 hour later
                timeZone: "UTC"
            },
            isOnlineMeeting: false, // Set accordingly
            subject: "Meeting with Client" // Example subject
        };

        this.appointmentService.createEvent(agentId, eventDto).subscribe(
            response => {
                console.log("Event created successfully:", response);
            },
            error => {
                console.error("Error creating event:", error);
            }
        );
    }

    handleChangeModeAndTime(modeTime: { viewMode: 'Day' | 'Week' | 'Month', selectedDate: Date }) {

        // this.setTodayRange(modeTime.selectedDate);
        // this.fetchAppointments();

    }

    handleDateRangeChanged(range: { start: Date, end: Date }) {

        this.startDate = range.start;
        this.endDate = range.end;

        this.fetchAppointments();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
