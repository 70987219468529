import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerService} from "../../service/api/customer.service";
import {CustomerDto} from "../../dto/customer.dto";
import {
    ObjectIdentifier,
    EntityStatusEnum,
    DtoImportObjectContext, MvsCrudModeEnum,
} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    MvsUiObjectService
} from "@kvers/alpha-ui";
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";
import {CustomerInteractionService} from "../../service/api/customer-interaction.service";
import {CustomerInteractionDto} from "../../dto/customer-interaction.dto";

@Component({
    selector: 'cr-customer-interaction-inline-component',
    templateUrl: './cr-customer-interaction-inline-component.html',
})
export class CrCustomerInteractionInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() size: string = 'M';
    icon: string;
    dto: CustomerInteractionDto;

    protected readonly EntityStatusEnum = EntityStatusEnum;
    initialized: boolean;
    busy: boolean;

    constructor(protected customerInteractionService: CustomerInteractionService,
                protected navigationService: MvsObjectNavigationService,
                protected objectService: MvsUiObjectService
                ) {
    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        this.icon = this.customerInteractionService.getObjectIcon();
        this.refreshComponent();

    }

    refreshComponent() {
        this.customerInteractionService.get(this.objectIdentifier.objectId).subscribe((res: CustomerInteractionDto) => {
            this.dto = res;
            this.initialized = true;
        });
    }
    handleClick() {
        this.objectService.openObjectViaDialog(
            null,
            this.objectIdentifier.objectType,
            this.objectIdentifier.objectId,
            null,
            false,
            false,
            () => {
                //
            },
            MvsCrudModeEnum.update
        );
        // const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        // this.navigationService.addOverlay(navigationEntry);
    }

    ngOnChanges(changes: SimpleChanges) {

    }
}
