<p-tabView>

    <!--    Search Via Basic Data    -->
    <p-tabPanel header="via Name">

        <div class="grid">

            <div class="col-2">

                <!--                <form (ngSubmit)="onSearchViaBasicData()">-->
                <!--                    <div class="flex flex-column gap-3">-->
                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="firstName" class="text-500 text-sm">Vorname</label>-->
                <!--                            <input pInputText id="firstName" name="firstName" [(ngModel)]="firstName"-->
                <!--                                   />-->
                <!--                        </div>-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="lastName" class="text-500 text-sm">Nachname</label>-->
                <!--                            <input pInputText id="lastName" name="lastName" [(ngModel)]="lastName"-->
                <!--                                   />-->
                <!--                        </div>-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="gender" class="text-500 text-sm">Geschlecht</label>-->
                <!--                            <p-dropdown-->
                <!--                                    id="gender"-->
                <!--                                    name="gender"-->
                <!--                                    [options]="genders"-->
                <!--                                    [(ngModel)]="gender"-->
                <!--                                    placeholder="auswählen"-->
                <!--                                    optionLabel="name"-->
                <!--                                    optionValue="value"-->
                <!--                                    styleClass="w-full"-->

                <!--                            >-->
                <!--                            </p-dropdown>-->
                <!--                        </div>-->

                <!--                        <div class="flex justify-content-end">-->
                <!--                            <p-button type="submit" label="Search"></p-button>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </form>-->

                <form [formGroup]="basicDataSearchForm" (ngSubmit)="onSearchViaBasicData()">
                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="firstName" class="text-500 text-sm">Vorname</label>
                            <input pInputText id="firstName" name="firstName" formControlName="firstName">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="lastName" class="text-500 text-sm">Nachname</label>
                            <input pInputText id="lastName" name="lastName" formControlName="lastName">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="gender" class="text-500 text-sm">Geschlecht</label>
                            <p-dropdown
                                    id="gender"
                                    name="gender"
                                    [options]="genders"
                                    formControlName="gender"
                                    placeholder="auswählen"
                                    optionLabel="name"
                                    optionValue="value"
                                    styleClass="w-full"
                            ></p-dropdown>
                        </div>

                        <div class="flex justify-content-end">
                            <p-button type="submit" label="Search"></p-button>
                        </div>
                    </div>
                </form>


            </div>


            <div class="col-10">
                <mvs-widget
                        *ngIf="widgetTableViaBasicData"
                        [widgetData]="widgetTableViaBasicData"
                        (onObjectSelect)="handlePersonObjectSelect($event)"
                ></mvs-widget>

                <mvs-display-message-before-result *ngIf="!widgetTableViaBasicData"></mvs-display-message-before-result>
            </div>
        </div>

    </p-tabPanel>


    <!--    Search Via Person    -->
    <p-tabPanel header="via Geburtstag">

        <div class="grid">

            <div class="col-2">

                <!--                <form (ngSubmit)="onSearchViaPerson()">-->
                <!--                    <div class="flex flex-column gap-3">-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="birthName" class="text-500 text-sm">Geburtsname</label>-->
                <!--                            <input pInputText id="birthName" name="birthName" [(ngModel)]="birthName"-->
                <!--                                   />-->
                <!--                        </div>-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="birthDate" class="text-500 text-sm">Geburtstag</label>-->
                <!--                            <p-calendar id="birthDate" name="birthDate" [(ngModel)]="birthDate" [showIcon]="true"-->
                <!--                                        styleClass="w-full"></p-calendar>-->
                <!--                        </div>-->

                <!--                        <div class="flex justify-content-end">-->
                <!--                            <p-button type="submit" label="Search"></p-button>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </form>-->

                <form [formGroup]="personDataSearchForm" (ngSubmit)="onSearchViaPerson()">
                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="birthName" class="text-500 text-sm">Geburtsname</label>
                            <input pInputText id="birthName" name="birthName" formControlName="birthName">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="birthDate" class="text-500 text-sm">Geburtstag</label>
                            <p-calendar
                                    id="birthDate"
                                    name="birthDate"
                                    formControlName="birthDate"
                                    [showIcon]="true"
                                    styleClass="w-full"
                            ></p-calendar>
                        </div>

                        <div class="flex justify-content-end">
                            <p-button type="submit" label="Search"></p-button>
                        </div>
                    </div>
                </form>


            </div>


            <div class="col-10">
                <mvs-widget
                        *ngIf="widgetTableViaPerson"
                        [widgetData]="widgetTableViaPerson"
                        (onObjectSelect)="handlePersonDetailObjectSelect($event)"
                ></mvs-widget>

                <mvs-display-message-before-result
                        *ngIf="!widgetTableViaPerson"></mvs-display-message-before-result>
            </div>

        </div>


    </p-tabPanel>


    <!--    Search Via Customer Data    -->
    <p-tabPanel header="via Kundendaten">

        <div class="grid">

            <div class="col-2">

                <!--                <form (ngSubmit)="onSearchViaCustomer()">-->
                <!--                    <div class="flex flex-column gap-3">-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="alias" class="text-500 text-sm">Kundennummer</label>-->
                <!--                            <input pInputText id="alias" name="alias" [(ngModel)]="alias"/>-->
                <!--                        </div>-->

                <!--                        <div class="flex flex-column gap-2">-->
                <!--                            <label for="customerId" class="text-500 text-sm">ID</label>-->
                <!--                            <input id="customerId" name="customerId" type="text" pInputText [(ngModel)]="customerId"/>-->
                <!--                        </div>-->

                <!--                        <div class="flex justify-content-end">-->
                <!--                            <p-button type="submit" label="Search"></p-button>-->
                <!--                        </div>-->

                <!--                    </div>-->
                <!--                </form>-->

                <form [formGroup]="customerDataSearchForm" (ngSubmit)="onSearchViaCustomer()">
                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="alias" class="text-500 text-sm">Kundennummer</label>
                            <input pInputText id="alias" name="alias" formControlName="alias">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="customerId" class="text-500 text-sm">ID</label>
                            <input id="customerId" name="customerId" type="text" pInputText
                                   formControlName="customerId">
                        </div>

                        <div class="flex justify-content-end">
                            <p-button type="submit" label="Search"></p-button>
                        </div>
                    </div>
                </form>


            </div>

            <div class="col-10">
                <mvs-widget
                        *ngIf="widgetTableViaCustomer"
                        [widgetData]="widgetTableViaCustomer"
                        (onObjectSelect)="handleObjectSelect($event)"
                ></mvs-widget>

                <mvs-display-message-before-result *ngIf="!widgetTableViaCustomer"></mvs-display-message-before-result>
            </div>

        </div>
    </p-tabPanel>
</p-tabView>
