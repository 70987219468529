<div class="flex flex-column gap-3">

    <!--    WF-PROCESS-ACTIVITY-SIDE-PANEL-->

    <!-- Activity Types -->
    <div *ngFor="let activity of optionalActivityTypes; let i = index">

        <!--        SIDE ACTIVITY: {{ activity.objectTypeDtoName }}-->

        <!-- Show Side Activity -->
        <div
                [ngClass]="{'wf-side-activity-input-field-selected-radio-background':selectedActivity == i}">
            <mvs-wf-process-step-activity-side
                    [activityType]="activity"
                    [processRuntime]="processRuntime"
                    [processMeta]="processMeta"
                    [stepType]="stepType"
                    (onAction)="onOptionalComponentSelect(activity,i)"
            ></mvs-wf-process-step-activity-side>
        </div>

    </div>

</div>
