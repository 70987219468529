import {
    WfProcessTypeStepActivityFormDefaultValueTypeEnum
} from "../../enum/wf-process-type-step-activity-form-default-value-type.enum";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {WfProcessRuntimeBindingDto} from "./wf-process-runtime-binding.dto";

export class WfActivityFormDefaultDto {

    public processTypeStepActivity: number;
    public fieldName: string;

    public entityFieldName: string;

    public defaultValue: boolean;   // flag, whether value should be defaulted

    public fieldValue: any; // actual value

    public fieldValueIsNull: boolean;   // indicator that the NULL should be set (takes priority)

    public valueType: WfProcessTypeStepActivityFormDefaultValueTypeEnum;

    public fieldAccess: MvsFormFieldAccessEnum;

    public includeInFilter: boolean;


    public static processFormDefaults(
        formDefaults: WfActivityFormDefaultDto[],
        defaultDto: DtoDetail,
        filterCriteria: FilterCriteria[],
        formControlOverwrite: MvsFormControlOverwrite) {

        if (formDefaults && formDefaults.length > 0) {

            for (let formDefault of formDefaults) {
                const fieldValue = defaultDto[formDefault.fieldName];

                if (formDefault.fieldValue || fieldValue) {
                    if (formDefault.includeInFilter) {
                        const fieldName = fieldValue ? formDefault.fieldName.replace('DtoId','') : formDefault.fieldName;
                        filterCriteria.push(FilterCriteria.create(fieldName, FilterCriteria.cOperatorEqual, fieldValue ?? formDefault.fieldValue));
                    }
                    defaultDto[formDefault.fieldName] = fieldValue ?? formDefault.fieldValue;

                }

                // check if access should be changed via overwrite
                if (formDefault.fieldAccess !== undefined && formDefault.fieldAccess !== null) {
                    formControlOverwrite.addField(formDefault.fieldName, formDefault.fieldAccess);
                }
            }
        }
    }

    public static processBindings(
        bindings: WfProcessRuntimeBindingDto[],
        defaultDto: DtoDetail,
        constBindingFieldPrefix: string,
        filterCriteria: FilterCriteria[],
    ) {

        if (!filterCriteria) {
            filterCriteria = []
        }

        if (bindings && bindings.length) {

            for (let binding of bindings) {

                let fieldName: string;
                let fieldValue: any;

                if (!binding.alias.startsWith(constBindingFieldPrefix)) {
                    continue; // binding not relevant
                }

                fieldName = binding.alias.slice(constBindingFieldPrefix.length);

                if (binding.objectBinding) {
                    // object is processed
                    fieldName = fieldName + "DtoId";
                    fieldValue = binding.objectValue.id;
                } else {
                    fieldValue = binding.simpleValue;
                }

                if (binding.includeInFilter) {
                    filterCriteria.push(FilterCriteria.create(binding.alias.slice(constBindingFieldPrefix.length),FilterCriteria.cOperatorEqual,fieldValue));
                }


                defaultDto[fieldName] = fieldValue;
            }
        }
    }

}