import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AppointmentBookingBaseComponent} from "../appointment-booking-base.component";


@Component({
    selector: 'as-appointment-booking-side',
    templateUrl: 'appointment-booking-side.component.html',
})
export class AppointmentBookingSideComponent extends AppointmentBookingBaseComponent implements OnInit {


    ngOnInit() {
        super.ngOnInit();
    }

}
