import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {WfProcessRuntimeDto} from "../../../service/dto/wf-process-runtime.dto";
import {MenuItem} from "primeng/api";
import {MvsCrudModeEnum, MvsMessageService, ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {DtoImportObjectContext} from "@kvers/alpha-core-common/lib/dto/dto.import.object.context";
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {WfActivityProcessingResultFieldDto} from "../../../service/dto/wf-activity-processing-result-field.dto";
import {WfProcessContinueRequestDto} from "../../../service/dto/wf-process-continue-request.dto";
import {WfProcessRuntimeService} from "../../../service/wf-process-runtime.service";
import {WfProcessService} from "../../../service/api/wf-process.service";
import {WfProcessTypeStepActivityDto} from "../../../dto/wf-process-type-step-activity.dto";
import {WfProcessTypeFlowTypeEnum} from "../../../enum/wf-process-type-flow-type.enum";
import {WfProcessTypeStepNextTypeEnum} from "../../../enum/wf-process-type-step-next-type.enum";
import {WfProcessStepDto} from "../../../dto/wf-process-step.dto";
import {WfProcessStepService} from "../../../service/api/wf-process-step.service";


@Component({
    selector: 'wf-process-header',
    templateUrl: './wf-process-header.component.html',
    styleUrls: ['./wf-process-header.component.scss']
})
export class WfProcessHeaderComponent implements OnInit, OnChanges {

    @Input() processRuntime: WfProcessRuntimeDto;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() dto: WfProcessDto;

    @Output() showProcessedObject: EventEmitter<boolean> = new EventEmitter();
    @Output() onStepUpdate: EventEmitter<WfProcessStepDto> = new EventEmitter();
    @Output() onContinue: EventEmitter<WfProcessRuntimeDto> = new EventEmitter();

    mainActivity: WfProcessTypeStepActivityDto;

    isValid: boolean;
    busy: boolean;
    initialized: boolean;
    isLastStep: boolean;
    configurationItems: MenuItem[] | undefined;

    showAgentSearch: boolean;
    selectedAgentId: number;
    selectedAgentPoolId: number;

    constructor(
        protected messageService: MvsMessageService,
        protected processStepService: WfProcessStepService,
        protected objectService: MvsUiObjectService,
        protected processService: WfProcessService) {
    }

    ngOnInit() {

        this.refreshComponent()

        this.initialized = true;
    }

    refreshComponent() {

        if (this.processRuntime?.stepMeta?.activities?.entries?.length) {
            this.mainActivity = WfProcessTypeStepActivityDto.getMainActivityForStep(this.processRuntime.stepMeta.activities.entries);
            this.isLastStep = !!this.processRuntime.stepMeta.activeStepNextSteps.entries.find(res => res.stepNextType == WfProcessTypeStepNextTypeEnum.COMPLETE_PROCESS)
        } else {
            this.mainActivity = null;
            this.isLastStep = false;
        }


        this.handleConfigurationItems();

    }

    /**
     * slide menu configuration
     */
    handleConfigurationItems() {

        this.configurationItems = [
            {
                label: 'Process Information',
                command: () => this.onOpenProcessCrud()
            },
            {
                label: 'Processed Objects',
                command: () => this.showProcessedObject.emit(true)
            },
            {
                label: 'Configure',
                items: [
                    {
                        label: 'Process Type',
                        command: () => this.openConfigInNewWindow('wf.WfProcessType')
                    },
                    {
                        label: 'Process Step Type',
                        command: () => this.openConfigInNewWindow('wf.WfProcessType')
                    },
                ]
            }
        ];
    }

    /**
     * show process form
     * @protected
     */
    protected onOpenProcessCrud() {
        this.objectService.openObjectViaDialog(this.importObjectContext, this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, false, false, null, MvsCrudModeEnum.create);
    }

    /**
     * method to open wf/config page in tab
     * @param tid
     */
    openConfigInNewWindow(tid: string) {
        window.open(`/#/wf/config?tid=${tid}:${this.dto.typeDtoId}:object&tid-action=0`, '_blank');
    }

    /**
     * handle skip and continue
     * @param action
     */
    handleContinue(action: string) {


        const result: WfActivityProcessingResultFieldDto[] = WfProcessRuntimeService.activityProcessingResult;

        if (!result && action == 'continue') {
            return;
        }

        this.busy = true;
        let skipStep = false;
        if (action == 'skip') {
            skipStep = true;
        }


        const requestDto: WfProcessContinueRequestDto = {
            processId: this.dto.id,
            processStepActivity: this.processRuntime.activeStepActivity.id,
            fields: result,
            skipStep: skipStep
        }

        this.processService.completeMainActivity(requestDto).subscribe(res => {
            WfProcessRuntimeService.resetResult();
            this.onContinue.emit(<WfProcessRuntimeDto><unknown>res);
            this.busy = false;
        }, error => {
            WfProcessRuntimeService.resetResult();
            this.messageService.showErrorMessage("can't continue ", error);
            this.busy = false;
        });
    }


    onAgentSearchDisplay() {
        this.showAgentSearch = true;
    };

    ngOnChanges(changes: SimpleChanges) {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();

    }

    cancelForwardAssignments() {
        this.showAgentSearch = false;
        this.selectedAgentId = null;
        this.selectedAgentPoolId = null;
    }

    handleSelectedAgent(event: { id: number, name: string }) {
        this.selectedAgentId = event.id;
    }


    confirmSelectedAgent() {
        const step: WfProcessStepDto = new WfProcessStepDto();
        step.id = this.processRuntime.activeStep.id;
        if (this.selectedAgentId) {
            step.assignmentAgentDtoId = this.selectedAgentId;
        } else {
            step.assignmentAgentDtoId = null;
        }
        this.updateProcessStep(step);
    }

    handleSelectedAgentPool(event: { id: number, name: string }) {
        this.selectedAgentPoolId = event.id;
    }

    confirmSelectedAgentPool() {
        const step: WfProcessStepDto = new WfProcessStepDto();
        step.id = this.processRuntime.activeStep.id;

        if (this.selectedAgentPoolId) {
            step.assignmentAgentPoolDtoId = this.selectedAgentPoolId;
        } else {
            step.assignmentAgentPoolDtoId = null;
        }
        this.updateProcessStep(step);
    }

    updateProcessStep(step: WfProcessStepDto) {
        this.processStepService.update(step).subscribe(value => {
            this.onStepUpdate.emit(<WfProcessStepDto>value);
            this.cancelForwardAssignments();
        })
    }

    protected readonly WfProcessRuntimeService = WfProcessRuntimeService;
}