<ng-container *ngIf="initialized">

    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>

    <ng-container *ngIf="activeItem.id == 'basic'">
        <mvs-widget
                *ngIf="widgetObjectType"
                [widgetData]="widgetObjectType"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeItem.id == 'fieldConfig'">

        <br />

        <mvs-form-configure-fields
                *ngIf="objectTypeForm"
                [layoutId]=""
                [form]="objectTypeForm"
                [objectType]="dto.alias"
                [readonly]="false"
        ></mvs-form-configure-fields>

    </ng-container>


</ng-container>