import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {EmployeePage} from "./page/overview/employee/employee.page";
import {OrganizationUnitPage} from "./page/overview/organization-unit/organization-unit.page";
import {DepartmentPage} from "./page/overview/department/department.page";


@NgModule({
  declarations: [
    EmployeePage,
    OrganizationUnitPage,
    DepartmentPage,
  ],
  exports:[
    EmployeePage,
    OrganizationUnitPage,
    DepartmentPage,
  ],
  imports: [
    CoreModule
  ]
})
export class OmModule { }
 
 
 
