import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DmAssignmentTypeDto} from "../../dto/dm-assignment-type.dto";
import {DtoBag} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class DmDocumentTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/dm/dmDocumentTypes');
  }

  /**
   * Retrieve assignment type.
   * @param id
   */
  public getAssignmentType(id: number): Observable<DmAssignmentTypeDto> {

    const url = `${this.apiUrl}/assignmentType/${id}`;
    return <Observable<DmAssignmentTypeDto>>this.getInternal(url);

  }

  public getDocumentTypesByObjectType(objectTypeId: number): Observable<DtoBag> {

    const url = `${this.apiUrl}/listByObjectType/${objectTypeId}`;
    return <Observable<DtoBag>>this.getInternal(url);

  }

  public getDocumentTypesByObjectTypeAlias(objectTypeAlias: string): Observable<DtoBag> {

    const url = `${this.apiUrl}/listByObjectTypeAlias/${objectTypeAlias}`;
    return <Observable<DtoBag>>this.getInternal(url);

  }



}
