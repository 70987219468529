import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {firstValueFrom, Observable, tap} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {ObjectTypeDto} from "../../dto/object-type.dto";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {ObjectTypeObjectComponent} from "../../component/object-type-object-component/object-type-object.component";

@Injectable({
    providedIn: 'root'
})
export class ObjectTypeService extends MvsCrudService{

    bufferedByAlias: Map<string,ObjectTypeDto> = new Map<string, ObjectTypeDto>();
    bufferedById: Map<number,ObjectTypeDto> = new Map<number, ObjectTypeDto>();

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/objectTypes');
    }

    /**
     * Retrieve Object Types by aliases (multi).
     * @param aliases
     */
    public getObjectTypesByAlias(aliases: string[]) : Observable<DtoList<ObjectTypeDto>> {

        return this.listByAttributeValues("alias", aliases);

    }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update) : Type<any> {
        if (mode == MvsCrudModeEnum.update) {
            return ObjectTypeObjectComponent;
        }
        return null;
    }


    /**
     * Retrieve object type information via alias.
     * @param oid
     */
    public getViaObjectType(objectType: string) : Promise<ObjectTypeDto> {

        if (this.bufferedByAlias.has(objectType)) {

            const dto = this.bufferedByAlias.get(objectType);

            // return already retrieved result
            return new Promise<ObjectTypeDto>(function(resolve, reject) {
                resolve(dto);
            });

        } else {

            // retrieve user and buffer data
            return firstValueFrom(<Observable<ObjectTypeDto>>this.getByAttribute("alias", objectType)
                .pipe(
                    map(value => {

                        let objectTypeDto = <ObjectTypeDto>value;

                        // buffer information
                        this.bufferObjectType(objectTypeDto);

                        return objectTypeDto;
            })));
        }
    }
/*
    public getMultiViaObjectId(objectId: number[]) : Promise<ObjectTypeDto[]> {
        // TODO: Implement Multi
        return;
    }

 */

    /**
     * Retrieve object type via ID.
     * @param objectId
     */
    public getViaObjectId(objectId: number) : Promise<ObjectTypeDto> {

        if (this.bufferedById.has(objectId)) {

            const dto = this.bufferedById.get(objectId);

            // return already retrieved result
            return new Promise<ObjectTypeDto>(function(resolve, reject) {
                resolve(dto);
            });

        } else {

            // retrieve user and buffer data
            return firstValueFrom(<Observable<ObjectTypeDto>>this.get(objectId)
                .pipe(
                    map(value => {

                        let objectTypeDto = <ObjectTypeDto>value;

                        // buffer information
                        this.bufferObjectType(objectTypeDto);

                        return objectTypeDto;
                    }))
            );
        }
    }

    protected bufferObjectType(objectTypeDto: ObjectTypeDto) {
        this.bufferedByAlias.set(objectTypeDto.alias, objectTypeDto);
        this.bufferedById.set(objectTypeDto.id, objectTypeDto);
    }



}
