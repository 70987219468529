import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    ObjectBaseComponent,
} from "@kvers/alpha-core-common";


@Component({
    selector: 'cc-meta-extension',
    templateUrl: 'meta-extension-object.component.html',
})
export class MetaExtensionObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    ngOnInit() {
        this.handleName('Object Type: Meta Extension');
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
