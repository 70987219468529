import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudService} from "@kvers/alpha-core-common";

@Injectable({
    providedIn: 'root'
})
export class FieldValueListService extends MvsCrudService{

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/fieldValueLists');
    }
}
