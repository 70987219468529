import {Route, Routes} from "@angular/router";
import {ImDashboardPage} from "./page/overview/im-dashboard/im-dashboard.page";
import {ImImportPage} from "./page/overview/im-import/im-import.page";
import {ImConfigPage} from "./page/overview/im-config/im-config.page";
import {ImOverviewPage} from "./page/im-overview-page/im-overview.page";

export function ImSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'im', component: ImOverviewPage},
        {path: 'im/dashboard', component: ImDashboardPage},
        {path: 'im/dataImport', component: ImImportPage},
        {path: 'im/config', component: ImConfigPage},

    ];

    return routes;

}
