import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-wf-activity-type-page',
  templateUrl: './activity-type.component.html',
  styleUrls: ['./activity-type.component.scss']
})
export class WfActivityTypeComponent extends PageComponent  {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Workflow Activity Configuration";

  ngOnInit(): void {

    super.ngOnInit();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'wf.WfActivityTypeObject',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Aktivitätstyp - Objekt Bearbeiten"

        },
        {
          'id'                : 2,
          'objectAlias'       : 'wf.WfActivityTypeStartProcess',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Aktivitätstyp - Prozess Starten"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'wf.WfActivityTypeNotification',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Aktivitätstyp - Benachrichtigungen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'wf.WfReminderType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Reminder"
        }
      ]
    };

  }
}
