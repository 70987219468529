import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-customer-document-upload',
    templateUrl: './cr-customer-document-upload.component.html',
    styleUrls: ['./cr-customer-document-upload.component.scss']
})
export class CrCustomerDocumentUploadComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;

    constructor() {
    }

    ngOnInit(): void {
    }


    ngOnChanges(changes: SimpleChanges): void {

    }

}
