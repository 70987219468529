<ng-container *ngIf="initialized">

    <p-dropdown *ngIf="listOutgoingCallTypeDtoList" [options]="listOutgoingCallTypeDtoList.entries"
                [(ngModel)]="selectedCallType" placeholder="Typ auswählen" optionLabel="name" scrollHeight="385px"></p-dropdown>

    <ng-container *ngFor="let recipientAddress of customerContactPhoneInfo.recipientAddresses">
        <div class="surface-card shadow-1 border-round flex align-items-center justify-content-start p-2 my-2 cursor-pointer"
             (click)="confirmStartCall($event, recipientAddress)">
            <span class="fa-stack mr-1">
                <i *ngIf="recipientAddress.preferred"
                   class="{{uiPreferredIconClass}} fa-fw fa-stack-2x text-blue-200"></i>
                <i class="{{recipientAddress.contactTypeImage}} fa-fw fa-stack-1x"></i>
            </span>

<!--            <span class="text-600">{{recipientAddress.phoneNumber}}</span>-->
            <mvs-form-control-output-text-clipboard [value]="recipientAddress.phoneNumber"> </mvs-form-control-output-text-clipboard>


            <i class="{{recipientAddress.uiChannelAccessInfo.iconClass}} ml-auto text-xl"></i>
        </div>
    </ng-container>

    <ng-container
            *ngIf="!customerContactPhoneInfo.recipientAddresses || customerContactPhoneInfo.recipientAddresses.length == 0">
        Keine Telefonnummer hinterlegt
    </ng-container>

</ng-container>

