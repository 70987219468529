import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {NsNotificationTypesForRecipientInfoDto} from "../../dto/service/ns-notification-types-for-recipient-info.dto";
import {NsNotificationTypeRecipientDetailDto} from "../../dto/service/ns-notification-type-recipient-detail.dto";
import {NsNotificationTypeObjectComponent} from "../../component/ns-notification/ns-notification-type-object.component";

@Injectable({
  providedIn: 'root'
})
export class NsNotificationTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ns/nsNotificationTypes');
  }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    if (mode == MvsCrudModeEnum.update) {
      return NsNotificationTypeObjectComponent;
    }
    return null;
  }

  /**
   * Retrieve notification types for recipient.
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public getTypesForRecipient(objectTypeId: number, objectId: number): Observable<NsNotificationTypesForRecipientInfoDto> {

    const url = `${this.apiUrl}/getTypesForRecipient`;

    return this.getTypesForRecipientObservable(url, { objectTypeId : objectTypeId, objectId : objectId});

  }

  /**
   * Retrieve notification types for recipient.
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public getTypesForRecipientAlias(
      objectTypeAlias: string,
      objectId: number): Observable<NsNotificationTypesForRecipientInfoDto> {

    const url = `${this.apiUrl}/getTypesForRecipient`;
    return this.getTypesForRecipientObservable(url, { objectTypeAlias : objectTypeAlias, objectId : objectId});

  }

  /**
   * Retrieve notification types for object.
   * @param objectTypeId
   * @param objectId
   */
  public getTypesForObject(objectTypeId: number, objectId: number): Observable<NsNotificationTypesForRecipientInfoDto> {

    const url = `${this.apiUrl}/getTypesForObject`;

    return this.getTypesForRecipientObservable(url, { objectTypeId : objectTypeId, objectId : objectId});

  }

  /**
   * Retrieve notification types for object.
   * @param objectTypeAlias
   * @param objectId
   */
  public getTypesForObjectAlias(
      objectTypeAlias: string,
      objectId: number): Observable<NsNotificationTypesForRecipientInfoDto> {

    const url = `${this.apiUrl}/getTypesForObject`;
    return this.getTypesForRecipientObservable(url, { objectTypeAlias : objectTypeAlias, objectId : objectId});

  }

  /**
   * Retrieve notification types for recipient and convert incoming DTO.
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public getTypesForRecipientObservable(url: string, queryParams: any = {}) : Observable<NsNotificationTypesForRecipientInfoDto> {

    return this.http.get<NsNotificationTypesForRecipientInfoDto>(url, { params: queryParams}).pipe(
        tap(dto => {

          // this is a complex DTO and we need to transform all DtoLists
          MvsCrudService.transformDtoListOutToIn(dto.notificationTypes);
          MvsCrudService.transformDtoListOutToIn(dto.channelTypes);
          MvsCrudService.transformDtoListOutToIn(dto.notificationTypeChannelTypes);

          if (dto.historyRecipients) {
            MvsCrudService.transformDtoListOutToIn(dto.historyRecipients);
          }

        }),
        catchError(this.handleError<NsNotificationTypesForRecipientInfoDto>(`get URL=${url}`))
    );

  }

  /**
   * Get Details.
   * @param notificationTypeId
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public getTypeRecipientDetails(notificationTypeId: number, objectTypeId: number, objectId: number): Observable<NsNotificationTypeRecipientDetailDto> {

    const url = `${this.apiUrl}/getRecipientDetails/${notificationTypeId}`;
    return this.getTypeRecipientDetailsObservable(url, { objectTypeId : objectTypeId, objectId : objectId});

  }

  /**
   * Get Details via Alias.
   * @param notificationTypeId
   * @param objectTypeId
   * @param objectId
   * @protected
   */
  public getTypeRecipientDetailsAlias(notificationTypeId: number, objectTypeAlias: string, objectId: number): Observable<NsNotificationTypeRecipientDetailDto> {

    const url = `${this.apiUrl}/getRecipientDetails/${notificationTypeId}`;
    return this.getTypeRecipientDetailsObservable(url, { objectTypeAlias : objectTypeAlias, objectId : objectId});

  }

  /**
   * Get Details via Object.
   * @param notificationTypeSourceId
   * @param objectTypeAlias
   * @param objectId
   */
  public getTypeObjectRecipientDetails(notificationTypeSourceId: number, objectTypeAlias: string, objectId: number): Observable<NsNotificationTypeRecipientDetailDto> {

    const url = `${this.apiUrl}/getObjectRecipientDetails/${notificationTypeSourceId}`;
    return this.getTypeRecipientDetailsObservable(url, { objectTypeAlias : objectTypeAlias, objectId : objectId});

  }


  public getTypeRecipientDetailsObservable(url: string, queryParams: any = {}) : Observable<NsNotificationTypeRecipientDetailDto> {

    return this.http.get<NsNotificationTypeRecipientDetailDto>(url,{ params: queryParams}).pipe(
        tap(dto => {

          // this is a complex DTO and we need to transform all DtoLists
          MvsCrudService.transformDtoListOutToIn(dto.historyRecipients);

        }),
        catchError(this.handleError<NsNotificationTypeRecipientDetailDto>(`get URL=${url}`))
    );

  }



}