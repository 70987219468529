<ng-container *ngIf="initialized">

    <div class="grid">


        <div class="col-3">

            <mvs-widget *ngIf="processWidget"
                        [widgetData]="processWidget"
                        (onObjectSelect)="handleProcess($event)"

            >

            </mvs-widget>

        </div>

        <div class="col-6">

            <mvs-widget *ngIf="processTypeWidget"
                        [widgetData]="processTypeWidget"
                        (onObjectSelect)="handleProcessStep($event)"

            >
            </mvs-widget>

            <div class="grid">

                <div class="col-8">
                    <mvs-widget *ngIf="processStepWidgetTable"
                                [widgetData]="processStepWidgetTable"

                    >
                    </mvs-widget>
                </div>

                <div class="col-4">
                    <mvs-widget *ngIf="processStepWidgetCategory"
                                [widgetData]="processStepWidgetCategory"
                                (onObjectSelect)="handleProcessStepCategory($event)"

                    >
                    </mvs-widget>
                </div>
            </div>

        </div>

<!--        <div class="col-12">-->

<!--            <mvs-widget *ngIf="processTypeStepWidgetTable"-->
<!--                        [widgetData]="processTypeStepWidgetTable"-->
<!--                        (onObjectSelect)="handleProcess($event)"-->

<!--            >-->
<!--            </mvs-widget>-->

<!--        </div>-->

<!--        <div class="col-12">-->

<!--            <mvs-widget *ngIf="processTypeWidget"-->
<!--                        [widgetData]="processTypeWidget"-->
<!--                        (onObjectSelect)="handleProcess($event)"-->

<!--            >-->
<!--            </mvs-widget>-->

<!--        </div>-->

    </div>



</ng-container>