import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ImSeverityIconDirective} from "./directive/im-severity-icon.directive";
import {ImDataImportComponent} from "./component/im-data-import/im-data-import.component";
import {ImDashboardPage} from "./page/overview/im-dashboard/im-dashboard.page";
import {ImViewImportComponent} from "./component/im-view-import/im-view-import.component";
import {ImImportPage} from "./page/overview/im-import/im-import.page";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ImConfigPage} from "./page/overview/im-config/im-config.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {SiKpiComponent} from "../si/page/si-overview-page/components/kpiComponent/si-kpi.component";
import {
    SiStatisticsComponent
} from "../si/page/si-overview-page/components/statisticsComponent/si-statistics.component";
import {ImOverviewPage} from "./page/im-overview-page/im-overview.page";
import {ImStatisticsComponent} from "./page/im-overview-page/components/statisticsComponent/im-statistics.component";
import {ImKpiComponent} from "./page/im-overview-page/components/kpiComponent/im-kpi.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
    declarations: [
        ImDataImportComponent,
        ImDashboardPage,
        ImViewImportComponent,
        ImImportPage,
        ImSeverityIconDirective,
        ImConfigPage,
        ImOverviewPage,
        ImStatisticsComponent,
        ImKpiComponent
    ],
    exports: [
        ImDataImportComponent,
        ImDashboardPage,
        ImViewImportComponent,
        ImImportPage,
        ImSeverityIconDirective,
        ImConfigPage,
    ],
    imports: [
        CoreModule,
        UiModule,
        BlockUIModule,
        ProgressSpinnerModule
    ]
})
export class ImModule implements DynamicModule {

    alias = 'im';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return ImKpiComponent;
    }

    getStatisticsComponent() {
        return ImStatisticsComponent;
    }
}
