import {Component, OnInit, SimpleChanges} from '@angular/core';
import {
  FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {PageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'cr-customer-search',
  templateUrl: './customer-search.page.html',
  styleUrls: ['./customer-search.page.scss']
})
export class CustomerSearchPage extends PageComponent implements OnInit {

  objectType: string = 'cr.Customer';
  defaultLabel: string = "Customer Search";

  ngOnInit(): void {
    super.ngOnInit();
    this.initComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {
    this.refreshComponent();
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  handleObjectSelect(event: FlexibleSearchDataEvent) {
    const customerId = event.id;
    const url = '/#/cr/customers/' + customerId;
    window.open(url, "_blank");
  }
}


