import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    NavigationItem,
     ObjectPageComponent,
} from "@kvers/alpha-core-common";
import {CalculationLogicTypeDto} from "../../dto/calculation-logic-type.dto";
import {Navigation} from "@angular/router";


@Component({
    selector: 'lg-calculation-logic-page',
    templateUrl: './lg-calculation-logic-page.page.html',
    styleUrls: ['./lg-calculation-logic-page.page.scss']
})
export class LgCalculationLogicPagePage extends ObjectPageComponent implements OnInit, OnDestroy, OnChanges {

    navigationItems: NavigationItem[];

    dto: CalculationLogicTypeDto


    ngOnInit(): void {
        super.ngOnInit();
    }

    onObjectChanged() {
        debugger
    }

    protected getObjectType(): string {
        return "lg.CalculationLogicType";
    }

    handleNavigationItems(navigationItems: NavigationItem[]): void {
        this.navigationItems = navigationItems;
        this.setActivePage();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)

        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy()

    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

}
