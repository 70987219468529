import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {
    PageComponent,
    ObjectIdentifier,
    MvsCoreService, MvsMessageService,
    ObjectTypeDto,
    UiPageUserService,
    UiPageVisitResponseDto
} from "@kvers/alpha-core-common";
import {SearchResultEntryDto} from "../../si/logic/search-result-entry.dto";
import {UmUserDto} from "../../um/dto/um-user.dto";
import {UiPageUserFavoriteService} from "../../ui/service/api/ui-page-user-favorite.service";
import {UmUserService} from "../../um/service/api/um-user.service";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {SearchResultDto} from "../../si/logic/search-result.dto";
import {UiPageUserFavoriteDto} from "../../ui/dto/ui-page-user-favorite.dto";
import {SearchResultListInterface} from "./interface/search-result-list.interface";
import {TicketGroupFilterService} from "../../tm/service/api/ticket-group-filter.service";
import {TicketGroupFilterInfoDto} from "../../tm/service/api/dto/ticket-group-filter-info.dto";
import {UiAgentAssignmentEnum} from "../../tm/component/ticket-management/data/ui-agent-assignment.enum";
import {TicketGroupFilterDto} from "../../tm/dto/ticket-group-filter.dto";


@Component({
    selector: 'mvs-home-page',
    templateUrl: './home-page.page.html',
    styleUrls: ['home-page.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomePagePage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    override defaultLabel: string = "Home Page";
    searchedResultList: SearchResultListInterface[];
    isSearched: boolean;
    maxSearchCount: number = 20;
    lastVisits: UiPageVisitResponseDto[];
    favorites: UiPageVisitResponseDto[];
    recentCustomerList: UiPageVisitResponseDto[];
    recentContractList: UiPageVisitResponseDto[];
    loggedOnUser: UmUserDto;
    ticketGroupFavoriteFilter: TicketGroupFilterInfoDto[];
    readonly objectTypeAlias: string[] = ['cr.Customer', 'cm.Contract', 'ui.UiPage', 'tm.Ticket'];
    tempAlias: string[];
    customersCount: number = 10;
    contractsCount: number = 10;
    showCustomerLoadMore: boolean = false;
    showContractLoadMore: boolean = false;
    customerBusy: boolean = false;
    contractBusy: boolean = false;

    constructor(
        protected override route: ActivatedRoute,
        protected override coreService: MvsCoreService,
        protected uiPageUserService: UiPageUserService,
        protected uiPageUserFavoriteService: UiPageUserFavoriteService,
        protected messageService: MvsMessageService,
        protected userService: UmUserService,
        private router: Router,
        protected ticketGroupFilterService: TicketGroupFilterService,
        protected navigationService: MvsObjectNavigationService) {
        super(route, coreService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.getLoggedOnUser();
        this.getLastVisits();
        this.getFavorites();
        this.getRecentCustomers();
        this.getRecentContracts();
        this.getMeFavourites();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * result returned from general search component
     * @param searchResult
     */
    onSearchResult(searchResult: SearchResultDto) {

        this.searchedResultList = Object.keys(searchResult.objectTypes).map(objectTypeId => ({
            objectType: searchResult.objectTypes[objectTypeId],
            searchResultEntries: searchResult.searchResultEntries.filter(entry => entry.objectTypeId === parseInt(objectTypeId, 10)),
            resultCountsPerObjectType: searchResult.resultCountsPerObjectType[objectTypeId]
        }));

        this.isSearched = true

    }

    /**
     * retrieve last visits
     */
    getLastVisits() {
        this.uiPageUserService.getLastVisits(10).subscribe(lastVisits => {
                this.lastVisits = lastVisits;
            }
        );
    }

    /**
     * retrieve favorites
     */
    getFavorites() {
        this.uiPageUserFavoriteService.getFavorites().subscribe(favorites => {
                this.favorites = favorites.reverse().slice(0, 10);
            }
        );
    }

    /**
     * retrieving recent customers routed
     */
    getRecentCustomers() {
        if (this.customerBusy) {
            return;
        }

        this.customerBusy = true;

        this.uiPageUserService.getLastVisitsByRoute("cr/customers/:id", this.customersCount).subscribe(value => {
            this.recentCustomerList = value;
            this.showCustomerLoadMore = this.displayLoadMore(this.recentCustomerList.length, this.customersCount);
            this.customerBusy = false;
        }, error => {
            this.customerBusy = false;
        });
    }

    /**
     * retrieving recent contracts routed
     */
    getRecentContracts() {
        if (this.contractBusy) {
            return;
        }

        this.contractBusy = true;

        this.uiPageUserService.getLastVisitsByRoute("cm/contract/:id", this.contractsCount).subscribe(value => {
            this.recentContractList = value;
            this.showContractLoadMore = this.displayLoadMore(this.recentContractList.length, this.contractsCount);
            this.contractBusy = false;
        }, error => {
            this.contractBusy = false;
        });
    }



    displayLoadMore(retrievedLength: number, total: number) : boolean {

        if (retrievedLength == 0) {
            return false;
        }
        if (retrievedLength % total == 0) {
            // limit didn't reach, we need to display load more button
            return true;
        }
            //limit reached, no need to display button
        return false;
    }

    loadMoreCustomers() {
        this.customersCount = this.customersCount + 10;
        this.getRecentCustomers();
    }

    loadMoreContracts() {
        this.contractsCount = this.contractsCount + 10;
        this.getRecentContracts();
    }

    /**
     * add page to favorites
     * @param visitedPage
     * @param event
     */
    addToFavorites(visitedPage: UiPageVisitResponseDto, event: Event) {

        event.stopPropagation();

        const uiPageUserFavoriteDto = new UiPageUserFavoriteDto();
        uiPageUserFavoriteDto.favoritePageDtoId = visitedPage.pageId;
        uiPageUserFavoriteDto.favoritePageDtoName = visitedPage.label;
        uiPageUserFavoriteDto.user = this.loggedOnUser.username;
        uiPageUserFavoriteDto.priority = 0;

        const visitedPageAlreadyExist = this.favorites.length ? this.favorites.find((favorite) => favorite.pageId === visitedPage.pageId) : false;

        if (!visitedPageAlreadyExist) {
            this.uiPageUserFavoriteService.create(uiPageUserFavoriteDto).subscribe(() => {
                this.messageService.showSuccessMessage("Success", "Added to Favorite");
                this.getFavorites();
            });
        } else {
            this.messageService.showInfoMessage("Info", "Already Exists");
        }
    }

    /**
     * remove page from favorite
     * @param id
     * @param event
     */
    removeFromFavorite(id: number, event: Event) {

        event.stopPropagation();

        this.uiPageUserFavoriteService.delete(id).subscribe(() => {
            this.messageService.showSuccessMessage('Success', 'Removed from favorite')
            this.getFavorites();
        });
    }

    /**
     * get logged on user
     */
    getLoggedOnUser() {
        this.userService.me().subscribe((user: UmUserDto) => {
            this.loggedOnUser = user;
            this.refreshComponent();
        });
    }

    /**
     * handle route for searched items
     * @param objectType
     * @param searchItem
     * @param event
     */
    handleRoute(objectType: ObjectTypeDto, searchItem: SearchResultEntryDto, event: MouseEvent) {

        const object: UiPageVisitResponseDto = new UiPageVisitResponseDto();
        object.objectTypeAlias = objectType.alias;
        object.objectId = searchItem.objectId;

        if (objectType.alias === 'ui.UiPage') {
            object.route = searchItem.dataFields['pageRoute'].originalValue;
        }

        this.onNavigation(object, event);
    }

    /**
     * navigate to respected page
     * @param object
     * @param event
     */
    onNavigation(object: UiPageVisitResponseDto, event: MouseEvent) {
        const objectType = object.objectTypeAlias;
        const objectId = object.objectId;
        const objectIdentifier: ObjectIdentifier = new ObjectIdentifier(objectType, objectId);

        if (objectType && objectId && objectType === 'tm.Ticket') {
            //this.navigationService.handleObjectNavigation(objectIdentifier, event, {location: 'right'}, object.label);

            const mvsObjectNavigationEntry =
                MvsObjectNavigationEntry.createNavigationEntry(
                    objectIdentifier.objectType,
                    objectIdentifier.objectId,
                    null,
                    "",
                    null,
                    null,
                    MvsObjectNavigationActionEnum.display
                );

            this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');


        } else if (objectType && objectId && objectType !== 'ui.UiPage') {
            this.navigationService.handleObjectNavigation(objectIdentifier, event, null, object.label);
        } else {
            this.navigationService.handlePageNavigation(object.route, event, null, object.label)
        }

    }


    showMore(alias: string) {
        this.maxSearchCount += 20;
        this.tempAlias = [];
        this.tempAlias.push(alias);
    }

    clearShowMore() {
        this.maxSearchCount = 20;
        this.tempAlias = null;
    }

    getMeFavourites() {
        this.ticketGroupFilterService.getMeFavourites(4).subscribe(res => {
           this.ticketGroupFavoriteFilter = res;

           //TODO: Bad Approach
           // store style and class information
            if (this.ticketGroupFavoriteFilter && this.ticketGroupFavoriteFilter.length > 0) {
                for (let ticketGroupFilterInfoDto of this.ticketGroupFavoriteFilter) {

                    ticketGroupFilterInfoDto.ticketGroupFilter['uiStyle'] = {};
                    ticketGroupFilterInfoDto.ticketGroupFilter['uiClass'] = [];

                    if (ticketGroupFilterInfoDto.ticketGroupFilter.rgbaColor && ticketGroupFilterInfoDto.ticketGroupFilter.rgbaColorBackground) {
                        ticketGroupFilterInfoDto.ticketGroupFilter['uiStyle'] = {
                            color : 'var(--' + ticketGroupFilterInfoDto.ticketGroupFilter.rgbaColor + ')',
                            backgroundColor : 'var(--' + ticketGroupFilterInfoDto.ticketGroupFilter.rgbaColorBackground + ')',
                        }
                    } else {
                        ticketGroupFilterInfoDto.ticketGroupFilter['uiClass'] = ['bg-primary-100', 'text-primary'];
                    }
                }
            }
        });
    }

    handleTicketBadgeCountClick(groupId: number, value: number) {
        this.router.navigate(['tm/me', groupId], {
            queryParams: { type: value },
        });
        event.stopPropagation();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    override ngOnChanges(changes: SimpleChanges) {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    override ngOnDestroy(): void {

    }

    protected readonly UiAgentAssignmentEnum = UiAgentAssignmentEnum;
}
