@if (initialized) {

    <div class="grid">

        <div class="col-12">

            <div class="flex align-items-center justify-content-between w-full">
                <!-- Left-aligned Back button (Even if hidden, space is reserved) -->
                <div class="flex-1">
                    <p-button
                            *ngIf="breadCrumb?.length && selectedObjectType && navigationHistory?.length"
                            label="Back"
                            icon="pi pi-arrow-left"
                            styleClass="p-button-link"
                            (click)="goBack()"
                            [disabled]="!backButtonEnabled || busy">
                    </p-button>
                </div>

                <!-- Right-aligned Back to Overview button (Even if hidden, space is reserved) -->
                <div class="flex-1 flex justify-content-end">
                    <p-button
                            *ngIf="metaData"
                            (click)="onBackToOverview()"
                            styleClass="p-button-link"
                            icon="fa-solid fa-backward"
                            label="Back to Overview"
                            [disabled]="busy">
                    </p-button>
                </div>
            </div>

        </div>


        <!--            <p-breadcrumb-->
        <!--                    *ngIf="breadCrumb?.length && selectedObjectType"-->
        <!--                    class="max-w-full" [model]="breadCrumb">-->
        <!--                <ng-template pTemplate="item" let-item>-->
        <!--                    <span class="{{item?.styleClass}}">-->
        <!--                        {{ item.label }}-->
        <!--                    </span>-->
        <!--                </ng-template>-->
        <!--            </p-breadcrumb>-->

        <div class="col-12" *ngIf="breadCrumb?.length">

            <!--                        <p-breadcrumb-->
            <!--                                *ngIf="breadCrumb?.length && selectedObjectType"-->
            <!--                                class="max-w-full" [model]="breadCrumb">-->
            <!--                            <ng-template pTemplate="item" let-item>-->
            <!--                                <span class="{{item?.styleClass}}">-->
            <!--                                    {{ item.label }}-->
            <!--                                </span>-->
            <!--                            </ng-template>-->
            <!--                        </p-breadcrumb>-->

            <ul class="list-none my-0 px-0">
                <li
                        *ngFor="let item of breadCrumb; let i = index"
                        (click)="changeSelectedObjectType(item.data, true)"
                        class="flex gap-1 align-items-center transition-all duration-200 {{i == breadCrumb?.length - 1 ? 'text-primary bold' : ''}}">

                    <!-- Indented Join Indicator -->
                    <span *ngIf="i != 0" [ngStyle]="{'margin-left': (i * 20) + 'px'}" class="text-gray-500 pr-2">
                            →
                        </span>

                    <!-- Entity Name -->
                    <span>
                            {{ item.label }}
                        </span>

                    <!-- Join Information -->
                    <span *ngIf="item.join" class="text-sm">
                            ({{ item.join.joinObjectTypeAlias }} → {{ item.join.name }})
                        </span>
                </li>
            </ul>

            <!--            <div class="breadcrumb-container">-->
            <!--                <ul class="breadcrumb-list">-->
            <!--                    <li *ngFor="let entity of breadCrumb; let i = index"-->
            <!--                        class="flex align-items-center whitespace-nowrap">-->

            <!--                        &lt;!&ndash; Show separator unless it's the first item &ndash;&gt;-->
            <!--                        <span *ngIf="i != 0" class="pr-2">-->
            <!--                            <i class="fa-solid fa-chevron-right"></i>-->
            <!--                        </span>-->

            <!--                        &lt;!&ndash; Entity Name &ndash;&gt;-->
            <!--                        <span [ngClass]="{'text-primary font-bold': i === breadCrumb?.length - 1}">-->
            <!--                            {{ entity.label }}-->
            <!--                        </span>-->

            <!--                        &lt;!&ndash; Join Information &ndash;&gt;-->
            <!--                        <span *ngIf="entity.join" class="text-sm text-gray-500 ml-1">-->
            <!--                            ({{ entity.join.joinObjectTypeAlias }} → {{ entity.join.name }})-->
            <!--                        </span>-->

            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </div>-->

        </div>


        <div class="col-12" *ngIf="!metaData">
            <p-listbox
                    [options]="objectTypes.entries"
                    [filter]="true"
                    filterBy="alias"
                    optionLabel="alias"
                    [multiple]="false"
                    (onChange)="onChangeObjectType($event)"
                    [listStyle]="{'max-height':'800px'}"
            >
            </p-listbox>
        </div>

        @if (activeNavigationItem.action == 'attributes') {

            <div class="col-12" *ngIf="metaData">

                <!-- Show Meta Information -->
                <p-table [value]="metaData.attributes" [tableStyle]="{ 'min-width': '50rem' }"
                         styleClass="p-datatable-sm">
                    <ng-template pTemplate="caption"> Attributes</ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <th>Data Type</th>
                            <th>Label</th>
                            <th>Mandatory</th>
                            <th>Extended</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-attribute>
                        <tr>
                            <td>{{ attribute.attributeName }}</td>
                            <td>{{ attribute.dataType }}</td>
                            <td>{{ attribute.label }}</td>
                            <td>{{ attribute.mandatory }}</td>
                            <td>{{ attribute.extended }}</td>
                        </tr>
                    </ng-template>
                </p-table>


            </div>

        }

        @if (activeNavigationItem.action == 'joins') {

            <div class="col-12" *ngIf="metaData">

                <p-table [value]="metaData.joins" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm">
                    <ng-template pTemplate="caption"><i class="fa-solid fa-link"></i> Joins</ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <th>Join Object Type</th>
                            <th>Join Attribute</th>
                            <th>Generic</th>
                            <th>Relation</th>
                            <th>Extension via Code <i class="fa-solid fa-code"></i></th>
                            <th>Extension via Config <i class="fa-solid fa-pen-to-square"></i></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-join>
                        <tr>
                            <td>{{ join.name }}</td>
                            <td>
                                <p-button styleClass="p-button-link p-0"
                                          (click)="onNavigateToJoinObjectType(join)">{{ join.joinObjectTypeAlias }}
                                </p-button>
                            </td>
                            <td>{{ join.attributeName }}</td>
                            <td>{{ join.generic }}</td>
                            <td>{{ metaDataJoinRelationEnum[join.joinRelationEnum] }} <i
                                    *ngIf="join.joinRelationEnum == 'manyToMany' || join.joinRelationEnum == 'oneToMany'"
                                    class="fa-solid fa-table"></i></td>
                            <td>{{ join.extendedService }}</td>
                            <td>{{ join.extendedConfig }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>


            <!--            <div class="col-12" *ngIf="treeNodes?.length">-->
                    <!--                <p-tree [value]="treeNodes"></p-tree>-->
                    <!--            </div>-->


        }

        @if (activeNavigationItem.action == 'joinExtension') {

            <div class="col-12">

                <!-- Show Extended Joins-->
                <p-table [value]="metaJoinExtensions.entries" [tableStyle]="{ 'min-width': '50rem' }"
                         styleClass="p-datatable-sm" *ngIf="metaJoinExtensions">
                    <ng-template pTemplate="caption">
                        <i class="fa-solid fa-link"></i> Join Extensions
                        <p-toolbar>
                            <div class="p-toolbar-group-start">
                                <p-button label="New" icon="pi pi-plus" class="mr-2"
                                          (click)="onCreateJoinExtension();"></p-button>
                            </div>
                        </p-toolbar>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Join Object Type</th>
                            <th>Join Attribute</th>
                            <th>Rel. Type</th>
                            <th>Binding</th>
                            <th>Multiple Values</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-join>
                        <tr>
                            <td>{{ join.name }}</td>
                            <td>{{ join.description }}</td>
                            <td>
                                <p-button styleClass="p-button-link p-0"
                                          (click)="onNavigateToJoinObjectType(join)">{{ join.objectRelationDto.targetObjectTypeDtoName }}
                                </p-button>
                            </td>
                            <td>{{ join.objectRelationDto.linkAttribute }}</td>
                            <td>{{ join.objectRelationDto.relationType }}</td>
                            <td>{{ join.objectRelationDto.binding }}</td>
                            <td>{{ join.multipleValues }} <i *ngIf="join.multipleValues" class="fa-solid fa-table"></i>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7">There are no extensions yet.</td>
                        </tr>
                    </ng-template>
                </p-table>


            </div>

        }

    </div>

}

