
<ng-container *ngIf="initialized">


        <!-- TOP AREA -->
        <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
                <div class="text-900 text-3xl font-medium mb-3 flex align-items-center">{{dashboardDto.name}}</div>
                <div class="text-900 text-xs font-medium mb-3 flex align-items-center">{{dashboardDto.description}}</div>
        </div>

        <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
                <div class="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
                        <span class="p-input-icon-left mr-3">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Search" style="border-radius: 20px" />
                        </span>

                        <div class="mt-3 md:mt-0">
                                <button pButton pRipple label="Anruf starten" class="p-button-outlined mr-2" icon="pi pi-user-plus"></button>
                                <button pButton pRipple label="Anruf erhalten" icon="pi pi-check"></button>
                        </div>
                </div>
        </div>

        <!-- MUTABLE AREA -->
        <mvs-mutable-area
                [id]="dashboardDto.mutableAreaDtoId"
        ></mvs-mutable-area>

</ng-container>
