import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserObjectAccess} from './interface/user-object-access.interface';
import {UserObjectAccessRoleEnum} from "./enum/user-object-access-role.enum";

@Component({
  selector: 'mvs-user-group-avatar',
  templateUrl: './mvs-user-group-avatar.component.html',
  styleUrls: ['./mvs-user-group-avatar.component.scss'],
})
export class MvsUserGroupAvatarComponent implements OnInit, OnChanges {

  initialized: boolean; // indicator whether the component was initialized

  @Input() userObjectAccess: UserObjectAccess[] = [];
  @Input() maxVisibleAvatars: number = 3;
  @Input() size: string = 'S';

  @Output() onUserSelected: EventEmitter<UserObjectAccess> = new EventEmitter();

  lockedUsers: UserObjectAccess[]
  visibleLockedUsers: UserObjectAccess[]
  otherUsers: UserObjectAccess[]
  visibleOtherUsers: UserObjectAccess[]
  remainingLockedUserCount:number;
  remainingOtherUserCount:number;

  ngOnInit(): void {
    this.refreshComponent();
    this.initialized = true;
  }

  refreshComponent(): void {

    this.sortList();

    this.lockedUsers = this.handleLockedUsers();
    this.visibleLockedUsers = this.handleVisibleLockedUsers();
    this.otherUsers = this.handleOtherUsers();
    this.visibleOtherUsers = this.handleVisibleOtherUsers();
    this.remainingLockedUserCount = this.handleRemainingLockedUserCount();
    this.remainingOtherUserCount = this.handleRemainingOtherUserCount();

  }

  /** Locked Users */
  handleLockedUsers(): UserObjectAccess[] {
    return this.userObjectAccess.filter(
      (user) => user.role === UserObjectAccessRoleEnum.LOCK
    );
  }

  handleVisibleLockedUsers(): UserObjectAccess[] {
    return this.lockedUsers.slice(0, this.maxVisibleAvatars);
  }

  handleRemainingLockedUserCount(): number {
    return Math.max(this.lockedUsers.length - this.maxVisibleAvatars, 0);
  }

  /** Other Users */
  handleOtherUsers(): UserObjectAccess[] {
    return this.userObjectAccess.filter(
      (user) => user.role !== UserObjectAccessRoleEnum.LOCK
    );
  }

  handleVisibleOtherUsers(): UserObjectAccess[] {
    return this.otherUsers.slice(0, this.maxVisibleAvatars);
  }

  handleRemainingOtherUserCount(): number {
    return Math.max(this.otherUsers.length - this.maxVisibleAvatars, 0);
  }


  handleUserSelected(user: UserObjectAccess): void {
    this.onUserSelected.emit(user)
  }

  sortList() {
    // Sorting function
    this.userObjectAccess.sort((a, b) => {
      // First, compare the roles
      const rolePriority = (role: UserObjectAccessRoleEnum) => {
        switch (role) {
          case UserObjectAccessRoleEnum.LOCK:
            return 1;
          case UserObjectAccessRoleEnum.WRITE:
            return 2;
          case UserObjectAccessRoleEnum.READ:
            return 3;
          default:
            return 4; // Handle undefined role if needed
        }
      };

      const roleComparison = rolePriority(a.role) - rolePriority(b.role);

      // If roles are the same, compare by lastAccess (newer dates first)
      if (roleComparison === 0) {
        if (a.lastAccess && b.lastAccess) {
          return b.lastAccess.getTime() - a.lastAccess.getTime();
        }
        // Handle cases where one of the lastAccess is undefined
        if (a.lastAccess) return -1;
        if (b.lastAccess) return 1;
        return 0;
      }

      return roleComparison;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }
    if (changes['userObjectAccess'] || changes['maxVisibleAvatars']) {
      this.refreshComponent();
    }
  }

}
