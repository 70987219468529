import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cd-contact-type-object-type-page',
  templateUrl: './contact-type-object-type.page.html',
  styleUrls: ['./contact-type-object-type.page.scss']
})
export class ContactTypeObjectTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  defaultLabel: string = "Zuordnung Kontakttypen zu Objekttypen";

  ngOnInit(): void {
    this.objectBrowserRequest = {

      "extractDto": false,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cd.ContactTypeObjectType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Zuordnung Kontakttypen zu Objekttypen',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"

        },
      ]

    }

    super.ngOnInit();

  }
}
