<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="lg:col-2 md:col-3 col-12 flex flex-column gap-2">

            <div class="mvs-widget h-full"> <!--  style="max-height: 42rem;" -->
                <!--                [uiStyle]="selectedRelatedPersons"-->


                <!--                <mvs-widget *ngIf="customerRelatedPersonWidget"-->
                <!--                            [widgetData]="customerRelatedPersonWidget"-->
                <!--                            [importObjectContext]="importObjectContext"-->
                <!--                            [uiStyle]="selectedRelatedPersons"-->
                <!--                            (onObjectSelect)="handleRelatedPerson($event)"-->
                <!--                >-->
                <!--                </mvs-widget>-->

<!--                (onObjectSelect)="handleRelationPersonSelect($event)"-->
                <mvs-widget *ngIf="personRelationPersonWidget"
                            [widgetData]="personRelationPersonWidget"
                            [importObjectContext]="personRelationPersonObjectContext"
                            (onMouseHover)="handleHoverPersonRelationPerson($event)">
                </mvs-widget>
<!--                [uiStyle]="selectedPersonWidgetStyle"-->

                <mvs-widget *ngIf="customerInsurableObjectsWidgetData"
                            [widgetData]="customerInsurableObjectsWidgetData"
                            [importObjectContext]="importObjectContext"
                            (onObjectSelect)="handleInsurableObjectSelect($event)"
                            (onMouseHover)="handleHoverInsurableObject($event)">
                </mvs-widget>

<!--                [uiStyle]="selectedInsurableObjectWidgetStyle"-->

            </div>

        </div>

        <div class="lg:col-8 md:col-6 col-12 flex flex-column gap-2">
            <div class="mvs-widget h-full overflow-auto">


                <div class="mvs-widget pb-0 py-3">
                    <mvs-cm-customer-assessment-component
                            [customerId]="objectIdentifier.objectId"></mvs-cm-customer-assessment-component>
                </div>


                <!--                <div class="col-12 flex" *ngIf="selectedRelatedPersons">-->

                <div class="flex align-items-center gap-2 flex-wrap my-3" *ngIf="filterCriteria.length>0">
                    <p-chip
                            *ngFor="let filter of filterCriteria"
                            [label]="filter.label"
                            [removable]="true"
                    ></p-chip>
                </div>

                <!--                </div>-->


                <mvs-widget *ngIf="customerContractWidgetData"
                            [widgetData]="customerContractWidgetData"
                            [uiStyle]="contractPersonWidgetStyle"
                            [importObjectContext]="contractObjectContext"
                            (onObjectSelect)="handleRowSelection($event,'#target')"
                            (onObjectSelectDtoList)="handleContractsDtoList($event,'#target')"
                >
                    <ng-template let-object="object" mvs-widget-custom-field-directive label="testDataField"
                                 fieldId="testDataField">
                        <customer-contract-relationships [customerContractDto]="object.dto">
                        </customer-contract-relationships>
                    </ng-template>
                </mvs-widget>

            </div>

        </div>

        <div class="lg:col-2 md:col-3 col-12 flex flex-column gap-2">
            <div class="mvs-widget h-full overflow-auto">

                <mvs-widget *ngIf="customerHouseholdPerson"
                            [widgetData]="customerHouseholdPerson"
                            [uiStyle]="contractPersonWidgetStyle"
                            [importObjectContext]="importObjectContext"
                            [widgetToolbarButtons]="widgetButtons"
                            (onToolbarButtonClick)="handleContractPersonToolbarClick($event)"
                            (onObjectSelect)="handleHouseholdPersonSelect($event)"
                >
                </mvs-widget>


            </div>
        </div>

    </div>

    <p-sidebar #bottomBar
               [style]="{'width':'95%', 'left' : '4.5%', 'border':'1px solid lightgray', 'height':'50%','min-height' : '700px'}"
               [(visible)]="contractBottomBar"
               [blockScroll]="true"
               [showCloseIcon]="true"
               [baseZIndex]="2003"
               position="bottom"
               styleClass="layout-config p-sidebar-lg fs-small p-0"
               [modal]="false">

        <div id="target" class="cr-cutomer-table-widget-bottom p-3">

            <mvs-cr-customer-contract-details
                    [selectedContract]="selectedItem"
                    [selectedCustomer]="dto"
                    [selectedRelatedPersons]="selectedRelatedPersons"
            >
            </mvs-cr-customer-contract-details>

        </div>

    </p-sidebar>

</ng-container>