import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PmPersonGender} from "../../enum/pm-person-gender.enum";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Subscription, timer} from "rxjs";
import {MvsSearchFlexibleBaseComponent} from "@kvers/alpha-ui";
import {MvsSearchFlexibleResultData} from "@kvers/alpha-ui";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PrimeNGConfig} from "primeng/api";
import {MvsConfigService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-search-flexible-customer',
    templateUrl: './cr-search-flexible-customer.component.html',
})
export class CrSearchFlexibleCustomerComponent
    extends MvsSearchFlexibleBaseComponent
    implements OnInit {

    @Input() displayInDialog: boolean;

    widgetTableViaBasicData: WidgetData;
    widgetTableViaPerson: WidgetData;
    widgetTableViaCustomer: WidgetData;
    firstName: string;
    lastName: string;
    gender: PmPersonGender
    birthName: string;
    birthDate: string;
    alias: string;
    customerId: string;
    subscription: Subscription;
    basicDataSearchForm: FormGroup;
    personDataSearchForm: FormGroup;
    customerDataSearchForm: FormGroup;
    genders = [
        {name: 'nicht definiert', value: 0},
        {name: 'Mann', value: 1},
        {name: 'Frau', value: 2},
        {name: 'Divers', value: 3}
    ]

    constructor(private fb: FormBuilder, private primengConfig: PrimeNGConfig) {
        super();
        this.basicDataSearchForm = this.fb.group({
            firstName: [''],
            lastName: [''],
            gender: ['']
        });

        this.personDataSearchForm = this.fb.group({
            birthName: [''],
            birthDate: ['']
        });

        this.customerDataSearchForm = this.fb.group({
            alias: [''],
            customerId: ['']
        });
    }

    ngOnInit() {

        this.primengConfig.setTranslation(MvsConfigService.germanConfig);
    }

    /**
     * search Via Basic Data (eg: firstName, lastName)
     */
    onSearchViaBasicData() {
        const formValues = this.basicDataSearchForm.value;

        if (!formValues.firstName && !formValues.lastName && !formValues.gender) {
            return;
        }

        const filterCriteria: FilterCriteria[] = [];

        if (formValues.firstName) {
            filterCriteria.push(
                FilterCriteria.create('firstName', FilterCriteria.cOperatorContainsPattern, '%' + formValues.firstName + '%', null)
            );
        }
        if (formValues.lastName) {
            filterCriteria.push(
                FilterCriteria.create('lastName', FilterCriteria.cOperatorContainsPattern, '%' + formValues.lastName + '%', null)
            );
        }

        if (formValues.gender) {
            filterCriteria.push(FilterCriteria.create('gender', FilterCriteria.cOperatorEqual, formValues.gender, null));
        }

        const complexSelection = ObjectRequestComplex.build(true, false,

            ObjectRequestComplexNode.createRelationNode("customerDto",
                ObjectRequestRelation.createList(
                    "cr.Customer",
                    "person",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ANY)
            )
        );

        this.onSearchAction.emit(formValues);

        this.loadTableViaBasicData(complexSelection, filterCriteria);
    }

    /**
     * search Via PersonDetails (eg: birthName, birthDate)
     */
    onSearchViaPerson() {
        const formValues = this.personDataSearchForm.value;

        if (!formValues.birthName && !formValues.birthDate) {
            return;
        }

        const filterCriteria: FilterCriteria[] = [];

        if (formValues.birthName) {
            filterCriteria.push(
                FilterCriteria.create('birthName', FilterCriteria.cOperatorContainsPattern, '%' + formValues.birthName + '%', null)
            );
        }
        if (formValues.birthDate) {
            filterCriteria.push(
                FilterCriteria.create('birthDate', FilterCriteria.cOperatorEqual, formValues.birthDate, null)
            );
        }

        const complexSelection = ObjectRequestComplex.build(true, false,

            ObjectRequestComplexNode.createRelationNode("personDto",
                ObjectRequestRelation.createList(
                    "pm.Person",
                    "personDetail",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ANY)
            ).addNodes(
                ObjectRequestComplexNode.createRelationNode("customerDto",
                    ObjectRequestRelation.createList(
                        "cr.Customer",
                        "person",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ANY)
                ))
        );

        this.onSearchAction.emit(formValues);

        this.loadTableViaPerson(complexSelection, filterCriteria);
    }

    /**
     * search Via customer (eg: alias, id)
     */
    onSearchViaCustomer() {

        const formValues = this.customerDataSearchForm.value;
        if (!formValues.alias && !formValues.customerId) {
            return;
        }

        const filterCriteria: FilterCriteria[] = [];

        if (formValues.alias) {
            filterCriteria.push(
                FilterCriteria.create('alias', FilterCriteria.cOperatorContainsPattern, '%' + formValues.alias + '%', null)
            );
        }
        if (formValues.customerId) {
            filterCriteria.push(FilterCriteria.create('id', FilterCriteria.cOperatorEqual, formValues.customerId, null));
        }

        this.onSearchAction.emit(formValues);

        this.loadTableViaCustomer(null, filterCriteria);
    }

    /**
     * method to return the widget table instance
     * @param dataProviderObject
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTable(dataProviderObject: string, complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]): WidgetData {

        const widgetData = new WidgetData();
        widgetData.idAlias = 'cr.search.flexible.customer.' + dataProviderObject;
        widgetData.name = 'Ergebnis';
        widgetData.uiComponent = 'table';
        widgetData.dataProvider = 'list';
        widgetData.dataSource = 'entity';
        widgetData.dataProviderObject = dataProviderObject;

        widgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(true, filterCriteria, [new Sorting('createdDate', false)], PagingDto.create(0, 8));
        widgetData.dataProviderListRequest.objectRequestComplex = complexSelection;
        widgetData.setParamValue("selectionMode", "rowSelect");
        widgetData.setParamValue("rowSelectionMode", "sticky");
        widgetData.setParamValue('size', 'S');

        return widgetData;
    }

    /**
     * method to load customer info using customer basic data
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaBasicData(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaBasicData = this.loadTable('pm.Person', complexSelection, filterCriteria);
    }

    /**
     * method to load customer info using person details
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaPerson(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaPerson = this.loadTable('pm.PersonDetail', complexSelection, filterCriteria);
    }


    /**
     * method to load customer info using customer alias and id
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaCustomer(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaCustomer = this.loadTable('cr.Customer', complexSelection, filterCriteria);
    }

    handleObjectSelect(event: ObjectIdentifierData) {

        let name: string = "";

        if (event.data["firstName"] && event.data["lastName"]) {
            name = event.data["firstName"] + " " + event.data["lastName"];
        } else {
            const searchFields = ["name", "personDtoName", "alias", "id"];

            for (let searchField of searchFields) {
                if (event.data[searchField]) {
                    name = event.data[searchField];
                    break;
                }
            }
        }

        const result: MvsSearchFlexibleResultData =
            MvsSearchFlexibleResultData.create(event, name);

        this.onObjectSelect.emit(result);
    }

    handlePersonObjectSelect(event: ObjectIdentifierData) {
        if (!event.data.customerDto) {
            return;
        }
        const customer = event.data.customerDto;
        const objectIdentifierData: ObjectIdentifierData = new ObjectIdentifierData('cr.Customer', customer.id, customer);
        this.handleObjectSelect(objectIdentifierData);
    }

    handlePersonDetailObjectSelect(event: ObjectIdentifierData) {
        if (!event.data.personDto.customerDto) {
            return;
        }
        const customer = event.data.personDto.customerDto;
        const objectIdentifierData: ObjectIdentifierData = new ObjectIdentifierData('cr.Customer', customer.id, customer);
        this.handleObjectSelect(objectIdentifierData);
    }

    onInputFieldDetails(caseValue: string) {
        //this.busy = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = timer(1000).subscribe(() => {

            switch (caseValue) {
                case 'basic':
                    this.onSearchViaBasicData();
                    break;
                case 'person':
                    this.onSearchViaPerson();
                    break;
                case 'customer':
                    this.onSearchViaCustomer();
                    break;
            }

            this.subscription = null;
        });
    }
}
