<div class="grid">
    <div class="col-3">
        <p-calendar
                class="max-w-full am-custom-calendar"
                [inline]="true"
                [(ngModel)]="selectedDate"
                [showWeek]="false"
                [firstDayOfWeek]="1"
                [disabledDays]="[0,6]"
                (onSelect)="navigateToSelectedDate()"
        ></p-calendar>
    </div>
    <div class="col-9">
        <div id="calendar"></div>
    </div>
</div>
