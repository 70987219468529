<ng-container *ngIf="!objectIdentifier">
    <div class="card quickview --workflowS">
        <span>Upload not possible because connected field not defined yet</span>
    </div>
</ng-container>

<ng-container *ngIf="objectIdentifier">
    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>

    <div class="grid">

            <div class="col-12" *ngIf="activityType.activityDocumentDto.activityEnum == 0">
                <mvs-dm-upload class="mvs-widget h-full"
                               [objectIdentifier]="objectIdentifier"
                               [documentTypeId]="documentTypeUploadDtoId"
                               (onDocumentUploaded)="handleDocumentUpload($event)"
                ></mvs-dm-upload>
            </div>
            <div class="col-12">
                <mvs-dm-object-documents class="mvs-widget h-full"
                                         [objectIdentifier]="objectIdentifier"
                                         [layoutVariation]="objectIdentifier.objectType"
                                         [autoRefresh]="this.activityType.refreshType"
                                         (onObjectSelectEvent)="handleDocumentAssigned($event)"
                ></mvs-dm-object-documents>
            </div>
    </div>
</ng-container>
