import {DtoDetail} from "@kvers/alpha-core-common";

export class RpReportFilterDto extends DtoDetail {

    public name : string;
    public description : string;
    public filterJson : string;
    public sourceObjectTypeDtoId : number;
    public sourceObjectTypeDtoName : string;
    public sourceObjectTypeDtoImage : string;
    public sourceObjectTypeDtoColor : string;
}