import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerDto } from "./customer.dto";
import { CustomerEntityAssignmentType } from "../enum/customer-entity-assignment-type.enum";
import {EntityStatusEnum} from "@kvers/alpha-core-common";

export class CustomerInsurableObjectDto extends DtoDetail{

    public customerDto? : CustomerDto;
    public customerDtoId : number;
    public customerDtoName : string;
    public insurableObjectDtoId : number;
    public insurableObjectDtoName : string;
    public assignmentType: CustomerEntityAssignmentType;
    public entityStatus: EntityStatusEnum;
    public startDate: Date;
    public endDate: Date;
    public plannedEndDate: Date;

}