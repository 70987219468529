import { Injectable } from '@angular/core';
import {DtoDetail, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UiSettingUserDto} from "../../dto/ui-setting-user.dto";

@Injectable({
  providedIn: 'root'
})
export class UiSettingUserService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiSettingUsers')
  }

  me(layoutJson: string): Observable<DtoDetail> {
    const dto = new UiSettingUserDto();
    dto.layoutJson = layoutJson;
    const url = `${this.apiUrl}/me`;
    return this.http.post<DtoDetail>(url, dto);
  }
}