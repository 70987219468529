import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {CustomerRelatedPersonDto} from "../../../cr/dto/customer-related-person.dto";
import {ActivatedRoute} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {CustomerRelatedPersonService} from "../../../cr/service/api/customer-related-person.service";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {ContractPersonDto} from "../../../ci/dto/contract-person.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {PmPersonDto} from "../../../cr/dto/pm-person.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {
    FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ContractDto} from "../../dto/contract.dto";
import {
    CmInsurableObjectWidgetStyle
} from "../cm-create-insurable-object-others/uiStyle/cm-insurable-object-widget-style";
import {ContractInsurableObjectDto} from "../../../ci/dto/contract-insurable-object.dto";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {ContractPersonService} from "../../../ci/service/api/contract-person.service";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {CmInsurablePersonWidgetStyle} from "./uiStyle/cm-insurable-person-widget-style";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cm-create-insurable-object-person',
    templateUrl: './cm-create-insurable-object-person.component.html',
})
export class CmCreateInsurableObjectPersonComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerId: number;
    @Input() contractId: number;
    @Input() highlightRelation: boolean;

    @Output() onObjectSelected: EventEmitter<ObjectIdentifier> = new EventEmitter<ObjectIdentifier>();

    stepItems = [];
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    activeStep: number = 1;

    selectedCustomerRelatedPersonId: number;
    selectedPersonId: number;
    contextPersonId: number;

    cmInsurableObjectWidgetStyle: CmInsurablePersonWidgetStyle;
    contractInsurableObjectList: ContractPersonDto[];

    customerRelatedPersonWidget: WidgetData;
    relatedPersonForm: WidgetData;
    contractPersonForm: WidgetData;
    personFormWidget: WidgetData;

    flexiblePersonSearchDto: PmPersonDto;

    constructor(protected contractPersonService: ContractPersonService,
                protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected configService: MvsConfigService,
                protected override observerService: ObserverService
    ) {
        super(coreService, messageService, confirmationService, observerService);

        // this component is only used to create
        this.createOnly = true;
    }


    protected getRetrieveObjectObservable(): Observable<any> {
        return null;
    }

    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        if (this.highlightRelation) {
            this.handleContractInsurablePerson();
        } else {
            this.refreshComponent();
        }
    }

    resetComponent() {

        this.selectedCustomerRelatedPersonId = undefined;
        this.selectedPersonId = undefined;
        this.customerRelatedPersonWidget = undefined;
        this.relatedPersonForm = undefined;
        this.contractPersonForm = undefined;
        this.personFormWidget = undefined;
        this.flexiblePersonSearchDto = undefined;
        this.contextPersonId = undefined;
    }

    /**
     * Retrieve import variables either from the direct variables customerId and contractId or from the ImportObjectContext.
     */
    retrieveImportVariables() {

        if (this.importObjectContext) {
            if (!this.customerId) {
                const customerEntry = this.importObjectContext.getEntry("cr.Customer");
                if (customerEntry) {
                    this.customerId = customerEntry.id;
                }
            }
            if (!this.contractId) {
                const contractEntry = this.importObjectContext.getEntry("cm.Contract");
                if (contractEntry) {
                    this.contractId = contractEntry.id;
                }
            }

            const personEntry = this.importObjectContext.getEntry("pm.Person");

            if (personEntry) {
                this.contextPersonId = personEntry.id;
            }

        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        if (this.highlightRelation) {
            this.handleContractSelect();
        }
        this.resetComponent();

        this.retrieveImportVariables();

        this.stepItems = [
            {
                label: 'Überprüfen, ob vorhanden', id: 'check'   // Check if exist
            },
            {
                label: 'Person suchen', id: 'search'  // Select Person
            },
            {
                label: 'Person erstellen', id: 'create'  // Create New Person
            },
            {
                label: 'Kunden zuweisen', id: 'assignCustomer'  // Create Customer Relation
            }
        ];

        // add additional step if a contract was passed!
        if (this.contractId) {
            this.stepItems.push({label: 'Vertrag zuweisen', id: 'assignContract'}); // Create Contract Assignment
        }

        // check for special processing
        if (this.contextPersonId && this.contractId) {
            this.selectedPersonId = this.contextPersonId;
            this.gotoStep(4);
        } else {
            this.gotoStep(0);
        }

        this.initialized = true;


    }


    /**
     * Initialize Step 1)
     * Check whether Customer Related Person already exists.
     */
    initStep0() {

        this.customerRelatedPersonWidget = WidgetFactory.createWidgetList(
            'cm.create.customer.related.persons.basic.customer.related.persons',
            'Personen im Zusammenhang mit Kunden',
            'list',
            'list',
            'entity',
            'cr.CustomerRelatedPerson',
            'Keine Personen vorhanden',
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.customerId)],
                [new Sorting("createdDate", true)]
            )
        );

    }

    /**
     * A related person was selected, therefore we need to decide how to continue.
     */
    handleStep0RelatedPersonSelect(objectIdentifier: ObjectIdentifierData) {

        if (this.contractId) {
            // create the contract assignment
            const customerRelatedPerson = <CustomerRelatedPersonDto>objectIdentifier.data;
            this.selectedPersonId = customerRelatedPerson.personDtoId;
            this.gotoStep(4);
        } else {
            // we are finished as the related person actually exists!
            this.personCreationFinished(new ObjectIdentifier("cr.CustomerRelatedPerson", objectIdentifier.objectId));
        }

    }

    gotoStep(step: number) {

        switch (step) {
            case 0:             // search customer persons
                this.initStep0();

                break;
            case 1:             // search persons
                this.initStep1();

                break;
            case 2:             // create person
                this.initStep2();
                break;

            case 3:             // create customer - person assignment
                this.initStep3();
                break;

            case 4:             // create customer - person assignment
                this.initStep4();
                break;

        }
        this.activeStep = step;

    }

    /**
     * Initialize Step 2)
     * Within this step the user needs to search for a person.
     */
    initStep1() {
        // no initialization required
    }


    /**
     * User has selected a person.
     * @param event
     */
    handlePersonSelectStep1(event: FlexibleSearchDataEvent) {
        this.selectedPersonId = event.id;
    }

    /**
     * Continue with step 3.
     */
    handleContinueWithSelectedPersonStep1() {
        if (this.selectedPersonId) {
            this.gotoStep(3);
        }
    }

    /**
     * Store search result.
     * @param event
     */
    handlePersonSearchStep1(event: DtoDetail) {
        this.flexiblePersonSearchDto = <PmPersonDto>event;
    }

    /**
     * User requests to create a new person.
     */
    handleCreateNewPersonStep1() {

        // only allow to move to the next step, if user actually searched
        if (!this.flexiblePersonSearchDto) {
            this.confirmationService.confirm({
                header: 'Alert',
                message: 'Bitte suchen Sie nach der Person, bevor Sie eine neue Person erstellen!',
                acceptLabel: 'Okay',
                rejectVisible: false, // Hide the cancel button
                accept: () => {
                }
            });

            return;
        }

        this.gotoStep(2);

    }


    /**
     * Step 3) Create a new person.
     */
    initStep2() {

        this.personFormWidget = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.customer.related.person.object.create.person.form.widget",
            "Person erstellen",
            "pm.Person",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    return {dto: this.flexiblePersonSearchDto};
                }
            }
        );

    }

    /**
     * Person was successfully created so we can move to the next step.
     * @param objectChangeInformation
     */
    handlePersonCreateStep2(objectChangeInformation: ObjectChangeInformation) {
        this.selectedPersonId = objectChangeInformation.after.id;
        this.gotoStep(3);
    }

    initStep3() {

        this.relatedPersonForm = new WidgetData();
        this.relatedPersonForm.idAlias = "cm.create.customer.related.person.object.create.form.widget";
        this.relatedPersonForm.name = "Zuweisung Kunde";
        this.relatedPersonForm.uiComponent = "object";
        this.relatedPersonForm.dataProvider = "list";
        this.relatedPersonForm.dataSource = "entity";
        this.relatedPersonForm.dataProviderObject = "cr.CustomerRelatedPerson";

        this.relatedPersonForm.functionCallbacks = {
            onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new CustomerRelatedPersonDto();
                dto.customerDtoId = this.customerId;
                dto.personDtoId = this.selectedPersonId;
                dto.startDate = new Date();

                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);
                formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let widgetToolbarCallbackInterface: WidgetToolbarCallbackInterface;
                widgetToolbarCallbackInterface = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return widgetToolbarCallbackInterface;
            }
        };

    }

    handleCreateRelatedPersonStep3(objectChangeInformation: ObjectChangeInformation) {

        if (this.contractId) {
            this.gotoStep(4);
        } else {
            this.personCreationFinished(new ObjectIdentifier("cr.CustomerRelatedPerson", objectChangeInformation.after.id));
        }

    }

    handleCreateContractPersonStep4(objectChangeInformation: ObjectChangeInformation) {
        this.personCreationFinished(new ObjectIdentifier("ci.ContractPerson", objectChangeInformation.after.id));
        this.handleContractInsurablePerson();
    }

    personCreationFinished(objectIdentifier: ObjectIdentifier) {
        this.onObjectSelected.emit(objectIdentifier);
    }


    initStep4() {

        this.contractPersonForm = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.customer.related.person.contract.form.widget",
            "Zuweisung Vertrag",
            "ci.ContractPerson",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new ContractPersonDto();
                    dto.personDtoId = this.selectedPersonId;
                    dto.contractDtoId = this.contractId;
                    dto.startDate = new Date();

                    const formControlOverwrite = new MvsFormControlOverwrite();
                    formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);
                    formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);

                    return {
                        dto: dto,
                        formControlOverwrite: formControlOverwrite
                    };

                }
            }
        );

    }


    handleContractInsurablePerson() {

        this.resetComponent();
        this.retrieveImportVariables();
        const filterCriteria = [FilterCriteria.create('contract', FilterCriteria.cOperatorEqual, this.contractId)];

        const complexSelection =
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode('person').addNodes(
                    ObjectRequestComplexNode.createRelationNode("relatedPerson",
                        ObjectRequestRelation.createJoin(
                            "cr.CustomerRelatedPerson#person",)
                    )
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            filterCriteria,
            null,
            null,
            null);


        this.contractPersonService.list(objectRequestList).subscribe(res => {
            this.contractInsurableObjectList = res.entries;
            this.refreshComponent();
        })
    }

    handleContractSelect() {

        if (!this.cmInsurableObjectWidgetStyle) {
            this.cmInsurableObjectWidgetStyle = new CmInsurablePersonWidgetStyle(this.configService, this.contractInsurableObjectList);
        }

        this.cmInsurableObjectWidgetStyle.addSelectedPartner(this.contractInsurableObjectList);
        this.cmInsurableObjectWidgetStyle = this.cmInsurableObjectWidgetStyle.clone();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"] || changes["contractId"] || changes["customerId"]) {
            this.initComponent();
        }

        if (changes["importObjectContext"]) {
            this.contractId = null;
            this.customerId = null;
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
