
<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12">
            <!-- show all customers created sort by descending -->
            <mvs-widget
                    *ngIf="createdCustomersWidgetData"
                    [widgetData]="createdCustomersWidgetData"
                    (onObjectSelect)="onCustomerSelect($event)"
            ></mvs-widget>
        </div>
    </div>

</ng-container>
