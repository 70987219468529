import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {NsNotificationService} from "../../service/api/ns-notification.service";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {NsNotificationTypesModeEnum} from "./enums/ns-notification-types-mode.enum";
import {NsNotificationRequestDto} from "../../dto/service/ns-notification-request.dto";
import {NsNotificationObjectReferenceDto} from "../../dto/service/ns-notification-object-reference.dto";
import {NsNotificationTypesOverviewDto} from "../../dto/service/ns-notification-types-overview.dto";
import {NsNotificationTypeOverviewDto} from "../../dto/service/ns-notification-type-overview.dto";


/**
 * This component is used to show the notification types assigned either to a recipient or an object.
 * A recipient might be a customer or a partner while an object could be a contract.
 *
 * The available notifications types for a recipient are retrieved in a different way then the notification
 * types for an object.
 *
 */
@Component({
    selector: 'mvs-ns-notification-types-for-object',
    templateUrl: './ns-notification-types-for-object.component.html',
    styleUrls: ['./ns-notification-types-for-object.component.scss']
})
export class NsNotificationTypesForObjectComponent implements OnInit, OnChanges, OnDestroy {

    @Input() mode: NsNotificationTypesModeEnum = NsNotificationTypesModeEnum.recipient;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() notificationTypeDtoId: number;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    notificationTypesOverview: NsNotificationTypesOverviewDto;

    selectedNotificationType: NsNotificationTypeOverviewDto;
    showSendNotification: boolean;

    constructor(
        protected notificationService: NsNotificationService,
        protected messageService: MvsMessageService,

        ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }




    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.busy = true;
        this.notificationTypesOverview = undefined;

        // default mode is recipient
        this.notificationService.getNotificationTypesInfo(this.getRequest()).subscribe(value => {

            this.notificationTypesOverview = value;

            this.uiRefresh();
        }, error => {
            this.busy = false;
        });

    }

    /**
     * Refresh the UI.
     */
    uiRefresh() {

        this.initialized = true;
        this.busy = false;
    }

    onDialogVisibleChange(event: any) {
        if (!event) {
            // reload the component
            this.refreshComponent();
        }
    }

    handleClickNotification(item: NsNotificationTypeOverviewDto) {

        this.selectedNotificationType = item;
        this.showSendNotification = true;

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    /**
     * Derive request based on provided parameters.
     * @protected
     */
    protected getRequest() : NsNotificationRequestDto {

        let recipient: NsNotificationObjectReferenceDto | null = null;
        let notificationObject: NsNotificationObjectReferenceDto | null = null;

        if (this.mode == NsNotificationTypesModeEnum.recipient) {
            recipient = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        } else {
            notificationObject = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        }

        return {
            objectBased: (this.mode ==  NsNotificationTypesModeEnum.object),
            referenceObject : notificationObject,
            referenceRecipient: recipient
        };
    }



}
