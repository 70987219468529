<ng-container *ngIf="initialized">

    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>

    <ng-container *ngIf="activeItem.id == 'pm.Person'">
        <mvs-widget
                *ngIf="widgetPerson"
                [widgetData]="widgetPerson"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeItem.id == 'pm.PersonAddress'">
        <mvs-widget
                *ngIf="widgetPersonAddress"
                [widgetData]="widgetPersonAddress"
                [importObjectContext]="contextPerson"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeItem.id == 'pm.PersonContact'">
        <mvs-widget
                *ngIf="widgetPersonContact"
                [widgetData]="widgetPersonContact"
                [importObjectContext]="contextPerson"
        ></mvs-widget>
    </ng-container>


</ng-container>