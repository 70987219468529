import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'tm-ticket-crete-new-task',
    templateUrl: './tm-ticket-create-new-task.component.html',
    styleUrls: ['./tm-ticket-create-new-task.component.scss']
})
export class TmTicketCreateNewTaskComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Output() changeMode: EventEmitter<string> = new EventEmitter<string>();
    objectType: string = 'cr.Customer';

    constructor() {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    handleBackToTicket() {
        this.changeMode.emit('show-ticket')
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
