import {DmDocumentAssignmentTypeEnum} from "../enum/dm-document-assignment-type.enum";
import {DtoDetail} from "@kvers/alpha-core-common";
import {DmEntityStatusEnum} from "../enum/dm-entity-status.enum";
import {DmDmDocumentEndUserVisibility} from "../enum/dm-document-end-user-visibility.enum";

export class DmDocumentAssignmentDto extends DtoDetail {

    public documentDtoId : number;
    public documentDtoName : string;
    public documentDtoImage : string;
    public documentDtoColor : string;
    public documentDtoColorBackground : string;
    public entityStatus : DmEntityStatusEnum;
    public documentAssignmentType : DmDocumentAssignmentTypeEnum;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectTypeDtoImage : string;
    public objectTypeDtoColor : string;
    public objectTypeDtoColorBackground : string;
    public objectId : number;
    public euHide : Boolean;
    public euVisibility : DmDmDocumentEndUserVisibility;
}