import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import { basicSetup} from "codemirror";
import { EditorView } from '@codemirror/view';
import {html} from "@codemirror/lang-html";
import {EditorState, Extension} from "@codemirror/state";

@Component({
    selector: 'code-mirror-editor',
    templateUrl: './code-mirror-editor.component.html',
    styleUrls: ['./code-mirror-editor.component.scss']
})
export class CodeMirrorEditorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    @Input() editorContent: string;
    @Output() contentChanged = new EventEmitter<string>();

    // @ViewChild('codeMirrorHost') private codeMirrorHost: ElementRef | undefined;
    @ViewChild('editor') myEditor: any;

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    ngAfterViewInit(): void {
        let myEditorElement = this.myEditor.nativeElement;
        let myExt: Extension = [basicSetup, html()];
        let state!: EditorState;

        try {
            state = EditorState.create({
                doc: this.editorContent,
                extensions: [
                    myExt,
                    EditorView.updateListener.of(update => {
                        if (update.docChanged) {
                            this.contentChanged.emit(update.state.doc.toString());
                        }
                    })
                ],
            });
        } catch (e) {
            console.error(e);
        }

        let view = new EditorView({
            state,
            parent: myEditorElement,
        });
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["editorContent"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
