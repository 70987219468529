<div class="bg-white mt-5" *ngIf="initialized">

    <mvs-config-header header="Lead Konfiguration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView>
        <p-tabPanel *ngFor="let managedEntity of getFilteredManagedEntities()" [header]="managedEntity.label">
            <mvs-widget
                    *ngIf="managedEntity.widgetData"
                    [widgetData]="managedEntity.widgetData">
            </mvs-widget>
        </p-tabPanel>
    </p-tabView>

</div>
