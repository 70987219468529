import {Component, OnChanges, OnInit} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {NsNotificationDto} from "../../dto/ns-notification.dto";
import {NsNotificationTypeChannelTypeService} from "../../service/api/ns-notification-type-channel-type.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {NsNotificationTypeChannelTypeDto} from "../../dto/ns-notification-type-channel-type.dto";
import {CoChannelTypeService} from "../../../co/service/api/co-channel-type.service";
import {CoChannelTypeDto} from "../../../co/dto/co-channel-type.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TeTemplateGroupService} from "../../../te/service/api/te-template-group.service";
import {TeTemplateGroupDto} from "../../../te/dto/te-template-group.dto";
import {TeTemplateService} from "../../../te/service/api/te-template.service";
import {TeTemplateDto} from "../../../te/dto/te-template.dto";
import {TeContentTypeEnum} from "../../../te/enum/te-content-type.enum";
import {TeEntityStatusEnum} from "../../../te/enum/te-entity-status.enum";
import {Dialog} from "primeng/dialog";
import {Sorting} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ns-notification-type-object',
    templateUrl: './ns-notification-type-object.component.html',
    styleUrls: ['./ns-notification-type-object.component.scss']
})
export class NsNotificationTypeObjectComponent
    extends ObjectBaseComponent
    implements OnInit, OnChanges {
    dto: NsNotificationDto;
    notificationTypeChannelTypeList: NsNotificationTypeChannelTypeDto[];
    channelTypeList: CoChannelTypeDto[];
    channelsList: CoChannelTypeDto[];
    templateGroupList: TeTemplateGroupDto[];
    selectedChannel: CoChannelTypeDto;
    selectedTemplateGroup: TeTemplateGroupDto;
    subjectOptions: any[] = [{label: 'No', value: false}, {label: 'Yes', value: true}];
    selectedSubject: boolean = false;
    widgetNotificationTypeChannelType: WidgetData;
    widgetTemplate: WidgetData;
    widgetSubjectTemplate: WidgetData;

    widgetNotificationType: WidgetData;

    widgetFields: WidgetData;
    widgetTemplateFieldBinding: WidgetData;
    widgetContactType: WidgetData;
    widgetDocumentType: WidgetData;

    contextNotificationType: DtoImportObjectContext;
    contextNotificationTypeChannel: DtoImportObjectContext;

    visible: boolean;
    selectedIndex: number = 0;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected templateService: TeTemplateService,
        protected templateGroupService: TeTemplateGroupService,
        protected navigationService: MvsObjectNavigationService,
        protected channelTypeService: CoChannelTypeService,
        protected notificationTypeChannelTypeService: NsNotificationTypeChannelTypeService,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * retrieve object
     * @protected
     */
    protected retrieveObject() {

        // needs to be implemented within root component
        this.busy = true;

        this.getRetrieveObjectObservable().subscribe(dto => {

            this.dto = dto;
            this.onObjectChanged();
            this.onObjectChangedPost();
            this.refreshLists();

        }, error => {
            this.postRefreshObjectError(error);
        });
    }


    /**
     * gets data from API
     */
    refreshLists() {


        this.widgetNotificationType = WidgetFactory.createWidgetEntityData(
            "ns.notification.type.object.type.basic",
            "Basisdaten",
            "ns.NsNotificationType",
            this.dto.id
        );


        // create import object context based on the currently processed notification type
        this.contextNotificationType = DtoImportObjectContext.createFromObjectIdentifier(this.objectIdentifier);

        const filterCriteria: FilterCriteria = FilterCriteria.create('notificationType', FilterCriteria.cOperatorEqual, this.dto.id);
        const objectRequestList: ObjectRequestList = new ObjectRequestList(false, [filterCriteria], [new Sorting('createdDate', true)]);

        this.notificationTypeChannelTypeService.list(objectRequestList).subscribe(res => {
            this.notificationTypeChannelTypeList = <NsNotificationTypeChannelTypeDto[]>res.entries;

            const objectRequestList: ObjectRequestList = new ObjectRequestList(false, null, null)
            this.channelTypeService.list(objectRequestList).subscribe(res => {
                this.channelTypeList = <CoChannelTypeDto[]>res.entries;

                this.channelsList = [];

                for (const channelType of this.channelTypeList) {
                    // Check if the current channelTypeDtoId is not present in this.notificationTypeChannelTypeList
                    const isNotPresent = !this.notificationTypeChannelTypeList.find(
                        item => item.channelTypeDtoId === channelType.id
                    );

                    // If not present, add it to this.channelsList
                    if (isNotPresent) {
                        this.channelsList.push(channelType);
                    }
                }
            })

            if (this.notificationTypeChannelTypeList && this.notificationTypeChannelTypeList.length) {
                this.getNotificationChannelId();
            }
        });

        // 01/2024 Show Notification Type Fields
        this.widgetFields = WidgetFactory.createWidgetListEntity(
            "ns.notification.type.object.fields",
            "Felder",
            "ns.NsNotificationTypeField",
            "Keine Felder vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create('type', FilterCriteria.cOperatorEqual, this.dto.id)], [])
        );





    }

    /**
     * update the widgets based on selected notification channel
     * @param type
     */
    onNotificationChannelSelect(type: NsNotificationTypeChannelTypeDto) {

        //********************************************//
        // set the router with selected notification type channel type id
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {'tid-action': type.id},
            queryParamsHandling: 'merge',
        });

        //********************************************//
        // ns-notification.type.channel.type Basic Data

        this.widgetNotificationTypeChannelType = null;
        this.widgetNotificationTypeChannelType = new WidgetData();
        this.widgetNotificationTypeChannelType.idAlias = "ns-notification.type.channel.type.object.widget";
        this.widgetNotificationTypeChannelType.name = "Basic Data";
        this.widgetNotificationTypeChannelType.uiComponent = "data";
        this.widgetNotificationTypeChannelType.dataProvider = "list";
        this.widgetNotificationTypeChannelType.dataSource = "entity";
        this.widgetNotificationTypeChannelType.dataProviderObject = "ns.NsNotificationTypeChannelType";
        this.widgetNotificationTypeChannelType.setParamValue("size", "S");
        this.widgetNotificationTypeChannelType.setParamValue("objectId", type.id);


        //********************************************//
        // template.subject
        this.widgetSubjectTemplate = null;
        if (type.subjectTemplateDtoId) {
            this.widgetSubjectTemplate = new WidgetData();
            this.widgetSubjectTemplate.idAlias = "ns-notification.type.channel.type.template.subject.object.widget";
            this.widgetSubjectTemplate.name = "Template Subject";
            this.widgetSubjectTemplate.uiComponent = "data";
            this.widgetSubjectTemplate.dataProvider = "list";
            this.widgetSubjectTemplate.dataSource = "entity";
            this.widgetSubjectTemplate.dataProviderObject = "te.TeTemplate";
            this.widgetSubjectTemplate.setParamValue("size", "S");
            this.widgetSubjectTemplate.setParamValue("objectId", type.subjectTemplateDtoId);
        }

        //********************************************//
        // template

        this.widgetTemplate = null;
        this.widgetTemplate = new WidgetData();
        this.widgetTemplate.idAlias = "ns-notification.type.channel.type.template.object.widget";
        this.widgetTemplate.name = "Template Body";
        this.widgetTemplate.uiComponent = "data";
        this.widgetTemplate.dataProvider = "list";
        this.widgetTemplate.dataSource = "entity";
        this.widgetTemplate.dataProviderObject = "te.TeTemplate";
        this.widgetTemplate.setParamValue("size", "S");
        this.widgetTemplate.setParamValue("objectId", type.templateDtoId);


        // 01/2024 added variable assignment
        this.contextNotificationTypeChannel = DtoImportObjectContext.createFromObjectIdentifier(new ObjectIdentifier("ns.NsNotificationTypeChannelType", type.id));

        this.widgetTemplateFieldBinding = WidgetFactory.createWidgetListEntity(
            "ns.notification.type.object.channel.type.variable",
            "Mapping",
            "ns.NsNotificationTypeChannelTypeTeVariable",
            "Kein Mapping vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create('notificationTypeChannelType', FilterCriteria.cOperatorEqual, type.id)], [])
        );


        // 02/2024 added contact type limitation
        this.widgetContactType = WidgetFactory.createWidgetListEntity(
            "ns.notification.type.object.channel.type.contact.type",
            "Kontakttypen Einschränken",
            "ns.NsNotificationTypeChannelTypeContactType",
            "Keine Einschränkung vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create('notificationTypeChannelType', FilterCriteria.cOperatorEqual, type.id)], [])
        );


        // 02/2024 added attachments
        this.widgetDocumentType = WidgetFactory.createWidgetListEntity(
            "ns.notification.type.object.channel.type.attachment",
            "Dokument - Anlagen",
            "ns.NsNotificationTypeChannelTypeDocument",
            "Keine Anlagen vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create('notificationTypeChannelType', FilterCriteria.cOperatorEqual, type.id)], [])
        );


    }

    /**
     * open up dialog box
     */
    showDialog() {

        this.visible = true;

        const objectRequestList: ObjectRequestList = new ObjectRequestList(false, null, null);

        if (!this.templateGroupList) {
            this.templateGroupService.list(objectRequestList).subscribe(res => {
                this.templateGroupList = <TeTemplateGroupDto[]>res.entries;
            })
        }


    }

    // create() {
    //
    //     if (!this.selectedTemplateGroup) {
    //     return;
    // }
    //
    //     const notificationTypeChannelType = new NsNotificationTypeChannelTypeDto();
    //     notificationTypeChannelType.notificationTypeDtoId = this.dto.id;
    //     notificationTypeChannelType.channelTypeDtoId = this.selectedChannel.id;
    //
    //     const teTemplateDto: TeTemplateDto = new TeTemplateDto();
    //     teTemplateDto.templateGroupDtoId = this.selectedTemplateGroup.id;
    //     teTemplateDto.contentTypeEnum = TeContentTypeEnum.html;
    //     teTemplateDto.entityStatus = TeEntityStatusEnum.active;
    //     teTemplateDto.name = this.dto.notificationTypeDtoName + ' ( ' + this.selectedChannel.name + '-body' + ' )';
    //
    //     this.templateService.create(teTemplateDto).subscribe(res => {
    //         this.templateDto = <TeTemplateDto>res;
    //
    //         notificationTypeChannelType.templateDtoId = this.templateDto.id;
    //         if (this.selectedSubject) {
    //             teTemplateDto.name = this.dto.notificationTypeDtoName + '(' + this.selectedChannel.name + 'subject' + ')';
    //             this.templateService.create(teTemplateDto).subscribe(res => {
    //                 this.subjectTemplateDto = <TeTemplateDto>res;
    //                 notificationTypeChannelType.subjectTemplateDtoId = this.subjectTemplateDto.id;
    //                 this.notificationTypeChannelTypeService.create(notificationTypeChannelType).subscribe(res => {
    //                     debugger
    //                     this.retrieveObject();
    //                 })
    //             })
    //         } else {
    //             this.notificationTypeChannelTypeService.create(notificationTypeChannelType).subscribe(res => {
    //                 debugger
    //                 this.retrieveObject();
    //             })
    //         }
    //     })
    // }

    /**
     * create new notificationTypeChannelType based on selection from dialog box
     * @param pDialog
     * @param event
     */
    async create(pDialog: Dialog, event: Event) {


        if (!this.selectedChannel) {
            return;
        }

        if (!this.selectedTemplateGroup) {
            return;
        }

        const notificationTypeChannelType = this.createNotificationTypeChannelType();

        try {
            const templateDto = await this.createTemplate('body');
            notificationTypeChannelType.templateDtoId = templateDto.id;

            if (this.selectedSubject) {
                const subjectTemplateDto = await this.createTemplate('subject');
                notificationTypeChannelType.subjectTemplateDtoId = subjectTemplateDto.id;
            }

            await this.notificationTypeChannelTypeService.create(notificationTypeChannelType).toPromise();
            this.messageService.showSuccessMessage('Notification Type Channel Type', 'Notification Type Channel Type Created');
            pDialog.close(event)
            this.selectedChannel = undefined;
            this.selectedTemplateGroup = undefined;
            this.retrieveObject();
        } catch (error) {
            console.error('Error creating notification:', error);
            // Handle error as needed
        }
    }

    private createNotificationTypeChannelType(): NsNotificationTypeChannelTypeDto {
        const notificationTypeChannelType = new NsNotificationTypeChannelTypeDto();
        notificationTypeChannelType.notificationTypeDtoId = this.dto.id;
        notificationTypeChannelType.channelTypeDtoId = this.selectedChannel.id;
        return notificationTypeChannelType;
    }

    private async createTemplate(type: string): Promise<TeTemplateDto> {
        const teTemplateDto: TeTemplateDto = new TeTemplateDto();
        teTemplateDto.templateGroupDtoId = this.selectedTemplateGroup.id;
        teTemplateDto.contentTypeEnum = TeContentTypeEnum.html;
        teTemplateDto.entityStatus = TeEntityStatusEnum.active;
        teTemplateDto.name = `${this.dto['name']} (${this.selectedChannel.name}-${type})`;

        const templateDto = await this.templateService.create(teTemplateDto).toPromise();
        return templateDto as TeTemplateDto;
    }

    /**
     * navigate to template component based on selected template id
     * @param event
     */
    navigateToTemplate(event: ObjectIdentifierData) {
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry('te.TeTemplate', event['id'], "object", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    /**
     * get tid-action from route and based on it selects the notification channel
     */
    getNotificationChannelId() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const selectedChannel = Number(params['tid-action']);
            if (!selectedChannel) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex = this.notificationTypeChannelTypeList.findIndex(item => item.id == selectedChannel);
                if (this.selectedIndex == -1) {
                    this.selectedIndex = 0;
                }
            }
            this.onNotificationChannelSelect(this.notificationTypeChannelTypeList[this.selectedIndex]);
        });
    }

}
