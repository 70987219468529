import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FilterCriteria, MvsCoreService, ObjectRequestList, ObjectTypeService} from "@kvers/alpha-core-common";
import {UmUserDto} from "../../../../um/dto/um-user.dto";
import {UserService} from "../../../../um/service/api/user.service";
import {UmUserService} from "../../../../um/service/api/um-user.service";
import {UserProfileHelper} from "../../../../um/components/user-profile-id/helper/user-profile-helper";
import {UserObjectAccess} from "../interface/user-object-access.interface";

@Component({
  selector: 'mvs-avatar',
  templateUrl: './mvs-avatar.component.html',
  styleUrls: ['./mvs-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MvsAvatarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() imageSrc?: string;
  // @Input() userName: string;
  @Input() activeUser: boolean;
  @Input() size: string = 'S';
  // @Input() userId: number;
  @Input() user: UserObjectAccess;
  userInfo: UmUserDto;
  initialized: boolean;

  constructor(protected coreService: MvsCoreService, protected userService: UserService, protected umUserService : UmUserService) {
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {

    this.userService.get(this.user.id).subscribe((res: UmUserDto) => {
      this.userInfo = res;
      this.user.name = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
      this.getUserInfo();
    });
  }

  getUserInfo() {

    this.umUserService.getPictureBinaryById(this.user.id).subscribe(res => {
      UserProfileHelper.getImageSrcFromBlob(res).then(imageSrc => {
        this.imageSrc = imageSrc;
        this.initialized = true;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {

  }


  getInitials(name: string): string {
    if (!name) {
      return '';
    }
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part.charAt(0)).join('');
    return initials.length > 2 ? initials.slice(0, 2) : initials; // Limit to 2 initials
  }

  ngOnDestroy() {
  }

}
