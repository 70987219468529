<ng-container *ngIf="initialized">
    <div class="">
        <div *ngIf="!insurableObjectType">
            <div class="mt-5 text-center">
                <p-badge value="1" styleClass="bg-primary"></p-badge>
            <p class="bold mt-2">Select Type</p>
            </div>


            <div class="w-full flex justify-content-center gap-4 mt-7">
            <ng-container *ngFor="let category of insurableObjectOptions">
        <div class="w-3">
                <div class="w-full border-round-md p-4 shadow-1" [ngClass]="insurableObjectType == category.name ?  'border-2 border-primary' : 'border-300 border-1'">
               <div class="flex justify-content-between mb-3">
                   <p-button styleClass="p-button-text"
                             [icon]="category.icon"
                             [styleClass]="category.name == 'Person' ? 'bg-blue-300' : 'bg-teal-300'"
                   ></p-button>
                   <p-radioButton [inputId]="category.key" name="category" [value]="category.name" [(ngModel)]="insurableObjectType"></p-radioButton>
               </div>
               <h3 class="my-2 bold">{{ category.name }}</h3>

           </div>
                <p-button (click)="onForceCreate(category.name)" [label]="category.buttonText"
                          styleClass="p-button-text mt-3 pointer" icon="fa-regular fa-circle-plus"></p-button>
        </div> </ng-container>
        </div>
        </div>


        <ng-container *ngIf="insurableObjectType == 'Person' ">
            <cm-create-insurable-object-person [customerId]="customerId"
                                               [forceCreate]="forceCreate"
                                               (onChangedObject)="handleChangedObject($event)"
                                               (startStepValueEmitter)="stepValue($event)">

            </cm-create-insurable-object-person>
        </ng-container>

        <ng-container *ngIf="insurableObjectType == 'Others' ">
            <cm-create-insurable-object-others [customerId]="customerId"
                                               [forceCreate]="forceCreate"
                                               (onChangedObject)="handleChangedObject($event)"
                                               (startStepValueEmitter)="stepValue($event)">

            </cm-create-insurable-object-others>

        </ng-container>

    </div>

</ng-container>