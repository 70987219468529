import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {PageComponent} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";

@Component({
    selector: 'mvs-wf-test-page',
    templateUrl: './wf-process-start-process.page.html',
    styleUrls: ['./wf-process-start-process.page.scss']
})
export class WfProcessStartProcessPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    routeParamProcessGroupId: number;
    routeParamProcessTypeId: number;

    routeParamSubscription: Subscription;

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.refreshComponent();

        this.routeParamSubscription = this.route.queryParams.subscribe(value => {
            if (value["group"]) {
                this.routeParamProcessGroupId = value["group"];
            }
            if (value["type"]) {
                this.routeParamProcessTypeId = value["type"];
            }
        });


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }

    }

    onProcessStarted(processDto: WfProcessDto) {

        const processId = processDto.id;
        const url = '/#/wf/process/' + processId;
        window.open(url, "_self");

    }

}
