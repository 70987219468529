import { Component } from '@angular/core';

@Component({
  selector: 'mvs-dm-document-dashboard',
  templateUrl: './dm-document-dashboard.component.html',
  styleUrls: ['./dm-document-dashboard.component.scss']
})
export class DmDocumentDashboardComponent {

}
