import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ProvisionContractSimulateComponent} from "../../provision-contract-simulate.component";

@Component({
    selector: 'provision-contract-simulate-side',
    templateUrl: 'provision-contract-simulate-side.component.html',
    styleUrls: ['provision-contract-simulate-side.component.scss']
})
export class ProvisionContractSimulateSideComponent extends ProvisionContractSimulateComponent implements OnInit, OnChanges, OnDestroy {


    ngOnInit(): void {
        super.ngOnInit();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
