import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cr-customer-form-type-page',
  templateUrl: './customer-form-type.page.html',
  styleUrls: ['./customer-form-type.page.scss']
})
export class CustomerFormTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Customer Form Config";


  ngOnInit(): void {

    super.ngOnInit();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerFormGroup',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Customer Form Groups",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Group"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerFormType',
          'level'             : 1,
          'parentBinding'     : 'none',
          //'processRecursion'  : true,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Customer Form Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Type"
        }
      ]
    };

    this.subObjectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerFormType',
          'level'             : 1,
          'parentBinding'     : 'none'
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerFormTypeField',
          'level'             : 2,
          'parentBinding'     : 'mandatory',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Fields",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Field"
        }
      ]
    };

  }
}
