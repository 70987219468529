@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
    <date-selection-dropdown
            [defaultOption]="'thisMonth'"
            (selectedDateStart)="handlePeriodFilter($event)"
    ></date-selection-dropdown>
    </div>

        <div class="grid dashboard">
            <div class="col-12 md:col-6 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="userAccessStatisticsWidget"
                        [widgetData]="userAccessStatisticsWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="permissionGroupsWidget"
                        [widgetData]="permissionGroupsWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="userStatusBreakdownWidget"
                        [widgetData]="userStatusBreakdownWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-8 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="recentlyJoinedUsersWidget"
                        [widgetData]="recentlyJoinedUsersWidget"
                >
                    <ng-template let-object="object" mvs-widget-custom-field-directive label="User Image"
                                 fieldId="userImage">

                        <div *ngIf="object.dto.id">
                            <user-profile-id [userId]="object.dto.id"
                                             [readonly]="true">

                            </user-profile-id>
                        </div>


                    </ng-template>
                </mvs-widget>
            </div>


            <div class="col-12 md:col-4 mb-2">
                <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                    <h4 class="text-xl font-medium">User Overview</h4>
                    <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>
                </div>
            </div>


        </div>

}