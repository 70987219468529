import {Component, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AgentActiveObjectService} from "../../service/api/agent-active-object.service";
import {AgentActiveObjectDto} from "../../dto/agent-active-object.dto";
import {AgentService} from "../../service/api/agent.service";
import {
    DtoList,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation
} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import {OverlayPanel} from "primeng/overlaypanel";
import {TicketService} from "../../../tm/service/api/ticket.service";
import {TicketDto} from "../../../tm/dto/ticket.dto";

@Component({
    selector: 'am-agent-active-objects',
    templateUrl: './am-agent-active-objects.component.html',
    styleUrls: ['./am-agent-active-objects.component.scss'],
    animations: [
        trigger('flipLeft', [
            transition(':increment', [
                animate('.8s linear', keyframes([
                    style({
                        transform: 'scale3d(0.2, 0.2, 0.2)',
                        opacity: 1,
                    }),
                    style({
                        transform: 'scale3d(1, 1, 1)',
                        opacity: 1,
                    }),
                ])),
            ]),
            transition(':decrement', [
                animate('.8s linear', keyframes([
                    style({
                        transform: 'scale3d(0.2, 0.2, 0.2)',
                        opacity: 1,
                    }),
                    style({
                        transform: 'scale3d(1, 1, 1)',
                        opacity: 1,
                    }),
                ])),
            ]),
        ]),
    ],
})
export class AmAgentActiveObjectsComponent implements OnInit, OnDestroy {

    busy: boolean;
    initialized: boolean;
    agentActiveObjects: AgentActiveObjectDto[];
    activeObjectsSubscription: Subscription;
    overlayLoaded: boolean = false;

    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected agentActiveObjectService: AgentActiveObjectService,
        protected agentService: AgentService,
        protected coreService: MvsCoreService,
        protected objectTypeService: ObjectTypeService,
        protected ticketService: TicketService,
        protected messageService: MvsMessageService) {

    }

    ngOnInit(): void {
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.activeObjectsSubscription = this.agentActiveObjectService.subjectAgentActiveObjects.subscribe(value => {
            this.agentActiveObjects = value.filter(item => !item.mainObject);
            this.agentActiveObjectService.handleObjectData(this.coreService);
            this.handleTickets();
        });
    }

    handleTickets() {
        if (!this.agentActiveObjects || !this.agentActiveObjects.length) {
            return;
        }
        this.busy = true;
        const filterCriteria = new FilterCriteria();

        for (let item of this.agentActiveObjects) {

            if (item.objectTypeDto.alias != 'tm.Ticket') {
                continue;
            }

            filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('id', FilterCriteria.cOperatorEqual, item.objectId));
        }

        if (!filterCriteria?.or?.length) {
            return;
        }

        const objectRequest = new ObjectRequestList(false, [filterCriteria], []);
        objectRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode('ticketObjects', ObjectRequestRelation.createJoin('tm.TicketObject#ticket')),
        );
        this.ticketService.list(objectRequest).subscribe((res: DtoList<TicketDto>) => {
            this.distributeTicketObjects(res.entries)
            this.busy = false;
        })

    }

    distributeTicketObjects(entries: TicketDto[]) {
        for (let entry of entries) {
            for (let object of this.agentActiveObjects) {
                if (object.objectId == entry.id) {
                    object.objectInfoDto = entry;
                }
            }
        }
    }

    unPinAgentObject(id: number) {
        this.agentActiveObjectService.complete(id);
    }

    activeAgentPinnedObject(object: AgentActiveObjectDto) {
        const objectAlias: string = object.objectTypeDto.alias;
        const objectId: number = object.objectId;
        const mainObject: boolean = true;
        this.agentActiveObjectService.start(objectAlias, objectId, mainObject);
    }

    completeAllObjects(op: OverlayPanel) {
        this.agentActiveObjectService.completeAll();
        op.hide();
    }

    /**
     * open the selected object on sidebar
     * @param object
     */
    openObjectDrawer(object: AgentActiveObjectDto) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(object.objectTypeDto.alias, object.objectId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    openOverlayPanel(op: OverlayPanel, event) {
        if (this.agentActiveObjects && this.agentActiveObjects.length) {
            op.toggle(event);
        }
    }

    /**
     * to determine the display name
     * @param activeObject
     */
    getDisplayName(activeObject: AgentActiveObjectDto): string {
        if (activeObject && activeObject.objectDto && activeObject.objectDto['alias']) {
            return activeObject.objectDto['alias'];
        } else if (activeObject && activeObject.objectDto && activeObject.objectDto['name']) {
            return activeObject.objectDto['name'];
        } else {
            return activeObject.objectTypeDto.name + ' ' + activeObject.objectId;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

    }

    handleShowOverlay() {
        this.overlayLoaded = true;
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.activeObjectsSubscription.unsubscribe();
    }
}
