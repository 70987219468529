import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TeEditorExpressions, TeItem, TeMenuItem} from '../../te-editor-expressions';
import {
    ConfirmationService,
    MenuItem,
    MessageService,
    TreeDragDropService,
    TreeNode,
    TreeNodeDragEvent
} from 'primeng/api';
import {MetaService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ContextMenu} from 'primeng/contextmenu';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {MetaDataAttributeDto} from "@kvers/alpha-core-common";
import {TeVariableComponentBase} from '../../logic/te-variable-component-base';
import {TinymceAutosave} from '../../logic/tinymce-autosave';
import {TeAutosaveCookieDto} from '../../dto/te-autosave-cookie.dto';
import {debounceTime, fromEvent, Subscription} from 'rxjs';
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {TeContentTypeEnum} from "../../enum/te-content-type.enum";
import {MetaDataValueListEntryDto} from "@kvers/alpha-core-common";
import {TableInfoConditionsEvent} from "./components/table-info-conditions/table-info-conditions.component";
import {MvsCrudObjectHistoryService} from "@kvers/alpha-ui";
import {TestCaseChangeEvent} from "../te-test-case/te-test-case.component";
import {TeTemplateService} from "../../service/api/te-template.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {MvsMessageService} from "@kvers/alpha-core-common";

export enum variableTypesEnum {
    SIMPLE,
    CHANGEABLE,
    CHECK_NULL,
    DATE_FIELD
}

export interface TableAttributes {
    attribute: string;
    value: string;
    subPath: string;
}

interface ConditionHighlighterEvent {
    info: string,
    condition: string,
    targetElement: HTMLElement,
    editor: any
}

@Component({
    selector: 'mvs-te-editor',
    templateUrl: './te-editor.component.html',
    styleUrls: ['./te-editor.component.scss']
})
export class TeEditorComponent extends TeVariableComponentBase
    implements OnInit, OnChanges {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    conditions: TeMenuItem[] = [];
    loopIterations: TeMenuItem[] = [];
    teVariables: TeMenuItem[] = [];
    nodeChildrenAttributes: MetaDataAttributeDto[] = [];
    dropLocation: string;
    itemsForVariable: MenuItem[];
    dragAttribute: string;
    dragAttributeChild: string;
    dragAttributeNode: TreeNodeDragEvent;
    dataTypeTs: string;
    tinyMceConfig: object;
    openVariableDialog: boolean = false;
    enumKey: number;


    isContentChanged: boolean = false;

    editor: "tinyMCE" | "textArea" = "tinyMCE";

    // editorTextAreaContent: string = undefined;
    editorContent: string;

    showTableConditionDialog: boolean = false;
    showHighlightConditionDialog: boolean = false;
    tableObjectName: string;
    tableObjectTypeName: string;
    tableAttributes: TableAttributes[];
    changedTableAttributes: TableAttributes[];
    tableCondition: string;
    highlightCondition: string;
    tableAttributePath: string;
    tableNode: any;
    tableName: string;
    toolbarButtonList: MenuItem[];
    inlineConditions: ConditionHighlighterEvent;

    // templateGenerateResponse: TeTemplateGenerateResponseDto;
    // trustedGenerateResponseHtml: SafeHtml;
    // occurredException: string;
    // occurredExceptionDetails: string;
    // showTestResultSidebar: boolean = false;

    widgetDataSubscription: Subscription;
    copiedText: string;

    // saveTimeoutId: ReturnType<typeof setTimeout>;
    // continuousTimeoutId: ReturnType<typeof setTimeout>;
    // isTypingContinuously: boolean = false;

    @Input() content: string;
    @Input() selectedTestCase: TestCaseChangeEvent;
    @Output() onSave: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('contextMenu') contextMenuComponent: ContextMenu;
    @ViewChild('contextMenu') contextMenuCopyText: ContextMenu;
    @ViewChild('tinyEditor') tinyEditor!: EditorComponent;

    private teEditorExpression = new TeEditorExpressions();
    private autoSave = new TinymceAutosave();

    constructor(
        protected treeDragDropService: TreeDragDropService,
        protected metaService: MetaService,
        protected coreService: MvsCoreService,
        protected toast: MessageService,
        protected templateService: TeTemplateService,
        protected objectHistory: MvsCrudObjectHistoryService,
        protected messageService: MvsMessageService,
        protected objectService: MvsUiObjectService,
        public sanitizer: DomSanitizer,
        protected confirmationService: ConfirmationService) {
        super();
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
        this.toolbarButtonList = [
            {
                label: 'Show History',
                icon: 'pi pi-history',
                command: () => {
                    this.showHistory();
                }
            },
            {
                label: 'Editor wechseln',
                icon: 'fa-regular fa-edit',
                command: () => {
                    this.switchEditor();
                }
            },
            {
                label: 'Freemarker Doku',
                icon: 'fa-regular fa-file-contract',
                command: () => {
                    this.navigateToFreeMarker();
                }
            },
        ];
    }

    public openVariables() {
        this.openVariableDialog = true;
    }

    handleSave() {

        let content = undefined;

        if (this.editor == "tinyMCE") {
            content = this.tinyEditor.editor.getContent({format: "html"});

            if (this.contentType == TeContentTypeEnum.plain_text) {
                content = this.stripHtmlTags(content);
            } else {
                content = this.removeHighlights(content);
            }

        } else {
            content = this.editorContent;
        }

        this.onSave.emit(content);
    }

    copyExpressions(source: TeMenuItem[], tableAttribute: boolean = false): TeMenuItem[] {

        // copy
        const target = JSON.parse(JSON.stringify(source));

        for (let teMenuItem of target) {

            for (let item of teMenuItem.items) {

                item.command = event => {
                    if (tableAttribute) {
                        this.setConditionIntoTable(event.item);
                    } else {
                        this.setVariableIntoCondition(event.item);
                    }

                }

            }

        }
        return target;
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.configureTinyMce();

        // this.conditions = this.copyExpressions(this.teEditorExpression.ifOptions);
        this.loopIterations = this.copyExpressions(this.teEditorExpression.forOptions);
        this.teVariables = this.copyExpressions(this.teEditorExpression.teVariables);

        // when drag node, drop into editor
        this.treeDragDropService.dragStop$.subscribe((dragAttribute) => {
            this.dragAttributeChild = '';
            if (this.dropLocation === "editor" && dragAttribute["node"] && (!dragAttribute["node"].children || dragAttribute["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany || dragAttribute["node"].parent.data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany)) {
                this.dragAttributeNode = dragAttribute;


                if (this.dragAttributeNode.node.data.valueList) {
                    //it has value list which needs to be shown in if conditions
                    this.conditions = this.enhanceIfOptionsWithValueList(this.teEditorExpression.ifOptions, this.dragAttributeNode.node.data.valueListEntries);
                } else {
                    this.conditions = this.copyExpressions(this.teEditorExpression.ifOptions);
                }


                this.initializeItemsForVariable();
                if (dragAttribute["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany || dragAttribute["node"].parent.data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany) {
                    this.removePlainTextField();
                } else {
                    this.removeLoopFromField();
                }
                const {dataType} = dragAttribute.node.data;
                this.checkDataType(dataType);
                this.dragAttribute = this.findParentNodes(dragAttribute);
                if (dragAttribute["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.manyToOne || dragAttribute["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToOne) {
                    //we are at deeper level
                    const values = this.getOneToOneRelation(this.dragAttribute);
                    this.dragAttribute = values[0];
                    this.dragAttributeChild = values[1];

                }

                // this.contextMenuComponent.toggle()
                // this.contextMenuComponent.show();
                // this.contextMenuComponent.containerViewChild.nativeElement.style.display = 'block';
                // this.contextMenuComponent.containerViewChild.nativeElement.style.zIndex = '999';
            }
            this.dropLocation = "";
        });

        this.objectService.widgetSelectData.subscribe(res => {
            const artefactContent = res['artefactDto'].content;
            this.insertTextIntoPosition(artefactContent);
        })

        this.autoSave.getAutoSaveContent().subscribe({
            next: (content) => {
                if (this.isContentChanged) {
                    this.onSave.emit(content);
                }
            },
            error: (error) => console.log(error)
        });

        (window as any).templateVariable = this;
    }

    getOneToOneRelation(input: string): string[] {
        const lastDotIndex = input.lastIndexOf('.');
        if (lastDotIndex === -1) {
            // No dot found, return the whole string as the first part and an empty string as the second part
            return [input, ''];
        }

        const firstPart = input.substring(0, lastDotIndex);
        const lastPart = input.substring(lastDotIndex + 1);

        return [firstPart, lastPart];
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // create form control
        this.editorContent = this.content;

        if (this.editorContent && this.editorContent.includes('html')) {
            this.editor = 'textArea';
        }

        // this.handleContentDisplay();
        this.checkUpdatedContentWithInCookie();

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    configureTinyMce(): void {
        const component = this;
        this.tinyMceConfig = {
            selector: "#templateEngineTinyEditor",
            contextmenu_never_use_native: true,
            contextmenu: 'copy paste',
            base_url: '/assets/plugin/js/tinymce',
            suffix: '.min',
            menubar: true,
            branding: false,
            height: 67 + 'vh',
            inline: false,
            paste_block_drop: true,

            // --> INSERT MARKO -> TRY TO DISABLE THE CHECKS AND REPLACEMENTS
            valid_elements: '*[*]',  // Allows all elements and attributes
            extended_valid_elements: 'img[class|src|border=0|alt|title|hspace|vspace|width|height|align|name|style|span[data-info]]',
            // --> END INSERT MARKO -> TRY TO DISABLE THE CHECKS AND REPLACEMENTS

            plugins: 'lists link image table code help wordcount emoticons conditionalHighlight',//conditionalHighlight
            toolbar: 'copy undo redo formatselect | bold italic strikethrough codesample forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat hr pagebreak code | emoticons',
            table_toolbar: 'tableprops tabledelete | tableConditions',
            // variable_valid: ["#if", "#else", "#list"],
            // content_style: `.variable {
            //                     color: red;
            //                     }
            //                 .set-condition-btn {
            //                 color: red
            //                 }
            //
            //                     `,
            content_style: `.highlight-condition {
                                 color: #c9930a;
                              }
                              .highlight-tag {
                              color: #2196F3;
                              font-family: Monaco, courier, monospace;
                              }
                              
                              .highlight-symbol {
                                color:  #EB286A;
                            }
                              `,
            setup: (editor) => {

                // editor.on('contextmenu', function(event) {
                //     event.preventDefault(); // Prevent the default context menu
                //
                //     let target = event.target;
                //
                //     if (target && target.classList.contains('highlight-variable')) {
                //         editor.fire('editorCopyText', { text: target.innerText, targetElement: target });
                //
                //         // Open TinyMCE context menu manually
                //         editor.execCommand('mceContextMenu', true, target);
                //     }
                // });

                let currentTable = null;

                editor.on('highlightClick', (event) => {
                    component.onHighlightClick(event, editor);
                });

                // editor.on('editorCopyText', (event) => {
                //     component.editorCopyText(event);
                // });

                editor.on('input change', () => {
                    this.isContentChanged = true;
                    /*
                    TODO: The auto save needs to be reimplemented
                    this.autoSave.setAlias(this.alias+this.templateId);
                    this.autoSave.autoSaveEditorContent(this.tinyEditor.editor.getContent());
                      this.setContentIntoCookie(editor);
                     */
                });

                editor.on('NodeChange', function (e) {
                    const node = e.element;
                    currentTable = node.closest('table');
                });

                editor.ui.registry.addButton('tableInfo', {
                    tooltip: 'Table Info',
                    icon: 'info',
                    onAction: function () {
                        if (currentTable) {
                            const objectName = currentTable.getAttribute('data-object-name') || 'No object name set';
                            component.tableObjectName = objectName;
                            editor.windowManager.open({
                                title: 'Table Information',
                                body: {
                                    type: 'panel',
                                    items: [
                                        {
                                            type: 'htmlpanel',
                                            html: `
                                                    <p>Object Name: ${objectName}</p>
                                                    <p>Has Conditions?: No</p>
`
                                        }
                                    ]
                                },
                                buttons: [
                                    {
                                        type: 'cancel',
                                        text: 'Close'
                                    }
                                ]
                            });
                        }
                    }
                });

                editor.ui.registry.addButton('tableConditions', {
                    // text: 'Add conditions',
                    icon: 'settings',
                    tooltip: 'Add Filters',
                    onAction: function () {
                        const selectedNode = editor.selection.getNode();
                        const tableNode = selectedNode.closest('table');


                        if (tableNode) {
                            // self.addConditionsToTable(editor, tableNode);
                            component.extractAttributes(tableNode);
                            editor.getBody().blur(); //hide the toolbar
                            // self.extractCondition(tableNode);
                        }
                    }
                });

                // condition button on attribute level
                // editor.on('click', (e) => {
                //     const target = e.target;
                //     if (target.classList.contains('set-condition-btn')) {
                //         const attributeName = target.getAttribute('data-attribute-name');
                //         const originalVariable = target.getAttribute('original-variable-name');
                //         this.setTableAttributeContextMenu();
                //         this.contextMenuComponent.show();
                //     }
                // });

            }
        };
    }

    onHighlightClick(event: ConditionHighlighterEvent, editor: any) {
        this.inlineConditions = event;
        this.inlineConditions.editor = editor;
        this.tableAttributePath = 'cr_CustomerContract_attr_customer';
        this.showHighlightConditionDialog = true;
    }

    updateInlineCondition(newCondition: string, targetElement: HTMLElement, editor: any) {
        targetElement.innerText = newCondition;
        let htmlContent = editor.getContent();
        editor.setContent(htmlContent);
    }

    extractAttributes(tableNode: any) {
        //extract table name
        const objectName = tableNode.getAttribute('data-object-name')
        const parentObjectName = tableNode.getAttribute('data-parent-object-name');
        this.tableObjectTypeName = parentObjectName;
        this.tableName = objectName;
        this.tableNode = tableNode;
        this.dragAttributeChild = tableNode.getAttribute('drag-attribute-child');

        if (!objectName) {
            return;
        }

        this.tableAttributes = this.extractTableData(tableNode);
        this.prepareTableDialog(tableNode);


    }

    extractTableData(tableNode: HTMLTableElement): Array<TableAttributes> {
        tableNode.innerHTML = this.removeHighlights(tableNode.innerHTML);
        let headers = tableNode.querySelectorAll('thead th');
        let rows = tableNode.querySelectorAll('tbody tr');
        let tableData = [];

        // Assuming each row has the same number of cells as there are headers
        rows.forEach(row => {
            let cells = row.querySelectorAll('td');
            cells.forEach((cell, index) => {
                let header = headers[index].textContent.trim();  // Get the header name
                let valueContainerSpan = cell.querySelector('span'); // Assuming the value is in a <span> element
                let valueContainer = cell.innerHTML; //direct text in cell
                let value = valueContainerSpan ? valueContainerSpan.textContent : valueContainer;

                tableData.push({
                    attribute: header,
                    value: value
                });
            });
        });

        return tableData;
    }

    prepareTableDialog(tableNode: any) {
        tableNode.innerHTML = this.removeHighlights(tableNode.innerHTML);
        const tBody = tableNode.querySelector('tbody');

        if (!tBody) {
            return;
        }

        const bodyInnerHtml = tBody.innerHTML;
        this.tableCondition = this.extractConditionFromString(bodyInnerHtml);
        this.tableAttributePath = this.extractAttributePath(bodyInnerHtml);

        this.showTableConditionDialog = true;
    }

    extractAttributePath(htmlString: string): string | null {

        //remove highlighting conditions from string
        htmlString = this.removeHighlights(htmlString);

        // Adjusting the regex to be more flexible with spaces and to correctly stop before the comment closure
        const listRegex = /<!--\s*<#list\s+[^ ]+\s+as\s+([^ ]+?)\s*(?=-->)/;

        let newString = '';

        const match = htmlString.match(listRegex);
        newString = match[1];

        if (newString.endsWith('>')) {
            const newMatch = match[1].split('>')
            newString = newMatch[0];
        }

        if (newString) {
            // Return the captured attribute path without trailing characters
            return newString.trim();  // Trim any accidental whitespace capture for safety
        } else {
            return null;
        }
    }


    extractConditionFromString(htmlString: string) {
        const regex = /<!--\s*<#if(.*?)>\s*-->/s;

        const match = htmlString.match(regex);

        if (match && match[1]) {
            return `<#if ${match[1]} >`;
        } else {
            return null;
        }
    }

    // restoreOriginalHtml(htmlString: string): string {
    //     // Step 1: Remove the highlighting spans
    //     const cleanedHtml = htmlString
    //         .replace(/<span class="highlight-all" contenteditable="false">(.*?)<\/span>/g, '$1')
    //         .replace(/<span class="highlight-tag">(.*?)<\/span>/g, '$1')
    //         .replace(/<span class="highlight-condition">(.*?)<\/span>/g, '$1');
    //
    //     // Step 2: Replace &#47# with /#
    //     const restoredHtml = cleanedHtml.replace(/&#47#/g, '/#');
    //
    //     return restoredHtml;
    // }


    // addConditionsToTable(condition: string) {
    //
    //     const conditionStartTag = `<!-- ${condition}-->`;
    //     const conditionEndTag = "<!--</#if>-->";
    //
    //     const tbody = this.tableNode.querySelector('tbody');
    //     if (tbody) {
    //         let tbodyHTML = tbody.innerHTML;
    //
    //         // Identify the positions for the list and tr tags
    //         const listStartTag = "<!--<#list";
    //         const listEndTag = "<!--</#list>-->";
    //
    //
    //         const listStartIndex = tbodyHTML.indexOf(listStartTag);
    //         const listEndIndex = tbodyHTML.lastIndexOf(listEndTag) + listEndTag.length;
    //
    //         const listTagCloseIndex = tbodyHTML.indexOf("-->", listStartIndex) + 3;
    //         const beforeList = tbodyHTML.substring(0, listTagCloseIndex);
    //         const afterListAndBeforeTrs = tbodyHTML.substring(listTagCloseIndex, listEndIndex - listEndTag.length);
    //         const afterTrs = tbodyHTML.substring(listEndIndex - listEndTag.length, listEndIndex);
    //
    //         tbodyHTML = beforeList + conditionStartTag + afterListAndBeforeTrs + conditionEndTag + afterTrs;
    //         tbody.innerHTML = tbodyHTML;
    //
    //     }
    // }

    addConditionsToTable(newCondition: string) {
        const tbody = this.tableNode.querySelector('tbody');
        if (tbody) {
            // let tbodyHTML = this.restoreOriginalHtml(tbody.innerHTML);
            let tbodyHTML = tbody.innerHTML;
            tbodyHTML = this.removeHighlights(tbodyHTML);

            // Check for the existing condition by looking for the closing tag.
            let hasExistingCondition = false;

            if (tbodyHTML.includes(`<!--&l<!--&lt;/#if&gt;-->`) || tbodyHTML.includes("<!--</#if>-->")) {
                hasExistingCondition = true;
            }

            if (hasExistingCondition) {
                // Regex to find and capture the existing condition expression for replacement
                // This aims to capture only the expression within <#if ... >, excluding the content of the block
                let conditionExprRegex: RegExp;
                if (newCondition) {
                    conditionExprRegex = /(<!--\s*<#if).*?(>)/s;
                } else {
                    conditionExprRegex = /(<!--\s*<#if.*?>\s*-->)/s;
                }


                // Prepare the new condition expression for insertion, wrapped in comment tags

                let newConditionExpr = '';
                if (newCondition) {
                    newConditionExpr = `<!-- ${newCondition} `;
                } else {
                    newConditionExpr = '';
                }


                // Replace only the condition expression, preserving the content within the block
                tbodyHTML = tbodyHTML.replace(conditionExprRegex, newConditionExpr);

                if (!newCondition) {
                    // const searchString = /<!--<\/#if>-->/g;
                    const searchString = /<!--\s*(<\/?#if[^>]*>|&lt;\/?#if[^>]*&gt;)\s*-->/g;
                    const replacementString = '';

                    tbodyHTML = tbodyHTML.replace(searchString, replacementString);
                }
            } else {
                //If no existing condition, simply append the new condition at the end before </#list>
                const conditionStartTag = `<!-- ${newCondition}-->`;
                const conditionEndTag = "<!--</#if>-->";
                // Identify the positions for the list and tr tags
                const listStartTag = "<!--<#list";
                const listEndTag = "<!--</#list>-->";
                const listEndTagAlt = "<!--&lt;/#list&gt;-->";


                const listStartIndex = tbodyHTML.indexOf(listStartTag);
                // const listEndIndex = tbodyHTML.lastIndexOf(listEndTag) + listEndTag.length;
                let listEndIndex = tbodyHTML.indexOf(listEndTag);
                if (listEndIndex === -1) {
                    listEndIndex = tbodyHTML.indexOf(listEndTagAlt);
                    if (listEndIndex !== -1) {
                        listEndIndex += listEndTagAlt.length;
                    }
                } else {
                    listEndIndex += listEndTag.length;
                }

                const listTagCloseIndex = tbodyHTML.indexOf("-->", listStartIndex) + 3;
                const beforeList = tbodyHTML.substring(0, listTagCloseIndex);
                const afterListAndBeforeTrs = tbodyHTML.substring(listTagCloseIndex, listEndIndex - listEndTag.length);

                const afterTrs = tbodyHTML.substring(listEndIndex - listEndTag.length, listEndIndex);

                tbodyHTML = beforeList + conditionStartTag + afterListAndBeforeTrs + conditionEndTag + listEndTag;
                tbody.innerHTML = tbodyHTML;

            }

            // Update the tbody's HTML
            tbody.innerHTML = tbodyHTML;
        }
    }

    setConditionIntoTable(event: any) {
        console.log('table attribute');
    }

    setTableAttributeContextMenu(attributes: MetaDataAttributeDto[]) {
        const ifConditions = this.teEditorExpression.tableIfOptions;
        this.conditions = this.copyExpressions(ifConditions, true);

        for (let conditionGroup of this.conditions) {
            for (let condition of conditionGroup.items) {
                condition.items = attributes.map((value) => ({
                    label: value.attributeName,
                    command: () => {
                        this.createConditionForTable(value, condition);
                    }
                }));
            }
        }

        this.itemsForVariable = [
            {
                label: 'Conditions',
                items: this.conditions,
            },
        ];
    }

    createConditionForTable(attribute: MetaDataAttributeDto, condition: TeItem) {
        const {attributeName} = attribute;
        const {startTemplate, endTemplate} = condition;

        // Replace the first occurrence of 'XXX' with the attributeName
        const firstXXXIndex = startTemplate.indexOf('XXX');
        if (firstXXXIndex !== -1) {
            // Reconstruct the startTemplate with attributeName replacing the first 'XXX'
            const updatedStartTemplate = startTemplate.substring(0, firstXXXIndex) +
                attributeName +
                startTemplate.substring(firstXXXIndex + 'XXX'.length);

            // Log or use the updated template as needed
            console.log(updatedStartTemplate + endTemplate);
        }
    }

    enhanceIfOptionsWithValueList(ifOptions: TeMenuItem[], valueList: MetaDataValueListEntryDto[]) {
        let enhancedIfOptions = JSON.parse(JSON.stringify(ifOptions));

        for (let conditionGroup of enhancedIfOptions) {
            for (let condition of conditionGroup.items) {
                condition.items = valueList.map((value) => ({
                    label: `${value.label} (${value.key})`,
                    command: () => {
                        this.enumKey = value.key;
                        this.setVariableIntoCondition(condition);
                    }
                }));
            }
        }

        return enhancedIfOptions;
    }

    insertConditionWithEnumValue(template: string): string {
        const finalTemplate = template.replace(/XXX/g, this.enumKey.toString());
        this.enumKey = null;
        return finalTemplate;
    }

    // initialize variable items
    initializeItemsForVariable(): void {
        this.itemsForVariable = [
            {
                label: 'Simple',
                command: event => {
                    this.setVariableIntoCondition(event.item, variableTypesEnum.SIMPLE);
                },
            },
            {
                label: 'Changeable',
                command: event => {
                    this.setVariableIntoCondition(event.item, variableTypesEnum.CHANGEABLE);
                },
            },
            {
                label: 'Check Null',
                command: event => {
                    this.setVariableIntoCondition(event.item, variableTypesEnum.CHECK_NULL);
                },
            },
            // {
            //     label: 'Date Fields',
            //     command: event => {
            //         this.setVariableIntoCondition(event.item, variableTypesEnum.DATE_FIELD);
            //     },
            // },
            {
                label: 'Conditions',
                items: this.conditions,
            },
            {
                label: 'For Loop',
                items: this.loopIterations
            },
        ];
    }

    removePlainTextField(): void {
        this.itemsForVariable.filter((item, index) => {
            if (item.label === 'Simple') {
                this.itemsForVariable.splice(index, 1);
                return true;
            } else {
                return false;
            }
        });
    }

    removeLoopFromField(): void {
        this.itemsForVariable.find((item, index) => {
            if (item.label === 'For Loop') {
                this.itemsForVariable.splice(index, 1);
                return true;
            } else {
                return false;
            }
        });
    }

    handleCloseDialog(isDialogClose: boolean): void {
        this.openVariableDialog = isDialogClose;
    }

    handleLabelActive(template: TeItem): void {
        // this.insertTextIntoPosition(template)
    }

    handleOnDrop(event?: DragEvent): void {
        this.dropLocation = "editor";
        if (event) event.preventDefault();
    }

    handleVariableSelect(selectedNode: TreeNode) {
        if (selectedNode["node"] && !selectedNode["node"].children) {
            (window as any).onVariableSelect(selectedNode);
        }
    }

    private checkUpdatedContentWithInCookie(): void {
        let editorContentFromCookie = this.autoSave.getAutoSaveCookie(this.alias + this.contentProviderId);
        if (editorContentFromCookie && this.editorContent !== editorContentFromCookie.content) {
            this.confirmationService.confirm({
                message: 'Do you want to update your content within cookie?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.isContentChanged = true;
                    this.editorContent = editorContentFromCookie?.content;
                    this.autoSave.autoSaveEditorContent(editorContentFromCookie?.content);
                },
                reject: () => {
                },
            });
        }
    }

    private setContentIntoCookie(editor: any) {

        //TODO: fromEvent implementation needs to check more cases/tests
        fromEvent(editor, 'input change').pipe(
            debounceTime(2000)
        ).subscribe(() => {
            const autoSaveCookieDto = new TeAutosaveCookieDto();
            autoSaveCookieDto.id = this.contentProviderId;
            autoSaveCookieDto.alias = this.alias;
            autoSaveCookieDto.content = editor.getContent();
            this.autoSave.setCookie(autoSaveCookieDto, this.alias + this.contentProviderId, 1);
        });

        // const autoSaveCookieDto = new TeAutosaveCookieDto();
        // autoSaveCookieDto.id = this.templateId;
        // autoSaveCookieDto.alias = this.alias;
        // autoSaveCookieDto.content = editor.getContent();
        // this.autoSave.setCookie(this.alias+this.templateId, autoSaveCookieDto, 1);
    }

    private checkDataType(dataType: string): void {
        switch (dataType) {
            case "java.math.BigDecimal":
                this.dataTypeTs = "number";
                break;
            case "java.lang.Integer":
                this.dataTypeTs = "number";
                break;
            case "java.lang.Long":
                this.dataTypeTs = "number";
                break;
            case "java.time.LocalDate":
                this.dataTypeTs = "Date";
                break;
            case "java.lang.String":
                this.dataTypeTs = "string";
                break;
            case "java.time.Instant":
                this.dataTypeTs = "Date";
                break;
            default:
                this.dataTypeTs = "Didn't match";
        }
    }

    private getAliasFromNode(node: TreeNode): string {

        if (node.data["attributeName"]) {

            if ((node.data["attributeName"] as string).charAt(0) == "+") {
                return "metaAttribute_" + (node.data["attributeName"] as string).substring(1);
            }
            return node.data["attributeName"];

        } else if (node.data["alias"]) {
            return node.data["alias"];

        } else if (node.data["name"]) {

            let join = node.data["name"];
            join = join.replace('#', '_attr_');
            join = join.replace('.', '_');

            if ((join as string).charAt(0) == "+") {
                return "metaJoin_" + (join as string).substring(1);
            }
            return join;

        } else {

        }

        return "";

    }

    // find attribute parent nodes
    private findParentNodes(dragAttribute: TreeNodeDragEvent) {

        let variableName = "";

        const aliasFromNode = this.getAliasFromNode(dragAttribute.node);
        variableName = aliasFromNode;

        let node = dragAttribute.node.parent;
        while (node) {

            const aliasFromSubNode = this.getAliasFromNode(node);
            variableName = aliasFromSubNode + "." + variableName;

            node = node.parent;

        }

        return variableName;

    }

    private addVariableToEditor(value: string, mode: variableTypesEnum): void {

        const draggedNode = this.teEditorExpression.createTemplateVariable(value, this.dataTypeTs, mode);

        this.insertTextIntoPosition(draggedNode);
    }

    // add text at cursor location
    private insertTextIntoPosition(value: string): void {

        // Don't remove it, it is basically for prime ng editor
        // let _quill = this.editorComponent.getQuill();
        // let selection = _quill.getSelection(true);
        // _quill.insertText(selection.index, value);

        this.tinyEditor.editor.execCommand('mceInsertContent', false, value);
    }

    // add variable attribute node into template text
    private setVariableIntoCondition(item: TeItem, mode?: variableTypesEnum): void {
        if (item.template) {
            let replaceValue = this.teEditorExpression.replaceVariable(item.template, this.dragAttribute, this.dataTypeTs, this.dragAttributeNode, this.dragAttribute);

            if ((this.dragAttributeNode["node"].data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany || this.dragAttributeNode["node"].parent.data.joinRelationEnum === MetaDataJoinRelationEnum.oneToMany) && item.label === 'Create Table') {
                this.metaService.getByAlias(this.dragAttributeNode["node"].data.joinObjectTypeAlias, true, true, true).subscribe((response) => {
                    this.nodeChildrenAttributes = response.attributes;
                    this.createDynamicTable(this.nodeChildrenAttributes, replaceValue);
                })
            } else {

                if (this.dragAttributeNode.node.data.valueList) {
                    //it's an enum so XXX should be changed before inserting it
                    replaceValue = this.insertConditionWithEnumValue(replaceValue);
                }

                this.insertTextIntoPosition(replaceValue);


            }
        } else {
            this.addVariableToEditor(this.dragAttribute, mode);
        }
    }

    private createDynamicTable(nodeChildrenAttributes: MetaDataAttributeDto[], replaceValue: string): void {

        const table = document.createElement("table");
        const tableHeader = document.createElement("thead");
        const tableBody = document.createElement("tbody");

        const parent = this.dragAttributeNode.node.parent.data;
        let objectName = '';

        if (this.dragAttributeNode.node.data.joinRelationEnum == MetaDataJoinRelationEnum.oneToMany) {
            objectName = this.dragAttributeNode.node.data.joinObjectTypeAlias;
        } else {
            objectName = parent.joinObjectTypeAlias;
        }

        table.setAttribute('data-object-name', this.dragAttributeNode.node.data.joinObjectTypeAlias);
        table.setAttribute('data-parent-object-name', objectName);
        table.setAttribute('drag-attribute-child', this.dragAttributeChild);

        // creating all cells for header
        for (let i = 0; i < 1; i++) {
            // creates a table row
            const row = document.createElement("tr");

            for (let j = 0; j < nodeChildrenAttributes.length; j++) {
                /** Create a <th> element and a text node, make the text
                 node the contents of the <th>, and put the <th> at
                 the start of the table row
                 */
                const cell = document.createElement("th");
                let capitalizeAttributeName = nodeChildrenAttributes[j].attributeName.charAt(0).toUpperCase() + nodeChildrenAttributes[j].attributeName.slice(1);
                const cellText = document.createTextNode(capitalizeAttributeName);


                /**
                 * condition button for table on attribute level
                 const conditionButton = document.createElement("button");
                 conditionButton.textContent = "+";
                 conditionButton.setAttribute("class", "set-condition-btn mr-2");
                 conditionButton.setAttribute("data-attribute-name", nodeChildrenAttributes[j].attributeName);

                 //set original name in our th
                 conditionButton.setAttribute("original-variable-name", `${this.teEditorExpression.loopVariable}.${nodeChildrenAttributes[j].attributeName}`);

                 cell.appendChild(conditionButton);
                 */
                cell.appendChild(cellText);

                row.appendChild(cell);
            }

            // add the row to the start of the table header
            tableHeader.appendChild(row);
        }

        // creating all cells for body
        for (let i = 0; i < 1; i++) {
            // creates a table row
            const row = document.createElement("tr");

            for (let j = 0; j < nodeChildrenAttributes.length; j++) {
                /** Create a <td> element and a text node, make the text
                 node the contents of the <td>, and put the <td> at
                 the end of the table row
                 */
                const cell = document.createElement("td");

                const spanForTemplateVariable = document.createElement("span");
                spanForTemplateVariable.id = 'kTemplateVariable';
                spanForTemplateVariable.setAttribute('contenteditable', 'false');

                let oneToOneRelation: string = '';

                if (this.dragAttributeNode.node.data.joinRelationEnum == MetaDataJoinRelationEnum.manyToOne || this.dragAttributeNode.node.data.joinRelationEnum === MetaDataJoinRelationEnum.oneToOne) {
                    oneToOneRelation = `.${this.dragAttributeChild}`;
                }

                const cellText = document.createTextNode(`${this.teEditorExpression.loopVariable}${oneToOneRelation}.${nodeChildrenAttributes[j].attributeName}!''`);
                // const cellText = document.createTextNode(`\${(${this.teEditorExpression.loopVariable}${oneToOneRelation}.${nodeChildrenAttributes[j].attributeName})!''}`);


                // spanForTemplateVariable.innerHTML =
                //     `<span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">{{</span>${cellText.textContent}<span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">}}</span>`;

                spanForTemplateVariable.innerHTML =
                    '${' + cellText.textContent + '}';

                // spanForTemplateVariable.innerHTML =
                //     '<#if ' + cellText.textContent + '?? >' + '</#if>' + '${' + cellText.textContent + '}';

                cell.appendChild(spanForTemplateVariable);
                row.appendChild(cell);
            }

            // add the row to the end of the table body
            tableBody.appendChild(row);
        }

        // put the </thead> and <tbody> in the <table>
        table.appendChild(tableHeader);
        table.appendChild(tableBody);
        this.insertListIntoComment(table.outerHTML, replaceValue);
    }

    private insertListIntoComment(tableText: string, replaceValue: string): void {
        let firstRowIndex = tableText.indexOf('<tbody>');
        firstRowIndex = firstRowIndex + 7;
        let startingListInsert = tableText.slice(0, firstRowIndex) + `<!--${replaceValue}-->` + tableText.slice(firstRowIndex);
        let lastRowIndex = startingListInsert.lastIndexOf('</tbody>');
        // lastRowIndex = lastRowIndex;
        let afterCompleteListInsert = startingListInsert.slice(0, lastRowIndex) + "<!--</#list>-->" + startingListInsert.slice(lastRowIndex);
        this.insertTextIntoPosition(afterCompleteListInsert);
    }

    navigateToFreeMarker() {
        const url = 'https://freemarker.apache.org/index.html';
        window.open(url, "_blank");
    }

    switchEditor() {

        if (this.editor == "tinyMCE") {
            this.setCodeMirrorEditor();
            this.editor = "textArea";
        } else if (this.editor == 'textArea' && this.editorContent.includes('html')) {
            // cant change to tinyMCE since our content includes html tags
            this.messageService.showWarningMessage('Warnung','Sie können die Editoren nicht wechseln, da HTML enthalten ist.');
        } else {
            this.editor = "tinyMCE";
        }
    }

    handleInlineCondition(event: TableInfoConditionsEvent) {
        this.updateInlineCondition(event.condition, this.inlineConditions.targetElement, this.inlineConditions.editor);
        this.showHighlightConditionDialog = false;

    }

    setCodeMirrorEditor() {
        this.editorContent = this.tinyEditor.editor.getContent();
        this.editorContent = this.removeHighlights(this.editorContent);
    }

    handleTableCondition(event: TableInfoConditionsEvent) {
        this.addConditionsToTable(event.condition);
        this.changedTableAttributes = event.tableAttributes;
        this.updateTable(this.changedTableAttributes);
        this.showTableConditionDialog = false;

    }

    private updateTable(newAttributes: TableAttributes[]): void {

        // Update the header
        const thead = this.tableNode.querySelector('thead');
        const newHeaderRow = document.createElement('tr');
        newAttributes.forEach(attr => {
            const th = document.createElement('th');
            th.textContent = attr.attribute;
            newHeaderRow.appendChild(th);
        });
        thead.innerHTML = ''; // Clear old header row
        thead.appendChild(newHeaderRow);

        // Update the body row
        const tbodyRow = this.tableNode.querySelector('tbody tr');
        const newBodyRow = document.createElement('tr');
        newAttributes.forEach(attr => {
            const td = document.createElement('td');
            td.textContent = attr.value;
            newBodyRow.appendChild(td);
        });
        tbodyRow.parentNode.replaceChild(newBodyRow, tbodyRow); // Replace old row with new

        // Update current attributes with new
        this.tableAttributes = [...newAttributes];
    }

    handleCodeMirrorContentChange(event: string) {
        this.editorContent = event;
    }

    showHistory() {
        this.objectHistory.showHistory('te.TeTemplate', this.contentProviderId, onLoadHistory => {
            this.busy = false;
        });
    }


    handleContentDisplay() {
        // if (this.editorContent) {
        //     const pattern = /(<span\s+id="kTemplateVariable"\s*>.*?<\/span\s*>)/g;
        //     this.editorContent = this.editorContent.replace(pattern, '<span style="color:red">$1</span>');
        // }
    }



    removeHighlights(content: string): string {
        content = content
            .replace(/<span class="highlight-all" contenteditable="false">(.*?)<\/span>/g, '$1')
            .replace(/<span class="highlight-tag">(.*?)<\/span>/g, '$1')
            .replace(/<span class="highlight-condition">(.*?)<\/span>/g, '$1')
            .replace(/&#47#(if|list)/g, '/#$1')
            .replace(/<span class="highlight-symbol" contenteditable="false">\$\{<\/span>/g, '${')
            .replace(/<span class="highlight-symbol" contenteditable="false">}<\/span>/g, '}')
            .replace(/<span class="highlight-variable" contenteditable="false">(.*?)<\/span>/g, '$1')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
        ;
        return content;
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.autoSave.destroyAutoSaveSubscription();
        this.unsubscribeWidgetDropAction();
    }

    unsubscribeWidgetDropAction() {
        if (this.widgetDataSubscription) {
            this.widgetDataSubscription.unsubscribe();
        }
    }

    stripHtmlTags(html: string): string {
        return html.replace(/<[^>]*>?/gm, ' ');
    }
}
