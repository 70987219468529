    <ng-container>

        <div class="" style="text-align: end; position: relative; top: -12px; right: 25px;" *ngIf="!contractId">
            <p-button (click)="navigateToNewTab()"
                      icon="pi pi-window-maximize cursor px-1"
                      label="Open in new window"
                      styleClass="p-button-icon-only cursor-pointer p-button-text w-auto"></p-button>
        </div>

        <div  class="grid box-padding" *ngIf="selectedContract">

            <div class="col-3 p-1 shadow-1">
                <mvs-widget *ngIf="widgetOverviewContract"
                            [widgetData]="widgetOverviewContract"
                            [uiStyle]="selectedRelatedPersons"
                ></mvs-widget>

                <mvs-widget *ngIf="contractPriceWidgetData"
                            [widgetData]="contractPriceWidgetData"
                ></mvs-widget>

                <mvs-widget *ngIf="companyContactWidgetData"
                            [widgetData]="companyContactWidgetData"
                            [uiStyle]="selectedRelatedPersons"
                ></mvs-widget>

            </div>

            <div class="col-3 p-1">

                <mvs-widget *ngIf="contractStatusWidgetData"
                            [widgetData]="contractStatusWidgetData"
                            [uiStyle]="selectedRelatedPersons"
                ></mvs-widget>

                <mvs-widget *ngIf="insuredPersonWidgetData"
                            [widgetData]="insuredPersonWidgetData"
                            [uiStyle]="selectedRelatedPersons"
                ></mvs-widget>

                <mvs-widget *ngIf="insurableObjectWidgetData"
                            [widgetData]="insurableObjectWidgetData"
                            [uiStyle]="selectedRelatedPersons"
                ></mvs-widget>
            </div>


            <div class="col-3 shadow-1">
                <div class="col-12 p-1">
                    <mvs-dm-upload
                            *ngIf="contractObjectIdentifier"
                            [objectIdentifier]="contractObjectIdentifier"
                            [layoutVariation]="'s-version'"
                            documentTypeId="3387"
                    ></mvs-dm-upload>
                </div>

                <div class="col-12 p-1">
                    <mvs-dm-object-documents
                            *ngIf="contractObjectIdentifier"
                            [objectIdentifier]="contractObjectIdentifier"
                            layoutVariation="contract"
                    ></mvs-dm-object-documents>
                </div>
            </div>

            <div class="col-3 p-1">
                <!-- Document Comments-->
                <mvs-object-comments
                        headline="Kommentare/ Vetragsinformationen"
                        [mainObject]="contractObjectIdentifier"
                        linkedEntityObjectType="cm.ContractComment"
                ></mvs-object-comments>
            </div>



        </div>
    </ng-container>