import { Component, OnInit } from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-om-employee-page',
  templateUrl: './employee.page.html',
  styleUrls: ['./employee.page.scss']
})
export class EmployeePage extends PageComponent {

  objectBrowserRequest: any;

  defaultLabel: string = "Mitarbeiter";

  activeIndex = 0;

  objectEmployeeWidget: WidgetData;
  objectDataWidget: WidgetData;

  ngOnInit(): void {

    this.refreshObjectEmployeeWidget();

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      "entries": [
        {
          'id'                : 1,
          'objectAlias'       : 'om.Employee',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Mitarbeiter",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },

      ]

    };

    super.ngOnInit();
  }

  refreshObjectEmployeeWidget() {
    this.objectEmployeeWidget = WidgetFactory.createWidgetListEntity(
        'om.object.employee.config.widget',
        'Mitarbeiter',
        'om.Employee',
        'No Data',
        this.getObjectRequestList());
    this.objectEmployeeWidget.uiComponent = 'selectable';
  }



  getObjectRequestList() {
    return ObjectRequestList.createBasic(true, [], []);
  }

  handleObjectSelect(event: ObjectIdentifierData) {

    this.objectDataWidget = WidgetFactory.createWidgetEntityData(
        `${event.objectType}.om.object.employee.widget`,
        '',
        event.objectType,
        event.objectId
    )

  }

  clearWidgets() {
    this.objectDataWidget = null;
  }
}
