import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {UserService} from "../../../../service/api/user.service";
import {RoleService} from "../../../../service/api/role.service";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'um-statistics.component.html',
})
export class UmStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    activeUsers: number = 0;
    inactiveUsers: number = 0;
    newUsers: number = 0;
    workingHours: number = 0;

    userAccessStatisticsWidget: WidgetData;
    permissionGroupsWidget: WidgetData;
    userStatusBreakdownWidget: WidgetData;
    recentlyJoinedUsersWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected userService: UserService,
        protected roleService: RoleService,) {
        super(route, coreService)
    }

    ngOnInit(): void {

        this.items = [
            {label: 'User Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getTotalUsers();
        this.getUserOverviewThisMonth();
        this.refreshRecentlyJoinedUsers();
        this.refreshUserStatistics();
        this.refreshPermissionGroupWidgets();
        this.refreshStatusBreakdownWidgets();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/um/config']);
    }

    getTotalUsers() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['enabled'],
            [new ObjectRequestListAttribute('enabled', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.userService.groupBy(objectRequestList).subscribe(res => {
            this.overviewDto = [
                {label: 'Active Users', value: 0},
                {label: 'Inactivated', value: 0}
            ];

            for (let entry of res.entries) {
                if (entry['enabled'] == true) {
                    this.overviewDto.find(item => item.label === 'Active Users').value = entry['enabled_count'];
                } else {
                    this.overviewDto.find(item => item.label === 'Inactivated').value = entry['enabled_count'];
                }
            }

        });
    }


    getUserOverviewThisMonth() {
        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())],
            [],
        );

        this.userService.list(objectRequestList).subscribe(res => {
            this.newUsers = res.entries.length;
        });

    }

    refreshUserStatistics() {
        this.userAccessStatisticsWidget = new WidgetData();
        this.userAccessStatisticsWidget.idAlias = "um.user.overview.user.access.statistics.widget";
        this.userAccessStatisticsWidget.name = "User Access Statistics";
        this.userAccessStatisticsWidget.uiComponent = "history.chart";
        this.userAccessStatisticsWidget.dataProvider = "list";
        this.userAccessStatisticsWidget.dataSource = "entity.groupBy";
        this.userAccessStatisticsWidget.dataProviderObject = "ig.IgAccessToken";
        this.userAccessStatisticsWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["user", "createdDate"],
            [new ObjectRequestListAttribute("user", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.userAccessStatisticsWidget.setParamValue("fieldCategory", "user");
        this.userAccessStatisticsWidget.setParamValue("fieldCategoryCount", "user_count");
        this.userAccessStatisticsWidget.setParamValue("historyPeriodMode", "year");
        this.userAccessStatisticsWidget.setParamValue("fieldDate", "createdDate");
        this.userAccessStatisticsWidget.setParamValue("selectionMode", "single");
        this.userAccessStatisticsWidget.setParamValue("size", "S");
    }

    refreshPermissionGroupWidgets() {
        this.permissionGroupsWidget = WidgetFactory.createWidgetGroupBy(
            'um.user.overview.permission.group.widget',
            'Permission Group',
            'category',
            'entity.groupBy',
            'um.UserRole',
            'role',
            'role_count',
            this.filterCriteria,
            'role',
            'Anzahl');
    }

    refreshStatusBreakdownWidgets() {
        this.userStatusBreakdownWidget = WidgetFactory.createWidgetGroupBy(
            'um.user.overview.status.breakdown.widget',
            'User Status Breakdown',
            'category',
            'entity.groupBy',
            'um.User',
            'enabled',
            'enabled_count',
            this.filterCriteria,
            'enabled',
            'Anzahl');
    }

    refreshRecentlyJoinedUsers() {

        const objectRequestList = ObjectRequestList.createBasic(
            true,
            // complexRequest,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
            // null
        );
        this.recentlyJoinedUsersWidget = WidgetFactory.createWidgetTableEntity(
            'um.user.overview.recently.joined.user',
            'Recently Joined Users',
            'um.User',
            'No User',
            objectRequestList
        )
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
