import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-cc-field-page',
  templateUrl: './field.page.html',
  styleUrls: ['./field.page.scss']
})

//TODO: Change template Url path to mvs-crud-page html
export class FieldPage implements OnInit {

  objectBrowserRequest: any

  defaultLabel: string = "Feld- Konfiguration";

  ngOnInit(): void {

    this.objectBrowserRequest = {
      'extractDto' : false,
      "createMode": true,

      'entries' : [
        {
          'id' : 1,
          'objectAlias' : 'cc.FieldNamespace',
          'level' : 1,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Namensräume',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id' : 2,
          'objectAlias' : 'cc.Field',
          'level' : 2,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Felder',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id' : 3,
          'objectAlias' : 'cc.FieldType',
          'level' : 1,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Feldtypen',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id' : 4,
          'objectAlias' : 'cc.FieldValueList',
          'level' : 1,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Wertelisten',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },
        {
          'id' : 5,
          'objectAlias' : 'cc.FieldValueListEntry',
          'level' : 1,
          'parentBinding' : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : 'Feldwerte in Werteliste',
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        }
      ]
    };

  }

}
