import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectPageComponent} from "@kvers/alpha-core-common";




@Component({
  selector: 'wf-process',
  templateUrl: './wf-process.page.html'
})
export class WfProcessPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

  defaultLabel: string = "WfProcess";


  protected getObjectType(): string {
    return "wf.WfProcess";
  }

  isBreadcrumbVisible(): boolean {
    return false;
  }


}
