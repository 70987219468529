import {Route, Routes} from "@angular/router";
import {AmConfigPage} from "./page/overview/config/am-config.page";
import {AmTestPage} from "./page/overview/am-test.page.ts/am-test.page";
import {AmAgentOverviewPage} from "./page/overview/am-agent-overview-page/am-agent-overview.page";
import {authGuard} from "@kvers/alpha-auth";
import {AmAppointmentDashboardPage} from "./page/overview/am-appointment-dashboard-page/am-appointment-dashboard.page";
import {AmAppointmentListPage} from "./page/overview/am-appointment-list-page/am-appointment-list.page";

export function AmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'am', component: AgentOverviewPage, canActivate: [authGuard]},
        {path: 'am/config', component: AmConfigPage, canActivate: [authGuard]},
        {path: 'am/test', component: AmTestPage, canActivate: [authGuard]},
        {path: 'am/agents-overview', component: AmAgentOverviewPage, canActivate: [authGuard]},
        {path: 'am/appointment-dashboard', component: AmAppointmentDashboardPage, canActivate: [authGuard]},
        {path: 'am/appointment-list', component: AmAppointmentListPage, canActivate: [authGuard]},

    ];

    return routes;

}
