import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NavigationItem, ObjectPageComponent, PageComponent} from "@kvers/alpha-core-common";



@Component({
  selector: 'cp-provision-agent-page',
  templateUrl: './cp-provision-agent.page.html'
})
export class CpProvisionAgentPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

  defaultLabel: string = "Provision Agent";

  // navigationItems: NavigationItem[] = [
  //   {
  //     label: 'cp.ProvisionAgent',
  //     tooltip: 'Provision Agent',
  //     icon: 'fa fa-calendar-alt', // Represents a time period
  //     toggleable: false,
  //     default: true
  //   },
  //   {
  //     label: 'cp.ProvisionAgentGroup',
  //     tooltip: 'Provision Agent Group',
  //     icon: 'fa fa-user-tie', // Represents an agent or professional
  //     toggleable: false,
  //     default: false
  //   },
  //
  // ];

  ngOnInit() {
    super.ngOnInit();
  }



  protected getObjectType(): string {
    return "cp.ProvisionAgent";
  }



  // isNavComponent(): NavigationItem[] {
  //   return this.navigationItems;
  // }

}
