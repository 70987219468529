import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ObjectChangeInformation, ObjectIdentifier} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'tm-ticket-add-sub-ticket',
    templateUrl: './tm-ticket-add-sub-ticket.component.html',
    styleUrls: ['./tm-ticket-add-sub-ticket.component.scss']
})
export class TmTicketAddSubTicketComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized


    @Input() objectIdentifier: ObjectIdentifier;
    @Input() breadcrumbsItems: MenuItem[] = [];
    @Output() onClose: EventEmitter<string> = new EventEmitter();
    @Output() onChangedObject: EventEmitter<ObjectChangeInformation> = new EventEmitter();
    @Output() onFormDirty: EventEmitter<boolean> = new EventEmitter();


    constructor() {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    handleClose() {
        this.onClose.emit('show-ticket')
    }

    handleChangedObject(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }


    handleFormDirty(event: boolean) {
        this.onFormDirty.emit(event)
    }
    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
