import {Component, OnInit, SimpleChanges} from '@angular/core';
import {XhAnonymizationDataService} from "../../../service/api/xh-anonymization-data.service";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-xx-table-page',
  templateUrl: './xh-anonymize.page.html',
  styleUrls: ['./xh-anonymize.page.scss']
})
export class XhAnonymizePage extends PageComponent {

  defaultLabel: string = "Anonymize Data";

  widgetData: WidgetData;

  anonymizePersons: boolean = false;
  anonymizePersonDetails: boolean = false;

  anonymizeContacts: boolean = false;
  anonymizeAddresses: boolean = false;

  anonymizeAud: boolean = false;
  anonymizeDocuments: boolean = false;

  constructor(
      protected anonymizationService: XhAnonymizationDataService,
      protected messageHandler: MvsMessageService,
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService) {

    super(route, coreService);
  }

  ngOnInit(): void {

    super.ngOnInit();

    this.initComponent();
    this.refreshComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {

    this.widgetData = WidgetFactory.createWidgetTableEntity(
        "xh.anonymize.page.values",
        "Daten für Anonymisierung",
        "xh.AnonymizationData",
        "Keine Daten vorhanden",
        ObjectRequestList.createWithPaging(
            true,
            [],
            [new Sorting("id", false)],
            PagingDto.create(0, 20)
        )
    );

  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  onAnonymize(event: any) {

    if (this.busy)
      return;

    if (!this.anonymizeAddresses &&
        !this.anonymizePersons &&
        !this.anonymizePersonDetails &&
        !this.anonymizeContacts &&
        !this.anonymizeAud &&
        !this.anonymizeDocuments) {
      this.messageHandler.showErrorMessage("Anonymize", "Please select at least one option");
      return;
    }

    this.busy = true;

    this.anonymizationService.anonymizeData(
        this.anonymizePersons,
        this.anonymizePersonDetails,
        this.anonymizeAddresses,
        this.anonymizeContacts,
        this.anonymizeAud,
        this.anonymizeDocuments).subscribe(value => {
      this.messageHandler.showSuccessMessage("Anonymize", "Data was successfully anonymized");
      this.busy = false;
    }, error => {
      this.messageHandler.showErrorMessage("Anonymize", "Data was not anonymized!");
      this.busy = false;
    });

  }


  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }


}
