

<mvs-crud-page
        [objectStructureRequest]="objectBrowserRequest"
        mainObjectType="rp.Report"
        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"
        (onChangedObject)="onChangedObject($event)"
        [showActionBar] = false
        [useOnlyRegularCrudComponent]="true"
>
</mvs-crud-page>
