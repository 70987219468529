import {Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation } from '@angular/core';
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {AgentPoolDto} from "../../../am/dto/agent-pool.dto";
import {AgentPoolAgentDto} from "../../../am/dto/agent-pool-agent.dto";
import {AgentPoolAgentService} from "../../../am/service/api/agent-pool-agent.service";
import {AgentPoolService} from "../../../am/service/api/agent-pool.service";


@Component({
    selector: 'mvs-dashboard-agent-pool-filter',
    templateUrl: './mvs-dashboard-agent-pool-filter.component.html',
})
export class MvsDashboardAgentPoolFilterComponent implements OnInit {

    @Input() displayLabel: string = 'Agent Pools';
    @Input() placeHolder: string = 'Select AgentPool';
    @Input() agentPoolDtoList: DtoList<AgentPoolDto>;

    @Output() onSelect = new EventEmitter<FilterCriteria>();
    @Output() onClear = new EventEmitter();

    initialized: boolean;
    agentPoolAgentDtoList: DtoList<AgentPoolAgentDto>;

    selectedAgentPool: AgentPoolDto;

    constructor(
        protected agentPoolAgentService: AgentPoolAgentService,
        protected agentPoolService: AgentPoolService,) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    initComponent() {
        const objectRequestList = new ObjectRequestList(false, null, null);

        if (this.agentPoolDtoList?.entries?.length) {
            this.refreshComponent();
        } else {

            this.agentPoolService.list(objectRequestList).subscribe(value => {
                this.agentPoolDtoList = value;
                this.refreshComponent();
            });
        }
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    handleAgentPool() {

        const filterCriteriaList = FilterCriteria.create('agentPool', FilterCriteria.cOperatorEqual, this.selectedAgentPool.id)
        this.agentPoolAgentService.list(ObjectRequestList.createBasic(false, [filterCriteriaList], null)).subscribe(agents => {
            this.agentPoolAgentDtoList = <DtoList<AgentPoolAgentDto>>agents;
            this.handleAgentPoolFilter();
        })

    }

    handleAgentPoolFilter() {

        const filterCriteria = FilterCriteria.createOrFromArray("agent", "agentDtoId", this.agentPoolAgentDtoList.entries);
        filterCriteria.label = this.selectedAgentPool.name;
        this.onSelect.emit(filterCriteria);

    }

}
