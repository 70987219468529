import {Component} from '@angular/core';
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";

import {
    WfProcessStepActivitySideBaseComponent
} from "../../../../wf/component/process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessTypeDto} from "../../../../wf/dto/wf-process-type.dto";
import {WfProcessDto} from "../../../../wf/dto/wf-process.dto";
import {
    WfProcessTypeStepActivityFormDefaultDto
} from "../../../../wf/dto/wf-process-type-step-activity-form-default.dto";
import {WfProcessTypeFieldDto} from "../../../../wf/dto/wf-process-type-field.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-object-call',
    templateUrl: './pc-call-wf-process-step-activity-side.component.html',
})
export class PcCallWfProcessStepActivitySideComponent
    extends WfProcessStepActivitySideBaseComponent {

    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    objectIdentifier: ObjectIdentifier;

    /**
     * Refresh Component.
     */
    async refreshComponent() {

        if (!this.activityType) {
            return;
        }

        // TODO: ConnectedObjectId needs to be provided from outside !!!!
        // const field: WfProcessTypeFieldDto = WfProcessTypeDto.getFieldById(this.activityType.objectProcessTypeFieldDtoId, this.process.typeDto)
        //
        // if (field) {
        //     const genericField = `gen_${field.id}DtoId`
        //     const processDto: WfProcessDto = this.process;
        //     this.connectedObjectId = processDto[genericField];
        // }

        /*
        this.createDefaultDto = new DtoDetail({});

        if (this.processRuntime.stepMeta.formDefaults.entries && this.processRuntime.stepMeta.formDefaults.entries.length) {

            for (let formAttributeDefault of this.processRuntime.stepMeta.formDefaults.entries) {

                this.createDefaultDto[formAttributeDefault.fieldName] = WfProcessTypeStepActivityFormDefaultDto.getActualFieldValue(formAttributeDefault, this.process);

                // check if access should be changed via overwrite
                if (formAttributeDefault.fieldAccess !== undefined && formAttributeDefault.fieldAccess !== null) {

                    if (!this.formControlOverwrite) {
                        this.formControlOverwrite = new MvsFormControlOverwrite();
                    }
                    this.formControlOverwrite.addField(formAttributeDefault.fieldName, formAttributeDefault.fieldAccess);
                }

            }

        }

         */

        // this.objectIdentifier = new ObjectIdentifier(this.createDefaultDto['alias'],this.createDefaultDto['customerDtoId']);
        // this.objectIdentifier = new ObjectIdentifier('cr.Customer', this.connectedObjectId);

        this.initialized = true;

    }

}
