import {Component, OnInit} from "@angular/core";
import {IntegrationTypeBaseComponent} from "../integration-type-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'hc-integration-full-component',
    templateUrl: 'integration-type-full.component.html',
    styleUrls: ['integration-type-full.component.scss'],
})

export class IntegrationTypeFullComponent extends IntegrationTypeBaseComponent implements OnInit  {
    variableDisplay: boolean = false;
    navigationItems: NavigationItem[] = [
        {
            label: 'integrationTypeInfo',
            action: 'integrationTypeInfo',
            tooltip: 'Integration Type Info',
            icon: 'fa fa-info-circle',
            toggleable: false,
            default: true
        },
        {
            label: 'integrationType',
            action: 'integrationType',
            tooltip: 'Integration Type Step',
            icon: 'fa-regular fa-chart-diagram',
            toggleable: false,
            default: true
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }

    showVariable() {
        this.variableDisplay = !this.variableDisplay;
    }
}