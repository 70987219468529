import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ConfigPage} from "./page/overview/config/config.page";
import {
    MsBusinessStaffMembersComponent
} from "./component/ms/ms-business-staff-members/ms-business-staff-members.component";
import {MsBookComponent} from "./component/ms/ms-book/ms-book.component";
import {PeriodDurationPipe} from "@kvers/alpha-core-common";
import {AsOverviewPage} from "./page/as-overview-page/as-overview.page";
import {AsStatisticsComponent} from "./page/as-overview-page/components/statisticsComponent/as-statistics.component";
import {AsKpiComponent} from "./page/as-overview-page/components/kpiComponent/as-kpi.component";
import {UiModule} from "../ui/ui.module";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {AsCalendarComponent} from "./component/as-calendar/as-calendar.component";
import {AsAppointmentEventComponent} from "./component/as-appointment-event/as-appointment-event.component";
import {AsCustomerAppointmentPage} from "./component/as-customer-appointment-page/as-customer-appointment.page";
import {
    AsCustomerAppointmentDetailPage
} from "./component/as-customer-appointment-detail-page/as-customer-appointment-detail.page";
import {FloatLabelModule} from "primeng/floatlabel";
import {AsAgentMapComponent} from "./component/as-agent-map/as-agent-map.component";
import {AsFreeSlotComponent} from "./component/as-free-slot/as-free-slot.component";
import {
    AppointmentBookingObjectComponent
} from "./component/object-component/appointment-booking-object-component/appointment-booking-object.component";
import {
    AppointmentBookingBaseComponent
} from "./component/object-component/appointment-booking-object-component/view/appointment-booking-base.component";
import {
    AppointmentBookingSideComponent
} from "./component/object-component/appointment-booking-object-component/view/side/appointment-booking-side.component";
import {
    AppointmentBookingFullComponent
} from "./component/object-component/appointment-booking-object-component/view/full/appointment-booking-full.component";
import {
    AppointmentBookingComponent
} from "./component/object-component/appointment-booking-object-component/shared/appointment-booking-component/appointment-booking.component";

@NgModule({
    declarations: [
        ConfigPage,
        MsBusinessStaffMembersComponent,
        MsBookComponent,
        AsOverviewPage,
        AsStatisticsComponent,
        AsKpiComponent,
        AsCalendarComponent,
        AsAppointmentEventComponent,
        AsCustomerAppointmentPage,
        AsCustomerAppointmentDetailPage,
        AsAgentMapComponent,
        AsFreeSlotComponent,
        AppointmentBookingObjectComponent,
        AppointmentBookingBaseComponent,
        AppointmentBookingSideComponent,
        AppointmentBookingFullComponent,
        AppointmentBookingComponent,
    ],
    exports: [
        ConfigPage,
        MsBookComponent,
        AsCalendarComponent,
        AsAppointmentEventComponent,
        AsCustomerAppointmentPage,
        AsCustomerAppointmentDetailPage,
        AsAgentMapComponent,
        AsFreeSlotComponent,
        AppointmentBookingObjectComponent,
        AppointmentBookingBaseComponent,
        AppointmentBookingSideComponent,
        AppointmentBookingFullComponent,
        AppointmentBookingComponent,
    ],
    imports: [
        CoreModule,
        UiModule,
        PeriodDurationPipe,
        FloatLabelModule
    ]
})
export class AsModule implements DynamicModule {

    alias = 'as';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return AsKpiComponent;
    }

    getStatisticsComponent() {
        return AsStatisticsComponent;
    }
}
