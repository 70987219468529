import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {NavigationItem} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-example',
    templateUrl: './eu-config.component.html',
})
export class EuConfigComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "EU Konfiguration";

    euFrontEndTextWidget: WidgetData;
    EuThemeColorWidget: WidgetData;
    EuTmConfigurationWidget: WidgetData;
    EuStaticNotificationWidget: WidgetData;
    EuStaticNotificationContractPersonTypeWidget: WidgetData;
    EuStaticNotificationContractTypeWidget: WidgetData;
    EuStaticNotificationInsurableObjectTypeWidget: WidgetData;
    EuPromotionWidget: WidgetData;
    // EuTmConfigurationWidget: WidgetData;
    EuNotificationTypeWidget: WidgetData;
    EuUserDocumentTypeWidget: WidgetData;

    objectType: string;
    objectId: number;
    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    activeNavItem: string;
    navigationItems: NavigationItem[] = [
        { label: 'textColors', tooltip: 'Text & Colors', icon: 'fa fa-paint-brush', toggleable: false, default: false },
        { label: 'notifications', tooltip: 'On-Screen Notifications', icon: 'fa fa-bell', toggleable: false, default: false },
        { label: 'promotions', tooltip: 'Promotions', icon: 'fa fa-tags', toggleable: false, default: false },
        { label: 'configuration', tooltip: 'Cross Module Configuration', icon: 'fa fa-tools', toggleable: false, default: false },
    ];

    widgetEntries: TabEntries[] = [
        {label: 'Texte', id: 'eu.EuFrontEndText', type: 'textColors'},
        {label: 'Farben', id: 'eu.EuThemeColor', type: 'textColors'},
        {label: 'Statische Benachrichtigungen', id: 'eu.EuStaticNotification', type: 'notifications'},
        {label: 'Nach Vertragspersonen', id: 'eu.EuStaticNotificationContractPersonType', type: ''},
        {label: 'Nach Vertragstyp', id: 'eu.EuStaticNotificationContractType', type: ''},
        {label: 'Nach Versicherten Objekttyp', id: 'eu.EuStaticNotificationInsurableObjectType', type: ''},
        {label: 'Promotions', id: 'eu.EuPromotion', type: 'promotions'},
        {label: 'Benachrichtigungen', id: 'eu.EuNotificationType', type: 'notifications'},
        {label: 'Ticket Konfiguration', id: 'eu.EuTmConfiguration', type: 'configuration'},
        {label: 'Dokumenttypen Anzeige am User', id: 'eu.EuUserDocumentType', type: 'configuration'},
    ];

    uiWidgetEntries: TabEntries[];

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
        this.objectBrowserRequest = {

            "extractDto": false,
            "createMode": true,

            'entries': [
                {
                    'id'                : 1,
                    'objectAlias'       : 'eu.EuFrontEndText',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Texte",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Neuen Text Anlegen"
                },
                {
                    'id'                : 2,
                    'objectAlias'       : 'eu.EuThemeColor',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Farben",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Neue Farbe Anlegen"
                },
                {
                    'id'                : 3,
                    'objectAlias'       : 'eu.EuTmConfiguration',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Ticket Konfiguration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 4,
                    'objectAlias'       : 'eu.EuStaticNotification',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Statische Benachrichtigungen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 5,
                    'objectAlias'       : 'eu.EuStaticNotificationContractPersonType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Nach Vertragspersonen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 6,
                    'objectAlias'       : 'eu.EuStaticNotificationContractType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Nach Vertragstyp",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 7,
                    'objectAlias'       : 'eu.EuStaticNotificationInsurableObjectType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Nach Versicherten Objekttyp",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 8,
                    'objectAlias'       : 'eu.EuPromotion',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Promotions",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 9,
                    'objectAlias'       : 'eu.EuTmConfiguration',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "TM Konfiguration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 10,
                    'objectAlias'       : 'eu.EuNotificationType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Benachrichtigungen",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
                {
                    'id'                : 11,
                    'objectAlias'       : 'eu.EuUserDocumentType',
                    'level'             : 1,
                    'parentBinding'     : 'none',
                    'dummyParentCreate' : true,
                    'dummyParentLabel'  : "Dokumenttypen Anzeige am User",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel' : "Anlegen"
                },
            ]

        };
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    // subscribeToParams() {
    //     this.queryParamSubscription = this.route.queryParams.subscribe(params => {
    //         if (params['tid']) {
    //             const object = this.widgetEntries.find(item => item.id == params['tid']);
    //             if (object) {
    //                 this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
    //                 this.activeWidget = object;
    //             }
    //         }
    //     });
    // }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.initialized = false;
            let objectType = params['tid'];
            if (params['navItem']) {
                if (this.activeNavItem && this.activeNavItem !== params['navItem']) {
                    objectType = this.getFirstActiveWidget(params['navItem'])?.id;
                    this.router.navigate([], {
                        queryParams: { tid: objectType, 'tid-action': 2 },
                        queryParamsHandling: 'merge'
                    });
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }
                this.activeNavItem = params['navItem'];
                this.uiWidgetEntries = this.getRelevantGroupedEntries(this.activeNavItem);
                if (!objectType) {
                    this.activeTabIndex = 0;
                    this.activeWidget = this.uiWidgetEntries[0];
                }

            }

            if (objectType) {
                const object = this.uiWidgetEntries.find(item => item.id === objectType);
                if (object) {
                    this.activeTabIndex = this.uiWidgetEntries.findIndex(item => item.id === object.id);
                    this.activeWidget = object;
                }
            }

            if (this.activeNavItem != 'notifications' || objectType != 'eu.EuStaticNotification') {
                this.resetWidgets();
            }

            //should be solved after primeng library update (tabPanel not resetting properly)
            setTimeout(() => {
                this.initialized = true;
            },10);

        });
    }

    getFirstActiveWidget(currentNavItem: string) {
        const relevantEntries = this.getRelevantGroupedEntries(currentNavItem);
        if (relevantEntries) {
            return relevantEntries[0];
        }
        return null;
    }

    getRelevantGroupedEntries(currentNavItem: string) {
        return this.widgetEntries.filter(item => item.type === currentNavItem);
    }

    refreshWidgets() {
        this.refreshEuFrontEndTextWidget();
        this.refreshEuThemeColorWidget();
        this.refreshEuTmConfigurationWidget();
        this.refreshEuStaticNotificationWidget();
        // this.refreshEuStaticNotificationContractPersonTypeWidget();
        // this.refreshEuStaticNotificationContractTypeWidget();
        // this.refreshEuStaticNotificationInsurableObjectTypeWidget();
        this.refreshEuPromotionWidget();
        this.refreshEuNotificationTypeWidget();
        this.refreshEuUserDocumentTypeWidget();
    }

    refreshEuFrontEndTextWidget() {
        this.euFrontEndTextWidget = WidgetFactory.createWidgetList(
            "eu.frontend.text.widget.simple",
            'Texte',
            'table',
            'list',
            'entity',
            'eu.EuFrontEndText',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshEuThemeColorWidget() {
        this.EuThemeColorWidget = WidgetFactory.createWidgetList(
            "eu.theme.color.widget.simple",
            'Farben',
            'table',
            'list',
            'entity',
            'eu.EuThemeColor',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    refreshEuTmConfigurationWidget() {
        this.EuTmConfigurationWidget = WidgetFactory.createWidgetList(
            "eu.tm.configuration.widget.simple",
            'Ticket Konfiguration',
            'table',
            'list',
            'entity',
            'eu.EuTmConfiguration',
            null,
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }


    refreshEuStaticNotificationWidget() {
        this.EuStaticNotificationWidget = WidgetFactory.createWidgetList(
            "eu.static.notification.widget.simple",
            'Statische Benachrichtigungen',
            'selectable',
            'list',
            'entity',
            'eu.EuStaticNotification',
            'No data found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }


    refreshEuStaticNotificationContractPersonTypeWidget(filter: FilterCriteria[]) {
        this.EuStaticNotificationContractPersonTypeWidget = WidgetFactory.createWidgetList(
            "eu.static.notification.contract.person.type.widget.simple",
            'Nach Vertragspersonen',
            'table',
            'list',
            'entity',
            'eu.EuStaticNotificationContractPersonType',
            'No data found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }


    refreshEuStaticNotificationContractTypeWidget(filter: FilterCriteria[]) {
        this.EuStaticNotificationContractTypeWidget = WidgetFactory.createWidgetList(
            "eu.static.notification.contract.type.widget.simple",
            'Nach Vertragstyp',
            'table',
            'list',
            'entity',
            'eu.EuStaticNotificationContractType',
            'No data found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }


    refreshEuStaticNotificationInsurableObjectTypeWidget(filter: FilterCriteria[]) {
        this.EuStaticNotificationInsurableObjectTypeWidget = WidgetFactory.createWidgetList(
            "eu.static.notification.insurable.object.type.widget.simple",
            'Nach Versicherten Objekttyp',
            'table',
            'list',
            'entity',
            'eu.EuStaticNotificationInsurableObjectType',
            'No data found!',
            ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 20))
        );
    }


    refreshEuPromotionWidget() {
        this.EuPromotionWidget = WidgetFactory.createWidgetList(
            "eu.promotion.widget.simple",
            'Promotions',
            'table',
            'list',
            'entity',
            'eu.EuPromotion',
            'No data found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }


    refreshEuNotificationTypeWidget() {
        this.EuNotificationTypeWidget = WidgetFactory.createWidgetList(
            "eu.notification.type.widget.simple",
            'Benachrichtigungen',
            'table',
            'list',
            'entity',
            'eu.EuNotificationType',
            'No data found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }


    refreshEuUserDocumentTypeWidget() {
        this.EuUserDocumentTypeWidget = WidgetFactory.createWidgetList(
            "eu.user.document.type.widget.simple",
            'Dokumenttypen Anzeige am User',
            'table',
            'list',
            'entity',
            'eu.EuUserDocumentType',
            'No data found!',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.uiWidgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    handleTemplateSelect(event: ObjectIdentifierData) {
        this.objectType = event.objectType;
        this.objectId = event.objectId;
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));

    }

    handleStaticNotifications(event: ObjectIdentifierData) {
        const filter = FilterCriteria.create('staticNotification',FilterCriteria.cOperatorEqual, event.objectId);
        this.refreshEuStaticNotificationContractPersonTypeWidget([filter]);
        this.refreshEuStaticNotificationContractTypeWidget([filter]);
        this.refreshEuStaticNotificationInsurableObjectTypeWidget([filter]);

    }

    resetWidgets() {
        this.EuStaticNotificationContractPersonTypeWidget = null;
        this.EuStaticNotificationContractTypeWidget = null;
        this.EuStaticNotificationInsurableObjectTypeWidget = null;
    }

    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }
}
