import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent,
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {PersonService} from "../../../../service/api/person.service";
import {PersonTypeService} from "../../../../service/api/person-type.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'pm-kpi.component.html',
})
export class PmKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalPerson: number = 0;
    totalPersonType: number = 0;
    thisMonthPerson: number = 0;
    thisWeekPerson: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Person Module'}];

    home: MenuItem | undefined;

    constructor(
        protected personService: PersonService,
        protected personTypeService: PersonTypeService,) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalPersons();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalPersons() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['gender'],
            [new ObjectRequestListAttribute('gender', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.personService.groupBy(objectRequestList).subscribe(res => {
            this.totalPerson = res.entries.reduce((acc, item) => acc + item['gender_count'], 0);
            this.getTotalPersonTypes();
        },error => {
            this.getTotalPersonTypes();
        });
    }

    getTotalPersonTypes() {

        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.personTypeService.list(objectRequestList).subscribe(res => {
            this.totalPersonType = res.entries.length;
            this.getThisMonthPersons();
        },error => {
            this.getThisMonthPersons();
        });

    }


    getThisMonthPersons() {
        const objectRequestList = this.getThisMonthRequest('gender');
        this.personService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthPerson = res.entries.reduce((acc, item) => acc + item['gender_count'], 0);
            this.getThisWeekPersons();
        }, error => {
            this.getThisWeekPersons();
        });
    }

    getThisWeekPersons() {
        const objectRequestList = this.getThisWeekRequest('gender');
        this.personService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekPerson = res.entries.reduce((acc, item) => acc + item['gender_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Person', value: this.totalPerson,
            },
            {
                label: 'Total Person Types', value: this.totalPersonType,
            },
            {
                label: 'This Month Person', value: this.thisMonthPerson,
            },
            {
                label: 'This Week person', value: this.thisWeekPerson,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
