import {Component, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {AgentService} from "../../../../../am/service/api/agent.service";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {PagingDto} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationProviderGeneric
} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {AgentPoolService} from "../../../../../am/service/api/agent-pool.service";
import {DtoList} from "@kvers/alpha-core-common";
import {AgentPoolDto} from "../../../../../am/dto/agent-pool.dto";
import {AgentPoolAgentService} from "../../../../../am/service/api/agent-pool-agent.service";
import {AgentPoolAgentDto} from "../../../../../am/dto/agent-pool-agent.dto";
import {CallService} from "../../../../service/api/call.service";

@Component({
    selector: 'mvs-pc-my-team-calls-page',
    templateUrl: './pc-my-team-calls.page.html',
})
export class PcMyTeamCallsPage extends PageComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    defaultLabel: string = "Meine Team Anrufe";

    agentPoolDtoList: DtoList<AgentPoolDto>;
    agentPoolAgentDtoList: DtoList<AgentPoolAgentDto>;

    widgetCallResults: WidgetData;
    widgetCallType: WidgetData;
    widgetCallAgent: WidgetData;
    widgetCalls: WidgetData;

    filterCriteria: FilterCriteria[] = [];

    constructor(
        protected callService: CallService,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected agentService: AgentService,
        protected agentPoolService: AgentPoolService,
        protected agentPoolAgentService: AgentPoolAgentService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.agentPoolService.my().subscribe(value => {

            this.agentPoolDtoList = value;

            // retrieve all collagues
            const filterCriteriaList = [FilterCriteria.createOrFromArray("agentPool", "id", this.agentPoolDtoList.entries)];
            this.agentPoolAgentService.list(ObjectRequestList.createBasic(false, filterCriteriaList, null)).subscribe(agents => {
                this.agentPoolAgentDtoList = <DtoList<AgentPoolAgentDto>>agents;

                this.refreshComponent();
            })

        });

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // this.uiRefresh();

        this.initialized = true;
    }

    uiRefresh() {

        const filterCriteriaList = [FilterCriteria.createOrFromArray("agent", "agentDtoId", this.agentPoolAgentDtoList.entries)];

        if (this.filterCriteria && this.filterCriteria.length) {
            for (let filterCriterion of this.filterCriteria) {
                filterCriteriaList.push(filterCriterion);
            }
        }

        // TABLE Widget with all Calls
        this.widgetCalls = WidgetFactory.createWidgetListComplex(
            "pc.my.team.calls.table",
            "Anrufe",
            "table",
            "list",
            "entity",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestList.createWithPaging(
                true,
                filterCriteriaList,
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 20)),
            this.callService.getComplexSelectionCustomerAndLastProtocol(),
            WidgetDataParam.create("selectionMode", "rowSelect")
        );

        // Category Chart - Call Success Rate
        this.widgetCallResults = WidgetFactory.createWidgetList(
            "pc.my.team.calls.result.categories",
            "Erfolgshistorie",
            "category",
            "list",
            "entity.groupBy",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                filterCriteriaList,
                [],
                ["callResult"],
                [new ObjectRequestListAttribute("callResult", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]),

            WidgetDataParam.create("fieldCategory", "callResult"),
            WidgetDataParam.create("fieldCategoryCount", "callResult_count")
        );

        //my calls type
        this.widgetCallType = WidgetFactory.createWidgetList(
            "pc.my.team.calls.type.categories",
            "Call type",
            "category",
            "list",
            "entity.groupBy",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                filterCriteriaList,
                [],
                ["callType"],
                [new ObjectRequestListAttribute("callType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]),

            WidgetDataParam.create("fieldCategory", "callType"),
            WidgetDataParam.create("fieldCategoryCount", "callType_count")
        );


        //my calls agent
        this.widgetCallAgent = WidgetFactory.createWidgetList(
            "pc.my.team.calls.agent.categories",
            "Call Agent",
            "category",
            "list",
            "entity.groupBy",
            "pc.Call",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                filterCriteriaList,
                [],
                ["agent"],
                [new ObjectRequestListAttribute("agent", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]),

            WidgetDataParam.create("fieldCategory", "agent"),
            WidgetDataParam.create("fieldCategoryCount", "agent_count")
        );

    }


    onSetFilterCriteria(objectData: ObjectIdentifierData, field: string) {
        if (this.isFilterCriteriaExists(field)) {
            return;
        }

        const filterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        filterCriteria.label = objectData.data.label;
        this.filterCriteria.push(filterCriteria);

        this.uiRefresh();
    }

    onRemoveFilterCriteria(filter: FilterCriteria) {
        const index = this.filterCriteria.findIndex(item => item.field === filter.field);
        if (index !== -1) {
            this.filterCriteria.splice(index, 1);
            this.uiRefresh();
        }
    }

    private isFilterCriteriaExists(field: string): FilterCriteria {
        return this.filterCriteria.find(res => res.field === field);
    }

    handleDurationFilter(filterCriteria: FilterCriteria) {

        let found: boolean = false;

        for (let filterCriterion of this.filterCriteria) {
            if (filterCriterion.field == filterCriteria.field) {
                filterCriterion.value = filterCriteria.value;
                filterCriterion.valueTo = filterCriteria.valueTo;
                filterCriterion.label = filterCriteria.label;
                found = true;
                break;
            }
        }

        if (!found) {
            this.filterCriteria.push(filterCriteria);
        }

        this.uiRefresh();
    }

    clearDuration() {
        if (this.isFilterCriteriaExists('createdDate')) {
            this.onRemoveFilterCriteria(this.isFilterCriteriaExists('createdDate'));
        }
    }

    clearAgentPool() {
        for (let filterCriterion of this.filterCriteria) {
            if (filterCriterion.or && filterCriterion.or.length) {
                const agentPoolFound = filterCriterion.or.find(item => item.field == 'agent');
                if (agentPoolFound) {
                    this.onRemoveFilterCriteria(filterCriterion);
                }
                return;
            }
        }
    }

    handleAgentPoolFilter(filterCriteria: FilterCriteria) {
        this.clearAgentPool();
        this.filterCriteria.push(filterCriteria);
        this.uiRefresh();
    }

    onRowSelect(objectIdentifierData: ObjectIdentifierData) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifierData.objectType, objectIdentifierData.objectId, "Call", null, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

}
