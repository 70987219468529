import {Component, Input} from '@angular/core';
import {UiNavigationItemDto} from "../../dto/ui-navigation-item.dto";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'mvs-ui-object-navigation-card',
    templateUrl: './ui-object-navigation-card.component.html',
    styleUrls: ['./ui-object-navigation-card.component.scss']
})
export class UiObjectNavigationCardComponent {

    @Input() navigationItem!: UiNavigationItemDto;
    @Input() parentId!: number; // Add this line
    @Input() parentAlias!: string; // Add this line
    @Input() url: string = null;
    link: string = null;

    constructor(protected route: ActivatedRoute) {
    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            const id = params['id'];
            const subId = params['subId'];
            const mainAlias = params['mainAlias'];
            const subAlias = params['subAlias'];

            if (id && !subId) {
                this.link = `/ui/nav/${id}/sub/${this.navigationItem.id}`;
            } else if (mainAlias && !subAlias) {
                this.link = `/${mainAlias}/subNav/${this.navigationItem.alias}`;
            } else {
                this.link = this.navigationItem.pageDto != null ? '/' + this.navigationItem.pageDto.pageRoute : null;
            }

        });

    }

    uiCssColorClassHelper(colorClass: string, part: string = 'bg', contrast: boolean = false): string {
        //  return "bg-blue-600";
        //debugger
        const defaultColorClass: string = part + '-' + 'gray-600';
        // color class is provided and contrast is set
        if (contrast){

            if(colorClass && colorClass.length > 0){
                // split brightness level and calculate contrast
                const brightnessLevel: number = Number(colorClass.substring(colorClass.length-3));
                // wrong or no brightnesslevel set default
                if(isNaN(brightnessLevel)){
                    return defaultColorClass;
                }
                const contrastLevel: number = brightnessLevel - 500;
                let newBrightnessLevel: number;

                if(contrastLevel == 0){
                    newBrightnessLevel = 50;
                }else {
                    if (contrastLevel < 0){
                        newBrightnessLevel = brightnessLevel + 500;
                    }
                    if (contrastLevel > 0){
                        newBrightnessLevel = contrastLevel;
                    }
                    // join css class with contrast brightness
                }
                return part + '-' + colorClass.slice(0,-3) + newBrightnessLevel

            }else {
                return part + '-' + 'gray-100';
            }
        }
        // color class is provided, just return usable color class
        else {
            if(colorClass && colorClass.length > 0){
                return part + '-' + colorClass
            }else{
                return defaultColorClass;
            }
        }

    }
}
