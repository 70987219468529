import {Component} from '@angular/core';
import {
    MvsCoreService, MvsMessageService,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, Sorting,
    TabEntries, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {MessageService} from "primeng/api";


@Component({
    selector: 'mvs-hg-config',
    templateUrl: './hg-config.page.html',
})
export class HgConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Data Hub Configuration";

    activeNavItem: string = "hc";

    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    navigationItems: NavigationItem[] = [
        {
            label: 'hg',
            action: 'hg',
            tooltip: 'GDV Konfiguration',
            icon: 'fa-brands fa-hubspot', // Represents connections or adapters
            toggleable: false,
            default: false
        }
    ];

    managedEntities: { module: string, alias: string, label: string, widgetData?: WidgetData }[] = [
        { module: 'hg', alias: 'hg.HgPartner', label: 'GDV Partner' },
    ];

    batchBusy: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        for (let managedEntity of this.managedEntities) {

            managedEntity.widgetData = WidgetFactory.createWidgetTableEntity(
                "config." + managedEntity.alias + ".simple",
                managedEntity.label,
                managedEntity.alias,
                "Keine Daten vorhanden",
                ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
            );

        }


    }

    // Method to get filtered entities
    getFilteredManagedEntities() {
        return this.managedEntities.filter(entity => entity.module === this.activeNavItem);
    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
        });
    }

    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], [])
    }


    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


}


