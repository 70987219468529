<ng-container *ngIf="initialized">

    <div class="flex flex-column gap-2 p-2" [ngClass]="styleClass" #targetDiv>
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
                <mvs-object-icon class="p-2 text-center {{iconClass}}" style="min-width: 2rem; min-height: 2rem"
                                 [objectType]="objectType"></mvs-object-icon>
                <div class="flex flex-column gap-1">
                    <span class="">{{ name }}</span>
                    <div class="flex gap-2 text-sm text-600">
                        @if (startDate) {
                            <span class="">{{ startDate ? (startDate | date: 'dd.MM.yyyy') : 'N/A' }}</span>
                        } @else if (uiDescription) {
                            <span class="">{{ uiDescription }}</span>
                        }

                        -
                        <span class="text-orange-500"> {{ endDate ? (endDate | date: 'dd.MM.yyyy') : 'N/A' }}</span>
                    </div>

                </div>
            </div>

            <p-tag [style]="statusTagStyle" [value]="status" [icon]="statusIcon" rounded="true"></p-tag>

<!--            <p-tag [style]="{ border: '2px solid var(&#45;&#45;border-color)', background: 'transparent', color: 'var(&#45;&#45;text-color)'}">-->

        </div>

        @if (uiIcon || uiColor || uiName) {
            <div class="flex gap-2 text-sm pl-2 text-{{uiColor}}">
                <i class="{{uiIcon}}"></i>
                <span class="">{{uiName}}</span>
            </div>
        }
    </div>

<!--    <p-contextMenu #cm1 appendTo="body" [model]="contextMenuItems" [target]="targetDiv"></p-contextMenu>-->


    <!--  old design-->

    <!--    <div class="flex justify-content-between align-items-center p-2" [ngClass]="styleClass">-->
    <!--&lt;!&ndash;      <p-avatar [icon]="icon" class="cursor-pointer" styleClass="text-xs bg-primary-100 text-primary" shape="circle"></p-avatar>&ndash;&gt;-->
    <!--      <div class="col-1">-->
    <!--        <mvs-object-icon class="p-2 text-center {{iconClass}}" style="min-width: 2rem; min-height: 2rem" [objectType]="objectType"></mvs-object-icon>-->
    <!--      </div>-->

    <!--      <div class="flex flex-column gap-1 text-sm col-4">-->
    <!--        <span class="text-500">Name</span>-->
    <!--        <span class="">{{ name }}</span>-->
    <!--      </div>-->

    <!--      <div class="flex flex-column gap-1 text-sm col-2">-->
    <!--        <span class="text-500">Start Date</span>-->
    <!--        <span class="">{{startDate ? (startDate | date: 'dd.MM.yyyy') : 'N/A'}}</span>-->
    <!--      </div>-->

    <!--      <div class="flex flex-column gap-1 text-sm col-2">-->
    <!--        <span class="text-500">End Date</span>-->
    <!--        <span class=""> {{endDate ? (endDate | date: 'dd.MM.yyyy') : 'N/A'}}</span>-->
    <!--      </div>-->

    <!--      <div class="flex flex-column gap-1 text-sm col-1">-->
    <!--&lt;!&ndash;        <span class="text-500">Status</span>&ndash;&gt;-->
    <!--        <p-tag [value]="status" rounded="true"></p-tag>-->

    <!--      </div>-->

    <!--      <ng-container *ngIf="buttons && buttons.length">-->
    <!--        <div class="flex justify-content-end gap-1 col">-->
    <!--          <ng-container *ngFor="let button of buttons">-->
    <!--            <div class="flex align-items-center cursor gap-1 overflow-visible" pRipple-->
    <!--                 [pTooltip]="button.label ? null :  button.tooltip" tooltipPosition="bottom" (click)="handleButtonClick(button)">-->
    <!--              <i class="{{button.icon}} p-2 text-color-secondary"-->
    <!--                 *ngIf="button.display">-->
    <!--              </i>-->
    <!--            </div>-->
    <!--          </ng-container>-->
    <!--        </div>-->

    <!--      </ng-container>-->
    <!--    </div>-->


</ng-container>
