import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {DmDocumentService} from "../../../../service/api/dm-document.service";
import {DmDocumentStatusInternalEnum} from "../../../../enum/dm-document-status-internal.enum";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'dm-statistics.component.html',
})
export class DmStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalUploaded: number = 0;
    totalAssigned: number = 0;
    totalCompleted: number = 0;
    totalError: number = 0;


    documentTypeHistoryWidget: WidgetData;
    documentGroupWidget: WidgetData;
    documentStatusWidget: WidgetData;
    recentlyAddedDocumentsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];


    constructor(
        protected documentService: DmDocumentService,
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'DM Module'},
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.getTotalDocuments();
        this.refreshDocumentTypeHistoryWidget();
        this.refreshDocumentGroupWidget();
        this.refreshDocumentStatusWidget();
        this.refreshAddedDocuments();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/dm/config']);
    }

    getTotalDocuments() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            this.filterCriteria,
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.documentService.groupBy(objectRequestList).subscribe(res => {

            this.overviewDto = [
                { label: 'Uploaded Documents', value: 0 },
                { label: 'Assigned Documents', value: 0 },
                { label: 'Completed Documents', value: 0 },
                { label: 'Error Documents', value: 0 }
            ];

            for (let entry of res.entries) {
                if (entry['status'] === DmDocumentStatusInternalEnum.uploaded) {
                    this.overviewDto.find(item => item.label === 'Uploaded Documents').value = entry['status_count'];
                } else if (entry['status'] === DmDocumentStatusInternalEnum.assigned) {
                    this.overviewDto.find(item => item.label === 'Assigned Documents').value = entry['status_count'];
                } else if (entry['status'] === DmDocumentStatusInternalEnum.completed) {
                    this.overviewDto.find(item => item.label === 'Completed Documents').value = entry['status_count'];
                } else if (entry['status'] === DmDocumentStatusInternalEnum.error) {
                    this.overviewDto.find(item => item.label === 'Error Documents').value = entry['status_count'];
                }
            }


        });
    }


    refreshDocumentTypeHistoryWidget() {
        this.documentTypeHistoryWidget = new WidgetData();
        this.documentTypeHistoryWidget.idAlias = "dm.overview.document.type.history.widget";
        this.documentTypeHistoryWidget.name = "Document Type History";
        this.documentTypeHistoryWidget.uiComponent = "history.chart";
        this.documentTypeHistoryWidget.dataProvider = "list";
        this.documentTypeHistoryWidget.dataSource = "entity.groupBy";
        this.documentTypeHistoryWidget.dataProviderObject = "dm.DmDocumentType";
        this.documentTypeHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["documentGroup", "createdDate"],
            [new ObjectRequestListAttribute("documentGroup", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.documentTypeHistoryWidget.setParamValue("fieldCategory", "documentGroup");
        this.documentTypeHistoryWidget.setParamValue("fieldCategoryCount", "documentGroup_count");
        this.documentTypeHistoryWidget.setParamValue("historyPeriodMode", "year");
        this.documentTypeHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.documentTypeHistoryWidget.setParamValue("selectionMode", "single");
        this.documentTypeHistoryWidget.setParamValue("size", "S");
    }

    refreshDocumentGroupWidget() {
        this.documentGroupWidget = WidgetFactory.createWidgetGroupBy(
            'dm.overview.document.group.widget',
            'Document Groups',
            'category',
            'entity.groupBy',
            'dm.DmDocumentType',
            'documentGroup',
            'documentGroup_count',
            this.filterCriteria,
            'documentGroup',
            'Anzahl');
    }

    refreshDocumentStatusWidget() {
        this.documentStatusWidget = WidgetFactory.createWidgetGroupBy(
            'dm.overview.status.widget',
            'Document Status',
            'category',
            'entity.groupBy',
            'dm.DmDocument',
            'status',
            'status_count',
            this.filterCriteria,
            'status',
            'Anzahl');
    }

    refreshAddedDocuments() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedDocumentsWidget = WidgetFactory.createWidgetTableEntity(
            'dm.overview.recently.added.documents',
            'Recently Added Documents',
            'dm.DmDocument',
            'No Documents',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
