import {DtoDetail} from "@kvers/alpha-core-common";
import { WfProcessStepDto } from "./wf-process-step.dto";
import { WfProcessTypeStepActivityDto } from "./wf-process-type-step-activity.dto";
import { WfProcessStepActivityStatusEnum } from "../enum/wf-process-step-activity-status-enum.enum";
import {ObjectTypeDto} from "../../cc/dto/object-type.dto";

export class WfProcessStepActivityDto extends DtoDetail{


    public processStepDto? : WfProcessStepDto;
    public processStepDtoId : number;
    public processStepDtoName : string;
    public processTypeStepActivityDto? : WfProcessTypeStepActivityDto;
    public processTypeStepActivityDtoId : number;
    public processTypeStepActivityDtoName : string;
    public status: WfProcessStepActivityStatusEnum;
    public objectTypeDto? : ObjectTypeDto;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectId: number;

    /**
     * Retrieve processed activity within step.
     * @param step
     */
    public static getProcessStepActivity(step: WfProcessStepDto): WfProcessStepActivityDto[] | null {

        if (!step.activities || step.activities.length === 0) {
            return null;
        }

        const processedStepActivity: WfProcessStepActivityDto[] | null = [];

        for (let activity of step.activities) {
            processedStepActivity.push(activity);
        }

        return processedStepActivity;

    }
}