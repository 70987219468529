<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="cr.customerWelcome"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->


<p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidgets()">
    <p-tabPanel header="Onboarding Konfiguration">
        <ng-container *ngIf="activeIndex == 0">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerRegistrationWidget"
                            [widgetData]="customerRegistrationWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>
    </p-tabPanel>
    <p-tabPanel header="User Konfiguration">
        <ng-container *ngIf="activeIndex == 1">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerUserWidget"
                            [widgetData]="customerUserWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>

        </ng-container>

    </p-tabPanel>
    <p-tabPanel header="Willkommen Seiten">
        <ng-container *ngIf="activeIndex == 2">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerWelcomeWidget"
                            [widgetData]="customerWelcomeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>

    </p-tabPanel>
    <p-tabPanel header="Info Seiten">
        <ng-container *ngIf="activeIndex == 3">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerInfoWidget"
                            [widgetData]="customerInfoWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>

    </p-tabPanel>
</p-tabView>