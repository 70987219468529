import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'cm-create-customer-related-person-object',
    templateUrl: 'cr-create-customer-contract-object.component.html',
})
export class CrCreateCustomerContractObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    objectIdentifier: ObjectIdentifier;
    importObjectContext: DtoImportObjectContext;
    customerId: number;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected override observerService: ObserverService) {
        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.createNew();
        this.initialized = true;
    }

    createNew() {
        const dto: DtoImportObjectContextEntry = this.importObjectContext.getEntry('cr.Customer');
        if (!dto) {
            return;
        }
        this.customerId = dto.id;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }

    handleObjectChange(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }

}

