
<ng-container *ngIf="initialized">
    <div class="grid">
        <div class="col-4">
            <p-panel title="Start Indexing">

                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="requestedObjectTypeIds" class="text-500 text-sm">Index Object Types</label>
                            <p-multiSelect
                                    id="requestedObjectTypeIds"
                                    formControlName="requestedObjectTypeIds"
                                    [options]="objectTypes"
                                    optionLabel="alias"
                                    optionValue="id"
                                    display="chip"
                                    showClear="true"
                            ></p-multiSelect>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="limitProcessingCount" class="text-500 text-sm">Limit Processing Count (Test Only)</label>
                            <p-inputNumber id="limitProcessingCount" name="lastName" formControlName="limitProcessingCount"></p-inputNumber>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="resolveSources" class="text-500 text-sm">Resolve Sources</label>
                            <p-checkbox id="resolveSources" formControlName="resolveSources" [binary]="true"></p-checkbox>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="threadCount" class="text-500 text-sm">Thread Count (0 & 1 = No Threads)</label>
                            <p-inputNumber type="number" id="threadCount" formControlName="threadCount" class="form-control"></p-inputNumber>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="threadPackageSize" class="text-500 text-sm">Thread Package Size</label>
                            <p-inputNumber type="number" id="threadPackageSize" formControlName="threadPackageSize" class="form-control"></p-inputNumber>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="disableReferenceCreation" class="text-500 text-sm">Dsiable reference creation</label>
                            <p-checkbox id="disableReferenceCreation" formControlName="disableReferenceCreation" [binary]="true"></p-checkbox>
                        </div>

                        <div class="flex justify-content-end">
                            <p-button type="submit" label="Start indexing" [disabled]="form.status != 'VALID'"></p-button>
                        </div>
                    </div>

                </form>

            </p-panel>
        </div>
        <div class="col-8">
            <mvs-widget *ngIf="indexWidget" [widgetData]="indexWidget" (onObjectSelect)="onSelect($event)"></mvs-widget>
        </div>
    </div>

</ng-container>









