import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseComponent,
    ObjectRequestList,
    WidgetData,
    MvsCoreService,
    MvsMessageService,
    ObjectIdentifier,
    DtoDetail,
    WidgetToolbarCallbackInterface,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
} from '@kvers/alpha-core-common';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {WidgetFactory} from '@kvers/alpha-ui';
import {ObserverService} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common/lib/basic/object-identifier-data";


@Component({
    selector: 'contract-base-type-object-component',
    templateUrl: './contract-base-type.component.html',
    styleUrls: ['./contract-base-type.component.scss'],
})
export class ContractBaseTypeComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    sideBarMenuItems: MenuItem[];
    breadCrumbItems: MenuItem[] = [];
    selectedItem: MenuItem;
    objectViewWidget: WidgetData;
    objectWidget: WidgetData;

    contractBaseTypeWidget: WidgetData;
    contractBaseTypeUpdateWidget: WidgetData;
    contractBaseTypeReasonWidget: WidgetData;
    automaticTicketWidget: WidgetData;

    selectedObject: ObjectIdentifier;

    activeIndex: number;
    showBreadCrumb: boolean = false;
    isContractTypeUpdate: boolean;
    isCreateMode: boolean;
    selectedObjectType: string;


    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected observerService: ObserverService,
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    /**
     * On Object Change. When object is retrieved
     */
    onObjectChanged() {



        // push the object to breadcrumb
        this.breadCrumbItems.push({
            icon: this.dto['image'],
            label: this.dto['name'],
            id: this.dto.id as unknown as string,
        });

        // initialize all widgets
        this.refreshWidgets();

        // initialize navigation items
        this.loadSideBarMenuItems();

        // checks if ticket type has parent then gets all of its parent
        this.breadCrumbItems[0]['lastItem'] = true;
        this.showBreadCrumb = true;

        // setting tab title
        const title = `${this.dto.name} - Konfiguration`;

        this.setPageTitle(title);
    }

    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {
        this.sideBarMenuItems = [
            {
                id: 'cm.ContractBaseType',
                label: 'Contract Base Type',
                icon: 'fa-solid fa-file text-xl',
                tooltip: 'Contract Base Type',
                anchorId: 'contractBaseType'
            },
            {
                id: 'cm.ContractBaseTypeReason',
                label: 'Contract Base Type Reason',
                icon: 'fa-solid fa-circle-info text-xl',
                tooltip: 'Contract Base Type Reason',
                anchorId: 'contractBaseTypeReason'
            },
            {
                id: 'cm.ContractBaseTypeTicketType',
                label: 'Automatic Tickets',
                icon: 'fa-solid fa-ticket text-xl',
                tooltip: 'Automatic Tickets',
                anchorId: 'automaticTickets'
            },

        ];

        this.selectedItem = this.sideBarMenuItems[0];
    }



    /**
     * handle whether to show data widget or object widget
     * @param object
     * @param fieldName
     */
    handleObjectViewWidget(object: ObjectIdentifierData, fieldName: string) {
        this.clearWidget();
        this.selectedObject = object;

        this.objectViewWidget = WidgetFactory.createWidgetEntityData(
            `${object.objectType}.object.view.widget`,
            `${object.data[fieldName]}`,
            object.objectType,
            object.objectId
        );

    }

    /**
     * clear widget data when navigate
     */
    clearWidget() {
        this.isCreateMode = false;
        this.isContractTypeUpdate = false;
        this.selectedObject = null;
        this.objectViewWidget = null;
        this.objectWidget = null;
    }

    /**
     * initialize widgets data
     */
    refreshWidgets() {
        this.refreshContractBaseType();
        this.refreshContractBaseTypeReason();
        this.refreshAutomaticTicketWidget();
    }

    /**
     * refresh ticket type widget
     */
    refreshContractBaseType() {
        this.contractBaseTypeWidget = WidgetFactory.createWidgetEntityData(
            'cm.contract.base.type.widget.basic',
            'Contract Base Type',
            'cm.ContractBaseType',
            this.objectIdentifier.objectId
        );

        this.contractBaseTypeUpdateWidget = WidgetFactory.createWidgetObject(
            'cm.contract.base.type.widget.basic.create',
            'Contract Base Type',
            'cm.ContractBaseType',
            this.objectIdentifier.objectId
        );
    }

    /**
     * refresh ticket type action widget
     */
    refreshContractBaseTypeReason() {
        const filterCriteria = [FilterCriteria.create('baseType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        const objectRequest = ObjectRequestList.createBasic(true, filterCriteria, []);

        this.contractBaseTypeReasonWidget = WidgetFactory.createWidgetListEntity(
            'cm.contract.base.type.reason.widget.basic',
            'Contract Base Type Reason',
            'cm.ContractBaseTypeReason',
            'No Data',
            objectRequest
        );
        this.contractBaseTypeReasonWidget.uiComponent = 'selectable';
        this.contractBaseTypeReasonWidget.functionCallbacks = this.widgetToolbarCreate('baseTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type action ticket mapping widget
     */
    refreshAutomaticTicketWidget() {
        const filterCriteria = [FilterCriteria.create("contractBaseType", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        const objectRequest = ObjectRequestList.createBasic(true, filterCriteria, []);

        this.automaticTicketWidget = WidgetFactory.createWidgetListEntity(
            'cm.contract.automatic.tickets.widget.basic',
            'Automatic Ticket',
            'cm.ContractBaseTypeTicketType',
            'No Data',
            objectRequest
        );
        this.automaticTicketWidget.uiComponent = 'selectable';
        this.automaticTicketWidget.functionCallbacks = this.widgetToolbarCreate('contractBaseTypeDtoId', this.objectIdentifier.objectId);
    }


    /**
     * handle navigation change
     * @param event
     */
    handleNavigationChange(event: MenuItem) {
        this.clearWidget();
        this.refreshWidgets();

        this.selectedItem = event;

    }

    /**
     * defaulting fields for adding new entry
     * @param fieldName
     * @param fieldValue
     */
    widgetToolbarCreate(fieldName: string, fieldValue: number) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[fieldName] = fieldValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(fieldName, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}
