import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    DtoList,
    FilterCriteria,
    ObjectIdentifier,
    ObjectRequestList,
    ObjectTypeService, WidgetData
} from "@kvers/alpha-core-common";
import {TmTicketDto} from "../../../../dto/tm-ticket.dto";
import {DmDocumentDto} from "../../../../../dm/dto/dm-document.dto";
import {ObjectTypeDto} from "../../../../../cc/dto/object-type.dto";
import {DmDocumentAssignmentEuService} from "../../../../../dm/service/api/dm-document-assignment-eu.service";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {DmDocumentAssignmentDto} from "../../../../../dm/dto/dm-document-assignment.dto";
import {DmDocumentAssignmentEuDto} from "../../../../../dm/dto/dm-document-assignment-eu.dto";

@Component({
    selector: 'tm-ticket-attachment',
    templateUrl: './tm-ticket-attachment.component.html',
    styleUrls: ['./tm-ticket-attachment.component.scss']
})
export class TmTicketAttachmentComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    toggle: boolean;
    documentEuWidget: WidgetData;
    @Input() euUserDocuments: DtoList<DmDocumentAssignmentEuDto>;
    @Input() processMode: boolean = false;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() ticketDto: TmTicketDto;
    @Output() onDocumentUploaded = new EventEmitter();

    constructor(protected objectTypeService: ObjectTypeService,
                protected navigationService: MvsObjectNavigationService,
                ) {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        if (this.processMode == true) {
            this.toggle = true;
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        if (this.euUserDocuments) {
            this.prepareDocumentEuWidget(this.euUserDocuments);
        }
        this.initialized = true;
    }

    prepareDocumentEuWidget(res: DtoList) {
        this.documentEuWidget = WidgetFactory.createWidgetTransient(
            'tm.ticket.attachment.eu.document.users.basic.widget',
            "End User Documents",
            'table',
            'transient',
            'entity',
            'cr.Customer',
            res,
        );
    }

    onObjectSelect(dto: DmDocumentAssignmentDto) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
            "dm.DmDocument",
            dto.documentDtoId,
            'object',
            "Dokument " + dto.objectTypeDtoId,
            null,
            null,
            MvsObjectNavigationActionEnum.any,
            'side');

        this.navigationService.navigateTo(mvsObjectNavigationEntry, "left");

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    handleDocumentUploaded(event: DmDocumentDto) {

    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
