import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiCrudOnlyReadService} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TicketStatusService extends MvsApiCrudOnlyReadService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketStatuss');
  }

}

