<ng-container *ngIf="initialized">

    <div class="flex gap-3 align-items-center">

        <p-dropdown
                [options]="ticketTypes"
                [(ngModel)]="ticketTypeId"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Ticket Type"/>

        <p-button label="Create" [disabled]="!ticketTypeId" (click)="handleCreateTicket()"></p-button>

    </div>

</ng-container>