import {Component, OnInit} from '@angular/core';
import {LgLogicObjectBaseComponent} from "../lg-logic-object-base.component";

@Component({
    selector: 'mvs-lg-logic-object-component-side',
    templateUrl: 'lg-logic-object-side.component.html'
})
export class LgLogicObjectSideComponent extends LgLogicObjectBaseComponent implements OnInit {


    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {
        this.navigationItems = [
            {
                label: 'logic',
                action: 'logic',
                icon: 'fa-regular fa-square-code',
                tooltip: 'Logic',
                toggleable: false,
                default: true
            },
            {
                label: 'testen',
                action: 'testen',
                icon: 'fa-regular fa-flask-vial',
                tooltip: 'Testen',
                toggleable: false,
                default: false
            },
            {
                label: 'imports',
                action: 'imports',
                icon: 'fa-regular fa-file-import',
                tooltip: 'Imports',
                toggleable: false,
                default: false
            },
            {
                label: 'exports',
                action: 'exports',
                icon: 'fa-regular fa-file-export',
                tooltip: 'Exports',
                toggleable: false,
                default: false
            },
        ];

        this.activeNavigationItem = this.navigationItems[0];
        this.handleNavigationItemsChange();
    }
}
