import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../wf-process-step-activity-base/wf-process-step-activity-base.component";
import {SearchResultDto} from "../../../../si/logic/search-result.dto";
import {SearchResultEntryDto} from "../../../../si/logic/search-result-entry.dto";
import {WfActivityProcessingResultFieldDto} from "../../../service/dto/wf-activity-processing-result-field.dto";
import {WfProcessRuntimeService} from "../../../service/wf-process-runtime.service";
import {FilterCriteria, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";


/**
 * TODO: Describe which activities are processed here ...
 */
@Component({
    selector: 'mvs-wf-process-step-activity-search',
    templateUrl: './wf-process-step-activity-search.component.html',
})
export class WfProcessStepActivitySearchComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {


    // TODO: will adjust WfProcessTypeStepActivityService to get the component based on activity type

    objectType: string;
    selectedObjectId: number;
    searchedResultEntries: SearchResultEntryDto[];
    @Input() searchString: string;
    objectTypeSelectionWidget: WidgetData;

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        // TODO: will get object type in activity type

        // TODO: will adjust later as per API result


        this.objectType = this.activityType.activityObjectDto['objectTypeAlias']

        this.initialized = true;
    }

    /**
     * following method gets the searched results
     * @param searchResult
     */
    onSearchResult(searchResult: SearchResultDto) {
        this.searchedResultEntries = searchResult?.searchResultEntries;

        this.handleSelectionWidget();

    }

    /**
     * method gets objectId and update the process component
     * @param objectId
     */
    onSelect(objectId: number) {

        this.selectedObjectId = objectId;

        const result: WfActivityProcessingResultFieldDto = {
            //TODO: ask alias and action from marko
            alias: 'search',
            action: null,
            valueObjectTypeAlias: this.objectType,
            valueObjectId: objectId
        };

        WfProcessRuntimeService.setResult([result])
    }

    handleSelectionWidget() {

        const filterCriteria = FilterCriteria.createOrFromArray('id','objectId',this.searchedResultEntries);

        const objectRequestList = new ObjectRequestList(true, [filterCriteria],[]);

        this.objectTypeSelectionWidget = WidgetFactory.createWidgetTableEntity(`wf.process.step.activity.search.${this.objectType}`,'Search List',this.objectType,'No Result',objectRequestList)

    }

    onObjectSelect() {

    }



}
