import {Route, Routes} from "@angular/router";
import {TeConfigPage} from "./page/overview/config/te-config.page";
import {TeConfigTestPage} from "./page/overview/config-test/te-config-test.page";
import {TeOverviewPage} from "./page/te-overview-page/te-overview.page";

export function TeSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'te', component: TeOverviewPage},
        {path: 'te/config', component: TeConfigPage},
        {path: 'te/config-test', component: TeConfigTestPage},

    ];

    return routes;

}
