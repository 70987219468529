import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
  CrCustomerInteractionInlineComponent
} from "../../component/cr-customer-interaction-inline-component/cr-customer-interaction-inline-component";

@Injectable({
  providedIn: 'root'
})
export class CustomerInteractionService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerInteractions')
  }

  getInlineComponent(): Type<any> {
    return CrCustomerInteractionInlineComponent;
  }
}