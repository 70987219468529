<ng-container *ngIf="initialized">

    <div class="grid">
                <div class="col-6">

                    <div class="widget-boundary bg-white">
                        <mvs-object-document-component
                                *ngIf="objectIdentifier"
                                [objectTypeAlias]="'cm.Contract'"
                                [objectId]="objectIdentifier.objectId"
                                [viewOptions]="dmDocumentViewOptionsEnumEnum.default"
                                [widthSize]="'500px'"
                        ></mvs-object-document-component>
                    </div>

                </div>

        <div class="col-6">

            <div class="widget-boundary bg-white">
                <mvs-object-document-component
                        *ngIf="objectIdentifier"
                        [objectTypeAlias]="'cm.Contract'"
                        [uiComponent]="'timeline'"
                        [objectId]="objectIdentifier.objectId"
                        [viewOptions]="dmDocumentViewOptionsEnumEnum.default"
                        [widthSize]="'500px'"
                ></mvs-object-document-component>
            </div>

        </div>
    </div>

</ng-container>