import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WfProcessTypeStepActivityDto} from "../../../dto/wf-process-type-step-activity.dto";
import {TabView} from "primeng/tabview";
import {WfProcessStepActivityDto} from "../../../dto/wf-process-step-activity.dto";

@Component({
    selector: 'mvs-wf-process-step-optional-activity-object-create',
    templateUrl: './wf-process-step-optional-activity-object-create.component.html',
})
export class WfProcessStepOptionalActivityObjectCreateComponent implements OnInit {

    @Input() activityTypes: WfProcessTypeStepActivityDto[];
    @Input() stepActivities: WfProcessStepActivityDto[];
    @Input() tabIndex: number;
    @Input() combinedActivities: (WfProcessTypeStepActivityDto | WfProcessStepActivityDto)[];
    @Output() onTabChange = new EventEmitter<any>();
    @Output() onActivitySelect = new EventEmitter<WfProcessStepActivityDto>;

    @ViewChild('tabView') tabView: TabView;

    constructor() {
    }

    ngOnInit() {

    }

    onOptionalComponentSelect(event) {
        this.onActivitySelect.emit(this.stepActivities[event.index]);
    }

}
