import {Component, Input, OnChanges, OnDestroy, OnInit,ViewEncapsulation } from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Observable, Subject} from "rxjs";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TreeSelect} from "primeng/treeselect";
import {debounceTime} from "rxjs/operators";
import {Sorting} from "@kvers/alpha-core-common";
import {AgentService} from "../../../../../am/service/api/agent.service";
import {MvsFormFieldBaseComponent} from "@kvers/alpha-ui";
import {AgentPoolService} from "../../../../../am/service/api/agent-pool.service";
import {AgentPoolAgentService} from "../../../../../am/service/api/agent-pool-agent.service";
import {AgentPoolAgentDto} from "../../../../../am/dto/agent-pool-agent.dto";
import {AgentDto} from "../../../../../am/dto/agent.dto";

@Component({
    selector: 'mvs-form-field-agent-pool-agent',
    templateUrl: 'mvs-form-field-agent-pool-agent.component.html'
})
export class MvsFormFieldAgentPoolAgentComponent
    extends MvsFormFieldBaseComponent
    implements OnInit, OnDestroy, OnChanges {

    @Input() objectTypes: string[];
    objectTypeList: ObjectTypeDto[];
    objectIdentifier: ObjectIdentifier;
    selectedObjectType: string;
    formControlName: string;

    formFieldObjectType: MvsFormFieldDto;

    protected objectService: ObjectTypeService

    agentService: AgentService;
    agentPoolService: AgentPoolService;
    agentPoolAgentService: AgentPoolAgentService;

    agentPoolList: AgentPoolAgentDto[];
    agentDtoList: AgentDto[];
    treeData: any;

    selectedAgentPool: string;
    selectedAgent: number;
    uiAssigneePlaceholder: string;

    searchSubject = new Subject<string>();
    otherAgentsList: AgentDto[];

    fieldNameAgent: string;
    fieldNameAgentName: string;
    fieldNameAgentPool: string;
    fieldNameAgentPoolName: string;
    agentName: string;
    agentPoolName: string;

    isSearch: boolean = false;
    searchObservable: any;

  override ngOnInit() {


        this.agentService = <AgentService>this.coreService.getCrudService("am.Agent");

        this.agentPoolService = <AgentPoolService>this.coreService.getCrudService("am.AgentPool");

        this.agentPoolAgentService = <AgentPoolAgentService>this.coreService.getCrudService("am.AgentPoolAgent");

        this.retrieveData();

        this.refreshComponent();

    }

    retrieveData(){
        this.getAgentPools();
    }

    getAgentPools() {

        const dtoRequest = new ObjectRequestList(false, [], [new Sorting('name', true)]);

        this.agentPoolService.list(dtoRequest).subscribe((value: any) => {
            this.agentPoolList = value.entries;
            this.generateTreeData();
        })
    }

    handleSelection(event: any) {

        const node = event.node;

        if (node.label == 'Other Agents') {
            this.isSearch = true;
            this.uiAssigneePlaceholder = node.label;
        } else {

            this.isSearch = false;
            if (node.parent && node.parent.label == 'Other Agents') {
                this.selectedAgentPool = null;
                this.selectedAgent = node.id;
                this.uiAssigneePlaceholder = node.label;

            } else if (!node.parent) {
                this.selectedAgentPool = node.id;
                this.selectedAgent = null;
                this.uiAssigneePlaceholder = node.label;

            } else {
                this.selectedAgent = node.id;
                this.selectedAgentPool = node.parent.id;
                this.uiAssigneePlaceholder = node.parent.label + ' (' + node.label + ')';
            }

            this.patchValues();
        }
    }

    generateTreeData() {
        const treeMap = {};
        const treeMapAgentPool = {};

        // Process agents with agentPools
        for (const agentPool of this.agentPoolList) {
            const agentPoolName = agentPool['name'];
            const agentPoolId = agentPool.id;

            if (!treeMap[agentPoolName]) {
                treeMap[agentPoolName] = [];
            }

            treeMapAgentPool[agentPoolName] = agentPoolId;
        }

        // //add Other agents
        // treeMap['Other Agents'] = [];
        // treeMapAgentPool['Other Agents'] = 0;

        // Create the final tree structure
        this.treeData = Object.keys(treeMap).map(agentPoolName => {
            let children = [{}];
            if (agentPoolName == 'Other Agents') {
                children = [];
            }

            return {
                label: agentPoolName,
                children: children,
                id: treeMapAgentPool[agentPoolName],
                leaf: true
            };
        });
    }

  override refreshComponent() {
        this.handleExistingObject();
    }

    patchValues() {

        this.formGroup.patchValue({
            [this.fieldNameAgent]: this.selectedAgent,
            [this.fieldNameAgentPool]: this.selectedAgentPool,
        });

        // this.formGroup.patchValue({
        //     ['assigneeAgentDtoId']: this.selectedAgent,
        //     ['assigneeAgentPoolDtoId']: this.selectedAgentPool,
        // });
    }

    handleExistingObject() {

        if (this.formField.id.endsWith("AgentPoolDtoId")) {
            // linked against agent Pool field
            this.fieldNameAgentPool = this.formField.id;
            this.fieldNameAgent = this.fieldNameAgentPool.replace("AgentPoolDtoId", "AgentDtoId");
        } else if (this.formField.id.endsWith("AgentDtoId")) {
            // linked against agent field
            this.fieldNameAgent =  this.formField.id;
            this.fieldNameAgentPool = this.fieldNameAgent.replace( "AgentDtoId", "AgentPoolDtoId");
        }

        this.fieldNameAgentName = this.fieldNameAgent.replace( "Id", "Name");
        this.fieldNameAgentPoolName = this.fieldNameAgentPool.replace( "Id", "Name");

        this.agentName = this.formGroup.value[this.fieldNameAgentName];
        this.agentPoolName = this.formGroup.value[this.fieldNameAgentPoolName];


        // let agentName: string, agentPoolName: string;
        // if (this.formGroup.value['assigneeAgentDtoId']) {
        //     this.selectedAgent = this.formGroup.value['assigneeAgentDtoId'];
        //     agentName = this.formGroup.value['assigneeAgentDtoName'];
        // }
        //
        // if (this.formGroup.value['assigneeAgentPoolDtoId']) {
        //     this.selectedAgentPool = this.formGroup.value['assigneeAgentPoolDtoId'];
        //     agentPoolName = this.formGroup.value['assigneeAgentPoolDtoName'];
        // }

        if (this.agentPoolName && this.agentName){
            this.uiAssigneePlaceholder = this.agentName + ' (' + this.agentPoolName + ')';
        } else {
            this.uiAssigneePlaceholder = this.agentName;
        }

        this.initialized = true;
    }

    handleNodeExpand(event: any, tree: TreeSelect){
        tree.nodeCollapse(event.originalEvent);

        const node = event.node;

        if (node.children[0].label || node.label == 'Other Agents') {
            return;
        }

        const filterCriteria = FilterCriteria.create('agentPool', FilterCriteria.cOperatorEqual, node.id);

        const dtoRequest = new ObjectRequestList(false,
            [filterCriteria],
            []
        );

        this.agentPoolAgentService.list(dtoRequest).subscribe(res => {

            this.addChildrenToNode(node.label, res.entries);
            tree.nodeExpand(event.originalEvent);

        })
    }

    addChildrenToNode(name: string, children: any) {
        const node = this.treeData.find(item => item.label == name);
        node.children = [];

        for (let item of children) {
            node.children.push({label: item.agentDtoName, id: item.agentDtoId, leaf: true})
        }

        node.children.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }

    search(event: any) {
        const searchQuery = event.query;
        this.handleSearchQuery(searchQuery);
    }

    handleSearchQuery(event: any) {
        if (this.searchObservable) {
            this.searchObservable.unsubscribe();
        }
        this.searchObservable = this.searchSubject.pipe(debounceTime(800))
            .subscribe(searchString => {
                const filterCriteria = FilterCriteria.create(
                    'name',
                    FilterCriteria.cOperatorContainsPattern,
                    '%' + searchString + '%',
                    null
                );
                const dtoRequest = new ObjectRequestList(
                    false,
                    [filterCriteria],
                    null
                );
                // dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
                //     false,
                //     false,
                //     ObjectRequestComplexNode.createRelationNode(
                //         'agentPools',
                //         ObjectRequestRelation.createList(
                //             'am.AgentPoolAgent',
                //             'agent',
                //             null,
                //             null,
                //             ObjectRequestComplexRelationBindingEnum.ANY
                //         )
                //     )
                // );

                this.agentService.list(dtoRequest).subscribe(res => {
                    res.entries.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    this.otherAgentsList = res.entries;
                });
            });
        this.searchSubject.next(event);

    }

    onFreeAgentSelect(agent: AgentDto) {
        this.selectedAgent = agent.id;
        this.selectedAgentPool = null;
        this.patchValues();
    }

}
