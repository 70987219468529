<span *ngIf="!hideLabel" class="text-lg text-800 font-medium p-3">Kommentare</span>
<div class="p-3">
    <div
            *ngIf="!focused"
            class="formgroup-inline align-items-center">
<!--        <textarea-->
<!--                placeholder="Enter your comment"-->
<!--                class="w-full"-->
<!--                (focusin)="focused = true"-->
<!--                type="text"-->
<!--                pInputText>-->
<!--        </textarea>-->
        <input placeholder="Enter your comment"
               class="w-full"
               (focusin)="focused = true"
               type="text"
               pInputText />
    </div>
    <div
            *ngIf="focused"
            class="formgroup-inline align-items-center rounded-normal"
            [class.custom-field-disabled]="busy">
        <div class="col-12">

            <mvs-quill-editor
                    [content]="content"
                    [isEndUserTicket]="euVisible"
                    [documentTypeId]="documentTypeId"
                    [objectIdentifier]="objectIdentifier"
                    (onSave)="createComment($event)"
                    (onContentChange)="handleInputChange($event)">
            </mvs-quill-editor>

<!--                    <textarea-->
<!--                            [disabled]="busy"-->
<!--                            pInputTextarea-->
<!--                            [mention]="userNamesList"-->
<!--                            [(ngModel)]="newComment"-->
<!--                            (input)="handleInputChange()"-->
<!--                            placeholder="Enter your comment"-->
<!--                            class="w-full h-full textarea-blank"-->
<!--                    ></textarea>-->
        </div>
<!--        <div class="col-12 flex justify-content-end">-->
<!--            <div class="flex">-->
<!--&lt;!&ndash;                <button&ndash;&gt;-->
<!--&lt;!&ndash;                        *ngIf="newComment"&ndash;&gt;-->
<!--&lt;!&ndash;                        [disabled]="busy"&ndash;&gt;-->
<!--&lt;!&ndash;                        (click)="createComment()"&ndash;&gt;-->
<!--&lt;!&ndash;                        pButton&ndash;&gt;-->
<!--&lt;!&ndash;                        pRipple&ndash;&gt;-->
<!--&lt;!&ndash;                        type="button"&ndash;&gt;-->
<!--&lt;!&ndash;                        icon="pi pi-send"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="p-button-rounded -mt-5"&ndash;&gt;-->
<!--&lt;!&ndash;                ></button>&ndash;&gt;-->

<!--                <p-button pTooltip="Use recovered value" tooltipPosition="left"-->
<!--                          *ngIf="recoveredText && !newComment"-->
<!--                          (click)="loadRecoveredValue()"-->
<!--                          class="align-self-center"-->
<!--                          styleClass="w-2rem h-2rem p-button-rounded"-->
<!--                          icon="fa-regular fa-arrow-rotate-right text-sm">-->
<!--                </p-button>-->
<!--            </div>-->

<!--        </div>-->
    </div>
</div>