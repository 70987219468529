
<ng-container *ngIf="initialized">



    <div class="grid">

        <div class="col-12">
            <h4>Create Customer</h4>

            <mvs-cr-create-object-customer></mvs-cr-create-object-customer>
        </div>

    </div>


    <div class="grid">

        <div class="col-4">

            <mvs-widget
                    *ngIf="widgetCustomerTypes"
                    [widgetData]="widgetCustomerTypes"
            ></mvs-widget>

        </div>
        <div class="col-4">

            <mvs-widget
                    *ngIf="widgetCustomerStatus"
                    [widgetData]="widgetCustomerStatus"
            ></mvs-widget>

        </div>

        <div class="col-12">
            <mvs-widget
                    *ngIf="widgetCustomerHistory"
                    [widgetData]="widgetCustomerHistory"
            ></mvs-widget>

        </div>

        <div class="col-12">

            <mvs-widget
                    *ngIf="widgetCustomerTable"
                    [widgetData]="widgetCustomerTable"
            ></mvs-widget>

        </div>
    </div>



</ng-container>
