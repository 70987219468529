<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme">

    <ng-container *ngIf="showTopBar != false">
        <app-topbar></app-topbar>
    </ng-container>

    <app-rightmenu></app-rightmenu>


    <!-- Sidebar Navigation -->
    <mvs-object-navigation-sidebar location="left"></mvs-object-navigation-sidebar>
    <mvs-object-navigation-sidebar location="right"></mvs-object-navigation-sidebar>
    <mvs-object-navigation-sidebar location="bottom"></mvs-object-navigation-sidebar>

    <!-- Overlay Navigation -->
    <mvs-object-navigation-overlay></mvs-object-navigation-overlay>

    <div class="menu-wrapper" *ngIf="navigationItems">
        <div class="layout-menu-container">
            <ul class="layout-menu mt-3">
                <ng-container *ngFor="let item of navigationItems;  let i = index;">
                    <li class="mb-3" app-menuitem *ngIf="!item.divider"
                        [item]="item"
                        [index]="i"
                        [ngClass]="item.label == selectedNavItem ? 'bg-primary-50 active-side-nav' : ''"
                        [root]="true"
                        (click)="onMenuClick(item)">
                    </li>
                    <p-divider styleClass="border-top-1 border-400 mt-2 mb-4" *ngIf="item.divider"></p-divider>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="layout-main" [ngClass]="{'mx-0': !navigationItems, 'pt-0' : showTopBar == false}">
        <!--<app-breadcrumb></app-breadcrumb> -->
        <div *ngIf="showBreadcrumb"
             class="layout-breadcrumb-container flex justify-content-between align-items-center pl-1 pr-4 py-3">
            <mvs-object-navigation-bread-crumb></mvs-object-navigation-bread-crumb>
            <div class="flex align-items-center">
                <mvs-object-navigation-auto-complete></mvs-object-navigation-auto-complete>
            </div>
        </div>

        <div class="layout-content px-5 m-0 w-auto"
             [ngClass]="{ 'config-background': isConfigRoute, 'pt-0' : navigationItems, 'pt-5': !navigationItems, 'no-padding' : showTopBar == false}">
                <p-toast position="bottom-right"></p-toast>

<!--            <div [class.hidden]="currentOverlayObject">-->
                <ng-container *ngIf="!currentObject || !currentObject.objectId">
                    <router-outlet></router-outlet>
                </ng-container>
<!--            </div>-->

            <ng-container *ngIf="currentObject && currentObject.objectId">
                <mvs-object-navigation-main></mvs-object-navigation-main>
            </ng-container>
        </div>

        <!--<app-footer></app-footer>-->
    </div>

    <app-config></app-config>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>

    <!-- confirm Dialog -->
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <!-- Object History -->
    <mvs-crud-object-history></mvs-crud-object-history>

    <!-- UI Configuration -->
    <mvs-ui-configuration></mvs-ui-configuration>

    <!-- CRUD Object Dialog -->
    <mvs-crud-object-dialog></mvs-crud-object-dialog>

    <!-- CRUD Info Dialog -->
    <mvs-crud-info-dialog></mvs-crud-info-dialog>

    <!-- Object Widget Dialog -->
    <mvs-widget-object-dialog></mvs-widget-object-dialog>

    <!-- Condition Builder Service Wrapper -->
    <mvs-condition-builder-service-wrapper></mvs-condition-builder-service-wrapper>

    <!-- Sorting Service Wrapper -->
    <mvs-sorting-service-wrapper></mvs-sorting-service-wrapper>

    <!-- Command Menu Dialog -->
    <!--    <mvs-command-menu-dialog></mvs-command-menu-dialog>-->
    <mvs-command-menu-dialog-new></mvs-command-menu-dialog-new>


</div>
