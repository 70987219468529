
<ng-container *ngIf="initialized">


    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>

    <ng-container *ngIf="activeItem?.id == 'search'">
        <div class="grid">
            <p-button *ngIf="searchHidden" label="Show search" class="my-3" (click)="searchHidden=false;"></p-button>

            <div class="col-3" *ngIf="!searchHidden">
                <p-panel title="Start Indexing">

                    <form [formGroup]="formSearch" (ngSubmit)="onSubmit()">

                        <div class="flex flex-column gap-3">
                            <div class="flex flex-column gap-2">
                                <label for="requestedObjectTypeIds" class="text-500 text-sm">Index Object Types</label>
                                <p-multiSelect
                                        id="requestedObjectTypeIds"
                                        formControlName="requestedObjectTypeIds"
                                        [options]="objectTypes"
                                        optionLabel="alias"
                                        optionValue="id"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="splitResultsByType" class="text-500 text-sm">Split results by type (the max hit configuration will be applied for each type)</label>
                                <p-checkbox id="splitResultsByType" formControlName="splitResultsByType" [binary]="true"></p-checkbox>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="maxHits" class="text-500 text-sm">Max Hits</label>
                                <p-inputNumber type="number" id="maxHits" formControlName="maxHits" class="form-control"></p-inputNumber>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="minSearchRating" class="text-500 text-sm">Min search rating</label>
                                <p-multiSelect
                                        id="minSearchRating"
                                        formControlName="minSearchRating"
                                        [options]="ratings"
                                        optionLabel="label"
                                        optionValue="key"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>

                            <div class="flex flex-column gap-2">
                                <label for="searchString" class="text-500 text-sm">Search String</label>
                                <input pInputText id="searchString" formControlName="searchString" class="form-control">
                            </div>

                            <div class="flex justify-content-between">
                                <p-button label="Hide" (click)="searchHidden=true"></p-button>
                                <p-button type="submit" label="Search" [disabled]="formSearch.status != 'VALID'"></p-button>
                            </div>

                        </div>


                    </form>

                </p-panel>
            </div>
            <div class="{{ searchHidden ? 'col-12' : 'col-8' }}" *ngIf="searchResult">

                <p-tag [value]="'Result Count' + searchResult.resultCount"></p-tag>
                <p-tag [value]="'Result Limited' + searchResult.resultLimited"></p-tag>

                <p-table [value]="searchResult.searchResultEntries" [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Object Type</th>
                            <th>ICON</th>
                            <th>ID</th>
                            <th>Rating</th>
                            <th>Result Name</th>
                            <th *ngFor="let index of [0,1,2,3,4,5,6,7,8,9,10,11,12]">
                                DF{{index}}
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entry>
                        <tr>
                            <td>{{ entry.objectTypeId }} {{ searchResult.objectTypes[entry.objectTypeId].alias }}</td>
                            <td> <i class="{{ searchResult.objectTypes[entry.objectTypeId].icon }}"></i></td>
                            <td>{{ entry.objectId }}</td>
                            <td>{{ entry.rating }}</td>
                            <td>{{ entry.resultName }}</td>
                            <td *ngFor="let index of [0,1,2,3,4,5,6,7,8,9,10,11,12]">
                                <ng-container *ngIf="entry.dataFieldsList[index]">
                                    <div class="flex flex-column">
                                        <span class="text-xs">Fieldname</span>
                                        <span>{{entry.dataFieldsList[index].fieldName}}</span>
                                        <span class="text-xs">Token</span>
                                        <span>{{entry.dataFieldsList[index].originalValue}}</span>
                                        <span *ngIf="entry.dataFieldsList[index].rating" class="text-xs">Rating</span>
                                        <span *ngIf="entry.dataFieldsList[index].rating">{{entry.dataFieldsList[index].rating}}</span>
                                        <span *ngIf="entry.dataFieldsList[index].matched" class="text-xs">Match</span>
                                        <span *ngIf="entry.dataFieldsList[index].matched" style="color:green">{{entry.dataFieldsList[index].matched}}</span>
                                        <span *ngIf="entry.dataFieldsList[index].matchedOriginalValue" class="text-xs">Matched Value</span>
                                        <span *ngIf="entry.dataFieldsList[index].matchedOriginalValue" style="color:green">{{entry.dataFieldsList[index].matchedOriginalValue}}</span>
                                        <span class="text-xs">Distance</span>
                                        <span>{{entry.dataFieldsList[index].distance}}</span>
                                    </div>
                                </ng-container>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
            </div>

    </ng-container>

    <ng-container *ngIf="activeItem?.id == 'browseObjectType'">

        <div class="grid">
            <div class="col-12">
                <p-panel title="Object Type">
                    <form [formGroup]="formBrowseObjectType" (ngSubmit)="handleSubmitBrowseByObjectType()">
                        <div class="flex gap-3" style="align-items: end; height: 100%;">
                            <div class="flex flex-column gap-2 flex-grow-1">
                                <label for="browseObjectTypes" class="text-500 text-sm">Object Types</label>
                                <p-multiSelect
                                        id="browseObjectTypes"
                                        formControlName="requestedObjectTypeIds"
                                        [options]="objectTypes"
                                        optionLabel="alias"
                                        optionValue="id"
                                        display="chip"
                                        showClear="true"
                                ></p-multiSelect>
                            </div>
                            <div class="flex align-items-end justify-content-end">
                                <p-button type="submit" label="Search" [disabled]="formBrowseObjectType.status != 'VALID'"></p-button>
                            </div>
                        </div>
                    </form>
                </p-panel>
            </div>

            <div class="col-12">
                <mvs-widget *ngIf="widgetBrowseObjectTypeTokens" [widgetData]="widgetBrowseObjectTypeTokens"></mvs-widget>
            </div>

            <div class="col-12">
                <mvs-widget *ngIf="widgetBrowseObjectTypeReferences" [widgetData]="widgetBrowseObjectTypeReferences"></mvs-widget>
            </div>

        </div>

    </ng-container>


    <ng-container *ngIf="activeItem?.id == 'browseObject'">

        <div class="grid">
            <div class="col-12">
                <p-panel title="Object Type">
                    <form [formGroup]="formBrowseObject" (ngSubmit)="handleSubmitBrowseByObject()">
                        <div class="flex gap-3" style="align-items: end; height: 100%;">
                            <div class="flex flex-column gap-2 flex-grow-1">
                                <label for="objectType" class="text-500 text-sm">Object Type</label>
                                <p-dropdown id="objectType" [filter]="true" filterBy="alias" formControlName="objectType" [options]="objectTypes" optionLabel="alias" optionValue="id"></p-dropdown>
                            </div>
                            <div class="flex flex-column gap-2 flex-grow-1">
                                <label for="objectId" class="text-500 text-sm">Object ID</label>
                                <p-inputNumber formControlName="objectId" mode="decimal" inputId="objectId" [useGrouping]="false"> </p-inputNumber>
                            </div>
                            <div class="flex align-items-end justify-content-end">
                                <p-button type="submit" label="Search" [disabled]="formBrowseObject.status != 'VALID'"></p-button>
                            </div>

                        </div>
                    </form>
                </p-panel>
            </div>

            <div class="col-12">
                <mvs-widget *ngIf="widgetBrowseObjectTokens" [widgetData]="widgetBrowseObjectTokens"></mvs-widget>
            </div>

            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetBrowseObjectReferences"
                        [widgetData]="widgetBrowseObjectReferences"
                        (onObjectsSelect)="handleBrowseObjectSelectedReferences($event)"
                ></mvs-widget>
            </div>

            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetBrowseObjectReferenceTokens"
                        [widgetData]="widgetBrowseObjectReferenceTokens"
                ></mvs-widget>
            </div>



        </div>

    </ng-container>


</ng-container>









