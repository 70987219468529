import {Injectable} from "@angular/core";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {catchError, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {XrCrossReportSelectionFormDto} from "../../logic/xr-cross-report-selection-form.dto";
import {XrCrossReportResultDto} from "../../logic/xr-cross-report-result.dto";
import {XrCrossReportRequestDto} from "../../logic/xr-cross-report-request.dto";

@Injectable({
    providedIn: 'root'
})
export class XrReportService {

    public static baseUrl: string = environment.API_URL

    constructor(protected http: HttpClient) {

    }

    public executeReport(reportName: string) : Observable<XrCrossReportResultDto> {

        const reportRequest: XrCrossReportRequestDto = new XrCrossReportRequestDto();
        reportRequest.report = reportName;

        return this.http.post<XrCrossReportResultDto>(XrReportService.baseUrl + `/xr/executeReport`, reportRequest);

    }

    /**
     * Get report selection.
     * @param reportName
     */
    public getSelection(reportName: string) : Observable<XrCrossReportSelectionFormDto> {

        return this.http.get<XrCrossReportSelectionFormDto>(XrReportService.baseUrl + `/xr/selectionMeta/${reportName}`);

    }


}