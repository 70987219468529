import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {NsConfigPage} from "./page/overview/ns-config/ns-config.page";
import {NsEmailConfigPage} from "./page/overview/ns-email-config/ns-email-config.page";
import {NsSmsConfigPage} from "./page/overview/ns-sms-config/ns-sms-config.page";
import {
  NsNotificationTypesForObjectComponent
} from "./component/ns-notification-types-for-object/ns-notification-types-for-object.component";
import {NsBlackListPage} from "./page/overview/ns-black-list/ns-black-list.page";
import {NsWhiteListPage} from "./page/overview/ns-white-list/ns-white-list.page";
import {
  NsNotificationCommentWfProcessStepMainActivityComponent
} from "./component/wf/ns-notification-comment-wf-process-step-main-activity/ns-notification-comment-wf-process-step-main-activity.component";
import {
  NsNotificationCommentWfProcessStepSideActivityComponent
} from "./component/wf/ns-notification-comment-wf-process-step-side-activity/ns-notification-comment-wf-process-step-side-activity.component";
import {NsDashboardPage} from "./page/dashboard/ns-dashboard/ns-dashboard.page";
import {NsNotificationObjectPage} from "./page/object/notification-object-page/ns-notification-object.page";
import {NsNotificationTypeObjectComponent} from "./component/ns-notification/ns-notification-type-object.component";
import {ProgressSpinner, ProgressSpinnerModule} from "primeng/progressspinner";
import {BlockUIModule} from "primeng/blockui";
import {NsNotificationSendComponent} from "./component/ns-notification-send/ns-notification-send.component";
import {NsConfigTestPage} from "./page/overview/ns-config-test/ns-config-test.page";
import {NotificationOverviewPage} from "./page/notification-overview-page/notification-overview.page";
import {
  NotificationStatisticsComponent
} from "./page/notification-overview-page/components/statisticsComponent/notification-statistics.component";
import {
  NotificationKpiComponent
} from "./page/notification-overview-page/components/kpiComponent/notification-kpi.component";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";
import {
  NsNotificationObjectComponent
} from "./component/notification-object-component/ns-notification-object.component";


@NgModule({
  declarations: [
    NsConfigPage,
    NsEmailConfigPage,
    NsSmsConfigPage,
    NsNotificationTypesForObjectComponent,
    NsBlackListPage,
    NsWhiteListPage,
    NsNotificationCommentWfProcessStepMainActivityComponent,
    NsNotificationCommentWfProcessStepSideActivityComponent,
    NsDashboardPage,
    NsNotificationObjectPage,
    NsNotificationTypeObjectComponent,
    NsNotificationSendComponent,
    NsConfigTestPage,
    NotificationOverviewPage,
    NotificationStatisticsComponent,
    NotificationKpiComponent,
    NsNotificationObjectComponent
  ],
  exports:[
    NsConfigPage,
    NsEmailConfigPage,
    NsSmsConfigPage,
    NsNotificationTypesForObjectComponent,
    NsBlackListPage,
    NsWhiteListPage,
    NsNotificationCommentWfProcessStepMainActivityComponent,
    NsNotificationCommentWfProcessStepSideActivityComponent,
    NsDashboardPage,
    NsNotificationObjectPage,
    NsNotificationTypeObjectComponent,
    NsNotificationSendComponent,
    NsConfigTestPage,
    NsNotificationObjectComponent


  ],
    imports: [
        CoreModule,
        UiModule,
        ProgressSpinnerModule,
        BlockUIModule
    ]
})
export class NsModule implements DynamicModule {

  alias = 'ns';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return NotificationKpiComponent;
  }

  getStatisticsComponent() {
    return NotificationStatisticsComponent;
  }

}



