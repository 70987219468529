<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="dm.DmDocumentType"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->

<div class="bg-white" *ngIf="initialized">
    <mvs-config-header header="System Configuration" [categoryTypeMode]="'system'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <div class="flex align-items-center gap-2">
                        <span class="font-bold white-space-nowrap m-0"> {{ widEntry.label }}</span>
                    </div>
                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">
        <div class="grid">
            <div class="col-12">
                <ng-container *ngIf="activeWidget.id == 'im.ImSystem'">
                    <mvs-widget
                            *ngIf="externeSystemWidget"
                            [widgetData]="externeSystemWidget"
                            (onObjectSelect)="handleTemplateSelect($event)"
                    ></mvs-widget>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>