import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {AgentService} from "../../../../service/api/agent.service";
import {AppointmentService} from "../../../../../as/service/api/appointment.service";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'agent-kpi.component.html',
})
export class AgentKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalAgents: number = 0;
    appointments: number = 0;
    thisMonthAgents: number = 0;
    thisWeekAgents: number = 0;

    items: MenuItem[] | undefined= [{label: 'Agent Module'}];

    constructor(
        protected agentService: AgentService,
        protected appointmentService: AppointmentService) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

        this.getTotalAgents();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalAgents() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['type'],
            [new ObjectRequestListAttribute('type', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.agentService.groupBy(objectRequestList).subscribe(res => {
            this.totalAgents = res.entries.reduce((acc, item) => acc + item['type_count'], 0);


            this.getTotalAppointments();

        }, error => {

            this.getTotalAppointments();
        });
    }

    getTotalAppointments() {
        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.appointmentService.list(objectRequestList).subscribe(res => {
            this.appointments = res.entries.length;

            this.getThisMonthAgents();
        }, error => {

            this.getThisMonthAgents();
        });
    }



    getThisMonthAgents() {
        const objectRequestList = this.getThisMonthRequest('type')
        this.agentService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthAgents = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.getThisWeekAgents();
        }, error => {
            this.getThisWeekAgents();
        });
    }

    getThisWeekAgents() {
        const objectRequestList = this.getThisWeekRequest('type')
        this.agentService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekAgents = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.initKpiHeaderDto();
        },error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {
        this.kpiHeaderDto = [
            {
                label: 'Total Agents', value: this.totalAgents, route:'/am/agents-overview'
            },
            {
                label: 'Appointments', value: this.appointments, route:'/am/appointment-dashboard'
            },
            {
                label: 'This Month Agents', value: this.thisMonthAgents
            },
            {
                label: 'This Week Agents', value: this.thisWeekAgents,
            }
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
