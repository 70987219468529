import {Routes} from "@angular/router";
import {
    MvsGenericObjectPageComponent
} from "@kvers/alpha-ui";

export function CoreSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'object', component: MvsGenericObjectPageComponent},
        // 17.05.2024 rework packaging
        // {path: 'core-gt-name', component: CoreGtNameComponent},
        // {path: 'dashboard', component: CoreDashboardComponent},
        // {path: 'dashboard/:id', component: CoreDashboardComponent},
        // {path: 'filter', component: ConditionTestComponent },

    ];

    return routes;

}
