import {Component, OnInit} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CallTypeService} from "../../../../pc/service/api/call-type.service";
import {CallTypeDto} from "../../../../pc/dto/call-type.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-test-templating',
  templateUrl: './test-designs.page.html',
  styleUrls: ['./test-designs.page.scss']
})
export class TestDesignsPage extends PageComponent implements OnInit {

  visible: boolean;

  customerObjectIdentifier: ObjectIdentifier;
  callTypeDto: DtoDetail;

  constructor(protected callTypeService:CallTypeService, protected route:ActivatedRoute, protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {
    this.customerObjectIdentifier = new ObjectIdentifier("cr.Customer", 11391);

    this.callTypeService.get(1).subscribe((callTypeDto) => {
      this.callTypeDto = callTypeDto;
    });
  }

  showDialog() {
    this.visible = true;
  }


}
