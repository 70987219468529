import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FilterCriteria, MvsCoreService, PageComponent,} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {getStartOfMonth} from "../helper/date-utils";


@Component({
    selector: 'mvs-example',
    template: '',
})
export class OverviewStatisticsBaseComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {


    filteredDate: Date = getStartOfMonth(new Date());
    filterCriteria: FilterCriteria[];
    items: MenuItem[] | undefined;
    home: MenuItem | undefined;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {

        this.filterCriteria = [FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, this.filteredDate)];
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    handlePeriodFilter(startDate: Date | null) {
        this.filteredDate = startDate;

        if (this.filteredDate) {
            this.filterCriteria = [FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, this.filteredDate)];
        } else {
            this.filterCriteria = [];
        }
        this.refreshComponent();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
