import {Component, EventEmitter, OnInit, Output, SimpleChanges} from "@angular/core";
import {
    DtoDetail, EntityStatusEnum,
    FilterCriteria,
    MvsCoreService, MvsCrudModeEnum, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    MvsMessageService,
    NavigationItem,
    ObjectBaseModeComponent, ObjectChangeInformation, ObjectChangeInformationActionEnum, ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation,
    ObserverService,
    WidgetData, WidgetDataParam
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {IntegrationTypeDto} from "../../../../dto/integration-type.dto";
import {IntegrationTypeStepDto} from "../../../../dto/integration-type-step.dto";
import {IntegrationTypeService} from "../../../../service/api/integration-type.service";
import {IntegrationTypeStepTransitionService} from "../../../../service/api/integration-type-step-transition.service";
import {MvsUiObjectService, WidgetFactory} from "@kvers/alpha-ui";
import {IntegrationTypeStepTransitionDto} from "../../../../dto/integration-type-step-transition.dto";
import {IntegrationTypeStepInputDto} from "../../../../dto/integration-type-step-input.dto";
import {IntegrationTypeStepOutputDto} from "../../../../dto/integration-type-step-output.dto";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../../../core/helper/widget-function-call-back-create";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'hc-integration-base-component',
    template: ''
})

export class IntegrationTypeBaseComponent extends ObjectBaseModeComponent implements OnInit {


    integrationTypeDto: IntegrationTypeDto;

    integrationTypeWidget: WidgetData;

    integrationTypeVariableTableWidget: WidgetData;
    integrationTypeVariableObjectWidget: WidgetData;

    integrationTypeStepTransitionTableWidget: WidgetData;
    integrationTypeStepTransitionObjectWidget: WidgetData;

    sortedStep: IntegrationTypeStepDto[];

    constructor(
        protected route: ActivatedRoute,
        protected integrationTypeService: IntegrationTypeService,
        protected integrationTypeStepTransitionService: IntegrationTypeStepTransitionService,
        protected objectService: MvsUiObjectService,
    ) {

        super(route);

    }

    ngOnInit() {


        this.activeNavigationItem = this.navigationItems[0];
        this.onNavigationItems.emit(this.navigationItems);
        super.ngOnInit();

        this.refreshIntegrationTypeWidget();
        this.refreshVariableTableWidget();

        this.initialized = true;
    }

    refreshIntegrationTypeWidget() {
        this.integrationTypeWidget = WidgetFactory.createWidgetEntityData(
            'hc.integration.type.data.widget',
            'Integration Type',
            'hc.IntegrationType',
            this.objectIdentifier.objectId
        )
    }

    onNavigationItemChange(navigationItem: NavigationItem) {
        if (navigationItem?.action == 'integrationType') {
            this.getIntegrationTypeFull();
        }
    }

    getIntegrationTypeFull() {

        if (!this.objectIdentifier || !this.objectIdentifier.objectId) {
            console.error("objectIdentifier is not set.");
            return;
        }

        const filterCriteria = FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequestList = ObjectRequestList.createBasic(false, [filterCriteria], []);

        objectRequestList.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode('integrationTypeStepTransition', ObjectRequestRelation.createJoin('hc.IntegrationTypeStepTransition#integration')),
            ObjectRequestComplexNode.createRelationNode('integrationTypeStep', ObjectRequestRelation.createJoin('hc.IntegrationTypeStep#integration')).addNodes(
                ObjectRequestComplexNode.createRelationNode('integrationTypeStepInput', ObjectRequestRelation.createJoin('hc.IntegrationTypeStepInput#step')),
                ObjectRequestComplexNode.createRelationNode('integrationTypeStepOutput', ObjectRequestRelation.createJoin('hc.IntegrationTypeStepOutput#step'))
            ),
            ObjectRequestComplexNode.createRelationNode('integrationTypeVariable', ObjectRequestRelation.createJoin('hc.IntegrationTypeVariable#integration')),
        );


        this.integrationTypeService.list(objectRequestList).subscribe(res => {
            this.integrationTypeDto = res.entries[0];

            if (this.integrationTypeDto?.integrationTypeStep && this.integrationTypeDto?.integrationTypeStepTransition) {
                this.sortedStep = this.getOrderedSteps(
                    this.integrationTypeDto.startStepDtoId,  // Directly use the start step ID
                    this.integrationTypeDto.integrationTypeStep,
                    this.integrationTypeDto.integrationTypeStepTransition
                );
            }

        })
    }

    getOrderedSteps(startStepId: number, steps: IntegrationTypeStepDto[], transitions: IntegrationTypeStepTransitionDto[]): IntegrationTypeStepDto[] {
        const stepMap = new Map<number, IntegrationTypeStepDto>();
        steps.forEach(step => {
            step['stepTransitionDto'] = []; // Initialize transition storage in each step
            stepMap.set(step.id, step);
        });

        // Build adjacency list based on transitions
        const transitionMap = new Map<number, {
            nextStepId: number,
            priority: number,
            transitionDto: IntegrationTypeStepTransitionDto
        }[]>();
        transitions.forEach(transition => {
            if (!transitionMap.has(transition.fromStepDtoId)) {
                transitionMap.set(transition.fromStepDtoId, []);
            }
            transitionMap.get(transition.fromStepDtoId)?.push({
                nextStepId: transition.toStepDtoId,
                priority: transition.priority,
                transitionDto: transition
            });
        });

        // Sort transitions by priority (higher priority first)
        for (const [key, values] of transitionMap.entries()) {
            values.sort((a, b) => b.priority - a.priority);
        }

        // Traverse steps in correct order
        const visited = new Set<number>();
        const orderedSteps: IntegrationTypeStepDto[] = [];
        let currentStepId = startStepId;

        while (currentStepId && stepMap.has(currentStepId)) {
            const step = stepMap.get(currentStepId)!;
            orderedSteps.push(step);
            visited.add(currentStepId);

            // Attach transitions to the step
            step['stepTransitionDto'] = transitionMap.get(currentStepId)?.map(t => t.transitionDto) || [];

            const nextSteps = transitionMap.get(currentStepId) || [];
            if (nextSteps.length > 0) {
                currentStepId = nextSteps[0].nextStepId; // Follow highest priority transition
            } else {
                break; // No more transitions, end of sequence
            }
        }

        // Add remaining steps that were not part of any transition at the end
        steps.forEach(step => {
            if (!visited.has(step.id)) {
                orderedSteps.push(step);
            }
        });

        return orderedSteps;
    }


    handleStep(step?: IntegrationTypeStepDto) {

        const id = step?.id ? step?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "hc.IntegrationTypeStep",
            id,
            defaultDto,
            false,
            false,
            (event: ObjectChangeInformation) => {

                if (event.action == ObjectChangeInformationActionEnum.created) {
                    this.handleNewStepCreation(event);
                } else {
                    this.getIntegrationTypeFull();
                }

            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }


    handleStepInput(step: IntegrationTypeStepDto, stepInput?: IntegrationTypeStepInputDto) {
        const id = stepInput?.id ? stepInput?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['stepDtoId'] = step.id;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('stepDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "hc.IntegrationTypeStepInput",
            id,
            defaultDto,
            false,
            false,
            () => {
                this.getIntegrationTypeFull();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }

    handleStepOutput(step: IntegrationTypeStepDto, stepOutput?: IntegrationTypeStepOutputDto) {
        const id = stepOutput?.id ? stepOutput?.id : 0;

        const defaultDto = new DtoDetail();
        defaultDto['stepDtoId'] = step.id;

        const formControlOverwrite = new MvsFormControlOverwrite()
        formControlOverwrite.addField('stepDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "hc.IntegrationTypeStepOutput",
            id,
            defaultDto,
            false,
            false,
            () => {
                this.getIntegrationTypeFull();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }

    handleStepTransition(step: IntegrationTypeStepDto) {

        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;
        defaultDto['fromStepDtoId'] = step.id;

        const formControlOverwrite = new MvsFormControlOverwrite()
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ);
        formControlOverwrite.addField('fromStepDtoId', MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            null,
            "hc.IntegrationTypeStepTransition",
            0,
            defaultDto,
            false,
            false,
            () => {
                this.getIntegrationTypeFull();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        )
    }


    showTransition(id: number) {

        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite()
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ)

        this.objectService.openObjectViaDialog(
            null,
            "hc.IntegrationTypeStepTransition",
            id,
            defaultDto,
            false,
            false,
            () => {
                this.getIntegrationTypeFull();
            },
            MvsCrudModeEnum.update,
            null,
            formControlOverwrite
        )
    }


    refreshVariableTableWidget() {

        const filterCriteria = FilterCriteria.create('integration', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], [])

        this.integrationTypeVariableTableWidget = WidgetFactory.createWidgetTableEntity(
            'hc.integration.type.variable.table.widget',
            'Variable',
            'hc.IntegrationTypeVariable',
            'No Variables',
            objectRequest);

        const fields: WidgetToolbarCreateInterface[] = [{
            fieldName: 'integrationDtoId',
            fieldValue: this.objectIdentifier.objectId
        }]
        this.integrationTypeVariableTableWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    refreshTransitionObjectWidget() {
        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ);

        this.integrationTypeStepTransitionObjectWidget = WidgetFactory.createWidgetObject(
            'hc.integration.type.transition.object.widget',
            'Transition',
            'hc.IntegrationTypeStepTransition',
            0,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite));
    }

    refreshTransitionTableWidget() {

        const filterCriteria = FilterCriteria.create('integration', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], [])

        this.integrationTypeStepTransitionTableWidget = WidgetFactory.createWidgetTableEntity(
            'hc.integration.type.transition.table.widget',
            'Transition',
            'hc.IntegrationTypeStepTransition',
            'No Transitions',
            objectRequest);
    }

    handleVariableSelect(event: ObjectIdentifierData) {

        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ);

        this.integrationTypeVariableObjectWidget = WidgetFactory.createWidgetObject(
            'hc.integration.type.variable.object.widget',
            'Variable',
            event.objectType,
            event.objectId,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite)
        )
        ;
    }

    handleTransitionSelect(event: ObjectIdentifierData) {

        const defaultDto = new DtoDetail();
        defaultDto['integrationDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('integrationDtoId', MvsFormFieldAccessEnum.READ);

        this.integrationTypeStepTransitionObjectWidget = WidgetFactory.createWidgetObject(
            'hc.integration.type.transition.object.widget',
            'Transition',
            event.objectType,
            event.objectId,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite)
        )
        ;
    }

    handleNewStepCreation(event: ObjectChangeInformation) {

        const dto = new IntegrationTypeStepTransitionDto();

        const lastStep = this.sortedStep[this.sortedStep.length - 1]

        dto.fromStepDtoId = lastStep.id;
        dto.toStepDtoId = event.after.id;
        dto.integrationDtoId = this.objectIdentifier.objectId;
        dto.entityStatus = EntityStatusEnum.active;
        dto.priority = 10;

        this.integrationTypeStepTransitionService.create(dto).subscribe(res => {
            this.getIntegrationTypeFull();
        })

    }


}