import {Injectable, OnInit} from '@angular/core';
import {DtoDetail, DtoList, DtoTemplate, MvsCrudService, MvsFormDto, ObjectRequestList} from "@kvers/alpha-core-common";
import {MOCK_DATA, MOCK_FORM} from "./data";
import {Observable, of} from "rxjs";
import {delay} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ActivityTestService extends MvsCrudService implements OnInit{

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + 'test');
    }

    initialized: boolean;
    count = 0;



    ngOnInit() {
    }




    override list(request: ObjectRequestList): Observable<DtoList<any>> {
        const dtoList: DtoList<any> = {
            entries: MOCK_DATA,
            form: request.includeTemplate ? MOCK_FORM : null,
            pagingResponse: null,
            sortBy: null
        };
        return of(dtoList).pipe(delay(500));
    }

    override get(id: number): Observable<DtoDetail> {
        const item = MOCK_DATA.find((data) => data.id === id);
        item.form = MOCK_FORM;
        return of(item).pipe(delay(500));
    }

    override create(dto: DtoDetail): Observable<DtoDetail> {
        dto.id = MOCK_DATA.length + 1; // Simulate ID assignment
        MOCK_DATA.push(dto);
        return of(dto).pipe(delay(500));
    }

    override update(dto: DtoDetail): Observable<DtoDetail> {
        const index = MOCK_DATA.findIndex((data) => data.id === dto.id);
        if (index !== -1) {
            MOCK_DATA[index] = dto;
        }
        return of(dto).pipe(delay(500));
    }

    override delete(id: number): Observable<any> {
        MOCK_DATA.filter((data) => data.id !== id);
        return of(null).pipe(delay(500));
    }

    override template(obj: DtoTemplate): Observable<MvsFormDto> {
        let mockForm2 = MOCK_FORM;
        mockForm2.objectType = "test.activityTest";
        this.initialized = true;
        return of(MvsCrudService.transformForm(<MvsFormDto><unknown>mockForm2)).pipe(delay(500));
    }


}
