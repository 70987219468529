import {Component, OnDestroy, OnInit} from '@angular/core';
import {AgentActiveObjectDto} from "../../dto/agent-active-object.dto";
import {Subscription} from "rxjs";
import {AgentActiveObjectService} from "../../service/api/agent-active-object.service";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";

@Component({
    selector: 'am-agent-main-active-objects',
    templateUrl: './am-agent-main-active-objects.component.html',
    styleUrls: ['./am-agent-main-active-objects.component.scss']
})
export class AmAgentMainActiveObjectsComponent implements OnInit, OnDestroy {

    mainObject: AgentActiveObjectDto;
    activeObjectsSubscription: Subscription;

    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected agentActiveObjectService: AgentActiveObjectService) {

    }

    ngOnInit(): void {
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.activeObjectsSubscription = this.agentActiveObjectService.subjectAgentActiveObjects.subscribe(value => {
            this.mainObject = value?.find(item => item.mainObject == true);
        });
    }

    /**
     * open sidebar to show selected object
     * @param object
     */
    openObjectDrawer(object: AgentActiveObjectDto) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(object.objectTypeDto.alias, object.objectId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * remove object as main object
     * @param object
     */
    removeActiveMainObject(object: AgentActiveObjectDto) {
        this.agentActiveObjectService.completeMainObject(object.id);
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.activeObjectsSubscription.unsubscribe();
    }

}
