import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {AppRoutingModule} from "../app-routing.module";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {PanelModule} from 'primeng/panel';
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {InputSwitchModule} from "primeng/inputswitch";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {CardModule} from "primeng/card";
import {CheckboxModule} from "primeng/checkbox";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {AccordionModule} from "primeng/accordion";
import {ChipModule} from "primeng/chip";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {TooltipModule} from "primeng/tooltip";
import {InputNumberModule} from "primeng/inputnumber";
import {SliderModule} from "primeng/slider";
import {CalendarModule} from "primeng/calendar";
import {ToastModule} from "primeng/toast";
import {TreeModule} from "primeng/tree";
import {MenuModule} from "primeng/menu";
import {ChipsModule} from "primeng/chips";
import {DividerModule} from 'primeng/divider';

import {AutoCompleteModule} from "primeng/autocomplete";
import {ColorPickerModule} from "primeng/colorpicker";
import {TabViewModule} from "primeng/tabview";
import {SplitButtonModule} from "primeng/splitbutton";
import {BadgeModule} from "primeng/badge";
import {TimelineModule} from "primeng/timeline";
import {RatingModule} from "primeng/rating";
import {EditorModule as QuillEditorModule} from "primeng/editor";
import { EditorModule } from '@tinymce/tinymce-angular';
import {ChartModule} from "primeng/chart";
import {SkeletonModule} from "primeng/skeleton";
import {ListboxModule} from "primeng/listbox";
import {MessageModule} from "primeng/message";
import {FieldsetModule} from "primeng/fieldset";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ToggleButtonModule} from "primeng/togglebutton";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {MultiSelectModule} from "primeng/multiselect";
import {NgPrimeUltimaModule} from "../ng.prime.ultima.module";
import {CoreDashboardComponent} from "./dashboard/page/dashboard/core-dashboard.component";
import {MentionModule} from "angular-mentions";
import {ProgressBarModule} from "primeng/progressbar";
import {SpeedDialModule} from "primeng/speeddial";
import {TreeSelectModule} from "primeng/treeselect";
import {MvsDashboardPage} from "./dashboard/page/dashboard-page/mvs-dashboard.page";
import {
    MvsDashboardTopNavigationComponent
} from "./dashboard/components/mvs-dashboard-top-navigation/mvs-dashboard-top-navigation.component";
import {MvsDashboardSideOptionsComponent} from "./dashboard/components/mvs-dashboard-side-options/mvs-dashboard-side-options.component";
import {MvsDashboardObjectComponent} from "./dashboard/components/mvs-dashboard-object/mvs-dashboard-object.component";
// import {MvsWidgetCodeComponent} from "../tw/component/mvs-widget-code/mvs-widget-code.component";
import {
    ContractPersonObjectWidgetsComponent
} from "../cm/components/contract-person-object-widgets/contract-person-object-widgets.component";
import {CoreCommonModule} from "@kvers/alpha-core-common";
import {AlphaUiModule} from "@kvers/alpha-ui";
import {environment} from "../../environments/environment";
import {HomePagePage} from "./home-page/home-page.page";
import {MvsTicketComponent} from "./ticket/component/mvs-ticket/mvs-ticket.component";
import {MvsWorkflowComponent} from "./workflow/component/mvs-workflow/mvs-workflow.component";
import {MvsUserGroupAvatarComponent} from "./components/mvs-user-group-avatar/mvs-user-group-avatar.component";
import {MvsAvatarListComponent} from "./components/mvs-user-group-avatar/mvs-avatar-list/mvs-avatar-list.component";
import {MvsAvatarComponent} from "./components/mvs-user-group-avatar/mvs-avatar/mvs-avatar.component";
import {MvsInlineComponent} from "./components/mvs-inline-component/mvs-inline-component";
import {TreeTableModule} from "primeng/treetable";
import {KpiHeaderComponent} from "./overview/components/kpi-header/kpi-header.component";
import {OverviewComponent} from "./overview/components/overview/overview.component";
import {DateDropdownComponent} from "./components/date-droupdown-component/date-dropdown.component";
import {OverviewStatisticsBaseComponent} from "./overview/overview-statistics-base.component";
import {OverviewKpiBaseComponent} from "./overview/overview-kpi-base.component";
import {
    MvsFormFieldAgentPoolAgentComponent
} from "./form/field/input/mvs-form-field-agent-pool-agent/mvs-form-field-agent-pool-agent.component";
import {
    MvsDashboardAgentPoolFilterComponent
} from "./components/mvs-dashboard-agentpool-filter/mvs-dashboard-agent-pool-filter.component";
import {MvsHistoryComponent} from "./components/mvs-history/mvs-history.component";
import {
    MvsUserObjectAccessOutputComponent
} from "./form/field/output/mvs-user-object-access-output/mvs-user-object-access-output.component";
import {
    MvsUserObjectAccessOutputConfigComponent
} from "./form/field/output/mvs-user-object-access-output/mvs-user-object-access-output-config/mvs-user-object-access-output-config.component";
import {
    MvsTicketCountOutputComponent
} from "./form/field/output/mvs-user-ticket-count-output/mvs-ticket-count-output.component";
import {
    MvsTicketCountOutputConfigComponent
} from "./form/field/output/mvs-user-ticket-count-output/mvs-ticket-count-output-config/mvs-ticket-count-output-config.component";
import {MvsObjectTypeComponent} from "./components/mvs-object-type/mvs-object-type.component";
import {
    MvsFormFieldOutputUserImageComponent
} from "./form/field/output/mvs-form-field-output-user-image/mvs-form-field-output-user-image.component";
import {MvsObjectCommentsComponent} from "./object/component/mvs-object-comments/mvs-object-comments.component";
import {
    MvsCreateTicketOutputComponent
} from "./form/field/output/mvs-create-ticket-output/mvs-create-ticket-output.component";
import {
    MvsCreateTicketOutputConfigComponent
} from "./form/field/output/mvs-create-ticket-output/mvs-create-ticket-output-config/mvs-create-ticket-output-config.component";
import {
    MvsLinkReportOutputFieldConfigComponent
} from "./form/field/output/mvs-link-report-output-field/mvs-link-report-output-field-config/mvs-link-report-output-field-config.component";
import {
    MvsLinkReportOutputFieldComponent
} from "./form/field/output/mvs-link-report-output-field/mvs-link-report-output-field.component";
import {
    MvsFormFieldTinyMceEditorComponent
} from "./form/field/input/mvs-form-field-tiny-mce-editor/mvs-form-field-tiny-mce-editor.component";
import {
    MvsNavigateToNewWindowOutputComponent
} from "./form/field/output/mvs-navigate-to-new-window-output/mvs-navigate-to-new-window-output.component";
import {
    MvsNavigateToNewWindowOutputConfigComponent
} from "./form/field/output/mvs-navigate-to-new-window-output/mvs-navigate-to-new-window-output-config/mvs-navigate-to-new-window-output-config.component";
import {
    MvsFormFieldOutputLogicDisplayComponent
} from "./form/field/output/mvs-form-field-output-logic-display/mvs-form-field-output-logic-display.component";
import {MvsQuillEditorComponent} from "./components/mvs-quill-editor/mvs-quill-editor.component";
import {
    MvsOneToManyOutputConfigComponent
} from "./form/field/output/mvs-one-to-many-output/mvs-one-to-many-output-config/mvs-one-to-many-output-config.component";
import {MvsOneToManyOutputComponent} from "./form/field/output/mvs-one-to-many-output/mvs-one-to-many-output.component";
import {
    MvsFormFieldOneToManyComponent
} from "./form/field/input/mvs-form-field-one-to-many/mvs-form-field-one-to-many.component";
import {
    MvsFormFieldOneToManyConfigComponent
} from "./form/field/input/mvs-form-field-one-to-many/mvs-form-field-one-to-many-config/mvs-form-field-one-to-many-config.component";
import {
    MvsOneToManyCountOutputComponent
} from "./form/field/output/mvs-one-to-many-count-output/mvs-one-to-many-count-output.component";
import {
    MvsOneToManyCountOutputConfigComponent
} from "./form/field/output/mvs-one-to-many-count-output/mvs-one-to-many-count-output-config/mvs-one-to-many-count-output-config.component";
import {AgentScheduleComponent} from "./components/agent-schedule-component/agent-schedule.component";
import {AgentCalendarComponent} from "./components/agent-calendar-component/agent-calendar.component";
import {SearchGoogleAddressPage} from "./components/search-google-address/search-google-address.page";
import {MvsEntitySearchComponent} from "./components/mvs-entity-search/mvs-entity-search.component";
import {
    MvsCustomerPhoneContactAvailabilityComponent
} from "./components/mvs-customer-phone-contact-availability/mvs-customer-phone-contact-availability.component";
import {MvsPcCallDialComponent} from "./components/mvs-pc-call-dial/mvs-pc-call-dial.component";

@NgModule({

    providers: [
        DatePipe
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        EditorModule,
        QuillEditorModule,  // TODO: needs to be removed
        AppRoutingModule,
        HttpClientModule,
        NgPrimeUltimaModule,
        TreeTableModule,
        MentionModule,
        PanelModule,
        DialogModule,
        TableModule,
        ButtonModule,
        DropdownModule,
        RippleModule,
        InputSwitchModule,
        InputTextModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        ListboxModule,
        CheckboxModule,
        BreadcrumbModule,
        AccordionModule,
        ChipModule,
        TriStateCheckboxModule,
        TooltipModule,
        InputNumberModule,
        SliderModule,
        MessageModule,
        CalendarModule,
        ToastModule,
        TreeModule,
        MenuModule,
        ChipsModule,
        DividerModule,
        AutoCompleteModule,
        ColorPickerModule,
        TabViewModule,
        SplitButtonModule,
        BadgeModule,
        TimelineModule,
        RatingModule,
        EditorModule,
        ChartModule,
        SkeletonModule,
        FieldsetModule,
        OverlayPanelModule,
        FontAwesomeModule,
        ToggleButtonModule,
        BlockUIModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        MultiSelectModule,
        SpeedDialModule,
        TreeSelectModule,
        CoreCommonModule.forRoot({API_URL: environment.API_URL, MS_graph_endpoint: environment.MS_graph_endpoint}),
        AlphaUiModule.forRoot({debug: environment.debug}),

    ],
    declarations: [

        CoreDashboardComponent,
        // MvsWidgetCodeComponent,
        MvsDashboardPage,
        MvsDashboardTopNavigationComponent,
        MvsDashboardSideOptionsComponent,
        MvsDashboardObjectComponent,
        ContractPersonObjectWidgetsComponent,
        HomePagePage,
        MvsTicketComponent,
        MvsWorkflowComponent,
        KpiHeaderComponent,
        OverviewComponent,
        MvsUserGroupAvatarComponent,
        MvsAvatarListComponent,
        MvsAvatarComponent,
        MvsInlineComponent,
        DateDropdownComponent,
        OverviewStatisticsBaseComponent,
        OverviewKpiBaseComponent,
        MvsFormFieldAgentPoolAgentComponent,
        MvsDashboardAgentPoolFilterComponent,
        MvsHistoryComponent,
        MvsUserObjectAccessOutputComponent,
        MvsUserObjectAccessOutputConfigComponent,
        MvsTicketCountOutputComponent,
        MvsTicketCountOutputConfigComponent,
        MvsObjectTypeComponent,
        MvsFormFieldOutputUserImageComponent,
        MvsObjectCommentsComponent,
        MvsCreateTicketOutputComponent,
        MvsCreateTicketOutputConfigComponent,
        MvsLinkReportOutputFieldConfigComponent,
        MvsLinkReportOutputFieldComponent,
        MvsFormFieldTinyMceEditorComponent,
        MvsNavigateToNewWindowOutputComponent,
        MvsNavigateToNewWindowOutputConfigComponent,
        MvsFormFieldOutputLogicDisplayComponent,
        MvsQuillEditorComponent,
        MvsOneToManyOutputConfigComponent,
        MvsOneToManyOutputComponent,
        MvsFormFieldOneToManyComponent,
        MvsFormFieldOneToManyConfigComponent,
        MvsOneToManyCountOutputComponent,
        MvsOneToManyCountOutputConfigComponent,
        AgentScheduleComponent,
        AgentCalendarComponent,
        SearchGoogleAddressPage,
        MvsEntitySearchComponent,
        MvsCustomerPhoneContactAvailabilityComponent,
        MvsPcCallDialComponent,

    ],
    exports: [

        EditorModule,
        QuillEditorModule,  // TODO: needs to be removed
        NgPrimeUltimaModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PanelModule,
        DialogModule,
        ButtonModule,
        RippleModule,
        TableModule,
        CardModule,
        ListboxModule,
        CheckboxModule,
        BreadcrumbModule,
        AccordionModule,
        BadgeModule,
        FieldsetModule,
        ToggleButtonModule,
        OverlayPanelModule,
        MessageModule,
        TimelineModule,
        FontAwesomeModule,
        TreeSelectModule,
        ProgressBarModule,
        MentionModule,
        TreeTableModule,
        CoreDashboardComponent,
        // MvsWidgetCodeComponent,
        MvsDashboardPage,
        MvsDashboardTopNavigationComponent,
        MvsDashboardSideOptionsComponent,
        MvsDashboardObjectComponent,
        ContractPersonObjectWidgetsComponent,
        CoreCommonModule,
        AlphaUiModule,
        HomePagePage,
        MvsTicketComponent,
        MvsWorkflowComponent,
        KpiHeaderComponent,
        OverviewComponent,
        MvsUserGroupAvatarComponent,
        MvsAvatarListComponent,
        MvsAvatarComponent,
        MvsInlineComponent,
        DateDropdownComponent,
        OverviewStatisticsBaseComponent,
        OverviewKpiBaseComponent,
        MvsHistoryComponent,
        OverviewKpiBaseComponent,
        MvsFormFieldAgentPoolAgentComponent,
        MvsDashboardAgentPoolFilterComponent,
        MvsUserObjectAccessOutputComponent,
        MvsUserObjectAccessOutputConfigComponent,
        MvsTicketCountOutputComponent,
        MvsTicketCountOutputConfigComponent,
        MvsObjectTypeComponent,
        MvsFormFieldOutputUserImageComponent,
        MvsObjectCommentsComponent,
        MvsCreateTicketOutputComponent,
        MvsCreateTicketOutputConfigComponent,
        MvsLinkReportOutputFieldConfigComponent,
        MvsLinkReportOutputFieldComponent,
        MvsFormFieldTinyMceEditorComponent,
        MvsNavigateToNewWindowOutputComponent,
        MvsNavigateToNewWindowOutputConfigComponent,
        MvsFormFieldOutputLogicDisplayComponent,
        MvsQuillEditorComponent,
        MvsOneToManyOutputConfigComponent,
        MvsOneToManyOutputComponent,
        MvsFormFieldOneToManyComponent,
        MvsFormFieldOneToManyConfigComponent,
        MvsOneToManyCountOutputComponent,
        MvsOneToManyCountOutputConfigComponent,
        AgentScheduleComponent,
        AgentCalendarComponent,
        SearchGoogleAddressPage,
        MvsEntitySearchComponent,
        MvsCustomerPhoneContactAvailabilityComponent,
        MvsPcCallDialComponent,

    ],

})
export class CoreModule { }
