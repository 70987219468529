
<div [routerLink]="link"
   class="px-3 py-2 hover:surface-hover border-round shadow-1 flex align-items-center surface-card cursor-pointer h-full">
    <div style="width:48px;height:48px;border-radius:10px"
         [ngClass]="uiCssColorClassHelper(navigationItem.rgbaColor,'bg',true)" class="inline-flex align-items-center justify-content-center mr-3">
        <i [ngClass]="uiCssColorClassHelper(navigationItem.rgbaColor,'text',false)"
           class="{{navigationItem.image ? navigationItem.image : 'fa-sharp-duotone fa-circle fa-solid'}} text-3xl"></i>
    </div>
    <div class="w-full">
       <div class="flex justify-content-between align-items-center gap-2">
           <div class="flex gap-2 align-items-center">
               <span class="text-900 text-xl font-medium">{{navigationItem.name}}</span>
               <span class="font-bold text-primary">{{navigationItem.alias}}</span>
           </div>
           <i class="fa-regular fa-arrow-right text-xl"></i>
       </div>
        <div class="text-600 text-m" [innerHTML]="navigationItem.description">
        </div>
    </div>
</div>


