<form [formGroup]="addressForm" id="address-form" action="" method="get" autocomplete="off">
    <p class="title">Sample address form for North America</p>
    <p class="note"><em>* = required field</em></p>

    <label class="full-field">
        <span class="form-label">Deliver to*</span>
        <input formControlName="shipAddress" #shipAddress id="ship-address" name="ship-address" required
               autocomplete="off"/>
    </label>

    <label class="full-field">
        <span class="form-label">Apartment, unit, suite, or floor #</span>
        <input formControlName="address2" id="address2" name="address2"/>
    </label>

    <label class="full-field">
        <span class="form-label">City*</span>
        <input formControlName="locality" id="locality" name="locality" required/>
    </label>

    <label class="slim-field-start">
        <span class="form-label">State/Province*</span>
        <input formControlName="state" id="state" name="state" required/>
    </label>

    <label class="slim-field-end" for="postcode">
        <span class="form-label">Postal code*</span>
        <input formControlName="postcode" id="postcode" name="postcode" required/>
    </label>

    <label class="full-field">
        <span class="form-label">Country/Region*</span>
        <input formControlName="country" id="country" name="country" required/>
    </label>

    <button type="button" class="my-button">Save address</button>

    <input type="reset" value="Clear form"/>
</form>