
<mvs-dm-upload
        *ngIf="ticketDto && ticketDto?.typeDto && objectIdentifier"
        [objectIdentifier]="objectIdentifier"
        [documentTypeId]="ticketDto?.typeDto?.dmDocumentTypeDtoId"
        [isDocumentTypeSelectionMode]="false"
        [showDocumentTypeDropdown]="false"
        (onDocumentUploaded)="handleDocumentUploaded($event)"
        layoutVariation="s-version"
></mvs-dm-upload>

<mvs-dm-object-documents *ngIf="objectIdentifier"
                         [objectIdentifier]="objectIdentifier"
                         [widgetObjectType]="'dm.DmDocumentAssignment'"
>
</mvs-dm-object-documents>

@if (euUserDocuments && euUserDocuments.entries.length) {
        <p-messages severity="error">
            <ng-template pTemplate>
                <div class="w-full flex justify-content-between align-items-center">
                    <div class="flex gap-3 align-items-center">
                        <i class="fa-regular fa-triangle-exclamation text-2xl"></i>
                        <div class="flex flex-column gap-1">
                            <span>Warnung: Hochgeladene Dokumente können schädliche Inhalte wie Viren oder Malware enthalten. Stellen Sie sicher, dass Sie der Quelle vertrauen, bevor Sie eine Datei öffnen.</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-messages>

        <mvs-widget [widgetData]="documentEuWidget"
                    *ngIf="documentEuWidget"
                    (onObjectSelect)="onObjectSelect($event['data'])">
        </mvs-widget>

}

