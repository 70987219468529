<div class="card">
    <p-tabView>

        <p-tabPanel header="Search by Customer">

            <div class="grid mt-5">
                <div class="col-12">
                    <search-flexible
                            [objectType]="'cr.Customer'"
                            (onObjectSelect)="handleCustomerObject($event)"
                    ></search-flexible>
                </div>
                <div class="col-12 mt-5">

                    <mvs-widget
                            *ngIf="widgetTableViaCustomer"
                            [widgetData]="widgetTableViaCustomer"
                            (onObjectSelect)="handleCustomerObjectSelect($event)"
                    ></mvs-widget>
                </div>

            </div>

        </p-tabPanel>


    </p-tabView>
</div>