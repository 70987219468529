@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
        <date-selection-dropdown
                [defaultOption]="'thisMonth'"
                (selectedDateStart)="handlePeriodFilter($event)"
        ></date-selection-dropdown>
    </div>

        <div class="grid dashboard">
            <div class="col-12 md:col-6 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="callHistoryWidget"
                        [widgetData]="callHistoryWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="callTypeWidget"
                        [widgetData]="callTypeWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="callAgentWidget"
                        [widgetData]="callAgentWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-8 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="recentCallsWidget"
                        [widgetData]="recentCallsWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-4 mb-2">

                <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                    <h4 class="text-lg font-medium">Call Result Overview</h4>
                    <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>

<!--                    <div class="flex h-full w-full gap-2">-->
<!--                        <div class="card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ busyCalls }}</h4>-->
<!--                            <p>Busy</p>-->
<!--                        </div>-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ no_answerCalls }}</h4>-->
<!--                            <p>No Answer</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex h-full w-full gap-2">-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ no_timeCalls }}</h4>-->
<!--                            <p>No Time</p>-->
<!--                        </div>-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ successCalls }}</h4>-->
<!--                            <p>Success</p>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>


        </div>

}