<div class="mvs-options-p-card h-full" [ngClass]="{ 'collapsed': isCollapsed }" [style]="{'width':width}">
    <p-card styleClass="h-full shadow-1">
        <ng-template pTemplate="header">

            <div class="mvs-sideOption-filter-header-open">

                <i class="fa-regular fa-filter text-xl text-primary cursor"></i>

                <i
                        (click)="toggleCollapse()"
                        class="fa-regular fa-arrow-up text-xl text-600 cursor">
                </i>

            </div>

        </ng-template>

        <ng-template pTemplate="content">

            <ng-container *ngIf="optionGroups && optionGroups.length && isCollapsable && !isCollapsed">
                <ng-container *ngFor="let item of optionGroups">
                    <div *ngIf="item.placement == 0">

                        <p-panel
                            #ticketPanel
                            [toggleable]="true"
                            [collapsed]="false"
                            expandIcon="pi pi-chevron-down"
                            collapseIcon="pi pi-chevron-up"
                            class="panel-blank"
                            [iconPos]="'end'">

                            <ng-template pTemplate="header">
                                <span class="text-lg font-medium w-full cursor" (click)="collapsedChange(ticketPanel)">
                                    {{item.label}}
                                </span>
                            </ng-template>

                            <ng-container *ngIf="item.groupItems && item.groupItems.length">
                                <div>
                                        <span
                                                *ngFor="let option of item.groupItems"
                                                class="tab"
                                                [class.active]="option.key === selectedItem?.key"
                                                (click)="setSelectedOption(option)">
                                            <span class="flex gap-2"><i [class]="option?.icon"></i> {{option.label}}</span>
                                            <span>{{option.count}}</span>
                                        </span>
                                </div>
                            </ng-container>
                        </p-panel>

                    </div>
                </ng-container>

            </ng-container>

        </ng-template>

        <ng-template pTemplate="footer">

            <ng-container *ngIf="isCollapsable && !isCollapsed">
                <ng-container *ngFor="let item of optionGroups">
                    <div *ngIf="item.placement == 1">
                        <div>
                                    <span
                                            *ngFor="let option of item.groupItems"
                                            class="tab"
                                            [class.active]="option.key === selectedItem?.key"
                                            (click)="setSelectedOption(option)">
                                        <span>{{option.label}}</span>
                                        <span>{{option.count}}</span>
                                    </span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>

    </p-card>
</div>