import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {AmSearchFlexibleAgentComponent} from "./search/am-search-flexible-agent/am-search-flexible-agent.component";
import {
    AmSearchFlexibleAgentPoolComponent
} from "./search/am-search-flexible-agent-pool/am-search-flexible-agent-pool.component";
import {AmAgentActiveObjectsComponent} from "./components/am-agent-active-objects/am-agent-active-objects.component";
import {
    AmAgentMainActiveObjectsComponent
} from "./components/am-agent-main-active-objects/am-agent-main-active-objects.component";
import {AmConfigPage} from "./page/overview/config/am-config.page";
import {AmTestPage} from "./page/overview/am-test.page.ts/am-test.page";
import {
    AgentCalendarManagementComponent
} from "./components/agent-calendar-management/agent-calendar-management.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {AmAgentAffiliateComponent} from "./components/am-agent-affiliate/am-agent-affiliate.component";
import {AmAffiliateScoreboardComponent} from "./components/am-affiliate-scoreboard/am-affiliate-scoreboard.component";
import {AmAgentOverviewPage} from "./page/overview/am-agent-overview-page/am-agent-overview.page";
import {
    AgentInfoDetailObjectComponent
} from "./components/agent-info-detail-object-component/agent-info-detail-object.component";
import {
    AgentPoolInfoDetailObjectComponent
} from "./components/agent-pool-info-detail-object-component/agent-pool-info-detail-object.component";
import {DragDropModule} from "primeng/dragdrop";
import {NsModule} from "../ns/ns.module";
import {AmAppointmentDashboardPage} from "./page/overview/am-appointment-dashboard-page/am-appointment-dashboard.page";
import {AmAppointmentListPage} from "./page/overview/am-appointment-list-page/am-appointment-list.page";
import {AmAgentMapComponent} from "./components/am-agent-map/am-agent-map.component";
import {FloatLabelModule} from "primeng/floatlabel";
import {AgentOverviewPage} from "./page/agent-overview-page/agent-overview.page";
import {UmModule} from "../um/um.module";
import {
    AgentStatisticsComponent
} from "./page/agent-overview-page/components/statisticsComponent/agent-statistics.component";
import {AgentKpiComponent} from "./page/agent-overview-page/components/kpiComponent/agent-kpi.component";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {UiModule} from "../ui/ui.module";
import {
    AgentInfoDetailObjectFullComponent
} from "./components/agent-info-detail-object-component/view/agent-info-detail-object-full/agent-info-detail-object-full.component";
import {
    AgentInfoDetailObjectSideComponent
} from "./components/agent-info-detail-object-component/view/agent-info-detail-object-side/agent-info-detail-object-side.component";
import {
    AgentInfoDetailObjectBaseComponent
} from "./components/agent-info-detail-object-component/view/agent-info-detail-object-base.component";

@NgModule({
    declarations: [
        AmSearchFlexibleAgentComponent,
        AmSearchFlexibleAgentPoolComponent,
        AmAgentActiveObjectsComponent,
        AmAgentMainActiveObjectsComponent,
        AmConfigPage,
        AmTestPage,
        AgentCalendarManagementComponent,
        AmAgentAffiliateComponent,
        AmAffiliateScoreboardComponent,
        AmAgentOverviewPage,
        AgentInfoDetailObjectComponent,
        AgentPoolInfoDetailObjectComponent,
        AmAppointmentDashboardPage,
        AmAppointmentListPage,
        AmAgentMapComponent,
        AgentOverviewPage,
        AgentStatisticsComponent,
        AgentKpiComponent,
        AgentInfoDetailObjectFullComponent,
        AgentInfoDetailObjectSideComponent,
        AgentInfoDetailObjectBaseComponent,

    ],
    exports: [
        AmSearchFlexibleAgentComponent,
        AmSearchFlexibleAgentPoolComponent,
        AmAgentActiveObjectsComponent,
        AmAgentMainActiveObjectsComponent,
        AgentCalendarManagementComponent,
        AmAgentOverviewPage,
        AgentInfoDetailObjectComponent,
        AgentPoolInfoDetailObjectComponent,
        AmAppointmentDashboardPage,
        AmAppointmentListPage,
        AgentInfoDetailObjectFullComponent,
        AgentInfoDetailObjectSideComponent,
        AgentInfoDetailObjectBaseComponent,

    ],
    imports: [
        CoreModule,
        FullCalendarModule,
        DragDropModule,
        NsModule,
        FloatLabelModule,
        UmModule,
        UiModule
    ]
})
export class AmModule implements DynamicModule {

    alias = 'am';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return AgentKpiComponent;
    }

    getStatisticsComponent() {
        return AgentStatisticsComponent;
    }
}
