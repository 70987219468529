import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TicketComponent} from "../../component/ticket/ticket.component";
import {
    TicketObjectQuickViewComponent
} from "../../component/ticket-object-quick-view/ticket-object-quick-view.component";
import {
    WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {
    TmTicketWfProcessStepActivityComponent
} from "../../component/wf/tm-ticket-wf-process-step-activity/tm-ticket-wf-process-step-activity.component";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
    WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {
    DtoDetail,
    DtoList,
    FilterCriteria, MvsCoreService,
    MvsCrudModeEnum,
    MvsCrudService,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    PagingDto,
    Sorting
} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {TicketActionAvailableResponseDto} from "./dto/ticket-action-available-response.dto";
import {MvsTicketComponent} from "../../../core/ticket/component/mvs-ticket/mvs-ticket.component";
import {TicketQuickCreateComponent} from "../../component/ticket-quick-create/ticket-quick-create.component";
import {AgentActiveObjectDto} from "../../../am/dto/agent-active-object.dto";

@Injectable({
    providedIn: 'root'
})
export class TicketService extends MvsCrudService implements WfProcessStepActivityMainProviderInterface {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/tm/tickets');
    }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return TicketComponent;
        }

        if (mode == MvsCrudModeEnum.create) {
            return TicketQuickCreateComponent;
        }

        return null;
    }

    /**
     * Get Object List Component.
     */
    getObjectListComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        return MvsTicketComponent;
    }

    getObjectQuickViewComponent(): Type<any> {
        return TicketObjectQuickViewComponent;
    }

    getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
        return TmTicketWfProcessStepActivityComponent;
    }

    includeTicketStatusOpenToFilter(filterList: FilterCriteria[]) {
        filterList.push(FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved));
        filterList.push(FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled));
    }

    includeTicketStatusClosedToFilter(filterList: FilterCriteria[]) {
        const statusFilter = FilterCriteria.createOr(
            FilterCriteria.create("status", FilterCriteria.cOperatorEqual, TmStatusEnum.resolved),
            FilterCriteria.create("status", FilterCriteria.cOperatorEqual, TmStatusEnum.cancelled));

        filterList.push(statusFilter);
    }

    static getComplexSelectionCustomerAndPerson(filterCriteriaList: FilterCriteria[], sorting: Sorting[]=[new Sorting("createdDate", false)]): ObjectRequestList {
        const complexSelection = ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('referenceCustomer')
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person"))
        );

        return ObjectRequestList.createComplexRequestList(
            true,
            complexSelection,
            filterCriteriaList,
            sorting,
            PagingDto.create(0, 20),
            null);
    }

    listTicketObjects(objectId: number, objectTypeAlias: string, includeIndirect: boolean = true, includeTemplate: boolean = true): Observable<DtoList<DtoDetail>> {
        const url = '/extend/listViaObject';

        let queryParams = {
            objectId: objectId,
            objectTypeAlias: objectTypeAlias,
            includeIndirect: includeIndirect,
            includeTemplate: includeTemplate
        };

        return this.getInternalReturnList(url, queryParams);
    }

    createTicketForObject(ticketTypeId: number, objectId: number, objectTypeAlias?: string): Observable<DtoDetail> {
        const apiUrl = `/action/createTicketForObject?ticketTypeId=${ticketTypeId}&objectId=${objectId}&objectTypeAlias=${objectTypeAlias}`;
        return this.createInternal(null, apiUrl);
    }

    getAvailableActions(ticketId: number): Observable<TicketActionAvailableResponseDto> {
        return <Observable<TicketActionAvailableResponseDto>><unknown>this.getInternal(this.apiUrl + `/${ticketId}/availableActions`)
    }

}
