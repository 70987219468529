<ng-container *ngIf="initialized && dto">

    @if (activeNavigationItem.action == 'standard') {
        <cm-contract-standard-navigation
                [objectIdentifier]="objectIdentifier"
                [importObjectContext]="customerImportObjectContext"
                [dto]="dto"
        ></cm-contract-standard-navigation>
    }

    @if (activeNavigationItem.action == 'documentUpload') {
        <cm-contract-document-upload
                [objectIdentifier]="objectIdentifier">
        </cm-contract-document-upload>
    }

    @if (activeNavigationItem.action == 'objects') {
        <cm-contract-objects-navigation
                [contractDto]="dto"
                [customerDto]="dto.joinCurrentMainCustomer">
        </cm-contract-objects-navigation>
    }

    @if (activeNavigationItem.action == 'notifications') {

        <mvs-ns-notification-types-for-object
                [objectIdentifier]="objectIdentifier"
                [mode]="1"
                class="mvs-widget block"
        ></mvs-ns-notification-types-for-object>
    }

    @if (activeNavigationItem.action == 'genericTickets') {

        <mvs-ticket
                [visible]="activeNavigationItem.action == 'genericTickets'"
                [objectIdentifier]="{objectType:'cm.Contract', objectId:dto.id}"
                (onTicketsCount)="handleTicketsCount($event)">
        </mvs-ticket>
    }

    @if (activeNavigationItem.action == 'genericWorkflows') {

        <mvs-workflow
                [visible]="activeNavigationItem.action == 'genericWorkflows'"
                [objectRequestList]="workflowObjectRequestList"
                (onWorkflowCount)="handleWorkflowsCount($event)">
        </mvs-workflow>
    }


</ng-container>
