import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {
    FilterCriteria,
    MvsCoreService,
    ObjectIdentifierData,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {CustomersContractsObjectsRuntime} from "../new-contract-navigation/data/customer-contracts-object-runtime";
import {
    CustomerContractPersonWidgetStyle
} from "../new-contract-navigation/uiStyle/customer-contract-person-widget-style";
import {ObjectInfo} from "../new-contract-navigation/data/object-info";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {HouseholdPersonService} from "../../../../../pm/service/api/household-person.service";
import {ObjectRelation} from "../new-contract-navigation/data/object-relation";

@Component({
    selector: 'cr-customer-contracts-navigation',
    templateUrl: './cr-customer-contracts-navigation.component.html',
})
export class CrCustomerContractsNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() viewType: string;

    customerContractsWidget: WidgetData;
    personRelationWidget: WidgetData;
    insurableObjectsWidget: WidgetData;
    householdPersonsWidget: WidgetData;
    showActive: boolean = true;
    customerContractsRuntime: CustomersContractsObjectsRuntime;
    contractPersonWidgetStyle: CustomerContractPersonWidgetStyle;
    personRelationObjects: ObjectInfo[];
    householdPersonObjects: ObjectInfo[];
    insuredObjects: ObjectInfo[];
    selectedRelationPersonContractIds: Set<number> = new Set<number>();
    selectedInsuredObjectContractIds: Set<number> = new Set<number>();
    selectedHouseholdPersonContractIds: Set<number> = new Set<number>();


    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService: MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerService: CustomerService,
                protected customerContractService: CustomerContractService,
                protected householdPersonService: HouseholdPersonService,
                protected configService: MvsConfigService,
    ) {
        super(coreService, confirmationService, messageService, configService, navigationService);

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.contractPersonWidgetStyle = new CustomerContractPersonWidgetStyle(this.configService, this.dto, []);
        this.getContractsRuntime();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    getContractsRuntime() {
        this.customerContractService.getCustomerContracts(this.objectIdentifier.objectId, this.showActive).subscribe(res => {
            this.customerContractsRuntime = res;
            this.contractPersonWidgetStyle.setCustomerContracts(this.customerContractsRuntime.customerContracts);
            this.postContractsProcessing();
            this.initialized = true;
        })
    }

    getBaseContractsFilter(): FilterCriteria {
        const filter = this.prepareInFilter(this.selectedHouseholdPersonContractIds);
        // return this.prepareInFilter(this.customerContractsRuntime.contractIds);
        return filter;
    }

    postContractsProcessing() {
        this.prepareHouseholdWidgetStyle();
        const filterCriteria = this.getBaseContractsFilter();
        this.getAllContracts(filterCriteria);
        this.refreshWidgets();
    }

    getAllContracts(filter: FilterCriteria) {


        this.customerContractsWidget = WidgetFactory.createWidgetList(
            'cr.customer.basic.contract.new',
            'Verträge',
            'table',
            'list',
            'entity',
            'cm.Contract',
            null,
            ObjectRequestList.createBasic(true, [filter], [])
        );
    }

    refreshWidgets() {
        if (!this.customerContractsRuntime || !this.customerContractsRuntime.objectInfos?.length) {
            return;
        }


        this.preparePersonRelationPersonWidget();
        this.prepareInsurableObjectsWidget();
        this.prepareHouseholdPersonsWidget();

    }

    preparePersonRelationPersonWidget() {

        this.personRelationObjects = this.customerContractsRuntime.objectInfos.filter(item => item.objectAlias == 'pm.Person');

        for (let item of this.personRelationObjects) {
            item['__count'] = this.getUniqueRecordsCount(item.id, this.customerContractsRuntime.personContracts);
        }

        this.personRelationWidget = WidgetFactory.createWidgetTransientExpandable(
            "cr.customer.new.contracts.navigation",
            'Verknüpfte Personen',
            'transientExpandable',
            'pm.Person',
            this.personRelationObjects,
            new ObjectRequestList(true, [], [])
        );

    }

    prepareHouseholdWidgetStyle() {
        this.householdPersonObjects = [];

        const clonedInfo = structuredClone(this.customerContractsRuntime.objectInfos);
        for (let item of this.customerContractsRuntime.householdPersonsIds) {
            const person = clonedInfo.find(value => item == value.id);
            if (person) {
                this.householdPersonObjects.push(person);
            }
        }

        for (let person of this.householdPersonObjects) {
            const customer = this.customerContractsRuntime.personCustomers.find(item => item.fromId == person.id);
            if (!customer) {
                continue; //we are only handling customers
            }

            const contracts = this.customerContractsRuntime.customerContracts.filter(value => value.fromId === customer.toId);
            contracts.forEach(c => this.selectedHouseholdPersonContractIds.add(c.toId));
            person['__selected'] = true;
            person['customerId'] = this.getCustomerIdFromPerson(person.id);
            this.contractPersonWidgetStyle.handleHouseholdPerson(person as any);
        }


        // for (let item of this.customerContractsRuntime.householdPersonsIds) {
        //     const person = this.customerContractsRuntime.objectInfos.find(value => item == value.id);
        //     if (person) {
        //         const customer = this.customerContractsRuntime.personCustomers.find(item => item.fromId == person.id);
        //         if (!customer) {
        //             continue; //we are only handling customers
        //         }
        //
        //         const contracts = this.customerContractsRuntime.customerContracts.filter(value => value.fromId === customer.toId);
        //         contracts.forEach(c => this.selectedHouseholdPersonContractIds.add(c.toId));
        //         this.contractPersonWidgetStyle.handleHouseholdPerson(person as any);
        //     }
        //
        // }
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();
    }

    getCustomerIdFromPerson(personId: number): number {
        const customer = this.customerContractsRuntime.personCustomers.find(item => item.fromId == personId);
        if (customer) {
            return customer.toId;
        }
        return null;
    }

    prepareInsurableObjectsWidget() {
        this.insuredObjects = this.customerContractsRuntime.objectInfos.filter(item => item.objectAlias == 'ci.InsurableObject');

        for (let item of this.insuredObjects) {
            item['__count'] = this.getUniqueRecordsCount(item.id, this.customerContractsRuntime.insurableObjectContracts);
        }

        this.insurableObjectsWidget = WidgetFactory.createWidgetTransientExpandable(
            "cr.customer.new.insurable.objects.navigation",
            'Insurable Objects',
            'transientExpandable',
            'ci.InsurableObject',
            this.insuredObjects,
            new ObjectRequestList(true, [], [])
        );
    }

    prepareHouseholdPersonsWidget() {

        for (let item of this.householdPersonObjects) {
            item['__count'] = this.getUniqueRecordsCount(item.id, this.customerContractsRuntime.customerContracts);
            // item['__count'] = this.customerContractsRuntime.customerContracts.filter(value => value.fromId == item.id)?.length;
        }


        this.householdPersonsWidget = WidgetFactory.createWidgetTransientExpandable(
            "cr.customer.new.household.persons.navigation",
            'Personen im Haushalt',
            'transientExpandable',
            'pm.Person',
            this.householdPersonObjects,
            new ObjectRequestList(true, [], [])
        );
    }

    getUniqueRecordsCount(attribute: number, entries: ObjectRelation[]): number {
        const records = entries.filter(value => value.fromId == attribute);
        const uniqueContracts = new Set<string>();
        for (const contract of records) {
            const normalizedPair = `${Math.min(contract.fromId, contract.toId)}-${Math.max(contract.fromId, contract.toId)}`;
            uniqueContracts.add(normalizedPair);
        }
        return uniqueContracts.size;
    }

    prepareInFilter(entries: Set<number>, attribute: string = 'id') {
        let ids: number[] = [];

        for (let entry of entries) {
            ids.push(entry);
        }
        return FilterCriteria.create(attribute, FilterCriteria.cOperatorIn, ids);

    }

    handleToggleContracts() {
        this.showActive = !this.showActive;
        this.refreshComponent();
    }

    handleHouseholdPersonSelect(event: ObjectIdentifierData) {
        this.selectedHouseholdPersonContractIds = new Set<number>();
        for (let person of this.householdPersonObjects) {
            if (person['__selected']) {
                const customer = this.customerContractsRuntime.personCustomers.find(item => item.fromId == person.id);
                if (!customer) {
                    continue; //we are only handling customers
                }

                const contracts = this.customerContractsRuntime.customerContracts.filter(value => value.fromId === customer.toId);
                contracts.forEach(c => this.selectedHouseholdPersonContractIds.add(c.toId));
            }
        }
        this.reloadContracts();

        const person = event.data;

        const customer = this.customerContractsRuntime.personCustomers.find(item => item.fromId == person.id);

        // || !person['__selected']
        if (!customer) {
            return; //we are only handling customers
        }

        person.customerId = this.getCustomerIdFromPerson(person.id);
        this.contractPersonWidgetStyle.handleHouseholdPerson(person);
        this.contractPersonWidgetStyle = this.contractPersonWidgetStyle.clone();

    }

    handleRelatedPersonSelect(event: ObjectIdentifierData) {
        this.selectedRelationPersonContractIds = new Set<number>();
        try {
            for (let item of this.personRelationObjects) {
                if (item['__selected'] === true) {
                    const contracts = this.customerContractsRuntime.personContracts.filter(
                        value => value.fromId === item.id
                    );
                    contracts.forEach(c => this.selectedRelationPersonContractIds.add(c.toId));
                }
            }
            this.reloadContracts();
        } catch (error) {
            console.error("Error in handleRelatedPersonSelect:", error);
        }
    }

    handleInsuredObjectSelect(event: ObjectIdentifierData) {
        this.selectedInsuredObjectContractIds = new Set<number>();
        try {
            for (let item of this.insuredObjects) {
                if (item['__selected'] === true) {
                    const contracts = this.customerContractsRuntime.insurableObjectContracts.filter(
                        value => value.fromId === item.id
                    );
                    contracts.forEach(c => this.selectedInsuredObjectContractIds.add(c.toId));
                }
            }
            this.reloadContracts();
        } catch (error) {
            console.error("Error in handleRelatedPersonSelect:", error);
        }
    }

    reloadContracts() {
        let combinedSet = new Set<number>();
        let filter: FilterCriteria;
        if (this.selectedRelationPersonContractIds.size || this.selectedInsuredObjectContractIds.size) {
            combinedSet = new Set<number>([...this.selectedRelationPersonContractIds, ...this.selectedInsuredObjectContractIds]);
            const intersection = new Set([...combinedSet].filter(id => this.selectedHouseholdPersonContractIds.has(id))); // only unique ids from left side and right side
            filter = this.prepareInFilter(intersection);
        } else {
            filter = this.getBaseContractsFilter();
        }
        this.getAllContracts(filter);

    }



}
