@if (initialized) {


    @if (activeNavigationItem.action === 'provisionAgent') {
        <mvs-widget
                *ngIf="provisionAgentWidget"
                [widgetData]="provisionAgentWidget">
        </mvs-widget>
    }

    @if (activeNavigationItem.action === 'provisionAgentGroup') {
        <mvs-widget
                *ngIf="provisionAgentGroupWidget"
                [widgetData]="provisionAgentGroupWidget">
        </mvs-widget>
    }

    @if (activeNavigationItem.action === 'provisionAgentComment') {
        <mvs-object-comments
                headline="Kommentare"
                [mainObject]="objectIdentifier"
                linkedEntityObjectType="cp.ProvisionAgentComment"
        ></mvs-object-comments>
    }
}