import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  DtoListAttributeRequestAggregateEnum,
  FilterCriteria,
  MvsCoreService,
  ObjectIdentifierData,
  ObjectPageComponent, ObjectRequestList, ObjectRequestListAttribute, ObjectRequestListGroupBy, PagingDto, PeriodHelper,
  WidgetData
} from "@kvers/alpha-core-common";
import {ContractStatusTypeEntryDto} from "../../../dto/contract-status-type-entry.dto";
import {ContractService} from "../../../service/api/contract.service";
import {ContractStatusTypeEntryService} from "../../../service/api/contract-status-type-entry.service";
import {ContractStatusInternal} from "../../../enum/contract-status-internal.enum";
import {MvsWidgetObjectDataChangeInfo} from "@kvers/alpha-ui";

@Component({
  selector: 'cm-contract-status',
  templateUrl: './cm-contract-status-type-overview.page.html',
  styleUrls: ['./cm-contract-status-type-overview.page.scss']
})
export class CmContractStatusTypeOverviewPage
              extends ObjectPageComponent implements OnInit {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  contractStatusWidgetData: WidgetData;
  contractStatusPeriodWidgetData: WidgetData;
  contractsWidgetData: WidgetData;
  statusTypeEntries: ContractStatusTypeEntryDto[] = [];

  constructor(protected route: ActivatedRoute,
              protected coreService: MvsCoreService,
              protected contractService: ContractService,
              protected contractStatusTypeEntryService: ContractStatusTypeEntryService,
              ) {

    super(route, coreService)
  }


  protected getObjectType(): string {
    // return Object Type
    return "cm.ContractStatusType";
  }



  handleCategoryStatusSelect(object: ObjectIdentifierData) {

    const filterCriteria = FilterCriteria.create('statusTypeEntry' , FilterCriteria.cOperatorEqual, object.objectId);
    this.showAllContracts(filterCriteria);
    this.showContractStatusPeriod(filterCriteria);
  }

  handleCategoryStatusPeriodSelect(object: ObjectIdentifierData) {

    const dates = PeriodHelper.getDateRangeForKey(object.objectId);
    const startDate = PeriodHelper.convertDateToApiDate(dates.startDate);
    const endDate = PeriodHelper.convertDateToApiDate(dates.endDate);
    const filterCriteria = FilterCriteria.create('lastModifiedDate' , FilterCriteria.cOperatorBetween, startDate,endDate);
    this.showAllContracts(filterCriteria);
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {

    const filterCriteria: FilterCriteria[] = [];
    filterCriteria.push(
        FilterCriteria.create('contractStatusType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)
    )
    const dtolistrequest = new ObjectRequestList(false,
        filterCriteria,
        []);
    this.contractStatusTypeEntryService.list(dtolistrequest).subscribe(res =>{
      this.statusTypeEntries = <ContractStatusTypeEntryDto[]>res.entries;
      this.showAllContractStatuses();
    })

    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  showAllContractStatuses() {

    const tempFilterCriteria = [];
    for (const item of this.statusTypeEntries) {
      if (item.statusInternal == ContractStatusInternal.active || item.statusInternal == ContractStatusInternal.pending) {
        tempFilterCriteria.push(FilterCriteria.create("statusTypeEntry", FilterCriteria.cOperatorEqual, item.id))
      }
    }

    const filterCriteria = new FilterCriteria();
    filterCriteria.or = tempFilterCriteria;
    //show contract status by category
    this.contractStatusWidgetData = new WidgetData();
    this.contractStatusWidgetData.id = 1;
    this.contractStatusWidgetData.idAlias = "cm.contract.status.type.group.by.contract.status";
    this.contractStatusWidgetData.name = "Contract Statuses";
    this.contractStatusWidgetData.uiComponent = "category";
    this.contractStatusWidgetData.dataProvider = "list";
    this.contractStatusWidgetData.dataSource = "entity.groupBy";
    this.contractStatusWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractStatusWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["statusTypeEntry"],
        [ new ObjectRequestListAttribute("statusTypeEntry", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
    this.contractStatusWidgetData.setParamValue("fieldCategory", "statusTypeEntry");
    this.contractStatusWidgetData.setParamValue("fieldCategoryCount", "statusTypeEntry_count");
    this.contractStatusWidgetData.setParamValue("selectionMode", "single");
    this.contractStatusWidgetData.setParamValue("size", "S");


    this.showContractStatusPeriod(filterCriteria);
    this.showAllContracts(filterCriteria);
  }

  showContractStatusPeriod(filterCriteria: FilterCriteria) {

    //show contract status by category period
    this.contractStatusPeriodWidgetData = new WidgetData();
    this.contractStatusPeriodWidgetData.id = 1;
    this.contractStatusPeriodWidgetData.idAlias = "cm.contract.status.type.group.by.category.period.contract.status";
    this.contractStatusPeriodWidgetData.name = "Contract Status By Period";
    this.contractStatusPeriodWidgetData.uiComponent = "category.period";
    this.contractStatusPeriodWidgetData.dataProvider = "list";
    this.contractStatusPeriodWidgetData.dataSource = "entity.groupBy";
    this.contractStatusPeriodWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractStatusPeriodWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["lastModifiedDate"],
        [ new ObjectRequestListAttribute("lastModifiedDate", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
    this.contractStatusPeriodWidgetData.setParamValue("fieldCategory", "lastModifiedDate");
    this.contractStatusPeriodWidgetData.setParamValue("fieldCategoryCount", "lastModifiedDate_count");
    this.contractStatusPeriodWidgetData.setParamValue("selectionMode", "single");
    this.contractStatusPeriodWidgetData.setParamValue("size", "S");

  }

  showAllContracts(filterCriteria: FilterCriteria) {

    //show contracts
    this.contractsWidgetData = new WidgetData();
    this.contractsWidgetData.idAlias = "cm.contract.status.overview..show.contracts";
    this.contractsWidgetData.name = "Contracts";
    this.contractsWidgetData.uiComponent = "table";
    this.contractsWidgetData.dataProvider = "list";
    this.contractsWidgetData.dataSource = "entity";
    this.contractsWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractsWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [filterCriteria],
        [],
        PagingDto.create(0, 10));

    this.contractsWidgetData.dataProviderListRequest.objectRequestComplex = this.contractService.getContractsComplexSelection();
    this.contractsWidgetData.setParamValue("size", "S");
    this.contractsWidgetData.setParamValue("objectWidget", "data");
  }

  handleObjectDataChange(event: MvsWidgetObjectDataChangeInfo) {

    this.refreshComponent();
  }

}
