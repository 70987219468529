import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoDetail} from "@kvers/alpha-core-common";
import {catchError, tap} from "rxjs/operators";
import {MvsObjectActionEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class UiDashboardService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/ui/uiDashboards')
  }

}
