@if (initialized) {
    <div class="grid">
        <div class="col-12">
            @if (activeNavigationItem.action === 'logic') {
                <div class="flex flex-column gap-3">
                    <div style="margin-left: auto;">
                        <div class="flex gap-3">
                            <p-button icon="pi pi-play" styleClass="p-button-outlined" label="Run Syntax Check" (click)="checkSyntax()"></p-button>
                            <p-button icon="pi pi-save" label="Speichern" (click)="updateScript()"></p-button>
                        </div>
                    </div>
                    <div>

                        <app-code-editor
                                [editorContent]="script"
                                [logicLanguage]="dto.logicLanguage"
                                (contentChanged)="onContentChange($event)"
                                (onLogicLanguageChanged)="handleLogicLanguageChange($event)"
                        ></app-code-editor>


                    </div>
                    <ng-container *ngIf="syntaxResponse">
                        <div class="bg-black-alpha-90 p-2 border-round mt-2">
                            <div class="pb-3 pt-2 px-2 text-white font-bold">
                                Syntax Result
                            </div>
                            <div class="bg-white py-2">
                                @for (error of syntaxResponse?.errors; track error) {

                                    <div class="text-red-500 p-2">
                                        <i class="fa-solid fa-circle-exclamation"></i> {{ error }}
                                    </div>
                                }

                                @for (warning of syntaxResponse?.warnings; track warning) {

                                    <div class="text-yellow-500 p-2">
                                        <i class="fa-solid fa-triangle-exclamation"></i> {{ warning }}
                                    </div>
                                }
                            </div>

                        </div>

                    </ng-container>
                </div>
            }
            @if(activeNavigationItem.action === 'imports') {
                <mvs-lg-variables
                        [variableProviderService]="logicImportService"
                        alias="lg.LogicImport"
                        attributeName="variableName"
                        title="Logic Imports"
                        [contentProviderId]="dto.id"
                ></mvs-lg-variables>
            }
            @if(activeNavigationItem.action === 'exports') {
                <mvs-lg-variables
                        [variableProviderService]="logicExportService"
                        alias="lg.LogicExport"
                        attributeName="name"
                        title="Logic Exports"
                        [contentProviderId]="dto.id"
                ></mvs-lg-variables>
            }
            @if(activeNavigationItem.action === 'testen') {
                <mvs-lg-test-case [screen]="'sm'" [logicId]="objectIdentifier.objectId"></mvs-lg-test-case>
            }

        </div>

    </div>
}

