import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild,} from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import {DocumentTestData} from "./data/document-test-data";
import {ContextMenu} from "primeng/contextmenu";
import {BoundingBox} from "./data/bounding-box.interface";
import {IdentifiedField} from "./data/identified-field.interface";
import {MenuItem} from "primeng/api";
import {SearchRequestDto} from "../../../si/logic/search-request.dto";
import {IndexRunService} from "../../../si/service/api/index-run.service";
import {SearchResultDto} from "../../../si/logic/search-result.dto";
import {CustomerContractService} from "../../../cr/service/api/customer-contract.service";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {CustomerContractDto} from "../../../cr/dto/customer-contract.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {ContractDto} from "../../../cm/dto/contract.dto";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {PmPersonDto} from "../../../cr/dto/pm-person.dto";
import {DmDocumentAssignmentService} from "../../service/api/dm-document-assignment.service";
import {DmDocumentAssignmentDto} from "../../dto/dm-document-assignment.dto";
import {DmEntityStatusEnum} from "../../enum/dm-entity-status.enum";
import {DmDocumentAssignmentTypeEnum} from "../../enum/dm-document-assignment-type.enum";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DmDocumentService} from "../../service/api/dm-document.service";
import {DocumentHelper} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {
    ObjectChangeInformation,
    ObjectChangeInformationActionEnum
} from "@kvers/alpha-core-common";
import {CustomerEntityAssignmentType} from "../../../cr/enum/customer-entity-assignment-type.enum";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../cr/service/api/customer.service";
import {CrCustomerDto} from "../../../cr/dto/cr-customer.dto";
import {DmDocumentDto} from "../../dto/dm-document.dto";
import {DmDocumentStatusInternalEnum} from "../../enum/dm-document-status-internal.enum";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormValueListEntryDto} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";

export interface UserAction {
    action: string,
    entity: string,
    relation: string,
    color: string;
}

export interface DmDocumentRelevantField {
    actualField: string,
    field: string,
    value: any
}

export interface DocumentLabels {
    label: string,
    value: DocumentLabelValue[]
}

export interface DocumentLabelValue {
    boundingBoxes: any;
    page: number,
    text: string
}

@Component({
    selector: 'dm-document-processing',
    templateUrl: `./dm-document-processing.component.html`,
    styleUrls: ['./dm-document-processing.component.scss']
})
export class DmDocumentProcessingComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('labelCanvas') labelCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('cm') contextMenu: ContextMenu;
    formData: any;
    zoom: number = 1;
    rotation: number = 0;
    background: HTMLImageElement = new Image();
    selectedLabel: string;

    @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
    selectedFile: File | null = null;
    boundingBoxes: BoundingBox[] = [];
    selectedBox: BoundingBox | null = null;
    selectedBoxes: BoundingBox[] | null = null;
    currentLabel: string = '';
    wheelEventListener: any;
    pageNumber: number = 1;
    totalPages: number;
    prefilledLabels: IdentifiedField[];
    isContextMenuVisible: boolean = false;
    selectedText: string;
    busy: boolean = false;
    matchingCustomers: CustomerDto[];
    matchingContracts: ContractDto[];
    searchObjectTypeAlias: string [];
    contextMenuItems: MenuItem[];
    selectedCustomer: CustomerDto;
    selectedContract: ContractDto;
    tempSelectedCustomer: CustomerDto;
    tempSelectedContract: ContractDto;
    documentId: number;
    navObjectIdParameterObserver: Subscription;
    navObjectIdParameterObserverQuery: Subscription;
    pdfUrl: any;
    documentAssignments: DmDocumentAssignmentDto[];
    documentAssignment: DmDocumentAssignmentDto;
    objectTypeDtoId: number;
    activeTab: number = 1;
    initialized: boolean = false;
    userActions: UserAction[];
    actionWidget: WidgetData;
    historyWidget: WidgetData;
    documentDto: DmDocumentDto;
    enumValues: MvsFormValueListEntryDto[];
    documentFields: DocumentLabels[];
    documentStatus: number;
    dmDocumentType: number;
    documentList: DmDocumentDto[];
    selectedDocumentIndex: number;

    constructor(protected renderer: Renderer2,
                protected customerContractService: CustomerContractService,
                protected documentAssignmentService: DmDocumentAssignmentService,
                protected documentService: DmDocumentService,
                protected customerService: CustomerService,
                protected objectService: ObjectTypeService,
                protected coreService: MvsCoreService,
                protected route: ActivatedRoute,
                protected navigationService: MvsObjectNavigationService,
                protected messageService: MvsMessageService,
                protected indexService: IndexRunService) {
    }

    ngAfterViewInit() {
        this.wheelEventListener = this.onMouseWheel.bind(this);
        this.canvas.nativeElement.addEventListener('wheel', this.wheelEventListener);
    }

    ngOnDestroy() {
        this.canvas.nativeElement.removeEventListener('wheel', this.wheelEventListener);

        if (this.navObjectIdParameterObserver){
            this.navObjectIdParameterObserver.unsubscribe();

        }

        if (this.navObjectIdParameterObserverQuery){
            this.navObjectIdParameterObserverQuery.unsubscribe();
        }

    }


    ngOnInit() {

        this.navObjectIdParameterObserver = this.route.queryParams.subscribe(params => {

            this.documentStatus = +params['status'];
            if (params['status'] && params['status'] == 'null' ) {
                this.documentStatus = null;
            }
            this.dmDocumentType = +params['dmDocumentType'];
        });

        this.navObjectIdParameterObserverQuery = this.route.params.subscribe(params => {

            const newObject = new ObjectIdentifier(this.getObjectType(), +params["id"]);
            if (newObject) {
                this.documentId = newObject.objectId;
                this.getDocumentList();
            }
        });

        this.configurePdfLibrary();
        this.getFormData();
    }

    getDocumentList() {

        const filterCriteria = this.getDocumentListFilterCriteria();
        const objectRequest = new ObjectRequestList(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)],
        );

        this.documentService.list(objectRequest).subscribe((res: DtoList<DmDocumentDto>) => {
            this.documentList = res.entries;
            const selectedIndex = this.getDocumentIndex();
            this.setNewDocumentIndex(selectedIndex);
            this.getDocument();
        })

    }

    getDocumentListFilterCriteria(): FilterCriteria[] {
        const filterCriteria: FilterCriteria[] = [];

        if (this.documentStatus != undefined) {
            filterCriteria.push(FilterCriteria.create('status', FilterCriteria.cOperatorEqual, this.documentStatus));
        }

        if (this.dmDocumentType) {
            filterCriteria.push(FilterCriteria.create('dmDocumentType', FilterCriteria.cOperatorEqual, this.dmDocumentType));
        }
        return filterCriteria;
    }

    configurePdfLibrary() {
        const path = "./assets/pdf.worker.min.js";
        pdfjsLib.GlobalWorkerOptions.workerSrc = path;
    }

    getFormData() {
        this.formData = DocumentTestData.analyzedResult;
        this.documentFields = this.getFieldsFromDocument();
    }

    getDocument() {
        this.busy = true;
        // const objectRequest = new ObjectRequestList(
        //     true,
        //     [FilterCriteria.create('document',FilterCriteria.cOperatorEqual, this.documentId)],
        //     []
        // );

        const objectRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id',FilterCriteria.cOperatorEqual, this.documentId)],
            []
        );

        objectRequest.objectRequestComplex = ObjectRequestComplex.build(
            false,
            false,
            ObjectRequestComplexNode.createRelationNode("documentAssignments",
                ObjectRequestRelation.createList(
                    "dm.DmDocumentAssignment",
                    "document",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        );

        this.documentService.downloadViaUrl(this.documentId).subscribe(value => {
            this.pdfUrl = value;
            this.displayFile();
        });

        this.documentService.list(objectRequest).subscribe(res => {

            this.documentDto = res.entries[0];
            // this.documentSelectedStatus = this.documentDto.status;
            this.documentAssignments = [];
            this.documentAssignments = this.documentDto['documentAssignments'];
            if (this.documentAssignments && this.documentAssignments.length) {

                if (this.documentAssignments.length == 1) {
                    this.documentAssignment = this.documentAssignments[0];
                } else {
                    const desiredAssignment = this.documentAssignments.find(item => item.documentAssignmentType ==  DmDocumentAssignmentTypeEnum.primary);
                    this.documentAssignment = desiredAssignment;
                }
                this.getObjectAlias();
                this.activeTab = 2;
                this.resetTempValues();
            } else {
                this.documentAssignment = null;
                this.busy = false;
                this.initialized = true;
            }
            this.prepareDocumentStatusValues(res.form);
        },error => {

        })

        // this.documentAssignmentService.list(objectRequest).subscribe(res => {
        //     this.documentAssignments = [];
        //     this.documentAssignments = res.entries;
        //     if (this.documentAssignments && this.documentAssignments.length) {
        //
        //         if (this.documentAssignments.length == 1) {
        //             this.documentAssignment = this.documentAssignments[0];
        //         } else {
        //             const desiredAssignment = this.documentAssignments.find(item => item.documentAssignmentType ==  DmDocumentAssignmentTypeEnum.primary);
        //             this.documentAssignment = desiredAssignment;
        //         }
        //         this.documentDto = this.documentAssignment['documentDto'];
        //         if (this.documentDto) {
        //             this.documentSelectedStatus = this.documentDto.status;
        //         }
        //
        //         this.getObjectAlias();
        //         this.activeTab = 2;
        //         this.resetTempValues();
        //     } else {
        //         this.documentAssignment = null;
        //         this.busy = false;
        //         this.initialized = true;
        //     }
        //     this.prepareDocumentStatusValues(res.form.subForms['documentDto']);
        // })
    }

    prepareDocumentStatusValues(form: MvsFormDto) {
        const values = this.getDocumentStatusValues(form);
        this.enumValues = values.map(value => {
            return {
                backgroundColor: value.backgroundColor,
                color: value.color,
                icon: value.image,
                key: value.key,
                label: value.label,
                priority: value.priority,
                technicalKey: value.technicalKey,
                style: this.documentDto.status == value.key ? {'background-color': 'var(--primary-200)'} : '',
                command: () => this.onDocumentStatusChange(value.key)
            }
        });
        // this.enumValues = values;
    }

    getDocumentStatusValues(form: MvsFormDto): MvsFormValueListEntryDto[] {
        const field = form.getFormField('status');
        return field.valueList.entries;
    }

    // getEnumValues(enumObj: any): MenuItem[] {
    //     return Object.keys(enumObj)
    //         .filter(key => isNaN(Number(key)))
    //         .map(key => ({
    //             label: key,
    //             style: this.selectedStatus == key ? {'background-color': 'var(--primary-200)'} : '',
    //             command: () => this.onDocumentStatusChange(key)
    //         }));
    // }

    onDocumentStatusChange(status: number): void {
        if (this.documentDto.status == status) {
            return;
        }
        // this.documentSelectedStatus = status;

        const dto = new DmDocumentDto();
        dto.id = this.documentDto.id;
        dto.status = status;

        this.documentService.update(dto).subscribe((res: DmDocumentDto) => {
            this.documentDto = res;
            this.messageService.showSuccessMessage("", "Dokumentenstatus aktualisiert!");
            // this.prepareDocumentStatusValues();
        })

    }

    // findRelevantInformation(alias: string) {
    //     const crudService = this.coreService.getCrudService(alias);
    //     const objectRequestList = new ObjectRequestList(
    //         false,
    //         [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.documentAssignment.objectId)],
    //         []
    //     );
    //     crudService.list(objectRequestList).subscribe(res => {
    //         debugger
    //     })
    // }

    resetTempValues() {
        this.tempSelectedContract = null;
        this.tempSelectedCustomer = null;
    }

    findRelevantInformation(alias: string) {
        const crudService = this.coreService.getCrudService(alias);
        const objectRequestList = new ObjectRequestList(
            false,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.documentAssignment.objectId)],
            []
        );
        objectRequestList.objectRequestComplex = this.getComplexRequest(alias);
        crudService.list(objectRequestList).subscribe(res => {
            this.matchingCustomers = [];
            this.matchingContracts = [];
            if (this.documentAssignment.objectTypeDtoId == 1576) {
                this.selectedCustomer = res.entries[0];
                this.matchingCustomers.push(this.selectedCustomer);
                this.initialized = true;
            } else {
                this.selectedContract = res.entries[0];
                this.matchingContracts.push(this.selectedContract);
                this.getCustomerPerson(this.selectedContract['customerDto']);
            }
            // this.prepareUserActions();
            this.busy = false;
        })
    }

    getObjectAlias() {
        this.objectService.getViaObjectId(this.documentAssignment.objectTypeDtoId).then(res => {
            this.findRelevantInformation(res.alias);
        });
    }

    getComplexRequest(alias: string): ObjectRequestComplex {
        let objectRequestComplex = new ObjectRequestComplex();
        if (alias == 'cr.Customer') {
            objectRequestComplex = this.getCustomerPersonComplexRequest();
        } else if (alias == 'cm.Contract') {
            objectRequestComplex = ObjectRequestComplex.build(
                false,
                false,
                ObjectRequestComplexNode.createRelationNode(
                    "customerDto", ObjectRequestRelation.createJoin("+currentMainCustomer"))
            )
        }
        return objectRequestComplex;
    }

    getCustomerPersonComplexRequest(): ObjectRequestComplex {
        return ObjectRequestComplex.build(
            false,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode('person')
        )
    }

    getCustomerPerson(customer: CrCustomerDto) {
        if (!customer) {
            this.initialized = true;
            return;
        }
        const objectRequest = new ObjectRequestList(
            false,
            [FilterCriteria.create('id',FilterCriteria.cOperatorEqual, customer.id)],
            []
        );

        objectRequest.objectRequestComplex = this.getCustomerPersonComplexRequest();

        this.customerService.list(objectRequest).subscribe(res => {
            this.selectedCustomer = res.entries[0];
            this.initialized = true;
        })

    }


    protected getObjectType(): string {
        return "dm.DmDocument"; // return Object Type
    }

    async onFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            this.selectedFile = input.files[0];
            this.boundingBoxes = [];
            this.selectedBox = null;
            this.currentLabel = '';
            await this.displayFile();
        }
    }

    // async displayFile() {
    //     if (!this.selectedFile) {
    //         return;
    //     }
    //
    //     this.prepareLabelsFromResponse();
    //     this.prepareContextMenu();
    //
    //     const fileType = this.selectedFile.type;
    //     if (fileType === 'application/pdf') {
    //         await this.displayPdf(this.selectedFile, this.pageNumber);
    //     } else if (fileType.startsWith('image/')) {
    //         await this.displayImage(this.selectedFile);
    //     } else {
    //         console.error('Unsupported file type:', fileType);
    //     }
    // }


    // private async displayPdf(pdfFile: File, pageNumber: number) {
    //     const pdfData = await this.readFileAsArrayBuffer(pdfFile);
    //     const uint8Array = new Uint8Array(pdfData);
    //     const pdf = await pdfjsLib.getDocument({data: uint8Array}).promise;
    //     this.totalPages = pdf.numPages;
    //     const page = await pdf.getPage(pageNumber);
    //     const viewport = page.getViewport({scale: 1.5 * this.zoom});
    //     const canvas = this.canvas.nativeElement;
    //     const context = canvas.getContext('2d')!;
    //     canvas.height = viewport.height;
    //     canvas.width = viewport.width;
    //
    //     await page.render({canvasContext: context, viewport: viewport}).promise;
    //     const imageData = canvas.toDataURL('image/png');
    //
    //     this.background.src = imageData; // Store the rendered page as background
    //     this.background.onload = async () => {
    //         await this.recognizeText();
    //     };
    // }

    async displayFile() {
        if (this.pdfUrl) {
            // Assuming this.pdfUrl is a Blob URL
            const response = await fetch(this.pdfUrl);
            const blob = await response.blob();
            this.selectedFile = new File([blob], 'document.pdf', { type: 'application/pdf' });
            const base64Code = DocumentHelper.arrayBufferToBase64(this.pdfUrl);
            // console.log(base64Code);
        }

        if (!this.selectedFile) {
            return;
        }

        this.prepareLabelsFromResponse();
        this.prepareContextMenu();

        const fileType = this.selectedFile.type;
        if (fileType === 'application/pdf') {
            await this.displayPdf(this.selectedFile, this.pageNumber);
        } else if (fileType.startsWith('image/')) {
            await this.displayImage(this.selectedFile);
        } else {
            console.error('Unsupported file type:', fileType);
        }
    }


    private async displayPdf(pdfFile: File, pageNumber: number) {
        const pdfData = await this.readFileAsArrayBuffer(pdfFile);
        const uint8Array = new Uint8Array(pdfData);
        const pdf = await pdfjsLib.getDocument({data: uint8Array}).promise;
        this.totalPages = pdf.numPages;
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({scale: 1.5 * this.zoom});
        const canvas = this.canvas.nativeElement;
        const context = canvas.getContext('2d')!;
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({canvasContext: context, viewport: viewport}).promise;
        const imageData = canvas.toDataURL('image/png');

        this.background.src = imageData; // Store the rendered page as background
        this.background.onload = async () => {
            await this.recognizeText();
        };
    }


    prepareContextMenu() {
        this.contextMenuItems = this.prefilledLabels.map(field => {
            return {
                label: field.label,
                command: () => this.onLabel(field.label)
            }
        });
    }

    private async displayImage(imageFile: File) {
        const imageData = await this.readFileAsDataURL(imageFile);
        const img = new Image();
        img.onload = async () => {
            const canvas = this.canvas.nativeElement;
            const context = canvas.getContext('2d')!;
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);
            await this.recognizeText();
        };
        img.src = imageData;
    }

    private async recognizeText() {
        this.parseAzureResponse(this.formData);
        this.drawBoundingBoxes();
        this.updateCanvasTransform();
    }

    private parseAzureResponse(response: any) {
        const page = response.ocrResult.analyzeResult[this.pageNumber - 1];

        this.boundingBoxes = [];

        this.boundingBoxes = page.words.map((word: any) => {
            const predefinedLabel = this.checkForPredefinedLabels(word);
            let labelColor: string = null;
            let selected: boolean = false;
            let label: string = null;
            if (predefinedLabel) {
                label = predefinedLabel.label
                labelColor = this.getLabelColors(label);
                selected = true;
            }
            const [topLeft, topRight, bottomRight, bottomLeft] = word.boundingPolygon;
            const canvas = this.canvas.nativeElement;
            const scaleX = (canvas.width / this.zoom) / 8.2639; // Adjust for zoom level
            const scaleY = (canvas.height / this.zoom) / 11.6806; // Adjust for zoom level
            return {
                top: topLeft.y * scaleY * this.zoom,
                left: topLeft.x * scaleX * this.zoom,
                width: (topRight.x - topLeft.x) * scaleX * this.zoom,
                height: (bottomLeft.y - topLeft.y) * scaleY * this.zoom,
                text: word.content,
                selected: selected,
                labelColor: labelColor,
                label: label
            };
        });
    }


    private drawBoundingBoxes() {
        const canvas = this.canvas.nativeElement;
        const context = canvas.getContext('2d')!;

        context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before redrawing
        context.drawImage(this.background, 0, 0);

        this.boundingBoxes.forEach(box => {

            if (box.selected && box.label) {
                context.strokeStyle = box.labelColor;
                context.lineWidth = 2;
                context.strokeRect(box.left, box.top, box.width, box.height);

            } else if (box.selected) {
                context.fillStyle = 'rgba(197, 255, 185, 0.5)';
                context.fillRect(box.left, box.top, box.width, box.height);

            } else {
                context.fillStyle = 'rgba(255, 255, 204, 0.5)';
                context.fillRect(box.left, box.top, box.width, box.height);
            }
        });
    }


    checkForPredefinedLabels(word: any): IdentifiedField {
        let identifiedLabel: IdentifiedField;
        for (let label of this.prefilledLabels) {
            if (label.textPaths && label.textPaths.includes(word.content)) {
                identifiedLabel = label;
                break;
            }
        }
        return identifiedLabel;
    }

    onBoxClick(box: BoundingBox) {

        if (!this.selectedBoxes) {
            this.selectedBoxes = [];
        }

        box.selected = !box.selected;

        if (box.selected) {
            this.selectedBoxes.push(box);
        }
        // else {
        //     this.removeTextPath(box.label, box.text);
        //     box.labelColor = null;
        //     box.label = null;
        // }

        this.selectedBox = box.selected ? box : null;
        this.currentLabel = box.selected ? (box.label || '') : '';
        // this.highlightSelectedBox(box);
        //this.drawBoundingBoxes(); //will be needed in next version when we have to provide labels
    }

    removeTextPath(label: string, text: string) {
        const labelField = this.prefilledLabels.find(item => item.label == label);

        if (!labelField) {
            return;
        }

        const index = labelField.textPaths.findIndex(item => item == text);
        if (index > -1) {
            labelField.textPaths.splice(index, 1);
        }
    }

    private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    }

    private readFileAsDataURL(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    zoomIn() {
        if (this.zoom >= 1.5) {
            return;
        }
        this.zoom += 0.05;
        this.updateCanvasTransform();
    }

    zoomOut() {
        if (this.zoom < 1) {
            return;
        }
        this.zoom -= 0.05;
        this.updateCanvasTransform();

    }

    onMouseWheel(event: WheelEvent) {
        if (!this.selectedFile) {
            return;
        }
        event.preventDefault();
        if (event.deltaY < 0) {

            if (this.zoom >= 1.5) {
                return;
            }

            this.zoom += 0.1; // Zoom in
        } else {

            if (this.zoom < 1) {
                return;
            }

            this.zoom -= 0.1; // Zoom out
        }

        this.updateCanvasTransform();
    }


    fullScreen() {
        this.zoom = 1;
        this.updateCanvasTransform();
    }

    rotateClockwise() {
        this.rotation = (this.rotation + 90) % 360;
        const ctx = this.canvas.nativeElement.getContext('2d');
        ctx.rotate(20 * Math.PI / 180);
        this.updateCanvasTransform();
    }

    rotateCounterClockwise() {
        this.rotation = (this.rotation - 90 + 360) % 360;
        this.updateCanvasTransform();
    }

    updateCanvasTransform() {
        const canvas = this.canvas.nativeElement;
        const transform = `scale(${this.zoom}) rotate(${this.rotation}deg)`;
        this.renderer.setStyle(canvas, 'transform', transform);
    }

    onCanvasClick(event: MouseEvent) {
        event.preventDefault();
        const canvas = this.canvas.nativeElement;
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left) / this.zoom;
        const y = (event.clientY - rect.top) / this.zoom;

        this.boundingBoxes.forEach(box => {
            if (x >= box.left && x <= box.left + box.width && y >= box.top && y <= box.top + box.height) {
                this.onBoxClick(box);
                this.selectedText = box.text;
                // if (box.selected) {
                //     this.openContextMenu(event);
                // } else {
                //     this.contextMenu.hide();
                // }
                this.openContextMenu(event);
            }
        });
    }

    openContextMenu(event: MouseEvent): void {
        event.preventDefault();
        setTimeout(() => {
            this.contextMenu.show(event);
        }, 0);
    }


    onLabel(selectedLabel: string) {
        if (this.activeTab != 1) {
            this.activeTab = 1;
        }
        this.selectedLabel = selectedLabel;
        const label = this.prefilledLabels.find(item => item.label == this.selectedLabel);
        if (label) {
            for (let item of this.selectedBoxes) {
                for (let value of this.boundingBoxes) {
                    if (item.text == value.text && item.top == value.top && item.left == value.left) {
                        this.selectedText = item.text;
                        value.label = label.label;
                        value.labelColor = this.getLabelColors(label.label);
                        if (!label.textPaths) {
                            label.textPaths = [];
                        }
                        label.textPaths.push(value.text);
                    }
                }
            }

            // this.drawBoundingBoxes();
            this.contextMenu.hide();
            this.findMatches();
            this.selectedBoxes = [];
            this.isContextMenuVisible = false;
        }
    }

    findMatches() {
        this.searchObjectTypeAlias = [];
        this.tempSelectedContract = null;
        this.tempSelectedCustomer = null;
        // const label = this.selectedLabel.toLowerCase();
        // if (label.includes('customer')) {
        //     this.searchObjectTypeAlias.push('cr.Customer');
        // } else if (label.includes('contract')) {
        //     this.searchObjectTypeAlias.push('cm.Contract');
        // }
        const alias = this.getAliasFromSelectedLabel();
        if (!alias) {
            return
        }
        this.searchObjectTypeAlias.push(alias);
        this.searchMatches(this.searchObjectTypeAlias, this.selectedText);
    }

    getAliasFromSelectedLabel(): string {
        const label = this.selectedLabel.toLowerCase();
        if (label.includes('customer')) {
            return 'cr.Customer';
        } else if (label.includes('contract')) {
            return 'cm.Contract';
        }
        return null;
    }

    getFieldsFromDocument() {
        return this.formData.labels.labels;
    }

    prepareLabelsFromResponse() {
        this.prefilledLabels = [];


        for (let label of this.documentFields) {

            const value = label.value[0];
            const texts = value.text?.split(' ');
            let polygon = value.boundingBoxes;
            if (polygon) {
                polygon = polygon[0];
            }
            const obj: IdentifiedField = {
                label: label.label,
                value: label.value,
                boundingPolygon: polygon,
                text: value.text,
                textPaths: texts
            }
            this.prefilledLabels.push(obj);
        }
    }

    getLabelColors(label: any): string {
        const lcLabel = label.toLowerCase();

        if (lcLabel.includes('price')) {
            return 'bg-red-500';
        } else if (lcLabel.includes('customer')) {
            return 'bg-green-500';
        } else if (lcLabel.includes('contract')) {
            return 'bg-purple-500';
        }
        return 'black';
    }

    async onChangePage(event: 'next' | 'previous') {
        let pageNumber = this.pageNumber;

        if (event == 'next') {
            pageNumber++;
        } else {
            pageNumber--;
        }

        if (pageNumber <= 0) {
            pageNumber = this.pageNumber;
            return;
        }

        if (pageNumber > this.totalPages) {
            pageNumber = this.totalPages;
            return;
        }

        this.pageNumber = pageNumber;
        this.zoom = 1;
        this.rotation = 0;
        await this.displayPdf(this.selectedFile, this.pageNumber);

    }

    searchMatches(objectTypeAlias: string[], searchString: string) {
        if (!searchString) {
            this.busy = false;
            return;
        }
        const searchRequestDto = new SearchRequestDto();
        searchRequestDto.requestedObjectTypeAlias = objectTypeAlias;
        searchRequestDto.maxHits = 5;
        // searchRequestDto.searchString = searchString;
        searchRequestDto.searchString = 'Richard Conley';
        // searchRequestDto.searchString = '9210';
        this.busy = true;

        this.indexService.searchRequest(searchRequestDto).subscribe(value => {
            this.filterResults(value);
            this.busy = false;
        }, error => {
            this.busy = false;
        });
    }

    filterResults(value: SearchResultDto) {
        this.matchingCustomers = [];
        this.matchingContracts = [];

        if (this.searchObjectTypeAlias.includes('cr.Customer')) {
            this.prepareCustomers(value);

        } else if (this.searchObjectTypeAlias.includes('cm.Contract')) {
            this.prepareContracts(value);
        }
    }

    prepareCustomers(value: SearchResultDto) {
        this.matchingCustomers = [];
        for (let item of value.searchResultEntries) {
            const fields = item.dataFields;
            const dto = new CustomerDto();
            dto.personDto = new PmPersonDto();
            dto.personDto.firstName = fields['person_firstName']?.originalValue;
            dto.personDto.lastName = fields['person_lastName']?.originalValue;
            dto.personDto['nickName'] = fields['person_nickName']?.originalValue;
            dto.id = fields['id'].originalValue;
            dto.alias = fields['alias'].originalValue;
            this.matchingCustomers.push(dto);
        }
    }

    prepareContracts(value: SearchResultDto) {
        this.matchingContracts = [];
        for (let item of value.searchResultEntries) {
            const dto = new ContractDto();
            dto.contractIdentifier = item.dataFields['contractIdentifier']['originalValue'];
            dto.id = item.dataFields['id']['originalValue'];
            this.matchingContracts.push(dto);
        }
    }

    onSearchCustomerContracts(customer: CustomerDto) {
        if (this.tempSelectedCustomer && this.tempSelectedCustomer.id == customer.id) {
            this.tempSelectedCustomer = null;
            return;
        }
        this.tempSelectedCustomer = customer;
        this.tempSelectedContract = null;
        this.matchingContracts = null;
        const objRequest: ObjectRequestList = new ObjectRequestList(
            false,
            [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, +customer.id)],
            []
        );
        objRequest.objectRequestComplex = ObjectRequestComplex.build(
            false,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contract")
        );

        this.customerContractService.list(objRequest).subscribe((res: DtoList<CustomerContractDto>) => {
            this.matchingContracts = [];
            for (let item of res.entries) {
                this.matchingContracts.push(item.contractDto);
            }
        });
    }

    onContractClick(contract: ContractDto) {
        if (this.tempSelectedContract && this.tempSelectedContract.id == contract.id) {
            this.tempSelectedContract = null;
            return;
        }

        this.tempSelectedContract = contract;

        if (!this.tempSelectedCustomer) {
            this.busy = true;
            // user is coming from contract, we need to display the customer with its user id
            const objRequest: ObjectRequestList = new ObjectRequestList(
                false,
                [FilterCriteria.create('contract', FilterCriteria.cOperatorEqual, +contract.id)],
                []
            );
            objRequest.objectRequestComplex = ObjectRequestComplex.build(
                false,
                false,
                ObjectRequestComplexNode.createSimpleAttributeNode("customer").addNodes(
                    ObjectRequestComplexNode.createSimpleAttributeNode("person")
                )
            );
            this.customerContractService.list(objRequest).subscribe(res => {
                const dto: CustomerDto = res.entries[0].customerDto;
                if (dto) {
                    this.tempSelectedCustomer = dto;
                    this.matchingCustomers = [];
                    this.matchingCustomers.push(dto);
                }
                this.busy = false;
            }, error => {
                this.busy = false;
            })

        }
    }

    handleNavigation() {
        this.selectedContract = this.tempSelectedContract;
        this.selectedCustomer = this.tempSelectedCustomer;
        if (this.documentAssignment) {
            this.handleUpdateDocumentAssignment();
        } else {
            this.handleCreateDocumentAssignment();
        }
        this.historyWidget = null;
        this.actionWidget = null;
        this.tempSelectedContract = null;
        this.tempSelectedCustomer = null;
    }

    handleCreateDocumentAssignment() {
        const dto = this.prepareDocumentAssignmentDto();

        this.documentAssignmentService.create(dto).subscribe((res: DmDocumentAssignmentDto) => {
            this.documentAssignment = res;
            this.activeTab = 2;
            this.prepareDocumentStatusValues(res.form);
            this.resetTempValues();
            // this.prepareUserActions();
        })
    }

    handleUpdateDocumentAssignment() {
        const dto = this.prepareDocumentAssignmentDto();
        dto.id = this.documentAssignment.id;
        this.documentAssignmentService.update(dto).subscribe((res: DmDocumentAssignmentDto) => {
            this.documentAssignment = res;
            this.activeTab = 2;
            this.resetTempValues();
            // this.prepareUserActions();
        })
    }

    prepareDocumentAssignmentDto(): DmDocumentAssignmentDto {
        const dto = new DmDocumentAssignmentDto();
        dto.documentDtoId = this.documentId;
        dto.objectId = this.getObjectId();
        dto.objectTypeDtoId = this.getObjectTypeDtoId();
        dto.entityStatus = DmEntityStatusEnum.active;
        dto.documentAssignmentType = DmDocumentAssignmentTypeEnum.primary;
        return dto;
    }

    getObjectId(): number {

        if (this.selectedContract) {
            return this.selectedContract.id;
        } else if (this.selectedCustomer) {
            return this.selectedCustomer.id;
        }
        return null;
    }

    getObjectTypeDtoId(): number {
        if (this.selectedContract) {
            return 1175193; //contract objectTypeId
        } else if (this.selectedCustomer) {
            return 1576 //customer objectTypeId
        }
        return null;
    }

    handleAddContract() {
        if (!this.tempSelectedCustomer) {
            return;
        }
        this.activeTab = 3;

    }

    handleContractCreate(event: ObjectChangeInformation) {

        if (event.action == ObjectChangeInformationActionEnum.created) {
            const createdObjectIdentifier = new ObjectIdentifier(event.objectType, event.after.id);
            this.createCustomerContract(createdObjectIdentifier);
        }
    }

    createCustomerContract(objectIdentifier: ObjectIdentifier) {

        const customerContractDto = new CustomerContractDto();
        customerContractDto.customerDtoId = this.tempSelectedCustomer.id;
        customerContractDto.contractDtoId = objectIdentifier.objectId;
        customerContractDto.assignmentType = CustomerEntityAssignmentType.main;
        customerContractDto.entityStatus = EntityStatusEnum.active;

        this.customerContractService.create(customerContractDto).subscribe((res: CustomerContractDto) => {
            this.onSearchCustomerContracts(this.tempSelectedCustomer);
            this.tempSelectedContract = null;
            this.activeTab = 1;
        })
    }

    handleTabNavigation() {
        this.activeTab = 1;
    }

    handlePreviousDocument() {
        const index = this.getDocumentIndex();
        if (index <= this.documentList.length) {
            const newDocumentIndex = index - 1;
            this.documentId = this.documentList[newDocumentIndex].id;
            this.setNewDocumentIndex(newDocumentIndex);
            this.getDocument();
        }
    }

    handleNextDocument() {
        const index = this.getDocumentIndex();
        if (index >= 0) {
            const newDocumentIndex = index + 1;
            this.documentId = this.documentList[newDocumentIndex].id;
            this.setNewDocumentIndex(newDocumentIndex);
            this.getDocument();
        }
    }

    getDocumentIndex(): number {
        const index = this.documentList.findIndex(item => item.id == this.documentId);
        return index;
    }

    setNewDocumentIndex(index: number) {
        this.selectedDocumentIndex = index;
    }
    handleNewTabNavigation(objectType: string, objectId: number) {
        const objectIdentifier = new ObjectIdentifier(objectType, objectId);
        this.navigationService.handleObjectNavigation(objectIdentifier, null, { openNewTab: true});
    }


    protected readonly DmDocumentStatusInternalEnum = DmDocumentStatusInternalEnum;
}
