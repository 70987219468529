import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";

@Component({
    selector: 'cm-update-insurable-object',
    templateUrl: './cm-update-insurable-object.component.html',
})
export class CmUpdateInsurableObjectComponent
    implements OnInit {

    @Input() customerId: number;
    @Input() insurableObjectId: number;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() defaultCreateDto: DtoDetail;

    @Output() onChangedObject = new EventEmitter<ObjectChangeInformation>();


    // customerId: number;

    customerInsurableObjectForm: WidgetData;
    insurableObjectForm: WidgetData;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();
    }
    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.showWidgets();
        this.initialized = true;
    }

    showWidgets() {
        this.showCustomerInsurableObjectForm();
        this.showInsurableObjectForm();
    }

    showCustomerInsurableObjectForm() {
        // const dtoRequest= new ObjectRequestList(
        //     true,
        //     [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
        //     []
        //     );
        this.customerInsurableObjectForm = WidgetFactory.createWidgetList(
            "cm.update.customer.insurable.object.form.customer",
            "Customer Insurable Object",
            "object",
            "list",
            "entity",
            this.objectIdentifier.objectType,
            null,
            null,
            WidgetDataParam.create('objectId', this.objectIdentifier.objectId)
        )
        this.customerInsurableObjectForm.functionCallbacks = this.widgetCustomerInsuranceToolbarFunction();

    }

    widgetCustomerInsuranceToolbarFunction() {
        return {onFunctionObjectDefaultNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.customerId;
                dto['insurableObjectDtoId'] = this.insurableObjectId;
                dto.id = this.objectIdentifier.objectId;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);
                formControlOverwrite.addField('insurableObjectDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }};
    }

    showInsurableObjectForm() {
        // const dtoRequest= new ObjectRequestList(
        //     true,
        //     [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.insurableObjectId)],
        //     []
        // );
        this.insurableObjectForm = WidgetFactory.createWidgetList(
            "cm.update.insurable.object.form.object",
            "Insurable Object",
            "object",
            "list",
            "entity",
           'ci.InsurableObject',
            null,
            null,
            WidgetDataParam.create('objectId', this.insurableObjectId)
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }
    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleChangedObject(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }

}
