import {Component} from '@angular/core';
import {FilterCriteria, PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../service/api/customer.service";
import {DtoList} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsSearchService} from "@kvers/alpha-ui";


@Component({
  selector: 'mvs-ns-dashboard-page',
  templateUrl: './cr-dashboard.page.html',
  styleUrls: ['./cr-dashboard.page.scss']
})
export class CrDashboardPage extends PageComponent{

  widgetCustomerStatus: WidgetData;
  widgetCustomerTypes: WidgetData;
  widgetCustomerHistory: WidgetData;
  widgetCustomerTable: WidgetData;

  customerService: CustomerService;
  customersListDto: DtoList;

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected messageService: MvsMessageService,
      protected searchService: MvsSearchService,
      protected objectTypeService: ObjectTypeService) {
    super(route, coreService);

  }




  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();

    this.refreshComponent();

    this.initialized = true;
  }

  customerForm: MvsFormDto;

  customerFormFields: MvsFormFieldDto[];

  objectTypes: ObjectTypeDto[] | any[];
  selectedObjectType: ObjectTypeDto;

  objectTypeFields: MvsFormFieldDto[];

  searchString: string;
  searchResults: DtoDetail[];
  searchResultDtoList: DtoList;
  autoCompleteField: string;


  initComponent() {
    this.customerService = <CustomerService>this.coreService.getCrudService("cr.Customer");
  }

  /**
   * Search for customer.
   * @param event
   */
  async search(event) {

    this.searchResultDtoList = await this.searchService.searchEntity(this.selectedObjectType.alias, event.query);
    this.searchResults = this.searchResultDtoList.entries;

    this.autoCompleteField = "alias";
  }


  /**
   * Refresh Component.
   */
  refreshComponent() {
    this.refreshWidgetCustomerTable();
    this.refreshWidgetCustomerHistory();
    this.refreshWidgetCustomerTypes();
    this.refreshWidgetCustomerStatus();
  }

  /**
   * Widget latest Customers.
   */
  refreshWidgetCustomerTable() {

    this.widgetCustomerTable = new WidgetData();
    this.widgetCustomerTable.id = 1;
    this.widgetCustomerTable.idAlias = "cr.dashboard.customer.table";
    this.widgetCustomerTable.name = "Neue Kunden";
    this.widgetCustomerTable.uiComponent = "table";
    this.widgetCustomerTable.dataProvider = "list";
    this.widgetCustomerTable.dataSource = "entity";
    this.widgetCustomerTable.dataProviderObject = "cr.Customer";
    this.widgetCustomerTable.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        [FilterCriteria.create('id', FilterCriteria.cOperatorBetween,5633,5640)],
        [new Sorting("createdDate", false)],
        PagingDto.create(0,10));
    this.widgetCustomerTable.setParamValue("selectionMode", "single");
    this.widgetCustomerTable.setParamValue("size", "S");

  }

  /**
   * Widget: Customer Types.
   */
  refreshWidgetCustomerTypes() {

    this.widgetCustomerTypes = new WidgetData();
    this.widgetCustomerTypes.id = 1;
    this.widgetCustomerTypes.idAlias = "cr.dashboard.customer.type.table";
    this.widgetCustomerTypes.name = "Mime Types";
    this.widgetCustomerTypes.uiComponent = "category";
    this.widgetCustomerTypes.dataProvider = "list";
    this.widgetCustomerTypes.dataSource = "entity.groupBy";
    this.widgetCustomerTypes.dataProviderObject = "cr.Customer";
    this.widgetCustomerTypes.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["customerType"],
        [ new ObjectRequestListAttribute("customerType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetCustomerTypes.setParamValue("fieldCategory", "customerType");
    this.widgetCustomerTypes.setParamValue("fieldCategoryCount", "customerType_count");
    this.widgetCustomerTypes.setParamValue("selectionMode", "single");
    this.widgetCustomerTypes.setParamValue("size", "S");

  }

  /**
   * Widget Customer Status
   */
  refreshWidgetCustomerStatus() {

    this.widgetCustomerStatus = new WidgetData();
    this.widgetCustomerStatus.id = 1;
    this.widgetCustomerStatus.idAlias = "cr.dashboard.customer.type.table";
    this.widgetCustomerStatus.name = "Mime Types";
    this.widgetCustomerStatus.uiComponent = "category";
    this.widgetCustomerStatus.dataProvider = "list";
    this.widgetCustomerStatus.dataSource = "entity.groupBy";
    this.widgetCustomerStatus.dataProviderObject = "cr.Customer";
    this.widgetCustomerStatus.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["entityStatus"],
        [ new ObjectRequestListAttribute("entityStatus", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetCustomerStatus.setParamValue("fieldCategory", "entityStatus");
    this.widgetCustomerStatus.setParamValue("fieldCategoryCount", "entityStatus_count");
    this.widgetCustomerStatus.setParamValue("selectionMode", "single");
    this.widgetCustomerStatus.setParamValue("size", "S");

  }


  /**
   * Widget Customer History.
   */
  refreshWidgetCustomerHistory() {

    this.widgetCustomerHistory = new WidgetData();
    this.widgetCustomerHistory.id = 1;
    this.widgetCustomerHistory.idAlias = "cr.dashboard.customer.history.1";
    this.widgetCustomerHistory.name = "Neu Kunden";
    this.widgetCustomerHistory.uiComponent = "history.chart";
    this.widgetCustomerHistory.dataProvider = "list";
    this.widgetCustomerHistory.dataSource = "entity.groupBy";
    this.widgetCustomerHistory.dataProviderObject = "cr.Customer";
    this.widgetCustomerHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [],
        [],
        ["customerType", "createdDate"],
        [ new ObjectRequestListAttribute("customerType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetCustomerHistory.setParamValue("fieldCategory", "customerType");
    this.widgetCustomerHistory.setParamValue("fieldCategoryCount", "customerType_count");
    this.widgetCustomerHistory.setParamValue("historyPeriodMode", "year");
    this.widgetCustomerHistory.setParamValue("fieldDate", "createdDate");
    this.widgetCustomerHistory.setParamValue("selectionMode", "single");
    this.widgetCustomerHistory.setParamValue("size", "S");

  }



}
