import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {PersonSearchComponent} from "../../component/person-search/person-search.component";
import {
  WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {
  PmCreatePersonWfProcessStepActivityComponent
} from "../../component/wf/pm-create-person-wf-process-step-activity/pm-create-person-wf-process-step-activity.component";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
  WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {MvsCoreGetNameInterface} from "@kvers/alpha-ui";
import {PmPersonDto} from "../../../cr/dto/pm-person.dto";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {PmPersonComponent} from "../../component/pm-person-component/pm-person.component";

@Injectable({
  providedIn: 'root'
})
export class PersonService
    extends MvsCrudService
    implements
        WfProcessStepActivityMainProviderInterface,
        MvsCoreGetNameInterface<PmPersonDto> {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/pm/persons');
  }

  getFlexibleSearchComponent(): Type<any> {
    return PersonSearchComponent;
  }

  getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
    return PmCreatePersonWfProcessStepActivityComponent;
  }

  /**
   * Retrieve DTO object name.
   * @param dto
   */
  getObjectNameFromDto(dto: PmPersonDto): string {
    return dto.firstName + " " + dto.lastName;
  }

  // PmPersonComponent
  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update) : Type<any> {

    if (mode == MvsCrudModeEnum.update) {
      return PmPersonComponent;
    }

    return null;
  }

}
