import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {TicketService} from "../../../../service/api/ticket.service";
import {TmStatusEnum} from "../../../../enum/tm-status.enum";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'tm-kpi.component.html',
})
export class TmKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalTickets: number = 0;
    resolvedTickets: number = 0;
    thisMonthOpenTickets: number = 0;
    thisWeekOpenTickets: number = 0;
    thisMonthClosedTickets: number = 0;
    thisWeekClosedTickets: number = 0;


    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Ticket Module'}];

    constructor(
        protected ticketService: TicketService) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalTickets();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalTickets() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['type'],
            [new ObjectRequestListAttribute('type', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.ticketService.groupBy(objectRequestList).subscribe(res => {
            this.totalTickets = res.entries.reduce((acc, item) => acc + item['type_count'], 0);
            this.getTotalResolvedTickets();
        }, error => {
            this.getTotalResolvedTickets();
        });
    }


    getTotalResolvedTickets() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.ticketService.groupBy(objectRequestList).subscribe(res => {
            for (let entry of res.entries) {
                if (entry['status'] == TmStatusEnum.resolved) {
                    this.resolvedTickets = this.resolvedTickets + entry['status_count']
                }
            }
            this.getThisMonthTickets();
        }, error => {
            this.getThisMonthTickets();
        });
    }

    getThisMonthTickets() {
        const objectRequestList = this.getThisMonthRequest('status');
        this.ticketService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthOpenTickets = res.entries
                .filter(item => item['status'] !== TmStatusEnum.resolved && item['status'] !== TmStatusEnum.cancelled)
                .reduce((acc, item) => acc + item['status_count'], 0);

            this.thisMonthClosedTickets = res.entries
                .filter(item => item['status'] === TmStatusEnum.resolved || item['status'] === TmStatusEnum.cancelled)
                .reduce((acc, item) => acc + item['status_count'], 0);
            this.getThisWeekTickets();
        }, error => {
            this.getThisWeekTickets();
        });
    }

    getThisWeekTickets() {
        const objectRequestList = this.getThisWeekRequest('status');
        this.ticketService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekOpenTickets = res.entries
                .filter(item => item['status'] !== TmStatusEnum.resolved && item['status'] !== TmStatusEnum.cancelled)
                .reduce((acc, item) => acc + item['status_count'], 0);

            this.thisWeekClosedTickets = res.entries
                .filter(item => item['status'] === TmStatusEnum.resolved || item['status'] === TmStatusEnum.cancelled)
                .reduce((acc, item) => acc + item['status_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Tickets', value: this.totalTickets,
            },
            {
                label: 'Resolved Tickets', value: this.resolvedTickets,
            },
            {
                label: 'This Month Open and Closed Tickets', value: [this.thisMonthOpenTickets, this.thisMonthClosedTickets],
            },
            {
                label: 'This Week Open and Closed Tickets', value: [this.thisWeekOpenTickets, this.thisWeekClosedTickets],
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
