<ng-container *ngIf="initialized">


<!--<h1>Agent</h1>-->
<!--    <mvs-object-quick-view [objectIdentifier]="objectIdentifierAgent" [size]="'S'"></mvs-object-quick-view>-->

<h1>Tickets Quickview</h1>
    <div class="grid">
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>

        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
    </div>

<!--<h1>Process Quickview</h1>-->
<!--    <div class="grid">-->
<!--        <div class="col-12 lg:col-4">-->
<!--            <mvs-object-quick-view [objectIdentifier]="objectIdentifierProcess" [size]="'S'"></mvs-object-quick-view>-->
<!--        </div>-->
<!--        <div class="col-12 lg:col-4">-->
<!--            <mvs-object-quick-view [objectIdentifier]="objectIdentifierProcess" [size]="'M'"></mvs-object-quick-view>-->
<!--        </div>-->
<!--        <div class="col-12 lg:col-4">-->
<!--            <mvs-object-quick-view [objectIdentifier]="objectIdentifierProcess" [size]="'L'"></mvs-object-quick-view>-->
<!--        </div>-->
<!--        <div class="col-12 lg:col-4">-->
<!--            <mvs-object-quick-view [objectIdentifier]="objectIdentifierProcess" [size]="'XL'"></mvs-object-quick-view>-->
<!--        </div>-->
<!--    </div>-->

    <!--
<h2>Quickview Stacks</h2>
    <h3>Tickets S</h3>
    <div class="grid">
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-3">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'S'"></mvs-object-quick-view>
        </div>
    </div>
    <h3>Tickets M</h3>
    <div class="grid">
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'M'"></mvs-object-quick-view>
        </div>
    </div>
    <h3>Tickets L</h3>
    <div class="grid">
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'L'"></mvs-object-quick-view>
        </div>
    </div>
    <h3>Tickets XL</h3>
    <div class="grid">
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>
        <div class="col-12 lg:col-4">
            <mvs-object-quick-view [objectIdentifier]="objectIdentifierTicket" [size]="'XL'"></mvs-object-quick-view>
        </div>

    </div>
-->



</ng-container>