import {Component, OnChanges, OnDestroy, OnInit,} from '@angular/core';
import {
    FilterCriteria,
    MvsCoreService,
    MvsMessageService,
    ObjectIdentifier,
    ObjectRequestList, ObjectTypeService
} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessRuntimeBindingDto} from "../../../../wf/service/dto/wf-process-runtime-binding.dto";
import {WfActivityProcessingResultFieldDto} from "../../../../wf/service/dto/wf-activity-processing-result-field.dto";
import {WfProcessRuntimeService} from "../../../../wf/service/wf-process-runtime.service";
import {DmDocumentDto} from "../../../dto/dm-document.dto";
import {DmDocumentAssignmentDto} from "../../../dto/dm-document-assignment.dto";
import {WfProcessStepActivityObjectService} from "../../../../wf/service/api/wf-process-step-activity-object.service";
import {WfProcessStepActivityService} from "../../../../wf/service/api/wf-process-step-activity.service";
import {WfBindingFieldActionEnum} from "../../../../wf/enum/wf-binding-field-action.enum";
import {WfProcessStepActivityObjectDto} from "../../../../wf/dto/wf-process-step-activity-object.dto";
import {WfProcessStepActivityDto} from "../../../../wf/dto/wf-process-step-activity.dto";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-document-object',
    templateUrl: './dm-document-upload-wf-process-step-activity.component.html',
})
export class DmDocumentUploadWfProcessStepActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    objectIdentifier: ObjectIdentifier;
    documentTypeUploadDtoId: number;

    busy: boolean;

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected processStepActivityObjectService?: WfProcessStepActivityObjectService,
        protected objectTypeService?: ObjectTypeService,) {
        super(coreService, messageService)
    }

    ngOnInit() {
        super.ngOnInit();
        this.checkForExistingEntries();
    }

    initComponent() {

    }


    refreshComponent() {

        if (this.activityType?.activityDocumentDto?.documentTypeUploadDtoId) {
            this.documentTypeUploadDtoId = this.activityType?.activityDocumentDto?.documentTypeUploadDtoId;
        }

        const importReferenceObject = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "importReferenceObject");

        if (importReferenceObject) {
            this.objectIdentifier = new ObjectIdentifier(importReferenceObject.objectType.alias, importReferenceObject.objectValue.id);
        }


    }

    // WfProcessStepActivityObjectService
    handleDocumentUpload(document: DmDocumentDto) {

        this.handleActivityObject(document.id)

    }


    async handleActivityObject(documentId: number) {

        if (this.busy) {
            return;
        }
        const objectType = await this.objectTypeService.getViaObjectType('dm.DmDocument');

        const filterCriteria: FilterCriteria[] = [
            FilterCriteria.create('processStepActivity', FilterCriteria.cOperatorEqual, this.activity.id)
        ]

        const objectRequest = ObjectRequestList.createBasic(false, filterCriteria, []);

        this.processStepActivityObjectService.list(objectRequest).subscribe(res => {
            if (res.entries.length) {
                const result: WfActivityProcessingResultFieldDto = {
                    alias: 'processedDocumentAssignment',
                    action: WfBindingFieldActionEnum.selected,
                    valueObjectTypeAlias: 'wf.WfProcessStepActivityObject',
                    valueObjectId: res.entries[0].id
                };

                WfProcessRuntimeService.setResult([result]);
                this.busy = false;
            } else {

                const object: Partial<WfProcessStepActivityObjectDto> = {
                    processStepActivityDtoId: this.activity.id,
                    action: WfBindingFieldActionEnum.created,
                    objectTypeDtoId: objectType.id,
                    objectId: documentId,
                }

                this.processStepActivityObjectService.create(object as WfProcessStepActivityObjectDto).subscribe(res => {
                    const result: WfActivityProcessingResultFieldDto = {
                        alias: 'processedDocumentAssignment',
                        action: WfBindingFieldActionEnum.created,
                        valueObjectTypeAlias: 'wf.WfProcessStepActivityObject',
                        valueObjectId: res.id
                    };
                    WfProcessRuntimeService.setResult([result])
                    this.busy = false;
                })
            }
        }, error => {
            this.busy = false;
        })

    }

    private checkForExistingEntries(): void {
        // Common logic for retrieving existing entries and setting results
        const filterCriteria: FilterCriteria[] = [
            FilterCriteria.create('processStepActivity', FilterCriteria.cOperatorEqual, this.activity.id)
        ];
        const objectRequest = ObjectRequestList.createBasic(false, filterCriteria, []);

        this.processStepActivityObjectService.list(objectRequest).subscribe(res => {
            if (res.entries.length) {
                const result: WfActivityProcessingResultFieldDto = {
                    alias: 'processedDocumentAssignment',
                    action: WfBindingFieldActionEnum.selected,
                    valueObjectTypeAlias: 'wf.WfProcessStepActivityObject',
                    valueObjectId: res.entries[0].id
                };
                WfProcessRuntimeService.setResult([result]);
            }
            this.busy = false;
        }, error => {
            this.busy = false;
        });
    }


}

