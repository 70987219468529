import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {HcConfigPage} from "./page/overview/hc-config/hc-config.page";
import {
  IntegrationTypeFullComponent
} from "./component/object-base-components/integration-type-object-base-component/view/full/integration-type-full.component";
import {
  IntegrationTypeSideComponent
} from "./component/object-base-components/integration-type-object-base-component/view/side/integration-type-side.component";
import {
  IntegrationTypeBaseComponent
} from "./component/object-base-components/integration-type-object-base-component/view/integration-type-base.component";
import {
  IntegrationTypeObjectBaseComponent
} from "./component/object-base-components/integration-type-object-base-component/integration-type-object-base.component";

import { DragDropModule } from 'primeng/dragdrop';
import {
  AlphaBatchEntityObjectBaseComponent
} from "../ha/component/object-base-components/alpha-batch-entity-object-base-component/alpha-batch-entity-object-base.component";
import {
  AlphaBatchEntityFullComponent
} from "../ha/component/object-base-components/alpha-batch-entity-object-base-component/view/alpha-batch-entity-full-component/alpha-batch-entity-full.component";
import {
  AlphaBatchEntitySideComponent
} from "../ha/component/object-base-components/alpha-batch-entity-object-base-component/view/alpha-batch-entity-side-component/alpha-batch-entity-side.component";
import {
  AlphaBatchEntityBaseComponent
} from "../ha/component/object-base-components/alpha-batch-entity-object-base-component/view/alpha-batch-entity-base.component";
import {
  IntegrationAdapterTypeObjectBaseComponent
} from "./component/object-base-components/integration-adapter-type-object-base-component/integration-adapter-type-object-base.component";
import {
  IntegrationAdapterTypeBaseComponent
} from "./component/object-base-components/integration-adapter-type-object-base-component/view/integration-adapter-type-base.component";
import {
  IntegrationAdapterTypeSideComponent
} from "./component/object-base-components/integration-adapter-type-object-base-component/view/side/integration-adapter-type-side.component";
import {
  IntegrationAdapterTypeFullComponent
} from "./component/object-base-components/integration-adapter-type-object-base-component/view/full/integration-adapter-type-full.component";

@NgModule({
  declarations: [
    HcConfigPage,
    IntegrationTypeFullComponent,
    IntegrationTypeSideComponent,
    IntegrationTypeBaseComponent,
    IntegrationTypeObjectBaseComponent,
    AlphaBatchEntityObjectBaseComponent,
    AlphaBatchEntityFullComponent,
    AlphaBatchEntitySideComponent,
    AlphaBatchEntityBaseComponent,
    IntegrationAdapterTypeObjectBaseComponent,
    IntegrationAdapterTypeBaseComponent,
    IntegrationAdapterTypeSideComponent,
    IntegrationAdapterTypeFullComponent,
  ],
  exports:[
    HcConfigPage,
    IntegrationTypeFullComponent,
    IntegrationTypeSideComponent,
    IntegrationTypeBaseComponent,
    IntegrationTypeObjectBaseComponent,
    AlphaBatchEntityObjectBaseComponent,
    AlphaBatchEntityFullComponent,
    AlphaBatchEntitySideComponent,
    AlphaBatchEntityBaseComponent,
    IntegrationAdapterTypeObjectBaseComponent,
    IntegrationAdapterTypeBaseComponent,
    IntegrationAdapterTypeSideComponent,
    IntegrationAdapterTypeFullComponent,
  ],
  imports: [
    CoreModule,
    DragDropModule,

  ]
})
export class HcModule { }
 
 
 
