import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail, DtoList,
    MvsCoreService,
    MvsFormValueListEntryDto,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";
import {AppointmentSelectionDto} from "../../dto/appointment-selection.dto";
import {TmTicketAppointmentDto} from "../../../tm/dto/tm-ticket-appointment.dto";
import {AgentService} from "../../../am/service/api/agent.service";
import {CustomerService} from "../../../cr/service/api/customer.service";
import {TicketAppointmentService} from "../../../tm/service/api/ticket-appointment.service";


@Component({
    selector: 'as-customer-appointment-page',
    templateUrl: './as-customer-appointment.page.html',
    styleUrl: "as-customer-appointment.page.scss"
})
export class AsCustomerAppointmentPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    ticketAppointmentWidget: WidgetData;
    ticketAppointmentWidgetTest: WidgetData;
    customersCoords: { coordinates: [number, number], dto: DtoDetail, initials: string }[];
    items: MenuItem[] | undefined;
    statusList: { id: number, name: string }[] | undefined;
    selectedStatus: MvsFormValueListEntryDto;
    entityStatus: MvsFormValueListEntryDto[];
    testAgents: { coordinates: [number, number], dto: DtoDetail, initials: string }[] = [];
    showSideDrawer: boolean = false;

    showConsultants: boolean = false;

    appointmentSelectionDtoList: (Partial<AppointmentSelectionDto>)[];

    ticketAppointmentList: DtoList<TmTicketAppointmentDto>;

    constructor(
        protected agentService: AgentService,
        protected customerService: CustomerService,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected router: Router,
        protected ticketAppointmentService: TicketAppointmentService,
        protected navigationService: MvsObjectNavigationService
    ) {
        super(route, coreService)
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();

        this.getTicketAppointments()
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.items = [
            {
                label: 'Filters',
                items: [
                    {
                        label: 'Status',
                        icon: 'pi pi-refresh'
                    },
                    {
                        label: 'History',
                        icon: 'pi pi-upload'
                    },
                    {
                        label: 'Tags',
                        icon: 'pi pi-upload'
                    },
                    {
                        label: 'Last Contacted',
                        icon: 'pi pi-upload'
                    }
                ]
            }
        ];

        this.statusList = [
            {name: 'New York', id: 0},
            {name: 'Rome', id: 1},
            {name: 'London', id: 2},
            {name: 'Istanbul', id: 3},
            {name: 'Paris', id: 4}
        ];

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshCustomer() {

        const dtoList = DtoList.createFromExternal(this.ticketAppointmentList.entries, this.ticketAppointmentList.form);

        this.ticketAppointmentWidget = WidgetFactory.createWidgetTransient(
            'ticket.appointment.list.transient.widget',
            "Appointments",
            'list',
            'transient',
            'entity',
            'tm.TicketAppointment',
            dtoList,
        );

        this.refreshComponent()

    }

    handleObjectSelect(object: ObjectIdentifier | ObjectIdentifierData) {
        this.router.navigate([`as/customer-appointment-detail/${object.objectId}`])
    }


    geocodeAddress(): void {

        for (const ticketAppointment of this.ticketAppointmentList.entries) {
            if (!this.customersCoords) {
                this.customersCoords = [];
            }

            const customer = ticketAppointment.ticketDto['customer'];

            if (!customer?.mainAddress) {continue}

            this.customersCoords.push({
                coordinates: [customer.mainAddress.geoLongitude, customer.mainAddress.geoLatitude],
                dto: ticketAppointment,
                initials: this.getInitials(customer.personDtoName)
            });
        }

        // for (let appointmentSelection of this.appointmentSelectionDtoList) {
        //
        //     if (!this.customersCoords) {
        //         this.customersCoords = [];
        //     }
        //
        //     this.customersCoords.push({
        //         coordinates: [appointmentSelection.address.geoLongitude, appointmentSelection.address.geoLatitude],
        //         dto: appointmentSelection as AppointmentSelectionDto,
        //         initials: this.getInitials(appointmentSelection.customer.personDtoName)
        //     });
        //
        // }
    }

    /**
     * Function to extract initials from a full name.
     * If the number of initials exceeds 2, it returns the first 2 initials.
     *
     * @param name - The full name from which to extract initials.
     * @returns A string containing up to 2 initials.
     */
    getInitials(name: string): string {
        if (!name) return '';

        // Split the name into words and filter out any empty strings
        const words = name.split(' ').filter(word => word.length > 0);

        // Map each word to its first letter, then join the first two initials
        const initials = words.map(word => word[0].toUpperCase()).join('');

        // Return only the first two initials
        return initials.substring(0, 2);
    }

    handleShowConsultants() {

    }


    getTicketAppointments() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,

                ObjectRequestComplexNode.createSimpleAttributeNode("ticket")
                    .addNodes(ObjectRequestComplexNode.createRelationNode("customer", ObjectRequestRelation.createJoin("+objectLatestCustomer"))
                        .addNodes(ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress")))),
                ObjectRequestComplexNode.createSimpleAttributeNode("appointment")
            ),
            [],
            [new Sorting("createdDate", false)],
            null
        );

        // this.ticketAppointmentWidgetTest = WidgetFactory.createWidgetListEntity(
        //     'ticket.appointment.list.transient.widget.test',
        //     "Appointments",
        //     'tm.TicketAppointment',
        //     'no Entry',
        //     objectRequest
        // );
        //     this.refreshComponent();

        this.ticketAppointmentService.list(objectRequest).subscribe(res => {
            this.ticketAppointmentList = res;
            this.geocodeAddress();
            this.refreshCustomer();

        })
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }



}
