<ng-container *ngIf="initialized">

    <div class="grid mb-3">

        <div class="col-12">

            <h4>Weiterempohlen von</h4>
            <mvs-widget *ngIf="customerReferredFrom" [widgetData]="customerReferredFrom"></mvs-widget>

            <p-divider></p-divider>

            <h4>Weiterempfehlungen</h4>
            <mvs-widget *ngIf="customerReferrals" [widgetData]="customerReferrals"></mvs-widget>

            <p-divider></p-divider>

            <h4>Ausgewählte Optionen</h4>
            <mvs-widget *ngIf="customerReferralOptions" [widgetData]="customerReferralOptions"></mvs-widget>

            <p-divider></p-divider>

            <h4>Einlösung von Geschenken</h4>
            <mvs-widget *ngIf="customerReferralRedeems" [widgetData]="customerReferralRedeems"></mvs-widget>

        </div>

    </div>

</ng-container>