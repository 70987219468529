import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {SiDashboardComponent} from "./page/overview/si-dashboard/si-dashboard.component";
import {SiIndexRunComponent} from "./component/si-index-run/si-index-run.component";
import {SiBrowseComponent} from "./page/overview/si-browse/si-browse.component";
import {SiIndexRunObjectPage} from "./page/object/si-index-run-object-page/si-index-run-object.page";
import {SiConfigPage} from "./page/overview/si-config/si-config.page";
import {SiOverviewPage} from "./page/si-overview-page/si-overview.page";
import {SiStatisticsComponent} from "./page/si-overview-page/components/statisticsComponent/si-statistics.component";
import {SiKpiComponent} from "./page/si-overview-page/components/kpiComponent/si-kpi.component";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";

@NgModule({
    declarations: [
        SiDashboardComponent,
        SiIndexRunComponent,
        SiBrowseComponent,
        SiIndexRunObjectPage,
        SiConfigPage,
        SiOverviewPage,
        SiStatisticsComponent,
        SiKpiComponent,
    ],
    exports: [
        SiDashboardComponent,
        SiIndexRunComponent,
        SiBrowseComponent,
        SiIndexRunObjectPage,
        SiConfigPage
    ],
    imports: [
        CoreModule,
        UiModule
    ]
})
export class SiModule implements DynamicModule {

    alias = 'si';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return SiKpiComponent;
    }

    getStatisticsComponent() {
        return SiStatisticsComponent;
    }
}



