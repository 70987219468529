import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AgentDto} from "../../../am/dto/agent.dto";
import {AgentScheduleWeekService} from "../../../am/service/api/agent-schedule-week.service";
import {
    FilterCriteria,
    MvsCrudModeEnum,
    MvsMessageService,
    ObjectChangeInformation,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {AgentScheduleWeekDto} from "../../../am/dto/agent-schedule-week.dto";
import {AmWeekdayEnum} from "../../../am/enum/am-week-day.enum";
import {MvsUiObjectService, WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../helper/widget-function-call-back-create";
import {forkJoin} from "rxjs";
import {AgentService} from "../../../am/service/api/agent.service";

interface DayInterface {
    label: string,
    weekDay: AmWeekdayEnum,
    selected: boolean,
    schedule: AgentScheduleWeekDto,
    isFreeDay: boolean
}

@Component({
    selector: 'mvs-agent-schedule',
    templateUrl: 'agent-schedule.component.html',
    styleUrls: ['agent-schedule.component.scss']
})
export class AgentScheduleComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() agent: AgentDto;
    agentScheduleWeekDto: AgentScheduleWeekDto[];

    days: DayInterface[] = [
        {
            label: 'Monday',
            weekDay: AmWeekdayEnum.MONDAY,
            selected: true,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Tuesday',
            weekDay: AmWeekdayEnum.TUESDAY,
            selected: true,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Wednesday',
            weekDay: AmWeekdayEnum.WEDNESDAY,
            selected: true,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Thursday',
            weekDay: AmWeekdayEnum.THURSDAY,
            selected: true,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Friday',
            weekDay: AmWeekdayEnum.FRIDAY,
            selected: true,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Saturday',
            weekDay: AmWeekdayEnum.SATURDAY,
            selected: false,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
        {
            label: 'Sunday',
            weekDay: AmWeekdayEnum.SUNDAY,
            selected: false,
            schedule: new AgentScheduleWeekDto(),
            isFreeDay: false
        },
    ];

    agentScheduleDayWidget: WidgetData;

    constructor(
        protected agentScheduleWeekService: AgentScheduleWeekService,
        protected agentService: AgentService,
        protected objectService: MvsUiObjectService,
        protected messageService: MvsMessageService
    ) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        // this.listAddress();
        this.getAgentScheduleWeek();
        this.refreshAgentScheduleDayWidget();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getAgentScheduleWeek() {
        const objectRequest = ObjectRequestList.createBasic(false, [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.agent.id)], []);

        this.agentScheduleWeekService.list(objectRequest).subscribe(res => {
            this.agentScheduleWeekDto = res.entries;

            this.days.forEach(day => {
                const existing = this.agentScheduleWeekDto.find(item => item.weekday === day.weekDay);

                if (existing) {
                    day.schedule.id = existing.id;
                    day.schedule.fromHours = existing.fromHours.substring(0, 5); // Convert "HH:mm:ss" -> "HH:mm"
                    day.schedule.toHours = existing.toHours.substring(0, 5);
                    day.schedule.agentDtoId = this.agent.id;
                    day.schedule.startLocationDtoId = existing.startLocationDtoId;
                    day.schedule.startLocationDtoName = existing.startLocationDtoName;

                    if (!day.schedule.fromHours && !day.schedule.toHours) {
                        day.isFreeDay = true;
                    } else if (day.schedule.fromHours == '00:00' && day.schedule.toHours == '00:00') {
                        day.isFreeDay = true;
                    } else {
                        day.isFreeDay = false;
                    }
                }
            });


            this.refreshComponent();

        });
    }

    addLocation(day: DayInterface) {
        this.objectService.openObjectViaDialog(
            null,
            "ad.Address",
            0,
            null,
            false,
            false,
            (event: ObjectChangeInformation) => {
                day.schedule.startLocationDtoId = event.after.id;
                const address = event.after;
                day.schedule.startLocationDtoName = `${address['postalCode']} ${address['city']}, ${address['street']}`;
            },
            MvsCrudModeEnum.create
        );
    }

    copy(id: number, name: string) {
        const clipboardText = `${id}|${name}`; // Format as "id|name"

        navigator.clipboard.writeText(clipboardText).then(() => {
            console.log('Copied to clipboard:', clipboardText);
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    }

    onPaste(event: ClipboardEvent, day: DayInterface) {
        const pastedText = event.clipboardData?.getData('text') || '';

        // Split the copied string using "|"
        const [id, name] = pastedText.split('|');

        day.schedule

        // Assign values to the model
        day.schedule.startLocationDtoId = Number(id); // Convert back to number
        day.schedule.startLocationDtoName = name;

    }

    toggleFreeDay(day: DayInterface) {

        // day.schedule = new AgentScheduleWeekDto();

        if (day.isFreeDay) {
            day.schedule.fromHours = '00:00';
            day.schedule.toHours = '00:00';
            day.schedule.startLocationDtoId = null;
            day.schedule.startLocationDtoName = null;
        }
    }

    handleSaveAll() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        const updateRequests = [];
        const createRequests = [];

        this.days.filter(day => day.selected).forEach(day => {
            const existing = this.agentScheduleWeekDto.find(item => item.weekday === day.weekDay);
            const dto = new AgentScheduleWeekDto();
            dto.agentDtoId = this.agent.id;
            dto.weekday = day.weekDay;
            dto.startLocationDtoId = day.schedule.startLocationDtoId;

            // Convert Date to "HH:mm" format
            if (day.schedule.fromHours instanceof Date) {
                const date = day.schedule.fromHours;
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                dto.fromHours = `${hours}:${minutes}`;
            } else {
                dto.fromHours = day.schedule.fromHours;
            }

            if (day.schedule.toHours instanceof Date) {
                const date = day.schedule.toHours;
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                dto.toHours = `${hours}:${minutes}`;
            } else {
                dto.toHours = day.schedule.toHours;
            }

            // Check if any data has changed
            const isUpdated = existing &&
                (existing.fromHours.substring(0, 5) !== dto.fromHours ||
                    existing.toHours.substring(0, 5) !== dto.toHours ||
                    existing.startLocationDtoId !== day.schedule.startLocationDtoId);

            if (existing) {
                // Update only if data has changed
                if (isUpdated) {
                    dto.id = existing.id;
                    updateRequests.push(this.agentScheduleWeekService.update(dto));
                }
            } else {
                // Create only if the day was selected but doesn't exist in the backend
                createRequests.push(this.agentScheduleWeekService.create(dto));
            }
        });
        // Execute only if there are changes
        if (updateRequests.length > 0 || createRequests.length > 0) {
            forkJoin([...updateRequests, ...createRequests]).subscribe(
                responses => {
                    this.getAgentScheduleWeek();
                    this.busy = false;
                    this.messageService.showSuccessMessage('Success', 'Save');
                },
                error => {
                    this.busy = false;
                }
            );
        } else {
            this.busy = false;
            console.log("No changes detected, no API calls made.");
        }
    }

    enableSaveButton() {
        const updateRequests = [];
        const createRequests = [];

        this.days.filter(day => day.selected).forEach(day => {
            const existing = this.agentScheduleWeekDto.find(item => item.weekday === day.weekDay);
            const dto = new AgentScheduleWeekDto();
            dto.agentDtoId = this.agent.id;
            dto.weekday = day.weekDay;
            dto.startLocationDtoId = day.schedule.startLocationDtoId;

            // Convert Date to "HH:mm" format
            if (day.schedule.fromHours instanceof Date) {
                const date = day.schedule.fromHours;
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                dto.fromHours = `${hours}:${minutes}`;
            } else {
                dto.fromHours = day.schedule.fromHours;
            }

            if (day.schedule.toHours instanceof Date) {
                const date = day.schedule.toHours;
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                dto.toHours = `${hours}:${minutes}`;
            } else {
                dto.toHours = day.schedule.toHours;
            }

            // Check if any data has changed
            const isUpdated = existing &&
                (existing.fromHours.substring(0, 5) !== dto.fromHours ||
                    existing.toHours.substring(0, 5) !== dto.toHours ||
                    existing.startLocationDtoId !== day.schedule.startLocationDtoId);

            if (existing) {
                // Update only if data has changed
                if (isUpdated) {
                    dto.id = existing.id;
                    updateRequests.push(this.agentScheduleWeekService.update(dto));
                }
            } else {
                // Create only if the day was selected but doesn't exist in the backend
                createRequests.push(this.agentScheduleWeekService.create(dto));
            }
        });

        return !(updateRequests.length || createRequests.length);
    }

    refreshAgentScheduleDayWidget() {

        const filterCriteria = [
            FilterCriteria.create('date', FilterCriteria.cOperatorBetween, '${D-TODAY}', '9999-12-31'),
            FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.agent.id)
        ];
        const objectRequestList = ObjectRequestList.createBasic(true, filterCriteria, []);

        this.agentScheduleDayWidget = WidgetFactory.createWidgetTableEntity(
            'mvs.am.agent.schedule.day',
            'Schedule Day',
            'am.AgentScheduleDay',
            'No Entry',
            objectRequestList
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'agentDtoId', fieldValue: this.agent.id}]

        this.agentScheduleDayWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
