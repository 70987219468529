import {Route, Routes} from "@angular/router";
import {WfProcessTypeComponent} from "./page/overview/wf-config/wf-config.page";
import {WfActivityTypeComponent} from "./page/overview/activity-type/activity-type.page";
import {WfTestPage} from "./page/overview/wf-test/wf-test.page";
import {
    WfProcessConfigOverviewComponent
} from "./component/wf-process-config-overview/wf-process-config-overview.component";
import {WfProcessStartProcessPage} from "./page/overview/wf-process-start-process/wf-process-start-process.page";
import {WfDashboardOverviewPage} from "./page/overview/wf-dashboard/wf-dashboard-overview.page";
import {WfProcessPage} from "./page/overview/wf-process/wf-process.page";
import {WfAnalyzePage} from "./page/overview/wf-analyze/wf-analyze.page";
import {WorkflowOverviewPage} from "./page/workflow-overview-page/workflow-overview.page";

export function WfSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'wf', component: WorkflowOverviewPage},
        {path: 'wf/config', component: WfProcessTypeComponent},
        {path: 'wf/activityTypes', component: WfActivityTypeComponent},
        {path: 'wf/test', component: WfTestPage},
        {path: 'wf/config-overview', component: WfProcessConfigOverviewComponent},
        {path: 'wf/start', component: WfProcessStartProcessPage},
        {path: 'wf/process/:id', component: WfProcessPage},
        {path: 'wf/dashboard', component: WfDashboardOverviewPage},
        {path: 'wf/analyze', component: WfAnalyzePage},

    ];

    return routes;

}
