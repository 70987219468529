import {Component, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CrCustomerObjectViewTypeUiEnum} from "./data/cr-customer-object-view-type-ui.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {CustomerDto} from "../../../dto/customer.dto";
import {CustomerService} from "../../../service/api/customer.service";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../../component/cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {
    DtoList,
    FilterCriteria, IndicatorButton, MvsCoreService,
    NavigationItem, ObjectIdentifierData,
    ObjectPageComponent, ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList,
} from "@kvers/alpha-core-common";
import {MvsObjectCount} from "../../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {OverlayPanel} from "primeng/overlaypanel";
import {HouseholdPersonDto} from "../../../../pm/dto/household-person.dto";


interface ObjectCountClass {
    countBadge: number,
    objectBadgeType: string
}

@Component({
    selector: 'mvs-cr-customer-object-page',
    templateUrl: './cr-customer-object.page.html',
    styleUrls: ['./cr-customer-object.page.scss']

})
export class CrCustomerObjectPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

    defaultLabel: string = "Kundenansicht";
    viewType: CrCustomerObjectViewTypeUiEnum = CrCustomerObjectViewTypeUiEnum.standard;

    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorObjectRequest: ObjectRequestList;
    customerName: string;
    customerFilter: FilterCriteria[] = [];
    changedFilter: FilterCriteria;
    photoUrl: string;
    customerDto: CustomerDto;
    customerRelatedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    personRelatedPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;
    selectedNavigationItem: string;
    showCreateTicketOverlay: boolean;
    householdPersons: HouseholdPersonDto[];
    navigateViewTypes: NavigationItem[] = [
        {
            label: 'standard',
            tooltip: 'Standard',
            icon: 'fa-regular fa-house',
            toggleable: false,
            default: true
        },
        {
            label: 'contracts',
            tooltip: 'Verträge',
            icon: 'fa-regular fa-file-contract',
            toggleable: false,
        },
        {
            label: 'communication',
            tooltip: 'Kommunikation',
            icon: 'fa-regular fa-comments',
            toggleable: false,
        },
        {
            label: 'registration',
            tooltip: 'Registrierung und Onboarding',
            icon: 'fa-regular fa-user-plus',
            toggleable: false,
        },
        // {
        //     label: 'ticketsAndWorkflows',
        //     tooltip: 'Tickets und Workflows',
        //     icon: 'fa-regular fa-ticket',
        //     toggleable: false,
        // },
        {
            label: 'interactions',
            tooltip: 'Interaktionen',
            icon: 'fa-regular fa-handshake',
            toggleable: false,
        },
        {
            label: 'additionalInformation',
            tooltip: 'Weitere Informationen',
            icon: 'fa-regular fa-info-circle',
            toggleable: false,
        },
        {
            label: 'documentUpload',
            tooltip: 'Dokument hochladen',
            icon: 'fa-regular fa-file-circle-plus',
            toggleable: false,
        },
        {
            label: 'sales',
            tooltip: 'Sales',
            icon: 'fa-regular fa-dollar-sign',
            toggleable: false,
        },
        {
            label: 'promo',
            tooltip: 'Promo',
            icon: 'fa-regular fa-rectangle-ad',
            toggleable: false,
        },
        {
            label: '',
            divider: true,
        },
        {
            label: 'genericTickets',
            tooltip: 'General Tickets',
            icon: 'fa-regular fa-ticket',
            toggleable: false,
        },
        {
            label: 'addTicket',
            tooltip: 'Add Ticket',
            icon: 'pi pi-plus',
            toggleable: true,
            customFunction: () => this.customFunction()
        },
        {
            label: 'genericWorkflows',
            tooltip: 'General Workflows',
            icon: 'fa-sharp-duotone fa-solid fa-arrow-progress',
            toggleable: false,
        },
    ];

    @ViewChild("op", {static: true}) createTicketOverlay: OverlayPanel;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected processService: WfProcessService,
        // protected topbarService: TopbarService,
        protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem'] && params['navItem'] == this.selectedNavigationItem) {
                return;
            }

            if (params['navItem'] == undefined && this.selectedNavigationItem == 'addTicket') {
                this.toggleAddTicket();
                return;
            }

            if (params['navItem']) {
                const exists = this.checkNavigationEntryExist(this.navigateViewTypes, params['navItem']);
                if (exists) {
                    this.selectedNavigationItem = params['navItem'];
                    const viewType = CrCustomerObjectViewTypeUiEnum[this.selectedNavigationItem];
                    if (viewType != undefined) {
                        this.viewType = viewType;
                    }
                    this.postNavItemSelection(this.selectedNavigationItem);
                } else {
                    this.removeNavItemParam();
                }
            } else {
                this.selectedNavigationItem = this.navigateViewTypes[0].label;
                this.viewType = CrCustomerObjectViewTypeUiEnum[this.selectedNavigationItem];
                const queryParams = {};
                queryParams['navItem'] = this.selectedNavigationItem;
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: queryParams,
                    queryParamsHandling: 'merge'
                });
            }
        });

        super.ngOnInit();
    }

    onRouteChangeAfter() {
    }


    protected getObjectType(): string {
        return "cr.Customer";
    }

    setCoreIndicators() {
        const ticketIndicator = new IndicatorButton('tm.Ticket', 'Tickets', this.ticketIndicatorFilterCriteria, 'top-right', null);
        const workFlowIndicator = new IndicatorButton('wf.WfProcess', 'Workflows', this.workflowIndicatorFilterCriteria, 'top-right', this.workflowIndicatorObjectRequest);

        const buttons: IndicatorButton[] = [];
        buttons.push(ticketIndicator);
        buttons.push(workFlowIndicator);

        // this.topbarService.setButtons(buttons);

    }

    refreshComponent() {

        // this.setMegaMenuItems();
        this.getPhoto();
        this.getCustomerName();
        // this.setIndicatorsData();
        // this.setCoreIndicators();
    }

    getPhoto() {
        const crudServiceCustomer: CustomerService = <CustomerService>this.coreService.getCrudService("cr.Customer");
        this.photoUrl = crudServiceCustomer.getPhotoUrl(this.objectIdentifier.objectId);
    }

    getCustomerName() {
        const dtoRequest: ObjectRequestList = new ObjectRequestList(
            false,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []);

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(false, true, ObjectRequestComplexNode.createSimpleAttributeNode('person'));

        const crudService = this.coreService.getCrudService(this.objectIdentifier.objectType);

        crudService.list(dtoRequest).subscribe((res: DtoList<CustomerDto>) => {
            this.customerDto = res.entries[0];

            if (this.customerDto && this.customerDto.personDto) {
                //rename tab
                this.customerDto.personDtoName = `${this.customerDto.personDto.firstName} ${this.customerDto.personDto.lastName}`;
                const tabName = `${this.customerDto.personDto.lastName}, ${this.customerDto.personDto.firstName}`
                this.renameTab(tabName);
            }
        })

    }

    setIndicatorsData() {

        this.ticketIndicatorFilterCriteria = [
            FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled)

        ];

        this.workflowIndicatorObjectRequest = this.processService.getRequestForProcessForObjectViaAlias(
            this.objectIdentifier.objectType,
            this.objectIdentifier.objectId,
            true
        );
    }

    isBreadcrumbVisible(): boolean {
        return false;
    }

    handleCustomerFilter(event: FilterCriteria[]) {
        this.customerFilter = event;
    }

    onRemoveFilterCriteria(filter: FilterCriteria) {
        let index = this.customerFilter.findIndex(item => item.field === filter.field);

        if (index > -1) {
            this.changedFilter = filter;
            this.customerFilter.splice(index, 1);
        }
    }

    handleSelectedRelatedPersonsChanged(event: CrCustomerRelatedPersonWidgetStyle) {
        this.customerRelatedPersonWidgetStyle = event;
        this.customerRelatedPersonWidgetStyle = this.customerRelatedPersonWidgetStyle.clone();
    }

    handlePersonRelationPersonsChanged(event: PmPersonRelationPersonWidgetStyle) {
        this.personRelatedPersonWidgetStyle = event;
        this.personRelatedPersonWidgetStyle = this.personRelatedPersonWidgetStyle.clone();
    }

    isNavComponent(): NavigationItem[] {
        return this.navigateViewTypes;
    }

    checkNavigationEntryExist(items: NavigationItem[], label: string): boolean {
        const exists = items.find(item => item.label == label);
        if (exists) {
            return true;
        }
        return false;
    }

    removeNavItemParam(): void {
        const queryParams = {...this.route.snapshot.queryParams};
        delete queryParams['navItem'];
        this.router.navigate([], {queryParams: queryParams});
    }

    handleTicketsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);
        const ticketObject = this.navigateViewTypes.find(item => item.label == 'genericTickets');
        ticketObject.badge = data.countBadge;
        ticketObject.badgeType = data.objectBadgeType;
    }

    handleWorkflowsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);

        const workflowObject = this.navigateViewTypes.find(item => item.label == 'genericWorkflows');
        workflowObject.badge = data.countBadge;
        workflowObject.badgeType = data.objectBadgeType;

    }

    getObjectCountClass(event: MvsObjectCount): ObjectCountClass {
        let countBadge = 0;
        let objectBadgeType: string;

        if (!event.pendingCount && !event.completedCount) {
            countBadge = 0;
        } else if (event.completedCount && !event.pendingCount) {
            countBadge = event.completedCount;
            objectBadgeType = 'gray';
        } else if (!event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectBadgeType = 'primary';
        } else if (event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectBadgeType = 'primary';
        }

        return {countBadge: countBadge, objectBadgeType: objectBadgeType};
    }


    ngOnDestroy() {
        // this.topbarService.clearButtons();
        this.removeNavItemParam();
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    postNavItemSelection(navItem: string) {
        if (navItem == 'addTicket') {
            if (this.createTicketOverlay.overlayVisible) {
                this.createTicketOverlay.hide();
            } else {
                this.showCreateTicketOverlay = true;
                this.createTicketOverlay.toggle(event);
            }

        }
    }

    handleTicketCreation(event: ObjectIdentifierData) {
        this.createTicketOverlay.hide();
        this.showCreateTicketOverlay = false;
    }

    toggleAddTicket() {
        if (this.createTicketOverlay.overlayVisible) {
            this.createTicketOverlay.hide();
            this.selectedNavigationItem = null;
        } else {
            this.createTicketOverlay.toggle(event);
        }
    }

    handleHouseholdPersonsLoaded(event: HouseholdPersonDto[]) {
        this.householdPersons = event;
    }

    customFunction() {
        this.showCreateTicketOverlay = true;
        this.toggleAddTicket();
    }

}
