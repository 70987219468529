import {Component, OnInit} from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-cr-contact-page',
    templateUrl: './customer-welcome.page.html',
    styleUrls: ['./customer-welcome.page.scss']
})
export class CustomerWelcomePage extends PageComponent implements OnInit {

    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Customer Welcome Page Configuration";

    activeIndex = 0;

    customerRegistrationWidget: WidgetData;
    customerUserWidget: WidgetData;
    customerWelcomeWidget: WidgetData;
    customerInfoWidget: WidgetData;
    objectDataWidget: WidgetData;

    ngOnInit(): void {

        super.ngOnInit();

        this.refreshWidgets();

        // Main Structure
        this.objectBrowserRequest = {

            "extractDto": false,
            "createMode": true,

            'entries': [
                {
                    'id': 1,
                    'objectAlias': 'cr.CustomerRegistrationConfiguration',
                    'level': 1,
                    'parentBinding': 'none',
                    'dummyParentCreate': true,
                    'dummyParentLabel': "Onboarding Konfiguration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel': "Konfiguration anlegen"
                },
                {
                    'id': 2,
                    'objectAlias': 'cr.CustomerUserConfiguration',
                    'level': 1,
                    'parentBinding': 'none',
                    'dummyParentCreate': true,
                    'dummyParentLabel': "User Konfiguration",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel': "Konfiguration anlegen"
                },
                {
                    'id': 3,
                    'objectAlias': 'cr.CustomerWelcome',
                    'level': 1,
                    'parentBinding': 'none',
                    'dummyParentCreate': true,
                    'dummyParentLabel': "Willkommen Seiten",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel': "Neue Seite anlegen"
                },
                {
                    'id': 4,
                    'objectAlias': 'cr.CustomerInfoPage',
                    'level': 1,
                    'parentBinding': 'none',
                    'dummyParentCreate': true,
                    'dummyParentLabel': "Info Seiten",
                    'newEntryNodeCreate': true,
                    'newEntryNodeLabel': "Neue Seite anlegen"
                },
            ]
        };

        this.subObjectBrowserRequest = null;

    }

    refreshWidgets() {
        this.refreshCustomerRegistrationWidget();
        this.refreshCustomerUserWidget();
        this.refreshCustomerWelcomeWidget();
        this.refreshCustomerInfoWidget();
    }


    refreshCustomerRegistrationWidget() {
        this.customerRegistrationWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.registration.configuration.widget',
            'Onboarding Konfiguration',
            'cr.CustomerRegistrationConfiguration',
            'No Data',
            this.getObjectRequestList());
        this.customerRegistrationWidget.uiComponent = 'selectable';
    }

    refreshCustomerUserWidget() {
        this.customerUserWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.user.configuration.widget',
            'User Konfiguration',
            'cr.CustomerUserConfiguration',
            'No Data',
            this.getObjectRequestList());
        this.customerUserWidget.uiComponent = 'selectable';
    }

    refreshCustomerWelcomeWidget() {
        this.customerWelcomeWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.welcome.widget',
            'Willkommen Seiten',
            'cr.CustomerWelcome',
            'No Data',
            this.getObjectRequestList());
        this.customerWelcomeWidget.uiComponent = 'selectable';
    }

    refreshCustomerInfoWidget() {
        this.customerInfoWidget = WidgetFactory.createWidgetListEntity(
            'cr.customer.info.page.widget',
            'Info Seiten',
            'cr.CustomerInfoPage',
            'No Data',
            this.getObjectRequestList());
        this.customerInfoWidget.uiComponent = 'selectable';
    }

    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], []);
    }

    handleObjectSelect(event: ObjectIdentifierData) {

        this.objectDataWidget = WidgetFactory.createWidgetEntityData(
            `${event.objectType}.cr.customer.configuration.widget`,
            '',
            event.objectType,
            event.objectId
        )

    }

    clearWidgets() {
        this.objectDataWidget = null;
    }


}
