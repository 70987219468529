@if (initialized && dto) {

    @if (uiMode == 'side') {
        <em-schema-object-component-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></em-schema-object-component-side>
    } @else {
        <div class="mt-5">

            <em-schema-object-component-full
                    [objectIdentifier]="objectIdentifier"
                    [dto]="dto"
                    [uiMode]="uiMode"
                    (onNavigationItems)="handleNavigationItems($event)"
            ></em-schema-object-component-full>
        </div>
    }
}