import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";
import {FilterCriteria, ObjectRequestList, Sorting} from "@kvers/alpha-core-common";
import {AutoCompleteCompleteEvent} from "primeng/autocomplete";

enum ObjectTypeState {
    VALID = 'VALID',
    INVALID = 'INVALID',
    IN_PROGRESS = 'IN_PROGRESS',
    NONE = 'NONE',
}

@Component({
    selector: 'mvs-object-type',
    templateUrl: './mvs-object-type.component.html',
    styleUrls: ['./mvs-object-type.component.scss'],
})
export class MvsObjectTypeComponent implements OnInit {

    initialized: boolean;
    objectTypeList: string[];
    selectedObject: string;

    @Input() objectTypeAlias: string;
    @Output() objectTypeChange: EventEmitter<string> = new EventEmitter();

    state: ObjectTypeState = ObjectTypeState.NONE;

    constructor(protected objectService: ObjectTypeService, private cdr: ChangeDetectorRef) {

    }

    ngOnInit(): void {
        this.refreshComponent();
    }

    refreshComponent() {

        if (this.objectTypeAlias) {
            this.selectedObject = this.objectTypeAlias;
            this.handleOnChange();
        }
    }


    async handleOnChange() {

        if (this.selectedObject) {
            this.state = ObjectTypeState.IN_PROGRESS;
            try {
                const objectType = await this.objectService.getViaObjectType(this.selectedObject);
                if (objectType) {
                    this.state = ObjectTypeState.VALID;
                    this.objectTypeChange.emit(this.selectedObject);
                } else {
                    this.state = ObjectTypeState.INVALID;
                }
            } catch (error) {
                this.state = ObjectTypeState.INVALID;
                console.error('Error validating selected object:', error);
            }
        }
    }


    search(event: AutoCompleteCompleteEvent) {

        const filterCriteria = FilterCriteria.create('alias', FilterCriteria.cOperatorContainsPattern, `%${event.query}%`);
        const objectRequestList = ObjectRequestList.createBasic(false, [filterCriteria], [new Sorting('alias', false)]);

        this.objectService.list(objectRequestList).subscribe(
            res => {
                this.objectTypeList = res.entries.map(entry => entry.alias);
                this.state = this.objectTypeList.length > 0 ? ObjectTypeState.NONE : ObjectTypeState.INVALID;
                this.cdr.detectChanges(); // Manually trigger change detection
            },
            error => {
                this.state = ObjectTypeState.INVALID;
                console.error('Error fetching object type list:', error);
            }
        );
    }

}
