import {Route, Routes} from "@angular/router";
import {ConfigPage} from "./page/overview/config/config.page";
import {authGuard} from "@kvers/alpha-auth";
import {AsCustomerAppointmentPage} from "./component/as-customer-appointment-page/as-customer-appointment.page";
import {
    AsCustomerAppointmentDetailPage
} from "./component/as-customer-appointment-detail-page/as-customer-appointment-detail.page";

export function AsSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        // {path: 'as', component: AsOverviewPage},
        {path: 'as/config', component: ConfigPage},
        {path: 'as/customer-appointment', component: AsCustomerAppointmentPage},
        {path: 'as/customer-appointment-detail/:id', component: AsCustomerAppointmentDetailPage},

    ];

    return routes;

}
