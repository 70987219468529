import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WfProcessTypeStepService} from "../../../service/api/wf-process-type-step.service";


@Component({
    selector: 'wf-analyze',
    templateUrl: './wf-analyze.page.html',
})
export class WfAnalyzePage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Analyze Workflows";
    processWidget: WidgetData;
    processStepWidgetTable: WidgetData;
    processStepWidgetCategory: WidgetData;
    processTypeWidget: WidgetData;
    filterCriteria: FilterCriteria[] = [];


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected processTypeStepService: WfProcessTypeStepService,) {
        super(route,coreService);
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        this.processWidget = WidgetFactory.createWidgetList(
            'wf.analyze.processes',
            'wf.WfProcess',
            'category',
            'list',
            'entity.groupBy',
            'wf.WfProcess',
            'No processes found',
            ObjectRequestListGroupBy.create(
                true,
                [],
                [],
                ["type"],
                [ new ObjectRequestListAttribute("type", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),
            WidgetDataParam.create('fieldCategory','type'),
                   WidgetDataParam.create('fieldCategoryCount','type_count'),
        );
    }

    handleProcess(event: ObjectIdentifierData) {

        this.processStepWidgetTable = null;
        this.processStepWidgetCategory = null;
        const objectRequest = new ObjectRequestList(
          true,
          [FilterCriteria.create('processType',FilterCriteria.cOperatorEqual,event.objectId)],
            []
        );
        this.processTypeStepService.list(objectRequest).subscribe(res => {

            const processTypeSteps = res.entries;
            const filterCriteriaList = [ FilterCriteria.createOrFromArray("processTypeStep", "id", processTypeSteps) ];
            this.refreshProcessTypeWidget(filterCriteriaList);
        });

    }

    refreshProcessTypeWidget(filterCriteria : FilterCriteria[]){
        this.processTypeWidget = WidgetFactory.createWidgetList(
            'wf.analyze.process.step.widget',
            'wf.WfProcessStep',
            'category',
            'list',
            'entity.groupBy',
            'wf.WfProcessStep',
            'No process steps found',
            ObjectRequestListGroupBy.create(
                true,
                filterCriteria,
                [],
                ["processTypeStep"],
                [ new ObjectRequestListAttribute("processTypeStep", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),
            WidgetDataParam.create('fieldCategory','processTypeStep'),
            WidgetDataParam.create('fieldCategoryCount','processTypeStep_count'),
        )
    }
    handleProcessStep(event: ObjectIdentifierData) {

        this.filterCriteria = [];
        const filter = FilterCriteria.create('processTypeStep',FilterCriteria.cOperatorEqual,event.objectId);
        this.filterCriteria.push(filter);
        this.refreshProcessStepWidgetTable(this.filterCriteria);
        this.refreshProcessStepWidgetCategory(this.filterCriteria);


    }

    refreshProcessStepWidgetTable(filter: FilterCriteria[]) {
        this.processStepWidgetTable = WidgetFactory.createWidgetList(
            'wf.analyze.process.step.table',
            'wf.WfProcessStep',
            'table',
            'list',
            'entity',
            'wf.WfProcessStep',
            'No processes step found',
            ObjectRequestList.createWithPaging(
                true,
                filter,
                [],
                PagingDto.create(0,10)),
        )
    }


    refreshProcessStepWidgetCategory(filter: FilterCriteria[]) {

        this.processStepWidgetCategory = WidgetFactory.createWidgetList(
            'wf.analyze.process.type.step.category',
            'wf.WfProcessStep',
            'category',
            'list',
            'entity.groupBy',
            'wf.WfProcessStep',
            'No processes found',
            ObjectRequestListGroupBy.create(
                true,
                filter,
                [],
                ["createdBy"],
                [ new ObjectRequestListAttribute("createdBy", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]),
            WidgetDataParam.create('fieldCategory','createdBy'),
            WidgetDataParam.create('fieldCategoryCount','createdBy_count'),
        )
    }

    handleProcessStepCategory(event: ObjectIdentifierData) {

        let filter: FilterCriteria[] = [];
        for (let item of this.filterCriteria) {
            filter.push(item);
        }
        filter.push(FilterCriteria.create('createdBy',FilterCriteria.cOperatorEqual,event.objectId))
        this.refreshProcessStepWidgetTable(filter);
    }

}
