import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'am-appointment-list-page',
    templateUrl: './am-appointment-list.page.html',
    styleUrl: "am-appointment-list.page.scss"
})
export class AmAppointmentListPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    appointmentsWidget: WidgetData;
    appointmentTypeWidget: WidgetData;
    appointmentResultWidget: WidgetData;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
    ) {
        super(route, coreService)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.refreshAppointmentsWidget();
        this.refreshAppointmentTypeWidget();
        this.refreshAppointmentResultWidget();
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshAppointmentsWidget() {
        const objectRequest = ObjectRequestList.createBasic(true,[],[]);
        this.appointmentsWidget = WidgetFactory.createWidgetTableEntity('am-appointment-list-table-widget', 'Appointments', 'as.Appointment', 'No Appointments Found', objectRequest);
    }

    refreshAppointmentTypeWidget() {
        this.appointmentTypeWidget = WidgetFactory.createWidgetGroupBy(
            "am.appointment.type.distribution",
            "Appointment Types",
            "category",
            "entity.groupBy",
            "as.Appointment",
            "appointmentType",
            "appointmentType_count",
            null,
            "appointmentType",
            "Anzahl"
        );
    }

    refreshAppointmentResultWidget() {
        this.appointmentResultWidget = WidgetFactory.createWidgetGroupBy(
            "am.appointment.result.distribution",
            "Appointment Result",
            "category",
            "entity.groupBy",
            "as.Appointment",
            "appointmentResultType",
            "appointmentResultType_count",
            null,
            "appointmentResultType",
            "Anzahl"
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
