import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import tt from "@tomtom-international/web-sdk-maps";
import {DtoDetail} from "@kvers/alpha-core-common";

@Component({
    selector: 'as-agent-map',
    templateUrl: 'as-agent-map.component.html',
    styleUrl: 'as-agent-map.component.scss'
})
export class AsAgentMapComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    map!: tt.Map;
    customerMarkers: tt.Marker[] = [];
    agentMarkers: tt.Marker[] = [];


    @Input() customersCoords: { coordinates: [number, number], dto: DtoDetail, initials:string }[];
    @Input() agentsCoords: { coordinates: [number, number], dto: DtoDetail, initials:string }[];
    @Output() onSelect: EventEmitter<DtoDetail> = new EventEmitter();

    displayDialog: boolean = false;
    selectedMarkerInfo: DtoDetail | null = null;
    showAgents: boolean = false; // Toggle to show or hide agents

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.map = tt.map({
            key: 'POQwSkANG2wVgN1qMbook38s5EMkN7pG',
            container: 'map',
            center: [9.993682, 53.551086], // Coordinates for Hamburg, Germany
            zoom: 12
        });

        // Add markers
        this.addMarkers();

        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Add markers to the map based on the input coordinates.
     */
    addMarkers(): void {

        // Clear existing markers
        this.clearMarkers();

        // if (this.customerMarkers && this.customerMarkers.length > 0) {
        //     this.customerMarkers.forEach(markerData => {
        //         const markerElement = this.createCustomMarker(markerData.initials);
        //
        //         new tt.Marker({
        //             element: markerElement
        //         })
        //             .setLngLat(markerData.coordinates)
        //             .addTo(this.map);
        //
        //         markerElement.addEventListener('click', () => {
        //             this.onMarkerClick(markerData.dto);
        //         });
        //     });
        // }

        // Add customer markers
        this.customerMarkers = this.customersCoords.map(customerData => {
            const markerElement = this.createCustomMarker(customerData.initials);

            const marker = new tt.Marker({
                element: markerElement
            })
                .setLngLat(customerData.coordinates)
                .addTo(this.map);

            markerElement.addEventListener('click', () => {
                this.onMarkerClick(customerData.dto);
            });

            return marker;
        });

        // Conditionally add agent markers
        if (this.showAgents) {
            this.agentMarkers = this.agentsCoords.map(agentData => {
                const markerElement = this.createCustomMarker(agentData.initials, 'agent');

                const marker = new tt.Marker({
                    element: markerElement
                })
                    .setLngLat(agentData.coordinates)
                    .addTo(this.map);

                markerElement.addEventListener('click', () => {
                    this.onMarkerClick(agentData.dto);
                });

                return marker;
            });
        }
    }

    /**
     * Handle marker click event to show the dialog.
     * @param info - Information to display in the dialog.
     */
    onMarkerClick(dto: DtoDetail | undefined): void {
        this.selectedMarkerInfo = dto;
        this.displayDialog = true;
    }

    /**
     * Create a custom marker element with image or initials.
     * @param imageUrl - URL of the image to display on the marker.
     * @param initials - Initials to display if there is no image.
     * @returns HTMLDivElement - The created custom marker element.
     */
    createCustomMarker(initials?: string, type: 'customer' | 'agent' = 'customer'): HTMLDivElement {
        const markerElement = document.createElement('div');
        markerElement.className = type === 'agent' ? 'custom-marker agent-marker' : 'agent-marker customer-marker';

        // const img = document.createElement('img');
        // img.src = imageUrl;
        // img.style.width = '100%';
        // img.style.height = '100%';
        // img.style.borderRadius = '50%';
        // markerElement.appendChild(img);

        const initialsElement = document.createElement('div');
        initialsElement.textContent = initials;
        initialsElement.style.width = '40px';
        initialsElement.style.height = '40px';
        initialsElement.style.backgroundColor = type === 'agent' ? '#FFA500' : '#005F9E';
        initialsElement.style.color = 'white';
        initialsElement.style.display = 'flex';
        initialsElement.style.alignItems = 'center';
        initialsElement.style.justifyContent = 'center';
        initialsElement.style.borderRadius = '50%';

        markerElement.appendChild(initialsElement);


        return markerElement;
    }

    /**
     * Clear all markers from the map.
     */
    clearMarkers(): void {
        // const existingMarkers = document.querySelectorAll('.tt-icon-marker');
        // existingMarkers.forEach(marker => marker.remove());
        this.customerMarkers.forEach(marker => marker.remove());
        this.agentMarkers.forEach(marker => marker.remove());
        this.customerMarkers = [];
        this.agentMarkers = [];
    }

    /**
     * Toggle visibility of agent markers.
     */
    toggleAgents(): void {
        this.addMarkers(); // Refresh markers on the map
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes['customersCoords'] || changes['agentsCoords']) {
            this.addMarkers();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.map) {
            this.map.remove();
        }
    }
}
