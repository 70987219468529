<ng-container *ngIf="initialized">

    <div (click)="handleClick()" class="pointer">
        <mvs-inline-component
                [objectType]="'cm.Contract'"
                [name]="dto.contractIdentifier"
                [startDate]="dto.startDate"
                [endDate]="dto.endDate"
                [status]="EntityStatusEnum[dto.entityStatus]"
                [buttons]="toolbarButtons"
                (onButtonClick)="handleButtonClick($event)"
        >
        </mvs-inline-component>
    </div>

</ng-container>