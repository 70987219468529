import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import {TenantService} from "@kvers/alpha-auth";
import {
    FilterCriteria,
    MvsCoreService,
    ObjectIdentifier,
    ObjectRequestList,
    TopbarService,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {IndicatorButton} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {UmUserService} from "./um/service/api/um-user.service";
import {UserProfileHelper} from "./um/components/user-profile/helper/user-profile-helper";
import {UiSettingUserService} from "./ui/service/api/ui-setting-user.service";
import {UiThemeInterface} from "./ui/component/ui-setting-user/data/ui-theme-interface";
import {UiSettingUserDto} from "./ui/dto/ui-setting-user.dto";
import {UiUserThemeConfigurations} from "./ui/component/ui-setting-user/data/ui-user-theme-configurations";
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ],
    styles: ['.topbar {\n' +
    '  display: flex;\n' +
    '  align-items: center;\n' +
    '  padding: 5px;\n' +
    // '  background-color: #f1f1f1;\n' +
    '}\n' +
    '\n' +
    '.overlay-item {\n' +
    '  cursor: pointer;\n' +
    '  padding: 5px;\n' +
    // '  margin: 0 5px;\n' +
    '}\n' +
    '\n' +
    '.overlay-item.active {\n' +
    '  font-weight: bold;\n' +
    '  color: #007bff;\n' +
    '}']
})
export class AppTopBarComponent implements OnInit, OnDestroy, AfterViewInit {

    tenantLogo?: string = undefined;
    profilePicture: string;
    uiUserSettings: UiSettingUserDto;
    userSettings: UiUserThemeConfigurations;
    busy: boolean = false;

    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        protected topbarService: TopbarService,
        protected navigationService: MvsObjectNavigationService,
        protected userService: UmUserService,
        protected coreService: MvsCoreService,
        protected uiSettingUserService: UiSettingUserService,
        private tenantService: TenantService) {
    }

    activeItem: number;
    buttons: IndicatorButton[] = [];
    indicatorSubscription: Subscription;
    currentOverlay: MvsObjectNavigationEntry[] = [];
    displayStack: MvsObjectNavigationEntry[] = [];
    objectLabel: string;
    activeOverlay: MvsObjectNavigationEntry;
    activeOverlayIndex: number;
    profileSubscription: Subscription;

    model: MegaMenuItem[] = [
        {
            label: 'Quick Access',
            items: [
                [
                    {
                        label: 'END USER APP',
                        items: [
                            { label: 'Konfiguration', icon: 'pi pi-fw pi-id-card', routerLink: ['/eu/euAppConfig'] },
                        ]
                    },
                    {
                        label: 'IMPORTER',
                        items: [
                            { label: 'Dashboard', icon: 'pi pi-fw pi-file', routerLink: ['/im/dashboard'] },
                            { label: 'Importieren', icon: 'pi pi-fw pi-file-import', routerLink: ['/im/dataImport'] },
                        ]
                    }
                ],
            ]
        },
        {
            label: 'Entity Browser',
            routerLink: ['/cc/entities']
        },
    ];

    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    ngOnInit() {

        this.tenantLogo = this.tenantService.getTenantInfo().logo;

        this.indicatorSubscription = this.topbarService.coreIndicatorButtonSubscription.subscribe((buttons) => {
            this.buttons = buttons;
        });

        this.navigationService.navigationObjectOverlay.subscribe(navItem => {
            this.refreshOverlayObject(navItem);
        });

        this.userService.getPictureBinary().subscribe(res => {
            UserProfileHelper.getImageSrcFromBlob(res).then(imageSrc => {
                this.profilePicture = imageSrc;
            });
        });

        this.profileSubscription = this.userService.profileSubscription.subscribe(res => {
            this.profilePicture = res;
        });

        this.getTopbarTheme();

    }

    ngAfterViewInit() {
        this.handleTopbarTheme()
    }

    getTopbarTheme() {
        const userSettings = localStorage.getItem('ui.userSettings');

        if (userSettings) {
            this.uiUserSettings = JSON.parse(userSettings);
            return;
        }

        const meUser: string ='${E:ME_USERNAME}';

        const filterCriteria = new FilterCriteria();
        filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('user', FilterCriteria.cOperatorEqual, meUser));
        filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('user', FilterCriteria.cOperatorEqual, ''));


        const objectRequest = new ObjectRequestList(
            false,
            [filterCriteria],
            []
        );
        this.uiSettingUserService.list(objectRequest).subscribe(res => {
            if (!res.entries || !res.entries.length) {
                return;
            }
            let preferredSettings= null;
            const mySettings = res.entries.find(item => item.user != '');
            const defaultSettings = res.entries.find(item => item.user == '');

            if (mySettings) {
                preferredSettings = mySettings;
            } else if (defaultSettings) {
                preferredSettings = defaultSettings;
            }

            if (preferredSettings) {
                this.uiUserSettings = preferredSettings;
                this.handleTopbarTheme();
            }


        }, error => {
            console.log(error);
        })
    }

    handleTopbarTheme() {
        if (!this.uiUserSettings) {
            return;
        }
        this.userSettings = JSON.parse(this.uiUserSettings.layoutJson);
        localStorage.setItem('ui.userSettings', JSON.stringify(this.uiUserSettings));
        const theme = this.userSettings.topbarTheme;
        this.applyTopbarTheme(theme);

    }

    applyTopbarTheme(theme: UiThemeInterface) {
        this.app.topbarTheme = theme.name;
        const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;
        const appIcons: NodeListOf<HTMLElement> = document.querySelectorAll('.dark-topbar-icon');

        if (this.userSettings.topbarLogo == 'light') {

            appLogoLink.style.setProperty('filter', 'brightness(5)', 'important');

            appIcons.forEach(icon => {
                icon.style.setProperty('color', 'var(--surface-0)', 'important');
            });
        }
        else {
            appLogoLink.style.removeProperty('filter');
            appIcons.forEach(icon => {
                icon.style.setProperty('color', 'var(--surface-500)', 'important');
            });
        }
    }

    refreshOverlayObject(navItem: MvsObjectNavigationEntry[]) {
        if (this.currentOverlay.length == navItem.length) {
            return;
        }
        if (!navItem || !navItem.length) {
            this.objectLabel = null;
            this.currentOverlay = [];
        } else {
            this.objectLabel = this.navigationService.objectLabel;
            this.currentOverlay = navItem;
            this.handleDisplayStack();
            this.activeOverlay = this.navigationService.getActiveOverlay();
            this.activeOverlayIndex = this.navigationService.activeOverlayIndex;
            this.getLabels();
        }
    }

    handleDisplayStack() {
        for (let item of this.currentOverlay) {
            const exists = this.checkInDisplayStack(item.objectIdentifier);
            if (!exists) {
                this.displayStack.push(item);
            }
        }
    }

    checkInDisplayStack(objectIdentifier: ObjectIdentifier) {
        for (let item of this.displayStack) {
            const isSame = ObjectIdentifier.compare(item.objectIdentifier, objectIdentifier);
            if (isSame) {
                return true;
            }
        }
        return false;
    }

    getLabels() {
        for (let item of this.currentOverlay) {

            if (item['label']) {
                return;
            }

            const crudService = this.coreService.getCrudService(item.objectIdentifier.objectType);
            const objectLabels: string[] = crudService.getObjectLabels();
            crudService.get(item.objectIdentifier.objectId).subscribe(res => {

                for (let label of objectLabels) {
                    if (res[label]) {
                        item['label'] = res[label];
                        return;
                    }
                }
            });

        }
    }

    toggleOverlay(index: number): void {
        this.navigationService.setActiveOverlay(index);
        this.activeOverlay = this.navigationService.getActiveOverlay();
        this.activeOverlayIndex = this.navigationService.activeOverlayIndex;
    }

    ngOnDestroy() {
        this.indicatorSubscription.unsubscribe();

        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    onSearchAnimationEnd(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }

    handleHomePageNavigation() {
        this.navigationService.navigateToHomePage();
    }

    onNavigateToMainPage() {
        this.displayStack = [];
        this.navigationService.clearOverlay();
    }

    handleRemoveItem(index: number) {
        this.displayStack.splice(index,1);
        this.navigationService.removeOverlay(index);
    }

    handleNavigationToProfile() {
        const url = '/user/profile';
        this.navigationService.handlePageNavigation(url);
    }

    createTicket() {
            const object = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', null,null, null, null, null, MvsObjectNavigationActionEnum.any, 'side');
            this.navigationService.navigateTo(object, 'right');
    }

}
