<ng-container *ngIf="initialized">

    <div class="mvs-widget">

        <div class="flex justify-content-end mb-2">
            <p-toggleButton [(ngModel)]="showActive" (onChange)="this.refreshProcessesList()" onLabel="Show Inactive"
                            offLabel="Show Active"></p-toggleButton>
        </div>
        <mvs-widget
                *ngIf="wfProcessList"
                [widgetData]="wfProcessList"
        ></mvs-widget>

    </div>
</ng-container>