import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {PersonSearchSelectEvent} from "../../person-search/person-search-select.event";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessStepActivityEvent} from "../../../../wf/logic/wf-process-step-activity.event";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WfProcessTypeStepProcessingEnum} from "../../../../wf/enum/wf-process-type-step-processing.enum";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {WfActivityFormDefaultDto} from "../../../../wf/service/dto/wf-activity-form-default.dto";
import {WfActivityTypeObjectActivityEnum} from "../../../../wf/enum/wf-activity-type-object-activity-enum.enum";
import {WfProcessRuntimeBindingDto} from "../../../../wf/service/dto/wf-process-runtime-binding.dto";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";
import {WfActivityRefreshType} from "../../../../wf/enum/wf-activity-refresh-type.enum";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WfActivityTypeService} from "../../../../wf/service/api/wf-activity-type.service";
import {WfProcessTypeStepActivityService} from "../../../../wf/service/api/wf-process-type-step-activity.service";

@Component({
    selector: 'mvs-wf-process-step-activity-object-create',
    templateUrl: './pm-create-person-wf-process-step-activity.component.html',
    styleUrls: ['./pm-create-person-wf-process-step-activity.component.scss'],
})
export class PmCreatePersonWfProcessStepActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    protected processService: WfProcessService;
    objectTypeDto: ObjectTypeDto;
    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    createdObjectIdentifier: ObjectIdentifier;
    personSearchSuffix: string;
    stepActivityMultiSelectWidget: WidgetData;

    listFilterCriteria: FilterCriteria[];
    objectId: number;
    constBindingFieldPrefix: string = "f_";

    protected objectTypeService: ObjectTypeService;
    protected activityTypeService: WfProcessTypeStepActivityService;


    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.handleObjectRefresh();
        this.initialized = true;
    }

    async handleObjectRefresh() {

        this.objectTypeService = <ObjectTypeService>this.coreService.getCrudService("cc.ObjectType");

        this.personSearchSuffix = "wf." + this.stepType.id;

        if (!this.objectTypeDto || this.objectTypeDto.id !== this.activityType.activityObjectDto.objectTypeDtoId) {
            this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.activityObjectDto.objectTypeDtoId);
        }


        // initialize filters
        this.listFilterCriteria = [];
        this.createDefaultDto = new DtoDetail({});
        this.formControlOverwrite = new MvsFormControlOverwrite();

        if (this.activityType.activityObjectDto.activityEnum !== WfActivityTypeObjectActivityEnum.create) {
            const processedObjectBinding = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "objectProcess");

            //processedObjectBinding.objectValue
            if (processedObjectBinding) {
                this.objectId = processedObjectBinding.objectId;
            }

        }

        WfActivityFormDefaultDto.processFormDefaults(this.formDefaults, this.createDefaultDto, this.listFilterCriteria, this.formControlOverwrite);

        // process field bindings
        WfActivityFormDefaultDto.processBindings(this.bindings, this.createDefaultDto, this.constBindingFieldPrefix, this.listFilterCriteria);

        if (this.activityType.activityObjectDto.objectShowList || this.activityType.activityObjectDto.activityEnum == WfActivityTypeObjectActivityEnum.select) {
            this.refreshStepActivityMultiSelectWidgetTable(this.listFilterCriteria);
        }

    }

    /**
     * Whenever a target object was selected.
     * @param personEvent
     */
    onPersonSelect(personEvent: PersonSearchSelectEvent) {
        this.createdObjectIdentifier = new ObjectIdentifier(personEvent.selectedTargetObject.objectType, personEvent.selectedTargetObject.objectId);
        // this.connectedObjectId = this.createdObjectIdentifier.objectId;

        if (this.activityType.processing === WfProcessTypeStepProcessingEnum.optional) {
            this.onOptionalActivityChangeEvent.emit(new WfProcessStepActivityEvent(this.createdObjectIdentifier, this.activityType));
        } else if (this.activityType.processing === WfProcessTypeStepProcessingEnum.main) {
            //update the process

        }

    }

    onBackToCreate() {
        this.objectId = null;
        //update the process
    }


    refreshStepActivityMultiSelectWidgetTable(filterCriteriaList: FilterCriteria[]) {

        this.activityTypeService = <WfProcessTypeStepActivityService>this.coreService.getCrudService("wf.WfProcessTypeStepActivity");
        this.stepActivityMultiSelectWidget = this.activityTypeService.refreshStepActivityMultiSelectWidgetTable(this.activityType,this.objectTypeDto.alias,filterCriteriaList);
    }

}
