<!--<div *ngIf="initialized" class="grid">-->


<!--    &lt;!&ndash; Display Selected Role &ndash;&gt;-->

<!--    <div class="col-12">-->
<!--        <div class="card mt-2 mb-2">-->
<!--            <div class="mr-5">-->
<!--                <h5 class="mb-0">{{ roleDto.name }}</h5>-->
<!--                <small>{{ roleDto.id }}</small>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Widget Display &ndash;&gt;-->
<!--    <div class="col-12">-->
<!--        <ng-container [ngSwitch]="selectedWidget">-->
<!--            <mvs-widget-->
<!--                    *ngSwitchCase="'Config'"-->
<!--                    [widgetData]="roleAuthConfigWidget"-->
<!--            ></mvs-widget>-->
<!--            <mvs-widget-->
<!--                    *ngSwitchCase="'Module'"-->
<!--                    [widgetData]="roleAuthModuleWidget"-->
<!--            ></mvs-widget>-->
<!--            <mvs-widget-->
<!--                    *ngSwitchCase="'ObjectType'"-->
<!--                    [widgetData]="roleAuthObjectTypeWidget"-->
<!--            ></mvs-widget>-->
<!--            <mvs-widget-->
<!--                    *ngSwitchCase="'Contract'"-->
<!--                    [widgetData]="roleAuthContractWidget"-->
<!--            ></mvs-widget>-->
<!--            <mvs-widget-->
<!--                    *ngSwitchCase="'Customer'"-->
<!--                    [widgetData]="roleAuthCustomerWidget"-->
<!--            ></mvs-widget>-->
<!--            <div *ngSwitchDefault>-->
<!--                <mvs-widget-->
<!--                        [widgetData]="roleAuthConfigWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->
<!--        </ng-container>-->
<!--    </div>-->
<!--</div>-->

@if (initialized) {

    @if (uiMode == 'side') {
        <um-role-object-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                (onNavigationItems)="handleNavigationItems($event)"
        ></um-role-object-side>
    } @else {
        <um-role-object-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                (onNavigationItems)="handleNavigationItems($event)"
        ></um-role-object-full>
    }
}