import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectSubBaseComponent} from "../../../../../cr/component/cr-customer/component/object-sub-base.component";
import {DmDocumentViewOptionsEnumEnum} from "../../../../../dm/enum/dm-document-view-options.enum";


@Component({
    selector: 'cm-contract-documents-navigation',
    templateUrl: './cm-contract-documents-navigation.component.html',
})
export class CmContractDocumentsNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {
    dmDocumentViewOptionsEnumEnum = DmDocumentViewOptionsEnumEnum;

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {
    }



}
