import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {TabEntries} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";

@Component({
  selector: 'mvs-dm-document-type-page',
  templateUrl: './im-config.page.html',
  styleUrls: ['./im-config.page.scss']
})
export class ImConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "System Configuration";
  queryParamSubscription: Subscription;

  externeSystemWidget: WidgetData;
  activeIndex: number = 0;
  objectType: string;
  objectId: number;

  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label:'Externe Systeme', id: 'im.ImSystem', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.initComponent();
    this.refreshWidgets();
    this.subscribeToParams();
    this.handleTabSelection(0);
    // Main Structure
    // converted to widgets 25/07/2024

    // this.objectBrowserRequest = {
    //
    //   "extractDto": false,
    //   "createMode": true,
    //
    //   'entries' : [
    //     {
    //       'id'                : 1,
    //       'objectAlias'       : 'im.ImSystem',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Externe Systeme",
    //       'dummyParentDescription' : "Externe Systeme.",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Neues System anlegen"
    //     },
    //
    //   ]
    // };

    // this.subObjectBrowserRequest = null;

  }
  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshexterneSystemWidget();
  }
  refreshexterneSystemWidget() {
    this.externeSystemWidget = WidgetFactory.createWidgetList(
        "im.externe.widget",
        "Externe Systeme",
        "table",
        "list",
        "entity",
        "im.ImSystem",
        null,
        ObjectRequestList.createBasic(true, [], [new Sorting("name", true)])
    );
  }


  initComponent(): void {
    this.initialized = true;
  }

  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }

}
