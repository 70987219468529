<ng-container *ngIf="initialized && objectTypes && variables">
    <!--    [style]="{height:'920px'}"-->

    <div class="w-full text-right mb-3">
        <button pButton label="Change Name" class="p-button-outlined text-sm p-2"
                style="height: 1.8rem"
                (click)="toggleNodeLabel()"></button>
    </div>

    <!--    [style]="{height:height+'px'}"-->
    <div>

        <mvs-widget-header
                *ngIf="configurationItems"
                [configurationItems]="configurationItems"
                title="Variable"
        ></mvs-widget-header>


        <!--        [scrollHeight]="height + 'px'"-->
        <p-tree
                [value]="variables"
                [selectionMode]="selectMode"
                [style]="{ width: treeWidth ? '185px' : '' }"
                (onNodeExpand)="nodeExpand($event)"
                (onNodeSelect)="nodeSelect($event)"
                [loading]="busy"
                [draggableNodes]="dragMode"
                [droppableNodes]="true"
                filterPlaceholder="Search Item"
                draggableScope="variable"
                styleClass="p-fluid text-sm px-0 template-component-treenode border-0"
                class="scrollable-section"
                pDraggable="variable"
                [(selection)]="selectionNode"
        >
            <ng-template let-node pTemplate="actions">
                <div class="w-full flex justify-content-between">
                    <span>{{ node.label }}</span>
                    <span *ngIf="!node.parent && node.data.variableType != 'system'" class="node-actions">
                    <i class="pi pi-pencil pi-pencil-variable-node" (click)="handleEditVariableName(node.data.id)"></i>
                    <i class="pi pi-trash pi-trash-variable-node" (click)="handleDeleteVariableName(node.data.id)"></i>
                </span>
                </div>
            </ng-template>
        </p-tree>

        <!--        <p-toast [preventOpenDuplicates]="true"></p-toast>-->
    </div>
</ng-container>

<p-dialog header="Variable anlegen" (onHide)="handleVariableDialogHide()" [(visible)]="visible" [style]="{width: '50vw'}">

    <ng-container *ngIf="variableToggle">
        <div class="grid text-sm">
            <div class="col-12">
                <span>Alias (Technischer Name)</span>
                <br/>
                <input type="text" pInputText [(ngModel)]="variableAlias"/>
            </div>
            <div class="col-12">
                <span>Name der Variable</span>
                <br/>
                <input type="text" pInputText [(ngModel)]="variableName"/>
            </div>
            <div class="col-12">
                <span>Variable Data Type:</span>
                <br/>
                <p-dropdown
                        [options]="dataTypes"
                        [(ngModel)]="selectedVariableType"
                        optionLabel="name"
                        appendTo="body"
                ></p-dropdown>
            </div>

            <div class="col-12">
                <div class="flex gap-3 justify-content-end">
                    <p-button label="Abbrechen" class="p-button-text" (click)="onCancel()"></p-button>
                    <button
                            *ngIf="variableName && selectedVariableType"
                            pButton
                            label="Speichern"
                            class="p-button-success"
                            [disabled]="!variableName || !selectedVariableType"
                            (click)="createVariable(teVariableTypeEnum.simple,selectedVariableType)"
                    ></button>
                </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="!variableToggle">
        <div class="grid text-sm">
            <div class="col-12">
                <span>Alias (Technischer Name)</span>
                <br/>
                <input type="text" pInputText [(ngModel)]="variableAlias"/>
            </div>
            <div class="col-12">
                <span>Name der Variable:</span>
                <br/>
                <input
                        type="text"
                        class="w-12"
                        pInputText
                        [(ngModel)]="variableName"
                />
            </div>
            <div class="col-12">
                <span>Objekttyp:</span>
                <br/>
                <p-dropdown
                        [options]="objectTypes"
                        [(ngModel)]="selectedObjectType"
                        [filter]="true"
                        optionLabel="alias"
                        appendTo="body"
                ></p-dropdown>
            </div>
            <div class="col-12">
                <div class="flex gap-3 justify-content-end">
                    <p-button label="Abbrechen" class="p-button-text" (click)="onCancel()"></p-button>
                    <button
                            *ngIf="variableName && selectedObjectType"
                            pButton
                            label="Speichern"
                            class="p-button-success"
                            [disabled]="!variableName || !selectedObjectType"
                            (click)="createVariable(teVariableTypeEnum.object,selectedObjectType)"
                    ></button>
                </div>
            </div>

        </div>
    </ng-container>

</p-dialog>