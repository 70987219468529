<ng-container *ngIf="initialized">

    <p-card styleClass="mb-4">
        <div class="flex justify-content-between align-items-center">
            <div class="flex flex-column">

                <ng-container
                        *ngIf="selectedUserAddedInsurance && userAddedInsuranceStatusInternal?.length">
                    <div class="flex align-items-center gap-2 flex-wrap">
                        <p-chip
                                *ngFor="let entry of userAddedInsuranceStatusInternal"
                                class="cursor"
                                [label]="entry.label"
                                [icon]="entry.image"
                                (click)="handleInsuranceStatus(entry.key)"
                                [styleClass]="selectedUserAddedInsurance?.internalStatus === entry?.key ? 'bg-'+entry.backgroundColor + ' text-'+entry.color:''"
                        ></p-chip>
                    </div>
                </ng-container>

                <div class="flex mt-3">
                    <button class="p-ripple p-element p-button p-component p-button-text flex gap-2"
                            [disabled]="disablePrevious || busy"
                            (click)="gotoPreviousAddedInsurance()"><i class="fa-solid fa-arrow-left"></i> <span>Vorherige Versicherung</span>
                    </button>
                    <button class="p-ripple p-element p-button p-component p-button-text flex gap-2"
                            [disabled]="disableNext || busy"
                            (click)="gotoNextAddedInsurance()"><span>Nächste Versicherung</span> <i
                            class="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>

            <p-button
                    icon="fa-solid fa-bolt"
                    label="Action"
                    [disabled]="busy"
                    (click)="handleSideDrawer()"
            ></p-button>
        </div>
    </p-card>

    <ng-container *ngIf="selectedUserAddedInsurance">

        <div class="">
            <div class="grid mx-0">

                <div class="col-8 card mb-4">

                    <mvs-widget
                            *ngIf="userAddedInsuranceWidget"
                            [widgetData]="userAddedInsuranceWidget"
                    ></mvs-widget>

                </div>

                <div class="col-4"></div>

                <div class="col-2 card p-4 mb-4" style="height:70vh; overflow:auto">

                    <div
                            *ngIf="!selectedUserAddedInsurance?.documents?.length"
                            class="flex flex-column justify-content-center align-items-center p-4 gap-2 mt-8 pt-8">
                        <i class="far fa-file-alt document-icon-size"></i>
                        Keine Dokumente vorhanden
                    </div>

                    <div class="grid" *ngIf="selectedUserAddedInsurance?.documents?.length">
                        <div class="col-12">
                            <div
                                    *ngIf="selectedUserAddedInsurance?.documents?.length > 1">
                                <div class="flex justify-content-between align-items-center">
                                    <div>
                                        Document {{ currentIndex + 1 }}
                                        of {{ selectedUserAddedInsurance?.documents?.length }}
                                    </div>
                                    <div class="flex gap-2">
                                        <p-button
                                                styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                                                icon="fa fa-arrow-left text-sm"
                                                [disabled]="busy || currentIndex == 0 || selectedUserAddedInsurance?.documents?.length == 1"
                                                (click)="gotoDocument('prev')"
                                        ></p-button>
                                        <p-button
                                                styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-rounded p-button-outlined w-2rem h-2rem"
                                                icon="fa fa-arrow-right text-sm"
                                                [disabled]="busy || currentIndex == selectedUserAddedInsurance?.documents?.length-1 || selectedUserAddedInsurance?.documents?.length == 1"
                                                (click)="gotoDocument('next')"
                                        ></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section
                                class="col-12"
                                *ngFor="let item of selectedUserAddedInsurance.documents">
                            <div class="px-2 py-3 border-1 border-300 border-round-lg                                                                                                      "
                                 [ngClass]="selectedDocument?.id == item.id ? 'bg-blue-50 border-1 border-blue-500' : ''">
                                <div class="flex justify-content-between gap-2 cursor" (click)="onDocumentSelect(item)">
                                    <div class="flex gap-2">
                                        <div class="p-1">


                                            <mvs-pdf-viewer [objectId]="item.documentDtoId" [width]="50"
                                                            [height]="50"></mvs-pdf-viewer>
                                        </div>
                                        <div class="flex flex-column gap-2">
                                            <span class="text-sm">{{ item.name }}</span>
                                            <p-tag
                                                    [value]="statusEntries.get(item.documentDto.status).label"
                                                    severity="warning"></p-tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                <div class="col-6 py-0 mb-4">
                    <div class="px-3" style="height:70vh" *ngIf="selectedDocument">
                        <mvs-pdf-viewer
                                [objectId]="selectedDocument?.documentDtoId"
                                view="#view=FitH"
                                class="w-full h-full"
                                width="100%"
                                (onDocumentLoad)="handleDocumentLoaded()"
                                (onDocumentFail)="handleDocumentLoaded()"
                        ></mvs-pdf-viewer>
                    </div>
                </div>


            </div>
        </div>

    </ng-container>
</ng-container>
