import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {CrCustomerDto} from "../../dto/cr-customer.dto";
import {
    ObjectChangeInformation,
    ObjectChangeInformationActionEnum
} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CustomerContractService} from "../../service/api/customer-contract.service";
import {CustomerContractDto} from "../../dto/customer-contract.dto";
import {CustomerEntityAssignmentType} from "../../enum/customer-entity-assignment-type.enum";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {Router} from "@angular/router";
import {ContractDto} from "../../../cm/dto/contract.dto";
import {ContractFormerService} from "../../../cm/service/api/contract-former.service";
import {ContractFormerDto} from "../../../cm/dto/contract-former.dto";
import {ContractFormerContractEnum} from "../../../cm/enum/contract-former-contract-enum.enum";
import {ContractStatusService} from "../../../cm/service/api/contract-status.service";
import {ContractStatusDto} from "../../../cm/dto/contract-status.dto";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'cr-create-customer-contract',
    templateUrl: './cr-create-customer-contract.component.html',
})
export class CrCreateCustomerContractComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerId: number;
    @Input() formerContract: ContractDto;

    createdObjectIdentifier: ObjectIdentifier;
    createdContract: ContractDto;


    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected customerContractService: CustomerContractService,
        protected router: Router,
        protected contractFormerService: ContractFormerService,
        protected contractStatusService: ContractStatusService,
        protected messageService: MvsMessageService,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initialized = true;

    }

    ngOnChanges(changes: SimpleChanges): void {

        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

    }


    /**
     * On Object Change.
     */
    onObjectChanged() {

    }

    handleObject(event: ObjectChangeInformation) {

        if (event.action == ObjectChangeInformationActionEnum.created) {
            this.createdObjectIdentifier = new ObjectIdentifier(event.objectType, event.after.id);
            this.createdContract = <ContractDto>event.after;
            this.createCustomerContract(this.createdObjectIdentifier);
            this.createContractStatus();
        } else {
            this.messageService.showWarningMessage("Info", "Vertrag wurde bereits angelegt und zugewiesen, bitte mit der Bearbeitung auf der Vertragsseite weitermachen.");
        }


    }

    createCustomerContract(objectIdentifier: ObjectIdentifier) {

        const customerContractDto = new CustomerContractDto();
        customerContractDto.customerDtoId = this.customerId;
        customerContractDto.contractDtoId = objectIdentifier.objectId;
        customerContractDto.assignmentType = CustomerEntityAssignmentType.main;
        customerContractDto.entityStatus = EntityStatusEnum.active;

        this.customerContractService.create(customerContractDto).subscribe((res: CustomerContractDto) => {

            if (this.formerContract) {
                this.assignFormerContract(objectIdentifier);
            }


            this.navigateToCustomerContract(res);
        })
    }

    assignFormerContract(objectIdentifier: ObjectIdentifier) {

        const dto = new ContractFormerDto();
        dto.contractDtoId = objectIdentifier.objectId;
        dto.assignment = ContractFormerContractEnum.change;
        dto.contractFormerDtoId = this.formerContract.id;

        this.contractFormerService.create(dto).subscribe(res => {
        })
    }

    createContractStatus() {

        const contractStatusDto = new ContractStatusDto();
        contractStatusDto.startDate = this.createdContract.startDate;
        contractStatusDto.contractDtoId = this.createdContract.id;
        //statusTypeDtoId 1 = Hauptstatus and statusTypeEntryDtoId 2 = active / draft
        contractStatusDto.statusTypeDtoId = 1;
        contractStatusDto.statusTypeEntryDtoId = 1;

        this.contractStatusService.create(contractStatusDto).subscribe(res => {
        })


    }

    navigateToCustomerContract(dto: CustomerContractDto) {
        const event = new ObjectChangeInformation();
        event.after = dto;
        this.onChangedObject.emit(event);
        this.router.navigateByUrl("/cm/contract/" + this.createdObjectIdentifier.objectId);

    }

}
