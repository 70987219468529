import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    JbJobRunObjectComponent
} from "../../component/object-base/jb-job-run-object-component/jb-job-run-object.component";

@Injectable({
    providedIn: 'root'
})
export class JbJobRunService extends MvsCrudService {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/jb/jbJobRuns')
  }

  private eventSource: EventSource | null = null;

  getStreamJobUpdates(): Observable<any> {
    return new Observable((observer) => {
      const connect = () => {
        //TODO: Retrieve the tenant properly!
        this.eventSource = new EventSource(MvsCrudService.baseUrl + '/jb/jbJobRuns/streamSSE?x-alpha-tenant=tenantA');

        this.eventSource.onmessage = (event) => {
          observer.next(JSON.parse(event.data));
        };

        this.eventSource.onerror = (error) => {
          //TODO: Only reconnect if screen is in Focus or becomes focused
          console.error('SSE Error:', error);
          this.eventSource?.close();
          setTimeout(connect, 3000); // Reconnect after 3 seconds
        };
      };

      connect();

      return () => this.closeStreamJobUpdates();
    });
  }


  closeStreamJobUpdates() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
      console.log('SSE connection closed');
    }
  }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return JbJobRunObjectComponent;
        }
        return null;
    }
}