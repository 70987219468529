import {Component} from "@angular/core";
import {JbJobRunBaseComponent} from "../jb-job-run-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: "jb-job-run-side",
    templateUrl: 'jb-job-run-side.component.html'
})

export class JbJobRunSideComponent extends JbJobRunBaseComponent{

    navigationItems: NavigationItem[] = [
        {
            label: 'jb.JbJobRun',
            action: 'jb.JbJobRun',
            tooltip: 'Job Run',
            icon: 'fa-regular fa-circle-info',
            toggleable: false,
            default: true
        },
        {
            label: 'jb.JbJobRunEntryLog',
            action: 'jb.JbJobRunEntryLog',
            tooltip: 'Job Run Entry Log',
            icon: 'fa-solid fa-file-alt',
            toggleable: false,
            default: false
        },
    ]

    ngOnInit() {
        super.ngOnInit();
    }

}