<div class="bg-white">
    <mvs-config-header header="Ticket Type Configuration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2">
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                        </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'tm.TmCommunicationChannel'">
            <mvs-widget *ngIf="communicationChannelWidget"
                        [widgetData]="communicationChannelWidget"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'tm.TicketGroup'">
            <mvs-widget *ngIf="ticketGroupWidget"
                        [widgetData]="ticketGroupWidget"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'tm.TicketGroupFilter'">
            <mvs-widget *ngIf="ticketGroupFilter"
                        [widgetData]="ticketGroupFilter"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'tm.TicketType'">

            <div class="grid">
                <div class="col-3">
                    <mvs-widget *ngIf="ticketTypeGroupWidget"
                                [widgetData]="ticketTypeGroupWidget"
                                (onObjectSelect)="onGroupSelect($event)">
                    </mvs-widget>
                </div>

                <div class="col-9">
                    <mvs-widget *ngIf="ticketTypeWidget"
                                [widgetData]="ticketTypeWidget"
                                (dblclick)="openObjectInNewTab($event)"
                                (onObjectSelect)="openObjectInNewTabId($event)">
                    </mvs-widget>
                </div>
            </div>


        </ng-container>


    </ng-container>

</div>