import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {WidgetData} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {CoreApiResponseDto} from "@kvers/alpha-core-common";
import {MessageService} from "primeng/api";
import {MvsCoreService} from "@kvers/alpha-core-common";


@Component({
  selector: 'mvs-eu-app-config',
  templateUrl: './eu-overview.page.html',
  styleUrls: ['./eu-overview.page.scss']
})
export class EuOverviewPage extends PageComponent implements OnInit {

  latestUserWidgetData: WidgetData;

  tableReportWidgetData: WidgetData;

  testMessageSubject: string;
  testMessageBody: string;
  testMessageToken: string;

  defaultLabel: string = "Overview Page Configuration";


  constructor(
      protected route: ActivatedRoute,
      protected http: HttpClient,
      protected messageService: MessageService,
      protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.refreshReportDataWidget();

  }

  refreshReportDataWidget() {

    // Table Widget Report
    this.tableReportWidgetData = new WidgetData();
    this.tableReportWidgetData.id = 1;
    this.tableReportWidgetData.idAlias = "eu.customer.overview.1";
    this.tableReportWidgetData.name = "User Übersicht";
    this.tableReportWidgetData.uiComponent = "table";
    this.tableReportWidgetData.dataProvider = "list";
    this.tableReportWidgetData.dataSource = "report";
    this.tableReportWidgetData.dataProviderObject = "4";
    this.tableReportWidgetData.setParamValue("size", "S");

  }

  refreshLatestUsersDataWidget() {

    // Table Widget Report
    this.tableReportWidgetData = new WidgetData();
    this.tableReportWidgetData.id = 1;
    this.tableReportWidgetData.idAlias = "eu.customer.overview.1";
    this.tableReportWidgetData.name = "User Übersicht";
    this.tableReportWidgetData.uiComponent = "table";
    this.tableReportWidgetData.dataProvider = "list";
    this.tableReportWidgetData.dataSource = "report";
    this.tableReportWidgetData.dataProviderObject = "4";
    this.tableReportWidgetData.setParamValue("size", "S");

  }

  onSendMessage(event: any) {

    const message = {
      subject : this.testMessageSubject,
      body : this.testMessageBody,
      token : this.testMessageToken
    };


    return this.http.post<CoreApiResponseDto>(
        MvsCrudService.baseUrl + '/ns/nsNotificationService/sendMessage',
        message).subscribe(value => {

          this.messageService.add({severity:'success', summary:'Erfolgreich gesendet', detail:value.data});

        }, error => {
          this.messageService.add({severity:'error', summary:'Fehler', detail:error.data});
    });
  }



}
