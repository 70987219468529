import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {UserService} from "../../../../../um/service/api/user.service";
import {UmUserDto} from "../../../../../um/dto/um-user.dto";
import {UserProfileHelper} from "../../../../../um/components/user-profile/helper/user-profile-helper";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {
  DtoDetail,
  DtoImportObjectContext,
  DtoImportObjectContextEntry,
  MvsCoreService,
  ObjectTypeService
} from "@kvers/alpha-core-common";
import {UmUserService} from "../../../../../um/service/api/um-user.service";
import {EuUserService} from "../../../../../eu/service/api/eu-user.service";
import {Observable} from "rxjs";


@Component({
  selector: 'mvs-form-control-output-user-image',
  template: `        <div [ngClass]="displayInfo ? 'flex gap-2 align-items-center' : ''">
    <img class="h-2rem w-2rem border-circle"
                          [src]="imageSrc"
                          [pTooltip]="username"
                          tooltipPosition="top"
  />
  <span *ngIf="displayInfo">{{username}}</span>
  </div>
  `
})
export class MvsFormFieldOutputUserImageComponent
  extends MvsFormFieldOutputBaseComponent
  implements OnInit, OnDestroy, OnChanges {

  constructor(protected navigationService: MvsObjectNavigationService,
              protected objectService: ObjectTypeService,
              protected coreService: MvsCoreService,
              protected umUserService: UmUserService,
              protected euUserService: EuUserService
              ) {
    super(navigationService, objectService, coreService);
  }

  @Input() displayInfo: boolean = false;
  imageSrc: string;
  username: string;

  /**
   * Initialize component.
   * @constructor
   */
  override ngOnInit() {

    super.ngOnInit();

    this.refreshComponent();

    this.initialized = true;

  }

  /**
   * Process on changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (!this.initialized) {
      return;
    }

    this.refreshComponent();

  }

  override refreshComponent() {
    const paths = this.getUserIdPaths();
    const userId = paths ? +paths[1] : null;

    if (!userId || typeof userId != 'number') {
      return;
    }

    const observable = this.getObservable(userId);

    if (!observable) {
      return;
    }

    this.getObservable(userId).subscribe((res: UmUserDto) => {
      this.username = `${res.firstName} ${res.lastName}`;
      this.getUserImage(userId);
    });

  }

  getUserImage(userId: number) {
    const observable = this.getObservable(userId);
    if (!observable) {
      return;
    }

    this.getImageObservable(userId).subscribe(
      (value: any) => {

        UserProfileHelper.getImageSrcFromBlob(value).then(imageSrc => {
          this.imageSrc = imageSrc;
          this.initialized = true;
        });

      });
  }

  getObservable(userId: number): Observable<DtoDetail> {
    const paths = this.getUserIdPaths();
    if (!paths || !paths.length) {
      return null;
    }

    const alias = paths[0];

    if (alias == 'ex') {
      return this.euUserService.getProfile(userId);

    } else if (alias == 'um') {
      return this.umUserService.getUserByUserId(userId);
    }
    return null;
  }

  getImageObservable(userId: number): Observable<DtoDetail> {
    const paths = this.getUserIdPaths();
    if (!paths || !paths.length) {
      return null;
    }

    const alias = paths[0];

    if (alias == 'ex') {
      return this.euUserService.getPictureBinaryById(userId);

    } else if (alias == 'um') {
      return this.umUserService.getPictureBinaryById(userId);
    }
    return null;
  }

  getUserIdPaths(): string[] {
    const paths = this.value.split(':');
    return paths;
  }


}
