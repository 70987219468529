
<ng-container *ngIf="initialized">

    <mvs-wf-process-start></mvs-wf-process-start>

    <!--
    <mvs-wf-process [objectIdentifier]="{ objectType : 'wf.WfProcess', objectId: 4}"></mvs-wf-process>
    -->


    <!--
        <mvs-wf-process [objectIdentifier]="{ objectType : 'wf.WfProcess', objectId: 2}"></mvs-wf-process>

        <mvs-widget [widgetData]="formWidget" *ngIf="formWidget"></mvs-widget>
        -->


    <!--
    <mvs-crud-object [objectType]="'wf.WfProcessTypeStepActivity'"></mvs-crud-object>
    -->

    <!--
    <p-button label="search" (onClick)="searchCustomer()"></p-button>
    -->

    <!--
    <mvs-widget [widgetData]="customerWidgetData">

        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns" label="Active Tickets" fieldId="tmActiveTickets">

            <tm-ticket-output-field [dtoList]="object['dto']"></tm-ticket-output-field>

        </ng-template>

        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns" label="Active Process" fieldId="wfActiveProcesses">

            <wf-process-output-field [dtoList]="object['dto']"></wf-process-output-field>

        </ng-template>

    </mvs-widget>
-->
</ng-container>
