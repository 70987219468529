import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent} from "@kvers/alpha-ui";
import {AppointmentTypeService} from "../../../../../service/api/appointment-type.service";
import {FilterCriteria, ObjectRequestList} from "@kvers/alpha-core-common";
import {AppointmentTypeDto} from "../../../../../dto/appointment-type.dto";
import {AppointmentTypeAgentService} from "../../../../../service/api/appointment-type-agent.service";
import {AppointmentTypeAgentDto} from "../../../../../dto/appointment-type-agent.dto";
import {AgentDto} from "../../../../../../am/dto/agent.dto";

@Component({
    selector: 'as-appointment-booking',
    templateUrl: 'appointment-booking.component.html',
})
export class AppointmentBookingComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() customerId: number;

    appointmentTypeList: AppointmentTypeDto[];
    selectedAppointmentType: AppointmentTypeDto;

    appointmentTypeAgentList: AppointmentTypeAgentDto[];
    selectedAppointmentTypeAgent: AppointmentTypeAgentDto[];

    agents: AgentDto[];
    selectedAgents: AgentDto[];

    constructor(protected appointmentTypeService: AppointmentTypeService, protected appointmentTypeAgentService: AppointmentTypeAgentService) {
    }

    ngOnInit(): void {

        const agents:any = [{id:157, name: 'marko'}, {id:2, name: 'carlo'}];
        this.selectedAgents = agents;

        this.agents = agents;

        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.retrieveAppointmentType();
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    retrieveAppointmentType() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.appointmentTypeService.list(ObjectRequestList.createBasic(false, [], [])).subscribe(res => {
            this.appointmentTypeList = res.entries;
            this.busy = false;
        }, error => {
            this.appointmentTypeList = null;
            this.selectedAppointmentType = null;
            this.busy = false;
        });
    }

    retrieveAppointmentTypeAgent() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        const filterCriteria = FilterCriteria.create('appointmentType', FilterCriteria.cOperatorEqual, this.selectedAppointmentType.id);

        this.appointmentTypeAgentService.list(ObjectRequestList.createBasic(false, [filterCriteria], [])).subscribe(res => {
            this.appointmentTypeAgentList = res.entries;
            this.busy = false;
        }, error => {
            this.appointmentTypeAgentList = null;
            this.selectedAppointmentTypeAgent = null;
            this.busy = false;
        });
    }

    handleSelect() {
        this.retrieveAppointmentTypeAgent();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
