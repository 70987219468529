<p-toolbar>
    <div class="p-toolbar-group-start">
        <p-breadcrumb
                class="max-w-full"
                [model]="breadcrumb"
                [home]="home"/>
    </div>

    <div class="p-toolbar-group-end">
        <i class="pi pi-cog pointer" (click)="navigateToConfig()"></i>

    </div>
</p-toolbar>

<div class="grid my-2">
    <div class="col-12 lg:col" *ngFor="let card of itemsArray">
        <div class="card shadow-1 m-1" (click)="navigateTo(card.route)">
            <p class="my-2 text-700">{{ card.label }}</p>
            <p *ngIf="!card?.value?.length" class="text-3xl font-medium">{{ card.value }}</p>
            <div *ngIf="card?.value?.length">
                <ng-container *ngFor="let value of card.value; let last = last;">
                    <span class="text-3xl font-medium">{{ value }}</span> <span *ngIf="!last" class="separator"></span>
                </ng-container>
            </div>
        </div>
    </div>
</div>
