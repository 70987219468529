import { Component, OnInit } from '@angular/core';
import {ObjectIdentifierData, ObjectRequestList, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
  selector: 'mvs-cr-contact-page',
  templateUrl: './customer-type.page.html',
  styleUrls: ['./customer-type.page.scss']
})
export class CustomerTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  defaultLabel: string = "Customer Type Config";

  activeIndex = 0;

  customerTypeWidget: WidgetData;
  customerRelatedPersonTypeWidget: WidgetData;
  objectDataWidget: WidgetData;

  ngOnInit(): void {


    this.refreshWidgets();

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Customer Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Customer Type anlegen"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerRelatedPersonType',
          'level'             : 2,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Related Person Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"
        },

      ]

    }

    super.ngOnInit();
  }

  refreshWidgets() {
    this.refreshCustomerTypeWidget();
    this.refreshCustomerRelatedPersonTypeTypeWidget();
  }

  refreshCustomerTypeWidget() {
    this.customerTypeWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.type.config.widget',
        'Customer Types',
        'cr.CustomerType',
        'No Data',
        this.getObjectRequestList());
    this.customerTypeWidget.uiComponent = 'selectable';
  }


  refreshCustomerRelatedPersonTypeTypeWidget() {
    this.customerRelatedPersonTypeWidget = WidgetFactory.createWidgetListEntity(
        'cr.customer.related.person.type.config.widget',
        'Related Person Types',
        'cr.CustomerRelatedPersonType',
        'No Data',
        this.getObjectRequestList());
    this.customerRelatedPersonTypeWidget.uiComponent = 'selectable';
  }



  getObjectRequestList() {
    return ObjectRequestList.createBasic(true, [], []);
  }

  handleObjectSelect(event: ObjectIdentifierData) {

    this.objectDataWidget = WidgetFactory.createWidgetEntityData(
        `${event.objectType}.cr.customer.type.widget`,
        '',
        event.objectType,
        event.objectId
    )

  }

  clearWidgets() {
    this.objectDataWidget = null;
  }
}
