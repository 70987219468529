import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'te-statistics.component.html',
})
export class TeStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    pending: number = 0;
    processed: number = 0;
    inProcess: number = 0;
    unfinished: number = 0;


    templateHistoryWidget: WidgetData;
    templateByContentTypeWidget: WidgetData;
    artefactByContentTypeWidget: WidgetData;
    recentlyAddedTemplateWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'End User Module'},
        ];

        this.overviewDto = [
            {label: ' Pending', value: 0 },
            {label: ' Processed', value: 0 },
            {label: 'In-Process', value: 0 },
            {label: 'Unfinished', value: 0 }
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshTemplateHistoryWidget();
        this.refreshTemplateByContentTypeWidget();
        this.refreshArtefactByContentTypeWidget();
        this.refreshAddedTemplate();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/te/config']);
    }



    refreshTemplateHistoryWidget() {
        this.templateHistoryWidget = new WidgetData();
        this.templateHistoryWidget.idAlias = "te.template.overview.history.widget";
        this.templateHistoryWidget.name = "Template History";

        this.templateHistoryWidget.uiComponent = "history.chart";
        this.templateHistoryWidget.dataProvider = "list";
        this.templateHistoryWidget.dataSource = "entity.groupBy";
        this.templateHistoryWidget.dataProviderObject = "te.TeTemplate";
        this.templateHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["templateGroup", "createdDate"],
            [new ObjectRequestListAttribute("templateGroup", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.templateHistoryWidget.setParamValue("fieldCategory", "templateGroup");
        this.templateHistoryWidget.setParamValue("fieldCategoryCount", "templateGroup_count");
        this.templateHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.templateHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.templateHistoryWidget.setParamValue("selectionMode", "single");
        this.templateHistoryWidget.setParamValue("size", "S");
    }

    refreshTemplateByContentTypeWidget() {
        this.templateByContentTypeWidget = WidgetFactory.createWidgetGroupBy(
            'te.overview.template.content.type.widget',
            'Template By Content Type',
            'category',
            'entity.groupBy',
            'te.TeTemplate',
            'contentTypeEnum',
            'contentTypeEnum_count',
            this.filterCriteria,
            'contentTypeEnum',
            'Anzahl');
    }

    refreshArtefactByContentTypeWidget() {
        this.artefactByContentTypeWidget = WidgetFactory.createWidgetGroupBy(
            'te.overview.artefact.content.type.widget',
            'Artefact By Content Type',
            'category',
            'entity.groupBy',
            'te.TeArtefact',
            'contentTypeEnum',
            'contentTypeEnum_count',
            this.filterCriteria,
            'contentTypeEnum',
            'Anzahl');
    }

    refreshAddedTemplate() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedTemplateWidget = WidgetFactory.createWidgetTableEntity(
            'te.overview.recently.added.template',
            'Recent Templates',
            'te.TeTemplate',
            'No Templates',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
