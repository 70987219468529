import {Route, Routes} from "@angular/router";
import {EuAppConfigPage} from "./page/overview/eu-app-config/eu-app-config.page";
import {EuOverviewPage} from "./page/overview/eu-overview/eu-overview.page";
import {EuRegistrationOverviewPage} from "./page/overview/eu-registration-overview/eu-registration-overview.page";
import {EuConfigComponent} from "./page/overview/eu-config-page/eu-config.component";
import {EuConfigPageWebappComponent} from "./page/overview/eu-config-page-webapp/eu-config-page-webapp.component";
import {OverviewEuPage} from "./page/eu-overview-page/overview-eu.page";

export function EuSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'eu', component: OverviewEuPage},
        {path: 'eu/config', component: EuConfigComponent},


        {path: 'eu/euAppConfig', component: EuAppConfigPage},
        {path: 'eu/euOverview', component: EuOverviewPage},
        {path: 'eu/registrations', component: EuRegistrationOverviewPage},

        {path: 'eu/euAppConfigTest', component: EuConfigPageWebappComponent},

    ];

    return routes;

}
