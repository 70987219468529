<div class="mt-5">
    @if (objectIdentifier) {
        @if (activeNavigationItem.action == 'Calculation-Logic-Fields') {
            <div class="grid">
                <div class="col-9">
                    <div class="shadow-1 border-round mb-4">
                        <mvs-widget
                                *ngIf="calculationLogicTypeFieldWidgetData"
                                [widgetData]="calculationLogicTypeFieldWidgetData"
                        ></mvs-widget>
                    </div>
                    <div class="shadow-1 border-round surface-section mb-4">
                        <lg-calculation-logic-type-step-component
                                [calculationLogicTypeId]="this.objectIdentifier.objectId"
                        ></lg-calculation-logic-type-step-component>
                    </div>
                </div>

                <div class="col-3">
                    <div class="shadow-1 border-round surface-section">
                        <mvs-object-comments
                                headline="Kommentare"
                                [mainObject]="objectIdentifier"
                                linkedEntityObjectType="lg.CalculationLogicTypeComment"
                        ></mvs-object-comments>
                    </div>
                </div>
            </div>
        }

        @if (activeNavigationItem.action == 'TestCases') {
            <mvs-lg-calculation-test-case
                    [calculationLogicTypeId]="objectIdentifier.objectId"
            ></mvs-lg-calculation-test-case>
        }


        @if (activeNavigationItem.action == 'Info') {
            <mvs-widget
                    *ngIf="calculationLogicTypeWidgetData"
                    [widgetData]="calculationLogicTypeWidgetData"
            ></mvs-widget>
        }
    }
</div>
