import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mvs-hierarchy-entry-wrapper-component',
    templateUrl: './hierarchy-entry-wrapper.component.html',
    styleUrls: ['./hierarchy-entry-wrapper.component.scss']
})
export class HierarchyEntryWrapperComponent implements OnInit {

    showTopBorder = false;
    showBottomBorder = true;
    showLeftBorder = true;
    showRightBorder = true;
    showTopLine = true;
    showBottomLine = true;
    showLeftLine = true;
    showRightLine = true;
    showTopRightLine = true;
    showBottomRightLine = true;
    showTopLeftLine = true;
    showBottomLeftLine = true;
    showTopRightHalfBorder = true;
    showTopLeftHalfBorder = false;
    showBottomRightHalfBorder = false;
    showBottomLeftHalfBorder = false;
    showRightTopHalfBorder = false;
    showRightBottomHalfBorder = false;
    showLeftTopHalfBorder = false;
    showLeftBottomHalfBorder = false;

    constructor() {
    }

    ngOnInit(): void {

    }


}