@if (activeNavigationItem.action == 'integrationTypeInfo') {
    <mvs-widget *ngIf="integrationTypeWidget"
                [widgetData]="integrationTypeWidget"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'integrationType') {

    <div class="kanban-board-side align-items-center">
        @if (sortedStep?.length) {
            <div *ngFor="let step of sortedStep" class="kanban-column">
                <div class="kanban-header flex flex-column gap-2">

                    <div class="flex justify-content-between">
                        <span>{{ step.name }}</span>
                        <i class="pi pi-ellipsis-h" (click)="handleStep(step)"></i>
                    </div>
                    <div class="flex">
                        <!--                                <p-chip [label]="step?.adapterTypeDtoName"></p-chip>-->
                        <p-tag [value]="step?.adapterTypeDtoName"></p-tag>
                    </div>
                </div>

                <!-- Step Inputs -->
                <div class="kanban-cards card">

                    <div class="flex justify-content-between kanban-header">
                        <p>Input</p>
                        <i class="pi pi-ellipsis-h" (click)="handleStepInput(step)"></i>
                    </div>
                    @if (step?.integrationTypeStepInput?.length) {

                        <div *ngFor="let item of step?.integrationTypeStepInput" class="kanban-card"
                             (click)="handleStepInput(step,item)">
                            <p-tag [value]="item?.integrationVariableDtoName"></p-tag>
                            <p-tag [value]="item?.adapterVariableDtoName"></p-tag>
                        </div>
                    }
                </div>

                <!-- Step Outputs -->
                <div class="kanban-cards card">
                    <div class="flex justify-content-between kanban-header">
                        <p>Output</p>
                        <i class="pi pi-ellipsis-h" (click)="handleStepOutput(step)"></i>
                    </div>

                    @if (step?.integrationTypeStepOutput?.length) {

                        <div *ngFor="let item of step?.integrationTypeStepOutput" class="kanban-card"
                             (click)="handleStepOutput(step,item)">
                            <p-tag [value]="item?.integrationVariableDtoName"></p-tag>
                            <p-tag [value]="item?.adapterVariableDtoName"></p-tag>
                        </div>
                    }
                </div>

                <!-- Step Transitions -->

                <div class="kanban-cards card">
                    <div class="flex justify-content-between kanban-header">
                        <p>Step Transition</p>
                        <i class="pi pi-ellipsis-h" (click)="handleStepTransition(step)"></i>
                    </div>

                    @if (step['stepTransitionDto']) {

                        <p-button
                                *ngFor="let stepTransition of step['stepTransitionDto']"
                                [label]="'Transition: ' + stepTransition?.fromStepDtoName + ' to ' + stepTransition?.toStepDtoName"
                                (click)="showTransition(stepTransition.id)"></p-button>
                    }
                </div>

            </div>
        }
        <p-button icon="pi pi-plus" [rounded]="true" (click)="handleStep()"/>
    </div>
}

@if (activeNavigationItem.action == 'integrationTypeVariable') {
    <mvs-widget
            *ngIf="integrationTypeVariableTableWidget"
            [widgetData]="integrationTypeVariableTableWidget"
            (onObjectSelect)="handleVariableSelect($event)"
    ></mvs-widget>
}
