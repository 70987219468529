@if (initialized) {
    <scheduler-component
            *ngIf="schedulerData"
            [schedulerData]="schedulerData"
            [enableSelection]="true"
            (onFindSlot)="handleFindSlot($event)"
            (onShowEvent)="handleEventSelect($event)"
            (onMoveEvent)="handleEventMove($event)"
            (onDeleteEvent)="handleDeleteEvent($event)"
            (onCurrentSelection)="handleCurrentSelection($event)"
            (onChangeModeAndTime)="handleChangeModeAndTime($event)"
            (dateRangeChanged)="handleDateRangeChanged($event)"
    ></scheduler-component>

    <p-dialog [(visible)]='createEventDialog'>

        @if (createEventDialog) {
            @if (customerId) {
                <div class="flex flex-column gap-4">
                    <div>
                        appointmentType:
                        <p-dropdown
                                *ngIf="appointmentType?.length"
                                [options]="appointmentType"
                                [(ngModel)]="selectedAppointmentType"
                                appendTo="body"
                                optionLabel="name"
                                placeholder="Select appointment type"/>
                    </div>


                    <div>
                        agent:
                        <p-dropdown
                                *ngIf="agents?.length"
                                [options]="agents"
                                [(ngModel)]="selectedAgent"
                                appendTo="body"
                                optionLabel="name"
                                placeholder="Select agent"/>
                    </div>

                    <div>
                        startTime:
                        <p-calendar
                                inputId="calendar-12h"
                                appendTo="body"
                                [(ngModel)]="currentSelectionStart"
                                [showTime]="true"
                                hourFormat="12"/>
                    </div>


                    <div>
                        endTime:
                        <p-calendar
                                inputId="calendar-12h"
                                appendTo="body"
                                [(ngModel)]="currentSelectionEnd"
                                [showTime]="true"
                                hourFormat="12"/>
                    </div>

                    <div>
                        <p-button
                                label="Create Event"
                                [disabled]="!selectedAppointmentType || !selectedAgent || !currentSelectionStart || !currentSelectionEnd"
                                (click)="handleCreateAppointment()"
                        ></p-button>
                    </div>
                </div>
            }

            @if (!customerId) {
                <div class="flex flex-column gap-4">
                    <div class="p-field">
                        <label for="subject">Subject</label>
                        <input id="subject" type="text" pInputText [(ngModel)]="subject"/>
                    </div>

                    <div>
                        agent:
                        <p-dropdown
                                *ngIf="agents?.length"
                                [options]="agents"
                                [(ngModel)]="selectedAgent"
                                appendTo="body"
                                optionLabel="name"
                                placeholder="Select agent"/>
                    </div>

                    <div class="p-field-checkbox">
                        <p-checkbox id="allowNewTimeProposals" binary [(ngModel)]="allowNewTimeProposals"></p-checkbox>
                        <label for="allowNewTimeProposals">Allow New Time Proposals</label>
                    </div>

                    <div class="p-field">
                        <label for="startDate">Start Time</label>
                        <p-calendar
                                id="startDate"
                                inputId="calendar-12h"
                                appendTo="body"
                                [(ngModel)]="currentSelectionStart"
                                [showTime]="true"
                                hourFormat="12"/></div>

                    <div class="p-field">
                        <label for="endDate">End Time</label>
                        <p-calendar
                                id="endDate"
                                inputId="calendar-12h"
                                appendTo="body"
                                [(ngModel)]="currentSelectionEnd"
                                [showTime]="true"
                                hourFormat="12"/>
                          </div>

                    <div class="p-field-checkbox">
                        <p-checkbox id="isOnlineMeeting" binary [(ngModel)]="isOnlineMeeting"></p-checkbox>
                        <label for="isOnlineMeeting">Online Meeting</label>
                    </div>
                </div>

                <p-footer>
                    <p-button label="Save Event" icon="pi pi-save" (click)="createEvent()"></p-button>
                </p-footer>
            }

        }

    </p-dialog>
}