import {DtoDetail} from "@kvers/alpha-core-common";
import { PersonDto } from "./person.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";

export class PersonAddressDto extends DtoDetail{


    public personDto? : PersonDto;
    public personDtoId : number;
    public personDtoName : string;
    public addressDtoId : number;
    public addressDtoName : string;
    public entityStatus: EntityStatusEnum;
    public startDate: Date;
    public endDate: Date;

}