<ng-container *ngIf="initialized">

    <mvs-inline-component
            [objectType]="'tm.TicketAppointment'"
            [name]="dto.appointmentDtoName"
            [startDate]="dto.createdDate"
            [endDate]="dto.createdDate"
            [status]="AppointmentRequestUrgency[dto.appointmentRequestUrgency]"
    >
    </mvs-inline-component>

</ng-container>