@if (initialized) {
    @if (uiMode == 'side') {
        <jb-job-run-side
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></jb-job-run-side>
    } @else {
        <jb-job-run-full
                [objectIdentifier]="objectIdentifier"
                (onNavigationItems)="handleNavigationItems($event)"
        ></jb-job-run-full>
    }
}