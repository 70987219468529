import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ObjectBrowserResultDto} from "@kvers/alpha-core-common";
import {environment} from "../../../../../environments/environment";
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-sm-signature-type-page',
  templateUrl: './signature-type.page.html',
  styleUrls: ['./signature-type.page.scss']
})
export class SignatureTypePage extends PageComponent {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  ngOnInit(): void {
    super.ngOnInit()
    /*
    this.http.get(environment.API_URL.replace("mvsa", "mvseureg") + '/eu/welcome/getWelcomePages?firstUse=true').subscribe(value => {
        alert("hello world");
    });
    */


    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'sm.SignatureGroup',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Signature Group",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Signature Group"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'sm.SignatureType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'parentId'          : 1,
          'processRecursion'  : true,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Signature Type",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Signature Type"
        }
      ]
    };

    this.subObjectBrowserRequest = null;

  }
}
