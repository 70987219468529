import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'mvs-hierarchy-entry-content-component',
    templateUrl: './hierarchy-entry-content.component.html',
    styleUrls: ['./hierarchy-entry-content.component.scss']
})
export class HierarchyEntryContentComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

    }


}