import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {HgPartnerDto} from "../../dto/hg-partner.dto";
import {EmSchemaRuntimeDataDto} from "../../../em/service/dto/em-schema-runtime-data.dto";
import {
    HgGdvPartnerObjectComponent
} from "../../component/hg-gdv-partner-object-component/hg-gdv-partner-object.component";

@Injectable({
    providedIn: 'root'
})
export class HgPartnerService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/hg/hgPartners')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return HgGdvPartnerObjectComponent;
        }
        return null;
    }

    public generateSchema(id: number): Observable<HgPartnerDto> {
        return <Observable<HgPartnerDto>>this.http.post(this.apiUrl + "/generate/" + id, {});
    }

  /**
   * Calls the Java controller to process an inventory file.
   * Sends the file as FormData.
   */
  public processInventoryFile(id: number, file: File): Observable<EmSchemaRuntimeDataDto> {
    const url = `${this.apiUrl}/${id}/readInventoryFile`;

    // Prepare form data
    const formData: FormData = new FormData();
    formData.append('file', file);

    // Set headers (Let browser set Content-Type for FormData)
    const headers = new HttpHeaders();

    return this.http.post<EmSchemaRuntimeDataDto>(url, formData, { headers });
  }

    /**
     * Uploads an inventory file and processes it
     * @param id - The entity ID
     * @param messageType - The message type enum value
     * @param file - The uploaded file
     * @returns Observable of processed data
     */
    processFile(id: number, messageType: number, file: File): Observable<EmSchemaRuntimeDataDto> {
        const formData = new FormData();
        formData.append('file', file);

        const url = `${this.apiUrl}/${id}/readFile?messageType=${messageType}`;

        return this.http.post<EmSchemaRuntimeDataDto>(url, formData);
    }



}