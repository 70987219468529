import {Route, Routes} from "@angular/router";
import {ContactTypePage} from "./page/overview/contact-type/contact-type.page";
import {
    ContactTypeObjectTypePage
} from "./page/overview/contact-type-object-type/contact-type-object-type.page";

export function CdSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'cd/contactTypes', component: ContactTypePage},
        {path: 'cd/contactTypeObjectTypes', component: ContactTypeObjectTypePage},

    ];

    return routes;

}
