<div class="node-container">
    <!-- Complex Node (Button) -->
    <button *ngIf="node?.type === 'complex'" class="complex-node" (click)="focusOnNode(node)">
        {{ node?.label }}
    </button>

    <!-- Simple Nodes as a Grid -->
    <div *ngIf="node?.data?.children?.length && !hasSimpleChildren(node?.data?.children)" class="simple-nodes-grid">
        <div class="simple-node flex flex-column" *ngFor="let child of node?.data?.children">
                <span (mouseenter)="showNodeInfo(child, $event)" (mouseleave)="handleMouseLeave($event)">{{ child?.field?.name }}</span>
                <strong>{{ child?.value }}</strong>
        </div>
    </div>

    <!-- Recursively Render Complex Children -->
    <div *ngIf="hasComplexChildren(node?.children)" class="children-container">
        <em-schema-node-data *ngFor="let child of node?.children" [node]="child" (nodeFocused)="focusOnNode($event)" (onShowNode)="showNodeInfo($event.node,$event.event)" (onMouseLeave)="handleMouseLeave($event)"></em-schema-node-data>
    </div>
</div>