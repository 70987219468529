import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {CustomerContractDto} from "../../../../../../dto/customer-contract.dto";


@Component({
    selector: 'customer-contract-relationships',
    templateUrl: './customer-contract-relationships.component.html',
})
export class CustomerContractRelationshipsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerContractDto: CustomerContractDto;
    initialized: boolean;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,) {

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshInternal();
        this.initialized = true;
    }

    refreshInternal() {

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
