<p-toolbar>
    <div class="p-toolbar-group-start">
    </div>
    <div class="p-toolbar-group-center flex gap-2">
        <p-inputNumber
                [size]="1"
                inputId="integeronly"
                placeholder="1"
                [step]="1"
                min="1"
                [max]="totalPages"
                showButtons="true"
                (onInput)="changePage($event.value)"
        ></p-inputNumber>

        <!-- the error occurs due to totalPages Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'undefined'. Current value: '2'-->
        <ng-container *ngIf="totalPages">
            {{'Total Pages: ' + totalPages}}
        </ng-container>

        <p-button [icon]="fullScreen ? 'fa-regular fa-compress-wide' : 'fa-regular fa-expand-wide'"
                  (click)="handleFullScreen()"></p-button>

        <p-button icon="fa-solid fa-minus" (click)="zoom = zoom - 0.1; handleZoom();"></p-button>
        {{zoom * 100 | number}}
        <p-button icon="fa-solid fa-plus" (click)=" zoom = zoom + 0.1; handleZoom();"></p-button>

    </div>
    <div class="p-toolbar-group-end">
    </div>
</p-toolbar>

<div class="mvs-dm-document-object-wrapper">
    <div #rootElement class="mvs-dm-document-object"></div>
</div>