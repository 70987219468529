import {DtoDetail} from "@kvers/alpha-core-common";

export class TeTemplateArtefactVariableLinkDto extends DtoDetail {

    public templateArtefactDtoId: number;
    public templateArtefactDtoName: string;
    public templateArtefactDtoImage: string;
    public templateArtefactDtoColor: string;
    public artefactVariableDtoId: number;
    public artefactVariableDtoName: string;
    public artefactVariableDtoImage: string;
    public artefactVariableDtoColor: string;
    public templateVariableDtoId: number;
    public templateVariableDtoName: string;
    public templateVariableDtoImage: string;
    public templateVariableDtoColor: string;
    public attributeName: string;
}