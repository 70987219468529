<ng-container *ngIf="initialized">
    <ng-container *ngIf="objectTypesDtoList">
        <p-dropdown
                [options]="objectTypesDtoList.entries"
                [(ngModel)]="selectedObjectType"
                optionLabel="name"
        ></p-dropdown>

        <mvs-search-entity
                [objectType]="selectedObjectType.alias"
                (onObjectSelected)="onObjectSelect($event)"
        ></mvs-search-entity>
    </ng-container>


</ng-container>
