import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ContactTypePage} from "./page/overview/contact-type/contact-type.page";
import {
    ContactTypeObjectTypePage
} from "./page/overview/contact-type-object-type/contact-type-object-type.page";

@NgModule({
    declarations: [
        ContactTypePage,
        ContactTypeObjectTypePage,

    ],
    exports: [
        ContactTypePage,
        ContactTypeObjectTypePage,

    ],
    imports: [
        CoreModule
    ]
})
export class CdModule { }
