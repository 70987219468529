@if (initialized && dto) {
    @if (uiMode == 'side') {

        <ticket-type-object-component-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></ticket-type-object-component-side>
    } @else {

        <ticket-type-object-component-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
                (onBreadcrumbItems)="handleBreadcrumbItems($event)"
        ></ticket-type-object-component-full>
    }
}
