import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiCrudServiceTagsInterface} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class ContractTagService extends MvsCrudService implements MvsApiCrudServiceTagsInterface  {

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/contractTags')
  }

  getTagAttribute(): string {
    return "";
  }

  getTagCategoryAttribute(): string {
    return "";
  }

  hasTagCategory(): boolean {
    return false;
  }
}