import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsCoreService, MvsMessageService, ObjectPageComponent,
    ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry, MvsObjectNavigationService,
    Person, SchedulerData, TransformedEventInterface,
    WidgetFactory
} from "@kvers/alpha-ui";
import {ParamRequest} from "@kvers/alpha-ui/lib/navigation/mvs-object-navigation-entry-param.interface";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {CustomerService} from "../../../cr/service/api/customer.service";
import {AgentDto} from "../../../am/dto/agent.dto";
import {AgentService} from "../../../am/service/api/agent.service";
import {AppointmentService} from "../../service/api/appointment.service";
import {ColorGeneratorService} from "../../service/color-generator.service";
import {ConfirmationService} from "primeng/api";


@Component({
    selector: 'as-customer-appointment-detail-page',
    templateUrl: './as-customer-appointment-detail.page.html',
    styleUrl: "as-customer-appointment-detail.page.scss"
})
export class AsCustomerAppointmentDetailPage extends ObjectPageComponent implements OnInit, OnChanges, OnDestroy {

    customersCoords: { coordinates: [number, number], dto: DtoDetail, initials: string };
    customerWidget: WidgetData;
    customerDto: CustomerDto;

    selectedOption: string;
    options: string[] = ['Schedule Appointment']

    showCalendar: boolean = false;

    agents: AgentDto[];

    // Todo @daud change appointment type id

    selectedAppointmentTypeId: number = 2;

    schedulerData: SchedulerData;

    visible: boolean;


    startDate: Date = new Date(new Date().setHours(0, 0, 0, 0)); // Start of today (midnight)
    endDate: Date = new Date(new Date().setHours(23, 59, 59, 999)); // End of today (23:59:59)

    selectedPeriod: { startTime: Date, endTime: Date }[];


    agentEvents: Record<string, any[]>;


    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService,
        protected navigationService: MvsObjectNavigationService,
        protected agentService: AgentService,
        protected appointmentService: AppointmentService,
        protected colorGeneratorService: ColorGeneratorService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
    ) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    protected getObjectType(): string {
        return 'cr.Customer'
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        // Subscribe to query parameter changes
        this.route.queryParamMap.subscribe((params) => {

            const agentIds = params.getAll('agentIds');

            if (agentIds?.length) {
                const agentIdsFromObjects = new Set(this.agents?.map(agent => agent.id));
                const agentIdsSet = new Set(agentIds.map(Number)); // Directly convert to numbers

                if (agentIdsFromObjects.size !== agentIdsSet.size ||
                    [...agentIdsFromObjects].some(id => !agentIdsSet.has(id))) {
                    this.getAgents(agentIds); // Fetch agents if there's a difference
                }
            }

            this.showCalendar = agentIds && agentIds.length > 0;

            this.refreshCustomer();
        });

        this.refreshCustomer()
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getAgents(agentIds: string[]) {

        let agentIdArray: { id: number }[] = [];
        for (let agentId of agentIds) {
            agentIdArray.push({id: Number(agentId)})
        }

        let filterCriteria = FilterCriteria.createOrFromArray('id', 'id', agentIdArray)


        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);
        this.agentService.list(objectRequest).subscribe(response => {
            this.agents = response.entries;

            for (let agent of this.agents) {
                // Generate random colors for the agent
                const colors = this.colorGeneratorService.generateColorPair();
                agent['color'] = colors.color;
                agent['bgColor'] = colors.bgColor;
            }


            this.fetchAppointments();
        })
    }


    fetchAppointments() {

        // Extract IDs as number[]
        const agentIds: number[] = this.agents.map(agent => agent.id);

        this.appointmentService.getCalendarAppointments(agentIds, this.startDate, this.endDate)
            .subscribe(
                data => {
                    this.agentEvents = data;
                    this.schedulerData = this.transformToSchedulerData(this.agents, data);
                },
                error => {
                    this.schedulerData = null;
                }
            );
    }

    refreshCustomer() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode("person"),
                ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress"))
                // .addNodes(ObjectRequestComplexNode.createRelationNode("customerAddress", ObjectRequestRelation.createJoin("cr.CustomerAddress#customer")))
            ),
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [],
            null
        );


        this.customerService.list(objectRequest).subscribe(res => {
            this.customerDto = res.entries[0];

            this.customerWidget = WidgetFactory.createWidgetTransient(
                'cr.customer.list.transient.widget',
                "Customers",
                'list',
                'transient',
                'entity',
                'cr.Customer',
                res,
            );

            this.geocodeAddress()
        })

    }

    geocodeAddress(): void {


        const addressDto = this.customerDto['mainAddress']

        const address = {
            street: addressDto.street,
            postalCode: addressDto.postalCode,
            city: addressDto.city,
            country: addressDto.country ? addressDto.country : 'Germany'
        };

        // Construct the query for geocoding
        const query = `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`;

        // Use TomTom's Search API to geocode the address
        fetch(
            `https://api.tomtom.com/search/2/geocode/${encodeURIComponent(query)}.json?key=POQwSkANG2wVgN1qMbook38s5EMkN7pG`
        )
            .then(response => response.json())
            .then(data => {
                const position = data.results[0].position;

                this.customersCoords = {
                    coordinates: [position.lon, position.lat],
                    dto: this.customerDto,
                    initials: this.getInitials(this.customerDto.personDtoName)
                };
                if (this.customersCoords) {
                    this.refreshComponent();
                }
            })
            .catch(error => console.error('Error fetching geocode:', error));


    }


    /**
     * Function to extract initials from a full name.
     * If the number of initials exceeds 2, it returns the first 2 initials.
     *
     * @param name - The full name from which to extract initials.
     * @returns A string containing up to 2 initials.
     */
    getInitials(name: string): string {
        if (!name) return '';

        // Split the name into words and filter out any empty strings
        const words = name.split(' ').filter(word => word.length > 0);

        // Map each word to its first letter, then join the first two initials
        const initials = words.map(word => word[0].toUpperCase()).join('');

        // Return only the first two initials
        return initials.substring(0, 2);
    }

    handleOptionChange() {
        this.router.navigate(
            ['/as/customer-appointment-detail', this.objectIdentifier.objectId],
            {
                queryParams: {agentIds: ['157', '5']}
            }
        );
    }

    handleFindSlot(slots: { startTime: Date, endTime: Date }[]) {

        this.selectedPeriod = slots;

        const test = {agentIds: [157]};

        const reportParams: ParamRequest = {

            module: JSON.stringify(test),
            report: null,
            filters: null
        };

        this.visible = true;

    }


    handleEventSelect(event: TransformedEventInterface) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('as.Appointment', event.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any, 'full');
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');

    }

    handleEventMove(event: TransformedEventInterface) {

        this.appointmentService.moveAppointmentDate(event.id, event.start, event.end).subscribe(res => {
            this.fetchAppointments();
        }, error => {
            this.fetchAppointments();
        })

    }


    handleDeleteEvent(event: TransformedEventInterface) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this appointment?',
            header: 'Confirm Deletion',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.appointmentService.deleteAppointment(event.id).subscribe({
                    next: () => {
                        this.fetchAppointments();
                        this.messageService.showSuccessMessage('Delete', 'Successfully deleted');
                    },
                    error: () => {
                        this.fetchAppointments();
                    }
                });
            }
        });
    }


    handleDateRangeChanged(range: { start: Date, end: Date }) {

        this.startDate = range.start;
        this.endDate = range.end;

        this.fetchAppointments();
    }


    transformToSchedulerData(rawAgents: AgentDto[], rawEvents: Record<string, any[]>): SchedulerData {
        const transformedAgents: Person[] = [];

        rawAgents.forEach((agent) => {
            const id = agent['id']; // Get the agent's email
            const agentEvents = rawEvents[id] || []; // Find matching events


            const transformedPerson: Person = {
                id: agent.id,
                name: agent.name,
                color: agent['color'],
                bgColor: agent['bgColor'],
                events: agentEvents.map((event, index) => {

                    const eventData = event?.appointmentEvent?.event;
                    const appointmentData = event?.appointmentEvent?.appointment;

                    const customer = {id: event?.customer?.id, name: event?.customer?.calculatedName};

                    return {
                        id: appointmentData?.id || eventData?.eventId, // Generate a unique ID for the event
                        title: eventData?.subject, // Placeholder title
                        start: new Date(
                            `${eventData?.startAsLocalDateTime}Z`
                            || appointmentData?.startTime
                        ),
                        end: new Date(
                            `${eventData?.endAsLocalDateTime}Z`
                            || appointmentData?.endTime
                        ),
                        preBufferTime: event?.appointmentEvent?.preBufferTime || "PT0M",
                        postBufferTime: event?.appointmentEvent?.postBufferTime || "PT0M",
                        location: eventData?.location?.displayName || "Unknown Location",
                        isOnlineMeeting: eventData?.isOnlineMeeting || false,
                        status: appointmentData?.statusEnumName || "Unknown",
                        color: appointmentData?.statusEnumColor || "gray-800",
                        bgColor: appointmentData?.statusEnumColorBackground || "gray-200",
                        isFreeSlot: false,
                        customer: customer

                    }
                })
            };

            transformedAgents.push(transformedPerson);
        });

        return {agents: transformedAgents};
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
