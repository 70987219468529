import {Component, OnInit} from "@angular/core";
import {IntegrationAdapterTypeBaseComponent} from "../integration-adapter-type-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'hc-integration-adapter-side-component',
    templateUrl: 'integration-adapter-type-side.component.html',
    styleUrls: ['integration-adapter-type-side.component.scss'],
})

export class IntegrationAdapterTypeSideComponent extends IntegrationAdapterTypeBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'adapterTypeVariable',
            action: 'adapterTypeVariable',
            tooltip: 'Adapter Type Variable',
            icon: 'fa-solid fa-cogs',
            toggleable: false,
            default: true
        },
        {
            label: 'adapterType',
            action: 'adapterType',
            tooltip: 'Adapter Type',
            icon: 'fa fa-info-circle',
            toggleable: false,
            default: false
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}