import {Route, Routes} from "@angular/router";
import {TestWidgetPage} from "./page/overview/test-widget/test-widget.page";
import {TestFormPage} from "./page/overview/test-form/test-form.page";
import {TestTemplatingPage} from "./page/overview/test-templating/test-templating.page";
import {TestDesignsPage} from "./page/overview/test-designs/test-designs.page";
import {TestMarkoPage} from "./page/overview/test-marko/test-marko.page";
import {TestDaudPage} from "./page/overview/test-daud/test-daud.page";
import {TestHarisPage} from "./page/overview/test-haris/test-haris.page";
import {TestYasirPage} from "./page/overview/test-yasir/test-yasir.page";
import {TestAtherPage} from "./page/overview/test-ather/test-ather.page";
import {TestWorkflowPage} from "./page/overview/test-workflow/test-workflow.page";
import {TestAddressPage} from "./page/overview/test-address/test-address.page";
import {TestBpmnPage} from "./page/overview/test-bpmn/test-bpmn.page";
import {TestWorkflowActivitiesPage} from "./page/overview/test-workflow-activities/test-workflow-activities.page";
import {TestEditorPage} from "./page/overview/test-editor/test-editor.page";

export function TestSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        {path: 'test/widgets', component: TestWidgetPage},
        {path: 'test/editor', component: TestEditorPage},
        {path: 'test/form', component: TestFormPage},
        {path: 'test/template', component: TestTemplatingPage},
        {path: 'test/design', component: TestDesignsPage},
        {path: 'test/marko', component: TestMarkoPage},
        {path: 'test/haris', component: TestHarisPage},
        {path: 'test/daud', component: TestDaudPage},
        {path: 'test/yasir', component: TestYasirPage},
        {path: 'test/ather', component: TestAtherPage},
        {path: 'test/workflow', component: TestWorkflowPage},
        {path: 'test/address', component: TestAddressPage},
        {path: 'test/bpmn', component: TestBpmnPage},
        {path: 'test/activity', component: TestWorkflowActivitiesPage},


    ];

    return routes;

}
