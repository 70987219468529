<div class="grid">
    <div class="col-3">
        <mvs-object-type
                (onObjectTypeChange)="handleObjectType($event)"
                [showValidation]="false"
        ></mvs-object-type>
    </div>

    <div class="col-9">
        <mvs-form-field-flexible-search
                *ngIf="objectType"
                class="custom-autocomplete"
                [objectTypeAlias]="objectType.alias"
                [formGroup]="formGroup"
                [formField]="formField"
                (onObjectSelect)="handleObjectSelect($event)">
        </mvs-form-field-flexible-search>
    </div>
</div>