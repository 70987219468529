import {Component, OnInit} from '@angular/core';
import {ObjectBaseModeComponent, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {HgPartnerDto} from "../../../dto/hg-partner.dto";
import {ActivatedRoute} from "@angular/router";
import {HgPartnerService} from "../../../service/api/hg-partner.service";


@Component({
    selector: 'hg-gdv-partner-base',
    template: '',
})
export class HgGdvPartnerBaseComponent extends ObjectBaseModeComponent implements OnInit {

    basicWidgetData: WidgetData;
    dto: HgPartnerDto;

    busy: boolean;


    constructor(
        protected route: ActivatedRoute,
        protected hgPartnerService: HgPartnerService,
    ) {
        super(route);
    }

    ngOnInit(): void {

        if (this.navigationItems?.length) {
            this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            this.onNavigationItems.emit(this.navigationItems);
        }


        super.ngOnInit();
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.basicWidgetData = WidgetFactory.createWidgetEntityData(
            'hg.gdv.partner.basic.widget.data',
            'GDV Partner',
            'hg.HgPartner',
            this.objectIdentifier.objectId
        );
    }

    generateSchema() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.hgPartnerService.generateSchema(this.objectIdentifier.objectId).subscribe(res => {
            this.busy = false;
        }, error => {
            this.busy = false;
        });
    }



}
