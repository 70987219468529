import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterCriteriaChangeEvent} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {FilterAttributeGroup} from "@kvers/alpha-core-common";
import {FilterCriteriaUi} from "@kvers/alpha-core-common";
import {FilterCriteriaUiType} from "@kvers/alpha-core-common";
import {TreeDragDropService, TreeNode,} from "primeng/api";
import {TableAttributes} from "../../te-editor.component";
import {MetaService} from "@kvers/alpha-core-common";
import {ConditionsHelper} from "./conditions-helper/conditions-helper";

interface EditorConditions {
    attribute: string,
    operator: string,
    value: any
}

export interface TableInfoConditionsEvent {
    condition: string;
    tableAttributes: TableAttributes[];
}

@Component({
    selector: 'te-table-info-conditions',
    templateUrl: './table-info-conditions.component.html',
})
export class TableInfoConditionsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() tableAttributes: TableAttributes[];
    @Input() condition: string;
    @Input() attributePath: string;
    @Input() tableName: string;
    @Input() parentObjectTypeName: string;
    @Input() conditionType: 'inline' | 'table' = 'table';
    @Output() handleTableCondition: EventEmitter<TableInfoConditionsEvent> = new EventEmitter<TableInfoConditionsEvent>;

    clonedTableAttributes: TableAttributes[];
    tableConditions: EditorConditions[];
    valid: boolean = false;
    hasConditions: boolean = false;
    stringFilterCriteria: string;
    changedFilterCriteria: FilterCriteria[];
    filterCriteria: FilterCriteriaUi[] = [];
    filterObjectTypeIds: number[];
    filterAttributeGroups: FilterAttributeGroup[];
    operationItems = [
        {
            label: "EQ",
            template: ' == ',
        },
        {
            label: "NE",
            template: ' != ',
        },
        {
            label: "LT",
            template: ' lt ',
        },
        {
            label: "LE",
            template: ' lte ',
        },
        {
            label: "GT",
            template: ' gt ',
        },
        {
            label: "GE",
            template: ' gte ',
        },
        {
            label: "AND",
            template: ' && ',
        },
        {
            label: "OR",
            template: ' || ',
        },
    ];

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected treeDragDropService: TreeDragDropService, protected metaService: MetaService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.clonedTableAttributes = structuredClone(this.tableAttributes);

        if (this.clonedTableAttributes && this.clonedTableAttributes.length) {
            this.preparePathsForAttributes();
        }

    }

    preparePathsForAttributes() {
        for (let item of this.clonedTableAttributes) {
            let subPath = ConditionsHelper.extractSubPath(item.value, this.attributePath);

            if (subPath.includes('metaJoin_')) {
                subPath = subPath.replace('metaJoin_', '');
            }
            item.subPath = subPath;

        }
    }



    /**
     * Initialize Component.
     */
    initComponent() {

        this.treeDragDropService.dragStop$.subscribe((dragAttribute) => {
            if (dragAttribute.node && dragAttribute.node['uiType'] == 'conditions') {

                const label = dragAttribute.node.label;
                const attributeItemPath = this.getCompleteAttributePath(dragAttribute.node);
                const fullPath = this.attributePath + attributeItemPath
                const fullPathSyntax = document.createTextNode(`${fullPath}`);
                const nullHandler: string = `!''`;
                const finalPath = '${(' + fullPathSyntax.textContent + ')' + nullHandler + '}';
                const subPath = ConditionsHelper.extractSubPath(finalPath, this.attributePath);

                // prepare attribute to push into attribute list
                const attribute: TableAttributes = {
                    attribute: label,
                    value: finalPath,
                    subPath: subPath
                };
                this.clonedTableAttributes.push(attribute);
            }
        });

        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        //check if any existing condition is added in the table
        if (this.condition) {
            this.tableConditions = this.convertStringToConditions(this.condition);

            for (let item of this.tableConditions) {
                const operation = this.operationItems.find(value => value.template == item.operator);
                const filter: FilterCriteriaUi = FilterCriteriaUi.createUi(item.attribute, item.attribute, operation.label, item.value, null, 0 , FilterCriteriaUiType.condition);
                this.filterCriteria.push(filter);
            }

            this.hasConditions = true;
        }

        this.initialized = true;
    }

    handleApplyConditions() {

        const finalCondition = this.constructConditionString();

        const event: TableInfoConditionsEvent = {
            condition: finalCondition,
            tableAttributes: this.clonedTableAttributes,
        }

        this.handleTableCondition.emit(event);
    }

    constructConditionString(): string {
        let finalCondition: string = '';

        // const freemarkerSyntax = this.convertToFreemarkerSyntax(this.stringFilterCriteria);
        const freemarkerSyntax = this.convertToFreemarkerSyntax(this.stringFilterCriteria);
        if (freemarkerSyntax.trim() == '') {
            //do nothing
        } else {
            if (this.conditionType == 'table') {
                finalCondition = `<#if ${freemarkerSyntax} >`;
            } else {
                finalCondition = `${freemarkerSyntax}`;
            }

        }
        return finalCondition;
    }

    // convertToFreemarkerSyntax(input: string): string {
    //
    //     if (!this.changedFilterCriteria) {
    //         return '';
    //     }
    //
    //     // Define a function to escape regex special characters in field names
    //     const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    //
    //     // Process each criterion
    //     this.changedFilterCriteria.forEach(criterion => {
    //         const fieldRegexPart = escapeRegExp(criterion.field);
    //         // Construct regex to match the exact field name ensuring it does not form part of another field name
    //         // The regex needs to match exactly the full field name either standalone or exactly after a dot (.)
    //         const regex = new RegExp(`(?<!\\w\\.)\\b${fieldRegexPart}\\b(?!\\w)`, 'g');
    //
    //         // Perform replacement
    //         let fieldName = criterion.field;
    //         const typeOfValue = typeof(criterion.value);
    //         if (typeOfValue == 'number') {
    //            fieldName = `${fieldName}?number`;
    //         }
    //         input = input.replace(regex, `${this.attributePath}.${fieldName}`);
    //     });
    //
    //
    //     // Step 2: Replace operators using templates from operationItems
    //     this.operationItems.forEach(item => {
    //         const regex = new RegExp(`\\b${item.label}\\b`, 'g'); // Match the operation label as a whole word
    //         input = input.replace(regex, item.template);
    //     });
    //
    //     input = input.replace(/(==|!=|>|<|>=|<=)\s*([a-zA-Z_]\w*)/g, '$1 \'$2\''); // Quotes identifiers after comparison operators
    //
    //     return input;
    // }


    convertToFreemarkerSyntax(input: string): string {
        if (!this.changedFilterCriteria) {
            return '';
        }

        const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

        this.changedFilterCriteria.forEach(criterion => {
            const fieldRegexPart = escapeRegExp(criterion.field);
            const regex = new RegExp(`(?<!\\w\\.)\\b${fieldRegexPart}\\b(?!\\w)`, 'g');

            let fieldName = criterion.field;
            const typeOfValue = typeof(criterion.value);
            if (typeOfValue == 'number') {
                fieldName += '?number';
            }

            let fullPathWithChecks: string;

            if (this.attributePath) {
                fullPathWithChecks = ConditionsHelper.insertNullChecks(`${this.attributePath}.${fieldName}`);
            } else {
                fullPathWithChecks = ConditionsHelper.insertNullChecks(`${fieldName}`);
            }

            input = input.replace(regex, fullPathWithChecks);
        });

        this.operationItems.forEach(item => {
            const regex = new RegExp(`\\b${item.label}\\b`, 'g');
            input = input.replace(regex, item.template);
        });

        input = input.replace(/(==|!=|>|<|>=|<=)\s*([a-zA-Z_]\w*)/g, '$1 \'$2\'');

        return input;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["attributesList"] || changes["tableCondition"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    convertStringToConditions(freeMarkerString: string): any[] {
        let conditionsStr = freeMarkerString.replace(/<#if\s+|\s+<\/#if>/g, '').trim();
        let conditions = conditionsStr.split(/\s+\|\|\s+|\s+&&\s+/);

        return conditions.map(condition => {
            let formattedCondition = condition.trim().replace(/^\(|\)|>$/g, '').trim();

            for (let item of this.operationItems) {
                let operatorRegex = new RegExp("\\s*" + item.template.trim() + "\\s*");
                if (formattedCondition.match(operatorRegex)) {
                    let parts = formattedCondition.split(operatorRegex);

                    if (parts.length === 2) {
                        let attributeParts = parts[0].trim().split('.');
                        let fullAttribute = parts[0].trim();
                        let attributeName = attributeParts.join('.');

                        // Remove the common attributePath prefix if it exists
                        if (attributeName.startsWith(this.attributePath + '.')) {
                            attributeName = attributeName.substring(this.attributePath.length + 1);
                        }

                        let isInteger: boolean = false;
                        const integerPaths = attributeName.split('?');
                        // check if it has ?number identifier
                        if (integerPaths.length == 1) {
                            // do nothing
                        } else {
                            attributeName = integerPaths[0];
                            isInteger = true;
                        }

                        let value: any;

                        if (isInteger) {
                            value = +parts[1];
                        } else {
                            const valueMatch = parts[1].trim().match(/^'([^']*)'/);
                            value = valueMatch ? valueMatch[1] : '';
                        }

                        return {
                            attribute: attributeName,
                            attributePath: this.attributePath,  // Assuming attributePath is common and known
                            fullAttribute: fullAttribute,
                            operator: item.template,
                            value: value,
                        };
                    }
                }
            }

            return {};
        }).filter(condition => Object.keys(condition).length > 0);
    }

    handleFilterChange(event: FilterCriteriaChangeEvent) {
        this.stringFilterCriteria = event.filterCriteriaRoot.toString();
        this.changedFilterCriteria = event.filterCriteria;
        this.valid = event.valid;
    }

    getCompleteAttributePath(dragAttribute: TreeNode) {
        let variableName = "";

        const aliasFromNode = this.getAliasFromNode(dragAttribute);
        variableName = aliasFromNode;

        let node = dragAttribute?.parent;
        while (node) {

            const aliasFromSubNode = this.getAliasFromNode(node);
            variableName = aliasFromSubNode + "." + variableName;

            node = node.parent;

        }

        return variableName;
    }

    private getAliasFromNode(node: TreeNode): string {

        if (!node || !node.data) {
            return "";
        }

        if (node.data["attributeName"]) {

            if ((node.data["attributeName"] as string).charAt(0) == "+") {
                return "metaAttribute_" + (node.data["attributeName"] as string).substring(1);
            }
            return node.data["attributeName"];

        } else if (node.data["alias"]) {
            return node.data["alias"];

        } else if (node.data["name"]) {

            let join = node.data["name"];
            join = join.replace('#', '_attr_');
            join = join.replace('.', '_');

            if ((join as string).charAt(0) == "+") {
                return "metaJoin_" + (join as string).substring(1);
            }
            return join;

        } else {

        }

        return "";

    }


    onRemoveAttribute(index: number) {
        if (index > -1) {
            this.clonedTableAttributes.splice(index, 1);
        }
    }

}
