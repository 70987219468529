import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService, PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {ChartData} from "chart.js";
import {testData} from "./data/test-data";

@Component({
    selector: 'ticket-controlling',
    templateUrl: './ticket-controlling.component.html',
})
export class TicketControllingComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    ticketData = testData;

    chartData!: ChartData<'bar'>;
    isGrouped: boolean = false;
    chartOptions: any;


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.transformChartData();
        this.chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                }
            },
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true
                }
            }
        };
        this.initialized = true;
    }

    transformChartData() {
        const groupedData: { [date: string]: { [status: string]: number } } = {};
        const uniqueStatuses: Set<string> = new Set();
        const pendingStatuses = new Set(['draft', 'on_hold']);
        const workingStatuses = new Set(['assigned', 'working', 'awaiting_customer', 'awaiting_partner', 'resolved', 'cancelled']);

        for (const ticket of this.ticketData) {
            const status = this.isGrouped ? (pendingStatuses.has(ticket.ticket_status) ? 'Pending' : 'Working') : ticket.ticket_status;

            if (!groupedData[ticket.date]) {
                groupedData[ticket.date] = {};
            }
            if (!groupedData[ticket.date][status]) {
                groupedData[ticket.date][status] = 0;
            }
            groupedData[ticket.date][status] += ticket.ticket_count;
            uniqueStatuses.add(status);
        }

        const labels = Object.keys(groupedData).sort();

        const datasets = Array.from(uniqueStatuses).map(status => ({
            label: status,
            data: labels.map(date => groupedData[date][status] || 0),
            backgroundColor: this.getColorForStatus(status.toLowerCase()),
        }));

        this.chartData = { labels, datasets };
    }

    toggleGrouping() {
        this.isGrouped = !this.isGrouped;
        this.transformChartData();
    }

    getColorForStatus(status: string): string {
        const colors: { [key: string]: string } = {
            assigned: 'blue',
            working: 'orange',
            draft: 'yellow',
            awaiting_customer: 'red',
            awaiting_partner: 'purple',
            resolved: 'green',
            on_hold: 'gray',
            cancelled: 'black'
        };
        return colors[status] || 'black';
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
