import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ObjectRequestList } from "@kvers/alpha-core-common";
import { FilterCriteria } from "@kvers/alpha-core-common";
import { Sorting } from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-te-test-case-setup',
  templateUrl: './te-test-case-setup.component.html',
  styleUrls: ['./te-test-case-setup.component.scss']
})
export class TeTestCaseSetupComponent implements OnInit, OnChanges, OnDestroy {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized


  widgetTestCases: WidgetData;
  widgetTestCaseVariables: WidgetData;

  importContext: DtoImportObjectContext;

  ngOnInit(): void {
    this.initComponent();
  }

  /**
   * Initialize Component.
   */
  initComponent() {

    //create widget for test cases
    // this.widgetTestCases = WidgetFactory.createWidgetListEntity(
    //     "te.test.case.setup.test.cases",
    //     "Test Cases",
    //     "te.TeTestCase",
    //     "Keine Test Cases vorhanden",
    //     ObjectRequestList.createBasic(true, null, [new Sorting("name", true)])
    // );

    this.widgetTestCases = WidgetFactory.createWidgetList(
        'te.test.case.setup.test.cases',
        "Test Cases",
        'selectable',
        'list',
        'entity',
        'te.TeTestCase',
        'Keine Test Cases vorhanden',
        ObjectRequestList.createBasic(true, null, [new Sorting("name", true)])
    );

    this.initialized = true;

  }

  handleTestCaseSelect(event: ObjectIdentifier) {

    this.importContext = DtoImportObjectContext.createFromObjectIdentifier(event);

    this.widgetTestCaseVariables = WidgetFactory.createWidgetListEntity(
        "te.test.case.setup.test.case.variables",
        "Variablen",
        "te.TeTestCaseVariable",
        "Keine Variablen gefunden",
        ObjectRequestList.createBasic(
            true,
            [FilterCriteria.create("testCase", FilterCriteria.cOperatorEqual, event.objectId)],
            [new Sorting("name", true)]
        )
    );

  }



  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }
}
