import {DtoDetailConfigurable} from "@kvers/alpha-core-common";
import { ContractPersonTypeDto } from "./contract-person-type.dto";
import {ContractDto} from "../../cm/dto/contract.dto";
import {PmPersonDto} from "../../cr/dto/pm-person.dto";

export class ContractPersonDto extends DtoDetailConfigurable{


    public contractDto? : ContractDto;
    public contractDtoId : number;
    public contractDtoName : string;
    public personDto? : PmPersonDto;
    public personDtoId : number;
    public personDtoName : string;
    public startDate: Date;
    public endDate: Date;
    public plannedEndDate: Date;
    public contractPersonTypeDto? : ContractPersonTypeDto;
    public contractPersonTypeDtoId : number;
    public contractPersonTypeDtoName : string;
    public owner: boolean;
    public insured: boolean;

}