import {TicketActionDto} from "../../../../../dto/ticket-action.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {TicketActionStatusInternal} from "../../../../../enum/ticket-action-status-internal.enum";

export class UiTicketActionDto {
    ticketAction: TicketActionDto;
    showComments: boolean;
    icon: string;
    buttonClass: string;
    tooltip: string;
    status: string;
    statusEnum:  TicketActionStatusInternal;
    statusColor: string;
    statusBackgroundColor: string;
    commentsIdentifier: ObjectIdentifier;
    isProcess: boolean;
    actionIcon: string;
    isTicket: boolean;
    isNotification: boolean;
    isAppointment: boolean;
    isStarted: boolean;
    showSimple: boolean;
}