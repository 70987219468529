import {Component, OnInit} from '@angular/core';
import {MvsFormDto, MvsFormFieldDto} from "@kvers/alpha-core-common";
import {FormControl, FormGroup} from "@angular/forms";
import {MvsFormFieldOutputConfigBaseComponent} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-one-to-many-output-config-component',
    templateUrl: 'mvs-one-to-many-output-config.component.html',
})
export class MvsOneToManyOutputConfigComponent extends MvsFormFieldOutputConfigBaseComponent implements OnInit {


    objectId: number;
    form: MvsFormDto

    fieldTypes: string[];
    formFieldsArray: MvsFormFieldDto[];
    filteredFormFields: MvsFormFieldDto[] = [];
    initialized: boolean;


    ngOnInit(): void {

        this.fieldTypes = Object.keys(this.form.subForms);
        this.formFieldsArray = Object.values(this.formFields);
        this.filteredFormFields = [...this.formFieldsArray]; // Initially, all fields are available

        this.initializeUiConfigData();
        this.initialized = true;

        // Listen to fieldType changes and filter formFieldsArray
        this.widgetUiField.get('uiConfigData.fieldType')?.valueChanges.subscribe((selectedFieldType) => {
            this.onFieldTypeChange(selectedFieldType);
        });

    }

    onFieldTypeChange(selectedFieldType: string): void {
        const uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        const outputFieldControl = uiConfigData.get('outputField');

        const excludeSuffixes = ["DtoImage", "DtoColor", "DtoColorBackground", "createdDate", "createdBy", "lastModifiedDate", "lastModifiedBy"];


        if (selectedFieldType) {
            // Enable outputField
            outputFieldControl?.enable();

            // Filter formFieldsArray using `id` values from selected subForm
            const subForm: MvsFormDto = this.form.subForms[selectedFieldType];
            if (subForm) {
                // Extract allowed field IDs and prefix them with fieldType
                const allowedFieldIds = Object.entries(subForm.formFields)
                    .filter(([key]) => !excludeSuffixes.some(suffix => key.endsWith(suffix)))
                    .map(([key]) => `${selectedFieldType}#${key}`); // Prefix with fieldType

                this.filteredFormFields = this.formFieldsArray.filter(field => allowedFieldIds.includes(field.absoluteId));
            } else {
                this.filteredFormFields = [];
            }
        } else {
            // Disable and reset outputField if no fieldType is selected
            outputFieldControl?.setValue(null);
            outputFieldControl?.disable();
            this.filteredFormFields = [...this.formFieldsArray];
        }
    }


    initializeUiConfigData(): void {

        let uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        if (!uiConfigData) {
            uiConfigData = new FormGroup({});
            this.widgetUiField.addControl('uiConfigData', uiConfigData);
        }

        // Ensure outputField control exists
        if (!uiConfigData.get('fieldType')) {
            uiConfigData.addControl('fieldType', new FormControl(null));
        }

        // Ensure outputField control exists
        if (!uiConfigData.get('outputField')) {
            uiConfigData.addControl('outputField', new FormControl({value: null, disabled: true}));
        }


    }

}
