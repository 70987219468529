import {Route, Routes} from "@angular/router";
import {AdConfigPage} from "./page/overview/ad-config/ad-config.page";
import {AdOverviewPage} from "./page/ad-overview-page/ad-overview.page";

export function AdSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'ad', component: AdOverviewPage},
        {path: 'ad/config', component: AdConfigPage},

    ];

    return routes;

}
