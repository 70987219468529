import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cr-customer-notification-type-page',
  templateUrl: './customer-interaction-type.page.html',
  styleUrls: ['./customer-interaction-type.page.scss']
})
export class CustomerInteractionTypePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Customer Interaction Config";


  ngOnInit(): void {

    super.ngOnInit();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerInteractionType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Interaction Type",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neue Interaktion anlegen"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerInteractionTypeInfoStep',
          'level'             : 1,
          'parentBinding'     : 'none',
          'parentId'          : 1,
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Info Steps",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Create Info Step"
        },
      ]
    };

    this.subObjectBrowserRequest = null;

  }
}
