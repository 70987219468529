import {
    MvsFormFieldAgentPoolAgentComponent
} from "../input/mvs-form-field-agent-pool-agent/mvs-form-field-agent-pool-agent.component";
import {
    MvsUserObjectAccessOutputComponent
} from "../output/mvs-user-object-access-output/mvs-user-object-access-output.component";
import {
    MvsUserObjectAccessOutputConfigComponent
} from "../output/mvs-user-object-access-output/mvs-user-object-access-output-config/mvs-user-object-access-output-config.component";
import {MvsTicketCountOutputComponent} from "../output/mvs-user-ticket-count-output/mvs-ticket-count-output.component";
import {
    MvsTicketCountOutputConfigComponent
} from "../output/mvs-user-ticket-count-output/mvs-ticket-count-output-config/mvs-ticket-count-output-config.component";
import {
    MvsFormFieldOutputUserImageComponent
} from "../output/mvs-form-field-output-user-image/mvs-form-field-output-user-image.component";
import {
    MvsCreateTicketOutputConfigComponent
} from "../output/mvs-create-ticket-output/mvs-create-ticket-output-config/mvs-create-ticket-output-config.component";
import {MvsCreateTicketOutputComponent} from "../output/mvs-create-ticket-output/mvs-create-ticket-output.component";

export const additionalInputFields = new Map<string, { component: any }>([
    ['agentPoolAgent', { component: MvsFormFieldAgentPoolAgentComponent }],
]);

export const additionalOutputFields = new Map<string, { component: any, label: string, visibility?: string, configComponent?: any }>([

    ['userObjectAccess', {component: MvsUserObjectAccessOutputComponent, label: "User ObjectAccess Output", configComponent: MvsUserObjectAccessOutputConfigComponent}],
    ['ticketCount', {component: MvsTicketCountOutputComponent, label: "Ticket Count", configComponent: MvsTicketCountOutputConfigComponent}],
    ['createTicket', {component: MvsCreateTicketOutputComponent, label: "Create Ticket", configComponent: MvsCreateTicketOutputConfigComponent}],
    ['userImage', {component: MvsFormFieldOutputUserImageComponent, label: "User Image"}],
    ['userInitials', {component: MvsFormFieldOutputUserImageComponent, label: "User Initials"}],
]);