import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {NavigationItem} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-dm-document-type-page',
  templateUrl: './dm-config.page.html',
  styleUrls: ['./dm-config.page.scss']
})
export class DmConfigPage extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Document Configuration";

  dmDocumentStorageWidget: WidgetData;
  dmFormRecognizerTypeWidget: WidgetData;
  dmMimeTypeWidget: WidgetData;
  dmDocumentGroupWidget: WidgetData;
  dmDocumentTypeWidget: WidgetData;
  dmDocumentTypeMimeTypeWidget: WidgetData;
  dmDashboardWidget: WidgetData;
  dmAssignmentTypeWidget: WidgetData;
  dmAssignmentTypeObjectTypeWidget: WidgetData;

  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;
  activeNavItem: string;
  navigationItems: NavigationItem[] = [
    { label: 'basicConfig', tooltip: 'Basic Configuration', icon: 'fa fa-regular fa-cogs', toggleable: false, default: false },
    { label: 'documentType', tooltip: 'Document Types', icon: 'fa fa-regular fa-file-alt', toggleable: false, default: false },
    { label: 'assignments', tooltip: 'Assignments', icon: 'fa fa-regular fa-tasks', toggleable: false, default: false },
    { label: 'dashboards', tooltip: 'Dashboards', icon: 'fa fa-regular fa-chart-bar', toggleable: false, default: false },
  ];

  widgetEntries: TabEntries[] = [
    {label: 'Storage Type', id: 'dm.DmDocumentStorage', type: 'basicConfig'},
    {label: 'Form Recognizer Type', id: 'dm.DmFormRecognizerType', type: 'basicConfig'},
    {label: 'Mime Types', id: 'dm.DmMimeType', type: 'basicConfig'},
    {label: 'Dokumentgruppen', id: 'dm.DmDocumentGroup', type: ''},
    {label: 'Dokumenttypen', id: 'dm.DmDocumentType', type: ''},
    {label: 'Mime Types', id: 'dm.DmDocumentTypeMimeType', type: ''},
    {label: 'Dashboards', id: 'dm.DmDashboard', type: 'dashboards'},
    {label: 'Zuordnungstyp', id: 'dm.DmAssignmentType', type: 'assignments'},
    {label: 'Objekttypen zu Zuordnungstypen', id: 'dm.DmAssignmentTypeObjectType', type: 'assignments'},
  ];
  uiWidgetEntries: TabEntries[];

  constructor(
      protected route: ActivatedRoute,
      protected router: Router,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();

    // Main Structure
    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'dm.DmDocumentStorage',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Storage Type",
          'dummyParentDescription' : "Unter diesem Menüpunkt können die Storage Types angelegt und verwaltet werden.",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Storage Type anlegen"
        },
        {
          'id'                : 2,
          'objectAlias'       : 'dm.DmFormRecognizerType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Form Recognizer Type",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Form Recognizer anlegen"
        },
        {
          'id'                : 3,
          'objectAlias'       : 'dm.DmMimeType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Mime Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Mime Type anlegen"
        },
        {
          'id'                : 4,
          'objectAlias'       : 'dm.DmDocumentGroup',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Dokumentgruppen",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neue Gruppe anlegen"
        },
        {
          'id'                : 5,
          'objectAlias'       : 'dm.DmDocumentType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Dokumenttypen",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Typ anlegen"
        },
        {
          'id'                : 6,
          'objectAlias'       : 'dm.DmDocumentTypeMimeType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Mime Types",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Mime Type hinzufügen"
        },
        {
          'id'                : 7,
          'objectAlias'       : 'dm.DmDashboard',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Dashboards",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neues Dashboard anlegen"
        },
        {
          'id'                : 8,
          'objectAlias'       : 'dm.DmAssignmentType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Zuordnungstyp",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Zuordnungstyp anlegen"
        },
        {
          'id'                : 9,
          'objectAlias'       : 'dm.DmAssignmentTypeObjectType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Objekttypen zu Zuordnungstypen",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neuen Object Typ anlegen"
        },
      ]
    };

    this.subObjectBrowserRequest = null;
  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      this.initialized = false;
      let objectType = params['tid'];
      if (params['navItem']) {
        if (this.activeNavItem && this.activeNavItem !== params['navItem']) {
          this.refreshWidgets();
          objectType = this.getFirstActiveWidget(params['navItem'])?.id;
          this.router.navigate([], {
            queryParams: { tid: objectType, 'tid-action': 2 },
            queryParamsHandling: 'merge'
          });
          this.activeTabIndex = 0;
          this.activeWidget = this.uiWidgetEntries[0];
        }
        this.activeNavItem = params['navItem'];
        this.uiWidgetEntries = this.getRelevantGroupedEntries(this.activeNavItem);
        if (!objectType) {
          this.activeTabIndex = 0;
          this.activeWidget = this.uiWidgetEntries[0];
        }

      }

      if (objectType) {
        const object = this.uiWidgetEntries.find(item => item.id === objectType);
        if (object) {
          this.activeTabIndex = this.uiWidgetEntries.findIndex(item => item.id === object.id);
          this.activeWidget = object;
        }
      }

      //should be solved after primeng library update (tabPanel not resetting properly)
      setTimeout(() => {
        this.initialized = true;
      },10);

    });
  }

  getFirstActiveWidget(currentNavItem: string) {
    const relevantEntries = this.getRelevantGroupedEntries(currentNavItem);
    if (relevantEntries) {
      return relevantEntries[0];
    }
    return null;
  }

  getRelevantGroupedEntries(currentNavItem: string) {
    return this.widgetEntries.filter(item => item.type === currentNavItem);
  }

  refreshWidgets() {
    this.refreshDmDocumentStorageWidget();
    this.refreshDmFormRecognizerTypeWidget();
    this.refreshDmMimeTypeWidget();
    this.refreshDmDocumentGroupWidget();
    this.refreshDmDashboardWidget();
    this.refreshDmAssignmentTypeWidget();
    this.refreshDmAssignmentTypeObjectTypeWidget();
    this.refreshDmDocumentTypeWidget(null);
    // this.refreshDmDocumentTypeMimeTypeWidget(null);
  }

  refreshDmDocumentStorageWidget() {
    this.dmDocumentStorageWidget = WidgetFactory.createWidgetList(
        "dm.document.storage.widget.simple",
        'Storage Type',
        'table',
        'list',
        'entity',
        'dm.DmDocumentStorage',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshDmFormRecognizerTypeWidget() {
    this.dmFormRecognizerTypeWidget = WidgetFactory.createWidgetList(
        "dm.form.recognizer.type.widget.simple",
        'Form Recognizer Type',
        'table',
        'list',
        'entity',
        'dm.DmFormRecognizerType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshDmMimeTypeWidget() {
    this.dmMimeTypeWidget = WidgetFactory.createWidgetList(
        "dm.mime.type.widget.simple",
        'Mime Types',
        'table',
        'list',
        'entity',
        'dm.DmMimeType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }
  refreshDmDocumentGroupWidget() {
    this.dmDocumentGroupWidget = WidgetFactory.createWidgetList(
        "dm.document.group.widget.simple",
        'Dokumentgruppen',
        'selectable',
        'list',
        'entity',
        'dm.DmDocumentGroup',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }
  refreshDmDocumentTypeWidget(filter: FilterCriteria[]) {
    this.dmDocumentTypeWidget = WidgetFactory.createWidgetList(
        "dm.document.type.widget.simple",
        'Dokumenttypen',
        'table',
        'list',
        'entity',
        'dm.DmDocumentType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 10))
    );
  }
  refreshDmDocumentTypeMimeTypeWidget(filter: FilterCriteria[]) {
    this.dmDocumentTypeMimeTypeWidget = WidgetFactory.createWidgetList(
        "dm.document.type.mime.type.widget.simple",
        'Mime Types',
        'table',
        'list',
        'entity',
        'dm.DmDocumentTypeMimeType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 10))
    );
  }
  refreshDmDashboardWidget() {
    this.dmDashboardWidget = WidgetFactory.createWidgetList(
        "dm.dashboard.widget.simple",
        'Dashboards',
        'table',
        'list',
        'entity',
        'dm.DmDashboard',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }
  refreshDmAssignmentTypeWidget() {
    this.dmAssignmentTypeWidget = WidgetFactory.createWidgetList(
        "dm.assignnment.type.widget.simple",
        'Zuordnungstyp',
        'table',
        'list',
        'entity',
        'dm.DmAssignmentType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }
  refreshDmAssignmentTypeObjectTypeWidget() {
    this.dmAssignmentTypeObjectTypeWidget = WidgetFactory.createWidgetList(
        "dm.assignment.type.object.type.widget.simple",
        'Objekttypen zu Zuordnungstypen',
        'table',
        'list',
        'entity',
        'dm.DmAssignmentTypeObjectType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.uiWidgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleDocumentGroupSelect(event: ObjectIdentifierData) {
    const filter = FilterCriteria.create('documentGroup', FilterCriteria.cOperatorEqual, event.objectId);
    this.refreshDmDocumentTypeWidget([filter]);
    this.dmDocumentTypeMimeTypeWidget = null;

  }
  handleDocumentTypeSelect(event: ObjectIdentifierData) {
    const filter = FilterCriteria.create('documentType', FilterCriteria.cOperatorEqual, event.objectId);
    this.refreshDmDocumentTypeMimeTypeWidget([filter]);

  }

  isConfigurationPage(): boolean {
    return true;
  }

  isNavComponent(): NavigationItem[] {
    return this.navigationItems;
  }

}
