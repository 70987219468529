<ng-container *ngIf="initialized">

    @if (size == 'M') {
        <div (click)="handleClick()" class="pointer">
            <mvs-inline-component
                    [objectType]="'cr.Customer'"
                    [name]="dto.customerFormDtoName"
                    [uiDescription]="dto.customerDtoName"
                    [status]="dto.statusEnumName">
            </mvs-inline-component>
<!--            [endDate]="dto.endDate"-->
        </div>
    }

</ng-container>

