

<div class="grid p-fluid">

    <div class="col-4">
        <!-- Data Import -->
        <mvs-im-data-import (onImported)="handleDataImported($event)"></mvs-im-data-import>
    </div>

    <div class="col-8">
        <ng-container *ngIf="uploadedImportId">
            <mvs-im-view-import [importId]="uploadedImportId"></mvs-im-view-import>
        </ng-container>
    </div>

</div>






