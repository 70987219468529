import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {PmPersonGender} from "../../../cr/enum/pm-person-gender.enum";
import {ContractService} from "../../service/api/contract.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {Subscription, timer} from "rxjs";
import {MvsSearchFlexibleBaseComponent} from "@kvers/alpha-ui";
import {MvsSearchFlexibleResultData} from "@kvers/alpha-ui";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'cm-search-flexible-contract',
    templateUrl: './cm-search-flexible-contract.component.html',
})
export class CmSearchFlexibleContractComponent
    extends MvsSearchFlexibleBaseComponent
    implements OnInit {

    widgetTableViaContract: WidgetData;
    widgetTableViaPerson: WidgetData;
    widgetTableViaCustomer: WidgetData;
    firstName: string;
    lastName: string;
    gender: PmPersonGender
    birthName: string;
    alias: string;
    contractIdentifier: string;
    contractType: DtoDetail[];
    Group: DtoDetail[];
    selectedContractType: number;
    selectedGroup: number;
    subscription: Subscription;
    customerObjectSelected: { id: number, name: string };
    personObjectSelected: { id: number, name: string };
    searchForm: FormGroup;


    constructor(protected contractService: ContractService, private fb: FormBuilder) {
        super();

        this.searchForm = this.fb.group({
            alias: [''],
            contractId: [''],
            contractType: [''],
            contractGroup: ['']
        });

    }

    ngOnInit() {
        // load template list
        this.contractService.template(new DtoTemplate()).subscribe(res => {
            this.contractType = res.formFields['contractTypeDtoId'].valueList.entries;
            this.Group = res.formFields['groupDtoId'].valueList.entries;
            console.log(res);
        })
    }


    /**
     * search Via Contract (eg: alias, contractIdentifier)
     */
    onSearchViaContact() {
        const formValues = this.searchForm.value;

        if (!formValues.alias && !formValues.contractId) {
            return;
        }

        const filterCriteria: FilterCriteria[] = [];

        if (formValues.alias) {
            filterCriteria.push(
                FilterCriteria.create('alias', FilterCriteria.cOperatorContainsPattern, '%' + formValues.alias + '%', null)
            );
        }
        if (formValues.contractId) {
            filterCriteria.push(FilterCriteria.create('id', FilterCriteria.cOperatorEqual, formValues.contractId, null));
        }
        if (formValues.contractType) {
            filterCriteria.push(FilterCriteria.create('contractType', FilterCriteria.cOperatorEqual, formValues.contractType, null));
        }
        if (formValues.contractGroup) {
            filterCriteria.push(FilterCriteria.create('group', FilterCriteria.cOperatorEqual, formValues.contractGroup, null));
        }

        this.onSearchAction.emit(formValues);

        this.loadTableViaContract(null, filterCriteria);
    }

    /**
     * method to return the widget table instance
     * @param dataProviderObject
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTable(dataProviderObject: string, complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]): WidgetData {

        const widgetData = new WidgetData();
        widgetData.idAlias = 'cm.search.flexible.contracts.' + dataProviderObject;
        widgetData.name = 'Contracts';
        widgetData.uiComponent = 'table';
        widgetData.dataProvider = 'list';
        widgetData.dataSource = 'entity';
        widgetData.dataProviderObject = dataProviderObject;

        widgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(true, filterCriteria, [new Sorting('createdDate', false)], PagingDto.create(0, 8));
        widgetData.dataProviderListRequest.objectRequestComplex = complexSelection;
        widgetData.setParamValue("selectionMode", "rowSelect");
        widgetData.setParamValue("rowSelectionMode", "sticky");
        widgetData.setParamValue('size', 'S');

        return widgetData;
    }

    /**
     * method to load Contracts info using contact basic data
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaContract(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaContract = this.loadTable('cm.Contract', complexSelection, filterCriteria);
    }

    /**
     * method to load Contracts info using person details
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaPerson(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaPerson = this.loadTable('ci.ContractPerson', complexSelection, filterCriteria);
    }


    /**
     * method to load Contracts info using customer details
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaCustomer(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaCustomer = this.loadTable('cr.CustomerContract', complexSelection, filterCriteria);
    }

    /**
     * trigger the event when object is selected
     * @param event
     */
    handleObjectSelect(event: ObjectIdentifierData) {

        const result: MvsSearchFlexibleResultData =
            MvsSearchFlexibleResultData.create(event, event.data["alias"] + "(" + event.objectId + ")");

        this.onObjectSelect.emit(result);

    }

    /**
     * trigger the event when object is selected and searched via customer table
     * @param event
     */
    handleCustomerObjectSelect(event: ObjectIdentifierData) {
        if (!event.data.contractDto) {
            return;
        }
        const contract = event.data.contractDto;
        const objectIdentifierData: ObjectIdentifierData = new ObjectIdentifierData('cm.Contract', contract.id, contract);
        this.handleObjectSelect(objectIdentifierData);
    }

    /**
     * trigger the event when object is selected and searched via person table
     * @param event
     */
    handlePersonObjectSelect(event: ObjectIdentifierData) {
        if (!event.data.contractDto) {
            return;
        }
        const contract = event.data.contractDto;
        const objectIdentifierData: ObjectIdentifierData = new ObjectIdentifierData('cm.Contract', contract.id, contract);
        this.handleObjectSelect(objectIdentifierData);
    }

    /**
     * handle object when row is selected from customer table
     * @param event
     */
    handleCustomerObject(event: { id: number, name: string }) {
        this.customerObjectSelected = event;
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(
            FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, event.id, null)
        );

        const complexSelection = ObjectRequestComplex.build(true, false,

            ObjectRequestComplexNode.createSimpleAttributeNode('contract')
        );

        this.loadTableViaCustomer(complexSelection, filterCriteria);
    }

    /**
     * handle object when row is selected from person table
     * @param event
     */
    handlePersonObject(event: { id: number, name: string }) {
        this.personObjectSelected = event;
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(
            FilterCriteria.create('person', FilterCriteria.cOperatorEqual, event.id, null)
        );

        const complexSelection = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('contract')
        );

        this.loadTableViaPerson(complexSelection, filterCriteria);
    }

    onInputFieldDetails() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = timer(1000).subscribe(() => {

            this.onSearchViaContact();

            this.subscription = null;
        });
    }

    clearObject(identifier) {
        if (identifier == 'customer') {
            this.customerObjectSelected = null;
            this.widgetTableViaCustomer = null;
        }else if(identifier == 'person') {
            this.personObjectSelected = null;
            this.widgetTableViaPerson = null;
        }
    }

    handleSearchAction(event) {
        this.onSearchAction.emit(event);
    }

}
