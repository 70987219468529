import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectPageComponent} from "@kvers/alpha-core-common";


@Component({
  selector: 'si-index-run-object-page',
  templateUrl: './si-index-run-object.page.html'
})
export class SiIndexRunObjectPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

  defaultLabel: string = "Search Index Run";


  protected getObjectType(): string {
    return "si.IndexRun";
  }

}
