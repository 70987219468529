@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
        <date-selection-dropdown
                [defaultOption]="'thisMonth'"
                (selectedDateStart)="handlePeriodFilter($event)"
        ></date-selection-dropdown>
    </div>

    <div class="grid dashboard">
        <div class="col-12 md:col-6 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="appointmentsPerMonthWidget"
                        [widgetData]="appointmentsPerMonthWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 md:col-3 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="agentCategoryByAgentPoolWidget"
                        [widgetData]="agentCategoryByAgentPoolWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 md:col-3 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="appointmentTypeWidget"
                        [widgetData]="appointmentTypeWidget"
            ></mvs-widget>
        </div>

        <div class="col-12 md:col-8 mb-2">
            <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="recentlyJoinedAgentsWidget"
                        [widgetData]="recentlyJoinedAgentsWidget"
            >
                <ng-template let-object="object" mvs-widget-custom-field-directive label="User Image"
                             fieldId="userImage">

                    <div *ngIf="object.dto.userDtoId">
                        <user-profile-id [userId]="object.dto.userDtoId"
                                         [readonly]="true">
                        </user-profile-id>
                    </div>
                </ng-template>
            </mvs-widget>
        </div>

        <!--            <div class="col-12 md:col-4">-->
        <!--                <mvs-widget-->
        <!--                        *ngIf="appointmentResultWidget"-->
        <!--                        [widgetData]="appointmentResultWidget"-->
        <!--                ></mvs-widget>-->
        <!--            </div>-->


        <div class="col-12 md:col-4 mb-2">
            <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                <h4 class="text-xl font-medium">Overview</h4>
                <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>
            </div>
        </div>

    </div>

}