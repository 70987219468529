<div class="card p-5" *ngIf="dto && customerCallInfo">

    <p-table class="cr-schedule-table" [value]="timeSlots" [(contextMenuSelection)]="selectedTimeSlot"
             [contextMenu]="cm">
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <i
                            class="fa-solid fa-clock-rotate-left cursor-pointer"
                            (click)="refreshCallListWidget()">
                    </i>
                </th>
                <th #daysMenu *ngFor="let day of days" (contextmenu)="onSelectColumn(day)">{{ day.value }}
                    <p-contextMenu [target]="daysMenu" [model]="contextColumnMenuItems" appendTo="body"></p-contextMenu>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-timeSlot>
            <tr>
                <th #timeSlotMenu (contextmenu)="onSelectRow(timeSlot)">{{ timeSlot.value }}

                    <p-contextMenu [target]="timeSlotMenu" [model]="contextRowMenuItems"
                                   appendTo="body"></p-contextMenu>
                </th>
                <td *ngFor="let day of days"
                    [pContextMenuRow]="{timeSlot,day}"
                    [style]="{'border-width':'0px'}"
                    [ngClass]="{ 'cr-hover-cell': hoveredDay === day.name || hoveredTimeSlot === timeSlot.name }">
                    <div
                            class="flex"
                            (mouseover)="handleHoverEffect(day.name, timeSlot.name)"
                            (mouseout)="handleHoverEffect(null, null)"
                    >
                    <span class="relative">

                    <p-button
                            [icon]="getIcon(day.name, timeSlot.name)"
                            [styleClass]="'p-button-rounded p-button-text p-button-lg ' + getClasses(day.name, timeSlot.name)"
                            [disabled]="busy"
                    ></p-button>
                    <i
                            [class]="getCallBadgeClass(getCallForDayTime(day.name, timeSlot.name))"
                            (click)="handleCallForDayTime(day.name, timeSlot.name, $event)"
                    ></i>
                    </span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="w-full mt-3" [formGroup]="formGroup" *ngIf="formGroup">
        <mvs-form-field-toggleable
                [formGroup]="formGroup"
                defaultState="display"
                [formField]="uiFieldOverride"
                (onSave)="handleComment($event)"
                enableDblClick="true"
                showUpdateButton="true"
                [value]="dto.comment">
        </mvs-form-field-toggleable>

    </div>
</div>

<!-- ************** Overlay panel to show the history of call at specific time slot ************** -->

<p-overlayPanel #op appendTo="body">

    <p-table [value]="dayTimeCallInfo" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th>Start Time</th>
                <th>Result</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-callInfo>
            <tr>
                <td>{{ callInfo['startTime'] | mvsDateFormat }}</td>
                <td>{{ getCallResult(callInfo) }}</td>
            </tr>
        </ng-template>
    </p-table>

</p-overlayPanel>

<!-- ************** Dialog box to show the full history of call ************** -->

<p-dialog header="Call History" [(visible)]="visible" [style]="{width: '50vw'}">
    <mvs-widget
            *ngIf="customerCallListWidget"
            [widgetData]="customerCallListWidget"
    ></mvs-widget>
</p-dialog>

<!-- ************** context menu to select the customer availability ************** -->

<p-contextMenu #cm [model]="contextMenuItems"></p-contextMenu>