import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessTypeFieldDto} from "../../../../wf/dto/wf-process-type-field.dto";
import {WfProcessTypeDto} from "../../../../wf/dto/wf-process-type.dto";
import {WfProcessDto} from "../../../../wf/dto/wf-process.dto";

@Component({
    selector: 'mvs-wf-process-step-activity-object-customer-comment',
    templateUrl: './cr-customer-comment-wf-process-step-activity.component.html',
})
export class CrCustomerCommentWfProcessStepActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    objectTypeDto: ObjectTypeDto;

    /**
     * Refresh Component.
     */
    async refreshComponent() {

        // if (!this.objectTypeDto || this.objectTypeDto.id !== this.activityType.activityObjectDto.objectTypeDtoId) {
        //     this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.activityObjectDto.objectTypeDtoId);
        // }

        // const field: WfProcessTypeFieldDto = WfProcessTypeDto.getFieldById(this.activityType.objectProcessTypeFieldDtoId, this.process.typeDto)

        // if (field) {
        //     const genericField = `gen_${field.id}DtoId`
        //     const processDto: WfProcessDto = this.process;
        //     this.connectedObjectId = processDto[genericField];
        // }

        this.initialized = true;

    }


}
