import {Component, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, Type, ViewChild} from '@angular/core';
import {
  DtoDetail,
  FilterCriteria,
  ObjectRequestComplex,
  ObjectRequestComplexNode,
  ObjectRequestList, ObjectRequestRelation
} from "@kvers/alpha-core-common";
import {
  ObjectQuickViewBaseComponent
} from "@kvers/alpha-ui";
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {TicketDto} from "../../dto/ticket.dto";
import {UiTicketQuickViewDto} from "./data/ui-ticket-quick-view.dto";
import {TicketObjectDto} from "../../dto/ticket-object.dto";

@Component({
  selector: 'ticket-object-quick-view',
  templateUrl: './ticket-object-quick-view.component.html'
})
export class TicketObjectQuickViewComponent
                  extends ObjectQuickViewBaseComponent
                  implements OnInit, OnChanges {

  dto: TmTicketDto;
  uiTicketDto: UiTicketQuickViewDto;

  /**
   * Method called whenever the object changed.
   */
  onObjectChanged() {
    // perform UI specific tasks ...
  }

  protected retrieveObject() {
    if (this.transientDto) {
      this.storeNewDto(this.transientDto);
      this.postProcessDto(this.transientDto);
      return;
    }

    this.busy = true;
    const crudService = this.coreService.getCrudService('tm.Ticket');
    const objectRequestList = new ObjectRequestList(
        true,
        [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
        []
    );
    objectRequestList.objectRequestComplex = ObjectRequestComplex.build(
        true,
        false,
        ObjectRequestComplexNode.createRelationNode('ticketObjects', ObjectRequestRelation.createJoin('tm.TicketObject#ticket')),
    );

    crudService.list(objectRequestList).subscribe(res => {
      const dto: TicketDto = res.entries[0];
      dto.form = res.form;
      this.storeNewDto(dto);
      this.postProcessDto(dto);
    })
  }

  postProcessDto(dto: DtoDetail) {
    this.uiTicketDto = new UiTicketQuickViewDto();
    this.uiTicketDto.name = dto.name;
    this.uiTicketDto.type = dto['typeDtoName'];
    this.uiTicketDto.alias = dto['alias'];
    this.uiTicketDto.uiObjectsLength = dto['ticketObjects'] ? dto['ticketObjects'].length : 0;

    if (this.uiTicketDto.uiObjectsLength) {

      this.uiTicketDto.uiExtraObjectsLength = this.uiTicketDto.uiObjectsLength - 1;
      const customerObject = this.objectExists('Customer', dto['ticketObjects']);
      const contractObject = this.objectExists('Contract', dto['ticketObjects']);

      if (customerObject) {
        this.uiTicketDto.uiIcon =  'fa-regular fa-user'; // prefer customer
        this.uiTicketDto.uiBgColor = 'bg-primary-100 text-primary';
        this.uiTicketDto.uiLatestObjectId = customerObject.objectId;
      } else if (contractObject) {
        this.uiTicketDto.uiIcon = 'fa-regular fa-file';
        this.uiTicketDto.uiBgColor = 'bg-blue-100 text-primary border-round';
        this.uiTicketDto.uiLatestObjectId = contractObject.objectId;
      }

    } else {
      this.uiTicketDto.uiIcon = 'fa-regular fa-ticket'; // just show ticket icon
      this.uiTicketDto.uiBgColor = 'bg-yellow-100 text-yellow-600 border-round';
    }
    this.busy = false;

  }

  objectExists(objectType: string, ticketObjects: TicketObjectDto[]): TicketObjectDto {
    return ticketObjects.find(item => item.objectTypeDtoName == objectType);
  }

}
