<ng-container *ngIf="initialized">

    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="home"></p-breadcrumb>

    <!--  ******************************** Display registration Information *******************************  -->


    <ng-container *ngIf="!selectedUserAddedInsuranceId">

        <div class="grid">

            <div class="col-12">
                <p-card class="">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex flex-column">
                            <div *ngIf="selectedUserRegistration" class="flex flex-column gap-3 pl-3">

                                <ng-container
                                        *ngIf="userRegistrationInternalStatusEntries?.length">
                                    <div class="flex align-items-center gap-2 flex-wrap">
                                        <p-chip
                                                *ngFor="let entry of userRegistrationInternalStatusEntries"
                                                class="cursor"
                                                [label]="entry.label"
                                                [icon]="entry.image"
                                                (click)="handleRegistrationChangeStatus(entry.key)"
                                                [styleClass]="selectedUserRegistration?.registrationStatusInternal === entry?.key ? 'bg-'+entry.backgroundColor + ' text-'+entry.color:''"
                                        ></p-chip>
                                    </div>
                                </ng-container>

                            </div>

                            <div class="flex mt-3">
                                <button class="p-ripple p-element p-button p-component p-button-text flex gap-2"
                                        [disabled]="disablePrevious || busy"
                                        (click)="navigateToPreviousRegistration()"><i
                                        class="fa-solid fa-arrow-left"></i>
                                    <span>Vorherige Registrierung</span>
                                </button>
                                <button class="p-ripple p-element p-button p-component p-button-text flex gap-2"
                                        [disabled]="disableNext || busy"
                                        (click)="navigateToNextRegistration()"><span>Nächste Registrierung</span> <i
                                        class="fa-solid fa-arrow-right"></i></button>

                            </div>
                        </div>

                        <p-button
                                icon="fa-solid fa-bolt"
                                label="Action"
                                [disabled]="busy"
                                (click)="showSideDrawer = !showSideDrawer"
                        ></p-button>
                    </div>
                </p-card>
            </div>

            <div class="col-4">

                <mvs-widget
                        *ngIf="registrationWidget"
                        [widgetData]="registrationWidget"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="customerWidget"
                        [widgetData]="customerWidget"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="onboardingWidget"
                        [widgetData]="onboardingWidget"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="userWidget"
                        [widgetData]="userWidget"
                ></mvs-widget>

            </div>

            <div class="col-4">

                <mvs-widget
                        *ngIf="userAddedInsurancesWidget"
                        [widgetData]="userAddedInsurancesWidget"
                        (onObjectSelect)="handleUserAddedInsuranceObject($event)"
                ></mvs-widget>

                <mvs-widget
                        *ngIf="customerAgentWidget"
                        [widgetData]="customerAgentWidget"
                ></mvs-widget>


            </div>
        </div>
    </ng-container>


    <!--  ******************************** Display Insurance Information *******************************  -->

    <ng-container *ngIf="selectedUserAddedInsuranceId">
        <cr-manage-registration-document
                [userId]="selectedCustomerUser.userDtoId"
                [selectedUserAddedInsuranceId]="selectedUserAddedInsuranceId"
                [selectedUserRegistration]="selectedUserRegistration"
                [(showSideDrawer)]="showSideDrawer"
                [(selectedDocument)]="selectedDocument"
                [direction]="direction"
                [(lastDocument)]="lastDocument"
                (selectedUserAddedInsuranceIdChange)="handleRemoveContractTab()"
                (selectedDocumentChange)="handleDocumentStatus()"
        ></cr-manage-registration-document>
    </ng-container>


    <!--  ******************************** Display Side Drawer Information *******************************  -->

    <mvs-sidebar
            sidebarPosition="right-30%"
            [toolbarButtons]="toolbarButton"
            (onToolbarButtonClick)="handleToolbarButton($event)"
            [isSidebarOpen]="showSideDrawer"
            (check)="showSideDrawer = $event">

        <ng-template mvs-sidebar-directive>

            <div class="grid">

                <div class="col-12">
                    <ng-container
                            *ngIf="selectedDocument && selectedUserAddedInsuranceId && documentStatusEntries">

                        <div class="flex align-items-center justify-content-center gap-2 w-full">
                            <p-dropdown
                                    [options]="documentStatusEntries"
                                    [(ngModel)]="selectedDocumentStatus"
                                    optionLabel="label"
                                    class="w-full ml-2"
                                    styleClass="w-full"
                                    optionValue="key"
                            ></p-dropdown>

                            <p-button
                                    icon="fa-solid fa-circle-right"
                                    [disabled]="busy"
                                    [pTooltip]="lastDocument ? 'speichern' : 'speichern & weiter.'"
                                    tooltipPosition="left"
                                    (click)="handleDocumentChangeStatus()"
                                    class="flex-none"
                            ></p-button>
                        </div>

                    </ng-container>
                </div>

                <div class="col-12">
                    <div class="border-right-1 border-top-1 border-300 border-round-md mb-4 scroll-section pt-3">
                        <p-tabView [activeIndex]="tabIndex" (activeIndexChange)="tabIndex = $event">
                            <p-tabPanel header="Erinnern">

                                <!-- show notification for customer -->

                                <div class="mvs-widget" *ngIf="selectedCustomer">
                                    <mvs-ns-notification-types-for-object
                                            [objectIdentifier]="{objectType:'cr.Customer',objectId:selectedCustomer.id}"
                                    ></mvs-ns-notification-types-for-object>
                                </div>

                                <!-- show notification for registration if customer not available -->

                                <div class="mvs-widget" *ngIf="!selectedCustomer">
                                    <mvs-ns-notification-types-for-object
                                            [objectIdentifier]="{objectType:'eu.EuRegistration',objectId:selectedUserRegistrationId}"
                                    ></mvs-ns-notification-types-for-object>
                                </div>

                                <div class="mvs-widget" *ngIf="!selectedCustomer">
                                    Customer Not Available
                                </div>


                            </p-tabPanel>

                            <p-tabPanel header="Einarbeitung">

                                <mvs-widget
                                        *ngIf="selectedCustomerOnboarding?.appointmentDtoId && appointmentWidget"
                                        [widgetData]="appointmentWidget"
                                ></mvs-widget>


                            </p-tabPanel>

                            <p-tabPanel header="Alle Verträge">

                                <div class="flex justify-content-between mb-2">
                                    <h5>Bestehende Verträge</h5>
                                    <p-button label="Neuer Vertrag" icon="fa-regular fa-plus"
                                              (click)="createContractBool = true; resetContract(); this.moveToContractTab()"></p-button>
                                </div>

                                <div
                                        *ngIf="!contractsDtoList?.length"
                                        class="flex flex-column justify-content-center align-items-center p-4 gap-2"
                                >

                                    <i class="far fa-file-contract contract-icon-size"></i>
                                    Keine Verträge vorhanden

                                </div>

                                <div class="flex flex-column gap-2">
                                    <div class="cursor" *ngFor="let contract of contractsDtoList"
                                         (click)="handleContractSelect(contract, $event)">
                                        <div class="card">

                                            <div class="grid">
                                                <div class="col-4">
                                                    <div class="flex gap-2">
                                                        <i [class]="contract?.contractTypeDtoImage"></i>
                                                        <span>{{ contract.contractTypeDtoName }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    {{ contract.categoryDtoName }}
                                                </div>

                                                <div class="col-4">
                                                    <div class="flex gap-3 align-items-center">
                                                        <i class="fa-regular fa-handshake"
                                                           [ngClass]="contract?.contractPartner ? 'text-green-500' : 'text-red-500'"></i>
<!--                                                        <i class="fa-regular fa-bars-progress"-->
<!--                                                           [ngClass]="contract?.contractStatus ? 'text-green-500' : 'text-red-500'"></i>-->
                                                        <i class="fa-regular fa-user-tie"
                                                           [ngClass]="contract?.contractAgent ? 'text-green-500' : 'text-red-500'"></i>

                                                        <p-knob
                                                                class="cr-manage-knob"
                                                                [strokeWidth]="20"
                                                                [(ngModel)]="contract['completedTask']"
                                                                [min]="0"
                                                                [max]="3"
                                                                [size]="32"
                                                                [valueColor]="contract['completedTask'] == 3 ? 'var(--green-500, Black)':'var(--primary-color, Black)'"
                                                                rangeColor="var(--gray-300, Black)"
                                                        ></p-knob>

                                                        <div class="flex flex-column">
                                                            <span class="text-sm bold">Status</span>
                                                            <span class="text-sm bold" *ngIf="contract['completedTask'] != 3">{{ contract['completedTask'] }} / 3 Tasks</span>
                                                            <span class="text-sm bold" *ngIf="contract['completedTask'] == 3">Abgeschlossen</span>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="flex gap-2">
                                                        <p-chip *ngFor="let insurableObject of contract.contractInsurableObject"
                                                                [label]="insurableObject.insurableObjectDto.typeDtoName">
                                                        </p-chip>
                                                        <p-chip *ngFor="let insurablePerson of contract.contractPerson"
                                                                [label]="insurablePerson.contractPersonTypeDto.name">
                                                        </p-chip>

                                                        <span *ngIf="!contract?.contractInsurableObject?.length && !contract?.contractPerson?.length" class="text-red-500">
                                                            Kein versicherbares Objekt oder Person.
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </p-tabPanel>

                            <p-tabPanel *ngIf="selectedContractId || createContractBool">

                                <ng-template pTemplate="header">
                                    <div class="flex gap-2 justify-content-center p-0 m-0">
                                        <span class="p-0 m-0">Vertrag</span>
                                        <i class="fa-solid fa-circle-xmark text-xl p-0 m-0 ml-2" style="color:var(--surface-500)" (click)="handleRemoveContractTab()"></i>
                                    </div>
                                </ng-template>
                                <p-tabView>

                                    <p-tabPanel [header]="selectedContractId ? 'Vertragsdetails' : 'Neuer Vertrag'">

                                        <ng-container>
                                            <cr-create-contract-combined-form
                                                    *ngIf="selectedCustomer"
                                                    usedIn="sidebar"
                                                    variation="Fremd"
                                                    [agentId]="selectedCustomer['customerAgents']?.agentDtoId"
                                                    [customerId]="selectedCustomer.id"
                                                    [contractId]="selectedContractId"
                                                    (onObjectChanged)="handleCombinedFormObjectChange($event)"
                                                    (onCreateNew)="resetContract(); createContractBool = true"
                                            ></cr-create-contract-combined-form>

                                            <div class="mvs-widget" *ngIf="!selectedCustomer">
                                                No Customer Registration
                                            </div>
                                        </ng-container>

                                    </p-tabPanel>

                                    <ng-container *ngIf="selectedContractId">
                                        <p-tabPanel header="Versicherbare Objekte">
                                            <cm-create-insurable-object-others
                                                    [customerId]="selectedCustomer.id"
                                                    [contractId]="selectedContractId"
                                                    [highlightRelation]="true"
                                                    (onChangedObject)="handleInsurableObjectChange($event)"
                                            ></cm-create-insurable-object-others>
                                        </p-tabPanel>


                                        <p-tabPanel header="Versicherbare Person">

                                            <div class="flex justify-content-end">
                                                <p-button
                                                        styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-rounded p-button-text"
                                                        icon="fa-light fa-user-plus"
                                                        [pTooltip]="'Kunden hinzufügen'"
                                                        tooltipPosition="left"
                                                        (click)="addMeAsInsurablePerson()"
                                                ></p-button>
                                            </div>

                                            <cm-create-insurable-object-person
                                                    *ngIf="contextContractAndCustomer"
                                                    [importObjectContext]="contextContractAndCustomer"
                                                    [highlightRelation]="true"
                                                    (onObjectSelected)="handleInsurableObjectChange($event)"
                                            ></cm-create-insurable-object-person>
                                        </p-tabPanel>

                                        <p-tabPanel header="Kommunikation">
                                        <mvs-ns-notification-types-for-object
                                                [objectIdentifier]="{objectType:'cm.Contract',objectId:selectedContractId}"
                                                [mode]="1"
                                                class="mvs-widget block"
                                        ></mvs-ns-notification-types-for-object>
                                        </p-tabPanel>
                                    </ng-container>
                                </p-tabView>

                            </p-tabPanel>

                        </p-tabView>
                    </div>
                </div>

            </div>
        </ng-template>

    </mvs-sidebar>

</ng-container>
