import {
    AfterViewInit,
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

export interface PlaceSearchResult {
    address: string,
    name: string | undefined,
    location: google.maps.LatLng | undefined,
    iconUrl: string | undefined
}

declare global {
    interface Window {
        initAutocomplete: () => void;
    }
}

@Component({
    selector: 'mvs-test-form-address',
    templateUrl: './test-address.page.html',
    styleUrls: ['./test-address.page.scss']
})
export class TestAddressPage implements OnInit {

    addressForm: FormGroup;
    autoComplete: google.maps.places.Autocomplete | undefined;
    googleMapKey = 'AIzaSyDayqYhQydF2tvoIVFQg6k9uV92onTrJII'
    scriptLoaded: boolean = false;

    @ViewChild('shipAddress') shipAddress!: ElementRef;

    constructor(private formBuilder: FormBuilder) {
        this.addressForm = this.formBuilder.group({
            shipAddress: ['', Validators.required],
            address2: [''],
            locality: ['', Validators.required],
            state: ['', Validators.required],
            postcode: ['', Validators.required],
            country: ['', Validators.required],
        });
    }

    ngOnInit(): void {

        this.loadGoogleMapsScript();
    }

    private loadGoogleMapsScript(): void {

        if (!this.scriptLoaded) {

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapKey}&libraries=places`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                this.scriptLoaded = true;
                this.initAutocomplete();
            };

            document.head.appendChild(script);
        }
    }


    initAutocomplete() {
        this.autoComplete = new google.maps.places.Autocomplete(this.shipAddress.nativeElement, {
            componentRestrictions: {country: ['de']},
            // fields: ['address_components', 'geometry'],
            // types: ['address'],
        });

        this.shipAddress.nativeElement.focus();

        this.autoComplete.addListener('place_changed', this.fillInAddress);
    }

    fillInAddress = () => {
        const place = this.autoComplete?.getPlace();

        if (place && place.address_components) {
            this.addressForm.patchValue({
                shipAddress: this.shipAddress.nativeElement.value,
                address2: '',
                locality: place.address_components.find((c) => c.types.includes('locality'))?.long_name || '',
                state: place.address_components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name || '',
                postcode: place.address_components.find((c) => c.types.includes('postal_code'))?.long_name || '',
                country: place.address_components.find((c) => c.types.includes('country'))?.long_name || '',
            });
        }
    };

}
