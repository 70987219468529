import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-wf-config-page',
  templateUrl: './wf-config.component.html',
  styleUrls: ['./wf-config.component.scss']
})
export class WfProcessTypeComponent extends PageComponent implements OnInit {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Workflow Konfiguration";

  wfProcessGroupWidget: WidgetData;
  wfProcessGroupFieldWidget: WidgetData;
  wfProcessTypeWidget: WidgetData;
  wfHintTypeWidget: WidgetData;
  wfResultCategoryWidget: WidgetData;

  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label: 'Prozessgruppen', id: 'wf.WfProcessGroup', type: ''},
    {label: 'Prozessgruppenfelder', id: 'wf.WfProcessGroupField', type: ''},
    {label: 'Prozesse', id: 'wf.WfProcessType', type: ''},
    {label: 'Hinweistypen', id: 'wf.WfHintType', type: ''},
    {label: 'Ergebniskategorie', id: 'wf.WfResultCategory', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();
    this.handleTabSelection(0);

    // Main Structure
    // this.objectBrowserRequest = {
    //
    //   "extractDto": false,
    //   "createMode": true,
    //
    //   'entries' : [
    //     {
    //       'id'                : 1,
    //       'objectAlias'       : 'wf.WfProcessGroup',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Prozessgruppen",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Neue Gruppe anlegen"
    //     },
    //     {
    //       'id'                : 2,
    //       'objectAlias'       : 'wf.WfProcessGroupField',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Prozessgruppenfelder",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Neues Feld anlegen"
    //     },
    //     {
    //       'id'                : 3,
    //       'objectAlias'       : 'wf.WfProcessType',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Prozesse",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Neuen Worklfow anlegen"
    //     },
    //     {
    //       'id'                : 4,
    //       'objectAlias'       : 'wf.WfHintType',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Hinweistypen",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Typ anlegen"
    //     },
    //     {
    //       'id'                : 5,
    //       'objectAlias'       : 'wf.WfResultCategory',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Ergebniskategorie",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Kategorie anlegen"
    //     },
    //   ]
    // };

  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshWfProcessGroupWidget();
    this.refreshWfProcessGroupFieldWidget();
    this.refreshWfProcessTypeWidget();
    this.refreshWfHintTypeWidget();
    this.refreshWfResultCategoryWidget();
  }

  refreshWfProcessGroupWidget() {
    this.wfProcessGroupWidget = WidgetFactory.createWidgetList(
        "wf.process.group.widget.simple",
        'Prozessgruppen',
        'table',
        'list',
        'entity',
        'wf.WfProcessGroup',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshWfProcessGroupFieldWidget() {
    this.wfProcessGroupFieldWidget = WidgetFactory.createWidgetList(
        "wf.process.group.field.widget.simple",
        'Prozessgruppenfelder',
        'table',
        'list',
        'entity',
        'wf.WfProcessGroupField',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshWfProcessTypeWidget() {
    this.wfProcessTypeWidget = WidgetFactory.createWidgetList(
        "wf.process.type.widget.simple",
        'Prozesse',
        'table',
        'list',
        'entity',
        'wf.WfProcessType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshWfHintTypeWidget() {
    this.wfHintTypeWidget = WidgetFactory.createWidgetList(
        "wf.hint.type.widget.simple",
        'Hinweistypen',
        'table',
        'list',
        'entity',
        'wf.WfHintType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshWfResultCategoryWidget() {
    this.wfResultCategoryWidget = WidgetFactory.createWidgetList(
        "wf.result.category.widget.simple",
        'Ergebniskategorie',
        'table',
        'list',
        'entity',
        'wf.WfResultCategory',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }

}
