import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectSubBaseComponent} from "../../../../../cr/component/cr-customer/component/object-sub-base.component";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerContractService} from "../../../../../cr/service/api/customer-contract.service";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {Sorting} from "@kvers/alpha-core-common";


@Component({
    selector: 'cm-contract-standard-navigation',
    templateUrl: './cm-contract-standard-navigation.component.html',
})
export class CmContractStandardNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    contractWidgetData: WidgetData;
    customerContractWidgetData: WidgetData;
    contractDataWidgetData: WidgetData;
    contractStatusWidgetData: WidgetData;
    contractAgentWidgetData: WidgetData;
    contractPartnerWidgetData: WidgetData;
    contractPriceWidgetData: WidgetData;
    contractFormerWidgetData: WidgetData;
    ticketWidget: WidgetData;
    myWidget: WidgetData;

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerContractService: CustomerContractService,
                protected configService: MvsConfigService) {
        super(coreService, confirmationService, messageService, configService,navigationService);

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        // make sure that the widget is loaded

        this.refreshContractStatusWidget();
        this.refreshTicketWidget();

        let filterCriteria = FilterCriteria.create("customer", FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId, null );


        WidgetFactory.createWidget

        // CONTRACT INFORMATION
        this.contractWidgetData = WidgetFactory.createWidgetList(
            "cm.contract.contracts.complex.widget",
            "Vertrag",
            "data",
            "list",
            "entity",
            "cm.Contract",
            "Daten nicht vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(
                    true,
                    false,
                    ObjectRequestComplexNode.createSimpleAttributeNode("+agencyNumber"),
                    ObjectRequestComplexNode.createRelationNode("currentRating", ObjectRequestRelation.createJoin("+currentRating"))
                ),
                [FilterCriteria.create("id",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [],
                null
                ),
            new WidgetDataParam("objectId",this.objectIdentifier.objectId)
        );
        /*
        this.contractWidgetData = new WidgetData();
        this.contractWidgetData.idAlias = "cm.contract.contracts.complex.widget";
        this.contractWidgetData.name = "Vertrag";
        this.contractWidgetData.uiComponent = "data";
        this.contractWidgetData.dataProvider = "list";
        this.contractWidgetData.dataSource = "entity";
        this.contractWidgetData.dataProviderObject = "cm.Contract";

        this.contractWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("id",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            []
        );

        this.contractWidgetData.setParamValue("size", "S");
        this.contractWidgetData.setParamValue("objectId",this.objectIdentifier.objectId);
         */


        // CUSTOMER CONTRACT INFORMATION
        this.customerContractWidgetData = new WidgetData();
        this.customerContractWidgetData.idAlias = "cm.contract.customer.contract.complex.widget";
        this.customerContractWidgetData.name = "Kunde";
        this.customerContractWidgetData.uiComponent = "list";
        this.customerContractWidgetData.dataProvider = "list";
        this.customerContractWidgetData.dataSource = "entity";
        this.customerContractWidgetData.dataProviderObject = "cr.CustomerContract";

        this.customerContractWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            []
        );

        this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCustomerContractFromContractComplexSelection();
        this.customerContractWidgetData.setParamValue("size", "S");
        this.customerContractWidgetData.setParamValue("objectWidget", "data");
        this.customerContractWidgetData.functionCallbacks = this.customerContractToolbarCreate();

        // CONTRACT DATA INFORMATION
        this.contractDataWidgetData = new WidgetData();
        this.contractDataWidgetData.idAlias = "cm.contract.contract.data.complex.widget";
        this.contractDataWidgetData.name = "Vertragsdaten";
        this.contractDataWidgetData.uiComponent = "list";
        this.contractDataWidgetData.dataProvider = "list";
        this.contractDataWidgetData.dataSource = "entity";
        this.contractDataWidgetData.dataProviderObject = "cm.ContractData";

        this.contractDataWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            []
        );
        this.contractDataWidgetData.setParamValue("size", "S");
        // this.contractDataWidgetData.noDataText = "Couldn't find anything!!!";


        // CONTRACT STATUS INFORMATION
        this.contractAgentWidgetData = new WidgetData();
        this.contractAgentWidgetData.idAlias = "cm.contract.contract.agent.complex.widget";
        this.contractAgentWidgetData.name = "Berater";
        this.contractAgentWidgetData.uiComponent = "list";
        this.contractAgentWidgetData.dataProvider = "list";
        this.contractAgentWidgetData.dataSource = "entity";
        this.contractAgentWidgetData.dataProviderObject = "cm.ContractAgent";

        this.contractAgentWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            []
        );
        this.contractAgentWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        this.contractAgentWidgetData.setParamValue("size", "S");
        this.contractAgentWidgetData.setParamValue("objectWidget", "data");

        // CONTRACT PARTNER INFORMATION
        this.contractPartnerWidgetData = new WidgetData();
        this.contractPartnerWidgetData.idAlias = "cm.contract.partner.agent.complex.widget";
        this.contractPartnerWidgetData.name = "Partner";
        this.contractPartnerWidgetData.uiComponent = "list";
        this.contractPartnerWidgetData.dataProvider = "list";
        this.contractPartnerWidgetData.dataSource = "entity";
        this.contractPartnerWidgetData.dataProviderObject = "cm.ContractPartner";
        this.contractPartnerWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            []
        );
        this.contractPartnerWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getContractPartnerComplexSelection();
        this.contractPartnerWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        this.contractPartnerWidgetData.setParamValue("size", "S");
        this.contractPartnerWidgetData.setParamValue("objectWidget", "data");
        this.contractPartnerWidgetData.alternateIdAlias = "cm.contract.partner.agent.complex.widget.alternate.variation";
        this.contractPartnerWidgetData.alternateComplexRequest = this.customerContractService.getExtendedContractPartnerComplexSelection();



        // CONTRACT PRICE INFORMATION
        this.contractPriceWidgetData = new WidgetData();
        this.contractPriceWidgetData.idAlias = "cm.contract.price.agent.complex.widget";
        this.contractPriceWidgetData.name = "Preis";
        this.contractPriceWidgetData.uiComponent = "list";
        this.contractPriceWidgetData.dataProvider = "list";
        this.contractPriceWidgetData.dataSource = "entity";
        this.contractPriceWidgetData.dataProviderObject = "cm.ContractPrice";
        this.contractPriceWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            [new Sorting('startDate', false)]
        );
        this.contractPriceWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
        this.contractPriceWidgetData.setParamValue("size", "S");

        this.myWidget = WidgetFactory.createWidgetList(
            "cm.contract.standard.navigation.contract.address.widget",
            "Kunden-Hauptadresse",
            "list",
            "list",
            "entity",
            "cr.CustomerContract",
            "Keine Hauptadresse gefunden.",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(false, false,
                    ObjectRequestComplexNode.createRelationNode('customer', ObjectRequestRelation.createJoin('customer'))
                        .addNodes(ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress")))),
                [FilterCriteria.create("contract", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
                [],
                PagingDto.create(1, 10)
            ),
            new WidgetDataParam("size", "S")
        );
        this.myWidget.functionCallbacks = this.customerContractToolbarCreate();



        this.contractFormerWidgetData = WidgetFactory.createWidgetList(
            "cm.contract.former.complex.widget",
            "Vorheriger Vertrag",
            "list",
            "list",
            "entity",
            "cm.ContractFormer",
            "Kein vorheriger Vertrag vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("contractFormer")),
                [FilterCriteria.create("contract", FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [],
                PagingDto.create(1,10),
                this.importObjectContext
            ),
            new WidgetDataParam("size", "S")
        );

        this.contractFormerWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();



    }

    refreshTicketWidget(){
        const request = new ObjectRequestList(true, [],[]);
        request.preferredEndpoint = `/extend/listViaObject?objectId=${this.objectIdentifier.objectId}&objectTypeAlias=${this.objectIdentifier.objectType}&includeIndirect=true&includeTemplate=true`;
        request.preferredMethod = 'GET';
        this.ticketWidget = WidgetFactory.createWidgetList(
            'cm.contract.standard.navigation.tm.ticket.base.basic.widget',
            'Tickets',
            'list',
            'list',
            'entity',
            'tm.Ticket',
            'No tickets found!',
            request,
        );
    }

    refreshContractStatusWidget() {
        // CONTRACT STATUS INFORMATION
        this.contractStatusWidgetData = new WidgetData();
        this.contractStatusWidgetData.idAlias = "cm.contract.contract.status.complex.widget";
        this.contractStatusWidgetData.name = "Status";
        this.contractStatusWidgetData.uiComponent = "list";
        this.contractStatusWidgetData.dataProvider = "list";
        this.contractStatusWidgetData.dataSource = "entity";
        this.contractStatusWidgetData.dataProviderObject = "cm.ContractStatus";

        this.contractStatusWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("contract",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
            [new Sorting('startDate', false)]
        );

        this.contractStatusWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();

        this.contractStatusWidgetData.setParamValue("size", "S");
        this.contractStatusWidgetData.setParamValue("objectWidget", "data");
        this.contractStatusWidgetData.setParamValue("formEdit", true);
        this.contractStatusWidgetData.setParamValue("formAdd", true);
    }

    customerContractToolbarCreate() {
        return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['contractDtoId'] = this.objectIdentifier.objectId;
                dto['customerDtoId'] = this.dto.joinCurrentMainCustomer.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }};
    }

    widgetProcessToolbarCreate() {
        return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
            const dto = new DtoDetail();
            dto['contractDtoId'] = this.objectIdentifier.objectId;
            const formControlOverwrite = new MvsFormControlOverwrite();
            formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);

            let test: WidgetToolbarCallbackInterface;
            test = {
                dto: dto,
                formControlOverwrite: formControlOverwrite
            };
            return test;
        }};
    }

    handleContractStatusAdd(object: ObjectChangeInformation) {
        this.refreshContractStatusWidget();
    }



}
