import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'ad-statistics.component.html',
})
export class AdStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    pending: number = 0;
    processed: number = 0;
    inProcess: number = 0;
    unfinished: number = 0;


    addressHistoryWidget: WidgetData;
    addressByTypeWidget: WidgetData;
    addressByCountryWidget: WidgetData;
    recentlyAddedAddressWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'AD Module'},
        ];

        this.overviewDto = [
            {label: 'Pending Registrations', value: 0 },
            {label: 'Processed Registrations', value: 0 },
            {label: 'In-Process Registrations', value: 0 },
            {label: 'Unfinished Registrations', value: 0 }
        ];

        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshAddressHistoryWidget();
        this.refreshAddressByTypeWidget();
        this.refreshAddressByCountryWidget();
        this.refreshAddedAddress();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/ui/config']);
    }



    refreshAddressHistoryWidget() {
        this.addressHistoryWidget = new WidgetData();
        this.addressHistoryWidget.idAlias = "ad.address.overview.history.widget";
        this.addressHistoryWidget.name = "Address History";

        this.addressHistoryWidget.uiComponent = "history.chart";
        this.addressHistoryWidget.dataProvider = "list";
        this.addressHistoryWidget.dataSource = "entity.groupBy";
        this.addressHistoryWidget.dataProviderObject = "ad.Address";
        this.addressHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["city", "createdDate"],
            [new ObjectRequestListAttribute("city", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.addressHistoryWidget.setParamValue("fieldCategory", "city");
        this.addressHistoryWidget.setParamValue("fieldCategoryCount", "city_count");
        this.addressHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.addressHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.addressHistoryWidget.setParamValue("selectionMode", "single");
        this.addressHistoryWidget.setParamValue("size", "S");
    }

    refreshAddressByTypeWidget() {
        this.addressByTypeWidget = WidgetFactory.createWidgetGroupBy(
            'ad.address.overview.by.type.widget',
            'Address By Type',
            'category',
            'entity.groupBy',
            'ad.Address',
            'addressType',
            'addressType_count',
            this.filterCriteria,
            'addressType',
            'Anzahl');
    }

    refreshAddressByCountryWidget() {
        this.addressByCountryWidget = WidgetFactory.createWidgetGroupBy(
            'ad.address.overview.by.country.widget',
            'Address By Country',
            'category',
            'entity.groupBy',
            'ad.Address',
            'country',
            'country_count',
            this.filterCriteria,
            'country',
            'Anzahl');
    }

    refreshAddedAddress() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedAddressWidget = WidgetFactory.createWidgetTableEntity(
            'ad.address.overview.recently.added.address',
            'Recent Address',
            'ad.Address',
            'No Address',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
