import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";
import {TeTemplateService} from "../../../../service/api/te-template.service";
import {TeArtefactService} from "../../../../service/api/te-artefact.service";


@Component({
    selector: 'mvs-example',
    templateUrl: 'te-kpi.component.html',
})
export class TeKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalTemplate: number = 0;
    totalArtefacts: number = 0;
    thisMonthTemplate: number = 0;
    thisWeekTemplate: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'TE Module'}];

    constructor(
        protected templateService: TeTemplateService,
        protected artefactService: TeArtefactService
    ) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalTemplate();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalTemplate() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.templateService.groupBy(objectRequestList).subscribe(res => {
            this.totalTemplate = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getTotalArtefacts();
        }, error => {
            this.getTotalArtefacts();
        });
    }

    getTotalArtefacts() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.artefactService.groupBy(objectRequestList).subscribe(res => {
            this.totalArtefacts = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getThisMonthTemplate();
        }, error => {
            this.getThisMonthTemplate();
        });
    }

    getThisMonthTemplate() {
        const objectRequestList = this.getThisMonthRequest('entityStatus');
        this.templateService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthTemplate = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getThisWeekTemplate();
        }, error => {
            this.getThisWeekTemplate();
        });
    }

    getThisWeekTemplate() {
        const objectRequestList = this.getThisWeekRequest('entityStatus');
        this.templateService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekTemplate = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Template', value: this.totalTemplate,
            },
            {
                label: 'Total Artefacts', value: this.totalArtefacts,
            },
            {
                label: 'This Month Templates', value: this.thisMonthTemplate,
            },
            {
                label: 'This Week Templates', value: this.thisWeekTemplate,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
