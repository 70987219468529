import {DtoDetail} from "@kvers/alpha-core-common";

export class CrCustomerEmergencyContactDto extends DtoDetail {

    public customerDtoId : number;
    public customerDtoName : string;
    public customerDtoImage : string;
    public customerDtoColor : string;
    public customerDtoColorBackground : string;
    public endDate : Date;
    public personDtoId : number;
    public personDtoName : string;
    public personDtoImage : string;
    public personDtoColor : string;
    public personDtoColorBackground : string;
    public description : string;
}