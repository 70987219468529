import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {WfProcessTypeComponent} from "./page/overview/wf-config/wf-config.page";
import {WfActivityTypeComponent} from "./page/overview/activity-type/activity-type.page";
import {WfIndicatorComponent} from "./component/wf-indicator/wf-indicator.component";
import {WfTestPage} from "./page/overview/wf-test/wf-test.page";
import {WfProcessStartComponent} from "./component/wf-process-start/wf-process-start.component";
import {WfProcessComponent} from "./component/wf-process/wf-process.component";
import {WfProcessStepActivityDirective} from "./component/wf-process/directive/wf-process-step-activity.directive";
import {
    WfProcessStepActivityBaseComponent
} from "./component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
    WfProcessStepActivityObjectComponent
} from "./component/process-activity/wf-process-step-activity-object/wf-process-step-activity-object.component";
import {
    WfProcessStepOptionalActivityDirective
} from "./component/wf-process/directive/wf-process-step-optional-activity.directive";
import {
    WfProcessStepOptionalActivityObjectCreateComponent
} from "./component/process-activity/wf-process-step-optional-activity-object-create/wf-process-step-optional-activity-object-create.component";
import {
    WfProcessObjectQuickViewComponent
} from "./component/wf-process-object-quick-view/wf-process-object-quick-view.component";
import {
    WfProcessShowAllProcessedActivitiesComponent
} from "./component/wf-process-show-all-processed-activities/wf-process-show-all-processed-activities.component";
import {
    WfProcessStepActivitySidePanelComponent
} from "./component/process-activity-side/wf-process-step-activity-side-panel/wf-process-step-activity-side-panel.component";
import {
    WfProcessStepActivitySubProcessObjectComponent
} from "./component/process-activity/wf-process-step-activity-sub-process-object/wf-process-step-activity-sub-process-object.component";
import {
    WfProcessStepActivityChooseNextStepComponent
} from "./component/process-activity/wf-process-step-activity-choose-next-step/wf-process-step-activity-choose-next-step.component";
import {RefreshComponent} from "./component/refresh-component/refresh.component";
import {
    WfProcessConfigOverviewComponent
} from "./component/wf-process-config-overview/wf-process-config-overview.component";
import {WfProcessOutputFieldComponent} from "./component/wf-process-output-field/wf-process-output-field.component";
import {WfObjectProcessesComponent} from "./component/wf-object-processes/wf-object-processes.component";
import {
    WfProcessStepSideActivityObjectCreateComponent
} from "./component/process-activity-side/wf-process-step-side-activity-object-create/wf-process-step-side-activity-object-create.component";
import {
    WfProcessStepActivitySideBaseComponent
} from "./component/process-activity-side/wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessStepActivitySideDirective} from "./component/process-activity-side/wf-process-step-activity-side.directive";
import {WfProcessStepActivitySideComponent} from "./component/process-activity-side/wf-process-step-activity-side.component";
import {
    WfProcessStepActivitySideGenericComponent
} from "./component/process-activity-side/wf-process-step-activity-side-generic/wf-process-step-activity-side-generic.component";
import {WfProcessStepActivityMainComponent} from "./component/process-activity/wf-process-step-activity-main.component";
import {WfProcessStepActivityMainDirective} from "./component/process-activity/wf-process-step-activity-main.directive";
import {
    WfProcessStepActivityMainPanelComponent
} from "./component/process-activity/wf-process-step-activity-main-panel/wf-process-step-activity-main-panel.component";
import {WfProcessStartProcessPage} from "./page/overview/wf-process-start-process/wf-process-start-process.page";
import {WfDashboardOverviewPage} from "./page/overview/wf-dashboard/wf-dashboard-overview.page";
import {WfProcessPage} from "./page/overview/wf-process/wf-process.page";
import {WfAnalyzePage} from "./page/overview/wf-analyze/wf-analyze.page";
import {WfProcessHeaderComponent} from "./component/wf-process/components/wf-process-header.component";
import {
    WfProcessStepActivitySearchComponent
} from "./component/process-activity/wf-process-step-activity-search/wf-process-step-activity-search.component";
import {WorkflowOverviewPage} from "./page/workflow-overview-page/workflow-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UmKpiComponent} from "../um/page/user-overview-page/components/kpiComponent/um-kpi.component";
import {
    UmStatisticsComponent
} from "../um/page/user-overview-page/components/statisticsComponent/um-statistics.component";
import {WfKpiComponent} from "./page/workflow-overview-page/components/kpiComponent/wf-kpi.component";
import {
    WfStatisticsComponent
} from "./page/workflow-overview-page/components/statisticsComponent/wf-statistics.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
    declarations: [
        WfProcessTypeComponent,
        WfActivityTypeComponent,
        WfIndicatorComponent,
        WfProcessStartComponent,
        WfProcessComponent,
        WfTestPage,
        WfProcessStepActivityDirective,
        WfProcessStepActivityBaseComponent,
        WfProcessStepActivityObjectComponent,
        WfProcessStepOptionalActivityDirective,
        WfProcessStepOptionalActivityObjectCreateComponent,
        WfProcessObjectQuickViewComponent,
        WfProcessShowAllProcessedActivitiesComponent,
        WfProcessStepActivitySidePanelComponent,
        WfProcessShowAllProcessedActivitiesComponent,
        WfProcessStepActivitySidePanelComponent,
        WfProcessStepActivitySubProcessObjectComponent,
        WfProcessStepActivityChooseNextStepComponent,
        RefreshComponent,
        WfProcessConfigOverviewComponent,
        WfProcessOutputFieldComponent,
        WfObjectProcessesComponent,
        WfProcessStepSideActivityObjectCreateComponent,
        WfProcessStepActivityBaseComponent,
        WfProcessStepActivitySideBaseComponent,
        WfProcessStepActivitySideDirective,
        WfProcessStepActivitySideComponent,
        WfProcessStepActivitySideGenericComponent,
        WfProcessStepActivityMainComponent,
        WfProcessStepActivityMainDirective,
        WfProcessStepActivityMainPanelComponent,
        WfProcessStartProcessPage,
        WfDashboardOverviewPage,
        WfProcessPage,
        WfAnalyzePage,
        WfProcessHeaderComponent,
        WfProcessStepActivitySearchComponent,
        WorkflowOverviewPage,
        WfKpiComponent,
        WfStatisticsComponent
    ],
    exports: [
        WfProcessTypeComponent,
        WfActivityTypeComponent,
        WfIndicatorComponent,
        WfProcessStartComponent,
        WfProcessComponent,
        WfTestPage,
        WfProcessStepActivityDirective,
        WfProcessStepActivityBaseComponent,
        WfProcessStepActivityObjectComponent,
        WfProcessStepOptionalActivityDirective,
        WfProcessStepOptionalActivityObjectCreateComponent,
        WfProcessObjectQuickViewComponent,
        WfProcessShowAllProcessedActivitiesComponent,
        WfProcessStepActivitySidePanelComponent,
        WfProcessShowAllProcessedActivitiesComponent,
        WfProcessStepActivitySidePanelComponent,
        WfProcessStepActivitySubProcessObjectComponent,
        WfProcessStepActivityChooseNextStepComponent,
        RefreshComponent,
        WfProcessConfigOverviewComponent,
        WfProcessOutputFieldComponent,
        WfProcessStepSideActivityObjectCreateComponent,
        WfObjectProcessesComponent,
        WfProcessStepActivityBaseComponent,
        WfProcessStepActivitySideBaseComponent,
        WfProcessStepActivitySideDirective,
        WfProcessStepActivitySideComponent,
        WfProcessStepActivitySideGenericComponent,
        WfProcessStepActivityMainComponent,
        WfProcessStepActivityMainDirective,
        WfProcessStepActivityMainPanelComponent,
        WfProcessStartProcessPage,
        WfDashboardOverviewPage,
        WfProcessPage,
        WfAnalyzePage,
        WfProcessStepActivitySearchComponent,
    ],
    imports: [
        CoreModule,
        UiModule,
    ]
})
export class WfModule implements DynamicModule {

    alias = 'wf';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return WfKpiComponent;
    }

    getStatisticsComponent() {
        return WfStatisticsComponent;
    }

}




