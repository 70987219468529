@if (initialized) {
    <span (click)="op.toggle($event)" class="cursor pointer">
        <i class="fa-regular fa-comment text-2xl" pBadge
           [value]="extractedData?.length ? extractedData?.length : 0"></i>
    </span>

    <p-overlayPanel #op>




        <div class="comment-container">
            <!-- Input for adding new comment -->
            <div class="input-area">
                <textarea [(ngModel)]="newComment" placeholder="Write a comment..." rows="3"></textarea>
                <button pButton type="button" label="Post" (click)="addComment()" [disabled]="!newComment.trim()"></button>
            </div>

            @if (extractedData?.length > 0) {
            <!-- Comments List -->
            <div class="comment-list">
                <div class="comment" *ngFor="let data of extractedData">
                    <!-- User Icon -->
                    <mvs-form-control-output-user-image [value]="data?.createdBy"
                                                        [displayInfo]="false">
                    </mvs-form-control-output-user-image>

                    <!-- Comment Content -->
                    <div class="comment-content">
                        <span class="p-text-secondary text-xs">{{ data?.createdDate | mvsDateFormat }}</span>

                        <div *ngIf="!data['isEditing']; else editMode">
                            <p>{{ data[fieldToDisplay] }}</p>
                        </div>

                        <ng-template #editMode>
                            <div class="input-area">
                                <textarea [(ngModel)]="data[fieldToDisplay]" placeholder="Write a comment..." rows="3"></textarea>
                                <button pButton type="button" label="Save" (click)="saveComment(data)"></button>
                            </div>
                        </ng-template>
                    </div>

                    <!-- Edit/Delete Buttons -->
                    <div class="comment-actions">
                        <button pButton icon="pi pi-pencil" class="edit-btn" (click)="editComment(data)"></button>
                        <button pButton icon="pi pi-trash" class="delete-btn" (click)="deleteComment(data.id)"></button>
                    </div>
                </div>
            </div>
            }
        </div>


    </p-overlayPanel>
}