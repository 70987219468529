import {
  Component,
  Input, OnInit
} from '@angular/core';
import {DmDocumentService} from "../../service/api/dm-document.service";
import {DmDocumentViewOptionsEnumEnum} from "../../enum/dm-document-view-options.enum";
import {DomSanitizer} from "@angular/platform-browser";
@Component({
  selector: 'mvs-dm-pdf-viewer',
  templateUrl: './dm-document-pdf-viewer.component.html',
  styleUrls: ['./dm-document-pdf-viewer.component.scss']
})
export class DmDocumentPdfViewerComponent implements OnInit{

  @Input() pdfSrc: any = null;
  @Input() width: number = 500;
  @Input() height: number = 600;
  @Input() objectId:number;

  constructor(protected documentService: DmDocumentService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (!this.objectId) {
      return
    }

    // let viewDoc: string = "#view=FitV&navpanes=0";

    this.documentService.downloadViaUrl(this.objectId).subscribe(document => {
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(document);
    })
  }

}
