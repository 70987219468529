<ng-container *ngIf="uiMode=='start'">
    <mvs-wf-process-start
            (onProcessCreated)="onProcessStarted($event)"
    ></mvs-wf-process-start>
</ng-container>

<ng-container *ngIf="uiMode=='process'">
    <mvs-wf-process
            *ngIf="startedProcessObjectIdentifier"
            [objectIdentifier]="startedProcessObjectIdentifier"
    ></mvs-wf-process>
</ng-container>







