import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {SignatureTypePage} from "./page/overview/signature-type/signature-type.page";


@NgModule({
  declarations: [
    SignatureTypePage,
  ],
  exports:[
    SignatureTypePage,
  ],
  imports: [
    CoreModule
  ]
})
export class SmModule { }
 
 
 
