@if (initialized) {
    <div class="flex align-items-center justify-content-between p-3">
        <h5 class="text-xl font-medium">Calculation Logic Type Step</h5>
        <i class="pi pi-plus cursor pointer text-primary" (click)="showStep()"></i>
    </div>
    <p-timeline [value]="steps" *ngIf="steps && steps.length" class="custom-timeline">
        <!-- Step Number Badge -->
        <ng-template let-step pTemplate="marker">
            <span class="p-timeline-event-marker step-badge cursor pointer"
                  (click)="showStep(step?.data?.id)">{{ step?.data?.orderPriority }}</span>
        </ng-template>

        <!-- Step Content -->
        <ng-template let-step pTemplate="content">
            <div class="timeline-step-container ml-2">
                <div class="step-details w-full">
                    <h5 class="text-lg mb-0 font-medium">{{ step?.label }}</h5>
                    <p class="text-color-secondary mb-5">{{ step?.description }}</p>

                    <!--                    <p-accordion [activeIndex]="[step?.index]" [multiple]="true">-->
                    <!--                    <p-accordionTab tabStyleClass="mb-1 shadow-none">-->
                    <!--                        <ng-template pTemplate="header">-->
                    <!--                            <div class="flex align-items-center" (click)="toggleStep(step?.index)">-->
                    <!--                                <i [class]=""></i>-->
                    <!--                                <span class="vertical-align-middle ml-2"> {{ expandedSteps[step?.index] ? 'Hide Mapping' : 'Show Mapping' }}</span>-->
                    <!--                            </div>-->
                    <!--                        </ng-template>-->
                    <!--                        <ng-template pTemplate="content">-->
                    <!--                            <div *ngIf="expandedSteps[step?.index]" class="step-accordion">-->
                    <!--                                <mvs-widget *ngIf="step?.widgetData" [widgetData]="step?.widgetData"></mvs-widget>-->
                    <!--                            </div>-->
                    <!--                        </ng-template>-->
                    <!--                    </p-accordionTab>-->

                    <!--                    <p-accordionTab tabStyleClass="mb-1 shadow-none">-->
                    <!--                        <ng-template pTemplate="header">-->
                    <!--                            <div class="flex align-items-center" (click)="toggleLogic(step?.index)">-->
                    <!--                                <i [class]=""></i>-->
                    <!--                                <span class="vertical-align-middle ml-2">-->
                    <!--                                    {{ expandedLogics[step?.index] ? 'Hide Calculation' : 'Show Calculation' }}-->
                    <!--                                </span>-->
                    <!--                            </div>-->
                    <!--                        </ng-template>-->
                    <!--                        <ng-template pTemplate="content">-->
                    <!--                            <div *ngIf="expandedLogics[step?.index]" class="step-accordion">-->
                    <!--                                <mvs-form-control-output-logic-display *ngIf="step?.logicId"-->
                    <!--                                                                       [value]="step?.logicId"></mvs-form-control-output-logic-display>-->
                    <!--                            </div>-->
                    <!--                        </ng-template>-->
                    <!--                    </p-accordionTab>-->
                    <!--                    </p-accordion>-->


                    <div class="mb-5">
                        <a class="font-normal text-primary-700 cursor" href="javascript:void(0)"
                           (click)="toggleStep(step?.index)">
                            <i class="mr-2"
                               [ngClass]="expandedSteps[step?.index] ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-right'"></i>
                            {{ expandedSteps[step?.index] ? 'Hide Mapping' : 'Show Mapping' }}
                        </a>
                        <!-- Conditionally Show Details -->
                        <div *ngIf="expandedSteps[step?.index]" class="step-accordion my-3 shadow-1">
                            <mvs-widget *ngIf="step?.widgetData" [widgetData]="step?.widgetData"></mvs-widget>
                        </div>
                    </div>

                    <div class="mb-5">
                        <a class="font-normal text-primary-700 cursor" href="javascript:void(0)"
                           (click)="toggleLogic(step?.index)">
                            <i class="mr-2"
                               [ngClass]="expandedLogics[step?.index] ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-right'"></i>
                            {{ expandedLogics[step?.index] ? 'Hide Calculation' : 'Show Calculation' }}
                        </a>
                        <!-- Conditionally Show Details -->
                        <div *ngIf="expandedLogics[step?.index]" class="step-accordion my-3 shadow-1">
                            <mvs-form-control-output-logic-display *ngIf="step?.logicId"
                                                                   [value]="step?.logicId"></mvs-form-control-output-logic-display>
                        </div>
                    </div>

                </div>


            </div>
        </ng-template>
    </p-timeline>
}