import {DtoDetail} from "@kvers/alpha-core-common";
import {CrEntityStatusEnum} from "../enum/cr-entity-status.enum";

export class CrCustomerDto extends DtoDetail {

    public alias : string;
    public startDate : Date;
    public endDate : Date;
    public customerTypeDtoId : number;
    public customerTypeDtoName : string;
    public customerTypeDtoImage : string;
    public customerTypeDtoColor : string;
    public entityStatus : CrEntityStatusEnum;
    public personDtoId : number;
    public personDtoName : string;
    public personDtoImage : string;
    public personDtoColor : string;
    public registrationDtoId : number;
    public registrationDtoName : string;
    public registrationDtoImage : string;
    public registrationDtoColor : string;
}