<!--<mvs-crud-page [objectStructureRequest]="objectBrowserRequest"-->
<!--               [defaultLabel]="defaultLabel">-->
<!--</mvs-crud-page>-->

<div *ngIf="initialized">
    <mvs-config-header header="UI Navigation Config"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidget()">
        <!--        <p-tabPanel header="Agenten" leftIcon="fa-thin fa-layer-group"></p-tabPanel>-->

        <p-tabPanel header="Pages"></p-tabPanel>
        <p-tabPanel header="Main Navigation"></p-tabPanel>
        <p-tabPanel header="Sub Navigation"></p-tabPanel>
        <p-tabPanel header="Assignment Page To Navigation"></p-tabPanel>

    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="uiPageWidget"
                        [widgetData]="uiPageWidget"
                        (onObjectSelect)="refreshObjectWidget($event.objectType, $event.objectId)"
                ></mvs-widget>
            </div>
<!--            <div class="col-8">-->

<!--                <mvs-widget-->
<!--                        *ngIf="objectWidget"-->
<!--                        [widgetData]="objectWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="uiNavigationMainWidget"
                        [widgetData]="uiNavigationMainWidget"
                        (onObjectSelect)="refreshObjectWidget($event.objectType, $event.objectId)"
                ></mvs-widget>
            </div>
<!--            <div class="col-8">-->

<!--                <mvs-widget-->
<!--                        *ngIf="objectWidget"-->
<!--                        [widgetData]="objectWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="uiNavigationSubWidget"
                        [widgetData]="uiNavigationSubWidget"
                        (onObjectSelect)="refreshObjectWidget($event.objectType, $event.objectId)"
                ></mvs-widget>

            </div>
<!--            <div class="col-8">-->

<!--                <mvs-widget-->
<!--                        *ngIf="objectWidget"-->
<!--                        [widgetData]="objectWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->

        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==3">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="uiNavigationSubPageWidget"
                        [widgetData]="uiNavigationSubPageWidget"
                        (onObjectSelect)="refreshObjectWidget($event.objectType, $event.objectId)"
                ></mvs-widget>

            </div>
<!--            <div class="col-8">-->

<!--                <mvs-widget-->
<!--                        *ngIf="objectWidget"-->
<!--                        [widgetData]="objectWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->

        </div>

    </ng-container>

</div>