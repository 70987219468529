@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
    <date-selection-dropdown
            [defaultOption]="'thisMonth'"
            (selectedDateStart)="handlePeriodFilter($event)"
    ></date-selection-dropdown>
    </div>
        <div class="grid dashboard">

            <!-- Charts Section -->
            <div class="col-12 md:col-9 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="contractSignedPerMonthWidget"
                        [widgetData]="contractSignedPerMonthWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                    <mvs-widget  class="surface-0 h-full block shadow-1 m-1"
                            *ngIf="contractStatusBreakdownWidget"
                            [widgetData]="contractStatusBreakdownWidget"
                    ></mvs-widget>
            </div>

            <!-- Upcoming Expirations Table -->
            <div class="col-12 md:col-8 mb-2">
                    <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                            *ngIf="contractExpirationWidget"
                            [widgetData]="contractExpirationWidget"
                    ></mvs-widget>
            </div>

            <!-- Contract Performance -->
            <div class="col-12 md:col-4 mb-2">

                <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                    <h4 class="text-xl font-medium">Contract Performance Overview</h4>
                    <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>
                </div>

            </div>

        </div>

}