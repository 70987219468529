import {DtoDetail} from "@kvers/alpha-core-common";

export class UiPageUserFavoriteDto extends DtoDetail {

    public favoritePageDtoId : number;
    public favoritePageDtoName : string;
    public favoritePageDtoImage : string;
    public favoritePageDtoColor : string;
    public user : string;
    public priority : number;

}