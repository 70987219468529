import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavigationItem, ObjectIdentifier, PageComponent, TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";

@Component({
    selector: 'lg-logic-page',
    templateUrl: './lg-logic-page.page.html',
    styleUrls: ['./lg-logic-page.page.scss']
})
export class LgLogicPagePage extends PageComponent implements OnInit, OnDestroy, OnChanges {


    objectIdentifier: ObjectIdentifier;

    queryParamSubscription: Subscription;
    activeNavItem: string;
    activeTabIndex: number = -1;
    activeWidget: TabEntries | null = null;
    uiWidgetEntries: TabEntries[];

    navigationComponent: 'Logic' | 'TestCases' = 'Logic';

    defaultLabel = 'Logics';

    navigationItems: NavigationItem[] = [
        {label: 'Logic',action: 'Logic', tooltip: 'Logic', icon: 'fa-regular fa-square-code', toggleable: false, default: true},
        {label: 'TestCases',action: 'TestCases', tooltip: 'Test Cases', icon: 'fa-regular fa-flask-vial', toggleable: false, default: false},

    ];

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService, protected router: Router,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        // Get the 'id' parameter from the route
        this.route.paramMap.subscribe(params => {
            const id = params.get('id'); // Retrieve the 'id' parameter
            this.objectIdentifier = new ObjectIdentifier('lg.Logic', Number(id));
        });

        this.subscribeToParams();

    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.initialized = false;
            let objectType = params['tid'];
            if (params['navItem']) {
                this.navigationComponent = params['navItem'];
            }

            if (objectType) {
                const object = this.uiWidgetEntries.find(item => item.id === objectType);
                if (object) {
                    this.activeTabIndex = this.uiWidgetEntries.findIndex(item => item.id === object.id);
                    this.activeWidget = object;
                }
            }
            //should be solved after primeng library update (tabPanel not resetting properly)
            setTimeout(() => {
                this.initialized = true;
            }, 10);
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)
    }

    ngOnDestroy() {
        super.ngOnDestroy()

        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

}
