import {Route, Routes} from "@angular/router";
import {XxConfigPage} from "./page/overview/config/xx-config.page";
import {XxTablePage} from "./page/overview/tables/xx-table.page";
import {XxWidgetPage} from "./page/overview/widgets/xx-widget.page";
import { XxTestComponentComponent} from "./page/testComponent/test-component.component";


export function XxSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        {path: 'xx/config', component: XxConfigPage},
        {path: 'xx/table', component: XxTablePage},
        {path: 'xx/testComponent', component: XxTestComponentComponent},
        {path: 'xx/widget', component: XxWidgetPage},
    ];

    return routes;

}
