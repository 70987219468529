@if (objectIdentifier) {
    @if (uiMode == 'side') {
        <cc-meta-extension-side
                [uiMode]="uiMode"
                [objectIdentifier]="objectIdentifier"
                [params]="params"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cc-meta-extension-side>
    } @else {
        <cc-meta-extension-full
                [uiMode]="uiMode"
                [objectIdentifier]="objectIdentifier"
                [params]="params"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cc-meta-extension-full>
    }
}