import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {NsNotificationService} from "../../../../service/api/ns-notification.service";
import {NsNotificationTypeChannelTypeService} from "../../../../service/api/ns-notification-type-channel-type.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: './notification-kpi.component.html',
})
export class NotificationKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    initialized: boolean;

    totalNotifications: number = 0;
    totalNotificationChannel: number = 0;
    thisMonthNotifications: number = 0;
    thisWeekNotifications: number = 0;


    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Notification Module'}];

    constructor(
        protected notificationService: NsNotificationService,
        protected notificationChannelTypeService: NsNotificationTypeChannelTypeService,) {
        super()
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalNotification();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalNotification() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['notificationStatus'],
            [new ObjectRequestListAttribute('notificationStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.notificationService.groupBy(objectRequestList).subscribe(res => {
            this.totalNotifications = res.entries.reduce((acc, item) => acc + item['notificationStatus_count'], 0);

            this.getTotalNotificationChannels();
        },error => {
            this.getTotalNotificationChannels();
        });
    }

    getTotalNotificationChannels() {

        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.notificationChannelTypeService.list(objectRequestList).subscribe(res => {
            this.totalNotificationChannel = res.entries.length;
            this.getThisMonthNotifications();
        },error => {
            this.getThisMonthNotifications();
        });
    }

    getThisMonthNotifications() {
        const objectRequestList = this.getThisMonthRequest('notificationStatus');
        this.notificationService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthNotifications = res.entries.reduce((acc, item) => acc + item['notificationStatus_count'], 0);
            this.getThisWeekLogins();
        }, error => {
            this.getThisWeekLogins();
        });
    }

    getThisWeekLogins() {
        const objectRequestList = this.getThisWeekRequest('notificationStatus');
        this.notificationService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekNotifications = res.entries.reduce((acc, item) => acc + item['notificationStatus_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Notifications', value: this.totalNotifications,
            },
            {
                label: 'Notification Channels', value: this.totalNotificationChannel,
            },
            {
                label: 'This Month Notifications', value: this.thisMonthNotifications,
            },
            {
                label: 'This Week Notifications', value: this.thisWeekNotifications,
            },
        ]
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
