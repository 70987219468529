import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CustomerContactPhoneInfoDto} from "../dto/customer-contact-phone-info.dto";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class CustomerContactService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerContacts');
  }

  /**
   * Get phone info.
   * @param customerId
   */
  public getPhoneInfo(customerId: number) : Observable<CustomerContactPhoneInfoDto> {

    const url = `${this.apiUrl}/phone/${customerId}`;

    return <Observable<CustomerContactPhoneInfoDto>>this.getInternal(url);

  }


}
