@if (initialized) {
    @if (uiMode == 'side') {
        <alpha-batch-entity-side-component
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></alpha-batch-entity-side-component>
    } @else {
        <alpha-batch-entity-full-component
                [objectIdentifier]="objectIdentifier"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></alpha-batch-entity-full-component>
    }
}

