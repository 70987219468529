import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {
    WfProcessConfigOverviewComponent
} from "../../component/wf-process-config-overview/wf-process-config-overview.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
    providedIn: 'root'
})
export class WfProcessTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/wf/processTypes');
    }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update) : Type<any> {
        return WfProcessConfigOverviewComponent;
    }

    public getFull(id: number, includeTemplate: boolean = false): Observable<WfProcessTypeDto> {


        const complexSelection = WfProcessTypeService.getFullComplexSelection();

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            includeTemplate,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
            null,
            null,
            null);
        //TODO: needs to be moved to crud service
        return this.list(objectRequestList).pipe(
            map(
                value => {
                    const dto = <WfProcessTypeDto>value.entries[0];
                    dto.form = value.form;
                    return dto;
                }
            ));

    }

    public static getFullComplexSelection():ObjectRequestComplex {
        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ...WfProcessTypeService.complexSelection()
            );
        return complexSelection;
    }

    public static complexSelection(): ObjectRequestComplexNode[] {
        return [ObjectRequestComplexNode.createRelationNode("steps",
            ObjectRequestRelation.createList(
                "wf.WfProcessTypeStep",
                "processType",
                null,
                [new Sorting("position", true)],
                ObjectRequestComplexRelationBindingEnum.ALL)
        ).addNodes(
            ObjectRequestComplexNode.createRelationNode("activities",
                ObjectRequestRelation.createList(
                    "wf.WfProcessTypeStepActivity",
                    "processTypeStep",
                    null,
                    [new Sorting("priority", true)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(
                ObjectRequestComplexNode.createRelationNode("formAttributeDefaults",
                    ObjectRequestRelation.createList(
                        "wf.WfProcessTypeStepActivityFormDefault",
                        "stepActivity",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ),
            ),
            ObjectRequestComplexNode.createRelationNode("nextSteps",
                ObjectRequestRelation.createList(
                    "wf.WfProcessTypeStepNext",
                    "processTypeStep",
                    null,
                    [new Sorting("position", true)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ),
            ObjectRequestComplexNode.createRelationNode("stepHints",
                ObjectRequestRelation.createList(
                    "wf.WfProcessTypeStepHint",
                    "processTypeStep",
                    null,
                    [new Sorting("priority", true)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("hintType"))
        ),
            ObjectRequestComplexNode.createRelationNode("fields",
                ObjectRequestRelation.createList(
                    "wf.WfProcessTypeField",
                    "type",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("fieldType")),
            ObjectRequestComplexNode.createRelationNode("results",
                ObjectRequestRelation.createList(
                    "wf.WfProcessTypeResult",
                    "processType",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        ];
    }
}
