<div class="h-full wf-side-activity-input-field-radio-background"
     (click)="onStartActivity()" id="subActivity">
        <div class="flex align-items-center gap-4" [pTooltip]="fullPage ? '' : activityType?.name" tooltipPosition="left">
            <div class="flex flex-column gap-2">
                <div class="flex gap-2 align-items-center" [ngClass]="fullPage ? '' : 'flex-column'">
                    <div
                            *ngIf="processMeta.internalActivityTypes[activityType.activityType].color &&
                            processMeta.internalActivityTypes[activityType.activityType].image"
                            class="wf-side-activity-input-field-icon-background"
                    >
                        <i [class]="processMeta.internalActivityTypes[activityType.activityType].image"
                           [style]="{color:'var(--'+processMeta.internalActivityTypes[activityType.activityType].color+')'}"></i>
                    </div>
<!--                    wf-side-activity-input-name-text-->
                    <span
                          [ngClass]="fullPage ? 'font-medium' : 'line-ellipses text-xs text-center'"
                          [ngStyle]="{'width': !fullPage ? 'calc(' + sectionWidth + 'px - 20px)' : 'auto'}"
                    >{{ activityType?.name }}</span>
                </div>
                <span *ngIf="activityType.description"
                      class="wf-side-activity-input-description-ellipses wf-side-activity-input-name-description text-color-secondary"
                      [innerHTML]="activityType.description | mvsSavePipe:'html'"></span>

            </div>
        </div>
</div>