<ng-container *ngIf="initialized">



</ng-container>


<div class="bg-white">
    <mvs-config-header header="Household Configuration" [categoryTypeMode]="'household'"
                       icon="fa-light fa-user">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'pm.Household'">
            <mvs-widget [widgetData]="householdWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
                        *ngIf="householdWidget">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'pm.HouseholdPerson'">

            <mvs-widget [widgetData]="householdPersonWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
                        *ngIf="householdPersonWidget">
            </mvs-widget>
        </ng-container>


    </ng-container>

</div>
