@if (activeNavigationItem.action == 'em.EmSchema') {
    <mvs-widget
            *ngIf="emSchemaWidget"
            [widgetData]="emSchemaWidget"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'em.EmSchemaField') {

    <div class="grid">
        <div class="col-9">
            <mvs-widget
                    *ngIf="emSchemaFieldWidget"
                    [widgetData]="emSchemaFieldWidget"
                    (onObjectSelect)="handleFieldSelect($event)"
            ></mvs-widget>
        </div>

        <div class="col-3">

            @if (selectField?.hasValueList) {

                <!--                Field: {{ selectField?.name }}-->
                <mvs-widget
                        *ngIf="emSchemaFieldValueWidget"
                        [widgetData]="emSchemaFieldValueWidget"
                ></mvs-widget>
            }
        </div>
    </div>
}

@if (activeNavigationItem.action == 'em.EmSchemaStructure') {
    <em-schema-structure [nodes]="nodes" (onNodeExpand)="onNodeExpand($event)"></em-schema-structure>
}

@if (activeNavigationItem.action == 'em.EmSchemaRuntimeData') {
    <em-schema-runtime-data></em-schema-runtime-data>
}
