<ng-container *ngIf="objectIdentifier && initialized">
    <p-tabView styleClass="bg-transparent-tabview" [(activeIndex)]="activeIndex">
        <p-tabPanel header="Overview">
            <ng-container *ngIf="activeIndex == 0">
                <kpi-container-component
                        *ngIf="moduleAlias"
                        [alias]="moduleAlias"
                ></kpi-container-component>
                <router-outlet></router-outlet>
            </ng-container>
        </p-tabPanel>
        <p-tabPanel header="Statistics">
            <ng-container *ngIf="activeIndex == 1">
                <statistics-container-component
                        *ngIf="moduleAlias"
                        [alias]="moduleAlias"
                ></statistics-container-component>
            </ng-container>
        </p-tabPanel>
    </p-tabView>
</ng-container>