import {Route, Routes} from "@angular/router";
import {PmConfigPage} from "./page/overview/pm-config/pm-config.page";
import {PersonSearchComponent} from "./component/person-search/person-search.component";
import {HouseholdConfigPage} from "./page/overview/household-config/household-config.page";
import {PmOverviewPage} from "./page/pm-overview-page/pm-overview.page";

export function PmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'pm', component: PmOverviewPage},
        {path: 'pm/config', component: PmConfigPage},
        {path: 'pm/household-config', component: HouseholdConfigPage},
        {path: 'pm/test', component: PersonSearchComponent},

    ];

    return routes;

}
