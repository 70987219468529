import {Component, OnInit} from '@angular/core';
import {MetaExtensionBaseComponent} from "../meta-extension-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";


@Component({
    selector: 'cc-meta-extension-full',
    templateUrl: 'meta-extension-full.component.html',
    styleUrls: ['meta-extension-full.component.scss'],
})
export class MetaExtensionFullComponent extends MetaExtensionBaseComponent implements OnInit  {


    navigationItems: NavigationItem[] = [
        {
            label: 'attributes',
            action: 'attributes',
            icon: 'fa-solid fa-list-ul',
            tooltip: 'Attributes',
            default: true
        },
        {
            label: 'joins',
            action: 'joins',
            icon: 'fa-solid fa-link',
            tooltip: 'Joins',
            default: false
        },
        {
            label: 'joinExtension',
            action: 'joinExtension',
            icon: 'fa-solid fa-code-branch',
            tooltip: 'Join Extension',
            default: false
        },
    ];

    ngOnInit() {
        super.ngOnInit();
    }
}
