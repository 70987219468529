import {Route, Routes} from "@angular/router";
import {IgDashboardComponent} from "./page/dashboard/ig-dashboard.component";

export function IgSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'ig/dashboard', component: IgDashboardComponent},

    ];

    return routes;

}
