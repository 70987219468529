import {Component, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-ns-dashboard-page',
    templateUrl: './ns-dashboard.page.html',
    styleUrls: ['./ns-dashboard.page.scss']
})
export class NsDashboardPage extends PageComponent {

    defaultLabel: string = "Notification Dashboard";

    widgetNotificationChannelHistory: WidgetData;
    widgetNotificationType: WidgetData;
    widgetNotificationRecipient: WidgetData;
    widgetNotifications: WidgetData;
    filterCriteria: FilterCriteria[];

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshNotificationChannel();

        this.refreshWidgetNotificationChannelRecipientHistory();

        this.refreshWidgetNotificationChannelRecipientChart();

        this.refreshWidgetNotifications();

        this.initialized = true;
    }

    refreshNotificationChannel() {
        this.widgetNotificationType = new WidgetData();
        this.widgetNotificationType.id = 1;
        this.widgetNotificationType.idAlias = "ns.notification.channel.type.dashboard.overview";
        this.widgetNotificationType.name = "Channel Overview";
        this.widgetNotificationType.uiComponent = "card";
        this.widgetNotificationType.dataProvider = "list";
        this.widgetNotificationType.dataSource = "entity.groupBy";
        this.widgetNotificationType.dataProviderObject = "ns.NsNotificationChannel";
        this.widgetNotificationType.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["channelType"],
            [new ObjectRequestListAttribute("channelType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.widgetNotificationType.setParamValue("fieldCategory", "channelType");
        this.widgetNotificationType.setParamValue("fieldCategoryCount", "channelType_count");
        this.widgetNotificationType.setParamValue("selectionMode", "single");
        this.widgetNotificationType.setParamValue("size", "S");

    }

    refreshWidgetNotificationChannelRecipientHistory() {

        this.widgetNotificationChannelHistory = new WidgetData();
        this.widgetNotificationChannelHistory.id = 1;
        this.widgetNotificationChannelHistory.idAlias = "ns.dashboard.notification.channel.history";
        this.widgetNotificationChannelHistory.name = "Channel Usage";
        this.widgetNotificationChannelHistory.uiComponent = "history.chart";
        this.widgetNotificationChannelHistory.dataProvider = "list";
        this.widgetNotificationChannelHistory.dataSource = "entity.groupBy";
        this.widgetNotificationChannelHistory.dataProviderObject = "ns.NsNotificationChannelRecipient";
        this.widgetNotificationChannelHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["notificationChannel", "createdDate"],
            [new ObjectRequestListAttribute("notificationChannel", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.widgetNotificationChannelHistory.setParamValue("fieldCategory", "notificationChannel");
        this.widgetNotificationChannelHistory.setParamValue("fieldCategoryCount", "notificationChannel_count");
        this.widgetNotificationChannelHistory.setParamValue("historyPeriodMode", "year");
        this.widgetNotificationChannelHistory.setParamValue("fieldDate", "createdDate");
        this.widgetNotificationChannelHistory.setParamValue("selectionMode", "single");
        this.widgetNotificationChannelHistory.setParamValue("size", "S");

    }

    refreshWidgetNotificationChannelRecipientChart() {

        this.widgetNotificationRecipient = new WidgetData();
        this.widgetNotificationRecipient.id = 1;
        this.widgetNotificationRecipient.idAlias = "ns.notification.recipient.group.by.notification";
        this.widgetNotificationRecipient.name = "Notification Recipient By Period";
        this.widgetNotificationRecipient.uiComponent = "category";
        this.widgetNotificationRecipient.dataProvider = "list";
        this.widgetNotificationRecipient.dataSource = "entity.groupBy";
        this.widgetNotificationRecipient.dataProviderObject = "ns.NsNotificationRecipient";
        this.widgetNotificationRecipient.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["objectType"],
            [new ObjectRequestListAttribute("objectType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);
        this.widgetNotificationRecipient.setParamValue("fieldCategory", "objectType");
        this.widgetNotificationRecipient.setParamValue("fieldCategoryCount", "objectType_count");
        this.widgetNotificationRecipient.setParamValue("selectionMode", "single");
        this.widgetNotificationRecipient.setParamValue("size", "S");
        this.widgetNotificationRecipient.setParamValue("fieldName", "objectType");
        this.widgetNotificationRecipient.setParamValue("sortAscending", true);

    }

    refreshWidgetNotifications() {
        this.widgetNotifications = new WidgetData();
        this.widgetNotifications.idAlias = "ns.notification.notification.list";
        this.widgetNotifications.name = "Notification Record";
        this.widgetNotifications.uiComponent = "table";
        this.widgetNotifications.dataProvider = "list";
        this.widgetNotifications.dataSource = "entity";
        this.widgetNotifications.dataProviderObject = "ns.NsNotification";
        this.widgetNotifications.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            this.filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5)
        );

        this.widgetNotifications.setParamValue("selectionMode", "rowSelect");
        this.widgetNotifications.setParamValue("rowSelectionMode", "sticky");
        this.widgetNotifications.setParamValue("size", "S");

    }

    handleCategoryStatusSelect(object: ObjectIdentifierData) {
        const url = '/#/ns/notification/' + object.objectId;
        window.open(url, '_blank');

    }

    onDateSelected(event: FilterCriteria) {
        this.filterCriteria = [];
        this.filterCriteria.push(event);
        this.refreshComponent();
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
