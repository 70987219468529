import {Component, OnDestroy, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MvsDashboardPage} from "../../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page";
import {
    DtoDetail, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    MvsFormValueListEntryDto,
    MvsObjectService,
    ObjectIdentifier,
    Sorting,
    WidgetData, WidgetHelperButton, WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {AgentDto} from "../../../../../am/dto/agent.dto";
import {AgentPoolDto} from "../../../../../am/dto/agent-pool.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../service/api/ticket.service";
import {AgentService} from "../../../../../am/service/api/agent.service";
import {AgentPoolService} from "../../../../../am/service/api/agent-pool.service";
import {TicketGroupService} from "../../../../service/api/ticket-group.service";
import {Observable, Subscription} from "rxjs";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestEntityProcessingTypeEnum} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {OptionGroups} from "../../../../../core/dashboard/interfaces/mvs-dashboard-option-groups.interface";
import {OptionGroupPlacementEnum} from "../../../../../core/dashboard/interfaces/option-group-placement.enum";
import {SubSelection} from "../../../../../core/dashboard/interfaces/mvs-dashboard-sub-selection.interface";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../../../enum/tm-status.enum";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {ObjectRequestComplexRelationBindingEnum} from "@kvers/alpha-core-common";


@Component({
    selector: 'cm-contract-dashboard-status',
    templateUrl: '../../../../../core/dashboard/page/dashboard-page/mvs-dashboard.page.html',
    styleUrls: ['./tm-me-dashboard.page.scss']
})
export class TmMeDashboardPage
    extends MvsDashboardPage implements OnDestroy {

    // Properties for the component
    defaultLabel: string = "Offene Statusübersicht";

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    // Arrays to store template data
    templateTicketStatus: MvsFormValueListEntryDto[]
    templateTicketUrgency: MvsFormValueListEntryDto[]

    // Properties related to the logged-in agent
    loggedOnAgent: AgentDto;
    loggedOnAgentPools: AgentPoolDto[];

    broadcastSubscription: Subscription;

    // Constructor for the component, injecting necessary services
    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected ticketService: TicketService,
        protected agentService: AgentService,
        protected agentPoolService: AgentPoolService,
        protected ticketGroupService: TicketGroupService,
        protected navigationService: MvsObjectNavigationService,
        protected objectService: MvsObjectService
    ) {

        super(route, router, coreService, navigationService)
    }


    ngOnInit() {
        super.ngOnInit();

        this.widgetToolbarButtons = [
            {
                label: null,
                icon: 'fa-regular fa-plus text-primary',
                display: true,
                action: 'ticket',
                tooltip: 'Create Ticket'
            }
        ];

        this.handleBroadcastChange();
    }

    /**
     *  Custom method to start the initialization process
     */
    startInit() {
        // we are not calling the super implementation as we need to
        // retrieve some additional information upfront.
        this.loadBasicData();
    }

    /**
     * Method to load basic data required for the component
     */
    loadBasicData() {

        this.fetchTicketTemplate();

    }


    /**
     * Refreshes the component by fetching ticket template and logged-on agent and setting the 'initialized' flag.
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * Method to get observable for main navigation
     */
    getMainNavigationObservable(): Observable<any> {
        return null;
    }

    /**
     * Return observable which returns the required data to show the top navigation.
     */
    getSubNavigationObservable(): Observable<any> {
        const filterCriteria = this.getSubNavigationFilter();

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteria,
            [],
            ['type'],
            [new ObjectRequestListAttribute('type', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.ticketService.groupBy(objectRequestList);

    }

    /**
     * Method to get observable for right navigation
     */
    getTopRightNavigationObservable(): Observable<any> {
        const filterCriteria = this.getTopRightNavigationFilter();

        if (!filterCriteria) {
            return null;
        }

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteria,
            [],
            ['urgency'],
            [new ObjectRequestListAttribute('urgency', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.ticketService.groupBy(objectRequestList);
    }

    /**
     * Method to get observable for load option groups
     */
    getOptionGroupsObservable(): Observable<any> {
        const filterCriteria = this.getOptionGroupsFilter();

        if (!filterCriteria) {
            return null;
        }

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteria,
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        return this.ticketService.groupBy(objectRequestList);

    }

    /**
     * Method to store main options
     * @param value
     */
    storeMainOptions(value: any) {
        this.mainSelections = [];

        this.mainSelections = this.loggedOnAgentPools.map(obj => ({
            key: obj.id,
            label: obj.name,
            icon: obj.image,
            subLabel: ''
        }));

        const item = {
            key: 0,
            label: "All",
            icon: '',
            subLabel: ''
        }

        this.mainSelections.unshift(item);


        const loggedOnAgentItem = {
            key: this.loggedOnAgent.id,
            label: "My Tickets",
            icon: '',
            subLabel: ''
        }

        this.mainSelections.push(loggedOnAgentItem);

    }

    /**
     * Method to store sub-navigation options
     * @param value
     */
    storeSubNavigation(value: any) {

        this.mergeCounts(this.subSelections, value.entries)

    }


    /**
     * Method to store right option groups
     * @param value
     */
    storeTopRightOptions(value: any) {

        // this.typeFilterSelections = [];
        //
        // this.typeFilterSelections = value.entries
        //     .filter(obj => obj.urgency !== undefined) // Filter out entries where urgency is undefined
        //     .map(obj => ({
        //         key: obj.urgency,
        //         label: this.templateTicketUrgency.find(res => res.key == obj.urgency)?.label,
        //         count: obj['urgency_count']
        //     }));
        //
        // const totalCount = this.typeFilterSelections.reduce((acc, item) => acc + item.count, 0);
        //
        // const item: GroupItem = {
        //     key: -1,
        //     label: 'All',
        //     count: totalCount,
        //     icon: ''
        // }
        //
        // this.typeFilterSelections.unshift(item)

    }

    /**
     * Method to store side option groups
     * @param value
     */
    storeOptionGroups(value: any) {

        const groupedItems = value.entries.map(obj => ({
            key: obj.status,
            label: this.templateTicketStatus.find(res => res.key == obj.status)?.label,
            count: obj['status_count'],
            icon: this.templateTicketStatus.find(res => res.key == obj.status)?.image
        }));

        const totalCount = groupedItems.reduce((acc, item) => acc + item.count, 0);

        const groupItemAll = [{
            key: -1,
            label: 'All',
            count: totalCount
        }];

        const optionGroupAll: OptionGroups = {
            key: 0,
            label: 'All',
            groupItems: groupItemAll,
            placement: OptionGroupPlacementEnum.regular
        };
        const optionGroup: OptionGroups = {
            key: 1,
            label: 'Ticket Status',
            groupItems: groupedItems,
            placement: OptionGroupPlacementEnum.regular
        };

        this.optionGroups = [optionGroupAll, optionGroup];

    }


    /**
     * Helper method to merge counts in the menu structure
     * @param menuStructure
     * @param countData
     */


    mergeCounts(menuStructure: SubSelection[], countData: any[]): SubSelection[] {
        const countMap: Map<any, number> = new Map<any, number>();

        // Create a map of typeDtoId to type_count
        countData.forEach(dtoDetail => {
            dtoDetail.typeDtoId = 't_' + dtoDetail.typeDtoId
            countMap.set(dtoDetail.typeDtoId, dtoDetail.type_count);
        });


        // Update counts and calculate sums in the menu structure
        menuStructure.forEach(menu => this.updateCount(menu, countMap));

        return menuStructure;
    }

    updateCount(menu: SubSelection, countMap: Map<any, number>) {
        if (menu.items && menu.items.length > 0) {
            // The menu has child items
            menu.count = 0; // Initialize count to 0
            menu.items.forEach(item => {
                this.updateCount(item, countMap);
                menu.count += item.count || 0;
            });
        } else {
            // The menu is a leaf node
            const typeDtoId = menu.key;
            menu.count = countMap.get(typeDtoId) || 0;
        }
    }

    /**
     * Helper method to handle filter criteria based on statusTypeGroupByList and subSelectionKey.
     */
    private createBaseFilter(): FilterCriteria[] {
        const filters: FilterCriteria[] = [];

        if (this.loggedOnAgent && this.mainSelection?.key == this.loggedOnAgent?.id) {
            filters.push(FilterCriteria.createSingleCondition("assigneeAgent", FilterCriteria.cOperatorEqual, this.mainSelection?.key, null));
        } else if (this.mainSelection?.key) {
            filters.push(FilterCriteria.createSingleCondition("assigneeAgentPool", FilterCriteria.cOperatorEqual, this.mainSelection?.key, null));
        } else {
            const filter: FilterCriteria[] = [];
            for (let loggedOnAgentPool of this.loggedOnAgentPools) {
                filter.push(FilterCriteria.createSingleCondition("assigneeAgentPool", FilterCriteria.cOperatorEqual, loggedOnAgentPool.id, null));
            }
            filter.push(FilterCriteria.createSingleCondition("assigneeAgent", FilterCriteria.cOperatorEqual, this.loggedOnAgent.id, null));
            filters.push(FilterCriteria.createOr(...filter));


        }


        if (this.subSelection && this.subSelection?.key) {
            let filter;
            if (this.subSelection?.items) {
                filter = this.handleSubSelectionNestedItems(this.subSelection?.items);
            }

            if (this.subSelection?.field == 'group' && this.subSelection?.items) {
                filters.push(FilterCriteria.createOr(...filter));
            } else if (this.subSelection?.field == 'type') {
                // Extract the numeric part of the key by removing the prefix
                let keyWithoutPrefix = this.subSelection?.key;

                // Check if the key starts with "g_" or "t_"
                if (this.subSelection?.key.startsWith('g_') || this.subSelection.key.startsWith('t_')) {
                    // Remove the first two characters (the prefix)
                    keyWithoutPrefix = this.subSelection?.key.substring(2);
                }
                filters.push(FilterCriteria.createSingleCondition(this.subSelection?.field, FilterCriteria.cOperatorEqual, keyWithoutPrefix, null));
            }
        }

        filters.push(FilterCriteria.createSingleCondition("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved, null));
        filters.push(FilterCriteria.createSingleCondition("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled, null));

        return filters;
    }

    handleSubSelectionNestedItems(subMenus: SubSelection[]): FilterCriteria[] {
        const filters: FilterCriteria[] = [];

        if (subMenus && subMenus.length) {
            for (let subMenu of subMenus) {
                if (subMenu.items && subMenu.items.length > 0) {
                    // If the current subMenu has nested subMenus, recursively create filters for them
                    const nestedFilters = this.handleSubSelectionNestedItems(subMenu.items);
                    filters.push(...nestedFilters);
                }

                // Extracting key without prefix
                let keyWithoutPrefix = subMenu.key;

                // Check if the key starts with "g_" or "t_"
                if (subMenu.key.startsWith('g_') || subMenu.key.startsWith('t_')) {
                    // Remove the prefix
                    keyWithoutPrefix = subMenu.key.substring(2);
                }

                filters.push(FilterCriteria.createSingleCondition(subMenu.field, FilterCriteria.cOperatorEqual, +keyWithoutPrefix, null));

            }
        }
        return filters;
    }


    /**
     * Private method to construct filter criteria for top navigation
     * @private
     */
    private getSubNavigationFilter(): FilterCriteria[] {
        const filters = this.createBaseFilter();

        // Return the constructed filters or handle no filters if the length is 0
        return filters.length > 0 ? filters : this.handleNoFilters();
    }

    /**
     * Private method to construct filter criteria for top right navigation
     * @private
     */
    private getTopRightNavigationFilter(): FilterCriteria[] {
        const filters = this.createBaseFilter();

        // Return the constructed filters or handle no filters if the length is 0
        return filters.length > 0 ? filters : this.handleNoFilters();
    }

    /**
     * Private method to construct filter criteria for option groups
     * @private
     */
    private getOptionGroupsFilter(): FilterCriteria[] {

        const filters = this.createBaseFilter();

        // Add a condition to filter by urgency if the type filter selection is present

        if (this.typeFilterSelection && this.typeFilterSelection.key >= 0) {
            filters.push(FilterCriteria.createSingleCondition("urgency", FilterCriteria.cOperatorEqual, this.typeFilterSelection.key, null));
        }

        // Return the constructed filters or handle no filters if the length is 0
        return filters.length > 0 ? filters : this.handleNoFilters();
    }


    /**
     * Private method to construct filter criteria for the widget
     * @private
     */
    private getWidgetFilter(): FilterCriteria[] {

        const filters = this.getOptionGroupsFilter();

        // Add conditions to filter by status and urgency if the selections are present
        if (this.optionGroupSelection && this.optionGroupSelection?.key >= 0) {
            filters.push(FilterCriteria?.createSingleCondition("status", FilterCriteria?.cOperatorEqual, this.optionGroupSelection?.key, null));
        }

        // Return the constructed filters or handle no filters if the length is 0
        return filters.length > 0 ? filters : this.handleNoFilters();
    }


    /**
     * Private method to handle the case when no filters are available
     * @private
     */
    private handleNoFilters(): FilterCriteria[] {
        // Display an error message and return null
        this.messageService.showErrorMessage("Error", "Keine Statuseinträge vorhanden!");
        return null;
    }


    /**
     * Refreshes the widget based on the provided filter criteria.
     */
    refreshWidget() {
        let filter = this.getWidgetFilter();

        if (!this.initialized) {
            filter = this.createBaseFilter();
        }


        const objectRequest = ObjectRequestList.createWithPaging(
            true,
            filter,
            [new Sorting('actionMandatoryPendingCount', false), new Sorting('dueDate', true), new Sorting('urgency', false)],
            PagingDto.create(0, 10),
            ObjectRequestEntityProcessingTypeEnum.regular);


        // if (this.widgetData && JSON.stringify(this.widgetData.dataProviderListRequest) === JSON.stringify(objectRequest)) {
        //     return;
        // }

        let name = 'Tickets';

        if (this.subSelectionKey.startsWith('t_')) {
            for (let subSelection of this.subSelections) {
                if (subSelection?.items?.length > 0) {
                    const found = subSelection?.items?.find(selection => selection.key == this.subSelectionKey);
                    if (found) {
                        name = found.label;
                        break;
                    }
                }
            }
        }

        this.widgetData = WidgetFactory.createWidgetTableEntity(
            "tm.ticket.status.overview.dashboard.show.ticketss",
            name,
            "tm.Ticket",
            "Keine Daten vorhanden",
            objectRequest
        )

        this.widgetData.functionCallbacks = this.widgetToolbarCreate()


        this.refreshComponent();

    }


    widgetToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                const formControlOverwrite = new MvsFormControlOverwrite();

                if (this.subSelectionKey) {
                    let id: number | undefined;

                    if (this.subSelectionKey.startsWith('t_')) {
                        id = Number(this.subSelectionKey.substring(2));
                        dto['typeDtoId'] = id;
                        // formControlOverwrite.addField(`typeDtoId`, MvsFormFieldAccessEnum.READ);
                    }
                }

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

    /**
     * Method to fetch ticket template data
     */
    fetchTicketTemplate() {

        this.ticketService.template(new DtoTemplate()).subscribe(res => {
            this.templateTicketStatus = res.getFormField('status').valueList.entries;
            this.templateTicketUrgency = res.getFormField('urgency').valueList.entries;
            this.fetchTicketGroups();
        })
    }

    /**
     * Method to fetch ticket groups
     */
    fetchTicketGroups() {

        const dtoRequest = new ObjectRequestList(
            false,
            [],
            [])

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createRelationNode("ticketTypes",
                ObjectRequestRelation.createList(
                    "tm.TicketType",
                    "ticketGroup",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
        );

        this.ticketGroupService.list(dtoRequest).subscribe(res => {
            // Convert the API response to a format suitable for display
            this.subSelections = this.convertToSubSelection(res.entries);

            const item = {
                key: 'g_0',
                label: 'All',
                field: "group"

            }

            this.subSelections.unshift(item);

            // Fetch additional data related to logged-in agent
            this.fetchLoggedOnAgent();
        })
    }

    /**
     * Method to fetch information about the logged-on agent
     */
    fetchLoggedOnAgent() {

        this.agentService.me().subscribe(res => {
            this.loggedOnAgent = res;
            this.fetchLoggedOnAgentPools()
        })
    }

    /**
     * Method to fetch information about the logged-on agent's pools
     */
    fetchLoggedOnAgentPools() {

        this.agentPoolService.my().subscribe(res => {
            this.loggedOnAgentPools = res.entries;

            // if (this.mainSelectionKey == 0 && this.subSelectionKey == 'g_0' && (!this.optionKey || this.optionKey == -1)) {
            //     this.refreshWidget();
            // } else {
            //     this.postInitialized = true;
            // }


            // now, we continue with the general lifecycle of the Dashboard
            this.initComponent();
        })
    }

    /**
     * convert the ticket group data intp proper SubSelection
     * @param data
     */
    convertToSubSelection(data: any[]): SubSelection[] {

        // Initialize an object to store grouped data using keys
        const groupedData: { [key: number]: SubSelection } = {};

        // Iterate through the raw data and organize it into SubSelection format
        data.forEach((entry) => {
            const dtoDetail = entry;
            // Recursively convert child ticket types if they exist
            const child = dtoDetail.ticketTypes
                ? this.convertToSubSelection(dtoDetail.ticketTypes)
                : undefined;

            // Determine whether the current entry is a group or a type
            const isGroup = dtoDetail.ticketGroupDtoId === undefined;
            let key;
            if (isGroup) {
                key = 'g_' + dtoDetail.id;
            } else {
                key = 't_' + dtoDetail.id;
            }

            // Create a SubSelection object based on the current entry
            const subSelection: SubSelection = {
                key: key,
                label: dtoDetail.name,
                items: child,
                icon: dtoDetail.image,
                field: isGroup ? 'group' : 'type', // Additional field to indicate type or group
            };

            // If there is a parent, link the current SubSelection to its parent
            if (dtoDetail.parentDtoId) {

                const parentKey = dtoDetail.parentDtoId;

                // Check if parentKey exists in the root level of groupedData
                if (!groupedData[parentKey]) {

                    // Find the existing parent entry
                    const existingParentEntry = this.findEntryByKey(Object.values(groupedData), parentKey);


                    if (existingParentEntry) {
                        // If found, add the SubSelection to the items of the existing parent entry
                        if (!existingParentEntry.items) {
                            existingParentEntry.items = [];
                        }
                        existingParentEntry.items.push(subSelection);
                    }
                }

                // Ensure the parentKey exists and has an items array
                if (groupedData[parentKey] && !groupedData[parentKey].items) {
                    groupedData[parentKey].items = [];
                }

                // Add the current SubSelection to the items of the parent entry
                if (groupedData[parentKey]) {
                    groupedData[parentKey].items.push(subSelection);
                }
            } else {
                // If there's no parentDtoId, add it to the root level
                groupedData[dtoDetail.id] = subSelection;
            }
        });

        // Convert the groupedData object to an array of SubSelections and return
        return Object.values(groupedData);
    }

    // Function to find an entry by key within the entries
    findEntryByKey(entries: SubSelection[], key: number): SubSelection | undefined {
        for (const entry of entries) {
            if (entry.key === key) {
                return entry;
            }
            if (entry.items) {
                const foundInItems = this.findEntryByKey(entry.items, key);
                if (foundInItems) {
                    return foundInItems;
                }
            }
        }
        return undefined;
    }

    handleRowAction(object: ObjectIdentifier, location: "left" | "right" | "bottom" | "main" = 'right', openObject: boolean = true) {
        super.handleRowAction(object, location, openObject);
    }

    handleBroadcastChange() {
        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.objectType == "tm.Ticket") {
                this.initComponent();
            }
        });
    }

    handleToolbarButtonClick(event: WidgetHelperButton) {
        if (event.action == 'ticket') {
            const object = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', null, null, null, null, null, MvsObjectNavigationActionEnum.any, 'side');
            this.navigationService.navigateTo(object, 'right');
        }
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

}
