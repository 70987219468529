import {Component} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../../process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {WfProcessTypeStepNextDto} from "../../../dto/wf-process-type-step-next.dto";

@Component({
    selector: 'mvs-wf-process-show-main-activities',
    templateUrl: './wf-process-step-activity-main-panel.component.html',
})
export class WfProcessStepActivityMainPanelComponent extends WfProcessStepActivityBaseComponent {

    onContinue(event: WfProcessStepActivityEvent) {
        this.onAction.emit(event);
    }

    onSkipStep(event: WfProcessStepActivityEvent) {
        event['actionType'] = 'cancel';
        this.onSkip.emit(event);
    }

    onNextStep(event: WfProcessStepActivityEvent) {
        this.onNextStepSelect.emit(event);
    }

    handleProcessCreated(event: WfProcessStepActivityEvent) {
        this.onProcessCreated.emit(event);
    }

}
