import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {UserObjectAccess} from "../interface/user-object-access.interface";
import {UserObjectAccessRoleEnum} from "../enum/user-object-access-role.enum";

@Component({
    selector: 'mvs-avatar-list',
    templateUrl: './mvs-avatar-list.component.html',
    styleUrls: ['./mvs-avatar-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MvsAvatarListComponent implements OnInit, OnChanges {

    @Input() visibleUsers: UserObjectAccess[];
    @Input() overlayUsers: UserObjectAccess[];
    @Input() remainingUserCount: number;
    @Input() maxVisibleAvatars: number;
    @Input() size: string = 'S';

    initialized: boolean;

    uiVisibleUsers: { user: UserObjectAccess, activeUser: boolean, size: string, name: string }[];

    @Output() onUserSelected: EventEmitter<UserObjectAccess> = new EventEmitter();

    ngOnInit() {
        this.uiVisibleUsers = []
        for (let visibleUser of this.visibleUsers) {

            const activeUser = visibleUser.role == UserObjectAccessRoleEnum.WRITE;
            const user = {user: visibleUser, activeUser: activeUser, size: this.size, name: visibleUser.name};
            this.uiVisibleUsers.push(user)
        }

        this.initialized = true;

    }

    handleUserChanges(): void {
        // Map `uiVisibleUsers` by user ID for quick lookup
        const currentUsersMap = new Map(this.uiVisibleUsers.map((u, index) => [u.user.id, { user: u, index }]));

        // Step 1: Add or update users
        for (const visibleUser of this.visibleUsers) {
            const existingEntry = currentUsersMap.get(visibleUser.id);

            if (existingEntry) {
                const { user: existingUiUser, index } = existingEntry;

                // Check if properties have changed
                if (
                    existingUiUser.user.name !== visibleUser.name ||
                    existingUiUser.user.role !== visibleUser.role ||
                    existingUiUser.size !== this.size ||
                    existingUiUser.name !== visibleUser.name
                ) {
                    // Update the existing user
                    this.uiVisibleUsers[index] = {
                        user: visibleUser,
                        activeUser: visibleUser.role === UserObjectAccessRoleEnum.WRITE,
                        size: this.size,
                        name:visibleUser.name
                    };
                }
                // If no changes, do nothing
            } else {
                // New user: Push to `uiVisibleUsers`
                this.uiVisibleUsers.push({
                    user: visibleUser,
                    activeUser: visibleUser.role === UserObjectAccessRoleEnum.WRITE,
                    size: this.size,
                    name:visibleUser.name
                });
            }
        }

        // Step 2: Remove users no longer in `visibleUsers`
        const visibleUserIds = new Set(this.visibleUsers.map(u => u.id));
        for (let i = this.uiVisibleUsers.length - 1; i >= 0; i--) {
            const uiUser = this.uiVisibleUsers[i];
            if (!visibleUserIds.has(uiUser.user.id)) {
                // Remove the user from `uiVisibleUsers`
                this.uiVisibleUsers.splice(i, 1);
            }
        }
    }



    handleUserSelected(user: UserObjectAccess): void {
        this.onUserSelected.emit(user)
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes.visibleUsers) {
            this.handleUserChanges();
        }

    }

    protected readonly UserObjectAccessRoleEnum = UserObjectAccessRoleEnum;
}
