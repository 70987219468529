import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerDto } from "./customer.dto";
import { CustomerRelatedPersonTypeDto } from "./customer-related-person-type.dto";
import {PmPersonDto} from "./pm-person.dto";

export class CustomerRelatedPersonDto extends DtoDetail{


    public customerDto? : CustomerDto;
    public customerDtoId : number;
    public customerDtoName : string;
    public personDtoId : number;
    public personDto? : PmPersonDto;
    public personDtoName : string;
    public relatedPersonTypeDto? : CustomerRelatedPersonTypeDto;
    public relatedPersonTypeDtoId : number;
    public relatedPersonTypeDtoName : string;
    public startDate: Date;
    public endDate: Date;
    public color: string;



}