import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import {TenantService} from "@kvers/alpha-auth";
import {MvsCoreService, ObjectIdentifier, TopbarService, WidgetHelperButton} from "@kvers/alpha-core-common";
import {IndicatorButton} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {UmUserService} from "./um/service/api/um-user.service";
import {UserProfileHelper} from "./um/components/user-profile/helper/user-profile-helper";
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ],
    styles: ['.topbar {\n' +
    '  display: flex;\n' +
    '  align-items: center;\n' +
    '  padding: 10px;\n' +
    '  background-color: #f1f1f1;\n' +
    '}\n' +
    '\n' +
    '.overlay-item {\n' +
    '  cursor: pointer;\n' +
    '  padding: 5px 10px;\n' +
    '  margin: 0 5px;\n' +
    '}\n' +
    '\n' +
    '.overlay-item.active {\n' +
    '  font-weight: bold;\n' +
    '  color: #007bff;\n' +
    '}']
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    tenantLogo?: string = undefined;
    profilePicture: string;

    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        protected topbarService: TopbarService,
        protected navigationService: MvsObjectNavigationService,
        protected userService: UmUserService,
        protected coreService: MvsCoreService,
        private tenantService: TenantService) {
    }

    activeItem: number;
    buttons: IndicatorButton[] = [];
    indicatorSubscription: Subscription;
    currentOverlay: MvsObjectNavigationEntry[] = [];
    displayStack: MvsObjectNavigationEntry[] = [];
    objectLabel: string;
    activeOverlay: MvsObjectNavigationEntry;
    activeOverlayIndex: number;
    profileSubscription: Subscription;

    model: MegaMenuItem[] = [
        {
            label: 'Quick Access',
            items: [
                [
                    {
                        label: 'END USER APP',
                        items: [
                            { label: 'Konfiguration', icon: 'pi pi-fw pi-id-card', routerLink: ['/eu/euAppConfig'] },
                        ]
                    },
                    {
                        label: 'IMPORTER',
                        items: [
                            { label: 'Dashboard', icon: 'pi pi-fw pi-file', routerLink: ['/im/dashboard'] },
                            { label: 'Importieren', icon: 'pi pi-fw pi-file-import', routerLink: ['/im/dataImport'] },
                        ]
                    }
                ],
            ]
        },
        {
            label: 'Entity Browser',
            routerLink: ['/cc/entities']
        },
    ];

    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    ngOnInit() {

        this.tenantLogo = this.tenantService.getTenantInfo().logo;

        this.indicatorSubscription = this.topbarService.coreIndicatorButtonSubscription.subscribe((buttons) => {
            this.buttons = buttons;
        });

        this.navigationService.navigationObjectOverlay.subscribe(navItem => {
            this.refreshOverlayObject(navItem);
        });

        this.userService.getPictureBinary().subscribe(res => {
            UserProfileHelper.getImageSrcFromBlob(res).then(imageSrc => {
                this.profilePicture = imageSrc;
            });
        });

        this.profileSubscription = this.userService.profileSubscription.subscribe(res => {
            this.profilePicture = res;
        });
    }

    refreshOverlayObject(navItem: MvsObjectNavigationEntry[]) {
        if (this.currentOverlay.length == navItem.length) {
            return;
        }
        if (!navItem || !navItem.length) {
            this.objectLabel = null;
            this.currentOverlay = [];
        } else {
            this.objectLabel = this.navigationService.objectLabel;
            this.currentOverlay = navItem;
            this.handleDisplayStack();
            this.activeOverlay = this.navigationService.getActiveOverlay();
            this.activeOverlayIndex = this.navigationService.activeOverlayIndex;
            this.getLabels();
        }
    }

    handleDisplayStack() {
        for (let item of this.currentOverlay) {
            const exists = this.checkInDisplayStack(item.objectIdentifier);
            if (!exists) {
                this.displayStack.push(item);
            }
        }
    }

    checkInDisplayStack(objectIdentifier: ObjectIdentifier) {
        for (let item of this.displayStack) {
            const isSame = ObjectIdentifier.compare(item.objectIdentifier, objectIdentifier);
            if (isSame) {
                return true;
            }
        }
        return false;
    }

    getLabels() {
        for (let item of this.currentOverlay) {

            if (item['label']) {
                return;
            }

            const crudService = this.coreService.getCrudService(item.objectIdentifier.objectType);
            const objectLabels: string[] = crudService.getObjectLabels();
            crudService.get(item.objectIdentifier.objectId).subscribe(res => {

                for (let label of objectLabels) {
                    if (res[label]) {
                        item['label'] = res[label];
                        return;
                    }
                }
            });

        }
    }

    toggleOverlay(index: number): void {
        this.navigationService.setActiveOverlay(index);
        this.activeOverlay = this.navigationService.getActiveOverlay();
        this.activeOverlayIndex = this.navigationService.activeOverlayIndex;
    }

    ngOnDestroy() {
        this.indicatorSubscription.unsubscribe();

        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    onSearchAnimationEnd(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }

    handleHomePageNavigation() {
        this.navigationService.navigateToHomePage();
    }

    onNavigateToMainPage() {
        this.displayStack = [];
        this.navigationService.clearOverlay();
    }

    handleRemoveItem(index: number) {
        this.displayStack.splice(index,1);
        this.navigationService.removeOverlay(index);
    }

    handleNavigationToProfile() {
        const url = '/user/profile';
        this.navigationService.handlePageNavigation(url);
    }

    createTicket() {
            const object = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', null,null, null, null, null, MvsObjectNavigationActionEnum.any, 'light');
            this.navigationService.navigateTo(object, 'right');
    }

}
