import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";


@NgModule({
  declarations: [

  ],
  exports:[

  ],
  imports: [
    CoreModule
  ]
})
export class OsModule { }
 
 
 
