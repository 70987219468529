@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
    <date-selection-dropdown
            [defaultOption]="'thisMonth'"
            (selectedDateStart)="handlePeriodFilter($event)"
    ></date-selection-dropdown>
    </div>

        <div class="grid dashboard">

            <div class="col-12 md:col-6 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="importHistoryWidget"
                        [widgetData]="importHistoryWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="importsBySystemWidget"
                        [widgetData]="importsBySystemWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="importRecordsByImportWidget"
                        [widgetData]="importRecordsByImportWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-8 mb-2">
                <mvs-widget class="surface-0 h-full block shadow-1 m-1"
                        *ngIf="recentlyAddedImportsWidget"
                        [widgetData]="recentlyAddedImportsWidget"
                ></mvs-widget>
            </div>


            <div class="col-12 md:col-4 mb-2">
                <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                    <h4 class="text-lg font-medium">Registration Overview</h4>
                    <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>
<!--                <p-panel header="Registration Overview">-->
<!--                    <div class="flex h-full w-full gap-2">-->
<!--                        <div class="card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ pending }}</h4>-->
<!--                            <p>Pending Registrations</p>-->
<!--                        </div>-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ processed }}</h4>-->
<!--                            <p>Processed Registrations</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex h-full w-full gap-2">-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ inProcess }}</h4>-->
<!--                            <p>In-Process Registrations</p>-->
<!--                        </div>-->
<!--                        <div class=" card h-full w-full p-8 text-center">-->
<!--                            <h4>{{ unfinished }}</h4>-->
<!--                            <p>Unfinished Registrations</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </p-panel>-->
            </div>
            </div>

        </div>

}