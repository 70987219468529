<div class="flex" *ngIf="initialized">

    <mvs-crud-one-to-many-multi-select
            [mainObject]="objectIdentifier"
            [linkedEntityObjectType]="linkedEntityObjectType"
            [linkedEntityTargetObjectType]="linkedEntityTargetObjectType"
            [linkedEntityTargetAttributeName]="linkedEntityTargetAttributeName"
            [fieldName]="fieldName"
            [showObjectType]="false"
            (onSuccessCreate)="handleCreate($event)"
            class="w-full">
    </mvs-crud-one-to-many-multi-select>

</div>
