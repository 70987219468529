<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-3">


            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Ticket Details">
                    <mvs-widget *ngIf="customerWidget" [widgetData]="customerWidget"></mvs-widget>
                </p-accordionTab>
                <p-accordionTab header="Times">
                    Times
                </p-accordionTab>
                <p-accordionTab header="Previous History">
                    Previous History
                </p-accordionTab>
            </p-accordion>

        </div>
        <div class="col-9">
            <am-agent-map *ngIf="customersCoords" [customersCoords]="[customersCoords]"></am-agent-map>
        </div>
    </div>
</ng-container>