<div class="bg-white mt-5" *ngIf="initialized">
    <mvs-config-header header="Vertragskonfiguration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of uiWidgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2">
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                        </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">


        <!--        <ng-container *ngIf="activeWidget.id == 'cm.ContractBaseType'">-->
        <!--            <mvs-widget *ngIf="cmContractStatusTypeWidget"-->
        <!--                        [widgetData]="cmContractStatusTypeWidget"-->
        <!--                        (onObjectSelect)="handleTemplateSelect($event)">-->
        <!--            </mvs-widget>-->
        <!--        </ng-container>-->

        <!--        <ng-container *ngIf="activeWidget.id == 'cm.ContractBaseTypeReason'">-->
        <!--            <mvs-widget *ngIf="cmContractStatusTypeWidget"-->
        <!--                        [widgetData]="cmContractStatusTypeWidget"-->
        <!--                        (onObjectSelect)="handleTemplateSelect($event)">-->
        <!--            </mvs-widget>-->
        <!--        </ng-container>-->


        <ng-container *ngIf="activeWidget.id == 'cm.ContractStatusType'">
            <mvs-widget *ngIf="cmContractStatusTypeWidget"
                        [widgetData]="cmContractStatusTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'cm.ContractStatusTypeEntry'">
            <mvs-widget *ngIf="cmContractStatusTypeEntryWidget"
                        [widgetData]="cmContractStatusTypeEntryWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'cm.PriceReason'">
            <mvs-widget *ngIf="cmPriceReasonWidget"
                        [widgetData]="cmPriceReasonWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'cm.ContractNotificationType'">
            <mvs-widget *ngIf="cmContractNotificationTypeWidget"
                        [widgetData]="cmContractNotificationTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'cm.ContractOriginType'">
            <mvs-widget *ngIf="cmContractOriginTypeWidget"
                        [widgetData]="cmContractOriginTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'cm.ContractCategoryType'">
            <mvs-widget *ngIf="cmContractCategoryTypeWidget"
                        [widgetData]="cmContractCategoryTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="activeNavItem && activeNavItem == 'contractStructure'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmCategoryWidget"
                                [widgetData]="cmCategoryWidget"
                                (onObjectSelect)="handleCategorySelect($event)">
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">
                <!-- Tab Panels Now -->
                <div class="shadow-1 flex flex-column gap-3">

                    <p-tabView [(activeIndex)]="activeTabIndex">
                        <p-tabPanel header="Vertragstypen">
                            <mvs-widget
                                    *ngIf="cmContractTypeWidget"
                                    [widgetData]="cmContractTypeWidget"
                                    (onObjectSelect)="handleContractTypeSelect($event)">
                            </mvs-widget>

                            <mvs-widget
                                    *ngIf="cmContractTypeAmountTypeFieldWidget"
                                    [widgetData]="cmContractTypeAmountTypeFieldWidget"
                                    (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>

                            <mvs-widget
                                    *ngIf="cmContractTypeFieldWidget"
                                    [widgetData]="cmContractTypeFieldWidget"
                                    (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>

                        </p-tabPanel>

                        <p-tabPanel header="Sparte">
                            <mvs-widget
                                    *ngIf="cmGroupWidget"
                                    [widgetData]="cmGroupWidget"
                                    (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>
                        </p-tabPanel>

                        <p-tabPanel header="Sparte Beitragskonfiguration">
                            <mvs-widget
                                    *ngIf="cmGroupAmountTypeWidget"
                                    [widgetData]="cmGroupAmountTypeWidget"
                                    (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>
                        </p-tabPanel>

                        <p-tabPanel header="Produkt">
                            <mvs-widget
                                    *ngIf="cmProductWidget"
                                    [widgetData]="cmProductWidget"
                                    (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>
                        </p-tabPanel>
                    </p-tabView>

                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem && activeNavItem == 'rating'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmRatingTypeWidget"
                                [widgetData]="cmRatingTypeWidget"
                                (onObjectSelect)="handleRatingTypeSelect($event)">
                    </mvs-widget>
                </div>
            </div>

            <div class="col-9">
                <div class="shadow-1">
                    <p-tabView [(activeIndex)]="activeTabIndex">
                        <p-tabPanel header="Rating">
                            <mvs-widget *ngIf="cmRatingWidget"
                                        [widgetData]="cmRatingWidget"
                                        (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>
                        </p-tabPanel>
                        <p-tabPanel header="Rating Type - Object Type">
                            <mvs-widget *ngIf="cmRatingTypeObjectTypeWidget"
                                        [widgetData]="cmRatingTypeObjectTypeWidget"
                                        (onObjectSelect)="handleTemplateSelect($event)">
                            </mvs-widget>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem && activeNavItem == 'tags'">
        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmTagTypeWidget"
                                [widgetData]="cmTagTypeWidget"
                                (onObjectSelect)="handleTagTypeSelect($event)">
                    </mvs-widget>
                </div>
            </div>
            <div class="col-9">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmTagTypeScopeWidget"
                                [widgetData]="cmTagTypeScopeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem && activeNavItem == 'storno'">

        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmContractTerminationTypeWidget"
                                [widgetData]="cmContractTerminationTypeWidget"
                                (onObjectSelect)="handleContractTerminationSelect($event)">
                    </mvs-widget>
                </div>
            </div>
            <div class="col-9">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmContractTerminationReasonTypeWidget"
                                [widgetData]="cmContractTerminationReasonTypeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem && activeNavItem == 'contractBase'">

        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                    <mvs-widget *ngIf="cmBaseCategoryWidget"
                                [widgetData]="cmBaseCategoryWidget"
                                (onObjectSelect)="handleCategoryBaseSelect($event)">
                    </mvs-widget>
                </div>
            </div>
            <div class="col-9">
                <div class="shadow-1">

                    <mvs-widget *ngIf="cmBaseTypeWidget"
                                [widgetData]="cmBaseTypeWidget"
                                (onObjectSelect)="handleContractBaseTypeSelect($event)"
                                (dblclick)="openObjectInNewTab($event)">
                    </mvs-widget>


                    <!--                    <mvs-widget *ngIf="cmBaseTypeReasonWidget"-->
                    <!--                                [widgetData]="cmBaseTypeReasonWidget"-->
                    <!--                                (onObjectSelect)="handleTemplateSelect($event)">-->
                    <!--                    </mvs-widget>-->

                    <!--                    <mvs-widget *ngIf="cmBaseTypeTicketTypeWidget"-->
                    <!--                                [widgetData]="cmBaseTypeTicketTypeWidget"-->
                    <!--                                (onObjectSelect)="handleTemplateSelect($event)">-->
                    <!--                    </mvs-widget>-->

                </div>
            </div>
        </div>
    </ng-container>

</div>
