<ng-container *ngIf="initialized">
    <p-panel header="History" *ngIf="differences.length">

        <div class="grid">
            <div class="col-12">
                <p-timeline [value]="differences">
                    <ng-template pTemplate="content" let-event>
                        <small class="p-text-secondary">{{event['lastModifiedDate'] | date}}</small>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-event>
                        <div class="col-12" *ngFor="let item of event">
                            <p class="font-bold">{{'Field: ' + item.field}}</p>
                            <!-- <span>{{ '{' }}</span> -->
                            <p><span class="font-bold">Old: </span> {{item.old}}</p>
                            <p><span class="font-bold">New: </span>{{item.new}}</p>
                            <!-- <span>{{ '}' }}</span> -->
                        </div>
                    </ng-template>
                </p-timeline>
            </div>
        </div>

    </p-panel>
</ng-container>