import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectTypeMetaJoinExtensionDto} from "../../dto/object-type-meta-join-extension.dto";
import {Sorting} from "@kvers/alpha-core-common";
import {
    MetaExtensionObjectComponent
} from "../../component/object-component/meta-extension-object-component/meta-extension-object.component";

@Injectable({
    providedIn: 'root'
})
export class ObjectTypeMetaJoinExtensionService extends MvsCrudService{

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cc/objectTypeMetaJoinExtensions');
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        return MetaExtensionObjectComponent;
    }

    /**
     * Retrieve all entries belonging to a specific object type.
     * @param objectTypeId
     */
    listByObjectTypeId(objectTypeId: number) : Observable<DtoList> {

        return this.listByAttribute("objectType", objectTypeId, [new Sorting("lastModifiedDate", false)]);

    }


}

