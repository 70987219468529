<ng-container *ngIf="initialized">

    <ng-container>

        <mvs-wf-process-start
                [processGroupId]="routeParamProcessGroupId"
                [processTypeId]="routeParamProcessTypeId"
                (onProcessCreated)="onProcessStarted($event)"
        ></mvs-wf-process-start>

    </ng-container>

</ng-container>







