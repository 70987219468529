import {ObjectIdentifier} from "@kvers/alpha-core-common";

export class PersonSearchSelectEvent {

    constructor(
        public selectedPerson: ObjectIdentifier,
        public selectedTargetObject: ObjectIdentifier,
        public selectedRecord: any) {
    }

}