<!--<ng-container *ngIf="initialized && objectLoaded">-->

<!--    &lt;!&ndash;    <div>&ndash;&gt;-->

<!--    &lt;!&ndash;        <div class="grid">&ndash;&gt;-->
<!--    &lt;!&ndash;            <div class="col-3 py-0">&ndash;&gt;-->

<!--    &lt;!&ndash;                <div class="card-border px-2" style="height:83vh;">&ndash;&gt;-->
<!--    &lt;!&ndash;                    <p-tabView styleClass="px-0">&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Variablen" styleClass="px-0">&ndash;&gt;-->

<!--    &lt;!&ndash;                            &lt;!&ndash;                            height="920"&ndash;&gt;&ndash;&gt;-->
<!--    &lt;!&ndash;                            <mvs-te-variables&ndash;&gt;-->
<!--    &lt;!&ndash;                                    *ngIf="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    [variableProviderService]="crudService"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    alias="te.TeTemplateVariable"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    attributeName="template"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    [contentProviderId]="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                            ></mvs-te-variables>&ndash;&gt;-->
<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Artefakte">&ndash;&gt;-->
<!--    &lt;!&ndash;                            <mvs-embedded-artefacts&ndash;&gt;-->
<!--    &lt;!&ndash;                                    *ngIf="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    [templateId]="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                            ></mvs-embedded-artefacts>&ndash;&gt;-->
<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Basisdaten">&ndash;&gt;-->
<!--    &lt;!&ndash;                            <mvs-widget *ngIf="widgetBasicData" [widgetData]="widgetBasicData"></mvs-widget>&ndash;&gt;-->
<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                    </p-tabView>&ndash;&gt;-->
<!--    &lt;!&ndash;                </div>&ndash;&gt;-->
<!--    &lt;!&ndash;            </div>&ndash;&gt;-->

<!--    &lt;!&ndash;            <div class="col-9">&ndash;&gt;-->

<!--    &lt;!&ndash;                <div class="card-border h-full">&ndash;&gt;-->
<!--    &lt;!&ndash;                    <p-tabView class="relative">&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Text">&ndash;&gt;-->
<!--    &lt;!&ndash;                            <div class="flex">&ndash;&gt;-->
<!--    &lt;!&ndash;                                <div class="w-full">&ndash;&gt;-->

<!--    &lt;!&ndash;                                    <mvs-te-editor&ndash;&gt;-->
<!--    &lt;!&ndash;                                            [variableProviderService]="crudService"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            [contentProviderId]="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            [selectedTestCase]="selectedTestCase"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            [content]="dto.content"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            (onSave)="handleEditorOnSave($event)"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            [contentType]="dto.contentTypeEnum"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            alias="te.TeTemplateVariable"&ndash;&gt;-->
<!--    &lt;!&ndash;                                            attributeName="template"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    ></mvs-te-editor>&ndash;&gt;-->
<!--    &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--    &lt;!&ndash;                            </div>&ndash;&gt;-->
<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Testen">&ndash;&gt;-->

<!--    &lt;!&ndash;                            <mvs-te-test-case&ndash;&gt;-->
<!--    &lt;!&ndash;                                    [templateId]="dto.id"&ndash;&gt;-->
<!--    &lt;!&ndash;                                    (onSelectTestCase)="handleSelectTestCase($event)"&ndash;&gt;-->
<!--    &lt;!&ndash;                            ></mvs-te-test-case>&ndash;&gt;-->

<!--    &lt;!&ndash;                            &lt;!&ndash;&ndash;&gt;-->
<!--    &lt;!&ndash;                            <mvs-te-test-case-result></mvs-te-test-case-result>&ndash;&gt;-->
<!--    &lt;!&ndash;                            &ndash;&gt;&ndash;&gt;-->

<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                        <p-tabPanel header="Test Cases">&ndash;&gt;-->

<!--    &lt;!&ndash;                            <mvs-te-test-case-setup></mvs-te-test-case-setup>&ndash;&gt;-->

<!--    &lt;!&ndash;                        </p-tabPanel>&ndash;&gt;-->

<!--    &lt;!&ndash;                    </p-tabView>&ndash;&gt;-->

<!--    &lt;!&ndash;                </div>&ndash;&gt;-->
<!--    &lt;!&ndash;            </div>&ndash;&gt;-->
<!--    &lt;!&ndash;        </div>&ndash;&gt;-->

<!--    &lt;!&ndash;    </div>&ndash;&gt;-->

<!--    &lt;!&ndash;new design inprogress&ndash;&gt;-->
<!--    <div class="min-h-screen flex surface-ground mt-5">-->

<!--        <div class="col-12 p-0"-->
<!--             *ngIf="selectedNavigationItem"-->
<!--             [ngClass]="selectedNavigationItem == 'Testen' || selectedNavigationItem == 'Test Case' || selectedNavigationItem == 'Selected Case' ?-->
<!--                      'lg:col-5' : 'lg:col-3'">-->

<!--                    <div class="surface-border border-round shadow-1 flex-auto h-full surface-section p-3">-->
<!--                        <div class="" *ngIf="selectedNavigationItem == 'Variablen'">-->
<!--                            <mvs-te-variables-->
<!--                                    *ngIf="dto.id"-->
<!--                                    [variableProviderService]="crudService"-->
<!--                                    alias="te.TeTemplateVariable"-->
<!--                                    attributeName="template"-->
<!--                                    [contentProviderId]="dto.id"-->
<!--                            ></mvs-te-variables>-->
<!--                        </div>-->

<!--                        <div *ngIf="selectedNavigationItem == 'Selected Case'">-->

<!--                            <div class="flex justify-content-between align-items-center surface-50 border-round-lg p-3 mb-3">-->

<!--                                <h4 class="font-medium mb-0">-->
<!--                                    Test Result-->
<!--                                    <p-progressSpinner *ngIf="busy"-->
<!--                                                       styleClass="w-2rem h-2rem"-->
<!--                                                       animationDuration=".5s">-->
<!--                                    </p-progressSpinner>-->
<!--                                </h4>-->


<!--                                <p-dropdown-->
<!--                                        [options]="testCaseDtoList.entries"-->
<!--                                        [(ngModel)]="selectedTestCase.dto"-->
<!--                                        (onChange)="refreshTestCaseVariables()"-->
<!--                                        placeholder="Select a test case"-->
<!--                                        optionLabel="name"-->
<!--                                        class="col-5 p-0" styleClass="w-full">-->
<!--                                </p-dropdown>-->

<!--                            </div>-->



<!--                            <div class="col-12" *ngIf="occurredException">-->
<!--                                <p-message severity="error" [text]="occurredException"></p-message>-->

<!--                                <p-message *ngIf="occurredExceptionDetails" severity="error"-->
<!--                                           [text]="occurredExceptionDetails"></p-message>-->
<!--                            </div>-->

<!--                            <div class="col-12" *ngIf="templateGenerateResponse">-->

<!--                                <div style="word-break: break-word;"-->
<!--                                     [innerHTML]="trustedGenerateResponseHtml"-->
<!--                                     *ngIf="trustedGenerateResponseHtml">-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="" *ngIf="selectedNavigationItem == 'Artefakte'">-->
<!--                            <mvs-embedded-artefacts-->
<!--                                    *ngIf="dto.id"-->
<!--                                    [templateId]="dto.id"-->
<!--                            ></mvs-embedded-artefacts>-->
<!--                        </div>-->

<!--                        <div class="" *ngIf="selectedNavigationItem == 'Basisdaten'">-->
<!--                            <mvs-widget *ngIf="widgetBasicData" [widgetData]="widgetBasicData"></mvs-widget>-->
<!--                        </div>-->

<!--                        <div class="" *ngIf="selectedNavigationItem == 'Testen'">-->
<!--                            <mvs-te-test-case-->
<!--                                    [templateId]="dto.id"-->
<!--                                    (onSelectTestCase)="handleSelectTestCase($event)"-->
<!--                            ></mvs-te-test-case>-->
<!--                        </div>-->

<!--                    </div>-->
<!--        </div>-->

<!--        <div class="min-h-screen p-0 col-12"-->
<!--             [ngClass]="selectedNavigationItem == 'Testen' || selectedNavigationItem == 'Test Case' || selectedNavigationItem == 'Selected Case' ?-->
<!--                      'lg:col-7' : !selectedNavigationItem ? 'lg:col-12' : 'lg:col-9'">-->

<!--            <div class="px-3 h-full" [ngClass]="selectedNavigationItem == 'Testen' ? 'hidden' : 'block'">-->
<!--                <div class="surface-border surface-section flex-auto px-5 h-full shadow-1 border-round">-->
<!--                    <mvs-te-editor-->
<!--                            [variableProviderService]="crudService"-->
<!--                            [contentProviderId]="dto.id"-->
<!--                            [selectedTestCase]="selectedTestCase"-->
<!--                            [content]="dto.content"-->
<!--                            (onSave)="handleEditorOnSave($event)"-->
<!--                            [contentType]="dto.contentTypeEnum"-->
<!--                            alias="te.TeTemplateVariable"-->
<!--                            attributeName="template"-->
<!--                    ></mvs-te-editor>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="px-2" *ngIf="selectedNavigationItem == 'Testen'">-->
<!--                    <mvs-te-test-case-setup></mvs-te-test-case-setup>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->

<!--</ng-container>-->

@if (initialized && objectLoaded && dto) {

    @if (uiMode == 'side') {

        <te-template-object-side
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></te-template-object-side>
    } @else {

        <te-template-object-full
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [uiMode]="uiMode"
                (onNavigationItems)="handleNavigationItems($event)"
        ></te-template-object-full>
    }


}