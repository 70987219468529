@if (activeNavigationItem.action == 'integrationTypeInfo') {
    <mvs-widget *ngIf="integrationTypeWidget"
                [widgetData]="integrationTypeWidget"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'integrationType') {
    <div class="flex justify-content-between p-3 mb-3 shadow-2 align-items-center mt-5">
        <h4 class="m-0 font-medium">Integration Type</h4>
        <i class="fa-regular fa-chart-diagram cursor" (click)="showVariable()"></i>
    </div>
    <div class="grid mx-0">
        <div class="col-12 smooth-transition surface-0 shadow-1 border-round"
             [ngClass]="variableDisplay ? 'md:col-9' : 'md:col-12'">
            <div class="flex gap-3 p-3 overflow-x-auto">
                @if (sortedStep?.length) {
                    <div *ngFor="let step of sortedStep" class="kanban-column h-max">
                        <!--                        <div class="flex flex-column gap-2">-->

                        <div class="flex justify-content-between mb-3">
                            <div class="flex flex-column gap-1">
                                <h6 class="mb-0">{{ step.name }}</h6>
                                <span class="text-color-secondary text-xs"> {{ step?.adapterTypeDtoName }}</span>
                            </div>
                            <i class="fa fa-edit text-600 hover:text-primary cursor" (click)="handleStep(step)"></i>
                        </div>
                        <!--                            <div class="flex">-->
                        <!--                                &lt;!&ndash;                                <p-chip [label]="step?.adapterTypeDtoName"></p-chip>&ndash;&gt;-->
                        <!--                                <p-tag [value]="step?.adapterTypeDtoName"></p-tag>-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                        <!-- Step Inputs -->
                        <div class="flex flex-column gap-3 shadow-1 card mb-2">

                            <div class="flex justify-content-between">
                                <h6 class="font-medium mb-0">Input</h6>
                                <i class="pi pi-plus-circle text-primary cursor" (click)="handleStepInput(step)"></i>
                            </div>
                            @if (step?.integrationTypeStepInput?.length) {

                                <div *ngFor="let item of step?.integrationTypeStepInput"
                                     class="border-round border-1 border-200 hover:border-primary-500 py-3 px-2 cursor"
                                     (click)="handleStepInput(step,item)">
                                    <div class="flex flex-wrap gap-2">
                                        <p-tag [rounded]="true" styleClass="surface-200 text-color font-normal"
                                               [value]="item?.integrationVariableDtoName"></p-tag>
                                        <p-tag [rounded]="true" styleClass="surface-200 text-color font-normal"
                                               [value]="item?.adapterVariableDtoName"></p-tag>
                                    </div>
                                </div>
                            }
                        </div>

                        <!-- Step Outputs -->
                        <div class="flex flex-column gap-3 shadow-1 card mb-2">
                            <div class="flex justify-content-between align-items-center">
                                <h6 class="font-medium mb-0">Output</h6>
                                <i class="pi pi-plus-circle text-primary cursor" (click)="handleStepOutput(step)"></i>
                            </div>

                            @if (step?.integrationTypeStepOutput?.length) {

                                <div *ngFor="let item of step?.integrationTypeStepOutput"
                                     class="border-round border-1 border-200 hover:border-primary-500 py-3 px-2 cursor"
                                     (click)="handleStepOutput(step,item)">
                                    <div class="flex flex-wrap gap-2">
                                        <p-tag [rounded]="true" styleClass="surface-200 text-color font-normal"
                                               [value]="item?.integrationVariableDtoName"></p-tag>
                                        <p-tag [rounded]="true" styleClass="surface-200 text-color font-normal"
                                               [value]="item?.adapterVariableDtoName"></p-tag>
                                    </div>
                                </div>
                            }
                        </div>

                        <!-- Step Transitions -->

                        <div class="flex flex-column gap-3 shadow-1 card mb-2">
                            <div class="flex justify-content-between align-items-center">
                                <h6 class="font-medium mb-0">Step Transition</h6>
                                <i class="pi pi-plus-circle text-primary cursor"
                                   (click)="handleStepTransition(step)"></i>
                            </div>

                            @if (step['stepTransitionDto']) {
                                <div class="flex flex-column gap-2">
                                    <div class="border-1 border-300 p-3 pointer"
                                         *ngFor="let stepTransition of step['stepTransitionDto']"
                                         (click)="showTransition(stepTransition.id)">
                                        <div class="flex flex-wrap gap-2 align-items-center">
                                            <span class="text-800"> {{ stepTransition?.fromStepDtoName }} </span>
                                            <i class="fa-solid fa-angles-right text-600 text-sm"></i>
                                            <span class="text-800">{{ stepTransition?.toStepDtoName }}</span>
                                        </div>
                                    </div>
                                </div>
                                <!--                                <p-button-->
                                        <!--                                        *ngFor="let stepTransition of step['stepTransitionDto']"-->
                                        <!--                                        [label]="'Transition: ' + stepTransition?.fromStepDtoName + ' to ' + stepTransition?.toStepDtoName"-->
                                        <!--                                        (click)="showTransition(stepTransition.id)"></p-button>-->
                            }
                        </div>

                    </div>
                }
                <div>

                </div>
<!--                <div class="card flex gap-2">-->
<!--                    <div class="w-2rem h-2rem border-circle bg-primary">-->
<!--                        <i class="pi pi-plus"></i>-->
<!--                    </div>-->
<!--                    <span>Creat New Step</span>-->
<!--                </div>-->

                <p-button icon="pi pi-plus" styleClass="" [rounded]="true" (click)="handleStep()"/>
            </div>
        </div>
        <div class="col-3" [class.hidden-filter]="!variableDisplay">
            <mvs-widget
                    *ngIf="integrationTypeVariableTableWidget"
                    [widgetData]="integrationTypeVariableTableWidget"
                    (onObjectSelect)="handleVariableSelect($event)"
            ></mvs-widget>
        </div>
    </div>
}