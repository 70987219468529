import {DtoDetail} from "@kvers/alpha-core-common";
import { CallDto } from "./call.dto";
import {CallProtocolStatusEnum} from "../enum/call-protocol-status.enum";

export class CallProtocolDto extends DtoDetail{


    public callDto? : CallDto;
    public callDtoId : number;
    public callDtoName : string;
    public comment: string;
    public protocolStatus: CallProtocolStatusEnum;

}