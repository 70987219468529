import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {CustomerContactDto} from "../../../service/dto/customer-contact.dto";
import {CustomerService} from "../../../service/api/customer.service";
import {CustomerUserService} from "../../../service/api/customer-user.service";
import {CustomerUserDto} from "../../../dto/customer-user.dto";

@Component({
    selector: 'cr-create-user',
    templateUrl: './cr-create-user.component.html',
})
export class CrCreateUserComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy

    form: FormGroup;

    emailAddresses: CustomerContactDto[] = [];
    phoneNumbers: CustomerContactDto[] = [];

    userCreated: boolean;

    @Input() customerId?: number;
    @Output() onUserCreated: EventEmitter<CustomerUserDto> = new EventEmitter<CustomerUserDto>();

    constructor(
        protected fb: FormBuilder,
        protected customerService: CustomerService,
        protected customerUserService: CustomerUserService,
        ) {
    }
    initialized: boolean; // indicator whether the component was initialized


    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.form = new FormGroup({
            email: new FormControl(''),
            phone: new FormControl('')
        });

        this.userCreated = false;

        this.customerService.geContactsEmail(this.customerId).subscribe(value => {
            this.emailAddresses = value;
        });

        this.customerService.geContactsMobile(this.customerId).subscribe(value => {
            this.phoneNumbers = value;
        });

        this.initialized = true;

    }

    handleCreateUser() {
        if (this.busy) {
            return;
        }

        this.busy = true;

        this.customerUserService.createUser(this.customerId, {
            phoneNumber: this.form.get('phone').value,
            email: this.form.get('email').value
        }).subscribe(value => {
            this.onUserCreated.emit(value);
        }, error => {
            this.busy = false;
        })



    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
