import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-cf-frequency-page',
  templateUrl: './cf-frequency.page.html',
  styleUrls: ['./cf-frequency.page.scss']
})
export class CfFrequencyPage implements OnInit {

  objectBrowserRequest: any;

  constructor() {
  }

  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,
      "createMode": true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cf.CfFrequency',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Frequencies",
          'dummyParentDescription' : "Frequenz anlegen.",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Neue Frequenz anlegen"

        },
      ]

    }
  }
}
