import {DtoDetail} from "@kvers/alpha-core-common";
import { EuOtpCodeDto } from "./eu-otp-code.dto";
import {DmDocumentDto} from "../../dm/dto/dm-document.dto";
import {PmPersonGender} from "../../cr/enum/pm-person-gender.enum";
import {PmPersonMaritalStatus} from "../../cr/enum/pm-person-martial-status.enum";
import {CustomerDto} from "../../cr/dto/customer.dto";

export enum EuEuRegistrationStatusInternal{

    pending,
    in_processing,
    processed,
    unfinished_reminder,
    unfinished_closed,
    duplicate

}

export class EuRegistrationDto extends DtoDetail{


    public phoneNumber: string;
    public registrationGuid: string;
    public otpCodeDto? : EuOtpCodeDto;
    public otpCodeDtoId : number;
    public otpCodeDtoName : string;
    public otpCodeConfirmed: boolean;
    public otpAccessCount: number;
    public otpCodeGeneratedInstant: Date;
    public startInstant: Date;
    public validUntilInstant: Date;
    public deviceId: string;
    public deviceType: string;
    public notificationToken: string;
    public email: string;
    public password: string;
    public documentPhotoDto? : DmDocumentDto;
    public documentPhotoDtoId : number;
    public documentPhotoDtoName : string;
    public firstName: string;
    public lastName: string;
    public gender: PmPersonGender;
    public addressCasually: boolean;
    public nickName: string;
    public addressStreet: string;
    public addressStreetNumber: string;
    public addressLine2: string;
    public addressCity: string;
    public addressPostalCode: string;
    public dateOfBirth: Date;
    public maritalStatus: PmPersonMaritalStatus;
    public childrenCount: number;
    public confirmContactViaPhone: boolean;
    public confirmNotifications: boolean;
    public confirmWhatsApp: boolean;
    public confirmEmail: boolean;
    public confirmNewsletter: boolean;
    public signaturesDtoId : number;
    public signaturesDtoName : string;
    public registrationStatusInternal: EuEuRegistrationStatusInternal;

    customer?: CustomerDto

}