// module-registry.service.ts
import { Injectable } from '@angular/core';
import {DynamicModule} from "./dynamic-module.interface";

@Injectable({
    providedIn: 'root',
})
export class ModuleRegistryService {
    private modules = new Map<string, DynamicModule>();

    registerModule(module: DynamicModule) {
        this.modules.set(module.alias, module);
    }

    getModule(alias: string): DynamicModule | undefined {
        return this.modules.get(alias);
    }
}
