<div *ngIf="initialized">
    <mvs-config-header header="Benutzerverwaltung"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Benutzer" leftIcon="fa-thin fa-user"></p-tabPanel>
        <p-tabPanel header="Rollen" leftIcon="fa-thin fa-user-tag"></p-tabPanel>
        <p-tabPanel header="Benutzerberechtigungen" leftIcon="fa-thin fa-shield-alt"></p-tabPanel>
        <p-tabPanel header="Dokument Konfiguration" leftIcon="fa-thin fa-shield-alt"></p-tabPanel>
        <p-tabPanel header="Benachrichtigungen" leftIcon="fa-thin fa-shield-alt"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex == 0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetUser"
                        [widgetData]="widgetUser">
                    <ng-template let-object="object" mvs-widget-custom-field-directive label="testDataField"
                                 fieldId="testDataField">

                        <div *ngIf="object.dto.id" (click)="handleUploadAvatar(object.dto.id)">
                            <user-profile-id [userId]="object.dto.id"
                                             [readonly]="true">

                            </user-profile-id>
                        </div>


                    </ng-template>
                </mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetRole"
                        [widgetData]="widgetRole"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==2">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetUserRole"
                        [widgetData]="widgetUserRole"
                ></mvs-widget>

            </div>

        </div>

    </ng-container>

    <p-dialog
            header="Upload"
            [(visible)]="avatarDialogVisible"
            [modal]="true"
            [style]="{width: '50rem', height: '50rem'}"
            (onHide)="resetDialog()"
    >
        <ng-template pTemplate="content">
            <user-profile-id [userId]="selectedUserId"
                             (onObjectChanged)="handleAvatarChanged($event)">

            </user-profile-id>
        </ng-template>
    </p-dialog>

    <ng-container *ngIf="activeIndex==3">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetDocumentConfiguration"
                        [widgetData]="widgetDocumentConfiguration"
                ></mvs-widget>

            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="activeIndex==4">

        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetNotification"
                        [widgetData]="widgetNotification"
                ></mvs-widget>

            </div>

        </div>

    </ng-container>

</div>