import {Component, OnChanges, OnDestroy, OnInit,} from '@angular/core';
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessTypeStepActivityService} from "../../../../wf/service/api/wf-process-type-step-activity.service";
import {WfActivityTypeEnum} from "../../../../wf/enum/wf-activity-type.enum";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WfProcessRuntimeBindingDto} from "../../../../wf/service/dto/wf-process-runtime-binding.dto";
import {WfActivityFormDefaultDto} from "../../../../wf/service/dto/wf-activity-form-default.dto";
import {FilterCriteria} from "@kvers/alpha-core-common";


/**
 * Implementation of two WF Activities:
 *  - TICKET_CREATE
 *  - TICKET_CHANGE_STATUS
 */
@Component({
    selector: 'mvs-wf-process-step-activity-ticket-object',
    templateUrl: './tm-ticket-wf-process-step-activity.component.html',
})
export class TmTicketWfProcessStepActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    createdObjectIdentifier: ObjectIdentifier;
    showTicketObjectForm: boolean = true;
    objectId: number = 0;
    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    objectWidget: WidgetData;
    listFilterCriteria: FilterCriteria[];

    constBindingFieldPrefix: string = "f_";

    ngOnInit() {

        super.ngOnInit();

        // this.loadObjectType();


        // this.refreshComponent();
    }

    refreshComponent() {
        this.listFilterCriteria = [];
        this.createDefaultDto = new DtoDetail({});
        this.formControlOverwrite = new MvsFormControlOverwrite();

        // ************************************************************************************** //
        //  create ticket
        // ************************************************************************************** //

        if (this.activityType.activityType == WfActivityTypeEnum.create_ticket) {

            WfActivityFormDefaultDto.processFormDefaults(this.formDefaults, this.createDefaultDto, this.listFilterCriteria, this.formControlOverwrite);
            // WfActivityFormDefaultDto.processBindings(this.bindings, this.createDefaultDto, this.constBindingFieldPrefix);

            const referenceObjectBinding = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "referenceObject");

            // defaulting form values
            this.createDefaultDto['typeDtoId'] = this.activityType.activityTicketCreateDto.ticketTypeDtoId;
            this.createDefaultDto['urgency'] = this.activityType.activityTicketCreateDto.defaultUrgency;
            this.createDefaultDto['status'] = this.activityType.activityTicketCreateDto.status;
            this.createDefaultDto['typeDtoId'] = this.activityType.activityTicketCreateDto.ticketTypeDtoId;
            this.createDefaultDto['assigneeAgentPoolDtoId'] = this.activityType.activityTicketCreateDto.defaultAgentPoolDtoId;
            this.createDefaultDto['assigneeAgentDtoId'] = this.activityType.activityTicketCreateDto.defaultAgentDtoId;
            this.createDefaultDto['loggedOnUser'] = this.activityType.activityTicketCreateDto.autoAssignToLoggedOnUser;
            this.createDefaultDto['referenceObjectTypeDtoId'] = this.activityType.activityTicketCreateDto.referenceObjectProcessTypeFieldDtoId;

            if (referenceObjectBinding) {
                this.createDefaultDto['referenceObjectTypeDtoId'] = referenceObjectBinding.simpleValue;
            }

        } else if (this.activityType.activityType == WfActivityTypeEnum.ticket_status) {


            // ************************************************************************************** //
            //  update ticket status
            // ************************************************************************************** //


            // defaulting form values

            WfActivityFormDefaultDto.processFormDefaults(this.formDefaults, this.createDefaultDto, this.listFilterCriteria, this.formControlOverwrite);
            this.objectId = this.activityType.activityTicketStatusDto.processFieldTicketDtoId;
            this.createDefaultDto['status'] = this.activityType.activityTicketStatusDto.status;


            // binding form values
            const processedTicketBinding = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "ticket");
            if (processedTicketBinding) {
                this.objectId = processedTicketBinding.objectId;
            }

            WfActivityFormDefaultDto.processFormDefaults(this.formDefaults, this.createDefaultDto, this.listFilterCriteria, this.formControlOverwrite);

            // deciding form access
            this.formControlOverwrite = MvsFormControlOverwrite.createHideAll('status')

        }

        this.refreshObject();

    }


    handleObject(event: ObjectChangeInformation) {

        this.objectId = event.after.id;

        // this.createdObjectIdentifier = new ObjectIdentifier(event.objectType, event.after.id);
        // WfProcessTypeStepActivityService.objectIdentifier = this.createdObjectIdentifier;
        //
        // if (this.activityType.processing === WfProcessTypeStepProcessingEnum.optional) {
        //     this.onOptionalActivityChangeEvent.emit(new WfProcessStepActivityEvent(this.createdObjectIdentifier, this.activityType));
        // } else if (this.activityType.processing === WfProcessTypeStepProcessingEnum.main) {
        //     this.updateProcess(this.createdObjectIdentifier.objectId);
        //
        // }
    }

    onBackToCreate() {
        this.updateProcess(null);
    }

    updateProcess(connectedObjectId: number) {

    }


    onWidgetSelectEntry(objectIdentifier: ObjectIdentifier) {
        // change the ID
        this.createdObjectIdentifier = objectIdentifier;
        this.objectId = objectIdentifier.objectId;
        WfProcessTypeStepActivityService.objectIdentifier = objectIdentifier


    }

    /**
     * form widget to show display objects
     */
    refreshObject() {
        this.objectWidget = new WidgetData();
        this.objectWidget.id = 1;
        this.objectWidget.name = 'Object Widget';
        this.objectWidget.idAlias = 'wf.processed.object.widget.tm.Ticket';

        this.objectWidget.uiComponent = 'object';
        this.objectWidget.dataProvider = 'list';
        this.objectWidget.dataSource = 'entity';
        this.objectWidget.dataProviderObject = 'tm.Ticket';

        // this.stepActivityMultiSelectWidget.setParamValue("selectionMode", "single");
        this.objectWidget.setParamValue("size", "S");
        this.objectWidget.setParamValue("objectId", this.objectId);
        this.objectWidget.setParamValue("formControlOverwrite", this.formControlOverwrite);
        this.objectWidget.setParamValue("createDefaultDto", this.createDefaultDto);
    }


}
