import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {DtoDetail} from "@kvers/alpha-core-common";
import {filter} from "rxjs/operators";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {TicketService} from "../../service/api/ticket.service";

@Component({
    selector: 'tm-object-tickets',
    templateUrl: './tm-object-tickets.component.html',
    styleUrls: ['./tm-object-tickets.component.scss']
})
export class TmObjectTicketsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() size: string = 'M';
    @Input() ticketDrawerLocation: string = 'right';
    @Input() showActive: boolean = true;
    selectedTicketId: number;
    objectTicketList: WidgetData;

    constructor(
        protected objectTypeService: ObjectTypeService,
        protected navigationService: MvsObjectNavigationService,
        protected ticketService: TicketService) {
    }

    ngOnInit(): void {

        // to open ticket drawer
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.handleObjectTypeId();
        this.initialized = true;
    }

    openTicketDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", ticketId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, this.ticketDrawerLocation);
    }

    onTicketSelect(object: ObjectIdentifier) {
        this.selectedTicketId = object.objectId;
        this.openTicketDrawer(object.objectId);
    }

    /**
     * handle objectTypeAlias and retrieve objectType
     */
    handleObjectTypeId() {

        this.objectTypeService.getViaObjectType(this.objectIdentifier.objectType).then(value => {
            this.refreshObjectTicketList(value.id);
        });
    }

    /**
     * list tickets related to specific objectId
     * @param objectTypeId
     */
    refreshObjectTicketList(objectTypeId: number) {
        this.objectTicketList = new WidgetData();
        this.objectTicketList.idAlias = "tm.object.tickets.list." + this.size;
        this.objectTicketList.name = "Ticket List";
        this.objectTicketList.uiComponent = "list";
        this.objectTicketList.dataProvider = "list";
        this.objectTicketList.dataSource = "entity";
        this.objectTicketList.dataProviderObject = 'tm.Ticket';

        const filterList = [];
        if (this.objectIdentifier.objectType == 'cr.Customer') {
            filterList.push(FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, objectTypeId))
        } else {
            alert("Ticket Component does not support this type!");
        }

        if (this.showActive) {
            this.ticketService.includeTicketStatusOpenToFilter(filterList);
        }


        this.objectTicketList.dataProviderListRequest = new ObjectRequestList(
            true,
            filterList,
            [new Sorting("createdDate", false)]
        );


        //6 resolved, 8 cancelled
        this.objectTicketList.setParamValue("size", "S");
        this.objectTicketList.setParamValue("objectWidget", "data");
    }


    onFieldValueSelected(event: DtoDetail) {
        this.openTicketDrawer(event.id);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
