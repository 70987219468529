import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {getStartOfMonth, getStartOfToday, getStartOfWeek, getStartOfYear} from "../../helper/date-utils";
@Component({
    selector: 'date-selection-dropdown',
    templateUrl: './date-dropdown.component.html',
})
export class DateDropdownComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;
    initialized: boolean;

    @Input() defaultOption: string = 'thisMonth'; // Default option
    @Output() selectedDateStart: EventEmitter<Date | null> = new EventEmitter<Date | null>();

    dateOptions = [
        { label: 'This Month', value: 'thisMonth' },
        { label: 'This Week', value: 'thisWeek' },
        { label: 'Today', value: 'today' },
        { label: 'This Year', value: 'thisYear' },
        { label: 'All', value: 'all' },
    ];


    selectedDateOption: string = this.defaultOption;

    ngOnInit() {
        this.selectedDateOption = this.defaultOption;
        // this.emitStartDate(this.selectedDateOption);
    }

    onDateOptionChange(event: any) {
        const selectedOption = event.value;
        this.emitStartDate(selectedOption);
    }

    private emitStartDate(option: string) {
        let startDate: Date | null = null;

        switch (option) {
            case 'thisMonth':
                startDate = getStartOfMonth(new Date());
                break;
            case 'thisWeek':
                startDate = getStartOfWeek(new Date());
                break;
            case 'today':
                startDate = getStartOfToday();
                break;
            case 'thisYear':
                startDate = getStartOfYear(new Date());
                break;
            case 'all':
                startDate = null;
                break;
        }

        this.selectedDateStart.emit(startDate);
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
