import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldOutputBaseComponent, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {
    DtoDetail,
    MvsCoreService,
    MvsCrudService,
    MvsFormFieldDto,
    MvsObjectService,
    ObjectChangeInformation,
    ObjectChangeInformationActionEnum,
    ObjectTypeService
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";


@Component({
    selector: 'mvs-one-to-many-count-output-component',
    templateUrl: 'mvs-one-to-many-count-output.component.html',
    styleUrls: ['mvs-one-to-many-count-output.component.scss']
})
export class MvsOneToManyCountOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy {

    objectFieldName: string;
    objectId: number;
    busy: boolean;
    initialized: boolean;
    extractedData: DtoDetail[] = [];

    fieldToDisplay: string;
    fieldToUpdate: string;

    newComment: string = '';

    crudService: MvsCrudService;
    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected objectService: ObjectTypeService,
        protected mvsObjectService: MvsObjectService,
        protected coreService: MvsCoreService) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.extractData();
        this.refreshComponent();

    }

    extractData() {
        const objectField: MvsFormFieldDto = this.formField['uiConfigData']['outputFieldToDisplay'];
        this.fieldToDisplay = objectField.id;
        this.objectFieldName = objectField.uiLabel;
        const keys = objectField.absoluteId.split('#');

        const objectFieldToUpdate: MvsFormFieldDto = this.formField['uiConfigData']['outputFieldToUpdate'];
        this.fieldToUpdate = objectFieldToUpdate.id


        const objectType = this.form.subForms[keys[keys.length - 2]].objectType;

        this.crudService = this.coreService.getCrudService(objectType);

        // Extract the parent object instead of the final value
        const extractParentValue = (obj: any, keyPath: string[]) => {
            return keyPath.slice(0, -1).reduce((acc, key) => acc?.[key], obj);
        };

        this.extractedData = Array.isArray(this.dto[keys[0]])
            ? this.dto[keys[0]].map(item => extractParentValue(item, keys.slice(1)))
            : extractParentValue(this.dto, keys);
    }

    addComment() {
        if (this.newComment.trim()) {

            const dtoDetail: DtoDetail = new DtoDetail();
            dtoDetail['comment'] = this.newComment;
            // dtoDetail[this.fieldToUpdate] = this.extractedData[0][this.fieldToUpdate];
            dtoDetail[this.fieldToUpdate] = this.dto.id;

            if (this.busy) {
                return;
            }

            this.busy = true;

            this.crudService.create(dtoDetail).subscribe(res => {
                this.newComment = '';

                const event = new ObjectChangeInformation();
                event.after = res;
                event.objectType = this.crudService.objectType;
                event.action = ObjectChangeInformationActionEnum.created;

                this.mvsObjectService.broadcastObjectAction(event);

                this.busy = false;
            }, error => {
                this.busy = false;
            })

        }
    }

    editComment(dto: DtoDetail) {
        dto['isEditing'] = true;
    }

    saveComment(dto: DtoDetail) {

        const dtoDetail: DtoDetail = new DtoDetail();
        dtoDetail['id'] = dto['id'];
        dtoDetail['comment'] = dto['comment'];

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.crudService.update(dtoDetail).subscribe(res => {
            this.busy = false;

            const event = new ObjectChangeInformation();
            event.after = res;
            event.objectType = this.crudService.objectType;
            event.action = ObjectChangeInformationActionEnum.created;

            this.mvsObjectService.broadcastObjectAction(event);

            dto['isEditing'] = false;
        }, error => {
            this.busy = false;
        })
    }

    deleteComment(id: number) {
        if (this.busy) {
            return;
        }

        this.busy = true;

        this.crudService.delete(id).subscribe(res => {
            const event = new ObjectChangeInformation();
            event.after = res;
            event.objectType = this.crudService.objectType;
            event.action = ObjectChangeInformationActionEnum.created;

            this.mvsObjectService.broadcastObjectAction(event);

            this.busy = false;

        }, error => {
            this.busy = false;
        })
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
