import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavigationItem, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-example',
    templateUrl: './si-config.page.html',
})
export class SiConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    activeNavigationItem: NavigationItem;
    navigationItems: NavigationItem[] = [
        {
            label: 'searchResultConfiguration',
            action: 'searchResultConfiguration',
            tooltip: 'Search Result Field Configuration',
            icon: 'fa-solid fa-table-list', // Represents search results or structured lists
            toggleable: false,
            default: false
        },
        {
            label: 'objectTypeAttributeConfiguration',
            action: 'objectTypeAttributeConfiguration',
            tooltip: 'Object Type Attribute Search Configuration',
            icon: 'fa-solid fa-list-check', // Represents attributes and settings
            toggleable: false,
            default: false
        },
        {
            label: 'reference',
            action: 'reference',
            tooltip: 'Referenzen und Strukturen',
            icon: 'fa-solid fa-table-tree', // Represents attributes and settings
            toggleable: false,
            default: false
        },
        {
            label: 'shortCut',
            action: 'shortCut',
            tooltip: 'Short Cuts',
            icon: 'fa-brands fa-searchengin', // Represents attributes and settings
            toggleable: false,
            default: false
        }
    ];
// <i class="fa-solid fa-table-tree"></i>

    searchResultFieldConfigWidget: WidgetData;
    objectTypeAttributeSearchConfigWidget: WidgetData;
    referenceWidgetData: WidgetData;
    referenceStructureWidget: WidgetData;
    shortCutFieldWidget: WidgetData;

    defaultLabel: string = "Search - Configuration ";

    queryParamSubscription: Subscription;


    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        const objectRequestList = ObjectRequestList.createBasic(true, [], []);

        this.searchResultFieldConfigWidget = WidgetFactory.createWidgetTableEntity(
            'si.config.searchResultFieldConfig.widget.data',
            'Search Result Field Configuration',
            'si.SearchResultObjectType',
            'No Data',
            objectRequestList
        );

        this.objectTypeAttributeSearchConfigWidget = WidgetFactory.createWidgetTableEntity(
            'si.config.objectTypeAttributeSearchConfig.widget.data',
            'Object Type Attribute Search Configuration',
            'si.SearchObjectTypeAttribute',
            'No Data',
            objectRequestList
        );

        this.referenceWidgetData = WidgetFactory.createWidgetTableEntity(
            'si.config.search-object-type-reference.widget.data',
            'Referenz- Objekt',
            'si.SearchObjectTypeReference',
            'No Data',
            objectRequestList
        );

        this.referenceStructureWidget = WidgetFactory.createWidgetTableEntity(
            'si.config.search-object-type-reference-structure.widget.data',
            'Referenz Struktur',
            'si.SearchObjectTypeReferenceStructure',
            'No Data',
            objectRequestList
        );

        this.shortCutFieldWidget = WidgetFactory.createWidgetTableEntity(
            'si.config.search-short-cut-field.widget.data',
            'Short Cuts',
            'si.SearchShortCutField',
            'No Data',
            objectRequestList
        );

    }

    subscribeToParams() {


        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            const navItem = params['navItem'];

            if (navItem) {
                this.activeNavigationItem = this.navigationItems.find(item => item.action == navItem);
            } else {
                this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            }
        });
    }

    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

}
