import {DtoDetail} from "@kvers/alpha-core-common";
import { InsurableObjectDto } from "./insurable-object.dto";
import {ContractDto} from "../../cm/dto/contract.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";

export class ContractInsurableObjectDto extends DtoDetail{


    public contractDto? : ContractDto;
    public contractDtoId : number;
    public contractDtoName : string;
    public insurableObjectDto? : InsurableObjectDto;
    public insurableObjectDtoId : number;
    public insurableObjectDtoName : string;
    public startDate: Date;
    public entityStatus: EntityStatusEnum;
    public comment: string;

}