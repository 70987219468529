import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {TmTicketTagDto} from 'src/app/tm/dto/tm-ticket-tag.dto';
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";

interface ISelectedTag {
    id: number,
    tag: string
}

@Component({
    selector: 'mvs-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnChanges, OnDestroy {
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() serviceInterface: MvsCrudService;
    @Input() referenceId: number;
    tags: ISelectedTag[];

    mostlyUsedTags: DtoDetail[];

    dtoListTags: DtoList;

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshTags();

        this.initialized = true;
    }

    getTicketFilter(): FilterCriteria[] {
        return [FilterCriteria.create("ticket", FilterCriteria.cOperatorEqual, this.referenceId, null)];
    }

    /**
     * refresh tags
     */
    refreshTags() {
        const dtoListRequest = new ObjectRequestList(true, this.getTicketFilter(), [new Sorting("lastModifiedDate", false)]);

        this.serviceInterface.list(dtoListRequest).subscribe(value => {
            this.dtoListTags = value;
            this.tags = this.dtoListTags.entries.map((element: any) => {
                return {id: element.id, tag: element.tag}
            });
            this.frequentlyUsedTags();
        });

    }

    /**
     * gets frequently used tags
     */
    frequentlyUsedTags() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ["tag"],
            [new ObjectRequestListAttribute("tag", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        this.serviceInterface.groupBy(objectRequestList).subscribe(res => {

            const tags = res.entries
            const tagsSet = new Set(this.tags.map(tag => tag['tag']));

            const result = tags.filter(obj => !tagsSet.has(obj['tag']));

            result.sort((a: any, b: any) => b.tag_count - a.tag_count);
            if (result.length > 3) {
                this.mostlyUsedTags = result.slice(0, 3);
            } else {
                this.mostlyUsedTags = result;
            }
        })
    }

    /**
     * add tag
     * @param tagValue
     */
    createTag(tag: string) {
        this.busy = true
        const ticketTag: TmTicketTagDto = new TmTicketTagDto()
        ticketTag.ticketDtoId = this.referenceId;
        ticketTag.tag = tag;

        this.serviceInterface.create(ticketTag).subscribe(value => {
            // refresh Ticket comments
            this.refreshTags();

            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "Ticket Tag created");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })

    }

    /**
     * remove tag
     * @param tag
     */
    removeTag(tag: any) {
        this.busy = true;
        this.serviceInterface.delete(tag.id).subscribe(value => {
            // refresh Ticket Watchlist

            this.refreshTags();
            this.busy = false;

            this.messageService.showSuccessMessage("Ticket", "Ticket Tag removed");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
