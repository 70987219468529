@if (initialized) {
    <div class="grid">

        <div class="col-12">
            <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
                <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
            </div>
        </div>

        <div class="col-12">
            <mvs-search-input
                    [objectTypeAlias]="[objectType]"
                    [maxSearchCounts]="20"
                    (onSearchResult)="onSearchResult($event)"
            ></mvs-search-input>
        </div>

        @if (searchedResultEntries?.length) {

<!--            <div class="col-12">-->

<!--                <div class="grid">-->

<!--                    <div class="col" *ngFor="let entry of searchedResultEntries; let i = index">-->
<!--                        <span (click)="onSelect(entry.objectId)">{{ entry.objectId }}- {{ entry.resultName }}</span>-->
<!--                        @if (selectedObjectId == entry.objectId) {-->
<!--                            selected object-->
<!--                        }-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            @if (objectTypeSelectionWidget) {
                <mvs-widget [widgetData]="objectTypeSelectionWidget" (onObjectSelect)="onObjectSelect($event)"></mvs-widget>
            }
        }
    </div>
}
