<ng-container *ngIf="initialized">

    <div class="grid surface-0">
        <div class="lg:col-6 col-12 text-center">
            <p-calendar
                    class="max-w-full"
                    [(ngModel)]="date"
                    (onMonthChange)="handleMonthChange($event)"
                    (onSelect)="handleSelectDate($event)"
                    view="date"
                    dateFormat="yy-mm-dd"
                    [minDate]="today"
                    [inline]="true"
                    [showWeek]="false"
                    [firstDayOfWeek]="1"
                    [disabledDays]="[0,6]"
            >

                <ng-template pTemplate="date" let-date>
                    <span [class]="isDayFree(date) ? 'free-day' : ''">{{ date.day }}</span>
                </ng-template>

            </p-calendar>
        </div>

        <div class="lg:col-6 col-12">

            <div class="border-1 border-300 border-round-lg h-full p-3" *ngIf="slots">

                <div class="mb-5 flex flex-column">
                    <span class="text-color text-3xl font-semibold px-2">Zeitfenster</span>

                    <p-message text="Keine freien Zeiten an diesem Tag"
                               severity="warn"
                               *ngIf="slots.length == 0"
                               [ngStyle]="{'word-break': 'break-word'}"></p-message>

                </div>


                <div
                        *ngFor="let slot of slots"
                        [ngClass]="selectedSlot?.startTime == slot.startTime ? 'bg-primary-50 border-blue-500' : 'surface-border'"
                        class="text-primary border-round-lg p-3 mb-4 border-1"
                        (click)="onSelect(slot)">
                    <div class="flex justify-content-between">
                        <span>{{ slot.startTimeFormatted }} - {{ slot.endTimeFormatted }}</span>
                        <span>{{ slot.duration | mvsPeriodDurationPipe }}</span>
                    </div>
                </div>

                <div class="mt-5" *ngIf="slots.length > 0">
                    <p-button
                            label="Buchen"
                            iconPos="right"
                            icon="pi pi-arrow-right"
                            (click)="schedule()"
                    ></p-button>
                </div>

            </div>


        </div>
    </div>


</ng-container>
