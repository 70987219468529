import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {MySpacePage} from "./page/overview/my-space/my-space.page";
import {CrModule} from "../cr/cr.module";

@NgModule({
    declarations: [
        MySpacePage,
    ],
    exports: [
        MySpacePage,
    ],
    imports: [
        CoreModule,
        CrModule
    ]
})
export class WsModule {
}

 
 


