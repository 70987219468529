import {NsNotificationSendRequestRecipientDto} from "./ns-notification-send-request-recipient.dto";
import {NsNotificationSendRequestChannelDto} from "./ns-notification-send-request-channel.dto";
import {NsNotificationObjectReferenceDto} from "./ns-notification-object-reference.dto";

export class NsNotificationSingleSendRequestDto {


    // definition of object based notification
    public objectBasedNotification: boolean;
    public referenceObject: NsNotificationObjectReferenceDto;

    // Which notification Type
    public sourceNotificationTypeId: number;

    // recipient
    public sendRequestRecipient: NsNotificationObjectReferenceDto;

    // channels
    public channels: NsNotificationSendRequestChannelDto[];

    // variables
    public variables: Map<number,any>;

    /**
     * Create simple send request.
     * @param notificationTypeId
     * @param sendRequestRecipient
     * @param channel
     */
    public static createSimple(
        sourceNotificationTypeId: number,
        sendRequestRecipient: NsNotificationObjectReferenceDto,
        channel: NsNotificationSendRequestChannelDto) {

        const sendRequest = new NsNotificationSingleSendRequestDto();

        sendRequest.sourceNotificationTypeId = sourceNotificationTypeId;
        sendRequest.sendRequestRecipient = sendRequestRecipient;
        sendRequest.channels = [ channel ];

        return sendRequest;

    }

    public static createObjectBased(
        sourceNotificationTypeId: number,
        referenceObject: NsNotificationObjectReferenceDto,
        sendRequestRecipient: NsNotificationObjectReferenceDto,
        channel: NsNotificationSendRequestChannelDto) {

        const sendRequest = new NsNotificationSingleSendRequestDto();

        sendRequest.sourceNotificationTypeId = sourceNotificationTypeId;
        sendRequest.sendRequestRecipient = sendRequestRecipient;
        sendRequest.channels = [ channel ];
        sendRequest.objectBasedNotification = true;
        sendRequest.referenceObject = referenceObject;

        return sendRequest;

    }




}