<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="lg:col-2 md:col-3 col-12 flex flex-column gap-2">
            <mvs-widget *ngIf="personRelationWidget"
                        [widgetData]="personRelationWidget"
                        (onObjectSelect)="handleRelatedPersonSelect($event)">
                <div class="">
                    <ng-template let-item="entry">

                        <div class="flex flex-column gap-2 cursor" *ngIf="item">
                            <!--                        {{item | json}}-->
                            <div class="flex gap-2">
                            <span class="w-2rem h-2rem surface-100 p-2 border-circle">
                                <i class="{{item.image}} {{item.rgbaColor}}"></i>
                            </span>
                                <div class="flex flex-column">
                                    <span class="font-medium"> {{item.name}}</span>
                                    <span class="text-500">{{item.subText}}</span>
                                </div>
                            </div>

                            <!--                        <span *ngIf="item['__count']">Count: {{item['__count']}}</span>-->
                        </div>
                    </ng-template>
                </div>
            </mvs-widget>

            <mvs-widget *ngIf="insurableObjectsWidget"
                        [widgetData]="insurableObjectsWidget"
                        (onObjectSelect)="handleInsuredObjectSelect($event)"
            >
                <ng-template let-item="entry">

                    <div class="flex flex-column gap-2 cursor" *ngIf="item">
                        <div class="flex gap-2">
                            <span class="w-2rem h-2rem surface-100 p-2 border-circle">
                                <i class="{{item.image}} {{item.rgbaColor}}"></i>
                            </span>
                            <div class="flex flex-column">
                                <span class="font-medium"> {{item.name}}</span>
                                <span class="text-500">{{item.subText}}</span>
                            </div>
                        </div>

                        <!--                        <span *ngIf="item['__count']">Count: {{item['__count']}}</span>-->
                    </div>

                </ng-template>
            </mvs-widget>
        </div>

        <div class="lg:col-8 md:col-6 col-12 flex flex-column gap-2">
            <p-button [label]="showActive ? 'Show Inactive' : 'Show Active'" (click)="handleToggleContracts()"></p-button>
            <div class="col-12">
                <mvs-widget *ngIf="customerContractsWidget"
                            [widgetData]="customerContractsWidget"
                            (onObjectSelectDtoList)="handleContractsDtoList($event,'#target')"
                            [uiStyle]="contractPersonWidgetStyle"
                >
                    <ng-template let-object="object" mvs-widget-custom-field-directive label="Relationships"
                                 fieldId="testDataField">
                        <contracts-relationships [customerContractDto]="object.dto"
                                                 [customerContractsRuntime]="customerContractsRuntime">
                        </contracts-relationships>
                    </ng-template>
                </mvs-widget>
            </div>
        </div>

        <div class="lg:col-2 md:col-3 col-12 flex flex-column gap-2">
            <mvs-widget *ngIf="householdPersonsWidget"
                        [widgetData]="householdPersonsWidget"
                        [uiStyle]="contractPersonWidgetStyle"
                        (onObjectSelect)="handleHouseholdPersonSelect($event)"
            >
                <ng-template let-value="entry">

                    <div class="flex flex-column gap-2 cursor" *ngIf="value">
                        <div class="flex gap-2">
                            <span class="w-2rem h-2rem surface-100 p-2 border-circle">
                                <i class="{{value.image}} {{value.rgbaColor}}"></i>
                            </span>
                            <div class="flex flex-column">
                                <span class="font-medium"> {{value.name}}</span>
                                <span class="text-500">{{value.subText}}</span>
                            </div>
                        </div>

                        <!--                        <span *ngIf="item['__count']">Count: {{item['__count']}}</span>-->
                    </div>

                </ng-template>
            </mvs-widget>
        </div>


    </div>

</ng-container>