@if (initialized) {
    <div class="flex justify-content-center p-2" *ngIf="!readonly">
        <div class="login-box">
            <div class="surface-card p-4 border-round w-full">
                <div class="text-center mb-5">
                    <div class="text-color text-3xl font-medium mb-2">User Avatar</div>
                </div>
                <div class="flex flex-column align-items-center flex-or" *ngIf="!showImageCropperDialog">
                    <!--          <span class="text-color mb-2 text-sm">Profile Picture</span>-->
                    <div class="h-7rem w-7rem border-circle">
                        <img class="h-7rem w-7rem border-circle"
                             [src]="imageSrc"/>
                    </div>
                    <div class="flex gap-2">
                        <input id="upload" type="file" class="none" hidden accept="image/*"
                               (change)="onUploadHandler($event)"/>
                        <label for="upload"
                               class="p-element p-button-rounded p-button-text surface-500 w-2rem h-2rem -mt-3 p-button p-component p-button-icon-only">
                            <span class="p-button-icon text-sm pi pi-upload text-white" aria-hidden="true"></span>
                        </label>

                        <label for="delete"
                               class="p-element p-button-rounded p-button-text  surface-500 w-2rem h-2rem -mt-3 p-button p-component p-button-icon-only"
                               *ngIf="imageSrc && !isSvg">
                            <span id="delete" (click)="handleDeleteImage()"
                                  class="p-button-icon text-sm pi pi-trash text-white"></span>
                        </label>
                    </div>

                </div>

                <ng-container *ngIf="showImageCropperDialog">
                    <div class="align-items-center h-full">
                        <image-cropper
                                *ngIf="imageChangedEvent"
                                [imageChangedEvent]="imageChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="1/1"
                                format="png"
                                (imageCropped)="imageCropped($event)"
                        style="max-height: 28rem">
                        </image-cropper>

                        <div class="flex justify-content-end mt-5">
                            <button type="button" pButton label="Übernehmen" icon="pi pi-check"
                                    (click)="uploadCroppedImage()"></button>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>

    <div *ngIf="readonly">
        <img class="h-2rem w-2rem border-circle"
             [src]="imageSrc"/>
    </div>

}
