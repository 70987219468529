import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {WfActivityRefreshType} from "../../enum/wf-activity-refresh-type.enum";

@Component({
    selector: 'mvs-refresh',
    templateUrl: './refresh.component.html',
})
export class RefreshComponent implements OnInit {


    disableButton: boolean;
    interval: any;
    countdown: number;
    showIcon: boolean = true;
    @Input() refreshType: WfActivityRefreshType;
    @Output() onRefresh: EventEmitter<boolean> = new EventEmitter<boolean>;

    constructor() {
    }

    ngOnInit() {
        if (this.refreshType == WfActivityRefreshType.auto || this.refreshType == WfActivityRefreshType.available_auto) {
            this.autoRefreshStartInterval();
        }

    }

    onRefreshList() {
        this.countdown = 10;
        this.disableButton = true;
        this.onRefresh.emit(true)
    }

    handleRefresh() {
        this.onRefresh.emit(true)
    }

    /**
     * when called start the interval for 20 times and called after each 1 second
     */
    autoRefreshStartInterval() {
        let counter = 0;
        this.interval = setInterval(() => {
            this.countdown--;

            this.disableButton = false;
            if (this.countdown === 0) {
                this.onRefreshList();
                counter++;

                if (counter === 20) {
                    this.autoRefreshStopInterval();
                }
            }
        }, 1000);
    }

    /**
     * stops the auto refresh interval
     */
    autoRefreshStopInterval() {
        this.countdown = 0;
        this.showIcon = false
        this.interval.unsubscribe();
    }


}

