import {Component} from '@angular/core';
import {
    MvsCoreService, MvsMessageService,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, Sorting,
    TabEntries, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {MessageService} from "primeng/api";


@Component({
    selector: 'mvs-cr-config',
    templateUrl: './hc-config.page.html',
})
export class HcConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Data Hub Configuration";

    activeNavItem: string = "hc";


    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    navigationItems: NavigationItem[] = [
        {
            label: 'hc',
            action: 'hc',
            tooltip: 'Data Hubs',
            icon: 'fa-brands fa-hubspot', // Represents connections or adapters
            toggleable: false,
            default: false
        },
        {
            label: 'hc_a',
            action: 'hc_a',
            tooltip: 'Adapters',
            icon: 'fa-solid fa-link', // Represents connections or adapters
            toggleable: false,
            default: false
        },
        {
            label: 'hc_s',
            action: 'hc_s',
            tooltip: 'Systems',
            icon: 'fa-solid fa-server', // Represents systems or infrastructure
            toggleable: false,
            default: false
        },
        {
            label: 'hc_t',
            action: 'hc_t',
            tooltip: 'Transformations',
            icon: 'fa-solid fa-exchange-alt', // Represents transformations or exchanges
            toggleable: false,
            default: false
        },
        {
            label: 'hc_i',
            action: 'hc_i',
            tooltip: 'Integrations',
            icon: 'fa-solid fa-project-diagram', // Represents integrations or connections
            toggleable: false,
            default: false
        },
        {
            label: 'he',
            action: 'he',
            tooltip: 'Extended Core Configuration',
            icon: 'fa-solid fa-sliders-h', // Represents extended configurations and tools
            toggleable: false,
            default: false
        },
        {
            label: 'ha',
            action: 'ha',
            tooltip: 'Alpha Integration',
            icon: 'fa-solid fa-plug', // Represents integrations or connectivity
            toggleable: false,
            default: false
        },
        {
            label: 'hb',
            action: 'hb',
            tooltip: 'Bipro',
            icon: 'fa-solid fa-handshake', // Represents agreements or collaborations
            toggleable: false,
            default: false
        },
        {
            label: 'ye',
            action: 'ye',
            tooltip: 'Excoda',
            icon: 'fa-solid fa-lightbulb', // Represents ideas, reasoning, or insights
            toggleable: false,
            default: false
        }
    ];

    managedEntities: { module: string, alias: string, label: string, widgetData?: WidgetData }[] = [
        { module: 'hc', alias: 'hc.DataHub', label: 'Data Hub' },
        { module: 'ha', alias: 'ha.IntegrationAlphaAuth', label: 'Alpha-Authentifizierung' },
        { module: 'ha', alias: 'ha.IntegrationAlphaBatchEntity', label: 'Alpha-Batch-Entität' },
        { module: 'ha', alias: 'ha.IntegrationAlphaBatchEntityStep', label: 'Alpha-Batch-Entität-Schritt' },
        { module: 'ha', alias: 'ha.IntegrationAlphaBatchEntityStepActivity', label: 'Alpha-Batch-Schritt-Aktivität' },
        { module: 'ha', alias: 'ha.IntegrationAlphaBatchEntityStepActivityRequest', label: 'Alpha-Batch-Schritt-Anfrage' },
        { module: 'ha', alias: 'ha.IntegrationAlphaBatchThreading', label: 'Alpha-Batch-Threading' },
        { module: 'ha', alias: 'ha.IntegrationAlphaSystem', label: 'Alpha-System' },
        { module: 'hb', alias: 'hb.IntegrationBiproAuthType', label: 'BIPRO-Authentifizierungstyp' },
        { module: 'hb', alias: 'hb.IntegrationBiproTemplate', label: 'BIPRO-Vorlage' },
        { module: 'hb', alias: 'hb.IntegrationBiproVersion', label: 'BIPRO-Version' },
        { module: 'hb', alias: 'hb.IntegrationSystemBiproAuth', label: 'BIPRO-System-Authentifizierung' },
        { module: 'hb', alias: 'hb.IntegrationSystemBiproShipment', label: 'BIPRO-System-Versand' },
        { module: 'hc_a', alias: 'hc.IntegrationAdapterType', label: 'Adapter-Typ' },
        { module: 'hc_s', alias: 'hc.IntegrationSystemType', label: 'Systemtyp' },
        { module: 'hc_t', alias: 'hc.IntegrationTransformationType', label: 'Transformationstyp' },
        { module: 'hc_i', alias: 'hc.IntegrationType', label: 'Integrationstyp' },
        { module: 'hc_i', alias: 'hc.IntegrationTypeStep', label: 'Integrationstyp-Schritt' },
        { module: 'hc_i', alias: 'hc.IntegrationTypeStepInput', label: 'Integrationstyp-Schritt-Eingabe' },
        { module: 'hc_i', alias: 'hc.IntegrationTypeStepOutput', label: 'Integrationstyp-Schritt-Ausgabe' },
        { module: 'hc_i', alias: 'hc.IntegrationTypeStepTransition', label: 'Integrationstyp-Schritt-Übergang' },
        { module: 'hc_i', alias: 'hc.IntegrationTypeVariable', label: 'Integrationstyp-Variable' },
        { module: 'he', alias: 'he.IntegrationSftpConnection', label: 'SFTP-Verbindung' },
        { module: 'ye', alias: 'ye.IntegrationExcodaConfig', label: 'Excoda-Konfiguration' },
        { module: 'ye', alias: 'ye.IntegrationExcodaConfigFileProcessor', label: 'Excoda-Dateiverarbeitung' }
    ];

    batchBusy: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        for (let managedEntity of this.managedEntities) {

            managedEntity.widgetData = WidgetFactory.createWidgetTableEntity(
                "config." + managedEntity.alias + ".simple",
                managedEntity.label,
                managedEntity.alias,
                "Keine Daten vorhanden",
                ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
            );

        }


    }

    // Method to get filtered entities
    getFilteredManagedEntities() {
        return this.managedEntities.filter(entity => entity.module === this.activeNavItem);
    }

    handleObjectSelect(objectIdentifier: ObjectIdentifierData) {
        // if (objectIdentifier.objectType == 'hc.IntegrationType' || objectIdentifier.objectType == 'ha.IntegrationAlphaBatchEntity') {
            const navigationObject = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifier.objectType, objectIdentifier.objectId, 'object', null, null, null, MvsObjectNavigationActionEnum.any, 'side');
            this.navigationService.navigateTo(navigationObject, 'right')
        // }
    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
        });
    }

    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], [])
    }


    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


}


