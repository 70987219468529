import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {EmConfigPage} from "./page/overview/hc-config/em-config.page";
import {
  EmSchemaObjectComponent
} from "./component/object-component/em-schema-object-component/em-schema-object.component";
import {
  EmSchemaObjectFullComponent
} from "./component/object-component/em-schema-object-component/view/em-schema-object-component-full/em-schema-object-full.component";
import {
  EmSchemaObjectSideComponent
} from "./component/object-component/em-schema-object-component/view/em-schema-object-component-side/em-schema-object-side.component";
import {
  EmSchemaObjectBaseComponent
} from "./component/object-component/em-schema-object-component/view/em-schema-object-base.component";
import {
  SchemaRuntimeComponent
} from "./component/object-component/em-schema-object-component/view/shared/schema-runtime-component/schema-runtime.component";
import {
  SchemaNodeComponent
} from "./component/object-component/em-schema-object-component/view/shared/schema-node-component/schema-node.component";
import {
  SchemaStructureComponent
} from "./component/object-component/em-schema-object-component/view/shared/schema-structure-component/schema-structure.component";


@NgModule({
  declarations: [
      EmConfigPage,
    EmSchemaObjectComponent,
    EmSchemaObjectFullComponent,
    EmSchemaObjectSideComponent,
    EmSchemaObjectBaseComponent,
    SchemaRuntimeComponent,
    SchemaNodeComponent,
    SchemaStructureComponent,
  ],
  exports:[
    EmConfigPage,
    EmSchemaObjectComponent,
    EmSchemaObjectFullComponent,
    EmSchemaObjectSideComponent,
    EmSchemaObjectBaseComponent,
    SchemaRuntimeComponent,
    SchemaNodeComponent,
    SchemaStructureComponent,
  ],
  imports: [
    CoreModule,

  ]
})
export class EmModule { }
 
 
 
