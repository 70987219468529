<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-4">

            <span class="text-lg font-medium"><i class="fa-light fa-file-contract"></i> Zugehörig zum Vertrag</span>

<!--            <mvs-widget-->
<!--                    *ngIf="personsWidget"-->
<!--                    [widgetData]="personsWidget"-->
<!--                    [importObjectContext]="contextContractAndCustomer"-->
<!--                    [widgetToolbarButtons]="personWidgetToolbarButtons"-->
<!--                    (onToolbarButtonClick)="handlePersonWidgetToolbarClick($event)"-->
<!--            ></mvs-widget>-->

<!--            <mvs-widget *ngIf="objectsWidget"-->
<!--                        [widgetData]="objectsWidget"-->
<!--                        [importObjectContext]="contextContractAndCustomer">-->

<!--            </mvs-widget>-->

            <cm-contract-person-object-widgets
                    *ngIf="customerDto && contractDto"
                    [customerDto]="customerDto"
                    [contractDto]="contractDto">
            </cm-contract-person-object-widgets>
        </div>

        <div class="col-4">

            <span class="text-lg font-medium"><i class="fa-light fa-file-contract"></i> Contract Former </span>

            <mvs-widget
                    *ngIf="personsContractFormerWidget"
                    [widgetData]="personsContractFormerWidget"
                    (onToolbarButtonClick)="handlePersonWidgetToolbarClick($event)"
            ></mvs-widget>

            <mvs-widget *ngIf="objectsContractFormerWidget"
                        [widgetData]="objectsContractFormerWidget">
            </mvs-widget>
        </div>

        <div class="col-4">

            <span class="text-lg font-medium"><i class="fa-light fa-user"></i> Zugehörig zum Kunden </span>

            <mvs-widget *ngIf="customerPersonsWidget"
                        [widgetData]="customerPersonsWidget"
                        [importObjectContext]="contextCustomer">

            </mvs-widget>

            <mvs-widget *ngIf="customerObjectsWidget"
                        [widgetData]="customerObjectsWidget"
                        [importObjectContext]="contextCustomer"></mvs-widget>
        </div>
    </div>

</ng-container>