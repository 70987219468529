@if (initialized) {
    <div class="flex justify-content-end p-2 mb-3 surface-0">
    <date-selection-dropdown
            [defaultOption]="'thisMonth'"
            (selectedDateStart)="handlePeriodFilter($event)"
    ></date-selection-dropdown>
    </div>

        <div class="grid dashboard">

            <div class="col-12 md:col-6 mb-2">
                <mvs-widget class="surface-0 border-round h-full block shadow-1 m-1"
                        *ngIf="customerTypeWidget"
                        [widgetData]="customerTypeWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 border-round h-full block shadow-1 m-1"
                        *ngIf="customerDistributionBrokerageWidget"
                        [widgetData]="customerDistributionBrokerageWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-3 mb-2">
                <mvs-widget class="surface-0 border-round h-full block shadow-1 m-1"
                        *ngIf="customerDistributionByConsultationTypeWidget"
                        [widgetData]="customerDistributionByConsultationTypeWidget"
                ></mvs-widget>
            </div>

            <div class="col-12 md:col-8 mb-2">
                <mvs-widget class="surface-0 border-round h-full block shadow-1 m-1"
                        *ngIf="customerListWithMostOpenTicketsWidget"
                        [widgetData]="customerListWithMostOpenTicketsWidget"
                ></mvs-widget>
            </div>

<!--            <div class="col-12 md:col-4">-->
<!--                <mvs-widget class="surface-0 h-full block shadow-1"-->
<!--                        *ngIf="customerGrowthByQuarterWidget"-->
<!--                        [widgetData]="customerGrowthByQuarterWidget"-->
<!--                ></mvs-widget>-->
<!--            </div>-->

            <div class="col-12 md:col-4 mb-2">
                <div class="shadow-1 h-full p-4 surface-0 border-round m-1">
                <h4 class="text-xl font-medium">Customer Overview this month</h4>
                <overview [itemsArray]="overviewDto" *ngIf="overviewDto"></overview>
            </div>
            </div>


        </div>

}