import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TicketService} from "../../service/api/ticket.service";
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";

@Component({
    selector: 'tm-active-ticket',
    templateUrl: './tm-active-ticket.component.html',
    styleUrls: ['./tm-active-ticket.component.scss']
})
export class TmActiveTicketComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() ticketId: number;
    ticketDto: TmTicketDto;

    constructor(protected ticketService: TicketService, protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {

        // to open ticket drawer
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshActiveTicket();
        this.initialized = true;
    }

    openTicketDrawer(ticketId: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", ticketId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }


    refreshActiveTicket() {
        this.ticketService.get(this.ticketId).subscribe((ticket: TmTicketDto) => {
            this.ticketDto = ticket;
        })
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["ticketId"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
