import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {catchError, tap} from "rxjs/operators";
import {RpReportObjectComponent} from "../../component/rp-report/rp-report-object.component";
import {Observable} from "rxjs";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class ReportService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/rp/reports');
  }

  /**
   * Retrieve Meta information for report.
   * @param id
   * @param dtoListRequest
   */
  public getMeta(id: number, dtoListRequest: ObjectRequestList) {

    const url: string = MvsCrudService.baseUrl + '/rp/reportRuntimes/' + id + '/meta';

    return this.http.post<DtoList>( url, dtoListRequest);

  }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    return RpReportObjectComponent;
  }


}
