<ng-container *ngIf="initialized">
    <div class="users">

        @for (user of uiVisibleUsers; track user.user.id) {

            <div
                    class="avatar-container"
                    pTooltip="{{ user.name }}"
                    tooltipPosition="top"
                    (click)="handleUserSelected(user.user)">
                <mvs-avatar
                        [user]="user.user"
                        [activeUser]="user.activeUser"
                        [size]="user.size"
                ></mvs-avatar>
                <!--    [avatarUrl]="user.avatarUrl"-->
                <!--    [userName]="user.name"-->

            </div>
        }

        <div
                *ngIf="remainingUserCount > 0"
                class="extra-count {{size}}"
                (click)="lockedOverlayPanel.toggle($event)">
            +{{ remainingUserCount }}
        </div>

        <p-overlayPanel #lockedOverlayPanel [dismissable]="true">
            <div class="overlay-content">
                <div
                        class="overlay-item"
                        *ngFor="let user of overlayUsers.slice(maxVisibleAvatars)"
                        (click)="handleUserSelected(user); lockedOverlayPanel.hide()"
                >
                    <mvs-avatar
                            [user]="user"
                            [activeUser]="user.role == UserObjectAccessRoleEnum.WRITE"
                            [size]="size"
                    ></mvs-avatar>
                    <!--        [avatarUrl]="user.avatarUrl"-->
                    <!--        [userName]="user.name"-->
                    <!--        [activeUser]="user.role == UserObjectAccessRoleEnum.WRITE"-->
                    <!--        [size]="size"-->
                    <span>{{ user.name }}</span>
                </div>
            </div>
        </p-overlayPanel>
    </div>

</ng-container>