import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ContractStatusTypeEntryDto} from "../../../dto/contract-status-type-entry.dto";
import {ContractStatusInternal} from "../../../enum/contract-status-internal.enum";
import {ContractStatusTypeInternal} from "../../../enum/contract-status-type-internal.enum";
import {ContractStatusTypeService} from "../../../service/api/contract-status-type.service";
import {
  DtoListAttributeRequestAggregateEnum,
  FilterCriteria,
  MvsCoreService,
  ObjectIdentifierData,
  ObjectPageComponent,
  ObjectRequestComplex,
  ObjectRequestComplexNode,
  ObjectRequestComplexRelationBindingEnum, ObjectRequestList, ObjectRequestListAttribute, ObjectRequestListGroupBy,
  ObjectRequestRelation,
  Sorting,
  WidgetData
} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-dm-document-page',
  templateUrl: './cm-contract-status-overview.page.html',
  styleUrls: ['./cm-contract-status-overview.page.scss']
})
export class CmContractStatusOverviewPage
              extends ObjectPageComponent implements OnInit {

  busy: boolean;  // indicator whether the component is busy
  initialized: boolean; // indicator whether the component was initialized

  contractStatusWidgetData: WidgetData;
  contractStatusDateWidgetData: WidgetData;
  contractStatusPeriodWidgetData: WidgetData;
  contractStatusHistoryChartWidget: WidgetData;
  statusTypeEntries: ContractStatusTypeEntryDto[] = [];

  constructor(protected route: ActivatedRoute,
              protected coreService: MvsCoreService,
              protected router: Router,
              protected contractStatusTypeService: ContractStatusTypeService,
              ) {

    super(route, coreService)
  }


  protected getObjectType(): string {
    // return Object Type
    return "cm.ContractStatus";
  }



  handleCategoryStatusSelect(object: ObjectIdentifierData) {
    this.router.navigate(['cm/contract-status-type/', object.objectId]);

  }

  /**
   * Refresh Component.
   */
  refreshComponent() {

    const complexSelection: ObjectRequestComplex = ObjectRequestComplex.build(true, false,
                    ObjectRequestComplexNode.createRelationNode("contractStatusTypeEntriesDto",
                    ObjectRequestRelation.createList(
                    "cm.ContractStatusTypeEntry",
                    "contractStatusType",
                    null,
                    [new Sorting("lastModifiedDate", false)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
    );

    const objectRequestList: ObjectRequestList = ObjectRequestList.createComplexRequestList(
        false,
        complexSelection,
        [FilterCriteria.create('statusTypeInternal', FilterCriteria.cOperatorNotEqual,ContractStatusTypeInternal.main )],
        null,
        null,
        null);

    this.contractStatusTypeService.list(objectRequestList).subscribe(res => {

      const entries = res.entries;

      for (const entry of entries) {
        for (const statusTypeEntry of entry['contractStatusTypeEntriesDto']) {
          this.statusTypeEntries.push(statusTypeEntry);
        }
      }
          this.showContractStatusCategory();
    })

    this.initialized = true;
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (!this.initialized) {
      return;
    }

    if (changes["id"]) {
      this.refreshComponent();
    }
  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {

  }

  showContractStatusCategory() {

    const tempFilterCriteria = [];
    for (const item of this.statusTypeEntries) {
      if (item.statusInternal == ContractStatusInternal.active || item.statusInternal == ContractStatusInternal.pending) {
        tempFilterCriteria.push(FilterCriteria.create("statusTypeEntry", FilterCriteria.cOperatorEqual, item.id))
      }
    }
    const filterCriteria = new FilterCriteria();
    filterCriteria.or = tempFilterCriteria;

    //show contract statuses group by status type
    this.contractStatusWidgetData = new WidgetData();
    this.contractStatusWidgetData.id = 1;
    this.contractStatusWidgetData.idAlias = "cm.contract.status.type.group.by.contract.status";
    this.contractStatusWidgetData.name = "Contract Statuses";
    this.contractStatusWidgetData.uiComponent = "category";
    this.contractStatusWidgetData.dataProvider = "list";
    this.contractStatusWidgetData.dataSource = "entity.groupBy";
    this.contractStatusWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractStatusWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["statusType"],
        [ new ObjectRequestListAttribute("statusType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
    this.contractStatusWidgetData.setParamValue("fieldCategory", "statusType");
    this.contractStatusWidgetData.setParamValue("fieldCategoryCount", "statusType_count");
    this.contractStatusWidgetData.setParamValue("selectionMode", "single");
    this.contractStatusWidgetData.setParamValue("size", "S");

    //show contract statuses group by date
    this.contractStatusDateWidgetData = new WidgetData();
    this.contractStatusDateWidgetData.id = 1;
    this.contractStatusDateWidgetData.idAlias = "cm.contract.status.type.group.by.contract.status.date";
    this.contractStatusDateWidgetData.name = "Contract Statuses By Date";
    this.contractStatusDateWidgetData.uiComponent = "category";
    this.contractStatusDateWidgetData.dataProvider = "list";
    this.contractStatusDateWidgetData.dataSource = "entity.groupBy";
    this.contractStatusDateWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractStatusDateWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["startDate"],
        [ new ObjectRequestListAttribute("startDate", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
    this.contractStatusDateWidgetData.setParamValue("fieldCategory", "startDate");
    this.contractStatusDateWidgetData.setParamValue("fieldCategoryCount", "startDate_count");
    this.contractStatusDateWidgetData.setParamValue("selectionMode", "single");
    this.contractStatusDateWidgetData.setParamValue("size", "S");

    //show contract statuses group by date
    this.contractStatusPeriodWidgetData = new WidgetData();
    this.contractStatusPeriodWidgetData.id = 1;
    this.contractStatusPeriodWidgetData.idAlias = "cm.contract.status.type.group.by.contract.status.date";
    this.contractStatusPeriodWidgetData.name = "Contract Statuses By Period";
    this.contractStatusPeriodWidgetData.uiComponent = "category.period";
    this.contractStatusPeriodWidgetData.dataProvider = "list";
    this.contractStatusPeriodWidgetData.dataSource = "entity.groupBy";
    this.contractStatusPeriodWidgetData.dataProviderObject = "cm.ContractStatus";
    this.contractStatusPeriodWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["startDate"],
        [ new ObjectRequestListAttribute("startDate", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);
    this.contractStatusPeriodWidgetData.setParamValue("fieldCategory", "startDate");
    this.contractStatusPeriodWidgetData.setParamValue("fieldCategoryCount", "startDate_count");
    this.contractStatusPeriodWidgetData.setParamValue("selectionMode", "single");
    this.contractStatusPeriodWidgetData.setParamValue("size", "S");
    this.contractStatusPeriodWidgetData.setParamValue("fieldName", "createdDate");
    this.contractStatusPeriodWidgetData.setParamValue("sortAscending", true);

    this.showContractStatusHistoryChart(filterCriteria);
  }

  showContractStatusHistoryChart(filterCriteria: FilterCriteria) {

    this.contractStatusHistoryChartWidget = new WidgetData();
    this.contractStatusHistoryChartWidget.id = 1;
    this.contractStatusHistoryChartWidget.idAlias = "cm.contract.status.type.group.by.contract.status.history.chart";
    this.contractStatusHistoryChartWidget.name = "Status History";
    this.contractStatusHistoryChartWidget.uiComponent = "history.chart";
    this.contractStatusHistoryChartWidget.dataProvider = "list";
    this.contractStatusHistoryChartWidget.dataSource = "entity.groupBy";
    this.contractStatusHistoryChartWidget.dataProviderObject = "cm.ContractStatus";
    this.contractStatusHistoryChartWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        [filterCriteria],
        [],
        ["statusType", "createdDate"],
        [ new ObjectRequestListAttribute("statusType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.contractStatusHistoryChartWidget.setParamValue("fieldCategory", "statusType");
    this.contractStatusHistoryChartWidget.setParamValue("fieldCategoryCount", "statusType_count");
    this.contractStatusHistoryChartWidget.setParamValue("historyPeriodMode", "day");
    this.contractStatusHistoryChartWidget.setParamValue("fieldDate", "createdDate");
    this.contractStatusHistoryChartWidget.setParamValue("selectionMode", "single");
    this.contractStatusHistoryChartWidget.setParamValue("size", "S");
  }

}
