import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MenuItem, TreeDragDropService} from 'primeng/api';
import {DtoDetail} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {TeTemplateArtefactVariableLinkDto} from '../../dto/te-template-artefact-variable-link.dto';
import {TeTeTemplateArtefactDto} from '../../dto/te-template-artefact.dto';
import {TeArtefactLocationEnum} from '../../enum/te-artefact-location.enum';
import {TeArtefactVariableService} from '../../service/api/te-artefact-variable.service';
import {TeArtefactService} from '../../service/api/te-artefact.service';
import {TeTemplateArtefactVariableLinkService} from '../../service/api/te-template-artefact-variable-link.service';
import {TeTemplateArtefactService} from '../../service/api/te-template-artefact.service';
import {TeTemplateVariableService} from '../../service/api/te-template-variable.service';
import {EntityPriorityHandler} from "@kvers/alpha-core-common";
import {Observable, forkJoin} from 'rxjs';
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {TeTemplateVariableDto} from "../../dto/te-template-variable.dto";
import {TeTeArtefactVariableDto} from "../../dto/te-artefact-variable.dto";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-embedded-artefacts',
    templateUrl: './embedded-artefacts.component.html',
    styleUrls: ['./embedded-artefacts.component.scss']
})
export class EmbeddedArtefactsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() templateId: number;

    artefactService: TeArtefactService;
    artefactVariableService: TeArtefactVariableService;
    templateVariableService: TeTemplateVariableService;
    templateArtefactService: TeTemplateArtefactService;
    templateArtefactVariableLinkService: TeTemplateArtefactVariableLinkService;

    artefactLocation = [
        {value: TeArtefactLocationEnum.before, label: 'Before', icon: 'fa-solid fa-up-to-line'},
        {value: TeArtefactLocationEnum.after, label: 'After', icon: 'fa-solid fa-down-from-line'}
    ];
    // array for updating artefact location using icons
    artefactLocationButton = [
        {position: TeArtefactLocationEnum.before, icon: 'fa-solid fa-up-to-line'},
        {position: TeArtefactLocationEnum.after, icon: 'fa-solid fa-down-from-line'}
    ];


    artefactLocationArray: number[] = [];

    selectedArtefact: DtoDetail;
    selectedLocation: number = TeArtefactLocationEnum.before;
    selectedPriority: number = 1;

    artefactsList: DtoList[] = [];
    templateVariables: DtoList;
    templateArtefacts: TeTeTemplateArtefactDto[] = [];

    templateVariablesDetail: TeTemplateVariableDto;
    templateArtefactsDetail: TeTeTemplateArtefactDto;
    artefactVariablesDetail: TeTeArtefactVariableDto;
    configurationItems: MenuItem[] | undefined;
    visible: boolean;
    dropLocation: string;
    dragNodeValue: any;
    showArtefacts: boolean[];


    widgetTemplateArtefacts: WidgetData;
    widgetTemplateArtefactVariableLink: WidgetData;

    importContextTemplate: DtoImportObjectContext;
    importContextArtefact: DtoImportObjectContext;

    constructor(
        protected coreService: MvsCoreService,
        protected metaService: MetaService,
        protected messageService: MvsMessageService,
        protected treeDragDropService: TreeDragDropService) {
    }

    ngOnInit(): void {
        //this.initComponent();
        //this.refreshComponent();
        this.initComponentNew();
    }

    initComponentNew() {

        this.importContextTemplate = DtoImportObjectContext.createFromObjectIdentifier(new ObjectIdentifier("te.TeTemplate", this.templateId));

        this.widgetTemplateArtefacts = WidgetFactory.createWidgetListEntity(
            "te.embedded.artefacts.list",
            "Artefakte",
            "te.TeTemplateArtefact",
            "Keine Artefakte zugewiesen",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("template", FilterCriteria.cOperatorEqual, this.templateId)],
                [new Sorting("locationEnum", true), new Sorting("priority", true)])
        );

        this.widgetTemplateArtefacts.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode('artefact'));


        this.initialized = true;
    }

    handleSelectArtefact(event: ObjectIdentifier) {

        this.importContextArtefact = DtoImportObjectContext.createFromObjectIdentifier(event);

        this.widgetTemplateArtefactVariableLink = WidgetFactory.createWidgetListEntity(
            "te.embedded.artefacts.variable.link",
            "Variables",
            "te.TeTemplateArtefactVariableLink",
            "Keine Variablen zugewiesen",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("templateArtefact", FilterCriteria.cOperatorEqual, event.objectId)],
                [new Sorting("attributeName", true)])
        );
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve template variable service
        this.templateVariableService = <TeTemplateVariableService>this.coreService.getCrudService("te.TeTemplateVariable");

        // retrieve artefact variable service
        this.artefactVariableService = <TeArtefactVariableService>this.coreService.getCrudService("te.TeArtefactVariable");

        // retrieve artefact service
        this.artefactService = <TeArtefactService>this.coreService.getCrudService("te.TeArtefact");

        // retrieve template artefact service
        this.templateArtefactService = <TeTemplateArtefactService>this.coreService.getCrudService("te.TeTemplateArtefact");

        // retrieve template artefact variable link service
        this.templateArtefactVariableLinkService = <TeTemplateArtefactVariableLinkService>this.coreService.getCrudService("te.TeTemplateArtefactVariableLink");

        // p-tree built-in observable to handle data of drop variables
        this.treeDragDropService.dragStop$.subscribe((value) => {
            // && value["node"] && !value["node"].children
            if (this.dropLocation === "dropDown") {
                this.dragNodeValue = value;
                this.findParentNodes(value);
            }
            this.dropLocation = "";
        });

    }


    refreshComponent() {

        this.refreshArtefacts();
        this.refreshTemplateArtefacts();
        this.handleConfigurationItems();

        this.initialized = true;
    }

    refreshArtefacts() {

        const dtoListRequest = new ObjectRequestList(false, [], [new Sorting("lastModifiedDate", false)])

        this.artefactService.list(dtoListRequest).subscribe((response: any) => {
            this.artefactsList = response;
        })
    }


    createTemplateArtefact() {

        const data: TeTeTemplateArtefactDto = new TeTeTemplateArtefactDto();

        data['templateDtoId'] = this.templateId;
        data['artefactDtoId'] = this.selectedArtefact.id;
        data['locationEnum'] = this.selectedLocation;
        data['priority'] = this.selectedPriority;


        if (!this.selectedArtefact.id) {
            this.messageService.showInfoMessage('Info', 'Please Select Artefact');
            return;
        }

        if (this.selectedLocation == null) {
            this.messageService.showInfoMessage('Info', 'Please Select Location');
            return;
        }

        if (!this.selectedPriority) {
            this.messageService.showInfoMessage('Info', 'Please Add Priority');
            return;
        }

        this.busy = true;

        this.templateArtefactService.create(data).subscribe(() => {

                this.busy = false;
                this.messageService.showSuccessMessage('Template Artefact', 'Template Artefact Created');
                this.refreshTemplateArtefacts();

                this.selectedArtefact = null;
                this.selectedLocation = null;
                this.selectedPriority = null;

            }, () => {

                this.busy = false;
                this.messageService.showWarningMessage('Template Artefact', 'Error');

            }
        )

    }

    changeAttributePriority(): void {

        this.busy = true;
        let operations: any = EntityPriorityHandler.adjustPriority(this.templateArtefacts, "priority", 1000);
        let updatedArray = [];

        for (const item of operations) {
            const data: any = {};
            data['id'] = item.entry.id;
            data['priority'] = item.entry.priority;
            updatedArray.push(data);
        }

        const entryObservables: Observable<any>[] = updatedArray.map(entry => {
            this.busy = false;
            return this.templateArtefactService.update(entry);
        });

        forkJoin(entryObservables).subscribe(value => {
        });
    }

    changeArtefactPosition(artefactID: number, position_priority: number, type: string) {

        this.busy = true;
        const data: any = {};
        data['id'] = artefactID;

        if (type === 'position') {
            data['locationEnum'] = position_priority;
        }

        if (type === 'priority') {
            data['priority'] = position_priority;
        }

        this.templateArtefactService.update(data).subscribe(() => {

                this.busy = false;
                this.messageService.showSuccessMessage('Template Artefact', 'Template Artefact Updated');
                this.refreshTemplateArtefacts();

            }, () => {

                this.busy = false;
                this.messageService.showWarningMessage('Template Artefact', 'Error');

            }
        )

    }

    refreshTemplateArtefacts() {

        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ObjectRequestComplexNode.createSimpleAttributeNode("template").addNodes(
                    ObjectRequestComplexNode.createRelationNode("templateVariables",
                        ObjectRequestRelation.createList(
                            "te.TeTemplateVariable",
                            "template",
                            null,
                            null,
                            ObjectRequestComplexRelationBindingEnum.ALL)
                    )
                ),
                ObjectRequestComplexNode.createSimpleAttributeNode("artefact").addNodes(
                    ObjectRequestComplexNode.createRelationNode("artefactVariables",
                        ObjectRequestRelation.createList(
                            "te.TeArtefactVariable",
                            "artefact",
                            null,
                            null,
                            ObjectRequestComplexRelationBindingEnum.ALL)
                    ).addNodes(
                        ObjectRequestComplexNode.createRelationNode("artefactVariableLink",
                            ObjectRequestRelation.createList(
                                "te.TeTemplateArtefactVariableLink",
                                "artefactVariable",
                                null,
                                null,
                                ObjectRequestComplexRelationBindingEnum.ANY)
                        )
                    )
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            [FilterCriteria.create("template", FilterCriteria.cOperatorEqual, this.templateId)],
            null,
            null,
            null);


        this.templateArtefactService.list(objectRequestList).subscribe((response: DtoList<TeTeTemplateArtefactDto>) => {
            this.templateArtefacts = response.entries;
            if (!this.showArtefacts?.length) {
                this.showArtefacts = new Array(response.entries.length).fill(false);
            }
            this.artefactLocationArray = this.templateArtefacts.map(entry => {
                return entry['locationEnum'];
            });
        });
    }

    onSaveTemplateArtefactVariableLink(templateArtefacts: TeTeTemplateArtefactDto, templateVariables: TeTemplateVariableDto, artefactVariables: TeTeArtefactVariableDto, attributeName: string) {
        // function to link template variables with artefact variables

        const data: TeTemplateArtefactVariableLinkDto = new TeTemplateArtefactVariableLinkDto();


        data['templateArtefactDtoId'] = templateArtefacts.id;
        data['artefactVariableDtoId'] = artefactVariables.id;
        data['templateVariableDtoId'] = templateVariables.id;
        data['attributeName'] = attributeName;

        this.busy = true;
        if (artefactVariables['artefactVariableLink']) {
            data.id = artefactVariables['artefactVariableLink'].id;

            this.templateArtefactVariableLinkService.update(data).subscribe(
                res => {
                    this.busy = false;
                    this.messageService.showSuccessMessage('Variable Link', 'Variable Link Updated');
                    this.refreshTemplateArtefacts();

                }, () => {

                    this.busy = false;
                    this.messageService.showWarningMessage('Variable Link', 'Error');

                }
            )

        } else {
            this.templateArtefactVariableLinkService.create(data).subscribe(
                res => {
                    this.busy = false;
                    this.messageService.showSuccessMessage('Variable Link', 'Variable Link Created');
                    this.refreshTemplateArtefacts();
                }, () => {

                    this.busy = false;
                    this.messageService.showWarningMessage('Variable Link', 'Error');

                }
            )
        }

    }

    onRemoveTemplateArtefactVariableLink(templateArtefactVariableLink: TeTemplateArtefactVariableLinkDto) {

        this.busy = true;
        this.templateArtefactVariableLinkService.delete(templateArtefactVariableLink.id).subscribe(
            res => {
                this.busy = false;
                this.messageService.showSuccessMessage('Variable Link', 'Variable Link Deleted');
                this.refreshTemplateArtefacts();

            }, () => {

                this.busy = false;
                this.messageService.showWarningMessage('Variable Link', 'Error');

            }
        )
    }


    private findParentNodes(value: any) {
        // to find the name/label of parent nodes
        if (value.node.parent.data.joinRelationEnum == MetaDataJoinRelationEnum.oneToMany) {
            this.messageService.showInfoMessage('Info', "one to many can't be bind");
            return;
        }
        let node = value.node;
        let nodes = [];
        const attribute = node.parent?.data?.typeId ? '.' + value.node.label : value.node.label;
        nodes.push(attribute);

        while (node.parent) {
            if (node.parent.data.typeId) {
                nodes.unshift('#' + node.parent.data.typeId);
            } else if (node.parent?.parent) {
                const attr = node.data.typeId ? node.parent.label : node.parent.label + '.';
                nodes.unshift(attr)
            }
            node = node.parent;
        }
        this.dragNodeValue = nodes.toString().replace(/,/g, '');
        this.templateVariablesDetail = node.data;
        this.onSaveTemplateArtefactVariableLink(this.templateArtefactsDetail, this.templateVariablesDetail, this.artefactVariablesDetail, this.dragNodeValue)
    }

    handleOnDrop(event: DragEvent, templateArtefacts: TeTeTemplateArtefactDto, artefactVariables: TeTeArtefactVariableDto): void {
        // assigning templateArtefacts & artefactVariables to corresponding variables
        this.templateArtefactsDetail = templateArtefacts;
        this.artefactVariablesDetail = artefactVariables;

        this.dropLocation = "dropDown";
        event.preventDefault();

    }

    handleConfigurationItems() {

        this.configurationItems = [
            {
                label: 'Add Artefact',
                icon: 'fa-solid fa-plus',
                command: (event: any) => this.toggleDialog()
            }

        ];
    }

    toggleDialog() {
        this.visible = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
