<ng-container *ngIf="initialized">
    <div class="flex flex-column gap-2 px-3 mt-3">

        <div class="flex justify-content-between">
            <span class="text-lg text-900 font-normal">Beteiligte</span>
            <span *ngIf="agentList" class="text-primary cursor" (click)="addMeToWatchlist()">Involve me</span>
        </div>
        <div *ngIf="agentList">
            <div class="col-12 p-0">
                <p-multiSelect
                        [(ngModel)]="selectedAgents"
                        [options]="agentList"
                        optionLabel="name"
                        (onChange)="createWatchlistEntry($event.itemValue)"
                        display="chip"
                        defaultLabel="Agent"
                        class="focus:border-primary"
                        [styleClass]="'w-full'"
                        [disabled]="busy"
                        (onPanelShow)="refreshAgentList()"
                ></p-multiSelect>


            </div>
        </div>
    </div>
</ng-container>