import {Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem} from "primeng/api";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CustomerPhoneContactAvailabilityService} from "../../service/api/customer-phone-contact-availability.service";
import {CustomerPhoneContactAvailabilityDto} from "../../dto/customer-phone-contact-availability.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {
    CustomerPhoneContactAvailabilityValueEnum
} from "../../enum/customer-phone-contact-availability-value-enum.enum";
import {Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {DtoDetail} from "@kvers/alpha-core-common";
import {CallService} from "../../../pc/service/api/call.service";
import {CallDto} from "../../../pc/dto/call.dto";
import {Sorting} from "@kvers/alpha-core-common";
import {OverlayPanel} from "primeng/overlaypanel";
import {WidgetData} from "@kvers/alpha-core-common";
import {MvsFormGroup} from "@kvers/alpha-core-common";
import {MvsFormControlService} from "@kvers/alpha-ui";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";

interface iTimeSlot {
    name: string,
    label: string,
    value: string,
    valueFrom: number,
    valueTo: number
}

interface iDay {
    name: string,
    label: string,
    value: string,
}


@Component({
    selector: 'mvs-cr-customer-phone-contact-availability',
    templateUrl: './cr-customer-phone-contact-availability.component.html',
    styleUrls: ['./cr-customer-phone-contact-availability.component.scss']
})
export class CrCustomerPhoneContactAvailabilityComponent
    extends ObjectBaseComponent implements OnDestroy, OnChanges {

    @Input() customerObjectId: number;

    @ViewChild("op") overlayPanel!: OverlayPanel;
    customerCallListWidget: WidgetData;
    visible: boolean;
    dto: CustomerPhoneContactAvailabilityDto;
    uiFieldOverride: any;
    formGroup: MvsFormGroup;
    hoveredDay: string | null = null;
    hoveredTimeSlot: string | null = null;
    customerCallInfo: DtoList<CallDto>;
    dayTimeCallInfo: CallDto[];
    form: MvsFormDto;
    contextMenuItems: MenuItem[];
    contextRowMenuItems: MenuItem[];
    contextColumnMenuItems: MenuItem[];
    selectedTimeSlot: { timeSlot: iTimeSlot, day: iDay };
    selectedRow: iTimeSlot;
    selectedColumn: iDay;
    broadcastSubscription: Subscription;
    objectTypeId: number;

    days: iDay[] = [
        {name: 'monday', label: "Montag", value: 'Mo.'},
        {name: 'tuesday', label: "Dienstag", value: 'Di.'},
        {name: 'wednesday', label: "Mittwoch", value: 'Mi.'},
        {name: 'thursday', label: "Donnerstag", value: 'Do.'},
        {name: 'friday', label: "Freitag", value: 'Fr.'},
        {name: 'saturday', label: "Samstag", value: 'Sa.'},
        {name: 'sunday', label: "Sonntag", value: 'So.'},
    ];

    timeSlots: iTimeSlot[] = [
        {name: 'Morning', label: "Morgens", value: '8:00 - 12:00', valueFrom: 0, valueTo: 12},
        {name: 'Noon', label: "Mittags", value: '12:00 - 14:00', valueFrom: 12, valueTo: 14},
        {name: 'Afternoon', label: "Nachmittags", value: '14:00 - 17:00', valueFrom: 14, valueTo: 17},
        {name: 'Evening', label: "Abends", value: '17:00 - 20:00', valueFrom: 17, valueTo: 24}
    ];

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected phoneContactAvailabilityService: CustomerPhoneContactAvailabilityService,
        protected callService: CallService,
        protected formService: MvsFormControlService,
        protected objectTypeService: ObjectTypeService,
        protected objectService: MvsUiObjectService,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit(): void {
        if (this.customerObjectId && !this.objectIdentifier) {
            this.objectIdentifier = new ObjectIdentifier("cr.CustomerPhoneContactAvailability", 0);
        }

        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(() => {

            if (!this.initialized) {
                return;
            }

            this.refreshComponent();
        })

        super.ngOnInit();

    }


    /**
     * return icons of customer availability
     * @param day
     * @param timePeriod
     */
    getIcon(day: string, timePeriod: string) {

        const fieldName: string = day + timePeriod;

        if (!this.dto) {
            return this.getIconFromForm(fieldName, 0);
        } else {
            return this.getIconFromForm(fieldName, this.dto[fieldName]);
        }
    }

    /**
     * get icons from form
     * @param fieldName
     * @param value
     */
    getIconFromForm(fieldName: string, value: number | null | undefined): string {

        if (!fieldName) {
            return null;
        }

        if (value == null || value == undefined) {
            return this.getIconFromForm(fieldName, 0);
        } else {
            if (this.form) {
                return this.form.formFields[fieldName].valueList.entries[value].image;
            } else {
                return null
            }
        }

    }

    /**
     * returns classes for coloring the icons
     * @param day
     * @param timePeriod
     */
    getClasses(day: string, timePeriod: string): string {
        const fieldName: string = day + timePeriod;
        const fieldValue = this.dto[fieldName];

        switch (fieldValue) {
            case CustomerPhoneContactAvailabilityValueEnum.available_preferred: {
                return 'p-button-success';
            }
            case CustomerPhoneContactAvailabilityValueEnum.not_available: {
                return 'p-button-danger';
            }
            case CustomerPhoneContactAvailabilityValueEnum.available: {
                return 'p-button-secondary';
            }
            default : {
                return '';
            }
        }
    }


    /**
     * decide whether the object should be created or updated
     * @param updateDto
     */
    getUpdateObservable(updateDto: CustomerPhoneContactAvailabilityDto): Observable<DtoDetail> {
        updateDto.id = this.objectIdentifier.objectId;

        if (updateDto.id) {
            return this.phoneContactAvailabilityService.update(updateDto);
        } else {
            return this.phoneContactAvailabilityService.create(updateDto);
        }

    }


    /**
     * when called the object is updated
     * @param updateDto
     */
    updateObject(updateDto: CustomerPhoneContactAvailabilityDto) {
        this.busy = true;
        this.getUpdateObservable(updateDto).subscribe((res: CustomerPhoneContactAvailabilityDto) => {
            this.dto = res;
            this.busy = false;
        });

    }


    /**
     * update the comment of customer phone contact availability
     * @param dto
     */
    handleComment(dto: CustomerPhoneContactAvailabilityDto) {
        const updateDto = new CustomerPhoneContactAvailabilityDto();
        updateDto.comment = dto.comment;

        this.updateObject(updateDto);

    }

    /**
     * return icon for call icons
     * @param call
     */
    getCallBadgeClass(call: CallDto): string {

        if (call?.callResult == null) {
            return null;
        }

        return this.customerCallInfo.form.getFormField('callResult').valueList.entries[call.callResult].image + ' cr-callIcon';
    }

    /**
     * shows the call details in overlay panel
     * @param day
     * @param timeSlot
     * @param event
     */
    handleCallForDayTime(day: string, timeSlot: string, event?) {

        this.overlayPanel.hide();
        const callTime = this.customerCallInfo?.entries.filter(call =>
            this.getDayFromStartTime(new Date(call.startTime)) === day &&
            this.getTimeSlotFromStartTime(new Date(call.startTime)) === timeSlot
        );

        this.dayTimeCallInfo = <CallDto[]>callTime;

        if (callTime && callTime.length && event) {
            this.overlayPanel.toggle(event);
        }

    }

    /**
     * get call details and return day and time info
     * @param day
     * @param timeSlot
     */
    getCallForDayTime(day: string, timeSlot: string): CallDto | undefined {

        const callTime = this.customerCallInfo?.entries.find(call =>
            this.getDayFromStartTime(new Date(call.startTime)) === day &&
            this.getTimeSlotFromStartTime(new Date(call.startTime)) === timeSlot
        );

        return <CallDto>callTime;
    }

    /**
     * retrieve day
     * @param startTime
     */
    getDayFromStartTime(startTime: Date): string {
        const dayIndex = startTime.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

        if (this.days[dayIndex - 1]) {
            return this.days[dayIndex - 1].name;
        } else {
            return "";
        }

    }

    /**
     * retrieve timeslot
     * @param startTime
     */
    getTimeSlotFromStartTime(startTime: Date): string {

        const hours = startTime.getHours();

        const foundEntry = this.timeSlots.find(value => (hours <= value.valueTo && hours >= value.valueFrom));

        if (foundEntry) {
            return foundEntry.name;
        } else {
            return "";
        }

    }

    /**
     * handle hover effect
     * @param day
     * @param timePeriod
     */
    handleHoverEffect(day: string, timePeriod: string) {
        this.hoveredDay = day;
        this.hoveredTimeSlot = timePeriod;
    }

    /**
     * retrieve Object
     * @protected
     */
    protected retrieveObject() {
        // needs to be implemented within root component
        this.busy = true;
        this.getRetrieveObjectObservable().subscribe(dto => {

            this.dto = dto;
            if (dto && dto.form) {
                this.form = dto.form;
            }
            if (!this.objectTypeId) {

                this.objectTypeService.getViaObjectType('cr.Customer').then(res => {
                    this.objectTypeId = res.id
                    this.onChange();
                });
            } else {
                this.onChange();
            }

        }, error => {
            this.postRefreshObjectError(error);
        });
    }

    onChange() {
        if (this.customerObjectId) {
            this.getCallDetails(this.objectTypeId, this.customerObjectId);
        } else if (this.dto) {
            this.getCallDetails(this.objectTypeId, this.dto?.customerDtoId);
        }

        this.setContextMenu();
        this.onObjectChanged();
        this.onObjectChangedPost();
    }

    /**
     * Retrieve obeservable depending on the input.
     * @protected
     */
    protected getRetrieveObjectObservable(): Observable<any> {
        if (this.objectIdentifier && this.objectIdentifier.objectId) {
            // retrieve via ID
            return this.crudService.get(this.objectIdentifier.objectId, null, true);

        } else if (this.customerObjectId) {

            // retrieve via customer ID
            return this.phoneContactAvailabilityService.listByAttribute('customer', this.customerObjectId).pipe(
                map(value => {

                    const dto = <CustomerPhoneContactAvailabilityDto>value.entries[0];
                    if (dto) {
                        dto.form = value.form;

                        this.objectIdentifier = new ObjectIdentifier("cr.CustomerPhoneContactAvailability", dto.id);

                        return dto;
                    } else {
                        const createDto: CustomerPhoneContactAvailabilityDto = new CustomerPhoneContactAvailabilityDto();
                        createDto.customerDtoId = this.customerObjectId;
                        this.updateObject(createDto);
                        return null;
                    }

                }));

        } else {
            return this.crudService.get(this.objectIdentifier.objectId, null, true);
        }

    }

    /**
     * gets the call details using call service
     * @param objectTypeId
     * @param objectId
     */
    getCallDetails(objectTypeId: number, objectId: number) {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, objectId));
        filterCriteria.push(FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, objectTypeId));

        const dtoListRequest = new ObjectRequestList(true, filterCriteria, [new Sorting('startTime', false)])

        this.callService.list(dtoListRequest).subscribe((value: DtoList<CallDto>) => {
            this.customerCallInfo = value;
        })
    }

    /**
     * get labels of call result
     * @param call
     */
    getCallResult(call: CallDto): string {
        return this.customerCallInfo.form.formFields['callResult'].valueList.entries[call.callResult].label;
    }

    /**
     * set form group
     */
    setFormGroup() {
        if (!this.dto) {
            return;
        }
        this.uiFieldOverride = {
            id: this.dto.form.formFields['comment'].id,
            field: this.dto.form.formFields['comment'],
            uiOutputControl: 'text'
        };
        this.formGroup = this.formService.buildFormGroup(this.dto.form);
        this.formGroup.patchValue(this.dto);
    }


    onObjectChanged() {
        this.setFormGroup();
    }

    /**
     * list widget to show call history
     */
    refreshCallListWidget() {

        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.dto.customerDtoId));
        filterCriteria.push(FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, this.objectTypeId));

        this.customerCallListWidget = WidgetFactory.createWidgetList(
            "cr.customer.call.list.widget",
            "Customer Calls",
            "list",
            "list",
            "entity",
            "pc.Call",
            "Kein vorheriger Vertrag vorhanden",
            ObjectRequestList.createBasic(
                true,
                filterCriteria,
                [new Sorting('startTime', false)]
            )
        );

        this.visible = true;
    }

    /**
     * initializing and defining the context menu
     */
    setContextMenu() {
        this.contextMenuItems = this.createMenuItems();
        this.contextRowMenuItems = this.createMenuItems(this.updateOnSelectRow.bind(this));
        this.contextColumnMenuItems = this.createMenuItems(this.updateOnSelectColumn.bind(this));
    }

    /**
     * create menuItem for contextMenu
     * @param updateFunction
     * @private
     */
    private createMenuItems(updateFunction?: (value: CustomerPhoneContactAvailabilityValueEnum) => void): MenuItem[] {
        return [
            this.createMenuItem('nicht definiert', 'fa-solid fa-empty-set text-primary-500', updateFunction, CustomerPhoneContactAvailabilityValueEnum.not_defined),
            this.createMenuItem('verfügbar', 'fa-solid fa-circle-check text-orange-800', updateFunction, CustomerPhoneContactAvailabilityValueEnum.available),
            this.createMenuItem('bevorzugt', 'fa-solid fa-badge-check text-green-500', updateFunction, CustomerPhoneContactAvailabilityValueEnum.available_preferred),
            this.createMenuItem('nicht verfügbar', 'fa-solid fa-circle-xmark text-red-500', updateFunction, CustomerPhoneContactAvailabilityValueEnum.not_available),
        ];
    }

    /**
     * create menu item
     * @param label
     * @param icon
     * @param command
     * @param value
     * @private
     */
    private createMenuItem(label: string, icon: string, command: (value: CustomerPhoneContactAvailabilityValueEnum) => void, value: CustomerPhoneContactAvailabilityValueEnum): MenuItem {
        return {label, icon, command: () => (command ? command(value) : this.updateOnSelectMenu(value))};
    }

    /**
     * update the single selected slot
     * @param availability
     */
    updateOnSelectMenu(availability: CustomerPhoneContactAvailabilityValueEnum) {

        const fieldName: string = this.selectedTimeSlot.day.name + this.selectedTimeSlot.timeSlot.name;
        const updateDto = new CustomerPhoneContactAvailabilityDto();
        updateDto[fieldName] = availability;

        this.updateObject(updateDto);

    }

    /**
     * update the whole row
     * @param availability
     */
    updateOnSelectRow(availability: CustomerPhoneContactAvailabilityValueEnum) {

        const updateDto = new CustomerPhoneContactAvailabilityDto();
        for (let timeSlot of this.days) {
            const fieldName: string = timeSlot.name + this.selectedRow.name;
            updateDto[fieldName] = availability;
        }
        this.updateObject(updateDto);

    }

    /**
     * update the whole column
     * @param availability
     */
    updateOnSelectColumn(availability: CustomerPhoneContactAvailabilityValueEnum) {

        const updateDto = new CustomerPhoneContactAvailabilityDto();
        for (let day of this.timeSlots) {
            const fieldName: string = this.selectedColumn.name + day.name;
            updateDto[fieldName] = availability;
        }
        this.updateObject(updateDto);

    }

    /**
     * on select column
     * @param column
     */
    onSelectColumn(column: iDay) {
        this.selectedColumn = column;
    }

    /**
     * on select row
     * @param row
     */
    onSelectRow(row: iTimeSlot) {
        this.selectedRow = row;
    }

    ngOnChanges(changes: SimpleChanges): void {

        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

        if (changes["customerObjectId"]) {
            this.refreshObject(false);
        }

    }

    ngOnDestroy() {
        this.broadcastSubscription.unsubscribe();
        super.ngOnDestroy();
    }

}
