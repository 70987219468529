<!--<mvs-crud-page [objectStructureRequest]="objectBrowserRequest"-->
<!--               [defaultLabel]="defaultLabel">-->
<!--</mvs-crud-page>-->

<div class="bg-white">
    <mvs-config-header header="Address Configuration" [categoryTypeMode]="'channel'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                        </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'ad.AddressType'">
            <mvs-widget *ngIf="adAddressTypeWidget"
                        [widgetData]="adAddressTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ad.Country'">
            <mvs-widget *ngIf="adCountryWidget"
                        [widgetData]="adCountryWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'ad.AddressTypeObjectType'">
            <mvs-widget *ngIf="adAddressTypeObjectTypeWidget"
                        [widgetData]="adAddressTypeObjectTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

    </ng-container>

</div>
