<ng-container *ngIf="initialized">
    <form [formGroup]="widgetUiField">
        <div [formGroupName]="'uiConfigData'">
            <div class="grid">
                <div class="col-12">
                    <h4>One To Many Input Config</h4>
                </div>

                <div class="col-3">
                    <label for="objectType">Object Type</label>
                    <mvs-object-type id="objectType"
                                     [objectTypeAlias]="widgetUiField.get('uiConfigData').get('objectType').value"
                                     (objectTypeChange)="handleObjectType($event,'objectType')"></mvs-object-type>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="objectId">Object ID</label>
                        <p-dropdown
                                [options]="objectIdOptions"
                                formControlName="objectId"
                                id="objectId"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select Object ID"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <label for="linkedEntityObjectType">Linked Entity Object Type</label>
                    <mvs-object-type id="linkedEntityObjectType"
                                     [objectTypeAlias]="widgetUiField.get('uiConfigData').get('linkedEntityObjectType').value"
                                     (objectTypeChange)="handleObjectType($event,'linkedEntityObjectType')"></mvs-object-type>
                </div>

                <div class="col-3">
                    <label for="linkedEntityTargetObjectType">Linked Entity Target Object Type</label>
                    <mvs-object-type id="linkedEntityTargetObjectType"
                                     [objectTypeAlias]="widgetUiField.get('uiConfigData').get('linkedEntityTargetObjectType').value"
                                     (objectTypeChange)="handleObjectType($event,'linkedEntityTargetObjectType')"></mvs-object-type>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="linkedEntityTargetAttributeName">linked Entity Target Attribute Name</label>
                        <p-dropdown
                                [options]="linkedEntityTargetAttribute"
                                formControlName="linkedEntityTargetAttributeName"
                                id="linkedEntityTargetAttributeName"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="uiLabel"
                                optionValue="id"
                                placeholder="Select Attribute"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="fieldName">Field Name</label>
                        <p-dropdown
                                [options]="linkedEntityTargetField"
                                formControlName="fieldName"
                                id="fieldName"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="uiLabel"
                                optionValue="id"
                                placeholder="Select Attribute"
                                [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>


            </div>
        </div>
    </form>

</ng-container>


<ng-container *ngIf="!initialized">
    <p-progressSpinner ariaLabel="Processing"/>
</ng-container>
