@if (activeNavigationItem.action == 'gdvPartner') {

    <div class="grid">
        <div class="col-12">
            <mvs-widget *ngIf="basicWidgetData" [widgetData]="basicWidgetData"></mvs-widget>
        </div>

        <div class="col-12">
            @if (!dto?.schemaDtoId) {
                <p-progressSpinner styleClass="w-3rem h-3rem" *ngIf="busy"></p-progressSpinner>
                <p-button label="Generate Schema" [disabled]="busy" (click)="generateSchema()"></p-button>
            }
        </div>
    </div>
}

@if (activeNavigationItem.action == 'test') {
    <hg-gdv-test-component
            *ngIf="objectIdentifier.objectId"
            [hgPartnerId]="objectIdentifier.objectId"
    ></hg-gdv-test-component>
}