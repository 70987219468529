import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {EuRegistrationDto} from "../../../../eu/dto/eu-registration.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {Router} from "@angular/router";


/**
 *
 * first get the required registration data as a list
 *          create widget with transient data
 *                      select registration and also pass the list to single registration component for navigation through different entries
 *
 */

@Component({
    selector: 'cr-manage-registration-widget',
    templateUrl: './cr-manage-registration-widget.component.html',
})
export class CrManageRegistrationWidgetComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    userRegistrationWidget: WidgetData;

    userRegistrationDtoList: DtoList<EuRegistrationDto>;

    isPending = true;

    constructor(protected router: Router) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshUserRegistrationWidget();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    getFilter(): FilterCriteria[] {

        let filter: FilterCriteria[];

        // if (this.isPending) {
        //     filter = FilterCriteria.createAnd(
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 2),
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 3),
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 4));
        // } else {
        //     filter = [FilterCriteria.createOr(
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 2),
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 3),
        //         FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 4))];
        // }

        return filter;
    }

    refreshUserRegistrationWidget() {

        let filter: FilterCriteria[] = this.getFilter();

        const complexRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createRelationNode('customer', ObjectRequestRelation.createJoinWithBindingType('cr.Customer#registration', ObjectRequestComplexRelationBindingEnum.LATEST))
                    .addNodes(ObjectRequestComplexNode.createRelationNode("user", ObjectRequestRelation.createJoinWithBindingType("cr.CustomerUser#customer", ObjectRequestComplexRelationBindingEnum.LATEST)))),
            filter,
            [new Sorting("id", false)],
            PagingDto.create(0, 10)
        )


        this.userRegistrationWidget = WidgetFactory.createWidgetTableEntity(
            "cr.manage.registration",
            `User Registration`,
            "eu.EuRegistration",
            "No Data",
            complexRequest
        );
        this.refreshComponent()
    }

    refreshWidget() {
        this.userRegistrationWidget = WidgetFactory.createWidgetTransient(
            'cr.manage.registration',
            "User Registration",
            'table',
            'transient',
            'transient',
            'eu.EuRegistration',
            this.userRegistrationDtoList,
        );

    }


    handleRegistrationStatusFilter(value: boolean) {
        if (this.isPending == value) {
            return;
        }
        this.userRegistrationDtoList = null;
        this.userRegistrationWidget = null;
        this.isPending = value;
        this.refreshUserRegistrationWidget();
    }

    handleRegistration(event: { dto: DtoDetail, row: DtoDetail }) {
        const selectedUserRegistration = event.dto as EuRegistrationDto;

        this.router.navigate(['cr/registrationManage/', selectedUserRegistration.id], {
            queryParams: { isPending: this.isPending ? 'true' : 'false' }
        });

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
