import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectChangeInformation} from "@kvers/alpha-core-common";

@Component({
    selector: 'cm-create-new-insurable-object',
    templateUrl: './cm-create-new-insurable-object.component.html',
})
export class CmCreateNewInsurableObjectComponent
    implements OnInit {

    @Input() customerId: number;
    @Input() insurableObjectType: string;

    @Output() onChangedObject = new EventEmitter<ObjectChangeInformation>();

    forceCreate:boolean;
    stepNumber: number;

    insurableObjectOptions = [
        { name: 'Person', id: 1, icon: 'fa-regular fa-users', buttonText: 'Create Person' },
        { name: 'Others', id: 2, icon: 'fa-regular fa-forward', buttonText: 'Create Others'}
    ];

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    onForceCreate(type:string) {
        this.forceCreate = true;
        this.insurableObjectType = type;
    }
    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }
    stepValue(value: number) {
        this.stepNumber = value;
        if (this.stepNumber == 0) {
            this.insurableObjectType = null;
        }
    }
    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleChangedObject(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }

}
