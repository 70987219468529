import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";

@Component({
    selector: 'ci-search-flexible-insurance',
    templateUrl: './ci-search-flexible-insurance.component.html',
})
export class CiSearchFlexibleInsuranceComponent
    implements OnInit {

    @Output() onObjectSelect: EventEmitter<ObjectIdentifierData> = new EventEmitter<ObjectIdentifierData>();
    @Input() currentObjectId: number;
    widgetTableViaCustomer: WidgetData;

    constructor() {

    }

    ngOnInit() {
        if (this.currentObjectId) {

            const obj = {id: this.currentObjectId, name: ''};
            this.handleCustomerObject(obj);

        }
    }

    handleCustomerObjectSelect(event: ObjectIdentifierData) {
        if (!event.data.contractDto) {
            return;
        }
        const contract = event.data.contractDto;
        const objectIdentifierData: ObjectIdentifierData = new ObjectIdentifierData('cm.Contract', contract.id, contract);
        this.handleObjectSelect(objectIdentifierData);
    }

    handleObjectSelect(event: ObjectIdentifierData) {
        this.onObjectSelect.emit(event);
    }

    /**
     * method to load Contracts info using customer details
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTableViaCustomer(complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]) {
        this.widgetTableViaCustomer = this.loadTable('cr.CustomerInsurableObject', complexSelection, filterCriteria);
    }

    /**
     * method to return the widget table instance
     * @param dataProviderObject
     * @param complexSelection
     * @param filterCriteria
     * @private
     */
    private loadTable(dataProviderObject: string, complexSelection: ObjectRequestComplex, filterCriteria: FilterCriteria[]): WidgetData {

        const widgetData = new WidgetData();
        widgetData.idAlias = 'cm.search.flexible.contracts.' + dataProviderObject;
        widgetData.name = 'Insurable Objects';
        widgetData.uiComponent = 'table';
        widgetData.dataProvider = 'list';
        widgetData.dataSource = 'entity';
        widgetData.dataProviderObject = dataProviderObject;

        widgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(true, filterCriteria, [new Sorting('createdDate', false)], PagingDto.create(0, 8));
        widgetData.dataProviderListRequest.objectRequestComplex = complexSelection;
        widgetData.setParamValue("selectionMode", "rowSelect");
        widgetData.setParamValue("rowSelectionMode", "sticky");
        widgetData.setParamValue('size', 'S');

        return widgetData;
    }

    /**
     * handle object when row is selected from customer table
     * @param event
     */
    handleCustomerObject(event: { id: number, name: string }) {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(
            FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, event.id, null)
        );

        const complexSelection = ObjectRequestComplex.build(true, false,

            ObjectRequestComplexNode.createSimpleAttributeNode('insurableObject')
        );

        this.loadTableViaCustomer(complexSelection, filterCriteria);
    }


}
