import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldBaseComponent} from "@kvers/alpha-ui";
import {DtoDetail, MvsFormFieldDto, ObjectIdentifier} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-form-field-one-to-many',
    templateUrl: 'mvs-form-field-one-to-many.component.html'
})
export class MvsFormFieldOneToManyComponent
    extends MvsFormFieldBaseComponent
    implements OnInit, OnDestroy, OnChanges {

    objectIdentifier: ObjectIdentifier;
    linkedEntityObjectType: string;
    linkedEntityTargetObjectType: string;
    linkedEntityTargetAttributeName: string;
    fieldName: string;
    // objectIdentifier: ObjectIdentifier = new ObjectIdentifier('tm.TicketGroupFilter', 1);
    // objectIdentifier2: ObjectIdentifier = new ObjectIdentifier('cp.ProvisionTypeAgentPayment', 52);

    override ngOnInit() {
        this.refreshComponent();
    }


    override refreshComponent() {
        const objectType = this.formField['uiConfigData']['objectType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];

        let objectId: number;

        if (objectIdField && objectIdField == 'id') {
            objectId = this.objectId;
        } else {
            objectId = this.formGroup.value[objectIdField]
        }


        this.linkedEntityObjectType = this.formField['uiConfigData']['linkedEntityObjectType'];
        this.linkedEntityTargetObjectType = this.formField['uiConfigData']['linkedEntityTargetObjectType'];
        const attributeName = this.formField['uiConfigData']['linkedEntityTargetAttributeName'];
        this.linkedEntityTargetAttributeName = attributeName.replace('DtoId', '');
        this.fieldName = this.formField['uiConfigData']['fieldName']
        this.objectIdentifier = new ObjectIdentifier(objectType, objectId);


        this.initialized = true;
    }

    handleCreate(dtoDetail: DtoDetail) {

    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    ngOnDestroy() {
    }

}
