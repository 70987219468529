<div class="bg-white">
    <mvs-config-header header="Report Configuration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'rp.ReportGroup'">
            <mvs-widget *ngIf="rpReportGroupWidget"
                        [widgetData]="rpReportGroupWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'rp.Report'">
            <mvs-widget *ngIf="rpReportWidget"
                        [widgetData]="rpReportWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'rp.ReportFilter'">
            <mvs-widget *ngIf="rpReportFilterWidget"
                        [widgetData]="rpReportFilterWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'rp.ReportSorting'">
            <mvs-widget *ngIf="rpReportSortingWidget"
                        [widgetData]="rpReportSortingWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

</div>
