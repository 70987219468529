import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CmCustomerAssessmentDto} from "../../dto/cm-customer-assessment.dto";
import {AssessmentGroupService} from "../../service/api/assessment-group.service";

@Component({
    selector: 'mvs-cm-customer-assessment-component',
    templateUrl: './cm-customer-assessment.component.html',
    styleUrls: ['./cm-customer-assessment.component.scss']
})
export class CmCustomerAssessmentComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() customerId: number;
    @Input() showStack: boolean = true;
    @Input() showOverall: boolean = true;
    @Input() showTitle: boolean;
    @Input() stackTitle: string;
    @Input() overallTitle: string;
    customerAssessment: CmCustomerAssessmentDto;

    constructor(protected assessService: AssessmentGroupService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshCustomerAssessment();
        this.initialized = true;
    }

    refreshCustomerAssessment() {

        this.assessService.assessCustomer(this.customerId).subscribe((value: CmCustomerAssessmentDto) => {
            this.customerAssessment = value;
        });
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }


}
