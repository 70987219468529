import { Component, OnInit } from '@angular/core';
import {
  MvsCoreService,
  ObjectIdentifierData,
  ObjectRequestList,
  TabEntries,
  WidgetData
} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {
  MvsObjectNavigationActionEnum,
  MvsObjectNavigationEntry,
  MvsObjectNavigationService,
  WidgetFactory
} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mvs-pm-person-page',
  templateUrl: './pm-config.page.html',
  styleUrls: ['./pm-config.page.scss']
})
export class PmConfigPage extends PageComponent {

  objectBrowserRequest: any;

  objectRequestList:ObjectRequestList;
  pmPersonTypeWidget: WidgetData;
  pmPersonRelationTypeWidget: WidgetData;

  defaultLabel: string = "PM Configuration";

  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label:'Person Types', id: 'pm.PersonType', type: ''},
    {label:'Person Type Assignment', id: 'pm.PersonRelationType', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }


  ngOnInit(): void {

    // this.objectBrowserRequest = {
    //
    //   "extractDto": false,
    //   "createMode": true,
    //
    //   'entries' : [
    //     {
    //       'id'                : 1,
    //       'objectAlias'       : 'pm.PersonType',
    //       'level'             : 1,
    //       'parentBinding'     : 'none',
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Personentypen",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Typ anlegen"
    //
    //     },
    //     {
    //       'id'                : 2,
    //       'objectAlias'       : 'pm.Person',
    //       'level'             : 2,
    //       'parentBinding'     : 'mandatory',
    //       'parentId'          : 1,
    //       'dummyParentCreate' : true,
    //       'dummyParentLabel'  : "Personen",
    //       'newEntryNodeCreate': true,
    //       'newEntryNodeLabel' : "Person anlegen"
    //
    //     },
    //     {
    //       'id'                : 3,
    //       'objectAlias'       : 'pm.PersonDetail',
    //       'level'             : 3,
    //       'parentBinding'     : 'optional',
    //       'parentId'          : 2,
    //       'noTree'            : true
    //
    //     }
    //   ]
    //
    // }

    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();
    this.handleTabSelection(0);

    // const filterCriteria: FilterCriteria[] = [];
    //
    // const lastNameFilterCriteria = FilterCriteria.create("lastName", FilterCriteria.cOperatorContainsPattern, '%' + "khan" + '%', null);
    // filterCriteria.push(lastNameFilterCriteria);
    //
    // this.objectRequestList = ObjectRequestList.createWithPaging(
    //     true,
    //     filterCriteria,
    //     [new Sorting("createdDate", false)],
    //     PagingDto.create(0, 10)
    // );
  }



  refreshWidgets() {
    this.refreshPersonTypeWidget();
    this.refreshPersonRelationTypeWidget();
  }

  refreshPersonTypeWidget() {
    this.pmPersonTypeWidget = WidgetFactory.createWidgetList(
        "pm.config.person.type.widget.simple",
        'Person Type',
        'table',
        'list',
        'entity',
        'pm.PersonType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, [], [], PagingDto.create(0, 20))
    );
  }
  refreshPersonRelationTypeWidget() {
    this.pmPersonRelationTypeWidget = WidgetFactory.createWidgetList(
        "pm.config.person.relation.type.widget.simple",
        'Person Relation Type',
        'table',
        'list',
        'entity',
        'pm.PersonRelationType',
        'No records found!',
        ObjectRequestList.createWithPaging(true, [], [], PagingDto.create(0, 20))
    );
  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }

  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }


}
