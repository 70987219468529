import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {CpConfigPage} from "./page/cp-config-page/cp-config.page";
import {
  ProvisionTemplateTypeObjectComponent
} from "./component/provision-template-type-object-component/provision-template-type-object.component";
import {
  ProvisionTypeObjectComponent
} from "./component/provision-type-object-component/provision-type-object.component";
import {
  ProvisionContractSimulateComponent
} from "./component/provision-contract-simulate/provision-contract-simulate.component";
import {
  CpProvisionAgentObjectComponent
} from "./component/object-base/cp-provision-agent-object-component/cp-provision-agent-object.component";
import {
  CpProvisionAgentSideComponent
} from "./component/object-base/cp-provision-agent-object-component/views/side/cp-provision-agent-side.component";
import {
  CpProvisionAgentFullComponent
} from "./component/object-base/cp-provision-agent-object-component/views/full/cp-provision-agent-full.component";
import {CpProvisionAgentPage} from "./page/cp-provision-agent-page/cp-provision-agent.page";
import {
  ProvisionContractSimulateSideComponent
} from "./component/provision-contract-simulate/view/provision-contract-simulate-side/provision-contract-simulate-side.component";
import {
  ProvisionContractSimulateFullComponent
} from "./component/provision-contract-simulate/view/provision-contract-simulate-full/provision-contract-simulate-full.component";

@NgModule({
  declarations: [
    CpConfigPage,
    ProvisionTemplateTypeObjectComponent,
    ProvisionTypeObjectComponent,
    ProvisionContractSimulateComponent,
    CpProvisionAgentObjectComponent,
    CpProvisionAgentSideComponent,
    CpProvisionAgentFullComponent,
    CpProvisionAgentPage,
    ProvisionContractSimulateSideComponent,
    ProvisionContractSimulateFullComponent,
  ],
  exports:[
    CpConfigPage,
    ProvisionTemplateTypeObjectComponent,
    ProvisionTypeObjectComponent,
    ProvisionContractSimulateComponent,
    CpProvisionAgentObjectComponent,
    CpProvisionAgentSideComponent,
    CpProvisionAgentFullComponent,
    CpProvisionAgentPage,
    ProvisionContractSimulateSideComponent,
    ProvisionContractSimulateFullComponent,
  ],
  imports: [
    CoreModule
  ]
})
export class CpModule { }

