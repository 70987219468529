<p-tabView>

    <!--    Search Via Basic Data    -->
    <p-tabPanel header="via Kunde">

        <div class="grid">
            <div class="col-12">
                <search-flexible
                        [hidden]="customerObjectSelected"
                        [objectType]="'cr.Customer'"
                        (onObjectSelect)="handleCustomerObject($event)"
                        (onSearchAction)="handleSearchAction($event)"
                ></search-flexible>

                <mvs-display-name-card *ngIf="customerObjectSelected" [name]="customerObjectSelected.name"
                                       (clearObject)="clearObject('customer')"></mvs-display-name-card>
            </div>
            <div class="col-12 mt-5">
                <mvs-widget
                        *ngIf="widgetTableViaCustomer"
                        [widgetData]="widgetTableViaCustomer"
                        (onObjectSelect)="handleCustomerObjectSelect($event)"
                ></mvs-widget>


            </div>
        </div>

    </p-tabPanel>


    <!--    Search Via Person    -->
    <p-tabPanel header="Search by Insured Person">
        <div class="grid">
            <div class="col-12">
                <search-flexible
                        [hidden]="personObjectSelected"
                        [objectType]="'pm.Person'"
                        (onObjectSelect)="handlePersonObject($event)"
                        (onSearchAction)="handleSearchAction($event)"
                ></search-flexible>

                <mvs-display-name-card *ngIf="personObjectSelected" [name]="personObjectSelected.name"
                                       (clearObject)="clearObject('person')"></mvs-display-name-card>
            </div>

            <div class="col-12 mt-5">
                <mvs-widget
                        *ngIf="widgetTableViaPerson"
                        [widgetData]="widgetTableViaPerson"
                        (onObjectSelect)="handlePersonObjectSelect($event)"
                ></mvs-widget>
            </div>
        </div>

    </p-tabPanel>


    <!--    Search Via Basic Data    -->
    <p-tabPanel header="Search by Contract Data">

        <div class="grid">

            <div class="col-2">
<!--                <form (ngSubmit)="onSearchViaContact()">-->
<!--                    <div class="flex flex-column gap-3">-->

<!--                        <div class="flex flex-column gap-2">-->
<!--                            <label for="alias" class="text-500 text-sm">Alias</label>-->
<!--                            <input id="alias" name="alias" type="text" pInputText [(ngModel)]="alias"/>-->
<!--                        </div>-->

<!--                        <div class="flex flex-column gap-2">-->
<!--                            <label for="contractId" class="text-500 text-sm">Contract ID</label>-->
<!--                            <input id="contractId" name="contractId" type="text" pInputText [(ngModel)]="contractIdentifier"/>-->
<!--                        </div>-->

<!--                        <div class="flex flex-column gap-2">-->
<!--                            <label for="contractType" class="text-500 text-sm">Contract Type</label>-->
<!--                            <p-dropdown-->
<!--                                    id="contractType"-->
<!--                                    name="contractType"-->
<!--                                    [options]="contractType"-->
<!--                                    [(ngModel)]="selectedContractType"-->
<!--                                    placeholder="Select Contract Type"-->
<!--                                    optionLabel="label"-->
<!--                                    optionValue="key"-->
<!--                                    styleClass="w-full"-->
<!--                            ></p-dropdown>-->
<!--                        </div>-->

<!--                        <div class="flex flex-column gap-2">-->
<!--                            <label for="contractGroup" class="text-500 text-sm">Contract Group</label>-->
<!--                            <p-dropdown-->
<!--                                    id="contractGroup"-->
<!--                                    name="contractGroup"-->
<!--                                    [options]="Group"-->
<!--                                    [(ngModel)]="selectedGroup"-->
<!--                                    placeholder="Select Group"-->
<!--                                    optionLabel="label"-->
<!--                                    optionValue="key"-->
<!--                                    styleClass="w-full"-->
<!--                            ></p-dropdown>-->
<!--                        </div>-->

<!--                        <div class="flex justify-content-end">-->
<!--                            <p-button type="submit" label="Search"></p-button>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </form>-->

                <form [formGroup]="searchForm" (ngSubmit)="onSearchViaContact()">
                    <div class="flex flex-column gap-3">
                        <div class="flex flex-column gap-2">
                            <label for="alias" class="text-500 text-sm">Alias</label>
                            <input id="alias" name="alias" type="text" pInputText formControlName="alias">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="contractId" class="text-500 text-sm">Contract ID</label>
                            <input id="contractId" name="contractId" type="text" pInputText formControlName="contractId">
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="contractType" class="text-500 text-sm">Contract Type</label>
                            <p-dropdown
                                    id="contractType"
                                    name="contractType"
                                    [options]="contractType"
                                    formControlName="contractType"
                                    placeholder="Select Contract Type"
                                    optionLabel="label"
                                    optionValue="key"
                                    styleClass="w-full"
                            ></p-dropdown>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="contractGroup" class="text-500 text-sm">Contract Group</label>
                            <p-dropdown
                                    id="contractGroup"
                                    name="contractGroup"
                                    [options]="Group"
                                    formControlName="contractGroup"
                                    placeholder="Select Group"
                                    optionLabel="label"
                                    optionValue="key"
                                    styleClass="w-full"
                            ></p-dropdown>
                        </div>

                        <div class="flex justify-content-end">
                            <p-button type="submit" label="Search"></p-button>
                        </div>
                    </div>
                </form>


            </div>

            <div class="col-10">

                <mvs-display-message-before-result *ngIf="!widgetTableViaContract"></mvs-display-message-before-result>

                <mvs-widget
                        *ngIf="widgetTableViaContract"
                        [widgetData]="widgetTableViaContract"
                        (onObjectSelect)="handleObjectSelect($event)"
                ></mvs-widget>
            </div>

        </div>
    </p-tabPanel>
</p-tabView>
