import {DtoDetail, EntityStatusEnum} from "@kvers/alpha-core-common";
import { IntegrationTypeDto } from "./integration-type.dto";
import { IntegrationTransformationLanguage } from "../enum/integration-transformation-language.enum";
import {IntegrationTypeStepDto} from "./integration-type-step.dto";

export class IntegrationTypeStepTransitionDto extends DtoDetail{


    public integrationDto? : IntegrationTypeDto;
    public integrationDtoId : number;
    public integrationDtoName : string;
    public fromStepDto? : IntegrationTypeStepDto;
    public fromStepDtoId : number;
    public fromStepDtoName : string;
    public toStepDto? : IntegrationTypeStepDto;
    public toStepDtoId : number;
    public toStepDtoName : string;
    public priority: number;
    public description: string;
    public transformationType: IntegrationTransformationLanguage;
    public script: string;


    public entityStatus: EntityStatusEnum;

}