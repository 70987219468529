export enum CrCustomerObjectViewTypeUiEnum {
    standard,
    service,
    sales,
    communication,
    registration,
    ticketsAndWorkflows,
    contracts,
    interactions,
    additionalInformation,
    documentUpload,
    promo,
    genericTickets,
    genericWorkflows,

}