<ng-container *ngIf="initialized">
    <div class="grid">
        <div class="col-12">
            <div class="search-card border-round-md" style="padding: 8rem">
                <h1 class="text-center text-white font-medium mb-0">Lost & Found</h1>
                <h1 class="text-center text-white font-medium mb-5 mt-0">Deine Schatzsuche im Info-Dschungel !</h1>
                <div class="xl:col-6 lg:col-8 mx-auto ">
                    <mvs-search-input
                            [objectTypeAlias]="tempAlias? tempAlias : objectTypeAlias"
                            [maxSearchCounts]="maxSearchCount"
                            (onSearchResult)="onSearchResult($event)"
                            (onSearchReset)="isSearched = false"
                            (onType)="clearShowMore()"
                    ></mvs-search-input>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isSearched">
            <p-card class="col-12" styleClass="h-30rem overflow-auto">

                <ng-template pTemplate="header">
                    <div class="flex justify-content-between px-3 align-items-baseline">
                        <h4 class="m-0">Search Result</h4>
                        <p-button icon="pi pi-times"
                                  styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-rounded p-button-text mt-2 mr-4"
                                  (click)="isSearched = false"></p-button>
                    </div>
                </ng-template>

                <ng-container *ngIf="searchedResultList.length">


                    <div class="grid">

                        <div class="col" *ngFor="let entry of searchedResultList; let i = index">


                            <div class="flex align-items-center gap-2 p-2">
                                <span class="bold">{{entry.objectType.name}} :</span>
                                <p-badge class="ml-2"
                                         [value]="entry.resultCountsPerObjectType ? entry.resultCountsPerObjectType.toString() : '0'">
                                </p-badge>
                            </div>


                            <mvs-extend-entries
                                    [entries]="entry.searchResultEntries"
                                    [defaultSize]="entry.resultCountsPerObjectType > maxSearchCount ? entry.searchResultEntries.length-1 : maxSearchCount-1"
                                    (showMore)="showMore(entry.objectType.alias)">
                                <ng-template let-searchItem="entry" mvs-template-directive area="entry">
                                    <ul
                                            class="list-none m-0 p-0 "
                                    >
                                        <li class="select-none p-2 font-normal text-base cursor-pointer border-round-lg hover:bg-black-alpha-20 hover:text-black"
                                            (click)="handleRoute(entry.objectType,searchItem, $event)">
                                            <div class="flex gap-2 align-items-center">


                                                <!-- printing/display icons -->
                                                <span class="mvs-command-menu-data-type-icon-wrapper"
                                                      [style]="{'background':'var(--' + entry.objectType['bgColor'] +')'}">
                                                <i [style]="{'color':'var(--' + entry.objectType['color'] +')'}"
                                                   [class]="entry.objectType['icon']"></i>
                                            </span>

                                                <!-- printing/display fields -->
                                                <span *ngFor="let field of entry.objectType['fields']">
                                                <span *ngIf="searchItem.dataFields.hasOwnProperty(field.fieldName)">
                                                      {{ searchItem.dataFields[field.fieldName].originalValue }}
                                                </span>
                                            </span>
<!--                                                <span>-->
<!--                                                    {{searchItem.objectId}}-->
<!--                                                </span>-->
                                            </div>
                                        </li>
                                    </ul>
                                </ng-template>
                            </mvs-extend-entries>

                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="!searchedResultList.length">
                    No result found
                </ng-container>
            </p-card>
        </ng-container>


        <ng-container *ngIf="!isSearched">
            <div class="grid col-12 mt-2">

                <div class="lg:col-3 col-6 cursor-pointer">
                    <p-card>
                        <div class="flex align-items-center mb-3">
                            <p-button icon="fa-regular fa-ticket text-xl"
                                      styleClass="text-primary bg-primary-100"></p-button>
                            <span class="text-3xl bold mx-3">10</span>
                        </div>

                        <span class="text-base text-color-secondary">My Tickets</span>
                    </p-card>
                </div>

                <div class="lg:col-3 col-6 cursor-pointer">
                    <p-card>
                        <div class="flex align-items-center mb-3">
                            <p-button icon="fa-regular fa-ticket text-xl"
                                      styleClass="text-yellow-500 bg-yellow-100"></p-button>
                            <span class="text-3xl bold mx-3">6</span>
                        </div>

                        <span class="text-base text-color-secondary">Open Tickets</span>
                    </p-card>
                </div>

                <div class="lg:col-3 col-6 cursor-pointer">
                    <p-card>
                        <div class="flex align-items-center mb-3">
                            <p-button icon="fa-regular fa-calendar-xmark text-xl"
                                      styleClass="text-red-500 bg-red-100"></p-button>
                            <span class="text-3xl bold mx-3">4</span>
                        </div>

                        <span class="text-base text-color-secondary">Overdue Tickets</span>
                    </p-card>
                </div>

                <div class="lg:col-3 col-6 cursor-pointer">
                    <p-card>
                        <div class="flex align-items-center mb-3">
                            <p-button icon="fa-regular fa-screen-users text-lg"
                                      styleClass="text-indigo-500 bg-indigo-100"></p-button>
                            <p class="text-3xl bold mx-3">20</p>
                        </div>

                        <span class="text-base text-color-secondary">My Team Tickets</span>
                    </p-card>
                </div>

            </div>


            <div class="col-12 mt-2">
                <p-card>
                    <h3 class="mx-3">My Workspace</h3>
                    <div class="grid px-3">

                        <div class="xl:col-3 col-6">
                            <div class="p-2 border-300 border-1 h-full">
                                <h4 class="my-3"><i class="fa-regular fa-clock-rotate-left text-primary mx-2"></i>
                                    Recent Searches
                                </h4>

                                <ul class="list-none p-0 m-0" *ngIf="lastVisits && lastVisits.length">
                                    <li class="p-2 pointer hover:bg-black-alpha-10 mb-2"
                                        *ngFor="let visitedPage of lastVisits"
                                        (click)="onNavigation(visitedPage,$event)">
                                        <div class="flex justify-content-between align-items-baseline">

                                            <div class="flex align-self-baseline">
                                                <i class="fa-regular fa-clock-rotate-left mx-2 pt-1 text-sm"></i>
                                                <span class="pr-3 w-full align-self-center">
                                         {{visitedPage.label}}</span>
                                            </div>

                                            <p-button styleClass="p-button-link p-ripple justify-content-center p-0"
                                                      (click)="addToFavorites(visitedPage, $event)">
                                                <i class="fa-regular fa-star"></i>
                                            </p-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="xl:col-3 col-6">
                            <div class="p-2 border-300 border-1 h-full">
                                <h4 class="my-3"><i class="fa-regular fa-user text-primary mx-2"></i> Recent Customers
                                </h4>

                                <ul class="list-none p-0 m-0" *ngIf="recentCustomerList && recentCustomerList.length">
                                    <li class="p-2 pointer hover:bg-black-alpha-10 mb-2"
                                        *ngFor="let recentCustomer of recentCustomerList"
                                        (click)="onNavigation(recentCustomer,$event)">
                                        <div class="flex justify-content-between align-items-baseline">

                                            <div class="flex align-self-baseline">
                                                <i class="fa-regular fa-user mx-2 pt-1 text-sm"></i>
                                                <span class="pr-3 w-full align-self-center">
                                         {{recentCustomer.label}}</span>
                                            </div>

                                            <p-button styleClass="p-button-link p-ripple justify-content-center p-0"
                                                      (click)="addToFavorites(recentCustomer, $event)">
                                                <i class="fa-regular fa-star"></i>
                                            </p-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="xl:col-3 col-6">
                            <div class="p-2 border-300 border-1 h-full">
                                <h4 class="my-3"><i class="fa-regular fa-file text-primary mx-2"></i> Recent Contracts
                                </h4>

                                <ul class="list-none p-0 m-0" *ngIf="recentContractList && recentContractList.length">
                                    <li class="p-2 pointer hover:bg-black-alpha-10 mb-2"
                                        *ngFor="let recentContract of recentContractList"
                                        (click)="onNavigation(recentContract,$event)">
                                        <div class="flex justify-content-between align-items-baseline">

                                            <div class="flex align-self-baseline">
                                                <i class="fa-regular fa-file mx-2 pt-1 text-sm"></i>
                                                <span class="pr-3 w-full align-self-center">
                                                 {{recentContract.label}}</span>
                                            </div>

                                            <p-button styleClass="p-button-link p-ripple justify-content-center p-0"
                                                      (click)="addToFavorites(recentContract, $event)">
                                                <i class="fa-regular fa-star"></i>
                                            </p-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="xl:col-3 col-6">
                            <div class="p-2 border-300 border-1 h-full">
                                <h4 class="my-3"><i class="fa-regular fa-star text-primary mx-2"></i> Favourite </h4>

                                <ul class="list-none p-0 m-0" *ngIf="favorites && favorites.length">
                                    <li class="p-2 pointer hover:bg-black-alpha-10 mb-2"
                                        *ngFor="let favorite of favorites" (click)="onNavigation(favorite,$event)">
                                        <div class="flex justify-content-between align-items-baseline">

                                            <div class="flex align-self-baseline">
                                                <i class="fa-regular fa-star mx-2 pt-1 text-sm"></i>
                                                <span class="pr-3 w-full align-self-center">
                                        {{favorite.label}}</span>
                                            </div>

                                            <p-button styleClass="p-button-link p-ripple justify-content-center p-0"
                                                      (click)="removeFromFavorite(favorite.favoriteId, $event)"><i
                                                    class="fa-regular fa-xmark"></i></p-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>

                </p-card>
            </div>
        </ng-container>

    </div>
</ng-container>
