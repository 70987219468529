import {Component, OnInit} from "@angular/core";
import {NavigationItem} from "@kvers/alpha-core-common";
import {TeTemplateObjectBaseComponent} from "../te-template-object-base.component";

@Component(
    {
        selector: "te-template-object-full",
        templateUrl: "./te-template-object-full.component.html",
        styleUrls: ["./te-template-object-full.component.scss"]
    }
)

export class TeTemplateObjectFullComponent extends TeTemplateObjectBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        { label: 'Variablen',action: 'Variablen', icon: 'pi pi-folder' },
        { label: 'Artefakte',action: 'Artefakte', icon: 'fa-sharp fa-regular fa-pen-nib' },
        { label: 'Basisdaten',action: 'Basisdaten', icon: 'pi pi-info-circle' },
        { label: 'Testen',action: 'Testen', icon: 'fa-regular fa-flask-vial' },
    ];

    ngOnInit() {
        super.ngOnInit();
    }

}