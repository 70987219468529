import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {TagsComponent} from './directive/tags/tags.component';
import {CommentsComponent} from './directive/comments/comments.component';
import {UmConfigPage} from "./page/overview/config/um-config.page";
import {UmRoleObjectComponent} from "./components/object/um-role-object-component/um-role-object.component";
import {UserProfileComponent} from "./components/user-profile/user-profile.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {UserProfileIdComponent} from "./components/user-profile-id/user-profile-id.component";
import {UserOverviewPage} from "./page/user-overview-page/user-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {TmKpiComponent} from "../tm/page/ticket-overview-page/components/kpiComponent/tm-kpi.component";
import {
    TmStatisticsComponent
} from "../tm/page/ticket-overview-page/components/statisticsComponent/tm-statistics.component";
import {UmKpiComponent} from "./page/user-overview-page/components/kpiComponent/um-kpi.component";
import {UmStatisticsComponent} from "./page/user-overview-page/components/statisticsComponent/um-statistics.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
    declarations: [
        TagsComponent,
        CommentsComponent,
        UmConfigPage,
        UmRoleObjectComponent,
        UserProfileComponent,
        UserProfileIdComponent,
        UserOverviewPage,
        UmKpiComponent,
        UmStatisticsComponent

    ],
    exports: [
        TagsComponent,
        CommentsComponent,
        UmConfigPage,
        UmRoleObjectComponent,
        UserProfileComponent,
        UserProfileIdComponent,
    ],
    imports: [
        CoreModule,
        ImageCropperModule,
        UiModule,
    ]
})
export class UmModule implements DynamicModule {

    alias = 'um';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return UmKpiComponent;
    }

    getStatisticsComponent() {
        return UmStatisticsComponent;
    }

}

