@if (initialized && dto) {

    @if (activeNavigationItem.action === 'Logic') {

        <div class="grid mt-5">

            <div class="col-12 p-0 lg:col-3">
                <div class="surface-border border-round shadow-1 flex-auto h-full surface-section p-3">
                    <div class="flex flex-column gap-5 h-full">
                        <div>

                            <mvs-lg-variables
                                    *ngIf="dto?.id"
                                    [variableProviderService]="logicImportService"
                                    alias="lg.LogicImport"
                                    attributeName="variableName"
                                    title="Logic Imports"
                                    [contentProviderId]="dto.id"
                            ></mvs-lg-variables>

                        </div>
                        <div>

                            <mvs-lg-variables
                                    *ngIf="dto?.id"
                                    [variableProviderService]="logicExportService"
                                    alias="lg.LogicExport"
                                    attributeName="name"
                                    title="Logic Exports"
                                    [contentProviderId]="dto.id"
                            ></mvs-lg-variables>
                        </div>
                    </div>
                </div>
            </div>
            <div class="min-h-screen p-0 col-12 lg:col-9">
                <div class="flex flex-column gap-5 h-full">
                    <div class="px-3 h-full">
                        <div class="surface-border surface-section flex-auto px-5 h-full shadow-1 border-round">
                            <div style="margin-left: auto;">
                                <div class="flex align-items-center justify-content-between gap-3 py-3">
                                    <h5 class="text-color text-2xl m-0 px-2">Logic Defination</h5>
                                    <div class="flex gap-3">
                                        <p-button icon="pi pi-play" styleClass="p-button-outlined"
                                                  label="Run Syntax Check"
                                                  (click)="checkSyntax()"></p-button>
                                        <p-button icon="pi pi-save" label="Speichern"
                                                  (click)="updateScript()"></p-button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <app-code-editor
                                        [editorContent]="script"
                                        [logicLanguage]="dto.logicLanguage"
                                        (contentChanged)="onContentChange($event)"
                                        (onLogicLanguageChanged)="handleLogicLanguageChange($event)"
                                ></app-code-editor>
                            </div>

                            <ng-container *ngIf="syntaxResponse">
                                <div class="bg-black-alpha-90 p-2 border-round mt-4">
                                    <div class="pb-3 pt-2 px-2 text-white font-bold">
                                        Syntax Result
                                    </div>
                                    <div class="bg-white py-2">
                                        @for (error of syntaxResponse?.errors; track error) {

                                            <div class="text-red-500 p-2">
                                                <i class="fa-solid fa-circle-exclamation"></i> {{ error }}
                                            </div>
                                        }

                                        @for (warning of syntaxResponse?.warnings; track warning) {

                                            <div class="text-yellow-500 p-2">
                                                <i class="fa-solid fa-triangle-exclamation"></i> {{ warning }}
                                            </div>
                                        }
                                    </div>

                                </div>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
    @if (activeNavigationItem.action === 'TestCases') {
        <mvs-lg-test-case [logicId]="objectIdentifier.objectId"></mvs-lg-test-case>
    }
}