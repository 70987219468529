<ng-container *ngIf="initialized">

  <div
          class="avatar-circle {{size}}"
          [ngClass]="{'active-user':activeUser}"
          pTooltip="{{ user.name }}"
          tooltipPosition="top"
  >
    <ng-container *ngIf="imageSrc">
      <img [src]="imageSrc | mvsSecureBlob" alt="{{ userInfo.username }}">
    </ng-container>

  </div>
</ng-container>
