import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    ObjectPageComponent,
    MvsCoreService,
    ObjectIdentifier,
    ObjectRequestList,
    FilterCriteria
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {UiNavigationMainService} from "../../../service/api/ui-navigation-main.service";
import {UiNavigationMainDto} from "../../../dto/ui-navigation-main.dto";
import {Subscription} from "rxjs";
import {UiNavigationSubService} from "../../../service/api/ui-navigation-sub.service";


@Component({
    selector: 'ui-object-navigation-main-page',
    templateUrl: './ui-object-navigation-main.page.html',
    styleUrls: ['./ui-object-navigation-main.page.scss']
})
export class UiObjectNavigationMainPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

    aliasSubscription: Subscription | undefined;

    defaultLabel: string = "Navigation";

    isSubNavigation: boolean = false;

    moduleAlias: string;

    activeIndex: number = 0;


    constructor(
        route: ActivatedRoute,
        coreService: MvsCoreService,
        protected uiNavigationMainService: UiNavigationMainService,
        protected uiNavigationSubService: UiNavigationSubService,
        private router: Router) {
        super(route, coreService);
    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            const id = params['id'];
            const mainAlias = params['mainAlias'];
            const subAlias = params['subAlias'];
            if (id) {
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationMain', id);
                this.initWithId();
            } else if (mainAlias) {
                this.isSubNavigation = !!subAlias;
                this.initWithAlias(mainAlias);
            } else {
                console.error('could not found id')
            }
        });

    }

    private initWithId() {
        this.changeObject(this.objectIdentifier);
    }

    private initWithAlias(alias: string) {
        if (this.isSubNavigation) {
            this.getSubObjectViaAlias(alias)
        } else {
            this.getObjectViaAlias(alias);
        }
    }

    protected getObjectType(): string {
        return "ui.UiNavigationMain";
    }

    changeObject(objectIdentifier: ObjectIdentifier) {
        super.changeObject(objectIdentifier);
        this.getModule()
    }


    getObjectViaAlias(alias: string) {
        const filterCriteria = FilterCriteria.create('alias', FilterCriteria.cOperatorEqual, alias);
        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);

        this.uiNavigationMainService.list(objectRequest).subscribe(res => {
            if (res.entries.length > 0) {
                const entry = res.entries[0];
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationMain', entry.id);
                this.changeObject(this.objectIdentifier);
            } else {
                this.router.navigate(['/']);
                // console.error('No object found for alias:', alias);
            }
        });
    }

    getSubObjectViaAlias(alias: string) {
        const filterCriteria = FilterCriteria.create('alias', FilterCriteria.cOperatorEqual, alias);
        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], []);

        this.uiNavigationSubService.list(objectRequest).subscribe(res => {
            if (res.entries.length > 0) {
                const entry = res.entries[0];
                this.objectIdentifier = new ObjectIdentifier('ui.UiNavigationSub', entry.id);
                // this.changeObject(this.objectIdentifier);
            } else {
                this.router.navigate(['/']);
                // console.error('No object found for alias:', alias);
            }
        });
    }


    getModule() {
        this.uiNavigationMainService.get(this.objectIdentifier.objectId).subscribe((res: UiNavigationMainDto) => {
            this.moduleAlias = res.alias;
            this.initialized = true;
        });
    }

    ngOnDestroy() {
        this.aliasSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

    }


}
