<div class="flex" *ngIf="initialized">

    <div [formGroup]="formGroup" class="flex">

        <p-treeSelect #treeSelect
                      class="md:w-20rem w-full"
                      containerStyleClass="w-full"
                      [options]="treeData"
                      (onNodeExpand)="handleNodeExpand($event, treeSelect)"
                      (onNodeSelect)="handleSelection($event)"
                      placeholder="Select Item"
                      showClear="true"
                      [filterInputAutoFocus]="true">
            <ng-template let-node pTemplate="value">

                <ng-container *ngIf="uiAssigneePlaceholder">
                    {{uiAssigneePlaceholder}}
                </ng-container>

                <ng-container *ngIf="!uiAssigneePlaceholder">
                    Select Assignee
                </ng-container>
            </ng-template>

        </p-treeSelect>

        <div *ngIf="isSearch">
            <p-autoComplete
                            (completeMethod)="search($event)"
                            (onSelect)="onFreeAgentSelect($event)"
                            [suggestions]="otherAgentsList"
                            field="name"
            >

            </p-autoComplete>

        </div>

    </div>
</div>
