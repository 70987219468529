import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {Subscription} from "rxjs";

@Component({
  selector: 'mvs-te-config-page',
  templateUrl: './te-config-test.page.html',
  styleUrls: ['./te-config-test.page.scss']
})
export class TeConfigTestPage extends PageComponent implements OnInit, OnDestroy {

  templateGroupWidget: WidgetData;
  templateWidget: WidgetData;
  artefactGroupWidget: WidgetData;
  artefactWidget: WidgetData;
  testCaseWidget: WidgetData;
  systemVariableWidget: WidgetData;
  objectType: string;
  objectId: number;
  activeWidget: TabEntries;
  queryParamSubscription: Subscription;

  widgetEntries: TabEntries[] = [
    {label:'Template Groups', id: 'te.TeTemplateGroup', type: ''},
    {label:'Templates', id: 'te.TeTemplate', type: ''},
    {label:'Artefact Group', id: 'te.TeArtefactGroup', type: ''},
    {label:'Artefact', id: 'te.TeArtefact', type: ''},
    {label:'Test Case', id: 'te.TeTestCase', type: ''},
    {label:'System id', id: 'te.TeSystemVariablePredefined', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }


  ngOnInit(): void {

    super.ngOnInit();
    this.subscribeToParams();
    this.refreshWidgets();


  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshTemplateGroupWidget();
    this.refreshArtefactGroupWidget();
    this.refreshTemplateWidget();
    this.refreshArtefactWidget();
    this.refreshTestCaseWidget();
    this.refreshSystemVariableWidget();
  }

  refreshTemplateGroupWidget() {
    this.templateGroupWidget =  WidgetFactory.createWidgetList(
        "te.config.template.group.widget.simple",
        'Template Groups',
        'list',
        'list',
        'entity',
        'te.TeTemplateGroup',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshTemplateWidget() {
    this.templateWidget =  WidgetFactory.createWidgetList(
        "te.config.template.widget.simple",
        'Templates',
        'table',
        'list',
        'entity',
        'te.TeTemplate',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshArtefactGroupWidget() {
    this.artefactGroupWidget =  WidgetFactory.createWidgetList(
        "te.config.artefact.group.widget.simple",
        'Artefact Groups',
        'list',
        'list',
        'entity',
        'te.TeArtefactGroup',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshArtefactWidget() {
    this.artefactWidget =  WidgetFactory.createWidgetList(
        "te.config.artefact.widget.simple",
        'Artefact',
        'list',
        'list',
        'entity',
        'te.TeArtefact',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshTestCaseWidget() {
    this.testCaseWidget =  WidgetFactory.createWidgetList(
        "te.config.test.case.widget.simple",
        'Anlegen',
        'list',
        'list',
        'entity',
        'te.TeTestCase',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshSystemVariableWidget() {
    this.systemVariableWidget =  WidgetFactory.createWidgetList(
        "te.config.system.variable.widget.simple",
        'Vordefinierte Systemvariablen',
        'list',
        'list',
        'entity',
        'te.TeSystemVariablePredefined',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  handleTabSelection(item: TabEntries) {
    this.activeWidget = item;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }

}
