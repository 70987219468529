import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerAddressService} from "../../../../service/api/customer-address.service";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerInsurableObjectService} from "../../../../service/api/customer-insurable-object.service";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-sales-navigation',
    templateUrl: './cr-customer-sales-navigation.component.html',
})
export class CrCustomerSalesNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerContactsWidgetData: WidgetData;
    customerPersonObjectWidgetData: WidgetData;
    widgetCustomerAddresses: WidgetData;
    widgetCustomerContacts: WidgetData;
    customerContractWidgetData: WidgetData;
    customerInsurableObjectsWidgetData: WidgetData;
    widgetCustomerAgents: WidgetData;
    customerRelatedPersonWidget: WidgetData;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerService: CustomerService,
                protected customerAddressService: CustomerAddressService,
                protected customerContractService: CustomerContractService,
                protected configService: MvsConfigService,
                protected customerInsurableObjectsService: CustomerInsurableObjectService) {
        super(coreService, confirmationService, messageService, configService,navigationService);

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        this.customerContactsWidgetData = WidgetFactory.createWidgetListComplex(
            "cr.customer.sales.navigation.basic.contact",
            "Kundeninfo",
            "data",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kontakdaten vorhanden",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("id",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            this.customerService.getCustomerWithContactComplexSelection(),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("objectId",this.objectIdentifier.objectId),
            WidgetDataParam.create("columns",3),
        );

        this.customerPersonObjectWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.sales.navigation.complex.widget.get.person",
            "Person Details",
            "data",
            "list",
            "entity",
            "pm.Person",
            "No person found!",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(false, false,
                    ObjectRequestComplexNode.createSimpleAttributeNode("personDetail")),
                [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.dto.personDtoId)],
                null,
                null,
                null),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectId", this.dto.personDtoId),
        );
        this.customerPersonObjectWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();

        this.widgetCustomerAddresses = WidgetFactory.createWidgetListComplex(
            "cr.customer.sales.navigation.basic.address",
            "Anschrift",
            "list",
            "list",
            "entity",
            "cr.CustomerAddress",
            "No address found!",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("startDate", false)]
            ),
            this.customerAddressService.getAddressComplexSelection(),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data")
        );

        this.widgetCustomerAddresses.functionCallbacks = this.widgetProcessToolbarCreate();

        this.widgetCustomerContacts = WidgetFactory.createWidgetListComplex(
            "cr.customer.sales.navigation.basic.contacts",
            "Kontaktdaten",
            "list",
            "list",
            "entity",
            "cr.CustomerContact",
            "No contact found!",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode("contact"),
            ),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data")
        );

        this.widgetCustomerContacts.functionCallbacks = this.widgetProcessToolbarCreate();

        this.customerContractWidgetData = WidgetFactory.createWidgetListComplex(
            "cr.customer.sales.navigation.basic.contract",
            "Verträge",
            "table",
            "list",
            "entity",
            "cr.CustomerContract",
            "No contract found!",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting('lastModifiedDate',false)]
            ),
            this.customerContractService.getCustomerContractsComplexSelection(this.dto.personDtoId),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("size", "S")
        );

        this.widgetCustomerContacts.functionCallbacks = this.widgetProcessToolbarCreate();

        this.customerInsurableObjectsWidgetData = WidgetFactory.createWidgetListComplex(
            "cr.customer.sales.navigation.basic.insurable-objects",
            "Versicherte Objekte",
            "list",
            "list",
            "entity",
            "cr.CustomerInsurableObject",
            "Keine versicherten Objekte vorhanden",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            this.customerInsurableObjectsService.getCustomerInsurableObjectsComplexSelection(),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("size", "S")
        );

        this.widgetCustomerAgents = WidgetFactory.createWidgetList(
            "cr.customer.sales.navigation.agents.list",
            "Berater",
            "list",
            "list",
            "entity",
            "cr.CustomerAgent",
            "No agents found!",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("size", "S")
        );
        this.widgetCustomerAgents.functionCallbacks = this.widgetProcessToolbarCreate();


        this.customerRelatedPersonWidget = WidgetFactory.createWidgetList(
            "cm.customer.sales.navigation.related.person.object",
            "Related Persons",
            "list",
            "list",
            "entity",
            "cr.CustomerRelatedPerson",
            "No related persons found!",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("customer",FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("size", "S")
        );
        this.customerRelatedPersonWidget.functionCallbacks = this.widgetProcessToolbarCreate();

    }

    widgetProcessToolbarCreate() {
        return {onFunctionCreateNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.dto.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }};
    }

}
