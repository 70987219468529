import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {ContextMenu} from "primeng/contextmenu";


@Component({
  selector: 'mvs-inline-component',
  templateUrl: './mvs-inline-component.html',
})
export class MvsInlineComponent implements OnInit, OnChanges {


  @Input() objectType: string;
  @Input() name: string;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() status: string;
  @Input() styleClass: string;
  @Input() buttons: WidgetHelperButton[];
  iconClass: string;
  contextMenuItems: any;

  @ViewChild("cm1") contextMenu!: ContextMenu;
  @Output() onButtonClick = new EventEmitter<WidgetHelperButton>();


  initialized: boolean;
  busy: boolean;
  relatedObjectMenuItems: MenuItem[];


  ngOnInit() {
    this.refreshComponent();
    if (this.objectType === 'tm.TicketAppointment') {
      this.iconClass = 'bg-green-100 text-green-700 border-round-lg';
    } else if (this.objectType === 'cm.Contract') {
      this.iconClass = 'bg-blue-100 text-blue-700 border-round-lg';
    } else if (this.objectType === 'cr.Customer') {
      this.iconClass = 'bg-primary-100 text-primary-700 border-circle';
    } else {
      this.iconClass = 'bg-primary-100';
    }
    this.initializeContextMenu();
  }

  initializeContextMenu() {
    this.contextMenuItems = [];

    for (let item of this.buttons) {
      const contextMenuItem =  {
        label: item.label,
        icon: item.icon,
        display: item.display,
        action: item.action,
        tooltip: item.tooltip,
        command: () => this.onContextMenuAction(item)
      }

      this.contextMenuItems.push(contextMenuItem);

    }
  }

  onContextMenuAction(item: WidgetHelperButton) {
    this.onButtonClick.emit(item);
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    if (this.contextMenu) {
      this.contextMenu.toggle(event);
    }
  }


  refreshComponent() {
    this.initialized = true;
  }


  ngOnChanges(changes: SimpleChanges) {


  }


  handleButtonClick(button: WidgetHelperButton) {
    this.onButtonClick.emit(button);
  }


}
