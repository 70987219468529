import {Route, Routes} from "@angular/router";
import {ConfigPage} from "./page/overview/config/config.page";
import {AsOverviewPage} from "./page/as-overview-page/as-overview.page";

export function AsSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [
        // {path: 'as', component: AsOverviewPage},
        {path: 'as/config', component: ConfigPage},

    ];

    return routes;

}
