import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cr-customerObject-page',
  templateUrl: './customer-object-page.page.html',
  styleUrls: ['./customer-object-page.page.scss']
})
export class CustomerObjectPagePage extends PageComponent implements OnInit {

  objectBrowserRequest: any;

  defaultLabel: string = "";

  ngOnInit(): void {

    super.ngOnInit();

    this.objectBrowserRequest = {

      "extractDto": false,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cr.CustomerInsurance',
          'level'             : 1,
          'parentBinding'     : 'none',
          'noTree'            : true
        },
        {
          'id'                : 2,
          'objectAlias'       : 'cr.CustomerInsurableObject',
          'level'             : 1,
          'parentBinding'     : 'none',
          'noTree'            : true
        },
        {
          'id'                : 3,
          'objectAlias'       : 'cr.CustomerAddress',
          'level'             : 1,
          'parentBinding'     : 'none',
          'noTree'            : true
        },
        {
          'id'                : 4,
          'objectAlias'       : 'cr.CustomerContact',
          'level'             : 1,
          'parentBinding'     : 'none',
          'noTree'            : true
        },
        {
          'id'                : 5,
          'objectAlias'       : 'pm.Person',
          'level'             : 1,
          'parentBinding'     : 'none',
          'noTree'            : true
        }
      ]

    }
  }
}
