<ng-container *ngIf="initialized">
    <div class="mt-2 mb-3">
    <span style="font-size: 1.125rem;font-weight: 500">
    {{displayLabel}}
    </span>
    </div>


    <p-dropdown
            [options]="agentPoolDtoList.entries"
            [(ngModel)]="selectedAgentPool"
            (onChange)="handleAgentPool()"
            (onClear)="onClear.emit()"
            [showClear]="true"
            optionLabel="name"
            [placeholder]="placeHolder"
            styleClass="w-full"></p-dropdown>
</ng-container>