import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { ObjectIdentifierData} from "@kvers/alpha-core-common";
import {CustomerRelatedPersonDto} from "../../../cr/dto/customer-related-person.dto";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {ContractPersonDto} from "../../../ci/dto/contract-person.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {
    FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {ContractPersonService} from "../../../ci/service/api/contract-person.service";
import {PersonRelationPersonDto} from "../../../pm/dto/person-relation-person.dto";
import {HouseholdPersonService} from "../../../pm/service/api/household-person.service";
import {HouseholdService} from "../../../pm/service/api/household.service";
import {PmPersonDto} from "../../dto/pm-person.dto";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-create-emergency-contact',
    templateUrl: './cr-create-customer-emergency-contact.component.html',
})
export class CrCreateCustomerEmergencyContactComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    // @Input() customerId: number;
    @Input() customerPersonId: number;

    @Output() onObjectSelected: EventEmitter<ObjectIdentifier> = new EventEmitter<ObjectIdentifier>();

    stepItems = [];
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    activeStep: number;

    selectedCustomerRelatedPersonId: number;
    selectedPersonId: number;
    contextPersonId: number;
    personRelationCreated: boolean;

    personRelationPersonWidget: WidgetData;
    personRelationPersonForm: WidgetData;
    contractPersonForm: WidgetData;
    personFormWidget: WidgetData;

    flexiblePersonSearchDto: PmPersonDto;

    constructor(protected contractPersonService: ContractPersonService,
                protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected configService: MvsConfigService,
                protected householdPersonService: HouseholdPersonService,
                protected householdService: HouseholdService,
                protected override observerService: ObserverService
    ) {
        super(coreService, messageService, confirmationService, observerService);

        // this component is only used to create
        this.createOnly = true;
    }


    protected getRetrieveObjectObservable(): Observable<any> {
        return null;
    }

    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    resetComponent() {

        this.selectedCustomerRelatedPersonId = undefined;
        this.selectedPersonId = undefined;
        this.personRelationPersonWidget = undefined;
        this.personRelationPersonForm = undefined;
        this.contractPersonForm = undefined;
        this.personFormWidget = undefined;
        this.flexiblePersonSearchDto = undefined;
        this.contextPersonId = undefined;
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.resetComponent();

        this.stepItems = [
            {
                label: 'Überprüfen, ob vorhanden', id: 'check'   // Check if exist
            },
            {
                label: 'Person suchen', id: 'search'  // Select Person
            },
            {
                label: 'Person erstellen', id: 'create'  // Create New Person
            },
            {
                label: 'Kunden zuweisen', id: 'assignCustomer'  // Create Customer Relation
            }
        ];
        this.gotoStep(0);

        this.initialized = true;

    }


    /**
     * Initialize Step 1)
     * Check whether Customer Related Person already exists.
     */
    initStep0() {

        this.personRelationPersonWidget = WidgetFactory.createWidgetList(
            'cr.create.customer.emergency.contact.show.basic.widget',
            'Personen im Zusammenhang mit Kunden',
            'list',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'Keine Personen vorhanden',
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("personA", FilterCriteria.cOperatorEqual, this.customerPersonId)],
                [new Sorting("createdDate", true)]
            )
        );

    }

    /**
     * A related person was selected, therefore we need to decide how to continue.
     */
    handleStep0RelatedPersonSelect(objectIdentifier: ObjectIdentifierData) {

        const customerRelatedPerson = <CustomerRelatedPersonDto>objectIdentifier.data;
        this.selectedPersonId = customerRelatedPerson.personDtoId;
        this.gotoStep(2);

    }

    gotoStep(step: number) {

        switch (step) {
            case 0:             // search customer persons
                this.initStep0();

                break;
            case 1:             // search persons
                this.initStep1();

                break;
            case 2:             // create person
                this.initStep2();
                break;

            case 3:             // create customer - person assignment
                this.initStep3();
                break;

            case 4:             // create customer - person assignment
                this.initStep4();
                break;

        }
        this.activeStep = step;
        this.activeStep = step;

    }

    /**
     * Initialize Step 2)
     * Within this step the user needs to search for a person.
     */
    initStep1() {
        // no initialization required
    }


    /**
     * User has selected a person.
     * @param event
     */
    handlePersonSelectStep1(event: FlexibleSearchDataEvent) {
        this.selectedPersonId = event.id;
    }

    /**
     * Continue with step 3.
     */
    handleContinueWithSelectedPersonStep1() {
        if (this.selectedPersonId) {
            this.gotoStep(3);
        }
    }

    /**
     * Store search result.
     * @param event
     */
    handlePersonSearchStep1(event: DtoDetail) {
        this.flexiblePersonSearchDto = <PmPersonDto>event;
    }

    /**
     * User requests to create a new person.
     */
    handleCreateNewPersonStep1() {

        // only allow to move to the next step, if user actually searched
        if (!this.flexiblePersonSearchDto) {
            this.confirmationService.confirm({
                header: 'Alert',
                message: 'Bitte suchen Sie nach der Person, bevor Sie eine neue Person erstellen!',
                acceptLabel: 'Okay',
                rejectVisible: false, // Hide the cancel button
                accept: () => {
                }
            });

            return;
        }

        this.gotoStep(2);

    }


    /**
     * Step 3) Create a new person.
     */
    initStep2() {

        this.personFormWidget = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.customer.related.person.object.create.person.form.widget",
            "Person erstellen",
            "pm.Person",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    return {dto: this.flexiblePersonSearchDto};
                }
            }
        );

    }

    /**
     * Person was successfully created so we can move to the next step.
     * @param objectChangeInformation
     */
    handlePersonCreateStep2(objectChangeInformation: ObjectChangeInformation) {
        this.selectedPersonId = objectChangeInformation.after.id;
        this.gotoStep(3);
    }

    initStep3() {

        this.personRelationPersonForm = new WidgetData();
        this.personRelationPersonForm.idAlias = "cr.create.customer.emergency.contact.basic.form";
        this.personRelationPersonForm.name = "Zuweisung Kunde";
        this.personRelationPersonForm.uiComponent = "object";
        this.personRelationPersonForm.dataProvider = "list";
        this.personRelationPersonForm.dataSource = "entity";
        this.personRelationPersonForm.dataProviderObject = "cr.CustomerEmergencyContact";

        this.personRelationPersonForm.functionCallbacks = {
            onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = this.customerPersonId;
                dto.personBDtoId = this.selectedPersonId;

                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);
                formControlOverwrite.addField('personBDtoId', MvsFormFieldAccessEnum.READ);

                let widgetToolbarCallbackInterface: WidgetToolbarCallbackInterface;
                widgetToolbarCallbackInterface = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return widgetToolbarCallbackInterface;
            }
        };

    }

    handleCreateRelatedPersonStep3(objectChangeInformation: ObjectChangeInformation) {
        this.personRelationCreated = true;
    }

    personCreationFinished(objectIdentifier: ObjectIdentifier) {
        this.onObjectSelected.emit(objectIdentifier);
        this.refreshComponent();
    }


    initStep4() {

        this.contractPersonForm = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.customer.related.person.contract.form.widget",
            "Zuweisung Vertrag",
            "ci.ContractPerson",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new ContractPersonDto();
                    dto.personDtoId = this.selectedPersonId;
                    dto.startDate = new Date();

                    const formControlOverwrite = new MvsFormControlOverwrite();
                    formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.HIDDEN);
                    formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);

                    return {
                        dto: dto,
                        formControlOverwrite: formControlOverwrite
                    };

                }
            }
        );

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"] || changes["contractId"] || changes["customerId"]) {
            this.initComponent();
        }

        if (changes["importObjectContext"]) {
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
