import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";
import {ImImportService} from "../../../../service/api/im-import.service";
import {ImImportRecordService} from "../../../../service/api/im-import-record.service";


@Component({
    selector: 'mvs-example',
    templateUrl: 'im-kpi.component.html',
})
export class ImKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalImports: number = 0;
    totalImportRecords: number = 0;
    thisMonthImports: number = 0;
    thisWeekImports: number = 0;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'IM Module'}];

    constructor(
        protected importService: ImImportService,
        protected importRecordService: ImImportRecordService,
    ) {
        super()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalImports();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    getTotalImports() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['severity'],
            [new ObjectRequestListAttribute('severity', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.importService.groupBy(objectRequestList).subscribe(res => {
            this.totalImports = res.entries.reduce((acc, item) => acc + item['severity_count'], 0);
            this.getTotalImportRecords();
        }, error => {
            this.getTotalImportRecords();
        });
    }

    getTotalImportRecords() {
        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['status'],
            [new ObjectRequestListAttribute('status', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.importRecordService.groupBy(objectRequestList).subscribe(res => {
            this.totalImportRecords = res.entries.reduce((acc, item) => acc + item['status_count'], 0);
            this.getThisMonthImports();
        }, error => {
            this.getThisMonthImports();
        });
    }

    getThisMonthImports() {
        const objectRequestList = this.getThisMonthRequest('severity');
        this.importService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthImports = res.entries.reduce((acc, item) => acc + item['severity_count'], 0);
            this.getThisWeekImports();
        }, error => {
            this.getThisWeekImports();
        });
    }

    getThisWeekImports() {
        const objectRequestList = this.getThisWeekRequest('severity');
        this.importService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekImports = res.entries.reduce((acc, item) => acc + item['severity_count'], 0);
            this.initKpiHeaderDto();
        }, error => {
            this.initKpiHeaderDto();
        });
    }


    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Imports', value: this.totalImports,
            },
            {
                label: 'Total Import Records', value: this.totalImportRecords,
            },
            {
                label: 'This Month Imports', value: this.thisMonthImports,
            },
            {
                label: 'This Week Imports', value: this.thisWeekImports,
            },
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
