<ng-container *ngIf="initialized">
    <mvs-cm-customer-assessment-component [showTitle]="false" overallTitle="Overall Assessment"
                                          stackTitle="Customer Insurance Assessment" [showStack]="true"
                                          [showOverall]="true"
                                          [customerId]="84538"></mvs-cm-customer-assessment-component>
    <div class="grid">

        <div class="col-12">
            <mvs-xx-dialog-box-object-document-component></mvs-xx-dialog-box-object-document-component>
        </div>

        <!--        <div class="col-12">-->
        <!--            <mvs-xx-object-document-component-->
        <!--                    [objectTypeAlias]="'cr.Customer'"-->
        <!--                    [objectId]="113676"-->
        <!--            ></mvs-xx-object-document-component>-->
        <!--        </div>-->

        <!-- objectType: string, public objectId -->
        <div class="col-12">

        </div>

        <div class="col-12">
            <mvs-xx-test-component (onObjectSelected)="showObject($event)"></mvs-xx-test-component>
        </div>

        <!--    <div class="col-12">-->
        <!--        <mvs-widget [widgetData]="simpleEntityTableWidgetData"></mvs-widget>-->
        <!--    </div>-->


        <div class="col-3">
            <p-panel header="Output Field: bool">

                <p-card header="NULL">
                    <mvs-form-field-output [value]="null" fieldName="bool"></mvs-form-field-output>
                </p-card>

                <p-card header="TRUE">
                    <mvs-form-field-output [value]="true" fieldName="bool"></mvs-form-field-output>
                </p-card>

                <p-card header="FALSE">
                    <mvs-form-field-output [value]="false" fieldName="bool"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: boolActive">

                <p-card header="NULL">
                    <mvs-form-field-output [value]="null" fieldName="boolActive"></mvs-form-field-output>
                </p-card>

                <p-card header="TRUE">
                    <mvs-form-field-output [value]="true" fieldName="boolActive"></mvs-form-field-output>
                </p-card>

                <p-card header="FALSE">
                    <mvs-form-field-output [value]="false" fieldName="boolActive"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: range">

                <p-card header="50">
                    <mvs-form-field-output [value]="50" fieldName="range"></mvs-form-field-output>
                </p-card>


                <p-card header="80">
                    <mvs-form-field-output [value]="80" fieldName="range"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: rangeColor">

                <p-card header="20">
                    <mvs-form-field-output [value]="20" [formField]="formFieldRange"
                                           fieldName="rangeColor"></mvs-form-field-output>
                </p-card>

                <p-card header="40">
                    <mvs-form-field-output [value]="40" [formField]="formFieldRange"
                                           fieldName="rangeColor"></mvs-form-field-output>
                </p-card>

                <p-card header="60">
                    <mvs-form-field-output [value]="60" [formField]="formFieldRange"
                                           fieldName="rangeColor"></mvs-form-field-output>
                </p-card>

                <p-card header="80">
                    <mvs-form-field-output [value]="80" [formField]="formFieldRange"
                                           fieldName="rangeColor"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: rangeProgress">

                <p-card header="20">
                    <mvs-form-field-output [value]="20" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress"></mvs-form-field-output>
                </p-card>

                <p-card header="70">
                    <mvs-form-field-output [value]="70" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress"></mvs-form-field-output>
                </p-card>

                <p-card header="100">
                    <mvs-form-field-output [value]="100" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress"></mvs-form-field-output>
                </p-card>

                <p-card header="160">
                    <mvs-form-field-output [value]="160" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: rangeProgress100">

                <p-card header="80">
                    <mvs-form-field-output [value]="80" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress100"></mvs-form-field-output>
                </p-card>

                <p-card header="100">
                    <mvs-form-field-output [value]="100" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress100"></mvs-form-field-output>
                </p-card>

                <p-card header="125">
                    <mvs-form-field-output [value]="125" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress100"></mvs-form-field-output>
                </p-card>

                <p-card header="150">
                    <mvs-form-field-output [value]="150" [formField]="formFieldIntegerRange"
                                           fieldName="rangeProgress100"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: chip">

                <p-card header="Single CHIP">
                    <mvs-form-field-output [value]="singleChipValue" fieldName="chip"></mvs-form-field-output>
                </p-card>

                <p-card header="Double CHIP">
                    <mvs-form-field-output [value]="doubleChipValue" fieldName="chip"></mvs-form-field-output>
                </p-card>

                <p-card header="Multiple CHIP">
                    <mvs-form-field-output [value]="multipleChipValue" fieldName="chip"></mvs-form-field-output>
                </p-card>


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: tag">

                <p-card header="Single tag">
                    <mvs-form-field-output [value]="singleChipValue" fieldName="tag"></mvs-form-field-output>
                </p-card>

                <p-card header="Double tag">
                    <mvs-form-field-output [value]="doubleChipValue" fieldName="tag"></mvs-form-field-output>
                </p-card>

                <p-card header="Multiple tag">
                    <mvs-form-field-output [value]="multipleChipValue" fieldName="tag"></mvs-form-field-output>
                </p-card>


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: amount">

                <p-card header="Number">
                    <mvs-form-field-output [value]="5" fieldName="amount"></mvs-form-field-output>
                </p-card>

                <p-card header="Number">
                    <mvs-form-field-output [value]="500" fieldName="amount"></mvs-form-field-output>
                </p-card>

                <p-card header="Negative Number">
                    <mvs-form-field-output [value]="-50000" fieldName="amount"></mvs-form-field-output>
                </p-card>


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: amount">

                <p-card header="Number">
                    <mvs-form-field-output [value]="5" fieldName="colorAmount"></mvs-form-field-output>
                </p-card>

                <p-card header="Number">
                    <mvs-form-field-output [value]="5000" fieldName="colorAmount"></mvs-form-field-output>
                </p-card>

                <p-card header="Negative Number">
                    <mvs-form-field-output [value]="-50000" fieldName="colorAmount"></mvs-form-field-output>
                </p-card>


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: amount">

                <p-card header="Current Date/Time">
                    <mvs-form-field-output [value]="date1" fieldName="dateAgo"></mvs-form-field-output>
                </p-card>

                <!--            <p-card header="Yasterday's Date">-->
                <!--                <mvs-form-field-output [value]="date2" fieldName="dateAgo"></mvs-form-field-output>-->
                <!--            </p-card>-->

                <!--            <p-card header="1 week ago">-->
                <!--            <mvs-form-field-output [value]="date3" fieldName="dateAgo"></mvs-form-field-output>-->
                <!--            </p-card>-->


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: User Initials">

                <p-card header="User Initials">
                    <mvs-form-field-output [value]="username1" fieldName="userInitials"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>


        <div class="col-3" style="position:relative">
            <p-panel header="Output Field: chip-hover">

                <p-chip (mouseover)="showDetails=true" (mouseout)="showDetails=false">
                    {{formFieldEnum.valueList.entries.length}} items
                    <div class="chip-details" *ngIf="showDetails">
                        <ul>
                            <li *ngFor="let entry of formFieldEnum.valueList.entries">
                                Key: {{entry.key}}, Label: {{entry.label}}, Technical Key: {{entry.technicalKey}}, Icon:
                                <i [class]="entry.image"></i> , Priority: {{entry.priority}}
                            </li>
                        </ul>
                    </div>
                </p-chip>


            </p-panel>
        </div>


        <div class="col-3">
            <p-panel header="Output Field: Anonymize Phone">

                <p-card header="Number Format 1">
                    <mvs-form-field-output [value]="phone1" fieldName="anonymizePhone"></mvs-form-field-output>
                </p-card>

                <p-card header="Number Format 2">
                    <mvs-form-field-output [value]="phone2" fieldName="anonymizePhone"></mvs-form-field-output>
                </p-card>


            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: Anonymize Email">

                <p-card header="Email Format 1">
                    <mvs-form-field-output [value]="email1" fieldName="anonymizeEmail"></mvs-form-field-output>
                </p-card>

                <p-card header="Email Format 2">
                    <mvs-form-field-output [value]="email2" fieldName="anonymizeEmail"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>


        <div class="col-3">
            <p-panel header="Output Field: Hyperlinks">

                <p-card header="Hyperlink 1">
                    <mvs-form-field-output [value]="link1" fieldName="hyperlink"></mvs-form-field-output>
                </p-card>

                <p-card header="Hyperlink 2">
                    <mvs-form-field-output [value]="link2" fieldName="hyperlink"></mvs-form-field-output>
                </p-card>

            </p-panel>
        </div>

        <div class="col-3">
            <p-panel header="Output Field: Text Event">

                <p-card header="Text Event 1">
                    <mvs-form-field-output
                            [value]="value1"
                            fieldName="textEvent"
                            (textClick)="onTextClick()">
                    </mvs-form-field-output>
                </p-card>

                <p-card header="Text Event 2">
                    <mvs-form-field-output
                            [value]="value2"
                            fieldName="textEvent"
                            (textClick)="onTextClick()">
                    </mvs-form-field-output>
                </p-card>


                <!--                <p-card header="Text Event 2">-->
                <!--                    <mvs-form-field-output [value]="link2"  fieldName="hyperlink"></mvs-form-field-output>-->
                <!--                </p-card>-->

            </p-panel>
        </div>

    </div>

    <div class="col-3">
        <p-panel header="Output Field: Size">

            <p-card header="Size Kb">
                <mvs-form-field-output [value]="2000" fieldName="sizeKb"></mvs-form-field-output>
            </p-card>

            <p-card header="Size Mb">
                <mvs-form-field-output [value]="2000" fieldName="sizeMb"></mvs-form-field-output>
            </p-card>

            <p-card header="Size Gb">
                <mvs-form-field-output [value]="2000" fieldName="sizeGb"></mvs-form-field-output>
            </p-card>


        </p-panel>


        <div class="card">
            <div class="flex flex-wrap align-items-center justify-content-center card-container blue-container">
                <div class="transition-colors transition-duration-500 bg-blue-500 hover:bg-yellow-500 text-white hover:text-gray-900
    flex align-items-center justify-content-center font-bold border-round cursor-pointer m-2 px-5 py-3">Hover me
                </div>
            </div>
        </div>

        <mvs-form-control-output-icon value="fa-solid fa-house"></mvs-form-control-output-icon>

        <mvs-form-control-output-image
                value="https://mimespublic.blob.core.windows.net/webapp/icon-leben.png"></mvs-form-control-output-image>

        <mvs-form-control-output-icon-image value="fa-solid fa-house"></mvs-form-control-output-icon-image>

        <mvs-form-control-output-icon-image
                value="https://mimespublic.blob.core.windows.net/webapp/icon-leben.png"></mvs-form-control-output-icon-image>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>

    <h1>Time Date</h1>
    <mvs-form-control-output-time-date value="2023-08-28T22:16:01.662082"></mvs-form-control-output-time-date>
    <br>
    <br>
    <br>
    <br>

    <h1>Date Time</h1>
    <mvs-form-control-output-date-time value="2023-08-28T22:16:01.662082"></mvs-form-control-output-date-time>

    <br>
    <br>
    <br>
    <br>

    <h1>HhMm</h1>
    <mvs-form-control-output-seconds-in-HhMm [value]="8000"></mvs-form-control-output-seconds-in-HhMm>


    <br>
    <br>
    <br>
    <br>

    <h1>HhMmSs</h1>
    <mvs-form-control-output-seconds-in-HhMmSs [value]="8000"></mvs-form-control-output-seconds-in-HhMmSs>

    <br>
    <br>
    <br>
    <br>

    <h1>bool negative true</h1>
    <mvs-form-control-output-bool-negative [value]="true"></mvs-form-control-output-bool-negative>
    <br>
    <br>
    <br>
    <br>


    <h1>bool negative false</h1>
    <mvs-form-control-output-bool-negative [value]="false"></mvs-form-control-output-bool-negative>
    <br>
    <br>
    <br>
    <br>

    <h1>Due Date</h1>
    <div *ngFor="let date of dates">
        <mvs-form-control-output-due-date [value]="date"></mvs-form-control-output-due-date>
    </div>

</ng-container>
