<ng-container *ngIf="initialized && objectLoaded">
    <div class="grid mx-0">
        <div class="col-12 lg:col-6">
            <div class="grid h-full surface-0 border-round shadow-1 m-1 card">
                <div class="flex flex-column gap-1 text-900 mb-3 px-1">
                    <p-breadcrumb
                            class="max-w-full mb-2"
                            [model]="[{label:dto.name}]"
                            [home]="{routerLink: routerLink }"/>
                    <span>
                        <span class="text-xl font-medium">{{ dto.name }}</span>&nbsp;
                        <span class="font-bold text-primary">{{ dto.alias }}</span>
                    </span>
                    <p class="text-s text-600" *ngIf="dto.description" [innerHTML]="dto.description"></p>

                </div>

                <div *ngFor="let pages of dto.pages" class="col-12 px-0">
                    <mvs-ui-object-navigation-card [navigationItem]="pages"></mvs-ui-object-navigation-card>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-3">
            <div class="grid h-full m-1">
                <div class="col-12 p-0">
                    <div class="border-round shadow-1 surface-card p-3 h-full">

                        <div class="text-xl font-medium mb-1">Last Visits</div>
                        <ui-navigation-last-visits
                                [uiNavigationMainDto]="{subNavigations:[dto]}"></ui-navigation-last-visits>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-3">
            <div class="grid h-full m-1">
                <div class="col-12 p-0">
                    <div class="border-round shadow-1 surface-card p-3 h-full">
                        <div class="text-xl font-medium mb-1">Favoriten</div>
                        <ui-navigation-favorites
                                [uiNavigationMainDto]="{subNavigations:[dto]}"></ui-navigation-favorites>

                    </div>
                </div>
            </div>
        </div>

    </div>


</ng-container>
