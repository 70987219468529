<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="cr.CustomerSignatureType"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->




<p-tabView [(activeIndex)]="activeIndex" (onChange)="clearWidgets()">
    <p-tabPanel header="Signature Types">
        <ng-container *ngIf="activeIndex == 0">
            <div class="grid">
                <div class="col-2">
                    <mvs-widget
                            *ngIf="customerSignatureTypeWidget"
                            [widgetData]="customerSignatureTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)"
                    ></mvs-widget>
                </div>
                <div class="col-10">
                    <mvs-widget
                            *ngIf="objectDataWidget"
                            [widgetData]="objectDataWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>
    </p-tabPanel>

</p-tabView>