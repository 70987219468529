import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    MvsCoreService, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    PageComponent, Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {OverviewStatisticsBaseComponent} from "../../../../../core/overview/overview-statistics-base.component";
import {OverviewDto} from "../../../../../core/overview/dto/overview.dto";


@Component({
    selector: 'mvs-example',
    templateUrl: 'ui-statistics.component.html',
})
export class UiStatisticsComponent extends OverviewStatisticsBaseComponent implements OnInit, OnChanges, OnDestroy {

    pending: number = 0;
    processed: number = 0;
    inProcess: number = 0;
    unfinished: number = 0;


    fieldHistoryWidget: WidgetData;
    fieldByTypeWidget: WidgetData;
    pageUserFavoriteWidget: WidgetData;
    recentlyAddedFieldsWidget: WidgetData;

    items: MenuItem[] | undefined;

    home: MenuItem | undefined;

    overviewDto: OverviewDto[];

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,) {
        super(route, coreService)
    }

    ngOnInit(): void {


        this.items = [
            {label: 'UI Module'},
        ];

        this.overviewDto = [
            {label: 'Pending', value: 0 },
            {label: 'Processed', value: 0 },
            {label: 'In-Process', value: 0 },
            {label: 'Unfinished', value: 0 }
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshFieldHistoryWidget();
        this.refreshFieldByTypeWidget();
        this.refreshPageUserFavoriteWidget();
        this.refreshAddedFields();
        this.initialized = true;
    }

    navigateToConfig() {
        this.router.navigate(['/ui/config']);
    }



    refreshFieldHistoryWidget() {
        this.fieldHistoryWidget = new WidgetData();
        this.fieldHistoryWidget.idAlias = "ui.field.overview.history.widget";
        this.fieldHistoryWidget.name = "UI Field History";

        this.fieldHistoryWidget.uiComponent = "history.chart";
        this.fieldHistoryWidget.dataProvider = "list";
        this.fieldHistoryWidget.dataSource = "entity.groupBy";
        this.fieldHistoryWidget.dataProviderObject = "ui.UiField";
        this.fieldHistoryWidget.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            this.filterCriteria,
            [],
            ["defaultAttributeSetting", "createdDate"],
            [new ObjectRequestListAttribute("defaultAttributeSetting", "Type", DtoListAttributeRequestAggregateEnum.count)]);

        this.fieldHistoryWidget.setParamValue("fieldCategory", "defaultAttributeSetting");
        this.fieldHistoryWidget.setParamValue("fieldCategoryCount", "defaultAttributeSetting_count");
        this.fieldHistoryWidget.setParamValue("historyPeriodMode", "month");
        this.fieldHistoryWidget.setParamValue("fieldDate", "createdDate");
        this.fieldHistoryWidget.setParamValue("selectionMode", "single");
        this.fieldHistoryWidget.setParamValue("size", "S");
    }

    refreshFieldByTypeWidget() {
        this.fieldByTypeWidget = WidgetFactory.createWidgetGroupBy(
            'ui.overview.field.by.type.widget',
            'Field By Type',
            'category',
            'entity.groupBy',
            'ui.UiField',
            'uiFieldType',
            'uiFieldType_count',
            this.filterCriteria,
            'uiFieldType',
            'Anzahl');
    }

    refreshPageUserFavoriteWidget() {
        this.pageUserFavoriteWidget = WidgetFactory.createWidgetGroupBy(
            'ui.overview.page.user.favorite.widget',
            'Page User Favorite',
            'category',
            'entity.groupBy',
            'ui.UiPageUser',
            'visitedPage',
            'visitedPage_count',
            this.filterCriteria,
            'visitedPage',
            'Anzahl');
    }

    refreshAddedFields() {
        // FilterCriteria.create('createdDate', FilterCriteria.cOperatorGreaterEqual, DatesHelper.calculateFirstDayOfCurrentMonth())
        const objectRequestList = ObjectRequestList.createBasic(
            true,
            this.filterCriteria,
            [new Sorting('createdDate', false)],
        );
        this.recentlyAddedFieldsWidget = WidgetFactory.createWidgetTableEntity(
            'ui.overview.recently.added.fields',
            'Recent Fields',
            'te.UiField',
            'No Fields',
            objectRequestList
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
