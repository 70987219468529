@if (initialized && dto) {

    @if (uiMode == 'side') {
        <cp-provision-agent-side
                [objectIdentifier]="objectIdentifier"
                [(activeNavigationItem)]="activeNavigationItem"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cp-provision-agent-side>
    } @else {
        <cp-provision-agent-full
                [objectIdentifier]="objectIdentifier"
                [(activeNavigationItem)]="activeNavigationItem"
                (onNavigationItems)="handleNavigationItems($event)"
        ></cp-provision-agent-full>
    }

}