import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {BasicConfigPage} from "./page/overview/basic-config/basic-config.page";
import {
  CiSearchFlexibleInsuranceComponent
} from "./search/ci-search-flexible-insurance/ci-search-flexible-insurance.component";
import {
  CiContractInsurableObjectComponent
} from "./components/ci-contract-insurable-object-component/ci-contract-insurable-object.component";
import {CiContractPersonComponent} from "./components/ci-contract-person-component/ci-contract-person.component";

@NgModule({
  declarations: [
    BasicConfigPage,
    CiSearchFlexibleInsuranceComponent,
    CiContractInsurableObjectComponent,
    CiContractPersonComponent,
  ],
  exports:[
    BasicConfigPage,
    CiSearchFlexibleInsuranceComponent,
    CiContractInsurableObjectComponent,
    CiContractPersonComponent,
  ],
  imports: [
    CoreModule
  ]
})
export class CiModule { }

