import {Component, OnInit} from "@angular/core";
import {
    FilterCriteria,
    ObjectBaseModeComponent,
    ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'am-agent-info-detail-object-base',
    template: ''
})

export class AgentInfoDetailObjectBaseComponent extends ObjectBaseModeComponent implements OnInit{


    agentWidget: WidgetData;
    agentPersonWidget: WidgetData;
    agentUserWidget: WidgetData;
    agentContactWidget: WidgetData;
    agentPoolAgentWidget: WidgetData;

    tabIndex: number = 0;


    ngOnInit() {
        super.ngOnInit();

        this.refreshWidgets();

    }

    /**
     * refresh all widgets
     */
    refreshWidgets() {
        this.refreshAgentWidget();
        this.refreshAgentContactWidget();
        this.refreshAgentPoolAgentWidget();
        this.refreshAgentUserWidget();
        if (this.dto && this.dto['employeeDtoId']) {
            this.refreshAgentPersonWidget();
        }
    }


    /**
     * refresh agent widget
     */
    refreshAgentWidget() {
        this.agentWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.info.detail',
            'Agent',
            'am.Agent',
            this.objectIdentifier.objectId,
        );
    }

    /**
     * refresh agent contact widget
     */
    refreshAgentContactWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.agentContactWidget = WidgetFactory.createWidgetList(
            'am.agent.contact.info.detail',
            'Agent Contact',
            'list',
            'list',
            'entity',
            'am.AgentContact',
            'Keine Daten vorhanden',
            dtoRequest
        );
    }

    /**
     * refresh agent pool agent widget
     */
    refreshAgentPoolAgentWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('agent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.agentPoolAgentWidget = WidgetFactory.createWidgetList(
            'am.agent.pool.agent.info.detail',
            'Agent Pools',
            'list',
            'list',
            'entity',
            'am.AgentPoolAgent',
            'Keine Daten vorhanden',
            dtoRequest
        );
    }

    /**
     * refresh agent user widget
     */
    refreshAgentUserWidget() {

        this.agentUserWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.user.info.detail',
            'Agent User',
            'am.Agent',
            this.objectIdentifier.objectId,
        );

        this.agentUserWidget.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("user")),
            null,
            null,
            null,
            null);
    }

    /**
     * refresh agent person widget
     */
    refreshAgentPersonWidget() {

        this.agentPersonWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.person.info.detail',
            'Agent Person',
            'am.Agent',
            this.objectIdentifier.objectId,
        );

        this.agentPersonWidget.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("employee").addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("person"))),
            null,
            null,
            null,
            null);
    }
}