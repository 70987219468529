import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {WfProcessTypeStepDto} from "../../../dto/wf-process-type-step.dto";
import {WfProcessTypeStepActivityDto} from "../../../dto/wf-process-type-step-activity.dto";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {WfProcessDto} from "../../../dto/wf-process.dto";
import {WfProcessTypeDto} from "../../../dto/wf-process-type.dto";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WfProcessTypeStepNextDto} from "../../../dto/wf-process-type-step-next.dto";
import {WfProcessTypeStepActivityService} from "../../../service/api/wf-process-type-step-activity.service";
import {WfProcessRuntimeDto} from "../../../service/dto/wf-process-runtime.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WfProcessTypeStepActivityFormDefaultDto} from "../../../dto/wf-process-type-step-activity-form-default.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessMetaDto} from "../../../service/dto/wf-process-meta.dto";
import {WfProcessRuntimeBindingDto} from "../../../service/dto/wf-process-runtime-binding.dto";
import {WfActivityFormDefaultDto} from "../../../service/dto/wf-activity-form-default.dto";
import {WfProcessStepActivityDto} from "../../../dto/wf-process-step-activity.dto";

@Component({
    selector: 'mvs-process-step-activity-base',
    template: 'about:blank'
})
export class WfProcessStepActivityBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() processRuntime: WfProcessRuntimeDto;
    @Input() processMeta: WfProcessMetaDto;

    @Input() activity: WfProcessStepActivityDto;

    @Input() stepType: WfProcessTypeStepDto;
    @Input() activityType: WfProcessTypeStepActivityDto;

    @Input() bindings: WfProcessRuntimeBindingDto[];        // remove
    @Input() formDefaults: WfActivityFormDefaultDto[];


    @Output() onAction: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();
    @Output() onSkip: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();
    @Output() onOptionalActivityChangeEvent: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();
    @Output() onNextStepSelect: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();
    @Output() onProcessCreated: EventEmitter<WfProcessStepActivityEvent> = new EventEmitter<WfProcessStepActivityEvent>();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["processRuntime"] || changes["processMeta"] || changes["stepType"] || changes["activityType"] || changes["bindings"] || changes["formDefaults"]) {
            // make sure that this is only performed when needed
            this.refreshComponent();
        }

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
