import { BehaviorSubject, timer } from "rxjs";
import { CookieAutosave } from "./cookie-autosave";
import { Subject, Subscription } from 'rxjs';
import { auditTime, debounce, debounceTime, delay, switchAll, take, throttleTime } from 'rxjs/operators';
import { TeAutosaveCookieDto } from "../dto/te-autosave-cookie.dto";

export class TinymceAutosave extends CookieAutosave {

    tinyMceConfig: any;

    alias: string;
    private saveTimeoutId: ReturnType<typeof setTimeout>;
    private continuousTimeoutId: ReturnType<typeof setTimeout>;
    private isTypingContinuously: boolean = false;

    private autosaveSubject = new Subject<string>();
    private autosaveSubscriptionDebounceTime: Subscription;
    private autosaveSubscriptionAuditTime: Subscription;

    private $autoSaveContent = new BehaviorSubject<string>('');
    editorContentFromCookie: TeAutosaveCookieDto;
    // private autosave$ = this.autosaveSubject.asObservable();

    constructor(object?: any) {
        super();

        this.autosaveSubscriptionDebounceTime = this.getAutoSaveEditorContent().pipe(
            debounceTime(10000),
        ).subscribe((content) => {
            this.editorContentFromCookie = this.getAutoSaveCookie(this.getAlias());
            this.saveContent(content);
        });

        this.autosaveSubscriptionAuditTime = this.getAutoSaveEditorContent().pipe(
            auditTime(20000),
        ).subscribe((content) => {
            if (content !== this.editorContentFromCookie?.content) {
                this.saveContent(content);
            }
        });

    }

    setAlias(alias: string): void {
        this.alias = alias;
    }

    getAlias(): string {
        return this.alias;
    }

    getAutoSaveContent() {
        return this.$autoSaveContent.asObservable();
    }

    getAutoSaveCookie(cookieName: string): TeAutosaveCookieDto {
        return this.getCookie(cookieName);
    }

    deleteAutoSaveCookie(cookieName: string): void {
        this.deleteCookie(cookieName);
    }

    autoSaveEditorContent(editorContent: string): void {

        // if user just started typing
        // if (!this.isTypingContinuously) {
        //     this.isTypingContinuously = true;

        //     this.continuousTimeoutId = setTimeout(() => {
        //         this.isTypingContinuously = false;
        //         clearTimeout(this.saveTimeoutId);
        //         this.saveContent(editorContent); 
        //     }, 10000); // save content after 10 seconds of continuous typing
        // } else { // if user is still typing continuously
        //     clearTimeout(this.saveTimeoutId);
        // }
        // this.saveTimeoutId = setTimeout(() => {
        //     this.isTypingContinuously = false;
        //     clearTimeout(this.continuousTimeoutId);
        //     this.saveContent(editorContent);
        // }, 4000); // save content 4 seconds after the user stops typing

        this.autosaveSubject.next(editorContent);

    };

    destroyAutoSaveSubscription(): void {
        this.autosaveSubscriptionDebounceTime.unsubscribe();
        this.autosaveSubscriptionAuditTime.unsubscribe();
    }

    private getAutoSaveEditorContent() {
        return this.autosaveSubject.asObservable();
    }

    private saveContent(editorContent: string): void {
        console.log('Content saved after specific time:', editorContent);
        this.$autoSaveContent.next(editorContent);
    }
}
