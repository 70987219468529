import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoDetail, DtoList, FilterCriteria,
    MvsCoreService,
    MvsCrudModeEnum,
    ObjectIdentifier, ObjectRequestComplex, ObjectRequestComplexNode, ObjectRequestList, Sorting,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {TmTicketObjectDto} from "../../../../dto/tm-ticket-object.dto";
import {ObjectTypeService} from "../../../../../cc/service/api/object-type.service";
import {ContractDto} from "../../../../../cm/dto/contract.dto";
import {CustomerDto} from "../../../../../cr/dto/customer.dto";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    MvsUiObjectService
} from "@kvers/alpha-ui";
import {DtoImportObjectContext} from "@kvers/alpha-core-common/lib/dto/dto.import.object.context";
import {TmTicketAppointmentDto} from "../../../../dto/tm-ticket-appointment.dto";
import {TicketAppointmentService} from "../../../../service/api/ticket-appointment.service";
import {TicketObjectDto} from "../../../../dto/ticket-object.dto";

@Component({
    selector: 'tm-ticket-landing-components',
    templateUrl: './tm-ticket-landing-components.component.html',
    styleUrls: ['./tm-ticket-landing-components.component.scss']
})
export class TmTicketLandingComponentsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() ticketObjects: TicketObjectDto[];
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() fullPage: boolean = false;

    @Output() onObjectChanged: EventEmitter<any> = new EventEmitter();
    @Output() changeMode: EventEmitter<string> = new EventEmitter();

    currentMainNavigationObject: ObjectIdentifier;
    suggestedTicketObjectLabel: string;
    // ticketObjectIdentifiers: ObjectIdentifier[];
    // ticketAppointmentList: DtoList<TmTicketAppointmentDto>;
    ticketObjectToolbarButtons: WidgetHelperButton[];

    constructor(
        protected objectTypeService: ObjectTypeService, protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,
        protected objectService: MvsUiObjectService,
        protected ticketAppointmentService: TicketAppointmentService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.ticketObjectToolbarButtons = [
            {
                label: null,
                icon: 'fa-regular fa-send-backward',
                display: true,
                action: 'background',
                tooltip: 'Open in background'
            },
            {
                label: null,
                icon: 'fa-regular fa-sidebar',
                display: true,
                action: 'sidebar',
                tooltip: 'Open in sidebar'
            }
        ];

        // this.refreshTicketAppointments();

        // this.getTicketObjectSuggestions();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = false;
        this.getTicketObjectIdentifier();
    }


    /**
     * retrieve objects linked with ticket and store their type and id in objectIdentifier
     */
    async getTicketObjectIdentifier() {
        if (this.ticketObjects && this.ticketObjects.length) {

            // this.ticketObjectIdentifiers = [];

            for (let ticketObject of this.ticketObjects) {
                const objectTypeDto = await this.objectTypeService.getViaObjectId(ticketObject.objectTypeDtoId);
                ticketObject['objectIdentifier'] = new ObjectIdentifier(objectTypeDto.alias, ticketObject.objectId);
            }
            this.initialized = true;
        } else {
            this.initialized = true;
        }

    }

    // async handleAddSuggestedObject() {
    //     this.busy = true;
    //     const crudService = this.coreService.getCrudService('tm.TicketObject');
    //     const dto = new TmTicketObjectDto();
    //     dto.ticketDtoId = this.objectIdentifier.objectId;
    //     dto.objectId = this.currentMainNavigationObject.objectId;
    //     await this.objectTypeService.getViaObjectType(this.currentMainNavigationObject.objectType).then(value => {
    //         dto.objectTypeDtoId = value.id;
    //     });
    //
    //     crudService.create(dto).subscribe(res => {
    //         //refresh component
    //         // this.refreshTicketAppointments();
    //         this.onObjectChanged.emit();
    //         this.busy = false;
    //     }, error => {
    //         this.busy = false;
    //     });
    //
    // }

    handleTicketTypeToolbarButton(event: WidgetHelperButton, objectIdentifier: ObjectIdentifier) {
        if (event.action == 'background') {
            this.navigationService.changeMainObject(objectIdentifier);
        } else if (event.action == 'sidebar') {
            const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifier.objectType, objectIdentifier.objectId, null, objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
            this.navigationService.navigateTo(mvsObjectNavigationEntry, 'left');
        }
    }

    handleSubNavigationView(event: string) {
        this.changeMode.emit(event)
    }

    handleRequestAppointment() {

        const defaultDto: DtoDetail = new DtoDetail()
        defaultDto['ticketDtoId'] = this.objectIdentifier.objectId;

        this.objectService.openObjectWidgetViaDialog(
            this.importObjectContext,
            "tm.TicketAppointment",
            0,
            'tm.ticket.appointment.object.widget',
            'Request Appointment',
            defaultDto,
            () => {
            },
            MvsCrudModeEnum.create
        );

    }

    // refreshTicketAppointments() {
    //
    //     const objectRequest = ObjectRequestList.createComplexRequestList(
    //         true,
    //         ObjectRequestComplex.build(
    //             true,
    //             false,
    //
    //             ObjectRequestComplexNode.createSimpleAttributeNode("ticket"),
    //             ObjectRequestComplexNode.createSimpleAttributeNode("appointment")
    //         ),
    //         [
    //             FilterCriteria.create(
    //                 'ticket',
    //                 FilterCriteria.cOperatorEqual,
    //                 this.objectIdentifier.objectId
    //             ),
    //         ],
    //         [new Sorting("createdDate", false)],
    //         null
    //     );
    //
    //     this.ticketAppointmentService.list(objectRequest).subscribe(res => {
    //         this.ticketAppointmentList = res;
    //
    //     })
    // }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes['ticketObjects']) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
