import {Injectable} from "@angular/core";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {PcHeaderCallActiveComponent} from "../../component/pc-header-call-active/pc-header-call-active.component";

@Injectable({
    providedIn: 'root'
})
export class CallUiService {

    headerActiveCallComponent: PcHeaderCallActiveComponent;

    /**
     * Register active call component.
     * @param component
     */
    public registerHeaderActiveCallComponent(component: PcHeaderCallActiveComponent) {
        this.headerActiveCallComponent = component;
    }

    public refreshHeaderActiveCall() {
        this.headerActiveCallComponent.refreshComponent();
    }


}