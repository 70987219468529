import {Component, OnInit} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    PageComponent,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {Subscription} from "rxjs";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-contact-page',
    templateUrl: './ui-config.page.html',
    styleUrls: ['./ui-config.page.scss']
})
export class UiConfigPage extends PageComponent implements OnInit {

    defaultLabel: string = "UI Configuration";

    userSettingsWidget: WidgetData;

    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;
    widgetEntries: TabEntries[] = [
        {label: 'User Settings', id: 'ui.UiSettingUser', type: ''},
    ];


    selectedTicketTypeId: number;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
        this.handleTabSelection(0);

    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            if (params['tid']) {
                const object = this.widgetEntries.find(item => item.id == params['tid']);
                if (object) {
                    this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
                    this.activeWidget = object;
                }
            }
        });
    }

    refreshWidgets() {
        this.refreshUserSettingWidget();
    }


    refreshUserSettingWidget() {
        this.userSettingsWidget = WidgetFactory.createWidgetList(
            "ui.config.user.setting.widget.simple",
            'User Settings',
            'table',
            'list',
            'entity',
            'ui.UiSettingUser',
            'No data found',
            ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
        );
    }

    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
        this.activeWidget = this.widgetEntries[selectedIndex];
        this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
    }

    isConfigurationPage(): boolean {
        return true;
    }

    widgetToolbarCreate(idField: string, idValue: number) {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[`${idField}DtoId`] = idValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(`${idField}DtoId`, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}
