import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {CmCustomerAssessmentDto} from "../../dto/cm-customer-assessment.dto";
import {CmCustomerAssessmentResultEnum} from "../../enum/cm-customer-assessment-result.enum";
import {CustomerAssessmentResultColorsEnum} from "../../enum/cm-customer-assessment-result-colors.enum";
import {CustomerAssessmentResultIconsEnum} from "../../enum/cm-customer-assessment-result-icons.enum";

@Component({
    selector: 'mvs-cm-insurance-overall-overview-component',
    templateUrl: './cm-insurance-overall-overview.component.html',
    styleUrls: ['./cm-insurance-overall-overview.component.scss']
})
export class CmInsuranceOverallOverviewComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() showOverall: boolean = true;
    @Input() showStack: boolean = true;
    @Input() customerAssessment: CmCustomerAssessmentDto;


    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    getColors(value: number): string {

        let color: string;

        switch (value) {
            case CmCustomerAssessmentResultEnum.not_applicable :
                break;
            case CmCustomerAssessmentResultEnum.poor :
                color = CustomerAssessmentResultColorsEnum.poor;
                break;
            case CmCustomerAssessmentResultEnum.below_average :
                color = CustomerAssessmentResultColorsEnum.below_average;
                break;
            case CmCustomerAssessmentResultEnum.average :
                color = CustomerAssessmentResultColorsEnum.average;
                break;
            case CmCustomerAssessmentResultEnum.good :
                color = CustomerAssessmentResultColorsEnum.good;
                break;
            case CmCustomerAssessmentResultEnum.excellent :
                color = CustomerAssessmentResultColorsEnum.excellent;
                break;

        }
        return color;
    }

    getIcons(value: number): string {

        let icon: string;

        switch (value) {
            case CmCustomerAssessmentResultEnum.not_applicable :
                break;
            case CmCustomerAssessmentResultEnum.poor :
                icon = CustomerAssessmentResultIconsEnum.poor;
                break;
            case CmCustomerAssessmentResultEnum.below_average :
                icon = CustomerAssessmentResultIconsEnum.below_average;
                break;
            case CmCustomerAssessmentResultEnum.average :
                icon = CustomerAssessmentResultIconsEnum.average;
                break;
            case CmCustomerAssessmentResultEnum.good :
                icon = CustomerAssessmentResultIconsEnum.good;
                break;
            case CmCustomerAssessmentResultEnum.excellent :
                icon = CustomerAssessmentResultIconsEnum.excellent;
                break;

        }
        return icon;
    }

}
