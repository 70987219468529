import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cm-insurance-structure-structure-page',
  templateUrl: './partner-config.page.html',
  styleUrls: ['./partner-config.page.scss']
})
export class PartnerConfigPage extends PageComponent implements OnInit {
  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "Partner Konfiguration";

  ngOnInit(): void {

    super.ngOnInit();

    this.objectBrowserRequest = {

      'extractDto'    : false,
      "createMode"    : true,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cm.PartnerType',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Partnertyp",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"

        },
        {
          'id'                : 2,
          'objectAlias'       : 'cm.Partner',
          'level'             : 1,
          'parentBinding'     : 'none',
          'dummyParentCreate' : true,
          'dummyParentLabel'  : "Partner",
          'newEntryNodeCreate': true,
          'newEntryNodeLabel' : "Anlegen"

        },

      ]

    };

    this.subObjectBrowserRequest = null;

  }

}
