import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {AsCalendarComponent} from "../../component/as-calendar/as-calendar.component";

@Injectable({
  providedIn: 'root'
})
export class ScheduleTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/as/scheduleTypes');
  }

  getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
    return AsCalendarComponent;
  }

}
