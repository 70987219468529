<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel header="CREATE OBJECT" >
        <mvs-wf-process-step-activity-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_DTO"
                [objectTypeDto]="objectTypeDto"
                [formDefaults]="[FORM_DEFAULTS]"
        ></mvs-wf-process-step-activity-object>
    </p-tabPanel>
    <p-tabPanel header="UPDATE OBJECT">
        <mvs-wf-process-step-activity-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_DTO_UPDATE"
                [objectTypeDto]="objectTypeDto"
                [bindings]="[BINDINGS]"
        ></mvs-wf-process-step-activity-object>
    </p-tabPanel>
    <p-tabPanel header="SEARCH">
        <mvs-wf-process-step-activity-search
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_SEARCH_DTO"
        ></mvs-wf-process-step-activity-search>
    </p-tabPanel>


    <p-tabPanel header="TICKET">
        <mvs-wf-process-step-activity-ticket-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_DTO"
        ></mvs-wf-process-step-activity-ticket-object>
    </p-tabPanel>


    <p-tabPanel header="TICKET STATUS">
        <mvs-wf-process-step-activity-ticket-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_TICKET_STATUS_DTO"
        ></mvs-wf-process-step-activity-ticket-object>
    </p-tabPanel>


    <p-tabPanel header="DOCUMENT UPLOAD">
        <mvs-wf-process-step-activity-document-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_DTO"
                [bindings]="[BINDINGS_DOCUMENT_DTO]"
        ></mvs-wf-process-step-activity-document-object>
    </p-tabPanel>


    <p-tabPanel header="DOCUMENT REVIEW">
        <mvs-wf-process-step-activity-document-object
                [activityType]="WF_PROCESS_TYPE_STEP_ACTIVITY_DOCUMENT_LIST_DTO"
                [bindings]="[BINDINGS_DOCUMENT_DTO]"
        ></mvs-wf-process-step-activity-document-object>
    </p-tabPanel>
</p-tabView>










