import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail, DtoList,
    MvsObjectService,
    ObjectIdentifier,
    ObjectTypeService,
    UiPageUserService, UiPageVisitRequestDto
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TicketService} from "../../service/api/ticket.service";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {forkJoin} from "rxjs";
import {UiPageVisitResponseDto} from "../../../ui/dto/ui-page-visit-response.dto";

export enum CommentHistoryEnum {
    'All Activity' = 1,
    'Comment',
    'History'
}

@Component({
    selector: 'mvs-ticket-history',
    templateUrl: './ticket-comment-history.component.html',
    styleUrls: ['./ticket-comment-history.component.scss']
})
export class TicketHistoryComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() selectedAction: CommentHistoryEnum = CommentHistoryEnum["All Activity"];
    historyList: DtoDetail[] = [];
    first = 0;
    rows = 5;
    enumCommentHistory = CommentHistoryEnum;
    ticketObjectTypeId: number;


    constructor(protected ticketService: TicketService, protected uiPageUserService: UiPageUserService, protected objectService: ObjectTypeService,) {
    }

    async ngOnInit() {

        await this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    async initComponent() {
        await this.objectService.getViaObjectType('tm.Ticket').then(res => {
            this.ticketObjectTypeId = res.id;
        })
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshHistory();
        this.initialized = true;
    }

    /**
     * refresh comments and actions and sort them
     */
    refreshHistory() {
        this.historyList = [];

        const complexSelection =
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createRelationNode("comments",
                    ObjectRequestRelation.createList(
                        "tm.TicketComment",
                        "ticket",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ),
                ObjectRequestComplexNode.createRelationNode("actions",
                    ObjectRequestRelation.createList(
                        "tm.TicketAction",
                        "ticket",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            null,
            null,
            null
        );

        // const visitObjectRequest = new ObjectRequestList(false,
        //     [
        //         FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
        //         FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, this.ticketObjectTypeId),
        //     ],
        //     []
        // );

        this.ticketService.list(objectRequestList).subscribe(res => {
            this.postProcessTicketData(res);
            this.sortEntries();
        })

        // forkJoin({
        //     ticketData: this.ticketService.list(objectRequestList),
        //     visitData: this.uiPageUserService.list(visitObjectRequest)
        // }).subscribe({
        //     next: ({ ticketData, visitData }) => {
        //        this.postProcessTicketData(ticketData);
        //        // this.postProcessVisitData(visitData);
        //        this.sortEntries();
        //
        //     }
        // });
    }

    postProcessVisitData(visitData: DtoList<UiPageVisitResponseDto>) {
        if (!visitData || !visitData.entries.length) {
            return;
        }

        for (let item of visitData.entries) {
            const dto = new DtoDetail();
            dto.createdDate = item.visitedAt;
            dto.lastModifiedDate = item.visitedAt;
            dto.createdBy = item.user;
            dto['uiType'] = 'visit';

            this.historyList.push(dto);
        }
    }
    postProcessTicketData(ticketData: DtoList<DtoDetail>) {
        // Process the first API call response
        const ticket = ticketData.entries[0];
        if (ticket['comments']) {
            ticket['comments'].forEach(element => {
                    element["uiType"] = "comment";
                    this.historyList.push(element);
                }
            )
        }

        if (ticket['actions']) {
            ticket['actions'].forEach(element => {
                    element["uiType"] = "action";
                    this.historyList.push(element);
                }
            )
        }
    }

    sortEntries() {
        this.historyList.sort((a, b) => {
            const dateA: any = new Date(a.lastModifiedDate);
            const dateB: any = new Date(b.lastModifiedDate);

            return dateB - dateA;

        });
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
