import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {CoUiChannelAccessTypeInfoUi} from "./data/co-ui-channel-access-type-info.ui";
import {CoChannelAccessTypeEnum} from "../../enum/co-channel-access-type-enum.enum";
import {AccessInfoDto} from "../../../pc/dto/access-info.dto";

@Injectable({
  providedIn: 'root'
})
export class CoUiService {


  channelAccessMap: CoUiChannelAccessTypeInfoUi[] = [
    {access: 0, text: 'Standardmäßig berechtigt', iconClass: 'fa-solid fa-circle-phone text-green-500', message: ''},
    {access: 1, text: 'Standardmäßig nicht berechtigt', iconClass: 'fa-solid fa-circle-phone text-yellow-500', message: 'Der Kunde hat noch keine eindeutige Freigabe für Anruferlaubniss erteilt!'},
    {access: 2, text: 'Berechtigt', iconClass: 'fa-solid fa-circle-phone text-green-500', message: ''},
    {access: 3, text: 'Berechtigt durch Kunde', iconClass: 'fa-solid fa-circle-phone text-green-500', message: ''},
    {access: 4, text: 'Nicht berechtigt', iconClass: 'fa-solid fa-phone-slash text-red-500', message: ''},
    {access: 5, text: 'Nicht berechtigt durch Kunde', iconClass: 'fa-solid fa-phone-slash text-red-500', message: ''},
  ];


  /**
   * Get channel access information.
   * @param accessType
   */
  public getChannelAccessTypeInfo(accessType: CoChannelAccessTypeEnum) : CoUiChannelAccessTypeInfoUi {

    const info = this.channelAccessMap.find(value => value.access===accessType);

    if (info) {
      return info;
    } else {
      return new class implements CoUiChannelAccessTypeInfoUi {
        access: CoChannelAccessTypeEnum;
        iconClass: string;
        message: string;
        text: string;
      };
    }


  }



}