import {Component, EventEmitter, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {CcObjectTypeDto} from "../dto/cc-object-type.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-xx-test-component',
    templateUrl: './test-component.component.html',
    styleUrls: ['./test-component.component.scss']
})
export class XxTestComponentComponent implements OnInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    @Output() onObjectSelected = new EventEmitter()

    objectTypesDtoList: DtoList;
    selectedObjectType: CcObjectTypeDto;

    protected objectService: ObjectTypeService;

    constructor(protected coreService: MvsCoreService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectService = <ObjectTypeService>this.coreService.getCrudService("cc.ObjectType");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshObjectTypes();

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    /**
     * retrieve list of object Types
     */

    refreshObjectTypes() {
        const doListRequest = new ObjectRequestList(false, [], []);

        this.objectService.list(doListRequest).subscribe(
            res => {
                this.objectTypesDtoList = res;
            }
        )
    }

    onObjectSelect(event) {
        const object = {objectType:this.selectedObjectType,object:event}
        this.onObjectSelected.emit(object);
    }

}
