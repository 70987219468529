import {
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ObjectBaseComponent } from "@kvers/alpha-core-common";
import { MvsCoreService } from "@kvers/alpha-core-common";
import { MvsMessageService } from "@kvers/alpha-core-common";
import { ConfirmationService } from "primeng/api";
import { ReportFieldService } from "../../service/api/report-field.service";
import { ObjectRequestList } from "@kvers/alpha-core-common";
import { FilterCriteria } from "@kvers/alpha-core-common";
import { DtoList } from "@kvers/alpha-core-common";
import { ObjectTypeService } from "@kvers/alpha-core-common";
import { Sorting } from "@kvers/alpha-core-common";
import { ReportObjectService } from "../../service/api/report-object.service";
import { RpReportObjectDto } from "../../dto/rp-report-object.dto";
import { ReportObjectLinkService } from "../../service/api/report-object-link.service";
import { RpReportDto } from "../../dto/rp-report.dto";
import { RpReportObjectLinkDto } from "../../dto/rp-report-object-link.dto";
import {first, forkJoin, Observable} from "rxjs";
import { RpObjectLinkTypeEnum } from "../../enum/rp-object-link-type.enum";
import { OverlayPanel } from "primeng/overlaypanel";
import { MetaService } from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import { DtoImportObjectContext } from "@kvers/alpha-core-common";
import { WidgetData } from "@kvers/alpha-core-common";
import { EntityPriorityHandler } from "@kvers/alpha-core-common";
import { ReportFilterService } from "../../service/api/report-filter.service";
import { ReportSortingService } from "../../service/api/report-sorting.service";
import { MetaDataAttributeDto } from "@kvers/alpha-core-common";
import { MetaDataJoinDto } from "@kvers/alpha-core-common";
import { DtoDetail } from "@kvers/alpha-core-common";
import { MvsSortingService } from "@kvers/alpha-core-common";
import {RpReportSortingDto} from "../../dto/rp-report-sorting.dto";
import {RpReportFilterDto} from "../../dto/rp-report-filter.dto";
import {CcObjectTypeDto} from "../../dto/cc-object-type.dto";
import {MetaDataGenericTypeDto} from "@kvers/alpha-core-common";
import {RpReportFieldDto} from "../../dto/rp-report-field.dto";
import {LinkTypeDto} from "../../dto/link-type.dto";
import {LinkJoinDto} from "../../dto/link-join.dto";
import {MetaEntityRequestDto} from "@kvers/alpha-core-common";
import {ObjectTypeAttributeService} from "../../../cc/service/api/object-type-attribute.service";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {DataTypeHelper} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {MvsConditionBuilderService} from "@kvers/alpha-ui";
import {ObserverService} from "@kvers/alpha-core-common";
export interface UiObjectTreeNode {
  startObject: boolean;
  reportObject: RpReportObjectDto;
  reportLink: RpReportObjectLinkDto;
  linkedObjects: UiObjectTreeNode[];

  level: number;
  peerLevel: number;
}

export interface RpReportUiRow {
  columns: RpReportUiColumn[];
}

export interface RpReportUiColumn {
  node: UiObjectTreeNode;
  type: string;
  connectionLine: string;
}

@Component({
  selector: "mvs-rp-report-object",
  templateUrl: "./rp-report-object.component.html",
  styleUrls: ["./rp-report-object.component.scss"],
})
export class RpReportObjectComponent
  extends ObjectBaseComponent
  implements OnInit, OnChanges {
  reportFieldService: ReportFieldService;
  reportObjectService: ReportObjectService;
  reportObjectLinkService: ReportObjectLinkService;
  reportFilterService: ReportFilterService;
  reportSortingService: ReportSortingService;

  node: UiObjectTreeNode[] = [];
  treeMaxLevels: number = 0;
  treeMaxPeerLevels: number = 0;

  selectedReportObj: RpReportObjectDto;

  rows: RpReportUiRow[] = [];
  reportDtoId: number;

  dto: RpReportDto;
  linkTypeEnum: typeof RpObjectLinkTypeEnum = RpObjectLinkTypeEnum;

  reportObjectDtoList: DtoList<RpReportObjectDto>;
  reportObjectLinkDtoList: DtoList<RpReportObjectLinkDto>;
  selectedProduct: any;
  products: any;
  attributes: MetaDataAttributeDto[] = [];
  selectedAttribute: MetaDataAttributeDto;

  reportFields: DtoList;

  attributeList: RpReportFieldDto[] = [];
  currentColumn: number;

  previousReportObject: RpReportObjectDto;
  attributesSidebar = false;
  blockedDocument: boolean = false;
  genericTypes: MetaDataGenericTypeDto[] = [];
  selectedGenericType: MetaDataGenericTypeDto;
  linkJoin : LinkJoinDto[] = [
    {
      joinName: 'Main',
      joinTypeId: 0
    },
    {
      joinName: 'Side',
      joinTypeId: 1
    }];

  linkType: LinkTypeDto[] = [
    {
      linkName: 'OneToOne',
      linkTypeId: 0
    },
    {
      linkName: 'OneToMany',
      linkTypeId: 1
    }];

  selectedLinkType: LinkTypeDto;
  selectedLinkJoin: LinkJoinDto;
  testBottomBar: boolean = false;
  reportWidgetData: WidgetData;
  displayLinkModal: boolean = false;
  displayLinkTypeModal: boolean = false;
  reportName: string;
  isMultiple: boolean = true;
  newFilterCriteria: FilterCriteria[] | undefined;
  displayStartObjectModal:boolean = false;
  sortingResponse: Sorting[];
  reportObjectSortingId: number;
  reportFilterId: number;
  objectTypes: DtoList;
  widgetReport: WidgetData;
  showWidgetReportData: boolean = false;
  reportLinkSortingId:number;
  isReportObject:boolean;
  displayGenericTypeModal: boolean = false;

  selectedReportObjectLink: RpReportObjectLinkDto;
  rowIncreased: boolean;

  @ViewChild("joins", { static: true }) joinsPanel: OverlayPanel;
  @ViewChild("attributesPanel", { static: true }) attributesPanel: OverlayPanel;
  @ViewChild("genericTypesPanel", { static: true }) genericPanel: OverlayPanel;
  @ViewChild("joinTypePanel", { static: true }) joinTypePanel: OverlayPanel;

  showSidebar: boolean = false;
  selectedUiReportObject: UiObjectTreeNode;
  uiTabIndex: number;

  constructor(
    protected coreService: MvsCoreService,
    protected messageService: MvsMessageService,
    protected confirmationService: ConfirmationService,
    protected metaService: MetaService,
    protected objectService: MvsUiObjectService,
    protected sortingService: MvsSortingService,
    protected conditionBuilderService: MvsConditionBuilderService,
    protected objectTypeService: ObjectTypeService,
    protected objectTypeAttributeService: ObjectTypeAttributeService,
    protected override observerService: ObserverService
  ) {
    super(coreService, messageService, confirmationService, observerService);
  }

  ngOnInit() {
    super.ngOnInit();

  }

  initComponent() {
    // retrieve specific service
    this.reportFieldService = <ReportFieldService>(
        this.coreService.getCrudService("rp.ReportField")
    );
    this.reportObjectService = <ReportObjectService>(
        this.coreService.getCrudService("rp.ReportObject")
    );
    this.reportObjectLinkService = <ReportObjectLinkService>(
        this.coreService.getCrudService("rp.ReportObjectLink")
    );
    this.reportFilterService = <ReportFilterService>(
        this.coreService.getCrudService("rp.ReportFilter")
    );

    this.reportSortingService = <ReportSortingService>(
        this.coreService.getCrudService("rp.ReportSorting")
    );

    this.objectTypeAttributeService = <ObjectTypeAttributeService>this.coreService.getCrudService("cc.ObjectTypeAttribute");
  }


  refreshComponent() {

    // call super implementation
    super.refreshComponent();

    this.refreshReport();

  }

  uiRenderReportTree() {
    // 1) find the start object
    let startObject: RpReportObjectDto;

    for (let entry of this.reportObjectDtoList.entries) {
      if (entry.startObject) {
        startObject = entry;
        break;
      }
    }

    const rootNode = {
      startObject: true,
      reportObject: startObject,
      reportLink: null,
      linkedObjects: [],
      level: 0,
      peerLevel: 0,
    };

    this.node = [];
    this.treeMaxLevels = 0;
    this.treeMaxPeerLevels = 0;

    this.node.push(rootNode);

    // create tree
    this.uiResolveTree(rootNode, 0, 0);

    // convert tree to matrix
    this.uiTreeToMatrix(rootNode);

    //fix matrix structure for null nodes
    this.spliceFirstNullNode(this.rows);
  }

  spliceFirstNullNode(rows) {
    for (const row of rows) {
      for (let i = 1; i < row.columns.length; i++) {
        const currentCell = row.columns[i];
        const previousCell = row.columns[i - 1];
        if (currentCell.node && !previousCell.node && i !== 1) {
          const nullObjectIndex = i - 1;
          if (nullObjectIndex !== 0) {
            row.columns.splice(nullObjectIndex, 1);
          }
        }
      }
    }
  }

  uiTreeToMatrix(node: UiObjectTreeNode) {
    // initialize array
    this.rows = [];

    //treeMaxPeerLevels = rows
    //treeMaxLevels = columns
    for (let i = 0; i <= this.treeMaxPeerLevels; i++) {
      const row: RpReportUiRow = {
        columns: [],
      };

      for (let x = 0; x <= this.treeMaxLevels * 2; x++) {
        row.columns.push({
          node: null,
          type: "",
          connectionLine: ""
        });
      }

      this.rows.push(row);
    }

    this.uiTreeToMatrixResolveNodes(null, node, 0);
  }

  uiTreeToMatrixResolveNodes(parent: UiObjectTreeNode, node: UiObjectTreeNode, childIndex: number) {
    this.uiTreeToMatrixAssignNode(parent, node, childIndex);

    let index = 0;
    for (let linkedObject of node.linkedObjects) {
      this.uiTreeToMatrixResolveNodes(node, linkedObject, index++);
    }
  }

  uiTreeToMatrixAssignNode(parent: UiObjectTreeNode, node: UiObjectTreeNode, childIndex: number) {
    const row = this.rows[node.peerLevel];
    const cell = row.columns[node.level * 2];
    cell.node = node;
    cell.type = "reportObject";

    if (node.level > 0) {
      const cellLink = row.columns[node.level * 2 - 1];
      cellLink.node = node;
      cellLink.type = "link";

      // calculate connection type
      if (parent.linkedObjects.length == 1) {
        cellLink.connectionLine = "main";
      } else {
        // multiple children exist
        if (childIndex == 0) {
          cellLink.connectionLine = "down";
        } else {
          if (parent.linkedObjects.length == childIndex + 1) {
            cellLink.connectionLine = "up";
          } else {
            cellLink.connectionLine = "middle";
          }
        }
      }

    }
  }

  uiResolveTree(
    node: UiObjectTreeNode,
    parentLevel: number,
    peerLevel: number
  ) {

    if (parentLevel > this.treeMaxLevels) {
      this.treeMaxLevels = parentLevel;
    }

    if (peerLevel > this.treeMaxPeerLevels) {
      this.treeMaxPeerLevels = peerLevel;
    }
    // else if (this.rowIncreased) {
    //   this.treeMaxPeerLevels++;
    // }

    // 1) Find children
    let linkedObjects = this.getLinkedObjects(node.reportObject);

    // 2 + 3) add children + call recursively for children
    if (linkedObjects) {
      let peerLevelChild: number = peerLevel;

      // process all links and resolve children
      for (let linkedObject of linkedObjects) {
        let childReportObject=
          this.getReportObjectTargetFromLink(linkedObject);

        // if (this.rowIncreased) {
        //   peerLevelChild++;
        // }

        const childNode = {
          startObject: false,
          reportObject: childReportObject,
          reportLink: linkedObject,
          linkedObjects: [],
          level: parentLevel + 1,
          peerLevel: peerLevelChild,
        };

        node.linkedObjects.push(childNode);

        // if (peerLevelChild > this.treeMaxPeerLevels) {
        //   this.treeMaxPeerLevels = this.treeMaxPeerLevels + 1;
        //   peerLevelChild++;
        // }

        // if (peerLevelChild > peerLevel) {
        //   this.rowIncreased = true;
        // } else {
        //   this.rowIncreased = false;
        // }

        // process children of child
        this.uiResolveTree(childNode, parentLevel + 1, peerLevelChild);
        peerLevelChild++;
      }

    }
  }

  getReportObjectTargetFromLink(
    link: RpReportObjectLinkDto
  ): RpReportObjectDto {

    for (let reportObject of this.reportObjectDtoList.entries) {
      if (link.targetObjectDtoId == reportObject.id) {
        return reportObject;
      }
    }
    return null;
  }

  /**
   * Return all links for a source object.
   * @param sourceObject
   */
  getLinkedObjects(sourceObject: RpReportObjectDto): RpReportObjectLinkDto[] {
    let links: RpReportObjectLinkDto[] = [];

    for (let link of this.reportObjectLinkDtoList.entries) {
      if (link.sourceObjectDtoId === sourceObject.id) {
        links.push(link);
      }
    }
    for (const item of links) {
      if (item.linkJoin == 0) {
        if (item.linkType == this.linkTypeEnum.OneToOne) {
          item.linkTypeLabel = '1:1'
        }
        else {
          item.linkTypeLabel = '1:N'
        }
      }
      else if (item.linkJoin == 1) {
        if (item.linkType == this.linkTypeEnum.OneToOne) {
          item.linkTypeLabel = '1:C'
        }
        else {
          item.linkTypeLabel = '1:CN'
        }
      }

      if (item.linkJoin == 1) {
        item.linkJoinLabel = 'Side'
      }
      else {
        item.linkJoinLabel = 'Main';
      }
    }

    return links;
  }

  refreshReport() {

    if (!this.dto) {
      return;
    }

    this.reportFieldService
        .listByAttribute("report", this.dto.id)
        .subscribe((value) => { });

    const dtoListRequest = new ObjectRequestList(
        false,
        [
          FilterCriteria.create(
              "report",
              FilterCriteria.cOperatorEqual,
              this.dto.id,
              null
          ),
        ],
        null
    );
    dtoListRequest.objectRequestSimple = ObjectRequestSimple.create(true, true,1);

    forkJoin({
      reportObject: this.reportObjectService.listByAttribute(
          "report",
          this.dto.id
      ),
      reportObjectLink: this.reportObjectLinkService.listByAttribute(
          "report",
          this.dto.id
      ),
    }).subscribe(({ reportObject, reportObjectLink }) => {
      if (reportObject && reportObject.entries) {
        this.reportObjectDtoList = <DtoList<RpReportObjectDto>>reportObject;
      }
      if (reportObjectLink && reportObjectLink.entries) {
        this.reportObjectLinkDtoList = <DtoList<RpReportObjectLinkDto>>(
            reportObjectLink
        );
      }
      this.uiRenderReportTree();
    });

    // this.onGetAttributes();

    // this.reportObjectService.listByAttribute("report", this.dto.id).subscribe((value) => {
    //         this.reportObjectDtoList = <DtoList<RpReportObjectDto>>value;

    // this.reportObjectLinkService.listByAttribute("report", this.dto.id).subscribe((value) => {
    //                 this.reportObjectLinkDtoList = <DtoList<RpReportObjectLinkDto>>(value);
    //                 this.uiRenderReportTree();
    //             });
    //     });
    this.blockedDocument = false;
  }

  /**
   * If the report ID changes.
   */
  onObjectChanged() {
    super.onObjectChanged();

    this.refreshReport();

  }

  onGetMetaInfo(obj: UiObjectTreeNode, action: string) {
    this.blockedDocument = true;
    this.selectedGenericType = undefined;
    this.previousReportObject = new RpReportObjectDto();
    this.previousReportObject = obj.reportObject;
    this.reportDtoId = obj.reportObject.reportDtoId;
    this.products = [];
    this.attributes = [];
    this.currentColumn = obj.level;
    this.selectedReportObj = obj.reportObject;
    this.metaService.getById(obj.reportObject.objectTypeDtoId, true, true, true).subscribe((res) => {
      let joins: MetaDataJoinDto[] = res.joins;

      let attributes: MetaDataAttributeDto[] = res.attributes;
      let genericTypeList: MetaDataGenericTypeDto[] = res.genericTypes;



      if (action == 'joins') {
        for (const item of joins) {
          this.products.push(item);
        }
        // this.displayLinkModal = true;
      }

      if (action == 'attributes') {
        if (obj.reportObject.objectGenericTypeId != undefined) {
          for (const item of genericTypeList) {
            if (obj.reportObject.objectGenericTypeId == item.typeId) {
              this.attributes = item.attributes;
            }
          }
        }
        else {
          for (let atr of attributes) {
            let dataType = DataTypeHelper.getInternalDataTypeForFormField(atr);
            atr["icon"] = dataType;
            this.attributes.push(atr);
          }
        }
        // this.attributesPanel.toggle(event);
      }

      this.blockedDocument = false;

    });
  }

  reset() {
    this.selectedProduct = null;
    this.selectedLinkJoin = null;
    this.selectedGenericType = null;
    this.reportName = null;
    this.isMultiple = true;
    this.selectedLinkType = null;
  }

  /**
   * Show widget information whenever the report data should be visible.
   */
  handleToggleReportData() {
    this.blockedDocument = true;
    if (this.showWidgetReportData) {

      this.widgetReport = new WidgetData();
      this.widgetReport.idAlias = "rp.config.report.view";
      this.widgetReport.name = "Report";
      this.widgetReport.uiComponent = "data";
      this.widgetReport.dataProvider = "list";
      this.widgetReport.dataSource = "entity";
      this.widgetReport.dataProviderObject = "rp.Report";
      this.widgetReport.setParamValue("objectId", this.objectIdentifier.objectId);

    } else {
      this.widgetReport = null;
    }
    this.blockedDocument = false;
  }

  checkIfGeneric() {
    // this.displayLinkTypeModal = true;
    //checking if it is of generic type
    if (this.selectedProduct.genericType == true) {
      this.getGenericTypes();
    }
    else {
      this.genericTypes = [];
      this.reportName = this.selectedProduct.name;
    }
  }

  openJoinTypeOverlay(event: any) {
    this.joinTypePanel.toggle(event.originalEvent);
  }

  onLinkEntity() {

    this.blockedDocument = true;
    let objectGenericTypeId = this.selectedGenericType?.typeId;
    let dto: RpReportObjectDto = new RpReportObjectDto();
        dto.reportDtoId =  this.reportDtoId;
        dto.name = this.reportName;
        dto.startObject = false;
        dto.objectTypeDtoId = this.selectedProduct.joinObjectTypeId;
        dto.objectGenericTypeId = objectGenericTypeId;
    this.reportObjectService.create(dto).subscribe((res:RpReportObjectDto) => {
      this.getReportLinkObject(res);
    });
  }

  getGenericTypes() {
    this.blockedDocument = true;
    this.metaService.getByAlias(this.selectedProduct.joinObjectTypeAlias, true, true, true).subscribe(res => {
      const obj : MetaDataGenericTypeDto = new MetaDataGenericTypeDto();
        obj.typeId = null;
        obj.typeName = this.selectedProduct.name;
      this.genericTypes = res.genericTypes;
      for (const item of this.genericTypes) {
        item.typeName = this.selectedProduct.name + ' - ' + item.typeName
      }

      this.genericTypes.unshift(obj);
      this.displayGenericTypeModal = true;
      this.blockedDocument = false;
    })
  }

  getReportLinkObject(obj: RpReportObjectDto) {
    let dto: RpReportObjectLinkDto = new RpReportObjectLinkDto();
        dto.reportDtoId =  obj.reportDtoId;
        dto.reportObjectDtoId =  this.previousReportObject.id;
        dto.linkType =  this.selectedProduct.joinRelationEnum == 'oneToOne' ? 0  :  this.selectedLinkType.linkTypeId;
        dto.linkJoin =  this.selectedLinkJoin.joinTypeId;
        dto.linkAttribute =  this.selectedProduct.attributeName;
        dto.sourceObjectDtoId =  this.previousReportObject.id;
        dto.targetObjectDtoId =  obj.id;
        dto.name =  this.selectedProduct.name;
        dto.priority =  this.currentColumn + 1;
        dto.multiple =  this.selectedProduct.joinRelationEnum == 'oneToOne' ? false  :  this.isMultiple;
        dto.optional = false;

    this.reportObjectLinkService.create(dto).subscribe((res) => {
      this.refreshReport();
      this.reset();
      this.displayGenericTypeModal = false;
      this.displayLinkModal = false;
      this.displayLinkTypeModal = false;
      this.blockedDocument = false;
    });
  }

  onConfirmDeleteLink(event: Event, ReportObjectID: number, ReportObjectLinkID: number) {

    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteReportObjectLink(ReportObjectID, ReportObjectLinkID);
      },
      reject: () => {
      },
    });
  }

  deleteReportObjectLink(ReportObjectID: number, ReportObjectLinkID: number) {

    this.blockedDocument = true;
    this.reportObjectLinkService.delete(ReportObjectLinkID).subscribe(res => {
      this.deleteReportObject(ReportObjectID);

    })
  }

  deleteReportObject(id: number) {
    this.reportObjectService.delete(id).subscribe(res => {
      this.refreshReport();
      this.blockedDocument = false;
    })
  }



  onAddAttributestoField(event: any) {

    this.blockedDocument = true;
    let priority = (this.attributeList.length);
    let dto: RpReportFieldDto = new RpReportFieldDto();

        dto.reportDtoId =  this.selectedReportObj.reportDtoId;
        dto.reportObjectDtoId =  this.selectedReportObj.id;
        dto.attributeName =  this.selectedAttribute.attributeName;
        dto.priority =  (priority + 1) * 10000;
        dto.name =  this.selectedAttribute.label;

    this.reportFieldService.create(dto).subscribe(res => {
      this.attributesPanel.toggle(event);
      this.attributesSidebar = false;
      this.onGetAttributes();
    })
  }
  onGetAttributes() {
    this.attributesSidebar = !this.attributesSidebar;
    if(this.attributesSidebar){
      this.blockedDocument = true;
      const filterCriteriaList = [];
      filterCriteriaList.push(FilterCriteria.createSingleCondition('report', FilterCriteria.cOperatorEqual, this.dto.id, null));

      const dtoListRequest = new ObjectRequestList(false, filterCriteriaList, [new Sorting('priority', true)]);

      dtoListRequest.objectRequestSimple = ObjectRequestSimple.create(true, true,1);
      this.reportFieldService.list(dtoListRequest).subscribe((res:DtoList<RpReportFieldDto>) => {
        this.attributeList = [];

        for (const item of res.entries) {
          // TODO
          // let dataType = this.MvsFilterHelperService.handleDataType(item);
          // item["icon"] = dataType;
          this.attributeList.push(item);
        }
        this.retrieveDataTypesForAttributeList(this.attributeList);

        this.blockedDocument = false;
      })
    }

    // retrieve data types

    // this.reportFieldService.delete();
  }

  //TODO: Haris, please finalize the implementation.
  /**
   * Retrieve attribute types based on selected attributes.
   * @param attributeList
   */
  retrieveDataTypesForAttributeList(attributeList: RpReportFieldDto[]) {

    const reportObjectIds = Array.from(new Set(attributeList.map(attr => attr.reportObjectDtoId)));
    const filteredReportObjects = this.reportObjectDtoList.entries.filter(reportObj => reportObjectIds.includes(reportObj.id));
    const objectTypeDtoIds = Array.from(new Set(filteredReportObjects.map(attr => attr.objectTypeDtoId)));

    const dtoListRequest = new MetaEntityRequestDto();
    dtoListRequest.listByIds = objectTypeDtoIds;
    dtoListRequest.extractAttributes = true;
    dtoListRequest.extractJoins = false;

    let metaAttributes: MetaDataEntityDto[];
    this.metaService.list(dtoListRequest).subscribe(res =>{
      metaAttributes = res.entities;

      // 1) Retrieve Object Types via the ReportObject

      for (let attribute of attributeList) {

        for (let entry of this.reportObjectDtoList.entries) {
          if (attribute.reportObjectDtoId == entry.id) {
            let reportObjectAttributes = metaAttributes.find(item => item.objectTypeId == entry.objectTypeDtoId).attributes;
            for (let element of reportObjectAttributes) {
              if(element.attributeName == attribute.attributeName){
                attribute["icon"] = DataTypeHelper.getInternalDataTypeForFormField(element);
              }
            }
          }
        }

      }
    })

    // 2) Use MetaService to retrieve the Attributes of the Object Types (run the meta service only once per object type)

    // 3) Map the retrieved Object Types and Attributes against the Attribute List


  }


  deleteAttribute(id: number) {

    this.blockedDocument = true;
    this.reportFieldService.delete(id).subscribe(res => {
      this.refreshReport();
      if (this.testBottomBar) {
        this.checkTest();
      }
    })
  }

  changeAttributePriority() {

    this.blockedDocument = true;
    let operations = EntityPriorityHandler.adjustPriority(this.attributeList, "priority", 1000);
    let updatedArray = [];
    for (const item of operations) {
      updatedArray.push(item.entry);
    }

    const entryObservables: Observable<any>[] = updatedArray.map(entry => {
      return this.reportFieldService.update(entry);
    });

    forkJoin(entryObservables).subscribe(value => {
    });
    this.blockedDocument = false;
  }

  onConfirmDeleteAttribute(id: number) {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this attribute?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAttribute(id)
      },
      reject: () => {
      },
    });
  }

  onEditAttributesField(id: number) {
    this.objectService.openObjectViaDialog(
      new DtoImportObjectContext([]),
      "rp.ReportField",
      id,
      null,
      false,
      false,
      () => {
        this.onGetAttributes();
      },
        MvsCrudModeEnum.create
    );
  }
  checkTest() {
    this.testBottomBar = !this.testBottomBar;
    if (this.testBottomBar) {
      this.reportWidgetData = new WidgetData();
      this.reportWidgetData.id = 1;
      this.reportWidgetData.idAlias = "ReportEditor.TestReport";
      this.reportWidgetData.name = "Test Report";
      this.reportWidgetData.uiComponent = "table";
      this.reportWidgetData.dataProvider = "list";
      this.reportWidgetData.dataSource = "report";
      this.reportWidgetData.dataProviderObject = this.dto.id + "";
      this.reportWidgetData.setParamValue("size", "M");
    }
  }

  onEditReportObjectName(item: RpReportObjectDto) {
    item.edit = false;
    let dto: RpReportObjectDto = new RpReportObjectDto();
    dto.id = item.id;
    dto.name = item.name;
    this.reportObjectService.update(dto).subscribe(res => {
    })

  }

  updateLinkRecords() {
    if (this.selectedLinkType.linkTypeId == 0) {
      this.isMultiple = false;
    }
    else {
      this.isMultiple = true;
    }
  }



  createReportFilter(reportObject: RpReportObjectDto, json) {

    const dto: RpReportFilterDto = new RpReportFilterDto();
    dto.name = reportObject.name;
    dto.filterJson = JSON.stringify(json);
    dto.sourceObjectTypeDtoId = reportObject.objectTypeDtoId;

    this.reportFilterService.create(dto).subscribe(res => {
      this.updateReportObjectFilter(reportObject.id, res);
    })
  }


  getReportObjectFilter(reportObject: RpReportObjectDto) {

    //TODO: newFilterCriteria must be part of the method
    if (!reportObject.reportFilterDtoId) {
      // no filter was defined yet
      this.newFilterCriteria = undefined;
      this.onShowCbViaId(reportObject);
      return;
    }

    // retrieve filter
    const dtoListRequest = new ObjectRequestList(false, [
      FilterCriteria.create(
        "id",
        FilterCriteria.cOperatorEqual,
        reportObject.reportFilterDtoId,
        null
      ),
    ], []);
    this.reportFilterService.list(dtoListRequest).subscribe((res: DtoList<RpReportFilterDto>) => {

      let filterObject = res.entries[0];

      if (filterObject) {
        this.newFilterCriteria = JSON.parse(filterObject.filterJson);
        this.reportFilterId = filterObject.id;
      }
      else {
        this.newFilterCriteria = undefined;
      }
      this.onShowCbViaId(reportObject);
    })
  }



  updateReportObjectFilter(reportObjectId: number, value: DtoDetail) {

    let dto : RpReportObjectDto = new RpReportObjectDto();
    dto.id = reportObjectId;
    dto.reportFilterDtoId = value.id;

    this.reportObjectService.update(dto).subscribe(res => {
      this.refreshReport();
    })
  }

  saveFilterJSON(response, reportObject: RpReportObjectDto) {

    if (!reportObject.reportFilterDtoId) {
      this.createReportFilter(reportObject, response);
    }
    else {
      this.updateReportFilter(response);
    }
  }


  updateReportLinkSorting(json) {

    let dto: RpReportSortingDto = new RpReportSortingDto();
    dto.id = this.reportLinkSortingId;
    dto.sortingJson = JSON.stringify(json);

    this.reportSortingService.update(dto).subscribe(res => {
    });
  }

  updateReportFilter(json) {

    let dto: RpReportFilterDto = new RpReportFilterDto();
    dto.id = this.reportFilterId;
    dto.filterJson = JSON.stringify(json);

    this.reportFilterService.update(dto).subscribe(res => {
    })
  }



  removeFilterReportObject(reportObjectId: number) {

    let dto: RpReportObjectDto = new RpReportObjectDto();
    dto.id = reportObjectId;
    dto.reportFilterDtoId = null;

    this.reportObjectService.update(dto).subscribe(res => {
      this.deleteFilter();
    });
  }

  deleteFilter() {

    this.reportFilterService.delete(this.reportFilterId).subscribe(res => {
      this.refreshReport();
    })
  }


  onShowFilter(reportObject: RpReportObjectDto) {

    this.getReportObjectFilter(reportObject);
  }

  onShowLinkedFilter(reportObjectLink: RpReportObjectLinkDto) {
    let reportObject: RpReportObjectDto = this.reportObjectDtoList.entries.find(item => item.id == reportObjectLink.targetObjectDtoId);
    this.onShowFilter(reportObject);
  }

  exposeDialog(reportObject: RpReportObjectDto) {

    this.sortingService.showSortingEntityDialog(
      this.sortingResponse,
      reportObject.objectTypeDtoId,
      false,
      (sorting: Sorting[]) => {this.saveSortingJSON(sorting, reportObject);
        return true},
      (sorting: Sorting[]) => {
      return true},
      (sorting: Sorting[]) => {this.removeSortingReportObject(reportObject.id);
      return true},
      (sorting: Sorting[]) => {this.saveSortingJSON(sorting, reportObject);
      return true}
    );
  }

  onShowCbViaId(reportObject: RpReportObjectDto) {

    this.conditionBuilderService.showConditionBuilderEntityDialog(
      this.newFilterCriteria,
      reportObject.objectTypeDtoId,
      null,
      false,
      (filterCriteria: FilterCriteria[]) => {this.saveFilterJSON(filterCriteria,reportObject);
      return true},
        (filterCriteria: FilterCriteria[]) => {
          return true},
        (filterCriteria: FilterCriteria[]) => {this.removeFilterReportObject(reportObject.id)
          return true}
    );
  }

  onViewAllObjectTypes(){
    if(this.objectTypes == undefined){
    this.blockedDocument = true;
    this.objectTypeService.list(ObjectRequestList.createSimple(null)).subscribe(value => {
      this.objectTypes = value;
      this.objectTypes.sortBy("alias", true);
      this.initialized = true;
      this.blockedDocument = false;
      this.displayStartObjectModal = true;
    });
    }
    else{
      this.displayStartObjectModal = true;
    }
  }

  // sorting create functions
  onCreateStartObject(type: CcObjectTypeDto){
    this.blockedDocument = true;
    let dto: RpReportObjectDto = new RpReportObjectDto();
        dto.reportDtoId =  this.dto.id;
        dto.name = type.name;
        dto.startObject = true;
        dto.objectTypeDtoId = type.id;

    this.reportObjectService.create(dto).subscribe((res) => {
      this.displayStartObjectModal = false;
      this.refreshReport();

    });
  }

  onShowSortings(reportObject: RpReportObjectDto, isReportObject:boolean) {

    this.isReportObject = isReportObject;
    //check for previous sortings if available
    this.getReportObjectSorting(reportObject);
  }

  getReportObjectSorting(reportObject: RpReportObjectDto) {

    // sorting does not exist yet
    if (!reportObject.reportSortingDtoId) {
      this.sortingResponse = undefined;
      this.exposeDialog(reportObject);
      return;
    }

    const dtoListRequest = new ObjectRequestList(false, [
      FilterCriteria.create(
          "id",
          FilterCriteria.cOperatorEqual,
          reportObject.reportSortingDtoId,
          null
      ),
    ], []);
    this.reportSortingService.list(dtoListRequest).subscribe((res:DtoList<RpReportSortingDto>) => {
      this.sortingResponse = [];
      let sortingObject = res.entries[0];
      if (sortingObject) {
        this.sortingResponse = JSON.parse(sortingObject.sortingJson);
        if(this.isReportObject){
          this.reportObjectSortingId = sortingObject.id;
        }
        else if (!this.isReportObject){
          this.reportLinkSortingId = sortingObject.id;
        }
      }
      else {
        this.sortingResponse = undefined;
      }
      this.exposeDialog(reportObject);
    })
  }

  saveSortingJSON(response, reportObject: RpReportObjectDto) {

    this.blockedDocument = true;
    //if sorting is for report object
    if(this.isReportObject){
      this.saveReportObjectSorting(response,reportObject);
    }
    //if sorting is for report link
    else if(!this.isReportObject){
      this.saveReportObjectLinkSorting(response,reportObject);
    }
  }

  saveReportObjectSorting(response, reportObject: RpReportObjectDto){
    //check if no sorting exists then create new
    if (!reportObject.reportSortingDtoId) {
      this.createReportSorting(reportObject, response);
    }
    //check if sorting exists then just update it
    else {
      this.updateReportSorting(response);
    }
  }

  createReportSorting(reportObject: RpReportObjectDto, json) {
    const dto: RpReportSortingDto = new RpReportSortingDto();
    dto.name = reportObject.name;
    dto.sortingJson = JSON.stringify(json);
    dto.sourceObjectTypeDtoId = reportObject.objectTypeDtoId;

    this.reportSortingService.create(dto).subscribe((res: DtoDetail) => {
      //once sorting is created, update the report object
      this.updateReportObjectSorting(reportObject.id, res);

    })
  }

  updateReportObjectSorting(reportObjectId: number, value: DtoDetail) {
    let dto : RpReportObjectDto = new RpReportObjectDto();
    dto.id = reportObjectId;
    dto.reportSortingDtoId = value.id;

    this.reportObjectService.update(dto).subscribe(res => {
      this.refreshReport();
    })
  }


  //sorting update function
  updateReportSorting(json) {

    let dto: RpReportSortingDto = new RpReportSortingDto();
    dto.id = this.reportObjectSortingId;
    dto.sortingJson = JSON.stringify(json);

    this.reportSortingService.update(dto).subscribe(res => {
      this.blockedDocument = false;
    });
  }

  //sorting delete functions
  removeSortingReportObject(reportObjectId: number) {
    //first remove the reportSortingDtoId from the report object
    this.blockedDocument = true;
    let dto: RpReportObjectDto = new RpReportObjectDto();
    dto.id = reportObjectId;
    dto.reportSortingDtoId = null;

    this.reportObjectService.update(dto).subscribe(res => {
      //once reportObject is done, delete the sorting itself
      this.deleteSorting();
    })
  }

  deleteSorting() {
    this.reportSortingService.delete(this.reportObjectSortingId).subscribe(res => {
      this.refreshReport();
    })
  }

  //report link sorting functions
  onShowLinkedSortings(reportObjectLink: RpReportObjectLinkDto) {

    let reportObject: RpReportObjectDto = this.reportObjectDtoList.entries.find(item => item.id == reportObjectLink.targetObjectDtoId);
    this.selectedReportObjectLink = reportObjectLink;
    this.onShowSortings(reportObject,false);
  }

  updateReportObjectLinkSorting(reportObjectLinkId: number, value: DtoDetail){
    let dto : RpReportObjectLinkDto = new RpReportObjectLinkDto();
    dto.id = reportObjectLinkId;
    dto.reportSortingDtoId = value.id;

    this.reportObjectLinkService.update(dto).subscribe(res => {
      this.selectedReportObjectLink = null;
      this.isReportObject = null;
      this.refreshReport();
    })
  }

  saveReportObjectLinkSorting(response, reportObject: RpReportObjectDto){

    if(!this.selectedReportObjectLink.reportSortingDtoId){
      this.createReportSorting(reportObject, response);
    }
    else{
      this.updateReportLinkSorting(response);
    }
  }

  handleSetSidebar(object: UiObjectTreeNode){

    this.showSidebar = true;

    this.selectedUiReportObject = object;

    if (this.uiTabIndex) {
    this.handleLoadSideBarMetaInfo(this.uiTabIndex);
    } else {
      this.uiTabIndex = 0;
    }
  }

  handleLoadSideBarMetaInfo(event: number){

    this.uiTabIndex = event;
    if (this.uiTabIndex == 2) {
      this.onGetMetaInfo(this.selectedUiReportObject,'attributes');
    } else if (this.uiTabIndex == 3) {
      this.onGetMetaInfo(this.selectedUiReportObject,'joins');
    }

  }

  onResetJoins() {
    this.selectedProduct =  null;
  }

}

