import { TeAutosaveCookieDto } from "../dto/te-autosave-cookie.dto";

export class CookieAutosave {


    constructor() {
        //TODO: expires in seconds if not passed then default
    }

    setCookie(editorContentObject: TeAutosaveCookieDto, useUniqueId?: string, expires?: number): void {
        const date = new Date();
        date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
        const expiresString = "expires=" + date.toUTCString();
        // if you add colon then needs to change from other side while passing to get cookie
        // const uniqueId = (!useUniqueId ? useUniqueId : editorContentObject.alias+ ':' +editorContentObject.id);
        const uniqueId = (!useUniqueId ? useUniqueId : editorContentObject.alias + editorContentObject.id);

        // convert object to string
        editorContentObject['timeStamp'] = Math.round(date.getTime() / 1000);
        editorContentObject.content = encodeURIComponent(editorContentObject.content);
        const objectToString = JSON.stringify(editorContentObject);
        const cookie = `${encodeURIComponent(uniqueId)}=${objectToString};${expiresString};path=/`;
        document.cookie = cookie;
        // console.log('Content saved into cookie class', cookie);
    }

    getCookie(name: string) {
        // const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                const cookieValue = cookie.substring(name.length + 1);
                const stringToObject = JSON.parse(cookieValue);
                stringToObject.content = decodeURIComponent(stringToObject.content);
                // console.log(stringToObject);
                return stringToObject;
            }
        }
        return null;
    }

    deleteCookie(name: string): void {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}
