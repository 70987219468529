<ng-container *ngIf="initialized">

<!--        <div class="flex gap-4 m-5">-->
<!--            <span (click)="handleRegistrationStatusFilter(true)" class="cursor" [ngClass]="isPending ? 'text-primary':''">Pending</span> | <span-->
<!--                (click)="handleRegistrationStatusFilter(false)" class="cursor" [ngClass]="!isPending ? 'text-primary':''">Complete</span>-->
<!--        </div>-->

        <mvs-widget
                *ngIf="userRegistrationWidget"
                [widgetData]="userRegistrationWidget"
        >

            <ng-template
                let-object="object"
                mvs-widget-custom-field-directive
                area="columns"
                label="Action"
                fieldId="showButton">
                <div class="flex gap-2">
                    <p-button
                            icon="fa fa-eye"
                            styleClass="small-icon"
                            (click)="handleRegistration(object)"
                    ></p-button>
                </div>
            </ng-template>

        </mvs-widget>

</ng-container>