import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WfProcessStepActivityDto} from "../../dto/wf-process-step-activity.dto";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {MvsFormFieldChange} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-wf-process-show-all-processed-activities',
    templateUrl: './wf-process-show-all-processed-activities.component.html',
})
export class WfProcessShowAllProcessedActivitiesComponent implements OnInit {

    @Input() dto: WfProcessDto;
    allOptionalActivityForStep: WfProcessStepActivityDto[] = [];

    @Input() visible: boolean = false;

    @Output() dialogBoxVisibility = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
        // this.handleAllOptionalActivities();
    }

    handleAllOptionalActivities() {
        let allOptionalActivities: WfProcessStepActivityDto[] = [];
        for (const step of this.dto.steps) {
            if (step.activities) {
                for (const activity of step.activities) {
                    allOptionalActivities.push(activity);
                }
            }
        }
        this.allOptionalActivityForStep = allOptionalActivities;
    }

    showDialog() {
        this.dialogBoxVisibility.emit(this.visible);
    }
}
