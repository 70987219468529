import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseComponent,
    ObjectBaseModeComponent,
    ObjectRequestList,
    WidgetData
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'jb-job-run-base',
    template: '',
})
export class JbJobRunBaseComponent extends ObjectBaseModeComponent implements OnInit {

    jobRunDataWidget: WidgetData;
    jobRunLogEntryTableWidget: WidgetData;


    ngOnInit() {


        this.activeNavigationItem = this.navigationItems[0];
        this.onNavigationItems.emit(this.navigationItems);
        super.ngOnInit();

        this.refreshWidgets();

    }

    refreshWidgets() {
        this.refreshJobRunDataWidget();
        this.refreshJobRunLogEntryTableWidget();
    }

    refreshJobRunDataWidget() {
        this.jobRunDataWidget = WidgetFactory.createWidgetEntityData(
            'jb.job.run.data.widget',
            'Job Run',
            'jb.JbJobRun',
            this.objectIdentifier.objectId
        )
    }


    refreshJobRunLogEntryTableWidget() {

        const filterCriteria = FilterCriteria.create('jobRun', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);
        const objectRequests = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.jobRunLogEntryTableWidget = WidgetFactory.createWidgetTableEntity(
            'jb.job.run.entry.log.widget',
            'Job Run Entry Log',
            'jb.JbJobRunLogEntry',
            'No Entries',
            objectRequests
        )
    }
}
