<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-3">
            <div class="flex flex-column gap-2">
                <mvs-search-dropdown
                        [objectTypeAlias]="['am.Agent']"
                        [selectedObject]="selectedObject"
                        (onSelect)="handleAgentSelect($event)"
                ></mvs-search-dropdown>

                <p-tree
                        class="w-full"
                        selectionMode="single"
                        [value]="agentPoolTree"
                        (onNodeExpand)="handleNodeExpand($event)"
                        (onNodeSelect)="handleNodeSelect($event)"
                        [(selection)]="selectedNode"
                ></p-tree>
            </div>
        </div>

        <div class="col-6">
            <am-agent-info-detail-object
                    *ngIf="objectIdentifier && objectIdentifier.objectType=='am.Agent'"
                    [objectIdentifier]="objectIdentifier"
            ></am-agent-info-detail-object>

            <am-agent-pool-info-detail-object
                    *ngIf="objectIdentifier && objectIdentifier.objectType=='am.AgentPool'"
                    [agentPoolId]="objectIdentifier.objectId"
            ></am-agent-pool-info-detail-object>
        </div>

        <div class="col-3">
            <mvs-ns-notification-types-for-object
                    *ngIf="objectIdentifier"
                    [objectIdentifier]="objectIdentifier"
                    [mode]="0"
                    class="mvs-widget block"
            ></mvs-ns-notification-types-for-object>
        </div>
    </div>
</ng-container>