<ng-container *ngIf="initialized">

    <p-table [value]="staffMembers.value" [tableStyle]="{ 'min-width': '50rem' }" styleClass="text-sm">
        <ng-template pTemplate="header">
            <tr>
                <th colspan="3">Zugeordnete Personen</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>eMail</th>
                <th>ID</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-staffMember>
            <tr>
                <td>{{ staffMember.displayName }}</td>
                <td>{{ staffMember.emailAddress }}</td>
                <td>{{ staffMember.id }}</td>
            </tr>
        </ng-template>
    </p-table>

</ng-container>
