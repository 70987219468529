import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {CustomerFormTypeDto} from "../../../dto/customer-form-type.dto";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'mvs-cr-customer-config-page',
  templateUrl: './cr-form-config-page.component.html',
  styleUrls: ['./cr-form-config-page.component.scss']
})
export class CrFormConfigPage extends PageComponent implements OnInit {

  defaultLabel: string = "Customer Form";

  widgetCustomerFormGroup: WidgetData;
  widgetCustomerFormType: WidgetData;
  widgetCustomerFormTypeField: WidgetData;
  selectedCustomerFormType: CustomerFormTypeDto;

  navigationEntries: MenuItem[];

  activeIndex: number = 0;

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
  }

  initComponent(): void {

    this.navigationEntries = [
      {label: 'Feld Typen', url: 'cc/config'},
    ];

    this.widgetCustomerFormGroup = WidgetFactory.createWidgetList(
        "cr.form.config.form.group",
        "FormularGruppen",
        "list",
        "list",
        "entity",
        "cr.CustomerFormGroup",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetCustomerFormType = WidgetFactory.createWidgetList(
        "cr.form.config.form.type",
        "Formulartypen",
        "list",
        "list",
        "entity",
        "cr.CustomerFormType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.initialized = true;

  }

  onFormTypeObjectSelect(event: ObjectIdentifierData) {
    this.selectedCustomerFormType = event.data;
    this.refreshFormFields();
  }

  refreshFormFields() {

    this.widgetCustomerFormTypeField = WidgetFactory.createWidgetList(
        "cr.form.config.form.type.field",
        "Zugewiesene Felder",
        "list",
        "list",
        "entity",
        "cr.CustomerFormTypeField",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(
            true,
            [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, this.selectedCustomerFormType.id)],
            [new Sorting("id", false)]
        )
    );

  }


}
