import {Injectable} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    WfProcessStepActivitySideGenericComponent
} from "../../component/process-activity-side/wf-process-step-activity-side-generic/wf-process-step-activity-side-generic.component";
import {WfProcessTypeStepActivityDto} from "../../dto/wf-process-type-step-activity.dto";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {
    WfProcessStepActivityObjectComponent
} from "../../component/process-activity/wf-process-step-activity-object/wf-process-step-activity-object.component";
import {WfActivityTypeEnum} from "../../enum/wf-activity-type.enum";
import {
    WfProcessStepActivitySubProcessObjectComponent
} from "../../component/process-activity/wf-process-step-activity-sub-process-object/wf-process-step-activity-sub-process-object.component";
import {
    WfProcessStepActivityChooseNextStepComponent
} from "../../component/process-activity/wf-process-step-activity-choose-next-step/wf-process-step-activity-choose-next-step.component";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";
import {WfActivityRefreshType} from "../../enum/wf-activity-refresh-type.enum";

@Injectable({
    providedIn: 'root'
})
export class WfProcessTypeStepActivityService extends MvsCrudService {

    static objectIdentifier: ObjectIdentifier;


    constructor(
        protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/wf/processTypeStepActivities')
    }

    /**
     * Retrieve component that should be used for display.
     * @param crudService
     * @param coreService
     * @param activityType
     */
    public getActivitySideComponent(crudService: MvsCrudService, coreService: MvsCoreService, activityType: WfProcessTypeStepActivityDto): any {


        switch (activityType.activityType) {
            case WfActivityTypeEnum.object:
            case WfActivityTypeEnum.select:
                return WfProcessStepActivitySideGenericComponent;
            default:
                return WfProcessStepActivitySideGenericComponent;
        }

    }


    /**
     * Retrieve component that should be used for display.
     * @param crudService
     * @param coreService
     * @param activityType
     */
    public getActivityComponent(crudService: MvsCrudService, coreService: MvsCoreService, activityType: WfProcessTypeStepActivityDto): any {


        switch (activityType.activityType) {
            case WfActivityTypeEnum.object:
            case WfActivityTypeEnum.select:
                return this.getActivityForSteps(activityType);

            case WfActivityTypeEnum.notification:
                return this.getComponentForActivityType(crudService, coreService, 'ns.NsNotification', activityType);

            case WfActivityTypeEnum.start_process:
                return this.getComponentForActivityType(crudService, coreService, '', activityType);

            case WfActivityTypeEnum.create_ticket:
                return this.getComponentForActivityType(crudService, coreService, 'tm.Ticket', activityType);

            case WfActivityTypeEnum.choose_next_step:
                return this.getComponentForActivityType(crudService, coreService, '', activityType);

            case WfActivityTypeEnum.document_upload_and_list:
                return this.getComponentForActivityType(crudService, coreService, 'dm.DmDocument', activityType);

            case WfActivityTypeEnum.ticket_status:
                return this.getComponentForActivityType(crudService, coreService, 'tm.Ticket', activityType);
            default:
                return this.getActivityForSteps(activityType);
        }

    }

    /**
     * Retrieve component that should be used for display.
     * @param crudService
     * @param coreService
     * @param objectAlias
     * @param activityType
     */
    getComponentForActivityType(crudService: MvsCrudService, coreService: MvsCoreService, objectAlias: string, activityType: WfProcessTypeStepActivityDto): any {
        try {
            crudService = coreService.getCrudService(objectAlias);
            // check whether the service has a Side Component implementation
            return crudService["getActivityMainComponent"](activityType);
        } catch (e) {
            return this.getActivityForSteps(activityType);
        }
    }


    /**
     * Retrieve component that should be used for display.
     * @param activityForStep
     */
    getActivityForSteps(activityForStep: WfProcessTypeStepActivityDto) {

        switch (activityForStep.activityType) {
            // Object
            case WfActivityTypeEnum.object:
                return WfProcessStepActivityObjectComponent;

            case WfActivityTypeEnum.select:
                return WfProcessStepActivityObjectComponent;

            // Start Sub Process
            case WfActivityTypeEnum.start_process:
                return WfProcessStepActivitySubProcessObjectComponent;

            // Choose next step
            case WfActivityTypeEnum.choose_next_step:
                return WfProcessStepActivityChooseNextStepComponent;
        }
        return WfProcessStepActivityObjectComponent;
    }


    refreshStepActivityMultiSelectWidgetTable(activityType:WfProcessTypeStepActivityDto,objectTypeAlias:string,filterCriteriaList: FilterCriteria[]) {

        return WidgetFactory.createWidgetListRefresh(
            'wf.processed.steps.widget.' + objectTypeAlias,
            "Erfasste Datensätze",
            "table",
            "list",
            "entity",
            objectTypeAlias,
            "No Workflows found!",
            ObjectRequestList.createWithPaging(
                true,
                filterCriteriaList,
                [new Sorting("createdDate", false)],
                PagingDto.create(0, 5)
            ),
            this.handleRefresh(activityType)
        );

    }

    private handleRefresh(activityType:WfProcessTypeStepActivityDto): WidgetDataRefresh {
        if (activityType.refreshType == WfActivityRefreshType.available) {
            return WidgetDataRefresh.createSimple();
        } else if (activityType.refreshType == WfActivityRefreshType.auto || activityType.refreshType == WfActivityRefreshType.available_auto) {

            let displayRefresh = false;

            if (activityType.refreshType == WfActivityRefreshType.available_auto) {
                displayRefresh = true;
            }

            return WidgetDataRefresh.createAutoRefresh(displayRefresh, 5, 20);
        }
        return null;
    }

}