<div class="grand-parent">
    <div class="parent" #slider>
        <ng-container *ngIf="initialized && dto ">

            <div class="wf-top-navigation shadow-1">
                <div class="col-12">
                    <p-toolbar>

                        <div class="flex justify-content-between align-items-center w-full">
                            <div class="pl-8 wf-process-title">

                                <div class="flex align-items-center gap-4">
                                    <span>{{ dto.name }}</span>
                                    <!--                                    <span pButton class="flex align-items-center gap-2 p-button-text"-->
                                    <!--                                          (click)="menu.toggle($event)">-->
                                    <!--                             <i class="fa-regular fa-ellipsis"></i>-->
                                    <!--                        </span>-->
                                </div>

                            </div>
                            <div class="p-toolbar-group-end gap-4 pr-2">

                                <p-button
                                        styleClass="p-button-success"
                                        [icon]="showProcessStart ? 'fa-regular fa-xmark' : 'fa-solid fa-play'"
                                        (onClick)="showProcessStart = !showProcessStart"
                                        [disabled]="busy"
                                ></p-button>

                                <p-button icon="fa-solid fa-search" label="Show Process"
                                          (click)="onShowProcess()" [disabled]="busy"></p-button>

                                <p-button *ngIf="!dto.steps || !dto.steps.length" icon="fa-solid fa-plus"
                                          label="Create Step"
                                          (click)="onCreateStep()" [disabled]="busy"></p-button>

                                <p-button
                                        icon="fa-solid fa-plus"
                                        label="Create Fields"
                                        [badge]="this.dto?.fields?.length.toString()" styleClass="mr-2"
                                        (click)="onCreateFields('Field')" [disabled]="busy"></p-button>

                                <p-button
                                        icon="fa-solid fa-plus"
                                        label="Process Result"
                                        [badge]="this.dto?.results?.length.toString()"
                                        (click)="onCreateFields('Result')" [disabled]="busy"></p-button>

                                <p-inputSwitch [(ngModel)]="showRuntimeInfo" *ngIf="processSteps"
                                               (onChange)="processRuntimeInfo()"></p-inputSwitch>


                            </div>
                        </div>


                    </p-toolbar>
                </div>

            </div>

            <div class="child dotted-background">

                <div class="grid">
                    <div class="col-12 mt-5" *ngIf="showProcessStart">
                        <div style="width: 95vw">
                            <mvs-wf-process-start
                                    *ngIf="showProcessStart"
                                    [processTypeId]="dto.id"
                                    [onStartNavigate]="true"
                                    (onProcessCreated)="showProcessStart = false"
                                    [heading]="false"
                            ></mvs-wf-process-start>
                        </div>
                    </div>
                    <div class="col-12">

                        <div class="main-wf-section">
                            <div class="wf-section-view">
                                <div class="flex"
                                     *ngIf="!activityDragStarted"
                                     (mouseleave)="activityDragStarted = false"
                                     cdkDropList
                                     cdkDropListOrientation="horizontal"
                                     [cdkDropListData]="processSteps"
                                     [cdkDropListConnectedTo]="['processStepDropList']"
                                     (cdkDropListDropped)="drop($event)">

                                    <ng-container *ngFor="let step of processSteps; let idx= index; let last = last">

                                        <div class="wf-add-step-hov">
                                            <p-button icon="fa-solid fa-plus" class="wf-add-step-button"
                                                      pTooltip="Create Step"
                                                      tooltipPosition="bottom"
                                                      (click)="onCreateStepInBetween(processSteps,idx)"></p-button>
                                        </div>

                                        <div class="wf-step-card"
                                             [ngClass]="{'wf-hover-section': wfHoverStep === step.name}"
                                             cdkDrag>

                                            <ng-container [ngTemplateOutlet]="stepItems"
                                                          [ngTemplateOutletContext]="{step:step, idx:idx, last:last}"></ng-container>
                                        </div>

                                        <div
                                                *ngIf="last"
                                                class="wf-step-card cursor-pointer ml-6"
                                                (click)="onCreateStepInBetween(processSteps,idx+1)">
                                            <div class="flex justify-content-center align-items-center w-full gap-2">

                                                <!--                                                <p-button icon="fa-light fa-plus"-->
                                                <!--                                                          styleClass="p-button-rounded"></p-button>-->
                                                <div class="wf-process-config-create-last">
                                                    <i class="fa-light fa-plus"></i>
                                                </div>

                                                <span>Create New Step</span>
                                            </div>
                                        </div>


                                    </ng-container>

                                </div>

                                <div *ngIf="activityDragStarted"
                                     (mouseleave)="activityDragStarted = false"
                                     class="flex"
                                >

                                    <ng-container *ngFor="let step of processSteps; let idx= index; let last = last">

                                        <div class="wf-add-step-hov">
                                            <p-button icon="fa-solid fa-plus" class="wf-add-step-button"
                                                      pTooltip="Create Step"
                                                      tooltipPosition="bottom"
                                                      (click)="onCreateStepInBetween(processSteps,idx)"></p-button>
                                        </div>

                                        <div class="wf-step-card"
                                             [ngClass]="{'wf-hover-section': wfHoverStep === step.name}"
                                             cdkDrag>

                                            <ng-container [ngTemplateOutlet]="stepItems"
                                                          [ngTemplateOutletContext]="{step:step, idx:idx, last:last}"></ng-container>
                                        </div>


                                        <div class="wf-step-card cursor-pointer ml-6" *ngIf="last">
                                            <div class="flex justify-content-center align-items-center w-full gap-2"
                                                 (click)="onCreateStepInBetween(processSteps,idx+1)">
                                                <!--                                                <p-button icon="fa-light fa-plus"-->
                                                <!--                                                          styleClass="p-button-rounded"></p-button>-->
                                                <div class="wf-process-config-create-last">
                                                    <i class="fa-light fa-plus"></i>
                                                </div>
                                                <span>Create New Step</span>
                                            </div>
                                        </div>


                                    </ng-container>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </ng-container>

    </div>
</div>

<!-- ******************* dialog box ******************* -->

<p-dialog
        [header]="dialogHeaderLabel"
        [(visible)]="visible"
        [style]="{width: '60vw', height: '80vh'}"
        (onHide)="onDialogHide($event)"
>

    <ng-container
            *ngIf="
                    (tabName != 'Activity' && !selectedObject) ||
                    (tabName != 'Activity' && selectedObject) ||
                    (tabName == 'Activity' && !selectedObject)"
            [ngTemplateOutlet]="formObjectTemplate"
    >
    </ng-container>

    <p-tabView
            *ngIf="tabName == 'Activity' && selectedObject"
            (onChange)="changeTab($event)">
        <p-tabPanel [header]="tabName">
            <ng-container
                    [ngTemplateOutlet]="formObjectTemplate"
            >
            </ng-container>
        </p-tabPanel>
        <p-tabPanel *ngIf="tabName == 'Activity' && selectedObject" header="Form Defaults">
            <div class="grid">

                <div class="col-4">
                    <div class="mvs-widget">

                        <!-- ******************* list ******************* -->
                        <mvs-widget
                                *ngIf="widgetDefaultFieldsTable"
                                [widgetData]="widgetDefaultFieldsTable"
                                (onObjectSelect)="onDefaultFieldSelect($event)"
                        ></mvs-widget>
                    </div>
                </div>

                <div class="col-8">
                    <div *ngIf="stepActivityFormDefaultId">
                        <p-button
                                label="New"
                                (click)="stepActivityFormDefaultId = 0;resetForm()"
                        ></p-button>
                        <br>
                    </div>


                    <!-- ******************* form ******************* -->

                    <mvs-widget *ngIf="objectFormWidget && this.tabIndex == 1"
                                [widgetData]="objectFormWidget"
                                (onChangedObject)="onChangeObject($event)"

                    >
                    </mvs-widget>
                </div>

            </div>
        </p-tabPanel>

        <!-- ******************* binding ******************* -->

        <p-tabPanel *ngIf="tabName == 'Activity' && selectedObject" header="Binding">
            <div class="grid">

                <div class="col-4">
                    <div class="mvs-widget">

                        <!-- ******************* list ******************* -->

                        <mvs-widget
                                *ngIf="widgetBindingFieldsTable"
                                [widgetData]="widgetBindingFieldsTable"
                                (onObjectSelect)="onBindingSelect($event)"
                        ></mvs-widget>
                    </div>
                </div>

                <div class="col-8">
                    <div *ngIf="stepActivityBindingId">

                        <p-button
                                label="New"
                                (click)="stepActivityBindingId = 0;resetForm()"
                        ></p-button>
                        <br>
                    </div>

                    <!-- ******************* form ******************* -->

                    <mvs-widget *ngIf="objectFormWidget && this.tabIndex == 2"
                                [widgetData]="objectFormWidget"
                                (onChangedObject)="onChangeObject($event)"
                    >
                    </mvs-widget>

                </div>

            </div>
        </p-tabPanel>
    </p-tabView>

</p-dialog>


<ng-template #formObjectTemplate>
    <div class="grid">

        <div class="col-4" *ngIf="widgetFieldsTable">
            <div class="mvs-widget">

                <!-- ******************* list ******************* -->

                <mvs-widget
                        *ngIf="widgetFieldsTable"
                        [widgetData]="widgetFieldsTable"
                        (onObjectSelect)="onFieldSelect($event)"
                ></mvs-widget>
            </div>
        </div>

        <div [ngClass]="widgetFieldsTable ? 'col-8' : 'col-12'">
            <div *ngIf="selectObjectIdentifier.objectType == 'wf.WfProcessTypeField' && selectObjectIdentifier.objectId">

                <p-button
                        label="New"
                        (click)="onCreateFields('Field')"
                ></p-button>
                <br>
            </div>

            <div *ngIf="selectObjectIdentifier.objectType == 'wf.WfProcessTypeResult' && selectObjectIdentifier.objectId">

                <p-button
                        label="New"
                        (click)="onCreateFields('Result')"
                ></p-button>
                <br>
            </div>

            <!-- ******************* form ******************* -->

            <mvs-widget *ngIf="objectFormWidget"
                        [widgetData]="objectFormWidget"
                        (onChangedObject)="onChangeObject($event)"
            >
            </mvs-widget>

        </div>

    </div>
</ng-template>

<p-slideMenu
    #menu
    [model]="configurationItems"
    [popup]="true"
    class="wf-slide-menu"
    [viewportHeight]="160"
    [menuWidth]="175"
>
</p-slideMenu>

<p-slideMenu
    #nextStepMenu
    [model]="nextStepsConfigurationItems"
    [popup]="true"
    class="wf-slide-menu"
    [viewportHeight]="160"
    [menuWidth]="175"
    (onHide)="isActivityStepsOverlay=false"
>
</p-slideMenu>

<ng-template #stepItems let-step='step' , let-idx='idx' , let-last='last'>


    <!-- Container for displaying workflow steps -->
    <ng-container>
        <div class="w-full flex flex-column gap-3"
             cdkDropList
             [cdkDropListSortingDisabled]="true"
             [cdkDropListData]="step.nextSteps"
             [id]="'stepDropList_' + idx"
             (cdkDropListDropped)="onDrop($event, step)"
             [cdkDropListConnectedTo]="getStepConnectedLists()"
        >

            <!-- Display each workflow step -->

            <div class="w-full"
                 (mouseenter)="activityDragStarted = false">
                <div class="flex justify-content-between align-items-center w-full">
                    <div class="flex align-items-center gap-2">

                        <!-- Display step index badge -->

                        <p-badge [value]="(idx + 1).toString()"></p-badge>
                        <div class="flex flex-column">

                            <!-- Display step name -->

                            <span class="pointer text-left step-name-font"
                                  (click)="onStepSelect(step)"
                                  [class]="selectedObject?.id == step.id ? selectedObject['_class'] : ''">
                                                    {{ step.name }}</span>
                        </div>
                    </div>

                    <!-- Display ellipsis icon for additional options -->

                    <i class="fa-regular fa-ellipsis pointer"
                       (click)="menu.toggle($event); selectedStep = step"></i>
                </div>
                <div [style]="{'padding-left':'27px'}">

                    <!-- Display workflow step flow type -->

                    <span class="text-left wf-process-config-flow-type-font"> {{ flowType[step.flowType] }} </span>
                </div>
            </div>

            <!--************************ ACTIVITIES ************************-->

            <!-- Activities section within the workflow step -->

            <ng-container>
                <div class="w-full flex flex-column gap-2"
                     cdkDropList
                     [cdkDropListSortingDisabled]="true"
                     [cdkDropListData]="step.activities"
                     [id]="'activityDropList_' + idx"
                     (cdkDropListDropped)="onDrop($event, step)"
                     [cdkDropListConnectedTo]="getActivityConnectedLists()">

                    <!-- Display each activity within the workflow step -->

                    <ng-container *ngFor="let activity of step.activities">

                        <!--************************ MAIN ACTIVITY ************************-->
                        <ng-container *ngIf="activity.processing == 1 || activity.processing == 2">

                            <div class="wf-step-box relative hover:surface-hover"
                                 (mouseenter)="activityDragStarted = true"
                                 cdkDrag
                                 [cdkDragData]="activity"
                                 (cdkDragStarted)="onDragActivityStarted($event, activity)"
                                 (cdkDragEntered)="onActivityDragEntered($event)"
                                 [class]="selectedObject?.id == activity.id ? selectedObject['_class'] : ''"
                                 (click)="onActivitySelect(activity,step)">
                                <!--                                            <div class="flex flex-column align-items-start">-->

                                <div class="flex w-full justify-content-between">
                                    <p-tag
                                            [style]="
                                                                {
                                                                  'display': 'flex',
                                                                  'padding': '4px 8px',
                                                                  'justify-content': 'center',
                                                                  'align-items': 'center',
                                                                  'gap': '4px',
                                                                  'border-radius': '32px',
                                                                  'font-weight': '400'
                                                                }"
                                            [icon]="getActivityImage(activity.activityType)"
                                            severity="success"
                                            [value]="getActivityField(activity.activityType)"
                                            [rounded]="true"></p-tag>

                                    <p-badge *ngIf="showRuntimeInfo" severity="danger"
                                             [value]="activity['processTypeStepActivity_count'] ? activity['processTypeStepActivity_count'] : 0"></p-badge>
                                </div>

                                <div
                                        *ngIf="activity.description"
                                        class="wf-description-ellipses wf-process-config-description-font"
                                        [innerHTML]="activity.description | mvsSavePipe:'html'">
                                </div>

                                <div *ngIf="activity.processing==2"
                                     class="wf-process-config-timer-icon">
                                    <div
                                            class="wf-process-config-timer-icon-wrapper">
                                        <i class="fa-regular fa-layer-group"></i>
                                    </div>
                                </div>

                                <div class="w-full" *ngIf="step.nextSteps && step.nextSteps.length">

                                    <div class="w-full wf-process-config-description-font flex justify-content-between"
                                         *ngIf="!activity.processTypeStepNextDtoId">
                                        <div class="flex gap-2">
                                            <i class="fa-solid fa-angles-right"></i>
                                            <span>Add Next Step</span>
                                        </div>
                                        <i class="fa-regular fa-circle-plus hover:text-primary"
                                           (click)="openStepsMenu(nextStepMenu,step.nextSteps,activity, $event)"></i>
                                    </div>

                                    <div class="wf-process-config-activity-step w-full wf-process-config-description-font"
                                         *ngIf="activity.processTypeStepNextDtoId">
                                        <i class="fa-solid fa-angles-right"></i>
                                        <span>{{ activity.processTypeStepNextDtoName }}</span>
                                        <i class="fa-regular fa-circle-minus hover:text-primary"
                                           (click)="openStepsMenu(nextStepMenu,step.nextSteps,activity, $event, true)"></i>
                                    </div>

                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                    <!-- Display message for missing Main Activity -->

                    <ng-container *ngIf="!handleMainActivityMissing(step.activities)">
                        <div class="wf-step-box wf-selected-red-box hover:surface-hover"
                             (click)="onCreateActivity(step)">
                            <div class="flex justify-content-center align-items-center w-full gap-2">
                                <i class="fa-regular fa-circle-info wf-selected-red-box-icon"></i>
                                <span class="wf-selected-red-box-text">
                                    Missing Main Activity
                                </span>
                            </div>
                        </div>
                    </ng-container>

                    <div class="wf-step-box wf-process-config-wf-step">

                        <ng-container *ngFor="let activity of step.activities">

                            <!--************************ OPTIONAL ACTIVITY ************************-->
                            <ng-container *ngIf="activity.processing == 0 || activity.processing == 3">

                                <div class="w-full">


                                    <div
                                            (mouseenter)="activityDragStarted = true"
                                            cdkDrag
                                            [cdkDragData]="activity"
                                            (cdkDragStarted)="onDragActivityStarted($event, activity)"
                                            (cdkDragEntered)="onActivityDragEntered($event)"
                                            [class]="selectedObject?.id == activity.id ? selectedObject['_class'] : ''"
                                            (click)="onActivitySelect(activity,step)">

                                        <div class="wf-step-box border-dashed relative w-full hover:surface-hover">
                                            <div class="flex w-full justify-content-between">
                                                <p-tag
                                                        [style]="
                                                                {
                                                                  'display': 'flex',
                                                                  'padding': '4px 8px',
                                                                  'justify-content': 'center',
                                                                  'align-items': 'center',
                                                                  'gap': '4px',
                                                                  'border-radius': '32px',
                                                                  'font-weight': '400'
                                                                }"
                                                        [icon]="getActivityImage(activity.activityType)"
                                                        [value]="getActivityField(activity.activityType)"
                                                        [rounded]="true"></p-tag>

                                                <p-badge *ngIf="showRuntimeInfo" severity="danger"
                                                         [value]="activity['processTypeStepActivity_count'] ? activity['processTypeStepActivity_count'] : 0"></p-badge>
                                            </div>

                                            <div
                                                    *ngIf="activity.description"
                                                    class="wf-description-ellipses wf-process-config-description-font"
                                                    [innerHTML]="activity.description | mvsSavePipe:'html'">
                                            </div>

                                            <div *ngIf="activity.processing==3"
                                                 class="flex justify-content-end w-full wf-description-ellipses wf-process-config-description-font">
                                                <div> {{ activityWaitTimeFrequency[activity['waitTimeFrequency']].label }}
                                                    : {{ activity['waitTimeFrequencyAmount'] }}
                                                </div>
                                            </div>

                                            <div *ngIf="activity.processing==3"
                                                 class="wf-process-config-timer-icon">
                                                <div
                                                        class="wf-process-config-timer-icon-wrapper"
                                                        [pTooltip]="activity['waitTimeFrequencyAmount'] + ' ' + activityWaitTimeFrequency[activity['waitTimeFrequency']].label">
                                                    <i class="fa-solid fa-timer"></i>
                                                </div>
                                            </div>

                                            <div class="w-full" *ngIf="step.nextSteps && step.nextSteps.length">
                                                <div class="w-full wf-process-config-description-font flex justify-content-between"
                                                     *ngIf="!activity.processTypeStepNextDtoId">
                                                    <div class="flex gap-2">
                                                        <i class="fa-solid fa-angles-right"></i>
                                                        <span>Add Next Step</span>
                                                    </div>
                                                    <i class="fa-regular fa-circle-plus hover:text-primary"
                                                       (click)="openStepsMenu(nextStepMenu,step.nextSteps,activity, $event)"></i>
                                                </div>

                                                <div class="wf-process-config-activity-step w-full wf-process-config-description-font"
                                                     *ngIf="activity.processTypeStepNextDtoId">
                                                    <i class="fa-solid fa-angles-right"></i>
                                                    <span>{{ activity.processTypeStepNextDtoName }}</span>
                                                    <i class="fa-regular fa-circle-minus hover:text-primary"
                                                       (click)="openStepsMenu(nextStepMenu,step.nextSteps,activity, $event, true)"></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </ng-container>
                        </ng-container>

                        <!-- Display message for missing Optional Activity -->
                        <!--                        *ngIf="!handleOptionalActivityMissing(step.activities)"-->
                        <ng-container>
                            <div class="wf-step-box border-dashed"
                                 [ngClass]="{'wf-process-config-wf-missing-optional':handleOptionalActivityMissing(step.activities)}"
                                 (click)="onCreateActivity(step)">
                                <div class="flex justify-content-center align-items-center gap-2 w-full">
                                    <i class="fa-regular fa-circle-plus hover:text-primary"></i>
                                    <span> Add Optional Activity </span>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </ng-container>


            <!--************************ HINTS ************************-->

            <!-- Display hints associated with the workflow step -->

            <ng-container *ngFor="let hint of step.stepHints">

                <div class="wf-step-box hover:surface-hover"
                     [class]="selectedObject?.id == hint.id ? selectedObject['_class'] : ''"
                     (click)="onHintSelect(hint)">

                    <p-tag
                            [style]="
                                                                {
                                                                  'display': 'flex',
                                                                  'padding': '4px 8px',
                                                                  'justify-content': 'center',
                                                                  'align-items': 'center',
                                                                  'gap': '4px',
                                                                  'border-radius': '32px',
                                                                  'font-weight': '400'
                                                                }"
                            icon="fa-regular fa-circle-info"
                            severity="info"
                            [value]="hint.hintTypeDtoName + ' ' + hint.priority"
                            [rounded]="true"></p-tag>

                    <span
                            *ngIf="hint.description"
                            class="wf-description-ellipses wf-process-config-description-font"
                            [innerHTML]="hint.description | mvsSavePipe:'html'">
                                                </span>

                </div>

            </ng-container>

            <!--************************ STEPS ************************-->

            <!-- Display Next Steps section -->

            <ng-container>

                <div class="wf-step-box">
                    <div class="flex justify-content-between align-items-center w-full">
                        <div class="flex gap-2">
                            <i class="fa-solid fa-angles-right"></i>
                            <span class="wf-process-config-next-step-font">Next Steps</span>
                        </div>
                        <div class="flex gap-3 align-items-center">
                            <i class="fa-light fa-circle-plus hover:text-primary"
                               (click)="createNextStep(step)"
                               pTooltip="Click to add next step"
                               tooltipPosition="top"></i>

                            <div
                                    (mouseenter)="stepDragStarted = true; activityDragStarted = true"
                                    cdkDrag
                                    [cdkDragData]="step"
                                    (cdkDragStarted)="onDragNextStepStarted($event, step)"
                                    (cdkDragEntered)="onStepDragEntered($event)"
                                    pTooltip="drag and drop on next step"
                                    tooltipPosition="top">
                                <i class="fa-regular fa-up-down-left-right hover:text-primary"></i>
                                <!--                            <i class="fa-light fa-circle-plus hover:text-primary"></i>-->
                            </div>
                        </div>
                    </div>

                    <!-- Display each Next Step -->

                    <ng-container *ngFor="let nextStep of step.nextSteps">

                        <div class="wf-step-box p-2 border-dashed hover:surface-hover"
                             [class]="selectedObject?.id == nextStep.id ? selectedObject['_class'] : ''"
                             (mouseover)="onParentWfHover(nextStep.nextProcessTypeStepDtoName)"
                             (mouseout)=" clearHoveredWf()"
                             (click)="onNextStepSelect(nextStep)">

                            <div class="w-full flex justify-content-center">
                                <span class="next-step-font"
                                      *ngIf="nextStep?.nextProcessTypeStepDtoName">{{ nextStep?.nextProcessTypeStepDtoName }}</span>
                                <span class="next-step-font" *ngIf="!nextStep?.nextProcessTypeStepDtoName"><i
                                        class="fa-solid fa-flag-checkered"></i></span>
                            </div>

                        </div>

                    </ng-container>

                </div>

            </ng-container>

            <!-- Display runtime info badge -->

            <div *ngIf="showRuntimeInfo">

                <p-badge *ngIf="showRuntimeInfo" severity="danger"
                         [value]="step['processStepsCount'] ? step['processStepsCount'] : 0"></p-badge>
            </div>


        </div>
    </ng-container>

</ng-template>
