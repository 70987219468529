import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {CfConfigPage} from "./page/overview/cf-config/cf-config.page";


@NgModule({
    declarations: [
        CfConfigPage,

    ],
    exports: [
        CfConfigPage,

    ],
    imports: [
        CoreModule
    ]
})
export class CfModule { }
