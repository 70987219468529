<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-3">
            <mvs-widget
                    *ngIf="widgetTicketStatus"
                    [widgetData]="widgetTicketStatus"
                    (onObjectSelect)="handleTicketStatusSelect($event)"
            ></mvs-widget>
        </div>

        <div class="col-9">
            <mvs-widget
                    *ngIf="widgetTicket"
                    [widgetData]="widgetTicket"
            ></mvs-widget>
        </div>

    </div>

</ng-container>
