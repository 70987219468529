import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {WfProcessTypeService} from "../../service/api/wf-process-type.service";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WfProcessService} from "../../service/api/wf-process.service";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {WfProcessTypeFieldDto} from "../../dto/wf-process-type-field.dto";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {WfFieldProcessingTypeEnum} from "../../enum/wf-field-processing-type.enum";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetGenericEvent
} from "@kvers/alpha-ui";
import {MvsFormGroup} from "@kvers/alpha-core-common";
import {MvsWidgetFormGenericEventConstants} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-wf-process-start',
    templateUrl: './wf-process-start.component.html',
    styleUrls: ['./wf-process-start.component.scss']
})
export class WfProcessStartComponent implements OnInit {

    // limit available process types via group
    @Input() processGroupId: number;
    @Input() processTypeId: number;
    @Input() objectsIdentifiers: ObjectIdentifier[];
    @Input() importContext: DtoImportObjectContext;
    @Input() onStartNavigate: boolean;
    @Input() heading: boolean = true;

    @Output() onProcessCreated: EventEmitter<WfProcessDto> = new EventEmitter<WfProcessDto>();

    selectedProcessType: WfProcessTypeDto;
    processTypes: WfProcessTypeDto[];
    fields: WfProcessTypeFieldDto[];
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    enableButton: boolean;

    formWidget: WidgetData;

    importFieldData: any;


    constructor(
        protected navigationService: MvsObjectNavigationService,
        protected wfProcessTypeService: WfProcessTypeService,
        protected wfProcessService: WfProcessService,
        protected objectTypeService: ObjectTypeService) {

    }

    ngOnInit(): void {
        this.refreshComponent();
    }


    /**
     * Handle form events.
     * @param event
     */
    handleGenericEvent(event: WidgetGenericEvent) {

        if (event.eventName == MvsWidgetFormGenericEventConstants.EVENT_FORM_VALUE_CHANGE) {

            const formGroup: MvsFormGroup = event.object[MvsWidgetFormGenericEventConstants.EVENT_FORM_VALUE_CHANGE_ATTR_FORM_GROUP];

            if (formGroup.status == "VALID") {
                this.importFieldData = formGroup.value;
                this.enableButton = true;
            } else {
                this.importFieldData = null;
            }
        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.busy = true;

        // reset the import field data
        this.importFieldData = null;

        const filterCriteria: FilterCriteria[] = [];

        if (this.processGroupId) {
            filterCriteria.push(
                FilterCriteria.create("processGroup", FilterCriteria.cOperatorGreaterEqual, this.processGroupId));
        } else if (this.processTypeId) {
            this.onProcessSelect(this.processTypeId);
            filterCriteria.push(
                FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.processTypeId));
        }

        const objectRequestList: ObjectRequestList = ObjectRequestList.createBasic(
            true,
            filterCriteria,
            null)

        // retrieve list of process types which are relevant

        this.wfProcessTypeService.list(objectRequestList).subscribe(dtoList => {
            this.processTypes = dtoList.entries;
            this.busy = false;
            this.initialized = true;
        });

    }

    /**
     * Start the process.
     */
    onProcessStart() {
        this.busy = true;
        this.wfProcessService.createNewProcess(this.selectedProcessType).subscribe(value => {
            this.onProcessCreated.emit(value);
            this.busy = false;
        });

    }


    /**
     * Start the process with imports.
     */
    onProcessStartWithImports() {

        // check if already in progress
        if (this.busy) {
            return;
        }

        // make sure that import field data was provided
        // if (this.fields && !this.importFieldData) {
        //     return;
        // }

        this.busy = true;

        const processDto = new WfProcessDto();

        if (this.importFieldData) {
            for (let importField in this.importFieldData) {
                processDto["gen_" + importField] = this.importFieldData[importField];
            }
        }

        this.wfProcessService.start(this.selectedProcessType, processDto).subscribe(value => {
            this.onProcessCreated.emit(value);
            this.busy = false;

            if (this.onStartNavigate) {
                const navigationObject = MvsObjectNavigationEntry.createNavigationEntry('wf.WfProcess', value.id, 'object', null, null, null, MvsObjectNavigationActionEnum.edit, 'light');
                this.navigationService.openObjectInNewTab(navigationObject);
            }
        },error => {
            this.busy = false;
        });

    }

    onProcessSelect(id: number) {
        this.fields = [];
        this.enableButton = false;
        this.wfProcessTypeService.getFull(id).subscribe(res => {

            this.selectedProcessType = res;
            this.setupForm(res.fields);

        });
    }

    /**
     * Setup Form.
     * @param fields
     */
    async setupForm(fields: WfProcessTypeFieldDto[]) {

        let foundFields: boolean = false;
        const importFields: MvsFormFieldDto[] = [];

        if (fields && fields.length > 0) {

            for (let field of fields) {

                if (field.fieldProcessingType != WfFieldProcessingTypeEnum.IMPORT) {
                    continue;
                }

                const importField: MvsFormFieldDto = new MvsFormFieldDto();
                importField.id = field.id + "DtoId";

                const objectTypeDto = await this.objectTypeService.getViaObjectId(field.fieldTypeDto.dataTypeObjectTypeDtoId);
                importField.dataType = objectTypeDto.alias;
                importField.access = 'update';
                importField.uiLabel = field.name;
                importField.relationship = 'ManyToOne'

                if (field.mandatory) {
                    importField.uiMandatory = true;
                }

                importFields[importField.id] = importField;

                foundFields = true;
            }

        }

        // check whether no import parameters exist
        if (!foundFields) {
            this.enableButton = true;
            return;
        }

        const mvsFormDto = new MvsFormDto();
        mvsFormDto.formFields = importFields;

        this.formWidget = null;
        this.formWidget = new WidgetData();
        this.formWidget.name = "Start Parameter";
        this.formWidget.idAlias = 'wf.start.process.' + this.selectedProcessType.id;

        this.formWidget.uiComponent = 'form';
        this.formWidget.dataProvider = 'transient';
        this.formWidget.dataSource = 'transient';
        this.formWidget.dataProviderObject = 'cr.Customer';

        this.formWidget.setParamValue("size", "S");
        this.formWidget.setParamValue("form", mvsFormDto);

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["processTypeId"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
