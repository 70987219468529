import {Route, Routes} from "@angular/router";
import {EmployeePage} from "./page/overview/employee/employee.page";
import {OrganizationUnitPage} from "./page/overview/organization-unit/organization-unit.page";
import {DepartmentPage} from "./page/overview/department/department.page";

export function OmSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'om/employees', component: EmployeePage},
        {path: 'om/organizationUnits', component: OrganizationUnitPage},
        {path: 'om/departments', component: DepartmentPage},

    ];

    return routes;

}
