<ng-container *ngIf="initialized">


    <div class="grid">

        <div class="col-5 flex flex-column gap-2">

            <div class="mvs-widget">
                <mvs-widget *ngIf="openTicketsWidgetData"
                            [widgetData]="openTicketsWidgetData"
                ></mvs-widget>
            </div>

        </div>

                <div class="col-4 flex flex-column gap-2">

                    <div class="mvs-widget">
                        <mvs-widget *ngIf="widgetCalls"
                                    [widgetData]="widgetCalls"
                        ></mvs-widget>
                    </div>

                </div>

        <div class="col-3 flex flex-column gap-2">

            <div class="mvs-widget">
                <mvs-widget *ngIf="openWorkflowsWidgetData"
                            [widgetData]="openWorkflowsWidgetData"
                ></mvs-widget>
            </div>

            <div class="mvs-widget">
                <mvs-widget *ngIf="assignedWorkFlowsWidgetData"
                            [widgetData]="assignedWorkFlowsWidgetData"
                ></mvs-widget>
            </div>

        </div>







    </div>



</ng-container>