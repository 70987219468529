
<ng-container *ngIf="initialized">

    <p-blockUI [blocked]="busy" [target]="uploadPanel"></p-blockUI>


    <p-panel #uploadPanel showHeader="true" header="Auswahl">

        <p-progressSpinner *ngIf="busy"></p-progressSpinner>


        <div class="grid p-fluid">
            <div class="field col-12">
                <label for="selectService">1) Auswahl Import Service</label>
                <p-dropdown id="selectService" [options]="selectServices" [(ngModel)]="selectedService" placeholder="Data Service" optionLabel="name" [showClear]="true" (onChange)="onChangeService($event)"></p-dropdown>
            </div>

            <div class="field col-12" *ngIf="selectImportTypes">
                <label for="selectImportType">2) Auswahl Import Typ</label>
                <p-dropdown id="selectImportType" [filter]="true" [options]="selectImportTypes" [(ngModel)]="selectedImportType" placeholder="Import Typ" optionLabel="name" [showClear]="true" (onChange)="onChangeImportType($event)"></p-dropdown>
            </div>

            <div class="field col-12" *ngIf="selectImportSubTypes">
                <label for="selectImportSubType">3) Auswahl Import Sub Typ</label>
                <p-dropdown id="selectImportSubType" [options]="selectImportSubTypes" [(ngModel)]="selectedImportSubType" placeholder="Import Sub Typ" optionLabel="name" [showClear]="true" (onChange)="onChangeImportSubType($event)"></p-dropdown>
            </div>

            <div class="field col-12" *ngIf="selectActions">
                <label for="selectAction">4) Auswahl Aktion</label>
                <p-dropdown id="selectAction" [options]="selectActions" [(ngModel)]="selectedAction" placeholder="Aktion" optionLabel="name" [showClear]="true" (onChange)="onChangeAction($event)"></p-dropdown>
            </div>

            <div class="field col-12" *ngIf="selectActions">
                <label for="selectSystem">System auswählen (optional)</label>
                <p-dropdown id="selectSystem" [options]="selectSystems" [(ngModel)]="selectedSystem" placeholder="System" optionLabel="name" [showClear]="true"></p-dropdown>
            </div>

            <div class="field col-12" *ngIf="selectedAction">
                <p-fileUpload
                    #imFileUpload
                    name="file"
                    fileLimit="1"
                    [files]="selectedFiles"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [showCancelButton]="false"
                    chooseLabel="Excel Datei auswählen"
                ></p-fileUpload>
            </div>

            <div class="field col-12" *ngIf="selectedAction">

                <!-- Multithreading selection -->
                <form [formGroup]="form">

                    <div class="flex flex-column gap-3">

                        <div class="flex flex-column gap-2">
                            <label for="enableThreading" class="text-500 text-sm">Enable Multithreading</label>
                            <p-checkbox id="enableThreading" formControlName="enableThreading" [binary]="true"></p-checkbox>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="threadCount" class="text-500 text-sm">Thread Count</label>
                            <p-inputNumber id="threadCount" name="lastName" formControlName="threadCount"></p-inputNumber>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="threadPackageSize" class="text-500 text-sm">Thread Package Size</label>
                            <p-inputNumber type="number" id="threadPackageSize" formControlName="threadPackageSize" class="form-control"></p-inputNumber>
                        </div>

                        <div class="flex flex-column gap-2">
                            <label for="onlyReportErrorRecords" class="text-500 text-sm">Only report error records</label>
                            <p-checkbox [binary]="true" id="onlyReportErrorRecords" formControlName="onlyReportErrorRecords" class="form-control"></p-checkbox>
                        </div>

                    </div>

                </form>


                <p-toolbar>
                    <p-splitButton label="Template" [model]="templateOptions" (onClick)="onTemplate()" styleClass="p-button-raised p-button-help mr-2 mb-2"></p-splitButton>
                    <!--
                    <p-button styleClass="p-button-help" icon="fa-regular fa-download" label="Template" (click)="onTemplate($event)"></p-button>
                    <p-button styleClass="p-button-help" icon="fa-regular fa-download" label="Template mit Daten" (click)="onTemplateWithData($event)"></p-button>
                    -->
                </p-toolbar>

                <p-toolbar>
                    <p-button icon="fa-regular fa-ballot-check" label="Check" (click)="onDataCheck($event)"></p-button>
                    <p-button styleClass="p-button-danger" icon="fa-regular fa-upload" label="Import" (click)="onDataImport($event)"></p-button>
                </p-toolbar>
            </div>

        </div>
    </p-panel>

    <p-panel title="Prüfung" *ngIf="checkProcessingInformation">

        <h4><i imSeverityIcon="{{checkProcessingInformation.severity}}"></i>&nbsp;Prüfung durchgeführt</h4>
        <p-table [value]="checkProcessingInformation.entries">
            <ng-template pTemplate="header">
                <tr>
                    <th>Severity</th>
                    <th>Message</th>
                    <th>Detailed Message</th>
                    <th>Location</th>
                    <th>Data Reference</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entry>
                <tr>
                    <td><i imSeverityIcon="{{entry.severity}}"></i></td>
                    <td>{{entry.message}}</td>
                    <td>{{entry.location}}</td>
                    <td>{{entry.detailMessage}}</td>
                    <td>{{entry.dataReference}}</td>
                </tr>
            </ng-template>
        </p-table>

    </p-panel>


</ng-container>
