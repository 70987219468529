import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum, ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy, ObjectRequestList,  FilterCriteria,  Sorting, EntityStatusEnum,
} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {DatesHelper} from "../../../../../core/helper/dates-helper";
import {ContractService} from "../../../../service/api/contract.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'cm-kpi.component.html',
})
export class CmKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Contract Overview";
    totalContracts: number = 0;
    todayContracts: number = 0;
    thisMonthContracts: number = 0;
    thisWeekContracts: number = 0;
    activeContracts: number = 0;
    contractsConversion: number;

    kpiHeaderDto: KpiHeaderDto[];
    items: MenuItem[] | undefined = [{label: 'Agent Module'}];

    constructor(
        protected contractService: ContractService) {
        super()
    }


    ngOnInit(): void {

        super.ngOnInit();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {


        this.getContractsGroupBy();

        this.initialized = true;

    }


    getContractsGroupBy() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.contractService.groupBy(objectRequestList).subscribe(res => {
            this.totalContracts = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);

            for (let entry of res.entries) {
                if (entry['entityStatus'] == EntityStatusEnum.active) {
                    this.activeContracts = entry['entityStatus_count']
                    break;
                }
            }
            this.getLastYearContractsGroupBy();
        }, error => {
            this.getLastYearContractsGroupBy();
        });

    }

    getLastYearContractsGroupBy() {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [FilterCriteria.create('startDate', FilterCriteria.cOperatorBetween, DatesHelper.getFirstDayOfPreviousYear(), DatesHelper.getLastDayOfPreviousYear())],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.contractService.groupBy(objectRequestList).subscribe(res => {
            this.getThisYearContractsGroupBy(res.entries.length)
        }, error => {
            this.getThisYearContractsGroupBy(0);
        });

    }

    getThisYearContractsGroupBy(lastYearContracts: number) {

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            [FilterCriteria.create('startDate', FilterCriteria.cOperatorBetween, DatesHelper.getFirstDayOfCurrentYear(), DatesHelper.getLastDayOfCurrentYear())],
            [],
            ['entityStatus'],
            [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
            ObjectRequestEntityProcessingTypeEnum.regular
        );

        this.contractService.groupBy(objectRequestList).subscribe(res => {

            if (lastYearContracts === 0) {
                this.contractsConversion = 100;
                return null; // Avoid division by zero if there were no contracts last year
            }

            const thisYearContracts = res.entries.length

            const percentageChange = ((thisYearContracts - lastYearContracts) / lastYearContracts) * 100;
            this.contractsConversion = Math.round(percentageChange * 100) / 100; // Round to 2 decimal places
            this.getTodayContracts();

        }, error => {
            this.getTodayContracts();
        });

    }


    getTodayContracts() {


        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [FilterCriteria.create('createdDate', FilterCriteria.cOperatorBetween, DatesHelper.getStartOfToday(), DatesHelper.getEndOfToday())],
            [new Sorting('createdDate', false)],
        );

        this.contractService.list(objectRequestList).subscribe(res => {
            this.todayContracts = res.entries.length;
            this.getThisMonthContracts();
        }, error => {
            this.getThisMonthContracts();
        });
    }

    getThisMonthContracts() {
        const objectRequestList = this.getThisMonthRequest('entityStatus')
        this.contractService.groupBy(objectRequestList).subscribe(res => {
            this.thisMonthContracts = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.getThisWeekContracts();
        }, error => {
            this.getThisWeekContracts();
        });
    }

    getThisWeekContracts() {
        const objectRequestList = this.getThisWeekRequest('entityStatus')
        this.contractService.groupBy(objectRequestList).subscribe(res => {
            this.thisWeekContracts = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
            this.initKpiHeaderDto();
        },error => {
            this.initKpiHeaderDto();
        });
    }


    // getContractsCreatedThisMonth(contracts: ContractDto[]): ContractDto[] {
    //     const today = new Date();
    //
    //     // First day of the current month in UTC
    //     const firstDayOfMonth = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1, 0, 0, 0, 0));
    //
    //     // Last day of the current month in UTC
    //     const lastDayOfMonth = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0, 23, 59, 59, 999));
    //
    //     return contracts.filter(contract => {
    //         const createdDate = new Date(contract.createdDate);
    //         return createdDate >= firstDayOfMonth && createdDate <= lastDayOfMonth;
    //     });
    // };

    // getExpiringContractsGroupBy() {
    //
    //     const today = new Date();
    //     const currentYear = today.getFullYear();
    //     const currentMonth = today.getMonth() + 1;
    //     const lastDayOfCurrentMonth = DatesHelper.calculateLastDayOfMonth(currentYear, currentMonth);
    //
    //
    //     const objectRequestList = ObjectRequestListGroupBy.create(
    //         false,
    //         [FilterCriteria.create('plannedEndDate', FilterCriteria.cOperatorLowerEqual, lastDayOfCurrentMonth)],
    //         [],
    //         ['entityStatus'],
    //         [new ObjectRequestListAttribute('entityStatus', "Anzahl", DtoListAttributeRequestAggregateEnum.count)],
    //         ObjectRequestEntityProcessingTypeEnum.regular
    //     );
    //
    //     this.contractService.groupBy(objectRequestList).subscribe(res => {
    //         this.expiringContracts = res.entries.reduce((acc, item) => acc + item['entityStatus_count'], 0);
    //         this.initKpiHeaderDto();
    //     }, error => {
    //         this.initKpiHeaderDto();
    //     });
    //
    // }

    initKpiHeaderDto() {

        const contractsConversionClass = this.contractsConversion > 0 ? 'text-green-500' : 'text-red-500';
        // const differenceInNewContractsClass = this.differenceInNewContracts > 0 ? 'text-green-500' : 'text-red-500';

        this.kpiHeaderDto = [
            {
                label: 'Total Contracts', value: this.totalContracts, route: '/cm/contract-dashboard-status'
                , otherInfo: `${this.contractsConversion}% since last year`, otherInfoClass: contractsConversionClass
            },
            {
                label: 'Today Contracts', value: this.todayContracts, route: '/cm/last'
            },
            {
                label: 'Active Contracts', value: this.activeContracts
            },
            {
                label: 'This Month Contracts', value: this.thisMonthContracts,
            },
            {
                label: 'This Week Contracts', value: this.thisWeekContracts
            },
            // {
            //     label: 'New Contracts (Last 30 Days)',
            //     value: this.currentMonthNewContracts,
            //     otherInfo: `${this.differenceInNewContracts} since last month`,
            //     otherInfoClass: differenceInNewContractsClass
            // },
            // {
            //     label: 'Contracts Expiring This Month', value: this.expiringContracts
            // }
        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
