<ng-container *ngIf="initialized">



    <div class="grid">
        <div class="col-3">

            <mvs-widget *ngIf="widgetBasic" [widgetData]="widgetBasic"></mvs-widget>

        </div>

        <div class="col-9">

            <mvs-widget *ngIf="widgetRestrictContractType" [widgetData]="widgetRestrictContractType"></mvs-widget>

            <mvs-widget *ngIf="widgetRestrictGroup" [widgetData]="widgetRestrictGroup"></mvs-widget>

        </div>
    </div>

</ng-container>


