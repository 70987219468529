<ng-container *ngIf="initialized">


    <p-tabView [(activeIndex)]="tabIndex">

        <p-tabPanel header="Agent Pool Info">

        </p-tabPanel>
        <p-tabPanel header="Agent Pool Calendar">

        </p-tabPanel>

    </p-tabView>

    <ng-container *ngIf="tabIndex == 0">
        <mvs-widget
                *ngIf="agentPoolWidget"
                [widgetData]="agentPoolWidget"
        ></mvs-widget>

        <mvs-widget
                *ngIf="agentPoolContactWidget"
                [widgetData]="agentPoolContactWidget"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="tabIndex == 1">
        <am-agent-calendar-management
                [selectedAgentIds]="agentIds"
        ></am-agent-calendar-management>
    </ng-container>


</ng-container>