import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvs-cf-template-cancellation-buffer-page',
  templateUrl: './cf-template-cancellation-buffer.page.html',
  styleUrls: ['./cf-template-cancellation-buffer.page.scss']
})
export class CfTemplateCancellationBufferPage implements OnInit {

  objectBrowserRequest: any;

  constructor() {
  }

  ngOnInit(): void {

    this.objectBrowserRequest = {

      "extractDto": false,

      'entries' : [
        {
          'id'                : 1,
          'objectAlias'       : 'cf.CfTemplateCancellationBuffer',
          'level'             : 1,
          'parentBinding'     : 'none'

        },
      ]

    }
  }
}
