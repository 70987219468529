import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {DtoDetail} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class TicketTypeActionService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/tm/ticketTypeActions');
  }

  /**
   * Retrieve all TicketActions via the Ticket Type.
   * @param dtoTicketType
   */
  listActionsFromTicketType(dtoTicketType: DtoDetail) : Observable<DtoList> {

    return this.listByAttribute("ticketType", dtoTicketType["id"]);

  }

}
