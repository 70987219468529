@if (initialized) {
    <div class="grid">
        <!-- Left column with filters (conditionally visible) -->
        <div class="md:col-3 col-12 transition-col" [class.hidden-filter]="toggleFilters">
            <!-- Filter Pane -->
            <div class="surface-0 shadow-1 p-3 border-round h-full" *ngIf="!toggleFilters">
                <div class="flex justify-content-between align-items-center border-bottom-1 border-200 pb-2">
                    <h4 class="m-0">Filters</h4>
                </div>

                <div class="w-full mt-3">
                    <!-- Favourites section -->
                    <div class="">
                        <div class="flex justify-content-between align-items-center mb-2">
                            <div class="flex gap-2 align-items-center">
                                <i class="pi pi-star"></i>
                                <span class="font-medium">Favourites</span>
                            </div>
                            <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"
                                    class="p-button-rounded p-button-text"
                                    (click)="menu.toggle($event)">
                            </button>
                        </div>

                        @if (reportAgentFavoriteDtoList && reportAgentFavoriteDtoList.length) {
                            <div class="">
                                @for (favorite of reportAgentFavoriteDtoList; track favorite.id) {
                                    <div class="flex justify-content-between align-items-center gap-2 mb-2 p-2 border-round"
                                         [ngClass]="{'bg-primary-100': selectedFilter?.id == favorite.id,'bg-gray-100': favorite.id == contextMenuId}"
                                         (contextmenu)="onUiSelectedLayoutContextMenu($event, favorite)"
                                         (click)="getFavoriteInfo(favorite)">
                                        <!--                                    (contextmenu)="onUiSelectedLayoutContextMenu($event, favorite, true)"-->
                                        <div class="cursor word-break">
                                            <i class="{{favorite.image}}"></i>
                                            {{ favorite.name }}
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div class="p-3" *ngIf="!reportAgentFavoriteDtoList || !reportAgentFavoriteDtoList.length">
                            No favorites added so far!
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- Main Content Area -->
        <div class="col-12 smooth-transition" [ngClass]="toggleFilters ? 'md:col-12' : 'md:col-9'">
            <div class="grid">
                <div class="col-12">
                    <div class="flex gap-4 card">

                        <!-- The toggle button for filters -->
                        <div>
                            <p-button styleClass="p-button-text text-600" (click)="toggleFilterSection()"
                                      icon="pi pi-bars"></p-button>
                        </div>

                        <!-- Dropdowns for Module / Category / Report -->
                        <div class="">
                            <p-dropdown
                                    [options]="moduleList"
                                    [(ngModel)]="selectedModule"
                                    (onChange)="refreshCategories(); isReportGenerated=false;"
                                    placeholder="Select a Module">
                            </p-dropdown>
                        </div>
                        <div class="">
                            <p-dropdown
                                    [options]="categoryList"
                                    [(ngModel)]="selectedCategory"
                                    (onChange)="refreshReports(); updateRoute(); isReportGenerated=false;"
                                    [disabled]="!selectedModule"
                                    placeholder="Select a Category">
                            </p-dropdown>
                        </div>
                        <div class="">
                            <p-dropdown
                                    [options]="reportList"
                                    [(ngModel)]="selectedReport"
                                    (onChange)="updateRoute(); handleSelectReport()"
                                    [disabled]="!selectedCategory"
                                    placeholder="Select a Report">
                            </p-dropdown>
                        </div>

                        <!-- Generate and Add-to-Favorite buttons -->
                        <div class="">
                            <p-button label="Generate" (click)="generateReport()" [disabled]="!selectedModule || !selectedCategory || !selectedReport"></p-button>
                        </div>
                        <div class="">
                            <p-button styleClass="p-button-text" label="Add to Favorite" icon="pi pi-star" (click)="addToFavorite()" [disabled]="!selectedModule || !selectedCategory || !selectedReport || !isDataLoaded"></p-button>
                        </div>

                        <!-- Period Filter -->
                        <div class="" style="margin-left: auto;" *ngIf="getKeyDateDependent()">
                            <p-dropdown
                                    [options]="periodFilter"
                                    [(ngModel)]="selectedPeriod"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Select Period"
                                    (onChange)="handleRoleFilters()"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>

                <!-- The actual report content -->
                <div class="col-12">

                    <rp-report-component
                            *ngIf="selectedModule && selectedCategory && selectedReport && isReportGenerated"
                            [module]="selectedModule.name"
                            [categoryId]="selectedCategory.id"
                            [reportId]="selectedReport.id"
                            [report]="selectedReport"
                            [category]="selectedCategory"
                            [roleBasedFilter]="roleBasedFilter"
                            [refreshTrigger]="refreshCounter"
                            (onDataLoaded)="handleDataLoad()"
                    ></rp-report-component>
                </div>
            </div>
        </div>
    </div>

    <p-slideMenu #menu
                 [model]="slideMenuItems"
                 [popup]="true"
                 [menuWidth]="175"
                 class="sort-icon"
                 appendTo="body"
    >
    </p-slideMenu>

    <p-contextMenu [appendTo]="'body'"
                   #cmLayout
                   [model]="uiLayoutContextMenuItems"
                   (onHide)="onUiHideContextMenu()">
    </p-contextMenu>

    <p-sidebar [(visible)]="manageFavoritesSidebar" position="right" styleClass="w-30rem">
        <h4>Manage Favorites</h4>
        @if (reportAgentFavoriteDtoList && reportAgentFavoriteDtoList.length) {
            <div class="group-list" cdkDropList
                 [cdkDropListData]="reportAgentFavoriteDtoList"
                 (cdkDropListDropped)="adjustPriority($event)">
                @for (favorite of reportAgentFavoriteDtoList; track favorite.id) {
                    <div class="flex justify-content-between align-items-center mb-3 pl-3 shadow-1 p-2 border-round">
                        <div class="flex gap-3 align-items-center">
                            <i cdkDrag class="fa-solid fa-grip-dots-vertical cursor-move text-xl"></i> {{ favorite.name }}
                        </div>
                        <div class="flex gap-2 align-items-center">

                            <p-button styleClass="p-button-text text-red-500 font-normal"
                                      (click)="removeFromFavorite(favorite)">
                                Remove
                            </p-button>

                        </div>
                    </div>
                }
            </div>
        }
    </p-sidebar>

}