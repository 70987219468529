import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {WfProcessTypeStepActivityDto} from "../dto/wf-process-type-step-activity.dto";

export class WfProcessStepActivityEvent {

    constructor(
        public objectIdentifier: ObjectIdentifier,
        public stepActivity: WfProcessTypeStepActivityDto,
    ) {
    }


}