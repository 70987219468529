import {DtoDetail} from "@kvers/alpha-core-common";

export class RpReportFieldDto extends DtoDetail {

    public reportDtoId : number;
    public reportDtoName : string;
    public reportDtoImage : string;
    public reportDtoColor : string;
    public reportObjectDtoId : number;
    public reportObjectDtoName : string;
    public reportObjectDtoImage : string;
    public reportObjectDtoColor : string;
    public attributeName : string;
    public priority : number;
    public name : string;
    public description : string;
}