import {DtoDetail} from "@kvers/alpha-core-common";
import { ContractDto } from "./contract.dto";
import { ContractStatusTypeDto } from "./contract-status-type.dto";
import { ContractStatusTypeEntryDto } from "./contract-status-type-entry.dto";

export class ContractStatusDto extends DtoDetail{


    public contractDto? : ContractDto;
    public contractDtoId : number;
    public contractDtoName : string;
    public statusTypeDto? : ContractStatusTypeDto;
    public statusTypeDtoId : number;
    public statusTypeDtoName : string;
    public startDate: Date;
    public statusTypeEntryDto? : ContractStatusTypeEntryDto;
    public statusTypeEntryDtoId : number;
    public statusTypeEntryDtoName : string;

}