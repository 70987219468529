import {RpObjectLinkTypeEnum} from "../enum/rp-object-link-type.enum";
import {RpObjectLinkJoinEnum} from "../enum/rp-object-link-join.enum";
import {DtoDetail} from "@kvers/alpha-core-common";

export class RpReportObjectLinkDto extends DtoDetail {

    public reportDtoId : number;
    public reportDtoName : string;
    public reportDtoImage : string;
    public reportDtoColor : string;
    public reportObjectDtoId : number;
    public reportObjectDtoName : string;
    public reportObjectDtoImage : string;
    public reportObjectDtoColor : string;
    public linkType : RpObjectLinkTypeEnum;
    public linkJoin : RpObjectLinkJoinEnum;
    public linkAttribute : string;
    public sourceObjectDtoId : number;
    public sourceObjectDtoName : string;
    public sourceObjectDtoImage : string;
    public sourceObjectDtoColor : string;
    public targetObjectDtoId : number;
    public targetObjectDtoName : string;
    public targetObjectDtoImage : string;
    public targetObjectDtoColor : string;
    public name : string;
    public description : string;
    public priority : number;
    public multiple : boolean;
    public optional : boolean;
    public reportFilterDtoId : number;
    public reportFilterDtoName : string;
    public reportFilterDtoImage : string;
    public reportFilterDtoColor : string;
    public reportSortingDtoId : number;
    public reportSortingDtoName : string;
    public reportSortingDtoImage : string;
    public reportSortingDtoColor : string;
    public linkTypeLabel: string;
    public linkJoinLabel: string;

}