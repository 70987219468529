<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        mainObjectType="dm.DmDashboard"-->
<!--        >-->

<!--        &lt;!&ndash; Add End to Body &ndash;&gt;-->
<!--        <ng-template mvs-template-directive area="main-body:end">-->
<!--                <div class="grid">-->
<!--                        <div class="col-8 md:col-12">-->

<!--                                <mvs-widget-->
<!--                                        *ngIf="widgetDocumentTypeHistory"-->
<!--                                        [widgetData]="widgetDocumentTypeHistory"-->
<!--                                ></mvs-widget>-->

<!--                        </div>-->

<!--                        <div class="col-4 xs:col-6">-->

<!--                                <mvs-widget-->
<!--                                        *ngIf="widgetDocumentStatus"-->
<!--                                        [widgetData]="widgetDocumentStatus"-->
<!--                                ></mvs-widget>-->

<!--                        </div>-->

<!--                        <div class="col-4 xs:col-6">-->
<!--                                <mvs-widget-->
<!--                                        *ngIf="widgetDocumentTypes"-->
<!--                                        [widgetData]="widgetDocumentTypes"-->
<!--                                ></mvs-widget>-->
<!--                        </div>-->

<!--                        <div class="col-4 xs:col-6">-->

<!--                                <mvs-widget-->
<!--                                        *ngIf="widgetMimeTypes"-->
<!--                                        [widgetData]="widgetMimeTypes"-->
<!--                                ></mvs-widget>-->

<!--                        </div>-->

<!--                </div>-->
<!--        </ng-template>-->
<!--</mvs-crud-page>-->

<div class="grid">
    <div class="col-8 md:col-12">

        <mvs-widget
                *ngIf="widgetDocumentTypeHistory"
                [widgetData]="widgetDocumentTypeHistory"
        ></mvs-widget>

    </div>

    <div class="col-4 xs:col-6">

        <mvs-widget
                *ngIf="widgetDocumentStatus"
                [widgetData]="widgetDocumentStatus"
        ></mvs-widget>

    </div>

    <div class="col-4 xs:col-6">
        <mvs-widget
                *ngIf="widgetDocumentTypes"
                [widgetData]="widgetDocumentTypes"
        ></mvs-widget>
    </div>

    <div class="col-4 xs:col-6">

        <mvs-widget
                *ngIf="widgetMimeTypes"
                [widgetData]="widgetMimeTypes"
        ></mvs-widget>

    </div>

</div>