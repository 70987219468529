<div *ngFor="let process of processList" class="flex">
    <a
            class="underline cursor-pointer text-primary white-space-nowrap"
            [pTooltip]="userName  + '\n' + (process.createdDate | MvsDateAgo)"
            tooltipPosition="top"
            (click)="openTicketDrawer(process.id)"
            (mouseenter)="getUserByOid(process.createdBy)"
    >
        {{process.typeDtoName}}
    </a>
</div>
