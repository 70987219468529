import {Component} from '@angular/core';
import {ObjectPageComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-ns-notification-page',
    templateUrl: './ns-notification-object.page.html'
})
export class NsNotificationObjectPage
    extends ObjectPageComponent {

    defaultLabel: string = "Notification";

    ngOnInit() {
        super.ngOnInit();
    }

    protected getObjectType(): string {
        return "ns.NsNotification";
    }

}
