import {
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ImDataImportService} from "../../service/api/im-data-import.service";
import {ImImportDataServiceInfoDto} from "../../dto/im-import-data-service-info.dto";
import {ImImportTypeDto} from "../../dto/im-import-type.dto";
import {ImImportActionDto} from "../../dto/im-import-action.dto";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {FileUpload} from "primeng/fileupload";
import {ImProcessingInformationDto} from "../../dto/im-processing-information.dto";
import {ImImportSubTypeDto} from "../../dto/im-import-sub-type.dto";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {ImImportDto} from "../../dto/im-import.dto";
import {FileHelperLogic} from "@kvers/alpha-core-common";
import {ImSystemDto} from "../../dto/im-system.dto";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ImRequestThreadingDto} from "../../logic/im-request-threading.dto";
import { MvsConditionBuilderService } from '@kvers/alpha-ui';

@Component({
  selector: 'mvs-im-data-import',
  templateUrl: './im-data-import.component.html',
  styleUrls: ['./im-data-import.component.scss']
})
export class ImDataImportComponent
    implements OnInit, OnDestroy, OnChanges {

  @Output() onImported: EventEmitter<ImImportDto> = new EventEmitter<ImImportDto>();

  @ViewChild('imFileUpload') fileUpload: FileUpload;


  busy: boolean;
  initialized: boolean;

  selectServices: ImImportDataServiceInfoDto[];
  selectedService: ImImportDataServiceInfoDto;

  selectImportTypes: ImImportTypeDto[];
  selectedImportType: ImImportTypeDto;

  selectImportSubTypes: ImImportSubTypeDto[];
  selectedImportSubType: ImImportSubTypeDto;

  selectActions: ImImportActionDto[];
  selectedAction: ImImportActionDto;

  excelSourceUrl: SafeResourceUrl;

  selectedFiles: File[];

  checkProcessingInformation: ImProcessingInformationDto;

  templateOptions: MenuItem[];

  selectSystems: ImSystemDto[];
  selectedSystem: ImSystemDto;

  form: FormGroup;

  /**
   * Call super constructor.
   */
  constructor(
      protected coreService: MvsCoreService,
      protected messageService: MvsMessageService,
      protected dataImportService: ImDataImportService,
      protected conditionBuilderService: MvsConditionBuilderService,
      private sanitizer: DomSanitizer,
      protected fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  initComponent() {

    // create menu items for template
    this.templateOptions = [
      {
        label : "Ohne Daten",
        command : event => { this.generateTemplate(false, false); }
      },
      {
        label : "Mit Daten",
        command : event => { this.generateTemplate(true, false); }
      },
      {
        label : "Mit Daten und Filter",
        command : event => { this.generateTemplate(true, true); }
      }
    ]

    // retrieve services
    this.dataImportService.listServices().subscribe(value => {
      this.selectServices = value;

      this.selectedService = null;
      this.initialized = true;
    });

    // retrieve systems
    this.dataImportService.listSystems().subscribe(value => {
      this.selectSystems = value;
    });

    this.initFormGroup();
  }


  initFormGroup() {
    this.form = this.fb.group({
      enableThreading: [false],
      threadCount: [5, Validators.required],
      threadPackageSize: [50],
      onlyReportErrorRecords: false
    });
  }


  onChangeService(event: any) {

    this.selectImportTypes = null;
    this.selectedImportType = null;
    this.selectActions = null;
    this.selectedAction = null;

    this.dataImportService.listImportTypes(this.selectedService.id).subscribe(value => {
      this.selectImportTypes = value;

      this.selectImportTypes.sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
      }
      );

      this.selectedImportType = null;
    });

  }

  onChangeImportType(event: any) {

    // clear sub types
    this.selectedImportSubType = null;
    this.selectImportSubTypes = null;

    // clear actions
    this.selectActions = null;
    this.selectedAction = null;

    this.dataImportService.listImportSubTypes(this.selectedService.id, this.selectedImportType.id).subscribe(value => {
      this.selectImportSubTypes = value;
      this.selectedImportSubType = null;
    });

  }

  onChangeImportSubType(event: any) {

    this.selectActions = null;
    this.selectedAction = null;

    this.dataImportService.listImportActions(this.selectedService.id, this.selectedImportType.id, this.selectedImportSubType.id).subscribe(value => {
      this.selectActions = value;
      this.selectedAction = null;
    });

  }

  onDataCheck(event: any) {

    if (!this.fileUpload.files || !this.fileUpload.files[0]) {
      this.messageService.showErrorMessage("Daten Prüfen", "Bitte Excel Datei auswählen!");
      return;
    }

    this.busy = true;

    this.dataImportService.executeImportCheck(
        this.selectedService.id,
        this.selectedImportType.id,
        this.selectedImportSubType.id,
        this.selectedAction.id,
        (this.selectedSystem ? this.selectedSystem.id : null),
        this.fileUpload.files[0]
    ).subscribe(value => {

      // update

      this.checkProcessingInformation = value;
      this.busy = false;
    }, error => {
      this.busy = false;
    });

  }

  onDataImport(event: any) {

    if (this.form.status !== "VALID") {
      this.messageService.showErrorMessage("Threading", "Entered Threading information is inconsistent!");
      return;
    }

    if (!this.fileUpload.files || !this.fileUpload.files[0]) {
      this.messageService.showErrorMessage("Daten Prüfen", "Bitte Excel Datei auswählen!");
      return;
    }

    this.busy = true;

    const requestThreadingDto = new ImRequestThreadingDto(this.form.value["enableThreading"], this.form.value["threadCount"], this.form.value["threadPackageSize"]);

    this.dataImportService.executeImport(
        this.selectedService.id,
        this.selectedImportType.id,
        this.selectedImportSubType.id,
        this.selectedAction.id,
        (this.selectedSystem ? this.selectedSystem.id : null),
        this.fileUpload.files[0],
        this.form.value["onlyReportErrorRecords"],
        requestThreadingDto
    ).subscribe(value => {

      const imImport = <ImImportDto>value;
      // inform
      this.onImported.emit(imImport);

      this.messageService.showSuccessMessage("Import gestartet!", `Datenimport wurde erfolgreich im Hintergrund gestartet. ID ${imImport.id}`)

      this.busy = false;
    }, error => {
      this.messageService.showErrorMessage("Fehler!", `Es kam zu einem Fehler!`)
      this.busy = false;
    });

  }


  onChangeAction(event: any) {

  }


  /**
   * Generate Template.
   * @param withData
   * @param withFilter
   */
  generateTemplate(withData: boolean, withFilter: boolean) {

    this.excelSourceUrl = null;

    if (withData && withFilter) {

      this.conditionBuilderService.showConditionBuilderEntityDialog(
          null,
          this.selectedImportType.id,
          null,
          false,
          (filterCriteria: FilterCriteria[]) => {

            this.dataImportService.downloadTemplateViaUrl(
                this.selectedService.id,
                this.selectedImportType.id,
                this.selectedImportSubType.id,
                this.selectedAction.id,
                true,
                filterCriteria
            ).subscribe(url => {

              this.downloadTemplate(url);

            });

            return true; // close popup
          }
      );

    } else {

      // download template via URL
      this.dataImportService.downloadTemplateViaUrl(
          this.selectedService.id,
          this.selectedImportType.id,
          this.selectedImportSubType.id,
          this.selectedAction.id,
          withData
      ).subscribe(url => {

        this.downloadTemplate(url);

      });
    }

  }

  /**
   * Download template within browser.
   * @param url
   */
  downloadTemplate(url: any) {

    FileHelperLogic.downloadFileViaUrl(url, "template_" + this.selectedService.id + "_" + this.selectedImportType.id + "_" + this.selectedAction.id + ".xlsx");

  }


  /**
   * Retrieve Template and show Excel.
   * @param event
   */
  onTemplate() {

    this.generateTemplate(false, false);

  }

}
