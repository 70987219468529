import {DtoDetail} from "@kvers/alpha-core-common";
import {TicketActionStatusInternal} from "../enum/ticket-action-status-internal.enum";
import {TicketTypeActionDto} from "./ticket-type-action.dto";
import {TicketObjectDto} from "./ticket-object.dto";

export class TicketActionDto extends DtoDetail {

    public ticketDtoId : number;
    public ticketDtoName : string;
    public ticketDtoImage : string;
    public ticketDtoColor : string;
    public ticketDtoColorBackground : string;
    public ticketTypeActionDtoId : number;
    public ticketTypeActionDtoName : string;
    public ticketTypeActionDtoImage : string;
    public ticketTypeActionDtoColor : string;
    public ticketTypeActionDtoColorBackground : string;
    public mandatory : Boolean;
    public statusInternal : TicketActionStatusInternal;
    public ticketObjectDtoId : number;
    public ticketObjectDtoName : string;
    public ticketObjectDtoImage : string;
    public ticketObjectDtoColor : string;
    public ticketObjectDtoColorBackground : string;
    public processDtoId : number;
    public processDtoName : string;
    public processDtoImage : string;
    public processDtoColor : string;
    public processDtoColorBackground : string;
    public actionTicketDtoId : number;
    public actionTicketDtoName : string;
    public actionTicketDtoImage : string;
    public actionTicketDtoColor : string;
    public actionTicketDtoColorBackground : string;
    public ticketTypeActionDto: TicketTypeActionDto;
    public name : string;
    public description : string;
    public ticketObject : TicketObjectDto;

    public notificationDtoId: number;
    public notificationDtoName : string;



    public notificationTypeDtoId?: number;
}