import {Component, ContentChildren, Input, OnInit, QueryList, TemplateRef} from '@angular/core';
import {TestFormPage} from "../test-form/test-form.page";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsSortingService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import { MvsConditionBuilderService } from '@kvers/alpha-ui';

@Component({
  selector: 'mvs-test-templating',
  templateUrl: './test-templating.page.html',
  styleUrls: ['./test-templating.page.scss']
})
export class TestTemplatingPage implements OnInit {

  @ContentChildren(TemplateRef) templates: QueryList<TemplateRef<any>>;



  constructor(
      protected sortingService: MvsSortingService,
      protected conditionBuilderService: MvsConditionBuilderService,
      protected objectTypeService: ObjectTypeService) {

  }

  ngOnInit(): void {

  }

  async showFilter() {

    const viaObjectType = await this.objectTypeService.getViaObjectType("cr.Customer");

    // show filter
    this.conditionBuilderService.showConditionBuilderEntityDialog(
        null,
        viaObjectType.id,
        null,
        false,
        (filterCriteria: FilterCriteria[]) => {


          return true;
        },
        (filterCriteria: FilterCriteria[]) => {
          return true;
        }
    );

  }

  initTestData(): void {

  }


}
