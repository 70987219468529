import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsCoreService,
    MvsCrudModeEnum,
    MvsMessageService,
    ObjectBaseComponent,
    ObserverService
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {TicketGroupFilterService} from "../../../../service/api/ticket-group-filter.service";
import {TicketGroupFilterDto} from "../../../../dto/ticket-group-filter.dto";
import {TicketGroupFilterAccess} from "../../../../enum/ticket-group-filter-access.enum";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-example',
    templateUrl: './ticket-filter-group-list.component.html',
})
export class TicketFilterGroupListComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    groupFilters: TicketGroupFilterDto[];
    selectedGroup: TicketGroupFilterDto;
    currentView: 'allFilters' | 'createFilter' | 'updateFilter' = 'allFilters';

    constructor(protected coreService: MvsCoreService,
                protected messageService: MvsMessageService,
                protected confirmationService: ConfirmationService,
                protected observerService: ObserverService,
                protected navigationService: MvsObjectNavigationService,
                protected ticketGroupFilterService: TicketGroupFilterService
                ) {
        super(coreService,messageService,confirmationService,observerService);
    }
    protected retrieveObject() {
        this.onObjectChanged();
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.getTicketGroupFilters();
    }

    getTicketGroupFilters() {
        this.ticketGroupFilterService.getMeGroups().subscribe(res => {
            this.groupFilters = res.entries;
            this.initialized = true;
        });
    }

    handleEditFilter(group: TicketGroupFilterDto) {
        this.currentView = "updateFilter";
        this.selectedGroup = group;
    }

    handleCreateFilter() {
        this.currentView = 'createFilter';
    }

    handleMainNavigation() {
        this.getTicketGroupFilters();
        this.currentView = 'allFilters';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    protected readonly TicketGroupFilterAccess = TicketGroupFilterAccess;
    protected readonly MvsCrudModeEnum = MvsCrudModeEnum;
}
