<ng-container *ngIf="initialized">

    <div class="card quickview --workflowS" *ngIf="activityType && activityType.description">
        <div [innerHTML]="activityType.description | mvsSavePipe:'html'"></div>
    </div>


<!--    <mvs-ns-notification-types-for-object-->
<!--            [mode]="1"-->
<!--            [notificationTypeDtoId]="notificationTypeDtoId"-->
<!--            [objectIdentifier]="objectIdentifier"-->
<!--    ></mvs-ns-notification-types-for-object>-->

    <mvs-ns-notification-send
            [mode]="1"
            [objectIdentifier]="objectIdentifier"
            [notificationSourceTypeId]="notificationSourceTypeId"
            (onSendNotification)="handleSentNotification($event)"
    ></mvs-ns-notification-send>

</ng-container>
