import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {CmPartnerComponent} from "../../components/cm-partner/cm-partner.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/partners')
  }

  /**
   * Use specific component for the Partner object.
   */
  getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
    return CmPartnerComponent;
  }

}