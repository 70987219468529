import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
    DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";


@Component({
    selector: 'contract-history-page',
    templateUrl: './contract-history.page.html',
})
export class ContractHistoryPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Contract History";
    widgetContractHistory: WidgetData;


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshContractHistoryWidget();
        this.initialized = true;
    }

    refreshContractHistoryWidget() {

        this.widgetContractHistory = new WidgetData();
        this.widgetContractHistory.idAlias = "cm.contract.overview.contract.history";
        this.widgetContractHistory.name = "Contract History";
        this.widgetContractHistory.uiComponent = "history.chart";
        this.widgetContractHistory.dataProvider = "list";
        this.widgetContractHistory.dataSource = "entity.groupBy";
        this.widgetContractHistory.dataProviderObject = "cm.Contract";
        this.widgetContractHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["group", "startDate"],
            [ new ObjectRequestListAttribute("group", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

        this.widgetContractHistory.setParamValue("fieldCategory", "group");
        this.widgetContractHistory.setParamValue("fieldCategoryCount", "group_count");
        this.widgetContractHistory.setParamValue("historyPeriodMode", "year");
        this.widgetContractHistory.setParamValue("fieldDate", "startDate");
        this.widgetContractHistory.setParamValue("selectionMode", "single");
        this.widgetContractHistory.setParamValue("size", "S");

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
