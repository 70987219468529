<ng-container *ngIf="initialized && objectLoaded && dto && objectIdentifier">

    <ng-container *ngIf="viewType==0">
        <cr-customer-standard-navigation
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
                [personRelationPersonWidgetStyle]="personRelationPersonWidgetStyle"
                [changedFilter]="changedFilter"
                [importObjectContext]="myContext"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-standard-navigation>

    </ng-container>

    <ng-container *ngIf="viewType==1">
        <cr-customer-service-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-service-navigation>
    </ng-container>

    <ng-container *ngIf="viewType==2">
        <cr-customer-sales-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier"
                [dto]="dto">
        </cr-customer-sales-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == 3">
        <cr-customer-communication-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-communication-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == 4">
        <cr-customer-registration-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-registration-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == 5">
        <cr-customer-tickets-workflows-navigation
                [objectIdentifier]="objectIdentifier">
        </cr-customer-tickets-workflows-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == 6">
        <cr-customer-contracts-navigation
                [objectIdentifier]="objectIdentifier"
                [importObjectContext]="myContext"
                [dto]="dto"
                [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
                [householdPersons]="householdPersons"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-contracts-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == 7">
        <cr-customer-interactions-navigation
                [objectIdentifier]="objectIdentifier"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-interactions-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == 8">
        <cr-customer-additional-information-navigation
                [objectIdentifier]="objectIdentifier">
        </cr-customer-additional-information-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == 9">
        <cr-customer-document-upload
                [objectIdentifier]="objectIdentifier">
        </cr-customer-document-upload>
    </ng-container>

    <ng-container *ngIf="viewType == 10">
        <cr-customer-promo
                [objectIdentifier]="objectIdentifier">
        </cr-customer-promo>
    </ng-container>

    <mvs-ticket [visible]="viewType == 11"
                [objectIdentifier]="{objectType:'cr.Customer', objectId:dto.id}"
                (onTicketsCount)="handleTicketsCount($event)">

    </mvs-ticket>

    <mvs-workflow [visible]="viewType == 12"
                  [objectRequestList]="workflowObjectRequestList"
                  (onWorkflowCount)="handleWorkflowsCount($event)"
    >
    </mvs-workflow>


</ng-container>