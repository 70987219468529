import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoImportObjectContext,
    FilterCriteria,
    NavigationItem,
    ObjectBaseModeComponent,
    ObjectRequestList, ObserverService
} from "@kvers/alpha-core-common";
import {ContractDto} from "../../../../dto/contract.dto";
import {MvsObjectCount} from "../../../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {MvsObjectNavigationProviderGeneric, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";
import {WfProcessService} from "../../../../../wf/service/api/wf-process.service";


@Component({
    selector: 'cm-contract-object-base-component',
    template: '',
})
export class ContractObjectBaseComponent extends ObjectBaseModeComponent implements OnInit {

    @Input() transientDto: ContractDto;
    @Input() isOverlay: boolean = false;

    @Output() onTicketsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();
    @Output() onWorkflowsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();

    dto: ContractDto;
    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowObjectRequestList: ObjectRequestList;
    @Input() customerImportObjectContext: DtoImportObjectContext;

    constructor(
        protected route: ActivatedRoute,
        protected navigationService: MvsObjectNavigationService,
        protected processService: WfProcessService,
        protected observerService: ObserverService
    ) {
        super(route);
    }


    ngOnInit() {

        if (this.navigationItems?.length) {
            this.activeNavigationItem = this.navigationItems.find(item => item.default == true);
            this.onNavigationItems.emit(this.navigationItems);
        }

        super.ngOnInit();

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());
        this.setGeneralTabsData();

        this.initialized = true;

    }


    setGeneralTabsData() {

        this.ticketIndicatorFilterCriteria = [
            FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.dto?.joinCurrentMainCustomer?.id),
        ];

        this.workflowObjectRequestList = this.processService.getRequestForProcessForObjectViaAlias(
            this.objectIdentifier.objectType,
            this.objectIdentifier.objectId,
            true
        );
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }


    }

    handleTicketsCount(event: MvsObjectCount) {
        this.onTicketsCount.emit(event);
    }

    handleWorkflowsCount(event: MvsObjectCount) {
        this.onWorkflowsCount.emit(event);
    }


}
