import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../service/api/customer.service";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-cr-customer-last',
    templateUrl: './cr-customer-last.page.html',
})
export class CrCustomerLastPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    defaultLabel: string = "Kundenanlage";

    createdCustomersWidgetData: WidgetData;


    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected customerService: CustomerService,
        protected navigationService: MvsObjectNavigationService,
        protected router: Router) {
        super(route, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;

        this.refreshWidgetCreatedCustomers();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    refreshWidgetCreatedCustomers(){

        this.createdCustomersWidgetData = WidgetFactory.createWidgetList(
            "cr.customer.last.view.all.customers",
            "Alle Kunden",
            "table",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kunden gefunden!",

            ObjectRequestList.createComplexRequestList(
                true,
                this.customerService.getCustomerWithAgentComplexRequest(),
                [],
                [new Sorting("createdDate", false)],
                PagingDto.create(0,40)),
            WidgetDataParam.create("selectionMode", "rowSelect"),
            WidgetDataParam.create("size", "S"),

        );

    }

    onCustomerSelect(selectedCustomer: ObjectIdentifier) {
        //open customer in new tab
        this.navigationService.handleObjectNavigation(new ObjectIdentifier(selectedCustomer.objectType, selectedCustomer.objectId),null, {openNewTab: true});
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
