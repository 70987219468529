<ng-container *ngIf="initialized">

    <div class="flex justify-content-between align-items-center bg-gray-100 p-3 border-round-lg mb-5">
       <div class="flex flex-column gap-2">
           <h5 class="font-medium text-3xl m-0">{{ tableName }}</h5>
           <div class="flex gap-2">
               <span>Has Conditions:</span>
               <span class="font-bold">{{ hasConditions ? 'Yes' : 'No' }}</span>
           </div>
       </div>
        <p-button icon="pi pi-check"
                  [disabled]="!this.valid"
                  (click)="handleApplyConditions()"
                  styleClass="px-3"
                  label="Apply">
        </p-button>
    </div>


    <h3 class="font-medium text-2xl m-0 pl-3">Add conditions</h3>

    <!--    <mvs-condition-builder-->
    <!--            *ngIf="initialized"-->
    <!--            [filterCriteria]="filterCriteria"-->
    <!--            [joins]="tableJoins"-->
    <!--            [filterAttributeGroups]="tableAttributes"-->
    <!--            [enumValuesType]="'technicalLabel'"-->
    <!--            (onChange)="handleFilterChange($event)"-->
    <!--    ></mvs-condition-builder>-->

    <entity-condition-builder
            *ngIf="initialized"
            [filterCriteria]="filterCriteria"
            [enumValuesType]="'technicalLabel'"
            [objectTypeAlias]="parentObjectTypeName"
            (onChange)="handleFilterChange($event)">
    </entity-condition-builder>


    <h3 class="font-medium text-2xl pl-3">Attributes</h3>
    <!--    <div class="col-12" *ngIf="tableAttributes.length > 0">-->
    <!--        <p-panel header="Attributes">-->
    <!--            <div class="flex align-items-center gap-2 flex-wrap">-->
    <!--                <p-chip-->
    <!--                        *ngFor="let attribute of clonedTableAttributes; let i=index"-->
    <!--                        [label]="attribute.attribute"-->
    <!--                        [removable]="true"-->
    <!--                        (onRemove)="onRemoveAttribute(i)"-->
    <!--                ></p-chip>-->
    <!--            </div>-->
    <!--        </p-panel>-->
    <!--    </div>-->
    <ng-container *ngIf="conditionType == 'table'">
        <p-table *ngIf="clonedTableAttributes" [value]="clonedTableAttributes" class="overlay-custom"
                 [reorderableColumns]="true">
            <ng-template pTemplate="header">
                <tr class="text-sm">
                    <th style="width:3rem"></th>
                    <th>Attribute</th>
                    <th>Full Path</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-atr let-rowIndex="rowIndex">
                <tr [pReorderableRow]="rowIndex" [pSelectableRow]="rowData" class="text-sm">
                    <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                    </td>
                    <td>{{ atr.attribute }}</td>
                    <td>{{ atr.subPath }}</td>
                    <td>
                        <span class="pi pi-trash pointer" (click)="onRemoveAttribute(rowIndex)"></span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>

    <!--
        <div class="grid justify-content-end mr-6">
            <button pButton type="button" label="Add Condition" (click)="onAddRow()"></button>
        </div>

        <p-table class="p-2" [value]="tableConditions" [reorderableColumns]="true">
            <ng-template pTemplate="header">
                <tr class="">
                    <th>Attribute</th>
                    <th>Condition</th>
                    <th >Value</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-index="rowIndex">
                <tr style="border:none" class="text-xs" [pReorderableRow]="index">
                    <td style="border:none">
                        <p-dropdown [options]="attributesList"
                                    [(ngModel)]="row.attribute"
                                    optionLabel="attributeName"
                                    optionValue="attributeName"
                                    appendTo="body"
                                    placeholder="Select an Option">
                        </p-dropdown>
                    </td>

                    <td style="border:none">
                        <p-dropdown [options]="operationItems"
                                    [(ngModel)]="row.operator"
                                    optionLabel="label"
                                    optionValue="template"
                                    appendTo="body"
                                    placeholder="Select an Option">
                        </p-dropdown>
                    </td>

                    <td style="border:none">
                        <input type="text"
                               *ngIf="!getAttributeByName(row?.attribute)?.valueList"
                               [(ngModel)]="row.value"
                               pInputText/>

                        <p-dropdown [options]="getAttributeByName(row?.attribute)?.valueListEntries"
                                    *ngIf="getAttributeByName(row?.attribute)?.valueList"
                                    [(ngModel)]="row.value"
                                    optionLabel="technicalLabel"
                                    optionValue="technicalLabel"
                                    appendTo="body"
                                    placeholder="Select an Option">
                        </p-dropdown>
                    </td>

                    <td style="border:none" class="Icon">
                        <button (click)="deleteAttribute(index)"
                                pButton
                                type="button"
                                icon="fa-regular fa-trash"
                                class="p-button-danger small-icon">
                        </button>
                    </td>

                </tr>
            </ng-template>
        </p-table>

    -->

</ng-container>