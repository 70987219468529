@if (activeNavigationItem.action == 'alphaIntegrationInfo') {

    <mvs-widget *ngIf="integrationAlphaBatchEntityWidget"
                [widgetData]="integrationAlphaBatchEntityWidget"
    ></mvs-widget>
}

@if (activeNavigationItem.action == 'alphaIntegration') {
    <div class="grid">
        <div class="col-12">
            <div class="kanban-board align-items-center">
                @if (integrationAlphaBatchEntityDto?.integrationAlphaBatchEntityStep?.length) {
                    <div *ngFor="let step of integrationAlphaBatchEntityDto?.integrationAlphaBatchEntityStep"
                         class="kanban-column">
                        <div class="kanban-header flex flex-column gap-2">

                            <div class="flex justify-content-between">
                                <span>{{ step.name }}</span>
                                <i class="pi pi-ellipsis-h" (click)="handleStep(step)"></i>
                            </div>
                            <div class="flex">
                            </div>
                        </div>

                        <div class="kanban-cards card">

                            <div class="flex justify-content-between kanban-header">
                                <p>Activity</p>
                                <i class="pi pi-ellipsis-h" (click)="handleStepActivity(step)"></i>
                            </div>
                            @if (step?.integrationAlphaBatchEntityStepActivity?.length) {

                                <div *ngFor="let item of step?.integrationAlphaBatchEntityStepActivity"
                                     class="kanban-card">
                                    <p-tag [value]="item?.entityName"
                                           (click)="handleStepActivity(step,item)"></p-tag>

                                    <div class="kanban-cards card">

                                        <div class="flex justify-content-between kanban-header">
                                            <p>Request</p>
                                            <i class="pi pi-ellipsis-h"
                                               (click)="handleStepActivityRequest(item)"></i>
                                        </div>
                                        @if (item?.integrationAlphaBatchEntityStepActivityRequest?.length) {

                                            <div *ngFor="let request of item?.integrationAlphaBatchEntityStepActivityRequest"
                                                 class="kanban-card"
                                                 (click)="handleStepActivityRequest(item,request)">
                                                <p-tag [value]="request?.importService"></p-tag>
                                            </div>
                                        }
                                    </div>

                                </div>
                            }
                        </div>

                    </div>
                }
                <p-button icon="pi pi-plus" [rounded]="true" (click)="handleStep()"/>
            </div>
        </div>
    </div>
}
