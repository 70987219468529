export class NsNotificationSendRequestChannelDto {

    // notification channel type which should be used
    public notificationTypeChannelTypeId: number;

    // provide forced recipient address
    public forceRecipientAddress: string;

    // provide new subject
    public overwriteSubject: string;

    // provide new body
    public overwriteBody: string;

    public static createSimple(notificationTypeChannelTypeId: number) {
        const channel = new NsNotificationSendRequestChannelDto();
        channel.notificationTypeChannelTypeId = notificationTypeChannelTypeId;
        return channel;
    }

}