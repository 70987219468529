import {Route, Routes} from "@angular/router";
import {CustomerWelcomePage} from "./page/overview/customer-welcome/customer-welcome.page";
import {CustomerOnboardingTypePage} from "./page/overview/customer-onboarding-type/customer-onboarding-type.page";
import {CustomerSignatureTypePagePage} from "./page/overview/customer-signature-type-page/customer-signature-type-page.page";
import {CustomerInteractionTypePage} from "./page/overview/customer-interaction-type/customer-interaction-type.page";
import {CustomerTypePage} from "./page/overview/customer-type/customer-type.page";
import {CrDashboardPage} from "./page/dashboard/cr-dashboard/cr-dashboard.page";
import {CrCustomerComponent} from "./component/cr-customer/cr-customer.component";
import {CustomerExternalAliasPage} from "./page/overview/customer-external-alias/customer-external-alias.page";
import {ConfigPage} from "./page/overview/config/config.page";
import {
    CrCustomerPhoneContactAvailabilityComponent
} from "./component/cr-customer-phone-contact-availability/cr-customer-phone-contact-availability.component";
import {
    CrCustomerContractDetailsComponent
} from "./component/cr-customer-contract-details/cr-customer-contract-details.component";
import {CustomerNotificationTypePage} from "./page/overview/customer-notification-type/customer-notification-type.page";
import {CrCustomerObjectPage} from "./page/object/cr-customer-object-page/cr-customer-object.page";
import {CustomerSearchPage} from "./page/overview/customer-search/customer-search.page";
import {CrCustomerCreatePage} from "./page/object/cr-customer-create/cr-customer-create.page";
import {CrCustomerLastPage} from "./page/object/cr-customer-last/cr-customer-last.page";
import {CrRegistrationManageComponent} from "./page/overview/cr-registration-manage/cr-registration-manage.component";
import {
    CrManageRegistrationUserComponent
} from "./component/cr-manage-registration/cr-manage-registration-user/cr-manage-registration-user.component";
import {CrFormConfigPage} from "./page/overview/cr-form-config/cr-form-config-page.component";
import {CustomerOverviewPage} from "./page/customer-overview-page/customer-overview.page";
import {CrConfigPage} from "./page/overview/cr-config/cr-config.page";
import {CustomerFormTypePage} from "./page/overview/customer-form-type/customer-form-type.page";

export function CrSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        // {path: 'cr', component: CustomerOverviewPage},
        {path: 'cr/customers', component: CustomerTypePage},
        {path: 'cr/dashboard', component: CrDashboardPage},
        {path: 'cr/customers/:id/:view', component: CrCustomerObjectPage},
        {path: 'cr/customers/:id', component: CrCustomerObjectPage},

        // --- CONFIGURATION --------- START
        {path: 'cr/config', component: CrConfigPage},

        // ---- FRONT END
        {path: 'cr/customerWelcomes', component: CustomerWelcomePage},
        {path: 'cr/customerOnboardingTypes', component: CustomerOnboardingTypePage},
        {path: 'cr/customerFormTypes', component: CustomerFormTypePage},
        {path: 'cr/customerInteractionTypes', component: CustomerInteractionTypePage},
        {path: 'cr/customerSignatureTypes', component: CustomerSignatureTypePagePage},

        {path: 'cr/customerExternalAlias', component: CustomerExternalAliasPage},

        {path: 'cr/customerNotificationType', component: CustomerNotificationTypePage },
        {path: 'cr/form-config', component: CrFormConfigPage},
        // --- CONFIGURATION --------- END


        {path: 'cr/customerContractDetails/:cid', component: CrCustomerContractDetailsComponent},

        {path: 'cr/test', component: CrCustomerComponent},
        {path: 'cr/create', component: CrCustomerCreatePage},
        {path: 'cr/phone-availability', component: CrCustomerPhoneContactAvailabilityComponent},
        {path: 'cr/customerSearch', component: CustomerSearchPage},

        {path: 'cr/last', component: CrCustomerLastPage},
        {path: 'cr/registrationManage', component: CrRegistrationManageComponent},
        {path: 'cr/registrationManage/:id', component: CrManageRegistrationUserComponent},

    ];

    return routes;

}
