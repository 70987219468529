import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {IgDashboardComponent} from "./page/dashboard/ig-dashboard.component";


@NgModule({
  declarations: [
    IgDashboardComponent,
  ],
  exports:[
    IgDashboardComponent,
  ],
  imports: [
    CoreModule,

  ]
})
export class IgModule { }
 
 
 
