import {Component, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CmContractObjectViewTypeUiEnum} from "./data/cm-contract-object-view-type-ui.enum";
import {ContractService} from "../../../service/api/contract.service";
import {ContractDto} from "../../../dto/contract.dto";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {
    DtoImportObjectContext, DtoImportObjectContextEntry,
    DtoList,
    FilterCriteria, MvsCoreService,
    NavigationItem, ObjectIdentifierData,
    ObjectPageComponent, ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation,
    TopbarService
} from "@kvers/alpha-core-common";
import {MvsObjectCount} from "../../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {ObjectCountClass} from "../../../../core/ticket/data/object-count.interface";
import {OverlayPanel} from "primeng/overlaypanel";
import {
    CrCustomerObjectViewTypeUiEnum
} from "../../../../cr/page/object/cr-customer-object-page/data/cr-customer-object-view-type-ui.enum";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-cm-contract-page',
    templateUrl: './cm-contract.page.html',
    styleUrls: ['cm-contract.page.scss']
})
export class CmContractPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

    defaultLabel: string = "Vertragsansicht";
    viewType: CmContractObjectViewTypeUiEnum = CmContractObjectViewTypeUiEnum.standard;
    contractDto: ContractDto;

    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorObjectRequest: ObjectRequestList;
    selectedNavigationItem: string;
    customerObjectContext: DtoImportObjectContext;

    showCreateTicketOverlay: boolean;
    @ViewChild("op", {static: true}) createTicketOverlay: OverlayPanel;

    navigateViewTypes: NavigationItem[] = [
        {
            label: 'standard',
            action: 'standard',
            tooltip: 'Standard',
            icon: 'fa-regular fa-house',
            toggleable: false,
            default: true
        },
        {
            label: 'objects',
            action: 'objects',
            tooltip: 'Objekte/ Personen',
            icon: 'fa-regular fa-rectangle-vertical-history',
            toggleable: false,
        },

        {
            label: 'documentUpload',
            action: 'documentUpload',
            tooltip: 'Dokumente',
            icon: 'fa-regular fa-file-circle-plus',
            toggleable: false,
        },
        {
            label: 'notifications',
            action: 'notifications',
            tooltip: 'Kommunikation',
            icon: 'fa-regular fa-comments',
            toggleable: false,
        },

        {
            label: '',
            action: '',
            divider: true,
        },
        {
            label: 'genericTickets',
            action: 'genericTickets',
            tooltip: 'General Tickets',
            icon: 'fa-regular fa-ticket',
            toggleable: false,
        },
        {
            label: 'addTicket',
            action: 'addTicket',
            tooltip: 'Add Ticket',
            icon: 'pi pi-plus',
            toggleable: true,
            customFunction: () => this.customFunction()
        },
        {
            label: 'genericWorkflows',
            action: 'genericWorkflows',
            tooltip: 'General Workflows',
            icon: ' fa-regular fa-sharp-duotone fa-arrow-progress',
            toggleable: false,
        },

    ];

    constructor(
        protected processService: WfProcessService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected topbarService: TopbarService,
        protected contractService: ContractService,
        protected navigationService: MvsObjectNavigationService,
        protected coreService: MvsCoreService) {

        super(route, coreService);
    }

    ngOnInit() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem'] && params['navItem'] == this.selectedNavigationItem) {
                return;
            }

            if (params['navItem'] == undefined && this.selectedNavigationItem == 'addTicket') {
                this.toggleAddTicket();
                return;
            }

            if (params['navItem']) {
                const exists = this.checkNavigationEntryExist(this.navigateViewTypes, params['navItem']);
                if (exists) {
                    this.selectedNavigationItem = params['navItem'];
                    const viewType = CmContractObjectViewTypeUiEnum[this.selectedNavigationItem];
                    if (viewType != undefined) {
                        this.viewType = viewType;
                    }
                    this.postNavItemSelection(this.selectedNavigationItem);
                } else {
                    this.removeNavItemParam();
                }
            } else {
                this.selectedNavigationItem = this.navigateViewTypes[0].label;
                this.viewType = CmContractObjectViewTypeUiEnum[this.selectedNavigationItem];
                const queryParams = {};
                queryParams['navItem'] =  this.selectedNavigationItem;
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: queryParams,
                    queryParamsHandling: 'merge'
                });
            }
        });
        super.ngOnInit();
    }

    ngOnDestroy() {
        this.topbarService.clearButtons();
        this.removeNavItemParam();
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    protected getObjectType(): string {
        return "cm.Contract";
    }

    protected onRouteChangeBefore(params: any) {

        if (params["view"]) {
            this.viewType = <CmContractObjectViewTypeUiEnum>+params["view"];
        }

    }


    isBreadcrumbVisible(): boolean {
        return false;
    }

    refreshComponent() {
        // this.setMegaMenuItems();
        this.getContractDto();
    }

    setIndicators() {
        // this.setIndicatorsData();
        // this.setCoreIndicators();
    }


    // setCoreIndicators() {
    //     const ticketIndicator = new IndicatorButton('tm.Ticket','Tickets', this.ticketIndicatorFilterCriteria, 'top-right',null);
    //     const workFlowIndicator = new IndicatorButton('wf.WfProcess','Workflows', this.workflowIndicatorFilterCriteria, 'top-right',this.workflowIndicatorObjectRequest);
    //
    //     const buttons: IndicatorButton[] = [];
    //     buttons.push(ticketIndicator);
    //     buttons.push(workFlowIndicator);
    //
    //     this.topbarService.setButtons(buttons);
    //
    // }
    //
    // setIndicatorsData() {
    //
    //     this.ticketIndicatorFilterCriteria = [
    //         FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.contractDto?.joinCurrentMainCustomer.id),
    //         FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved),
    //         FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled)
    //
    //     ];
    //
    //     this.workflowIndicatorObjectRequest = this.processService.getRequestForProcessForObjectViaAlias(
    //         this.objectIdentifier.objectType,
    //         this.objectIdentifier.objectId,
    //         true
    //     );
    // }

    getContractDto() {

        const dtoRequest: ObjectRequestList = new ObjectRequestList(
            false,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []);

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(
            false,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode('contractType'),
            ObjectRequestComplexNode.createRelationNode("joinCurrentMainPartner", ObjectRequestRelation.createJoin("+currentMainPartner")),
            ObjectRequestComplexNode.createRelationNode("joinCurrentMainCustomer", ObjectRequestRelation.createJoin("+currentMainCustomer"))
                .addNodes(ObjectRequestComplexNode.createRelationNode("personDto", ObjectRequestRelation.createJoin("person")))
        );

        this.contractService.list(dtoRequest).subscribe((res: DtoList<ContractDto>) => {
            this.contractDto = res.entries[0];

            // this.setIndicators();

            let lastName: string, statusName = '';

            if (this.contractDto.contractTypeDto) {
                statusName = this.contractDto.contractTypeDto.name;
            }

            if (this.contractDto.joinCurrentMainCustomer) {
                this.customerObjectContext = new DtoImportObjectContext([new DtoImportObjectContextEntry('cr.Customer', this.contractDto.joinCurrentMainCustomer.id)]);
                lastName = this.contractDto.joinCurrentMainCustomer.personDto.lastName;
            }

            //rename tab name
            const tabName = `${lastName} - ${statusName}`;
            this.renameTab(tabName);

            if (!this.selectedNavigationItem) {
                this.selectedNavigationItem = this.navigateViewTypes[0].label;
            }
            this.setActivePage();
            this.initialized = true;
        });
    }

    handleIdSelect(id: number) {
        this.objectIdentifier.objectId = id;
        this.objectIdentifier = this.objectIdentifier.clone();
        this.refreshComponent();
    }

    isNavComponent(): NavigationItem[] {
        return this.navigateViewTypes;
    }

    checkNavigationEntryExist(items: NavigationItem[], label: string): boolean {
        const exists = items.find(item => item.label == label);
        if (exists) {
            return true;
        }
        return false;
    }

    removeNavItemParam(): void {
        const queryParams = {...this.route.snapshot.queryParams};
        delete queryParams['navItem'];
        this.router.navigate([], {queryParams: queryParams});
    }

    handleTicketsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);
        const ticketObject = this.navigateViewTypes.find(item => item.label == 'genericTickets');

        //TODO: Haris (implement in proper class)
        ticketObject['badge'] = data.countBadge;
        ticketObject['badgeTypeClass'] = data.objectTypeClass;
    }

    handleWorkflowsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);

        const workflowObject = this.navigateViewTypes.find(item => item.label == 'genericWorkflows');
        workflowObject['badge'] = data.countBadge;
        workflowObject['badgeTypeClass'] = data.objectTypeClass;

    }

    getObjectCountClass(event: MvsObjectCount): ObjectCountClass {
        let countBadge = 0;
        let objectTypeClass: string;

        if (!event.pendingCount && !event.completedCount) {
            countBadge = 0;
        } else if (event.completedCount && !event.pendingCount) {
            countBadge = event.completedCount;
            objectTypeClass = 'gray';
        } else if (!event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectTypeClass = 'green';
        } else if (event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectTypeClass = 'green';
        }

        return {countBadge: countBadge, objectTypeClass: objectTypeClass};
    }

    postNavItemSelection(navItem: string) {
        if (navItem == 'addTicket') {
            if (this.createTicketOverlay.overlayVisible) {
                this.createTicketOverlay.hide();
            } else {
                this.showCreateTicketOverlay = true;
                this.createTicketOverlay.toggle(event);
            }

        }
    }

    handleTicketCreation(event: ObjectIdentifierData) {
        this.createTicketOverlay.hide();
        this.showCreateTicketOverlay = false;
    }


    toggleAddTicket() {
        if (this.createTicketOverlay.overlayVisible) {
            this.createTicketOverlay.hide();
            this.selectedNavigationItem = null;
        } else {
            this.createTicketOverlay.toggle(event);
        }
    }

    customFunction() {
        this.showCreateTicketOverlay = true;
        this.toggleAddTicket();
    }

    handleNavigateToPartner() {
        if (!this.contractDto?.joinCurrentMainPartner) {
            return;
        }

        const object = MvsObjectNavigationEntry.createNavigationEntry('cm.Partner', this.contractDto.joinCurrentMainPartner.id,null, null, null, null, MvsObjectNavigationActionEnum.any, 'full');
        this.navigationService.navigateTo(object, 'right');


    }
}
