import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ContractOutstandingPaymentService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cm/contractOutstandingPayments')
  }
}