import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";


@Component({
    selector: 'jb-job-run-object-component',
    templateUrl: 'jb-job-run-object.component.html',
})
export class JbJobRunObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.handleName('Job Run')
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
