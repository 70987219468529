import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent, WidgetFactory} from "@kvers/alpha-ui";
import {
    FilterCriteria, NavigationItem, ObjectBaseModeComponent,
    ObjectIdentifier,
    ObjectRequestList,
    WidgetData,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../../../../core/helper/widget-function-call-back-create";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'cp-provision-agent-side',
    templateUrl: './cp-provision-agent-side.component.html',
})
export class CpProvisionAgentSideComponent extends ObjectBaseModeComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() name: string;

    widgetButtons: WidgetHelperButton[];
    currentView: string = 'provisionAgent';


    provisionAgentWidget: WidgetData;
    provisionAgentGroupWidget: WidgetData;

    navigationItems: NavigationItem[] = [
        {
            label: 'provisionAgent',
            action: 'provisionAgent',
            icon: 'fa-regular fa-info-circle',
            tooltip: 'Provision Agent',
            toggleable: false,
            default: true
        }, {
            label: 'provisionAgentGroup',
            action: 'provisionAgentGroup',
            icon: 'fas fa-users',
            tooltip: 'Provision Agent Group',
            toggleable: false,
            default: false
        }, {
            label: 'provisionAgentComment',
            action: 'provisionAgentComment',
            icon: 'fa fa-comment',
            tooltip: 'Provision Agent Comment',
            toggleable: false,
            default: false
        },

    ];

    activeNavigationItem: NavigationItem;


    constructor(protected route: ActivatedRoute) {
        super(route);
    }

    ngOnInit(): void {
        this.initComponent();
        this.handleNavigationItemsChange();
        super.ngOnInit();
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.activeNavigationItem = this.navigationItems[0];

        this.refreshProvisionAgentWidget();
        this.refreshProvisionAgentGroupWidget();
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    refreshProvisionAgentWidget() {

        this.provisionAgentWidget = WidgetFactory.createWidgetEntityData(
            'cp.provision.agent.data.widget',
            'Provision Agent',
            'cp.ProvisionAgent',
            this.objectIdentifier.objectId
        );

    }

    refreshProvisionAgentGroupWidget() {

        const filterCriteria = FilterCriteria.create('provisionAgent', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionAgentGroupWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.group.widget',
            'Provision Agent Group',
            'cp.ProvisionAgentGroup',
            'No Record',
            objectRequest
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'provisionAgentDtoId', fieldValue: this.objectIdentifier.objectId}]

        this.provisionAgentGroupWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    setView(view: string) {
        this.currentView = view;
    }

    handleNavigationItemsChange(): void {
        this.onNavigationItems.emit(this.navigationItems)
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
