<ng-container *ngIf="initialized">

<!--    <app-code-mirror-test></app-code-mirror-test>-->

<!--    <p-panel header="Header" [toggleable]="true" [iconPos]="'end'">-->
<!--        <p>-->
<!--            Lorem ipsum dolor sit amet...-->
<!--        </p>-->
<!--    </p-panel>-->

    <ticket-management></ticket-management>

<!--            <p-button (click)="trigger()" label="Toggle"></p-button>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->

<!--    <div-->
<!--            *ngFor="let button of buttons; let i = index"-->
<!--            class="flex align-items-center cursor gap-2 overflow-visible"-->
<!--            pRipple-->
<!--            [pTooltip]="button.tooltip"-->
<!--            tooltipPosition="bottom"-->
<!--            appendTo="body"-->
<!--            #tooltipRefs-->
<!--    >-->
<!--        <i class="{{ button.icon }} p-2"></i>-->
<!--        <span>{{ button.label }}</span>-->
<!--    </div>-->

<!--    <ticket-quick-create >-->
<!--        -->
<!--    </ticket-quick-create >-->

<!--    <p-button (click)="showHistory = !showHistory" label="Toggle"></p-button>-->
<!--    <p-button (click)="getMainObject()" label="MainTicket"></p-button>-->

<!--    <mvs-avatar-list></mvs-avatar-list>-->

<!--    <mvs-crud-object [objectType]="'tm.Ticket'" [objectId]="0" (onFormChange)="handleFormChange($event)">-->

<!--    </mvs-crud-object>-->

<!--    <mvs-user-object-access-output-component *ngIf="showHistory" [objectIdentifier]="{objectType: 'tm.Ticket', objectId: 39}">-->
<!--    </mvs-user-object-access-output-component>-->

<!--    <mvs-user-object-access-output-component *ngIf="showHistory" [objectIdentifier]="{objectType: 'tm.Ticket', objectId: 81}">-->
<!--    </mvs-user-object-access-output-component>-->



<!--        <p-button label="Add Ticket Identifier" class="ml-1" (click)="addTicketIdentifier()"></p-button>-->
<!--        <p-button label="Perform Interaction on Customer" class="ml-1" (click)="performChange('customer')"></p-button>-->
<!--        <p-button label="Perform Interaction on Ticket" class="ml-1" (click)="performChange('ticket')"></p-button>-->

<!--    <p-button label="Set Activity Context" (click)="setActivityContext()"></p-button>-->
<!--    <p-button class="ml-1" label="Remove Activity Context" (click)="removeActivityContext()"></p-button>-->
<!--    <p-button class="ml-1" label="Test" (click)="requestAPI()"></p-button>-->
<!--    <p-button class="ml-1" label="Show History" (click)="showHistory = !showHistory"></p-button>-->

<!--    <p-messages #messages severity="success" [closable]="true">-->
<!--        <ng-template pTemplate>-->
<!--            <div class="w-full flex justify-content-between align-items-center">-->
<!--                <div class="flex gap-3">-->
<!--                    <i class="fa-regular fa-check text-2xl"></i>-->
<!--                    <div class="flex flex-column gap-1">-->
<!--                        <span>You are in active ticket</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-template>-->
<!--    </p-messages>-->

<!--    <mvs-widget *ngIf="widgetUserRole" [widgetData]="widgetUserRole">-->

<!--    </mvs-widget>-->

<!--    <mvs-widget *ngIf="widgetUserRole1" [widgetData]="widgetUserRole1">-->

<!--    </mvs-widget>-->

<!--    <mvs-form-control-output-user-image *ngIf="showHistory" [value]="'um:100000:harisiqbal848@gmail.com'">-->

<!--    </mvs-form-control-output-user-image>-->

<!--    <mvs-context-history [objectTypeAlias]="'tm.Ticket'"-->
<!--                         [objectId]="231"-->
<!--                         [pageSize]="3"-->
<!--    >-->
<!--    </mvs-context-history>-->


<!--    <mvs-search-input [objectTypeAlias]="['cr.Customer']">-->

<!--    </mvs-search-input>-->

    <!--    <user-profile></user-profile>-->

<!--        <mvs-ticket-history [ticketId]="objectIdentifier.objectId">-->
<!--        </mvs-ticket-history>-->

    <!--    <user-profile-id [userId]="100000"></user-profile-id>-->
    <!--    <user-profile-id [userId]="83"></user-profile-id>-->
    <!--    <user-profile-id [userId]="154"></user-profile-id>-->

    <!--    <img class="h-7rem w-7rem border-circle"-->
    <!--         [src]="imgSrc"/>-->

    <!--    <p-button label="Create" (click)="createAction()"></p-button>-->
    <!--    <p-button label="Get Picture" (click)="getPicture()"></p-button>-->
    <!--    <p-button label="Delete Picture" (click)="delPicture()"></p-button>-->

    <!--    <tm-ticket-select-predefine-task [ticketId]="35">-->

    <!--    </tm-ticket-select-predefine-task>-->
    <!--    <cm-create-insurable-object-person-relation-person [customerPersonId]="5637"-->
    <!--                                                       [customerId]="5637"-->
    <!--    >-->
    <!--    </cm-create-insurable-object-person-relation-person>-->

    <!--    <create-tm-ticket-actions [ticketId]="35">-->

    <!--    </create-tm-ticket-actions>-->

    <!--    <cr-create-contract-combined-form-->
    <!--            [variation]="'Antrag'"-->
    <!--            [customerId]="5637">-->
    <!--    </cr-create-contract-combined-form>-->

    <!--    <mvs-ticket [visible]="true"-->
    <!--                [objectAlias]="'cr.Customer'"-->
    <!--                [objectId]="5637">-->

    <!--    </mvs-ticket>-->

    <!--    <xx-user-mock [user]="mockUser1"></xx-user-mock>-->
    <!--    <xx-user-mock [user]="mockUser2"></xx-user-mock>-->

    <!--    <xx-user-mock [user]="user2"></xx-user-mock>-->

    <!--        <mvs-widget *ngIf="widgetUserRole" [widgetData]="widgetUserRole">-->
    <!--        </mvs-widget>-->

<!--    <mvs-widget *ngIf="customerContractWidgetData"-->
<!--                [widgetData]="customerContractWidgetData">-->
<!--    </mvs-widget>-->

    <!--    <mvs-widget-->
    <!--            *ngIf="widgetUser"-->
    <!--            [widgetData]="widgetUser">-->
    <!--        <ng-template let-object="object" mvs-widget-custom-field-directive label="testDataField"-->
    <!--                     fieldId="testDataField">-->

    <!--            <div *ngIf="object.dto.id">-->
    <!--                <user-profile-id [userId]="object.dto.id"-->
    <!--                                 [readonly]="true">-->

    <!--                </user-profile-id>-->
    <!--            </div>-->


    <!--        </ng-template>-->
    <!--    </mvs-widget>-->
    <!--    <mvs-create-ticket-object [objectId]="5637" [objectAlias]="'cr.Customer'">-->

    <!--    </mvs-create-ticket-object>-->

    <!--    <div [formGroup]="formGroup">-->
    <!--        <p-calendar-->
    <!--                formControl="date"-->
    <!--                [disabled]="true">-->
    <!--        </p-calendar>-->
    <!--    </div>-->

    <!--    <p-button (click)="toggleField()" label="Disable"></p-button>-->

    <!--    <mvs-widget *ngIf="personRelationPersonWidget"-->
    <!--                [widgetData]="personRelationPersonWidget"-->
    <!--                [importObjectContext]="personRelationPersonObjectContext"-->
    <!--    >-->
    <!--    </mvs-widget>-->

    <!--    <cm-create-insurable-object-person-relation-person [customerPersonId]="5637"-->
    <!--                                                       (onObjectSelected)="handlePersonCreated($event)" O-->
    <!--    >-->

    <!--    </cm-create-insurable-object-person-relation-person>-->


    <!--    <h3>Amos Balusek</h3>-->
    <!--    <pm-household-persons [personId]="5637"></pm-household-persons>-->

    <!--    <h3>Clark Hagenbuch</h3>-->
    <!--    <pm-household-persons [personId]="1"></pm-household-persons>-->

    <!--    <h3>Jacquelyn Stelfox</h3>-->
    <!--    <pm-household-persons [personId]="16237"></pm-household-persons>-->

    <!--    <mvs-widget [widgetData]="householdWidget" *ngIf="householdWidget">-->
    <!--    </mvs-widget>-->

    <!--    <mvs-widget [widgetData]="householdPersonWidget"-->
    <!--                *ngIf="householdPersonWidget">-->
    <!--    </mvs-widget>-->

    <!--    <mvs-widget [widgetData]="personRelationPersonWidget"-->
    <!--                *ngIf="personRelationPersonWidget">-->
    <!--    </mvs-widget>-->


    <!--    <mvs-workflow [objectRequestList]="workflowIndicatorObjectRequest"-->
    <!--                  [visible]="true"-->
    <!--    >-->

    <!--    </mvs-workflow>-->

    <!--        <input [(ngModel)]="ticketObjectId">-->
    <!--    <p-button (click)="deleteTicketObjects()"></p-button>-->


    <!--    <div class="col-12 lg:col-4">-->
    <!--        <mvs-object-quick-view [objectIdentifier]="ticketIdentifier" [size]="'M'"></mvs-object-quick-view>-->
    <!--    </div>-->

    <!--    <mvs-widget class="mvs-widget"-->
    <!--                *ngIf="customerCallsCategoryWidget"-->
    <!--                [widgetData]="customerCallsCategoryWidget"-->
    <!--                (onObjectSelect)="onSetFilterCriteria($event, 'callResult')"-->
    <!--    >-->
    <!--    </mvs-widget>-->

    <!--    <mvs-widget-->
    <!--            *ngIf="customerContractWidgetData"-->
    <!--            [widgetData]="customerContractWidgetData">-->
    <!--    </mvs-widget>-->

    <!--    <mvs-widget-->
    <!--            *ngIf="customerCallsCategoryWidget"-->
    <!--            [widgetData]="customerCallsCategoryWidget">-->
    <!--    </mvs-widget>-->

    <!--    <mvs-widget-->
    <!--            *ngIf="customerContractWidgetData"-->
    <!--            [widgetData]="customerContractWidgetData">-->
    <!--    </mvs-widget>-->


</ng-container>