import {MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {ContractPersonDto} from "../../../../ci/dto/contract-person.dto";

export class CmInsurablePersonWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;

    constructor(protected configService: MvsConfigService,
                protected selectedContract: ContractPersonDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    addSelectedPartner(contractInsurableObjectDto: ContractPersonDto[]) {

        this.selectedContract = contractInsurableObjectDto;

        for (let contractInsurableObjectDto of this.selectedContract) {
            contractInsurableObjectDto['__color'] = 'var(--green-100)';
        }
    }


    getListStyle(rowDto: ContractPersonDto, rowData: any): object {
        let found: ContractPersonDto = null;
        for (let contractPersonDto of this.selectedContract) {

            if (contractPersonDto.personDtoId == rowDto['customerDtoId']) {
                found = contractPersonDto;
                break;
            }

            const isFound = contractPersonDto.personDto['relatedPerson'].find(item => rowDto.id == item.id);

            if (isFound) {
                found = contractPersonDto;
                break;
            }

        }

        if (found) {
            return {
                "border": "2px",
                "background-color": found['__color']
            };
        }
        return {};
    }

    public clone(): CmInsurablePersonWidgetStyle {

        const widgetStyle = new CmInsurablePersonWidgetStyle(this.configService, this.selectedContract);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;

        return widgetStyle;
    }

}
