import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {RpConfigPage} from "./page/overview/config/rp-config.page";
import {RpReportObjectComponent} from "./component/rp-report/rp-report-object.component";
import {RpTestConfigPage} from "./page/overview/test-config/rp-test-config.page";
import {BlockUIModule} from 'primeng/blockui';
import {FilterHelper} from "./helper/filter-helper";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {RpConfigNewPage} from "./page/overview/new-config/rp-config-new.page";


@NgModule({
  declarations: [
    RpConfigPage,
    RpReportObjectComponent,
    RpTestConfigPage,
    RpConfigNewPage
  ],
  exports:[
    RpConfigPage,
    RpReportObjectComponent,
    RpTestConfigPage,
    RpConfigNewPage
  ],
    imports: [
        CoreModule,
        BlockUIModule,
        ProgressSpinnerModule
    ]
})
export class RpModule { }
 
 
 
