import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FilterService, MenuItem, MessageService, TreeDragDropService} from "primeng/api";
import {WfProcessStepDto} from "../../../../wf/dto/wf-process-step.dto";
import {DtoDetail, FilterCriteriaChangeEvent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {FilterAttributeGroup} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {MetaDataEntityDto} from "@kvers/alpha-core-common";
import {MetaDataJoinRelationEnum} from "@kvers/alpha-core-common";
import {DataTypeHelper} from "@kvers/alpha-core-common";
import {FilterAttribute} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {GroupByUiTypeEnum} from "@kvers/alpha-core-common";
import {KpiHeaderDto} from "../../../../core/overview/dto/kpi-header.dto";
import {OverviewDto} from "../../../../core/overview/dto/overview.dto";
import {MvsConditionBuilderService, MvsFilterHelper} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-test-yasir',
    providers: [TreeDragDropService, MessageService],
    templateUrl: './test-yasir.page.html',
    styleUrls: ['./test-yasir.page.scss']
})
export class TestYasirPage implements OnInit, AfterViewInit {

    // stepItems: MenuItem[] & { runtimeSteps: DtoDetail }[];
    // stepItems= [] ;
    stepperActiveStep: number = 0;
    busy: boolean;
    sidebar: boolean = true;
    historyDetail: boolean = true;
    showStepName: boolean = true;
    showButton: number | null = null;
    widgetObject: WidgetData;
    objectIdentifier: ObjectIdentifier = new ObjectIdentifier('wf.WfProcess',74);
    // kpiCards: KpiHeaderDto[] = [
    //     { label: 'Total Documents', value: '100', otherInfo: 'Info1', otherInfoClass: 'class1', route: '/' },
    //     { label: 'Total Document Types', value: '10', otherInfo: 'Info2', otherInfoClass: 'class2', route: '/' },
    //     { label: 'Total Document Groups', value: '5', otherInfo: 'Info3', otherInfoClass: 'class3', route: '/' },
    //     { label: 'Total Assignment Types', value: '3', otherInfo: 'Info4', otherInfoClass: 'class4', route: '/' }
    // ];
    //
    overViewData: OverviewDto[] = [
        { label: 'Notification Overview', value: 100 },
        { label: 'Pending Notification', value: 10 },
        { label: 'Processed Notification', value: 5 },
        { label: 'Deleted Notification', value: 3 }
    ];
    pendingNotification: number = 5;
    processedNotification: number = 7;
    deletedNotification: number = 3;
    errorNotification: number = 2;
    users = [
        {id: 1, name: 'Marko', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 2, name: 'Salman', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 3, name: 'Ather', role: 1, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 4, name: 'Yasir', role: 0, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 5, name: 'Carlo', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 4, name: 'Daud', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        {id: 5, name: 'Haris', role: 2, avatarUrl: 'https://mvsalphaplatform-mvs-platform-dev.azuremicroservices.io/mvsa/cr/customers/5637/photo/$'},
        // ... more users
    ];
    widgetButtons = [
        {
            label: null,
            icon: 'fa-regular fa-file-lines bg-primary-100 text-primary p-2 border-round-sm line-height-2',
            display: true,
            action: 'default',
            tooltip: 'Comment History'
        }, {
            label: null,
            icon: 'fa-sharp fa-light fa-diagram-subtask',
            display: true,
            action: 'sub-task',
            tooltip: 'Sub Task',
            type: 'badge'

        }, {
            label: null,
            icon: 'fa-regular fa-paperclip-vertical',
            display: true,
            action: 'attachment',
            tooltip: 'Attachment',
            type: 'badge'
        },
        {
            label: null,
            icon: 'fa-regular fa-ticket',
            display: true,
            action: 'activePinTicket',
            tooltip: 'Active Ticket'
        },
        {
            label: null,
            icon: 'fa-regular fa-bell',
            display: true,
            action: 'notifications',
            tooltip: 'Notifications'
        },
        {
            label: null,
            icon: 'fa-regular fa-tags',
            display: true,
            action: 'tags',
            tooltip: 'Tags'
        },
        {
            label: null,
            icon: 'fa-regular fa-thumbtack text-700',
            display: true,
            action: 'pinTicket',
            tooltip: 'Pin Ticket'
        }

    ];
    dataWithIcons = [
        {
            icon: "pi pi-phone",
            heading: "Phone",
            value: "921354268",
            color: "text-green-500",
            backgroundColor: "bg-green-100"
        },
        {
            icon: "fas fa-envelope",
            heading: "Email",
            value: "asd@gmail.com",
            color: "text-blue-500",
            backgroundColor: "bg-blue-100"
        },
        {
            icon: "fas fa-home",
            heading: "Address",
            value: "1234 Street Name, City, Country",
            color: "text-purple-500",
            backgroundColor: "bg-purple-100"
        },
        {
            icon: "fas fa-birthday-cake",
            heading: "Birthday",
            value: "January 1, 1990",
            color: "text-red-500",
            backgroundColor: "bg-red-100"
        },
        {
            icon: "fas fa-user",
            heading: "Username",
            value: "user123",
            color: "text-yellow-600",
            backgroundColor: "bg-yellow-100"
        },
        {
            icon: "fas fa-globe",
            heading: "Website",
            value: "https://www.example.com",
            color: "text-cyan-500",
            backgroundColor: "bg-cyan-100"
        },
        {
            icon: "fas fa-building",
            heading: "Company",
            value: "Company Name Inc.",
            color: "text-orange-500",
            backgroundColor: "bg-orange-100"
        },
        {
            icon: "fas fa-graduation-cap",
            heading: "Education",
            value: "University Degree",
            color: "text-teal-500",
            backgroundColor: "bg-teal-100"
        }
];

    profileData = [
        { title: "ID", value: 62124 },
        { title: "name", value: "Carlo's Company" },
        { title: "shortName", value: "Carlo's Company" },
        { title: "partnerTypeDtoName", value: "Kreditinstitut" },
        { title: "Angelegt am", value: "06.07.2023 10:43:33" },
        { title: "Geändert von", value: "root" }
    ];
    selectableItems = [
        { label: 'A', title: 'Item 1', color: 'bg-purple-500'},
        { label: 'B', title: 'Item 2', color: 'bg-red-500'},
        { label: 'C', title: 'Item 3', color: 'bg-yellow-500'},
        { label: 'D', title: 'Item 4', color: 'bg-cyan-500' },
        { label: 'E', title: 'Item 5', color: 'bg-orange-500' }
    ];
    selectedItem = 0;
    tabItems: MenuItem[] | undefined;

    activeItem: MenuItem | undefined;

    customers: any[];
    representatives: any[];
    statuses: any[];
    loading: boolean = false;

    constructor(protected conditionBuilderService: MvsConditionBuilderService,
                private filterService: FilterService,
                protected metaService: MetaService) {
    }

    ngOnInit(): void {
        this.refreshComponent();
        this.tabItems = [
            { label: 'Home', icon: 'pi pi-fw pi-home' },
            { label: 'Calendar', icon: 'pi pi-fw pi-calendar' },
            { label: 'Edit', icon: 'pi pi-fw pi-pencil' },
            { label: 'Documentation', icon: 'pi pi-fw pi-file' },
            { label: 'Settings', icon: 'pi pi-fw pi-cog' }
        ];

        this.activeItem = this.tabItems[0];
        // let runtimeSteps:DtoDetail[] = []
        // for (let i=0; i<this.stepItems.length; i++) {
        //     runtimeSteps[i]['status']=0;
        // }

        // this.stepItems = [
        //     {
        //         label: 'Select Type',
        //         // runtimeSteps: runtimeSteps[0]
        //
        //     },
        //     {
        //         label: 'Check if exist',
        //         // runtimeSteps: runtimeSteps[1]
        //     },
        //     {
        //         label: 'Select Person',
        //         // runtimeSteps: runtimeSteps[2]
        //     },
        //     {
        //         label: 'Create New Customer',
        //         // runtimeSteps: runtimeSteps[3]
        //     },
        //     {
        //         label: 'Create Customer RElation',
        //         // runtimeSteps: runtimeSteps[4]
        //     }
        // ];
        this.showWidget();
        this.loading = true;

        this.customers = [
            {
                id: 1,
                name: 'John Doe',
                country: { name: 'USA', code: 'us' },
                representative: { name: 'Amy Elsner', image: 'amyelsner.png' },
                status: 'new',
                verified: true
            },
            {
                id: 2,
                name: 'Jane Smith',
                country: { name: 'Germany', code: 'de' },
                representative: { name: 'Anna Fali', image: 'annafali.png' },
                status: 'qualified',
                verified: false
            },
            // Add more customers as needed
        ];

        this.representatives = [
            { name: 'Amy Elsner', image: 'amyelsner.png' },
            { name: 'Anna Fali', image: 'annafali.png' },
        ];

        this.statuses = [
            { label: 'New', value: 'new' },
            { label: 'Qualified', value: 'qualified' },
            { label: 'Unqualified', value: 'unqualified' },
            { label: 'Negotiation', value: 'negotiation' },
            { label: 'Renewal', value: 'renewal' },
            { label: 'Proposal', value: 'proposal' },
            // Add more statuses as needed
        ];

        this.loading = false;
    }

    getSeverity(status: string) {
        switch (status) {
            case 'new':
                return 'info';
            case 'qualified':
                return 'success';
            case 'unqualified':
                return 'danger';
            case 'negotiation':
                return 'warning';
            case 'renewal':
                return 'warning';
            case 'proposal':
                return 'info';
            default:
                return null;
        }

    }

    showWidget() {
        this.widgetObject = new WidgetData();
        this.widgetObject.idAlias = "test.haris.form.test";
        this.widgetObject.name = "Customer";
        this.widgetObject.uiComponent = "object";
        this.widgetObject.dataProvider = "list";
        this.widgetObject.dataSource = "entity";
        this.widgetObject.dataProviderObject = "cr.Customer";
        this.widgetObject.dataProviderListRequest = new ObjectRequestList(
            true,
            [],
            []
        );
        this.widgetObject.setParamValue("size", "S");
        this.widgetObject.setParamValue("objectId", "84876");

    }

    onComponentCompleted(step: number) {
        // this.stepItems[this.stepperActiveStep].runtimeSteps['status'] = 1;
        this.stepperActiveStep = step;
    }

    newFilterCriteria: FilterCriteria[] | undefined;

    onShowViaAlias() {

        this.conditionBuilderService.showConditionBuilderEntityDialog(
            this.newFilterCriteria,
            null,
            "cr.Customer",
            false,
            (filterCriteria: FilterCriteria[]) => {
                alert("save was pressed");

                return filterCriteria.length >= 2;

            },
            (filterCriteria: FilterCriteria[]) => {

                if (filterCriteria && filterCriteria.length >= 1) {
                    return confirm("Are you sure?");

                } else {
                    return true;
                }

            },
            (filterCriteria: FilterCriteria[]) => {

                if (filterCriteria && filterCriteria.length >= 1) {
                    return confirm("Are you sure You want to Delete?");

                } else {
                    return true;
                }

            }
        );

    }

    onShowViaId() {

        this.conditionBuilderService.showConditionBuilderEntityDialog(
            this.newFilterCriteria,
            3001,
            null,
            false
        );

    }

    filterCriteria: FilterCriteria[] | undefined;
    filterAttributeGroups: FilterAttributeGroup[] | undefined;
    readOnly: boolean | undefined;
    initialized: boolean;

    refreshComponent() {


        let observableMetaData: Observable<MetaDataEntityDto> = this.metaService.getByAlias('cr.Customer', true, true, false);


        observableMetaData.subscribe(res => {

            const simpleAttributes: any = res.attributes;
            const joins = res.joins.filter((entry: any) => entry.joinRelationEnum === MetaDataJoinRelationEnum.manyToOne);

            const joinedAttributes = simpleAttributes.concat(joins);

            this.filterAttributeGroups = MvsFilterHelper.convertToAttributeGroup(joinedAttributes);
            this.initialized = true;

        });

    }

    ticketTypes = [
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },        { name: 'Bug' },
        { name: 'Feature Request' },
        { name: 'Support' },
        { name: 'Critical Issue' },
        // Add more items
    ];

    maxVisibleTickets = 0;
    showMoreButton = false;
    showAll = false;

    @ViewChild('container', { static: false }) container!: ElementRef;
    @ViewChildren('ticketElement') ticketElements!: QueryList<ElementRef>;

    ngAfterViewInit() {
        this.calculateVisibleTickets();
    }

    calculateVisibleTickets() {
        const containerWidth = this.container.nativeElement.offsetWidth;
        let currentWidth = 0;

        this.ticketElements.forEach((element, index) => {
            const ticketWidth = element.nativeElement.offsetWidth;
            if (currentWidth + ticketWidth <= containerWidth) {
                currentWidth += ticketWidth;
                this.maxVisibleTickets = index + 1;
            }
        });

        this.showMoreButton = this.maxVisibleTickets < this.ticketTypes.length;
    }

    toggleShowMore() {
        this.showAll = !this.showAll;
        this.maxVisibleTickets = this.showAll ? this.ticketTypes.length : this.maxVisibleTickets;
    }

    handleFilter(event: any) {
        console.log(event);
    }

    handleFilterChange(event: FilterCriteriaChangeEvent) {
        console.log(event);
    }

    protected readonly GroupByUiType = GroupByUiTypeEnum;
}
