<ng-container *ngIf="initialized">
    <!-- Breadcrumb -->
    <p-breadcrumb class="max-w-full" [model]="breadCrumbItems"
                  *ngIf="breadCrumbItems && breadCrumbItems.length && showBreadCrumb">
        <ng-template pTemplate="item" let-item>
            <ng-container>
                <a class="p-menuitem-link">
                    <span class="font-semibold {{ item?.lastItem ? 'text-primary' : '' }}">
            {{ item?.label }}
          </span>
                </a>
            </ng-container>
        </ng-template>
    </p-breadcrumb>


    <!-- Main Content -->
    <ng-container *ngIf="sideBarMenuItems">
        <div class="container">

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--************************************      Side Drawer      ************************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="side-drawer-sticky flex">
                <mvs-side-drawer
                        [menuItems]="sideBarMenuItems"
                        [showLabel]="false"
                        (onObjectSelect)="handleNavigationChange($event)"
                ></mvs-side-drawer>
            </div>


            <div class="content-area">

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--*************************************      Contract Base Type      ************************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'cm.ContractBaseType'">
                    <div class="col-12">

                        <!--  Widget to display ticket type data  -->
                        <mvs-widget
                                *ngIf="contractBaseTypeWidget && !isContractTypeUpdate"
                                [widgetData]="contractBaseTypeWidget"
                        ></mvs-widget>

                        <!--  Widget to update ticket type  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="contractBaseTypeUpdateWidget && isContractTypeUpdate"-->
<!--                                [widgetData]="contractBaseTypeUpdateWidget"-->
<!--                        ></mvs-widget>-->
                    </div>

                </div>


                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--********************************      Contract Base Type Reason      *******************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'cm.ContractBaseTypeReason'">
                    <div class="col-4">

                        <mvs-widget
                                *ngIf="contractBaseTypeReasonWidget"
                                [widgetData]="contractBaseTypeReasonWidget"
                                (onObjectSelect)="handleObjectViewWidget($event, 'name')"
                        ></mvs-widget>
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

                        <!--  Widget for creating or updating  -->
                        <mvs-widget
                                *ngIf="objectWidget && isCreateMode"
                                [widgetData]="objectWidget"
                        ></mvs-widget>
                    </div>
                </div>


                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--********************************      Automatic Tickets      *******************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'cm.ContractBaseTypeTicketType'">
                    <div class="col-4">

                        <mvs-widget
                                *ngIf="automaticTicketWidget"
                                [widgetData]="automaticTicketWidget"
                                (onObjectSelect)="handleObjectViewWidget($event, 'ticketTypeDtoName')"
                        ></mvs-widget>
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

                        <!--  Widget for creating or updating  -->
                        <mvs-widget
                                *ngIf="objectWidget && isCreateMode"
                                [widgetData]="objectWidget"
                        ></mvs-widget>
                    </div>
                </div>


            </div>
        </div>
    </ng-container>
</ng-container>
