import {Component} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria, MvsCoreService, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    NavigationItem,
    ObjectIdentifierData,
    PageComponent,
    WidgetData, WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {ActivatedRoute} from "@angular/router";


@Component({
    selector: 'cp-config-page',
    templateUrl: './cp-config.page.html',
})
export class CpConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "Provision Configuration";


    activeNavItem: string;
    queryParamSubscription: Subscription;

    navigationItems: NavigationItem[] = [
        {
            label: 'cp.ProvisionPeriod',
            action: 'cp.ProvisionPeriod',
            tooltip: 'Provisionsperioden',
            icon: 'fa fa-calendar-alt', // Represents a time period
            toggleable: false,
            default: true
        },
        {
            label: 'cp.ProvisionAgent',
            action: 'cp.ProvisionAgent',
            tooltip: 'Agenten',
            icon: 'fa fa-user-tie', // Represents an agent or professional
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionPartner',
            action: 'cp.ProvisionPartner',
            tooltip: 'Partner',
            icon: 'fa fa-industry-windows',
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionCategoryType',
            action: 'cp.ProvisionCategoryType',
            tooltip: 'Kategorien',
            icon: 'fa fa-layer-group', // Represents categories or grouped items
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionGroupType',
            action: 'cp.ProvisionGroupType',
            tooltip: 'Gruppen',
            icon: 'fa fa-users', // Represents a group of people or items
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionContractEligibilityType',
            action: 'cp.ProvisionContractEligibilityType',
            tooltip: 'Zulässigkeit',
            icon: 'fa fa-file-signature', // Represents contracts and eligibility documents
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionCancellationAccountTemplate',
            action: 'cp.ProvisionCancellationAccountTemplate',
            tooltip: 'Haftung & Storno',
            icon: 'fa fa-ban', // Represents cancellation or restriction
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionCurrency',
            action: 'cp.ProvisionCurrency',
            tooltip: 'Währung',
            icon: 'fa fa-money-bill-wave', // Represents money and currency
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionType',
            action: 'cp.ProvisionType',
            tooltip: 'Provisionstypen',
            icon: 'fa fa-cogs', // Represents settings or configurations
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionTemplateType',
            action: 'cp.ProvisionTemplateType',
            tooltip: 'Templates',
            icon: 'fa fa-file-alt', // Represents templates and documents
            toggleable: false,
            default: false
        },
        {
            label: 'cp.ProvisionRequests',
            action: 'cp.ProvisionRequests',
            tooltip: 'Provision Request Configuration',
            icon: 'fa-regular fa-flask-vial', // Represents settings or configurations
            toggleable: false,
            default: false
        },
    ];

    provisionPeriodWidget: WidgetData;

    // AGENT
    provisionAgentWidget: WidgetData;
    provisionAgentGroupWidget: WidgetData;
    provisionAgentCommentWidget: WidgetData;
    provisionAgentPoolWidget: WidgetData;
    provisionAgentPoolGroupWidget: WidgetData;
    provisionAgentPoolAgentWidget: WidgetData;

    // CATEGORY
    provisionCategoryTypeWidget: WidgetData;
    provisionCategoryTypeAssignmentWidget: WidgetData;
    indexTabCategory = 1;

    // GROUP
    provisionGroupTypeWidget: WidgetData;
    provisionGroupTypeAssignmentWidget: WidgetData;
    indexTabGroup = 1;

    // PARTNER
    provisionPartnerWidget: WidgetData;
    provisionPartnerAssignmentWidget: WidgetData
    indexTabPartner = 1;


    // Eligibility
    provisionContractEligibilityTypeWidget: WidgetData;
    provisionContractChangeEligibilityTypeWidget: WidgetData;
    provisionContractEligibilityWidget: WidgetData;
    provisionContractEligibilityCommentWidget: WidgetData;
    provisionContractOTPEligibilityWidget: WidgetData;
    provisionContractPriceEligibilityWidget: WidgetData;

    // Cancellation & Liability
    provisionCancellationAccountTemplateWidget: WidgetData;
    provisionLiabilityTemplateWidget: WidgetData;

    // Currency
    provisionCurrencyWidget: WidgetData;
    provisionCurrencyExchangeWidget: WidgetData;
    provisionCurrencyMonetaryValueWidget: WidgetData;

    provisionTemplateTypeWidget: WidgetData;
    provisionTypeWidget: WidgetData;

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService, protected navigationService: MvsObjectNavigationService) {
        super(route, coreService);
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams();
        this.initialized = true;
    }

    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
        });
    }

    refreshWidgets() {
        this.refreshProvisionPeriodWidget();
        this.refreshProvisionAgentWidget();
        this.refreshProvisionAgentPoolWidget();
        this.refreshProvisionAgentPoolAgentWidget();
        this.refreshProvisionCategoryTypeWidget();
        this.refreshProvisionGroupTypeWidget();
        this.refreshProvisionContractEligibilityTypeWidget();
        this.refreshProvisionContractChangeEligibilityTypeWidget();
        this.refreshProvisionContractEligibilityWidget();
        this.refreshProvisionContractOTPEligibilityWidget();
        this.refreshProvisionContractPriceEligibilityWidget();
        this.refreshProvisionCancellationAccountTemplateWidget();
        this.refreshProvisionLiabilityTemplateWidget();
        this.refreshProvisionCurrencyWidget();
        this.refreshProvisionCurrencyExchangeWidget();
        this.refreshProvisionCurrencyMonetaryValueWidget();
        this.refreshProvisionTemplateTypeWidget();
        this.refreshProvisionTypeWidget();

        this.refreshProvisionCategoryTypeAssignmentWidget();
        this.refreshProvisionGroupTypeAssignmentWidget();

        this.refreshProvisionPartnerWidget();
        this.refreshProvisionPartnerAssignmentWidget();


    }

    handleObjectSelect(object: ObjectIdentifierData) {

        if (object.objectType == 'cp.ProvisionAgent') {
            this.refreshProvisionAgentGroupWidget(object.objectId);
            this.refreshProvisionAgentCommentWidget(object.objectId);
        }

        if (object.objectType == 'cp.ProvisionAgentPool') {
            this.refreshProvisionAgentPoolGroupWidget(object.objectId);
        }

        if (object.objectType == 'cp.ProvisionContractEligibility') {
            this.refreshProvisionContractEligibilityCommentWidget(object.objectId)
        }


    }

    refreshProvisionPeriodWidget() {
        this.provisionPeriodWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.period.widget',
            'Provision Period',
            'cp.ProvisionPeriod',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionAgentWidget() {
        this.provisionAgentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.widget',
            'Provision Agent',
            'cp.ProvisionAgent',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionAgentGroupWidget(provisionAgentId: number) {

        const filterCriteria = FilterCriteria.create('provisionAgent', FilterCriteria.cOperatorEqual, provisionAgentId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionAgentGroupWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.group.widget',
            'Provision Agent Group',
            'cp.ProvisionAgentGroup',
            'No Record',
            objectRequest
        );

        this.provisionAgentGroupWidget.functionCallbacks = this.widgetToolbarCreate('provisionAgentDtoId', provisionAgentId);
    }


    refreshProvisionAgentCommentWidget(provisionAgentId: number) {

        const filterCriteria = FilterCriteria.create('provisionAgent', FilterCriteria.cOperatorEqual, provisionAgentId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionAgentCommentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.comment.widget',
            'Provision Agent Comment',
            'cp.ProvisionAgentComment',
            'No Record',
            objectRequest
        );

        this.provisionAgentCommentWidget.functionCallbacks = this.widgetToolbarCreate('provisionAgentDtoId', provisionAgentId);

    }

    refreshProvisionAgentPoolWidget() {
        this.provisionAgentPoolWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.pool.widget',
            'Provision Agent Pool',
            'cp.ProvisionAgentPool',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionAgentPoolGroupWidget(provisionAgentPooId: number) {

        const filterCriteria = FilterCriteria.create('provisionAgentPool', FilterCriteria.cOperatorEqual, provisionAgentPooId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionAgentPoolGroupWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.pool.group.widget',
            'Provision Agent Pool Group',
            'cp.ProvisionAgentPoolGroup',
            'No Record',
            objectRequest
        );

        this.provisionAgentPoolGroupWidget.functionCallbacks = this.widgetToolbarCreate('provisionAgentPoolDtoId', provisionAgentPooId);
    }

    refreshProvisionAgentPoolAgentWidget() {
        this.provisionAgentPoolAgentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.agent.pool.agent.widget',
            'Provision Agent Pool Agent',
            'cp.ProvisionAgentPoolAgent',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionCategoryTypeWidget() {
        this.provisionCategoryTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.category.type.widget',
            'Provision Category Type',
            'cp.ProvisionCategoryType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionCategoryTypeAssignmentWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.provisionCategoryTypeAssignmentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.category.type.assignment.widget',
            '',
            'cp.ProvisionCategoryTypeAssignment',
            'No Record',
            objectRequest
        );

        // this.provisionCategoryTypeAssignmentWidget.functionCallbacks = this.widgetToolbarCreate('provisionCategoryTypeDtoId', provisionAgentPooId);
    }

    refreshProvisionGroupTypeWidget() {
        this.provisionGroupTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.group.type.widget',
            'Provision Group Type',
            'cp.ProvisionGroupType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionGroupTypeAssignmentWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.provisionGroupTypeAssignmentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.group.type.assignment.widget',
            '',
            'cp.ProvisionGroupTypeAssignment',
            'No Record',
            objectRequest
        );

        // this.provisionGroupTypeAssignmentWidget.functionCallbacks = this.widgetToolbarCreate('provisionGroupTypeDtoId');
    }

    refreshProvisionContractEligibilityTypeWidget() {
        this.provisionContractEligibilityTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.eligibility.type.widget',
            '',
            'cp.ProvisionContractEligibilityType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionContractChangeEligibilityTypeWidget() {
        this.provisionContractChangeEligibilityTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.change.eligibility.type.widget',
            '',
            'cp.ProvisionContractChangeEligibilityType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionContractEligibilityWidget() {
        this.provisionContractEligibilityWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.eligibility.widget',
            '',
            'cp.ProvisionContractEligibility',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionContractEligibilityCommentWidget(provisionAgentPooId: number) {

        const filterCriteria = FilterCriteria.create('provisionContractEligibility', FilterCriteria.cOperatorEqual, provisionAgentPooId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.provisionContractEligibilityCommentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.eligibility.comment.widget',
            'Provision Contract Eligibility Comment',
            'cp.ProvisionContractEligibilityComment',
            'No Record',
            objectRequest
        );

        this.provisionContractEligibilityCommentWidget.functionCallbacks = this.widgetToolbarCreate('provisionContractEligibilityDtoId', provisionAgentPooId);
    }


    refreshProvisionContractOTPEligibilityWidget() {
        this.provisionContractOTPEligibilityWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.otp.eligibility.widget',
            'Provision Contract OTP Eligibility ',
            'cp.ProvisionContractOneTimePaymentEligibility',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionContractPriceEligibilityWidget() {
        this.provisionContractPriceEligibilityWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.contract.price.eligibility.widget',
            'Provision Contract Price Eligibility ',
            'cp.ProvisionContractPriceEligibility',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionCancellationAccountTemplateWidget() {
        this.provisionCancellationAccountTemplateWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.cancellation.account.template.widget',
            'Provision Cancellation Account Template',
            'cp.ProvisionCancellationAccountTemplate',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionLiabilityTemplateWidget() {
        this.provisionLiabilityTemplateWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.liability.template.widget',
            'Provision liability Template',
            'cp.ProvisionLiabilityTemplate',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionCurrencyWidget() {
        this.provisionCurrencyWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.currency.widget',
            'Provision Currency',
            'cp.ProvisionCurrency',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionCurrencyExchangeWidget() {
        this.provisionCurrencyExchangeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.currency.exchange.widget',
            'Provision Currency Exchange',
            'cp.ProvisionCurrencyExchange',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionCurrencyMonetaryValueWidget() {
        this.provisionCurrencyMonetaryValueWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.currency.monetary.value.widget',
            'Provision Currency Monetary Value',
            'cp.ProvisionCurrencyMonetaryValue',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionTemplateTypeWidget() {
        this.provisionTemplateTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.template.type.widget',
            'Provision Template Type',
            'cp.ProvisionTemplateType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionPartnerWidget() {
        this.provisionPartnerWidget = WidgetFactory.createWidgetTableEntity(
            'cp.config.provision.partner.widget',
            '',
            'cp.ProvisionPartner',
            'No Record',
            this.getObjectRequestList()
        );
    }

    refreshProvisionPartnerAssignmentWidget() {
        this.provisionPartnerAssignmentWidget = WidgetFactory.createWidgetTableEntity(
            'cp.config.provision.partner.assignment.widget',
            '',
            'cp.ProvisionPartnerAssignment',
            'No Record',
            this.getObjectRequestList()
        );
    }


    refreshProvisionTypeWidget() {
        this.provisionTypeWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision.type.widget',
            'Provision Type',
            'cp.ProvisionType',
            'No Record',
            this.getObjectRequestList()
        );
    }

    navigateTo(object: ObjectIdentifierData) {
        const navigationObject = MvsObjectNavigationEntry.createNavigationEntry(object.objectType, object.objectId, 'object', null, null, null, MvsObjectNavigationActionEnum.any,'side');

        this.navigationService.navigateTo(navigationObject,'right');
        // this.navigationService.openObjectInNewTab(navigationObject);
    }

    /**
     * defaulting fields for adding new entry
     * @param fieldName
     * @param fieldValue
     */
    widgetToolbarCreate(fieldName: string, fieldValue: number) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[fieldName] = fieldValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(fieldName, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }


    getObjectRequestList(): ObjectRequestList {
        return ObjectRequestList.createBasic(true, [], []);
    }

    public getHeaderName(): string {

        let navigationItem = undefined;

        if (this.activeNavItem) {
            navigationItem = this.navigationItems.find(value => value.label == this.activeNavItem);
        }

        if (!navigationItem) {
            return "Provision Configuration";
        } else {
            return navigationItem.tooltip;
        }

    }

    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }
}
