<ng-container *ngIf="initialized">
    <!-- Breadcrumb -->
    <p-breadcrumb class="max-w-full" [model]="breadCrumbItems"
                  *ngIf="breadCrumbItems && breadCrumbItems.length && showBreadCrumb">
        <ng-template pTemplate="item" let-item>
            <ng-container>
                <a class="p-menuitem-link">
                    <span class="font-semibold {{ item?.lastItem ? 'text-primary' : '' }}">
            {{ item?.label }}
          </span>
                </a>
            </ng-container>
        </ng-template>
    </p-breadcrumb>


    <!-- Main Content -->
    <ng-container *ngIf="sideBarMenuItems">
        <div class="container">

            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->
            <!--************************************      Side Drawer      ************************************-->
            <!--***********************************************************************************************-->
            <!--***********************************************************************************************-->

            <div class="side-drawer-sticky flex">
                <mvs-side-drawer
                        [menuItems]="sideBarMenuItems"
                        [showLabel]="false"
                        (onObjectSelect)="handleNavigationChange($event)"
                ></mvs-side-drawer>
            </div>


            <div class="content-area">

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--*************************************      TicketType      ************************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketType'">
                    <div class="col-12">

                        <!--  Widget to display ticket type data  -->
                        <mvs-widget
                                *ngIf="ticketTypeWidget && !isTicketTypeUpdate"
                                [widgetData]="ticketTypeWidget"
                        ></mvs-widget>

                        <!--                                [widgetToolbarButtons]="ticketTypeToolbarButton"-->
                        <!--                                (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketType')"-->

                        <!--  Widget to update ticket type  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="ticketTypeUpdateWidget && isTicketTypeUpdate"-->
<!--                                [widgetData]="ticketTypeUpdateWidget"-->
<!--                                [widgetToolbarButtons]="ticketTypeUpdateToolbarButton"-->
<!--                                (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketType')"-->
<!--                        ></mvs-widget>-->
                    </div>

                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--**********************************      TicketTypeAction      *********************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeAction'">
                    <div class="col-4">

                        <p-accordion [multiple]="true" *ngIf="ticketTypeActionList && ticketTypeActionList.length">
                            <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                                <p-accordionTab
                                        [header]="item.name"
                                        *ngIf="getItemData(item, ticketTypeActionList, 'ticketTypeDtoId').length > 0"
                                >
                                    <p-table [value]="getItemData(item, ticketTypeActionList, 'ticketTypeDtoId')">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <!--                                                <th>Type</th>-->
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-field>
                                            <tr (click)="handleActionRowSelect(field)" class="pointer">
                                                <td>{{ field.id }}</td>
                                                <td>{{ field.name }}</td>
                                                <!--                                                <td>{{ field.ticketTypeDtoName }}</td>-->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>

                            </ng-container>
                        </p-accordion>

                        <mvs-widget
                                *ngIf="ticketTypeActionWidget"
                                [widgetData]="ticketTypeActionWidget"
                                (onObjectSelect)="handleTicketTypeAction($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeAction')"-->
                    </div>

                    <div class="col-8">


                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

                        <mvs-widget
                                *ngIf="ticketTypeActionTicketMappingWidget && ticketTypeActionInternalType == TicketTypeActionTypeInternal.ticket"
                                [widgetData]="ticketTypeActionTicketMappingWidget"
                        ></mvs-widget>

                        <mvs-widget
                                *ngIf="ticketTypeActionProcessMappingWidget && ticketTypeActionInternalType == TicketTypeActionTypeInternal.process"
                                [widgetData]="ticketTypeActionProcessMappingWidget"
                        ></mvs-widget>



<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeObjectRole')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--********************************      TicketTypeObjectRole      *******************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeObjectRole'">
                    <div class="col-4">

                        <p-accordion [multiple]="true"
                                     *ngIf="ticketTypeObjectRoleList && ticketTypeObjectRoleList.length">
                            <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                                <p-accordionTab
                                        [header]="item.name"
                                        *ngIf="getItemData(item, ticketTypeObjectRoleList, 'ticketTypeDtoId').length > 0"
                                >
                                    <p-table [value]="getItemData(item, ticketTypeObjectRoleList, 'ticketTypeDtoId')">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <!--                                                <th>Type</th>-->
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-field>
                                            <tr (click)="handleRowSelect('tm.TicketTypeObjectRole',field.id)"
                                                class="pointer">
                                                <td>{{ field.id }}</td>
                                                <td>{{ field.name }}</td>
                                                <!--                                                <td>{{ field.ticketTypeDtoName }}</td>-->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>

                        <mvs-widget
                                *ngIf="ticketTypeObjectRoleWidget"
                                [widgetData]="ticketTypeObjectRoleWidget"
                                (onObjectSelect)="handleObjectViewWidget($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeObjectRole')"-->
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeObjectRole')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--********************************      TicketTypeObjectType      *******************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeObjectType'">
                    <div class="col-4">

                        <p-accordion [multiple]="true"
                                     *ngIf="ticketTypeObjectTypeList && ticketTypeObjectTypeList.length">
                            <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                                <p-accordionTab
                                        [header]="item.name"
                                        *ngIf="getItemData(item, ticketTypeObjectTypeList, 'ticketTypeDtoId').length > 0"
                                >
                                    <p-table [value]="getItemData(item, ticketTypeObjectTypeList, 'ticketTypeDtoId')">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <!--                                                <th>Type</th>-->
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-field>
                                            <tr (click)="handleRowSelect('tm.TicketTypeObjectType',field.id)"
                                                class="pointer">
                                                <td>{{ field.id }}</td>
                                                <td>{{ field.name }}</td>
                                                <!--                                                <td>{{ field.ticketTypeDtoName }}</td>-->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>

                        <mvs-widget
                                *ngIf="ticketTypeObjectTypeWidget"
                                [widgetData]="ticketTypeObjectTypeWidget"
                                (onObjectSelect)="handleObjectViewWidget($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeObjectType')"-->
                    </div>

                    <!--  Widget to display data  -->
                    <div class="col-8">
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeObjectType')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--****************************      TicketTypeNotificationType      *****************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeNotificationType'">
                    <div class="col-4">

                        <p-accordion [multiple]="true"
                                     *ngIf="ticketTypeNotificationTypeList && ticketTypeNotificationTypeList.length">
                            <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                                <p-accordionTab
                                        [header]="item.name"
                                        *ngIf="getItemData(item, ticketTypeNotificationTypeList, 'ticketTypeDtoId').length > 0"
                                >
                                    <p-table
                                            [value]="getItemData(item, ticketTypeNotificationTypeList, 'ticketTypeDtoId')">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <!--                                                <th>Type</th>-->
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-field>
                                            <tr (click)="handleRowSelect('tm.TicketTypeNotificationType',field.id)"
                                                class="pointer">
                                                <td>{{ field.id }}</td>
                                                <td>{{ field.name }}</td>
                                                <!--                                                <td>{{ field.ticketTypeDtoName }}</td>-->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>

                        <mvs-widget
                                *ngIf="ticketTypeNotificationTypeWidget"
                                [widgetData]="ticketTypeNotificationTypeWidget"
                                (onObjectSelect)="handleTicketTypeNotificationType($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeNotificationType')"-->
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>


                        <mvs-widget
                                *ngIf="ticketTypeNotificationTypeMappingWidget"
                                [widgetData]="ticketTypeNotificationTypeMappingWidget"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeNotificationType')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--*****************************      TicketTypeCompleteStatus      ******************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeCompleteStatus'">
                    <div class="col-4">

                        <p-accordion [multiple]="true"
                                     *ngIf="ticketTypeCompletionList && ticketTypeCompletionList.length">
                            <ng-container *ngFor="let item of ticketTypes.reverse(); index as i">
                                <p-accordionTab
                                        [header]="item.name"
                                        *ngIf="getItemData(item, ticketTypeCompletionList, 'ticketTypeDtoId').length > 0"
                                >
                                    <p-table [value]="getItemData(item, ticketTypeCompletionList, 'ticketTypeDtoId')">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <!--                                                <th>Type</th>-->
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-field>
                                            <tr (click)="handleRowSelect('tm.TicketTypeCompleteStatus',field.id)"
                                                class="pointer">
                                                <td>{{ field.id }}</td>
                                                <td>{{ field.name }}</td>
                                                <!--                                                <td>{{ field.ticketTypeDtoName }}</td>-->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>

                        <mvs-widget
                                *ngIf="ticketTypeCompletionStatusWidget"
                                [widgetData]="ticketTypeCompletionStatusWidget"
                                (onObjectSelect)="handleObjectViewWidget($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeCompleteStatus')"-->
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeCompleteStatus')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>

                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->
                <!--**********************************      TicketTypeField      **********************************-->
                <!--***********************************************************************************************-->
                <!--***********************************************************************************************-->

                <div class="grid" *ngIf="selectedItem?.id == 'tm.TicketTypeField'">
                    <div class="col-4">
                        <mvs-widget
                                *ngIf="ticketTypeFieldWidget"
                                [widgetData]="ticketTypeFieldWidget"
                                (onObjectSelect)="handleObjectViewWidget($event)"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarCreateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeField')"-->
                    </div>

                    <div class="col-8">

                        <!--  Widget to display data  -->
                        <mvs-widget
                                *ngIf="objectViewWidget && !isCreateMode"
                                [widgetData]="objectViewWidget"
                        ></mvs-widget>

<!--                        [widgetToolbarButtons]="widgetToolbarUpdateButtons"-->
<!--                        (onToolbarButtonClick)="handleToolbarButtonClick($event, 'tm.TicketTypeField')"-->

                        <!--  Widget for creating or updating  -->
<!--                        <mvs-widget-->
<!--                                *ngIf="objectWidget && isCreateMode"-->
<!--                                [widgetData]="objectWidget"-->
<!--                        ></mvs-widget>-->
                    </div>
                </div>


            </div>
        </div>
    </ng-container>
</ng-container>
