import {Component, OnDestroy, OnInit} from '@angular/core';
import {DtoDetail, MvsCoreService, MvsObjectService, PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";


@Component({
  selector: 'mvs-um-config',
  templateUrl: './um-config.page.html',
  styleUrls: ['./um-config.page.scss']
})
export class UmConfigPage extends PageComponent implements OnInit, OnDestroy {

  objectBrowserRequest: any;
  subObjectBrowserRequest: any;

  defaultLabel: string = "User und Berechtigungen";

  widgetUser: WidgetData;
  widgetUserRole: WidgetData;
  widgetRole: WidgetData;
  widgetDocumentConfiguration: WidgetData;
  widgetNotification: WidgetData;

  activeIndex: number = 0;
  selectedUserId: number;
  avatarDialogVisible: boolean;

  constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
  }

  initComponent(): void {
    this.refreshUserWidget();

    this.widgetRole = WidgetFactory.createWidgetList(
        "am.config.role",
        "Rollen",
        "table",
        "list",
        "entity",
        "um.Role",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("name", true)])
    );

    this.widgetUserRole = WidgetFactory.createWidgetList(
        "am.config.user.role",
        "Berechtigungen",
        "table",
        "list",
        "entity",
        "um.UserRole",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetDocumentConfiguration = WidgetFactory.createWidgetList(
        "am.config.document.configuration",
        "Dokumentkonfiguration",
        "table",
        "list",
        "entity",
        "um.UmDocumentConfiguration",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetNotification = WidgetFactory.createWidgetList(
        "um.config.notification.configuration",
        "Benachrichtigungen",
        "table",
        "list",
        "entity",
        "um.UserNotificationType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [])
    );

    this.initialized = true;

  }

  ngOnDestroy() {
  }

  refreshUserWidget() {
    this.widgetUser = WidgetFactory.createWidgetList(
        "um.config.user",
        "User",
        "table",
        "list",
        "entity",
        "um.User",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("username", true)])
    );
  }

  resetDialog() {
    this.avatarDialogVisible = false;
    this.selectedUserId = null;
  }

  handleUploadAvatar(userId: number) {
    this.selectedUserId = userId;
    this.avatarDialogVisible = true;
  }

  handleAvatarChanged(userId: number) {
    this.widgetUser = null;
    this.refreshUserWidget();
  }

}
