<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-3">
            <div class="mvs-widget h-full flex flex-column gap-3">
                <mvs-widget
                        *ngIf="contractStatusWidgetData"
                        [widgetData]="contractStatusWidgetData"
                        (onObjectSelect)="handleCategoryStatusSelect($event)"
                ></mvs-widget>

    <!--            <mvs-widget-->
    <!--                    *ngIf="contractStatusDateWidgetData"-->
    <!--                    [widgetData]="contractStatusDateWidgetData"-->
    <!--            ></mvs-widget>-->

                <mvs-widget
                        *ngIf="contractStatusPeriodWidgetData"
                        [widgetData]="contractStatusPeriodWidgetData"
                ></mvs-widget>
            </div>

        </div>



        <div class="col-9">
            <div class="mvs-widget h-full">
                <mvs-widget
                        *ngIf="contractStatusHistoryChartWidget"
                        [widgetData]="contractStatusHistoryChartWidget"
                        (onObjectSelect)="handleCategoryStatusSelect($event)"
                ></mvs-widget>
            </div>
        </div>

    </div>

</ng-container>
