import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiServiceMeInterface} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {AmSearchFlexibleAgentComponent} from "../../search/am-search-flexible-agent/am-search-flexible-agent.component";
import {AgentDto} from "../../dto/agent.dto";

@Injectable({
  providedIn: 'root'
})
export class AgentService extends MvsCrudService implements MvsApiServiceMeInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/am/agents');
  }

  /**
   * Get user's agent assignment.
   */
  me(): Observable<AgentDto> {
    const url = `${this.apiUrl}/me`;
    return <Observable<AgentDto>>this.getInternal(url);
  }


  getFlexibleSearchComponent(): Type<any> {
    return AmSearchFlexibleAgentComponent;
  }

}
