import {DtoDetail} from "@kvers/alpha-core-common";
import { CustomerDto } from "./customer.dto";
import { CustomerEntityAssignmentType } from "../enum/customer-entity-assignment-type.enum";
import {ContractDto} from "../../cm/dto/contract.dto";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {ContractInsurableObjectDto} from "../../ci/dto/contract-insurable-object.dto";

export class CustomerContractDto extends DtoDetail{


    public customerDto? : CustomerDto;
    public customerDtoId : number;
    public customerDtoName : string;
    public contractDto? : ContractDto;
    public contractDtoId : number;
    public contractDtoName : string;
    public assignmentType: CustomerEntityAssignmentType;
    public entityStatus: EntityStatusEnum;
    public euCustomerVisible: boolean;

}