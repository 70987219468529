<ng-container *ngIf="initialized">

    <div class="flex flex-wrap">

        <ng-container *ngFor="let item of customerRelatedPersons">
<!--            <div class="flex justify-content-between">-->
                <div >
                    <p-splitButton class="button-pill mx-2"
                                   styleClass="filter-btn"
                                   menuStyleClass="split-button-menu-hover"
                                   [ngStyle]="item['__selected'] && { 'background-color': item['__backgroundColor'],'border': '2px solid' + item['__color'] }"
                                   [model]="splitButtonItems"
                                   (onClick)="handleSelectRelatedPerson(item, $event)"
                                   [ngClass]="item['__selected'] ? 'button-pill-selected' : '' "
                                   (onDropdownClick)="handleButtonClick(item);">

                        <ng-template pTemplate="content">
                            <span [innerHTML]="item['uiCustomerName']"></span>
                        </ng-template>

                    </p-splitButton>
<!--                    <span class="text-color-secondary mx-3">Clear All</span>-->
                </div>

<!--                <div class="flex align-items-center">-->
<!--                    <span class="text-color-secondary mx-3"> Show Only</span>-->

<!--                    <div class="border-1 border-200 p-2">-->
<!--                        <p-checkbox class="mr-3" styleClass="test" formControlName="city" value="New York"-->
<!--                                    inputId="ny"></p-checkbox>-->
<!--                        <label>-->
<!--                            <i class="fa-sharp fa-regular fa-house"></i> HouseHold-->
<!--                        </label>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </ng-container>

    </div>

    <!--</div>-->
</ng-container>