import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    IntegrationAdapterTypeObjectBaseComponent
} from "../../component/object-base-components/integration-adapter-type-object-base-component/integration-adapter-type-object-base.component";

@Injectable({
    providedIn: 'root'
})
export class IntegrationAdapterTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/hc/integrationAdapterTypes')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return IntegrationAdapterTypeObjectBaseComponent
        }
        return null;
    }

}