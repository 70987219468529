import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-ui-field-group-page',
  templateUrl: './ui-field-group.page.html',
  styleUrls: ['./ui-field-group.page.scss']
})
export class UiFieldGroupPageComponent extends PageComponent{

  ngOnInit(): void {
    super.ngOnInit()
  }

}
