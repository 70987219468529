import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

export class WfProcessRuntimeBindingDto {

    public processTypeStepActivity: number;
    public alias: string;

    // if true then objectId + type are provided, if false simple value is provided
    public objectBinding: boolean;

    public objectType?: ObjectTypeDto;
    public objectId?: number;

    public objectValue?: DtoDetail;
    public simpleValue?: any;

    public includeInFilter: boolean;


    public static deriveBinding(bindings: WfProcessRuntimeBindingDto[], alias: string): WfProcessRuntimeBindingDto | undefined {

        if (!bindings || !bindings.length) {
            return undefined;
        }

        for (let binding of bindings) {
            if (binding.alias == alias) {
                return binding;
            }
        }

        return undefined;
    }


}