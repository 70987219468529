import {Component, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {TicketRelTicketService} from '../../service/api/ticket-rel-ticket.service';
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {TicketService} from "../../service/api/ticket.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {TmTicketRelTicketDto} from "../../dto/tm-ticket-rel-ticket.dto";
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../enum/tm-status.enum";

@Component({
    selector: 'mvs-sub-ticket',
    templateUrl: './sub-ticket.component.html',
    styleUrls: ['./sub-ticket.component.scss']
})
export class SubTicketComponent
    extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ticketId: number;
    @Output() changeMode: EventEmitter<string> = new EventEmitter<string>();
    @Output() eventSubTicketId: EventEmitter<number> = new EventEmitter<number>();

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    dtoListSubTickets: DtoList<TmTicketRelTicketDto>;
    serviceTicket: TicketService;
    serviceTicketRelTicket: TicketRelTicketService;
    ticketList: DtoList;
    ticketTemplate;


    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve ticket service
        this.serviceTicket = <TicketService>this.coreService.getCrudService("tm.Ticket");

        this.serviceTicket.template(new DtoTemplate()).subscribe(template => {
            this.ticketTemplate = template;
        })

        // retrieve ticket sub ticket service
        this.serviceTicketRelTicket = <TicketRelTicketService>this.coreService.getCrudService("tm.TicketRelTicket");

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshTicketSubTickets();

        this.initialized = true;
    }

    /**
     * method to return ticket filter
     */
    getTicketFilter(): FilterCriteria[] {
        return [FilterCriteria.create("ticket", FilterCriteria.cOperatorEqual, this.ticketId, null)];
    }

    /**
     * function to get subTickets related to ticket
     */
    refreshTicketSubTickets() {

        const dtoListRequest =
            ObjectRequestList.createComplexRequestList(
                true,
                null,
                this.getTicketFilter(),
                [new Sorting("lastModifiedDate", false)],
                null,
                null);

        this.serviceTicketRelTicket.list(dtoListRequest).subscribe((value: DtoList<TmTicketRelTicketDto>) => {
            this.dtoListSubTickets = value;
            this.getSubTickets(value.entries);
        });
    }

    /**
     * get proper tickets for subTickets related to ticket
     * @param subTicketList
     */
    getSubTickets(subTicketList) {
        const filterCriteriaList: FilterCriteria = FilterCriteria.createOrFromArray("id", "subTicketDtoId", subTicketList);


        if (filterCriteriaList.or) {
            const objectListRequest: ObjectRequestList = new ObjectRequestList(false, [filterCriteriaList], null)
            this.serviceTicket.list(objectListRequest).subscribe(tickets => {
                this.ticketList = tickets;
                this.dtoListSubTickets.entries.forEach((subTicket: TmTicketRelTicketDto) => {
                    const foundedTicket: DtoDetail = tickets.entries.find((ticket: TmTicketDto) => ticket.id === subTicket['subTicketDtoId']);
                    if (foundedTicket) {
                        // Store the matching object in the respective object of the dtoListSubTickets.entries array
                        subTicket.subTicketDto = <TmTicketDto>foundedTicket;
                    }
                });
            })
        }
    }


    changeDisplayMode(mode: string) {
        this.changeMode.emit(mode);
    }

    eventSubTicketIdMethod(id: number) {
        this.eventSubTicketId.emit(id);
    }

    /**
     * handle status tags color
     * @param status
     */
    handleTagColor(status: TmStatusEnum) {
        switch (status) {
            case TmStatusEnum.draft:
                return "secondary";
            case TmStatusEnum.assigned:
                return "info";
            case TmStatusEnum.working:
                return "info";
            case TmStatusEnum.awaiting_customer:
                return "warning";
            case TmStatusEnum.awaiting_insurance_company:
                return "warning";
            case TmStatusEnum.resolved:
                return "success";
            case TmStatusEnum.on_hold:
                return "help";
            case TmStatusEnum.cancelled:
                return "danger";
            default:
                return "primary";
        }
    }

    /**
     * function to collapse or expand panel
     * @param ticketPanel
     */
    collapsedChange(ticketPanel) {
        ticketPanel.animating = true;
        ticketPanel.collapsed = !ticketPanel.collapsed;
    }

    /**
     * delete subTicket related to ticket
     * @param subTicket
     */
    onDelete(subTicket: TmTicketRelTicketDto) {
        this.serviceTicketRelTicket.delete(subTicket.id).subscribe(res => {
            this.messageService.showInfoMessage('Sub-Ticket Delete', 'Ticket ' + subTicket.ticketDtoName + ' Deleted Success');
            this.refreshTicketSubTickets();
        })
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
