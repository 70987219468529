<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-2 flex flex-column gap-2">
            <mvs-widget *ngIf="customerFormWidget"
                        [widgetData]="customerFormWidget">

            </mvs-widget>


        </div>

        <div class="col-7 flex flex-column gap-2">

            <mvs-widget *ngIf="customerInteractionWidget"
                        [widgetData]="customerInteractionWidget">

            </mvs-widget>

        </div>

        <div class="col-3">
            <mvs-widget *ngIf="customerInteractionDocumentsWidget"
                        [widgetData]="customerInteractionDocumentsWidget">

            </mvs-widget>
        </div>

    </div>

</ng-container>