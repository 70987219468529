    import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavigationItem, ObjectBaseComponent} from "@kvers/alpha-core-common";
    import {ContractObjectComponent} from "../../contract-object.component";
    import {ContractObjectBaseComponent} from "../contract-object-base.component";


@Component({
    selector: 'cm-contract-object-full-component',
    templateUrl: 'contract-object-full.component.html',
})
export class ContractObjectFullComponent extends ContractObjectBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'standard',
            action: 'standard',
            tooltip: 'Standard',
            icon: 'fa-regular fa-house',
            toggleable: false,
            default: true
        },
        {
            label: 'objects',
            action: 'objects',
            tooltip: 'Objekte/ Personen',
            icon: 'fa-regular fa-rectangle-vertical-history',
            toggleable: false,
        },

        {
            label: 'documentUpload',
            action: 'documentUpload',
            tooltip: 'Dokumente',
            icon: 'fa-regular fa-file-circle-plus',
            toggleable: false,
        },
        {
            label: 'notifications',
            action: 'notifications',
            tooltip: 'Kommunikation',
            icon: 'fa-regular fa-comments',
            toggleable: false,
        },

        {
            label: '',
            action: '',
            divider: true,
        },
        {
            label: 'genericTickets',
            action: 'genericTickets',
            tooltip: 'General Tickets',
            icon: 'fa-regular fa-ticket',
            toggleable: false,
        },
        {
            label: 'addTicket',
            action: 'addTicket',
            tooltip: 'Add Ticket',
            icon: 'pi pi-plus',
            toggleable: true,
        },
        {
            label: 'genericWorkflows',
            action: 'genericWorkflows',
            tooltip: 'General Workflows',
            icon: ' fa-regular fa-sharp-duotone fa-arrow-progress',
            toggleable: false,
        },

    ];

    ngOnInit() {
        super.ngOnInit();
    }

}
