import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {TabEntries} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-co-channel-type-page',
  templateUrl: './channel-config.page.html',
  styleUrls: ['./channel-config.page.scss']
})
export class ChannelConfigPage extends PageComponent implements OnInit {

  defaultLabel: string = "Channel Types";

  coChannelTypeWidget: WidgetData;
  coChannelTypeContactTypeWidget: WidgetData;

  objectType: string;
  objectId: number;
  queryParamSubscription: Subscription;
  activeWidget: TabEntries | null = null;
  activeTabIndex: number = -1;

  widgetEntries: TabEntries[] = [
    {label:'Channel Types', id: 'co.CoChannelType', type: ''},
    {label:'Contact Type Assignment', id: 'co.CoChannelTypeContactType', type: ''},
  ];

  constructor(
      protected route: ActivatedRoute,
      protected coreService: MvsCoreService,
      protected navigationService: MvsObjectNavigationService,) {
    super(route, coreService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshWidgets();
    this.subscribeToParams();
    this.handleTabSelection(0);

  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  subscribeToParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if (params['tid']) {
        const object = this.widgetEntries.find(item => item.id == params['tid']);
        if (object) {
          this.activeTabIndex = this.widgetEntries.findIndex(item => item.id == object.id);
          this.activeWidget = object;
        }
      }
    });
  }

  refreshWidgets() {
    this.refreshCoChannelTypeWidget();
    this.refreshCoChannelTypeContactTypeWidget();
  }

  refreshCoChannelTypeWidget() {
    this.coChannelTypeWidget = WidgetFactory.createWidgetList(
        "ns.notification.group.widget.simple",
        'Channel Types',
        'table',
        'list',
        'entity',
        'co.CoChannelType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }

  refreshCoChannelTypeContactTypeWidget() {
    this.coChannelTypeContactTypeWidget = WidgetFactory.createWidgetList(
        "co.channel.type.contract.type.widget.simple",
        'Contact Type Assignment',
        'table',
        'list',
        'entity',
        'co.CoChannelTypeContactType',
        null,
        ObjectRequestList.createWithPaging(true, null, [], PagingDto.create(0, 20))
    );
  }


  handleTabSelection(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeWidget = this.widgetEntries[selectedIndex];
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(this.activeWidget.id, null, "", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  handleTemplateSelect(event: ObjectIdentifierData) {
    this.objectType = event.objectType;
    this.objectId = event.objectId;
    this.navigationService.navigateTo(MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, "object", "", "", "", MvsObjectNavigationActionEnum.edit));
  }

  isConfigurationPage(): boolean {
    return true;
  }


}
