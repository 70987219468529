
<ng-container *ngIf="initialized">

    <div class="grid">

        <div class="col-3">
            <p-listbox
                    [options]="objectTypes.entries"
                    [filter]="true"
                    filterBy="alias"
                    optionLabel="alias"
                    [multiple]="false"
                    (onChange)="onChange($event)"
                    [listStyle]="{'max-height':'800px'}"
                    >
            </p-listbox>
        </div>

        <div class="col-9">

            <p-scrollPanel [style]="{width: '100%', height: '800px'}" styleClass="bg-white">

                <p-organizationChart [value]="rootNodes"
                                     styleClass="company" *ngIf="!busy">
                    <ng-template let-node pTemplate="form">

                        <div class="grid" class="border-round-sm">
                            <div class="col-12 bg-teal-100 border-bottom-1">
                                <div class="flex">
                                    <h4>{{node.label}}</h4> &nbsp;
                                    <button pButton pRipple type="button" pButton icon="pi pi-code"  class="p-button-rounded p-button-text" (click)="generateTsClass(node);op.toggle($event)"></button>
                                    <button pButton pRipple type="button" pButton icon="pi pi-cloud"  class="p-button-rounded p-button-text" (click)="generateApiAccess(node);op.toggle($event)"></button>
                                    <button pButton pRipple type="button" pButton icon="fa-regular fa-square-question"  class="p-button-rounded p-button-text" (click)="retrieveMeta(node);op.toggle($event)"></button>

                                </div>
                            </div>
                            <div class="col-12">
                                <p-chip *ngIf="node.form.genericEntityFieldType">Generic: {{node.form.genericEntityFieldType}}</p-chip>
                            </div>

                            <div class="col-12 text-sm border-bottom-1 text-left" style="padding:5px;border-color:grey" *ngFor="let field of node['fields']">
                                <i class="pi pi-bars font-teal-900"></i>&nbsp; <b>{{ field.id }}</b> {{ (field.uiMandatory || field.uiRequired) ? '*' : '' }} : {{field.dataType}}
                            </div>

                            <div *ngIf="node['relats'] && node['relats'].length > 0" class="text-sm" style="padding-top:10px">Relationships:</div>

                            <div class="col-12 text-sm border-bottom-1 text-left" style="padding:5px;border-color:grey" *ngFor="let relat of node['relats']">
                                <div style="min-width:300px" class="flex">
                                    <div style="width:80%">
                                        <div>
                                            <p-chip>{{relat.objectTypeAlias}}</p-chip> {{relat.relationship}}
                                        </div>
                                        <div>
                                            {{relat.idField}} : number
                                        </div>
                                        <div>
                                            {{relat.nameField}} : string
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <buton pButton pRipple type="button" icon="pi pi-chevron-circle-down" class="p-button-rounded p-button-text" (click)="getSubTemplates(node, relat.objectTypeAlias)"></buton>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </ng-template>

                </p-organizationChart>

                <p-skeleton *ngIf="busy" width="100%" height="100%"></p-skeleton>

            </p-scrollPanel>

        </div>

    </div>

</ng-container>

<!-- Skeleton -->
<ng-container *ngIf="!initialized">
    <div class="grid">

        <div class="col-3">
            <p-skeleton width="100%" height="800px"></p-skeleton>
        </div>

        <div class="col-9">

        </div>
    </div>

</ng-container>


<p-overlayPanel #op>
    <ng-template pTemplate>
        <textarea [rows]="30" [cols]="60" pInputTextarea [(ngModel)]="tsClassCode" readonly="true"></textarea>
        <button pButton pRipple type="button" pButton icon="pi pi-copy"  class="p-button-rounded p-button-text" (click)="copyToClipboard()"></button>
    </ng-template>
</p-overlayPanel>

