<!--<mvs-crud-page-->
<!--        [objectStructureRequest]="objectBrowserRequest"-->
<!--        [mainObjectTypeStructureRequest]="subObjectBrowserRequest"-->
<!--        >-->
<!--</mvs-crud-page>-->

<div class="bg-white">
    <mvs-config-header header="Workflow Configuration" [categoryTypeMode]="'workflow'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'wf.WfProcessGroup'">
            <mvs-widget *ngIf="wfProcessGroupWidget"
                        [widgetData]="wfProcessGroupWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'wf.WfProcessGroupField'">
            <mvs-widget *ngIf="wfProcessGroupFieldWidget"
                        [widgetData]="wfProcessGroupFieldWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'wf.WfProcessType'">
            <mvs-widget *ngIf="wfProcessTypeWidget"
                        [widgetData]="wfProcessTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'wf.WfHintType'">
            <mvs-widget *ngIf="wfHintTypeWidget"
                        [widgetData]="wfHintTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'wf.WfResultCategory'">
            <mvs-widget *ngIf="wfResultCategoryWidget"
                        [widgetData]="wfResultCategoryWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

</div>
