import {Component, OnInit} from "@angular/core";
import {ObjectBaseModeComponent} from "@kvers/alpha-core-common";
import {CmPartnerBaseComponent} from "../cm-partner-base.component";

@Component({
    selector: 'cm-partner-full',
    templateUrl:'cm-partner-full.component.html'
})


export class CmPartnerFullComponent extends CmPartnerBaseComponent implements OnInit {

    ngOnInit() {
        super.ngOnInit();
    }
}