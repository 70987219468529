import {Component, OnInit} from '@angular/core';
import {HgGdvPartnerBaseComponent} from "../hg-gdv-partner-base.component";
import {NavigationItem} from "@kvers/alpha-core-common";


@Component({
    selector: 'hg-gdv-partner-side',
    templateUrl: 'hg-gdv-partner-side.component.html',
})
export class HgGdvPartnerSideComponent extends HgGdvPartnerBaseComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {
            label: 'gdvPartner',
            action: 'gdvPartner',
            icon: 'fa-regular fa-info-circle',
            tooltip: 'GDV Partner',
            toggleable: false,
            default: true
        },
        {
            label: 'test',
            action: 'test',
            icon: 'fa-regular fa-vials',
            tooltip: 'Test',
            toggleable: false,
            default: false
        },
    ];

    ngOnInit(): void {
        super.ngOnInit();
    }

}
