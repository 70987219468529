import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {FieldPage} from "./page/overview/field-page/field.page";
import {ObjectTypePage} from "./page/overview/object-type-page/object-type.page";
import {EntityBrowserComponent} from "./component/mvs-entity-browser/entity-browser.component";
import {EntityOverviewPage} from "./page/overview/entity-overview-page/entity-overview.page";
import {ObjectTypeMetaExtensionPage} from "./page/overview/object-type-meta-extension/object-type-meta-extension.page";
import {ObjectTypeObjectComponent} from "./component/object-type-object-component/object-type-object.component";
import {ConfigComponent} from "./page/overview/config-page/config.component";
import {AsModule} from "../as/as.module";
import {CcOverviewPage} from "./page/cc-overview-page/cc-overview.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {AgentKpiComponent} from "../am/page/agent-overview-page/components/kpiComponent/agent-kpi.component";
import {
    AgentStatisticsComponent
} from "../am/page/agent-overview-page/components/statisticsComponent/agent-statistics.component";
import {CcStatisticsComponent} from "./page/cc-overview-page/components/statisticsComponent/cc-statistics.component";
import {CcKpiComponent} from "./page/cc-overview-page/components/kpiComponent/cc-kpi.component";
import {UiModule} from "../ui/ui.module";

@NgModule({
    declarations: [
        FieldPage,
        ObjectTypePage,
        EntityBrowserComponent,
        EntityOverviewPage,
        ObjectTypeMetaExtensionPage,
        ObjectTypeObjectComponent,
        ConfigComponent,
        CcOverviewPage,
        CcStatisticsComponent,
        CcKpiComponent

    ],
    exports: [
        FieldPage,
        ObjectTypePage,
        EntityBrowserComponent,
        EntityOverviewPage,
        ObjectTypeMetaExtensionPage,
        ObjectTypeObjectComponent,
    ],
    imports: [
        CoreModule,
        AsModule,
        UiModule
    ]
})
export class CcModule implements DynamicModule {

    alias = 'cc';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return CcKpiComponent;
    }

    getStatisticsComponent() {
        return CcStatisticsComponent;
    }
}
