import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoListAttributeRequestAggregateEnum,
    EntityStatusEnum,
    FilterCriteria,
    MvsCoreService,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestEntityProcessingTypeEnum,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy, ObjectRequestRelation,
    PageComponent,
    PagingDto,
    Sorting,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../service/api/customer.service";
import {DatesHelper} from "../../../core/helper/dates-helper";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MenuItem} from "primeng/api";


@Component({
    selector: 'mvs-example',
    templateUrl: './customer-overview.page.html',
})
export class CustomerOverviewPage extends PageComponent implements OnInit, OnChanges, OnDestroy {
    defaultLabel: string = "Customer Overview";

    ngOnInit(): void {
        super.ngOnInit();
    }
    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
