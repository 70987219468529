import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {CoChannelTypeService} from "../../../../service/api/co-channel-type.service";
import {CoChannelTypeContactTypeService} from "../../../../service/api/co-channel-type-contact-type.service";
import {KpiHeaderDto} from "../../../../../core/overview/dto/kpi-header.dto";
import {OverviewKpiBaseComponent} from "../../../../../core/overview/overview-kpi-base.component";


@Component({
    selector: 'mvs-example',
    templateUrl: 'co-kpi.component.html',
})
export class CoKpiComponent extends OverviewKpiBaseComponent implements OnInit, OnChanges, OnDestroy {

    totalChannelType: number = 0;
    totalChannelTypeContactType: number = 0;

    items: MenuItem[] | undefined = [{label: 'CO Module'}];
    kpiHeaderDto: KpiHeaderDto[];

    constructor(
        protected channelTypeService: CoChannelTypeService,
        protected channelTypeContactTypeService: CoChannelTypeContactTypeService,) {
        super()
    }

    ngOnInit(): void {

        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
        this.getTotalChannels();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    getTotalChannels() {

        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.channelTypeService.list(objectRequestList).subscribe(res => {
            this.totalChannelType = res.entries.length;
            this.getTotalChannelsContactTypes();
        },error => {
            this.getTotalChannelsContactTypes();
        });
    }

    getTotalChannelsContactTypes() {

        const objectRequestList = ObjectRequestList.createBasic(
            false,
            [],
            [],
        );

        this.channelTypeContactTypeService.list(objectRequestList).subscribe(res => {
            this.totalChannelTypeContactType = res.entries.length;
            this.initKpiHeaderDto();
        },error => {
            this.initKpiHeaderDto();
        });
    }

    initKpiHeaderDto() {

        this.kpiHeaderDto = [
            {
                label: 'Total Channel Types', value: this.totalChannelType,

            },
            {
                label: 'Total Channel Type Contact Types', value: this.totalChannelTypeContactType,
            },

        ]
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
