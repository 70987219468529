import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
    WfProcessStepActivityBaseComponent
} from "../../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {WfProcessRuntimeBindingDto} from "../../../../wf/service/dto/wf-process-runtime-binding.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {NsNotificationRequestDto} from "../../../dto/service/ns-notification-request.dto";
import {NsNotificationObjectReferenceDto} from "../../../dto/service/ns-notification-object-reference.dto";
import {
    NsNotificationTypesModeEnum
} from "../../ns-notification-types-for-object/enums/ns-notification-types-mode.enum";
import {NsNotificationTypesOverviewDto} from "../../../dto/service/ns-notification-types-overview.dto";
import {NsNotificationService} from "../../../service/api/ns-notification.service";
import {NsNotificationDto} from "../../../dto/ns-notification.dto";
import {WfActivityProcessingResultFieldDto} from "../../../../wf/service/dto/wf-activity-processing-result-field.dto";
import {WfProcessRuntimeService} from "../../../../wf/service/wf-process-runtime.service";

@Component({
    selector: 'mvs-wf-process-step-activity-object-notification',
    templateUrl: './ns-notification-comment-wf-process-step-main-activity.component.html',
})
export class NsNotificationCommentWfProcessStepMainActivityComponent
    extends WfProcessStepActivityBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    notificationTypeDtoId: number;
    objectIdentifier: ObjectIdentifier;

    mode: NsNotificationTypesModeEnum = NsNotificationTypesModeEnum.object;

    notificationTypesOverview: NsNotificationTypesOverviewDto;
    notificationSourceTypeId: number;
    protected notificationService: NsNotificationService;


    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.notificationService = <NsNotificationService>this.coreService.getCrudService("ns.NsNotification");


        const importReferenceObject = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "importReferenceObject");

        this.objectIdentifier = new ObjectIdentifier('wf.WfProcessStepActivity', this.activity.id);

        if (importReferenceObject) {
            this.objectIdentifier = new ObjectIdentifier(importReferenceObject.objectType.alias, importReferenceObject.objectValue.id);
        }

        if (this.activityType.activityNotificationDto) {

            // TODO: ask Marko notificationTypeDtoId usage
            this.notificationTypeDtoId = this.activityType.activityNotificationDto.notificationTypeDtoId;

        }

        this.refreshNotificationType();

    }

    refreshNotificationType() {

        this.busy = true;
        this.notificationTypesOverview = undefined;

        // default mode is recipient
        this.notificationService.getNotificationTypesInfo(this.getRequest()).subscribe(value => {

            this.notificationTypesOverview = value;
            this.notificationSourceTypeId = value.types[0].notificationSource.objectId

            this.initialized = true;
            this.busy = false;
        }, (error) => {
            this.messageService.showErrorMessage('Error', error);
            this.busy = false;
        });

    }


    /**
     * Derive request based on provided parameters.
     * @protected
     */
    protected getRequest(): NsNotificationRequestDto {

        let recipient: NsNotificationObjectReferenceDto | null = null;
        let notificationObject: NsNotificationObjectReferenceDto | null = null;

        if (this.mode == NsNotificationTypesModeEnum.recipient) {
            recipient = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        } else {
            notificationObject = {
                objectTypeAlias: this.objectIdentifier.objectType,
                objectId: this.objectIdentifier.objectId
            };
        }

        return {
            objectBased: (this.mode == NsNotificationTypesModeEnum.object),
            referenceObject: notificationObject,
            referenceRecipient: recipient
        };
    }

    handleSentNotification(notification: NsNotificationDto) {

        const result: WfActivityProcessingResultFieldDto = {
            alias: 'processedNotification',
            action: 0,
            valueObjectTypeAlias: 'ns.NsNotification',
            valueObjectId: notification.id
        };

        WfProcessRuntimeService.setResult([result])
    }

}
