import {Component, Input, SimpleChanges} from '@angular/core';
import {CallService} from "../../service/api/call.service";
import {CallDto} from "../../dto/call.dto";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../cr/service/api/customer.service";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {interval, Subscription} from "rxjs";
import {CallResultEnum} from "../../enum/call-result-enum.enum";
import {ConfirmationService} from "primeng/api";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {
    ObjectChangeInformation,
    ObjectChangeInformationActionEnum
} from "@kvers/alpha-core-common";

interface Time {
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
}

@Component({
    selector: 'mvs-pc-call-active',
    templateUrl: './pc-call-active.component.html',
    styleUrls: ['./pc-call-active.component.scss'],
})
export class PcCallActiveComponent {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() dto;

    @Input() refreshTimeStamp: any;

    myActiveCalls: DtoList<CallDto>;
    calledObjectType: ObjectTypeDto;
    calledObject: DtoDetail;
    activeCall: CallDto;

    timer$: Subscription;
    timer: Time;

    confirmationMessage: string = "Willst du den Anruf wirklich beenden?"
    confirmDialogShown: boolean = false;
    callResultEnum = CallResultEnum;

    constructor(
        protected confirmationService:ConfirmationService,
        protected callService:CallService,
        protected objectTypeService:ObjectTypeService,
        protected objectService: MvsUiObjectService,
        protected customerService:CustomerService,
        protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        if (this.timer$) {
            this.destroyTimer();
        }

        this.callService.getMyActiveCalls().subscribe(activeCalls => {
            this.myActiveCalls = <DtoList<CallDto>>activeCalls;

            // check if multiple calls are active
            if(this.myActiveCalls.entries.length == 0){
                this.activeCall = null;
                return;
            }

            for (const activeCall of this.myActiveCalls.entries) {

                this.getCalledObjectTypeDto(activeCall.objectTypeDtoId).then(res => {
                    if (res.alias === 'cr.Customer'){
                        this.customerService.get(activeCall.objectId,null,false,false,0)
                            .subscribe( value =>{
                                this.calledObject = value;
                                this.activeCall = activeCall;
                                this.timer$ = interval(1000).subscribe(() => {
                                        this.timer = this.calcElapsedTime(activeCall.startTime);
                                    }
                                )

                            });

                    }
                    else if(res.alias === 'pm.partner'){
                        //call partner service
                    }
                    else{
                        return null;
                    }
                });
            }
        });

        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        if (changes["refreshTimeStamp"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.destroyTimer();
    }

    protected destroyTimer() {
        if (this.timer$) {
            this.timer$.unsubscribe();
            this.timer$ = null;
        }
    }

    public handleEndCall(result: CallResultEnum) {
        this.callService.endCustomerCall(this.activeCall.id, result, null).subscribe(value => {
            if (result === CallResultEnum.success || result === CallResultEnum.no_time){
                this.navigateToCall();
            }

            // inform object service that the call has changed, this is required so that other consumers can update
            // their data
            this.objectService.broadcastObjectAction(ObjectChangeInformation.create("pc.Call",ObjectChangeInformationActionEnum.updated, this.activeCall, value));

            this.activeCall = null;
            this.confirmDialogShown = false;

        });
    }

    public navigateToCall() {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("pc.Call", this.activeCall.id, "Call", null, null,null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    public handleShowCall() {
        this.navigateToCall();
    }

    async getCalledObjectTypeDto(objectTypeDtoId: number){
        this.calledObjectType = await this.objectTypeService.getViaObjectId(objectTypeDtoId);
        return this.calledObjectType;
    }


    private calcElapsedTime(startTime: Date): Time {

        let totalSeconds = Math.floor((new Date((new Date()).toISOString()).getTime() - new Date(startTime).getTime()) / 1000);

        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        if (totalSeconds >= 3600) {
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds -= 3600 * hours;
        }

        if (totalSeconds >= 60) {
            minutes = Math.floor(totalSeconds / 60);
            totalSeconds -= 60 * minutes;
        }

        seconds = totalSeconds;

        return {
            hours: hours < 10 ? '0'+hours : hours,
            minutes: minutes < 10 ? '0'+minutes : minutes,
            seconds: seconds < 10 ? '0'+seconds : seconds
        };
    }


}
