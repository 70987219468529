import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {EuAppConfigPage} from "./page/overview/eu-app-config/eu-app-config.page";
import {EuOverviewPage} from "./page/overview/eu-overview/eu-overview.page";
import {
  EuRegistrationOverviewPage
} from "./page/overview/eu-registration-overview/eu-registration-overview.page";
import {EuRegistrationObjectComponent} from "./component/eu-registration-object/eu-registration-object.component";
import {EuConfigComponent} from "./page/overview/eu-config-page/eu-config.component";
import {EuConfigPageWebappComponent} from "./page/overview/eu-config-page-webapp/eu-config-page-webapp.component";
import {OverviewEuPage} from "./page/eu-overview-page/overview-eu.page";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {DmKpiComponent} from "../dm/page/dm-overview-page/components/kpiComponent/dm-kpi.component";
import {
  DmStatisticsComponent
} from "../dm/page/dm-overview-page/components/statisticsComponent/dm-statistics.component";
import {EuKpiComponent} from "./page/eu-overview-page/components/kpiComponent/eu-kpi.component";
import {EuStatisticsComponent} from "./page/eu-overview-page/components/statisticsComponent/eu-statistics.component";
import {UiModule} from "../ui/ui.module";


@NgModule({
  declarations: [
    EuAppConfigPage,
    EuOverviewPage,
    EuRegistrationOverviewPage,
    EuRegistrationObjectComponent,
    EuConfigComponent,
    EuConfigPageWebappComponent,
    OverviewEuPage,
    EuKpiComponent,
    EuStatisticsComponent
  ],
  exports:[
    EuAppConfigPage,
    EuOverviewPage,
    EuRegistrationOverviewPage,
    EuRegistrationObjectComponent,
    EuConfigComponent,
    EuConfigPageWebappComponent,
  ],
  imports: [
    CoreModule,
    UiModule,
  ]
})
export class EuModule  implements DynamicModule {

  alias = 'eu';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return EuKpiComponent;
  }

  getStatisticsComponent() {
    return EuStatisticsComponent;
  }
}
